import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  useAssetTypeQuery,
  useMaintenanceTemplateUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { MaintenanceTemplateFormData } from "../assetTypesMaintenancesCreate/presenter";
import AssetTypeMaintenancesEditPresenter from "./presenter";

interface AssetTypeMaintenancesEditProps {
  onClose: () => void;
  assetTypeId: string;
  assetCategoryId: string;
  maintenanceId: string;
}

const AssetTypeMaintenancesEdit: React.FC<AssetTypeMaintenancesEditProps> = ({
  onClose,
  assetTypeId,
  assetCategoryId,
  maintenanceId,
}) => {
  const navigate = useNavigate();
  const { data } = useAssetTypeQuery({
    variables: { id: assetTypeId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [maintenanceTemplateUpdateMutation, { loading }] =
    useMaintenanceTemplateUpdateMutation();
  const toast = useToast();

  const onSubmit = async (
    data: MaintenanceTemplateFormData,
    { setFieldError }: FormikHelpers<MaintenanceTemplateFormData>
  ) => {
    try {
      const { data: serverData, errors } =
        await maintenanceTemplateUpdateMutation({
          variables: { data, id: maintenanceId },
        });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onModalClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onModalClose = () => {
    onClose();
    setTimeout(() => {
      navigate(
        getRoutePath("assetTypesMaintenancesShow", {
          assetCategoryId,
          assetTypeId,
        })
      );
    }, 500);
  };

  const maintenance = useMemo(
    () =>
      data?.assetType.maintenanceTemplates.find(
        (mt) => mt.id === maintenanceId
      ),
    [data?.assetType.maintenanceTemplates, maintenanceId]
  );

  return (
    <Modal isOpen onClose={onModalClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton />
          {maintenance ? (
            <>
              <ModalHeader textAlign="left" pr="12">
                Edit Default Service Schedule
              </ModalHeader>
              <AssetTypeMaintenancesEditPresenter
                handleSubmit={onSubmit}
                isLoading={loading}
                maintenance={maintenance}
              />
            </>
          ) : (
            <ModalBody>
              <PageSpinner />
            </ModalBody>
          )}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AssetTypeMaintenancesEdit;
