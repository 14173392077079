import { Icon, IconProps } from "@chakra-ui/react";

const HumiditySensor = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M40.942 68.319c5.898-11.494 14.706-23.963 18.856-29.633l.202.148.202-.148c4.15 5.67 12.959 18.14 18.856 29.633 1.598 3.114 3 6.148 4.001 8.932 1.001 2.78 1.61 5.33 1.61 7.47 0 13.613-11.056 24.668-24.669 24.668-13.613 0-24.668-11.055-24.668-24.668 0-2.14.608-4.69 1.609-7.47 1.002-2.784 2.403-5.818 4.001-8.933Zm0 0 .223.114-.223-.115ZM60 31.25c.832 0 1.619.39 2.127 1.05v.001l.002.003.007.01.03.038.114.15.444.59a241.9 241.9 0 0 1 1.648 2.222 321.389 321.389 0 0 1 5.553 7.825c4.41 6.413 9.834 14.804 13.9 22.731l.222-.114-.222.114c3.698 7.21 6.204 14.051 6.204 18.85 0 16.573-13.457 30.03-30.029 30.03s-30.029-13.457-30.029-30.03c0-4.799 2.506-11.64 6.204-18.85l-.202-.104.202.104c4.066-7.927 9.49-16.318 13.9-22.732a321.389 321.389 0 0 1 5.553-7.824 241.9 241.9 0 0 1 2.092-2.811l.115-.151.03-.039.007-.01.001-.002A2.684 2.684 0 0 1 60 31.25Z"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.5 73a5.502 5.502 0 0 0-5.5 5.5c0 3.036 2.464 5.5 5.5 5.5s5.5-2.464 5.5-5.5-2.464-5.5-5.5-5.5Zm0 4.714a.786.786 0 1 1 0 1.572.786.786 0 0 1 0-1.572ZM67.5 86c-3.036 0-5.5 2.24-5.5 5s2.464 5 5.5 5 5.5-2.24 5.5-5-2.464-5-5.5-5Zm0 4.286c.434 0 .786.32.786.714 0 .394-.352.714-.786.714-.434 0-.786-.32-.786-.714 0-.394.352-.714.786-.714ZM67.919 73.7 48.7 92.92a2.39 2.39 0 0 0 3.378 3.38L71.3 77.08a2.39 2.39 0 0 0-3.381-3.378Z"
      fill="currentColor"
    />
    <path
      d="M72.571 24c-3.426.136-3.43 5.03 0 5.166 6.208 0 11.26 5.047 11.26 11.25a2.584 2.584 0 0 0 5.169 0C89 31.366 81.63 24 72.571 24Z"
      fill="currentColor"
    />
    <path
      d="M72.565 13c-3.416.135-3.424 5.017 0 5.155 12.284 0 22.278 9.989 22.278 22.268a2.578 2.578 0 0 0 5.157 0C100 25.302 87.693 13 72.565 13Z"
      fill="currentColor"
    />
  </Icon>
);

export default HumiditySensor;
