import { Icon, IconProps } from "@chakra-ui/react";

const Docx = (props: IconProps & any) => {
  return (
    <Icon width="12" height="auto" viewBox="0 0 48 48" {...props}>
      <path
        d="M40 23.333h-2.667v-2.666L40 23.333zM8 23.333h2.667v-2.666L8 23.333z"
        fill="#134996"
      />
      <path
        d="M38 40a2 2 0 01-2 2H12a2 2 0 01-2-2V8a2 2 0 012-2h17.172a2 2 0 011.414.586l6.828 6.828A2 2 0 0138 14.828V40z"
        fill="#F1F1F1"
      />
      <path stroke="#CCC" d="M15 17.5h18M15 19.5h18M15 21.5h18" />
      <path d="M38 14h-7a1 1 0 01-1-1V6l8 8z" fill="#CCC" />
      <path
        d="M8 23.333c0-.368.298-.666.667-.666h30.666c.369 0 .667.298.667.666v11.334a.667.667 0 01-.667.666H8.667A.667.667 0 018 34.667V23.333z"
        fill="#1C5DBB"
      />
      <path
        d="M16.26 27.197v3.59h1.072c.455 0 .806-.17 1.053-.51.248-.34.372-.764.372-1.273 0-.515-.124-.945-.372-1.29-.247-.345-.598-.517-1.053-.517H16.26zm1.072 4.718H15v-5.847h2.332c.835 0 1.49.277 1.969.832.478.548.717 1.25.717 2.104 0 .855-.24 1.553-.717 2.096-.478.544-1.134.815-1.97.815zM22.53 27.748c-.339.345-.509.764-.509 1.256s.17.911.51 1.256c.34.34.754.51 1.244.51.489 0 .903-.173 1.243-.518a1.7 1.7 0 00.518-1.248c0-.492-.172-.91-.518-1.256a1.679 1.679 0 00-1.244-.517c-.489 0-.903.172-1.243.517zm-1.77 1.256c0-.831.291-1.539.873-2.121A2.912 2.912 0 0123.775 26c.846 0 1.56.294 2.141.883.587.582.881 1.29.881 2.121 0 .826-.294 1.534-.88 2.122-.588.583-1.302.874-2.143.874-.84 0-1.554-.291-2.141-.874a2.91 2.91 0 01-.872-2.122zM31.98 30.023l1.02.695c-.265.397-.616.71-1.054.942-.437.227-.92.34-1.45.34-.847 0-1.558-.291-2.134-.874-.57-.583-.854-1.29-.854-2.122 0-.837.284-1.547.854-2.13.576-.583 1.287-.874 2.134-.874.518 0 .993.113 1.425.34.437.22.788.52 1.053.899l-1.028.696c-.357-.51-.84-.764-1.45-.764-.49 0-.899.178-1.227.535-.328.35-.492.783-.492 1.298 0 .51.164.942.492 1.299.328.35.737.526 1.227.526.305 0 .587-.074.846-.22.259-.148.472-.343.639-.586z"
        fill="#fff"
      />
    </Icon>
  );
};

export default Docx;
