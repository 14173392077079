import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pluralize from "pluralize";
import React from "react";

import { MaintenanceIntervalType } from "../../../graphql/graphql";
import AssetTypeMaintenanceDelete from "./assetTypeMaintenanceDelete";

interface AssetTypeMaintenanceProps {
  id: string;
  assetTypeId: string;
  name: string;
  intervalType: MaintenanceIntervalType;
  intervalValue: number;
  canEditAssetTypeMaintenance: boolean;
  canDeleteAssetTypeMaintenance: boolean;
  setSelectedMaintenanceId: (id: string) => void;
}

const AssetTypeMaintenance: React.FC<AssetTypeMaintenanceProps> = ({
  id,
  assetTypeId,
  name,
  intervalType,
  intervalValue,
  canEditAssetTypeMaintenance,
  canDeleteAssetTypeMaintenance,
  setSelectedMaintenanceId,
}) => {
  return (
    <Flex
      paddingBottom="2"
      marginBottom="2"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <Box flexGrow={1}>
        <Heading size="md" fontWeight="600">
          {name}
        </Heading>
        <Text fontSize="xs">
          Sent every{" "}
          {pluralize(intervalType.toLowerCase(), intervalValue, true)}
        </Text>
      </Box>
      <Box flexShrink={1}>
        {canEditAssetTypeMaintenance && (
          <Button
            onClick={() => setSelectedMaintenanceId(id)}
            aria-label="Edit asset type"
            variant="icon"
            colorScheme="gray"
            padding={2}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
        )}
        {canDeleteAssetTypeMaintenance && (
          <AssetTypeMaintenanceDelete name={name} id={id} />
        )}
      </Box>
    </Flex>
  );
};

export default AssetTypeMaintenance;
