import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import { useAssetCategoryQuery } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import AssetTypeCreatePresenter from "./presenter";

interface AssetTypeCreateProps {}

const AssetTypeCreate: React.FC<AssetTypeCreateProps> = () => {
  const { assetCategoryId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { data } = useAssetCategoryQuery({
    variables: { id: assetCategoryId },
  });

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetCategories"));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>
          Add your own custom asset type for {data?.assetCategory.name}
        </title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex alignItems="center">
              <Box>
                Add your own custom asset type for {data?.assetCategory.name}
              </Box>
              <Tooltip
                label={`Create a new asset type for ${data?.assetCategory.name} that you want to add to your plans and locations.`}
                hasArrow
              >
                <Box color="gray.700" marginLeft="2">
                  <FontAwesomeIcon icon={faInfoCircle} size="xs" />
                </Box>
              </Tooltip>
            </Flex>
          </DrawerHeader>
          <AssetTypeCreatePresenter
            onClose={onDrawerClose}
            assetCategory={data?.assetCategory}
          />
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetTypeCreate;
