import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useSearchParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import {
  useAppSettingsQuery,
  usePlansQuery,
  useUserWithCompaniesQuery,
} from "../../graphql/graphql";
import Presenter from "../../pages/companyPlanSubscribe/presenter";

const ChangePlanModal: React.FC<{ currentCompanyId: string }> = ({
  currentCompanyId,
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: plansData } = usePlansQuery();
  const { data: companiesData } = useUserWithCompaniesQuery();
  const { data: appSettingsQueryData } = useAppSettingsQuery();
  const appSettingsQuery = appSettingsQueryData?.appSettings;

  const handleModalClose = () => {
    setSearchParams({});
  };

  return (
    <Modal
      isOpen
      onClose={handleModalClose}
      size="full"
      trapFocus={!appSettingsQuery?.plaidOpen}
    >
      <Helmet>
        <title>
          {searchParams.has("upgradePlan")
            ? "Upgrade Your Plan"
            : "Change Your Plan"}
        </title>
      </Helmet>
      <ModalOverlay zIndex={1800}>
        <ModalContent margin="0" height="screenFull" overflowY="auto">
          <ModalCloseButton />
          <ModalBody>
            {plansData && companiesData ? (
              <Presenter
                plans={plansData.plans}
                userCompanies={companiesData.user.companyUsers.map(
                  (cu) => cu.company
                )}
                selectedCompanyId={currentCompanyId}
                redirectLink={`${location.pathname}?CaAprType=planChanged`}
                showCancelLink={false}
                header={
                  searchParams.has("upgradePlan")
                    ? "Upgrade Your Plan"
                    : "Change Your Plan"
                }
              />
            ) : (
              <PageSpinner />
            )}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ChangePlanModal;
