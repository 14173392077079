import { Icon, IconProps } from "@chakra-ui/react";

const LightLed = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M64 30.043c-19.108 0-34.655 15.446-34.655 34.434a34.197 34.197 0 0 0 18.227 30.232v11.47c0 1.974 1.61 3.574 3.597 3.574h25.662c1.987 0 3.597-1.599 3.597-3.574V94.797c11.145-6.028 18.226-17.69 18.226-30.32 0-18.987-15.546-34.434-34.654-34.434Zm11.326 59.306a3.575 3.575 0 0 0-2.093 3.247v10.008H54.767V92.477a3.574 3.574 0 0 0-2.137-3.266c-9.774-4.317-16.09-14.027-16.09-24.734C36.54 49.432 48.859 37.19 64 37.19c15.142 0 27.46 12.24 27.46 27.286 0 10.623-6.334 20.386-16.134 24.872ZM74.432 112.851H53.568c-1.987 0-3.598 1.6-3.598 3.574 0 1.974 1.61 3.574 3.598 3.574h20.864c1.987 0 3.598-1.6 3.598-3.574 0-1.974-1.61-3.574-3.598-3.574ZM67.598 8h-7.195v14.894h7.195V8ZM22.99 60.068H8v7.15h14.99v-7.15ZM120 60.068h-14.989v7.15H120v-7.15ZM26.958 21.782l-5.059 5.084L32.572 37.35l5.058-5.084-10.672-10.485ZM101.042 21.785 90.37 32.27l5.058 5.084 10.673-10.486-5.059-5.083Z"
      fill="currentColor"
    />
    <path
      d="M53.856 73H43.848V56.464h4.056v13.032h5.952V73Zm13.358 0H56.27V56.464h10.728v3.504h-6.72v3.168h5.904v3.432h-5.904v2.928h6.936V73Zm7.033-12.96v9.384h2.52c1.104 0 1.96-.448 2.568-1.344.624-.912.936-2.016.936-3.312 0-1.312-.312-2.424-.936-3.336-.608-.928-1.464-1.392-2.568-1.392h-2.52ZM70.215 73V56.464h6.552c2.32 0 4.152.784 5.496 2.352 1.344 1.552 2.016 3.536 2.016 5.952 0 2.4-.672 4.376-2.016 5.928C80.935 72.232 79.103 73 76.767 73h-6.552Z"
      fill="currentColor"
    />
  </Icon>
);

export default LightLed;
