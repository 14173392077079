import { Box } from "@chakra-ui/react";
import React from "react";

import { StripePaymentMethodTypeFragmentFragment } from "../../../graphql/graphql";
import CardIcon from "../creditCardIcon";

interface CreditCardProps {
  card: Pick<StripePaymentMethodTypeFragmentFragment, "card">;
  showExpiry?: boolean;
}

const CreditCard: React.FC<CreditCardProps> = ({ card, showExpiry = true }) => {
  const cardDetails = card.card;
  const [cardExpired, setCardExpired] = React.useState(false);

  React.useEffect(() => {
    if (
      showExpiry &&
      cardDetails &&
      cardDetails.exp_month &&
      cardDetails.exp_year
    ) {
      const today = new Date();
      const thisYear = today.getFullYear();
      const thisMonth = today.getMonth() + 1;
      if (
        cardDetails.exp_year < thisYear ||
        (cardDetails.exp_year === thisYear && cardDetails.exp_month < thisMonth)
      )
        setCardExpired(true);
    }
  }, [showExpiry, setCardExpired, cardDetails]);

  return (
    <>
      <Box marginRight={3} color="gray.700">
        <CardIcon brand={cardDetails?.brand} styleProps={{ boxSize: "50px" }} />
      </Box>
      <Box
        marginRight="2"
        fontSize="sm"
        color="black"
        flexGrow={1}
        textAlign="left"
      >
        <Box marginBottom="1">
          <Box as="span" textTransform="uppercase">
            {cardDetails?.brand}{" "}
          </Box>
          <Box lineHeight="21px" display={{ md: "inline" }}>
            XX{cardDetails?.last4}
          </Box>
        </Box>
        <Box color={cardExpired ? "red.500" : "gray.600"}>
          <Box as="span">Exp.</Box>
          <Box as="span" marginX="1">
            {cardDetails?.exp_month
              ? `${
                  cardDetails.exp_month < 10
                    ? "0" + cardDetails.exp_month
                    : cardDetails.exp_month
                }`
              : ""}{" "}
            / {cardDetails?.exp_year}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreditCard;
