import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { faFileUpload, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pluralize from "pluralize";
import React from "react";

import CenteredFullHeight from "../../../components/elements/centeredFullHeight";
import FilesUpload from "../../../components/elements/filesUpload";
import LabeledMenuButton from "../../../components/elements/labeledMenuButton";
import Link from "../../../components/elements/link";
import AddCircle from "../../../components/icons/addCircle";
import FolderIcon from "../../../components/icons/folder";
import ListViewIcon from "../../../components/icons/listView";
import TilesViewIcon from "../../../components/icons/tilesView";
import {
  CompanyFragmentFragment,
  FileFragmentFragment,
  FolderConfigQuery,
  FolderDisplayType,
  FolderFragmentFragment,
  useUpdateFolderConfigMutation,
} from "../../../graphql/graphql";
import { FolderWithChildren } from "../../../hooks/useFolder";
import { getRoutePath } from "../../../router";
import { mainLayoutPaddingX } from "../../../utils/layout";
import orderBy, { sortByOptions } from "../../../utils/sort";
import FolderBreadCrumb from "./breadCrumb";
import FilesBulkActions from "./bulkActions";
import GridPresenter from "./grid";
import ListPresenter from "./list";

interface FolderPresenterProps {
  folder: FolderWithChildren;
  currentCompany: CompanyFragmentFragment;
  folderConfigData: FolderConfigQuery;
  canAddFolder: boolean;
  canAddFile: boolean;
  canEditFile: boolean;
  canDeleteFile: boolean;
  canEditFolder: boolean;
  canDeleteFolder: boolean;
}

const FolderPresenter: React.FC<FolderPresenterProps> = ({
  folder,
  currentCompany,
  folderConfigData,
  canAddFolder,
  canAddFile,
  canEditFile,
  canDeleteFile,
  canEditFolder,
  canDeleteFolder,
}) => {
  const folderId = folder.id;
  const folderConfig = folderConfigData.folderConfig;
  const [updateFolderConfigMutation] = useUpdateFolderConfigMutation();
  const [selectedItems, setSelectedItems] = React.useState<
    Array<FolderFragmentFragment | FileFragmentFragment | undefined>
  >([]);

  const folderFiles = React.useMemo(() => {
    const { sortBy, sortOrder } = folderConfig;
    const folders = orderBy(folder.children, sortBy, sortOrder);
    const files = orderBy(folder.files, sortBy, sortOrder);
    return [...folders, ...files];
  }, [folder, folderConfig]);

  const setFileView = React.useCallback(
    (displayType: FolderDisplayType) => {
      updateFolderConfigMutation({
        variables: { displayType },
      });
    },
    [updateFolderConfigMutation]
  );

  const setSort = React.useCallback(
    (value) => {
      const [sortBy, sortOrder] = value.split("-");
      updateFolderConfigMutation({
        variables: { sortBy, sortOrder },
      });
    },
    [updateFolderConfigMutation]
  );

  const selectedSortOption = React.useMemo(
    () =>
      sortByOptions.find(
        (option) =>
          option.value === `${folderConfig.sortBy}-${folderConfig.sortOrder}`
      ),
    [folderConfig]
  );

  const handleUploadFileClick = () => {
    const filesUploadButton = document.getElementById("filesUploadButton");
    if (filesUploadButton) filesUploadButton.click();
  };

  return (
    <Box>
      <Box paddingX={mainLayoutPaddingX}>
        {!!folder.parentId && (
          <Box marginBottom="4">
            <Flex alignItems="center" marginBottom="3">
              <FolderIcon width="8" height="auto" />
              <Heading
                marginX="2"
                top="-2px"
                position="relative"
                display="flex"
                alignItems="center"
              >
                {folder.name}
                <Box as="small" marginLeft="2" marginTop="2" fontSize="sm">
                  ({pluralize("file", folder.files.length, true)})
                </Box>
              </Heading>
              {![
                currentCompany.uploadsFolderId,
                currentCompany.defaultFolderId,
              ].includes(folderId) && (
                <Tooltip label="Info" placement="bottom" hasArrow>
                  <Link
                    to={getRoutePath("foldersInfo", { folderId })}
                    aria-label="Folder info"
                    variant="icon"
                    colorScheme="gray"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Link>
                </Tooltip>
              )}
            </Flex>
            <FolderBreadCrumb folderId={folder.id} />
          </Box>
        )}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={{ base: selectedItems.length > 0 ? "2" : "6", sm: "6" }}
        >
          <Flex alignItems="center">
            <IconButton
              onClick={() => setFileView(FolderDisplayType.List)}
              variant="icon"
              aria-label="List File View"
              colorScheme={
                folderConfig.displayType === FolderDisplayType.Grid
                  ? "secondary"
                  : "gray"
              }
              fontSize="xl"
              marginRight="4"
              minWidth="auto"
              _hover={{ color: "gray.800" }}
            >
              <ListViewIcon />
            </IconButton>
            <IconButton
              onClick={() => setFileView(FolderDisplayType.Grid)}
              variant="icon"
              aria-label="Grid File View"
              colorScheme={
                folderConfig.displayType === FolderDisplayType.List
                  ? "secondary"
                  : "gray"
              }
              fontSize="xl"
              marginRight="4"
              minWidth="auto"
              _hover={{ color: "gray.800" }}
            >
              <TilesViewIcon />
            </IconButton>
            <Box>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <LabeledMenuButton
                      isOpen={isOpen}
                      value={
                        selectedSortOption ? selectedSortOption.label : "Sort"
                      }
                    />
                    <MenuList>
                      {sortByOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => setSort(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            {selectedItems.length > 0 && (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                display={{ base: "none", sm: "flex" }}
              >
                <Text color="gray.300" fontSize="sm">
                  {selectedItems.length} Selected
                </Text>
                <Button
                  variant="link"
                  marginX="4"
                  colorScheme="secondary"
                  onClick={() => {
                    if (selectedItems.length === folderFiles.length) {
                      setSelectedItems([]);
                    } else {
                      setSelectedItems(folderFiles);
                    }
                  }}
                >
                  {selectedItems.length === folderFiles.length
                    ? "Deselect All"
                    : "Select All"}
                </Button>
                <FilesBulkActions
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  folderId={folder.id}
                  canDeleteFolder={canDeleteFolder}
                />
              </Flex>
            )}
            {canAddFolder && (
              <Tooltip label="Create new folder" placement="bottom" hasArrow>
                <Link
                  to={getRoutePath("foldersCreate", { folderId })}
                  aria-label="Create folder"
                  variant="icon"
                  colorScheme="secondary"
                  marginX="5"
                >
                  <AddCircle boxSize="32px" />
                </Link>
              </Tooltip>
            )}
            {canAddFile && (
              <FilesUpload
                folderId={folderId}
                buttonVariant="icon"
                buttonColorScheme="secondary"
                buttonText={<FontAwesomeIcon icon={faFileUpload} size="2x" />}
              />
            )}
          </Flex>
        </Flex>
        {selectedItems.length > 0 && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom="6"
            display={{ base: "flex", sm: "none" }}
          >
            <FilesBulkActions
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              folderId={folder.id}
              canDeleteFolder={canDeleteFolder}
            />
            <Flex direction="column" alignItems="flex-end">
              <Text color="gray.300" fontSize="sm">
                {selectedItems.length} Selected
              </Text>
              <Button
                variant="link"
                colorScheme="secondary"
                onClick={() => {
                  if (selectedItems.length === folderFiles.length) {
                    setSelectedItems([]);
                  } else {
                    setSelectedItems(folderFiles);
                  }
                }}
              >
                {selectedItems.length === folderFiles.length
                  ? "Deselect All"
                  : "Select All"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Box>
      <Box width="full">
        {folderFiles.length > 0 ? (
          folderConfigData?.folderConfig.displayType ===
          FolderDisplayType.Grid ? (
            <GridPresenter
              folderFiles={folderFiles}
              canDeleteFile={canDeleteFile}
              canDeleteFolder={canDeleteFolder}
              canEditFile={canEditFile}
              canEditFolder={canEditFolder}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          ) : (
            <ListPresenter
              folderFiles={folderFiles}
              canDeleteFile={canDeleteFile}
              canDeleteFolder={canDeleteFolder}
              canEditFile={canEditFile}
              canEditFolder={canEditFolder}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )
        ) : (
          canAddFile && (
            <CenteredFullHeight>
              <Button onClick={handleUploadFileClick}>Upload Your File</Button>
            </CenteredFullHeight>
          )
        )}
      </Box>
    </Box>
  );
};

export default FolderPresenter;
