import { FC } from "react";

import { WOComment } from "../../../../../../../graphql/types/queries/workOrdersQuery";
import CommentItemPresenter from "./presenter";

interface CommentItemProps {
  comment: WOComment;
  timezone: string;
}

const CommentItem: FC<CommentItemProps> = ({ comment, timezone }) => {
  return <CommentItemPresenter comment={comment} timezone={timezone} />;
};

export default CommentItem;
