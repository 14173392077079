import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import MainBreakerInput from "../../../../../components/elements/mainBreakerInput";
import NumberInput from "../../../../../components/elements/numberInput";
import { GENERIC_SAVED_MESSAGE } from "../../../../../constants/lang/en";
import {
  DEFAULT_BREAKER_ROWS,
  DEFAULT_BREAKER_STARTING_NUMBER,
  DEFAULT_MAIN_BREAKER_OR_LUG,
  DEFAULT_MAIN_BREAKER_OR_LUG_POLES,
  DEFAULT_MAIN_BREAKER_OR_LUG_POSITION,
} from "../../../../../constants/misc";
import {
  AssetDocument,
  AssetFragmentFragment,
  useAssetUpdateConfigMutation,
} from "../../../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../../../utils/serverErrors";
import {
  breakerStartingNumberSchema,
  mainBreakerOrLugSchema,
  numberOfEvenRowsSchema,
  yupObject,
} from "../../../../../utils/validation";

interface ElectricalPanelNormalSettingEditPresenterProps {
  asset: AssetFragmentFragment;
  isOpen: boolean;
  onClose: () => void;
}

const ElectricalPanelNormalSettingEditPresenter: React.FC<ElectricalPanelNormalSettingEditPresenterProps> =
  ({ asset, isOpen, onClose }) => {
    const toast = useToast();
    const [assetUpdateConfigMutation, { loading }] =
      useAssetUpdateConfigMutation({
        refetchQueries: [{ query: AssetDocument, variables: { id: asset.id } }],
        awaitRefetchQueries: true,
      });

    const handleSubmit = useCallback(
      async (
        data: AssetConfigData,
        { setFieldError }: FormikHelpers<AssetConfigData>
      ) => {
        try {
          const { data: serverData, errors } = await assetUpdateConfigMutation({
            variables: {
              id: asset.id,
              config: {
                ...asset.config,
                ...data,
                numberOfRows: Math.round(data.numberOfRows / 2),
              },
            },
          });
          if (errors) {
            setServerErrors(errors, setFieldError);
          } else if (serverData) {
            onClose();
            toast({
              description: GENERIC_SAVED_MESSAGE,
              status: "success",
              position: "top",
              isClosable: true,
            });
            return;
          }
        } catch (error) {
          toast({
            description: setGenericMessage(error),
            status: "error",
            position: "top",
            isClosable: true,
          });
        }
      },
      [asset, assetUpdateConfigMutation, onClose, toast]
    );

    return (
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <Helmet>
          <title>Edit Electrical Panel Settings</title>
        </Helmet>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit Electrical Panel Settings</DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{
                  numberOfRows:
                    (asset.config.numberOfRows || DEFAULT_BREAKER_ROWS) * 2,
                  breakerStartingNumber:
                    asset.config.breakerStartingNumber ||
                    DEFAULT_BREAKER_STARTING_NUMBER,
                  mainBreakerOrLug:
                    asset.config.mainBreakerOrLug ||
                    DEFAULT_MAIN_BREAKER_OR_LUG,
                  mainBreakerOrLugPoles:
                    asset.config.mainBreakerOrLugPoles ||
                    DEFAULT_MAIN_BREAKER_OR_LUG_POLES,
                  mainBreakerOrLugPosition:
                    asset.config.mainBreakerOrLugPosition ||
                    DEFAULT_MAIN_BREAKER_OR_LUG_POSITION,
                }}
                validationSchema={assetConfigValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                <Form id="asset_file_edit" noValidate>
                  <NumberInput
                    label="Number of Breakers"
                    name="numberOfRows"
                    defaultValue={
                      (asset.config.numberOfRows || DEFAULT_BREAKER_ROWS) * 2
                    }
                    min={2}
                    max={200}
                    step={2}
                    autoFocus
                  />
                  <NumberInput
                    label="Breaker Starting Number"
                    name="breakerStartingNumber"
                    defaultValue={
                      asset.config.breakerStartingNumber ||
                      DEFAULT_BREAKER_STARTING_NUMBER
                    }
                    min={1}
                    max={201}
                    step={2}
                  />
                  <MainBreakerInput />
                  <Box marginY="10">
                    <Button
                      width="full"
                      type="submit"
                      isLoading={loading}
                      form="asset_file_edit"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  };

export default ElectricalPanelNormalSettingEditPresenter;

const assetConfigValidationSchema = yupObject().shape({
  numberOfRows: numberOfEvenRowsSchema,
  breakerStartingNumber: breakerStartingNumberSchema,
  mainBreakerOrLug: mainBreakerOrLugSchema,
});

export type AssetConfigData = {
  numberOfRows: number;
  breakerStartingNumber: number;
  mainBreakerOrLug: boolean;
};

gql`
  mutation AssetUpdateConfig($id: UUID!, $config: JSONObject!) {
    assetUpdateConfig(id: $id, config: $config) {
      ...AssetFragment
    }
  }
`;
