import { Icon, IconProps } from "@chakra-ui/react";
import { useState } from "react";

const Visa = (props: IconProps & any) => {
  const [id] = useState(`clip0_visa${Math.random()}`);
  return (
    <Icon viewBox="0 0 57 32" width="14" height="8" {...props}>
      <rect x="0.963867" width="55.8742" height="32" fill="white" />
      <g clipPath={`url(#${id})`}>
        <path
          d="M22.7144 21.6347L24.7685 10.2487H28.0942L26.0401 21.6347H22.7144Z"
          fill="#293688"
        />
        <path
          d="M37.8794 10.5431C37.2203 10.3123 36.1878 10.0652 34.8983 10.0652C31.6115 10.0652 29.2963 11.6099 29.2764 13.8229C29.2581 15.4586 30.9291 16.371 32.1914 16.916C33.4859 17.473 33.9212 17.8305 33.9149 18.3282C33.9068 19.0914 32.8806 19.4403 31.9244 19.4403C30.5934 19.4399 29.886 19.2676 28.7936 18.8427L28.365 18.6613L27.8979 21.2102C28.675 21.5281 30.1118 21.8039 31.6034 21.8178C35.0991 21.8171 37.3694 20.2915 37.3949 17.9276C37.4076 16.6326 36.5213 15.6469 34.6024 14.8341C33.4396 14.308 32.7275 13.9567 32.7352 13.4227C32.7352 12.9496 33.3384 12.4436 34.6401 12.4436C35.7277 12.4276 36.5157 12.6489 37.1299 12.8797L37.4275 13.011L37.8794 10.5431Z"
          fill="#293688"
        />
        <path
          d="M43.5495 10.2487C42.7526 10.2487 42.1769 10.4771 41.8268 11.2184L36.8979 21.6351H40.4191L41.1037 19.8903H45.3107L45.7015 21.6351H48.8319L46.0932 10.249H43.5496L43.5495 10.2487ZM42.0379 17.612C42.3126 16.9563 43.3626 14.4297 43.3626 14.4297C43.3435 14.4595 43.6359 13.7708 43.8038 13.3434L44.0289 14.3246C44.0289 14.3246 44.6651 17.0426 44.7986 17.6123H42.0379V17.612Z"
          fill="#293688"
        />
        <path
          d="M16.6495 18.0537L16.3565 16.4927C15.7496 14.6732 13.8658 12.7044 11.7549 11.718L14.6932 21.6347H18.2152L23.4971 10.2487H19.9759L16.6495 18.0537Z"
          fill="#293688"
        />
        <path
          d="M8.13916 10.2488V10.4327C12.3123 11.3748 15.2095 13.7567 16.3565 16.493L15.1506 11.2205C14.9493 10.5 14.3392 10.2739 13.6174 10.2489L8.13916 10.2488Z"
          fill="#F7981D"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="40.9079"
            height="12"
            fill="white"
            transform="translate(7.94824 10)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default Visa;
