import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import AgreementsField from "../../components/elements/agreementsField";
import FormGroup from "../../components/elements/formGroup";
import PasswordInput from "../../components/elements/passwordInput";
import {
  useUpdateSessionMutation,
  useUserAcceptInviteMutation,
} from "../../graphql/graphql";
import { getRoutePath } from "../../router";
import { getRedirectUrl, removeRedirectUrl } from "../../utils/redirection";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import {
  agreementsSchema,
  firstNameSchema,
  lastNameSchema,
  newPasswordSchema,
  yupObject,
} from "../../utils/validation";

interface AcceptInvitationPagePresenterProps {}

const AcceptInvitationPagePresenter: React.FC<
  AcceptInvitationPagePresenterProps
> = () => {
  const [userAcceptInviteMutation, { loading }] = useUserAcceptInviteMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const { email: emailEncoded, token: uuid } = useParams();
  const [updateSessionMutation] = useUpdateSessionMutation();

  const onSubmit = async (
    data: AcceptInvitationFormData,
    { setFieldError }: FormikHelpers<AcceptInvitationFormData>
  ) => {
    if (!emailEncoded) return;
    try {
      const { data: serverData, errors } = await userAcceptInviteMutation({
        variables: { data: { ...data, emailEncoded, uuid } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        await updateSessionMutation({
          variables: { jwt: serverData.userAcceptInvite.jwtToken },
        });
        const redirectTo = getRedirectUrl();
        navigate(redirectTo ?? getRoutePath("dashboard"), { replace: true });
        removeRedirectUrl();
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Box maxWidth="lg" margin="auto">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          passwordRaw: "",
          agreements: false,
        }}
        validationSchema={AcceptInvitationSchema}
        onSubmit={onSubmit}
      >
        {({ getFieldProps }) => (
          <Form noValidate>
            <Box
              paddingY={{ base: "none", lg: "4" }}
              paddingX={{ base: "none", lg: "12" }}
            >
              <FormGroup label="First Name" name="firstName">
                <Input
                  autoFocus
                  autoComplete="given-name"
                  {...getFieldProps("firstName")}
                />
              </FormGroup>
              <FormGroup label="Last Name" name="lastName">
                <Input
                  autoComplete="family-name"
                  {...getFieldProps("lastName")}
                />
              </FormGroup>
              <FormGroup
                label="Create Password"
                name="passwordRaw"
                renderError={false}
              >
                <PasswordInput
                  showPasswordHint
                  autoComplete="new-password"
                  name="passwordRaw"
                />
              </FormGroup>
              <AgreementsField />
              <Box marginTop="8" width="100%">
                <Button type="submit" width="full" isLoading={loading}>
                  Accept invitation and sign up
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AcceptInvitationPagePresenter;

const AcceptInvitationSchema = yupObject().shape({
  passwordRaw: newPasswordSchema.label("Password"),
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  agreements: agreementsSchema,
});

type AcceptInvitationFormData = {
  passwordRaw: string;
  firstName: string;
  lastName: string;
  agreements: boolean;
};

gql`
  mutation UserAcceptInvite($data: UserAcceptInviteInput!) {
    userAcceptInvite(data: $data) {
      jwtToken
    }
  }
`;
