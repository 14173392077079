import React from "react";

import { WOFloorPlan } from "../../../../../../graphql/types/queries/workOrdersQuery";
import { FloorPlansItem } from "../plansTable/presenter";
import WorkOrderCreatePlanPreviewModalPresenter from "./presenter";

interface WorkOrderCreatePlanPreviewModalProps {
  isPlanPreviewOpen?: boolean;
  assetId?: string;
  companyId?: string;
  plan?: WOFloorPlan | null;
  planItem?: FloorPlansItem | null;
  sublocationId?: string;
  closeModal: () => void;
  isPlanItemSelected?: boolean;
  selectPlanItem?: (selectedPlansItem: FloorPlansItem) => void;
}

const WorkOrderCreatePlanPreviewModal: React.FC<
  WorkOrderCreatePlanPreviewModalProps
> = ({
  isPlanPreviewOpen,
  assetId,
  companyId,
  plan,
  planItem,
  sublocationId,
  closeModal,
  isPlanItemSelected,
  selectPlanItem,
}) => {
  return (
    <>
      <WorkOrderCreatePlanPreviewModalPresenter
        isPlanPreviewOpen={isPlanPreviewOpen}
        assetId={assetId}
        companyId={companyId}
        plan={plan}
        sublocationId={sublocationId}
        closeModal={closeModal}
        planItem={planItem}
        selectPlanItem={selectPlanItem}
        isPlanItemSelected={isPlanItemSelected}
      />
    </>
  );
};

export default WorkOrderCreatePlanPreviewModal;
