import { EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from "@elastic/eui";
import React, { useCallback, useState } from "react";

import FileInput from "../../components/fileInput";
import Label from "../../components/label";
import AssetIcon from "../../components/svgIcons/assetIcon";
import AttachmentsIcon from "../../components/svgIcons/attachmentsIcon";
import WorkOrderCreateAddAssetModal from "../../create/services/addAsset";
import WorkOrderCreateServiceAssetsTable from "../../create/services/general/assetsTable";
import WorkOrderCreateServicesAttachments, {
  AttachmentsTableModeEnum,
} from "../../create/services/general/attachments";
import WorkOrderEditor from "../../create/services/general/editor";
import WorkOrderServicesTitle from "../../create/services/general/serviceTitle";
import {
  workOrdersAcceptedFileTypes,
  workOrdersFileMaxSize,
} from "../../helpers/filesValidation";
import { WorkOrder, WorkOrderAsset } from "../../models/workOrder";
import Comments from "./components/comments";

export type WorkOrderEditGeneralPresenterProps = {
  workOrder: WorkOrder;
  onFilesAdded: (files: File[]) => void;
  onFileDelete: (files: File) => void;
  onAssetAdd: (asset: WorkOrderAsset) => void;
  onAssetDelete: (asset: WorkOrderAsset) => void;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
};

const WorkOrderEditGeneralPresenter: React.FC<
  WorkOrderEditGeneralPresenterProps
> = ({
  workOrder,
  onWorkOrderChange,
  onFilesAdded,
  onFileDelete,
  onAssetAdd,
  onAssetDelete,
  changeIsEdit,
}: WorkOrderEditGeneralPresenterProps) => {
  const [isAddAssetModalVisible, setIsAddAssetModalVisible] = useState(false);
  const { euiTheme } = useEuiTheme();

  const onAddAssetClick = useCallback(() => {
    setIsAddAssetModalVisible(true);
  }, [setIsAddAssetModalVisible]);

  const handleAddNewAsset = useCallback(
    (asset: WorkOrderAsset) => {
      setIsAddAssetModalVisible(false);
      onAssetAdd(asset);
    },
    [setIsAddAssetModalVisible]
  );

  return (
    <FileInput
      multiple={true}
      accept={workOrdersAcceptedFileTypes}
      maxSize={workOrdersFileMaxSize}
      acceptErrorMassage="Only JPEG, PNG, PDF file are allowed"
      maxSizeErrorMassage="Max size of the file must be no more than 10 MB"
      onChange={onFilesAdded}
      disableClick={true}
      showDropArea={true}
      fullWidth={true}
      dropAreaMargin={12}
      style={{ minWidth: "100%", height: "100%", paddingBottom: "80px" }}
    >
      <EuiFlexGroup
        direction="column"
        justifyContent="flexStart"
        alignItems="stretch"
        gutterSize={"none"}
        style={{ width: "100%" }}
      >
        <EuiText className={"columnTitle"}>Service Details</EuiText>
        <EuiFlexItem
          style={{ marginTop: "8px", minWidth: "360px", width: "100%" }}
          grow={0}
        >
          <WorkOrderServicesTitle
            workOrder={workOrder}
            hideRequiredMark={true}
            changeIsEdit={changeIsEdit}
            onTitleChange={(title: string) =>
              onWorkOrderChange({ name: title })
            }
          />
        </EuiFlexItem>
        <EuiFlexItem
          style={{
            marginTop: "12px",
            minWidth: "360px",
            width: "100%",
            flexDirection: "row",
            gap: "16px",
          }}
          grow={0}
        >
          <button onClick={onAddAssetClick} className={"button-asset"}>
            <EuiText
              style={{ fontSize: euiTheme.size.m, fontWeight: 500 }}
              color={euiTheme.colors.darkShade}
            >
              <AssetIcon />
            </EuiText>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: 500,
                lineHeight: "21px",
              }}
              color={euiTheme.colors.darkShade}
            >
              Add Asset
            </EuiText>
          </button>
          <FileInput
            multiple={true}
            accept={workOrdersAcceptedFileTypes}
            maxSize={workOrdersFileMaxSize}
            acceptErrorMassage="Only JPEG, PNG, PDF file are allowed"
            maxSizeErrorMassage="Max size of the file must be no more than 10 MB"
            disableDragAndDrop={true}
            onChange={onFilesAdded}
          >
            <button onClick={() => {}} className={"button-attachments"}>
              <EuiText
                style={{ fontSize: euiTheme.size.m, fontWeight: 500 }}
                color={euiTheme.colors.darkShade}
              >
                <AttachmentsIcon />
              </EuiText>
              <EuiText
                style={{
                  fontSize: euiTheme.size.m,
                  fontWeight: 500,
                  lineHeight: "21px",
                }}
                color={euiTheme.colors.darkShade}
              >
                Attach Files
              </EuiText>
            </button>
          </FileInput>
        </EuiFlexItem>
        <EuiFlexItem
          style={{ marginTop: "24px", minWidth: "360px", width: "100%" }}
          grow={0}
        >
          <Label label="Description" style={{ paddingBottom: "5px" }} />
          <WorkOrderEditor
            workOrder={workOrder}
            changeIsEdit={changeIsEdit}
            onWorkOrderChange={({ description }) =>
              onWorkOrderChange({ description })
            }
          />
        </EuiFlexItem>
        <EuiFlexItem
          grow={0}
          style={{
            marginTop: "24px",
            minWidth: "360px",
          }}
        >
          {!!workOrder?.files?.length && (
            <WorkOrderCreateServicesAttachments
              workOrder={workOrder}
              onFilesAdded={onFilesAdded}
              onFileDelete={onFileDelete}
              mode={AttachmentsTableModeEnum.edit}
            />
          )}
        </EuiFlexItem>
        <EuiFlexItem
          grow={0}
          style={{
            marginTop: "24px",
            minWidth: "360px",
          }}
        >
          {!!workOrder?.assetWorkOrders?.length && (
            <WorkOrderCreateServiceAssetsTable
              companyId={workOrder.companyId}
              assets={workOrder.assetWorkOrders}
              editable={true}
              onAssetDelete={onAssetDelete}
              onAddNewAssetClick={onAddAssetClick}
            />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <Comments workOrderId={workOrder.id} />
      <WorkOrderCreateAddAssetModal
        isAddAssetModalVisible={isAddAssetModalVisible}
        addAssetModalVisibleChange={setIsAddAssetModalVisible}
        workOrder={workOrder}
        onAddNewAsset={handleAddNewAsset}
      />
    </FileInput>
  );
};
export default WorkOrderEditGeneralPresenter;
