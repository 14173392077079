import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faExternalLinkAlt,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";

import FileIcon from "../../../components/elements/fileIcon";
import { AssetFileFragmentFragment } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import AssetFileDelete from "../assetFileDelete";

interface AssetFilePresenterProps {
  assetId: string;
  assetFile: AssetFileFragmentFragment;
  fileSignedUrl?: string;
  onDrawerClose: () => void;
  isOpen: boolean;
}

const AssetFilePresenter: React.FC<AssetFilePresenterProps> = ({
  assetId,
  assetFile,
  fileSignedUrl,
  isOpen,
  onDrawerClose,
}) => {
  const navigate = useNavigate();
  const {
    isOpen: isOpenFileDelete,
    onClose: onCloseFileDelete,
    onOpen: onOpenFileDelete,
  } = useDisclosure();
  const [, setSearchParams] = useSearchParams();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>{assetFile.name}</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{assetFile.name}</DrawerHeader>
          <DrawerBody>
            <>
              <Flex marginBottom="6" align="center" justify="center">
                <IconButton
                  onClick={() =>
                    navigate(
                      getRoutePath("assetsShowFileEdit", {
                        assetId,
                        fileId: assetFile.id,
                      })
                    )
                  }
                  aria-label="Edit File"
                  variant="icon"
                  colorScheme="gray"
                  marginX="4"
                >
                  <FontAwesomeIcon icon={faPen} size="sm" />
                </IconButton>
                <IconButton
                  onClick={onOpenFileDelete}
                  aria-label="Delete File"
                  variant="icon"
                  colorScheme="grayRed"
                  marginX="4"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </IconButton>
                <IconButton
                  onClick={() =>
                    setSearchParams({
                      CaAprType: "fileDownload",
                      assetId,
                      assetFileId: assetFile.id,
                    })
                  }
                  aria-label="View File"
                  variant="icon"
                  colorScheme="gray"
                  marginX="4"
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </IconButton>
              </Flex>
              <Box marginBottom="6" textAlign="center">
                <FileIcon
                  signedUrl={fileSignedUrl}
                  filePath={assetFile.path}
                  fileName={assetFile.name}
                  width={400}
                />
              </Box>
              {!!assetFile.description && (
                <Text marginBottom="4" fontSize="sm" color="gray.800">
                  {assetFile.description}
                </Text>
              )}
            </>

            {isOpenFileDelete && (
              <AssetFileDelete
                assetFile={assetFile}
                assetId={assetId}
                handleDelete={() => {
                  onCloseFileDelete();
                  navigate(
                    getRoutePath("assetsShowFile", {
                      assetId,
                      fileId: assetFile.id,
                    })
                  );
                }}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetFilePresenter;
