import "moment-timezone";

import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  getUtcDateStringSwitchedBetweenTimezones,
  isDateValid,
} from "../../../helpers/dateTime";
import { ScheduleTypeEnum } from "../../../models/enums";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderEditSectionFooter from "../../components/sectionFooter";
import WorkOrderEditSchedulePresenter from "./presenter";

interface WorkOrderEditScheduleInfoProps {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

export type SectionDataSchedule = Pick<
  WorkOrder,
  "startDateTime" | "endDateTime" | "serviceScheduleTimezone" | "scheduleType"
>;
const WorkOrderEditScheduleInfo: React.FC<WorkOrderEditScheduleInfoProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const [initialSectionData, setInitialSectionData] =
    useState<SectionDataSchedule>({});
  const [sectionData, setSectionData] = useState<SectionDataSchedule>({});
  const [isSectionDataChanged, setSectionDataChanged] =
    useState<boolean>(false);
  const [isSectionDataValid, setSectionDataValid] = useState<boolean>(true);

  const handleScheduleTypeSelect = (
    scheduleType: ScheduleTypeEnum | string
  ) => {
    onDataChanged({
      scheduleType: scheduleType as ScheduleTypeEnum,
      startDateTime: null,
    });
  };

  const handleEndDateSelect = (endDate: string) => {
    onDataChanged({
      endDateTime: moment.utc(endDate).valueOf(),
    });
  };

  const handleStartDateSelect = (startDate: string) => {
    onDataChanged({
      startDateTime: moment.utc(startDate).valueOf(),
    });
  };

  const handleTimezoneSelection = (timezone: string) => {
    const data = {
      serviceScheduleTimezone: timezone,
      endDateTime: sectionData.endDateTime
        ? getUtcDateStringSwitchedBetweenTimezones(
            sectionData.endDateTime,
            sectionData.serviceScheduleTimezone,
            timezone
          )
        : null,
      startDateTime: sectionData.startDateTime
        ? getUtcDateStringSwitchedBetweenTimezones(
            sectionData.startDateTime,
            sectionData.serviceScheduleTimezone,
            timezone
          )
        : null,
    };
    onDataChanged(data);
  };

  const onDataChanged = (data: Partial<SectionDataSchedule>) => {
    const updatedSectionData = { ...sectionData, ...data };
    setSectionData(updatedSectionData);
  };

  useEffect(() => {
    const {
      scheduleType,
      serviceScheduleTimezone,
      endDateTime,
      startDateTime,
    } = workOrder || {};
    setSectionData(
      cloneDeep({
        scheduleType,
        serviceScheduleTimezone,
        endDateTime,
        startDateTime,
      })
    );
    setInitialSectionData({
      scheduleType,
      serviceScheduleTimezone,
      endDateTime,
      startDateTime,
    });
  }, [workOrder]);

  useEffect(() => {
    const isValid = Boolean(
      (sectionData.scheduleType === ScheduleTypeEnum.STRICT_TIME &&
        sectionData?.startDateTime &&
        sectionData.endDateTime &&
        isDateValid(sectionData.endDateTime, sectionData.startDateTime) &&
        isDateValid(moment.utc(sectionData.endDateTime), moment.utc()) &&
        isDateValid(moment.utc(sectionData.startDateTime), moment.utc())) ||
        (sectionData.scheduleType !== ScheduleTypeEnum.STRICT_TIME &&
          sectionData?.endDateTime &&
          isDateValid(moment.utc(sectionData.endDateTime), moment.utc()))
    );
    setSectionDataValid(isValid);
    const changed = !isEqual(initialSectionData, sectionData);
    setSectionDataChanged(changed);
  }, [sectionData]);

  useEffect(() => {
    changeIsEdit && changeIsEdit(isSectionDataChanged);
  }, [isSectionDataChanged, changeIsEdit]);

  const cancel = () => {
    setSectionData(cloneDeep(initialSectionData));
  };
  const save = () => {
    onWorkOrderChange(sectionData);
  };
  return (
    <>
      <WorkOrderEditSchedulePresenter
        sectionData={sectionData}
        onEndDateSelected={handleEndDateSelect}
        onStartDateSelected={handleStartDateSelect}
        onScheduleTypeSelected={handleScheduleTypeSelect}
        onTimezoneSelected={handleTimezoneSelection}
      >
        <WorkOrderEditSectionFooter
          disabled={!isSectionDataValid}
          cancel={cancel}
          save={save}
          visible={isSectionDataChanged}
        />
      </WorkOrderEditSchedulePresenter>
    </>
  );
};

export default WorkOrderEditScheduleInfo;
