import { Box, MenuItem, Tooltip, useDisclosure } from "@chakra-ui/react";
import React from "react";

interface CompanyOptionProps {
  option: { value: string; label: string };
  handleChange: (value: string) => void;
}

const CompanyOption: React.FC<CompanyOptionProps> = ({
  option,
  handleChange,
}) => {
  const ref = React.useRef<any>();

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  React.useEffect(() => {
    if (!ref.current) return;
    if (ref.current.offsetWidth < ref.current.scrollWidth) {
      onOpen();
    } else {
      onClose();
    }
  }, [ref, onOpen, onClose]);

  return (
    <MenuItem
      paddingY="1"
      paddingRight="0.5"
      backgroundColor="gray.800"
      color="white"
      _focus={{ backgroundColor: "gray.600" }}
      _hover={{ backgroundColor: "gray.600" }}
      _active={{ backgroundColor: "gray.600" }}
      minWidth="200px"
      onClick={() => handleChange(option.value)}
    >
      <Tooltip
        label={isOpen && option.label ? option.label : undefined}
        placement="right"
        offset={[0, 10]}
        hasArrow
      >
        <Box ref={ref} isTruncated>
          {option.label}
        </Box>
      </Tooltip>
    </MenuItem>
  );
};

export default CompanyOption;
