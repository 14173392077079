import React from "react";

import { WorkOrder } from "../../../models/workOrder";
import WorkOrderVerifyAndSaveGeneralPresenter from "./presenter";

export type WorkOrderVerifyAndSaveGeneralProps = {
  workOrder: WorkOrder;
};

const WorkOrderVerifyAndSaveGeneral: React.FC<
  WorkOrderVerifyAndSaveGeneralProps
> = ({ workOrder }: WorkOrderVerifyAndSaveGeneralProps) => {
  return (
    <>
      <WorkOrderVerifyAndSaveGeneralPresenter workOrder={workOrder} />
    </>
  );
};
export default WorkOrderVerifyAndSaveGeneral;
