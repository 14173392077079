import { Icon, IconProps } from "@chakra-ui/react";

const SearchArticles = (props: IconProps & any) => (
  <Icon viewBox="0 0 48 48" boxSize={{ base: "6", md: "8" }} {...props}>
    <g clipPath="url(#search_articles)">
      <path
        d="m-.004 43.382 9.934-9.934 1.988 1.988-9.934 9.934-1.988-1.988Z"
        fill="#646464"
      />
      <path
        d="M48 9.253V48H11.437V32.503l2.25 1.716c2.194 1.66 4.81 2.531 7.594 2.531a12.55 12.55 0 0 0 8.438-3.234c4.365-3.88 4.218-9.189 4.218-9.422 0-2.695-1.015-6.562-4.218-9.422a12.58 12.58 0 0 0-8.438-3.235c-5.625 0-8.437 3.404-9.843 4.247V0h27.309l2.812 6.44L48 9.254Z"
        fill="#444"
      />
      <path
        d="M48 9.253V48H29.719V33.516c4.365-3.88 4.218-9.189 4.218-9.422 0-2.695-1.015-6.562-4.218-9.422V0h9.028l2.812 6.44L48 9.254Z"
        fill="#444"
      />
      <path
        d="M35.75 24.094c0 7.99-6.478 14.468-14.469 14.468-7.99 0-14.468-6.477-14.468-14.468S13.29 9.625 21.28 9.625 35.75 16.103 35.75 24.094Z"
        fill="#C8C8C8"
        stroke="#646464"
        strokeWidth={2}
      />
      <path d="M48 9.253h-9.253V0L48 9.253Z" fill="#C8C8C8" />
      <path
        d="M25.5 19.875c0 1.49-.816 2.897-2.11 3.656a1.418 1.418 0 0 0-.703 1.21v2.165h-2.812v-2.165c0-1.181.534-2.335 1.406-3.122.569-.569 1.407-.735 1.407-1.744 0-.788-.62-1.406-1.407-1.406s-1.406.618-1.406 1.406h-2.813a4.213 4.213 0 0 1 4.22-4.219 4.213 4.213 0 0 1 4.218 4.22ZM19.875 29.719h2.813v2.812h-2.813V29.72Z"
        fill="#646464"
      />
      <path
        d="M21.281 29.719h1.407v2.812H21.28V29.72ZM25.5 19.875c0 1.49-.816 2.897-2.11 3.656a1.419 1.419 0 0 0-.703 1.21v2.165h-1.406V21.62c.569-.569 1.407-.735 1.407-1.744 0-.788-.62-1.406-1.407-1.406v-2.813a4.213 4.213 0 0 1 4.219 4.219Z"
        fill="#646464"
      />
    </g>
    <defs>
      <clipPath id="search_articles">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default SearchArticles;
