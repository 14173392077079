import { Icon, IconProps } from "@chakra-ui/react";

const Feedback = (props: IconProps & any) => (
  <Icon viewBox="0 0 48 48" boxSize={{ base: "6", md: "8" }} {...props}>
    <path
      d="M24.32 0H.64v10.572l23.68 3.576 1.528-11.003L24.32 0Z"
      fill="#404040"
    />
    <path
      d="M.64 10.572v36.7h23.68l4.439-23.835-4.44-12.865H.64Z"
      fill="#646464"
    />
    <path
      d="M10.24 25.88h-2.5a.625.625 0 0 0-.625.626v2.5c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625v-2.5a.625.625 0 0 0-.625-.625Zm-.625 2.5h-1.25v-1.25h1.25v1.25ZM10.24 33.38h-2.5a.625.625 0 0 0-.625.626v2.5c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625v-2.5a.625.625 0 0 0-.625-.625Zm-.625 2.5h-1.25v-1.25h1.25v1.25ZM10.62 18.483a.632.632 0 0 0-.874.186l-1.257 1.939-.382-.264a.632.632 0 1 0-.72 1.04l.918.634a.632.632 0 0 0 .89-.176l1.612-2.485a.632.632 0 0 0-.187-.874Z"
      fill="#C8C8C8"
    />
    <path d="M48 0H24.32v14.148L48 10.572V0Z" fill="#404040" />
    <path d="M24.32 10.572v36.7H48v-36.7H24.32Z" fill="#646464" />
    <path
      d="M13.047 18.38v3.75H24.32l2.93-1.874-2.93-1.875H13.047ZM13.047 25.88v3.75H24.32l2.93-1.487-2.93-2.262H13.047Z"
      fill="#C8C8C8"
    />
    <path
      d="M24.32 18.38h11.742v3.75H24.32v-3.75ZM24.32 25.88h11.742v3.75H24.32v-3.75ZM13.047 33.38h7.125v3.75h-7.125v-3.75Z"
      fill="#C8C8C8"
    />
  </Icon>
);

export default Feedback;
