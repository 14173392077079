import { Icon, IconProps } from "@chakra-ui/react";

const JBox = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      clipRule="evenodd"
      d="M104 109.5a.5.5 0 0 1-.5.5h-79a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h15a.5.5 0 0 0 .5-.5v-31a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h15a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 1 .5-.5h11a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v11a.5.5 0 0 0 .5.5h55a.5.5 0 0 1 .5.5v31a.5.5 0 0 0 .5.5h15a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-15a.5.5 0 0 0-.5.5v35Zm-12-67a.5.5 0 0 0-.5-.5h-55a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h55a.5.5 0 0 0 .5-.5v-15Z"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity="0"
    />
  </Icon>
);

export default JBox;
