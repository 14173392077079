import { useReactiveVar } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";

import { useAssetMaintenancesQuery } from "../../../../graphql/graphql";
import { calendarOptionsVar } from "../../../../graphql/reactiveVariables";
import MaintenancePresenter from "../../../maintenances/presenter";

interface AssetMaintenanceProps {
  assetId: string;
}

const AssetMaintenance: React.FC<AssetMaintenanceProps> = ({ assetId }) => {
  const { startDate, endDate } = useReactiveVar(calendarOptionsVar);
  const { data } = useAssetMaintenancesQuery({
    variables: {
      id: assetId,
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  return (
    <Box marginTop={{ base: "0", md: "-50px" }}>
      <MaintenancePresenter
        maintenances={data?.asset.maintenances}
        showSortFilter={false}
        virtualizeList={false}
      />
    </Box>
  );
};

export default AssetMaintenance;

gql`
  query AssetMaintenances(
    $id: UUID!
    $startDate: Timestamp
    $endDate: Timestamp
  ) {
    asset(id: $id) {
      id
      name
      maintenances {
        ...MaintenanceIndexFragment
      }
    }
  }
`;
