import { EuiDataGrid } from "@elastic/eui";
import { css } from "@emotion/react";
import { get } from "lodash";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { WOGetWorkOrdersQuery } from "../../../graphql/types/queries/workOrdersQuery";
import { getRoutePath } from "../../../router";
import { SeverityHelper } from "../helpers/severityHelper";
import { ScheduleTypeEnum, scheduleTypeLabels } from "../models/enums";
import { WorkOrder } from "../models/workOrder";
import { tableColumns } from "./columns";
import WorkOrderCreateButton from "./components/createWorkOrderButton";

export interface Sorting {
  id: string;
  direction: "asc" | "desc";
}

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

interface WorkOrdersPresenterProps {
  workOrdersList?: WOGetWorkOrdersQuery;
  sortingColumns: Sorting[];
  pagination: Pagination;
  changeSorting: (sorting: Sorting) => void;
  changePagination: (pagination: Pagination) => void;
}

const WorkOrdersPresenter: React.FC<WorkOrdersPresenterProps> = ({
  workOrdersList,
  sortingColumns,
  pagination,
  changeSorting,
  changePagination,
}) => {
  const navigate = useNavigate();
  const workOrders = useMemo(
    () => workOrdersList?.workOrders?.workOrders,
    [workOrdersList]
  );
  const totalCount = useMemo(
    () => workOrdersList?.workOrders?.totalCount,
    [workOrdersList]
  );
  const format = "MM/DD/YYYY hh:mm A";
  const [visibleColumns, setVisibleColumns] = useState(
    tableColumns.map(({ id }) => id)
  );

  const RenderCellValue = useCallback(
    ({ rowIndex, columnId }: { rowIndex: number; columnId: string }) => {
      const index = rowIndex - pagination.pageIndex * pagination.pageSize;
      const result = workOrders?.hasOwnProperty(index)
        ? get(workOrders, `[${index}].${columnId}`)
        : null;
      if (!result) {
        return null;
      }

      switch (columnId as keyof WorkOrder) {
        case "businessSeverity":
          return (
            <div
              className={`work-orders-table__severity work-orders-table__severity_${result.toLowerCase()}`}
            >
              {SeverityHelper.getWorkOrderTableLabel(result)}
            </div>
          );
        case "scheduleType":
          return scheduleTypeLabels[result as ScheduleTypeEnum];
        case "endDateTime":
        case "startDateTime":
          return moment(result).format(format);
        case "key":
          return (
            <a
              className={"key-link"}
              href={"/"}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                navigate(
                  getRoutePath("workOrdersEdit", {
                    workOrderId: workOrders ? workOrders[index].id : "",
                  })
                );
              }}
            >
              {result}
            </a>
          );
      }
      return result;
    },
    [workOrders]
  );

  const onChangeItemsPerPage = useCallback(
    (pageSize: number) =>
      changePagination({
        pageIndex: 0,
        pageSize,
      }),
    [changeSorting, pagination]
  );

  const onChangePage = useCallback(
    (pageIndex: number) =>
      changePagination({
        pageIndex,
        pageSize: pagination.pageSize,
      }),
    [changeSorting, pagination]
  );

  const onSort = useCallback(
    (sortingColumns: Sorting[]) => {
      if (sortingColumns?.length) {
        changeSorting(sortingColumns[sortingColumns.length - 1]);
      }
    },
    [changeSorting]
  );

  useEffect(() => {
    changePagination(pagination);
  }, [pagination]);

  return (
    <>
      <section style={{ height: "calc(100vh - 280px)", position: "relative" }}>
        <header
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "24px",
          }}
        >
          <WorkOrderCreateButton />
        </header>
        {workOrders?.length ? (
          <EuiDataGrid
            aria-label="Work Orders Table"
            columns={tableColumns}
            columnVisibility={{ visibleColumns, setVisibleColumns }}
            rowCount={totalCount || 0}
            renderCellValue={RenderCellValue}
            css={css`
              min-height: 300px;
            `}
            sorting={{ columns: sortingColumns, onSort }}
            pagination={{
              ...pagination,
              pageSizeOptions: [10, 20, 30],
              onChangeItemsPerPage: onChangeItemsPerPage,
              onChangePage: onChangePage,
            }}
            toolbarVisibility={false}
          />
        ) : (
          <div className={"work-orders-table__no-data"}>
            No any Work Order has been created yet.
            <br />
            Please proceed to create the first one using blue Create Work Order
            button on the top right corner.
          </div>
        )}
      </section>
    </>
  );
};

export default WorkOrdersPresenter;
