import { FC, HTMLAttributes } from "react";

import TextareaMarkdownPresenter from "./presenter";

interface TextAreaMarkdownProps extends HTMLAttributes<HTMLElement> {
  value: string;
  onChange: (value: any) => void;
  save: () => void;
  cancel?: () => void;
  description?: string;
  label?: string;
  saveButtonName?: string;
}

const TextareaMarkdown: FC<TextAreaMarkdownProps> = ({
  value = "",
  onChange,
  placeholder = "",
  description = "",
  label = "",
  cancel,
  save,
  style,
  onBlur = () => null,
  saveButtonName = "Save",
}) => {
  return (
    <TextareaMarkdownPresenter
      value={value}
      onChange={onChange}
      save={save}
      placeholder={placeholder}
      description={description}
      label={label}
      cancel={cancel}
      style={style}
      onBlur={onBlur}
      saveButtonName={saveButtonName}
    />
  );
};

export default TextareaMarkdown;
