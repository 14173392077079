import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  useAssetFileUpdateMutation,
  useAssetQuery,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import Presenter, { AssetFileFormData } from "./presenter";

interface AssetFileEditPageProps {}

const AssetFileEditPage: React.FC<AssetFileEditPageProps> = () => {
  const { assetId, fileId } = useParams();
  const { data } = useAssetQuery({
    variables: { id: assetId },
  });

  const toast = useToast();
  const navigate = useNavigate();
  const [assetFileUpdateMutation, { loading }] = useAssetFileUpdateMutation();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (
    data: AssetFileFormData,
    { setFieldError }: FormikHelpers<AssetFileFormData>
  ) => {
    try {
      const { data: serverData, errors } = await assetFileUpdateMutation({
        variables: {
          id: fileId,
          data,
        },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetsShow", { assetId: data?.asset.id }));
    }, 500);
  };

  if (!data) return null;
  const assetFile = data.asset.assetFiles.find((af) => af.id === fileId);

  return assetFile ? (
    <Presenter
      assetFile={assetFile}
      handleSubmit={onSubmit}
      loading={loading}
      onDrawerClose={onDrawerClose}
      isOpen={isOpen}
    />
  ) : null;
};

export default AssetFileEditPage;

gql`
  mutation AssetFileUpdate($id: UUID!, $data: AssetFileUpdateInput!) {
    assetFileUpdate(id: $id, data: $data) {
      ...AssetFileFragment
    }
  }
`;
