import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { MaintenanceStatusType } from "../../../graphql/graphql";

interface AssetStatusCountProps {
  assets: { maintenanceStatus: MaintenanceStatusType }[];
}

const AssetStatusCount: React.FC<AssetStatusCountProps> = ({ assets }) => {
  const totalCount = assets.length;

  let assetsCurrentCount = 0,
    assetsDueIn30Count = 0,
    assetsPastDueCount = 0;
  for (let i = 0; i < totalCount; i++) {
    switch (assets[i].maintenanceStatus) {
      case MaintenanceStatusType.DueIn_30:
        assetsDueIn30Count++;
        break;
      case MaintenanceStatusType.PastDue:
        assetsPastDueCount++;
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        assetsCurrentCount++;
    }
  }

  return (
    <Flex align="center" justify="center" mt="2">
      {assetsPastDueCount > 0 && (
        <Flex alignItems="center" color="gray.700" fontSize="sm" mx="2">
          <Box width="2" height="4" marginRight="1" backgroundColor="red.500" />
          {assetsPastDueCount} past due!
        </Flex>
      )}
      {assetsDueIn30Count > 0 && (
        <Flex alignItems="center" color="gray.700" fontSize="sm" mx="2">
          <Box
            width="2"
            height="4"
            marginRight="1"
            backgroundColor="orange.500"
          />
          {assetsDueIn30Count} due soon
        </Flex>
      )}
    </Flex>
  );
};

export default AssetStatusCount;
