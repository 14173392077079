import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Formik, FormikHelpers } from "formik";
import React, { FormEvent } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import PasswordInput from "../../../components/elements/passwordInput";
import SelectCombobox from "../../../components/elements/selectCombobox";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyUserRole,
  CompanyUserWithUserFragmentFragment,
  useCompanyUserUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  currentPasswordSchema,
  idSchema,
  yupObject,
} from "../../../utils/validation";

interface CompanyChangeOwnerPagePresenterProps {
  companyUsers: CompanyUserWithUserFragmentFragment[];
}

const CompanyChangeOwnerPagePresenter: React.FC<
  CompanyChangeOwnerPagePresenterProps
> = ({ companyUsers }) => {
  const toast = useToast();
  const client = useApolloClient();
  const navigate = useNavigate();

  const { isOpen: drawerIsOpen, onClose: drawerOnClose } = useDisclosure({
    defaultIsOpen: true,
  });
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [CompanyUserUpdateMutation, { loading }] =
    useCompanyUserUpdateMutation();

  const companyUsersOptions: Array<{
    label: string;
    query: string;
    value: string;
  }> = React.useMemo(
    () =>
      companyUsers
        .filter((u) => u.role !== CompanyUserRole.Owner && u.user.registered)
        .map((u) => ({
          label: u.user.fullName || u.user.email,
          query: u.user.fullName || u.user.email,
          value: u.id,
        })),
    [companyUsers]
  );

  const onSubmit = async (
    data: CompanyChangeOwnerFormData,
    { setFieldError }: FormikHelpers<any>
  ) => {
    alertOnClose();
    try {
      const { errors } = await CompanyUserUpdateMutation({
        variables: { ...data, role: CompanyUserRole.Owner },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else {
        await client.resetStore();
        navigate(getRoutePath("companyTeams"));
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  const onDrawerClose = () => {
    drawerOnClose();
    setTimeout(() => {
      navigate(getRoutePath("companyTeams"));
    }, 500);
  };

  return (
    <>
      <Helmet>
        <title>Change Owner</title>
      </Helmet>
      <Drawer isOpen={drawerIsOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Choose a new owner for this company</DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{
                  id: "",
                  passwordRaw: "",
                }}
                validationSchema={CompanyChangeOwnerValidationSchema}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, values }) => {
                  const selectedUser = values.id
                    ? companyUsersOptions.find((cu) => cu.value === values.id)
                    : null;
                  const openAlert = (e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    if (values.id && values.passwordRaw) {
                      alertOnOpen();
                    }
                  };

                  return (
                    <form onSubmit={openAlert} noValidate>
                      <SelectCombobox
                        sortOptions
                        options={companyUsersOptions}
                        name="id"
                        label="Select new owner"
                        autoFocus
                      />
                      <FormGroup label="Enter your password" name="passwordRaw">
                        <PasswordInput
                          autoComplete="current-password"
                          name="passwordRaw"
                        />
                      </FormGroup>
                      <Text
                        marginTop="4"
                        color="gray.800"
                        textAlign="left"
                        fontSize="sm"
                        fontWeight="light"
                      >
                        * If you change ownership, you will lose all your Owner
                        privileges
                      </Text>
                      <AlertDialog
                        leastDestructiveRef={cancelRef}
                        onClose={alertOnClose}
                        isOpen={alertIsOpen}
                        isCentered
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogBody textAlign="center">
                              Are you sure you want to{" "}
                              {selectedUser
                                ? `make ${selectedUser.label} the new`
                                : "change the"}{" "}
                              owner of this account?
                            </AlertDialogBody>
                            <AlertDialogFooter>
                              <Button
                                ref={cancelRef}
                                onClick={alertOnClose}
                                width="48%"
                              >
                                No, Don't Cancel!
                              </Button>
                              <Button
                                onClick={() => handleSubmit()}
                                colorScheme="red"
                                width="48%"
                                ml="4%"
                              >
                                Yes, Cancel
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                      <Button
                        width="full"
                        type="submit"
                        isLoading={loading}
                        marginTop="10"
                      >
                        Change Owner
                      </Button>
                    </form>
                  );
                }}
              </Formik>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default CompanyChangeOwnerPagePresenter;

const CompanyChangeOwnerValidationSchema = yupObject().shape({
  passwordRaw: currentPasswordSchema.label("Your password"),
  id: idSchema.label("New owner"),
});

type CompanyChangeOwnerFormData = {
  passwordRaw: string;
  id: string;
};
