import { Icon, IconProps } from "@chakra-ui/react";

const ShowerTub = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.87 49.994V33.406c0-2.998 1.057-5.817 2.992-7.928 1.868-2.04 4.348-3.176 6.982-3.176 4.76 0 8.397 2.729 9.571 6.942l.177.635h-2.014a2.4 2.4 0 0 0 0 4.802h9.281a2.4 2.4 0 0 0 0-4.802h-2.412l-.073-.414c-.536-3.073-1.95-5.81-4.113-7.899-2.71-2.617-6.406-4.066-10.417-4.066-3.984 0-7.719 1.675-10.521 4.733-2.74 2.991-4.255 6.957-4.255 11.173v16.588h-3.787c-2.285 0-4.463.88-6.133 2.48-1.712 1.642-2.648 3.81-2.648 6.11 0 2.3.937 4.47 2.648 6.11a8.906 8.906 0 0 0 3.389 2.04l.344.114V84.32c0 8.178 4.981 15.217 12.07 18.236l.305.129v5.414a2.4 2.4 0 1 0 4.8 0v-4.137l.553.058a20.04 20.04 0 0 0 2.075.109h45.632c.701 0 1.393-.038 2.075-.109l.552-.058v4.137a2.4 2.4 0 1 0 4.801 0v-5.414l.304-.129c7.09-3.02 12.071-10.058 12.071-18.236V66.848l.344-.113a8.898 8.898 0 0 0 3.389-2.04c1.711-1.642 2.648-3.81 2.648-6.11 0-2.3-.937-4.47-2.648-6.11a8.828 8.828 0 0 0-6.133-2.481h-71.85Zm-6.188 17.18h75.636V84.32c0 8.275-6.729 15.006-15.002 15.006H41.684c-8.273 0-15.002-6.731-15.002-15.006V67.175Zm78.037-4.802H24.281c-2.103 0-3.98-1.733-3.98-3.788 0-2.054 1.877-3.788 3.98-3.788h80.438c2.103 0 3.98 1.734 3.98 3.788s-1.877 3.788-3.98 3.788Z"
      fill="currentColor"
    />
  </Icon>
);

export default ShowerTub;
