import React from "react";

import { useCompanyUsersQuery, useUserQuery } from "../graphql/graphql";

const useCompanyUsers = (excludedCompanyUserIds?: string[]) => {
  const { data } = useUserQuery();
  const { data: companyUsersData } = useCompanyUsersQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const companyUsers = React.useMemo(() => {
    if (!companyUsersData) return [];
    const companyUsers: any = [];
    const companyUsersCount = companyUsersData.companyUsers.length;
    for (let i = 0; i < companyUsersCount; i++) {
      const companyUser = companyUsersData.companyUsers[i];
      if (!excludedCompanyUserIds?.includes(companyUser.id)) {
        const label = companyUser.user.fullName || companyUser.user.email;
        companyUsers.push({
          value: companyUser.id,
          label: `${label}${
            companyUser.user.id === data?.user.id ? " (myself)" : ""
          }`,
          query: label,
        });
      }
    }
    return companyUsers;
  }, [companyUsersData, data?.user.id, excludedCompanyUserIds]);

  return companyUsers;
};

export default useCompanyUsers;
