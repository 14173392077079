import { EuiFlexItem, EuiText, useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React from "react";

import { WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateServiceAssetsTable from "../../services/general/assetsTable";
import WorkOrderCreateServicesAttachments, {
  AttachmentsTableModeEnum,
} from "../../services/general/attachments";
import WorkOrderVerifyAndSaveDescription from "./description";
import WorkOrderVerifyAndSaveServicesTitle from "./serviceTitle";

interface WorkOrderVerifyAndSaveGeneralPresenterProps {
  workOrder: WorkOrder;
}

const WorkOrderVerifyAndSaveGeneralPresenter: React.FC<
  WorkOrderVerifyAndSaveGeneralPresenterProps
> = ({ workOrder }) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();
  return (
    <>
      <EuiText className={"columnTitle"}>Service Details</EuiText>
      <EuiFlexItem
        style={{ marginTop: "8px", minWidth: "360px", width: "100%" }}
        grow={0}
      >
        <WorkOrderVerifyAndSaveServicesTitle workOrder={workOrder} />
      </EuiFlexItem>
      <EuiFlexItem
        style={{ marginTop: "12px", minWidth: "360px", width: "100%" }}
        grow={0}
      >
        <WorkOrderVerifyAndSaveDescription workOrder={workOrder} />
      </EuiFlexItem>
      <EuiFlexItem
        style={{ marginTop: "12px", minWidth: "360px", width: "100%" }}
        grow={0}
      >
        {!!workOrder?.attachments?.length && (
          <WorkOrderCreateServicesAttachments
            workOrder={workOrder}
            mode={AttachmentsTableModeEnum.viewOnly}
          />
        )}
      </EuiFlexItem>
      <EuiFlexItem
        style={{ marginTop: "12px", minWidth: "360px", width: "100%" }}
        grow={0}
      >
        {!!workOrder?.assetWorkOrders?.length && (
          <WorkOrderCreateServiceAssetsTable
            companyId={workOrder.companyId}
            assets={workOrder.assetWorkOrders}
          />
        )}
      </EuiFlexItem>
    </>
  );
};

export default WorkOrderVerifyAndSaveGeneralPresenter;
