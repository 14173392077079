import { useDisclosure, useToast } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { getAssetImageCreatedMessage } from "../../../constants/lang/en";
import {
  AssetDocument,
  AssetFileType,
  useAssetFileCreateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import { setGenericMessage } from "../../../utils/serverErrors";
import Presenter, { AssetFileFormData } from "./presenter";

interface AssetFileCreatePageProps {}

const AssetFileCreatePage: React.FC<AssetFileCreatePageProps> = () => {
  const { assetId } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.has("type")
    ? searchParams.get("type")
    : AssetFileType.Other;
  const toast = useToast();
  const navigate = useNavigate();
  const [assetFileCreateMutation, { loading }] = useAssetFileCreateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AssetDocument,
        variables: { id: assetId },
      },
    ],
  });
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (data: AssetFileFormData) => {
    try {
      await Promise.all(
        data.fileIds.map((fileId) =>
          assetFileCreateMutation({
            variables: {
              data: {
                ...data,
                fileIds: undefined,
                fileId,
                assetId,
                type,
                fileIdsUploading: undefined,
              } as any,
            },
          })
        )
      );
      onDrawerClose();
      toast({
        description: getAssetImageCreatedMessage(data.fileIds.length),
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetsShow", { assetId }));
    }, 500);
  };

  return assetId ? (
    <Presenter
      handleSubmit={onSubmit}
      loading={loading}
      isOpen={isOpen}
      onDrawerClose={onDrawerClose}
      acceptFile={
        type === AssetFileType.Image ? "image/*" : "image/*, application/pdf"
      }
      type={type as AssetFileType}
    />
  ) : null;
};

export default AssetFileCreatePage;

gql`
  mutation AssetFileCreate($data: AssetFileCreateInput!) {
    assetFileCreate(data: $data) {
      ...AssetFileFragment
    }
  }
`;
