import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { addMonths, setHours, setMinutes } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import AssetSelectFields from "../../../components/elements/assetSelectFields";
import Autosuggest from "../../../components/elements/autosuggest";
import DatePicker from "../../../components/elements/datePickerField";
import FormGroup from "../../../components/elements/formGroup";
import FormSectionTitle from "../../../components/elements/formSectionTitle";
import MaintenanceIntervalField from "../../../components/elements/maintenanceIntervalField";
import MaintenanceRemindBeforeField from "../../../components/elements/maintenanceRemindBeforeField";
import TimezoneField from "../../../components/elements/timezoneField";
import {
  MaintenanceDurationType,
  MaintenanceIntervalType,
  MaintenanceRemindBeforeType,
} from "../../../graphql/graphql";
import { timezone } from "../../../utils/date/timezones";
import {
  dateSchema,
  descriptionSchema,
  endAfterSchema,
  maintenanceCompanyUserIdsSchema,
  maintenanceMiscTypeSchema,
  maintenanceMiscValueSchema,
  nameSchema,
  timezoneSchema,
  yupArray,
  yupNumber,
  yupObject,
} from "../../../utils/validation";

interface MaintenanceCreatePresenterProps {
  assetId?: string | null;
  loading: boolean;
  handleSubmit: (
    values: MaintenanceFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  isOpen: boolean;
  onDrawerClose: () => void;
  startTime?: Date;
  endTime?: Date;
}

const MaintenanceCreatePresenter: React.FC<MaintenanceCreatePresenterProps> = ({
  assetId,
  loading,
  handleSubmit,
  isOpen,
  onDrawerClose,
  startTime,
  // TODO: use endtime below to set duration later
  // endTime,
}) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>Create New Service Event</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create New Service Event</DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{
                assetId: assetId || undefined,
                name: "",
                description: "",
                startDateTime: startTime
                  ? startTime
                  : setHours(setMinutes(addMonths(new Date(), 1), 0), 9),
                timezone,
                // TODO: default 1 min for now, we have to change to one hour later on
                durationType: MaintenanceDurationType.Minutes,
                durationValue: 1,
                intervalType: undefined,
                intervalValue: undefined,
                endDateTime: undefined,
                endAfter: undefined,
                remindBeforeType: MaintenanceRemindBeforeType.Minutes,
                remindBeforeValue: 1,
                companyUserIds: [],
              }}
              enableReinitialize
              validationSchema={maintenanceFormValidationSchema}
              onSubmit={handleSubmit}
            >
              <Form noValidate>
                <FormGroup label="Title" name="name">
                  <Autosuggest
                    items={[
                      "Service reminder",
                      "Inspection",
                      "Tune up",
                      "Calibration",
                    ]}
                    autoComplete="off"
                    name="name"
                    autoFocus
                  />
                </FormGroup>

                {!assetId && (
                  <AssetSelectFields
                    name="assetId"
                    label="For which asset? (optional)"
                    multiSelect={false}
                  />
                )}

                <FormSectionTitle marginBottom="2" mt="6">
                  Reminders
                </FormSectionTitle>
                <DatePicker
                  name="startDateTime"
                  label="Send reminder on"
                  showTimeSelect
                  timeIntervals={15}
                />
                <TimezoneField />
                {/* <MaintenanceDurationField /> */}

                <Box as="fieldset">
                  <MaintenanceRemindBeforeField
                    populateCompanyUserIdsBasedOnAsset
                  />
                </Box>

                <Box as="fieldset" paddingTop="3" marginTop="3">
                  <MaintenanceIntervalField />
                </Box>

                <Box marginTop="8">
                  <Button width="full" type="submit" isLoading={loading}>
                    Save
                  </Button>
                </Box>
              </Form>
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MaintenanceCreatePresenter;

export const maintenanceFormValidationSchema = yupObject().shape({
  name: nameSchema.label("Title"),
  description: descriptionSchema,
  startDateTime: dateSchema.label("Start Date").required(),
  timezone: timezoneSchema,
  durationType: maintenanceMiscTypeSchema
    .label("Duration")
    .required("Required"),
  durationValue: maintenanceMiscValueSchema
    .label("Duration")
    .required("Required"),
  intervalType: maintenanceMiscTypeSchema.label("Interval"),
  intervalValue: maintenanceMiscValueSchema.label("Interval"),
  endDateTime: dateSchema.label("End Date").optional(),
  endAfter: endAfterSchema,
  remindBeforeType: maintenanceMiscTypeSchema.label("Reminder"),
  remindBeforeValue: maintenanceMiscValueSchema
    .label("Reminder")
    .when("remindBeforeType", {
      is: MaintenanceRemindBeforeType.Weeks,
      then: yupNumber().max(1, "Should be less than 1 week"),
    })
    .when("remindBeforeType", {
      is: MaintenanceRemindBeforeType.Days,
      then: yupNumber().max(14, "Should be less than 14 days"),
    })
    .when("remindBeforeType", {
      is: MaintenanceRemindBeforeType.Hours,
      then: yupNumber().max(24, "Should be less than 24 hours"),
    })
    .when("remindBeforeType", {
      is: MaintenanceRemindBeforeType.Minutes,
      then: yupNumber().max(1440, "Should be less than 1440 minutes"),
    }),
  companyUserIds: maintenanceCompanyUserIdsSchema.when(
    ["remindBeforeValue", "remindBeforeType"],
    {
      // is: true,
      is: (remindBeforeValue: number, remindBeforeType: string) =>
        !!remindBeforeValue && !!remindBeforeType,
      then: yupArray().min(1),
      otherwise: yupArray().min(0),
    }
  ),
});

export type MaintenanceFormData = {
  assetId?: string;
  name: string;
  description?: string;
  startDateTime: Date;
  timezone: string;
  durationType: MaintenanceDurationType;
  durationValue: number;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  endDateTime?: Date;
  endAfter?: number;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
  companyUserIds: string[];
};
