import {
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useCallback } from "react";

import FormGroup from "../formGroup";

interface NumberInputProps extends ChakraNumberInputProps {
  name: string;
  label: string;
  autoFocus?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({
  name,
  label,
  autoFocus = false,
  ...otherProps
}) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const value = get(values, name);
  const handleChange = useCallback(
    (_, value: number) => setFieldValue(name, value),
    [setFieldValue, name]
  );

  return (
    <FormGroup label={label} name={name}>
      <ChakraNumberInput
        {...otherProps}
        value={value || otherProps.defaultValue || ""}
        onChange={handleChange}
      >
        <NumberInputField autoFocus={autoFocus} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </ChakraNumberInput>
    </FormGroup>
  );
};

export default NumberInput;
