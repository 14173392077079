import { Icon, IconProps } from "@chakra-ui/react";

const SupportAgent = (props: IconProps & any) => (
  <Icon viewBox="0 0 48 48" boxSize={{ base: "6", md: "8" }} {...props}>
    <path
      d="M41.212 27.305c-2.053 5.85-6.975 10.265-12.993 11.644a8.854 8.854 0 0 1-1.181.253l-.17-1.04a1.844 1.844 0 0 1 1.349-2.082h.002a15.4 15.4 0 0 0 9.534-7.814 2.372 2.372 0 0 1 2.919-1.157l.54.196ZM41.24 15.099l-.562.197a2.321 2.321 0 0 1-2.897-1.153C35.227 9.219 30.052 5.733 24 5.733c-5.85 0-11.11 3.234-13.753 8.438a2.39 2.39 0 0 1-2.897 1.125l-.562-.197c.112-.31.224-.619.365-.928C10.023 7.308 16.631 2.92 24 2.893c8.01 0 14.764 5.204 17.24 12.206Z"
      fill="#646464"
    />
    <path
      d="M9.431 16.055c.225-.647.507-1.294.816-1.884H4.219A4.213 4.213 0 0 0 0 18.39v5.625a4.213 4.213 0 0 0 4.219 4.218h5.775l-.59-1.828c-1.154-3.515-1.126-7.087.027-10.35ZM26.605 36.443a.23.23 0 0 1-.035.003h-.01a14.86 14.86 0 0 1-2.532.225c-2.334 0-4.247 1.884-4.247 4.218A4.213 4.213 0 0 0 24 45.11a4.213 4.213 0 0 0 4.219-4.22V36.08c-.529.14-1.058.279-1.614.363Z"
      fill="#646464"
    />
    <path
      d="M36.656 21.202c0 6.98-5.677 12.656-12.656 12.656H11.344v-2.812a2.843 2.843 0 0 0 2.702-2.029 12.64 12.64 0 0 1-2.702-7.815c0-6.979 5.677-12.656 12.656-12.656 6.979 0 12.656 5.677 12.656 12.656Z"
      fill="#404040"
    />
    <path
      d="M36.656 21.202c0-6.979-5.677-12.656-12.656-12.656v25.312c6.979 0 12.656-5.677 12.656-12.656Z"
      fill="#404040"
    />
    <path
      d="M21 21.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM25.715 21.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM30.43 21.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      fill="#C8C8C8"
    />
    <path
      d="M26.565 36.446h-.006a14.86 14.86 0 0 1-2.53.225l-.029.002v8.435a4.213 4.213 0 0 0 4.219-4.218v-4.81c-.527.139-1.054.278-1.608.362a.298.298 0 0 1-.046.004ZM41.24 15.099l-.562.197a2.321 2.321 0 0 1-2.897-1.153C35.227 9.219 30.052 5.733 24 5.733v-2.84c8.01 0 14.764 5.204 17.24 12.206Z"
      fill="#646464"
    />
    <path
      d="M48 18.39a4.213 4.213 0 0 0-4.219-4.22h-6l.816 1.913c.31.816.534 1.688.675 2.56a15.518 15.518 0 0 1-.703 7.706l-.788 1.884h6A4.213 4.213 0 0 0 48 24.015V18.39Z"
      fill="#646464"
    />
  </Icon>
);

export default SupportAgent;
