import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import Link from "../../components/elements/link";
import PageSpinner from "../../components/elements/pageSpinner";
import {
  useSessionQuery,
  useUserAcceptInviteMutation,
} from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import expiredInvite from "../../images/expiredInvite.png";
import { getRoutePath } from "../../router";
import { setGenericMessage } from "../../utils/serverErrors";
import Presenter from "./presenter";

interface AcceptInvitationPageProps {}

const AcceptInvitationPage: React.FC<AcceptInvitationPageProps> = () => {
  const { data } = useSessionQuery();
  const [checked, setChecked] = React.useState(false);
  const [error, setError] = React.useState("");
  const [userAcceptInviteMutation] = useUserAcceptInviteMutation();
  const {
    email: emailEncoded,
    token: uuid,
    userName,
    companyName,
  } = useParams();

  React.useEffect(() => {
    let mounted = true;

    const checkLinkValidity = async () => {
      if (!emailEncoded) return;
      try {
        const { errors } = await userAcceptInviteMutation({
          variables: { data: { emailEncoded, uuid } },
        });
        if (errors) {
          if (mounted) setError(setGenericMessage(errors));
        } else {
          setChecked(true);
        }
      } catch (error) {
        if (mounted) setError(setGenericMessage(error));
      }
    };
    checkLinkValidity();

    return () => {
      mounted = false;
    };
  }, [userAcceptInviteMutation, emailEncoded, uuid]);

  React.useEffect(() => {
    layoutOptionsVar(
      error
        ? {}
        : {
            title: "Welcome!",
            subTitle: `Please complete the form below to accept your invitation${
              userName && companyName
                ? ` from ${decodeURIComponent(
                    userName
                  )} for ${decodeURIComponent(companyName)}`
                : companyName
                ? ` for ${decodeURIComponent(companyName)}`
                : ""
            }`,
          }
    );
  }, [error, companyName, userName]);

  return (
    <>
      <Helmet>
        <title>Accept Your Invitation</title>
      </Helmet>
      {error ? (
        <Box textAlign="center" marginX="auto" marginY="8" maxWidth="lg">
          <Image margin="auto" src={expiredInvite} />
          <Text
            fontSize="xl"
            fontWeight="semibold"
            width="70%"
            marginX="auto"
            marginTop="6"
            marginBottom="8"
          >
            {error}
          </Text>
          <Link
            to={
              data?.session.isLoggedIn
                ? getRoutePath("dashboard")
                : getRoutePath("signIn")
            }
            colorScheme="primary"
            width="full"
          >
            Continue
          </Link>
          {/* {!data?.session.isLoggedIn && (
            <Box marginTop="8">
              Don't have an account yet?{" "}
              <Link
                to={getRoutePath("signUp")}
                variant="link"
                colorScheme="secondary"
              >
                Sign up
              </Link>
            </Box>
          )} */}
        </Box>
      ) : checked ? (
        <Presenter />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default AcceptInvitationPage;
