import { EuiButtonIcon } from "@elastic/eui";
import React from "react";

export type WorkOrderEditSectionFooterProps = {
  save: () => void;
  cancel: () => void;
  disabled: boolean;
  visible: boolean;
};

const WorkOrderEditSectionFooter: React.FC<WorkOrderEditSectionFooterProps> = ({
  disabled,
  save,
  cancel,
  visible,
}: WorkOrderEditSectionFooterProps) => {
  return (
    <>
      {visible && (
        <footer className="edit-section-footer">
          <EuiButtonIcon
            display="fill"
            iconType="check"
            aria-label="save"
            onClick={save}
            disabled={disabled}
            style={{ width: "32px", height: "32px" }}
          />
          <EuiButtonIcon
            display="fill"
            iconType="cross"
            aria-label="cancel"
            onClick={cancel}
            style={{ width: "32px", height: "32px" }}
          />
        </footer>
      )}
    </>
  );
};
export default WorkOrderEditSectionFooter;
