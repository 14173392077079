import { createBreakpoints } from "@chakra-ui/theme-tools";

/**
 * Breakpoints for responsive design
 */
const breakpoints = createBreakpoints({
  xs: "20rem",
  sm: "40rem",
  md: "48rem",
  lg: "64rem",
  xl: "80rem",
  "2xl": "96rem",
});

export default breakpoints;
