import React, { useEffect, useState } from "react";

import { useUserQuery } from "../../../../../graphql/graphql";
import {
  useWOCompanyQuery,
  useWOGetCompanyUsersQuery,
  useWOGetOrderTypesQuery,
  useWOGetServiceCategoriesQuery,
  useWOLocationQuery,
} from "../../../../../graphql/queries/workOrders";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderVerifyAndSaveRightColumnPresenter from "./presenter";
import { SectionItem, WorkOrderMappingHelper } from "./sectionsHandler";

export type WorkOrderVerifyAndSaveRightColumnProps = {
  workOrder: WorkOrder;
  active: boolean;
};

const WorkOrderVerifyAndSaveRightColumn: React.FC<
  WorkOrderVerifyAndSaveRightColumnProps
> = ({ workOrder, active = false }: WorkOrderVerifyAndSaveRightColumnProps) => {
  const { data: companyUsers, loading } = useWOGetCompanyUsersQuery({
    variables: {
      companyId: workOrder?.companyId,
    },
    fetchPolicy: "cache-and-network",
    skip: !workOrder?.companyId,
  });
  const { data: orderTypes } = useWOGetOrderTypesQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: serviceCategories } = useWOGetServiceCategoriesQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: companies } = useWOCompanyQuery({
    fetchPolicy: "network-only",
    variables: {},
  });
  const { data: locations } = useWOLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: workOrder.companyId,
    },
    skip: !workOrder.companyId,
  });
  const { data: currentUser } = useUserQuery();
  const [sections, setSections] = useState<SectionItem[]>([]);
  useEffect(() => {
    if (
      active &&
      companyUsers &&
      serviceCategories &&
      orderTypes &&
      workOrder &&
      locations &&
      companies &&
      currentUser
    ) {
      const mappedSections = WorkOrderMappingHelper.getMappedWorkOrderSections({
        workOrder,
        users: companyUsers.workOrderCompanyUsers,
        orderTypes: orderTypes.orderTypes,
        serviceCategories: serviceCategories.serviceCategories,
        companies: companies.workOrdersCompanies,
        locations: locations.workOrderLocations,
        currentUser: currentUser.user,
      });
      setSections(mappedSections);
    }
  }, [
    companyUsers,
    serviceCategories,
    orderTypes,
    workOrder,
    active,
    companies,
    locations,
    currentUser,
  ]);

  return (
    <>
      <WorkOrderVerifyAndSaveRightColumnPresenter sections={sections} />
    </>
  );
};
export default WorkOrderVerifyAndSaveRightColumn;
