import {
  EuiFlexGroup,
  EuiFlexItem,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import React from "react";

import { WOCompany } from "../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrder } from "../../models/workOrder";
import WorkOrderCreateCompanyContactInfo from "./contactInfo";
import WorkOrderCreateCompanyDetails from "./details";

interface WorkOrderCreateCompanyPresenterProps {
  companies: WOCompany[];
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  workOrder: WorkOrder;
}

const WorkOrderCreateCompanyPresenter: React.FC<
  WorkOrderCreateCompanyPresenterProps
> = ({ companies, onWorkOrderChange, workOrder }) => {
  const isMobile = useIsWithinBreakpoints(["xs", "s"]);
  return (
    <EuiFlexGroup
      direction="row"
      justifyContent="spaceBetween"
      alignItems="flexStart"
      gutterSize="xl"
    >
      <EuiFlexItem
        grow={1}
        style={{
          width: "50%",
          minWidth: "360px",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <WorkOrderCreateCompanyDetails
          companies={companies}
          onWorkOrderChange={onWorkOrderChange}
          selectedCompanyId={workOrder.companyId}
          workOrder={workOrder}
        />
      </EuiFlexItem>
      <EuiFlexItem
        grow={1}
        style={{
          width: "50%",
          minWidth: "360px",
          alignItems: isMobile ? "center" : "flex-end",
        }}
      >
        <WorkOrderCreateCompanyContactInfo
          workOrder={workOrder}
          onWorkOrderChange={onWorkOrderChange}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default WorkOrderCreateCompanyPresenter;
