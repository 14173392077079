import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const MySettings = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 16 16" boxSize="16px" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.474 9.383a1.338 1.338 0 01-.48-1.096v-.574c-.021-.42.157-.827.48-1.096l.533-.44a.669.669 0 00.154-.849l-.774-1.336a.667.667 0 00-.573-.334.559.559 0 00-.233.047l-.667.24a1.257 1.257 0 01-.467.087c-.255 0-.505-.075-.72-.214a3.65 3.65 0 00-.493-.287 1.336 1.336 0 01-.707-.968l-.113-.668a.667.667 0 00-.667-.561H7.214a.667.667 0 00-.667.56l-.113.669c-.064.411-.315.77-.68.968a3.652 3.652 0 00-.493.287 1.331 1.331 0 01-.72.214c-.16.001-.318-.028-.467-.087l-.667-.24a.56.56 0 00-.233-.047.666.666 0 00-.573.334l-.76 1.336a.669.669 0 00.16.849l.533.44c.323.27.501.675.48 1.096v.574c.021.421-.157.827-.48 1.096l-.533.44a.669.669 0 00-.16.85l.773 1.335a.666.666 0 00.573.334c.08.002.16-.014.234-.047l.666-.24c.149-.058.307-.088.467-.087.256 0 .505.075.72.214.157.108.322.204.493.287.375.193.638.552.707.969l.113.668a.667.667 0 00.667.56h1.533a.667.667 0 00.667-.56l.113-.668c.07-.417.332-.776.707-.969.172-.083.337-.179.493-.287a1.33 1.33 0 01.72-.214c.16 0 .318.029.467.087l.667.24a.559.559 0 00.233.047.666.666 0 00.573-.334l.774-1.336a.669.669 0 00-.2-.848l-.527-.441zm-5.473 2.291A3.67 3.67 0 014.334 8a3.67 3.67 0 013.667-3.674A3.67 3.67 0 0111.667 8c0 .974-.386 1.909-1.073 2.598A3.663 3.663 0 018 11.674zM6.667 8a1.335 1.335 0 112.667 0c0 .738-.597 1.336-1.333 1.336A1.335 1.335 0 016.667 8z"
    />
  </Icon>
);

export default MySettings;
