import React from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import { useUserWithCompaniesQuery } from "../../graphql/graphql";
import Presenter from "./presenter";

interface CompanyCreatePageProps {}

const CompanyCreatePage: React.FC<CompanyCreatePageProps> = () => {
  const { data: userCompaniesData } = useUserWithCompaniesQuery();
  return (
    <>
      <Helmet>
        <title>Add New Company</title>
      </Helmet>
      {userCompaniesData ? (
        <Presenter
          userCompanies={userCompaniesData.user.companyUsers.map(
            (cu) => cu.company
          )}
        />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default CompanyCreatePage;
