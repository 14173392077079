import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  useAssetConfigQuery,
  useAssetQuery,
  useUpdateAssetConfigMutation,
} from "../../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface AssetsShowPageProps {}

const AssetsShowPage: React.FC<AssetsShowPageProps> = ({ children }) => {
  const { assetId: id } = useParams();
  const { data } = useAssetQuery({
    variables: { id },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: assetConfigData } = useAssetConfigQuery();
  const [updateAssetConfigMutation] = useUpdateAssetConfigMutation();
  const tabIndex = assetConfigData
    ? assetConfigData.assetConfig.activeTabIndex
    : 0;
  const setTabIndex = React.useCallback(
    (activeTabIndex: number) => {
      updateAssetConfigMutation({
        variables: {
          activeTabIndex,
        },
      });
    },
    [updateAssetConfigMutation]
  );
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  useEffect(() => {
    layoutOptionsVar({
      backLink: getRoutePath("assets"),
      backText: "Back to all assets",
    });
  }, []);

  return (
    <>
      {data ? (
        <Presenter
          asset={data.asset}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          canAddAsset={ROLES.assetsCreate.includes(currentCompanyRole.role)}
          canEditAsset={ROLES.assetsEdit.includes(currentCompanyRole.role)}
          canDeleteAsset={ROLES.assetsDelete.includes(currentCompanyRole.role)}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default AssetsShowPage;

gql`
  query Asset($id: UUID!) {
    asset(id: $id) {
      ...AssetWithRelationsFragment
    }
  }
`;
