import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, memo } from "react";
import isEqual from "react-fast-compare";
import { useLocation } from "react-router-dom";
import {
  Column,
  Row,
  useFlexLayout,
  useRowSelect,
  useTable,
} from "react-table";
import { useScrollbarWidth } from "react-use";
import { FixedSizeList } from "react-window";

import AssetsStatus from "../../../components/elements/assetsStatus";
import IndeterminateCheckbox from "../../../components/elements/indeterminateCheckbox";
import Link from "../../../components/elements/link";
import QrCodes from "../../../components/elements/qrCodes";
import TooltipName from "../../../components/elements/tooltipName";
import { FLOORPLAN_DELETED_MESSAGE } from "../../../constants/lang/en";
import {
  FloorPlanIndexFragmentFragment,
  MaintenanceStatusType,
  useFloorPlanDeleteMutation,
} from "../../../graphql/graphql";
import useBoxSize from "../../../hooks/useBoxSize";
import { getRoutePath } from "../../../router";
import { getFilesUrl } from "../../../utils/image";
import { mainLayoutPaddingX } from "../../../utils/layout";
import { setGenericMessage } from "../../../utils/serverErrors";
import FloorPlanBulkActions from "./bulkActions";

interface FloorPlanTableProps {
  floorPlans: FloorPlanIndexFragmentFragment[];
  canEditPlan: boolean;
  canDeletePlan: boolean;
  showMaintenanceNeededAssets?: boolean;
  bulkActionRef?: any;
  showFiltersAndActions?: boolean;
  virtualizeList?: boolean;
  showLocationColumn?: boolean;
}

const FloorPlanTable: React.FC<FloorPlanTableProps> = ({
  floorPlans,
  canEditPlan,
  canDeletePlan,
  bulkActionRef,
  showMaintenanceNeededAssets,
  showFiltersAndActions = true,
  virtualizeList = false,
  showLocationColumn = false,
}) => {
  const location = useLocation();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const sbw = useScrollbarWidth();
  const { containerRef, height } = useBoxSize();
  const listRef = React.useRef<HTMLDivElement>(null);
  const columns = React.useMemo(() => {
    let reactTableColumns: Column<FloorPlanIndexFragmentFragment>[] = [];

    if (showFiltersAndActions) {
      reactTableColumns.push({
        id: "selection",
        width: isMobile ? 20 : 10,
        minWidth: isMobile ? 20 : 10,
        maxWidth: isMobile ? 20 : 10,
        Cell: ({ row }: { row: any }) => (
          <Box
            px="2"
            as="button"
            visibility={{
              base: "visible",
              md: row.getToggleRowSelectedProps().checked
                ? "visible"
                : "hidden",
            }}
            _groupHover={{ visibility: "visible" }}
            mx="1"
            minW="4"
          >
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              title=""
              aria-label="Toggle Select row"
              borderColor="gray.300"
            />
          </Box>
        ),
      });
    }

    reactTableColumns.push({
      Header: (
        <Box pl={showFiltersAndActions ? "6" : "4"} pr="2">
          Name
        </Box>
      ),
      id: "name",
      accessor: (floorPlan: FloorPlanIndexFragmentFragment) => floorPlan,
      width: 250,
      Cell: ({ value }: { value: FloorPlanIndexFragmentFragment }) => (
        <Flex
          alignItems="center"
          position="relative"
          pl={showFiltersAndActions ? "6" : "4"}
          pr="2"
        >
          <AssetsStatus
            assets={value.floorPlanAssets.map((fpa) => fpa.asset)}
          />
          <Link
            to={`?CaAprType=planEditor&planId=${value.id}${
              showMaintenanceNeededAssets
                ? `&maintenanceStatuses=${MaintenanceStatusType.PastDue},${MaintenanceStatusType.DueIn_30}`
                : ""
            }`}
            variant="unstyled"
            key={value.id}
            borderWidth="1px"
            borderRadius="lg"
            height="auto"
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="left"
            _hover={{ color: "secondary.600" }}
            width="full"
            textTransform="none"
            fontSize="sm"
            fontWeight="normal"
          >
            <Image
              src={getFilesUrl(value.path, 250)}
              alt={value.name}
              boxSize="50px"
              objectFit="cover"
              fallback={<Center boxSize="50px" bgColor="gray.100" />}
              flexShrink={0}
            />
            <TooltipName name={value.name} marginLeft="2" />
          </Link>
        </Flex>
      ),
    });

    if (!isMobile) {
      if (showLocationColumn) {
        reactTableColumns.push({
          Header: () => <Text px="2">Location</Text>,
          id: "location",
          accessor: (floorPlan: FloorPlanIndexFragmentFragment) => floorPlan,
          width: 65,
          Cell: ({ value }: { value: FloorPlanIndexFragmentFragment }) => (
            <Text px="2">{value.location.name}</Text>
          ),
        });
      }

      reactTableColumns.push({
        Header: () => <Text px="2">Type</Text>,
        id: "type",
        accessor: (floorPlan: FloorPlanIndexFragmentFragment) => floorPlan,
        width: 65,
        Cell: ({ value }: { value: FloorPlanIndexFragmentFragment }) => (
          <Text px="2">{value.type}</Text>
        ),
      });

      reactTableColumns.push({
        Header: () => (
          <Text textAlign="center" px="2">
            Assets
          </Text>
        ),
        id: "assets_count",
        accessor: (floorPlan: FloorPlanIndexFragmentFragment) => floorPlan,
        width: 65,
        Cell: ({ value }: { value: FloorPlanIndexFragmentFragment }) => (
          <Text textAlign="center" px="2">
            {value.floorPlanAssets.length}
          </Text>
        ),
      });
    }

    if (!isMobile) {
      reactTableColumns.push({
        id: "actions",
        width: 65,
        Header: "",
        accessor: (floorPlan: FloorPlanIndexFragmentFragment) => floorPlan,
        Cell: ({ value }: { value: FloorPlanIndexFragmentFragment }) => (
          <Box px="2">
            <FloorPlanActions
              floorPlan={value}
              locationId={value.locationId}
              canDeletePlan={canDeletePlan}
              canEditPlan={canEditPlan}
              showFiltersAndActions={showFiltersAndActions}
              editPath={
                location.pathname === getRoutePath("floorPlans")
                  ? "floorPlansEdit"
                  : "locationsShowPlanEdit"
              }
            />
          </Box>
        ),
      });
    }
    return reactTableColumns;
  }, [
    canDeletePlan,
    canEditPlan,
    isMobile,
    location.pathname,
    showFiltersAndActions,
    showLocationColumn,
    showMaintenanceNeededAssets,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data: floorPlans,
    },
    useRowSelect,
    useFlexLayout
  );
  const selectedIds = selectedFlatRows.map((r) => r.original.id);

  return (
    <Box ref={containerRef} position="relative">
      <Portal containerRef={bulkActionRef}>
        {showFiltersAndActions && (
          <FloorPlanBulkActions
            floorPlans={floorPlans}
            selectedIds={selectedIds}
            canEditPlan={canEditPlan}
            canDeletePlan={canDeletePlan}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        )}
      </Portal>
      <Box width="100%" overflowX="auto">
        <Box {...getTableProps()}>
          {headerGroups.map((headerGroup) => (
            <Box
              {...headerGroup.getHeaderGroupProps()}
              alignItems="center"
              fontSize="xs"
              color="gray.600"
              textTransform="uppercase"
              backgroundColor="white"
              position="relative"
              zIndex="1"
              marginBottom="-4"
              px={virtualizeList ? mainLayoutPaddingX : "0"}
              mr={
                listRef.current &&
                listRef.current.clientHeight < listRef.current.scrollHeight
                  ? sbw
                  : 0
              }
            >
              {headerGroup.headers.map((column) => (
                <Box
                  p={{ base: "2", md: "3" }}
                  pt="0 !important"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </Box>
              ))}
            </Box>
          ))}
          <Box {...getTableBodyProps()}>
            {virtualizeList ? (
              <FixedSizeList
                outerRef={listRef}
                height={height - 20} // Subtracting 20px for table header
                width="100%"
                itemCount={rows.length}
                itemSize={66}
                itemData={{ rows, prepareRow, selectedIds }}
                itemKey={(i, data) => data.rows[i].original.id}
                overscanCount={8}
              >
                {RowItem}
              </FixedSizeList>
            ) : (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <Box
                      width="full"
                      alignItems="center"
                      fontSize="sm"
                      backgroundColor="secondary.10"
                      marginTop="4"
                      _hover={{ boxShadow: "md" }}
                      {...row.getRowProps()}
                      role="group"
                    >
                      {row.cells.map((cell) => (
                        <Box {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Box>
                      ))}
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FloorPlanTable;

interface FloorPlanActionsProps {
  floorPlan: FloorPlanIndexFragmentFragment;
  locationId?: string;
  canEditPlan: boolean;
  canDeletePlan: boolean;
  showFiltersAndActions: boolean;
  editPath: string;
}

const FloorPlanActions: React.FC<FloorPlanActionsProps> = ({
  floorPlan,
  locationId,
  canEditPlan,
  canDeletePlan,
  showFiltersAndActions,
  editPath,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const [deleting, setDeleting] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [floorPlanDeleteMutation] = useFloorPlanDeleteMutation();

  const deleteFloorPlan = async () => {
    setDeleting(true);
    try {
      await floorPlanDeleteMutation({
        variables: { ids: [floorPlan.id] },
      });
      toast({
        description: FLOORPLAN_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      client.cache.evict({ id: `FloorPlan:${floorPlan.id}` });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setDeleting(false);
      onClose();
    }
  };

  return (
    <Flex
      alignItems="center"
      paddingY="3"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      width="full"
      justifyContent="flex-end"
    >
      <QrCodes
        links={[
          {
            path: `${getRoutePath("floorPlans")}?CaAprType=planEditor&planId=${
              floorPlan.id
            }`,
            name: floorPlan.name,
          },
        ]}
        printDocumentTitle={floorPlan.name}
      />
      {showFiltersAndActions && canEditPlan && (
        <Tooltip label="Edit Plan" hasArrow placement="bottom">
          <Link
            to={getRoutePath(editPath, {
              locationId: locationId || floorPlan.locationId,
              planId: floorPlan.id,
            })}
            aria-label="Edit Plan"
            variant="icon"
            colorScheme="gray"
            marginX="3"
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
        </Tooltip>
      )}
      {showFiltersAndActions && canDeletePlan && (
        <>
          <Tooltip label="Delete Plan" hasArrow placement="bottom">
            <IconButton
              variant="icon"
              colorScheme="grayRed"
              aria-label="Delete Plan"
              onClick={onOpen}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
          <AlertDialog
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogBody textAlign="center">
                  Are you sure you want to delete the following plan?
                  <br />
                  <Text as="strong">{floorPlan.name}</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={onClose}
                    width="48%"
                    isLoading={deleting}
                  >
                    No, Don't Delete!
                  </Button>
                  <Button
                    onClick={deleteFloorPlan}
                    colorScheme="red"
                    ml="4%"
                    width="48%"
                    isLoading={deleting}
                  >
                    Yes, Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </Flex>
  );
};

interface Data {
  rows: Row<FloorPlanIndexFragmentFragment>[];
  prepareRow: (row: Row<FloorPlanIndexFragmentFragment>) => void;
  selectedIds: string[];
}

const RowItem: FC<{
  index: number;
  style: CSSProperties;
  data: Data;
}> = memo(({ index, style, data }) => {
  const { rows, prepareRow } = data;
  const row = rows[index];
  prepareRow(row);
  return (
    <Box style={style} paddingX={mainLayoutPaddingX}>
      <Box
        width="full"
        alignItems="center"
        fontSize="sm"
        backgroundColor="secondary.10"
        marginTop="4"
        _hover={{ boxShadow: "md" }}
        {...row.getRowProps()}
        role="group"
      >
        {row.cells.map((cell) => (
          <Box {...cell.getCellProps()}>{cell.render("Cell")}</Box>
        ))}
      </Box>
    </Box>
  );
}, isEqual);
