import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faInfoCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flatten } from "lodash";
import React from "react";

import AssetIcon from "../../../../components/elements/assetIcons";
import Link from "../../../../components/elements/link";
import TooltipName from "../../../../components/elements/tooltipName";
import AddCircle from "../../../../components/icons/addCircle";
import {
  DEFAULT_BREAKER_ROWS,
  DEFAULT_BREAKER_STARTING_NUMBER,
  DEFAULT_MAIN_BREAKER_OR_LUG,
  DEFAULT_MAIN_BREAKER_OR_LUG_POLES,
  DEFAULT_MAIN_BREAKER_OR_LUG_POSITION,
  getDefaultBreakerColumns,
} from "../../../../constants/misc";
import {
  AssetPartWithRelationsFragmentFragment,
  AssetWithRelationsFragmentFragment,
  SpecialAssetFieldTypes,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import AssetPartBulkAdd from "./assetPartBulkAdd";
import PrintPanel from "./panelPrint";
import ElectricalPanelGridSettingEdit from "./panelSettingEdit/electricalPanelGrid";
import ElectricalPanelNormalSettingEdit from "./panelSettingEdit/electricalPanelNormal";

interface AssetPanelProps {
  asset: AssetWithRelationsFragmentFragment;
  canEditAsset: boolean;
  canAddAsset: boolean;
  showHeader: boolean;
  canInteract?: boolean;
}

export interface PanelItem {
  numberOfRows: number;
  rowIndex: number;
  columnIndex: number;
  breakerNumbers?: number[];
  assetPart?: AssetPartWithRelationsFragmentFragment;
}

const AssetPanel: React.FC<AssetPanelProps> = ({
  asset,
  canEditAsset,
  showHeader,
  canInteract = true,
  canAddAsset,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isSpecial =
    asset.assetType.specialAssetField !== SpecialAssetFieldTypes.Na;
  const isNormalPanel =
    asset.assetType.specialAssetField ===
    SpecialAssetFieldTypes.ElectricalPanelNormal;
  const isGridPanel =
    asset.assetType.specialAssetField ===
    SpecialAssetFieldTypes.ElectricalPanelGrid;
  const {
    mainBreakerOrLug = DEFAULT_MAIN_BREAKER_OR_LUG,
    mainBreakerOrLugPosition = DEFAULT_MAIN_BREAKER_OR_LUG_POSITION,
    mainBreakerOrLugPoles = DEFAULT_MAIN_BREAKER_OR_LUG_POLES,
    numberOfColumns = getDefaultBreakerColumns(asset.assetType.name),
    numberOfRows = DEFAULT_BREAKER_ROWS,
    breakerStartingNumber = DEFAULT_BREAKER_STARTING_NUMBER,
    assetPartPositions = {},
  } = asset.config;
  const showInPanelAssetPartId = asset.assetType.assetPartFields.find(
    (apf) => apf.showInPanel
  )?.id;

  const columnData = Array.from(Array(numberOfColumns)).map(
    (_, columnIndex) => {
      let breakerIndex = breakerStartingNumber + columnIndex;
      const panelItems: PanelItem[] = [];
      let remainingRowsInColumn = numberOfRows;

      while (remainingRowsInColumn > 0) {
        const rowIndex = numberOfRows - remainingRowsInColumn;
        let rows = 0;
        let rowLoopIndex = rowIndex;
        let assetPartId: string = "";

        while (true) {
          const id = assetPartPositions[`${rowLoopIndex}-${columnIndex}`];
          if (!id || (!!id && !!assetPartId && id !== assetPartId)) {
            if (rows === 0) rows++;
            break;
          }
          rows++;
          assetPartId = id;
          rowLoopIndex++;
        }
        remainingRowsInColumn = remainingRowsInColumn - rows;

        panelItems.push({
          numberOfRows: rows,
          rowIndex,
          columnIndex,
          assetPart: assetPartId
            ? asset.assetParts.find(({ id }) => id === assetPartId)
            : undefined,
          breakerNumbers: isNormalPanel
            ? /* eslint no-loop-func: 0 */
              Array.from(Array(rows)).map(() => {
                const index = breakerIndex;
                breakerIndex = breakerIndex + numberOfColumns;
                return index;
              })
            : undefined,
        });
      }

      return panelItems;
    }
  );

  return isSpecial ? (
    <Box>
      {showHeader && (
        <>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="1"
          >
            {canEditAsset ? (
              <Tooltip
                label="Click any box below to add or edit a breaker"
                hasArrow
                placement="right"
              >
                <Box color="gray.300" marginLeft="3">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Box>
              </Tooltip>
            ) : (
              <Box />
            )}
            <Flex flexDirection="row" alignItems="center">
              {!isGridPanel && canAddAsset && (
                <AssetPartBulkAdd
                  asset={asset}
                  mainBreakerId={
                    asset.assetParts.find(
                      ({ id }) => id === assetPartPositions["-1--1"]
                    )?.id
                  }
                  rowData={flatten(columnData)}
                />
              )}
              <PrintPanel asset={asset} />
              {canEditAsset && (
                <Tooltip
                  label="Change panel settings"
                  hasArrow
                  placement="left"
                >
                  <Button
                    variant="icon"
                    colorScheme="gray"
                    aria-label="Change Panel Settings"
                    onClick={onOpen}
                    marginLeft="3"
                  >
                    <FontAwesomeIcon icon={faPen} size="sm" />
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Flex>
          {isNormalPanel && (
            <ElectricalPanelNormalSettingEdit
              asset={asset}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}
          {isGridPanel && (
            <ElectricalPanelGridSettingEdit
              asset={asset}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}
        </>
      )}
      <Box>
        {mainBreakerOrLug && mainBreakerOrLugPosition === "top" && (
          <MainBreaker
            asset={asset}
            assetPartPositions={assetPartPositions}
            poles={mainBreakerOrLugPoles}
            showInPanelAssetPartId={showInPanelAssetPartId}
            canInteract={canInteract}
            canAddAsset={canAddAsset}
          />
        )}
        <Flex
          marginBottom="8"
          justifyContent={isNormalPanel ? "space-between" : "center"}
        >
          {columnData.map((column, i) => (
            <AssetPanelColumn
              key={i}
              panelItems={column}
              asset={asset}
              isNormalPanel
              showInPanelAssetPartId={showInPanelAssetPartId}
              canInteract={canInteract}
              canAddAsset={canAddAsset}
            />
          ))}
        </Flex>
        {mainBreakerOrLug && mainBreakerOrLugPosition === "bottom" && (
          <MainBreaker
            asset={asset}
            assetPartPositions={assetPartPositions}
            poles={mainBreakerOrLugPoles}
            showInPanelAssetPartId={showInPanelAssetPartId}
            canInteract={canInteract}
            canAddAsset={canAddAsset}
          />
        )}
      </Box>
    </Box>
  ) : null;
};

export default AssetPanel;

interface MainBreakerProps {
  asset: AssetWithRelationsFragmentFragment;
  poles: number;
  assetPartPositions: any;
  showInPanelAssetPartId?: string;
  canInteract?: boolean;
  canAddAsset: boolean;
}

const MainBreaker: React.FC<MainBreakerProps> = ({
  asset,
  assetPartPositions,
  poles,
  showInPanelAssetPartId,
  canInteract,
  canAddAsset,
}) => {
  return (
    <Flex marginBottom="4" textAlign="center" flexFlow="column">
      <Center>
        <Box width="40">
          <AssetPanelBreaker
            assetPart={asset.assetParts.find(
              ({ id }) => id === assetPartPositions["-1--1"]
            )}
            rowIndex={-1}
            columnIndex={-1}
            asset={asset}
            numberOfRows={poles}
            isMainBreaker
            showInPanelAssetPartId={showInPanelAssetPartId}
            isNormalPanel={false}
            canInteract={canInteract}
            canAddAsset={canAddAsset}
          />
        </Box>
      </Center>
      <Box fontSize="xs" marginTop="1">
        Main Circuit Breaker
      </Box>
    </Flex>
  );
};

interface AssetPanelColumnProps {
  asset: AssetWithRelationsFragmentFragment;
  panelItems: PanelItem[];
  isNormalPanel: boolean;
  showInPanelAssetPartId?: string;
  canInteract?: boolean;
  canAddAsset: boolean;
}

const AssetPanelColumn: React.FC<AssetPanelColumnProps> = ({
  panelItems,
  asset,
  isNormalPanel,
  showInPanelAssetPartId,
  canInteract,
  canAddAsset,
}) => {
  return (
    <Box
      width={isNormalPanel ? "40%" : "40"}
      border="1px solid"
      borderColor="gray.100"
    >
      {panelItems.map((pi, i) => (
        <Box key={pi.assetPart?.id || i} position="relative" margin="2">
          <AssetPanelBreaker
            numberOfRows={pi.numberOfRows}
            assetPart={pi.assetPart}
            rowIndex={pi.rowIndex}
            columnIndex={pi.columnIndex}
            asset={asset}
            showInPanelAssetPartId={showInPanelAssetPartId}
            isNormalPanel={isNormalPanel}
            canInteract={canInteract}
            canAddAsset={canAddAsset}
          />
          {!!pi.breakerNumbers && pi.breakerNumbers.length > 0 && (
            <Flex
              position="absolute"
              right={pi.columnIndex === 0 ? "auto" : "100%"}
              left={pi.columnIndex === 0 ? "100%" : "auto"}
              marginRight={pi.columnIndex === 0 ? 0 : "4"}
              marginLeft={pi.columnIndex === 0 ? "4" : 0}
              top="0"
              height="full"
              flexDirection="column"
              justifyContent="space-around"
              color="gray.700"
            >
              {pi.breakerNumbers.map((number) => (
                <Box key={number}>{number}</Box>
              ))}
            </Flex>
          )}
        </Box>
      ))}
    </Box>
  );
};

interface AssetPanelBreakerProps {
  asset: AssetWithRelationsFragmentFragment;
  assetPart?: AssetPartWithRelationsFragmentFragment;
  numberOfRows?: number;
  rowIndex: number;
  columnIndex: number;
  isMainBreaker?: boolean;
  showInPanelAssetPartId?: string;
  isNormalPanel: boolean;
  canInteract?: boolean;
  canAddAsset: boolean;
}

const AssetPanelBreaker: React.FC<AssetPanelBreakerProps> = ({
  asset,
  assetPart,
  numberOfRows = 1,
  rowIndex,
  columnIndex,
  isMainBreaker = false,
  showInPanelAssetPartId,
  isNormalPanel,
  canInteract,
  canAddAsset,
}) => {
  const fedToAssets = assetPart
    ? asset.assetAffectedAssets.filter(
        (afa) => afa.assetPartId === assetPart.id
      )
    : [];

  const floorPlanAssetsWithAffectedAreas = assetPart
    ? asset.floorPlanAssets.filter(
        (fpa) =>
          fpa.mapAffectedArea &&
          fpa.mapAffectedArea.features?.some(
            (f: any) => f?.properties.assetPartId === assetPart.id
          )
      )
    : [];

  const height = `${32 * numberOfRows + 8 * (numberOfRows - 1)}px`;
  return assetPart ? (
    <Popover placement="top" isLazy returnFocusOnClose autoFocus>
      <PopoverTrigger>
        <Button
          variant="unstyled"
          height={height}
          background="gray.100"
          width="full"
          _hover={{ backgroundColor: "gray.300" }}
          fontSize="xs"
          justifyContent="flex-start"
          textTransform="none"
          textAlign="left"
          display="flex"
          alignItems="center"
        >
          {isNormalPanel && columnIndex === 0 && (
            <TooltipName name={assetPart.name} flexGrow={1} paddingX="1" />
          )}
          <Box
            width="7"
            height="full"
            backgroundColor={
              floorPlanAssetsWithAffectedAreas.length
                ? "secondary.500"
                : "gray.400"
            }
            color="white"
            overflow="hidden"
            fontSize="11px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex="none"
          >
            {assetPart.assetPartFieldValues
              .find((apfv) => apfv.assetPartFieldId === showInPanelAssetPartId)
              ?.value?.substring(0, 4) || "-"}
          </Box>
          {(!isNormalPanel || columnIndex !== 0) && (
            <TooltipName name={assetPart.name} flexGrow={1} paddingX="1" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Box as="section" mb="3">
            <Heading
              as="h4"
              size="sm"
              fontWeight="bold"
              paddingBottom="1"
              borderBottom="1px solid"
              borderColor="gray.100"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span>Plans</span>
              {canInteract && canAddAsset && (
                <Tooltip label="Add new asset plan" hasArrow>
                  <Link
                    to={getRoutePath("assetsShowFloorPlanCreate", {
                      assetId: asset.id,
                    })}
                    variant="icon"
                    colorScheme="graySecondary"
                    aria-label="Add New Asset Plan"
                  >
                    <AddCircle />
                  </Link>
                </Tooltip>
              )}
            </Heading>
            {asset.floorPlanAssets.length ? (
              <Box>
                {asset.floorPlanAssets.map(({ floorPlan }) => (
                  <Link
                    key={floorPlan.id}
                    alignItems="center"
                    justifyContent="flex-start"
                    to={`?CaAprType=planEditor&assetId=${asset.id}&planId=${
                      floorPlan.id
                    }${isMainBreaker ? "" : `&assetPartId=${assetPart.id}`}`}
                    variant="link"
                    colorScheme="secondary"
                    width="full"
                    fontSize="sm"
                  >
                    <TooltipName name={floorPlan.name} />
                  </Link>
                ))}
              </Box>
            ) : (
              <Box color="gray.300" fontSize="xs" as="span">
                —
              </Box>
            )}
          </Box>
          <Box as="section" mb="3">
            <Heading
              as="h4"
              size="sm"
              fontWeight="bold"
              paddingBottom="1"
              borderBottom="1px solid"
              borderColor="gray.100"
              textAlign="left"
            >
              Affected Areas
            </Heading>
            {floorPlanAssetsWithAffectedAreas.length ? (
              <Box>
                {floorPlanAssetsWithAffectedAreas.map(({ floorPlan }) => (
                  <Link
                    key={floorPlan.id}
                    alignItems="center"
                    justifyContent="flex-start"
                    to={`?CaAprType=planEditor&assetId=${asset.id}&planId=${
                      floorPlan.id
                    }${isMainBreaker ? "" : `&assetPartId=${assetPart.id}`}`}
                    variant="link"
                    colorScheme="secondary"
                    width="full"
                    fontSize="sm"
                  >
                    <TooltipName name={floorPlan.name} />
                  </Link>
                ))}
              </Box>
            ) : (
              <Box color="gray.300" fontSize="xs" as="span">
                —
              </Box>
            )}
          </Box>
          <Box as="section" mb="3">
            <Heading
              as="h4"
              size="sm"
              fontWeight="bold"
              paddingBottom="1"
              borderBottom="1px solid"
              borderColor="gray.100"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span>Fed To</span>
              {canInteract && canAddAsset && (
                <Tooltip label="Add new fed to asset" hasArrow>
                  <Link
                    to={`${getRoutePath("assetsShowAffectedCreate", {
                      assetId: asset.id,
                    })}?assetPartId=${assetPart.id}`}
                    variant="icon"
                    colorScheme="graySecondary"
                    aria-label="Add New Fed To Asset"
                  >
                    <AddCircle />
                  </Link>
                </Tooltip>
              )}
            </Heading>
            {fedToAssets.length ? (
              <Box>
                {fedToAssets.map(({ affectedAsset }) => (
                  <Link
                    key={affectedAsset.id}
                    alignItems="center"
                    justifyContent="flex-start"
                    to={getRoutePath("assetsShow", {
                      assetId: affectedAsset.id,
                    })}
                    variant="link"
                    flexGrow={1}
                    colorScheme="secondary"
                    width="full"
                    fontSize="sm"
                    marginY="1"
                  >
                    <AssetIcon
                      iconName={affectedAsset.assetType.iconName}
                      iconColor={affectedAsset.assetType.misc.resolvedIconColor}
                      iconType={affectedAsset.assetType.iconType}
                      iconSize="xs"
                    />
                    <TooltipName marginLeft="1" name={affectedAsset.name} />
                  </Link>
                ))}
              </Box>
            ) : (
              <Box color="gray.300" fontSize="xs" as="span">
                —
              </Box>
            )}
          </Box>
          {canInteract && canAddAsset && (
            <Link
              to={`${getRoutePath("assetsShowAssetPartEdit", {
                assetId: asset.id,
                assetPartId: assetPart.id,
              })}?rowIndex=${rowIndex}&columnIndex=${columnIndex}`}
              variant="outline"
              colorScheme="secondary"
              size="xs"
              width="full"
              fontSize="xs"
            >
              Edit
            </Link>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : canInteract && canAddAsset ? (
    <Link
      to={`${getRoutePath("assetsShowAssetPartCreate", {
        assetId: asset.id,
      })}?rowIndex=${rowIndex}&columnIndex=${columnIndex}`}
      height={height}
      background="gray.100"
      width="full"
      _hover={{ backgroundColor: "gray.300" }}
      aria-label="Add New Breaker"
    />
  ) : (
    <Box height={height} background="gray.100" />
  );
};
