import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  UserFragmentFragment,
  useUserEmailConfirmMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { emailSchema, yupObject } from "../../../utils/validation";

interface ChangeEmailPagePresenterProps {
  user: UserFragmentFragment;
}

const ChangeEmailPagePresenter: React.FC<ChangeEmailPagePresenterProps> = ({
  user,
}) => {
  const toast = useToast();
  const [userEmailConfirmMutation, { loading }] = useUserEmailConfirmMutation();
  const navigate = useNavigate();

  const onSubmit = async (
    data: ChangeEmailFormData,
    { setFieldError }: FormikHelpers<ChangeEmailFormData>
  ) => {
    if (data.email === user?.email) {
      navigate(getRoutePath("mySettings"));
      toast({
        description: GENERIC_SAVED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      return;
    }

    try {
      const { data: serverData, errors } = await userEmailConfirmMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        navigate(getRoutePath("mySettings"));
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        email: user.email || "",
      }}
      validationSchema={ChangeEmailValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ getFieldProps }) => (
        <Form noValidate>
          <FormGroup label="Email" name="email">
            <Input autoFocus autoComplete="email" {...getFieldProps("email")} />
          </FormGroup>
          <Box marginY="10">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeEmailPagePresenter;

const ChangeEmailValidationSchema = yupObject().shape({
  email: emailSchema,
});

type ChangeEmailFormData = {
  email: string;
};
