import React from "react";
import { useParams } from "react-router-dom";

import { useLocationQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface LocationShowEditPageProps {}

const LocationShowEditPage: React.FC<LocationShowEditPageProps> = () => {
  const { locationId } = useParams();
  const { data } = useLocationQuery({
    variables: { id: locationId },
  });

  return data && locationId ? (
    <Presenter id={locationId} location={data.location} />
  ) : null;
};

export default LocationShowEditPage;
