import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faChevronDown,
  faChevronUp,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Link from "../../../components/elements/link";
import QrCodes from "../../../components/elements/qrCodes";
import TooltipName from "../../../components/elements/tooltipName";
import {
  LocationConfigQuery,
  useLocationConfigQuery,
} from "../../../graphql/graphql";
import useTopLocation from "../../../hooks/useTopLocation";
import useTopLocationTree from "../../../hooks/useTopLocationTree";
import { getRoutePath } from "../../../router";
import orderBy from "../../../utils/sort";

const LocationTree = ({
  id,
  canEditLocation,
}: {
  id: string;
  canEditLocation: boolean;
}) => {
  const { data: locationConfigData } = useLocationConfigQuery();
  const location = useTopLocation(id);
  if (!location) return null;
  return locationConfigData ? (
    <Location
      id={location.id}
      activeLocationId={id}
      canEditLocation={canEditLocation}
      locationConfigData={locationConfigData}
    />
  ) : null;
};

export default LocationTree;

const Location = ({
  id,
  activeLocationId,
  locationConfigData,
  canEditLocation,
}: {
  id: string;
  activeLocationId: string;
  canEditLocation: boolean;
  locationConfigData: LocationConfigQuery;
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const location = useTopLocationTree(id);
  const locationConfig = locationConfigData.locationConfig;
  if (!location) return null;

  const isActive = activeLocationId === location.id;
  return (
    <Box
      position="relative"
      sx={
        location.parentId
          ? {
              "&:last-of-type:before": {
                content: '""',
                position: "absolute",
                top: "19px",
                backgroundColor: "white",
                width: "3px",
                height: "calc(100% - 20px)",
                left: "-18px",
                zIndex: 1,
              },
            }
          : undefined
      }
    >
      <Flex
        alignItems="center"
        marginBottom="2"
        backgroundColor="secondary.10"
        padding="2"
        position="relative"
        sx={
          location.parentId
            ? {
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: "50%",
                  borderTop: "1px solid",
                  borderColor: "secondary.100",
                  width: "16px",
                  left: "-16px",
                },
              }
            : undefined
        }
      >
        {location.children.length > 0 && (
          <IconButton
            variant="icon"
            aria-label={`${isOpen ? "Close" : "Open"} location children`}
            colorScheme="secondary"
            color={isActive ? "gray.700" : "secondary.500"}
            _hover={{ color: isActive ? "gray.700" : "secondary.600" }}
            size="xxs"
            onClick={onToggle}
            marginRight="2"
          >
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              size="xs"
            />
          </IconButton>
        )}
        <Link
          to={getRoutePath("locationsShow", { locationId: location.id })}
          variant="link"
          colorScheme="secondary"
          color={isActive ? "gray.700" : "secondary.500"}
          _hover={{ color: isActive ? "gray.700" : "secondary.600" }}
          flexGrow={1}
          justifyContent="flex-start"
        >
          <TooltipName name={location.name} />
        </Link>
        {isActive && (
          <>
            <QrCodes
              links={[
                {
                  path: getRoutePath("locationsShow", {
                    locationId: location.id,
                  }),
                  name: location.name,
                },
              ]}
              printDocumentTitle={location.name}
            />
            {canEditLocation && (
              <Tooltip label="Edit This Location" hasArrow placement="bottom">
                <Link
                  to={getRoutePath("locationsShowEdit", {
                    locationId: location.id,
                  })}
                  aria-label="Edit location"
                  variant="icon"
                  colorScheme="gray"
                  marginLeft="3"
                  marginRight="2"
                >
                  <FontAwesomeIcon icon={faPen} size="sm" />
                </Link>
              </Tooltip>
            )}
          </>
        )}
      </Flex>
      {isOpen && location.children.length > 0 && (
        <Box
          paddingLeft="8"
          position="relative"
          sx={
            location.children.length && isOpen
              ? {
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    top: "-8px",
                    backgroundColor: "secondary.100",
                    width: "1px",
                    height: "calc(100% - 10px)",
                    left: "15px",
                  },
                }
              : undefined
          }
        >
          {orderBy(
            location.children,
            locationConfig.sortBy,
            locationConfig.sortOrder,
            "children"
          ).map((l) => (
            <Location
              id={l.id}
              key={l.id}
              activeLocationId={activeLocationId}
              canEditLocation={canEditLocation}
              locationConfigData={locationConfigData}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
