const popover = {
  baseStyle: {
    popper: {
      width: "auto",
      zIndex: 2000,
    },
    header: {
      borderBottomWidth: 0,
      fontWeight: "bold",
      pb: "0",
    },
    content: {
      backgroundColor: "white",
      color: "black",
      minWidth: "200px",
      border: "1px solid",
      borderColor: "gray.100",
      boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.08) !important",
    },
  },
};

export default popover;
