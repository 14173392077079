import {
  Box,
  Button,
  Flex,
  IconButton,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faBell, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { FieldArray, useFormikContext } from "formik";
import pluralize from "pluralize";
import React, { useCallback, useState } from "react";

import { defaultShortDateWithTimeAndZoneFormat } from "../../../constants/date";
import { MaintenanceTemplateFragmentFragment } from "../../../graphql/graphql";
import { AssetMaintenanceValue } from "../../../pages/assets/create/presenter";
import AddCircle from "../../icons/addCircle";
import FormSectionTitle from "../formSectionTitle";
import ModalNewForm from "./modalNewForm";

interface DefaultAssetMaintenanceInputProps {
  maintenanceTemplates: MaintenanceTemplateFragmentFragment[];
}

const DefaultAssetMaintenanceInput: React.FC<
  DefaultAssetMaintenanceInputProps
> = ({ maintenanceTemplates }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { values } = useFormikContext<any>();
  const assetMaintenancesInputValue = values.assetMaintenancesInput;
  const [newMaintenanceTemplates, setNewMaintenanceTemplates] = useState<
    MaintenanceTemplateFragmentFragment[]
  >([]);
  const maintenanceTemplatesLength = maintenanceTemplates.length;

  return (
    <Box>
      <FormSectionTitle marginBottom="2" mt="6">
        <Flex alignItems="center">
          <Box as="span" color="gray.600">
            <FontAwesomeIcon icon={faBell} />
          </Box>
          <Box as="span" mx="2">
            Service Schedule
          </Box>
          <Tooltip label="Create new service event" hasArrow>
            <IconButton
              variant="icon"
              colorScheme="gray"
              type="button"
              aria-label="Create new service event"
              onClick={onOpen}
              minW="none"
            >
              <AddCircle />
            </IconButton>
          </Tooltip>
        </Flex>
      </FormSectionTitle>
      <Text fontSize="xs" mb="2">
        {maintenanceTemplates.length
          ? `${
              maintenanceTemplates.length === 1
                ? "Turn on the service schedule below"
                : "Turn on one or more of the default service schedule below"
            } or create your own to get notified when this asset needs service or maintenance.`
          : "Create your own to get notified when this asset needs service or maintenance."}
      </Text>
      <FieldArray
        name="assetMaintenancesInput"
        render={(arrayHelpers) => (
          <Box>
            {maintenanceTemplates.map((mt, index) => (
              <MaintenanceInput
                key={mt.id}
                index={index}
                value={
                  assetMaintenancesInputValue &&
                  assetMaintenancesInputValue.length > 0
                    ? assetMaintenancesInputValue[index]
                    : null
                }
                maintenanceTemplate={mt}
                arrayHelpers={arrayHelpers}
              />
            ))}
            {newMaintenanceTemplates.map((mt, index) => (
              <MaintenanceInput
                key={mt.id}
                index={maintenanceTemplatesLength + index}
                value={
                  assetMaintenancesInputValue &&
                  assetMaintenancesInputValue.length > 0
                    ? assetMaintenancesInputValue[
                        maintenanceTemplatesLength + index
                      ]
                    : null
                }
                maintenanceTemplate={mt}
                arrayHelpers={arrayHelpers}
                showAllFields
              />
            ))}
            <ModalNewForm
              isOpen={isOpen}
              onClose={onClose}
              showAllFields
              handleSubmit={(data) => {
                setNewMaintenanceTemplates((mt) => [
                  ...mt,
                  {
                    __typename: "MaintenanceTemplate",
                    id: Date.now(),
                    createdAt: new Date(),
                    description: "",
                    name: data.name,
                    intervalType: data.intervalType,
                    intervalValue: data.intervalValue,
                    assetTypeId: "",
                  },
                ]);
                arrayHelpers.push(data);
                onClose();
              }}
            />
          </Box>
        )}
      />
    </Box>
  );
};

export default DefaultAssetMaintenanceInput;

interface MaintenanceInputProps {
  arrayHelpers: any;
  maintenanceTemplate: MaintenanceTemplateFragmentFragment;
  value?: AssetMaintenanceValue;
  index: number;
  showAllFields?: boolean;
}

const MaintenanceInput: React.FC<MaintenanceInputProps> = ({
  maintenanceTemplate,
  value,
  index,
  arrayHelpers,
  showAllFields = false,
}) => {
  const isChecked = !!value;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const startTimeValue = value ? value.startDateTime || "" : undefined;

  const onToggle = useCallback(() => {
    if (isChecked) {
      arrayHelpers.replace(index, null);
    } else {
      onOpen();
    }
  }, [arrayHelpers, index, isChecked, onOpen]);

  return (
    <Box
      marginBottom="3"
      paddingBottom="3"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <Flex alignItems="center">
        <Flex alignItems="center" flex="1">
          <Switch
            isChecked={isChecked}
            onChange={onToggle}
            aria-label={`${isChecked ? "Disable" : "Enable"} ${
              maintenanceTemplate.name
            }`}
          />
          <Button
            variant="unstyled"
            onClick={onToggle}
            fontWeight="normal"
            textTransform="none"
            type="button"
            marginLeft="2"
            color="black"
          >
            {maintenanceTemplate.name}
          </Button>
        </Flex>
        {isChecked && (
          <Button
            onClick={onOpen}
            aria-label="Edit asset type"
            variant="icon"
            colorScheme="gray"
            padding={2}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
        )}
      </Flex>
      <Text mt="-3" mb="4" fontSize="xs" color="gray.700">
        A reminder will be sent to the users responsible for this asset every{" "}
        {pluralize(
          maintenanceTemplate.intervalType.toLowerCase(),
          maintenanceTemplate.intervalValue,
          true
        )}
        {startTimeValue
          ? ` starting ${format(
              startTimeValue,
              defaultShortDateWithTimeAndZoneFormat
            )}`
          : ""}
      </Text>
      <ModalNewForm
        isOpen={isOpen}
        onClose={onClose}
        showAllFields={showAllFields}
        handleSubmit={(data) => {
          arrayHelpers.replace(index, data);
          onClose();
        }}
        name={value?.name || maintenanceTemplate.name}
        intervalType={value?.intervalType || maintenanceTemplate.intervalType}
        intervalValue={
          value?.intervalValue || maintenanceTemplate.intervalValue
        }
        startDateTime={value?.startDateTime || undefined}
        timezone={value?.timezone || undefined}
        remindBeforeType={value?.remindBeforeType || undefined}
        remindBeforeValue={value?.remindBeforeValue || undefined}
        durationType={value?.durationType || undefined}
        durationValue={value?.durationValue || undefined}
        title={maintenanceTemplate.name}
      />
    </Box>
  );
};
