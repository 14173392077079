import { Icon, IconProps } from "@chakra-ui/react";
import { useState } from "react";

const PageNotFound = (props: IconProps & any) => {
  const [id] = useState(`pageNotFound_clip0${Math.random()}`);

  return (
    <Icon viewBox="0 0 498 564" width="498px" height="564px" {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M230.038 418.677c100.815 0 182.542-81.692 182.542-182.464 0-100.771-81.727-182.463-182.542-182.463S47.496 135.442 47.496 236.213c0 100.772 81.727 182.464 182.542 182.464z"
          fill="#F0F0F0"
        />
        <path
          d="M234.58 319.557v109.528c0 18.963-15.379 34.336-34.351 34.336H34.349c-14.103 0-25.536 11.427-25.536 25.524v70.651A4.405 4.405 0 014.406 564 4.405 4.405 0 010 559.596v-70.651c0-18.961 15.378-34.333 34.349-34.333H200.23c14.102 0 25.534-11.427 25.534-25.523V319.557h8.815z"
          fill="#000"
        />
        <path
          d="M244.494 343.197v17.553a7.59 7.59 0 01-7.592 7.588h-13.461a7.573 7.573 0 01-5.776-2.665v-.002a7.554 7.554 0 01-1.813-4.921v-17.552h28.642v-.001z"
          fill="#2D2D2D"
        />
        <path
          d="M259.455 302.281v35.061c0 5.415-4.392 9.804-9.81 9.804h-38.948c-5.417 0-9.808-4.39-9.808-9.804v-35.061h58.566z"
          fill="#3D3D3D"
        />
        <path
          d="M197.401 294.562h65.541c.711 0 1.287.577 1.287 1.288v5.145c0 .711-.576 1.287-1.287 1.287h-65.541a1.288 1.288 0 01-1.288-1.287v-5.145c0-.711.577-1.288 1.288-1.288z"
          fill="#414141"
        />
        <path
          d="M221.382 294.562H211.4v-23.861a4.99 4.99 0 014.991-4.988 4.99 4.99 0 014.991 4.988v23.861zM248.942 294.562h-9.981v-23.861a4.989 4.989 0 014.991-4.988 4.989 4.989 0 014.99 4.988v23.861z"
          fill="#3D3D3D"
        />
        <path
          d="M259.455 302.281v35.062c0 5.414-4.392 9.803-9.81 9.803h-38.948c-5.417 0-9.808-4.39-9.808-9.803 42.055-2.921 45.917-35.062 45.917-35.062h12.649z"
          fill="#2C2C2C"
        />
        <path
          d="M259.455 302.281h-58.566v2.827h58.566v-2.827z"
          fill="#202020"
        />
        <path
          d="M259.455 317.102v20.242c0 5.414-4.392 9.803-9.81 9.803h-27.342c27.61 0 37.152-30.045 37.152-30.045z"
          fill="#212121"
        />
        <path
          d="M244.493 347.146h-28.641v1.833h28.641v-1.833zM221.382 291.07H211.4v3.493h9.982v-3.493zM248.942 291.07h-9.981v3.493h9.981v-3.493z"
          fill="#000"
        />
        <path
          d="M244.493 347.146v13.604a7.591 7.591 0 01-7.592 7.588H223.44a7.573 7.573 0 01-5.776-2.665v-.002c20.982 2.572 24.186-18.524 24.186-18.524h2.643v-.001z"
          fill="#1A1A1A"
        />
        <path
          d="M497.999 4.405a4.406 4.406 0 01-4.406 4.405H260.116c-14.104 0-25.537 11.428-25.537 25.526v115.746h-8.813V34.336C225.766 15.373 241.142 0 260.116 0h233.477a4.406 4.406 0 014.406 4.405z"
          fill="#000"
        />
        <path
          d="M244.494 140.172v-17.553a7.591 7.591 0 00-7.592-7.588h-13.461a7.577 7.577 0 00-5.776 2.665v.002a7.554 7.554 0 00-1.813 4.921v17.553h28.642z"
          fill="#2D2D2D"
        />
        <path
          d="M259.455 181.088v-35.062c0-5.414-4.392-9.803-9.81-9.803h-38.948c-5.417 0-9.808 4.389-9.808 9.803v35.062h58.566z"
          fill="#3D3D3D"
        />
        <path
          d="M197.401 188.807h65.541c.711 0 1.287-.576 1.287-1.287v-5.145c0-.711-.576-1.287-1.287-1.287h-65.541c-.711 0-1.288.576-1.288 1.287v5.145c0 .711.577 1.287 1.288 1.287z"
          fill="#414141"
        />
        <path
          d="M259.455 181.088v-35.062c0-5.414-4.392-9.803-9.81-9.803h-38.948c-5.417 0-9.808 4.389-9.808 9.803 42.055 2.921 45.917 35.062 45.917 35.062h12.649z"
          fill="#2C2C2C"
        />
        <path d="M259.455 178.26h-58.566v2.827h58.566v-2.827z" fill="#202020" />
        <path
          d="M259.455 166.269v-20.243c0-5.414-4.392-9.803-9.81-9.803h-27.342c27.61 0 37.152 30.046 37.152 30.046z"
          fill="#212121"
        />
        <path d="M244.493 134.391h-28.641v1.832h28.641v-1.832z" fill="#000" />
        <path
          d="M244.493 136.223v-13.604a7.591 7.591 0 00-7.592-7.588H223.44a7.577 7.577 0 00-5.776 2.665v.002c20.982-2.572 24.186 18.524 24.186 18.524h2.643v.001z"
          fill="#1A1A1A"
        />
        <path
          d="M153.353 219.42h-18.134a2.495 2.495 0 110-4.99h18.134a2.496 2.496 0 110 4.99zM176.369 196.414a2.496 2.496 0 01-2.496-2.495v-18.127a2.496 2.496 0 014.993 0v18.127a2.496 2.496 0 01-2.497 2.495zM159.729 202.787a2.489 2.489 0 01-1.765-.731l-12.457-12.452a2.494 2.494 0 113.53-3.529l12.457 12.452a2.493 2.493 0 010 3.528 2.486 2.486 0 01-1.765.732zM306.991 275.559h18.134a2.495 2.495 0 110 4.99h-18.134a2.496 2.496 0 110-4.99zM283.975 298.564a2.496 2.496 0 012.496 2.496v18.126a2.495 2.495 0 01-4.992 0V301.06a2.496 2.496 0 012.496-2.496zM300.615 292.191c.639 0 1.278.244 1.765.731l12.458 12.452a2.496 2.496 0 11-3.53 3.529l-12.458-12.452a2.494 2.494 0 011.765-4.26z"
          fill="#0B0B0B"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h498v564H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default PageNotFound;
