import { sortBy } from "lodash";

import { DEFAULT_BREAKER_STARTING_NUMBER } from "../constants/misc";
import {
  AssetForMapFragmentFragment,
  AssetPartFragmentFragment,
  SpecialAssetFieldTypes,
} from "../graphql/graphql";
import { sortCollator } from "./sort";

interface AssetPartOption {
  label: string;
  query: string;
  value: string;
  assetPart?: AssetPartFragmentFragment;
}

export const getAssetPartOptions = (
  asset: AssetForMapFragmentFragment,
  sendAsetPart: boolean = false
): AssetPartOption[] => {
  const {
    assetParts,
    config,
    assetType: { specialAssetField },
  } = asset;
  const {
    assetPartPositions = {},
    breakerStartingNumber = DEFAULT_BREAKER_STARTING_NUMBER,
  } = config;
  const assetPartPositionsValues = Object.entries(assetPartPositions);
  const isSpecial =
    !!specialAssetField &&
    [
      SpecialAssetFieldTypes.ElectricalPanelNormal,
      SpecialAssetFieldTypes.ElectricalPanelGrid,
    ].includes(specialAssetField);
  const isElectricalPanel =
    !!specialAssetField &&
    specialAssetField === SpecialAssetFieldTypes.ElectricalPanelNormal;

  const parts = assetParts
    .map((assetPart) => {
      let key = "";
      if (isSpecial) {
        const positions = assetPartPositionsValues.filter(
          ([_, value]) => value === assetPart.id
        );

        // Main breaker starts with "-" position and don't return mainbreaker
        if (!positions.length || positions[0][0].startsWith("-")) {
          return null;
        }

        if (isElectricalPanel) {
          key = positions
            .map((position) => {
              const [r, c] = position[0].split("-");
              return breakerStartingNumber + +r * 2 + +c;
            })
            .join(", ");
        } else {
          const sortedPositions = sortBy(positions, [(p) => p[0]]);
          key = `${sortedPositions[0][0].split("-").reverse().join("-")}`;
        }
      }

      return {
        label: `${key ? `#${key} - ` : ""}${assetPart.name}`,
        query: assetPart.name,
        value: assetPart.id,
        assetPart: sendAsetPart ? assetPart : undefined,
      };
    })
    .filter((ap) => !!ap) as AssetPartOption[];

  const sortedParts = parts.sort((a, b) =>
    sortCollator.compare(a.label, b.label)
  );
  return isSpecial && !isElectricalPanel
    ? sortedParts.map((sp) => ({ ...sp, label: sp.label.split(" - ")[1] }))
    : sortedParts;
};
