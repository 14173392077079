import "react-app-polyfill/stable";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { polyfill } from "mobile-drag-drop";
import ReactDOM from "react-dom";

import App from "./app";
import { register } from "./serviceWorkerRegistration";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_COMMIT_ID,
    integrations: [new Integrations.BrowserTracing()],
  });
}

// mobile-drag-drop polyfill
polyfill({
  holdToDrag: 100,
});

ReactDOM.render(<App />, document.getElementById("root"));

register();
