export const PAGE_SIZE = 200;
export const DEFAULT_BREAKER_ROWS = 21;
export const DEFAULT_BREAKER_STARTING_NUMBER = 1;
export const DEFAULT_MAIN_BREAKER_OR_LUG = false;
export const DEFAULT_MAIN_BREAKER_OR_LUG_POLES = 1;
export const DEFAULT_MAIN_BREAKER_OR_LUG_POSITION = "top";

// TODO:
// move this as a DB field
// possible a default config field that has this this and all the above defaults
export const getDefaultBreakerColumns = (breakerName: string) => {
  if (breakerName === "Battery Inverter") return 1;
  return 2;
};
