import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Unlimited = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    viewBox="0 0 24 24"
    boxSize="40px"
    color="primary.500"
    {...props}
  >
    <circle cx="12" cy="12" r="12" />
    <path
      d="M16.2931 16.1998c2.2646 0 4.107-1.8841 4.107-4.2 0-2.31587-1.8424-4.2-4.107-4.2-.9651 0-2.1199.57783-3.5304 1.76648-.0069.00581-.0136.01158-.0204.01739l1.7019 1.74853c1.3016-1.0647 1.8265-1.0776 1.8489-1.0776.941 0 1.7065.7829 1.7065 1.7452s-.7655 1.7451-1.7065 1.7451c-.0234 0-.5888-.0137-2.0038-1.2061-.1636-.1378-2.8193-2.77655-3.0518-2.97252C9.82699 8.37763 8.67219 7.7998 7.70714 7.7998c-2.26462 0-4.10704 1.88413-4.10704 4.2 0 2.3159 1.84242 4.2 4.10701 4.2.96505 0 2.11985-.5778 3.53039-1.7665.0064-.0054.0126-.0108.019-.0162l-1.6966-1.753c-1.30413 1.0677-1.83028 1.0808-1.85279 1.0808-.94097 0-1.70652-.7828-1.70652-1.7451s.76552-1.7451 1.70652-1.7451c.02316 0 .64834-.0626 1.98538 1.1906.68211.6393 2.90031 2.8545 3.07021 2.988 1.4494 1.1387 2.5653 1.7665 3.5304 1.7665z"
      fill="#fff"
    />
  </Icon>
);

export default Unlimited;
