import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useAssetCategoryQuery } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface AssetCategoryEditPageProps {}

const AssetCategoryEditPage: React.FC<AssetCategoryEditPageProps> = () => {
  const { assetCategoryId } = useParams();
  const { data } = useAssetCategoryQuery({
    variables: { id: assetCategoryId },
  });
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetCategories"));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <DrawerOverlay>
        <DrawerContent>
          {data ? (
            <Presenter
              assetCategory={data.assetCategory}
              onDrawerClose={onDrawerClose}
            />
          ) : (
            <DrawerBody>
              <PageSpinner />
            </DrawerBody>
          )}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetCategoryEditPage;

gql`
  query AssetCategory($id: UUID!) {
    assetCategory(id: $id) {
      ...AssetCategoryFragment
    }
  }
`;
