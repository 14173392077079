import "moment-timezone";

import moment from "moment";
import { MomentInput } from "moment/moment";

// returns date string in UTC, set to end of the day, according to second date string in UTC
export const getDateEndWithOffset = (date: string, offset?: number | null) => {
  let endDateMoment = moment.utc(date);
  endDateMoment = endDateMoment.subtract(offset || 0, "minutes");
  return endDateMoment
    .hours(23)
    .minutes(59)
    .add(offset || 0, "minutes")
    .format()
    .slice(0, -1);
};

export const getUtcDateString = (date?: MomentInput): string | null => {
  if (!date) return null;
  return moment.utc(date).format().slice(0, -1);
};

export const getUtcOffsetFromTimezoneName = (
  timezone?: string | null
): number => {
  if (!timezone) return 0;
  const timezoneObj = moment.tz.zone(timezone);
  return timezoneObj ? timezoneObj.utcOffset(moment.utc().valueOf()) : 0;
};

export const getUtcDateStringSwitchedBetweenTimezones = (
  date?: number | null,
  oldTimezone?: string | null,
  newTimezone?: string | null
): number | null => {
  if (!date) return null;
  if (oldTimezone === newTimezone) return date;
  if (oldTimezone && newTimezone) {
    const oldOffset: number = getUtcOffsetFromTimezoneName(oldTimezone);
    const newOffset: number = getUtcOffsetFromTimezoneName(newTimezone);
    const offsetDiff: number = oldOffset - newOffset;
    return moment.utc(date).subtract(offsetDiff, "minutes").valueOf();
  } else {
    return date;
  }
};

export const isDateValid = (
  date: MomentInput,
  minDate?: MomentInput,
  maxDate?: MomentInput
): boolean => {
  if (!date) return false;
  let isMinValid = true;
  let isMaxValid = true;
  if (minDate) {
    isMinValid = moment.utc(date).valueOf() > moment.utc(minDate).valueOf();
  }
  if (maxDate) {
    isMaxValid = moment.utc(date).valueOf() < moment.utc(maxDate).valueOf();
  }
  return isMinValid && isMaxValid;
};
