import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { sortBy } from "lodash";
import pluralize from "pluralize";
import QRCode from "qrcode.react";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";

import Logo from "../../../../components/icons/logo";
import { defaultDateFormat } from "../../../../constants/date";
import {
  AssetWithRelationsFragmentFragment,
  FieldType,
  useCurrentCompanyQuery,
} from "../../../../graphql/graphql";
import useAssetLocations from "../../../../hooks/useAssetLocations";
import { getRoutePath } from "../../../../router";
import LocationBreadCrumb from "../../../locations/show/breadCrumb";
import AssetPanel from "./panel";

interface PrintPanelProps {
  asset: AssetWithRelationsFragmentFragment;
}

const PrintPanel: React.FC<PrintPanelProps> = ({ asset }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Tooltip label="Print panel schedule" hasArrow lineHeight="1">
        <Button
          variant="icon"
          colorScheme="gray"
          aria-label="Print panel schedule"
          onClick={onOpen}
        >
          <FontAwesomeIcon icon={faPrint} />
        </Button>
      </Tooltip>
      {isOpen && (
        <Modal isOpen onClose={onClose} size="4xl">
          <ModalOverlay>
            <ModalContent>
              <ModalCloseButton color="white" top="-8" right="-3" />
              <ModalBody>
                <PanelPrintContent asset={asset} onClose={onClose} />
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
    </Box>
  );
};

interface PanelPrintContentProps {
  asset: AssetWithRelationsFragmentFragment;
  onClose?: () => void;
  autoPrintOnLoad?: boolean;
}

export const PanelPrintContent: React.FC<PanelPrintContentProps> = ({
  asset,
  onClose,
  autoPrintOnLoad = true,
}) => {
  const printRef = useRef(null);
  const { data } = useCurrentCompanyQuery();
  const locations = useAssetLocations(asset);

  const fields = sortBy(asset.assetType.assetFields, "order");

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${asset.name} Panel Schedule`,
    onAfterPrint: () => onClose && onClose(),
    onPrintError: () => onClose && onClose(),
  });

  useEffect(() => {
    if (autoPrintOnLoad && handlePrint) setTimeout(() => handlePrint(), 1000);
  }, [autoPrintOnLoad, handlePrint]);

  return (
    <Box padding="2" ref={printRef}>
      <Flex flexDirection="row">
        <Box flexGrow={1} marginRight="2">
          <Heading as="h1" size="2xl">
            {asset.name}
          </Heading>
          {!!locations && locations.length > 0 && (
            <Box marginTop="2">
              <Box fontWeight="semibold">
                {pluralize("Location", locations.length)}
              </Box>
              <Box>
                {locations.map((location) => (
                  <LocationBreadCrumb
                    locationId={location.id}
                    key={location.id}
                  />
                ))}
              </Box>
            </Box>
          )}
          <Box marginTop="2">
            <SimpleGrid columns={{ base: 2, md: 4 }} spacing={3}>
              {fields.map((af) => {
                const assetFieldValue = asset.assetFieldValues.find(
                  (afv) => afv.assetFieldId === af.id
                );
                return assetFieldValue?.value != null ? (
                  <Box key={af.id}>
                    <Text fontWeight="semibold">{af.label}</Text>
                    <Text>
                      {af.type !== FieldType.Boolean ? (
                        <Flex alignItems="center" as="span">
                          {assetFieldValue.value}
                          {!!af.unit && (
                            <Text as="span" color="gray.600" ml="1">
                              {af.unit}
                            </Text>
                          )}
                        </Flex>
                      ) : assetFieldValue && assetFieldValue.value ? (
                        "Yes"
                      ) : (
                        "No"
                      )}
                    </Text>
                  </Box>
                ) : null;
              })}
            </SimpleGrid>
          </Box>
        </Box>
        <Box>
          <QRCode
            value={`${process.env.REACT_APP_WEB_APP_URL}${getRoutePath(
              "assetsShow",
              { assetId: asset.id }
            )}?companyId=${data?.currentCompany.id}`}
            size={120}
            imageSettings={{
              src: "/icon.png",
              height: 24,
              width: 24,
              excavate: true,
            }}
            renderAs="canvas"
          />
        </Box>
      </Flex>
      <Box marginTop="2">
        <AssetPanel
          asset={asset}
          canEditAsset={false}
          showHeader={false}
          canAddAsset={false}
        />
      </Box>
      <Flex alignItems="center" paddingY="4">
        <Box>Printed: {format(new Date(), defaultDateFormat)}</Box>
        <Spacer />
        <Logo />
      </Flex>
    </Box>
  );
};

export default PrintPanel;
