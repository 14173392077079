import { Icon, IconProps } from "@chakra-ui/react";

const Defibrillator = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M64 72.661H40.935a2.871 2.871 0 0 0-2.87 2.871v11.532a2.871 2.871 0 0 0 2.87 2.872H64a2.871 2.871 0 0 0 2.871-2.872V75.532A2.871 2.871 0 0 0 64 72.662Zm-2.871 11.532H43.807v-5.79h17.322v5.79ZM40.935 95.726c-1.501 0-2.94 1.32-2.87 2.87.07 1.556 1.261 2.872 2.87 2.872 1.502 0 2.941-1.321 2.872-2.871-.07-1.556-1.262-2.871-2.872-2.871ZM52.468 95.726c-1.502 0-2.94 1.32-2.871 2.87.07 1.556 1.261 2.872 2.87 2.872 1.502 0 2.941-1.321 2.872-2.871-.07-1.556-1.262-2.871-2.871-2.871ZM64 95.726c-1.502 0-2.94 1.32-2.871 2.87.07 1.556 1.261 2.872 2.871 2.872 1.502 0 2.94-1.321 2.871-2.871-.07-1.556-1.261-2.871-2.871-2.871ZM87.064 72.661H75.532a2.871 2.871 0 0 0 0 5.742h11.532a2.871 2.871 0 0 0 0-5.742ZM87.064 84.193H75.532a2.871 2.871 0 0 0 0 5.743h11.532a2.871 2.871 0 0 0 0-5.743ZM87.064 95.726H75.532a2.871 2.871 0 0 0 0 5.742h11.532a2.871 2.871 0 0 0 0-5.742Z"
      fill="currentColor"
    />
    <path
      d="M98.597 32.298h-8.362V23.25c0-4.549-3.701-8.25-8.25-8.25h-6.752a2.871 2.871 0 0 0-2.87 2.871v34.597a2.871 2.871 0 0 0 2.87 2.87h6.751c4.55 0 8.25-3.7 8.25-8.25v-9.047h8.363c4.776 0 8.661 3.885 8.661 8.66v40.363c0 3.77-2.421 6.983-5.79 8.17V69.871c0-4.82-3.922-8.742-8.743-8.742h-57.45c-4.82 0-8.743 3.922-8.743 8.742v25.363c-3.368-1.187-5.79-4.4-5.79-8.17V46.702c0-4.776 3.886-8.661 8.661-8.661h8.363v9.048c0 4.549 3.7 8.25 8.25 8.25h6.75a2.871 2.871 0 0 0 2.872-2.871V17.87A2.871 2.871 0 0 0 52.767 15h-6.751c-4.55 0-8.25 3.701-8.25 8.25v9.048h-8.363C21.461 32.298 15 38.76 15 46.702v40.362c0 6.96 4.961 12.781 11.532 14.116v3.078c0 4.82 3.922 8.742 8.743 8.742h57.45c4.821 0 8.743-3.922 8.743-8.742v-3.078C108.039 99.845 113 94.024 113 87.064V46.702c0-7.942-6.461-14.404-14.403-14.404Zm-14.104 14.79a2.51 2.51 0 0 1-2.509 2.509h-3.88V20.742h3.88a2.51 2.51 0 0 1 2.509 2.508V47.09ZM43.508 23.25a2.51 2.51 0 0 1 2.508-2.508h3.88v28.855h-3.88a2.51 2.51 0 0 1-2.508-2.508V23.25Zm52.218 81.008c0 1.654-1.346 3-3 3H35.274c-1.655 0-3-1.346-3-3V69.871c0-1.654 1.345-3 3-3h57.45c1.655 0 3 1.346 3 3v34.387Z"
      fill="currentColor"
    />
  </Icon>
);

export default Defibrillator;
