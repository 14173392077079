import {
  WorkOrderAssetInput,
  WorkOrderAssetPlanInput,
  WorkOrderInput,
} from "../../../graphql/types/workOrdersMutations";
import {
  WorkOrder,
  WorkOrderAsset,
  WorkOrderAssetPlan,
} from "../models/workOrder";

export const workOrderInputMapper = (workOrder: WorkOrder): WorkOrderInput => {
  return {
    id: workOrder.id,
    name: workOrder.name,
    description: workOrder.description,
    companyId: workOrder.companyId,
    locationId: workOrder.locationId,
    companyName: workOrder.companyName,
    locationName: workOrder.locationName,
    requestedById: workOrder.requestedById,
    companyContactEmail: workOrder.companyContactEmail,
    companyContactOccupation: workOrder.companyContactOccupation,
    companyContactFullName: workOrder.companyContactFullName,
    companyContactType: workOrder.companyContactType,
    companyContactTeamName: workOrder.companyContactTeamName,
    companyContactTeamId: workOrder.companyContactTeamId,
    companyContactMobilePhone: workOrder.companyContactMobilePhone,
    companyContactWorkPhone: workOrder.companyContactWorkPhone,
    companyContactUserId: workOrder.companyContactUserId,
    locationContactEmail: workOrder.locationContactEmail,
    locationContactOccupation: workOrder.locationContactOccupation,
    locationContactFullName: workOrder.locationContactFullName,
    locationContactType: workOrder.locationContactType,
    locationContactTeamName: workOrder.locationContactTeamName,
    locationContactTeamId: workOrder.locationContactTeamId,
    locationContactMobilePhone: workOrder.locationContactMobilePhone,
    locationContactWorkPhone: workOrder.locationContactWorkPhone,
    locationContactUserId: workOrder.locationContactUserId,
    serviceCategoryId: workOrder.serviceCategoryId,
    businessSeverity: workOrder.businessSeverity,
    orderPriority: workOrder.orderPriority,
    parentOrderId: workOrder.parentOrderId,
    scheduleType: workOrder.scheduleType,
    startDateTime: workOrder.startDateTime,
    endDateTime: workOrder.endDateTime,
    serviceScheduleTimezone: workOrder.serviceScheduleTimezone,
    orderTypeId: workOrder.orderTypeId,
    accountableUserId: workOrder.accountableUserId,
    consultingUserIds: workOrder.consultingUserIds,
    informingUserIds: workOrder.informingUserIds,
    responsibleUserIds: workOrder.responsibleUserIds,
    workOrderStageId: workOrder.workOrderStageId,
    transitionReasonId: workOrder.transitionReasonId,
    assetWorkOrdersInput: workOrder.assetWorkOrders?.map(
      workOrderAssetInputMapper
    ),
  };
};

const workOrderAssetInputMapper = (
  asset: WorkOrderAsset
): WorkOrderAssetInput => {
  return {
    id: asset.id,
    comment: asset.comment,
    assetId: asset.assetId,
    serviceTypeId: asset.serviceTypeId,
    sublocationId: asset.sublocationId,
    plansInput: asset?.plans?.map(workOrderAssetPlanInputMapper),
  };
};

const workOrderAssetPlanInputMapper = (
  plan: WorkOrderAssetPlan
): WorkOrderAssetPlanInput => {
  return {
    comment: plan.comment,
    itemId: plan.itemId,
    planId: plan.planId,
    type: plan.type,
    name: plan.name,
    sublocation: plan.sublocation,
    description: plan.description,
  };
};
