import { Center } from "@chakra-ui/react";
import { ICellEditorParams } from "ag-grid-community";
import { memo, useCallback } from "react";

const AgGridCheckboxRenderer = memo((props: ICellEditorParams) => {
  const checkedHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const colId = props.column.getColId();
      props.node.setDataValue(colId, checked);
    },
    [props.column, props.node]
  );

  return (
    <Center height="100%">
      <input
        type="checkbox"
        onChange={checkedHandler}
        checked={
          typeof props.value === "boolean"
            ? props.value
            : props.value === "true"
        }
        style={{ pointerEvents: props.data?.isDuplicate ? "none" : "unset" }}
      />
    </Center>
  );
});

export default AgGridCheckboxRenderer;
