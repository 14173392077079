import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import FileSelectInput from "../../../components/elements/fileSelectInput";
import FormGroup from "../../../components/elements/formGroup";
import { AssetFileType } from "../../../graphql/graphql";
import {
  descriptionSchema,
  fileIdsSchema,
  fileIdsUploadingSchema,
  nameSchema,
  yupObject,
} from "../../../utils/validation";

interface AssetFileCreatePresenterProps {
  folderId?: string;
  handleSubmit: (
    values: AssetFileFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  loading: boolean;
  isOpen: boolean;
  onDrawerClose: () => void;
  acceptFile: string;
  type: AssetFileType;
}

const AssetFileCreatePresenter: React.FC<AssetFileCreatePresenterProps> = ({
  handleSubmit,
  loading,
  isOpen,
  onDrawerClose,
  acceptFile,
  type,
}) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>
          {type === AssetFileType.Image ? "Add New Picture" : "Upload Document"}
        </title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {type === AssetFileType.Image
              ? "Add New Picture"
              : "Upload Document"}
          </DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{
                name: "",
                description: "",
                fileIds: [] as string[],
                fileIdsUploading: false,
              }}
              validationSchema={assetFileFormValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ getFieldProps }) => (
                <Form id="floor_plan_create" noValidate>
                  <Box marginTop="-4">
                    <FileSelectInput name="fileIds" acceptFile={acceptFile} />
                  </Box>
                  <FormGroup
                    label={`${
                      type === AssetFileType.Image ? "Picture" : "Document"
                    } Name`}
                    name="name"
                  >
                    <Input
                      autoFocus
                      autoComplete="off"
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  <FormGroup label="Description" name="description">
                    <Textarea
                      autoComplete="off"
                      {...getFieldProps("description")}
                    />
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </DrawerBody>
          <DrawerFooter>
            <Button
              width="full"
              type="submit"
              isLoading={loading}
              form="floor_plan_create"
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetFileCreatePresenter;

const assetFileFormValidationSchema = yupObject().shape({
  name: nameSchema.notRequired(),
  description: descriptionSchema,
  fileIds: fileIdsSchema.min(1, "Please add file to continue"),
  fileIdsUploading: fileIdsUploadingSchema,
});

export type AssetFileFormData = {
  name?: string;
  description: string;
  fileIds: string[];
  fileIdsUploading: boolean;
};
