import { Icon, IconProps } from "@chakra-ui/react";

const Toilet = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M91.216 82.356H36.918a7.092 7.092 0 0 1-5.003-2.074 7.07 7.07 0 0 1-2.075-4.996V72.91a7.07 7.07 0 0 1 2.076-4.995 7.092 7.092 0 0 1 5.003-2.072h54.297a7.092 7.092 0 0 1 5.002 2.073 7.07 7.07 0 0 1 2.076 4.994v2.375a7.07 7.07 0 0 1-2.075 4.996 7.091 7.091 0 0 1-5.003 2.074ZM36.918 68.7a4.225 4.225 0 0 0-2.98 1.235A4.213 4.213 0 0 0 32.7 72.91v2.375a4.212 4.212 0 0 0 1.237 2.975 4.225 4.225 0 0 0 2.98 1.235h54.299a4.225 4.225 0 0 0 2.98-1.235 4.212 4.212 0 0 0 1.236-2.975V72.91a4.213 4.213 0 0 0-1.236-2.975 4.225 4.225 0 0 0-2.98-1.235H36.918Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M31.032 73.901a8.468 8.468 0 0 1-5.005-2.903 8.445 8.445 0 0 1-1.967-5.435V28.357a8.481 8.481 0 0 1 2.49-5.993 8.507 8.507 0 0 1 6.001-2.486h16.31v2.858h-16.31a5.64 5.64 0 0 0-3.979 1.648 5.623 5.623 0 0 0-1.65 3.973v37.206a5.593 5.593 0 0 0 1.3 3.6 5.609 5.609 0 0 0 3.312 1.925l-.502 2.813ZM97.102 73.901l-.504-2.813a5.601 5.601 0 0 0 3.307-1.927 5.585 5.585 0 0 0 1.293-3.598V28.357a5.616 5.616 0 0 0-1.644-3.97 5.634 5.634 0 0 0-3.973-1.651h-16.31v-2.858h16.31c2.25.005 4.406.9 5.995 2.49a8.475 8.475 0 0 1 2.484 5.989v37.206a8.441 8.441 0 0 1-1.96 5.433 8.46 8.46 0 0 1-4.998 2.905Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M42.614 68.064a37.35 37.35 0 0 1-6.225-20.921C36.389 28.87 48.8 14 64.06 14s27.688 14.87 27.688 33.143a37.357 37.357 0 0 1-6.227 20.921l-2.363-1.61a34.476 34.476 0 0 0 5.723-19.307c.006-16.701-11.134-30.29-24.82-30.29-13.687 0-24.811 13.589-24.811 30.286a34.471 34.471 0 0 0 5.723 19.307l-2.36 1.614Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M70.785 68.514 69.419 66c5.733-3.12 9.586-10.699 9.586-18.857 0-11-6.845-20.286-14.947-20.286-8.103 0-14.924 9.286-14.924 20.286 0 8.158 3.851 15.738 9.585 18.857l-1.369 2.508c-6.624-3.605-11.077-12.192-11.077-21.367C46.273 34.6 54.417 24 64.06 24c9.642 0 17.809 10.595 17.809 23.142-.003 9.18-4.454 17.768-11.083 21.373ZM84.966 114H43.172a1.917 1.917 0 0 1-1.742-2.695c.174-.389.473-.709.85-.909l7.65-4.035a1.187 1.187 0 0 0 .45-1.677 1.193 1.193 0 0 0-.472-.434l-2.444-1.24a20.128 20.128 0 0 1-8.1-7.441 20.087 20.087 0 0 1-2.974-10.582V79.5h55.354v5.488a20.086 20.086 0 0 1-2.972 10.582 20.126 20.126 0 0 1-8.1 7.441l-2.444 1.24a1.194 1.194 0 0 0-.467 1.671c.113.186.274.338.467.439l7.634 4.034a1.916 1.916 0 0 1 .963 2.152A1.917 1.917 0 0 1 84.966 114Zm-37.981-2.857h34.17l-4.27-2.256a4.05 4.05 0 0 1-2.161-3.603 4.042 4.042 0 0 1 2.202-3.578l2.45-1.243a17.284 17.284 0 0 0 6.956-6.39 17.247 17.247 0 0 0 2.552-9.086v-2.631H39.25v2.631a17.247 17.247 0 0 0 2.55 9.085 17.285 17.285 0 0 0 6.95 6.391l2.447 1.24a4.049 4.049 0 0 1 1.644 5.678 4.054 4.054 0 0 1-1.582 1.505l-4.275 2.257Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
);

export default Toilet;
