import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import { useCompanyQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface CompanySettingsPageProps {}

const CompanySettingsPage: React.FC<CompanySettingsPageProps> = ({
  children,
}) => {
  const { data: currentCompanyData } = useCompanyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    layoutOptionsVar({
      title: "Company Settings",
      hideSearch: true,
      styleProps: { maxWidth: "1100px" },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Company Settings</title>
      </Helmet>
      {currentCompanyData ? (
        <Presenter currentCompany={currentCompanyData.company} />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default CompanySettingsPage;
