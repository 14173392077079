import "react-leaflet-markercluster/dist/styles.min.css";

import { useReactiveVar } from "@apollo/client";
import React, { useRef } from "react";
import { Marker, Popup } from "react-leaflet";
import { Tooltip } from "react-leaflet";

import { getCustomIconForAsset } from "../../../../components/elements/customLeafletIcons";
import {
  FloorPlanAssetForMapFragmentFragment,
  FloorPlanForMapFragmentFragment,
} from "../../../../graphql/graphql";
import { planEditorOptionsVar } from "../../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../../router";

interface MarkersControlProps {
  floorPlan: FloorPlanForMapFragmentFragment;
  floorPlanAssets?: FloorPlanAssetForMapFragmentFragment[];
  showAssetStatus: boolean;
}

const PlanMarkersControl: React.FC<MarkersControlProps> = ({
  floorPlanAssets,
  showAssetStatus,
}) => {
  return (
    <>
      {floorPlanAssets ? (
        <>
          {floorPlanAssets.map((floorPlanAsset) =>
            floorPlanAsset.mapPosition ? (
              <MarkerControl
                key={floorPlanAsset.id}
                floorPlanAsset={floorPlanAsset}
                showAssetStatus={showAssetStatus}
              />
            ) : null
          )}
        </>
      ) : null}
    </>
  );
};

export default PlanMarkersControl;

interface MarkerControlProps {
  floorPlanAsset: FloorPlanAssetForMapFragmentFragment;
  showAssetStatus: boolean;
}

const MarkerControl: React.FC<MarkerControlProps> = ({
  floorPlanAsset,
  showAssetStatus,
}) => {
  const coordinates = floorPlanAsset.mapPosition.geometry.coordinates;
  const markerRef = useRef<any>(null);
  const { assetIds } = useReactiveVar(planEditorOptionsVar);

  const assetType = floorPlanAsset.asset.assetType;

  // const handleClick = () => {
  //   window.open(url, '_blank');
  // };

  const icon = React.useMemo(
    () =>
      getCustomIconForAsset({
        iconColor: assetType.misc.resolvedIconColor,
        iconName: assetType.iconName,
        iconType: assetType.iconType,
        iconStatus: showAssetStatus
          ? floorPlanAsset.asset.maintenanceStatus
          : undefined,
        iconSize: "sm",
      }),
    [
      assetIds.length,
      assetType.iconName,
      assetType.iconType,
      assetType.misc.resolvedIconColor,
      floorPlanAsset.asset.maintenanceStatus,
      showAssetStatus,
    ]
  );

  return coordinates ? (
    <>
      <Marker
        key={floorPlanAsset.id}
        position={[coordinates[1], coordinates[0]]}
        ref={markerRef}
        icon={icon}
        eventHandlers={{
          click: () => {
            window.open(
              getRoutePath("assetsShow", {
                assetId: floorPlanAsset?.asset?.id,
              }),
              "_blank"
            );
          },
        }}
      >
        <Tooltip>{floorPlanAsset.asset.name}</Tooltip>
      </Marker>
    </>
  ) : null;
};
