import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import { useSessionQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  const { data } = useSessionQuery();

  useEffect(() => {
    layoutOptionsVar({ styleProps: { maxWidth: "full" } });
  }, []);

  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      {data ? (
        <Presenter isLoggedIn={data.session.isLoggedIn} />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default NotFoundPage;
