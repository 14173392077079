import React, { useEffect } from "react";

import { useUserQuery } from "../../../../../graphql/graphql";
import { CompanyContactTypeEnum, WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateLocationContactInfoPresenter from "./presenter";

interface WorkOrderCreateLocationContactInfoProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateLocationContactInfo: React.FC<
  WorkOrderCreateLocationContactInfoProps
> = ({ workOrder, onWorkOrderChange }) => {
  const { data: currentUser } = useUserQuery();

  const handleContactTypeChange = (newType: CompanyContactTypeEnum) => {
    if (newType === CompanyContactTypeEnum.SAME_AS_COMPANY) {
      onWorkOrderChange({
        locationContactType: CompanyContactTypeEnum.SAME_AS_COMPANY,
        locationContactUserId: workOrder.companyContactUserId,
        locationContactEmail: workOrder.companyContactEmail,
        locationContactOccupation: workOrder.companyContactOccupation,
        locationContactFullName: workOrder.companyContactFullName,
        locationContactTeamId: workOrder.companyContactTeamId,
        locationContactTeamName: workOrder.companyContactTeamName,
        locationContactMobilePhone: workOrder.companyContactMobilePhone,
        locationContactWorkPhone: workOrder.companyContactWorkPhone,
      });
    }
    if (newType === CompanyContactTypeEnum.CURRENT_USER && currentUser?.user) {
      onWorkOrderChange({
        locationContactType: CompanyContactTypeEnum.CURRENT_USER,
        locationContactUserId: currentUser.user.id,
        locationContactEmail: currentUser.user.email,
        locationContactOccupation: null,
        locationContactFullName: currentUser.user.fullName,
        locationContactTeamId: null,
        locationContactTeamName: null,
        locationContactMobilePhone: currentUser.user.phone,
        locationContactWorkPhone: null,
      });
    }
    if (newType === CompanyContactTypeEnum.ADD_MANUALLY) {
      onWorkOrderChange({
        locationContactType: CompanyContactTypeEnum.ADD_MANUALLY,
        locationContactUserId: null,
        locationContactEmail: null,
        locationContactOccupation: null,
        locationContactFullName: null,
        locationContactTeamId: null,
        locationContactTeamName: null,
        locationContactMobilePhone: null,
        locationContactWorkPhone: null,
      });
    }
    if (newType === CompanyContactTypeEnum.USER_SEARCH) {
      onWorkOrderChange({
        locationContactType: CompanyContactTypeEnum.USER_SEARCH,
        locationContactUserId: null,
        locationContactEmail: null,
        locationContactOccupation: null,
        locationContactFullName: null,
        locationContactTeamId: null,
        locationContactTeamName: null,
        locationContactMobilePhone: null,
        locationContactWorkPhone: null,
      });
    }
  };

  useEffect(() => {
    if (
      workOrder.locationContactType ===
        CompanyContactTypeEnum.SAME_AS_COMPANY &&
      (workOrder.locationContactUserId !== workOrder.companyContactUserId ||
        workOrder.locationContactEmail !== workOrder.companyContactEmail ||
        workOrder.locationContactOccupation !==
          workOrder.companyContactOccupation ||
        workOrder.locationContactFullName !==
          workOrder.companyContactFullName ||
        workOrder.locationContactTeamId !== workOrder.companyContactTeamId ||
        workOrder.locationContactTeamName !==
          workOrder.companyContactTeamName ||
        workOrder.locationContactMobilePhone !==
          workOrder.companyContactMobilePhone ||
        workOrder.locationContactWorkPhone !==
          workOrder.companyContactWorkPhone)
    ) {
      handleContactTypeChange(CompanyContactTypeEnum.SAME_AS_COMPANY);
    }
  }, [handleContactTypeChange, workOrder]);

  return (
    <>
      <WorkOrderCreateLocationContactInfoPresenter
        workOrder={workOrder}
        onWorkOrderChange={onWorkOrderChange}
        onContactInfoChange={handleContactTypeChange}
      />
    </>
  );
};

export default WorkOrderCreateLocationContactInfo;
