import React from "react";
import {
  Bar,
  BarChart,
  BarProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import defaultTheme from "../../../chakraTheme";
import { COLORS } from "../../../utils/chart";

interface ChartBarProps {
  data: BarProps["data"];
  handleClickCurrent?: () => void;
  handleClickDueIn30?: () => void;
  handleClickPastDue?: () => void;
}

const ChartBar: React.FC<ChartBarProps> = ({
  data,
  handleClickCurrent,
  handleClickDueIn30,
  handleClickPastDue,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <XAxis dataKey="name" fontSize=".65rem" />
        <YAxis fontSize=".65rem" />
        <Tooltip
          contentStyle={{ padding: "5px" }}
          itemStyle={{
            fontSize: ".6rem",
            lineHeight: ".6rem",
            color: defaultTheme.colors.gray["800"],
          }}
          separator=""
          formatter={(value: number, label: string) =>
            label === "No data" ? "" : `: ${value}`
          }
        />
        <Bar
          stackId="dashboardBarId"
          dataKey="Current"
          fill={COLORS[0]}
          onClick={handleClickCurrent}
        />
        <Bar
          stackId="dashboardBarId"
          dataKey="Due within 30 days"
          fill={COLORS[1]}
          onClick={handleClickDueIn30}
        />
        <Bar
          stackId="dashboardBarId"
          dataKey="Past Due"
          fill={COLORS[2]}
          onClick={handleClickPastDue}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartBar;
