import { InputMaybe, Scalars } from "../../../graphql/graphql";
import {
  WOCompany,
  WOGetAssetServiceTypes,
  WOLocation,
} from "../../../graphql/types/queries/workOrdersQuery";
import {
  AssetPlansTypeEnum,
  BusinessSeverityEnum,
  OrderPriorityEnum,
  ScheduleTypeEnum,
} from "./enums";

export enum CompanyContactTypeEnum {
  LEAVE_BLANK = "LEAVE_BLANK",
  CURRENT_USER = "CURRENT_USER",
  ADD_MANUALLY = "ADD_MANUALLY",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  SAME_AS_COMPANY = "SAME_AS_COMPANY",
  USER_SEARCH = "USER_SEARCH",
}

export interface BaseEntityType {
  id: string;
  name: string;
}

export type WorkOrder = {
  id?: InputMaybe<Scalars["UUID"] | null>;
  createdAt?: InputMaybe<Scalars["String"] | null>;
  name?: InputMaybe<Scalars["String"] | null>;
  key?: InputMaybe<Scalars["String"] | null>;
  description?: InputMaybe<Scalars["String"] | null>;
  companyId?: InputMaybe<Scalars["UUID"] | null>;
  locationId?: InputMaybe<Scalars["UUID"] | null>;
  companyName?: InputMaybe<Scalars["String"] | null>;
  locationName?: InputMaybe<Scalars["String"] | null>;
  floorPlanId?: InputMaybe<Scalars["UUID"] | null>;
  assetId?: InputMaybe<Scalars["UUID"] | null>;
  requestedById?: InputMaybe<Scalars["UUID"] | null>;
  companyContactType?: InputMaybe<CompanyContactTypeEnum>;
  companyContactEmail?: InputMaybe<Scalars["String"] | null>;
  companyContactOccupation?: InputMaybe<Scalars["String"] | null>;
  companyContactFullName?: InputMaybe<Scalars["String"] | null>;
  companyContactTeamName?: InputMaybe<Scalars["String"] | null>;
  companyContactTeamId?: InputMaybe<Scalars["UUID"] | null>;
  companyContactMobilePhone?: InputMaybe<Scalars["String"] | null>;
  companyContactWorkPhone?: InputMaybe<Scalars["String"] | null>;
  companyContactUserId?: InputMaybe<Scalars["UUID"] | null>;
  locationContactType?: InputMaybe<CompanyContactTypeEnum>;
  locationContactEmail?: InputMaybe<Scalars["String"] | null>;
  locationContactOccupation?: InputMaybe<Scalars["String"] | null>;
  locationContactFullName?: InputMaybe<Scalars["String"] | null>;
  locationContactTeamName?: InputMaybe<Scalars["String"] | null>;
  locationContactTeamId?: InputMaybe<Scalars["UUID"] | null>;
  locationContactMobilePhone?: InputMaybe<Scalars["String"] | null>;
  locationContactWorkPhone?: InputMaybe<Scalars["String"] | null>;
  locationContactUserId?: InputMaybe<Scalars["UUID"] | null>;
  businessSeverity?: BusinessSeverityEnum | null;
  orderPriority?: OrderPriorityEnum | null;
  serviceCategoryId?: InputMaybe<Scalars["UUID"]> | null;
  serviceCategory?: WorkOrderServiceCategory;
  orderTypeId?: InputMaybe<Scalars["UUID"]> | null;
  attachments?: File[];
  parentOrderId?: InputMaybe<Scalars["UUID"] | null>;
  parentOrder?: WorkOrder | null;
  scheduleType?: ScheduleTypeEnum | null;
  startDateTime?: number | null;
  endDateTime?: number | null;
  serviceScheduleTimezone?: string | null;
  accountableUserId?: InputMaybe<Scalars["UUID"]> | null;
  consultingUserIds?: InputMaybe<Scalars["UUID"]>[] | null;
  informingUserIds?: InputMaybe<Scalars["UUID"]>[] | null;
  responsibleUserIds?: InputMaybe<Scalars["UUID"]>[] | null;
  assetWorkOrders?: InputMaybe<WorkOrderAsset>[] | null;
  workOrderStageId?: InputMaybe<Scalars["UUID"]> | null;
  workOrderStage?: WorkOrderStage;
  transitionReasonId?: InputMaybe<Scalars["UUID"]> | null;
  transitionReason?: BaseEntityType;
  company?: WOCompany | null;
  location?: WOLocation | null;
  files?: WorkOrderFile[] | null;
};

export type WorkOrderAsset = {
  id?: InputMaybe<Scalars["UUID"] | null>;
  assetId?: InputMaybe<Scalars["UUID"] | null>;
  sublocationName?: InputMaybe<string | null>;
  sublocationId?: InputMaybe<Scalars["UUID"] | null>;
  serviceTypeId?: InputMaybe<Scalars["UUID"] | null>;
  serviceType?: InputMaybe<WOGetAssetServiceTypes | null>;
  comment?: string | null;
  floorPlansIds?: InputMaybe<Scalars["UUID"]>[] | null;
  affectedAreasIds?: InputMaybe<Scalars["UUID"]>[] | null;
  plans?: InputMaybe<WorkOrderAssetPlan>[] | null;
  asset?: InputMaybe<WOAsset> | null;
};

export type WorkOrderAssetPlan = {
  itemId?: InputMaybe<Scalars["UUID"] | null>;
  planId?: InputMaybe<Scalars["UUID"] | null>;
  comment?: string | null;
  name?: string | null;
  sublocation?: string | null;
  description?: string | null;
  type?: InputMaybe<AssetPlansTypeEnum | null>;
};

export type WorkOrderServiceCategory = {
  id?: InputMaybe<Scalars["UUID"] | null>;
  name?: string | null;
};

export type WorkOrderFile = {
  id?: InputMaybe<Scalars["UUID"] | null>;
  createdAt?: number | null;
  updatedAt?: number | null;
  path?: string | null;
  extension?: string | null;
  fileSize?: string | null;
  name?: string | null;
  signedUrl?: string | null;
};

export type WorkOrderComment = {
  id?: InputMaybe<Scalars["UUID"] | null>;
  text?: string | null;
  userId?: InputMaybe<Scalars["UUID"] | null>;
  workOrderId?: InputMaybe<Scalars["UUID"] | null>;
  createdAt?: number | null;
};

export interface WOAssetType extends BaseEntityType {
  assetCategory?: BaseEntityType;
}

export interface WOAffectedAsset {
  id?: string;
  asset?: BaseEntityType;
  affectedAsset?: BaseEntityType;
}

export interface WOAsset extends BaseEntityType {
  assetType?: WOAssetType;
  assetCategory?: BaseEntityType;
  fedTo?: BaseEntityType[];
  fedFrom?: BaseEntityType[];
  assetAffectedAssets?: WOAffectedAsset[];
  assetAffectedByAssets?: WOAffectedAsset[];
}

export interface WorkOrderStage extends BaseEntityType {
  color?: string;
}
