import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  List,
  ListItem,
} from "@chakra-ui/react";
import React from "react";

import FileIcon from "../fileIcon";

interface FileProgressProps {
  files: UploadingFile[];
}

export interface UploadingFile {
  name: string;
  path: string;
  progress: number;
  signedUrl: string;
}

const FileProgress: React.FC<FileProgressProps> = ({ files }) => {
  return (
    <List>
      {files.map((file) => (
        <ListItem
          marginBottom="4"
          paddingBottom="4"
          borderBottomWidth="1px"
          key={file.path}
        >
          <Flex alignItems="center">
            <Box marginRight="2" opacity="50">
              <FileIcon
                fileName={file.name}
                filePath={file.path}
                forceIcon
                width={40}
              />
            </Box>
            <Box flexGrow={1} fontSize="sm" color="gray.800">
              {file.name}
            </Box>
            <CircularProgress value={file.progress} color="secondary.500">
              <CircularProgressLabel>{file.progress}%</CircularProgressLabel>
            </CircularProgress>
          </Flex>
        </ListItem>
      ))}
    </List>
  );
};

export default FileProgress;
