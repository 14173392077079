import { Icon, IconProps } from "@chakra-ui/react";

const Jbox2 = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="m55.12 81.44.6-6c.907.32 2.187.48 3.84.48.853 0 1.48-.293 1.88-.88.427-.587.64-1.36.64-2.32V45.44h6.76v27.28c0 2.8-.76 5.08-2.28 6.84-1.493 1.76-3.827 2.64-7 2.64-1.467 0-2.947-.253-4.44-.76Z"
      fill="currentColor"
    />
    <circle
      cx={64}
      cy={64}
      r={47}
      stroke="currentColor"
      strokeWidth={6}
      fillOpacity={0}
    />
  </Icon>
);

export default Jbox2;
