import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface ResetPasswordPageProps {}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
  useEffect(() => {
    layoutOptionsVar({ title: "Reset Your Password" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Reset Your Password</title>
      </Helmet>
      <Presenter />
    </>
  );
};

export default ResetPasswordPage;
