import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";

import { useUserQuery } from "../../../graphql/graphql";
import PageSpinner from "../pageSpinner";

interface CalendlyProps {}

const Calendly: React.FC<CalendlyProps> = ({ ...props }) => {
  const { data } = useUserQuery();

  const initializeCalendly = useCallback((name = "", email = "") => {
    const calendly = (window as any).Calendly;
    if (calendly) {
      const calendlyWrapper = document.getElementById(
        "calendly_support_booking_widget"
      );
      if (!calendlyWrapper) return;
      if (calendlyWrapper) calendlyWrapper.innerHTML = "";
      calendly.initInlineWidget({
        url: "https://calendly.com/criticalasset-tomtylek/30min-1",
        parentElement: calendlyWrapper,
        prefill: { name, email },
      });
    } else {
      setTimeout(() => initializeCalendly(name, email), 500);
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    initializeCalendly(data.user.fullName, data.user.email);
    return () => {
      const calendlyWrapper = document.getElementById(
        "calendly_support_booking_widget"
      );
      if (calendlyWrapper) calendlyWrapper.innerHTML = "";
    };
  }, [data, initializeCalendly]);

  return (
    <Box
      id="calendly_support_booking_widget"
      height="calc(var(--vh, 1vh)*90)"
      {...props}
    >
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
        ></script>
      </Helmet>
      <PageSpinner />
    </Box>
  );
};

export default Calendly;
