import { useReactiveVar } from "@apollo/client";
import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import { useWOGetWorkOrdersQuery } from "../../../graphql/queries/workOrders";
import {
  currentCompanyVar,
  layoutOptionsVar,
} from "../../../graphql/reactiveVariables";
import { WOGetWorkOrdersQuery } from "../../../graphql/types/queries/workOrdersQuery";
import WorkOrdersPresenter, { Pagination, Sorting } from "./presenter";

interface WorkOrdersDashboardPageProps {}

const WorkOrdersDashboardPage: React.FC<WorkOrdersDashboardPageProps> = () => {
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE_INDEX = 0;
  const DEFAULT_SORT = "createdAt";
  const DEFAULT_ORDER = "desc";
  const [searchParams, setSearchParams] = useSearchParams();
  const [workOrdersList, setWorkOrdersList] = useState<WOGetWorkOrdersQuery>();
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [sortingColumns, setSortingColumns] = useState<Sorting[]>([]);
  const currentCompany = useReactiveVar(currentCompanyVar);
  useEffect(() => {
    layoutOptionsVar({
      title: "Work Orders",
    });
  }, []);
  const { data: workOrdersData, loading } = useWOGetWorkOrdersQuery({
    variables: {
      companyId: currentCompany?.id,
      sort: searchParams.get("sort") || DEFAULT_SORT,
      order: (searchParams.get("order") || DEFAULT_ORDER).toUpperCase(),
      skip:
        Number(searchParams.get("pageIndex") || 0) *
        Number(searchParams.get("pageSize") || 0),
      take: Number(searchParams.get("pageSize") || DEFAULT_PAGE_SIZE),
    },
    fetchPolicy: "network-only",
    skip: !currentCompany?.id,
  });

  const handleSortingChange = useCallback(
    (newSorting: Sorting) => {
      if (newSorting) {
        setSearchParams({
          pageIndex: searchParams.get("pageIndex") || `${DEFAULT_PAGE_INDEX}`,
          pageSize: searchParams.get("pageSize") || `${DEFAULT_PAGE_SIZE}`,
          sort: newSorting.id,
          order: newSorting.direction.toUpperCase(),
        });
      }
    },
    [searchParams]
  );

  const handlePaginationChange = useCallback(
    (pagination: Pagination) => {
      if (pagination) {
        setSearchParams({
          pageIndex: `${pagination.pageIndex}`,
          pageSize: `${pagination.pageSize}`,
          sort: searchParams.get("sort") || DEFAULT_SORT,
          order: searchParams.get("order") || DEFAULT_ORDER,
        });
      }
    },
    [searchParams]
  );

  useEffect(() => {
    if (workOrdersData && !loading) {
      setWorkOrdersList(workOrdersData);
    }
  }, [workOrdersList, loading]);

  useEffect(() => {
    const direction =
      searchParams.get("order")?.toLowerCase() === "asc" ? "asc" : "desc";
    setPagination({
      pageIndex: Number(searchParams.get("pageIndex")) || DEFAULT_PAGE_INDEX,
      pageSize: Number(searchParams.get("pageSize")) || DEFAULT_PAGE_SIZE,
    });
    setSortingColumns(
      searchParams.get("sort") && searchParams.get("sort") !== DEFAULT_SORT
        ? [
            {
              id: searchParams.get("sort") || DEFAULT_SORT,
              direction: direction || DEFAULT_ORDER,
            },
          ]
        : []
    );
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Work Orders</title>
      </Helmet>
      {!loading || workOrdersList?.workOrders?.workOrders.length ? (
        <WorkOrdersPresenter
          workOrdersList={workOrdersList}
          pagination={pagination}
          sortingColumns={sortingColumns}
          changeSorting={handleSortingChange}
          changePagination={handlePaginationChange}
        />
      ) : (
        <EuiFlexGroup
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "70vh" }}
        >
          <EuiLoadingSpinner size="xxl" />
        </EuiFlexGroup>
      )}
    </>
  );
};

export default WorkOrdersDashboardPage;
