import { Icon, IconProps } from "@chakra-ui/react";

const Battery = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M110.92 49.127a2.92 2.92 0 0 0 2.921-2.92V34.515a2.92 2.92 0 0 0-2.921-2.92H98.344V22.92a2.92 2.92 0 0 0-2.92-2.92h-18.07a2.92 2.92 0 0 0-2.92 2.92v8.674H53.566V22.92a2.92 2.92 0 0 0-2.92-2.92h-18.07a2.92 2.92 0 0 0-2.92 2.92v8.674H17.08a2.92 2.92 0 0 0-2.92 2.92v11.693a2.92 2.92 0 0 0 2.92 2.92h3.877v41.34H17.08a2.92 2.92 0 0 0-2.92 2.92v11.693a2.92 2.92 0 0 0 2.92 2.92h93.84a2.921 2.921 0 0 0 2.921-2.92V93.387a2.92 2.92 0 0 0-2.921-2.92h-3.877v-41.34h3.877ZM80.275 25.841h12.229v5.753h-12.23v-5.753Zm-44.779 0h12.23v5.753h-12.23v-5.753ZM20 37.435h88v5.852H20v-5.852Zm88 64.724H20v-5.851h88v5.851Zm-6.798-11.692H26.798v-41.34h74.404v41.34Z"
      fill="currentColor"
    />
    <path
      d="M56.327 74.536h6.75l-.51 1.144-4.742 4.136a2.92 2.92 0 0 0 3.838 4.403l11.93-10.402a2.92 2.92 0 0 0-1.92-5.122h-5.992l.48-1.075 4.342-3.404A2.92 2.92 0 0 0 66.9 59.62l-12.375 9.698a2.92 2.92 0 0 0 1.802 5.219ZM83.042 65.971h9.324a2.92 2.92 0 1 0 0-5.84h-9.324a2.92 2.92 0 0 0 0 5.84ZM35.634 66.945h1.742v1.741a2.92 2.92 0 0 0 5.84 0v-1.741h1.742a2.92 2.92 0 0 0 0-5.841h-1.741v-1.742a2.92 2.92 0 0 0-5.841 0v1.742h-1.742a2.92 2.92 0 0 0 0 5.84Z"
      fill="currentColor"
    />
  </Icon>
);

export default Battery;
