import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { useLocationQuery } from "../../../../../graphql/graphql";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderEditSectionFooter from "../../components/sectionFooter";
import WorkOrderEditCompanyInfoPresenter, {
  LocationInfoSectionData,
} from "./presenter";

interface WorkOrderEditLocationInfoProps {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
}

const WorkOrderEditLocationInfo: React.FC<WorkOrderEditLocationInfoProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const [sectionData, setSectionData] = useState<LocationInfoSectionData>();
  const [initialSectionData, setInitialSectionData] =
    useState<LocationInfoSectionData>();
  const [changed, setChanged] = useState<boolean>(false);
  const { data: location } = useLocationQuery({
    variables: { id: workOrder.locationId },
    skip: !workOrder.locationId,
  });

  const handleSectionDataChange = useCallback(
    (newSectionData: LocationInfoSectionData) => {
      if (!isEqual(newSectionData, initialSectionData)) {
        setChanged(true);
      } else {
        setChanged(false);
      }
      setSectionData(newSectionData);
    },
    [initialSectionData]
  );

  const cancel = () => {
    const workOrderData = {
      locationName: workOrder.location?.name,
      locationContactFullName: workOrder.locationContactFullName,
      locationContactEmail: workOrder.locationContactEmail,
      locationContactMobilePhone: workOrder.locationContactMobilePhone,
      locationContactWorkPhone: workOrder.locationContactWorkPhone,
    };
    setSectionData({ ...workOrderData });
    setInitialSectionData({ ...workOrderData });
    setChanged(false);
  };
  const save = () => {
    if (sectionData) {
      onWorkOrderChange({
        ...workOrder,
        locationContactFullName: sectionData.locationContactFullName,
        locationContactEmail: sectionData.locationContactEmail,
        locationContactMobilePhone: sectionData.locationContactMobilePhone,
        locationContactWorkPhone: sectionData.locationContactWorkPhone,
      });
    }
  };

  useEffect(() => {
    if (workOrder) {
      setSectionData({
        locationName: workOrder.location?.name,
        locationContactFullName: workOrder.locationContactFullName,
        locationContactEmail: workOrder.locationContactEmail,
        locationContactMobilePhone: workOrder.locationContactMobilePhone,
        locationContactWorkPhone: workOrder.locationContactWorkPhone,
      });
    }
    setChanged(false);
  }, [workOrder]);

  useEffect(() => {
    changeIsEdit && changeIsEdit(changed);
  }, [changed, changeIsEdit]);

  return (
    <>
      <WorkOrderEditCompanyInfoPresenter
        sectionData={sectionData}
        location={location}
        onSectionDataChange={handleSectionDataChange}
      >
        <WorkOrderEditSectionFooter
          disabled={false}
          cancel={cancel}
          save={save}
          visible={changed}
        />
      </WorkOrderEditCompanyInfoPresenter>
    </>
  );
};

export default WorkOrderEditLocationInfo;
