import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  CompanyFragmentFragment,
  CompanyQuery,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";

interface SwitchCompanyProps {
  company: CompanyFragmentFragment;
  currentCompanyData: CompanyQuery | undefined;
  active: boolean;
}

const SwitchCompany: React.FC<SwitchCompanyProps> = ({
  company,
  active,
  currentCompanyData,
}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleChange = async (value: string) => {
    navigate(getRoutePath("companyChange", { companyId: value }));
  };

  return (
    <>
      {company.id !== currentCompanyData?.company.id ? (
        !active ? (
          <Tooltip
            label="This company is not active. Please contact your account admin."
            hasArrow
            placement="left"
            shouldWrapChildren
          >
            <Button
              variant="outline"
              colorScheme="secondary"
              isDisabled
              size="xs"
              fontWeight={500}
            >
              Switch to this company
            </Button>
          </Tooltip>
        ) : (
          <Button
            onClick={onOpen}
            variant="outline"
            colorScheme="secondary"
            size="xs"
            fontWeight={500}
          >
            Switch to this company
          </Button>
        )
      ) : (
        <Text
          width={{ xs: "44", sm: "48", md: "56" }}
          textAlign="center"
          color="gray.700"
          fontSize="sm"
        >
          You are currently in this company account
        </Text>
      )}
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              <Text>
                Are you sure you want to switch from{" "}
                <Text as="strong">{currentCompanyData?.company.name}</Text> to{" "}
                <Text as="strong">{company.name}</Text>?
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} width="48%">
                cancel
              </Button>
              <Button
                onClick={() => handleChange(company.id)}
                colorScheme="secondary"
                ml="4%"
                width="48%"
              >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default SwitchCompany;
