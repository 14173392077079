import { Alert, AlertDescription, Button, useToast } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";

import {
  EMAIL_VERIFICATION_LINK_SENT,
  EMAIL_VERIFIED,
} from "../../../../../constants/lang/en";
import { useUserEmailConfirmMutation } from "../../../../../graphql/graphql";
import { setGenericMessage } from "../../../../../utils/serverErrors";

interface EmailConfirmProps {}

const EmailConfirm: React.FC<EmailConfirmProps> = () => {
  const toast = useToast();
  const [userEmailConfirmMutation, { loading }] = useUserEmailConfirmMutation();

  const confirmEmail = async () => {
    try {
      const { errors, data } = await userEmailConfirmMutation();
      if (errors) {
        throw errors;
      } else {
        toast({
          description: data?.userEmailConfirm.emailConfirmed
            ? EMAIL_VERIFIED
            : EMAIL_VERIFICATION_LINK_SENT,
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Alert status="error" role="group">
      <AlertDescription>
        Welcome! Click the link in the email we sent you to verify your email
        address
        <Button
          size="sm"
          variant="link"
          colorScheme="white"
          fontSize="sm"
          onClick={confirmEmail}
          isLoading={loading}
          marginLeft="2"
          padding="0 4px"
          _groupHover={{ backgroundColor: "red.800" }}
          textTransform="uppercase"
        >
          Resend
        </Button>
      </AlertDescription>
    </Alert>
  );
};

export default EmailConfirm;

gql`
  mutation userEmailConfirm($data: UserEmailConfirmInput) {
    userEmailConfirm(data: $data) {
      ...UserFragment
    }
  }
`;
