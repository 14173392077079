import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import { useSessionQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface NotAuthorizedPageProps {}

const NotAuthorizedPage: React.FC<NotAuthorizedPageProps> = () => {
  const { data } = useSessionQuery();

  useEffect(() => {
    layoutOptionsVar({ styleProps: { maxWidth: "full" } });
  }, []);

  return (
    <>
      <Helmet>
        <title>No Access</title>
      </Helmet>
      {data ? (
        <Presenter isLoggedIn={data.session.isLoggedIn} />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default NotAuthorizedPage;
