import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React from "react";

import { WOGetAssetServiceTypes } from "../../../../../graphql/types/queries/workOrdersQuery";
import { getRoutePath } from "../../../../../router";
import Accordion from "../../../components/accordion";
import Flyout from "../../../components/flyout";
import { WorkOrder, WorkOrderAsset } from "../../../models/workOrder";
import WorkOrderCreateAddAssetHeader from "./header";
import { AssetDropdownItem, ItemDropdownBase } from "./models/assets";
import { AssetToLocations } from "./models/relations";
import WorkOrderCreateAddAssetPlansTable from "./plansTable";
import { FloorPlansItem } from "./plansTable/presenter";
import WorkOrderCreateAddAssetRelations from "./relations";
import WorkOrderCreateAddAssetSettings from "./settings";

interface WorkOrderCreateAddAssetModalPresenterProps {
  isAddAssetModalVisible?: boolean;
  isAddAssetInfoValid?: boolean;
  addAssetModalVisibleChange: (isAddAssetModalVisible: boolean) => void;
  workOrder: WorkOrder;
  asset: WorkOrderAsset;
  onAssetChange: (asset: WorkOrderAsset | null) => void;
  onAddAsset: () => void;
  assetToLocationMap: AssetToLocations;
}

const WorkOrderCreateAddAssetModalPresenter: React.FC<
  WorkOrderCreateAddAssetModalPresenterProps
> = ({
  isAddAssetModalVisible,
  isAddAssetInfoValid,
  addAssetModalVisibleChange,
  workOrder,
  onAssetChange,
  onAddAsset,
  asset,
  assetToLocationMap,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();
  const addAssetModalWidth = "80%";
  const flyoutFooter = (
    <EuiFlexGroup justifyContent="flexEnd" alignItems="center">
      {asset?.assetId ? (
        <EuiFlexItem grow={false}>
          <EuiLink
            href={getRoutePath("assetsShow", { assetId: asset?.assetId })}
            target="_blank"
            style={{}}
          >
            View Asset Page
          </EuiLink>
        </EuiFlexItem>
      ) : null}
      <EuiFlexItem grow={false}>
        <EuiButton
          onClick={() => onAddAsset()}
          disabled={!isAddAssetInfoValid}
          fill
        >
          Add Asset to Work Order
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const onAssetSelected = (selectedAsset: AssetDropdownItem | null): void => {
    if (!selectedAsset) {
      onAssetChange(null);
      return;
    }
    onAssetChange({
      assetId: selectedAsset.id,
      asset: selectedAsset,
      sublocationId: asset.sublocationId,
      sublocationName: asset.sublocationName,
    });
  };

  const onSublocationSelected = (
    sublocation: ItemDropdownBase | null
  ): void => {
    onAssetChange({
      sublocationId: sublocation?.id,
      sublocationName: sublocation?.name,
    });
  };

  const onAssetTypeSelected = (
    assetServiceType: WOGetAssetServiceTypes
  ): void => {
    onAssetChange({
      serviceTypeId: assetServiceType.id,
      serviceType: assetServiceType,
    });
  };

  const onCommentCreated = (comment: string | null): void => {
    onAssetChange({
      comment,
    });
  };

  const onPlansSelected = (plans: FloorPlansItem[]): void => {
    onAssetChange({
      plans,
    });
  };

  return (
    <>
      <Flyout
        classBody={"add-asset"}
        classHeader={"add-asset"}
        width={addAssetModalWidth}
        visible={isAddAssetModalVisible}
        visibleChange={addAssetModalVisibleChange}
        headerTitle={"Add Asset"}
        footerContent={flyoutFooter}
      >
        <WorkOrderCreateAddAssetHeader
          workOrder={workOrder}
          onAssetSelected={onAssetSelected}
          onSublocationSelected={onSublocationSelected}
        />
        {!!asset.assetId ? (
          <div style={{ margin: `${euiTheme.size.l} 0` }}>
            <WorkOrderCreateAddAssetSettings
              workOrder={workOrder}
              onCommentCreated={onCommentCreated}
              onAssetTypeSelected={onAssetTypeSelected}
            />
          </div>
        ) : null}
        {!!asset.assetId ? (
          <div style={{ margin: `${euiTheme.size.l} 0` }}>
            <Accordion
              id="wo-add-asset-relations-table"
              label="Asset’s Relations"
              initialIsOpen={true}
              simplifyView={true}
            >
              <WorkOrderCreateAddAssetRelations
                assetId={asset.assetId}
                assetToLocationMap={assetToLocationMap}
                workOrder={workOrder}
              />
            </Accordion>
          </div>
        ) : null}

        {!!asset.assetId ? (
          <div style={{ margin: `${euiTheme.size.l} 0` }}>
            <Accordion
              id="wo-add-asset-plans-table"
              label="Add Plan(s) to Asset’s Service"
              initialIsOpen={true}
              simplifyView={true}
            >
              <WorkOrderCreateAddAssetPlansTable
                assetId={asset.assetId}
                sublocationId={asset.sublocationId}
                companyId={workOrder.companyId}
                onSelectPlans={onPlansSelected}
              />
            </Accordion>
          </div>
        ) : null}
      </Flyout>
    </>
  );
};

export default WorkOrderCreateAddAssetModalPresenter;
