import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";

import MobileAccordion from "../../../components/elements/mobileAccordion";
import PageSpinner from "../../../components/elements/pageSpinner";
import { ColumnProps } from "../../../components/elements/spreadsheet";
import { defaultShortDateWithTimeAndZoneFormat } from "../../../constants/date";
import {
  ReportAssetFilterInput,
  useReportAssetQuery,
} from "../../../graphql/graphql";
import { timezone } from "../../../utils/date/timezones";
import DownloadReport from "../download";
import ReportFilters from "../filter";
import ReportsTable from "../table";

interface AssetsReportPagePresenterProps {}

const AssetsReportPagePresenter: React.FC<
  AssetsReportPagePresenterProps
> = () => {
  const [filterBy, setFilterBy] = useState<ReportAssetFilterInput>({});
  const [isPrintReady, setPrintReady] = useState<boolean>(false);

  const { data, loading } = useReportAssetQuery({
    variables: {
      filterBy,
      timezone,
    },
    fetchPolicy: "no-cache",
  });

  const columns: ColumnProps[] = useMemo(() => {
    let columnData: ColumnProps[] = [];
    Object.keys(data?.reportAsset.length ? data.reportAsset[0] : {}).forEach(
      (colHeading) => {
        if (colHeading !== "__typename") {
          columnData.push({
            field: colHeading,
            cellType: "INPUT",
            headerName:
              colHeading.charAt(0).toUpperCase() +
              colHeading
                .slice(1)
                .replace(/([A-Z])/g, " $1")
                .trim(),
            styleProps: {
              minWidth: ["Asset", "Address"].includes(colHeading) ? 100 : 70,
            },
          });
        }
      }
    );
    return columnData;
  }, [data?.reportAsset]);

  const formattedData = useMemo(() => {
    if (data?.reportAsset.length) return data.reportAsset;
    else return [];
  }, [data?.reportAsset]);

  return (
    <Box px="4">
      <Flex
        direction="row"
        alignItems={{ base: "flex-start", lg: "center" }}
        justifyContent="space-between"
        marginBottom="4"
      >
        <MobileAccordion>
          <ReportFilters
            setFilterBy={setFilterBy}
            filterBy={filterBy}
            enabledFilters={["locations", "categories", "status", "contacts"]}
          />
        </MobileAccordion>
        <DownloadReport
          isDisabled={!formattedData?.length}
          reportType="Asset"
          filterBy={filterBy}
          setPrintReady={setPrintReady}
        />
      </Flex>
      {loading ? (
        <PageSpinner />
      ) : data?.reportAsset.length ? (
        <Box id="reports_table_wrap">
          {isPrintReady && (
            <Heading size="xl" mb="4">
              Assets Report –{" "}
              {format(new Date(), defaultShortDateWithTimeAndZoneFormat)}
            </Heading>
          )}
          <ReportsTable
            columns={columns}
            data={formattedData}
            isPrintReady={isPrintReady}
          />
        </Box>
      ) : (
        <Center height={100} color="gray.700" id="reports_table_wrap">
          No results found
        </Center>
      )}
    </Box>
  );
};

export default AssetsReportPagePresenter;
