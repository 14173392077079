import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownEditor,
  EuiMarkdownFormat,
  getDefaultEuiMarkdownParsingPlugins,
  useEuiTheme,
} from "@elastic/eui";
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";

import { WorkOrderEditorProps } from "./index";

const WorkOrderEditorPresenter: React.FC<WorkOrderEditorProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const placeholder = "Add description...";
  const [description, setDescription] = useState(workOrder.description || "");
  const [readOnly, setReadonly] = useState(true);
  const { euiTheme }: any = useEuiTheme();
  const plugins = getDefaultEuiMarkdownParsingPlugins({ exclude: ["tooltip"] });
  const saveDescription = () => {
    onWorkOrderChange({ description });
    setReadonly(true);
    changeIsEdit && changeIsEdit(false);
  };
  const cancel = () => {
    setDescription(workOrder.description || "");
    setReadonly(true);
    changeIsEdit && changeIsEdit(false);
  };

  useEffect(() => {
    setDescription(workOrder?.description || "");
    changeIsEdit && changeIsEdit(false);
  }, [workOrder.description]);

  useEffect(() => {
    if (changeIsEdit && description !== (workOrder.description || "")) {
      changeIsEdit && changeIsEdit(true);
    }
  }, [description]);

  if (readOnly) {
    return (
      <>
        <EuiFlexItem>
          <EuiMarkdownFormat
            onClick={() => setReadonly(false)}
            style={{
              padding: euiTheme.size.s,
              borderRadius: "0",
              cursor: "pointer",
              marginLeft: "-8px",
              maxHeight: "500px",
              overflow: "auto",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "17px",
              color: "#343741",
            }}
            css={css`
              &:hover {
                background: var(--color-hover);
              }
            `}
          >
            {description || placeholder}
          </EuiMarkdownFormat>
        </EuiFlexItem>
      </>
    );
  } else
    return (
      <>
        <EuiMarkdownEditor
          aria-label="Description"
          placeholder={placeholder}
          value={description}
          onChange={setDescription}
          height={135}
          maxHeight={500}
          uiPlugins={[]}
          parsingPluginList={plugins}
          css={css`
            min-height: 135px;

            .euiMarkdownEditorFooter .euiPopover {
              display: none;
            }

            .euiMarkdownEditorToolbar .euiMarkdownEditorToolbar__buttons {
              .euiButtonIcon {
                &[aria-label="Ordered list"],
                &[aria-label="Task list"],
                &[aria-label="Quote"] {
                  display: none;
                }
              }

              .euiToolTipAnchor:has(> .euiButtonIcon[aria-label="Unordered list"]) {
                margin-right: 0;
              }
            }
          `}
        />
        <EuiFlexGroup
          style={{
            marginTop: euiTheme.size.l,
          }}
          justifyContent="flexEnd"
        >
          <EuiButtonEmpty
            className={"critical-asset-button_compressed"}
            color="text"
            onClick={cancel}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            className={"critical-asset-button_compressed"}
            fill={true}
            onClick={saveDescription}
          >
            Save
          </EuiButton>
        </EuiFlexGroup>
      </>
    );
};

export default WorkOrderEditorPresenter;
