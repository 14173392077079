import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import PageSpinner from "../../../components/elements/pageSpinner";
import { usePlansQuery } from "../../../graphql/graphql";
import {
  currentCompanyVar,
  mainCompanyVar,
} from "../../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface PlansProps {
  header?: string;
  onPlanSelected: (planStripeId: string) => void;
}

const Plans: React.FC<PlansProps> = ({ header, onPlanSelected }) => {
  const { data: plansData } = usePlansQuery();
  const mainUserCompany = useReactiveVar(mainCompanyVar);
  const currentCompany = useReactiveVar(currentCompanyVar);

  return plansData && currentCompany && mainUserCompany ? (
    <Presenter
      header={header}
      onPlanSelected={onPlanSelected}
      plans={plansData.plans}
      currentCompany={currentCompany}
      mainUserCompany={mainUserCompany}
    />
  ) : (
    <PageSpinner />
  );
};

export default Plans;

gql`
  query Plans {
    plans {
      ...PlansFragment
    }
  }
`;
