import { Box, Tooltip } from "@chakra-ui/react";
import React from "react";

import { MaintenanceStatusType } from "../../../graphql/graphql";
import { getStatusBgColor } from "../../../utils/status";

interface StatusProps {
  status: MaintenanceStatusType;
  styleProps?: object;
}

const Status: React.FC<StatusProps> = ({ status, styleProps }) => {
  const label =
    status === MaintenanceStatusType.PastDue
      ? "Past due!"
      : status === MaintenanceStatusType.DueIn_30
      ? "Due soon"
      : "Current";

  return (
    <Tooltip label={label} hasArrow>
      <Box
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        width="3"
        height="100%"
        backgroundColor={getStatusBgColor(status)}
        {...styleProps}
      />
    </Tooltip>
  );
};

export default Status;
