import { Icon, IconProps } from "@chakra-ui/react";

const MotionDetector = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M90.656 14.877a2.115 2.115 0 0 0-2.116 2.117 19.036 19.036 0 0 0 19.041 19.041 2.117 2.117 0 1 0 0-4.233A14.81 14.81 0 0 1 93.9 22.661a14.808 14.808 0 0 1-1.127-5.667 2.114 2.114 0 0 0-2.117-2.117Z"
      fill="currentColor"
    />
    <path
      d="M107.581 27.569a2.113 2.113 0 0 0 1.956-1.307 2.123 2.123 0 0 0 0-1.62 2.104 2.104 0 0 0-1.146-1.145 2.113 2.113 0 0 0-.81-.161 6.346 6.346 0 0 1-6.342-6.342 2.116 2.116 0 1 0-4.233 0 10.577 10.577 0 0 0 10.575 10.575ZM107.581 40.269a23.269 23.269 0 0 1-23.274-23.275 2.115 2.115 0 0 0-4.226.008 27.5 27.5 0 0 0 27.493 27.492 2.115 2.115 0 1 0 .007-4.225ZM63.846 36.358c4.23 0 7.616-3.413 7.616-7.68 0-4.265-3.385-7.678-7.616-7.678-4.23 0-7.615 3.413-7.615 7.679s3.384 7.679 7.615 7.679Zm.423 26.45H85v-7.68H69.77L61.307 41.05c-1.27-2.133-3.808-3.839-6.346-3.839-.847 0-1.27 0-2.116.427L30 44.89v22.183h7.615V51.29l8.885-2.986L30 114h7.615l12.27-34.555 9.73 13.225V114h7.616V86.697L56.654 67.5l2.961-12.372 4.654 7.68ZM111.071 2l12.728 12.728-7.434 7.434-12.728-12.728z"
      fill="currentColor"
    />
    <path
      fill="currentColor"
      d="m107.828 13 4.914 4.914-2.828 2.829L105 15.828z"
    />
  </Icon>
);

export default MotionDetector;
