import { CompanyUserRole } from "./graphql/graphql";

const onlyOwner = [CompanyUserRole.Owner];

const ownerAndAdmin = [CompanyUserRole.Owner, CompanyUserRole.Admin];

const ownerAdminAndManager = [
  CompanyUserRole.Owner,
  CompanyUserRole.Admin,
  CompanyUserRole.Manager,
];

const ownerAdminManagerAndUser = [
  CompanyUserRole.Owner,
  CompanyUserRole.Admin,
  CompanyUserRole.Manager,
  CompanyUserRole.User,
];

// const all = [
//   CompanyUserRole.Owner,
//   CompanyUserRole.Admin,
//   CompanyUserRole.Manager,
//   CompanyUserRole.User,
//   CompanyUserRole.ViewOnly,
// ];

const ROLES: { [key: string]: CompanyUserRole[] } = {
  companyCreate: ownerAndAdmin,

  locationsCreate: ownerAdminManagerAndUser,
  locationsEdit: ownerAdminManagerAndUser,
  locationsDelete: ownerAdminManagerAndUser,

  floorPlansCreate: ownerAdminManagerAndUser,
  floorPlansEdit: ownerAdminManagerAndUser,
  floorPlansDelete: ownerAdminManagerAndUser,

  assetsCreate: ownerAdminManagerAndUser,
  assetsEdit: ownerAdminManagerAndUser,
  assetsDelete: ownerAdminManagerAndUser,

  maintenancesCreate: ownerAdminManagerAndUser,
  maintenancesEdit: ownerAdminManagerAndUser,
  maintenancesDelete: ownerAdminManagerAndUser,
  maintenancesNoteCreate: ownerAdminManagerAndUser,

  foldersCreate: ownerAdminManagerAndUser,
  foldersEdit: ownerAdminManagerAndUser,
  foldersDelete: ownerAdminManagerAndUser,
  filesCreate: ownerAdminManagerAndUser,
  filesEdit: ownerAdminManagerAndUser,
  filesDelete: ownerAdminManagerAndUser,

  reports: ownerAndAdmin,
  maintenanceReports: ownerAndAdmin,
  assetReports: ownerAndAdmin,
  userActivityReports: ownerAndAdmin,

  companyTeamsCreate: ownerAdminAndManager,
  companyTeamsEdit: ownerAdminAndManager,
  companyTeamsChangeOwner: onlyOwner,

  companySettings: ownerAndAdmin,
  companyNameChange: ownerAndAdmin,
  companyAddressChange: ownerAndAdmin,
  companyContactChange: ownerAndAdmin,
  companyEmailChange: ownerAndAdmin,
  companyPhoneChange: ownerAndAdmin,
  companyWebsiteChange: ownerAndAdmin,
  companyBusinessTypeChange: ownerAndAdmin,
  companyIndustryChange: ownerAndAdmin,

  assetCategoriesCreate: ownerAdminAndManager,
  assetCategoriesEdit: ownerAdminAndManager,
  assetCategoriesDelete: ownerAdminAndManager,
  assetTypesCreate: ownerAdminAndManager,
  assetTypesEdit: ownerAdminAndManager,
  assetTypesCopy: ownerAdminAndManager,
  assetTypesDelete: ownerAdminAndManager,
  assetTypesMaintenancesCreate: ownerAdminAndManager,
  assetTypesMaintenancesEdit: ownerAdminAndManager,
  assetTypesMaintenancesDelete: ownerAdminAndManager,

  companyPlan: ownerAndAdmin,
  companyPaymentMethods: ownerAndAdmin,
  companyBillingHistory: ownerAndAdmin,

  onboarding: ownerAndAdmin,

  workOrders: ownerAdminManagerAndUser,
};

export default ROLES;
