import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import useFolderTreeRestricted from "../../../hooks/useFolderTreeRestricted";
import { getRoutePath } from "../../../router";

const FolderBreadCrumb = ({ folderId }: { folderId: string }) => {
  const folderTree = useFolderTreeRestricted(folderId);
  return folderTree ? (
    <Breadcrumb separator="»" spacing=".25rem">
      {!!folderTree.parent && (
        <FolderBreadcrumbItem folder={folderTree.parent} />
      )}
      <BreadcrumbItem isCurrentPage isLastChild>
        <BreadcrumbLink
          to={getRoutePath("foldersShow", { folderId: folderTree.id })}
          as={RouterLink}
          isCurrentPage
          pointerEvents="none"
        >
          <Text as="div" maxWidth={150} isTruncated>
            {folderTree.name}
          </Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  ) : null;
};

export default FolderBreadCrumb;

const FolderBreadcrumbItem = ({ folder }: { folder: any }) => {
  return (
    <>
      {!!folder.parent && <FolderBreadcrumbItem folder={folder.parent} />}
      <BreadcrumbItem separator="»" spacing=".25rem">
        <BreadcrumbLink
          to={getRoutePath("foldersShow", { folderId: folder.id })}
          as={RouterLink}
        >
          <Text as="div" maxWidth={120} isTruncated>
            {folder.name !== "Company Folders" ? folder.name : "Files"}
          </Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </>
  );
};
