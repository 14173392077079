import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FOLDER_DELETED_MESSAGE } from "../../../constants/lang/en";
import { FolderWithRelationsFragmentFragment } from "../../../graphql/graphql";
import { useFolderDeleteMutation } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import { setGenericMessage } from "../../../utils/serverErrors";

interface FolderDeletePresenterProps {
  folder: FolderWithRelationsFragmentFragment;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const FolderDeletePresenter: React.FC<FolderDeletePresenterProps> = ({
  folder,
  isOpen,
  onOpen,
  onClose,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();

  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [folderDeleteMutation, { loading: deleteLoading }] =
    useFolderDeleteMutation();

  const deleteFolder = async () => {
    try {
      const { errors } = await folderDeleteMutation({
        variables: { ids: [folder.id] },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        onClose();
        setTimeout(() => {
          const parentPathname = getRoutePath("foldersShow", {
            folderId: folder.parentId,
          });
          if (parentPathname === location.pathname) {
            client.cache.evict({ id: `Folder:${folder.id}` });
          } else {
            navigate(
              getRoutePath("foldersShow", { folderId: folder.parentId })
            );
          }
        }, 500);
        toast({
          description: FOLDER_DELETED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogBody textAlign="center">
            Are you sure you want to delete the following folder?
            <br />
            <Text as="strong">{folder.name}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              disabled={deleteLoading}
              onClick={onClose}
              width="48%"
            >
              No, Don't Delete!
            </Button>
            <Button
              onClick={deleteFolder}
              colorScheme="red"
              ml="4%"
              width="48%"
            >
              Yes, Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default FolderDeletePresenter;

gql`
  mutation FolderDelete($ids: [UUID!]!) {
    folderDelete(ids: $ids)
  }
`;
