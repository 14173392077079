import { Flex } from "@chakra-ui/react";

import { AssetTypeFragmentFragment } from "../../../graphql/graphql";
import AssetIcon from "../assetIcons";

const AssetTypeSelectOption: React.FC<{
  assetType?: AssetTypeFragmentFragment;
}> = ({ assetType }) =>
  assetType ? (
    <Flex alignItems="center">
      <AssetIcon
        iconName={assetType.iconName}
        iconColor={assetType.misc.resolvedIconColor}
        iconType={assetType.iconType}
        iconSize="xs"
        marginRight="2"
      />
      {assetType.name}
    </Flex>
  ) : (
    <>All</>
  );

export default AssetTypeSelectOption;
