import { FloorPlanAssetForMapFragmentFragment } from "../graphql/graphql";

export const fedToAssetTree = (
  floorPlanAssets: FloorPlanAssetForMapFragmentFragment[],
  startFloorPlanAsset: FloorPlanAssetForMapFragmentFragment,
  assetPartIds: string[]
) => {
  let resolvedFloorPlanAssets = floorPlanAssets;
  let resolvedAssetPartIds = assetPartIds;

  const findFedTos = (
    floorPlanAsset: FloorPlanAssetForMapFragmentFragment
  ): FloorPlanAssetForMapWithFedTo => {
    const fedToAssets = resolvedAssetPartIds.length
      ? floorPlanAsset.asset.assetAffectedAssets.filter(
          (afa) =>
            !afa.assetPartId || resolvedAssetPartIds.includes(afa.assetPartId)
        )
      : floorPlanAsset.asset.assetAffectedAssets;
    const fedToAssetIds = fedToAssets.map((fta) => fta.affectedAsset.id);
    const fedToFloorPlanAssets = resolvedFloorPlanAssets.filter((a) =>
      fedToAssetIds.includes(a.asset.id)
    );

    // Only start fed from asset shoulc check for assetPartIds
    resolvedAssetPartIds = [];

    // Remove found fedToAssets from resolvedFloorPlanAssets
    // This is to avoid infinite loop issue which occurs when assets are fed to in circular manner
    // e.g. Asset A => Asset B => Asset C => Asset A
    resolvedFloorPlanAssets = resolvedFloorPlanAssets.filter(
      (a) => !fedToAssetIds.includes(a.asset.id)
    );

    return Object.assign({}, floorPlanAsset, {
      fedToFloorPlanAssets: fedToFloorPlanAssets.map((sl) => findFedTos(sl)),
    });
  };

  return findFedTos(startFloorPlanAsset);
};

export interface FloorPlanAssetForMapWithFedTo
  extends FloorPlanAssetForMapFragmentFragment {
  fedToFloorPlanAssets: FloorPlanAssetForMapWithFedTo[];
}
