import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React from "react";

import { ASSET_TYPE_DELETED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetTypeFragmentFragment,
  useAssetTypeDeleteMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";

interface AssetTypeDeleteProps {
  assetType: AssetTypeFragmentFragment;
}

const AssetTypeDelete: React.FC<AssetTypeDeleteProps> = ({ assetType }) => {
  const toast = useToast();
  const client = useApolloClient();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const {
    isOpen: isDeleteModalOpen,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useDisclosure();
  const [assetTypeDeleteMutation, { loading: deleteLoading }] =
    useAssetTypeDeleteMutation();

  const deleteAssetType = React.useCallback(async () => {
    try {
      const { errors } = await assetTypeDeleteMutation({
        variables: { id: assetType.id },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        client.cache.evict({ id: `AssetType:${assetType.id}` });
        onDeleteModalClose();
        toast({
          description: ASSET_TYPE_DELETED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [
    assetTypeDeleteMutation,
    toast,
    onDeleteModalClose,
    assetType.id,
    client.cache,
  ]);

  return (
    <>
      <Tooltip label="Delete asset" hasArrow placement="bottom">
        <IconButton
          onClick={onDeleteModalOpen}
          variant="icon"
          aria-label="Delete asset"
          colorScheme="grayRed"
          padding={2}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </IconButton>
      </Tooltip>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onDeleteModalClose}
        isOpen={isDeleteModalOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete the following asset type?
              <br />
              <Text as="strong">{assetType.name}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={deleteLoading}
                onClick={onDeleteModalClose}
                width="48%"
              >
                No, Don't Delete!
              </Button>
              <Button
                onClick={deleteAssetType}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AssetTypeDelete;

gql`
  mutation AssetTypeDelete($id: UUID!) {
    assetTypeDelete(id: $id)
  }
`;
