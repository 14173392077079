import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import AssetFields, {
  getAssetFieldsInitialValues,
  getAssetFieldsValidationSchema,
} from "../../../components/elements/assetFields";
import ContactCompanyUserField from "../../../components/elements/contactCompanyUserField";
import FormGroup from "../../../components/elements/formGroup";
import {
  AssetFieldValuesInput,
  AssetWithRelationsFragmentFragment,
} from "../../../graphql/graphql";
import {
  companyUserIdsSchema,
  descriptionSchema,
  nameSchema,
  yupObject,
} from "../../../utils/validation";

interface AssetsEditPresenterProps {
  handleSubmit: (
    values: AssetsFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  onDrawerClose: () => void;
  isOpen: boolean;
  loading: boolean;
  asset: AssetWithRelationsFragmentFragment;
}

const AssetsEditPresenter: React.FC<AssetsEditPresenterProps> = ({
  isOpen,
  handleSubmit,
  onDrawerClose,
  loading,
  asset,
}) => {
  const selectedAssetType = asset.assetType;
  const [searchParams] = useSearchParams();
  const contactForm = searchParams.get("contactForm");
  const validationSchema = React.useMemo(
    () =>
      yupObject().shape({
        name: nameSchema.label("Asset name"),
        description: descriptionSchema,
        companyUserIds: companyUserIdsSchema.optional().min(0),
        ...getAssetFieldsValidationSchema(asset.assetType),
      }),
    [asset.assetType]
  );

  const initialValues = React.useMemo(
    () => ({
      name: asset.name,
      description: asset.description,
      companyUserIds: asset.assetCompanyUsers
        ? asset.assetCompanyUsers.map((acu) => acu.companyUser.id)
        : [],
      ...getAssetFieldsInitialValues(asset.assetType, asset),
    }),
    [asset]
  );

  const onSubmit = React.useCallback(
    (data: any, formikHelpers: any) => {
      const formattedData: AssetsFormData = {
        name: data.name,
        description: data.description,
        companyUserIds: data.companyUserIds,
        assetFieldValuesInput: [],
      };
      asset.assetType.assetFields.forEach((assetField) => {
        if (data[assetField.id]) {
          formattedData.assetFieldValuesInput.push({
            assetFieldId: assetField.id,
            value: `${data[assetField.id]}`,
          });
        }
      });
      handleSubmit(formattedData, formikHelpers);
    },
    [asset.assetType, handleSubmit]
  );

  const title = contactForm
    ? `Contact for ${asset.name}`
    : `Edit ${asset.name}`;

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{title}</DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form id="asset_update" noValidate>
                  {!contactForm && (
                    <>
                      <FormGroup label="Asset Name" name="name">
                        <Input
                          autoFocus
                          autoComplete="off"
                          {...getFieldProps("name")}
                        />
                      </FormGroup>
                      <FormGroup label="Description" name="description">
                        <Textarea
                          autoComplete="off"
                          {...getFieldProps("description")}
                        />
                      </FormGroup>
                      {!!selectedAssetType && (
                        <AssetFields
                          assetFields={selectedAssetType.assetFields}
                        />
                      )}
                    </>
                  )}
                  <ContactCompanyUserField
                    name="companyUserIds"
                    label="Select user(s) responsible for servicing this asset"
                  />
                </Form>
              )}
            </Formik>
          </DrawerBody>
          <DrawerFooter>
            <Button
              width="full"
              type="submit"
              isLoading={loading}
              form="asset_update"
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetsEditPresenter;

export type AssetsFormData = {
  name: string;
  description: string;
  assetFieldValuesInput: AssetFieldValuesInput[];
  companyUserIds: string[];
};
