import { EuiDatePicker, EuiFlexGroup, EuiFormRow } from "@elastic/eui";
import { css } from "@emotion/react";
import moment, { Moment } from "moment";
import React, { memo, useCallback } from "react";

import {
  getUtcDateString,
  getUtcOffsetFromTimezoneName,
  isDateValid,
} from "../helpers/dateTime";
import Label from "./label";

interface SelectProps {
  label?: string;
  onChange: (value: any) => void;
  onBlur?: () => void;
  invalid?: boolean;
  selectedValue?: any;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  maxDate?: Moment | null;
  minDate?: Moment | null;
  maxTime?: Moment | null;
  minTime?: Moment | null;
  utcOffset?: number | null;
  returnUtc?: boolean;
  minDateValidationError?: string;
  maxDateValidationError?: string;
  errors?: string[];
  onInternalErrors?: (errors: string[]) => void;
  className?: string;
}

const DateTimePicker: React.FC<SelectProps> = memo(
  ({
    label,
    onChange,
    onBlur,
    selectedValue,
    required,
    disabled,
    placeholder,
    maxDate,
    minDate,
    maxTime,
    minTime,
    utcOffset = 0,
    invalid = false,
    returnUtc = false,
    minDateValidationError = "Not valid value",
    maxDateValidationError = "Not valid value",
    errors,
    onInternalErrors,
    className = "",
  }) => {
    const [value, setValue] = React.useState(moment() as Moment | null);
    const [touched, setTouched] = React.useState(false);
    const [currentErrors, setCurrentErrors] = React.useState<string[]>([]);
    const [isInvalid, setIsInvalid] = React.useState<boolean>(false);
    const [timeValidations, setTimeValidations] = React.useState<Moment[]>([]);
    const [offsetMinDate, setOffsetMinDate] = React.useState<Moment>();
    const [offsetMaxDate, setOffsetMaxDate] = React.useState<Moment>();

    const handleChange = (newValue: Moment) => {
      setValue(newValue);
      let returnValue: string | null = null;
      if (newValue) {
        if (!newValue.isUTC()) {
          newValue = moment(newValue).subtract(
            getUtcOffsetFromTimezoneName(moment.tz.guess()),
            "minutes"
          );
        }
        returnValue = newValue.format();
        if (utcOffset) {
          newValue = moment.utc(newValue).add(utcOffset, "minutes");
        }
        if (returnUtc) {
          returnValue = getUtcDateString(newValue);
        }
      }
      onChange(returnValue);
    };

    const handleOnBlur = () => {
      setTouched(true);
      onBlur && onBlur();
    };

    const setTimeLimits = useCallback(
      (calcMinDate?: Moment | null, calcMaxDate?: Moment | null) => {
        if (maxTime && minTime) {
          setTimeValidations([maxTime, minTime]);
          return;
        }
        if (!calcMinDate && !calcMaxDate) {
          setTimeValidations([]);
          return;
        }
        const currentValue = moment(value || new Date());
        const maxTimeCalc = calcMaxDate?.isSame(currentValue, "day")
          ? moment(calcMaxDate)
          : moment().endOf("day");
        const minTimeCalc = calcMinDate?.isSame(currentValue, "day")
          ? moment(calcMinDate)
          : moment().startOf("day");
        if (
          (!!calcMinDate &&
            currentValue.valueOf() < calcMinDate?.valueOf() &&
            !calcMinDate?.isSame(currentValue, "day")) ||
          (!!calcMaxDate &&
            currentValue.valueOf() > calcMaxDate?.valueOf() &&
            !calcMaxDate?.isSame(currentValue, "day"))
        ) {
          setTimeValidations([moment(), moment()]);
        } else {
          setTimeValidations([maxTimeCalc, minTimeCalc]);
        }
      },
      [maxTime, minTime, value]
    );

    React.useEffect(() => {
      if (selectedValue) {
        const newValue = moment.utc(selectedValue);
        if (utcOffset) {
          newValue.subtract(utcOffset, "minutes");
        }
        if (newValue?.toJSON() !== value?.toJSON()) setValue(newValue);
      } else {
        setValue(null);
      }
    }, [selectedValue, utcOffset]);

    React.useEffect(() => {
      let calcMaxDate = maxDate;
      let calcMinDate = minDate;
      if (maxDate) {
        calcMaxDate = utcOffset
          ? moment(maxDate).subtract(utcOffset, "minutes")
          : maxDate;
        setOffsetMaxDate(calcMaxDate);
      }
      if (minDate) {
        calcMinDate = utcOffset
          ? moment(minDate).subtract(utcOffset, "minutes")
          : minDate;
        setOffsetMinDate(calcMinDate);
      }
      setTimeLimits(calcMinDate, calcMaxDate);
    }, [minDate, maxDate, utcOffset, selectedValue, value]);

    React.useEffect(() => {
      if (errors?.length && currentErrors === errors) return;
      if (errors?.length && currentErrors !== errors) {
        setCurrentErrors(errors);
        onInternalErrors?.(errors);
        return;
      }
      if (!value && required) {
        setCurrentErrors(["Field is required"]);
        onInternalErrors?.(["Field is required"]);
        return;
      }
      if (isDateValid(value, offsetMinDate, offsetMaxDate)) {
        setCurrentErrors([]);
        onInternalErrors?.([]);
      } else {
        const validationError = isDateValid(selectedValue, offsetMinDate)
          ? minDateValidationError
          : maxDateValidationError;
        setCurrentErrors([validationError]);
        onInternalErrors?.([validationError]);
      }
    }, [
      errors,
      offsetMinDate,
      offsetMaxDate,
      minDateValidationError,
      maxDateValidationError,
      required,
    ]);

    React.useEffect(() => {
      setIsInvalid(invalid || !!currentErrors.length);
    }, [currentErrors.length, invalid]);

    return (
      <EuiFlexGroup
        wrap={true}
        direction="column"
        alignItems="stretch"
        gutterSize="none"
        className={className}
        style={{
          position: "relative",
          width: "100%",
          minWidth: "100%",
          flexGrow: 1,
        }}
      >
        {label && <Label label={label} required={required} />}
        <EuiFormRow
          isInvalid={touched && isInvalid}
          error={
            touched && isInvalid && !!currentErrors?.length
              ? currentErrors
              : null
          }
        >
          <EuiDatePicker
            showTimeSelect
            selected={value}
            isInvalid={isInvalid && touched}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={handleOnBlur}
            onSelect={handleChange}
            maxDate={offsetMaxDate ? offsetMaxDate : undefined}
            minDate={offsetMinDate ? offsetMinDate : undefined}
            maxTime={timeValidations[0]}
            minTime={timeValidations[1]}
            css={css`
              height: 32px;
            `}
          />
        </EuiFormRow>
      </EuiFlexGroup>
    );
  }
);

export default DateTimePicker;
