import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { useEffect, useState } from "react";

import { useWOGetAssetServiceTypesQuery } from "../../../../../../graphql/queries/workOrders";
import { WOGetAssetServiceTypes } from "../../../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrder } from "../../../../models/workOrder";
import WorkOrderCreateAddAssetSettingsPresenter from "./presenter";

export type WorkOrderCreateAddAssetSettingsProps = {
  workOrder: WorkOrder;
  onAssetTypeSelected: (assetType: WOGetAssetServiceTypes) => void;
  onCommentCreated: (comment: string | null) => void;
};

const WorkOrderCreateAddAssetSettings = ({
  workOrder,
  onAssetTypeSelected,
  onCommentCreated,
}: WorkOrderCreateAddAssetSettingsProps) => {
  const [serviceTypes, setServiceTypes] = useState<
    EuiSuperSelectOption<string>[]
  >([]);
  const { data: assetServiceTypes } = useWOGetAssetServiceTypesQuery({
    fetchPolicy: "cache-and-network",
  });

  const handleAssetServiceTypeSelected = (assetTypeId: string | null) => {
    const selectedAssetServiceType = assetServiceTypes?.assetServiceType.find(
      (type) => type.id === assetTypeId
    );
    if (selectedAssetServiceType) {
      onAssetTypeSelected(selectedAssetServiceType);
    }
  };

  useEffect(() => {
    if (assetServiceTypes?.assetServiceType) {
      const mappedTypes: EuiSuperSelectOption<string>[] =
        assetServiceTypes.assetServiceType.map((item) => ({
          inputDisplay: item.name,
          value: item.id,
        }));
      setServiceTypes(mappedTypes);
    }
  }, [assetServiceTypes]);

  return (
    <>
      <WorkOrderCreateAddAssetSettingsPresenter
        workOrder={workOrder}
        assetServiceTypes={serviceTypes}
        onAssetTypeSelected={handleAssetServiceTypeSelected}
        onCommentCreated={onCommentCreated}
      />
    </>
  );
};
export default WorkOrderCreateAddAssetSettings;
