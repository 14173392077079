export const baseSizes = {
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "2lg": "34rem",
  "2xl": "40rem",
};

export const pixelSizes = {
  "36px": "36px",
  "31px": "31px",
  "29px": "29px",
  "24px": "24px",
  "22px": "22px",
  "21px": "21px",
  "20px": "20px",
  "18px": "18px",
  "17px": "17px",
  "16px": "16px",
  "15px": "15px",
  "14px": "14px",
  "12px": "12px",
  "10px": "10px",
  "6px": "6px",
  "-8px": "-8px",
};

const largeSizes = {
  screenFull: "calc(var(--vh, 1vh)*100)",
};

const sizes = {
  ...baseSizes,
  ...largeSizes,
  ...pixelSizes,
};

export default sizes;
