import { useReactiveVar } from "@apollo/client";
import { FeatureCollection } from "geojson";
import { CRS } from "leaflet";
import React, { useEffect, useState } from "react";
import { MapContainer } from "react-leaflet";

import { ConnectionsData } from "../../../../components/elements/floorPlan/leaflet/connections";
import {
  AssetForMapFragmentFragment,
  FloorPlanAssetForMapFragmentFragment,
  FloorPlanForMapFragmentFragment,
} from "../../../../graphql/graphql";
import { planEditorOptionsVar } from "../../../../graphql/reactiveVariables";
import colors from "../../../../theme/foundations/colors";
import { arrayFlatReverse } from "../../../../utils/arrayFlatReverse";
import {
  FloorPlanAssetForMapWithFedTo,
  fedToAssetTree,
} from "../../../../utils/fedToAssetsTree";
import AssetConnectionLines, { AssetConnectionsData } from "./assetConnections";
import PlanDrawControl from "./planDrawControl";
import PlanOverlay from "./planImageOverlay";
import PlanMarkersControl from "./planMarkersControl";

interface AffectedArea {
  annotation: FeatureCollection;
  color: string;
  asset: AssetForMapFragmentFragment;
}

interface PlanPreviewProps {
  plan?: FloorPlanForMapFragmentFragment | null;
  assetId?: string | null;
  showAffectedAreas?: boolean;
}

const PlanPreviewPresenter: React.FC<PlanPreviewProps> = ({
  plan,
  assetId,
  showAffectedAreas,
}) => {
  const { assetColors } = useReactiveVar(planEditorOptionsVar);
  const [planImageLoaded, setPlanImageLoaded] = useState<boolean>(false);
  const [fedFromConnections, setFedFromConnections] = useState<
    AssetConnectionsData[]
  >([]);
  const [fedToConnections, setFedToConnections] = useState<
    AssetConnectionsData[]
  >([]);
  const [floorPlanAssets, setFloorPlanAssets] = useState<
    FloorPlanAssetForMapFragmentFragment[]
  >([]);
  const [affectedAreas, setAffectedAreas] = useState<AffectedArea[]>([]);

  useEffect(() => {
    if (!plan || !assetId) return;
    const sfpa = plan.floorPlanAssets.find((vfpa) => vfpa.asset.id === assetId);
    if (!sfpa) return;
    const fedFromConnections: ConnectionsData[] = [];
    const fedToConnections: ConnectionsData[] = [];
    let floorPlanAssetsArray: FloorPlanAssetForMapFragmentFragment[] = [sfpa];
    const asset = sfpa.asset;
    const assetCoordinates = sfpa.mapPosition?.geometry?.coordinates;

    if (assetCoordinates) {
      asset.assetAffectedByAssets.forEach((ffa) => {
        const fpa = plan.floorPlanAssets.find(
          (vfpa) => vfpa.asset.id === ffa.asset.id
        );
        if (!fpa) return;
        floorPlanAssetsArray.push(fpa);
        if (ffa?.pathPoints?.length) {
          fedFromConnections.push({
            coordinates: arrayFlatReverse([...ffa.pathPoints]),
            fromAssetId: fpa.asset.id,
            toAssetId: asset.id,
          });
        } else {
          const fpaCoordinates = fpa.mapPosition?.geometry?.coordinates;
          fpaCoordinates &&
            fedFromConnections.push({
              coordinates: [
                [fpaCoordinates[1], fpaCoordinates[0]],
                [assetCoordinates[1], assetCoordinates[0]],
              ],
              fromAssetId: fpa.asset.id,
              toAssetId: asset.id,
            });
        }
      });

      const fedToAssets = fedToAssetTree(plan.floorPlanAssets, sfpa, []);
      const fedToLinesAssign = (fedTo: FloorPlanAssetForMapWithFedTo) => {
        const coordinatesA = fedTo.mapPosition?.geometry?.coordinates;
        if (coordinatesA) {
          fedTo.fedToFloorPlanAssets.forEach((ftfpa) => {
            const isAddedToFromConnections = fedFromConnections.some(
              (fedFrom) =>
                fedFrom.toAssetId === ftfpa.asset.id &&
                fedFrom.fromAssetId === fedTo.asset.id
            );
            const pathPointsAsset = fedTo.asset.assetAffectedAssets.find(
              (affectedAsset) =>
                affectedAsset.affectedAsset.id === ftfpa.asset.id
            );
            if (pathPointsAsset?.pathPoints && !isAddedToFromConnections) {
              fedToConnections.push({
                coordinates: arrayFlatReverse(pathPointsAsset?.pathPoints),
                fromAssetId: fedTo.asset.id,
                toAssetId: ftfpa.asset.id,
              });
            } else if (!isAddedToFromConnections) {
              const coordinatesB = ftfpa.mapPosition?.geometry?.coordinates;
              coordinatesB &&
                fedToConnections.push({
                  coordinates: [
                    [coordinatesA[1], coordinatesA[0]],
                    [coordinatesB[1], coordinatesB[0]],
                  ],
                  fromAssetId: fedTo.asset.id,
                  toAssetId: ftfpa.asset.id,
                });
            }
            if (
              !floorPlanAssetsArray.map((asset) => asset.id).includes(ftfpa.id)
            ) {
              floorPlanAssetsArray.push(ftfpa);
            }
            if (ftfpa.fedToFloorPlanAssets.length) {
              fedToLinesAssign(ftfpa);
            }
          });
        }
      };
      fedToLinesAssign(fedToAssets);
    }

    setFloorPlanAssets(floorPlanAssetsArray);
    setFedFromConnections(fedFromConnections);
    setFedToConnections(fedToConnections);
  }, [assetId, plan]);

  useEffect(() => {
    if (!plan || !assetId) return;
    const affectedArea: AffectedArea[] = [];
    const sfpa = plan?.floorPlanAssets.find(
      (vfpa) => vfpa.asset.id === assetId
    );

    if (sfpa) {
      const features = sfpa.mapAffectedArea?.features || [];
      const color = assetColors[sfpa.asset.id]
        ? assetColors[sfpa.asset.id]
        : colors.secondary[500];

      affectedArea.push({
        annotation: {
          ...sfpa.mapAffectedArea,
          features,
        },
        color,
        asset: sfpa.asset,
      });
    }

    setAffectedAreas(affectedArea);
  }, [assetId, plan]);

  return (
    <>
      {plan ? (
        <div style={{ display: "flex", minHeight: "inherit" }}>
          <MapContainer
            style={{ flexGrow: 1 }}
            crs={CRS.Simple}
            minZoom={-4}
            maxZoom={2.5}
            zoomDelta={0.5}
            zoomSnap={0.25}
            attributionControl={false}
          >
            <PlanOverlay
              imageUrl={plan.signedUrl || ""}
              onImageLoaded={() => setPlanImageLoaded(true)}
            />
            {planImageLoaded ? (
              <>
                {showAffectedAreas
                  ? affectedAreas.map((aa) => (
                      <PlanDrawControl
                        key={aa.asset.id}
                        annotation={aa.annotation}
                        color={aa.color}
                      />
                    ))
                  : null}
                <PlanMarkersControl
                  floorPlan={plan}
                  floorPlanAssets={floorPlanAssets}
                  showAssetStatus={false}
                />
                <AssetConnectionLines
                  fedFromConnections={fedFromConnections}
                  fedToConnections={fedToConnections}
                />
              </>
            ) : null}
          </MapContainer>
        </div>
      ) : null}
    </>
  );
};

export default PlanPreviewPresenter;
