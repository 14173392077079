import { Icon, IconProps } from "@chakra-ui/react";

const InitiatingDevice = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M88 98H70.5V84H80a1 1 0 0 0 1-1V71a1 1 0 0 0-1-1H47a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h9.5v14H39"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity="0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67 89.675 63.5 93 60 89.675h2.262V84h2.475v5.675H67ZM60.365 78.644c.22.212.48.317.784.317.303 0 .564-.105.783-.317.225-.212.337-.484.337-.816V74h1.078v3.828c0 .641-.216 1.165-.648 1.571-.427.4-.944.601-1.55.601-.612 0-1.135-.2-1.567-.6-.427-.407-.64-.93-.64-1.572V74h1.095v3.828c0 .332.11.604.328.816Zm5.212 1.27V74h1.078v4.936h2.241v.978h-3.319Zm5.104 0V74h1.078v4.936H74v.978h-3.32ZM53 79.914V74h2.359c.606 0 1.064.189 1.373.567.314.372.472.82.472 1.347s-.158.976-.472 1.348c-.309.366-.767.55-1.373.55h-1.28v2.102H53Zm1.078-4.936h1.264c.253 0 .446.098.581.292.135.19.202.404.202.644s-.073.455-.219.644c-.14.183-.34.275-.598.275h-1.23v-1.855Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M60.365 78.644c.22.212.48.317.784.317.303 0 .564-.105.783-.317.225-.212.337-.484.337-.816V74h1.078v3.828c0 .641-.216 1.165-.648 1.571-.427.4-.944.601-1.55.601-.612 0-1.135-.2-1.567-.6-.427-.407-.64-.93-.64-1.572V74h1.095v3.828c0 .332.11.604.328.816Zm5.212 1.27V74h1.078v4.936h2.241v.978h-3.319Zm5.104 0V74h1.078v4.936H74v.978h-3.32ZM53 79.914V74h2.359c.606 0 1.064.189 1.373.567.314.372.472.82.472 1.347s-.158.976-.472 1.348c-.309.366-.767.55-1.373.55h-1.28v2.102H53Zm1.078-4.936h1.264c.253 0 .446.098.581.292.135.19.202.404.202.644s-.073.455-.219.644c-.14.183-.34.275-.598.275h-1.23v-1.855Z"
      stroke="currentColor"
    />
    <path
      d="M22 16a2 2 0 0 1 2-2h80a2 2 0 0 1 2 2v96a2 2 0 0 1-2 2H24a2 2 0 0 1-2-2V16Z"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity="0"
    />
    <path
      d="M38 54h52v44H38V54Z"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity="0"
    />
    <path
      d="M40 41.78V28h8.62v2.58h-5.68v3.14h4.5v2.56h-4.5v5.5H40ZM54.762 28h2.92v13.78h-2.92V28ZM81.648 41.78V28h8.72v2.58h-5.8v3.14h5.08v2.54h-5.08v2.94h5.98v2.58h-8.9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m71.694 36.9 3.42 4.88h-3.52l-3.08-4.76h-.66v4.76h-2.94V28h5.64c1.467 0 2.593.447 3.38 1.34.8.88 1.2 1.94 1.2 3.18 0 1.053-.293 1.987-.88 2.8-.587.813-1.44 1.34-2.56 1.58Zm-3.84-6.32h2.56c.507 0 .907.207 1.2.62a2.121 2.121 0 0 1-.02 2.66c-.307.4-.727.6-1.26.6h-2.48v-3.88Z"
      fill="currentColor"
    />
  </Icon>
);

export default InitiatingDevice;
