import { Icon, IconProps } from "@chakra-ui/react";

const Transponder = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M67 18h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2ZM64 98c-3.308 0-6 2.692-6 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6Zm0 8.572A2.575 2.575 0 0 1 61.429 104 2.574 2.574 0 0 1 64 101.429 2.574 2.574 0 0 1 66.571 104 2.575 2.575 0 0 1 64 106.572Z"
      fill="currentColor"
    />
    <path
      d="M95.454 11H32.546C27.283 11 23 15.24 23 20.45v87.1c0 5.211 4.283 9.45 9.546 9.45h62.908c5.263 0 9.546-4.24 9.546-9.45v-87.1c0-5.21-4.283-9.45-9.546-9.45Zm5.732 96.55c0 3.124-2.572 5.665-5.732 5.665H32.546c-3.16 0-5.732-2.541-5.732-5.665v-87.1c0-3.123 2.571-5.664 5.732-5.664h62.908c3.16 0 5.732 2.54 5.732 5.664v87.1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.693 47.019c.924.898 2.421.899 3.346 0 8.801-8.56 23.12-8.56 31.922 0 .463.45 1.066.674 1.673.674.608 0 1.21-.224 1.673-.674a2.256 2.256 0 0 0 0-3.254c-10.645-10.353-27.97-10.353-38.614 0a2.256 2.256 0 0 0 0 3.254Zm26.47 6.307c.463.45 1.066.674 1.672.674.608 0 1.21-.224 1.674-.673.924-.9.923-2.357 0-3.255-5.794-5.635-15.225-5.634-21.018 0a2.258 2.258 0 0 0 0 3.255 2.415 2.415 0 0 0 3.347-.001c3.95-3.842 10.374-3.841 14.325 0Z"
      fill="currentColor"
    />
    <path
      d="M63.193 58.28h.001a1.302 1.302 0 0 1 1.613 0l9.641 7.495c.34.264.552.687.552 1.15v14.647c0 .827-.647 1.428-1.358 1.428H54.358c-.71 0-1.358-.6-1.358-1.428V66.924c0-.462.212-.884.551-1.149l9.642-7.495Z"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
  </Icon>
);

export default Transponder;
