import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { HTMLAttributes, ReactNode } from "react";

interface FormGroupProps {
  name: string;
  label?: ReactNode;
  hint?: ReactNode;
  renderError?: boolean;
  styleProps?: object;
  labelUp?: boolean;
}

const StyledFormControl = styled(FormControl)`
  .input-wrapper:focus ~ .form-label,
  .input-wrapper:focus-within ~ .form-label,
  .chakra-select__wrapper:focus-within ~ .form-label,
  input:focus ~ .form-label,
  textarea:focus ~ .form-label,
  select:focus ~ .form-label {
    font-size: 12px;
    top: 1px;
  }
  select {
    z-index: 3;
  }
`;

const FormGroup: React.FC<FormGroupProps & HTMLAttributes<HTMLElement>> = ({
  name,
  label,
  hint,
  children,
  renderError = true,
  styleProps,
  labelUp,
}) => {
  const { errors, values, touched, submitCount } = useFormikContext<any>();
  const error = get(errors, name);
  const value = get(values, name);
  const fieldTouched = get(touched, name);
  const animatedLabel = typeof labelUp !== "undefined" ? labelUp : !!value;

  return (
    <StyledFormControl
      marginTop="3"
      position="relative"
      isInvalid={!!error && (!!submitCount || (fieldTouched && !!value))}
      aria-invalid={!!error && (!!submitCount || (fieldTouched && !!value))}
      {...styleProps}
    >
      {children}
      {!!label && (
        <FormLabel
          position="absolute"
          left="1px"
          top={animatedLabel ? "1px" : "16px"}
          zIndex="2"
          paddingX="4"
          margin="0"
          fontSize={animatedLabel ? "xs" : "base"}
          color="gray.600"
          width="90%"
          className="form-label"
        >
          {label}
        </FormLabel>
      )}
      {!!hint && (
        <FormHelperText color="gray.500" fontSize="xs">
          {hint}
        </FormHelperText>
      )}
      {renderError && (
        <FormErrorMessage alignItems="flex-start">
          <Text as="span" marginRight="1">
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Text>
          <Text role="alert">{error}</Text>
        </FormErrorMessage>
      )}
    </StyledFormControl>
  );
};

export default FormGroup;
