import React from "react";

import { WorkOrder } from "../../models/workOrder";
import WorkOrderCreateServicesPresenter from "./presenter";

interface WorkOrderCreateServicesProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreatServices: React.FC<WorkOrderCreateServicesProps> = ({
  workOrder,
  onWorkOrderChange,
}) => {
  const handleFilesAdded = (files: File[]) => {
    if (!!files.length) {
      onWorkOrderChange({
        ...workOrder,
        attachments: [...(workOrder?.attachments || []), ...files],
      });
    }
  };

  const handleFileDelete = (deletedFile: File) => {
    if (workOrder.attachments?.length) {
      const index = workOrder.attachments.findIndex(
        (file) => file === deletedFile
      );
      if (index > -1) {
        workOrder.attachments.splice(index, 1);
        onWorkOrderChange({
          ...workOrder,
        });
      }
    }
  };

  return (
    <WorkOrderCreateServicesPresenter
      workOrder={workOrder}
      onFilesAdded={handleFilesAdded}
      onFileDelete={handleFileDelete}
      onWorkOrderChange={onWorkOrderChange}
    ></WorkOrderCreateServicesPresenter>
  );
};

export default WorkOrderCreatServices;
