import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import CreatePayment from "../../components/elements/createPayment";
import FloorPlan from "../../components/elements/floorPlan";
import YoutubeIframe from "../../components/elements/youtubeIframe";
import { defaultDateWithTimeFormat } from "../../constants/date";
import { useCompanyQuery } from "../../graphql/graphql";
import FloorPlanCreate from "../floorPlans/create";
import LocationCreate from "../locations/create";
import TeamCreate from "../teams/create";
import AssetCategoryAndTypeSetup from "./assetCategoryAndTypeSetup";
import CompanyInfoForm from "./companyInfoForm";

interface StepProps {
  completed: boolean;
  percentageCompleted?: number;
  title: string;
  subtitle1: string;
  completedBy?: any;
  youtubeId?: string;
  type: OnboardingKey;
  locations: { id: string; name: string }[];
  floorPlan?: { id: string; name: string } | null;
}

const Step: FC<StepProps> = ({
  completed,
  percentageCompleted,
  title,
  subtitle1,
  completedBy,
  youtubeId,
  type,
  locations,
  floorPlan,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, refetch } = useCompanyQuery();
  const { isOpen, onClose } = useDisclosure();
  const {
    isOpen: isFormOpen,
    onClose: onFormClose,
    onOpen: onFormOpen,
  } = useDisclosure();

  useEffect(() => {
    if (searchParams.get("reload")) refetch();
  }, [searchParams, refetch]);

  const hidePlanCreateButton = type === "planUploaded" && !locations.length;
  const hideAssetCreateButton = type === "assetAdded" && !floorPlan;

  const handleClick = () => {
    if (type === "planSubscribed") {
      setSearchParams({ CaAprType: "changePlan" });
    } else {
      if (type === "assetAdded" && floorPlan) {
        setSearchParams({ CaAprType: "planEditor", planId: floorPlan.id });
      }

      onFormOpen();
    }
  };

  const handleClose = () => {
    refetch();
    onFormClose();
  };

  return (
    <>
      <Flex
        marginTop={{ base: "5", sm: "3" }}
        paddingTop={{ base: "5", sm: "3" }}
        borderTop="1px solid"
        borderColor="gray.100"
        alignItems="center"
        flexDirection={{ base: "column", sm: "row" }}
      >
        <Box
          flexShrink={0}
          width="8"
          marginRight="3"
          color={completed ? "green.500" : "gray.500"}
        >
          {percentageCompleted && !completed ? (
            <CircularProgress
              value={percentageCompleted}
              size="32px"
              color="green.500"
            >
              <CircularProgressLabel>
                {percentageCompleted}%
              </CircularProgressLabel>
            </CircularProgress>
          ) : (
            <FontAwesomeIcon
              icon={completed ? faCheckCircle : faCircle}
              size="2x"
            />
          )}
        </Box>

        <Box flexGrow={1} opacity={completed ? 0.5 : 1}>
          <Heading
            as="h4"
            size="lg"
            fontWeight="semibold"
            alignItems="center"
            display="flex"
            flexDirection={{ base: "column", sm: "row" }}
            marginBottom={{ base: "4", sm: 0 }}
          >
            {title}
            {/* {!!youtubeId && (
              <Button
                variant="link"
                colorScheme="secondary"
                marginLeft={{ base: 0, sm: "2" }}
                fontSize="sm"
                onClick={onOpen}
              >
                watch tutorial
              </Button>
            )} */}
          </Heading>
          <Box fontSize="sm">{subtitle1}</Box>
          {completedBy ? (
            <Box fontSize="sm" color="gray.700">
              Completed {completedBy.name ? `by ${completedBy.name}` : ""}{" "}
              {completedBy.time
                ? `on ${format(
                    new Date(completedBy.time),
                    defaultDateWithTimeFormat
                  )}`
                : ""}
            </Box>
          ) : hidePlanCreateButton || hideAssetCreateButton ? (
            <Box fontSize="sm" color="gray.700">
              <Box as="sup">*</Box>This step is available only after you have
              completed all previous steps
            </Box>
          ) : null}
        </Box>
        <Box
          flexShrink={0}
          marginLeft="2"
          marginTop={{ base: "3", sm: "0" }}
          width={{ base: "full", sm: "100px" }}
        >
          {!hidePlanCreateButton && !hideAssetCreateButton && (
            <Button
              onClick={handleClick}
              colorScheme={completed ? "gray" : "primary"}
              size="xs"
              width="full"
              fontSize="sm"
            >
              {completed ? "View" : "Go"}
            </Button>
          )}
        </Box>
      </Flex>
      {youtubeId && isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
          <ModalOverlay>
            <ModalContent>
              <ModalCloseButton
                position="absolute"
                right={{ base: "0", md: "-36px" }}
                top={{ base: "-40px", md: "0" }}
                color="white"
              />
              <ModalBody padding="0">
                <YoutubeIframe youtubeId={youtubeId} autoplay />
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
      {!!data && type === "companyInfo" && (
        <CompanyInfoForm
          isOpen={isFormOpen}
          onClose={onFormClose}
          company={data.company}
        />
      )}
      {type === "locationAdded" && isFormOpen && (
        <LocationCreate onClose={handleClose} />
      )}
      {type === "teamMemberAdded" && isFormOpen && (
        <TeamCreate onClose={handleClose} />
      )}
      {type === "paymentAdded" && isFormOpen && (
        <CreatePayment paymentMethodAdded={onFormClose} />
      )}
      {locations.length > 0 && type === "planUploaded" && isFormOpen && (
        <FloorPlanCreate onClose={handleClose} />
      )}
      {type === "categoryAndTypeAdded" && isFormOpen && (
        <AssetCategoryAndTypeSetup isOpen={isFormOpen} onClose={onFormClose} />
      )}
      {!!floorPlan && type === "assetAdded" && isFormOpen && <FloorPlan />}
    </>
  );
};

export default Step;

export type OnboardingKey =
  | "companyInfo"
  | "locationAdded"
  | "teamMemberAdded"
  | "planSubscribed"
  | "paymentAdded"
  | "planUploaded"
  | "categoryAndTypeAdded"
  | "assetAdded";
