import { Box, CloseButton, Flex } from "@chakra-ui/react";
import React, { useCallback } from "react";

import { useFileQuery } from "../../../graphql/graphql";
import FileIcon from "../fileIcon";

interface FileThumbProps {
  fileId: string;
  onRemoveFile: (fileId: string) => void;
}

const FileThumb: React.FC<FileThumbProps> = ({ fileId, onRemoveFile }) => {
  const { data } = useFileQuery({
    variables: { id: fileId },
  });
  const handleRemoveFile = useCallback(() => {
    if (data) onRemoveFile(data.file.id);
  }, [onRemoveFile, data]);

  if (!data) return null;
  const { file } = data;

  return (
    <Flex alignItems="center">
      <Box marginRight="2" opacity="50">
        <FileIcon
          fileName={file.name}
          filePath={file.path}
          width={40}
          imageWidth={100}
        />
      </Box>
      <Box flexGrow={1} fontSize="sm" color="gray.800">
        {file.name}
      </Box>
      <CloseButton onClick={handleRemoveFile} size="sm" />
    </Flex>
  );
};

export default FileThumb;
