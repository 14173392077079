import React from "react";

import { WorkOrder } from "../../../../models/workOrder";
import WorkOrderVerifyAndSaveDescriptionPresenter from "./presenter";

export interface WorkOrderVerifyAndSaveDescriptionProps {
  workOrder: WorkOrder;
}

const WorkOrderVerifyAndSaveDescription: React.FC<
  WorkOrderVerifyAndSaveDescriptionProps
> = ({ workOrder }) => {
  return (
    <>
      <WorkOrderVerifyAndSaveDescriptionPresenter workOrder={workOrder} />
    </>
  );
};

export default WorkOrderVerifyAndSaveDescription;
