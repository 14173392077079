export const INDUSTRIES = [
  "Agriculture & Farming",
  "Automotive & Transportation",
  "Aviation & Aerospace",
  "Commercial Real Estate & Property Management",
  "Construction & Engineering",
  "Energy",
  "Facilities Management",
  "Food & Beverage",
  "Gaming & E-Sports",
  "Government & Cities",
  "Gym & Fitness",
  "Healthcare & Pharmaceuticals",
  "Manufacturing",
  "Religious Institutions",
  "Restaurants & Hospitality",
  "Retails & Supermarkets",
  "Schools & Education",
  "Sports & Recreation",
  "Technology, Telecom & Media",
  "Travel & Entertainment",
  "Other",
].map((l) => ({
  label: l,
  query: l,
  value: l,
}));

export const JOB_TITLES = [
  "CEO/President/Owner",
  "CFO",
  "CIO",
  "COO",
  "CTO",
  "Building Owner",
  "Contractor",
  "Building or Property Manager",
  "Facilities Manager",
  "Engineer",
  "Financial Manager/Accountant",
  "Technician",
  "Other",
].map((l) => ({
  label: l,
  query: l,
  value: l,
}));

export const BUSINESS_TYPES = [
  "Distributor",
  "Electrical",
  "Engineering",
  "General Contractor",
  "Facilities Management",
  "Janitorial",
  "Manufacturer",
  "Mechanical",
  "Plumbing",
  "Property Management",
  "Other",
].map((l) => ({
  label: l,
  query: l,
  value: l,
}));
