import React from "react";

import Calendly from "../../../components/elements/calendly";

interface CalendlyPageProps {}

const NativeCalendlyPage: React.FC<CalendlyPageProps> = () => {
  return <Calendly />;
};

export default NativeCalendlyPage;
