import "leaflet.browser.print/dist/leaflet.browser.print.js";

import L from "leaflet";
import { BoundsLiteral } from "leaflet";
import React, { useCallback, useEffect, useState } from "react";
import { ImageOverlay, Polyline, useMapEvents } from "react-leaflet";

import { isNativeWebViewVar } from "../../../../graphql/reactiveVariables";
import PageSpinner from "../../pageSpinner";

interface PlanOverlayProps {
  floorPlanName: string;
  imageUrl: string;
  startPrinting: () => void;
  stopPrinting: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const PlanOverlay: React.FC<PlanOverlayProps> = ({
  floorPlanName,
  imageUrl,
  startPrinting,
  stopPrinting,
  isOpen,
  onOpen,
  onClose,
}) => {
  const [sessionKey] = useState(imageUrl.split("?")[0]);

  const map = useMapEvents({
    moveend: () => {
      sessionStorage.setItem(
        sessionKey,
        JSON.stringify({ center: map.getCenter(), zoom: map.getZoom() })
      );
    },
    "browser-print-init": startPrinting,
    "browser-print-end": stopPrinting,
  } as any);

  useEffect(() => {
    if (isNativeWebViewVar()) return;
    const printControl = (L as any).control
      .browserPrint({
        title: "Print Plan",
        documentTitle: floorPlanName,
        position: "topleft",
        printModes: ["Auto"],
      })
      .addTo(map);

    return () => {
      map.removeControl(printControl);
    };
  }, [map]);

  const [bounds, setBounds] = React.useState<any>([
    [0, 0],
    [2500, 3500],
  ]);

  const setImgBounds = useCallback(
    (height, width) => {
      const newBounds: BoundsLiteral = [
        [0, 0],
        [height, width],
      ];
      const maxBounds: BoundsLiteral = [
        [-500, -500],
        [height + 500, width + 500],
      ];
      setBounds(newBounds);
      map.setMaxBounds(maxBounds);

      const defaultViewport = sessionStorage.getItem(sessionKey);
      if (defaultViewport) {
        // If user has saved viewport options for this floor plan, use that
        const { zoom, center } = JSON.parse(defaultViewport);
        if (zoom && center) {
          map.setView(center, zoom);
        } else {
          map.fitBounds(newBounds);
        }
      } else {
        map.fitBounds(newBounds);
      }
    },
    [map, sessionKey]
  );

  React.useEffect(() => {
    onClose();
    let img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImgBounds(img.height, img.width);
      onOpen();
    };

    // // If we couldn't load image via js in 30 seconds, open the image manually
    // // so leaflet's image layer will load it with default viewport
    // const handler = setTimeout(() => {
    //   onOpen();
    //   setTimeout(() => {
    //     const img = document.querySelector(
    //       "img.leaflet-image-layer"
    //     ) as HTMLImageElement | null;
    //     if (!img) return;
    //     setImgBounds(img.naturalHeight, img.naturalWidth);
    //   }, 15000);
    // }, 30000);
    // return () => {
    //   clearTimeout(handler);
    // };
  }, [imageUrl, onClose, onOpen, setImgBounds]);

  return isOpen ? (
    <>
      <ImageOverlay bounds={bounds} url={imageUrl} />
      {/* Below polylines are for printing full map */}
      <Polyline positions={[bounds[0]]} />
      <Polyline positions={[bounds[1]]} />
    </>
  ) : (
    <PageSpinner />
  );
};

export default PlanOverlay;
