import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import FloorPlanSelectField from "../../../../components/elements/floorPlanSelectField";
import { floorPlanIdsSchema, yupObject } from "../../../../utils/validation";

interface AffectedAssetCreatePresenterProps {
  loading: boolean;
  handleSubmit: (
    values: FloorPlanAssetFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  floorPlanIds: string[];
  isOpen: boolean;
  onDrawerClose: () => void;
}

const AffectedAssetCreatePresenter: React.FC<AffectedAssetCreatePresenterProps> =
  ({ loading, handleSubmit, floorPlanIds, isOpen, onDrawerClose }) => {
    return (
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <Helmet>
          <title>Add New Asset Plan</title>
        </Helmet>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Add New Asset Plan</DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{ floorPlanIds: [] }}
                validationSchema={floorPlanAssetFormValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                <Form noValidate>
                  <FloorPlanSelectField
                    name="floorPlanIds"
                    excludedFloorPlanIds={floorPlanIds}
                    autoFocus
                  />
                  <Box marginTop="8">
                    <Button width="full" type="submit" isLoading={loading}>
                      Save
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  };

export default AffectedAssetCreatePresenter;

const floorPlanAssetFormValidationSchema = yupObject().shape({
  floorPlanIds: floorPlanIdsSchema,
});

export type FloorPlanAssetFormData = {
  floorPlanIds: string[];
};
