const TOKEN = "companyId";

export const setCompanyId = (companyId: string): boolean => {
  if (!companyId) return false;
  localStorage.setItem(TOKEN, companyId);
  return true;
};

export const getCompanyId = () => localStorage.getItem(TOKEN) || "";

export const removeCompanyId = () => localStorage.removeItem(TOKEN);
