import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import { useFormikContext } from "formik";
import React, { useState } from "react";

import useAddressAutoSuggest from "../../../hooks/useAddressAutoSuggest";
import {
  countryFromAlpha2,
  countryOptions,
  getFieldsOfCountry,
} from "../../../utils/address";
import FormGroup from "../../elements/formGroup";
import SelectCombobox from "../selectCombobox";

interface AddressFieldsProps {
  autoFocus?: boolean;
}

const AddressFields: React.FC<AddressFieldsProps> = ({ autoFocus = false }) => {
  const { values, setFieldValue, getFieldProps } = useFormikContext<any>();
  const [query, setQuery] = useState(values.address1);
  const country = React.useMemo(
    () => countryFromAlpha2(values.countryAlpha2),
    [values.countryAlpha2]
  );
  const fields = React.useMemo(
    () => getFieldsOfCountry(values.countryAlpha2),
    [values.countryAlpha2]
  );

  const stateOptions: any[] = React.useMemo(() => {
    if (!fields.state.options) return [];
    return (fields.state.options as any)?.map((state: any) => ({
      label: Object.values(state)[0] as string,
      query: Object.values(state)[0] as string,
      value: Object.keys(state)[0],
    }));
  }, [fields.state.options]);

  const { results, resetResults } = useAddressAutoSuggest(query, {
    country: values.countryAlpha2 as string,
  });

  const { isOpen: isCountriesOpen, onOpen: onCountriesOpen } = useDisclosure();

  const resetCoordinates = () => {
    setFieldValue("coordinates", [0, 0]);
  };

  const resetAll = () => {
    setFieldValue("address1", "");
    setQuery("");
    setFieldValue("address2", "");
    setFieldValue("city", "");
    setFieldValue("state", "");
    setFieldValue("zip", "");
    resetCoordinates();
  };

  const handleAddressSelect = (id: string) => {
    const selectedResult: any = results.find((result: any) => result.id === id);
    const context = selectedResult.context;
    setFieldValue(
      "address1",
      `${selectedResult.address || ""} ${selectedResult.text || ""}`
    );
    setQuery("");
    setFieldValue("coordinates", selectedResult.geometry.coordinates);

    if (context) {
      setTimeout(() => {
        setFieldValue(
          "city",
          context.find((c: any) => c.id.includes("place"))?.text || ""
        );
      }, 5);
      setTimeout(() => {
        const region = context.find((c: any) => c.id.includes("region"));
        let stateValue = "";
        if (region) {
          if (fields.state.options) {
            const codes = region.short_code.split("-");
            stateValue = codes[codes.length - 1];
          } else {
            stateValue = region.text;
          }
        }
        setFieldValue("state", stateValue);
      }, 10);
      setTimeout(() => {
        setFieldValue(
          "zip",
          context.find((c: any) => c.id.includes("postcode"))?.text || ""
        );
      }, 15);
    }
    resetResults();
  };

  const { onChange: cityOnChange, ...cityProps } = getFieldProps("city");
  const { onChange: stateOnChange, ...stateProps } = getFieldProps("state");
  const { onChange: zipOnChange, ...zipProps } = getFieldProps("zip");

  return (
    <>
      {isCountriesOpen ? (
        <SelectCombobox
          label="Country"
          name="countryAlpha2"
          options={countryOptions}
          onChange={() => {
            resetAll();
          }}
        />
      ) : (
        <Flex alignItems="center" marginTop={3}>
          <Text fontSize="sm" marginRight="1">
            {country?.label}
          </Text>
          <Button
            onClick={onCountriesOpen}
            variant="link"
            colorScheme="secondary"
            size="xs"
            fontSize="sm"
            aria-label="Change Country"
          >
            Change?
          </Button>
        </Flex>
      )}

      <FormGroup
        label={fields.address1.label}
        name="address1"
        styleProps={{ marginTop: isCountriesOpen ? "3" : "2" }}
      >
        <Combobox
          aria-label="Address suggestions"
          openOnFocus
          onSelect={(val) => {
            results.forEach((result: any) => {
              if (result.place_name === val) {
                handleAddressSelect(result.id);
                return;
              }
            });
          }}
          className="input-wrapper"
        >
          <ComboboxInput
            autocomplete={false}
            as={Input as any}
            autoComplete="off"
            {...getFieldProps("address1")}
            onKeyUp={(e: any) => setQuery(e.currentTarget.value)}
            autoFocus={autoFocus}
          />
          <ComboboxPopover>
            <ComboboxList>
              {results.map((result: any) => (
                <ComboboxOption key={result.id} value={result.place_name} />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </FormGroup>
      <FormGroup label={fields.address2.label} name="address2">
        <Input autoComplete="address-line2" {...getFieldProps("address2")} />
      </FormGroup>
      <FormGroup label={fields.city.label} name="city">
        <Input
          autoComplete="address-level2"
          {...cityProps}
          onChange={(e: any) => {
            cityOnChange(e);
            resetCoordinates();
          }}
        />
      </FormGroup>
      <Flex>
        <Box width="50%" paddingRight="2">
          {stateOptions.length ? (
            <SelectCombobox
              label={fields.state.label}
              name="state"
              options={stateOptions}
              onChange={() => {
                resetCoordinates();
              }}
            />
          ) : (
            <FormGroup label={fields.state.label} name="state">
              <Input
                autoComplete="address-level1"
                {...stateProps}
                onChange={(e: any) => {
                  stateOnChange(e);
                  resetCoordinates();
                }}
              />
            </FormGroup>
          )}
        </Box>
        <Box width="50%" paddingLeft="2">
          <FormGroup label={fields.zip.label} name="zip">
            <Input
              autoComplete="postal-code"
              {...zipProps}
              onChange={(e: any) => {
                zipOnChange(e);
                resetCoordinates();
              }}
            />
          </FormGroup>
        </Box>
      </Flex>
    </>
  );
};

export default AddressFields;
