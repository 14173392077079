import { EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import React, { useCallback } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";

import { useWOGetCompanyUsersQuery } from "../../../../../graphql/queries/workOrders";
import { WOGetCompanyUsers } from "../../../../../graphql/types/queries/workOrdersQuery";
import Combobox from "../../../components/combobox";
import InputField from "../../../components/inputField";
import Select from "../../../components/select";
import { MessageUtils } from "../../../helpers/messages";
import { validateEmail } from "../../../helpers/validationEmail";
import { CompanyContactTypeEnum, WorkOrder } from "../../../models/workOrder";

interface WorkOrderCreateLocationContactInfoPresenterProps {
  workOrder: WorkOrder;
  onContactInfoChange: (type: CompanyContactTypeEnum) => void;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateLocationContactInfoPresenter: React.FC<
  WorkOrderCreateLocationContactInfoPresenterProps
> = ({ onContactInfoChange, workOrder, onWorkOrderChange }) => {
  const { euiTheme } = useEuiTheme();
  const options = [
    {
      inputDisplay: "Same as Company",
      value: CompanyContactTypeEnum.SAME_AS_COMPANY,
    },
    {
      inputDisplay: "Same as User",
      value: CompanyContactTypeEnum.CURRENT_USER,
    },
    {
      inputDisplay: "Search for User",
      value: CompanyContactTypeEnum.USER_SEARCH,
    },
    {
      inputDisplay: "Add Manually",
      value: CompanyContactTypeEnum.ADD_MANUALLY,
    },
  ];

  const companyContactFields = [
    {
      label: "Role",
      valueField: "locationContactOccupation",
    },
    {
      label: "Team Name",
      valueField: "locationContactTeamName",
    },
    {
      label: "Email",
      valueField: "locationContactEmail",
      isInvalid:
        workOrder.locationContactEmail !== null
          ? !validateEmail(workOrder.locationContactEmail || "")
          : false,
    },
    {
      label: "Phone Number (work)",
      valueField: "locationContactWorkPhone",
      required:
        !workOrder.locationContactWorkPhone &&
        !workOrder.locationContactMobilePhone,
      isInvalid: !(
        isValidPhoneNumber(workOrder.locationContactWorkPhone || "") ||
        (workOrder.locationContactWorkPhone === null &&
          isValidPhoneNumber(workOrder.locationContactMobilePhone || ""))
      ),
    },
    {
      label: "Phone Number (mobile)",
      valueField: "locationContactMobilePhone",
      required:
        !workOrder.locationContactWorkPhone &&
        !workOrder.locationContactMobilePhone,
      isInvalid: !(
        isValidPhoneNumber(workOrder.locationContactMobilePhone || "") ||
        (workOrder.locationContactMobilePhone === null &&
          isValidPhoneNumber(workOrder.locationContactWorkPhone || ""))
      ),
    },
  ];

  const { data, loading } = useWOGetCompanyUsersQuery({
    variables: {
      companyId: workOrder?.companyId,
    },
    fetchPolicy: "cache-and-network",
    skip: !workOrder?.companyId,
  });

  const [selectedContactUser, setSelectedContactUser] = React.useState(
    {} as any
  );
  const [contactUserTouched, setContactUserTouched] =
    React.useState<boolean>(false);
  const [userOptions, setUserOptions] = React.useState(
    [] as EuiComboBoxOptionOption<string>[]
  );
  const [userErrors, setUserErrors] = React.useState([] as string[]);

  const getFieldValue = (valueField: string | null) => {
    if (workOrder && valueField) {
      const entry = Object.entries(workOrder).find(
        ([key, value]) => key === valueField
      );
      return entry ? entry[1] : null;
    }
    return null;
  };

  const onInputChange = useCallback(
    (field, value) => {
      onWorkOrderChange({ ...workOrder, [field]: value });
    },
    [onWorkOrderChange, workOrder]
  );

  const handleLocationUserIdChange = (userId: string | null) => {
    const selectedUser = data?.workOrderCompanyUsers.find(
      (user) => user.id === userId
    );
    onWorkOrderChange({
      locationContactUserId: selectedUser?.id || null,
      locationContactEmail: selectedUser?.email || null,
      locationContactOccupation: null,
      locationContactFullName: selectedUser?.fullName || null,
      locationContactTeamId: null,
      locationContactTeamName: null,
      locationContactMobilePhone: selectedUser?.phone || null,
      locationContactWorkPhone: null,
    });
  };

  const getUserLabel = (user: WOGetCompanyUsers): string => {
    return user.fullName || user.email || user.id;
  };

  React.useEffect(() => {
    if (data?.workOrderCompanyUsers) {
      setUserOptions(
        data?.workOrderCompanyUsers.map((user) => {
          return { label: getUserLabel(user), value: user.id };
        })
      );
    } else {
      setUserOptions([]);
    }
  }, [data]);

  React.useEffect(() => {
    const selectedUser = data?.workOrderCompanyUsers.find(
      (user) => user.id === workOrder.locationContactUserId
    );
    setSelectedContactUser(selectedUser || {});
  }, [data, workOrder]);

  React.useEffect(() => {
    const errors = [];
    if (!workOrder.locationContactUserId && contactUserTouched) {
      errors.push(MessageUtils.commonRequiredFieldErrorMessage);
    }
    setUserErrors(errors);
  }, [workOrder, contactUserTouched]);

  React.useEffect(() => {
    setContactUserTouched(false);
  }, [workOrder.locationContactType]);

  return (
    <EuiFlexGroup
      justifyContent="flexStart"
      direction="column"
      wrap={false}
      gutterSize="none"
      style={{
        width: "100%",
        flexGrow: 1,
        paddingBottom: "80px",
      }}
    >
      <EuiFlexItem
        style={{
          width: "100%",
        }}
      >
        <EuiText className={"columnTitle"}>
          Location Primary Contact Info
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem style={{ width: "100%", marginBottom: euiTheme.size.m }}>
        <Select
          label="Location Company Info"
          options={options}
          required={true}
          disabled={!workOrder?.locationId}
          selectedValue={workOrder.locationContactType}
          onSelect={onContactInfoChange}
        />
      </EuiFlexItem>
      <EuiFlexGroup
        justifyContent="flexStart"
        direction="column"
        wrap={false}
        gutterSize="none"
        style={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <EuiFlexItem
          style={{
            width: "100%",
            flexGrow: 1,
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {workOrder.locationContactType ===
            CompanyContactTypeEnum.USER_SEARCH && (
            <div
              style={{
                marginTop: euiTheme.size.m,
                marginBottom: euiTheme.size.m,
              }}
            >
              <Combobox
                ariaLabel="Contact User Name"
                label="Contact User Name"
                placeholder="Select User"
                required={true}
                loading={loading}
                options={userOptions}
                autoSelect={true}
                invalid={!!userErrors.length}
                errors={userErrors}
                onBlur={() => setContactUserTouched(true)}
                selectedValue={selectedContactUser.id}
                onChange={handleLocationUserIdChange}
              />
            </div>
          )}
          {workOrder.locationContactType !==
            CompanyContactTypeEnum.USER_SEARCH && (
            <InputField
              label="Full Name"
              key="Full Name"
              disabled={
                workOrder.locationContactType !==
                CompanyContactTypeEnum.ADD_MANUALLY
              }
              required={true}
              isInvalid={!workOrder.locationContactFullName}
              value={getFieldValue("locationContactFullName")}
              onInputChange={(value) =>
                onInputChange("locationContactFullName", value)
              }
              style={{
                marginTop: euiTheme.size.m,
                marginBottom: euiTheme.size.m,
              }}
            />
          )}
          {companyContactFields?.length &&
            companyContactFields.map((field) => {
              return (
                <InputField
                  label={field.label}
                  disabled={
                    workOrder.locationContactType !==
                    CompanyContactTypeEnum.ADD_MANUALLY
                  }
                  value={getFieldValue(field.valueField)}
                  key={field.label}
                  required={field.required}
                  isInvalid={field.isInvalid}
                  onInputChange={(value) =>
                    onInputChange(field.valueField, value)
                  }
                  style={{
                    marginTop: euiTheme.size.m,
                    marginBottom: euiTheme.size.m,
                  }}
                />
              );
            })}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};

export default WorkOrderCreateLocationContactInfoPresenter;
