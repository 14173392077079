import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  transparentize,
  useEuiTheme,
} from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import React, { useEffect, useMemo, useState } from "react";

import {
  WOStage,
  WOTransitionReason,
} from "../../../../../graphql/types/queries/workOrderStatuses";
import Accordion from "../../../components/accordion";
import Label from "../../../components/label";
import Select from "../../../components/select";

export interface WorkflowSectionData {
  stageId: string | null;
  reasonId: string | null;
}

interface WorkOrderEditWorkflowPresenterProps {
  sectionData?: WorkflowSectionData;
  stages: WOStage[];
  reasons: WOTransitionReason[];
  disableReasonsSelection?: boolean;
  onStageChange: (stage?: WOStage | null) => void;
  onReasonChange: (stage?: WOTransitionReason | null) => void;
  resetControl?: { reset: boolean };
  stageColor?: string;
}

const WorkOrderEditWorkflowPresenter: React.FC<
  WorkOrderEditWorkflowPresenterProps
> = ({
  sectionData,
  stages,
  reasons,
  disableReasonsSelection,
  onStageChange,
  onReasonChange,
  children,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const minSelectorWidth = "235px";
  const maxSelectorWidth = "235px";
  const [stageOptions, setStageOptions] = useState<
    EuiSuperSelectOption<WOStage>[]
  >([]);
  const [reasonOptions, setReasonOptions] = useState<
    EuiSuperSelectOption<WOTransitionReason>[]
  >([]);

  const selectedStage = useMemo(() => {
    return stageOptions.find(
      (option) => option.value.id === sectionData?.stageId
    )?.value;
  }, [stageOptions, sectionData]);

  const selectedReason = useMemo(() => {
    return reasonOptions.find(
      (option) => option.value.id === sectionData?.reasonId
    )?.value;
  }, [reasonOptions, sectionData]);

  const renderStageOption = (option: WOStage) => {
    return (
      <div style={{ width: "100%" }}>
        <EuiText
          style={{
            padding: `${euiTheme.size.xxs} ${euiTheme.size.s}`,
            borderRadius: euiTheme.border.radius.small,
            border: `1px solid ${option.color || euiTheme.colors.mediumShade}`,
            fontSize: euiTheme.sizes["12px"],
            lineHeight: euiTheme.sizes["18px"],
            color: option.color || "default",
            width: "fit-content",
          }}
          css={css`
            background-color: ${transparentize(
              option.color || euiTheme.colors.mediumShade,
              0.1
            )};
          `}
        >
          {option.name}
        </EuiText>
      </div>
    );
  };

  useEffect(() => {
    let stageOptions: EuiSuperSelectOption<WOStage>[] = [];
    if (!!stages?.length) {
      stageOptions = stages.map((stage) => {
        return {
          value: stage,
          dropdownDisplay: renderStageOption(stage),
          inputDisplay: stage.name,
        };
      });
    }
    setStageOptions(stageOptions);
  }, [stages]);

  useEffect(() => {
    let reasonsOptions: EuiSuperSelectOption<WOTransitionReason>[] = [];
    if (!!reasons?.length) {
      reasonsOptions = reasons.map((resaon) => {
        return {
          value: resaon,
          inputDisplay: resaon.name,
        };
      });
    }
    setReasonOptions(reasonsOptions);
  }, [reasons]);

  return (
    <EuiFlexItem style={{ minWidth: "100%" }} grow={0}>
      <Accordion
        id="wo-workflow"
        label="Workflow"
        required={false}
        initialIsOpen={true}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
          style={{ position: "relative" }}
        >
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Status" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <Select
                placeholder={"Select Status"}
                options={stageOptions}
                selectedValue={selectedStage}
                onSelect={onStageChange}
                compressed={true}
                color={selectedStage?.color}
                fullWidth={false}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Resolution" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <Select
                placeholder={"Select Resolution"}
                options={reasonOptions}
                selectedValue={selectedReason}
                onSelect={onReasonChange}
                compressed={true}
                invalid={
                  !disableReasonsSelection &&
                  !!reasonOptions?.length &&
                  !sectionData?.reasonId
                }
                noOptionsPlaceholder={"There are no available resolutions"}
                disabled={disableReasonsSelection}
                required={!disableReasonsSelection}
                fullWidth={false}
              />
            </div>
          </EuiFlexItem>
          {children}
        </EuiFlexGroup>
      </Accordion>
    </EuiFlexItem>
  );
};

export default WorkOrderEditWorkflowPresenter;
