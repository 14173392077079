import React from "react";

import { WorkOrder } from "../../../../models/workOrder";
import WorkOrderServicesTitlePresenter from "./presenter";

interface WorkOrderServicesTitleProps {
  workOrder: WorkOrder;
  placeholder?: string;
  hideRequiredMark?: boolean;
  changeIsEdit?: (isEditMode: boolean) => void;
  onTitleChange: (title: string) => void;
}

const WorkOrderServicesTitle: React.FC<WorkOrderServicesTitleProps> = ({
  workOrder,
  placeholder,
  hideRequiredMark,
  changeIsEdit,
  onTitleChange,
}) => {
  return (
    <>
      <WorkOrderServicesTitlePresenter
        workOrder={workOrder}
        hideRequiredMark={hideRequiredMark}
        placeholder={placeholder}
        changeIsEdit={changeIsEdit}
        onTitleChange={onTitleChange}
      />
    </>
  );
};

export default WorkOrderServicesTitle;
