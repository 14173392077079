import { Icon, IconProps } from "@chakra-ui/react";

const KitchenHood = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M109 69.648c0-.685-.268-1.36-.772-1.864L77.934 37.49V21.637A2.637 2.637 0 0 0 75.296 19H52.703a2.637 2.637 0 0 0-2.637 2.637V37.49L19.772 67.784A2.637 2.637 0 0 0 19 69.648v11.297a2.637 2.637 0 0 0 2.637 2.637h84.726A2.637 2.637 0 0 0 109 80.945V69.648ZM55.34 24.273h17.32v11.672H55.34V24.273ZM53.795 41.22h20.41l25.793 25.793H28.002l25.793-25.793Zm49.932 37.09H24.273v-6.024h79.454v6.024ZM30.112 89.606h-.003a2.636 2.636 0 1 0 .003 5.273 5.84 5.84 0 0 1 5.833 5.833v5.651a2.637 2.637 0 1 0 5.274 0v-5.651c0-6.124-4.983-11.106-11.107-11.106ZM97.888 89.606c-6.124 0-11.107 4.982-11.107 11.106v5.651a2.636 2.636 0 1 0 5.274 0v-5.651a5.84 5.84 0 0 1 5.833-5.833h.002a2.636 2.636 0 1 0-.002-5.273ZM49.882 89.606h-.003a2.636 2.636 0 1 0 .003 5.273 3.006 3.006 0 0 1 3.009 3.009v8.475a2.636 2.636 0 1 0 5.273 0v-8.475a8.276 8.276 0 0 0-8.282-8.282ZM78.118 89.606a8.277 8.277 0 0 0-8.282 8.282v8.475a2.636 2.636 0 1 0 5.273 0v-8.475a3.01 3.01 0 0 1 3.01-3.009h.002a2.636 2.636 0 1 0-.003-5.273Z"
      fill="currentColor"
    />
  </Icon>
);

export default KitchenHood;
