import { cloneDeep } from "lodash";

import countryList from "./addressfield";

export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  countryAlpha2?: string;
  coordinates?: number[];
}

const defaultFields = Object.freeze({
  address1: { label: "Address" },
  address2: { label: "Address 2" },
  city: { label: "City" },
  state: { label: "State / Province", options: undefined },
  zip: { label: "Zip" },
});

export const allCountries = countryList.options;

export const countryFromAlpha2 = (code: string) =>
  allCountries.find((c) => c.iso === code);

export const countryNameFromAlpha2 = (code: string) =>
  countryFromAlpha2(code)?.label;

const countryUs = countryFromAlpha2("US");
const countryCanada = countryFromAlpha2("CA");
export const countryOptions = [
  {
    label: countryUs?.label || "",
    query: countryUs?.label || "",
    value: countryUs?.iso || "",
  },
  {
    label: countryCanada?.label || "",
    query: countryCanada?.label || "",
    value: countryCanada?.iso || "",
  },
  {
    label: "───────────────",
    value: "",
    query: "",
    disabled: true,
  },
];
for (let i = 0; i < allCountries.length; i++) {
  const country = allCountries[i];
  if (!["US", "CA", "IR", "PS"].includes(country.iso)) {
    countryOptions.push({
      label: country.label,
      query: country.label,
      value: country.iso,
    });
  }
}

export const getFieldsOfCountry = (code: string) => {
  const countryFields = cloneDeep(defaultFields);
  const country = countryFromAlpha2(code);
  if (country) {
    country.fields.forEach((field: any) => {
      if (field.thoroughfare)
        countryFields.address1.label = field.thoroughfare.label;
      if (field.premise) countryFields.address2.label = field.premise.label;
      if (field.locality) {
        field.locality.forEach((l: any) => {
          if (l.localityname) countryFields.city.label = l.localityname.label;
          if (l.postalcode) countryFields.zip.label = l.postalcode.label;
          if (l.administrativearea) {
            countryFields.state.label = l.administrativearea.label;
            countryFields.state.options = l.administrativearea.options;
          }
        });
      }
    });
  }
  return countryFields;
};

export const addressToLinesString = (address: Address) =>
  `${address.address1}${
    address.address2 ? `, ${address.address2}` : ""
  }`.trim();

export const addressToNonLinesString = (address: Address) =>
  `${address.city ? address.city + "," : ""} ${address.state || ""} ${
    address.zip || ""
  }`.trim();

export const addressToString = (address: Address) =>
  `${address.address1 ? address.address1 + "," : ""} ${
    address.address2 ? address.address2 + "," : ""
  } ${address.city ? address.city + "," : ""} ${address.state || ""} ${
    address.zip || ""
  } ${
    address.countryAlpha2 && address.countryAlpha2 !== "US"
      ? countryNameFromAlpha2(address.countryAlpha2)
      : ""
  }`.trim();

export const addressToMultiLineString = (address: Address) =>
  `${address.address1 || ""} ${address.address2 || ""}\n ${
    address.city ? address.city : ""
  } ${address.state || ""} ${address.zip || ""} ${
    address.countryAlpha2 && address.countryAlpha2 !== "US"
      ? countryNameFromAlpha2(address.countryAlpha2)
      : ""
  }`.trim();

export const googleMapsUrl = (address: Address): string => {
  return `https://www.google.com/maps/search/?api=1&query=${addressToString(
    address
  )}`;
};
