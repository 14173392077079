import "@elastic/eui/dist/eui_theme_light.css";

import "./elasticUITheme.scss";

import { useReactiveVar } from "@apollo/client";
import {
  EuiGlobalToastList,
  EuiProvider,
  EuiThemeProvider,
} from "@elastic/eui";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import React, { FC } from "react";

import { toastVar } from "../pages/workOrders/helpers/workOrdersReactiveVariables";
import foundationTheme from "../theme/foundations";
import colors from "../theme/foundations/colors";

const ElasticUIProvider: FC = ({ children }) => {
  const errorAppearTime = 6000;
  const toasts = useReactiveVar(toastVar);
  const theme = {
    ...foundationTheme,
    themeColors: colors,
  };

  const removeToast = (removedToast: Toast) => {
    toastVar(toasts.filter((toast) => toast.id !== removedToast.id));
  };
  return (
    <>
      <EuiProvider colorMode={"light"} globalStyles={false}>
        <EuiThemeProvider modify={theme}>{children}</EuiThemeProvider>
        <EuiGlobalToastList
          toasts={toasts}
          dismissToast={removeToast}
          toastLifeTimeMs={errorAppearTime}
        />
      </EuiProvider>
    </>
  );
};

export default ElasticUIProvider;
