import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  FloorPlanForMapFragmentFragment,
  useAssetWithTypeQuery,
} from "../../../../graphql/graphql";
import { isNativeWebViewVar } from "../../../../graphql/reactiveVariables";
import AssetCreateForm from "../../../../pages/assets/create";
import { idSchema, yupObject } from "../../../../utils/validation";
import AssetIcon from "../../assetIcons";
import PageSpinner from "../../pageSpinner";
import SelectCombobox from "../../selectCombobox";

declare let window: any;

interface AssetCreateProps {
  floorPlan: FloorPlanForMapFragmentFragment;
  loading: boolean;
  assetTypeIdToAdd?: string;
  handleCreateNewAsset: (assetId: string) => Promise<void>;
  handleCancel: () => void;
  typeToAdd?: "new" | "existing";
}

const AssetCreate: FC<AssetCreateProps> = ({
  floorPlan,
  loading,
  handleCreateNewAsset,
  handleCancel,
  assetTypeIdToAdd,
  typeToAdd,
}) => {
  const [isNative] = useState(isNativeWebViewVar());
  const { data: assetsData } = useAssetWithTypeQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const filteredAssets = useMemo(() => {
    const assets = assetsData?.assets;
    if (!assets) return [];
    return assets.filter(
      (asset) =>
        !floorPlan.floorPlanAssets.some(
          (fpa) => fpa.asset.id === asset.id && fpa.mapPosition
        )
    );
  }, [assetsData?.assets, floorPlan.floorPlanAssets]);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [type, setType] = useState<"new" | "existing">();

  useEffect(() => {
    if (typeToAdd) setType(typeToAdd);
  }, [typeToAdd]);

  useEffect(() => {
    if (assetTypeIdToAdd || (filteredAssets && !filteredAssets.length)) {
      setType("new");
    }
  }, [assetTypeIdToAdd, filteredAssets, setType]);

  useEffect(() => {
    if (type === "new" && isNative) {
      if (window.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            eventType: "CA:CreateAsset",
            payload: { assetTypeIdToAdd },
          })
        );
      }
    }
  }, [assetTypeIdToAdd, isNative, type]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.data.eventType &&
        event.data.eventType === "CA:PlanEditor:AssetCreate"
      ) {
        handleCreateNewAsset(event.data.payload.assetId);
      } else if (
        event.data.eventType &&
        event.data.eventType === "CA:PlanEditor:AssetCancel"
      ) {
        handleCancel();
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, [handleCancel, handleCreateNewAsset]);

  return type === "new" ? (
    <>
      {isNative ? null : (
        <AssetCreateForm
          afterCreate={handleCreateNewAsset}
          afterCancel={handleCancel}
          loading={loading}
          assetTypeIdToAdd={assetTypeIdToAdd}
        />
      )}
    </>
  ) : type === "existing" ? (
    <Drawer isOpen placement="right" onClose={handleCancel}>
      <DrawerOverlay zIndex={1600}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Select an asset from the list</DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{ assetId: "" }}
              validationSchema={ExistingAssetAddToMapValidationSchema}
              onSubmit={(data) => handleCreateNewAsset(data.assetId)}
            >
              <Form noValidate>
                {!!filteredAssets?.length && (
                  <SelectCombobox
                    label="Select the asset you want to add to this plan"
                    name="assetId"
                    sortOptions
                    options={filteredAssets.map((asset) => ({
                      label: (
                        <Flex alignItems="center">
                          <AssetIcon
                            iconName={asset.assetType.iconName}
                            iconColor={asset.assetType.misc.resolvedIconColor}
                            iconType={asset.assetType.iconType}
                            iconSize="xs"
                            marginRight="2"
                          />
                          {asset.name}
                        </Flex>
                      ),
                      query: asset.name,
                      value: asset.id,
                    }))}
                    autoFocus
                  />
                )}
                <Button
                  width="full"
                  type="submit"
                  isLoading={loading}
                  marginTop="6"
                >
                  Save
                </Button>
              </Form>
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  ) : (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={handleCancel}
      isOpen
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogCloseButton />
          {filteredAssets.length ? (
            <>
              <AlertDialogBody textAlign="center" paddingRight="12">
                Do you you want to add an existing asset or a new asset to this
                plan?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  onClick={() => setType("existing")}
                  width="48%"
                  variant="outline"
                  colorScheme="secondary"
                >
                  Existing Asset
                </Button>
                <Button
                  onClick={() => setType("new")}
                  variant="outline"
                  colorScheme="secondary"
                  ml="4%"
                  width="48%"
                >
                  New Asset
                </Button>
              </AlertDialogFooter>
            </>
          ) : (
            <PageSpinner />
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AssetCreate;

export const ExistingAssetAddToMapValidationSchema = yupObject().shape({
  assetId: idSchema.label(
    "Please select the asset you want to add to this plan"
  ),
});

export type ExistingAssetAddToMapFormData = {
  assetId: string;
};
