import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ASSET_MAINTENANCE_DELETED_MESSAGE,
  ASSET_MAINTENANCE_SCHEDULE_DELETED_MESSAGE,
} from "../../../constants/lang/en";
import {
  CompanyStartUpDocument,
  useAssetLazyQuery,
  useMaintenanceDeleteMutation,
  useMaintenanceToggleDeletedScheduleTimestampMutation,
  useMaintenanceWithContactsQuery,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";

interface MaintenanceDeleteProps {
  maintenanceId: string;
  assetId?: string;
  onClose: () => void;
  maintenanceScheduleTimestamp?: Date;
  recurring: boolean;
}

const MaintenanceDelete: React.FC<MaintenanceDeleteProps> = ({
  maintenanceId,
  recurring,
  assetId,
  onClose,
  maintenanceScheduleTimestamp,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data } = useMaintenanceWithContactsQuery({
    variables: { id: maintenanceId },
  });
  const [refetchAsset] = useAssetLazyQuery({ fetchPolicy: "network-only" });
  const client = useApolloClient();
  const [deleting, setDeleting] = useState(false);
  const cancelRef1 = React.useRef<HTMLButtonElement>(null);
  const [maintenanceDeleteMutation] = useMaintenanceDeleteMutation({
    refetchQueries: [{ query: CompanyStartUpDocument }],
  });
  const [maintenanceToggleDeletedScheduleTimestampMutation] =
    useMaintenanceToggleDeletedScheduleTimestampMutation();

  const deleteMaintenance = useCallback(async () => {
    setDeleting(true);
    try {
      await maintenanceDeleteMutation({
        variables: { id: maintenanceId },
      });
      toast({
        description: ASSET_MAINTENANCE_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      if (assetId) {
        await refetchAsset({ variables: { id: assetId } });
      }
      client.cache.evict({
        id: `Maintenance:${maintenanceId}`,
      });
      navigate(-1);
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setDeleting(false);
      onClose();
    }
  }, [
    maintenanceDeleteMutation,
    maintenanceId,
    toast,
    assetId,
    client.cache,
    navigate,
    refetchAsset,
    onClose,
  ]);

  const deleteMaintenanceSchedule = useCallback(async () => {
    if (!maintenanceScheduleTimestamp || !recurring) return;
    setDeleting(true);
    try {
      await maintenanceToggleDeletedScheduleTimestampMutation({
        variables: {
          id: maintenanceId,
          timestamp: maintenanceScheduleTimestamp,
        },
      });
      toast({
        description: ASSET_MAINTENANCE_SCHEDULE_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      if (assetId) {
        await refetchAsset({ variables: { id: assetId } });
      }
      navigate(-1);
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setDeleting(false);
      onClose();
    }
  }, [
    assetId,
    maintenanceId,
    maintenanceScheduleTimestamp,
    maintenanceToggleDeletedScheduleTimestampMutation,
    navigate,
    onClose,
    recurring,
    refetchAsset,
    toast,
  ]);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef1}
      onClose={onClose}
      isOpen
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogBody textAlign="center">
            Do you want to delete this service event or the entire series?
            <br />
            <Text as="strong">{data?.maintenance.misc.resolvedName}</Text>
          </AlertDialogBody>
          <AlertDialogFooter flexWrap="wrap">
            <Button
              ref={cancelRef1}
              onClick={onClose}
              width="full"
              isLoading={deleting}
            >
              No, Don't Delete!
            </Button>
            {!!maintenanceScheduleTimestamp && recurring && (
              <Button
                onClick={deleteMaintenanceSchedule}
                colorScheme="red"
                mt="6"
                size="md"
                variant="link"
                width="full"
                isLoading={deleting}
              >
                Delete this service event only
              </Button>
            )}
            <Button
              onClick={deleteMaintenance}
              colorScheme="red"
              mt="6"
              size="md"
              variant="link"
              width="full"
              isLoading={deleting}
            >
              Delete entire series
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default MaintenanceDelete;

gql`
  mutation MaintenanceToggleDeletedScheduleTimestamp(
    $id: UUID!
    $timestamp: Timestamp!
  ) {
    maintenanceToggleDeletedScheduleTimestamp(id: $id, timestamp: $timestamp) {
      ...MaintenanceFragment
    }
  }
  mutation MaintenanceDelete($id: UUID!) {
    maintenanceDelete(id: $id)
  }
`;
