import {
  Box,
  Center,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { faChevronDown, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Column, useExpanded, useFlexLayout, useTable } from "react-table";

import AssetsStatus from "../../../components/elements/assetsStatus";
import Link from "../../../components/elements/link";
import PageSpinner from "../../../components/elements/pageSpinner";
import TooltipName from "../../../components/elements/tooltipName";
import AddCircle from "../../../components/icons/addCircle";
import { locationTableKeysVar } from "../../../graphql/reactiveVariables";
import useLocationAssets from "../../../hooks/useLocationAssets";
import useLocations, {
  LocationWithChildrenCheck,
} from "../../../hooks/useLocations";
import { getRoutePath } from "../../../router";
import {
  addressToLinesString,
  addressToNonLinesString,
} from "../../../utils/address";
import { getPlanIdForLocation } from "../../../utils/lastOpenedPlanForLocation";
import TableIndex from "./index";

interface LocationsTableProps {
  locations: LocationWithChildrenCheck[];
  canAddLocation: boolean;
  reactTableStateKey: string;
  depth: number;
}

const LocationsTable: React.FC<LocationsTableProps> = ({
  locations,
  canAddLocation = false,
  reactTableStateKey,
  depth,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const columns = React.useMemo(() => {
    const locationNameConfig = {
      Header: "Name",
      id: "name",
      accessor: (location: LocationWithChildrenCheck) => location,
      width: 250,
      Cell: ({
        value,
        row,
      }: {
        value: LocationWithChildrenCheck;
        row: any;
      }) => {
        const assets = useLocationAssets(value);
        return (
          <Flex
            height="full"
            backgroundColor="secondary.10"
            marginLeft={`${depth * 2}rem`}
            pl="4"
            pr="2"
            py="3"
            alignItems="center"
            position="relative"
            sx={{
              "&:before": depth
                ? {
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    borderTop: "1px solid",
                    borderColor: "secondary.100",
                    width: "16px",
                    left: "-16px",
                  }
                : {},
            }}
          >
            <AssetsStatus assets={assets} />
            {value.childrenAvailable ? (
              <Box paddingRight={{ base: "1", md: "2" }}>
                <IconButton
                  {...row.getToggleRowExpandedProps({
                    style: { opacity: 0.5 },
                    title: undefined,
                  })}
                  aria-label={`${
                    row.isExpanded ? "Close" : "Open"
                  } sublocations`}
                  variant="icon"
                  colorScheme="gray"
                >
                  <Box transform={row.isExpanded ? "rotate(180deg)" : ""}>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      aria-label="Collapse Row"
                    />
                  </Box>
                </IconButton>
              </Box>
            ) : null}
            <Box width={{ base: "80%", md: "full" }}>
              <Link
                to={getRoutePath("locationsShow", { locationId: value.id })}
                variant="link"
                colorScheme="secondary"
                fontSize="sm"
                maxWidth="full"
              >
                <TooltipName name={value.name} />
              </Link>
            </Box>
          </Flex>
        );
      },
    };

    const addSublocationConfig = {
      id: "add_sublocations",
      width: 50,
      Header: "",
      accessor: (location: LocationWithChildrenCheck) => location,
      Cell: ({ value }: { value: LocationWithChildrenCheck }) => (
        <Center
          height="full"
          backgroundColor="secondary.10"
          paddingX="2"
          paddingY="3"
        >
          {canAddLocation ? (
            <Tooltip label="Add new sublocation" hasArrow placement="bottom">
              <Link
                to={`${getRoutePath("locationsCreate")}?parentId=${value.id}`}
                aria-label="Add new sublocation"
                variant="icon"
                colorScheme="graySecondary"
              >
                <AddCircle />
              </Link>
            </Tooltip>
          ) : null}
        </Center>
      ),
    };

    const locationAddressConfig = {
      Header: "Address",
      id: "address",
      accessor: (location: LocationWithChildrenCheck) => location,
      width: 150,
      Cell: ({ value }: { value: LocationWithChildrenCheck }) => (
        <Flex
          height="full"
          backgroundColor="secondary.10"
          paddingX="2"
          paddingY="3"
        >
          <Box>
            {value.address1 ? (
              <Text as="span" color="black">
                {addressToLinesString(value)}
                <br />
                {addressToNonLinesString(value)}
              </Text>
            ) : (
              <Text as="span" display={{ md: "none" }}>
                -
              </Text>
            )}
          </Box>
        </Flex>
      ),
    };

    const locationTypeConfig = {
      Header: <Text textAlign="center">Type</Text>,
      id: "type",
      accessor: (location: LocationWithChildrenCheck) => location,
      width: 100,
      Cell: ({ value }: { value: LocationWithChildrenCheck }) => (
        <Center
          height="full"
          backgroundColor="secondary.10"
          paddingX="2"
          paddingY="3"
        >
          {value.type ? (
            <Text as="span" fontSize="sm">
              {value.type}
            </Text>
          ) : (
            <Text as="span" display={{ md: "none" }}>
              -
            </Text>
          )}
        </Center>
      ),
    };

    const locationPlanConfig = {
      Header: <Text textAlign="center">Plans</Text>,
      id: "floorPlans",
      accessor: (location: LocationWithChildrenCheck) => location,
      width: 100,
      Cell: ({ value }: { value: LocationWithChildrenCheck }) => (
        <Center
          height="full"
          backgroundColor="secondary.10"
          paddingX="2"
          paddingY="3"
        >
          {value.floorPlans && value.floorPlans.length > 0 ? (
            <Tooltip label="View plans" hasArrow placement="bottom">
              <Link
                to={`?CaAprType=planEditor&planId=${
                  getPlanIdForLocation(value.id) || value.floorPlans[0].id
                }`}
                aria-label="View plans"
                variant="icon"
                colorScheme="graySecondary"
                size="sm"
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </Tooltip>
          ) : canAddLocation ? (
            <Tooltip label="Add new plan" hasArrow placement="bottom">
              <Link
                to={`${getRoutePath("locationsPlansCreate", {
                  locationId: value.id,
                })}`}
                aria-label="Add new plan"
                variant="icon"
                colorScheme="graySecondary"
                size="sm"
              >
                <AddCircle />
              </Link>
            </Tooltip>
          ) : null}
        </Center>
      ),
    };

    const locationAssetConfig = {
      Header: () => <Text textAlign="center">Assets</Text>,
      id: "assets_count",
      accessor: (location: LocationWithChildrenCheck) => location,
      width: 65,
      Cell: ({ value }: { value: LocationWithChildrenCheck }) => {
        const assets = useLocationAssets(value);
        return (
          <Center
            flexDirection={{ base: "row", md: "column" }}
            height="full"
            backgroundColor="secondary.10"
            paddingX="2"
            paddingY="3"
          >
            <Text
              as="span"
              textAlign={{ base: "left", md: "center" }}
              fontSize="sm"
              color="black"
            >
              {assets.length}
            </Text>
          </Center>
        );
      },
    };

    let reactTableColumns: Column<LocationWithChildrenCheck>[] = [
      locationNameConfig,
      addSublocationConfig,
    ];
    if (!isMobile) {
      reactTableColumns.push(locationAddressConfig);
      reactTableColumns.push(locationTypeConfig);
    }
    reactTableColumns.push(locationPlanConfig);
    if (!isMobile) reactTableColumns.push(locationAssetConfig);

    return reactTableColumns;
  }, [isMobile, canAddLocation, depth]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data: locations,
      initialState: {
        expanded: locationTableKeysVar()[reactTableStateKey] || {},
      },
    },
    useFlexLayout,
    useExpanded
  );

  React.useEffect(() => {
    locationTableKeysVar({
      ...locationTableKeysVar(),
      [reactTableStateKey]: state.expanded,
    });
  }, [reactTableStateKey, state.expanded]);

  return (
    <Box>
      <Box {...getTableProps()}>
        {depth === 0 &&
          headerGroups.map((headerGroup) => (
            <Box key={headerGroup.getHeaderGroupProps().key}>
              <Box
                {...headerGroup.getHeaderGroupProps()}
                alignItems="center"
                fontSize="sm"
                lineHeight="normal"
                color="gray.600"
                backgroundColor="white"
                position="relative"
                zIndex="1"
                paddingX={isMobile ? "2" : "0"}
              >
                {headerGroup.headers.map((column) => (
                  <Box
                    padding={{ base: "2", md: "3" }}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        <Box {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.original.id}>
                <Box
                  width="full"
                  alignItems="stretch"
                  fontSize="sm"
                  marginBottom="3"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <Box {...cell.getCellProps()}>{cell.render("Cell")}</Box>
                  ))}
                </Box>
                {row.isExpanded && row.original.childrenAvailable ? (
                  <LocationWithChildren
                    locationId={row.original.id}
                    depth={depth}
                    canAddLocation={canAddLocation}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default LocationsTable;

const LocationWithChildren: React.FC<{
  locationId: string;
  depth: number;
  canAddLocation: boolean;
}> = ({ locationId, depth, canAddLocation }) => {
  const [locations] = useLocations(undefined, locationId);
  return locations ? (
    <Box
      position="relative"
      sx={{
        "&:after": {
          content: '""',
          position: "absolute",
          top: "-12px",
          backgroundColor: "secondary.100",
          width: "1px",
          height: "calc(100% - 10px)",
          left: `${depth * 2 + 1}rem`,
        },
        "&:last-of-type:before": {
          content: '""',
          position: "absolute",
          top: "-34px",
          backgroundColor: "white",
          width: "3px",
          height: "calc(100% + 12px)",
          left: `calc(${depth * 2}rem - 16px)`,
          zIndex: 1,
        },
      }}
    >
      <TableIndex
        locations={locations}
        depth={depth + 1}
        canAddLocation={canAddLocation}
      />
    </Box>
  ) : (
    <PageSpinner height={120} />
  );
};
