const TOKEN = "redirectTo";

export const setRedirectUrl = (url: string): boolean => {
  if (!url) return false;
  sessionStorage.setItem(TOKEN, url);
  return true;
};

export const getRedirectUrl = () => sessionStorage.getItem(TOKEN);

export const removeRedirectUrl = () => sessionStorage.removeItem(TOKEN);
