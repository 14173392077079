import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useLocationQuery } from "../../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../../graphql/reactiveVariables";
import useTopLocation from "../../../hooks/useTopLocation";
import ROLES from "../../../roles";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface LocationsShowPageProps {}

const LocationsShowPage: React.FC<LocationsShowPageProps> = ({ children }) => {
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const [searchParams] = useSearchParams();
  const { locationId } = useParams();
  const { data, refetch: refetchLocation } = useLocationQuery({
    variables: { id: locationId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const parentLocation = useTopLocation(locationId || "");

  useEffect(() => {
    if (searchParams.get("reload")) refetchLocation();
  }, [searchParams, refetchLocation]);

  useEffect(() => {
    layoutOptionsVar({
      backLink: getRoutePath("locations"),
      backText: "Back to all locations",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{parentLocation ? parentLocation.name : "Locations"}</title>
      </Helmet>
      {data && parentLocation ? (
        <Presenter
          location={data.location}
          parentLocation={parentLocation}
          canEditLocation={ROLES.locationsEdit.includes(
            currentCompanyRole.role
          )}
          canAddLocation={ROLES.locationsCreate.includes(
            currentCompanyRole.role
          )}
          canAddPlan={ROLES.floorPlansCreate.includes(currentCompanyRole.role)}
          canEditPlan={ROLES.floorPlansEdit.includes(currentCompanyRole.role)}
          canDeletePlan={ROLES.floorPlansDelete.includes(
            currentCompanyRole.role
          )}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default LocationsShowPage;

gql`
  query Location($id: UUID!) {
    location(id: $id) {
      ...LocationWithRelationsFragment
    }
  }

  mutation LocationDelete($id: UUID!) {
    locationDelete(id: $id)
  }
`;
