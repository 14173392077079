import React from "react";

import { WOLocation } from "../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrderCreateRedirectData } from "../../models/createWORedirectData";
import SublocationSelectModalPresenter from "./presenter";

export type SublocationSelectModalHeaderProps = {
  onWOCreateRedirectDataChange: (data: WorkOrderCreateRedirectData) => void;
  isOpen?: boolean;
  availableLocationIds?: string[];
  closeModal: () => void;
  onDataAccepted: () => void;
  locations?: WOLocation[];
  locationsLoading?: boolean;
  WOCreateData?: WorkOrderCreateRedirectData;
};

const SublocationSelectModal: React.FC<SublocationSelectModalHeaderProps> = ({
  onWOCreateRedirectDataChange,
  WOCreateData,
  isOpen,
  availableLocationIds,
  closeModal,
  onDataAccepted,
  locations,
  locationsLoading,
}: SublocationSelectModalHeaderProps) => {
  return (
    <>
      <SublocationSelectModalPresenter
        isOpen={isOpen}
        locations={locations || []}
        closeModal={closeModal}
        locationsLoading={locationsLoading}
        WOCreateData={WOCreateData}
        onDataAccepted={onDataAccepted}
        availableLocationIds={availableLocationIds}
        dataChanged={onWOCreateRedirectDataChange}
      />
    </>
  );
};

export default SublocationSelectModal;
