import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import useCompanyUsers from "../../../hooks/useCompanyUsers";
import CompanyTeamsForm from "../../../pages/teams/create/form";
import MultiSelect from "../multiSelect";

interface ContactCompanyUserFieldProps {
  name: string;
  label?: string;
  loading?: boolean;
  excludedCompanyUserIds?: string[];
  autoFocus?: boolean;
}

const ContactCompanyUserField: React.FC<ContactCompanyUserFieldProps> = ({
  name,
  label = "Select User(s)",
  loading,
  excludedCompanyUserIds,
  autoFocus = false,
}) => {
  const [teamValue, setTeamValue] = React.useState("");
  const { values, setFieldValue } = useFormikContext<any>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const companyUsers = useCompanyUsers(excludedCompanyUserIds);

  const handleNoResultClick = (value: string) => {
    if (!value) return;
    setTeamValue(value);
    onOpen();
  };

  const handleCreate = (id: string) => {
    setFieldValue(name, [...get(values, name, []), id]);
    onClose();
  };

  return (
    <>
      <MultiSelect
        sortOptions
        items={companyUsers}
        autoComplete="off"
        name={name}
        label={label}
        loading={loading}
        handleNoResultClick={handleNoResultClick}
        noResultLabel="Invite user to your team"
        autoFocus={autoFocus}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay zIndex={1700}>
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Send invite</ModalHeader>
            <ModalBody paddingBottom="6">
              <CompanyTeamsForm
                afterCreate={handleCreate}
                onClose={onClose}
                defaultEmailValue={teamValue}
              />
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default ContactCompanyUserField;
