import { EuiAccordion, EuiPanel, useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import React from "react";

import Label from "./label";

interface AccordionProps {
  id: string;
  label?: string;
  required?: boolean;
  initialIsOpen?: boolean;
  simplifyView?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  id,
  label,
  required,
  initialIsOpen,
  simplifyView,
  children,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();

  const buttonContent = (
    <Label
      label={label}
      required={required}
      color={euiTheme.colors.title}
      style={{
        lineHeight: euiTheme.sizes["21px"],
        fontSize: euiTheme.size.base,
        fontWeight: 500,
      }}
    />
  );

  return (
    <EuiAccordion
      id={id}
      buttonContent={buttonContent}
      arrowDisplay="right"
      initialIsOpen={initialIsOpen}
      className={simplifyView ? "no-borders" : ""}
      css={css`
        & {
          border: ${euiTheme.border.width.thin} solid ${euiTheme.border.color};
          border-radius: ${euiTheme.border.radius.medium};

          .euiAccordion__triggerWrapper {
            padding: ${simplifyView
              ? 0
              : `${euiTheme.size.s} ${euiTheme.size.base}`};
            border-bottom: ${euiTheme.border.width.thin} solid
              ${euiTheme.border.color};

            .euiAccordion__buttonContent .euiText {
              line-height: ${euiTheme.sizes["21px"]};
              font-size: ${euiTheme.size.base};
              font-weight: 500;
            }
          }

          .euiAccordion__childWrapper .euiPanel {
            padding: ${simplifyView ? 0 : `${euiTheme.size.base}`};
          }
        }

        &.no-borders {
          border: none;

          .euiAccordion__triggerWrapper {
            border-bottom: none;
          }
        }
      `}
    >
      <EuiPanel>{children}</EuiPanel>
    </EuiAccordion>
  );
};

export default Accordion;
