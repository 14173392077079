import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";
import React from "react";

import { FloorPlanForMapFragmentFragment } from "../../../../graphql/graphql";
import PlanPreviewPresenter from "./presenter";

interface planPreviewProps {
  plan?: FloorPlanForMapFragmentFragment | null;
  assetId?: string | null;
  companyId?: string | null;
  showAffectedAreas?: boolean;
}

const PlanPreview: React.FC<planPreviewProps> = ({
  plan,
  assetId,
  showAffectedAreas,
}) => {
  return (
    <>
      {plan && assetId ? (
        <PlanPreviewPresenter
          plan={plan}
          assetId={assetId}
          showAffectedAreas={showAffectedAreas}
        />
      ) : (
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          style={{ minWidth: "100%", minHeight: "inherit" }}
        >
          <EuiLoadingSpinner size="xxl" />
        </EuiFlexGroup>
      )}
    </>
  );
};

export default PlanPreview;
