import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { useSessionQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import { getRoutePath } from "../../router";
import Presenter from "./presenter";

interface ForgotPasswordPageProps {}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = () => {
  const { data } = useSessionQuery();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data?.session?.isLoggedIn) {
      navigate(getRoutePath("dashboard"));
    }
  }, [data, navigate]);

  React.useEffect(() => {
    layoutOptionsVar({
      title: "Forgot your password?",
      subTitle: "We'll send you an email with a link to reset your password.",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Forgot Your Password?</title>
      </Helmet>
      <Presenter />
    </>
  );
};

export default ForgotPasswordPage;
