import { Box, SimpleGrid } from "@chakra-ui/react";

import Link from "../../components/elements/link";
import Assets from "../../components/icons/asset";
import Dashboard from "../../components/icons/dashboard";
import Teams from "../../components/icons/team";
import { getRoutePath } from "../../router";
import { mainLayoutPaddingX } from "../../utils/layout";

interface ReportsPagePresenterProps {}

const ReportsPagePresenter: React.FC<ReportsPagePresenterProps> = () => {
  const boxProps = {
    variant: "unstyled",
    display: "flex",
    flexDir: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "full",
    p: { base: 4, md: 8 },
    height: "auto",
    bgColor: "gray.50",
    _hover: { boxShadow: "lg" },
    textAlign: "left",
    fontSize: "md",
    whiteSpace: "normal",
    fontWeight: "400",
    role: "group",
  } as any;

  const iconProps = {
    boxSize: "8",
    color: "gray.800",
    _groupHover: { color: "primary.500" },
  };

  const textProps = {
    mt: "3",
    fontSize: "15px",
  } as any;

  return (
    <Box paddingX={mainLayoutPaddingX}>
      <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={6} w="full">
        <Link {...boxProps} to={getRoutePath("userActivityReports")}>
          <Teams {...iconProps} />
          <Box {...textProps}>User Activity</Box>
        </Link>
        <Link {...boxProps} to={getRoutePath("maintenanceReports")}>
          <Dashboard {...iconProps} />
          <Box {...textProps}>Service &amp; Maintenance</Box>
        </Link>
        <Link {...boxProps} to={getRoutePath("assetReports")}>
          <Assets {...iconProps} />
          <Box {...textProps}>Assets</Box>
        </Link>
        {/* <Link {...boxProps} to={getRoutePath("planReports")}>
          <Files {...iconProps} />
          <Box {...textProps}>Plans</Box>
        </Link> */}
      </SimpleGrid>
    </Box>
  );
};

export default ReportsPagePresenter;
