import { Box, Flex, Menu, MenuItem, MenuList, Tooltip } from "@chakra-ui/react";
import React from "react";

import CenteredFullHeight from "../../components/elements/centeredFullHeight";
import LabeledMenuButton from "../../components/elements/labeledMenuButton";
import Link from "../../components/elements/link";
import MobileAccordion from "../../components/elements/mobileAccordion";
import AddCircle from "../../components/icons/addCircle";
import {
  LocationConfigQuery,
  useUpdateLocationConfigMutation,
} from "../../graphql/graphql";
import { LocationWithChildrenCheck } from "../../hooks/useLocations";
import { getRoutePath } from "../../router";
import { sortByOptions } from "../../utils/sort";
import LocationTable from "./table";

interface LocationsPresenterProps {
  locations: LocationWithChildrenCheck[];
  locationConfigData: LocationConfigQuery;
  canAddLocation: boolean;
}

const LocationsPresenter: React.FC<LocationsPresenterProps> = ({
  locations,
  locationConfigData,
  canAddLocation,
}) => {
  const locationConfig = locationConfigData.locationConfig;
  const [updateLocationConfigMutation] = useUpdateLocationConfigMutation();

  const setSort = React.useCallback(
    (value) => {
      const [sortBy, sortOrder] = value.split("-");
      updateLocationConfigMutation({
        variables: { sortBy, sortOrder },
      });
    },
    [updateLocationConfigMutation]
  );

  const selectedSortOption = React.useMemo(
    () =>
      sortByOptions.find(
        (option) =>
          option.value ===
          `${locationConfig.sortBy}-${locationConfig.sortOrder}`
      ),
    [locationConfig]
  );

  return (
    <Box>
      {locations.length > 0 ? (
        <>
          <Flex
            alignItems={{ base: "flex-start", md: "center" }}
            justifyContent="space-between"
            marginBottom="6"
            gap="2"
          >
            <MobileAccordion>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <LabeledMenuButton
                      isOpen={isOpen}
                      value={
                        selectedSortOption ? selectedSortOption.label : "Sort"
                      }
                    />
                    <MenuList>
                      {sortByOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => setSort(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            </MobileAccordion>
            <Box>
              {canAddLocation && (
                <Tooltip label="Add new location" hasArrow placement="left">
                  <Link
                    to={getRoutePath("locationsCreate")}
                    aria-label="Add new location"
                    variant="icon"
                    colorScheme="secondary"
                  >
                    <AddCircle boxSize="32px" />
                  </Link>
                </Tooltip>
              )}
            </Box>
          </Flex>

          <Box width="full" overflowX="auto">
            <LocationTable
              locations={locations}
              canAddLocation={canAddLocation}
            />
          </Box>
        </>
      ) : (
        canAddLocation && (
          <CenteredFullHeight>
            <Link to={getRoutePath("locationsCreate")}>Add a Location</Link>
          </CenteredFullHeight>
        )
      )}
    </Box>
  );
};

export default LocationsPresenter;
