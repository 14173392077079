import React from "react";
import { useLocation } from "react-router-dom";

import { initGA, recordPageview } from "./utils/ga";

const Tracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    recordPageview(location.pathname);
  }, [location]);

  React.useEffect(() => {
    initGA();
  }, []);

  return null;
};

export default Tracking;
