import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";

import { useAppSettingsQuery } from "../../../graphql/graphql";
import AddPaymentMethod from "../addPaymentMethod";

interface CreatePaymentProps {
  paymentMethodAdded: () => void;
  attemptToChargeOpenInvoice?: boolean;
}

const CreatePayment: React.FC<CreatePaymentProps> = ({
  paymentMethodAdded,
  attemptToChargeOpenInvoice,
}) => {
  const { data: appSettingsQueryData } = useAppSettingsQuery();
  const appSettingsQuery = appSettingsQueryData?.appSettings;

  return (
    <Drawer
      isOpen
      placement="right"
      onClose={paymentMethodAdded}
      trapFocus={!appSettingsQuery?.plaidOpen}
    >
      <Helmet>
        <title>New Payment Method</title>
      </Helmet>
      <DrawerOverlay zIndex={1900}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>New Payment Method</DrawerHeader>
          <DrawerBody>
            <AddPaymentMethod
              paymentMethodAdded={paymentMethodAdded}
              attemptToChargeOpenInvoice={attemptToChargeOpenInvoice}
              autoFocus
            />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CreatePayment;
