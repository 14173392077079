import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import { faCity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import defaultTheme from "../../../chakraTheme";
import { useUserQuery } from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../router";
import Link from "../../elements/link";
import MySettingsIcon from "../../icons/mySettings";
import SignOutIcon from "../../icons/signOut";

interface UserDropdownPresenterProps {
  simple?: boolean;
}

const userDropdownItems = [
  {
    path: "mySettings",
    text: "My Settings",
    Icon: MySettingsIcon,
    isHiddenInSimple: true,
  },
  {
    path: "myCompanies",
    text: "My Companies",
    Icon: () => (
      <FontAwesomeIcon icon={faCity} color={defaultTheme.colors.gray[500]} />
    ),
    isHiddenInSimple: true,
  },
  {
    path: "signOut",
    text: "Sign Out",
    Icon: SignOutIcon,
  },
];

const UserDropdownPresenter: React.FC<UserDropdownPresenterProps> = ({
  simple,
}) => {
  const { data } = useUserQuery();
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  if (!data) return null;
  const user = data.user;
  const name =
    user.fullName || (user?.email && `${user.email[0]} ${user.email[1]}`);

  return (
    <Menu placement="top-end">
      <MenuButton>
        <Avatar name={name} size="sm" backgroundColor="#FFBD29" color="white" />
      </MenuButton>
      <MenuList cursor="unset" width="56" marginTop="4">
        <MenuItem
          as="div"
          display="block"
          padding="4"
          backgroundColor="secondary.10"
          _focus={{}}
        >
          <Flex alignItems="center">
            <Avatar
              name={name}
              size="sm"
              backgroundColor="gray.800"
              color="white"
            />
            <Box marginLeft="2">
              <Box fontWeight="medium" color="black">
                {user.fullName}
              </Box>
              <Box
                fontSize="sm"
                color="gray.600"
                textTransform="capitalize"
                lineHeight="6"
              >
                {currentCompanyRole.role.toLowerCase().replace("_", " ")}
              </Box>
            </Box>
          </Flex>
        </MenuItem>
        {userDropdownItems.map((nav) => {
          if (nav.isHiddenInSimple && simple) return null;

          return (
            <MenuItem
              as={Link}
              role="link"
              to={getRoutePath(nav.path)}
              variant="unstyled"
              fontWeight="normal"
              fontSize="sm"
              justifyContent="left"
              textTransform="capitalize"
              color="secondary.500"
              height="8"
              padding="8px 16px"
              leftIcon={<nav.Icon color="gray.500" />}
              key={nav.text}
            >
              {nav.text}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

UserDropdownPresenter.defaultProps = {
  simple: false,
};

export default UserDropdownPresenter;
