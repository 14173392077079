import { Icon, IconProps } from "@chakra-ui/react";

const WaterSourceHeatPump = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M95.252 114.003h-5.73v-4.167h5.73c1.15 0 2.083-.933 2.083-2.083V66.084c0-1.15.934-2.083 2.084-2.083h10.417L64 19.002 18.164 63.988l10.417.013c1.15 0 2.084.933 2.084 2.083v41.669c0 1.15.933 2.083 2.083 2.083h5.99v4.167h-5.99a6.258 6.258 0 0 1-6.25-6.25V68.168h-8.334A4.174 4.174 0 0 1 13.997 64c0-1.105.45-2.188 1.234-2.971l47.31-46.436a2.088 2.088 0 0 1 2.917 0l47.295 46.419c.8.8 1.25 1.883 1.25 2.988a4.174 4.174 0 0 1-4.167 4.166h-8.334v39.586a6.258 6.258 0 0 1-6.25 6.25Z"
      fill="currentColor"
    />
    <path
      d="M52.129 89.192c-.254 2.065-2.16 3.306-2.208 3.336a1.343 1.343 0 0 0 1.416 2.286c.123-.076 3.023-1.897 3.455-5.255.318-2.465-.755-5.005-3.189-7.553-1.553-1.622-2.29-3.233-2.188-4.788.148-2.265 2.034-3.806 2.047-3.816A1.345 1.345 0 0 0 49.8 71.29c-.115.09-2.82 2.256-3.065 5.716-.166 2.35.818 4.659 2.924 6.86 1.837 1.921 2.668 3.714 2.47 5.327ZM62.433 89.192c-.254 2.069-2.166 3.31-2.208 3.336a1.344 1.344 0 0 0 1.415 2.286c.123-.077 3.023-1.897 3.456-5.256.317-2.464-.755-5.005-3.19-7.552-1.552-1.623-2.288-3.234-2.187-4.789.148-2.264 2.034-3.805 2.047-3.816a1.345 1.345 0 0 0-1.662-2.113c-.115.09-2.82 2.257-3.064 5.717-.166 2.35.817 4.657 2.923 6.859 1.837 1.922 2.668 3.714 2.47 5.328ZM72.737 89.192c-.254 2.064-2.162 3.305-2.21 3.336a1.344 1.344 0 0 0 1.414 2.286c.123-.076 3.025-1.897 3.458-5.256.317-2.464-.756-5.004-3.19-7.55-1.553-1.624-2.288-3.235-2.186-4.791.148-2.265 2.032-3.805 2.045-3.816a1.343 1.343 0 1 0-1.662-2.112c-.115.09-2.818 2.256-3.063 5.715-.164 2.35.818 4.659 2.922 6.86 1.84 1.922 2.67 3.714 2.472 5.328ZM83.656 110.315H46.73c-.94 0-1.043-.689-1.043-.985 0-.297.102-.985 1.044-.985h28.694c2.423 0 3.731-1.891 3.731-3.67 0-1.78-1.308-3.672-3.73-3.672H46.73c-.94 0-1.043-.688-1.043-.984 0-.297.102-.985 1.044-.985h36.925a1.344 1.344 0 1 0 0-2.688H46.73c-2.423 0-3.73 1.891-3.73 3.672 0 1.78 1.307 3.671 3.73 3.67h28.695c.942 0 1.043.689 1.043.985 0 .297-.102.984-1.043.984H46.731c-2.424 0-3.731 1.89-3.731 3.671S44.307 113 46.73 113h36.926a1.343 1.343 0 1 0 0-2.685Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
);

export default WaterSourceHeatPump;
