import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { useState } from "react";

import AssetDrawer from "../../../../components/elements/assetDrawer";
import AssetIcon from "../../../../components/elements/assetIcons";
import TooltipName from "../../../../components/elements/tooltipName";
import Close from "../../../../components/icons/close";
import {
  AFFECTED_ASSET_DELETED_MESSAGE,
  AFFECTED_BY_ASSET_DELETED_MESSAGE,
} from "../../../../constants/lang/en";
import {
  AssetWithTypeFragmentFragment,
  useAssetAffectedAssetDeleteMutation,
} from "../../../../graphql/graphql";
import { setGenericMessage } from "../../../../utils/serverErrors";

interface AffectedAssetProps {
  affectedAsset: AssetWithTypeFragmentFragment;
  type?: "affected" | "affectedBy";
  assetAffectedAssetId: string;
}

const AffectedAsset: React.FC<AffectedAssetProps> = ({
  assetAffectedAssetId,
  affectedAsset,
  type = "affected",
}) => {
  const typeAffected = type === "affected";
  const toast = useToast();
  const client = useApolloClient();
  const [openedAssetId, setOpenedAssetId] = useState("");
  const [deleting, setDeleting] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [assetAffectedAssetDeleteMutation] =
    useAssetAffectedAssetDeleteMutation();

  const deleteAsset = async () => {
    setDeleting(true);
    try {
      await assetAffectedAssetDeleteMutation({
        variables: { id: assetAffectedAssetId },
      });
      toast({
        description: typeAffected
          ? AFFECTED_ASSET_DELETED_MESSAGE
          : AFFECTED_BY_ASSET_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      client.cache.evict({ id: `AssetAffectedAsset:${assetAffectedAssetId}` });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setDeleting(false);
      onClose();
    }
  };
  return (
    <>
      <Flex
        alignItems="center"
        paddingY="1"
        width="full"
        _hover={{ backgroundColor: "gray.200" }}
      >
        <Button
          variant="link"
          colorScheme="secondary"
          justifyContent="flex-start"
          fontSize="sm"
          flexGrow={1}
          onClick={() => setOpenedAssetId(affectedAsset.id)}
        >
          <AssetIcon
            iconName={affectedAsset.assetType.iconName}
            iconColor={affectedAsset.assetType.misc.resolvedIconColor}
            iconType={affectedAsset.assetType.iconType}
            iconSize="xs"
          />
          <TooltipName marginLeft="1" name={affectedAsset.name} />
        </Button>
        <IconButton
          onClick={onOpen}
          aria-label={`Delete Fed ${typeAffected ? "to" : "from"} Asset`}
          variant="icon"
          colorScheme="grayRed"
          mx="2"
        >
          <Close boxSize="10px" />
        </IconButton>
        <AlertDialog
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogBody textAlign="center">
                Are you sure you want to delete the following fed{" "}
                {typeAffected ? "to" : "from"} asset?
                <br />
                <Text as="strong">{affectedAsset.name}</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={onClose}
                  width="48%"
                  isLoading={deleting}
                >
                  No, Don't Delete!
                </Button>
                <Button
                  onClick={deleteAsset}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                  isLoading={deleting}
                >
                  Yes, Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
      <AssetDrawer
        isOpen={!!openedAssetId}
        onClose={() => setOpenedAssetId("")}
        id={openedAssetId}
      />
    </>
  );
};

export default AffectedAsset;

gql`
  mutation AssetAffectedAssetDelete($id: UUID!) {
    assetAffectedAssetDelete(id: $id)
  }
`;
