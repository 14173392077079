import React from "react";
import { useParams } from "react-router-dom";

import useAssetCategories from "../../../hooks/useAssetCategories";
import Presenter from "./presenter";

interface FloorPlanCreatePageProps {
  locationId?: string;
  onClose?: () => void;
  goBack?: boolean;
}

const FloorPlanCreatePage: React.FC<FloorPlanCreatePageProps> = ({
  locationId: defaultLocationId,
  onClose,
  goBack,
}) => {
  const { locationId } = useParams();
  const { data: assetCategoriesData } = useAssetCategories({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return assetCategoriesData ? (
    <Presenter
      locationId={defaultLocationId ?? locationId}
      assetCategories={assetCategoriesData.assetCategories}
      handleClose={onClose}
      goBack={goBack}
    />
  ) : null;
};

export default FloorPlanCreatePage;
