import { format } from "date-fns";

export const formatDateFromSecondsTimestamp = (
  secondsTimestamp: number
): string => {
  const date = new Date(secondsTimestamp * 1000);
  return format(date, "MMM do, y");
};

export const dateToUTC = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
