import { Icon, IconProps } from "@chakra-ui/react";

const PortableGenerator = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m27.096 26.8-1.567 1.077v-9.995a1.882 1.882 0 0 0-3.765 0v32.706H16v39.065c0 4.956 3.355 9.146 7.916 10.419l.731.204v7.959h-3.882a1.882 1.882 0 1 0 0 3.765h28.823a1.883 1.883 0 1 0 0-3.765h-3.882v-7.764h28.048l.163.8C75.163 107.385 80.584 112 87.06 112c6.474 0 11.895-4.615 13.142-10.729l.163-.8h.818c5.964 0 10.818-4.854 10.818-10.818V50.588h-5.765V26.53c0-3.39 2.507-6.206 5.765-6.69v-3.792c-5.175.49-9.272 4.744-9.517 9.985l-.084 1.8-1.482-1.023a12.183 12.183 0 0 0-6.94-2.162H34.023c-2.57 0-4.954.796-6.927 2.153Zm-7.331 62.853a7.06 7.06 0 0 0 7.053 7.053h35.3V54.353H19.765v35.3Zm13.53-53.477h44.117v14.412h13.53v-12.53a1.882 1.882 0 0 1 3.765 0v12.53h7.764V36.906c0-4.685-3.81-8.494-8.495-8.494H34.023c-4.684 0-8.494 3.81-8.494 8.494v13.682h7.765V36.176Zm67.887 60.53a7.06 7.06 0 0 0 7.053-7.053V77.412h-9.647a1.882 1.882 0 1 1 0-3.765h9.647v-7.765h-9.647a1.882 1.882 0 0 1 0-3.765h9.647v-7.764H65.882v42.353h7.872l.163-.8c1.246-6.115 6.667-10.73 13.142-10.73 6.474 0 11.895 4.615 13.142 10.73l.163.8h.818Zm-14.123 11.529c5.32 0 9.647-4.326 9.647-9.647 0-5.32-4.327-9.647-9.647-9.647s-9.647 4.327-9.647 9.647c0 5.321 4.326 9.647 9.647 9.647Zm-50-57.647h36.588V39.941H37.06v10.647Zm4.882 57.647v-7.764h-13.53v7.764h13.53Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m25.53 27.877 1.566-1.077a12.18 12.18 0 0 1 6.927-2.153h59.953c2.576 0 4.965.8 6.941 2.162l1.482 1.022.084-1.799c.245-5.241 4.342-9.495 9.517-9.985v3.791c-3.258.485-5.765 3.3-5.765 6.691v24.06H112v39.064c0 5.964-4.854 10.818-10.818 10.818h-.818l-.163.8C98.955 107.385 93.533 112 87.059 112c-6.475 0-11.896-4.615-13.142-10.729l-.163-.8H45.706v7.764h3.882a1.882 1.882 0 1 1 0 3.765H20.765a1.883 1.883 0 0 1 0-3.765h3.882v-7.959l-.731-.204C19.355 98.799 16 94.609 16 89.652V50.589h5.765V17.882a1.882 1.882 0 0 1 3.765 0v9.995Zm-5.765 61.776a7.06 7.06 0 0 0 7.053 7.053h35.3V54.353H19.765v35.3Zm67.294 18.582c5.32 0 9.647-4.326 9.647-9.647 0-5.32-4.327-9.647-9.647-9.647s-9.647 4.327-9.647 9.647c0 5.321 4.326 9.647 9.647 9.647ZM73.647 50.588H37.06V39.941h36.588v10.647Zm-31.706 49.883v7.764h-13.53v-7.764h13.53Zm66.294-10.818a7.06 7.06 0 0 1-7.053 7.053h-.818l-.163-.8c-1.246-6.115-6.668-10.73-13.142-10.73-6.475 0-11.896 4.615-13.142 10.73l-.163.8h-7.872V54.353h42.353v7.765h-9.647a1.882 1.882 0 0 0 0 3.765h9.647v7.764h-9.647a1.882 1.882 0 0 0 0 3.765h9.647v12.24Zm-74.94-53.477h44.117v14.412h13.53v-12.53a1.882 1.882 0 0 1 3.765 0v12.53h7.764V36.906c0-4.685-3.81-8.494-8.495-8.494H34.023c-4.684 0-8.494 3.81-8.494 8.494v13.682h7.765V36.176Z"
      fill="currentColor"
    />
    <path
      d="M49 72.462h-4.524l3.452-7.302a.737.737 0 0 0-.1-.788C47.642 64.14 47.332 64 47 64h-7c-.422 0-.8.225-.942.562l-5 11.846a.742.742 0 0 0 .123.772c.188.224.492.358.819.358h4.523l-3.451 7.302c-.182.384-.008.823.41 1.038.16.082.34.122.518.122.283 0 .562-.103.76-.296l12-11.846c.253-.25.312-.604.148-.904-.162-.3-.518-.492-.908-.492Zm-8.828 8.26 1.755-3.715a.738.738 0 0 0-.1-.789c-.185-.232-.495-.372-.827-.372h-4.58l4.285-10.154h4.818l-3.451 7.302a.738.738 0 0 0 .1.788c.185.232.496.372.828.372h3.826l-6.654 6.569Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
);

export default PortableGenerator;
