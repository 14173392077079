import { useReactiveVar } from "@apollo/client";
import { Box, Button, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction } from "react";

import { layoutOptionsVar } from "../../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../../router";
import Link from "../../../elements/link";
import Logo from "../../../icons/logoIconDarkBg";
import Menu from "../../../icons/menu";
import UserDropdown from "../../userDropdown";
import Notifications from "../notifications";
import Search from "../search";

interface DashboardHeaderProps {
  setShowSideNavInMobile: Dispatch<SetStateAction<boolean>>;
  disableSearch?: boolean;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  setShowSideNavInMobile,
  disableSearch,
}) => {
  const {
    title,
    subTitle,
    backLink,
    backText,
    headerStyleProps = {},
  } = useReactiveVar(layoutOptionsVar);

  //todo dirty hack to fix jumping header. need to be fixed earlier
  const isWorkOrder =
    typeof title === "string" &&
    (title as string)?.toLowerCase().includes("work order");

  return (
    <Grid
      className={isWorkOrder ? "work-order-header" : ""}
      gridColumnGap="1rem"
      columnGap="1rem"
      gridRowGap="0.75rem"
      rowGap="0.75rem"
      position="relative"
      gridTemplateAreas={{
        base: '"header-content" "title"',
        lg: '"title header-content"',
      }}
      gridTemplateColumns={{ base: "auto", lg: "1fr 570px" }}
      marginX="auto"
      width="100%"
      paddingX={{ base: "0", lg: "8", "2xl": "32" }}
      {...headerStyleProps}
    >
      <Grid
        gridArea="header-content"
        gridColumnGap="24px"
        alignItems="center"
        gridTemplateColumns={{
          base: "24px 24px 1fr 24px 32px",
          lg: "1fr 380px 36px 32px",
        }}
        gridTemplateAreas={{
          base: '"side-nav search logo notification userbadge"',
          lg: '". search notification userbadge"',
        }}
        paddingX={{ base: "5", lg: 0 }}
        paddingY="3"
        backgroundColor={{ base: "black", lg: "white" }}
      >
        <Box gridArea="side-nav" display={{ base: "block", lg: "none" }}>
          <Button
            onClick={() => setShowSideNavInMobile((state) => !state)}
            color="gray.300"
            fontSize="2xl"
            lineHeight="1"
            minWidth="0"
            variant="link"
            colorScheme="secondary"
          >
            <Menu />
          </Button>
        </Box>
        {disableSearch ? null : <Search />}
        <Box
          gridArea="logo"
          display={{ base: "block", lg: "none" }}
          marginX="auto"
        >
          <Link to={getRoutePath("dashboard")} variant="link">
            <Logo width="26px" height="28px" />
          </Link>
        </Box>
        <Box gridArea="notification" textAlign="center">
          <Notifications />
        </Box>
        <Box gridArea="userbadge">
          <UserDropdown />
        </Box>
      </Grid>
      <Flex
        gridArea="title"
        alignItems="center"
        minWidth="0"
        paddingLeft={{ base: "5", lg: 0 }}
      >
        {!!backLink && (
          <Box flexGrow={0} flexShrink={0} marginRight="4">
            <Link
              to={backLink}
              variant="link"
              colorScheme="secondary"
              color="gray.800"
              leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
              size="sm"
              mt="2"
            >
              <Text display={["none", "none", "inline"]}>
                {backText || "Back"}
              </Text>
            </Link>
          </Box>
        )}
        <Box minWidth="0">
          {title && (
            <Heading as="h1" isTruncated flexGrow={1}>
              {title}
            </Heading>
          )}
          {subTitle && (
            <Heading
              as="h2"
              fontSize={{ xs: "xs", md: "sm" }}
              minWidth="0"
              isTruncated
              flexGrow={1}
            >
              {subTitle}
            </Heading>
          )}
        </Box>
      </Flex>
    </Grid>
  );
};

export default DashboardHeader;
