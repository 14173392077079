import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface ReportsProps {}

const ReportsPage: React.FC<ReportsProps> = () => {
  useEffect(() => {
    layoutOptionsVar({
      title: "Reports",
      hideSearch: true,
      styleProps: { paddingX: "0" },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <Presenter />
    </>
  );
};

export default ReportsPage;
