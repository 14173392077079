import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";

import UserField from "../../../components/elements/userField";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import { useMaintenanceScheduleCompleteMutation } from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { companyUserIdsSchema, yupObject } from "../../../utils/validation";

interface MaintenanceMarkAsCompleteProps {
  maintenanceScheduleId: string;
  contactIds: string[];
  maintenanceResolvedName: string;
  maintenanceScheduleDateCopy: string;
  maintenanceLocationsCopy: string;
  onClose: () => void;
}

const MaintenanceMarkAsComplete: React.FC<MaintenanceMarkAsCompleteProps> = ({
  maintenanceScheduleId,
  contactIds,
  maintenanceResolvedName,
  maintenanceScheduleDateCopy,
  maintenanceLocationsCopy,
  onClose,
}) => {
  const toast = useToast();
  const [maintenanceScheduleComplete, { loading }] =
    useMaintenanceScheduleCompleteMutation();

  const onSubmit = useCallback(
    async (
      data: MarkAsCompleteVFormData,
      { setFieldError }: FormikHelpers<MarkAsCompleteVFormData>
    ) => {
      try {
        const { data: serverData, errors } = await maintenanceScheduleComplete({
          variables: { ...data, id: maintenanceScheduleId },
        });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else if (serverData) {
          toast({
            description: GENERIC_SAVED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          onClose();
          return;
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [maintenanceScheduleComplete, maintenanceScheduleId, onClose, toast]
  );

  return (
    <Modal onClose={onClose} isOpen isCentered>
      <ModalOverlay zIndex={1700}>
        <ModalContent>
          <ModalBody textAlign="center">
            <Box pt="3" pb="2">
              <Heading size="xl" fontWeight="600" mb="0">
                Mark the following event as complete?
              </Heading>
              <Text mt="2" fontWeight="600">
                {maintenanceResolvedName}
              </Text>
              <Text fontSize="sm">{maintenanceLocationsCopy}</Text>
              <Text fontSize="sm">{maintenanceScheduleDateCopy}</Text>
            </Box>
            <Formik
              initialValues={{ userIds: contactIds }}
              validationSchema={MarkAsCompleteValidationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              <Form id="mark_as_complete" noValidate>
                <Box tabIndex={0} opacity={0} />
                <UserField
                  name="userIds"
                  label="User(s) completed the service"
                />
              </Form>
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Box flexGrow={1}>
              <Button
                width="full"
                type="submit"
                isLoading={loading}
                form="mark_as_complete"
              >
                Mark as Complete
              </Button>
              <Button
                onClick={onClose}
                variant="link"
                colorScheme="secondary"
                w="full"
                mt="4"
              >
                Cancel
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default MaintenanceMarkAsComplete;

gql`
  mutation MaintenanceScheduleComplete($id: UUID!, $userIds: [UUID!]!) {
    maintenanceScheduleComplete(id: $id, userIds: $userIds) {
      ...MaintenanceScheduleFragment
    }
  }
`;

export const MarkAsCompleteValidationSchema = yupObject().shape({
  userIds: companyUserIdsSchema.label("User(s)"),
});

export type MarkAsCompleteVFormData = {
  userIds: string[];
};
