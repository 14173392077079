import { Icon, IconProps } from "@chakra-ui/react";

const DoorContact = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M55.334 59.4a1.534 1.534 0 0 0 0-3.066 4.6 4.6 0 0 0-4.6 4.6v6.133a4.6 4.6 0 0 0 4.6 4.6 1.533 1.533 0 1 0 0-3.067 1.533 1.533 0 0 1-1.533-1.533v-6.133a1.533 1.533 0 0 1 1.533-1.534Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M88.535 18a21.419 21.419 0 0 0-16.42 7.667h-8.114V22.6a4.6 4.6 0 0 0-5.863-4.422l-34.577 9.88A7.7 7.7 0 0 0 18 35.428v57.142a7.7 7.7 0 0 0 5.56 7.372l34.575 9.88a4.603 4.603 0 0 0 5.865-4.422v-3.067h18.4a6.13 6.13 0 0 0 6.134-6.133V60.934a21.305 21.305 0 0 0 5.348-.703 9.506 9.506 0 0 1 .785 3.77 9.747 9.747 0 0 1-2.584 6.548 1.534 1.534 0 1 0 2.101 2.236 12.607 12.607 0 0 0 2.63-13.513c.976-.417 1.92-.907 2.823-1.465a17.091 17.091 0 0 1-2.981 17.376 1.53 1.53 0 0 0-.02 2.187 1.532 1.532 0 0 0 2.188-.019 20.058 20.058 0 0 0 3.373-21.333A21.46 21.46 0 0 0 88.535 18Zm-27.6 87.401a1.53 1.53 0 0 1-1.254 1.506 1.528 1.528 0 0 1-.701-.033l-34.576-9.88a4.621 4.621 0 0 1-3.336-4.423V35.43a4.623 4.623 0 0 1 3.336-4.423l34.576-9.878a1.534 1.534 0 0 1 1.955 1.472v82.801Zm24.533-9.2a3.067 3.067 0 0 1-3.067 3.066h-18.4V28.733h5.971a21.402 21.402 0 0 0 15.496 31.955v35.513Zm3.067-38.334a18.4 18.4 0 1 1 18.4-18.4 18.421 18.421 0 0 1-18.4 18.4Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M88.535 45.6a4.6 4.6 0 1 0 0 9.2 4.6 4.6 0 0 0 0-9.2Zm0 6.133a1.534 1.534 0 1 1 0-3.068 1.534 1.534 0 0 1 0 3.069Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M88.535 24.134a6.133 6.133 0 0 0-6.134 6.133c0 1.75 1.767 12.267 6.134 12.267 4.367 0 6.133-10.518 6.133-12.267a6.133 6.133 0 0 0-6.133-6.133Zm0 15.3c-1.212-.743-3.067-6.64-3.067-9.167a3.067 3.067 0 1 1 6.133 0c0 2.527-1.855 8.424-3.066 9.168Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
    />
  </Icon>
);

export default DoorContact;
