import { useEffect, useState } from "react";

import {
  useStripeBankAccountsQuery,
  useStripePaymentMethodsQuery,
} from "../graphql/graphql";

const usePaymentsAvailable = () => {
  const { data: paymentMethodsData } = useStripePaymentMethodsQuery();
  const { data: bankAccountsData } = useStripeBankAccountsQuery();
  const [isPaymentAvailable, setPaymentAvailable] = useState(false);

  useEffect(() => {
    if (paymentMethodsData && bankAccountsData) {
      setPaymentAvailable(
        paymentMethodsData.stripePaymentMethods.length > 0 ||
          bankAccountsData.stripeBankAccounts.length > 0
      );
    }
  }, [bankAccountsData, paymentMethodsData]);

  return isPaymentAvailable;
};

export default usePaymentsAvailable;
