import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Presenter from "./presenter";
import PresenterSublocation from "./presenterSublocation";

interface LocationsCreatePageProps {
  onClose?: () => void;
  goBack?: boolean;
}

const LocationsCreatePage: React.FC<LocationsCreatePageProps> = ({
  onClose,
  goBack,
}) => {
  const { locationId } = useParams();
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get("parentId");

  const subLocationParentId = (parentId || locationId) as string;

  return subLocationParentId ? (
    <PresenterSublocation
      parentId={subLocationParentId}
      handleClose={onClose}
      goBack={goBack}
    />
  ) : (
    <Presenter handleClose={onClose} goBack={goBack} />
  );
};

export default LocationsCreatePage;
