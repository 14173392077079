import { EuiText, useEuiTheme } from "@elastic/eui";
import React from "react";

interface InputFieldProps {
  disabled?: boolean;
  label?: string;
  required?: boolean;
  labelAlwaysEnabled?: boolean;
  color?: string;
  disabledColor?: string;
  style?: any;
}

const Label: React.FC<InputFieldProps> = ({
  disabled,
  label,
  required,
  labelAlwaysEnabled = false,
  color,
  disabledColor,
  style,
}) => {
  const { euiTheme } = useEuiTheme();
  return (
    <EuiText
      style={{
        paddingTop: style?.paddingTop || "0",
        fontSize: style?.fontSize || euiTheme.size.m,
        fontWeight: style?.fontWeight || 700,
        paddingBottom: style?.paddingBottom || "0",
        display: "inline",
        lineHeight: style?.lineHeight || "18px",
      }}
      color={
        disabled && !labelAlwaysEnabled
          ? disabledColor || euiTheme.colors.lightShade
          : color || euiTheme.colors.darkShade
      }
    >
      {label}
      {required && (
        <span
          style={{
            width: "fit-content",
            fontSize: style?.fontSize || euiTheme.size.m,
            fontWeight: style?.fontWeight || 700,
            color: disabled
              ? disabledColor || euiTheme.colors.lightShade
              : "#FD3D3D",
          }}
        >
          *
        </span>
      )}
    </EuiText>
  );
};

export default Label;
