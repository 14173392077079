import { Icon, IconProps } from "@chakra-ui/react";

const DisconnectSwitch2 = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M24 64.5V103h100V25H24v39.5Zm0 0H2.5V25"
      stroke="currentColor"
      strokeWidth={6}
      fillOpacity={0}
    />
  </Icon>
);

export default DisconnectSwitch2;
