import {
  Box,
  Button,
  Collapse,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import AssetFields, {
  getAssetFieldsInitialValues,
  getAssetFieldsValidationSchema,
} from "../../../components/elements/assetFields";
import AssetIcon from "../../../components/elements/assetIcons";
import { AssetTypeWithRelationsFragmentFragment } from "../../../graphql/graphql";
import { yupObject } from "../../../utils/validation";

interface AssetTypeShowPresenterProps {
  assetType: AssetTypeWithRelationsFragmentFragment;
}

const AssetTypeShowPresenter: React.FC<AssetTypeShowPresenterProps> = ({
  assetType,
}) => {
  const { isOpen: isDescriptionOpen, onToggle: toggleDescription } =
    useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = React.useState<any>({});

  const validationSchema = React.useMemo(() => {
    return yupObject().shape(getAssetFieldsValidationSchema(assetType));
  }, [assetType]);

  const initialValues = React.useMemo(() => {
    return getAssetFieldsInitialValues(assetType);
  }, [assetType]);

  const onSubmit = React.useCallback(
    (data) => {
      setData(data);
      onOpen();
    },
    [setData, onOpen]
  );

  return (
    <>
      <Helmet>
        <title>{assetType.name}</title>
      </Helmet>
      <DrawerCloseButton />
      <DrawerHeader>
        <Flex alignItems="center">
          <AssetIcon
            iconName={assetType.iconName}
            iconColor={assetType.misc.resolvedIconColor}
            iconType={assetType.iconType}
            iconSize="sm"
            marginRight="2"
          />
          {assetType.name}
        </Flex>
      </DrawerHeader>
      <DrawerBody>
        {!!assetType.description && (
          <>
            <Collapse in={isDescriptionOpen}>
              <Box fontSize="sm" whiteSpace="pre-wrap" marginTop="4">
                {assetType.description}
              </Box>
            </Collapse>
            <Button
              size="sm"
              fontSize="sm"
              variant="link"
              colorScheme="gray"
              paddingLeft="0"
              onClick={toggleDescription}
            >
              {isDescriptionOpen ? "Hide" : "Show"} description
              <Box marginLeft="2">
                <FontAwesomeIcon
                  icon={isDescriptionOpen ? faChevronUp : faChevronDown}
                  size="xs"
                />
              </Box>
            </Button>
          </>
        )}
        <Heading
          as="h3"
          size="xl"
          textAlign="center"
          marginTop="6"
          marginBottom="4"
        >
          Your asset form will look like this
        </Heading>
        {!!assetType.assetFields && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form noValidate>
              <AssetFields assetFields={assetType.assetFields} autoFocus />
              <Button marginTop="6" width="full" type="submit">
                Save
              </Button>
            </Form>
          </Formik>
        )}
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <Flex alignItems="center">
                  <AssetIcon
                    iconName={assetType.iconName}
                    iconColor={assetType.misc.resolvedIconColor}
                    iconSize="lg"
                    iconType={assetType.iconType}
                    marginRight="2"
                  />
                  {assetType.name}
                </Flex>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {!!assetType.assetFields && (
                  <Box marginBottom="4">
                    {assetType.assetFields.map((assetField) => (
                      <Flex
                        key={assetField.id}
                        alignItems="center"
                        marginBottom="2"
                      >
                        <Text marginRight="2">{assetField.label}:</Text>
                        {data[assetField.id] === "" ? (
                          <Text color="gray.700">No Data</Text>
                        ) : typeof data[assetField.id] === "boolean" ? (
                          <Text>
                            {String(data[assetField.id]).toUpperCase()}
                          </Text>
                        ) : (
                          <Text>{data[assetField.id]}</Text>
                        )}
                      </Flex>
                    ))}
                  </Box>
                )}
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </DrawerBody>
    </>
  );
};

export default AssetTypeShowPresenter;
