import React from "react";

import { useCompanyUsersQuery, useUserQuery } from "../../../graphql/graphql";
import MultiSelect from "../multiSelect";

interface UserFieldProps {
  name: string;
  label?: string;
  loading?: boolean;
  excludedCompanyUserIds?: string[];
  autoFocus?: boolean;
}

const UserField: React.FC<UserFieldProps> = ({
  name,
  label = "Select User(s)",
  loading,
  excludedCompanyUserIds,
  autoFocus = false,
}) => {
  const { data } = useUserQuery();
  const { data: companyUsersData } = useCompanyUsersQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const users = React.useMemo(() => {
    if (!companyUsersData) return [];
    const companyUsers: any = [];
    const companyUsersCount = companyUsersData.companyUsers.length;
    for (let i = 0; i < companyUsersCount; i++) {
      const companyUser = companyUsersData.companyUsers[i];
      if (!excludedCompanyUserIds?.includes(companyUser.id)) {
        const label = companyUser.user.fullName || companyUser.user.email;
        companyUsers.push({
          value: companyUser.user.id,
          label: `${label}${
            companyUser.user.id === data?.user.id ? " (myself)" : ""
          }`,
          query: label,
        });
      }
    }
    return companyUsers;
  }, [companyUsersData, data?.user.id, excludedCompanyUserIds]);

  return (
    <MultiSelect
      sortOptions
      items={users}
      autoComplete="off"
      name={name}
      label={label}
      loading={loading}
      autoFocus={autoFocus}
    />
  );
};

export default UserField;
