import { Icon, IconProps } from "@chakra-ui/react";

const ExhaustFan = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M64 105c-22.35 0-41-17.88-41-41 0-22.818 18.65-41 41-41s41 17.594 41 41c0 23.268-18.65 41-41 41Zm0-76.393c-19.516 0-35.393 15.877-35.393 35.393S44.484 99.393 64 99.393 99.393 83.516 99.393 64 83.516 28.607 64 28.607Z"
      fill="currentColor"
    />
    <path
      d="M74.422 43.06c1.488-.68 2.083-2.556 1.253-3.97-3.528-6.01-11.139-8.634-17.623-6.046C50.72 35.971 47.124 44.34 50.036 51.7a14.383 14.383 0 0 0 4.705 6.175 11.263 11.263 0 0 0-1.591 9.032 12.166 12.166 0 0 1-12.654-.967c-1.338-.961-3.271-.532-4.078.902-3.42 6.08-1.866 13.99 3.591 18.324 4.09 3.247 9.754 4.001 14.552 1.935 4.735-2.039 8.067-6.639 8.579-11.764 3.076.241 6.153-.812 8.433-2.893 3.84 2.465 6.037 6.895 5.584 11.57-.157 1.622 1.173 3.107 2.816 3.074 6.982-.065 13.018-5.4 14.023-12.291 1.142-7.838-4.283-15.15-12.093-16.298a14.27 14.27 0 0 0-7.687 1.001 11.265 11.265 0 0 0-6.952-6.072c.307-4.462 3.011-8.475 7.158-10.369Zm-14.29-4.809a8.627 8.627 0 0 1 8.899 1.492 17.956 17.956 0 0 0-7.37 13.457c-.883.19-1.729.483-2.521.868a8.767 8.767 0 0 1-3.89-4.43c-1.778-4.495.412-9.602 4.881-11.387Zm-4.407 41.085c-2.939 3.734-8.5 4.4-12.229 1.439-2.575-2.045-3.726-5.368-3.16-8.488a17.735 17.735 0 0 0 15.511-.462c.525.56 1.107 1.066 1.736 1.51a8.779 8.779 0 0 1-1.858 6c1.347-1.71-1.445 1.836 0 0Zm8.297-9.567a5.613 5.613 0 0 1-5.607-5.607 5.613 5.613 0 0 1 5.607-5.607 5.613 5.613 0 0 1 5.607 5.607 5.613 5.613 0 0 1-5.607 5.607Zm24.426 4.22c-.475 3.257-2.756 5.916-5.717 6.985a17.869 17.869 0 0 0-8.088-13.222c.264-.779.447-1.594.534-2.438a8.672 8.672 0 0 1 5.911-1.269c4.755.7 8.057 5.16 7.36 9.943Z"
      fill="currentColor"
    />
  </Icon>
);

export default ExhaustFan;
