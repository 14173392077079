import { Box, Button, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";
import { GithubPicker, MaterialPicker, SliderPicker } from "react-color";

import defaultTheme from "../../../../chakraTheme";
import { UserAdminRole, useUserQuery } from "../../../../graphql/graphql";

const ColorBox = styled(Box)`
  .github-picker {
    box-shadow: none !important;
    border: 0 !important;
    justify-content: space-between;
    padding: 0 !important;
    max-width: 450px;

    & > span {
      margin: 4px 6px;

      ${(props) => `div[title="${props.color}"]`} {
        border: 3px solid ${defaultTheme.colors.secondary[500]};
        padding: 2px;
        box-shadow: rgba(0, 0, 0, 0.35) 0 0 5px 2px;
        background-clip: content-box !important;
        background-color: ${(props) => props.color} !important;
      }
    }
  }

  .slider-picker {
    margin: 20px 0;
  }

  .material-picker {
    height: auto;
    width: auto;
  }
`;

const name = "iconColor";

interface ColorPickerProps {
  parentColor?: string;
  isColorPickerOpen: boolean;
  onColorPickerClose: () => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  parentColor,
  isColorPickerOpen,
  onColorPickerClose,
}) => {
  const { data } = useUserQuery();
  const { setFieldValue, values } = useFormikContext<any>();
  const value = get(values, name);

  return isColorPickerOpen ? (
    <Box marginTop="4">
      <ColorBox color={value.toUpperCase()}>
        <GithubPicker
          colors={defaultAssetCategoryColors}
          width="100%"
          color={value}
          triangle="hide"
          onChangeComplete={(color) => setFieldValue(name, color.hex)}
        />
        {data?.user.adminRole === UserAdminRole.SuperAdmin && (
          <>
            <SliderPicker
              color={value}
              onChangeComplete={(color) => setFieldValue(name, color.hex)}
            />
            <MaterialPicker
              color={value}
              onChangeComplete={(color) => setFieldValue(name, color.hex)}
            />
          </>
        )}
      </ColorBox>
      <Flex
        justifyContent="space-between"
        marginTop="1"
        paddingRight="1"
        maxWidth="450px"
      >
        <Box>
          <Button
            variant="link"
            colorScheme="secondary"
            onClick={onColorPickerClose}
            alignItems="center"
            fontSize="sm"
          >
            Close
          </Button>
        </Box>
        <Box>
          {!!parentColor && !!value && value !== parentColor && (
            <Button
              variant="link"
              colorScheme="secondary"
              onClick={() => setFieldValue(name, "")}
              alignItems="center"
              fontSize="sm"
            >
              Use default category color
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  ) : null;
};

export default ColorPicker;

export const defaultAssetCategoryColors = [
  "#C0C0C0",
  "#808080",
  "#2F4F4F",
  "#708090",
  "#000000",
  "#FF0000",
  "#8B0000",
  // "#FF4500",
  // "#800000",
  "#FFA500",
  "#FFD700",
  "#F0E68C",
  "#FFFF00",
  "#4169E1",
  "#1E90FF",
  "#6495ED",
  "#808000",
  "#00FF00",
  "#98FB98",
  "#008000",
  "#00FFFF",
  "#008080",
  "#0000FF",
  "#000080",
  "#FF00FF",
  "#800080",
];
