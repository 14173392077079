import { useMemo } from "react";

import { AssetTypeFragmentFragment } from "../graphql/graphql";
import useAssetCategories from "./useAssetCategories";

const useAssetTypes = () => {
  const { data } = useAssetCategories({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const assetTypes = useMemo(() => {
    if (!data) return null;
    const parsedTypes: AssetTypeFragmentFragment[] = [];
    const categoriesCount = data.assetCategories.length;
    for (let i = 0; i < categoriesCount; i++) {
      const category = data.assetCategories[i];
      if (category.assetTypes?.length) {
        parsedTypes.push(...category.assetTypes);
      }
    }
    return parsedTypes;
  }, [data]);

  return assetTypes;
};

export default useAssetTypes;
