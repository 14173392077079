import { useReactiveVar } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import React, { useCallback } from "react";

import { ASSET_MAINTENANCE_NOTE_CREATED_MESSAGE } from "../../../constants/lang/en";
import {
  MaintenanceDocument,
  MaintenanceFragmentFragment,
  useMaintenanceNoteCreateMutation,
} from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import Presenter, { MaintenanceFormData } from "./presenter";

interface MaintenanceNoteCreateProps {
  maintenance: MaintenanceFragmentFragment;
  onClose: () => void;
}

const MaintenanceNoteCreate: React.FC<MaintenanceNoteCreateProps> = ({
  maintenance,
  onClose,
}) => {
  const toast = useToast();
  const [maintenanceNoteCreateMutation, { loading }] =
    useMaintenanceNoteCreateMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: MaintenanceDocument,
          variables: { id: maintenance.id },
        },
      ],
    });
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  const onSubmit = useCallback(
    async (
      data: MaintenanceFormData,
      { setFieldError, resetForm }: FormikHelpers<MaintenanceFormData>
    ) => {
      try {
        const { data: serverData, errors } =
          await maintenanceNoteCreateMutation({
            variables: {
              data: {
                description: data.description,
                maintenanceId: maintenance.id,
              },
            },
          });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else if (serverData) {
          resetForm();
          toast({
            description: ASSET_MAINTENANCE_NOTE_CREATED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          onClose();
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [maintenance.id, maintenanceNoteCreateMutation, onClose, toast]
  );

  return maintenance ? (
    <Presenter
      maintenance={maintenance}
      handleSubmit={onSubmit}
      loading={loading}
      isOpen
      onModalClose={onClose}
      canEditMaintenance={ROLES.maintenancesEdit.includes(
        currentCompanyRole.role
      )}
    />
  ) : null;
};

export default MaintenanceNoteCreate;
