import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { useCallback } from "react";

import { LocationQuery } from "../../../../../graphql/graphql";
import Accordion from "../../../components/accordion";
import Label from "../../../components/label";
import ViewEditText from "../../components/viewEditText";

export interface LocationInfoSectionData {
  locationName?: string | null;
  locationContactFullName?: string | null;
  locationContactEmail?: string | null;
  locationContactMobilePhone?: string | null;
  locationContactWorkPhone?: string | null;
}

interface WorkOrderEditLocationInfoPresenterProps {
  sectionData?: LocationInfoSectionData;
  location?: LocationQuery;
  onSectionDataChange: (workOrder: LocationInfoSectionData) => void;
}

const WorkOrderEditLocationInfoPresenter: React.FC<
  WorkOrderEditLocationInfoPresenterProps
> = ({ sectionData, location, onSectionDataChange, children }) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const minSelectorWidth = "235px";
  const maxSelectorWidth = "235px";

  const handleLocationContactFullNameChange = useCallback(
    (locationContactFullName: string) => {
      onSectionDataChange({ ...sectionData, locationContactFullName });
    },
    [onSectionDataChange, sectionData]
  );
  const handleLocationContactEmailChange = useCallback(
    (locationContactEmail: string) => {
      onSectionDataChange({ ...sectionData, locationContactEmail });
    },
    [onSectionDataChange, sectionData]
  );
  const handleLocationContactWorkPhoneChange = useCallback(
    (phoneNumber: string) => {
      onSectionDataChange({
        ...sectionData,
        locationContactWorkPhone: phoneNumber || null,
      });
    },
    [onSectionDataChange, sectionData]
  );
  const handleLocationContactMobilePhoneChange = useCallback(
    (phoneNumber: string) => {
      onSectionDataChange({
        ...sectionData,
        locationContactMobilePhone: phoneNumber || null,
      });
    },
    [onSectionDataChange, sectionData]
  );

  return (
    <EuiFlexItem style={{ minWidth: "100%" }} grow={0}>
      <Accordion
        id="wo-location-info"
        label="Location Info"
        required={false}
        initialIsOpen={false}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
          style={{ position: "relative" }}
        >
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Location Name" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <EuiText
                color={euiTheme.colors.text}
                className={"ellipsis"}
                style={{
                  lineHeight: euiTheme.sizes["21px"],
                  fontSize: euiTheme.sizes["14px"],
                }}
              >
                {sectionData?.locationName}
              </EuiText>
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Full Name" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.locationContactFullName}
                onValueChange={handleLocationContactFullNameChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Email" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.locationContactEmail}
                onValueChange={handleLocationContactEmailChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Phone No (work)" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.locationContactWorkPhone}
                onValueChange={handleLocationContactWorkPhoneChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Phone No (mobile)" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.locationContactMobilePhone}
                onValueChange={handleLocationContactMobilePhoneChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            {location?.location.coordinates && (
              <EuiLink
                href={`http://www.google.com/maps/place/${location.location.coordinates[1]},${location.location.coordinates[0]}`}
                target="_blank"
              >
                {location.location.name + " on a Map"}
              </EuiLink>
            )}
          </EuiFlexItem>
          {children}
        </EuiFlexGroup>
      </Accordion>
    </EuiFlexItem>
  );
};

export default WorkOrderEditLocationInfoPresenter;
