import * as ApolloReactHooks from "@apollo/client";

import {
  WOCompanyDocument,
  WOFloorPlanDocument,
  WOLocationDocument,
  WOLocationUsersDocument,
  WOOrderAssetServiceTypesDocument,
  WOOrderTypesDocument,
  WOServiceCategoriesDocument,
  WOUserDocument,
  WOWorkOrderByIdDocument,
  WOWorkOrdersDocument,
} from "../fragments/workOrders";
import { defaultOptions } from "../graphql";
import {
  WOCompanyQuery,
  WOFloorPlanQuery,
  WOGetAssetServiceTypesQuery,
  WOGetCompanyOwnerQuery,
  WOGetCompanyUsersQuery,
  WOGetOrderTypesQuery,
  WOGetServiceCategoriesQuery,
  WOGetWorkOrderByIdQuery,
  WOGetWorkOrdersQuery,
  WOLocationQuery,
} from "../types/queries/workOrdersQuery";
import {
  WOCompanyQueryVariables,
  WOFloorPlansQueryVariables,
  WOGetAssetServiceTypesQueryVariables,
  WOGetCompanyOwnerQueryVariables,
  WOGetCompanyUsersQueryVariables,
  WOGetOrderTypesQueryVariables,
  WOGetServiceCategoriesVariables,
  WOGetWorkOrderByIdQueryVariables,
  WOGetWorkOrdersQueryVariables,
  WOLocationQueryVariables,
} from "../types/workOrdersVariables";

export function useWOCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOCompanyQuery,
    WOCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WOCompanyQuery, WOCompanyQueryVariables>(
    WOCompanyDocument,
    options
  );
}

export function useWOLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOLocationQuery,
    WOLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WOLocationQuery, WOLocationQueryVariables>(
    WOLocationDocument,
    options
  );
}

export function useWOFloorPlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOFloorPlanQuery,
    WOFloorPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOFloorPlanQuery,
    WOFloorPlansQueryVariables
  >(WOFloorPlanDocument, options);
}

export function useWOGetCompanyOwnerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetCompanyOwnerQuery,
    WOGetCompanyOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetCompanyOwnerQuery,
    WOGetCompanyOwnerQueryVariables
  >(WOUserDocument, options);
}

export function useWOGetCompanyUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetCompanyUsersQuery,
    WOGetCompanyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetCompanyUsersQuery,
    WOGetCompanyUsersQueryVariables
  >(WOLocationUsersDocument, options);
}

export function useWOGetWorkOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetWorkOrdersQuery,
    WOGetWorkOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetWorkOrdersQuery,
    WOGetWorkOrdersQueryVariables
  >(WOWorkOrdersDocument, options);
}

export function useWOGetWorkOrderByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetWorkOrderByIdQuery,
    WOGetWorkOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetWorkOrderByIdQuery,
    WOGetWorkOrderByIdQueryVariables
  >(WOWorkOrderByIdDocument, options);
}

export function useWOGetOrderTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetOrderTypesQuery,
    WOGetOrderTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetOrderTypesQuery,
    WOGetOrderTypesQueryVariables
  >(WOOrderTypesDocument, options);
}

export function useWOGetServiceCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetServiceCategoriesQuery,
    WOGetServiceCategoriesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetServiceCategoriesQuery,
    WOGetServiceCategoriesVariables
  >(WOServiceCategoriesDocument, options);
}

export function useWOGetAssetServiceTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetAssetServiceTypesQuery,
    WOGetAssetServiceTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetAssetServiceTypesQuery,
    WOGetAssetServiceTypesQueryVariables
  >(WOOrderAssetServiceTypesDocument, options);
}
