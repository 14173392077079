import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import useLocationTreeRestricted from "../../../hooks/useLocationTreeRestricted";
import { getRoutePath } from "../../../router";

const LocationBreadCrumb = ({ locationId }: { locationId: string }) => {
  const locationTree = useLocationTreeRestricted(locationId);
  return locationTree ? (
    <Breadcrumb separator="»" spacing=".25rem">
      <LocationBreadcrumbItem location={locationTree.parent} />
      <BreadcrumbItem isCurrentPage isLastChild>
        <BreadcrumbLink
          to={getRoutePath("locationsShow", { locationId: locationTree.id })}
          as={RouterLink}
          isCurrentPage
          pointerEvents="none"
        >
          <Text as="div" maxWidth={150} isTruncated>
            {locationTree.name}
          </Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  ) : null;
};

export default LocationBreadCrumb;

const LocationBreadcrumbItem = ({ location }: { location?: any }) => {
  if (!location) return null;
  return (
    <>
      {!!location.parent && (
        <LocationBreadcrumbItem location={location.parent} />
      )}
      <BreadcrumbItem separator="»" spacing=".25rem">
        <BreadcrumbLink
          to={getRoutePath("locationsShow", { locationId: location.id })}
          as={RouterLink}
        >
          <Text as="div" maxWidth={120} isTruncated>
            {location.name}
          </Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </>
  );
};
