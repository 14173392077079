import * as ApolloReactHooks from "@apollo/client";

import { WOCommentWorkOrderDocument } from "../fragments/workOrderComments";
import { defaultOptions } from "../graphql";
import { WOGetCommentWorkOrderQuery } from "../types/queries/workOrdersQuery";
import { WOGetCommentsByWorkOrderIdVariables } from "../types/workOrdersVariables";

export function useWOGetCommentsWorkOrderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetCommentWorkOrderQuery,
    WOGetCommentsByWorkOrderIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WOGetCommentWorkOrderQuery,
    WOGetCommentsByWorkOrderIdVariables
  >(WOCommentWorkOrderDocument, options);
}
