import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useCompanyQuery } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface CompanyEmailChangePageProps {}

const CompanyEmailChangePage: React.FC<CompanyEmailChangePageProps> = () => {
  const { data: currentCompanyData } = useCompanyQuery();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("companySettings"));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Company Email</DrawerHeader>
          <DrawerBody>
            <Box marginTop="10">
              {currentCompanyData ? (
                <Presenter currentCompany={currentCompanyData.company} />
              ) : (
                <PageSpinner />
              )}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CompanyEmailChangePage;
