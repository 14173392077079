import { Icon, IconProps } from "@chakra-ui/react";

const Ppt = (props: IconProps & any) => {
  return (
    <Icon width="12" height="auto" viewBox="0 0 48 48" {...props}>
      <path
        d="M40 23.333h-2.667v-2.666L40 23.333zM8 23.333h2.667v-2.666L8 23.333z"
        fill="#86331E"
      />
      <path
        d="M38 40a2 2 0 01-2 2H12a2 2 0 01-2-2V8a2 2 0 012-2h17.172a2 2 0 011.414.586l6.828 6.828A2 2 0 0138 14.828V40z"
        fill="#F1F1F1"
      />
      <path d="M38 14h-7a1 1 0 01-1-1V6l8 8z" fill="#CCC" />
      <path
        d="M8 23.333c0-.368.298-.666.667-.666h30.666c.369 0 .667.298.667.666v11.334a.667.667 0 01-.667.666H8.667A.667.667 0 018 34.667V23.333z"
        fill="#B7472A"
      />
      <path
        d="M17.839 29.684v2.003h-1.172V26h2.219c.427 0 .802.078 1.125.234.325.157.575.38.75.668.174.287.262.614.262.98 0 .558-.192.998-.575 1.321-.38.32-.907.48-1.582.48H17.84zm0-.95h1.047c.31 0 .545-.073.707-.218.164-.146.246-.354.246-.625 0-.279-.082-.504-.246-.676-.164-.172-.39-.26-.68-.266H17.84v1.785zM23.003 29.684v2.003H21.83V26h2.219c.427 0 .802.078 1.125.234.325.157.575.38.75.668.174.287.261.614.261.98 0 .558-.19.998-.574 1.321-.38.32-.907.48-1.582.48h-1.027zm0-.95h1.047c.31 0 .545-.073.707-.218.164-.146.246-.354.246-.625 0-.279-.082-.504-.246-.676-.164-.172-.39-.26-.68-.266h-1.074v1.785zM31.276 26.95h-1.742v4.738h-1.172v-4.739h-1.718V26h4.632v.95z"
        fill="#fff"
      />
      <path stroke="#CCC" d="M15 17.5h18M15 19.5h18M15 21.5h18" />
    </Icon>
  );
};

export default Ppt;
