import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React from "react";

import {
  BANK_ACCOUNT_DELETED,
  GENERIC_ERROR_MESSAGE,
  PRIMARY_CARD_NO_DELETE,
  PRIMARY_PAYMENT_CHANGED,
} from "../../../constants/lang/en";
import {
  StripeBankAccountFragmentFragment,
  StripeBankAccountsDocument,
  StripeCustomerDocument,
  useStripeBankAccountDeleteMutation,
  useStripeBankAccountMakePrimaryMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";
import BankAccount from "../bankAccount";
import Spinner from "../spinner";

interface BankAccountPaymentMethodProps {
  defaultSource?: string;
  bankAccount: StripeBankAccountFragmentFragment;
  type?: "default" | "simple";
  onSetPrimarySuccess?: () => void;
  showPrimary?: boolean;
}

const BankAccountPaymentMethod: React.FC<BankAccountPaymentMethodProps> = ({
  defaultSource,
  bankAccount,
  type = "default",
  showPrimary = true,
  onSetPrimarySuccess,
}) => {
  const isDefaultType = type === "default";
  const toast = useToast();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [stripeBankAccountMakePrimaryMutation, { loading: settingPrimary }] =
    useStripeBankAccountMakePrimaryMutation({
      refetchQueries: [{ query: StripeCustomerDocument }],
      awaitRefetchQueries: true,
    });
  const [stripeBankAccountDeleteMutation, { loading: deleting }] =
    useStripeBankAccountDeleteMutation({
      refetchQueries: [{ query: StripeBankAccountsDocument }],
      awaitRefetchQueries: true,
    });

  const makePrimary = async () => {
    if (defaultSource === bankAccount.id) {
      if (onSetPrimarySuccess) onSetPrimarySuccess();
      return;
    }

    try {
      if (!bankAccount.id) {
        throw new Error(GENERIC_ERROR_MESSAGE);
      }
      await stripeBankAccountMakePrimaryMutation({
        variables: { stripeBankAccountId: bankAccount.id },
      });
      if (onSetPrimarySuccess) onSetPrimarySuccess();
      if (isDefaultType) {
        toast({
          description: PRIMARY_PAYMENT_CHANGED,
          status: "success",
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDelete = async () => {
    if (defaultSource === bankAccount.id) {
      toast({
        description: PRIMARY_CARD_NO_DELETE,
        status: "info",
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      if (!bankAccount.id) {
        throw new Error(GENERIC_ERROR_MESSAGE);
      }
      await stripeBankAccountDeleteMutation({
        variables: { stripeBankAccountId: bankAccount.id },
      });
      toast({
        description: BANK_ACCOUNT_DELETED,
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      tabIndex={isDefaultType ? -1 : 0}
      onClick={isDefaultType ? undefined : makePrimary}
      alignItems="center"
      paddingX={{ base: "2", md: "7" }}
      paddingY={{ base: "6", md: "7" }}
      marginBottom="4"
      border="none"
      outline="none"
      backgroundColor="secondary.10"
      _hover={isDefaultType ? {} : { boxShadow: "md", cursor: "pointer" }}
    >
      <BankAccount bankAccount={bankAccount} />
      {defaultSource === bankAccount.id ? (
        <Flex
          alignItems="center"
          fontSize="sm"
          color="black"
          justifyContent="space-between"
        >
          <Box as="span" fontSize="sm" marginRight="2">
            {showPrimary ? "Primary" : ""}
          </Box>
          <Text as="span" color="secondary.500">
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          </Text>
        </Flex>
      ) : isDefaultType ? (
        <Flex
          alignItems="center"
          fontSize="sm"
          color="gray.300"
          justifyContent="space-between"
        >
          <Button
            variant="link"
            colorScheme="secondary"
            fontSize="sm"
            onClick={makePrimary}
            minWidth="0"
            marginRight="2"
            isLoading={settingPrimary}
          >
            Set as Primary
          </Button>
          <Tooltip label="Delete bank account" hasArrow placement="bottom">
            <IconButton
              variant="icon"
              colorScheme="grayRed"
              aria-label="Delete bank account"
              onClick={onOpen}
              isLoading={deleting}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        </Flex>
      ) : settingPrimary ? (
        <Spinner />
      ) : null}
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete the following payment method?
              <br />
              <Text as="strong">
                {bankAccount.bank_name?.toUpperCase()} ACH bank account ending
                in {bankAccount.last4}
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} width="48%">
                No, Don't Delete!
              </Button>
              <Button
                onClick={handleDelete}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default BankAccountPaymentMethod;

gql`
  query StripeBankAccounts {
    stripeBankAccounts {
      ...StripeBankAccountFragment
    }
  }
`;

gql`
  mutation StripeBankAccountCreate(
    $plaidAccountId: String!
    $plaidLinkPublicToken: String!
    $attemptToChargeOpenInvoice: Boolean
  ) {
    stripeBankAccountCreate(
      plaidAccountId: $plaidAccountId
      plaidLinkPublicToken: $plaidLinkPublicToken
      attemptToChargeOpenInvoice: $attemptToChargeOpenInvoice
    ) {
      ...StripeBankAccountFragment
    }
  }
`;

gql`
  mutation StripeBankAccountDelete($stripeBankAccountId: String!) {
    stripeBankAccountDelete(stripeBankAccountId: $stripeBankAccountId)
  }
`;

gql`
  mutation StripeBankAccountMakePrimary($stripeBankAccountId: String!) {
    stripeBankAccountMakePrimary(stripeBankAccountId: $stripeBankAccountId)
  }
`;
