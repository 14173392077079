import { Icon, IconProps } from "@chakra-ui/react";

const Door = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M73.167 73.167a6.883 6.883 0 0 0 6.875-6.875 6.883 6.883 0 0 0-6.875-6.875 6.883 6.883 0 0 0-6.875 6.875 6.883 6.883 0 0 0 6.875 6.875Zm0-9.167a2.295 2.295 0 0 1 2.291 2.292 2.295 2.295 0 0 1-2.291 2.292 2.296 2.296 0 0 1-2.292-2.292A2.296 2.296 0 0 1 73.167 64Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M105.25 114.417h-6.875V11.292A2.293 2.293 0 0 0 96.083 9H31.917c-.129 0-.243.055-.367.073-.12.019-.234.032-.348.069a2.25 2.25 0 0 0-.55.289c-.069.046-.152.06-.216.114-.023.019-.032.046-.055.069-.165.151-.293.34-.408.536-.046.073-.11.133-.142.211a2.268 2.268 0 0 0-.206.93v103.126H22.75a2.292 2.292 0 0 0 0 4.583h9.167c.123 0 .252-.009.375-.032l55-9.167a2.292 2.292 0 0 0 1.916-2.259V20.458a2.289 2.289 0 0 0-1.915-2.26l-27.689-4.615h34.188v103.125A2.293 2.293 0 0 0 96.082 119h9.167a2.292 2.292 0 0 0 0-4.583Zm-20.625-92.02v83.201l-50.417 8.406V13.996l50.417 8.401Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
);

export default Door;
