import {
  Box,
  Button,
  Center,
  Link as ChakraLink,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistanceToNowStrict } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import Link from "../../../components/elements/link";
import PasswordInput from "../../../components/elements/passwordInput";
import PlanIcon from "../../../components/icons/planIcon";
import Rocket from "../../../components/icons/rocket";
import { getPlanName, inactivePlanStatus } from "../../../constants/plan";
import {
  CompanyDocument,
  CompanyFragmentFragment,
  PlansFragmentFragment,
  StripeInvoiceUpcomingDocument,
  StripeInvoiceUpcomingQuery,
  UserWithCompaniesDocument,
  useStripeSubscriptionDeleteMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import { formatDateFromSecondsTimestamp } from "../../../utils/date";
import { formatPrice } from "../../../utils/price";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { currentPasswordSchema, yupObject } from "../../../utils/validation";

interface CompanyPlanPagePresenterProps {
  currentCompany: CompanyFragmentFragment;
  currentCompanyPlan: PlansFragmentFragment;
  invoiceUpcoming: StripeInvoiceUpcomingQuery | undefined;
  paymentsAvailable: Boolean;
}

const CompanyPlanPagePresenter: React.FC<CompanyPlanPagePresenterProps> = ({
  currentCompany,
  invoiceUpcoming,
  paymentsAvailable,
  currentCompanyPlan,
}) => {
  const toast = useToast();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, setSearchParams] = useSearchParams();
  const [stripeSubscriptionDeleteMutation, { loading }] =
    useStripeSubscriptionDeleteMutation({
      refetchQueries: [
        { query: UserWithCompaniesDocument },
        { query: CompanyDocument },
        { query: StripeInvoiceUpcomingDocument },
      ],
      awaitRefetchQueries: true,
    });

  const onSubmit = async (
    data: SubscriptionDeleteFormData,
    { setFieldError }: FormikHelpers<SubscriptionDeleteFormData>
  ) => {
    const deleteSubscription = async () => {
      try {
        const { errors } = await stripeSubscriptionDeleteMutation({
          variables: data,
        });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else {
          onClose();
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    };

    deleteSubscription();
  };

  const inactivePlan = inactivePlanStatus.includes(
    `${currentCompany.planStripeStatus}`
  );
  const isPlanGood =
    !currentCompany.planStripeCancelAt &&
    (currentCompany.planStripeStatus === "active" ||
      (currentCompany.planStripeStatus === "trialing" && paymentsAvailable));

  return (
    <>
      {currentCompanyPlan.productName ? (
        <Flex flexDirection="column">
          {isPlanGood && (
            <Text marginTop="4" marginBottom="4" textAlign="center">
              {currentCompanyPlan.interval === "month" &&
                "Save 20% by switching to yearly plan!"}
            </Text>
          )}
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            textAlign="center"
            backgroundColor="secondary.10"
            paddingX={{ base: "2", md: "7" }}
            paddingY={{ base: "6", md: "7" }}
            justifyContent="space-between"
          >
            <Flex
              color="gray.800"
              textAlign="center"
              fontSize="lg"
              alignItems="center"
              marginRight={{ base: "0", md: "5", lg: "5" }}
            >
              <PlanIcon planName={currentCompanyPlan.productName} />
              <Box
                marginLeft="4"
                textAlign="left"
                fontSize="sm"
                fontWeight="medium"
                color="black"
              >
                {getPlanName(currentCompanyPlan.productName)}
                <br />
                <Text
                  as="span"
                  marginY="1"
                  marginRight={{ base: "5", md: "0" }}
                  color="gray.600"
                  fontSize="sm"
                  fontWeight="normal"
                >
                  {!paymentsAvailable &&
                  currentCompany.planStripeStatus === "trialing" ? (
                    <>
                      Your free trial ends in{" "}
                      {formatDistanceToNowStrict(
                        new Date(currentCompany.planStripeTrialEnd),
                        { unit: "day", roundingMethod: "ceil" }
                      )}
                    </>
                  ) : currentCompany.planStripeStatus === "incomplete" ? (
                    <Box as="span" color="red.500">
                      Your free trial has ended
                    </Box>
                  ) : currentCompany.planStripeStatus === "past_due" ? (
                    <Box as="span" color="red.500">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      <Box as="span" marginX="1">
                        There is a problem with your payment
                      </Box>
                      <Link
                        to={`${getRoutePath(
                          "companyPaymentMethods"
                        )}?redirectPath=${location.pathname}`}
                        colorScheme="red"
                        size="xs"
                        textTransform="uppercase"
                      >
                        Fix Now
                      </Link>
                    </Box>
                  ) : currentCompany.planStripeCancelAt ? (
                    <>
                      We're sorry to see you go.
                      <br />
                      Your renewal has been cancelled and your plan will remain
                      active until{" "}
                      {formatDateFromSecondsTimestamp(
                        currentCompany.planStripeCancelAt / 1000
                      )}
                    </>
                  ) : inactivePlan ? (
                    <>Your plan has expired.</>
                  ) : (
                    isPlanGood &&
                    !!invoiceUpcoming &&
                    !!invoiceUpcoming?.stripeInvoiceUpcoming
                      ?.amount_remaining &&
                    !!invoiceUpcoming?.stripeInvoiceUpcoming?.created && (
                      <>
                        Your subscription will renew on{" "}
                        {formatDateFromSecondsTimestamp(
                          invoiceUpcoming!.stripeInvoiceUpcoming.created!
                        )}{" "}
                        for{" "}
                        {formatPrice(
                          invoiceUpcoming!.stripeInvoiceUpcoming!
                            .amount_remaining! / 100
                        )}
                      </>
                    )
                  )}
                </Text>
              </Box>
            </Flex>

            <Flex
              marginTop={{ base: "8", md: "0" }}
              order={{ sm: 2, md: 3 }}
              alignItems="center"
            >
              <Button
                onClick={() => setSearchParams({ CaAprType: "changePlan" })}
                size="md"
                width="full"
                fontSize="xs"
                leftIcon={
                  ((!paymentsAvailable &&
                    currentCompany.planStripeStatus === "trialing") ||
                    currentCompany.planStripeStatus === "incomplete" ||
                    currentCompany.planStripeCancelAt ||
                    inactivePlan) && <Rocket />
                }
              >
                {(!paymentsAvailable &&
                  currentCompany.planStripeStatus === "trialing") ||
                currentCompany.planStripeStatus === "incomplete" ? (
                  <Box as="span">Upgrade</Box>
                ) : currentCompany.planStripeCancelAt || inactivePlan ? (
                  <Box as="span" marginLeft="2">
                    Reactivate
                  </Box>
                ) : (
                  <>Change Plan</>
                )}
              </Button>
            </Flex>
          </Flex>
          {isPlanGood && (
            <Box>
              <Button
                variant="link"
                _hover={{ color: "red.500" }}
                color="gray.300"
                whiteSpace="normal"
                marginTop="4"
                justifyContent={{ base: "center", md: "left" }}
                onClick={onOpen}
                fontWeight="semibold"
                fontSize="xs"
              >
                Close Account
              </Button>
            </Box>
          )}
          <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay>
              <ModalContent>
                <ModalHeader>Close Account</ModalHeader>
                <ModalCloseButton />
                <ModalBody paddingBottom={12} paddingX={{ base: 6, md: 12 }}>
                  <Text
                    marginBottom="6"
                    textAlign="center"
                    fontSize="2xl"
                    lineHeight="9"
                  >
                    If you close this account, you will lose access to all data
                    {currentCompany.name && (
                      <>
                        {" "}
                        for{" "}
                        <Text as="span" fontSize="xl" fontWeight="bold">
                          {currentCompany.name}
                        </Text>
                      </>
                    )}
                    {!!currentCompany.planStripeCurrentPeriodEnd && (
                      <>
                        {" "}
                        on{" "}
                        <Text as="span" fontSize="xl" fontWeight="bold">
                          {formatDateFromSecondsTimestamp(
                            currentCompany.planStripeCurrentPeriodEnd / 1000
                          )}
                        </Text>
                      </>
                    )}
                    .
                  </Text>

                  <Text
                    color="black"
                    textAlign="center"
                    marginBottom="6"
                    lineHeight="6"
                  >
                    If you have questions, contact{" "}
                    <ChakraLink
                      color="secondary.500"
                      href="mailto:customersuccess@criticalasset.com?subject=I need help with my account"
                      isExternal
                    >
                      Customer Support
                    </ChakraLink>{" "}
                    or call us at{" "}
                    <ChakraLink
                      color="secondary.500"
                      href="tel:+18337441010"
                      isExternal
                    >
                      (833) 744-1010
                    </ChakraLink>{" "}
                    and we’ll be happy to help!
                  </Text>

                  <Text
                    marginBottom="8"
                    color="black"
                    textAlign="center"
                    lineHeight="7"
                  >
                    If you still want to close this account, enter your password
                    and click CLOSE ACCOUNT.
                  </Text>

                  <Formik
                    initialValues={{
                      passwordRaw: "",
                    }}
                    validationSchema={SubscriptionDeleteValidationSchema}
                    onSubmit={onSubmit}
                  >
                    {() => (
                      <Form noValidate>
                        <FormGroup label="Password" name="passwordRaw">
                          <PasswordInput
                            autoComplete="current-password"
                            name="passwordRaw"
                          />
                        </FormGroup>
                        <Box marginTop="4">
                          <Button type="button" width="full" onClick={onClose}>
                            DON'T CLOSE THIS ACCOUNT!
                          </Button>
                        </Box>
                        <Box marginTop="6">
                          <Button
                            variant="link"
                            colorScheme="red"
                            color="gray.300"
                            fontSize="sm"
                            width="full"
                            type="submit"
                            isLoading={loading}
                          >
                            CLOSE ACCOUNT
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
        </Flex>
      ) : (
        <Center height="100px" color="gray.700">
          You aren't subscribed to any plans
        </Center>
      )}
    </>
  );
};

export default CompanyPlanPagePresenter;

const SubscriptionDeleteValidationSchema = yupObject().shape({
  passwordRaw: currentPasswordSchema.label("Password"),
});

type SubscriptionDeleteFormData = {
  passwordRaw: string;
};

gql`
  mutation StripeSubscriptionDelete($passwordRaw: String!) {
    stripeSubscriptionDelete(passwordRaw: $passwordRaw) {
      ...StripeSubscriptionFragment
    }
  }
`;
