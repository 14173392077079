import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useUserQuery } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface ChangeEmailPagePresenterProps {}

const ChangeEmailPagePresenter: React.FC<
  ChangeEmailPagePresenterProps
> = () => {
  const { data } = useUserQuery();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const navigate = useNavigate();

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("mySettings"));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>My Email</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>My Email</DrawerHeader>
          <DrawerBody>
            <Box marginTop="10">
              {data ? <Presenter user={data.user} /> : <PageSpinner />}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ChangeEmailPagePresenter;
