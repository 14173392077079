export const PASSWORD_REGEX_ALPHABET = new RegExp(/[A-Za-z]/);
export const PASSWORD_REGEX_NUMBER = new RegExp(/\d/);
export const PASSWORD_REGEX_SPECIAL = new RegExp(
  // eslint-disable-next-line
  /[!#$%&'()*+,\-.\\\/:;=<>?@[\]^_`|{}~]/
);
export const PASSWORD_REGEX_8_CHARS = new RegExp(/[^\s]{8,}/);

export const PASSWORD_REGEX = new RegExp(
  `^(?=.*${PASSWORD_REGEX_ALPHABET.source})(?=.*${PASSWORD_REGEX_NUMBER.source})${PASSWORD_REGEX_8_CHARS.source}$`
);

export const NUMBER_GREATER_THAN_ZERO = new RegExp(/^[1-9][0-9]*$/);

// eslint-disable-next-line
export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex, no-useless-escape
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+((([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
