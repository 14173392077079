import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import {
  CompanyUserRole,
  useCompanyUsersQuery,
} from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface CompanyTeamsUpdatePageProps {}

const CompanyTeamsUpdatePage: React.FC<CompanyTeamsUpdatePageProps> = () => {
  const { teamId } = useParams();
  const { data: companyUsersData } = useCompanyUsersQuery();
  const companyUser = companyUsersData
    ? companyUsersData.companyUsers.find((u) => u.id === teamId)
    : null;

  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  if (!companyUser) return null;
  if (currentCompanyRole.isUser) return null;
  if (companyUser.role === CompanyUserRole.Owner && !currentCompanyRole.isOwner)
    return null;
  if (
    companyUser.role === CompanyUserRole.Admin &&
    currentCompanyRole.isManager
  )
    return null;

  return companyUser && companyUsersData ? (
    <Presenter
      companyUser={companyUser}
      companyUsersLength={companyUsersData.companyUsers.length}
    />
  ) : null;
};

export default CompanyTeamsUpdatePage;
