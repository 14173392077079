import { useReactiveVar } from "@apollo/client";
import { Box, Button, Center, Flex } from "@chakra-ui/react";
import { singular } from "pluralize";
import React from "react";

import { useStripeInvoiceUpcomingQuery } from "../../../graphql/graphql";
import {
  currentCompanyPlanVar,
  currentCompanyRoleVar,
} from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import { formatPrice } from "../../../utils/price";

interface BillingProRatedProps {
  type: string;
  onAccept: () => void;
  onCancel: () => void;
}

const BillingProRated: React.FC<BillingProRatedProps> = ({
  type,
  onAccept,
  onCancel,
}) => {
  const plan = useReactiveVar(currentCompanyPlanVar);
  const { data: invoiceUpcoming } = useStripeInvoiceUpcomingQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  return (
    <Center height="40">
      {ROLES.companyPlan.includes(currentCompanyRole.role) ? (
        <Box>
          {plan && invoiceUpcoming ? (
            <Box>
              You are adding{" "}
              {type === "files"
                ? "100GB of storage"
                : `a new ${singular(type)}`}{" "}
              to your plan. Your pro-rated plan price will increase from{" "}
              {formatPrice(
                (invoiceUpcoming.stripeInvoiceUpcoming.amount_remaining || 0) /
                  100
              )}{" "}
              {plan.proRateAmount ? (
                <>
                  to{" "}
                  {formatPrice(
                    ((invoiceUpcoming.stripeInvoiceUpcoming.amount_remaining ||
                      0) +
                      plan.proRateAmount) /
                      100
                  )}
                </>
              ) : null}{" "}
              per {plan.interval}. Continue?
            </Box>
          ) : null}
          <Flex
            marginTop="4"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button onClick={onAccept} width="45%">
              Yes
            </Button>
            <Button onClick={onCancel} variant="outline" width="45%">
              Cancel
            </Button>
          </Flex>
        </Box>
      ) : (
        <>
          Sorry, you have reached your{" "}
          {type === "files" ? "storage" : singular(type)} limit and you are not
          authorized to add {type === "files" ? "storage" : type}. Please
          contact your account admin to add more{" "}
          {type === "files" ? "storage" : type}.
        </>
      )}
    </Center>
  );
};

export default BillingProRated;
