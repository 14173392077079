import { Box, Center, Flex, Heading, IconButton } from "@chakra-ui/react";
import { faChartLine, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import FilterSelect, {
  FilterOption,
} from "../../../components/elements/filterSelect";
import MobileAccordion from "../../../components/elements/mobileAccordion";
import PageSpinner from "../../../components/elements/pageSpinner";
import { ColumnProps } from "../../../components/elements/spreadsheet";
import { defaultShortDateWithTimeAndZoneFormat } from "../../../constants/date";
import {
  ReportUserFilterInput,
  UserFragmentFragment,
  useCompanyUsersQuery,
  useReportUserActivityLazyQuery,
  useUserQuery,
} from "../../../graphql/graphql";
import { timezone } from "../../../utils/date/timezones";
import { sortCollator } from "../../../utils/sort";
import DownloadReport from "../download";
import ReportFilters from "../filter";
import ReportsTable from "../table";
import UserActivityChart from "./chart";

interface UserActivityReportPagePresenterProps {}

const UserActivityReportPagePresenter: React.FC<
  UserActivityReportPagePresenterProps
> = () => {
  const [type, setType] = useState<"table" | "chart">("table");
  const [filterBy, setFilterBy] = useState<ReportUserFilterInput>({
    startDate: subDays(startOfDay(new Date()), 7).getTime(),
    endDate: endOfDay(new Date()).getTime(),
  });
  const [isPrintReady, setPrintReady] = useState<boolean>(false);

  const { data: currentUser } = useUserQuery();
  const { data: companyUsersData } = useCompanyUsersQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [selectedUser, setSelectedUser] = useState<UserFragmentFragment>();

  const handleSelectedUser = useCallback(
    (id: string) => {
      if (!companyUsersData) return;
      const selectedCompanyUser = companyUsersData.companyUsers.filter(
        (companyUser) => companyUser.user.id === id
      );
      if (selectedCompanyUser.length) {
        setSelectedUser(selectedCompanyUser[0].user);
      }
    },
    [companyUsersData]
  );

  useEffect(() => {
    if (currentUser) handleSelectedUser(currentUser.user.id);
  }, [currentUser, handleSelectedUser]);

  const [fetchUserReport, { data, loading }] = useReportUserActivityLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!selectedUser) return;
    fetchUserReport({
      variables: {
        userId: selectedUser.id,
        filterBy,
        timezone,
      },
    });
  }, [fetchUserReport, filterBy, selectedUser]);

  const columns: ColumnProps[] = useMemo(() => {
    const columnData: ColumnProps[] = [];
    Object.keys(data?.reportUser.length ? data?.reportUser[0] : {}).forEach(
      (colHeading) => {
        if (colHeading !== "__typename") {
          columnData.push({
            field: colHeading,
            cellType: "INPUT",
            headerName:
              colHeading.charAt(0).toUpperCase() +
              colHeading
                .slice(1)
                .replace(/([A-Z])/g, " $1")
                .trim(),
            styleProps: {
              minWidth: colHeading === "Date" ? 100 : 70,
            },
          });
        }
      }
    );
    return columnData;
  }, [data?.reportUser]);

  const formattedData = useMemo(() => {
    if (data?.reportUser.length) return data.reportUser;
    else return [];
  }, [data?.reportUser]);

  const userOptions = useMemo(() => {
    const userOptions: FilterOption[] = [];
    if (companyUsersData && companyUsersData.companyUsers.length) {
      companyUsersData.companyUsers.forEach((user) => {
        const label = `${
          user.user.fullName ? user.user.fullName : user.user.email
        } ${user.user.id === currentUser?.user.id ? "(Me)" : " "}`;
        userOptions.push({
          label,
          value: user.user.id,
          query: label,
        });
      });
      userOptions.sort((a, b) => sortCollator.compare(a.query, b.query));
    }
    return userOptions;
  }, [companyUsersData, currentUser?.user.id]);

  return (
    <Box px="4">
      <Flex
        marginX={{ sm: "2" }}
        marginTop={{ base: "2", sm: "0" }}
        marginBottom="4"
        w="full"
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <MobileAccordion>
          <Flex gap="2">
            <FilterSelect
              label={
                selectedUser
                  ? selectedUser.fullName || selectedUser.email
                  : "User"
              }
              selectedOptions={[selectedUser?.id]}
              allOptions={userOptions}
              handleFilterChange={handleSelectedUser}
              showSelectedCount={false}
            />
            <ReportFilters
              setFilterBy={setFilterBy}
              filterBy={filterBy}
              enabledFilters={["date"]}
            />
          </Flex>
        </MobileAccordion>
        <Box mr="2" />
        <IconButton
          variant="icon"
          colorScheme="gray"
          aria-label="Show Table"
          p="2"
          onClick={() => setType("table")}
          isActive={type === "table"}
          _active={{ color: "secondary.500" }}
        >
          <FontAwesomeIcon icon={faTable} />
        </IconButton>
        <IconButton
          variant="icon"
          colorScheme="gray"
          aria-label="Show Chart"
          p="2"
          onClick={() => setType("chart")}
          isActive={type === "chart"}
          _active={{ color: "secondary.500" }}
        >
          <FontAwesomeIcon icon={faChartLine} />
        </IconButton>
        <DownloadReport
          isDisabled={!formattedData?.length}
          userId={selectedUser?.id}
          reportType="UserActivity"
          filterBy={filterBy}
          setPrintReady={setPrintReady}
        />
      </Flex>
      {loading ? (
        <PageSpinner />
      ) : data?.reportUser.length ? (
        <Box id="reports_table_wrap">
          {isPrintReady && (
            <Heading size="xl" mb="4">
              User Activity Report –{" "}
              {format(new Date(), defaultShortDateWithTimeAndZoneFormat)}
            </Heading>
          )}
          {type === "chart" ? (
            <UserActivityChart
              data={formattedData}
              startDate={filterBy.startDate}
              endDate={filterBy.endDate}
            />
          ) : (
            <ReportsTable
              columns={columns}
              data={formattedData}
              isPrintReady={isPrintReady}
            />
          )}
        </Box>
      ) : (
        <Center height={100} color="gray.700" id="reports_table_wrap">
          No results found
        </Center>
      )}
    </Box>
  );
};

export default UserActivityReportPagePresenter;
