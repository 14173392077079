import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useAssetFilesSignedUrlQuery,
  useAssetQuery,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface AssetFilePageProps {}

const AssetFilePage: React.FC<AssetFilePageProps> = () => {
  const { assetId, fileId } = useParams();
  const { data } = useAssetQuery({
    variables: { id: assetId },
  });
  const { data: signedUrlData } = useAssetFilesSignedUrlQuery({
    variables: { id: assetId },
    fetchPolicy: "no-cache",
  });
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetsShow", { assetId }));
    }, 500);
  };

  if (!data) return null;
  const assetFile = data.asset.assetFiles.find((af) => af.id === fileId);
  const fileSignedUrl = signedUrlData?.asset.assetFiles.find(
    (af) => af.id === fileId
  )?.signedUrl;
  return assetFile && assetId ? (
    <Presenter
      fileSignedUrl={fileSignedUrl}
      assetId={assetId}
      assetFile={assetFile}
      isOpen={isOpen}
      onDrawerClose={onDrawerClose}
    />
  ) : null;
};

export default AssetFilePage;
