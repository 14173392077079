import {
  EuiComboBox,
  EuiFlexGroup,
  EuiFormRow,
  EuiIcon,
  transparentize,
  useEuiTheme,
} from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { css } from "@emotion/react";
import { get } from "lodash";
import React, { ReactNode, useEffect, useRef, useState } from "react";

import Label from "./label";

interface ComboboxProps {
  label?: string;
  options: EuiComboBoxOptionOption<string>[];
  onChange: (value: any) => void;
  resetControl?: { reset: boolean };
  onBlur?: () => void;
  selectedValue?: any;
  required?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  autoSelect?: boolean;
  ariaLabel?: string;
  placeholder?: string;
  errors?: string[];
  singleSelect?: boolean;
  isClearable?: boolean;
  hidePrependIcon?: boolean;
  compressed?: boolean;
  height?: string;
  fullWidth?: boolean;
  color?: string;
  onDropdownClosed?: () => void;
  renderOption?: (
    option: EuiComboBoxOptionOption<string>,
    searchValue: string,
    OPTION_CONTENT_CLASSNAME: string
  ) => ReactNode;
}

const Combobox: React.FC<ComboboxProps> = ({
  label,
  options,
  onChange,
  onDropdownClosed,
  resetControl,
  onBlur,
  selectedValue,
  required,
  disabled,
  invalid,
  loading,
  autoFocus,
  autoSelect,
  ariaLabel,
  errors,
  placeholder = "",
  singleSelect = true,
  isClearable = true,
  hidePrependIcon,
  compressed = false,
  height = "40px",
  fullWidth = true,
  color,
  renderOption,
}) => {
  const { euiTheme } = useEuiTheme();
  const ref = useRef(null);
  const [selectedOptions, setSelectedOptions] = React.useState(
    [] as EuiComboBoxOptionOption<string>[]
  );
  const [isAutoselected, setIsAutoselected] = useState(false);

  const handleSelection = (options: EuiComboBoxOptionOption<string>[]) => {
    setSelectedOptions(options);
    if (singleSelect) {
      onChange(options[0]?.value || null);
    } else {
      onChange(options?.map((o) => o.value) || null);
    }
  };

  function createAutoSelectSingleOption(): void {
    if (!autoSelect) return;
    setSelectedOptions(options);
    handleSelection(options);
    setIsAutoselected(true);
    if (autoFocus && ref.current) {
      const comboboxRef: EuiComboBox<string> = ref.current;
      comboboxRef.closeList();
    }
  }

  React.useEffect(() => {
    if (selectedValue) {
      if (singleSelect) {
        const selectedOption = options.find(
          (option) => option.value === selectedValue
        );
        setSelectedOptions(selectedOption ? [selectedOption] : []);
      } else {
        const selectedOption = options.filter((option) =>
          selectedValue?.includes(option.value)
        );
        setSelectedOptions(selectedOption?.length ? selectedOption : []);
      }
    } else {
      if (
        autoSelect &&
        options?.length === 1 &&
        !isAutoselected &&
        !options[0]?.disabled
      ) {
        createAutoSelectSingleOption();
      } else {
        setSelectedOptions([]);
      }
    }
  }, [options, selectedValue]);

  const onBlurCallback = () => {
    onBlur?.();
    if (ref.current) {
      const combobox: EuiComboBox<any> = ref.current;
      combobox.clearSearchValue();
    }
    // this hack to fire close dropdown event by click without selecting an option
    setTimeout(() => {
      const isOpen = get(ref, "current.state.isListOpen", null);
      if (isOpen === false) {
        onDropdownClosed?.();
      }
    }, 100);
  };

  useEffect(() => {
    if (resetControl?.reset) {
      setIsAutoselected(false);
      selectedValue = null;
      onChange(null);
    }
  }, [resetControl]);

  return (
    <EuiFormRow error={errors} isInvalid={!!errors?.length} fullWidth={true}>
      <EuiFlexGroup
        wrap={true}
        direction="column"
        alignItems="stretch"
        gutterSize="none"
        className={`${compressed && singleSelect ? "compressed" : ""}`}
        style={{
          width: "100%",
          minWidth: "100%",
          flexGrow: 1,
        }}
      >
        {label && (
          <Label
            label={label}
            required={required}
            style={{ paddingBottom: "5px" }}
          />
        )}
        <EuiComboBox
          style={{ height: height }}
          aria-label={ariaLabel}
          options={options}
          singleSelection={singleSelect ? { asPlainText: true } : false}
          isClearable={isClearable}
          sortMatchesBy="startsWith"
          fullWidth={fullWidth}
          isLoading={loading}
          isDisabled={disabled}
          isInvalid={invalid}
          selectedOptions={selectedOptions || []}
          onChange={handleSelection}
          onBlur={onBlurCallback}
          autoFocus={autoFocus}
          placeholder={placeholder}
          rowHeight={37}
          ref={ref}
          renderOption={renderOption}
          prepend={hidePrependIcon ? "" : <EuiIcon type="search" />}
          compressed={compressed}
          css={
            color
              ? css`
                  &.euiComboBox {
                    .euiComboBox__inputWrap {
                      background-color: ${transparentize(color, 0.1)};

                      .euiComboBoxPill {
                        color: ${color};
                      }
                    }

                    .euiFormControlLayoutCustomIcon {
                      color: ${color};
                    }
                  }
                `
              : null
          }
        />
      </EuiFlexGroup>
    </EuiFormRow>
  );
};

export default Combobox;
