import { Icon, IconProps } from "@chakra-ui/react";
import { useState } from "react";

const Cup = (props: IconProps & any) => {
  const [id] = useState(`cup_clip${Math.random()}`);
  return (
    <Icon fill="currentColor" viewBox="0 0 102 102" boxSize="102px" {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M74.375 51a2.125 2.125 0 010-4.25c11.73-.014 21.236-9.52 21.25-21.25v-17H76.5a2.125 2.125 0 010-4.25h21.25c1.174 0 2.125.951 2.125 2.125V25.5c-.016 14.077-11.423 25.484-25.5 25.5zM27.625 51c-14.076-.016-25.484-11.423-25.5-25.5V6.375c0-1.174.951-2.125 2.125-2.125H25.5a2.125 2.125 0 010 4.25H6.375v17c.014 11.73 9.52 21.236 21.25 21.25a2.125 2.125 0 010 4.25zM64.29 84.694l-1.143-1.173c-4.454-4.582-5.678-17.391-5.772-21.939A2.125 2.125 0 0055.25 59.5h-8.5c-1.156 0-2.1.923-2.125 2.078-.09 4.548-1.313 17.349-5.771 21.939L37.71 84.69c-5.835 6.005-10.085 10.344-10.085 15.185 0 1.174.951 2.125 2.125 2.125h42.5a2.125 2.125 0 002.125-2.125c0-4.84-4.25-9.18-10.085-15.181z"
          fill="#FFA000"
        />
        <path
          d="M76.5 0h-51a2.125 2.125 0 00-2.125 2.125v34C23.575 51.3 35.826 63.551 51 63.75c15.174-.2 27.426-12.451 27.625-27.625v-34A2.125 2.125 0 0076.5 0z"
          fill="#FFC107"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h102v102H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default Cup;
