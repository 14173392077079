import { Box } from "@chakra-ui/react";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import L from "leaflet";
import { ReactElement } from "react";
import ReactDOMServer from "react-dom/server";

import defaultTheme from "../../../chakraTheme";
import { IconTypes, MaintenanceStatusType } from "../../../graphql/graphql";
import AssetIcon from "../assetIcons";

const createDivIcon = (component: ReactElement) =>
  L.divIcon({
    className: "custom-icon",
    html: ReactDOMServer.renderToStaticMarkup(component),
  });

const CustomLeafletIcons: { [key: string]: any } = {};

export const getCustomIcon = (
  name: string = "default",
  color: string = defaultTheme.colors.secondary[500]
) => {
  const key = JSON.stringify({ name, color });
  if (!CustomLeafletIcons[key]) {
    CustomLeafletIcons[key] = createDivIcon(
      <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color={color} />
    );
  }
  return CustomLeafletIcons[key];
};

export const getCustomIconForAsset = ({
  iconName,
  iconColor,
  iconType,
  iconStatus,
  iconSize,
}: {
  iconName: string;
  iconColor: string;
  iconType: IconTypes;
  iconStatus?: MaintenanceStatusType;
  iconSize?: "xs" | "sm" | "md" | "lg";
}) => {
  const key = JSON.stringify({
    iconName,
    iconColor,
    iconType,
    iconStatus,
    iconSize,
  });
  if (!CustomLeafletIcons[key]) {
    CustomLeafletIcons[key] = createDivIcon(
      <Box position="relative">
        {iconStatus && (
          <Box
            position="absolute"
            top="1px"
            right="1px"
            width="8px"
            height="8px"
            borderRadius="100%"
            border="1px solid"
            borderColor="white"
            backgroundColor={
              iconStatus === MaintenanceStatusType.PastDue
                ? defaultTheme.colors.red[500]
                : iconStatus === MaintenanceStatusType.DueIn_30
                ? defaultTheme.colors.orange[500]
                : defaultTheme.colors.green[500]
            }
          />
        )}
        <AssetIcon
          iconName={iconName}
          iconColor={iconColor}
          iconType={iconType}
          iconSize={iconSize}
        />
      </Box>
    );
  }
  return CustomLeafletIcons[key];
};
