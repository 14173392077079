import React, { memo } from "react";
import isEqual from "react-fast-compare";

import { LocationWithChildrenCheck } from "../../../hooks/useLocations";
import LocationTable from "./table";

interface LocationsTableProps {
  locations: LocationWithChildrenCheck[];
  depth?: number;
  canAddLocation: boolean;
}

const LocationsTable: React.FC<LocationsTableProps> = ({
  locations,
  canAddLocation,
  depth = 0,
}) => {
  return (
    <LocationTable
      locations={locations}
      canAddLocation={canAddLocation}
      reactTableStateKey={locations[0].parentId || "Locations"}
      depth={depth}
    />
  );
};

export default memo(LocationsTable, isEqual);
