import { useReactiveVar } from "@apollo/client";
import {
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import React, { useEffect, useState } from "react";

import { useUserQuery } from "../../../../../graphql/graphql";
import Accordion from "../../../components/accordion";
import Label from "../../../components/label";
import { companyChanged } from "../../../helpers/workOrdersReactiveVariables";
import { WorkOrder } from "../../../models/workOrder";
import ViewEditCombobox from "../../components/viewEditCombobox";
import ViewEditSelect from "../../components/viewEditSelect";
import { SectionDataAssignee } from "./index";

interface WorkOrderEditAssigneeProps {
  resetInputMode?: { reset: boolean };
  sectionData: SectionDataAssignee;
  userOptions: EuiComboBoxOptionOption<string>[];
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
}

const WorkOrderEditAssigneePresenter: React.FC<WorkOrderEditAssigneeProps> = ({
  resetInputMode,
  sectionData,
  userOptions,
  onWorkOrderChange,
  children,
}) => {
  const { euiTheme } = useEuiTheme();
  const minSelectorWidth = "235px";
  const maxSelectorWidth = "235px";
  const teamsAvailable = false;
  const [resetControl, setResetControl] = useState<{ reset: boolean }>({
    reset: false,
  });
  const { data: currentUser } = useUserQuery();

  const onAccountableSelected = (value: string) => {
    onWorkOrderChange({ accountableUserId: value });
  };
  const onResponsibleSelected = (value: string[]) => {
    onWorkOrderChange({ responsibleUserIds: value });
  };
  const onInformedSelected = (value: string[]) => {
    onWorkOrderChange({ informingUserIds: value });
  };
  const onConsultedSelected = (value: string[]) => {
    onWorkOrderChange({ consultingUserIds: value });
  };

  const isCompanyChanged: { changed: boolean } = useReactiveVar(companyChanged);

  useEffect(() => {
    if (isCompanyChanged.changed) {
      resetErrors();
    }
  }, [isCompanyChanged]);

  const resetErrors = () => {
    setResetControl({ reset: true });
  };

  return (
    <EuiFlexItem style={{ minWidth: "100%" }} grow={0}>
      <Accordion
        id="wo-service-plan"
        label="Service Assignee"
        required={true}
        initialIsOpen={true}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
          style={{ position: "relative" }}
        >
          {teamsAvailable ? (
            <>
              <EuiFlexItem
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  gap: euiTheme.size.s,
                }}
              >
                <Label label="Team" disabled={true} />
                <div
                  style={{
                    minWidth: minSelectorWidth,
                    maxWidth: maxSelectorWidth,
                  }}
                >
                  <ViewEditSelect
                    disabled={true}
                    options={[]}
                    placeholder={"Select Team"}
                    onSelect={() => {}}
                    compressed={true}
                  />
                </div>
              </EuiFlexItem>
              <EuiFlexItem
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  marginLeft: "135px",
                  gap: euiTheme.size.s,
                }}
              >
                <EuiCheckbox
                  disabled={true}
                  id={"team"}
                  label="Assign to the whole Team"
                  onChange={() => {}}
                />
              </EuiFlexItem>
            </>
          ) : null}
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
              minHeight: "34px",
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Accountable person is the one who delegates and reviews
                  that work related to the execution of the Work Order
                </div>
              }
            >
              <Label label="Accountable" required={true} />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditCombobox
                options={userOptions}
                selectedValue={sectionData.accountableUserId}
                required={true}
                onValueChange={onAccountableSelected}
                placeholder={"Select Accountable"}
                compressed={true}
                height={"auto"}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>

          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Responsible person is the one who actually does the work
                </div>
              }
            >
              <Label label="Responsible" required={true} />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditCombobox
                options={userOptions}
                selectedValue={sectionData.responsibleUserIds}
                required={true}
                placeholder={"Select Responsible"}
                singleSelect={false}
                resetInputMode={resetInputMode}
                onValueChange={onResponsibleSelected}
                height={"auto"}
                compressed={true}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Consulted person is the one whose opinion is sought and
                  who provides input and feedback on the work
                </div>
              }
            >
              <Label label="Consulted" />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditCombobox
                options={userOptions}
                selectedValue={sectionData.consultingUserIds}
                singleSelect={false}
                placeholder={"Select Consulted"}
                onValueChange={onConsultedSelected}
                height={"auto"}
                compressed={true}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Informed person is the one who is kept up-to-date on
                  progress
                </div>
              }
            >
              <Label label="Informed" />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditCombobox
                options={userOptions}
                selectedValue={sectionData?.informingUserIds}
                singleSelect={false}
                placeholder={"Select Informed"}
                onValueChange={onInformedSelected}
                height={"auto"}
                compressed={true}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            className="reporter"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Reporter" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <div className={"section-item"}>{currentUser?.user.fullName}</div>
            </div>
          </EuiFlexItem>
          {children}
        </EuiFlexGroup>
      </Accordion>
    </EuiFlexItem>
  );
};

export default WorkOrderEditAssigneePresenter;
