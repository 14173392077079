import { useApolloClient } from "@apollo/client";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import { useUpdateCurrentCompanyMutation } from "../../graphql/graphql";
import { getRoutePath } from "../../router";

interface ResetCompanyPageProps {}

const ResetCompanyPage: React.FC<ResetCompanyPageProps> = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();

  useEffect(() => {
    const resetCompany = async () => {
      await updateCurrentCompany();
      navigate(getRoutePath("dashboard"));
      await client.resetStore();
    };
    resetCompany();
  }, [updateCurrentCompany, client, navigate]);

  return <PageSpinner />;
};

export default ResetCompanyPage;
