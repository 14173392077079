import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import { useLocationConfigQuery } from "../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../graphql/reactiveVariables";
import useLocations from "../../hooks/useLocations";
import ROLES from "../../roles";
import Presenter from "./presenter";

interface LocationsPageProps {}

const LocationsPage: React.FC<LocationsPageProps> = ({ children }) => {
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data: locationConfigData } = useLocationConfigQuery();
  const [topLocations, refetchLocation] = useLocations({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("reload")) refetchLocation();
  }, [searchParams, refetchLocation]);

  useEffect(() => {
    layoutOptionsVar({
      title: "Locations",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Locations</title>
      </Helmet>
      {topLocations && locationConfigData ? (
        <Presenter
          locations={topLocations}
          locationConfigData={locationConfigData}
          canAddLocation={ROLES.locationsCreate.includes(
            currentCompanyRole.role
          )}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default LocationsPage;

gql`
  query Locations($parentId: UUID) {
    locations(parentId: $parentId) {
      ...LocationWithRelationsFragment
    }
  }

  fragment LocationIndexFragment on Location {
    id
    name
    type
    address1
    address2
    city
    state
    zip
    countryAlpha2
    coordinates
    parentId
    createdAt

    floorPlans {
      id
      name

      floorPlanAssets {
        id
        assetId

        asset {
          id
          maintenanceStatus
        }
      }
    }
  }

  query LocationIndex($parentId: UUID) {
    locations(parentId: $parentId) {
      ...LocationIndexFragment
    }
  }

  query LocationTypes {
    locationTypes
  }
`;
