import { useEffect, useState } from "react";

import { useUserQuery } from "../graphql/graphql";

const useUserSettingsProgress = () => {
  const { data } = useUserQuery();
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (data) {
      let localPercent = 0;
      const user = data.user;
      const name = user.fullName;
      if (name) localPercent += 20;
      if (user.email) localPercent += 20;
      if (user.emailConfirmed) localPercent += 20;
      if (user.phone) localPercent += 20;
      if (user.phoneConfirmed) localPercent += 20;
      setPercent(Math.round(localPercent));
    }
  }, [data]);

  return percent;
};

export default useUserSettingsProgress;
