export class SortingHelper {
  static sortItems<T>(
    newSortField: keyof T,
    newSortDirection: "asc" | "desc",
    items: T[]
  ): T[] {
    const sortedItems = [...items];
    sortedItems.sort((a, b) => {
      const el1 = a[newSortField] || "";
      const el2 = b[newSortField] || "";
      if (newSortDirection === "asc") {
        return el1 < el2 ? -1 : el1 > el2 ? 1 : 0;
      } else {
        return el1 > el2 ? -1 : el1 < el2 ? 1 : 0;
      }
    });
    return sortedItems;
  }
}
