import { useReactiveVar } from "@apollo/client";
import {
  EuiFlexGroup,
  EuiFlexItem,
  useEuiTheme,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { useEffect, useState } from "react";

import FileInput from "../../components/fileInput";
import {
  workOrdersAcceptedFileTypes,
  workOrdersFileMaxSize,
} from "../../helpers/filesValidation";
import { createWORedirectData } from "../../helpers/workOrdersReactiveVariables";
import { WorkOrderCreateRedirectData } from "../../models/createWORedirectData";
import { WorkOrder, WorkOrderAsset } from "../../models/workOrder";
import WorkOrderCreateAddAssetModal from "./addAsset";
import WorkOrderCreateServicesGeneralInfo from "./general";
import WorkOrderCreateServicesServiceAssigneeInfo from "./serviceAssignee";
import WorkOrderCreateServicesServicePlanInfo from "./servicePlan";
import WorkOrderCreateServicesServiceScheduleInfo from "./serviceSchedule";

interface WorkOrderCreateServicesPresenterProps {
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  workOrder: WorkOrder;
  onFilesAdded: (files: File[]) => void;
  onFileDelete: (files: File) => void;
}

const WorkOrderCreateServicesPresenter: React.FC<
  WorkOrderCreateServicesPresenterProps
> = ({ onWorkOrderChange, workOrder, onFilesAdded, onFileDelete }) => {
  const [isAddAssetModalVisible, setIsAddAssetModalVisible] = useState(false);
  const isMobile = useIsWithinBreakpoints(["xs", "s"]);
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();
  const WOCreateData: WorkOrderCreateRedirectData | null =
    useReactiveVar(createWORedirectData);

  const handleAddAssetClick = () => {
    setIsAddAssetModalVisible(true);
  };

  const handleAssetDelete = (deletedAsset: WorkOrderAsset) => {
    const filteredAssets = workOrder.assetWorkOrders?.filter(
      (asset) => asset.assetId !== deletedAsset.assetId
    );
    onWorkOrderChange({
      ...workOrder,
      assetWorkOrders: filteredAssets,
    });
  };

  const handleAddNewAsset = (asset: WorkOrderAsset) => {
    const assets = workOrder.assetWorkOrders?.length
      ? [...workOrder.assetWorkOrders, asset]
      : [asset];
    setIsAddAssetModalVisible(false);
    onWorkOrderChange({
      ...workOrder,
      assetWorkOrders: assets,
    });
  };

  useEffect(() => {
    if (WOCreateData?.assetId) {
      setIsAddAssetModalVisible(true);
    }
  }, [WOCreateData]);

  return (
    <>
      <FileInput
        multiple={true}
        accept={workOrdersAcceptedFileTypes}
        maxSize={workOrdersFileMaxSize}
        acceptErrorMassage="Only JPEG, PNG, PDF file are allowed"
        maxSizeErrorMassage="Max size of the file must be no more than 10 MB"
        onChange={onFilesAdded}
        disableClick={true}
        showDropArea={true}
        dropAreaMargin={12}
        style={{ width: "100%", height: "100%", paddingBottom: "80px" }}
      >
        <EuiFlexGroup
          direction="row"
          justifyContent="spaceBetween"
          alignItems="flexStart"
          gutterSize="xl"
          style={{ height: "calc(100vh - 328px)" }}
        >
          <EuiFlexItem
            grow={3}
            style={{
              minWidth: "360px",
              alignItems: isMobile ? "center" : "flex-start",
              overflow: "auto",
              marginRight: "-30px",
              height: "100%",
              paddingRight: "20px",
            }}
          >
            <WorkOrderCreateServicesGeneralInfo
              workOrder={workOrder}
              onFilesAdded={onFilesAdded}
              onFileDelete={onFileDelete}
              onWorkOrderChange={onWorkOrderChange}
              onAddAssetClick={handleAddAssetClick}
              onAssetDelete={handleAssetDelete}
            />
          </EuiFlexItem>
          <EuiFlexItem
            grow={false}
            style={{
              width: "416px",
              alignItems: isMobile ? "center" : "flex-end",
              gap: euiTheme.size.s,
              overflow: "auto",
              height: "100%",
              paddingRight: "10px",
              marginTop: euiTheme.size.base,
            }}
          >
            <EuiFlexItem
              grow={false}
              style={{
                minWidth: "100%",
              }}
            >
              <WorkOrderCreateServicesServicePlanInfo
                workOrder={workOrder}
                onWorkOrderChange={onWorkOrderChange}
              />
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              style={{
                minWidth: "100%",
              }}
            >
              <WorkOrderCreateServicesServiceScheduleInfo
                workOrder={workOrder}
                onWorkOrderChange={onWorkOrderChange}
              />
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              style={{
                minWidth: "100%",
              }}
            >
              <WorkOrderCreateServicesServiceAssigneeInfo
                workOrder={workOrder}
                onWorkOrderChange={onWorkOrderChange}
              />
            </EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </FileInput>
      <WorkOrderCreateAddAssetModal
        isAddAssetModalVisible={isAddAssetModalVisible}
        addAssetModalVisibleChange={setIsAddAssetModalVisible}
        workOrder={workOrder}
        onAddNewAsset={handleAddNewAsset}
      />
    </>
  );
};

export default WorkOrderCreateServicesPresenter;
