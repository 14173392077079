import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import Link from "../../components/elements/link";
import PageNotFoundIcon from "../../components/icons/pageNotFound";
import { getRoutePath } from "../../router";

interface NotFoundPagePresenterProps {
  isLoggedIn: boolean;
}

const NotFoundPagePresenter: React.FC<NotFoundPagePresenterProps> = ({
  isLoggedIn,
}) => (
  <Flex
    alignItems="center"
    flexDirection={{ base: "column", md: "row" }}
    justifyContent="center"
    minHeight={{ base: "500px", md: "700px" }}
  >
    <PageNotFoundIcon
      width={{ base: "200px", md: "250px" }}
      height={{ base: "250px", md: "290px" }}
    />
    <Box textAlign="center">
      <Text fontSize={{ base: "4xl", md: "6xl" }}>404</Text>
      <Text fontSize={{ base: "xl", md: "2xl" }}>
        Oops, that page doesn't exist!
      </Text>
      <Text fontSize={{ base: "xs", md: "xs" }} marginTop="2" color="gray.600">
        The page you requested could not be found
      </Text>
      <Link
        to={isLoggedIn ? getRoutePath("dashboard") : getRoutePath("signIn")}
        marginTop={{ base: "6", md: "12" }}
        paddingX="4"
        paddingY="2"
        height="auto"
      >
        Get Me Outa Here!
      </Link>
    </Box>
  </Flex>
);

export default NotFoundPagePresenter;
