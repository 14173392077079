import {
  Box,
  Center,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import FileIcon from "../../../../components/elements/fileIcon";
import Link from "../../../../components/elements/link";
import TooltipName from "../../../../components/elements/tooltipName";
import { AssetFileFragmentFragment } from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import AssetFileDelete from "../../assetFileDelete";

interface GridPresenterProps {
  assetFiles: AssetFileFragmentFragment[];
  assetId: string;
  canDeleteAsset: boolean;
  canEditAsset: boolean;
  selectedFiles: Array<string | undefined>;
  setSelectedFiles: Dispatch<SetStateAction<(string | undefined)[]>>;
}

const GridPresenter: React.FC<GridPresenterProps> = ({
  assetFiles,
  assetId,
  canDeleteAsset,
  canEditAsset,
  selectedFiles,
  setSelectedFiles,
}) => {
  return (
    <Flex flexWrap="wrap">
      {assetFiles.map((assetFile) => (
        <FileGridItem
          key={assetFile.id}
          assetFile={assetFile}
          assetId={assetId}
          canDeleteAsset={canDeleteAsset}
          canEditAsset={canEditAsset}
          onSelect={setSelectedFiles}
          selectedFiles={selectedFiles}
        />
      ))}
    </Flex>
  );
};

const FileGridItem = ({
  assetFile,
  assetId,
  canDeleteAsset,
  canEditAsset,
  onSelect,
  selectedFiles,
}: {
  assetFile: AssetFileFragmentFragment;
  assetId: string;
  canDeleteAsset: boolean;
  canEditAsset: boolean;
  onSelect: Dispatch<SetStateAction<(string | undefined)[]>>;
  selectedFiles: Array<string | undefined>;
}) => {
  const navigate = useNavigate();
  const {
    isOpen: isOpenFileDelete,
    onOpen: onOpenFileDelete,
    onClose: onCloseFileDelete,
  } = useDisclosure();

  return (
    <Box role="group" position="relative" pb="8" px="2" boxSize="160px">
      <Link
        to={getRoutePath("assetsShowFile", {
          assetId,
          fileId: assetFile.id,
        })}
        variant="link"
        colorScheme="secondary"
        width="full"
      >
        <Center width="100%">
          <Box width="100%" textAlign="center">
            <FileIcon
              filePath={assetFile.path}
              fileName={assetFile.name}
              width={100}
              height={80}
            />
            <TooltipName
              fontSize={{ base: "sm", md: "base" }}
              color="gray.800"
              marginTop="2"
              textAlign="center"
              name={assetFile.name}
            />
          </Box>
        </Center>
      </Link>
      {canDeleteAsset && (
        <Box
          as="button"
          display={{
            base: "block",
            md: selectedFiles.includes(assetFile.id) ? "block" : "none",
          }}
          position="absolute"
          _groupHover={{ display: "block" }}
          top="0"
          onClick={() => {
            if (!selectedFiles.includes(assetFile.id)) {
              onSelect([...selectedFiles, assetFile.id]);
            } else {
              onSelect((prevSelectedFiles) =>
                prevSelectedFiles.filter(
                  (selectedFile) => selectedFile !== assetFile.id
                )
              );
            }
          }}
          color={
            selectedFiles.includes(assetFile.id) ? "secondary.500" : "gray.500"
          }
          _hover={{ color: "secondary.500" }}
        >
          <FontAwesomeIcon
            size="lg"
            icon={
              selectedFiles.includes(assetFile.id) ? faCheckSquare : faSquare
            }
          />
        </Box>
      )}
      <Flex
        opacity="0"
        _groupHover={{ opacity: 1 }}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        width="100%"
        bottom="0"
        left="0"
      >
        {canEditAsset && (
          <Tooltip label="Edit Document" hasArrow placement="bottom">
            <Link
              to={getRoutePath("assetsShowFileEdit", {
                assetId,
                fileId: assetFile.id,
              })}
              aria-label="Edit Document"
              variant="icon"
              colorScheme="gray"
            >
              <FontAwesomeIcon icon={faPen} />
            </Link>
          </Tooltip>
        )}
        {canDeleteAsset && (
          <Tooltip label="Delete Document" hasArrow placement="bottom">
            <IconButton
              variant="icon"
              aria-label="Delete Document"
              colorScheme="grayRed"
              marginX="4"
              onClick={onOpenFileDelete}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        )}
      </Flex>

      {isOpenFileDelete && (
        <AssetFileDelete
          assetFile={assetFile}
          assetId={assetId}
          handleDelete={() => {
            onCloseFileDelete();
            navigate(getRoutePath("assetsShow", { assetId }));
          }}
        />
      )}
    </Box>
  );
};

export default GridPresenter;
