import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const MasterCard = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    viewBox="0 0 56 32"
    width="14"
    height="88"
    {...props}
  >
    <rect width="55.8742" height="32" fill="white" />
    <path
      d="M31.8537 8.83292H23.1484V23.1646H31.8537V8.83292Z"
      fill="#FF5F00"
    />
    <path
      d="M24.0454 16C24.0444 14.62 24.3547 13.2578 24.953 12.0165C25.5513 10.7753 26.4219 9.68748 27.4989 8.83544C26.165 7.77878 24.5631 7.12171 22.8763 6.93932C21.1894 6.75693 19.4857 7.05658 17.9598 7.80402C16.434 8.55146 15.1475 9.71654 14.2475 11.1661C13.3474 12.6157 12.8701 14.2912 12.8701 16.0013C12.8701 17.7113 13.3474 19.3869 14.2475 20.8364C15.1475 22.286 16.434 23.4511 17.9598 24.1985C19.4857 24.9459 21.1894 25.2456 22.8763 25.0632C24.5631 24.8808 26.165 24.2237 27.4989 23.1671C26.4216 22.3148 25.5508 21.2266 24.9525 19.9848C24.3541 18.7431 24.044 17.3804 24.0454 16V16Z"
      fill="#EB001B"
    />
    <path
      d="M41.2646 21.6481V21.3544H41.3902V21.2937H41.0913V21.3544H41.2094V21.6481H41.2646ZM41.8448 21.6481V21.2937H41.7544L41.6489 21.5468L41.5434 21.2937H41.453V21.6481H41.5183V21.3797L41.6162 21.6101H41.684L41.782 21.3797V21.6481H41.8448Z"
      fill="#F79E1B"
    />
    <path
      d="M42.1291 16C42.1291 17.7102 41.6517 19.3858 40.7515 20.8354C39.8513 22.2851 38.5646 23.4501 37.0385 24.1974C35.5125 24.9448 33.8086 25.2442 32.1217 25.0616C30.4347 24.8789 28.8328 24.2215 27.499 23.1646C28.5756 22.3118 29.4459 21.2237 30.0444 19.9823C30.6428 18.741 30.9538 17.3789 30.9538 15.9987C30.9538 14.6186 30.6428 13.2565 30.0444 12.0152C29.4459 10.7738 28.5756 9.68572 27.499 8.83292C28.8328 7.77593 30.4347 7.11854 32.1217 6.93589C33.8086 6.75324 35.5125 7.0527 37.0385 7.80003C38.5646 8.54737 39.8513 9.71243 40.7515 11.162C41.6517 12.6116 42.1291 14.2873 42.1291 15.9975V16Z"
      fill="#F79E1B"
    />
  </Icon>
);

export default MasterCard;
