import "moment-timezone";

import { EuiFlexGroup, EuiFlexItem, useEuiTheme } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";

import Accordion from "../../../components/accordion";
import DateTimePicker from "../../../components/dateTimePicker";
import Label from "../../../components/label";
import {
  getUtcOffsetFromTimezoneName,
  isDateValid,
} from "../../../helpers/dateTime";
import {
  endDateValid,
  startDateValid,
} from "../../../helpers/workOrdersReactiveVariables";
import { ScheduleTypeEnum } from "../../../models/enums";
import ViewEditSelect from "../../components/viewEditSelect";
import { SectionDataSchedule } from "./index";
import WorkOrderTimeZoneSelector from "./timezoneSelector";

interface WorkOrderEditSchedulePresenterProps {
  sectionData: SectionDataSchedule;
  onEndDateSelected: (endDate: string) => void;
  onStartDateSelected: (endDate: string) => void;
  onScheduleTypeSelected: (scheduleType: ScheduleTypeEnum | string) => void;
  onTimezoneSelected: (timezone: string) => void;
}

const WorkOrderEditSchedulePresenter: React.FC<
  WorkOrderEditSchedulePresenterProps
> = ({
  sectionData,
  onEndDateSelected,
  onStartDateSelected,
  onScheduleTypeSelected,
  onTimezoneSelected,
  children,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();
  const minSelectorWidth = "235px";
  const minValidDate = moment.utc();
  const recurrenceAvailable = false;
  const minValidDateError = "You cannot set up elapsed time";
  const scheduleTypeOptions: EuiSuperSelectOption<string>[] = [
    { value: ScheduleTypeEnum.OPEN_DUE, inputDisplay: "Open Due Date" },
    { value: ScheduleTypeEnum.STRICT_DUE, inputDisplay: "Strict Due Date" },
    { value: ScheduleTypeEnum.STRICT_TIME, inputDisplay: "Strict Time Frame" },
  ];
  const [endDateMinValue, setEndDateMinValue] = useState<Moment>(moment());
  const [startDateMaxValue, setStartDateMaxValue] = useState<Moment | null>();
  const [startDateErrors, setStartDateErrors] = useState<string[]>([]);
  const [endDateErrors, setEndDateErrors] = useState<string[]>([]);

  useEffect(() => {
    setStartDateMaxValue(
      sectionData.endDateTime
        ? moment.utc(sectionData.endDateTime).subtract(1, "minutes")
        : null
    );
    const minDateFromStartDate = moment
      .utc(sectionData.startDateTime)
      .add(1, "minutes");
    setEndDateMinValue(
      sectionData.startDateTime &&
        minDateFromStartDate.valueOf() > minValidDate.valueOf()
        ? minDateFromStartDate
        : minValidDate
    );
    let newEndDateErrors = !sectionData.endDateTime
      ? []
      : isDateValid(sectionData.endDateTime, minValidDate)
      ? []
      : [minValidDateError];
    let newStartDateErrors = !sectionData.startDateTime
      ? []
      : isDateValid(moment.utc(sectionData.startDateTime), minValidDate)
      ? []
      : [minValidDateError];
    (newEndDateErrors.length || endDateErrors.length) &&
      setEndDateErrors(newEndDateErrors);
    (newStartDateErrors.length || startDateErrors.length) &&
      setStartDateErrors(newStartDateErrors);
  }, [sectionData.endDateTime, sectionData.startDateTime]);

  const _onError = useCallback(
    ({
      startError,
      endError,
    }: {
      startError?: string[];
      endError?: string[];
    }): void => {
      if (startError) {
        startDateValid(!startError.length);
      }
      if (endError) {
        endDateValid(!endError.length);
      }
    },
    [sectionData.endDateTime, sectionData.startDateTime]
  );

  return (
    <EuiFlexItem style={{ minWidth: "100%" }} grow={0}>
      <Accordion
        id="wo-service-schedule"
        label="Service Schedule"
        required={true}
        initialIsOpen={true}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
          gutterSize="none"
        >
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Schedule Type" required={true} />
            <div style={{ minWidth: minSelectorWidth }}>
              <ViewEditSelect
                selectedValue={sectionData.scheduleType}
                options={scheduleTypeOptions}
                onSelect={onScheduleTypeSelected}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              marginTop: euiTheme.size.l,
            }}
          >
            <WorkOrderTimeZoneSelector
              workOrder={sectionData}
              onTimezoneSelected={onTimezoneSelected}
            />
          </EuiFlexItem>
          {sectionData?.scheduleType === ScheduleTypeEnum.STRICT_TIME && (
            <EuiFlexItem
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: euiTheme.size.s,
                marginTop: euiTheme.size.l,
              }}
            >
              <Label label="Start Date" required={true} />
              <div
                style={{
                  minWidth: minSelectorWidth,
                  maxWidth: minSelectorWidth,
                }}
              >
                <DateTimePicker
                  className={"view-edit-date-picker"}
                  selectedValue={sectionData.startDateTime}
                  utcOffset={getUtcOffsetFromTimezoneName(
                    sectionData.serviceScheduleTimezone
                  )}
                  minDate={minValidDate}
                  maxDate={startDateMaxValue}
                  required={true}
                  returnUtc={true}
                  placeholder="Start Date"
                  errors={startDateErrors}
                  invalid={
                    !sectionData.startDateTime || !!startDateErrors.length
                  }
                  onChange={onStartDateSelected}
                  onInternalErrors={(errors) =>
                    _onError({ startError: errors })
                  }
                />
              </div>
            </EuiFlexItem>
          )}
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
              marginTop: euiTheme.size.l,
            }}
          >
            <Label label="End Date" required={true} />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: minSelectorWidth }}
            >
              <DateTimePicker
                className={"view-edit-date-picker"}
                selectedValue={sectionData.endDateTime}
                utcOffset={getUtcOffsetFromTimezoneName(
                  sectionData.serviceScheduleTimezone
                )}
                minDate={endDateMinValue}
                required={true}
                returnUtc={true}
                errors={endDateErrors}
                invalid={!sectionData.endDateTime || !!endDateErrors.length}
                placeholder="End Date"
                onChange={onEndDateSelected}
                onInternalErrors={(errors) => _onError({ endError: errors })}
              />
            </div>
          </EuiFlexItem>
          {recurrenceAvailable ? (
            <EuiFlexItem
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: euiTheme.size.s,
                marginTop: euiTheme.size.l,
              }}
            >
              <Label label="Recurrence" disabled={true} />
              <div style={{ minWidth: minSelectorWidth }}>
                <ViewEditSelect
                  selectedValue={null}
                  disabled={true}
                  options={[]}
                  onSelect={() => {}}
                  compressed={true}
                  placeholder={"Does not repeat"}
                />
              </div>
            </EuiFlexItem>
          ) : null}
          <div style={{ marginTop: euiTheme.size.l }}>{children}</div>
        </EuiFlexGroup>
      </Accordion>
    </EuiFlexItem>
  );
};

export default WorkOrderEditSchedulePresenter;
