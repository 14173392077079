import { Box, Button, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
  FloorPlanAssetForMapFragmentFragment,
  FloorPlanForMapFragmentFragment,
} from "../../../graphql/graphql";
import Leaflet from "./leaflet";
import MapAssetList from "./mapAssetList";

interface FloorPlanPresenterProps {
  floorPlan: FloorPlanForMapFragmentFragment;
  floorPlanAssets: FloorPlanAssetForMapFragmentFragment[];
}

const FloorPlanPresenter: React.FC<FloorPlanPresenterProps> = ({
  floorPlan,
  floorPlanAssets,
}) => {
  const [isPlanView, setPlanView] = useState(true);
  const [isTabOrDesktop] = useMediaQuery("(min-width: 767px)");

  return (
    <Flex position="relative" height="screenFull">
      <Helmet>
        <title>{floorPlan.name}</title>
      </Helmet>
      <Box
        gap={2}
        flexGrow={0}
        paddingY="2"
        width={{ base: "full", md: "300px", lg: "400px" }}
        height="screenFull"
        overflowX="hidden"
        overflowY="auto"
        flexShrink={0}
        display="block"
        zIndex={isTabOrDesktop || !isPlanView ? "1" : "0"}
        position={isTabOrDesktop || !isPlanView ? "relative" : "absolute"}
        backgroundColor="white"
      >
        <MapAssetList floorPlan={floorPlan} floorPlanAssets={floorPlanAssets} />
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        zIndex={isTabOrDesktop || isPlanView ? "1" : "0"}
        position={isTabOrDesktop || isPlanView ? "relative" : "absolute"}
        width="full"
        height="screenFull"
        flexDirection="column"
        backgroundColor="white"
      >
        <Leaflet floorPlan={floorPlan} floorPlanAssets={floorPlanAssets} />
      </Box>
      {!isTabOrDesktop && (
        <Box
          position="fixed"
          left="50%"
          bottom="10px"
          transform="translateX(-50%)"
          zIndex="20"
        >
          <Button
            onClick={() => setPlanView((v) => !v)}
            size="sm"
            colorScheme="secondary"
            width="150px"
            boxShadow="lg"
          >
            View {isPlanView ? "Asset List" : "Plan"}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default FloorPlanPresenter;
