import { Alert, AlertDescription, Button, CloseButton } from "@chakra-ui/react";
import gql from "graphql-tag";
import { FC, useCallback, useEffect, useState } from "react";

import { useWebPushNotificationSubscriptionCreateMutation } from "../../../../../graphql/graphql";
import handleException from "../../../../../utils/error";
import { urlB64ToUint8Array } from "../../../../../utils/urlB64ToUint8Array";

const PUSH_DENIED_TOKEN = "push_notifications_denied";
const PUSH_CHECKED_TOKEN = "push_notifications_checked";

export const checkIfNotificationAllowed = () => {
  if (sessionStorage.getItem(PUSH_CHECKED_TOKEN)) return false;
  if (localStorage.getItem(PUSH_DENIED_TOKEN)) return false;
  if (!process.env.REACT_APP_PUBLIC_VAPID_KEY) return false;
  if (!("PushManager" in window) || !("serviceWorker" in navigator))
    return false;
  if (window.Notification.permission === "denied") return false;
  return true;
};

const PushNotification: FC<{ handlePostCheck: () => void }> = ({
  handlePostCheck,
}) => {
  const [webPushNotificationSubscriptionCreate] =
    useWebPushNotificationSubscriptionCreateMutation();
  const [askPermission, setPermission] = useState(true);

  const denyPermission = useCallback(() => {
    setPermission(false);
    handlePostCheck();
    localStorage.setItem(PUSH_DENIED_TOKEN, `${Date.now()}`);
  }, [handlePostCheck]);

  const updatePermission = useCallback(
    async (subscription: any) => {
      sessionStorage.setItem(PUSH_CHECKED_TOKEN, `${Date.now()}`);
      try {
        await webPushNotificationSubscriptionCreate({
          variables: { subscription },
        });
      } catch (error: any) {
        handleException(error);
      }
    },
    [webPushNotificationSubscriptionCreate]
  );

  const subscribeForPushNotifications = useCallback(async () => {
    setPermission(false);
    handlePostCheck();
    if (!process.env.REACT_APP_PUBLIC_VAPID_KEY) return;

    const swReg = await navigator.serviceWorker.ready;
    const subscription = await swReg.pushManager.subscribe({
      applicationServerKey: urlB64ToUint8Array(
        process.env.REACT_APP_PUBLIC_VAPID_KEY
      ),
      userVisibleOnly: true,
    });
    if (subscription) {
      updatePermission(subscription);
    }
  }, [updatePermission, handlePostCheck]);

  useEffect(() => {
    const checkForPermission = async () => {
      if (!checkIfNotificationAllowed()) {
        handlePostCheck();
        return;
      }

      const swReg = await navigator.serviceWorker.ready;
      let subscription = await swReg.pushManager.getSubscription();
      if (subscription) {
        handlePostCheck();
        updatePermission(subscription);
      } else {
        setPermission(true);
      }
    };
    checkForPermission();
  }, [updatePermission, handlePostCheck]);

  return askPermission ? (
    <Alert status="info" role="group">
      <AlertDescription>
        Enable notifications?
        <Button
          colorScheme="primary"
          onClick={subscribeForPushNotifications}
          size="xs"
          marginX="2"
        >
          Enable Now
        </Button>
        <CloseButton
          width={{ base: "auto", md: 8 }}
          position={{ md: "absolute" }}
          right={{ md: "2" }}
          onClick={denyPermission}
          size="sm"
        />
      </AlertDescription>
    </Alert>
  ) : null;
};

export default PushNotification;

gql`
  mutation WebPushNotificationSubscriptionCreate($subscription: JSONObject!) {
    webPushNotificationSubscriptionCreate(subscription: $subscription)
  }
`;
