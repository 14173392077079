import { useReactiveVar } from "@apollo/client";
import {
  Box,
  CloseButton,
  Divider,
  Flex,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@chakra-ui/react";
import { EuiButton, EuiButtonIcon, useEuiTheme } from "@elastic/eui";
import {
  faBriefcase,
  faCalendarAlt,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CompanyUserRole } from "../../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../../graphql/reactiveVariables";
import ROLES from "../../../../roles";
import { getRoutePath } from "../../../../router";
import Link from "../../../elements/link";
import Assets from "../../../icons/asset";
import AssetCategories from "../../../icons/assetCategories";
import Billing from "../../../icons/billing";
import Dashboard from "../../../icons/dashboard";
import Files from "../../../icons/files";
import Help from "../../../icons/help";
import Locations from "../../../icons/locations";
import Logo from "../../../icons/logoDarkBg";
import LogoIcon from "../../../icons/logoIconDarkBg";
import Settings from "../../../icons/settings";
import Teams from "../../../icons/team";
import ToggleNavIcon from "../../../icons/toggleNav";
import CompanySelector from "../companySelector";

interface SideNavProps {
  showInMobile: boolean;
  setShowSideNavInMobile: Function;
  sideNavMinimized: boolean;
  setSideNavMinimized: Function;
}

const TopSideNavItems = [
  {
    path: "workOrders",
    text: "Work orders",
    Icon: (
      <FontAwesomeIcon
        icon={faBriefcase}
        size="lg"
        style={{ paddingLeft: "3px" }}
      />
    ),
  },
  { path: "dashboard", text: "Dashboard", Icon: <Dashboard /> },
  { path: "locations", text: "Locations & Plans", Icon: <Locations /> },
  // {
  //   path: "floorPlans",
  //   text: "Plans",
  //   Icon: <FontAwesomeIcon icon={faMap} width="24px" height="24px" />,
  // },
  {
    path: "maintenances",
    text: "Service Schedule",
    Icon: <FontAwesomeIcon icon={faCalendarAlt} width="24px" height="24px" />,
  },
  { path: "assets", text: "Assets", Icon: <Assets /> },
  { path: "folders", text: "Files", Icon: <Files /> },
  {
    path: "reports",
    text: "Reports",
    Icon: <FontAwesomeIcon icon={faChartPie} size="lg" />,
  },
];

const BottomSideNavItems = [
  {
    path: "companyTeams",
    text: "Team",
    Icon: (
      <Teams boxSize="18px" position="relative" top="-3px" marginRight="6px" />
    ),
  },
  {
    path: "companySettings",
    text: "Company Settings",
    Icon: (
      <Settings
        boxSize="18px"
        position="relative"
        top="-3px"
        marginRight="6px"
      />
    ),
  },
  {
    path: "assetCategories",
    text: "Categories & Types",
    Icon: (
      <AssetCategories
        boxSize="18px"
        position="relative"
        top="-3px"
        marginRight="6px"
      />
    ),
  },
  {
    path: "companyPlan",
    text: "Plan & Billing",
    Icon: (
      <Billing
        boxSize="18px"
        position="relative"
        top="-3px"
        marginRight="6px"
      />
    ),
  },
  {
    path: "helpAndSupport",
    text: "Help & Support",
    Icon: (
      <Help boxSize="18px" position="relative" top="-3px" marginRight="6px" />
    ),
  },
];

const SideNav: React.FC<SideNavProps> = React.memo(
  ({
    showInMobile,
    setShowSideNavInMobile,
    sideNavMinimized,
    setSideNavMinimized,
  }) => {
    const { euiTheme }: any = useEuiTheme();
    const [isWorkOrderDisabled, setWorkOrderDisabled] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      setWorkOrderDisabled(location.pathname?.includes("work-orders/create"));
    }, [location]);
    const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
    const createWorkOrder = useCallback(async () => {
      setShowSideNavInMobile(false);
      return navigate(getRoutePath("workOrdersCreate"));
    }, [navigate]);
    if (!currentCompanyRole) {
      return null;
    }

    return (
      <Box
        backgroundColor="black"
        height="100%"
        overflowY="auto"
        overflowX="hidden"
        position={{ base: "fixed", lg: "static" }}
        shadow={{ base: "xl", lg: "none" }}
        top="0"
        left={showInMobile ? "0" : "-300px"}
        borderRightWidth="1px"
        borderColor="gray.100"
        width={sideNavMinimized ? "80px" : "250px"}
        transition="all 200ms cubic-bezier(0.4, 0, 1, 1)"
        zIndex="5"
      >
        <Box display={{ base: "none", lg: "block" }}>
          <Box paddingY="3" textAlign="center">
            <Link to={getRoutePath("dashboard")} variant="link">
              {sideNavMinimized ? (
                <Box>
                  <LogoIcon width="38px" height="34px" />
                </Box>
              ) : (
                <Box>
                  <Logo />
                </Box>
              )}
            </Link>
          </Box>
          <IconButton
            position="absolute"
            cursor="pointer"
            color={sideNavMinimized ? "secondary.500" : "gray.800"}
            transform={sideNavMinimized ? "rotate(180deg)" : undefined}
            _focus={{ outline: "none" }}
            top={sideNavMinimized ? "50px" : "56px"}
            left={sideNavMinimized ? "56px" : "226px"}
            zIndex="1"
            transition="all 200ms cubic-bezier(0.4, 0, 1, 1)"
            onClick={() => setSideNavMinimized((state: boolean) => !state)}
            variant="unstyled"
            aria-label={
              sideNavMinimized ? "Expand Navigation" : "Collapse Navigation"
            }
            size="sm"
          >
            <ToggleNavIcon />
          </IconButton>
        </Box>
        <Flex
          flexDirection="column"
          width={sideNavMinimized ? "80px" : "250px"}
        >
          <Box paddingX="6">
            <CloseButton
              size="lg"
              color="white"
              width="4"
              marginY="2"
              display={{ base: "block", lg: "none" }}
              onClick={() => setShowSideNavInMobile(false)}
            />
            {!sideNavMinimized && (
              <Box paddingBottom="2">
                <CompanySelector
                  onChange={() => setShowSideNavInMobile(false)}
                />
              </Box>
            )}
          </Box>
          <Box as="nav">
            <List marginLeft="0px">
              {TopSideNavItems.map((nav) => {
                if (
                  ROLES[nav.path] &&
                  !ROLES[nav.path].includes(currentCompanyRole.role)
                )
                  return null;
                return (
                  <ListItem key={nav.text}>
                    <Tooltip
                      label={sideNavMinimized ? nav.text : undefined}
                      placement="right"
                      hasArrow
                    >
                      <Link
                        to={getRoutePath(nav.path)}
                        onClick={() => setShowSideNavInMobile(false)}
                        variant="link"
                        colorScheme="secondary"
                        color="gray.300"
                        opacity="0.8"
                        _active={{ color: "primary.500" }}
                        alignItems="center"
                        justifyContent="center"
                        width="full"
                        leftIcon={nav.Icon}
                        isActive={location.pathname.startsWith(
                          getRoutePath(nav.path)
                        )}
                        _hover={{
                          color: "white",
                          opacity: 1,
                          backgroundColor: "gray.950",
                          borderLeftColor: "white",
                        }}
                        padding="12px 24px"
                        borderLeft="2px solid"
                        borderLeftColor="transparent"
                      >
                        {!sideNavMinimized && (
                          <Box as="span" marginLeft="1" flexGrow={1}>
                            {nav.text}
                          </Box>
                        )}
                      </Link>
                    </Tooltip>
                  </ListItem>
                );
              })}
            </List>
            <Divider
              borderColor="gray.100"
              width="auto"
              marginY="3"
              marginX="6"
              opacity="0.15"
            />
            <List marginLeft="0px">
              {BottomSideNavItems.map((nav) => {
                if (
                  ROLES[nav.path] &&
                  !ROLES[nav.path].includes(currentCompanyRole.role)
                )
                  return null;
                return (
                  <ListItem key={nav.text}>
                    <Tooltip
                      label={sideNavMinimized ? nav.text : undefined}
                      placement="right"
                      hasArrow
                    >
                      <Link
                        to={getRoutePath(nav.path)}
                        onClick={() => setShowSideNavInMobile(false)}
                        variant="link"
                        colorScheme="secondary"
                        color="gray.300"
                        opacity="0.8"
                        _active={{ color: "primary.500" }}
                        alignItems="center"
                        justifyContent="left"
                        width="full"
                        leftIcon={nav.Icon}
                        isActive={location.pathname.includes(
                          getRoutePath(nav.path)
                        )}
                        _hover={{
                          color: "white",
                          opacity: 1,
                          backgroundColor: "gray.950",
                          borderLeftColor: "white",
                        }}
                        padding="8px 24px"
                        borderLeft="2px solid"
                        borderLeftColor="transparent"
                        sx={{
                          svg: {
                            position: "relative",
                            top: "1px",
                          },
                        }}
                      >
                        {!sideNavMinimized && (
                          <Box as="span" marginLeft="1" flexGrow={1}>
                            {nav.text}
                          </Box>
                        )}
                      </Link>
                    </Tooltip>
                  </ListItem>
                );
              })}
            </List>
            <Divider
              borderColor="gray.100"
              width="auto"
              marginY="3"
              marginX="6"
              opacity="0.15"
            />
            {currentCompanyRole.role !== CompanyUserRole.ViewOnly ? (
              <>
                <List>
                  <Box marginRight="6" marginLeft="6" marginY="5">
                    {!sideNavMinimized && (
                      <EuiButton
                        onClick={() => createWorkOrder()}
                        iconType="plusInCircle"
                        iconSide="left"
                        fullWidth={true}
                        style={{
                          backgroundColor: isWorkOrderDisabled
                            ? euiTheme.themeColors?.gray["500"]
                            : euiTheme.themeColors?.primary["500"],
                          pointerEvents: isWorkOrderDisabled ? "none" : "auto",
                          color: euiTheme.themeColors?.black,
                          textDecoration: "none",
                        }}
                      >
                        Create Work Order
                      </EuiButton>
                    )}
                    {sideNavMinimized && (
                      <EuiButtonIcon
                        iconType="plus"
                        aria-label="Create Work Order"
                        onClick={() => createWorkOrder()}
                        style={{
                          backgroundColor: euiTheme.themeColors?.primary["500"],
                          color: euiTheme.themeColors?.white,
                        }}
                      />
                    )}
                  </Box>
                </List>
              </>
            ) : null}
            {/* {!sideNavMinimized &&
             ROLES.companyPlan.includes(currentCompanyRole.role) && (
             <Box paddingX="6">
             <Button
             variant="outline"
             width="full"
             _hover={{
             bgColor: "primary.500",
             borderColor: "primary.500",
             color: "black",
             }}
             onClick={() => {
             setSearchParams({ upgradePlan: "true" });
             setShowSideNavInMobile(false);
             }}
             marginY="5"
             size="sm"
             leftIcon={<Rocket />}
             >
             <Box as="span" marginLeft="1">
             Upgrade
             </Box>
             </Button>
             </Box>
             )} */}
          </Box>
        </Flex>
      </Box>
    );
  }
);

export default SideNav;
