import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import timezones from "../../../utils/date/timezones";
import SelectCombobox from "../selectCombobox";

interface TimezoneFieldProps {
  name?: string;
}

const TimezoneField: React.FC<TimezoneFieldProps> = ({ name = "timezone" }) => {
  const { values } = useFormikContext<any>();
  const timezoneValue = get(values, name);
  const { isOpen, onOpen } = useDisclosure({ defaultIsOpen: !timezoneValue });

  return isOpen ? (
    <SelectCombobox label="Timezone" name={name} options={timezones} />
  ) : (
    <Flex alignItems="center" marginTop={3}>
      <Text fontSize="xs" marginRight="1">
        {timezones.find((tz) => tz.value === timezoneValue)?.label}
      </Text>
      <Button
        onClick={onOpen}
        variant="link"
        colorScheme="secondary"
        size="xs"
        fontSize="xs"
        aria-label="Change Timezone"
      >
        Change?
      </Button>
    </Flex>
  );
};

export default TimezoneField;
