import { Box, Link as ChakraLink, Grid } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  useAppSettingsQuery,
  useUpdateAppSettingsMutation,
  useUserQuery,
} from "../../../graphql/graphql";
import { setUserData } from "../../../utils/ga";
import Banner from "./banners";
import DashboardHeader from "./header";
import DashboardMain from "./main";
import SideNav from "./sideNav";

interface DashboardLayoutProps {
  disableSearch?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  disableSearch,
  children,
}) => {
  const { data: userData } = useUserQuery();
  const { data } = useAppSettingsQuery();
  const [showSideNavInMobile, setShowSideNavInMobile] =
    useState<boolean>(false);
  const [updateAppSettingsMutation] = useUpdateAppSettingsMutation();

  const sideNavMinimized = !!data?.appSettings.sideNavMinimized;
  const setSideNavMinimized = React.useCallback(() => {
    updateAppSettingsMutation({
      variables: { sideNavMinimized: !sideNavMinimized },
    });
  }, [updateAppSettingsMutation, sideNavMinimized]);

  React.useEffect(() => {
    if (userData) {
      setUserData(userData.user.id, userData.user.fullName);
    }
  }, [userData]);

  return (
    <>
      <ChakraLink href="#main" srOnly>
        Skip to content
      </ChakraLink>
      <Grid
        height="screenFull"
        gridTemplateAreas={{
          base: `"billing-banner billing-banner" "header header" "main main"`,
          lg: `"billing-banner billing-banner" "side-nav header" "side-nav main"`,
        }}
        gridTemplateColumns={`${sideNavMinimized ? "80px" : "250px"} 1fr`}
        gridTemplateRows="auto auto minmax(0, 1fr)"
      >
        <Grid gridArea="billing-banner">
          <Banner />
        </Grid>
        <Box as="header" gridArea="header">
          <DashboardHeader
            setShowSideNavInMobile={setShowSideNavInMobile}
            disableSearch={disableSearch}
          />
        </Box>

        <DashboardMain>{children}</DashboardMain>

        {showSideNavInMobile && (
          <Grid
            order={2}
            top="0"
            left="0"
            bottom="0"
            right="0"
            opacity="0.25"
            backgroundColor="black"
            position="fixed"
            overflowY="auto"
            padding="4"
            onClick={() => setShowSideNavInMobile(false)}
            zIndex="4"
          />
        )}
        <Grid gridArea="side-nav" position="relative">
          <SideNav
            showInMobile={showSideNavInMobile}
            setShowSideNavInMobile={setShowSideNavInMobile}
            sideNavMinimized={sideNavMinimized}
            setSideNavMinimized={setSideNavMinimized}
          />
        </Grid>
      </Grid>
      <ChakraLink href="#main" srOnly>
        Skip to content
      </ChakraLink>
    </>
  );
};

export default DashboardLayout;
