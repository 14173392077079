import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import AddressFields from "../../../components/elements/addressFields";
import Autosuggest from "../../../components/elements/autosuggest";
import ContactCompanyUserField from "../../../components/elements/contactCompanyUserField";
import FormGroup from "../../../components/elements/formGroup";
import {
  GENERIC_SAVED_MESSAGE,
  LOCATION_DELETED_MESSAGE,
} from "../../../constants/lang/en";
import {
  LocationWithRelationsFragmentFragment,
  useLocationDeleteMutation,
  useLocationTypesQuery,
  useLocationUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  LocationFormData,
  locationFormValidationSchema,
} from "../create/presenter";

interface LocationsEditPresenterProps {
  id: string;
  location: LocationWithRelationsFragmentFragment;
}

const LocationsEditPresenter: React.FC<LocationsEditPresenterProps> = ({
  id,
  location,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { data } = useLocationTypesQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [locationDeleteMutation, { loading: deleting }] =
    useLocationDeleteMutation();

  const [locationUpdateMutation, { loading }] = useLocationUpdateMutation();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isDeleteOpen,
    onOpen: openDelete,
    onClose: closeDelete,
  } = useDisclosure();

  const onSubmit = async (
    data: LocationFormData,
    { setFieldError }: FormikHelpers<LocationFormData>
  ) => {
    try {
      const { data: serverData, errors } = await locationUpdateMutation({
        variables: { data: data as any, id },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("locationsShow", { locationId: id }));
    }, 500);
  };

  const deleteLocation = async () => {
    try {
      const { errors } = await locationDeleteMutation({
        variables: { id },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        onClose();
        navigate(getRoutePath("locations"));
        toast({
          description: LOCATION_DELETED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Edit {location.parentId ? "sublocation" : "location"} for{" "}
          {location.name}
        </title>
      </Helmet>
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              Edit {location.parentId ? "sublocation" : "location"} for{" "}
              {location.name}
            </DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{
                  type: location.type || "",
                  name: location.name || "",
                  description: location.description || "",
                  companyUserIds: location.locationCompanyUsers
                    ? location.locationCompanyUsers.map(
                        (lcu) => lcu.companyUser.id
                      )
                    : [],
                  address1: location.address1 || "",
                  address2: location.address2 || "",
                  city: location.city || "",
                  state: location.state || "",
                  zip: location.zip || "",
                  countryAlpha2: location.countryAlpha2 || "US",
                  coordinates: location.coordinates || [0, 0],
                }}
                validationSchema={locationFormValidationSchema(
                  location.parentId
                )}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ getFieldProps }) => (
                  <Form id="location_edit" noValidate>
                    {!!location.parentId && (
                      <FormGroup label="Sublocation Type" name="type">
                        <Autosuggest
                          items={data?.locationTypes || []}
                          autoComplete="off"
                          name="type"
                          autoFocus
                        />
                      </FormGroup>
                    )}
                    <FormGroup
                      label={
                        location.parent ? "Sublocation Name" : "Location Name"
                      }
                      name="name"
                      hint="Corporate Office, Warehouse, Parking Lot, etc."
                    >
                      <Input
                        autoFocus={!location.parentId}
                        autoComplete="off"
                        {...getFieldProps("name")}
                      />
                    </FormGroup>
                    <FormGroup label="Description" name="description">
                      <Textarea
                        autoComplete="off"
                        {...getFieldProps("description")}
                      />
                    </FormGroup>
                    {!location.parentId && (
                      <>
                        <AddressFields />
                        <ContactCompanyUserField name="companyUserIds" />
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter flexDirection="column">
              <Button
                type="submit"
                isLoading={loading}
                form="location_edit"
                width="full"
              >
                Save
              </Button>
              <Button
                type="button"
                variant="link"
                colorScheme="red"
                color="gray.500"
                marginTop="2"
                onClick={openDelete}
                fontSize="12px"
              >
                Delete this {location.parentId ? "sublocation" : "location"}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>

        {isDeleteOpen && (
          <AlertDialog
            leastDestructiveRef={cancelRef}
            onClose={closeDelete}
            isOpen={isDeleteOpen}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogBody textAlign="center">
                  <Text marginBottom="4">
                    Are you sure you want to delete the following{" "}
                    {location.parentId ? "sublocation" : "location"}?
                    <br />
                    <Text as="strong">{location.name}</Text>
                  </Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={closeDelete}
                    width="48%"
                    isLoading={deleting}
                  >
                    No, Don't Delete!
                  </Button>
                  <Button
                    onClick={deleteLocation}
                    colorScheme="red"
                    ml="4%"
                    width="48%"
                    isLoading={deleting}
                  >
                    Yes, Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}
      </Drawer>
    </>
  );
};

export default LocationsEditPresenter;

gql`
  mutation LocationUpdate($id: UUID!, $data: LocationInput!) {
    locationUpdate(id: $id, data: $data) {
      ...LocationWithRelationsFragment
    }
  }
`;
