import { useEffect, useState } from "react";

import { useFloorPlanSimpleQuery } from "../graphql/graphql";

const useFirstFloorPlan = () => {
  const { data } = useFloorPlanSimpleQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [floorPlan, setFloorPlan] = useState<{
    id: string;
    name: string;
  } | null>(null);

  useEffect(() => {
    if (data && data.floorPlans.length) {
      setFloorPlan(data.floorPlans[0]);
    }
  }, [data]);

  return floorPlan;
};

export default useFirstFloorPlan;
