import {
  Box,
  Button,
  ButtonProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  endOfDay,
  endOfYear,
  format,
  isEqual,
  isToday,
  startOfDay,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";

import defaultTheme from "../../../chakraTheme";
import { defaultShortDateFormatFullYear } from "../../../constants/date";
import colors from "../../../theme/foundations/colors";
import { DateInputWrap } from "../datePickerField";

export type DateRange = [Date | null, Date | null];

interface DateRangePickerProps {
  startDateTime: Date | null;
  endDateTime: Date | null;
  onChange: (range: DateRange) => void;
  buttonProps?: ButtonProps;
}

type DateRangeShortCut =
  | "7d"
  | "1m"
  | "2m"
  | "3m"
  | "6m"
  | "1y"
  | "cy"
  | "ly"
  | "cu";

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDateTime,
  endDateTime,
  onChange,
  buttonProps = {},
}) => {
  const ref = useRef<any>();
  const [isOpen, setOpen] = React.useState(false);
  const datePickerRef = useRef<any>(null);
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [showCalendar, setCalendar] = useState(false);

  useEffect(() => {
    if (ref?.current) ref.current.focus();
  }, [showCalendar]);

  useEffect(() => {
    setDateRange((range) =>
      range[0] &&
      startDateTime &&
      isEqual(range[0], startDateTime) &&
      range[1] &&
      endDateTime &&
      isEqual(range[1], endDateTime)
        ? range
        : [startDateTime, endDateTime]
    );
  }, [startDateTime, endDateTime]);

  const selectedShortCut = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) {
      return null;
    } else if (!isToday(dateRange[1])) {
      if (
        isEqual(startOfYear(subYears(new Date(), 1)), dateRange[0]) &&
        isEqual(endOfYear(subYears(new Date(), 1)), dateRange[1])
      ) {
        return "ly";
      }
      return null;
    } else if (isEqual(startOfYear(new Date()), dateRange[0])) {
      return "cy";
    } else if (differenceInDays(dateRange[1], dateRange[0]) === 7) {
      return "7d";
    } else if (differenceInMonths(dateRange[1], dateRange[0]) === 1) {
      return "1m";
    } else if (differenceInMonths(dateRange[1], dateRange[0]) === 2) {
      return "2m";
    } else if (differenceInMonths(dateRange[1], dateRange[0]) === 3) {
      return "3m";
    } else if (differenceInMonths(dateRange[1], dateRange[0]) === 6) {
      return "6m";
    } else if (differenceInYears(dateRange[1], dateRange[0]) === 1) {
      return "1y";
    }
    return null;
  }, [dateRange]);

  useEffect(() => {
    if (datePickerRef.current?.input) {
      datePickerRef.current.input.readOnly = true;
    }
  }, []);

  useEffect(() => {
    if (isOpen) setCalendar(false);
  }, [isOpen]);

  const handleDateRangeClose = useCallback(
    (dateRange: DateRange) => {
      setOpen(false);
      onChange([
        dateRange[0] ? startOfDay(new Date(dateRange[0])) : null,
        dateRange[1] ? endOfDay(new Date(dateRange[1])) : null,
      ]);
    },
    [onChange]
  );

  const handleDateRangeChange = useCallback(
    (range: DateRange) => {
      setDateRange(range);
      if (range[0] && range[1]) {
        handleDateRangeClose(range);
      }
    },
    [handleDateRangeClose]
  );

  const handleShortCutClick = useCallback(
    (shortcut: DateRangeShortCut) => {
      const today = new Date();
      switch (shortcut) {
        case "7d":
          handleDateRangeChange([subDays(today, 7), today]);
          break;
        case "1m":
          handleDateRangeChange([subMonths(today, 1), today]);
          break;
        case "2m":
          handleDateRangeChange([subMonths(today, 2), today]);
          break;
        case "3m":
          handleDateRangeChange([subMonths(today, 3), today]);
          break;
        case "6m":
          handleDateRangeChange([subMonths(today, 6), today]);
          break;
        case "1y":
          handleDateRangeChange([subYears(today, 1), today]);
          break;
        case "cy":
          handleDateRangeChange([startOfYear(today), today]);
          break;
        case "ly":
          const lastYear = subYears(today, 1);
          handleDateRangeChange([startOfYear(lastYear), endOfYear(lastYear)]);
          break;
        default:
          setCalendar(true);
      }
    },
    [handleDateRangeChange]
  );

  const linkButtonProps: ButtonProps = {
    variant: "link",
    size: "sm",
    colorScheme: "secondary",
    w: "full",
    fontSize: "sm",
    justifyContent: "flex-start",
    pr: "8px !important",
    pl: "32px !important",
    py: "4px !important",
    d: "flex",
    sx: {
      ".chakra-button__icon": {
        pos: "absolute",
        left: "3",
        top: "2",
      },
    },
  };

  return (
    <Popover
      placement="bottom-start"
      isLazy
      onClose={() => handleDateRangeClose(dateRange)}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Button
          isActive={isOpen}
          variant="ghost"
          rightIcon={
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              size="xs"
              color={colors.gray[700]}
            />
          }
          paddingX="2"
          fontWeight="400"
          colorScheme="gray"
          border="1px solid"
          borderColor="gray.500"
          height="8"
          textAlign="left"
          _focus={{ borderColor: "primary.500" }}
          _active={{ borderColor: "primary.500" }}
          _hover={{ backgroundColor: "transparent" }}
          textTransform="none"
          justifyContent="space-between"
          color="gray.600"
          fontSize="xs"
          w="full"
          onClick={() => setOpen(true)}
          {...buttonProps}
        >
          {selectedShortCut ? (
            <>
              {selectedShortCut === "7d" && "Past 7 days"}
              {selectedShortCut === "1m" && "Past 30 days"}
              {selectedShortCut === "2m" && "Past 60 days"}
              {selectedShortCut === "3m" && "Past 90 days"}
              {selectedShortCut === "6m" && "Past 6 months"}
              {selectedShortCut === "1y" && "Past 12 months"}
              {selectedShortCut === "cy" && "This calendar year"}
              {selectedShortCut === "ly" && "Last calendar year"}
            </>
          ) : (
            <>
              {startDateTime || endDateTime
                ? `${
                    startDateTime
                      ? format(startDateTime, defaultShortDateFormatFullYear)
                      : ""
                  } - ${
                    endDateTime
                      ? format(endDateTime, defaultShortDateFormatFullYear)
                      : ""
                  }`
                : "Date Range"}
            </>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="fit-content" borderRadius="0" ref={ref}>
          <PopoverBody overflow="hidden" p="0">
            {showCalendar ? (
              <Box boxSize="256px">
                <DateRangeInputWrap>
                  <DatePicker
                    ref={datePickerRef}
                    selectsRange
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={handleDateRangeChange}
                    monthsShown={1}
                    placeholderText="Pick a date range"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    showPopperArrow={false}
                    inline
                    minDate={subYears(new Date(), 1)}
                    maxDate={addYears(new Date(), 2)}
                  />
                </DateRangeInputWrap>
              </Box>
            ) : (
              <Box py="2" w="full">
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "7d" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("7d")}
                >
                  Past 7 days
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "1m" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("1m")}
                >
                  Past 30 days
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "2m" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("2m")}
                >
                  Past 60 days
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "3m" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("3m")}
                >
                  Past 90 days
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "6m" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("6m")}
                >
                  Past 6 months
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "1y" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("1y")}
                >
                  Past 12 months
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "cy" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("cy")}
                >
                  This calendar year
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    selectedShortCut === "ly" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("ly")}
                >
                  Last calendar year
                </Button>
                <Button
                  {...linkButtonProps}
                  leftIcon={
                    !selectedShortCut ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : undefined
                  }
                  onClick={() => handleShortCutClick("cu")}
                >
                  Custom date range
                </Button>
              </Box>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default DateRangePicker;

const DateRangeInputWrap = styled(DateInputWrap)`
  .react-datepicker {
    background-color: ${colors.gray[50]};
    box-shadow: none;
    width: 100%;
  }
  .react-datepicker__header {
    background-color: ${colors.gray[200]};
  }
  .react-datepicker__month-container {
    float: none;
  }
  .react-datepicker__current-month {
    display: inline-block;
    font-weight: 600;
  }
  .react-datepicker__close-icon {
    position: relative;
    padding-right: 0px;
  }
  .react-datepicker__input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${defaultTheme.colors.gray[300]};
    min-width: 165px;
  }
  .react-datepicker__input-container > input::placeholder {
    color: ${defaultTheme.colors.gray[600]};
    font-size: 15px;
  }
  .react-datepicker__input-container > input {
    border-radius: 0;
    color: ${defaultTheme.colors.black};
    background-color: ${defaultTheme.colors.white};
    border: none;
    font-size: 13px;
    padding: 8px;
    padding-right: 0px;
    height: 36px !important;
    cursor: pointer;
    width: 145px;
  }
  .react-datepicker__close-icon::after {
    background-color: transparent;
    color: ${defaultTheme.colors.gray[600]};
    font-size: 28px;
    position: relative;
    top: -3px;
  }
  .react-datepicker__day--in-selecting-range {
    color: ${defaultTheme.colors.black};
    background-color: ${defaultTheme.colors.secondary[100]};
    box-shadow: none;
  }
  .react-datepicker__day--in-range {
    color: ${defaultTheme.colors.black};
    background-color: ${defaultTheme.colors.secondary[100]};
    box-shadow: none;
  }
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    color: ${defaultTheme.colors.white};
    background-color: ${defaultTheme.colors.secondary[500]};
    box-shadow: none;
  }
  .react-datepicker__triangle::after,
  .react-datepicker__triangle::before {
    left: -12px !important;
  }
  .react-datepicker__header__dropdown {
    display: inline-block;
    font-weight: 600;
    color: ${defaultTheme.colors.gray[900]};
  }
  .react-datepicker__year-read-view--down-arrow {
    top: 2px;
  }
  .react-datepicker__year-read-view {
    visibility: visible !important;
  }
  .react-datepicker__current-month {
    display: none;
  }
  .react-datepicker__navigation-icon:before {
    top: 10px;
  }
`;
