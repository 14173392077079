import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";

import { useFloorPlanSignedUrlLazyQuery } from "../graphql/graphql";

const useFloorPlanSignedUrlData = (planId: string) => {
  const [signedUrlData, setSignedUrlData] =
    useState<{ id: string; name: string; signedUrl: string }>();
  const client = useApolloClient();
  const [loadSignedUrl] = useFloorPlanSignedUrlLazyQuery({
    variables: { id: planId },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const checkInCache = async () => {
      const floorPlan = client.readFragment({
        id: `FloorPlan:${planId}`,
        fragment: gql`
          fragment FloorPlanPathFragment on FloorPlan {
            id
            name
            path
          }
        `,
      });
      const filesCache = await caches.open("files");
      const cacheKeys = await filesCache.keys();
      if (cacheKeys.some((request) => request.url.includes(floorPlan.path))) {
        setSignedUrlData({
          id: floorPlan.id,
          name: floorPlan.name,
          signedUrl: `https://${process.env.REACT_APP_FILES_BUCKET}.s3.us-west-2.amazonaws.com/${floorPlan.path}`,
        });
      } else {
        const { data } = await loadSignedUrl();
        if (data) {
          setSignedUrlData({
            id: data.floorPlan.id,
            name: data.floorPlan.name,
            signedUrl: data.floorPlan.signedUrl,
          });
        }
      }
    };

    checkInCache();
  }, [client, loadSignedUrl, planId]);

  return signedUrlData;
};

export default useFloorPlanSignedUrlData;

gql`
  query FloorPlanSignedUrl($id: UUID!) {
    floorPlan(id: $id) {
      id
      name
      signedUrl
    }
  }
`;
