import {
  EuiFlexGroup,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiIcon,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { ReactNode } from "react";

interface FlyoutProps {
  width?: string;
  visible?: boolean;
  visibleChange?: (visible: boolean) => void;
  headerTitle?: string;
  footerContent?: ReactNode;
  classBody?: string;
  classHeader?: string;
  classFooter?: string;
}

const Flyout: React.FC<FlyoutProps> = ({
  width = "80%",
  visible = false,
  visibleChange,
  headerTitle,
  footerContent,
  children,
  classBody = "",
  classFooter = "",
  classHeader = "",
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();

  const handleVisibleChange = (isVisible: boolean) => {
    visibleChange && visibleChange(isVisible);
  };

  return (
    <>
      {visible ? (
        <EuiFlyout
          ownFocus
          hideCloseButton={true}
          size={width}
          onClose={() => handleVisibleChange(false)}
        >
          <EuiFlyoutHeader
            className={classHeader}
            hasBorder
            style={{
              padding: `${euiTheme.size.xxl} 0 ${euiTheme.size.m}`,
              margin: `0 ${euiTheme.size.xxl} -${euiTheme.size.xs}`,
            }}
          >
            <EuiFlexGroup
              direction="row"
              justifyContent="spaceBetween"
              alignItems="center"
              wrap={false}
            >
              <EuiText
                style={{
                  fontSize: euiTheme.sizes["24px"],
                  lineHeight: euiTheme.sizes["29px"],
                  fontWeight: 500,
                }}
              >
                {headerTitle}
              </EuiText>
              <EuiIcon
                type="cross"
                color={euiTheme.colors.text}
                onClick={() => handleVisibleChange(false)}
                style={{
                  width: euiTheme.sizes["22px"],
                  height: euiTheme.sizes["22px"],
                  cursor: "pointer",
                }}
              ></EuiIcon>
            </EuiFlexGroup>
          </EuiFlyoutHeader>
          <EuiFlyoutBody
            className={classBody}
            style={{
              padding: "0",
              margin: `${euiTheme.size.l} ${euiTheme.size.xxl}`,
              backgroundColor: "inherit",
            }}
          >
            {children}
          </EuiFlyoutBody>
          <EuiFlyoutFooter
            className={classFooter}
            style={{
              padding: `${euiTheme.size.l} ${euiTheme.size.xxl}`,
              margin: `0 ${euiTheme.size.xs}`,
              backgroundColor: "inherit",
            }}
          >
            {footerContent}
          </EuiFlyoutFooter>
        </EuiFlyout>
      ) : null}
    </>
  );
};

export default Flyout;
