import { Box } from "@chakra-ui/react";
import React from "react";
import Confetti from "react-confetti";

interface ConfettiProps {}

const StyledConfetti: React.FC<ConfettiProps> = ({ children }) => {
  return (
    <Box
      position="relative"
      overflow="hidden"
      maxWidth={{ md: "85%" }}
      margin="auto"
    >
      <Box
        position="absolute"
        width="100%"
        height="100%"
        marginTop="-16"
        marginLeft="-16"
      >
        <Confetti
          width={800}
          height={800}
          recycle={false}
          numberOfPieces={750}
          tweenDuration={25000}
          colors={["#FD3D3D", "#459BFE", "#2EC14E", "#FFBD29", "#9B51E0"]}
        />
      </Box>
      {children}
    </Box>
  );
};

export default StyledConfetti;
