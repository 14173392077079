import { useReactiveVar } from "@apollo/client";
import { Flex } from "@chakra-ui/layout";
import { Box, Tooltip } from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import {
  useAssetCategoryConfigQuery,
  useCompanyQuery,
} from "../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../graphql/reactiveVariables";
import useAssetCategories from "../../hooks/useAssetCategories";
import ROLES from "../../roles";
import orderBy from "../../utils/sort";
import Presenter from "./presenter";

interface AssetCategoriesPageProps {}

const AssetCategoriesPage: React.FC<AssetCategoriesPageProps> = ({
  children,
}) => {
  const { data: assetCategoryConfigData } = useAssetCategoryConfigQuery();
  const { data: companyData } = useCompanyQuery();
  const { data } = useAssetCategories(
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
    assetCategoryConfigData?.assetCategoryConfig.showHidden
  );
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  const sortedAssetCategories = React.useMemo(
    () =>
      data && assetCategoryConfigData
        ? orderBy(
            data.assetCategories,
            assetCategoryConfigData.assetCategoryConfig.sortBy,
            assetCategoryConfigData.assetCategoryConfig.sortOrder,
            "assetTypes"
          )
        : undefined,
    [data, assetCategoryConfigData]
  );

  useEffect(() => {
    layoutOptionsVar({
      title: (
        <Flex alignItems="center">
          <Box>Asset Categories &amp; Types</Box>
          <Tooltip
            label="Create asset categories that you want to track and asset types that you want to add to your plans and locations."
            hasArrow
          >
            <Box color="gray.700" marginLeft="2">
              <FontAwesomeIcon icon={faInfoCircle} size="xs" />
            </Box>
          </Tooltip>
        </Flex>
      ),
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Asset Categories &amp; Types</title>
      </Helmet>
      {sortedAssetCategories && assetCategoryConfigData && companyData ? (
        <Presenter
          assetCategoryConfigData={assetCategoryConfigData}
          assetCategories={sortedAssetCategories}
          company={companyData.company}
          canAddAssetCategory={ROLES.assetCategoriesCreate.includes(
            currentCompanyRole.role
          )}
          canEditAssetCategory={ROLES.assetCategoriesEdit.includes(
            currentCompanyRole.role
          )}
          canDeleteAssetCategory={ROLES.assetCategoriesDelete.includes(
            currentCompanyRole.role
          )}
          canAddAssetType={ROLES.assetTypesCreate.includes(
            currentCompanyRole.role
          )}
          canEditAssetType={ROLES.assetTypesEdit.includes(
            currentCompanyRole.role
          )}
          canDeleteAssetType={ROLES.assetTypesDelete.includes(
            currentCompanyRole.role
          )}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default AssetCategoriesPage;
