import { useReactiveVar } from "@apollo/client";
import { Box, Button, Heading, Input, Text, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { ReactNode } from "react";

import FormGroup from "../../../components/elements/formGroup";
import PageSpinner from "../../../components/elements/pageSpinner";
import RadioButtonInput from "../../../components/elements/radioButton";
import { TEAM_CREATE_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyUserRole,
  CompanyUsersDocument,
  useCompanyQuery,
  useCompanyUserCreateMutation,
} from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { emailSchema, roleSchema, yupObject } from "../../../utils/validation";

interface CompanyTeamsFormProps {
  afterCreate: (id: string) => void;
  onClose: () => void;
  defaultEmailValue?: string;
}

const CompanyTeamsForm: React.FC<CompanyTeamsFormProps> = ({
  afterCreate,
  onClose,
  defaultEmailValue,
}) => {
  const { data: companyData } = useCompanyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const toast = useToast();
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const [companyUserCreateMutation, { loading }] = useCompanyUserCreateMutation(
    {
      refetchQueries: [{ query: CompanyUsersDocument }],
      awaitRefetchQueries: true,
    }
  );

  const onSubmit = async (
    data: CompanyUserCreateFormData,
    { setFieldError }: FormikHelpers<CompanyUserCreateFormData>
  ) => {
    try {
      const { data: serverData, errors } = await companyUserCreateMutation({
        variables: { ...data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: `${TEAM_CREATE_MESSAGE} ${data.email}!`,
          status: "success",
          isClosable: true,
          position: "top",
        });
        afterCreate(serverData.companyUserCreate.id);
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  return companyData ? (
    <Formik
      initialValues={{
        email: defaultEmailValue || "",
        role: CompanyUserRole.User,
      }}
      validationSchema={CompanyUserCreateValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ getFieldProps }) => (
        <Form id="company_user_create" noValidate>
          <Text color="gray.800" textAlign="center">
            Invite people to join your account
          </Text>
          <FormGroup label="Email" name="email">
            <Input
              autoFocus
              autoComplete="email"
              type="email"
              {...getFieldProps("email")}
            />
          </FormGroup>
          <RadioButtonInput
            options={getCompanyRoleOptions(currentCompanyRole.role)}
            name="role"
            label="Role"
          />

          <Button
            width="full"
            type="submit"
            isLoading={loading}
            form="company_user_create"
            marginTop="10"
          >
            Send Invite
          </Button>
        </Form>
      )}
    </Formik>
  ) : (
    <PageSpinner />
  );
};

export default CompanyTeamsForm;

gql`
  mutation CompanyUserCreate($email: String!, $role: CompanyUserRole!) {
    companyUserCreate(data: { email: $email, role: $role }) {
      ...CompanyUserWithUserFragment
    }
  }
`;

export const CompanyUserCreateValidationSchema = yupObject().shape({
  email: emailSchema,
  role: roleSchema,
});

export type CompanyUserCreateFormData = {
  email: string;
  role: CompanyUserRole;
};

const userRoleOption = {
  label: (
    <Box cursor="pointer">
      <Heading size="md" fontWeight="semibold">
        User
      </Heading>
      <Text fontSize="sm">
        Can view, add and edit assets, plans and locations.
      </Text>
    </Box>
  ),
  value: CompanyUserRole.User,
};

// const managerRoleOption = {
//   label: (
//     <Box cursor="pointer">
//       <Heading size="md" fontWeight="semibold">
//         Manager
//       </Heading>
//       <Text fontSize="sm">
//         Can view, add and edit assets, plans, locations and categories, and can
//         add new users and locations.
//       </Text>
//     </Box>
//   ),
//   value: CompanyUserRole.Manager,
// };

const viewRoleOption = {
  label: (
    <Box cursor="pointer">
      <Heading size="md" fontWeight="semibold">
        View Only
      </Heading>
      <Text fontSize="sm">
        Can view assets, plans and locations, but cannot add, edit or delete
        anything.
      </Text>
    </Box>
  ),
  value: CompanyUserRole.ViewOnly,
};

const adminRoleOption = {
  label: (
    <Box cursor="pointer">
      <Heading size="md" fontWeight="semibold">
        Admin
      </Heading>
      <Text fontSize="sm">
        Has full access to view and change everything, but cannot change the
        account owner.
      </Text>
    </Box>
  ),
  value: CompanyUserRole.Admin,
};

export const getCompanyRoleOptions: (
  role: string
) => { label: ReactNode; value: string }[] = (role) => {
  if (role === CompanyUserRole.User) return [];

  return role !== CompanyUserRole.Manager
    ? [userRoleOption, adminRoleOption, viewRoleOption]
    : [userRoleOption, viewRoleOption];
};
