import { Heading, HeadingProps } from "@chakra-ui/react";
import React from "react";

interface FormSectionTitleProps extends HeadingProps {}

const FormSectionTitle: React.FC<FormSectionTitleProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <Heading
      fontSize="lg"
      fontWeight="600"
      marginTop="4"
      marginBottom="-1"
      {...otherProps}
    >
      {children}
    </Heading>
  );
};

export default FormSectionTitle;
