import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import React, { FormEvent, useState } from "react";
import { usePlaidLink } from "react-plaid-link";

import defaultTheme from "../../../chakraTheme";
import { GENERIC_ERROR_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyDocument,
  StripeBankAccountsDocument,
  StripeCustomerDocument,
  StripePaymentMethodsDocument,
  useStripeBankAccountCreateMutation,
  useStripeBankAccountMakePrimaryMutation,
  useStripePaymentMethodCreateMutation,
  useStripePaymentMethodMakePrimaryMutation,
  useUpdateAppSettingsMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";

interface AddPaymentMethodProps {
  paymentMethodAdded: () => void;
  creditCardAdded?: () => void;
  ctaCopy?: string;
  attemptToChargeOpenInvoice?: boolean;
  autoFocus?: boolean;
}

const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({
  creditCardAdded,
  paymentMethodAdded,
  ctaCopy,
  attemptToChargeOpenInvoice = false,
  autoFocus = false,
}) => {
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [stripePaymentMethodCreateMutation] =
    useStripePaymentMethodCreateMutation({
      refetchQueries: [{ query: StripePaymentMethodsDocument }],
      awaitRefetchQueries: true,
    });
  const [stripePaymentMethodMakePrimaryMutation] =
    useStripePaymentMethodMakePrimaryMutation({
      refetchQueries: [
        { query: StripeCustomerDocument },
        { query: CompanyDocument },
      ],
      awaitRefetchQueries: true,
    });
  const [stripeBankAccountCreateMutation] = useStripeBankAccountCreateMutation({
    refetchQueries: [{ query: StripeBankAccountsDocument }],
    awaitRefetchQueries: true,
  });
  const [stripeBankAccountMakePrimaryMutation] =
    useStripeBankAccountMakePrimaryMutation({
      refetchQueries: [
        { query: StripeCustomerDocument },
        { query: CompanyDocument },
      ],
      awaitRefetchQueries: true,
    });
  const [updateAppSettingsQueryMutation] = useUpdateAppSettingsMutation();

  const setPlaidStatus = React.useCallback(
    (plaidOpen: boolean) => {
      updateAppSettingsQueryMutation({
        variables: {
          plaidOpen,
        },
      });
    },
    [updateAppSettingsQueryMutation]
  );

  const toast = useToast();

  const [stripeError, setStripeError] = useState<StripeError | undefined>();

  const { open, ready } = usePlaidLink({
    clientName: "CriticalAsset",
    env: process.env.REACT_APP_PLAID_ENV as string,
    product: ["auth"],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY as string,
    onSuccess: React.useCallback(
      async (plaidLinkPublicToken, { accounts }) => {
        const plaidAccountId = accounts[0].id;
        try {
          setLoading(true);
          const { data: stripeBankAccount } =
            await stripeBankAccountCreateMutation({
              variables: {
                plaidAccountId,
                plaidLinkPublicToken,
                attemptToChargeOpenInvoice,
              },
            });
          if (
            !stripeBankAccount ||
            !stripeBankAccount.stripeBankAccountCreate.id
          ) {
            throw new Error(GENERIC_ERROR_MESSAGE);
          }
          await stripeBankAccountMakePrimaryMutation({
            variables: {
              stripeBankAccountId: stripeBankAccount.stripeBankAccountCreate.id,
            },
          });
          paymentMethodAdded();
        } catch (error) {
          toast({
            description: setGenericMessage(error),
            status: "error",
            position: "top",
            isClosable: true,
          });
        } finally {
          setLoading(false);
          setPlaidStatus(false);
        }
      },
      [
        setPlaidStatus,
        stripeBankAccountCreateMutation,
        stripeBankAccountMakePrimaryMutation,
        paymentMethodAdded,
        toast,
        attemptToChargeOpenInvoice,
      ]
    ),
    onExit: () => {
      setPlaidStatus(false);
    },
  });

  const handleStripeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    try {
      setLoading(true);
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return;
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (error || !paymentMethod) {
        setStripeError(error);
      } else {
        const { data: stripePaymentMethod } =
          await stripePaymentMethodCreateMutation({
            variables: {
              stripePaymentMethodId: paymentMethod.id,
              attemptToChargeOpenInvoice,
            },
          });
        if (
          !stripePaymentMethod ||
          !stripePaymentMethod.stripePaymentMethodCreate.id
        ) {
          throw new Error(GENERIC_ERROR_MESSAGE);
        }
        await stripePaymentMethodMakePrimaryMutation({
          variables: {
            stripePaymentMethodId:
              stripePaymentMethod.stripePaymentMethodCreate.id,
          },
        });
        paymentMethodAdded();
        if (creditCardAdded) creditCardAdded();
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box marginY="8" marginX="auto" maxWidth="xl">
      <form onSubmit={handleStripeSubmit} noValidate>
        <FormControl
          isInvalid={!!stripeError?.message}
          aria-invalid={!!stripeError?.message}
        >
          <CardElement
            options={{
              style: {
                base: {
                  lineHeight: "50px",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "20px",
                  letterSpacing: "0.5px",
                  color: defaultTheme.colors.black,
                  "::placeholder": {
                    color: defaultTheme.colors.gray[500],
                  },
                },
                invalid: {
                  color: defaultTheme.colors.red[500],
                },
              },
            }}
            onChange={() => setStripeError(undefined)}
            onReady={(element) => autoFocus && element.focus()}
          />
          {stripeError?.message && (
            <FormErrorMessage>
              <Text as="span" marginRight="1">
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Text>
              <Text role="alert">{stripeError.message}</Text>
            </FormErrorMessage>
          )}
          <Box marginTop="8">
            <Button
              type="submit"
              disabled={!stripe}
              isLoading={loading}
              width="full"
            >
              {ctaCopy}
            </Button>
          </Box>
        </FormControl>
      </form>
      <Box marginTop="5" textAlign="center">
        <Button
          variant="link"
          colorScheme="secondary"
          whiteSpace="normal"
          onClick={() => {
            setPlaidStatus(true);
            open();
          }}
          disabled={!ready || loading}
        >
          Or connect to your bank account and pay with ACH
        </Button>
      </Box>
    </Box>
  );
};

AddPaymentMethod.defaultProps = {
  ctaCopy: "Save Card",
};

export default AddPaymentMethod;
