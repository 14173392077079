import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import { cloneDeep, isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import {
  useWOGetOrderTypesQuery,
  useWOGetServiceCategoriesQuery,
  useWOGetWorkOrdersQuery,
} from "../../../../../graphql/queries/workOrders";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderEditSectionFooter from "../../components/sectionFooter";
import WorkOrderEditPlanPresenter from "./presenter";

interface WorkOrderEditPlanProps {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

export type SectionDataPlan = Pick<
  WorkOrder,
  | "parentOrderId"
  | "orderPriority"
  | "businessSeverity"
  | "serviceCategoryId"
  | "orderTypeId"
>;
const WorkOrderEditPlanInfo: React.FC<WorkOrderEditPlanProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const [initialSectionData, setInitialSectionData] = useState<SectionDataPlan>(
    {}
  );
  const [sectionData, setSectionData] = useState<SectionDataPlan>({});
  const [isSectionDataChanged, setSectionDataChanged] =
    useState<boolean>(false);
  const [isSectionDataValid, setSectionDataValid] = useState<boolean>(true);
  const [orderTypesOption, setOrderTypesOption] = React.useState<
    EuiSuperSelectOption<string>[]
  >([]);
  const [workOrdersOption, setWorkOrdersOption] = React.useState<
    EuiComboBoxOptionOption<string>[]
  >([]);
  const [serviceCategoriesOption, setServiceCategoriesOption] = React.useState<
    EuiSuperSelectOption<string>[]
  >([]);

  const { data: workOrdersData } = useWOGetWorkOrdersQuery({
    variables: {
      companyId: workOrder?.companyId,
    },
    fetchPolicy: "cache-and-network",
    skip: !workOrder?.companyId,
  });
  const { data: orderTypes } = useWOGetOrderTypesQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: serviceCategories } = useWOGetServiceCategoriesQuery({
    fetchPolicy: "cache-and-network",
  });

  const orderTypeRevisitId = useMemo(
    () =>
      orderTypesOption?.find(
        (item) => (item.inputDisplay as string)?.toLowerCase() === "revisit"
      )?.value,
    [orderTypesOption]
  );

  useEffect(() => {
    let newOrderTypesOptions: EuiSuperSelectOption<string>[] = [];
    if (orderTypes?.orderTypes?.length) {
      newOrderTypesOptions = orderTypes.orderTypes.map((orderType) => {
        const disabled =
          !workOrdersOption?.length &&
          orderType.name?.toLowerCase() === "revisit";
        const element = disabled ? (
          <>
            <strong
              style={{ display: "block" }}
              title={
                "Need to create at least one work order to activate this order type"
              }
            >
              {orderType.name}
            </strong>
          </>
        ) : null;
        return {
          inputDisplay: orderType.name,
          value: orderType.id,
          disabled,
          dropdownDisplay: element,
        };
      });
    }
    setOrderTypesOption(newOrderTypesOptions);
  }, [orderTypes, workOrdersOption]);

  useEffect(() => {
    let newServiceCategories: EuiSuperSelectOption<string>[] = [];
    if (serviceCategories?.serviceCategories?.length) {
      newServiceCategories = serviceCategories.serviceCategories.map(
        (serviceCategory) => {
          return {
            inputDisplay: serviceCategory.name,
            value: serviceCategory.id,
          };
        }
      );
    }
    setServiceCategoriesOption(newServiceCategories);
  }, [serviceCategories]);

  useEffect(() => {
    let workOrders: EuiComboBoxOptionOption<string>[] = [];
    if (workOrdersData?.workOrders?.workOrders.length) {
      workOrders = workOrdersData.workOrders.workOrders
        .filter((order) => order.id !== workOrder.id)
        .map((workOrder) => {
          return {
            label: `${workOrder.key} | ${workOrder.name}` || "",
            value: workOrder.id,
          };
        });
    }
    setWorkOrdersOption(workOrders);
  }, [workOrdersData]);

  useEffect(() => {
    const {
      orderTypeId,
      serviceCategoryId,
      businessSeverity,
      orderPriority,
      parentOrderId,
    } = workOrder || {};
    setSectionData(
      cloneDeep({
        orderTypeId,
        serviceCategoryId,
        businessSeverity,
        orderPriority,
        parentOrderId,
      })
    );
    setInitialSectionData({
      orderTypeId,
      serviceCategoryId,
      businessSeverity,
      orderPriority,
      parentOrderId,
    });
  }, [workOrder]);

  const onDataChanged = (data: Partial<SectionDataPlan>) => {
    const updatedSectionData = { ...sectionData, ...data };
    setSectionData(updatedSectionData);
  };

  useEffect(() => {
    const isValid =
      sectionData.orderTypeId &&
      !!sectionData?.serviceCategoryId &&
      !!sectionData?.businessSeverity &&
      (orderTypeRevisitId === sectionData.orderTypeId
        ? sectionData?.parentOrderId?.length
        : true);
    setSectionDataValid(isValid);
    const changed = !isEqual(initialSectionData, sectionData);
    setSectionDataChanged(changed);
  }, [sectionData]);

  const cancel = () => {
    setSectionData(cloneDeep(initialSectionData));
  };
  const save = () => {
    onWorkOrderChange(sectionData);
  };

  useEffect(() => {
    changeIsEdit && changeIsEdit(isSectionDataChanged);
  }, [isSectionDataChanged, changeIsEdit]);

  return (
    <>
      <WorkOrderEditPlanPresenter
        sectionData={sectionData}
        onWorkOrderChange={onDataChanged}
        workOrdersOptions={workOrdersOption}
        orderTypesOption={orderTypesOption}
        serviceCategoriesOption={serviceCategoriesOption}
      >
        <WorkOrderEditSectionFooter
          save={save}
          cancel={cancel}
          disabled={!isSectionDataValid}
          visible={isSectionDataChanged}
        />
      </WorkOrderEditPlanPresenter>
    </>
  );
};

export default WorkOrderEditPlanInfo;
