import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faChevronDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { difference, flatten, uniq } from "lodash";
import { useEffect } from "react";
import { FC, useMemo, useState } from "react";
import { Helmet } from "react-helmet";

import AssetIcon from "../../components/elements/assetIcons";
import TooltipName from "../../components/elements/tooltipName";
import {
  GENERIC_ERROR_MESSAGE,
  GENERIC_SAVED_MESSAGE,
} from "../../constants/lang/en";
import {
  AssetCategoryWithTypesFragmentFragment,
  SortBy,
  SortOrder,
  useAssetWithTypeQuery,
  useCompanyUpdateHiddenCategoryIdsMutation,
} from "../../graphql/graphql";
import useAssetCategories from "../../hooks/useAssetCategories";
import { setGenericMessage } from "../../utils/serverErrors";
import orderBy from "../../utils/sort";

interface AssetCategoryAndTypeSetupProps {
  isOpen: boolean;
  onClose: () => void;
}

const AssetCategoryAndTypeSetup: FC<AssetCategoryAndTypeSetupProps> = ({
  isOpen,
  onClose,
}) => {
  const { data } = useAssetCategories(undefined, true);
  const { data: assetsData } = useAssetWithTypeQuery();
  const toast = useToast();
  const [assetTypeVisibilityToggleMutation, { loading }] =
    useCompanyUpdateHiddenCategoryIdsMutation();
  const [staticVisibleCategoryIds, setStaticVisibleCategoryIds] = useState<
    string[]
  >([]);
  const [staticVisibleTypeIds, setStaticVisibleTypeIds] = useState<{
    [key: string]: string[];
  }>({});
  const [visibleCategoryIds, setVisibleCategoryIds] = useState<string[]>([]);
  const [visibleTypeIds, setVisibleTypeIds] = useState<{
    [key: string]: string[];
  }>({});
  const { disabledAssetCategoryIds, disabledAssetTypeIds } = useMemo(() => {
    if (!assetsData)
      return { disabledAssetCategoryIds: [], disabledAssetTypeIds: [] };
    const disabledAssetCategoryIds: string[] = [];
    const disabledAssetTypeIds: string[] = [];

    for (let i = 0; i < assetsData.assets.length; i++) {
      const asset = assetsData.assets[i];
      disabledAssetCategoryIds.push(asset.assetType.assetCategoryId);
      disabledAssetTypeIds.push(asset.assetType.id);
    }
    return {
      disabledAssetCategoryIds: uniq(disabledAssetCategoryIds),
      disabledAssetTypeIds: uniq(disabledAssetTypeIds),
    };
  }, [assetsData]);

  const sortedAssetCategories = useMemo(
    () =>
      data
        ? orderBy(
            data.assetCategories,
            SortBy.Name,
            SortOrder.Asc,
            "assetTypes"
          )
        : [],
    [data]
  );

  useEffect(() => {
    if (data) {
      let assetCategoriesId: string[] = [];
      let newVisibleAssetTypes: { [key: string]: string[] } = {};

      const categoriesLength = data.assetCategories.length;
      for (let i = 0; i < categoriesLength; i++) {
        const ac = data.assetCategories[i];
        assetCategoriesId.push(ac.id);

        newVisibleAssetTypes[ac.id] = [];
        const assetTypes = ac.assetTypes;
        if (assetTypes) {
          const assetTypesLength = assetTypes.length;
          for (let j = 0; j < assetTypesLength; j++) {
            newVisibleAssetTypes[ac.id].push(assetTypes[j].id);
          }
        }
      }

      setStaticVisibleCategoryIds(assetCategoriesId);
      setVisibleCategoryIds(assetCategoriesId);
      setStaticVisibleTypeIds(newVisibleAssetTypes);
      setVisibleTypeIds(newVisibleAssetTypes);
    }
  }, [
    data,
    setVisibleCategoryIds,
    setStaticVisibleCategoryIds,
    setVisibleTypeIds,
    setStaticVisibleTypeIds,
  ]);

  const onSubmit = async () => {
    try {
      const { data: serverData, errors } =
        await assetTypeVisibilityToggleMutation({
          variables: {
            hiddenCategoryIds: difference(
              staticVisibleCategoryIds,
              visibleCategoryIds
            ),
            hiddenTypeIds: difference(
              flatten(Object.values(staticVisibleTypeIds)),
              flatten(Object.values(visibleTypeIds))
            ),
          },
        });
      if (errors) {
        toast({
          description: GENERIC_ERROR_MESSAGE,
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else if (serverData) {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <Helmet>
        <title>Asset categories and types</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Asset categories and types</DrawerHeader>
          <DrawerBody>
            <Flex alignItems="center" marginBottom="6">
              <Box>
                Select the asset categories and types you want to manage
              </Box>
              <Tooltip
                label="You can make changes at any time and you can create your own categories and types after you've completed the company setup"
                hasArrow
              >
                <Box color="gray.700" marginLeft="2">
                  <FontAwesomeIcon icon={faInfoCircle} size="xs" />
                </Box>
              </Tooltip>
            </Flex>
            <CheckboxGroup
              value={visibleCategoryIds}
              onChange={(values: any) => {
                const newlyAddedValues = difference(values, visibleCategoryIds);
                const newlyRemovedValues = difference(
                  visibleCategoryIds,
                  values
                );
                let newVisibleTypeIds: { [key: string]: string[] } = {};
                newlyRemovedValues.forEach((id) => {
                  newVisibleTypeIds[id] = [];
                });
                newlyAddedValues.forEach((id) => {
                  newVisibleTypeIds[id] = staticVisibleTypeIds[id];
                });

                setVisibleCategoryIds(values);
                setVisibleTypeIds((vat) => ({ ...vat, ...newVisibleTypeIds }));
              }}
            >
              {sortedAssetCategories.map((ac) => (
                <Tooltip
                  key={ac.id}
                  label={
                    disabledAssetCategoryIds.includes(ac.id)
                      ? "You can't hide this asset category because it has existing assets. To hide this category, you must first remove all of this category's associated assets from the assets page."
                      : undefined
                  }
                  hasArrow
                >
                  <Box>
                    <AssetCategoryAndTypeInput
                      assetCategory={ac}
                      visibleTypeIds={visibleTypeIds}
                      setVisibleTypeIds={setVisibleTypeIds}
                      setVisibleCategoryIds={setVisibleCategoryIds}
                      disabledAssetCategoryIds={disabledAssetCategoryIds}
                      disabledAssetTypeIds={disabledAssetTypeIds}
                    />
                  </Box>
                </Tooltip>
              ))}
            </CheckboxGroup>
          </DrawerBody>
          <DrawerFooter>
            <Button
              width="full"
              type="submit"
              isLoading={loading}
              onClick={onSubmit}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetCategoryAndTypeSetup;

interface AssetCategoryAndTypeInputProps {
  assetCategory: AssetCategoryWithTypesFragmentFragment;
  visibleTypeIds: any;
  setVisibleTypeIds: (ids: any) => any;
  setVisibleCategoryIds: (ids: any) => any;
  disabledAssetCategoryIds: string[];
  disabledAssetTypeIds: string[];
}

const AssetCategoryAndTypeInput: FC<AssetCategoryAndTypeInputProps> = ({
  assetCategory,
  visibleTypeIds,
  setVisibleTypeIds,
  setVisibleCategoryIds,
  disabledAssetCategoryIds,
  disabledAssetTypeIds,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      paddingY="4"
      position="relative"
      borderBottom="1px solid"
      borderColor="gray.100"
      sx={
        isOpen
          ? {
              "&:after": assetCategory.assetTypes?.length
                ? {
                    content: '""',
                    position: "absolute",
                    top: "52px",
                    backgroundColor: "secondary.100",
                    width: "1px",
                    height: `${assetCategory.assetTypes.length * 44 - 3}px`,
                    left: "10px",
                  }
                : {},
            }
          : undefined
      }
    >
      <Checkbox
        marginRight="4"
        value={assetCategory.id}
        size="lg"
        isDisabled={disabledAssetCategoryIds.includes(assetCategory.id)}
      >
        <Flex alignItems="center" width="full" flexGrow={1}>
          <Box width="20px" flex="none">
            {assetCategory.assetTypes?.length ? (
              <button
                onClick={onToggle}
                aria-label={`${isOpen ? "Close" : "Open"} asset types`}
              >
                <Box transform={isOpen ? "rotate(180deg)" : ""}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Box>
              </button>
            ) : null}
          </Box>
          <AssetIcon
            iconName={assetCategory.iconName}
            iconColor={assetCategory.iconColor}
            iconType={assetCategory.iconType}
            marginRight="2"
            iconSize="sm"
          />
          <Box width="full" minWidth="0">
            <TooltipName
              fontSize="sm"
              maxWidth="full"
              name={assetCategory.name}
            />
          </Box>
        </Flex>
      </Checkbox>
      {!!assetCategory.assetTypes?.length &&
        assetCategory.assetTypes.map((at) => (
          <Box
            paddingLeft="12"
            marginTop="3"
            key={at.id}
            display={isOpen ? "block" : "none"}
          >
            <CheckboxGroup
              value={visibleTypeIds[assetCategory.id]}
              onChange={(values: any) => {
                setVisibleTypeIds((vat: any) => ({
                  ...vat,
                  [assetCategory.id]: values,
                }));
                setVisibleCategoryIds((vct: any) =>
                  uniq([...vct, assetCategory.id])
                );
              }}
            >
              <Box
                position="relative"
                sx={{
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    borderTop: "1px solid",
                    borderColor: "secondary.100",
                    width: "38px",
                    left: "-38px",
                  },
                }}
              >
                <Tooltip
                  key={at.id}
                  label={
                    disabledAssetTypeIds.includes(at.id)
                      ? "You can't hide this asset type because it has existing assets. To hide this type, you must first remove all of this type's associated assets from the assets page."
                      : undefined
                  }
                  hasArrow
                >
                  <Box>
                    <Checkbox
                      marginRight="4"
                      value={at.id}
                      size="lg"
                      isDisabled={disabledAssetTypeIds.includes(at.id)}
                    >
                      <Flex alignItems="center" flexGrow={1} width="full">
                        <AssetIcon
                          iconName={at.iconName}
                          iconColor={at.misc.resolvedIconColor}
                          iconType={at.iconType}
                          marginRight="2"
                          iconSize="xs"
                        />
                        <Box width="full" minWidth="0">
                          <TooltipName
                            fontSize="sm"
                            maxWidth="full"
                            name={at.name}
                          />
                        </Box>
                      </Flex>
                    </Checkbox>
                  </Box>
                </Tooltip>
              </Box>
            </CheckboxGroup>
          </Box>
        ))}
    </Box>
  );
};
