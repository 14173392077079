import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import {
  SortBy,
  SortOrder,
  useAssetConfigQuery,
  useAssetIndexQuery,
  useUpdateAssetConfigMutation,
} from "../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../graphql/reactiveVariables";
import useAssetCategories from "../../hooks/useAssetCategories";
import orderBy from "../../utils/sort";
import Presenter from "./presenter";

interface AssetsPageProps {}

const AssetsPage: React.FC<AssetsPageProps> = ({ children }) => {
  const { data: assetCategoriesData } = useAssetCategories();
  const [searchParams] = useSearchParams();
  const { data: assetConfigData } = useAssetConfigQuery();
  const assetConfig = assetConfigData?.assetConfig;
  const sortBy = assetConfig?.sortBy || SortBy.Name;
  const sortOrder = assetConfig?.sortOrder || SortOrder.Asc;
  const filter = assetConfig?.filter || [];
  const assetCategoryId = assetConfig?.filterCategoryId;
  const assetCategory = React.useMemo(
    () =>
      assetCategoriesData
        ? assetCategoriesData.assetCategories.find(
            (ac) => ac.id === assetCategoryId
          )
        : null,
    [assetCategoriesData, assetCategoryId]
  );
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const [updateAssetConfigMutation] = useUpdateAssetConfigMutation();
  const { data } = useAssetIndexQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const setAssetCategoryId = React.useCallback(
    (filterCategoryId: string) => {
      updateAssetConfigMutation({
        variables: { filterCategoryId },
      });
    },
    [updateAssetConfigMutation]
  );

  const setSort = React.useCallback(
    (value) => {
      const [sortBy, sortOrder] = value.split("-");
      updateAssetConfigMutation({
        variables: { sortBy, sortOrder },
      });
    },
    [updateAssetConfigMutation]
  );

  const setFilter = React.useCallback(
    (filter) => {
      updateAssetConfigMutation({ variables: { filter } });
    },
    [updateAssetConfigMutation]
  );

  const sortedAssetCategories = React.useMemo(
    () =>
      assetCategoriesData
        ? orderBy(
            assetCategoriesData.assetCategories,
            SortBy.Name,
            SortOrder.Asc
          )
        : null,
    [assetCategoriesData]
  );

  const filteredAndSortedAssets = React.useMemo(() => {
    if (!data || !assetConfig) return [];
    const assetConfigFilter =
      assetConfig.filter && assetConfig.filter.length > 0
        ? assetConfig.filter
        : undefined;
    const assetCategoryId = assetCategory?.id;
    let filteredAssets = data.assets;
    if (assetConfigFilter || assetCategoryId) {
      filteredAssets = filteredAssets.filter((asset) => {
        if (assetConfigFilter) {
          if (!assetConfigFilter.includes(asset.maintenanceStatus))
            return false;
        }
        if (assetCategoryId) {
          if (asset.assetType.assetCategoryId !== assetCategoryId) return false;
        }
        return true;
      });
    }
    return orderBy(filteredAssets, assetConfig.sortBy, assetConfig.sortOrder);
  }, [data, assetConfig, assetCategory]);

  useEffect(() => {
    if (searchParams.has("filter")) setFilter(searchParams.get("filter"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    layoutOptionsVar({
      title: "Assets",
      styleProps: { paddingX: "0" },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Assets</title>
      </Helmet>
      {sortedAssetCategories && data ? (
        <Presenter
          setSort={setSort}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filter={filter}
          setFilter={setFilter}
          currentCompanyRole={currentCompanyRole}
          assets={filteredAndSortedAssets}
          unfilteredAssetsPresent={data.assets.length > 0}
          assetCategories={sortedAssetCategories}
          assetCategory={assetCategory || undefined}
          setAssetCategoryId={setAssetCategoryId}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default AssetsPage;

gql`
  query Assets {
    assets {
      ...AssetWithRelationsFragment
    }
  }

  fragment AssetIndexFragment on Asset {
    id
    name
    maintenanceStatus
    createdAt

    floorPlanAssets {
      id
      floorPlan {
        id
        locationId
      }
    }

    assetType {
      id
      name
      iconName
      iconColor
      iconType
      assetCategoryId
      misc {
        resolvedName
        resolvedIconColor
      }
    }
  }

  query AssetIndex {
    assets {
      ...AssetIndexFragment
    }
  }
`;
