import React from "react";

import Billing from "../../../billing/presenter";

interface BillingPageProps {}

const NativeBillingPage: React.FC<BillingPageProps> = () => {
  return <Billing />;
};

export default NativeBillingPage;
