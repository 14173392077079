import { gql } from "@apollo/client";

export const WOCommentCreateDocument = gql`
  mutation CommentCreate($data: CommentWorkOrderInput!) {
    commentCreate(data: $data) {
      id
      text
      createdAt
      user {
        fullName
      }
    }
  }
`;

export const WOCommentWorkOrderDocument = gql`
  query workOrderComments($workOrderId: UUID!) {
    workOrderComments(workOrderId: $workOrderId) {
      id
      text
      createdAt
      user {
        fullName
      }
    }
  }
`;
