import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import { FC, useEffect } from "react";
import isEqual from "react-fast-compare";

import {
  CompanyUserRole,
  MaintenanceStatusType,
  useCompanyByIdQuery,
  useCompanyStartUpQuery,
  useCurrentCompanyQuery,
  usePlansQuery,
  useSessionQuery,
  useUserWithCompaniesQuery,
} from "../../../graphql/graphql";
import {
  assetCategoryIdsWithAssetsVar,
  assetTypeIdsWithAssetsVar,
  assetWorstStatusVar,
  currentCompanyPlanVar,
  currentCompanyRoleVar,
  currentCompanyVar,
  currentUserWithCompaniesVar,
  mainCompanyVar,
} from "../../../graphql/reactiveVariables";
import { restrictedPages } from "../../../router";
import PageSpinner from "../pageSpinner";

const StartUpChecks: FC = ({ children }) => {
  const { data: sessionData } = useSessionQuery();
  const { data: companyData } = useCurrentCompanyQuery();
  return !restrictedPages.includes(window.location.pathname) &&
    sessionData?.session.isLoggedIn &&
    companyData?.currentCompany.id ? (
    <StartUpChecksPresenter>{children}</StartUpChecksPresenter>
  ) : (
    <>{children}</>
  );
};

export default StartUpChecks;

const pollInterval = undefined; // 1800000;
const queryOptions: any = {
  fetchPolicy: "network-only",
  nextFetchPolicy: "cache-first",
  pollInterval,
};

const StartUpChecksPresenter: FC = ({ children }) => {
  const { data: userCompaniesData, loading: companyUsersLoading } =
    useUserWithCompaniesQuery(queryOptions);
  const { data: currentCompanyData, loading: companyLoading } =
    useCompanyStartUpQuery(queryOptions);
  const { data: currentCompany } = useCompanyByIdQuery({
    variables: { id: currentCompanyData?.company?.id },
    skip: !currentCompanyData?.company?.id,
  });
  const { data: plansData, loading: planLoading } = usePlansQuery(queryOptions);
  const mainUserCompany = useReactiveVar(mainCompanyVar);

  useEffect(() => {
    if (!currentCompanyData) {
      assetCategoryIdsWithAssetsVar([]);
      assetTypeIdsWithAssetsVar([]);
      assetWorstStatusVar(MaintenanceStatusType.Current);
    } else {
      assetCategoryIdsWithAssetsVar(
        currentCompanyData.company.misc.assetCategoryIds
      );
      mainCompanyVar(currentCompanyData.company.mainCompany);
      assetTypeIdsWithAssetsVar(currentCompanyData.company.misc.assetTypeIds);
      assetWorstStatusVar(currentCompanyData.company.misc.assetStatus);
    }
  }, [currentCompanyData, currentCompany]);

  useEffect(() => {
    if (currentCompany) {
      currentCompanyVar(currentCompany.company);
    }
  }, [currentCompany]);

  useEffect(() => {
    if (currentCompanyData && plansData) {
      let planId: string | undefined;
      let companyStripeId: string | undefined;
      if (!currentCompanyData.company.isMainCompany) {
        planId = mainUserCompany?.planId;
        companyStripeId = mainUserCompany?.planStripeId;
      } else {
        planId = currentCompanyData.company.planId;
        companyStripeId = currentCompanyData.company.planStripeId;
      }
      const newPlan =
        plansData.plans.find(
          (plan) => plan.id === planId || plan.stripeId === companyStripeId
        ) || null;

      if (!isEqual(currentCompanyPlanVar(), newPlan)) {
        currentCompanyPlanVar(newPlan);
      }
    }
  }, [currentCompanyData, plansData, mainUserCompany]);

  useEffect(() => {
    if (userCompaniesData) {
      currentUserWithCompaniesVar(userCompaniesData);
    }
  }, [userCompaniesData]);

  useEffect(() => {
    if (userCompaniesData && currentCompanyData) {
      const currentCompanyUser = userCompaniesData.user.companyUsers?.find(
        (companyUser) =>
          companyUser.company.id === currentCompanyData.company.id
      );

      let newCompanyRole = {
        role: CompanyUserRole.Owner,
        isOwner: true,
        isAdmin: true,
        isManager: true,
        isUser: true,
        isViewOnly: true,
      };

      if (currentCompanyUser) {
        const newRole = currentCompanyUser.role;
        newCompanyRole = {
          role: newRole,
          isOwner: CompanyUserRole.Owner === newRole,
          isAdmin: CompanyUserRole.Admin === newRole,
          isManager: CompanyUserRole.Manager === newRole,
          isUser: CompanyUserRole.User === newRole,
          isViewOnly: CompanyUserRole.ViewOnly === newRole,
        };
      }

      if (!isEqual(currentCompanyRoleVar(), newCompanyRole)) {
        currentCompanyRoleVar(newCompanyRole);
      }
    }
  }, [currentCompanyData, userCompaniesData]);

  return companyUsersLoading || companyLoading || planLoading ? (
    <PageSpinner />
  ) : (
    <>{children}</>
  );
};

gql`
  query AssetWithType {
    assets {
      ...AssetWithTypeFragment
      locationsLabel
    }
  }

  query CompanyStartUp {
    company {
      id
      planId
      planStripeId

      misc {
        assetCategoryIds
        assetTypeIds
        assetStatus
      }
    }
  }
`;
