const Heading = {
  baseStyle: {
    fontWeight: "400",
    lineHeight: "inherit",
  },
  sizes: {
    xs: {
      fontSize: ".75rem",
      lineHeight: "inherit",
    },
    sm: {
      fontSize: ".875rem",
      lineHeight: "inherit",
    },
    base: {
      fontSize: "1rem",
      lineHeight: "inherit",
    },
    md: {
      fontSize: "1rem",
      lineHeight: "inherit",
    },
    lg: {
      fontSize: "1.125rem",
      lineHeight: "inherit",
    },
    xl: {
      fontSize: "1.25rem",
      lineHeight: "inherit",
    },
    "2xl": {
      fontSize: "1.5rem",
      lineHeight: "inherit",
    },
    "3xl": {
      fontSize: "1.875rem",
      lineHeight: "inherit",
    },
    "4xl": {
      fontSize: "2.5rem",
      lineHeight: "inherit",
    },
    "5xl": {
      fontSize: "3.375rem",
      lineHeight: "inherit",
    },
    "6xl": {
      fontSize: "4rem",
      lineHeight: "inherit",
    },
    "7xl": {
      fontSize: "5rem",
      lineHeight: "inherit",
    },
  },
  defaultProps: {
    size: "2xl",
  },
};

export default Heading;
