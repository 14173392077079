import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import FileIcon from "../../../../components/elements/fileIcon";
import Link from "../../../../components/elements/link";
import TooltipName from "../../../../components/elements/tooltipName";
import { AssetFileFragmentFragment } from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import AssetFileDelete from "../../assetFileDelete";

interface ListPresenterProps {
  assetFiles: AssetFileFragmentFragment[];
  assetId: string;
  canDeleteAsset: boolean;
  canEditAsset: boolean;
  selectedFiles: Array<string | undefined>;
  setSelectedFiles: Dispatch<SetStateAction<(string | undefined)[]>>;
}

const ListPresenter: React.FC<ListPresenterProps> = ({
  assetFiles,
  assetId,
  canDeleteAsset,
  canEditAsset,
  selectedFiles,
  setSelectedFiles,
}) => {
  return (
    <Box>
      {assetFiles.map((assetFile) => (
        <FileListItem
          assetFile={assetFile}
          assetId={assetId}
          key={assetFile.id}
          canDeleteAsset={canDeleteAsset}
          canEditAsset={canEditAsset}
          onSelect={setSelectedFiles}
          selectedFiles={selectedFiles}
        />
      ))}
    </Box>
  );
};

const FileListItem = ({
  assetFile,
  assetId,
  canDeleteAsset,
  canEditAsset,
  onSelect,
  selectedFiles,
}: {
  assetFile: AssetFileFragmentFragment;
  assetId: string;
  canDeleteAsset: boolean;
  canEditAsset: boolean;
  onSelect: Dispatch<SetStateAction<(string | undefined)[]>>;
  selectedFiles: Array<string | undefined>;
}) => {
  const navigate = useNavigate();
  const {
    isOpen: isOpenFileDelete,
    onOpen: onOpenFileDelete,
    onClose: onCloseFileDelete,
  } = useDisclosure();

  return (
    <Flex
      width="full"
      justifyContent="space-between"
      _hover={{ bgColor: "gray.100" }}
      role="group"
      alignItems="center"
      paddingY="2"
      borderBottom="1px solid"
      borderColor="gray.100"
    >
      {canDeleteAsset && (
        <Box
          as="button"
          display="block"
          visibility={{
            base: "visible",
            md: selectedFiles.includes(assetFile.id) ? "visible" : "hidden",
          }}
          _groupHover={{ visibility: "visible" }}
          marginRight="4"
          marginLeft="2"
          onClick={() => {
            if (!selectedFiles.includes(assetFile.id)) {
              onSelect([...selectedFiles, assetFile.id]);
            } else {
              onSelect((prevSelectedFiles) =>
                prevSelectedFiles.filter(
                  (selectedFile) => selectedFile !== assetFile.id
                )
              );
            }
          }}
          color={
            selectedFiles.includes(assetFile.id) ? "secondary.500" : "gray.500"
          }
          _hover={{ color: "secondary.500" }}
        >
          <FontAwesomeIcon
            size="lg"
            icon={
              selectedFiles.includes(assetFile.id) ? faCheckSquare : faSquare
            }
          />
        </Box>
      )}
      <Link
        to={getRoutePath("assetsShowFile", {
          assetId,
          fileId: assetFile.id,
        })}
        variant="link"
        borderBottomWidth={1}
        borderBottomColor="gray.100"
        size="sm"
        width="full"
        justifyContent="start"
      >
        <FileIcon
          filePath={assetFile.path}
          fileName={assetFile.name}
          forceIcon
          width={20}
        />
        <TooltipName
          as="span"
          marginLeft="2"
          color="gray.800"
          flexGrow={1}
          fontWeight="normal"
          maxWidth="calc(100% - 32px)"
          display="inline-block"
          name={assetFile.name}
        />
      </Link>

      <Flex opacity="0" _groupHover={{ opacity: "1" }} alignItems="center">
        {canEditAsset && (
          <Tooltip label="Edit Document" hasArrow placement="bottom">
            <Link
              to={getRoutePath("assetsShowFileEdit", {
                assetId,
                fileId: assetFile.id,
              })}
              aria-label="Edit Document"
              variant="icon"
              colorScheme="gray"
              marginRight="4"
            >
              <FontAwesomeIcon icon={faPen} />
            </Link>
          </Tooltip>
        )}
        {canDeleteAsset && (
          <Tooltip
            label="Delete Document"
            hasArrow
            placement="bottom"
            zIndex={3}
          >
            <IconButton
              aria-label="Delete Document"
              variant="icon"
              colorScheme="grayRed"
              marginRight="4"
              onClick={onOpenFileDelete}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        )}
      </Flex>

      {isOpenFileDelete && (
        <AssetFileDelete
          assetFile={assetFile}
          assetId={assetId}
          handleDelete={() => {
            onCloseFileDelete();
            navigate(getRoutePath("assetsShow", { assetId }));
          }}
        />
      )}
    </Flex>
  );
};

export default ListPresenter;
