import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import { useSessionQuery } from "../../graphql/graphql";
import { getRoutePath } from "../../router";

interface HomeRouteProps {}

const HomeRoute: React.FC<HomeRouteProps> = () => {
  const { data } = useSessionQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate(getRoutePath(data.session.isLoggedIn ? "dashboard" : "signIn"));
    }
  }, [data, navigate]);

  if (!data) return null;

  return <PageSpinner />;
};

export default HomeRoute;
