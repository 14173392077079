import { Icon, IconProps } from "@chakra-ui/react";

const WaterHeater = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M99.887 14.556C95.433 12.585 83.019 8 64 8c-19.02 0-31.433 4.585-35.889 6.556a5.589 5.589 0 0 0-3.31 5.113v68.133a5.587 5.587 0 0 0 3.312 5.113c.99.438 2.393 1.008 4.154 1.624v7.117a5.6 5.6 0 0 0 4.02 5.374 88.592 88.592 0 0 0 9.046 2.063v5.307a5.6 5.6 0 0 0 5.6 5.6h3.734a5.6 5.6 0 0 0 5.6-5.6v-3.791c1.234.037 2.479.056 3.733.058 1.254.001 2.499-.018 3.733-.058v3.791a5.6 5.6 0 0 0 5.6 5.6h3.734a5.6 5.6 0 0 0 5.6-5.6v-5.307a88.821 88.821 0 0 0 9.044-2.064 5.601 5.601 0 0 0 4.022-5.376v-7.118a56.007 56.007 0 0 0 4.156-1.624 5.588 5.588 0 0 0 3.311-5.113v-68.13a5.586 5.586 0 0 0-3.313-5.112ZM56.533 114.4a1.867 1.867 0 0 1-1.866 1.867h-3.734a1.867 1.867 0 0 1-1.866-1.867v-4.721c2.435.332 4.924.581 7.466.747v3.974Zm22.4 0a1.867 1.867 0 0 1-1.866 1.867h-3.734a1.867 1.867 0 0 1-1.866-1.867v-3.97c2.542-.165 5.031-.414 7.466-.747v4.717ZM92 101.653a1.851 1.851 0 0 1-1.32 1.786A96.885 96.885 0 0 1 64 106.933a96.9 96.9 0 0 1-26.682-3.494A1.852 1.852 0 0 1 36 101.653v-5.92a100.325 100.325 0 0 0 28 3.734 100.34 100.34 0 0 0 28-3.734v5.92Zm7.467-13.855a1.85 1.85 0 0 1-1.09 1.699C94.139 91.373 82.308 95.733 64 95.733c-18.308 0-30.14-4.36-34.375-6.234a1.854 1.854 0 0 1-1.092-1.7v-68.13a1.85 1.85 0 0 1 1.09-1.7c4.238-1.875 16.069-6.236 34.377-6.236 18.308 0 30.14 4.36 34.375 6.235a1.854 1.854 0 0 1 1.092 1.7v68.13Z"
      fill="currentColor"
    />
    <path
      d="M64 49.067c8.248 0 14.933-6.686 14.933-14.934C78.933 25.886 72.247 19.2 64 19.2s-14.933 6.686-14.933 14.933c.009 8.244 6.69 14.924 14.933 14.934Zm0-26.134c6.186 0 11.2 5.015 11.2 11.2 0 6.186-5.014 11.2-11.2 11.2-6.185 0-11.2-5.014-11.2-11.2.006-6.183 5.017-11.194 11.2-11.2ZM88.267 62.133H39.733A3.733 3.733 0 0 0 36 65.867v11.2a3.733 3.733 0 0 0 3.733 3.733h48.534A3.733 3.733 0 0 0 92 77.067v-11.2a3.733 3.733 0 0 0-3.733-3.734ZM39.733 77.067v-11.2h48.534v11.2H39.733Z"
      fill="currentColor"
    />
    <path
      d="M62.68 35.453a1.867 1.867 0 0 0 2.64-2.64l-3.734-3.733a1.867 1.867 0 0 0-2.64 2.64l3.734 3.733ZM49.066 69.6h-3.733a1.867 1.867 0 1 0 0 3.733h3.733a1.867 1.867 0 1 0 0-3.733ZM82.666 69.6H56.533a1.867 1.867 0 1 0 0 3.733h26.133a1.867 1.867 0 1 0 0-3.733Z"
      fill="currentColor"
    />
  </Icon>
);

export default WaterHeater;
