import React from "react";
import { useSearchParams } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  useCurrentCompanyQuery,
  usePlansQuery,
  useUserWithCompaniesQuery,
} from "../../../graphql/graphql";
import CompanyPlanCreatePagePresenter from "../../companyPlanSubscribe/presenter";

declare let window: any;

interface NativeChangePlanPageProps {}

const NativeChangePlanPage: React.FC<NativeChangePlanPageProps> = () => {
  const [searchParams] = useSearchParams();
  const { data: plansData } = usePlansQuery();
  const { data: companiesData } = useUserWithCompaniesQuery();
  const { data } = useCurrentCompanyQuery();
  const currentCompanyId = data?.currentCompany.id;
  const handleSuccess = () => {
    if (window.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ eventType: "CA:PlanChanged" })
      );
    }
  };

  return plansData && companiesData ? (
    <CompanyPlanCreatePagePresenter
      plans={plansData.plans}
      userCompanies={companiesData.user.companyUsers.map((cu) => cu.company)}
      selectedCompanyId={currentCompanyId}
      onSuccess={handleSuccess}
      showCancelLink={false}
      header={
        searchParams.has("upgradePlan")
          ? "Upgrade Your Plan"
          : "Change Your Plan"
      }
    />
  ) : (
    <PageSpinner />
  );
};

export default NativeChangePlanPage;
