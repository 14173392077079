import React from "react";

import Map from "../../../components/elements/fileSelectInput/map";

declare let window: any;

interface MapboxPageProps {}

const MapboxPage: React.FC<MapboxPageProps> = () => {
  const handleMapboxSuccess = (id: string) => {
    if (window.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          eventType: "CA:MapCaptured",
          payload: { fileId: id },
        })
      );
    }
  };

  return <Map onSuccess={handleMapboxSuccess} />;
};

export default MapboxPage;
