import { useReactiveVar } from "@apollo/client";
import { Alert, AlertDescription, Button } from "@chakra-ui/react";
import { differenceInDays, formatDistanceToNowStrict } from "date-fns";
import React, { useCallback, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  useCompanyQuery,
  useUserQuery,
  useUserUpdateMutation,
} from "../../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../../graphql/reactiveVariables";
import usePaymentsAvailable from "../../../../hooks/usePaymentsAvailable";
import ROLES from "../../../../roles";
import { getRoutePath } from "../../../../router";
import { formatDateFromSecondsTimestamp } from "../../../../utils/date";
import Link from "../../../elements/link";
import Rocket from "../../../icons/rocket";
import BookDemo from "./bookDemo";
import CompanySetup from "./companySetup";
import EmailConfirm from "./emailConfirm";
import PhoneConfirm from "./phoneConfirm";
import PhoneCreate from "./phoneCreate";
import PushNotification from "./pushNotification";

interface BannersProps {}

const Banners: React.FC<BannersProps> = () => {
  const { data } = useUserQuery();
  const location = useLocation();
  const [pushNotificationsChecked, setPushNotificationsChecked] =
    useState(false);
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data: currentCompanyData } = useCompanyQuery();
  const { data: currentUserData } = useUserQuery();
  const [userUpdateMutation] = useUserUpdateMutation();
  const paymentsAvailable = usePaymentsAvailable();
  const [, setSearchParams] = useSearchParams();
  const handleDemoBookedOrClosed = useCallback(async () => {
    if (!currentUserData) return;

    await userUpdateMutation({
      variables: {
        data: {
          config: { ...currentUserData.user.config, demoBookedOrClosed: true },
        },
      },
    });
  }, [userUpdateMutation, currentUserData]);

  if (!data || !data.user || !currentCompanyData) return null;
  const company = currentCompanyData.company;

  const billingRole = ROLES.companyPlan.includes(currentCompanyRole.role);

  const changePlan = () => {
    setSearchParams({ CaAprType: "changePlan" });
  };

  if (!pushNotificationsChecked)
    return (
      <PushNotification
        handlePostCheck={() => setPushNotificationsChecked(true)}
      />
    );

  if (
    !currentCompanyData?.company.config.onboarding?.completed &&
    ROLES.onboarding.includes(currentCompanyRole.role)
  )
    return <CompanySetup />;

  if (
    billingRole &&
    !paymentsAvailable &&
    company.planStripeStatus === "trialing"
  ) {
    const stripeTrialEndDate = new Date(company.planStripeTrialEnd);
    const days = formatDistanceToNowStrict(stripeTrialEndDate, {
      unit: "day",
      roundingMethod: "ceil",
    });
    const remainingDays = differenceInDays(stripeTrialEndDate, new Date());
    return (
      <Alert
        variant="solid"
        justifyContent="center"
        status={
          remainingDays < 4
            ? "error"
            : remainingDays < 8
            ? "warning"
            : "success"
        }
        role="group"
      >
        <AlertDescription>
          Welcome! Your free trial ends in {days}{" "}
          <Button
            variant="link"
            colorScheme="white"
            onClick={changePlan}
            fontSize="sm"
            marginLeft="2"
            padding="0 4px"
            _groupHover={{
              backgroundColor:
                remainingDays < 4
                  ? "red.800"
                  : remainingDays < 8
                  ? "orange.700"
                  : "green.700",
            }}
            textTransform="uppercase"
            leftIcon={<Rocket />}
          >
            Upgrade
          </Button>
        </AlertDescription>
      </Alert>
    );
  }

  if (billingRole && company.planStripeStatus === "incomplete") {
    return (
      <Alert status="error" role="group">
        <AlertDescription>
          Welcome! Your free trial has ended{" "}
          <Button
            variant="link"
            colorScheme="white"
            onClick={changePlan}
            fontSize="sm"
            marginLeft="2"
            padding="0 4px"
            _groupHover={{ backgroundColor: "red.800" }}
            textTransform="uppercase"
            leftIcon={<Rocket />}
          >
            Upgrade
          </Button>
        </AlertDescription>
      </Alert>
    );
  }

  if (billingRole && company.planStripeCancelAt) {
    return (
      <Alert status="error" role="group">
        <AlertDescription>
          Welcome! Your renewal has been cancelled and your plan will remain
          active until{" "}
          {formatDateFromSecondsTimestamp(company.planStripeCancelAt / 1000)}{" "}
          <Button
            variant="link"
            colorScheme="white"
            onClick={changePlan}
            fontSize="sm"
            marginLeft="2"
            padding="0 4px"
            _groupHover={{ backgroundColor: "red.800" }}
            textTransform="uppercase"
            leftIcon={<Rocket />}
          >
            Reactivate
          </Button>
        </AlertDescription>
      </Alert>
    );
  }

  if (
    billingRole &&
    ["incomplete_expired", "canceled", "unpaid"].includes(
      `${company.planStripeStatus}`
    )
  ) {
    return (
      <Alert status="error" role="group">
        <AlertDescription>
          Welcome! Your plan has expired{" "}
          <Button
            variant="link"
            colorScheme="white"
            onClick={changePlan}
            fontSize="sm"
            marginLeft="2"
            padding="0 4px"
            _groupHover={{ backgroundColor: "red.800" }}
            textTransform="uppercase"
            leftIcon={<Rocket />}
          >
            Reactivate
          </Button>
        </AlertDescription>
      </Alert>
    );
  }

  if (billingRole && company.planStripeStatus === "past_due") {
    return (
      <Alert status="error" role="group">
        <AlertDescription>
          There is a problem with your payment method
          <Link
            to={`${getRoutePath("companyPaymentMethods")}?redirectPath=${
              location.pathname
            }`}
            variant="link"
            colorScheme="white"
            _groupHover={{ backgroundColor: "red.800" }}
            fontSize="sm"
            marginLeft="2"
            padding="0 4px"
            textTransform="uppercase"
          >
            Fix Now
          </Link>
        </AlertDescription>
      </Alert>
    );
  }

  if (!data.user.emailConfirmed) {
    return <EmailConfirm />;
  }
  if (currentUserData && !currentUserData.user.config.demoBookedOrClosed) {
    return <BookDemo onClose={handleDemoBookedOrClosed} />;
  }
  if (!data.user.phone) {
    return <PhoneCreate />;
  }
  if (!data.user.phoneConfirmed) {
    return <PhoneConfirm />;
  }

  return null;
};

export default Banners;
