import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, ReactNode, memo, useCallback } from "react";
import isEqual from "react-fast-compare";
import { FixedSizeList } from "react-window";

import colors from "../../../theme/foundations/colors";
import TooltipName from "../tooltipName";

export interface FilterOption {
  label: string | ReactNode;
  value: string;
  query: string;
}

interface FilterSelectProps {
  label: string;
  selectedOptions: string[] | undefined;
  handleFilterChange: (id: string) => void;
  allOptions: FilterOption[];
  showSelectedCount?: boolean;
  buttonProps?: ButtonProps;
}

const FilterSelect: React.FC<FilterSelectProps> = ({
  label,
  allOptions,
  selectedOptions,
  handleFilterChange,
  showSelectedCount = true,
  buttonProps = {},
}) => {
  const handleChange = useCallback(
    (id: string) => {
      handleFilterChange(id);
    },
    [handleFilterChange]
  );

  return (
    <Popover placement="bottom-start" isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              isActive={isOpen}
              variant="ghost"
              rightIcon={
                <FontAwesomeIcon
                  icon={isOpen ? faChevronUp : faChevronDown}
                  size="xs"
                  color={colors.gray[700]}
                />
              }
              paddingX="2"
              fontWeight="400"
              colorScheme="gray"
              border="1px solid"
              borderColor="gray.500"
              height="8"
              textAlign="left"
              _focus={{ borderColor: "primary.500" }}
              _active={{ borderColor: "primary.500" }}
              _hover={{ backgroundColor: "transparent" }}
              textTransform="none"
              justifyContent="space-between"
              w="full"
              {...buttonProps}
            >
              {!!label && (
                <Flex
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  w="calc(100% - 15px)"
                >
                  <TooltipName
                    color="gray.600"
                    position="static"
                    top="2px"
                    left="4"
                    fontSize="sm"
                    flexGrow={1}
                    name={label}
                  />
                  {showSelectedCount && !!selectedOptions?.length && (
                    <Text
                      borderRadius="full"
                      boxSize="5"
                      textAlign="center"
                      fontSize=".65rem"
                      lineHeight={1.2}
                      p="1"
                      ml="1"
                      backgroundColor={
                        selectedOptions?.length ? "secondary.500" : "gray.700"
                      }
                      color="white"
                    >
                      {selectedOptions.length === allOptions.length
                        ? "All"
                        : selectedOptions.length}
                    </Text>
                  )}
                </Flex>
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width={275} borderRadius="0">
              <PopoverBody padding="0" overflow="hidden">
                {allOptions.length ? (
                  <>
                    <FixedSizeList
                      height={
                        allOptions.length > 5 ? 240 : allOptions.length * 40
                      }
                      width="100%"
                      itemSize={40}
                      itemCount={allOptions.length}
                      itemData={{ handleChange, allOptions, selectedOptions }}
                      itemKey={(i, data) => data.allOptions[i].value}
                      overscanCount={8}
                    >
                      {RowItem}
                    </FixedSizeList>
                  </>
                ) : (
                  <Flex
                    height="37px"
                    alignItems="center"
                    justifyContent="flex-start"
                    paddingX="2"
                    fontSize="16px"
                    textAlign="left"
                  >
                    No filters available
                  </Flex>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default FilterSelect;

interface Data {
  allOptions: Array<{
    label: string | ReactNode;
    value: string;
  }>;
  handleChange: (id: string) => void;
  selectedOptions: string[] | undefined;
}

const RowItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: Data;
}> = memo(({ index, style, data }) => {
  const option = data.allOptions[index];
  return (
    <Button
      key={option.value}
      variant="ghost"
      width="full"
      colorScheme="gray"
      height="40px !important"
      fontWeight="normal"
      _hover={{ background: "gray.100" }}
      textTransform="none"
      onClick={() => data.handleChange(option.value)}
      px="1"
      style={style}
    >
      <Flex
        width="full"
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box
          visibility={
            data.selectedOptions?.includes(option.value) ? "visible" : "hidden"
          }
          boxSize="3"
          marginRight="1"
          flexShrink="0"
        >
          <FontAwesomeIcon icon={faCheck} color={colors.secondary[500]} />
        </Box>
        <Text
          flexGrow="1"
          fontSize="14px"
          color="gray.800"
          textAlign="left"
          isTruncated
        >
          {option.label}
        </Text>
      </Flex>
    </Button>
  );
}, isEqual);
