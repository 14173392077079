import React from "react";

import { WorkOrder } from "../../../../models/workOrder";
import WorkOrderTimeZoneSelectorPresenter from "./presenter";

interface WorkOrderTimeZoneSelectorProps {
  workOrder: WorkOrder;
  onIsSelectorVisibleChange?: (value: boolean) => void;
  onTimezoneSelected: (timezone: string) => void;
}

const WorkOrderTimeZoneSelector: React.FC<WorkOrderTimeZoneSelectorProps> = ({
  workOrder,
  onIsSelectorVisibleChange,
  onTimezoneSelected,
}) => {
  return (
    <>
      <WorkOrderTimeZoneSelectorPresenter
        selectedTimeZone={workOrder.serviceScheduleTimezone}
        onIsSelectorVisibleChange={onIsSelectorVisibleChange}
        onTimezoneSelected={onTimezoneSelected}
      />
    </>
  );
};

export default WorkOrderTimeZoneSelector;
