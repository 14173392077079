import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zonedTimeToUtc } from "date-fns-tz";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import AssetFields, {
  getAssetFieldsInitialValues,
  getAssetFieldsValidationSchema,
} from "../../../components/elements/assetFields";
import AssetIcon from "../../../components/elements/assetIcons";
import ContactCompanyUserField from "../../../components/elements/contactCompanyUserField";
import DefaultAssetMaintenanceInput from "../../../components/elements/defaultAssetMaintenanceInput";
import FormGroup from "../../../components/elements/formGroup";
import PageSpinner from "../../../components/elements/pageSpinner";
import SelectCombobox from "../../../components/elements/selectCombobox";
import {
  AssetFieldValuesInput,
  AssetTypeFragmentFragment,
  MaintenanceDurationType,
  MaintenanceIntervalType,
  MaintenanceRemindBeforeType,
  useAssetTypeLazyQuery,
  useCompanyQuery,
} from "../../../graphql/graphql";
import {
  assetMaintenanceFormValidationSchema,
  assetTypeIdSchema,
  companyUserIdsSchema,
  descriptionSchema,
  nameSchema,
  yupArray,
  yupObject,
} from "../../../utils/validation";

interface AssetsCreatePresenterProps {
  handleSubmit: (
    values: AssetsFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  onDrawerClose: () => void;
  isOpen: boolean;
  loading: boolean;
  assetTypes: AssetTypeFragmentFragment[];
  assetTypeIdToAdd?: string;
}

const AssetsCreatePresenter: React.FC<AssetsCreatePresenterProps> = ({
  isOpen,
  handleSubmit,
  onDrawerClose,
  loading,
  assetTypes,
  assetTypeIdToAdd,
}) => {
  const { data: companyData } = useCompanyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [fetchAssetType, { data }] = useAssetTypeLazyQuery({
    fetchPolicy: "network-only",
  });
  const [selectedAssetTypeId, setSelectedAssetTypeId] = React.useState<
    string | null
  >();
  const selectedAssetType = data?.assetType;
  React.useEffect(() => {
    if (!selectedAssetTypeId) return;
    fetchAssetType({ variables: { id: selectedAssetTypeId } });
  }, [fetchAssetType, selectedAssetTypeId]);

  React.useEffect(() => {
    if (assetTypeIdToAdd) {
      setSelectedAssetTypeId(assetTypeIdToAdd);
    }
  }, [assetTypeIdToAdd, setSelectedAssetTypeId]);

  const validationSchema = React.useMemo(() => {
    let yupShape: any = {
      name: nameSchema.label("Asset name"),
      description: descriptionSchema,
      assetTypeId: assetTypeIdSchema,
      companyUserIds: companyUserIdsSchema.when("assetMaintenancesInput", {
        is: (assetMaintenancesInput: any[]) =>
          assetMaintenancesInput.some((ami: any) => !!ami),
        then: yupArray().required().min(1),
        otherwise: yupArray().optional().min(0),
      }),
      assetMaintenancesInput: yupArray().of(
        assetMaintenanceFormValidationSchema
      ),
    };
    if (selectedAssetType) {
      yupShape = {
        ...yupShape,
        ...getAssetFieldsValidationSchema(selectedAssetType),
      };
    }
    return yupObject().shape(yupShape);
  }, [selectedAssetType]);

  const initialValues = React.useMemo(() => {
    let initialValues: any = {
      name: "",
      description: "",
      assetTypeId: selectedAssetType?.id,
      companyUserIds: [] as string[],
      assetMaintenancesInput: selectedAssetType?.maintenanceTemplates.length
        ? Array.from(Array(selectedAssetType.maintenanceTemplates.length))
        : [],
    };
    if (selectedAssetType) {
      initialValues = {
        ...initialValues,
        ...getAssetFieldsInitialValues(selectedAssetType),
      };
    }
    return initialValues;
  }, [selectedAssetType]);

  const onSubmit = React.useCallback(
    (data: any, formikHelpers: any) => {
      const formattedData: AssetsFormData = {
        name: data.name,
        description: data.description,
        assetTypeId: data.assetTypeId,
        companyUserIds: data.companyUserIds,
        assetFieldValuesInput: [],
        assetMaintenancesInput: [],
      };
      formattedData.assetMaintenancesInput = data.assetMaintenancesInput
        .filter((ami: any) => !!ami)
        .map((ami: any) => ({
          ...ami,
          startDateTime: zonedTimeToUtc(
            ami.startDateTime,
            ami.timezone
          ).getTime(),
        }));
      if (selectedAssetType) {
        selectedAssetType.assetFields.forEach((assetField) => {
          if (data[assetField.id]) {
            formattedData.assetFieldValuesInput.push({
              assetFieldId: assetField.id,
              value: `${data[assetField.id]}`,
            });
          }
        });
      }
      handleSubmit(formattedData, formikHelpers);
    },
    [selectedAssetType, handleSubmit]
  );

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>Add New Asset</title>
      </Helmet>
      <DrawerOverlay zIndex={1600}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex alignItems="center">
              <Box>Add New Asset</Box>
              <Tooltip
                label="Add assets that you want to track. After creating an asset, you can add it to your plans."
                hasArrow
              >
                <Box color="gray.700" marginLeft="2">
                  <FontAwesomeIcon icon={faInfoCircle} size="xs" />
                </Box>
              </Tooltip>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {companyData ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ getFieldProps }) => (
                  <Form id="asset_create" noValidate>
                    <SelectCombobox
                      label="Asset Type"
                      name="assetTypeId"
                      sortOptions
                      options={assetTypes.map((assetType) => ({
                        label: (
                          <Flex alignItems="center">
                            <AssetIcon
                              iconName={assetType.iconName}
                              iconColor={assetType.misc.resolvedIconColor}
                              iconType={assetType.iconType}
                              iconSize="xs"
                            />
                            <Box marginLeft="2">
                              {assetType.misc.resolvedName}
                            </Box>
                          </Flex>
                        ),
                        query: assetType.misc.resolvedName,
                        value: assetType.id,
                      }))}
                      onChange={setSelectedAssetTypeId}
                      autoFocus={!assetTypeIdToAdd}
                    />
                    <FormGroup label="Asset Name" name="name">
                      <Input autoComplete="off" {...getFieldProps("name")} />
                    </FormGroup>
                    <FormGroup label="Description" name="description">
                      <Textarea
                        autoComplete="off"
                        {...getFieldProps("description")}
                      />
                    </FormGroup>
                    {!!selectedAssetType && (
                      <AssetFields
                        assetFields={selectedAssetType.assetFields}
                      />
                    )}
                    <ContactCompanyUserField
                      name="companyUserIds"
                      label="Select user(s) responsible for servicing this asset"
                    />
                    <Box marginTop="4">
                      <DefaultAssetMaintenanceInput
                        maintenanceTemplates={
                          selectedAssetType?.maintenanceTemplates || []
                        }
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            ) : (
              <PageSpinner />
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button
              width="full"
              type="submit"
              isLoading={loading}
              form="asset_create"
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetsCreatePresenter;

export type AssetsFormData = {
  name: string;
  description: string;
  assetTypeId: string;
  assetFieldValuesInput: AssetFieldValuesInput[];
  companyUserIds: string[];
  assetMaintenancesInput: AssetMaintenanceValue[];
};

export type AssetMaintenanceValue = {
  name: string;
  description?: string;
  durationType: MaintenanceDurationType;
  durationValue: number;
  intervalType: MaintenanceIntervalType;
  intervalValue: number;
  startDateTime: Date;
  timezone: string;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
};
