import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface MobileAccordionProps {}

const MobileAccordion: React.FC<MobileAccordionProps> = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  return isMobile ? (
    <Accordion allowToggle flexGrow={1}>
      <AccordionItem border="none">
        <AccordionButton
          width="fit-content"
          background="transparent"
          _expanded={{ background: "transparent" }}
          px="0"
          color="gray.500"
        >
          <FontAwesomeIcon icon={faFilter} />
        </AccordionButton>
        <AccordionPanel padding="0">
          <Box maxW="full">{children}</Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <>{children}</>
  );
};

export default MobileAccordion;
