import { useReactiveVar } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import React from "react";

import { layoutOptionsVar } from "../../../../graphql/reactiveVariables";

interface AuthHeaderProps {}

const AuthHeader: React.FC<AuthHeaderProps> = () => {
  const { title, subTitle } = useReactiveVar(layoutOptionsVar);
  return (
    <>
      {title && (
        <Box
          textAlign="center"
          fontSize={{ base: "3xl", lg: "4xl" }}
          marginTop={{ base: "4", lg: "4" }}
          marginLeft={{ base: "4", lg: "4" }}
          style={{ marginLeft: "40px" }}
        >
          {title}
        </Box>
      )}
      {subTitle && (
        <Box
          marginX="auto"
          textAlign="center"
          marginTop="4"
          color="gray.600"
          fontSize="lg"
          maxWidth="2xl"
        >
          {subTitle}
        </Box>
      )}
    </>
  );
};

export default AuthHeader;
