import { QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";

import {
  AssetCategoriesQuery,
  AssetCategoriesQueryVariables,
  AssetCategoryWithTypesFragmentFragment,
  AssetTypeFragmentFragment,
  useAssetCategoriesQuery,
  useCompanyQuery,
} from "../graphql/graphql";

const useAssetCategories = (
  assetCategoriesQueryParams?: QueryHookOptions<
    AssetCategoriesQuery,
    AssetCategoriesQueryVariables
  >,
  showHiddenCategoriesAndTypes?: boolean
): ReturnType<typeof useAssetCategoriesQuery> => {
  const { data } = useCompanyQuery();
  const result = useAssetCategoriesQuery(assetCategoriesQueryParams);
  const [assetCategories, setAssetCategories] = useState(result);

  useEffect(() => {
    if (showHiddenCategoriesAndTypes) {
      setAssetCategories(result);
    } else {
      if (!data || !result.data) return;
      const { hiddenCategoryIds, hiddenTypeIds } = data.company;

      const visibleCategories: AssetCategoryWithTypesFragmentFragment[] = [];
      const categoriesCount = result.data.assetCategories.length;
      for (let i = 0; i < categoriesCount; i++) {
        const category = result.data.assetCategories[i];
        if (!hiddenCategoryIds.includes(category.id)) {
          const assetTypes = category.assetTypes;
          const visibleAssetTypes: AssetTypeFragmentFragment[] = [];
          if (assetTypes) {
            const typesCount = assetTypes.length;
            for (let j = 0; j < typesCount; j++) {
              const assetType = assetTypes[j];
              if (!hiddenTypeIds.includes(assetType.id)) {
                visibleAssetTypes.push(assetType);
              }
            }
          }

          visibleCategories.push({
            ...category,
            assetTypes: visibleAssetTypes,
          });
        }
      }
      setAssetCategories({
        ...result,
        data: { assetCategories: visibleCategories },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, result.data, showHiddenCategoriesAndTypes]);

  return assetCategories;
};

export default useAssetCategories;

gql`
  query AssetCategories {
    assetCategories {
      ...AssetCategoryWithTypesFragment
    }
  }

  query AssetCategoriesWithRelations {
    assetCategories {
      ...AssetCategoryWithRelationsFragment
    }
  }
`;
