import { addDays, isBefore, isPast } from "date-fns";

import {
  MaintenanceScheduleFragmentFragment,
  MaintenanceStatusType,
} from "../graphql/graphql";

export const getStatusBgColor = (status: MaintenanceStatusType) => {
  switch (status) {
    case MaintenanceStatusType.PastDue:
      return "red.500";
    case MaintenanceStatusType.DueIn_30:
      return "orange.500";
    default:
      return "green.500";
  }
};

export const getDateBgColor = (date?: Date) => {
  if (!date) return "green.500";
  if (isPast(date)) return "red.500";
  if (isBefore(date, addDays(new Date(), 30))) return "orange.500";
  return "green.500";
};

export const getScheduleBgColor = (
  maintenanceSchedule: MaintenanceScheduleFragmentFragment
) => {
  if (maintenanceSchedule.completedAt) return "green.500";
  return getDateBgColor(maintenanceSchedule.scheduledAt);
};
