import ReactGA from "react-ga";

const reactGAEnabled =
  process.env.REACT_APP_GA && process.env.REACT_APP_GA !== "NA";

export const initGA = () => {
  if (reactGAEnabled) ReactGA.initialize(process.env.REACT_APP_GA as string);
};

export const recordPageview = (path: string) => {
  if (reactGAEnabled) ReactGA.pageview(path);
};

export const setUserData = (userId?: string, name?: string) => {
  if (reactGAEnabled) ReactGA.set({ userId, name });
};
