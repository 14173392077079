import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import gql from "graphql-tag";
import React, { ReactNode } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

import {
  FileFragmentFragment,
  useFileCreateMutation,
  useSignedUrlCreateMutation,
} from "../../../graphql/graphql";
import FilesProgress, { UploadingFile } from "./filesProgress";

interface FilesUploadProps {
  buttonText?: ReactNode;
  isButtonTooltipNeeded?: boolean;
  folderId: string;
  accept?: string;
  allowMultipleUpload?: boolean;
  showProgress?: boolean;
  hideButton?: boolean;
  buttonColorScheme?: string;
  buttonVariant?: string;
  buttonSize?: string;
  buttonBlock?: boolean;
  onUpload?: (files: FileFragmentFragment[]) => void;
  setUploading?: (status: boolean) => void;
}

const FilesUpload: React.FC<FilesUploadProps> = ({
  folderId,
  buttonText,
  isButtonTooltipNeeded = true,
  accept,
  allowMultipleUpload,
  showProgress,
  hideButton,
  buttonColorScheme,
  buttonVariant,
  buttonBlock,
  buttonSize = "sm",
  onUpload,
  setUploading,
}) => {
  const client = useApolloClient();
  const [uploadingFiles, setUploadingFiles] = React.useState<UploadingFile[]>(
    []
  );
  const [fileCreateMutation] = useFileCreateMutation();
  const [signedUrlCreateMutation] = useSignedUrlCreateMutation();
  const toast = useToast();

  const onDropAccepted = React.useCallback(
    async (acceptedFiles: File[]) => {
      const signedUrlData = acceptedFiles.map((af) => ({
        key: "files",
        contentType: af.type,
      }));
      const { data } = await signedUrlCreateMutation({
        variables: { data: signedUrlData },
      });
      if (!data) return;
      const signedUrls = data.signedUrlCreate;

      if (setUploading) setUploading(true);

      const localUploadingFiles = acceptedFiles.map((af, i) => ({
        name: af.name,
        path: signedUrls[i].path,
        progress: 0,
        signedUrl: signedUrls[i].signedUrl,
      }));

      setUploadingFiles(localUploadingFiles);

      await Promise.all(
        acceptedFiles.map((af, i) =>
          axios.put(signedUrls[i].signedUrl, af, {
            onUploadProgress: async (progressEvent: any) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadingFiles((files) =>
                files.map((f) =>
                  f.path === signedUrls[i].path ? { ...f, progress } : f
                )
              );
            },
          })
        )
      );

      const filesResponse = await Promise.all(
        localUploadingFiles.map((file) =>
          fileCreateMutation({
            variables: {
              data: {
                path: file.path,
                name: file.name,
                folderId,
              },
            },
          })
        )
      );

      const files = filesResponse
        .filter(({ data }) => !!data)
        .map(({ data }) => data?.fileCreate);
      if (onUpload) onUpload(files as FileFragmentFragment[]);
      client.cache.evict({ id: `Folder:${folderId}`, fieldName: "files" });
      setUploadingFiles([]);
      if (setUploading) setUploading(false);
    },
    [
      signedUrlCreateMutation,
      fileCreateMutation,
      folderId,
      client,
      onUpload,
      setUploading,
    ]
  );

  const onDropRejected = React.useCallback(
    (rejectedFiles: FileRejection[]): void => {
      toast({
        description: `${rejectedFiles.map((fr) => fr.file.name).join(", ")} ${
          rejectedFiles.length > 1 ? "are" : "is"
        } too large. Max. file size is 200mb.`,
        status: "error",
        isClosable: true,
        position: "top",
      });
    },
    [toast]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    maxSize: 200000000,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <>
      <Box display="none" {...getRootProps()}>
        <input {...getInputProps()} multiple={allowMultipleUpload} />
      </Box>
      <Box visibility={hideButton ? "hidden" : "visible"} as="span">
        {isButtonTooltipNeeded ? (
          <Tooltip label="Upload file" hasArrow placement="left">
            <Button
              onClick={open}
              size={buttonSize}
              id="filesUploadButton"
              variant={buttonVariant}
              colorScheme={buttonColorScheme}
              width={buttonBlock ? "100%" : "auto"}
            >
              {buttonText}
            </Button>
          </Tooltip>
        ) : (
          <Button
            onClick={open}
            size={buttonSize}
            id="filesUploadButton"
            variant={buttonVariant}
            colorScheme={buttonColorScheme}
            width={buttonBlock ? "100%" : "auto"}
            isLoading={!showProgress && uploadingFiles.length > 0}
          >
            {buttonText}
          </Button>
        )}
      </Box>
      {uploadingFiles.length > 0 && showProgress && (
        <Drawer isOpen placement="right" onClose={() => {}}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerHeader>Upload Progress</DrawerHeader>
              <DrawerBody>
                <FilesProgress files={uploadingFiles} />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}
    </>
  );
};

FilesUpload.defaultProps = {
  allowMultipleUpload: true,
  showProgress: true,
};

export default FilesUpload;

gql`
  mutation SignedUrlCreate($data: [SignedUrlInput!]!) {
    signedUrlCreate(data: $data) {
      ...SignedUrlFragment
    }
  }

  mutation FileCreate($data: FileCreateInput!) {
    fileCreate(data: $data) {
      ...FileFragment
    }
  }
`;
