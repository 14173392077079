import "leaflet/dist/leaflet.css";
import "leaflet-editable";

import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faRedo, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Feature, FeatureCollection } from "geojson";
import gql from "graphql-tag";
import { CRS } from "leaflet";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import isEqual from "react-fast-compare";
import { MapContainer } from "react-leaflet";
import { useWindowSize } from "react-use";

import {
  GENERIC_SAVED_MESSAGE,
  PLAN_EDITOR_PROMPT,
} from "../../../../constants/lang/en";
import {
  AssetAffectedAssetUpdateInput,
  AssetForMapFragmentFragment,
  FloorPlanAssetForMapFragmentFragment,
  FloorPlanAssetUpdateManyInput,
  FloorPlanForMapFragmentFragment,
  useAssetAffectedAssetUpdateManyMutation,
  useFloorPlanAssetUpdateManyMutation,
} from "../../../../graphql/graphql";
import {
  changePlanEditorOptions,
  currentCompanyRoleVar,
  planEditorOptionsVar,
} from "../../../../graphql/reactiveVariables";
import useFloorPlanSignedUrlData from "../../../../hooks/useFloorPlanSignedUrlData";
import { usePrompt } from "../../../../hooks/userPrompt";
import ROLES from "../../../../roles";
import colors from "../../../../theme/foundations/colors";
import { arrayFlatReverse } from "../../../../utils/arrayFlatReverse";
import {
  FloorPlanAssetForMapWithFedTo,
  fedToAssetTree,
} from "../../../../utils/fedToAssetsTree";
import { setGenericMessage } from "../../../../utils/serverErrors";
import PageSpinner from "../../pageSpinner";
import AnnotationControl from "./annotationControl";
import Connections, { Connection, ConnectionsData } from "./connections";
import AssetLists from "./dragabbleList";
import ImageOverlay from "./imageOverlay";
import MarkerControl from "./markersControl";

interface AffectedArea {
  annotation: FeatureCollection;
  color: string;
  asset: AssetForMapFragmentFragment;
}

interface EditableConnection {
  fromAssetId: string;
  toAssetId: string;
}

interface PlanData {
  affectedArea: AffectedArea[];
  visibleFloorPlanAssets: FloorPlanAssetForMapFragmentFragment[];
  editableConnections: EditableConnection[];
}

interface PlanDataChanges {
  current: PlanData;
  undoAble: PlanData[];
  redoAble: PlanData[];
}

interface LeafletProps {
  floorPlan: FloorPlanForMapFragmentFragment;
  floorPlanAssets: FloorPlanAssetForMapFragmentFragment[];
}

const Leaflet: React.FC<LeafletProps> = ({ floorPlan, floorPlanAssets }) => {
  const {
    clusterAssets,
    quickDraw,
    affectedAreaConnections,
    showAssetStatus,
    assetColors,
    assetPartColors,
    assetIds,
    assetPartIds,
    planId,
  } = useReactiveVar(planEditorOptionsVar);
  const toast = useToast();
  const [isLoading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [planDataChanges, setPlanDataChanges] = useState<PlanDataChanges>({
    current: {
      visibleFloorPlanAssets: [],
      affectedArea: [],
      editableConnections: [],
    },
    undoAble: [],
    redoAble: [],
  });
  const [fedFromConnections, setFedFromConnections] = useState<
    ConnectionsData[]
  >([]);
  const [fedToConnections, setFedToConnections] = useState<ConnectionsData[]>(
    []
  );
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { width, height } = useWindowSize();
  const signedUrlData = useFloorPlanSignedUrlData(planId);
  const {
    isOpen: isPrinting,
    onOpen: startPrinting,
    onClose: stopPrinting,
  } = useDisclosure();

  const { visibleFloorPlanAssets, affectedArea } = planDataChanges.current;
  const canUndo = planDataChanges.undoAble.length > 0;
  const canRedo = planDataChanges.redoAble.length > 0;
  useEffect(() => {
    changePlanEditorOptions({ unsavedChanges: canUndo });
  }, [canUndo]);

  const selectedFloorPlanAssets = useMemo(
    () =>
      assetIds.length
        ? floorPlan.floorPlanAssets.filter((fpa) =>
            assetIds.includes(fpa.asset.id)
          )
        : [],
    [assetIds, floorPlan.floorPlanAssets]
  );
  const onlyOneAssetSelected = selectedFloorPlanAssets.length === 1;

  useEffect(() => {
    const affectedArea: AffectedArea[] = [];
    let visibleFloorPlanAssets = floorPlanAssets;

    selectedFloorPlanAssets.forEach((sfpa) => {
      let features = sfpa.mapAffectedArea?.features || [];
      if (assetPartIds.length) {
        features = features.filter(
          (feature: any) =>
            feature.properties?.assetPartId &&
            assetPartIds.includes(feature.properties.assetPartId)
        );
      }
      if (onlyOneAssetSelected && assetPartIds.length) {
        features = features.map((feature: any) =>
          feature.properties?.assetPartId &&
          assetPartColors[feature.properties.assetPartId]
            ? {
                ...feature,
                properties: {
                  ...feature.properties,
                  color: assetPartColors[feature.properties.assetPartId],
                },
              }
            : feature
        );
      }

      let color = "";
      if (
        onlyOneAssetSelected &&
        assetPartIds.length === 1 &&
        assetPartColors[assetPartIds[0]]
      ) {
        color = assetPartColors[assetPartIds[0]];
      }
      if (!color) {
        color = assetColors[sfpa.asset.id]
          ? assetColors[sfpa.asset.id]
          : colors.secondary[500];
      }

      affectedArea.push({
        annotation: {
          ...sfpa.mapAffectedArea,
          features,
        },
        asset: sfpa.asset,
        color,
      });
    });

    if (selectedFloorPlanAssets.length) {
      let fedFromFloorPlanAssets: FloorPlanAssetForMapFragmentFragment[] = [];
      let fedToFloorPlanAssets: FloorPlanAssetForMapFragmentFragment[] = [];

      if (onlyOneAssetSelected) {
        const sfpa = selectedFloorPlanAssets[0];
        const asset = sfpa.asset;

        const fedFromAssetIds = asset.assetAffectedByAssets.map(
          (afa) => afa.asset.id
        );
        fedFromFloorPlanAssets = fedFromAssetIds.length
          ? floorPlanAssets.filter((fpa) =>
              fedFromAssetIds.includes(fpa.asset.id)
            )
          : [];

        const fedToAssets = fedToAssetTree(floorPlanAssets, sfpa, assetPartIds);
        const fedToAssign = (fedTo: FloorPlanAssetForMapWithFedTo[]) => {
          fedTo.forEach((ftfpa) => {
            fedToFloorPlanAssets.push(ftfpa);
            if (ftfpa.fedToFloorPlanAssets.length) {
              fedToAssign(ftfpa.fedToFloorPlanAssets);
            }
          });
        };
        fedToAssign(fedToAssets.fedToFloorPlanAssets);
      }

      visibleFloorPlanAssets = uniqBy(
        [
          ...selectedFloorPlanAssets,
          ...fedFromFloorPlanAssets,
          ...fedToFloorPlanAssets,
        ],
        "id"
      );
    }

    setPlanDataChanges({
      current: {
        ...planDataChanges.current,
        affectedArea,
        visibleFloorPlanAssets,
      },
      undoAble: [],
      redoAble: [],
    });
  }, [
    assetColors,
    assetPartColors,
    assetPartIds,
    floorPlanAssets,
    onlyOneAssetSelected,
    selectedFloorPlanAssets,
  ]);

  useEffect(() => {
    if (!onlyOneAssetSelected) {
      setFedFromConnections([]);
      setFedToConnections([]);
      return;
    }

    const sfpa = visibleFloorPlanAssets.find(
      (vfpa) => vfpa.id === selectedFloorPlanAssets[0].id
    );
    if (!sfpa) return;
    const fedFromConnections: ConnectionsData[] = [];
    const fedToConnections: ConnectionsData[] = [];
    const asset = sfpa.asset;
    const assetCoordinates = sfpa.mapPosition?.geometry?.coordinates;

    if (assetCoordinates) {
      asset.assetAffectedByAssets.forEach((ffa) => {
        const fpa = visibleFloorPlanAssets.find(
          (vfpa) => vfpa.asset.id === ffa.asset.id
        );
        const isEditable = planDataChanges.current.editableConnections.some(
          (ec) => ec.fromAssetId === fpa?.asset?.id && ec.toAssetId === asset.id
        );
        if (!fpa) return;
        if (ffa?.pathPoints?.length) {
          fedFromConnections.push({
            coordinates: arrayFlatReverse([...ffa.pathPoints]),
            fromAssetId: fpa.asset.id,
            toAssetId: asset.id,
            editable: isEditable,
          });
        } else {
          const fpaCoordinates = fpa.mapPosition?.geometry?.coordinates;
          fpaCoordinates &&
            fedFromConnections.push({
              coordinates: [
                [fpaCoordinates[1], fpaCoordinates[0]],
                [assetCoordinates[1], assetCoordinates[0]],
              ],
              fromAssetId: fpa.asset.id,
              toAssetId: asset.id,
              editable: isEditable,
            });
        }
      });

      const fedToAssets = fedToAssetTree(
        visibleFloorPlanAssets,
        sfpa,
        assetPartIds
      );
      const fedToLinesAssign = (fedTo: FloorPlanAssetForMapWithFedTo) => {
        const coordinatesA = fedTo.mapPosition?.geometry?.coordinates;
        if (coordinatesA) {
          fedTo.fedToFloorPlanAssets.forEach((ftfpa) => {
            const isAddedToFromConnections = fedFromConnections.some(
              (fedFrom) =>
                fedFrom.toAssetId === ftfpa.asset.id &&
                fedFrom.fromAssetId === fedTo.asset.id
            );
            const isEditable = planDataChanges.current.editableConnections.some(
              (ec) =>
                ec.fromAssetId === fedTo?.asset?.id &&
                ec.toAssetId === ftfpa?.asset?.id
            );
            const pathPointsAsset = fedTo.asset.assetAffectedAssets.find(
              (affectedAsset) =>
                affectedAsset.affectedAsset.id === ftfpa.asset.id
            );
            if (pathPointsAsset?.pathPoints && !isAddedToFromConnections) {
              fedToConnections.push({
                coordinates: arrayFlatReverse(pathPointsAsset?.pathPoints),
                fromAssetId: fedTo.asset.id,
                toAssetId: ftfpa.asset.id,
                editable: isEditable,
              });
            } else if (!isAddedToFromConnections) {
              const coordinatesB = ftfpa.mapPosition?.geometry?.coordinates;
              coordinatesB &&
                fedToConnections.push({
                  coordinates: [
                    [coordinatesA[1], coordinatesA[0]],
                    [coordinatesB[1], coordinatesB[0]],
                  ],
                  fromAssetId: fedTo.asset.id,
                  toAssetId: ftfpa.asset.id,
                  editable: isEditable,
                });
            }
            if (ftfpa.fedToFloorPlanAssets.length) {
              fedToLinesAssign(ftfpa);
            }
          });
        }
      };
      fedToLinesAssign(fedToAssets);
    }

    setFedFromConnections(fedFromConnections);
    setFedToConnections(fedToConnections);
  }, [
    assetPartIds,
    floorPlanAssets,
    onlyOneAssetSelected,
    selectedFloorPlanAssets,
    visibleFloorPlanAssets,
    planDataChanges,
  ]);

  const handleAffectedAreaChange = React.useCallback(
    (annotation: FeatureCollection) => {
      if (
        !ROLES.assetsCreate.includes(currentCompanyRole.role) ||
        affectedArea.length !== 1
      )
        return;
      setPlanDataChanges((data) => ({
        redoAble: [],
        current: {
          ...data.current,
          affectedArea: [{ ...affectedArea[0], annotation }],
        },
        undoAble: [
          {
            affectedArea,
            visibleFloorPlanAssets,
            editableConnections: data.current.editableConnections,
          },
          ...data.undoAble,
        ],
      }));
    },
    [affectedArea, currentCompanyRole, visibleFloorPlanAssets]
  );

  const handleFloorPlanPositionChange = React.useCallback(
    (floorPlanAssetId: string, feature: Feature) => {
      if (!ROLES.assetsCreate.includes(currentCompanyRole.role)) return;
      const selectedFloorPlanAsset = visibleFloorPlanAssets.find(
        (fpa) => fpa.id === floorPlanAssetId
      );
      if (!selectedFloorPlanAsset) return;

      const newVisibleFloorPlanAssets = visibleFloorPlanAssets.map((fpa) => {
        if (fpa.id !== floorPlanAssetId) return fpa;
        return { ...fpa, mapPosition: feature };
      });

      setPlanDataChanges((data) => ({
        redoAble: [],
        current: {
          ...data.current,
          visibleFloorPlanAssets: newVisibleFloorPlanAssets,
        },
        undoAble: [
          {
            affectedArea,
            visibleFloorPlanAssets,
            editableConnections: data.current.editableConnections,
          },
          ...data.undoAble,
        ],
      }));
    },
    [currentCompanyRole, visibleFloorPlanAssets, affectedArea]
  );

  const handleFloorPlanAssetConnectionsChange = React.useCallback(
    (points: Connection, fromAssetId: string, toAssetId: string) => {
      if (!ROLES.assetsCreate.includes(currentCompanyRole.role)) return;
      console.log(planDataChanges.current.editableConnections);
      const isConnectionEdited =
        planDataChanges.current.editableConnections.some(
          (ec) => ec.fromAssetId === fromAssetId && ec.toAssetId === toAssetId
        );
      console.log(isConnectionEdited);
      let editableConnections = [
        ...planDataChanges.current.editableConnections,
      ];
      if (!isConnectionEdited) {
        editableConnections.push({ fromAssetId, toAssetId });
      }
      const fromAsset = visibleFloorPlanAssets.find(
        (fpa) => fpa.asset.id === fromAssetId
      );
      const toAsset = visibleFloorPlanAssets.find(
        (fpa) => fpa.asset.id === toAssetId
      );
      if (!fromAsset || !toAsset) return;
      const newAffectedAssetsArray = fromAsset?.asset?.assetAffectedAssets.map(
        (affectedAsset) => {
          if (affectedAsset.affectedAsset.id !== toAssetId)
            return affectedAsset;
          return Object.assign({}, affectedAsset, {
            pathPoints: points,
            assetId: fromAssetId,
          });
        }
      );
      const newAffectedAssetsByArray =
        toAsset?.asset?.assetAffectedByAssets.map((affectedByAsset) => {
          if (affectedByAsset.asset.id !== fromAssetId) return affectedByAsset;
          return Object.assign({}, affectedByAsset, {
            pathPoints: points,
            assetId: toAssetId,
          });
        });

      const newVisibleFloorPlanAssets = visibleFloorPlanAssets.map((fpa) => {
        if (fpa.asset.id === fromAsset.asset.id) {
          return {
            ...fpa,
            asset: {
              ...fpa.asset,
              assetAffectedAssets: newAffectedAssetsArray,
            },
          };
        }
        if (fpa.asset.id === toAsset.asset.id) {
          return {
            ...fpa,
            asset: {
              ...fpa.asset,
              assetAffectedByAssets: newAffectedAssetsByArray,
            },
          };
        }

        return fpa;
      });

      setPlanDataChanges((data) => ({
        redoAble: [],
        current: {
          ...data.current,
          visibleFloorPlanAssets: newVisibleFloorPlanAssets,
          editableConnections,
        },
        undoAble: [
          {
            affectedArea,
            visibleFloorPlanAssets,
            editableConnections: data.current.editableConnections,
          },
          ...data.undoAble,
        ],
      }));
    },
    [currentCompanyRole, visibleFloorPlanAssets, affectedArea, planDataChanges]
  );

  const [floorPlanAssetUpdateManyMutation] =
    useFloorPlanAssetUpdateManyMutation();
  const [assetAffectedAssetUpdateManyMutation] =
    useAssetAffectedAssetUpdateManyMutation();
  const handleFloorPlanAssetUpdate = React.useCallback(
    async (
      floorPlanAssetData: FloorPlanAssetUpdateManyInput[],
      assetAffectedAssetData: AssetAffectedAssetUpdateInput[]
    ) => {
      setLoading(true);
      try {
        let errors = null;
        let serverData = null;
        if (floorPlanAssetData?.length) {
          const {
            data: floorPlanAssetServerData,
            errors: floorPlanAssetErrors,
          } = await floorPlanAssetUpdateManyMutation({
            variables: { data: floorPlanAssetData },
          });
          errors = floorPlanAssetErrors;
          serverData = floorPlanAssetServerData;
        }
        if (assetAffectedAssetData?.length) {
          const {
            data: assetAffectedAssetServerData,
            errors: assetAffectedAssetErrors,
          } = await assetAffectedAssetUpdateManyMutation({
            variables: { data: assetAffectedAssetData },
          });
          errors = assetAffectedAssetErrors || errors;
          serverData = assetAffectedAssetServerData || serverData;
        }
        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
        } else if (serverData) {
          toast({
            description: GENERIC_SAVED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          return;
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      floorPlanAssetUpdateManyMutation,
      assetAffectedAssetUpdateManyMutation,
      toast,
    ]
  );

  const onPlanDataUndo = React.useCallback(() => {
    if (!canUndo) return;

    setPlanDataChanges((data) => ({
      redoAble: [data.current, ...data.redoAble],
      current: data.undoAble[0],
      undoAble: data.undoAble.slice(1),
    }));
  }, [canUndo]);

  const onPlanDataRedo = React.useCallback(() => {
    if (!canRedo) return;

    setPlanDataChanges((data) => ({
      undoAble: [data.current, ...data.undoAble],
      current: data.redoAble[0],
      redoAble: data.redoAble.slice(1),
    }));
  }, [canRedo]);

  const onPlanDataSave = React.useCallback(() => {
    // Cancel opened draw tools
    document
      .querySelectorAll(
        ".leaflet-draw-actions a[title='Cancel editing, discards all changes'], .leaflet-draw-actions a[title='Cancel drawing']"
      )
      .forEach((cancel) => {
        (cancel as HTMLElement).click();
      });

    const updateData: FloorPlanAssetUpdateManyInput[] = [];
    const updateAffectedAssetsData: AssetAffectedAssetUpdateInput[] = [];

    visibleFloorPlanAssets.forEach((vfpa) => {
      const fpa = floorPlanAssets.find(({ id }) => vfpa.id === id);
      if (!fpa) return;
      const mapPositionChanged = !isEqual(fpa.mapPosition, vfpa.mapPosition);
      const selectedFloorPlanAsset = selectedFloorPlanAssets.find(
        ({ id }) => id === vfpa.id
      );

      if (onlyOneAssetSelected && selectedFloorPlanAsset) {
        let mapAffectedArea: FeatureCollection | null = null;

        if (
          assetPartIds.length === 1 &&
          selectedFloorPlanAsset.mapAffectedArea
        ) {
          const firstAssetPartId = assetPartIds[0];
          const oldFeatures = selectedFloorPlanAsset.mapAffectedArea.features
            ? selectedFloorPlanAsset.mapAffectedArea.features.filter(
                (feature: any) =>
                  !feature.properties ||
                  !feature.properties.assetPartId ||
                  feature.properties.assetPartId !== firstAssetPartId
              )
            : [];
          const newFeatures = affectedArea[0].annotation.features.map(
            (feature) => ({
              ...feature,
              properties: {
                ...(feature.properties || {}),
                assetPartId: firstAssetPartId,
                color: undefined,
              },
            })
          );
          mapAffectedArea = {
            type:
              selectedFloorPlanAsset.mapAffectedArea.type ||
              "FeatureCollection",
            features: [...oldFeatures, ...newFeatures],
          };
        } else if (affectedArea[0].annotation.features.length) {
          mapAffectedArea = {
            ...affectedArea[0].annotation,
            features: affectedArea[0].annotation.features.map((feature) => ({
              ...feature,
              properties: {
                ...(feature.properties || {}),
                color: undefined,
              },
            })),
          };
        }

        updateData.push({
          id: vfpa.id,
          data: {
            mapAffectedArea,
            mapPosition: mapPositionChanged ? vfpa.mapPosition : undefined,
          },
        });
      } else if (mapPositionChanged) {
        updateData.push({
          id: vfpa.id,
          data: { mapPosition: vfpa.mapPosition },
        });
      }

      const assetAffectedAssetChanged = vfpa.asset.assetAffectedAssets.filter(
        (affectedAsset) => {
          const initialAffectedAsset = fpa.asset.assetAffectedAssets.find(
            (initialAffectedAsset) =>
              initialAffectedAsset.id === affectedAsset.id
          );
          return (
            !!initialAffectedAsset &&
            !isEqual(affectedAsset.pathPoints, initialAffectedAsset.pathPoints)
          );
        }
      );
      if (assetAffectedAssetChanged?.length) {
        assetAffectedAssetChanged.forEach((assetAffectedAsset) => {
          const alreadyAddedToUpdatedData = updateAffectedAssetsData
            .map((a) => a.id)
            .includes(assetAffectedAsset.id);
          !alreadyAddedToUpdatedData &&
            updateAffectedAssetsData.push(assetAffectedAsset);
        });
      }
    });

    const preparedUpdateAffectedAssetsData = updateAffectedAssetsData.map(
      (uaaa: any) => {
        return {
          id: uaaa.id,
          assetId: uaaa.assetId,
          affectedAssetId: uaaa.affectedAsset.id,
          assetPartId: uaaa.assetPartId,
          pathPoints: uaaa.pathPoints.flat(1),
        };
      }
    );
    handleFloorPlanAssetUpdate(
      updateData,
      preparedUpdateAffectedAssetsData
    ).then(() => {
      setPlanDataChanges((data) => ({
        ...data,
        current: {
          ...data.current,
          editableConnections: [],
        },
      }));
    });
  }, [
    affectedArea,
    assetPartIds,
    floorPlanAssets,
    handleFloorPlanAssetUpdate,
    onlyOneAssetSelected,
    selectedFloorPlanAssets,
    visibleFloorPlanAssets,
  ]);

  usePrompt(PLAN_EDITOR_PROMPT, canUndo);

  if (!signedUrlData) return <PageSpinner />;
  // When icons are clustered, we wont allow editing.
  // This is because clustering has lot of bugs related to updating icons on the fly
  const canAddAsset = ROLES.assetsCreate.includes(currentCompanyRole.role);

  return (
    <>
      {canAddAsset && <AssetLists />}
      <Flex
        flexDirection="column"
        position="relative"
        zIndex="0"
        flexGrow={1}
        id="map_container"
      >
        <MapContainer
          style={{ flexGrow: 1 }}
          crs={CRS.Simple}
          minZoom={-4}
          maxZoom={2.5}
          zoomDelta={0.5}
          zoomSnap={0.25}
          attributionControl={false}
          width={width}
          height={height}
          editable={true}
        >
          <ImageOverlay
            floorPlanName={signedUrlData.name}
            imageUrl={signedUrlData.signedUrl}
            startPrinting={startPrinting}
            stopPrinting={stopPrinting}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          />
          {isOpen && (
            <>
              {affectedArea.map((aa) => (
                <AnnotationControl
                  key={aa.asset.id}
                  quickDraw={quickDraw}
                  annotation={aa.annotation}
                  handleAnnotationChange={handleAffectedAreaChange}
                  canDraw={
                    canAddAsset &&
                    onlyOneAssetSelected &&
                    assetPartIds.length < 2
                  }
                  color={aa.color}
                  asset={aa.asset}
                />
              ))}
              {(!!visibleFloorPlanAssets || canAddAsset) && (
                <MarkerControl
                  floorPlan={floorPlan}
                  floorPlanAssets={visibleFloorPlanAssets}
                  clusterAssets={isPrinting ? false : clusterAssets}
                  showAssetStatus={showAssetStatus}
                  handleFloorPlanAssetMove={
                    canAddAsset ? handleFloorPlanPositionChange : undefined
                  }
                  canAddAsset={canAddAsset}
                  unsavedChanges={canUndo}
                  setLoading={setLoading}
                />
              )}
              <Connections
                assetCoordinates={
                  affectedAreaConnections && onlyOneAssetSelected
                    ? selectedFloorPlanAssets[0].mapPosition?.geometry
                        ?.coordinates
                    : undefined
                }
                geoJSON={
                  affectedAreaConnections && affectedArea.length === 1
                    ? affectedArea[0].annotation
                    : undefined
                }
                fedFromConnections={fedFromConnections}
                fedToConnections={fedToConnections}
                handleFloorPlanAssetConnectionsChange={
                  handleFloorPlanAssetConnectionsChange
                }
                editable={canUndo}
              />
            </>
          )}
        </MapContainer>
      </Flex>

      {(canUndo || canRedo) && (
        <Box
          position="absolute"
          left="50%"
          top="90px"
          transform="translateX(-50%)"
          zIndex="2"
          display="flex"
        >
          <Tooltip label="Undo Changes" hasArrow placement="bottom">
            <IconButton
              onClick={onPlanDataUndo}
              size="xs"
              colorScheme="gray"
              boxShadow="lg"
              aria-label="Undo changes"
              isDisabled={!canUndo}
            >
              <Box paddingX="3">
                <FontAwesomeIcon icon={faUndo} />
              </Box>
            </IconButton>
          </Tooltip>
          <Button
            onClick={onPlanDataSave}
            size="xs"
            colorScheme="primary"
            width="150px"
            boxShadow="lg"
            isDisabled={!canUndo}
          >
            Save Changes
          </Button>
          <Tooltip label="Redo Changes" hasArrow placement="bottom">
            <IconButton
              onClick={onPlanDataRedo}
              size="xs"
              colorScheme="gray"
              boxShadow="lg"
              aria-label="Redo changes"
              isDisabled={!canRedo}
            >
              <Box paddingX="3">
                <FontAwesomeIcon icon={faRedo} />
              </Box>
            </IconButton>
          </Tooltip>
        </Box>
      )}

      {isLoading && (
        <Center
          backgroundColor="rgba(0, 0, 0, .25)"
          position="absolute"
          left="0"
          top="0"
          width="full"
          height="full"
          zIndex="2"
        >
          <PageSpinner />
        </Center>
      )}
    </>
  );
};

export default Leaflet;

gql`
  mutation FloorPlanAssetUpdateMany($data: [FloorPlanAssetUpdateManyInput!]!) {
    floorPlanAssetUpdateMany(data: $data) {
      ...FloorPlanAssetFragment
    }
  }
`;
