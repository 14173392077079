import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { usePlansQuery } from "../../../graphql/graphql";
import {
  calculateStripePriceForInterval,
  formatPrice,
} from "../../../utils/price";

interface SubscriptionPriceProps {
  name: string;
  amount: number;
  interval: string;
}

const SubscriptionPrice: React.FC<SubscriptionPriceProps> = ({
  name,
  amount,
  interval,
}) => {
  const { data: plansData } = usePlansQuery();
  const monthlyPlanAmount = React.useMemo(() => {
    if (!plansData) return null;
    return plansData.plans.find(
      (plan) => plan.productName === name && plan.interval === "month"
    )?.amount;
  }, [plansData, name]);

  return (
    <Box>
      <Flex alignItems="center" fontStyle="bold" justifyContent="center">
        <Box as="span" fontSize="2xl" lineHeight="2.5rem">
          $
        </Box>
        <Box fontSize="4xl" marginLeft="1" lineHeight="2.5rem">
          {calculateStripePriceForInterval(amount, interval)}
        </Box>
      </Flex>
      <Box
        fontSize="sm"
        textTransform="uppercase"
        lineHeight="5"
        fontWeight="semibold"
        marginTop="1"
      >
        <Box>per month</Box>
      </Box>
      {interval === "year" && !!monthlyPlanAmount && (
        <Box fontSize=".7rem" color="black" height="5" marginTop="2">
          billed at
          <Box marginX="1" display="inline-flex">
            {formatPrice(amount / 100, false)}/yr
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionPrice;
