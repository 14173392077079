import "react-phone-number-input/style.css";

import { Input, InputProps } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { Props as ReactPhoneInputProps } from "react-phone-number-input";
import ReactPhoneInput from "react-phone-number-input/input";

interface PhoneInputProps extends Partial<ReactPhoneInputProps<InputProps>> {
  name: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ name, ...props }) => {
  const { setFieldValue, getFieldProps } = useFormikContext<any>();

  return (
    <ReactPhoneInput
      defaultCountry="US"
      inputComponent={Input}
      {...getFieldProps(name)}
      {...props}
      onChange={(value: string) => setFieldValue(name, value || "")}
    />
  );
};

export default PhoneInput;
