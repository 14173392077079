import { Icon, IconProps } from "@chakra-ui/react";

const SecurityControlPanel = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M99.156 25.719H83.531a2.93 2.93 0 0 0 0 5.86h15.625a2.93 2.93 0 0 0 0-5.86ZM97.203 43.297a2.93 2.93 0 1 0 0-5.86 2.93 2.93 0 0 0 0 5.86ZM97.203 55.016a2.93 2.93 0 1 0 0-5.86 2.93 2.93 0 0 0 0 5.86ZM85.484 43.297a2.93 2.93 0 1 0 0-5.86 2.93 2.93 0 0 0 0 5.86ZM85.484 55.016a2.93 2.93 0 1 0 0-5.86 2.93 2.93 0 0 0 0 5.86Z"
      fill="currentColor"
    />
    <path
      d="M103.063 14H24.937c-5.923 0-10.742 4.819-10.742 10.742V79.82c0 5.924 4.82 10.743 10.742 10.743h2.947c.081.588.202 1.177.37 1.761l3.966 13.885c1.31 4.587 5.558 7.791 10.33 7.791H83.53c5.923 0 10.742-4.819 10.742-10.742V90.563h8.79c5.923 0 10.742-4.82 10.742-10.743V24.742c0-5.923-4.819-10.742-10.742-10.742Zm-14.649 89.258a4.889 4.889 0 0 1-4.883 4.883H42.55a4.904 4.904 0 0 1-4.695-3.542l-3.967-13.885a6.84 6.84 0 0 1 3.516-7.992l4.136-2.067v10.884a2.93 2.93 0 0 0 5.86 0V46.227a2.933 2.933 0 0 1 2.93-2.93 2.933 2.933 0 0 1 2.929 2.93v35.547a2.93 2.93 0 0 0 5.86 0v-9.766a2.933 2.933 0 0 1 2.929-2.93 2.933 2.933 0 0 1 2.93 2.93v9.766a2.93 2.93 0 0 0 5.859 0v-9.766a2.933 2.933 0 0 1 2.93-2.93 2.933 2.933 0 0 1 2.93 2.93v9.766a2.93 2.93 0 0 0 5.859 0v-9.766a2.933 2.933 0 0 1 2.93-2.93 2.933 2.933 0 0 1 2.93 2.93v31.25Zm-38.086-65.82c-4.846 0-8.789 3.942-8.789 8.789v2.93h-9.766V31.577h37.11v17.578h-9.766v-2.93c0-4.846-3.943-8.788-8.789-8.788Zm57.617 42.382a4.888 4.888 0 0 1-4.882 4.883h-8.79V72.008c0-4.846-3.942-8.79-8.789-8.79-2.249 0-4.303.851-5.859 2.246a8.752 8.752 0 0 0-5.86-2.245c-2.249 0-4.302.85-5.859 2.245a8.753 8.753 0 0 0-5.86-2.245 8.748 8.748 0 0 0-2.929.504v-8.707h12.696a2.93 2.93 0 0 0 2.93-2.93V28.648a2.93 2.93 0 0 0-2.93-2.93h-42.97a2.93 2.93 0 0 0-2.929 2.93v23.438a2.93 2.93 0 0 0 2.93 2.93h12.695v19.087l-6.756 3.379a12.681 12.681 0 0 0-6.324 7.221h-3.521a4.888 4.888 0 0 1-4.883-4.883V24.742a4.888 4.888 0 0 1 4.883-4.883h78.125a4.888 4.888 0 0 1 4.882 4.883V79.82Z"
      fill="currentColor"
    />
  </Icon>
);

export default SecurityControlPanel;
