import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import CompanyTeamsCreateForm from "./form";

interface CompanyTeamsCreatePageProps {
  onClose?: () => void;
  goBack?: boolean;
}

const CompanyTeamsCreatePage: React.FC<CompanyTeamsCreatePageProps> = ({
  onClose: handleClose,
  goBack,
}) => {
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onDrawerClose = () => {
    if (handleClose) handleClose();
    onClose();
    if (goBack) {
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
  };

  return (
    <>
      <Helmet>
        <title>Send invite</title>
      </Helmet>
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Send invite</DrawerHeader>
            <DrawerBody>
              <CompanyTeamsCreateForm
                afterCreate={onDrawerClose}
                onClose={onDrawerClose}
              />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default CompanyTeamsCreatePage;
