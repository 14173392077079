import { QueryHookOptions } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  LocationIndexFragmentFragment,
  LocationIndexQuery,
  LocationIndexQueryVariables,
  SortBy,
  SortOrder,
  useLocationConfigQuery,
  useLocationIndexQuery,
} from "../graphql/graphql";
import orderBy from "../utils/sort";

const useLocations: (
  baseOptions?: QueryHookOptions<
    LocationIndexQuery,
    LocationIndexQueryVariables
  >,
  parentId?: string
) => [LocationWithChildrenCheck[] | undefined, () => void, boolean] = (
  baseOptions,
  parentId
) => {
  const { data, refetch, loading } = useLocationIndexQuery({
    ...baseOptions,
    variables: { parentId: undefined },
  });
  const [locations, setLocations] = useState<LocationWithChildrenCheck[]>();
  const { data: locationConfigData } = useLocationConfigQuery();

  useEffect(() => {
    if (!data) return;
    const locationsLength = data.locations.length;

    const findSubLocationAvailability = (
      location: LocationIndexFragmentFragment
    ): LocationWithChildrenCheck => {
      const childrenAvailable = data.locations.some(
        (l) => l.parentId === location.id
      );
      return { ...location, childrenAvailable };
    };

    const parsedLocations: LocationWithChildrenCheck[] = [];
    for (let i = 0; i < locationsLength; i++) {
      const location = data.locations[i];
      if (parentId ? location.parentId === parentId : !location.parentId) {
        parsedLocations.push(findSubLocationAvailability(location));
      }
    }

    setLocations(
      orderBy(
        parsedLocations,
        locationConfigData?.locationConfig.sortBy || SortBy.Name,
        locationConfigData?.locationConfig.sortOrder || SortOrder.Asc
      )
    );
  }, [
    data,
    locationConfigData?.locationConfig.sortBy,
    locationConfigData?.locationConfig.sortOrder,
    parentId,
  ]);

  return [locations, refetch, loading];
};

export default useLocations;

export interface LocationWithChildrenCheck
  extends LocationIndexFragmentFragment {
  childrenAvailable: boolean;
}
