import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import React from "react";

import Presenter from "./presenter";

interface AssetDrawerProps {
  id: string;
  currentFlPlanId?: string;
  isOpen: boolean;
  onClose: () => void;
}

const AssetDrawer: React.FC<AssetDrawerProps> = ({
  id,
  currentFlPlanId,
  isOpen,
  onClose,
}) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay zIndex={1600}>
        <DrawerContent>
          <DrawerCloseButton />
          <Presenter assetId={id} currentFloorPlanId={currentFlPlanId} />
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetDrawer;
