export const workOrdersAcceptedFileTypes = [
  "image/jpeg",
  "image/png",
  "application/pdf",
];
export const workOrdersFileMaxSize = 10 * 1024 * 1024;

export const getFileSizeString = (size: number, decimal = 1) => {
  const dimensionSize = 1024;
  let sizeString = "";
  if (size < dimensionSize) {
    sizeString = `${size} B`;
  }
  if (dimensionSize < size && size < dimensionSize * dimensionSize) {
    sizeString = `${(size / dimensionSize).toFixed(decimal)} KB`;
  }
  if (
    dimensionSize * dimensionSize < size &&
    size < dimensionSize * dimensionSize * dimensionSize
  ) {
    sizeString = `${(size / (dimensionSize * dimensionSize)).toFixed(
      decimal
    )} MB`;
  }
  if (
    dimensionSize * dimensionSize * dimensionSize < size &&
    size < dimensionSize * dimensionSize * dimensionSize * dimensionSize
  ) {
    sizeString = `${(
      size /
      (dimensionSize * dimensionSize * dimensionSize)
    ).toFixed(decimal)} GB`;
  }
  return sizeString;
};
