import React from "react";

import { useFolderQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface FolderDeleteProps {
  id: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const FolderDelete: React.FC<FolderDeleteProps> = ({
  id,
  isOpen,
  onOpen,
  onClose,
}) => {
  const { data } = useFolderQuery({
    variables: { id },
  });
  return id && data ? (
    <Presenter
      folder={data.folder}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    />
  ) : null;
};

export default FolderDelete;
