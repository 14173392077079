import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useAssetTypeQuery } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface AssetTypeShowPageProps {}

const AssetTypeShowPage: React.FC<AssetTypeShowPageProps> = () => {
  const { assetTypeId } = useParams();
  const { data } = useAssetTypeQuery({
    variables: { id: assetTypeId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onDrawerClose = () => {
    if (!data) return;
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetCategories"));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <DrawerOverlay>
        <DrawerContent>
          {data ? (
            <Presenter assetType={data.assetType} />
          ) : (
            <DrawerBody>
              <PageSpinner />
            </DrawerBody>
          )}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetTypeShowPage;
