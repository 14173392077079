import { Icon, IconProps } from "@chakra-ui/react";

const FanCoilUnit = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M29 49.26h15.88A23.999 23.999 0 0 1 51 43.59v-4.83h-4v6.5H35v-6.5h-4v6.5h-2a5.68 5.68 0 0 1-6-5.74 6.14 6.14 0 0 1 6-6.26h70A10.14 10.14 0 0 0 109 23h-4a6.14 6.14 0 0 1-6 6.26H29a10.14 10.14 0 0 0-10 10.26 9.662 9.662 0 0 0 10 9.74ZM29 81.26h18.58a23.782 23.782 0 0 1-3.42-4H35v-7.5h-4v7.5h-2a5.68 5.68 0 0 1-6-5.74 6.14 6.14 0 0 1 6-6.26h11v-1.5c-.002-.835.041-1.67.13-2.5H35v-7.5h-4v7.5h-2a10.14 10.14 0 0 0-10 10.26 9.661 9.661 0 0 0 10 9.74Z"
      fill="currentColor"
    />
    <path
      d="M63 39.76h1c1.003.002 2.005.066 3 .19v-1.19h-4v1ZM99 77.26v-7.5h-4v7.5H83.84a23.782 23.782 0 0 1-3.42 4H99a6.14 6.14 0 0 1 6 6.26 5.68 5.68 0 0 1-6 5.74v-7.5h-4v7.5H83v-7.5h-4v7.5H67v-5.69c-.995.124-1.997.188-3 .19h-1v5.5H51v-7.5h-4v7.5H35v-7.5h-4v7.5h-2a10.14 10.14 0 0 0-10 10.26h4a6.14 6.14 0 0 1 6-6.26h70a9.66 9.66 0 0 0 10-9.74 10.14 10.14 0 0 0-10-10.26ZM99 45.26v-6.5h-4v6.5H83v-6.5h-4v6.27a24.249 24.249 0 0 1 4.12 4.23H99a6.14 6.14 0 0 1 6 6.26 5.68 5.68 0 0 1-6 5.74v-7.5h-4v7.5h-7.13c.089.83.132 1.665.13 2.5v1.5h11a9.66 9.66 0 0 0 10-9.74 10.14 10.14 0 0 0-10-10.26Z"
      fill="currentColor"
    />
    <path
      d="M69.74 54.03a1.601 1.601 0 0 0 .71-2.27 8.18 8.18 0 0 0-14.64 7.18 8.14 8.14 0 0 0 2.69 3.52 6.39 6.39 0 0 0-.91 5.15 7.001 7.001 0 0 1-7.23-.55 1.619 1.619 0 0 0-2.33.51 8.18 8.18 0 1 0 15.26 4.84 6.49 6.49 0 0 0 4.82-1.65 6.999 6.999 0 0 1 3.19 6.59 1.608 1.608 0 0 0 1.61 1.75A8.18 8.18 0 0 0 74 62.82a8.119 8.119 0 0 0-4.39.57 6.441 6.441 0 0 0-4-3.46 7 7 0 0 1 4.13-5.9Zm-8.17-2.74a4.94 4.94 0 0 1 5.09.85 10.189 10.189 0 0 0-4.21 7.62 6.282 6.282 0 0 0-1.44.5 5 5 0 0 1 .56-9v.03Zm-2.51 23.39a5 5 0 0 1-8.79-4 10.171 10.171 0 0 0 8.86-.26c.305.318.64.606 1 .86a5 5 0 0 1-1.06 3.41l-.01-.01Zm4.74-5.44a3.2 3.2 0 1 1 0-6.4 3.2 3.2 0 0 1 0 6.4Zm14 2.4c-.135.899-.725 1.656-1.3 2.36-.575.704-1.116 1.328-1.97 1.64a10.17 10.17 0 0 0-4.62-7.53c.154-.45.258-.917.31-1.39a4.93 4.93 0 0 1 3.37-.72 5 5 0 0 1 4.16 5.64h.05Z"
      fill="currentColor"
    />
    <path
      d="M61.57 51.29a4.94 4.94 0 0 1 5.09.85 10.192 10.192 0 0 0-4.21 7.62 6.294 6.294 0 0 0-1.44.5 5 5 0 0 1 .56-9v.03ZM59.5 74.5c-.7.849-1.928 1.752-3 2s-2.367.005-3.368-.45a5 5 0 0 1-2.862-5.37 10.168 10.168 0 0 0 8.86-.26c.305.318.64.606 1 .86.073 1.226.132 2.257-.63 3.22ZM78 72c-.135.899-.76 1.385-1.336 2.089-.575.704-1.31 1.6-2.164 1.91-.154-1.52-.67-3.348-1.47-4.652a10.17 10.17 0 0 0-3.17-3.237c.154-.45.258-.917.31-1.39 1-.638 2.157-1.394 3.33-1.22 1.303.195 2.712.944 3.5 2 .788 1.056 1.183 3.195 1 4.5Z"
      fill="currentColor"
    />
  </Icon>
);

export default FanCoilUnit;
