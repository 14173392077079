import { StripeCouponFragmentFragment } from "../graphql/graphql";
import { formatPrice } from "./price";

const TOKEN = "coupon";

export const setCoupon = (code: string): boolean => {
  if (!code) return false;
  sessionStorage.setItem(TOKEN, code);
  return true;
};

export const getCoupon = () => sessionStorage.getItem(TOKEN);

export const removeCoupon = () => sessionStorage.removeItem(TOKEN);

const discountedSavingAmount = (
  amount: number,
  stripeCoupon: StripeCouponFragmentFragment
): number => {
  if (!stripeCoupon) return 0;

  let discountValue = amount;
  if (stripeCoupon.amount_off) discountValue = amount - stripeCoupon.amount_off;
  if (stripeCoupon.percent_off)
    discountValue = amount - (amount * stripeCoupon.percent_off) / 100;

  return discountValue > 0 ? discountValue : 0;
};

export const discountedAmount = (
  amount: number,
  stripeCoupon: StripeCouponFragmentFragment
): string => {
  if (!stripeCoupon) return `${amount}`;
  let discountValue = discountedSavingAmount(amount, stripeCoupon);
  return discountValue > 0 ? formatPrice(discountValue) : "0";
};

export const discountedSaving = (
  amount: number,
  stripeCoupon: StripeCouponFragmentFragment
): string => {
  if (!stripeCoupon) return "0";
  let discountValue = discountedSavingAmount(amount, stripeCoupon);
  return formatPrice(amount - discountValue);
};
