import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const LogoIconDarkBg = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    height="17px"
    width="19px"
    viewBox="0 0 17 19"
    {...props}
  >
    <path
      d="M0.604499 5.78434C0.604499 5.59788 0.628676 5.41142 0.701208 5.24827L7.61586 9.094C8.317 9.49022 9.16319 9.49022 9.86433 9.094L16.8032 5.22496C16.8757 5.38812 16.8999 5.57458 16.8999 5.76103V7.69555L9.5742 11.7977C9.06649 12.0774 8.4137 12.0774 7.90599 11.7977L0.604499 7.71886V5.78434ZM1.42652 4.3859L7.90599 0.773244C8.4137 0.493554 9.06649 0.493554 9.5742 0.773244L16.0537 4.3859C16.2229 4.47913 16.368 4.59566 16.4889 4.73551L9.5742 8.58124C9.06649 8.86092 8.4137 8.86092 7.90599 8.58124L0.991333 4.73551C1.11222 4.59566 1.25728 4.47913 1.42652 4.3859ZM13.4425 15.8298L9.5742 17.9974C9.06649 18.2771 8.4137 18.2771 7.90599 17.9974L1.42652 14.3848C0.918802 14.1051 0.580322 13.569 0.580322 12.9863V11.5646L7.59168 15.4802C8.29282 15.8765 9.16319 15.8765 9.84015 15.4802L13.4184 13.4991V15.8298H13.4425ZM13.5876 12.7533L9.5742 14.9908C9.06649 15.2705 8.4137 15.2705 7.90599 14.9908L0.604499 10.912V8.37147L7.61586 12.2871C8.317 12.6833 9.16319 12.6833 9.86433 12.2871L16.8757 8.37147V10.912L13.5876 12.7533Z"
      fill="white"
    />
  </Icon>
);

export default LogoIconDarkBg;
