import {
  Box,
  Button,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React from "react";

import { MaintenanceStatusType } from "../../../graphql/graphql";

interface LocationsTableProps {
  assets: { maintenanceStatus: MaintenanceStatusType }[];
  mini?: boolean;
  styleProps?: object;
}

const AssetsStatus: React.FC<LocationsTableProps> = ({
  assets,
  mini = false,
  styleProps,
}) => {
  const totalCount = assets.length;
  let assetsCurrentCount = 0,
    assetsDueIn30Count = 0,
    assetsPastDueCount = 0;
  for (let i = 0; i < totalCount; i++) {
    switch (assets[i].maintenanceStatus) {
      case MaintenanceStatusType.DueIn_30:
        assetsDueIn30Count++;
        break;
      case MaintenanceStatusType.PastDue:
        assetsPastDueCount++;
        break;
      default:
        assetsCurrentCount++;
    }
  }

  return totalCount ? (
    <Popover
      trigger="hover"
      placement={mini ? "auto" : "right"}
      isLazy
      returnFocusOnClose
      autoFocus
    >
      <PopoverTrigger>
        {mini ? (
          <Button
            variant="unstyled"
            width="full"
            size="sm"
            paddingY="0"
            height="auto"
            minWidth="none"
          >
            <Box
              width="3"
              height="3"
              borderRadius="full"
              backgroundColor={
                assetsPastDueCount
                  ? "red.500"
                  : assetsDueIn30Count
                  ? "orange.500"
                  : "green.500"
              }
            />
          </Button>
        ) : (
          <Button
            variant="unstyled"
            size="sm"
            p="0"
            height="auto"
            minWidth="none"
            width="3"
            position="absolute"
            top="0"
            left="0"
            bottom="0"
            flexDir="column"
            alignItems="flex-start"
            {...styleProps}
          >
            <VStack boxSize="full" spacing="0">
              <Box
                width="full"
                height={`${(assetsPastDueCount / totalCount) * 100}%`}
                backgroundColor="red.500"
              />
              <Box
                width="full"
                height={`${(assetsDueIn30Count / totalCount) * 100}%`}
                backgroundColor="orange.500"
              />
              <Box
                width="full"
                height={`${(assetsCurrentCount / totalCount) * 100}%`}
                backgroundColor="green.500"
              />
            </VStack>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          {assetsPastDueCount > 0 || assetsDueIn30Count > 0 ? (
            <List spacing={2} textAlign="left" fontSize="sm">
              {assetsPastDueCount > 0 && (
                <ListItem>
                  <Flex align="center">
                    <Box
                      backgroundColor="red.500"
                      marginRight="2"
                      width="2"
                      height="5"
                    />
                    {pluralize("asset", assetsPastDueCount, true)} past due!
                  </Flex>
                </ListItem>
              )}
              {assetsDueIn30Count > 0 && (
                <ListItem>
                  <Flex align="center">
                    <Box
                      backgroundColor="orange.500"
                      marginRight="2"
                      width="2"
                      height="5"
                    />
                    {pluralize("asset", assetsDueIn30Count, true)} due soon
                  </Flex>
                </ListItem>
              )}
              {assetsCurrentCount > 0 && (
                <ListItem>
                  <Flex align="center">
                    <Box
                      backgroundColor="green.500"
                      marginRight="2"
                      width="2"
                      height="5"
                    />
                    {pluralize("asset", assetsCurrentCount, true)} looking good
                  </Flex>
                </ListItem>
              )}
            </List>
          ) : (
            <Box fontSize="xs">
              Good job! All your assets at this location are in good shape.
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : null;
};

export default AssetsStatus;
