import Alert from "./alert";
import Button from "./button";
import Checkbox from "./checkbox";
import CloseButton from "./closeButton";
import Drawer from "./drawer";
import FormError from "./formError";
import Heading from "./heading";
import Input from "./input";
import Menu from "./menu";
import Modal from "./modal";
import NumberInput from "./numberInput";
import Popover from "./popover";
import Radio from "./radio";
import Select from "./select";
import Switch from "./switch";
import Tabs from "./tabs";
import Textarea from "./textarea";
import Tooltip from "./tooltip";

const Components = {
  Alert,
  Button,
  Checkbox,
  CloseButton,
  Drawer,
  Heading,
  Input,
  Menu,
  NumberInput,
  Popover,
  Select,
  Switch,
  Tabs,
  Textarea,
  Modal,
  FormError,
  Radio,
  Tooltip,
};

export default Components;
