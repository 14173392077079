import { Box, Link } from "@chakra-ui/react";
import React from "react";

import LogoSquare from "../../icons/logoSquare";
import LogoSquareDarkBg from "../../icons/logoSquareDarkBg";
import AuthHeader from "./header";

interface AuthLayoutProps {
  showProMessage?: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  showProMessage = false,
  children,
}) => {
  return (
    <Box
      display="flex"
      width="100%"
      height="screenFull"
      justifyContent="center"
    >
      <Box
        backgroundColor="black"
        width="50%"
        alignItems="center"
        overflow="hidden"
        justifyContent="center"
        position="relative"
        display={{ base: "none", lg: "flex" }}
      >
        <Box
          opacity="0.05"
          position="absolute"
          bottom="-25rem"
          right="-25rem"
          borderRadius="50%"
          width="50rem"
          height="50rem"
          borderWidth="10rem"
          borderColor="white"
          borderStyle="solid"
          top="-25rem"
          left="-25rem"
        />
        <Box width="75%" textAlign="center">
          <LogoSquareDarkBg boxSize="120px" />
          <Box
            fontSize="4xl"
            color="white"
            textAlign="left"
            marginTop="4"
            lineHeight="1.2"
          >
            The world's smartest buildings are powered by CriticalAsset
          </Box>
          <Box fontSize="xl" color="white" marginTop="10" textAlign="left">
            {showProMessage
              ? "Trusted by the world's best contractors, engineers, trade professionals, property managers and real estate pros."
              : "CriticalAsset is trusted by the world's best companies, cities, schools, healthcare facilities, contractors and property managers."}
          </Box>
        </Box>
        <Box
          opacity="0.05"
          position="absolute"
          bottom="-25rem"
          right="-25rem"
          borderRadius="50%"
          width="50rem"
          height="50rem"
          borderWidth="10rem"
          borderColor="white"
          borderStyle="solid"
        />
      </Box>
      <Box
        width={{ lg: "50%" }}
        display={{ lg: "flex" }}
        alignItems={{ lg: "center" }}
        height="screenFull"
        overflowY="auto"
        overflowX="hidden"
      >
        <Box maxWidth="4xl" margin="auto" padding="5" textAlign="center">
          <Link
            href={process.env.REACT_APP_LIVE_WEBSITE_URL}
            marginBottom={{ base: "0", md: "10" }}
            display={{ lg: "none" }}
          >
            <LogoSquare boxSize="120px" />
          </Link>
          <AuthHeader />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
