import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getAssetFloorPlanCreatedMessage } from "../../../../constants/lang/en";
import {
  AssetDocument,
  useAssetQuery,
  useFloorPlanAssetCreateMutation,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../../utils/serverErrors";
import Presenter, { FloorPlanAssetFormData } from "./presenter";

interface FloorPlanAssetCreatePageProps {}

const FloorPlanAssetCreatePage: React.FC<
  FloorPlanAssetCreatePageProps
> = () => {
  const { assetId } = useParams();
  const toast = useToast();
  const [assetFloorPlanAssetCreateMutation, { loading }] =
    useFloorPlanAssetCreateMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: AssetDocument,
          variables: { id: assetId },
        },
      ],
    });
  const { data } = useAssetQuery({
    variables: { id: assetId },
  });
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const navigate = useNavigate();

  const floorPlanIds = React.useMemo(() => {
    if (!data) return [];
    return data.asset.floorPlanAssets.map((la) => la.floorPlan.id);
  }, [data]);

  const onSubmit = async (
    data: FloorPlanAssetFormData,
    { setFieldError }: FormikHelpers<FloorPlanAssetFormData>
  ) => {
    const selectedFloorPlanIds = data.floorPlanIds.filter(
      (aId) => !floorPlanIds.includes(aId)
    );
    try {
      const { data: serverData, errors } =
        await assetFloorPlanAssetCreateMutation({
          variables: {
            data: selectedFloorPlanIds.map((floorPlanId) => ({
              floorPlanId,
              assetId,
            })),
          },
        });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: getAssetFloorPlanCreatedMessage(
            selectedFloorPlanIds.length
          ),
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetsShow", { assetId }));
    }, 500);
  };

  return data && assetId ? (
    <Presenter
      handleSubmit={onSubmit}
      loading={loading}
      floorPlanIds={floorPlanIds}
      isOpen={isOpen}
      onDrawerClose={onDrawerClose}
    />
  ) : null;
};

export default FloorPlanAssetCreatePage;

gql`
  mutation FloorPlanAssetCreate($data: [FloorPlanAssetCreateInput!]!) {
    floorPlanAssetCreate(data: $data)
  }
`;
