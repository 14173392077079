import { Select } from "@chakra-ui/react";
import { ICellEditorParams } from "ag-grid-community";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const AgGridSelect = memo(
  forwardRef((props: ICellEditorParams, ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
    }));

    const onChangeListener = useCallback((_, value) => setValue(value), []);
    const onKeyDownListener = useCallback(
      (event) => {
        if (event.keyCode === 13) {
          props.stopEditing();
        }
      },
      [props]
    );

    useEffect(() => refInput.current?.focus(), []);

    return (
      <Select
        ref={refInput}
        onChange={(e) => {
          onChangeListener(
            e,
            props.colDef.cellEditorParams.cellType === "MULTISELECT"
              ? [e.target.value]
              : e.target.value
          );
        }}
        onKeyDown={onKeyDownListener}
        defaultValue={
          props.colDef.cellEditorParams.cellType === "MULTISELECT"
            ? value[0]
            : value
        }
        isFullWidth
        height="42px"
        minHeight="unset"
        backgroundColor="white"
        size="sm"
        placeholder="Select a value"
      >
        {props.colDef.cellEditorParams.values.map(
          (option: { label: string; value: string }, index: number) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          )
        )}
      </Select>
    );
  })
);

export default AgGridSelect;
