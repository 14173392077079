import { EuiFlexGroup, EuiFlexItem, useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React from "react";

import Accordion from "../../../../components/accordion";
import Label from "../../../../components/label";
import { SectionField, SectionItem } from "../sectionsHandler";

interface WorkOrderVerifyAndSaveRightColumnSectionProps {
  section: SectionItem;
}

const WorkOrderVerifyAndSaveRightColumnSection: React.FC<
  WorkOrderVerifyAndSaveRightColumnSectionProps
> = ({ section }) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();
  return (
    <>
      <EuiFlexItem
        grow={false}
        style={{
          minWidth: "100%",
        }}
      >
        <Accordion
          id={section.label}
          label={section.label}
          initialIsOpen={!section.collapsed}
        >
          <EuiFlexGroup direction="column" wrap={false} gutterSize="l">
            {section.fields.map((field: SectionField) => (
              <EuiFlexGroup
                direction="column"
                justifyContent="spaceBetween"
                alignItems="stretch"
                wrap={false}
                key={field.fieldId ? field.fieldId : field.label}
              >
                <EuiFlexItem
                  className={field.className || ""}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    gap: euiTheme.size.s,
                  }}
                >
                  <Label label={field.label} style={{ paddingTop: "2px" }} />
                  {field.value instanceof Array ? (
                    <EuiFlexGroup
                      style={{
                        width: "235px",
                        minWidth: "235px",
                        maxWidth: "235px",
                      }}
                      direction={"column"}
                      gutterSize={"none"}
                    >
                      {field.value?.map((item) => (
                        <div className={"section-item"} key={item}>
                          {item}
                        </div>
                      ))}
                    </EuiFlexGroup>
                  ) : (
                    <EuiFlexItem
                      style={{
                        width: "235px",
                        minWidth: "235px",
                        maxWidth: "235px",
                      }}
                    >
                      <div className={"section-item"}>{field.value}</div>
                    </EuiFlexItem>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            ))}
          </EuiFlexGroup>
        </Accordion>
      </EuiFlexItem>
    </>
  );
};

export default WorkOrderVerifyAndSaveRightColumnSection;
