import { Icon, IconProps } from "@chakra-ui/react";

const Xls = (props: IconProps & any) => {
  return (
    <Icon width="12" height="auto" viewBox="0 0 48 48" {...props}>
      <path
        d="M40 23.333h-2.667v-2.666L40 23.333zM8 23.333h2.667v-2.666L8 23.333z"
        fill="#184F31"
      />
      <path
        d="M38 40a2 2 0 01-2 2H12a2 2 0 01-2-2V8a2 2 0 012-2h17.172a2 2 0 011.414.586l6.828 6.828A2 2 0 0138 14.828V40z"
        fill="#F1F1F1"
      />
      <path
        stroke="#CCC"
        d="M16 17.5h4M16 19.5h4M22 19.5h4M28 19.5h4M28 21.5h4M22 21.5h4M16 21.5h4M22 17.5h4M28 17.5h4"
      />
      <path d="M38 14h-7a1 1 0 01-1-1V6l8 8z" fill="#CCC" />
      <path
        d="M8 23.333c0-.368.298-.666.667-.666h30.666c.369 0 .667.298.667.666v11.334a.667.667 0 01-.667.666H8.667A.667.667 0 018 34.667V23.333z"
        fill="#217346"
      />
      <path
        d="M22.159 26.068l-1.78 2.729 1.96 3.11H20.92l-1.255-2.119-1.254 2.119H17l1.952-3.11-1.78-2.73h1.444l1.05 1.747 1.057-1.746h1.436zM23.03 31.907v-5.84h1.205v4.747h2.116v1.093h-3.322zM26.915 30.195h1.205c.011.198.09.364.238.5s.35.203.607.203c.246 0 .443-.056.59-.17a.553.553 0 00.238-.448.515.515 0 00-.139-.382c-.093-.107-.273-.2-.541-.28l-.681-.194c-.973-.311-1.46-.87-1.46-1.678 0-.514.186-.932.558-1.255.372-.327.834-.491 1.386-.491.558 0 1.014.161 1.37.483.36.322.54.757.54 1.305H29.63a.621.621 0 00-.188-.474c-.126-.125-.306-.187-.541-.187a.796.796 0 00-.525.17.505.505 0 00-.205.415c0 .277.202.474.606.593l.673.22c1.06.317 1.577.904 1.55 1.763-.005.367-.11.687-.312.958a1.735 1.735 0 01-.754.576 2.57 2.57 0 01-.968.178c-.612 0-1.107-.167-1.484-.5-.378-.339-.566-.774-.566-1.305z"
        fill="#fff"
      />
    </Icon>
  );
};

export default Xls;
