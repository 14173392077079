import { EuiDataGridColumn } from "@elastic/eui/src/components/datagrid/data_grid_types";

const defaultActions = {
  showMoveLeft: false,
  showMoveRight: false,
  showHide: false,
};
export const tableColumns: EuiDataGridColumn[] = [
  {
    id: "key",
    displayAsText: "ID",
    defaultSortDirection: "asc",
    actions: defaultActions,
    initialWidth: 80,
  },
  {
    id: "name",
    displayAsText: "Title",
    isSortable: false,
    actions: { ...defaultActions, showSortAsc: false, showSortDesc: false },
  },
  {
    id: "businessSeverity",
    displayAsText: "Severity",
    actions: defaultActions,
  },
  {
    id: "scheduleType",
    displayAsText: "Schedule Type",
    actions: defaultActions,
  },
  {
    id: "startDateTime",
    displayAsText: "Start Date",
    actions: defaultActions,
  },
  {
    id: "endDateTime",
    displayAsText: "End Date",
    actions: defaultActions,
  },
  {
    id: "serviceCategory.name",
    displayAsText: "Category",
    actions: defaultActions,
  },
  {
    id: "locationName",
    displayAsText: "Location",
    actions: defaultActions,
  },
];
