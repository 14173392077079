import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, memo } from "react";
import isEqual from "react-fast-compare";
import { FixedSizeList } from "react-window";

import FolderIcon from "../../../components/icons/folder";
import {
  CompanyFragmentFragment,
  FileFragmentFragment,
  FolderWithRelationsFragmentFragment,
  SortBy,
  SortOrder,
} from "../../../graphql/graphql";
import useFolder from "../../../hooks/useFolder";
import orderBy from "../../../utils/sort";
import FileIcon from "../fileIcon";
import PageSpinner from "../pageSpinner";

interface SelectFileProps {
  onSuccess: (id: string) => void;
  currentCompany: CompanyFragmentFragment;
  acceptFile?: string;
}

const gridItemHeight = 41;

const getFileTypes = (mime: string) => {
  const fileTypes: string[] = [];
  if (mime.includes("pdf")) {
    fileTypes.push("pdf");
  }
  if (mime.includes("image")) {
    fileTypes.push("jpg", "jpeg", "png", "gif", "webp");
  }
  return fileTypes;
};

const SelectFile: React.FC<SelectFileProps> = ({
  onSuccess,
  currentCompany,
  acceptFile = "image/*, application/pdf",
}) => {
  const [folderId, setFolderId] = React.useState(
    currentCompany.defaultFolderId || ""
  );
  const { folder } = useFolder(folderId, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const folderFiles = React.useMemo(() => {
    if (!folder) return [];
    const fileTypes = getFileTypes(acceptFile);
    const folders = orderBy(folder.children, SortBy.Name, SortOrder.Asc);
    const files = orderBy(
      folder.files.filter((f) => fileTypes.includes(f.path.split(".")[1])),
      SortBy.Name,
      SortOrder.Asc
    );
    return [...folders, ...files];
  }, [folder, acceptFile]);

  return folder ? (
    <>
      <Heading
        as="h4"
        size="base"
        isTruncated
        textAlign="center"
        paddingBottom="2"
        marginBottom="2"
        borderBottomColor="gray.100"
        borderBottomWidth="1px"
      >
        Company Files
      </Heading>
      {!!folder.parentId && (
        <Flex
          alignItems="center"
          isTruncated
          paddingBottom="2"
          marginBottom="2"
          borderBottomColor="gray.100"
          borderBottomWidth="1px"
        >
          <IconButton
            variant="icon"
            aria-label="Back to Parent folder"
            colorScheme="secondary"
            size="xxs"
            onClick={() => setFolderId(folder.parentId || "")}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
          <Heading marginLeft={2} as="h6" size="sm" isTruncated>
            {folder.name}
          </Heading>
        </Flex>
      )}
      {folderFiles.length > 0 ? (
        <FixedSizeList
          height={300}
          width={352}
          itemSize={gridItemHeight}
          itemCount={folderFiles.length}
          itemData={{ folderFiles, onSuccess, setFolderId }}
          itemKey={(i, data) => data.folderFiles[i].id}
          overscanCount={8}
        >
          {Row}
        </FixedSizeList>
      ) : (
        <Center height={300} width={352} color="gray.700">
          Folder empty
        </Center>
      )}
    </>
  ) : (
    <PageSpinner height={300} />
  );
};

export default SelectFile;

interface Data {
  folderFiles: Array<
    FolderWithRelationsFragmentFragment | FileFragmentFragment
  >;
  onSuccess: (id: string) => void;
  setFolderId: (id: string) => void;
}

const Row: FC<{
  index: number;
  style: CSSProperties;
  data: Data;
}> = memo(({ index, style, data: { folderFiles, onSuccess, setFolderId } }) => {
  const item = folderFiles[index];
  return item ? (
    <Box style={style}>
      {item.__typename === "File" ? (
        <Button
          onClick={() => onSuccess(item.id)}
          _hover={{ bgColor: "gray.100" }}
          variant="link"
          size="sm"
          width="100%"
          alignItems="center"
          justifyContent="start"
          paddingY=".5rem !important"
          borderBottom="1px solid"
          borderColor="gray.100"
          height="auto"
        >
          <FileIcon
            filePath={item.path}
            fileName={item.name}
            forceIcon
            width={20}
          />
          <Box
            marginLeft="2"
            color="gray.800"
            flexGrow={1}
            textAlign="left"
            isTruncated
          >
            {item.name}
          </Box>
        </Button>
      ) : (
        <Button
          onClick={() => setFolderId(item.id)}
          _hover={{ bgColor: "gray.100" }}
          variant="link"
          size="sm"
          width="100%"
          alignItems="center"
          justifyContent="start"
          paddingY=".5rem !important"
          borderBottom="1px solid"
          borderColor="gray.100"
          height="auto"
        >
          <FolderIcon width="20px" />
          <Box
            marginLeft="2"
            color="gray.800"
            flexGrow={1}
            textAlign="left"
            isTruncated
          >
            {item.name}
          </Box>
        </Button>
      )}
    </Box>
  ) : null;
}, isEqual);
