import { useReactiveVar } from "@apollo/client";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { isValidPhoneNumber } from "react-phone-number-input";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";

import {
  useUserQuery,
  useUserWithCompaniesQuery,
} from "../../../graphql/graphql";
import { useWorkOrderCreateMutation } from "../../../graphql/mutations/workOrders";
import { useWOGetOrderTypesQuery } from "../../../graphql/queries/workOrders";
import {
  currentCompanyVar,
  layoutOptionsVar,
} from "../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../router";
import { initialWorkOrder } from "../helpers/initialWorkOrder";
import { validateEmail } from "../helpers/validationEmail";
import { workOrderInputMapper } from "../helpers/workOrderInputMapper";
import {
  createWORedirectData,
  endDateValid,
  startDateValid,
  toastVar,
} from "../helpers/workOrdersReactiveVariables";
import { WorkOrderCreateRedirectData } from "../models/createWORedirectData";
import { WOCreateStepsEnum } from "../models/enums";
import { CompanyContactTypeEnum, WorkOrder } from "../models/workOrder";
import WorkOrderCreatePresenter from "./presenter";

interface WorkOrderCreatePageProps {}

const WorkOrderCreatePage: React.FC<WorkOrderCreatePageProps> = () => {
  const localUrl = process.env.REACT_APP_WEB_APP_URL;
  const MAX_STEPS_NUMBER = 4;
  const location = useLocation();
  const navigate = useNavigate();
  const toasts = useReactiveVar(toastVar);
  const { data: currentUserData } = useUserQuery();
  const isStartValid = useReactiveVar(startDateValid);
  const isEndValid = useReactiveVar(endDateValid);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const { euiTheme }: UseEuiTheme<{ colors: any }> = useEuiTheme();
  const WOCreateData: WorkOrderCreateRedirectData | null =
    useReactiveVar(createWORedirectData);

  const [stepNumber, setStepNumber] = React.useState(1);
  const [validStepsNumber, setValidStepsNumber] = React.useState(1);
  const [NewWorkOrder, setNewWorkOrder] = React.useState(initialWorkOrder);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isOrderCreationInProgress, setOrderCreationInProgress] =
    React.useState<boolean>(false);

  const { data: userCompaniesData } = useUserWithCompaniesQuery({
    fetchPolicy: "network-only",
  });
  const [workOrderCreateMutation, { data: workOrderData, error }] =
    useWorkOrderCreateMutation();

  const { data: orderTypes } = useWOGetOrderTypesQuery({
    fetchPolicy: "cache-and-network",
  });

  const isValidPhone = (phoneMobile: string, phoneWork: string): boolean => {
    return phoneMobile && phoneWork
      ? isValidPhoneNumber(phoneMobile) && isValidPhoneNumber(phoneWork)
      : isValidPhoneNumber(phoneMobile) || isValidPhoneNumber(phoneWork);
  };

  const isCompanyStepValid = (): boolean => {
    return (
      !!NewWorkOrder.companyId &&
      (NewWorkOrder.companyContactUserId ||
        NewWorkOrder.companyContactType !==
          CompanyContactTypeEnum.ADD_MANUALLY ||
        (NewWorkOrder.companyContactType ===
          CompanyContactTypeEnum.ADD_MANUALLY &&
          (NewWorkOrder.companyContactEmail !== null
            ? validateEmail(NewWorkOrder.companyContactEmail || "")
            : 1) &&
          NewWorkOrder.companyContactFullName &&
          isValidPhone(
            NewWorkOrder.companyContactMobilePhone || "",
            NewWorkOrder.companyContactWorkPhone || ""
          )))
    );
  };

  const isLocationStepValid = (): boolean => {
    return (
      !!NewWorkOrder.locationId &&
      (NewWorkOrder.locationContactUserId ||
        NewWorkOrder.locationContactType ===
          CompanyContactTypeEnum.SAME_AS_COMPANY ||
        (NewWorkOrder.locationContactType ===
          CompanyContactTypeEnum.ADD_MANUALLY &&
        NewWorkOrder.locationContactEmail !== null
          ? validateEmail(NewWorkOrder.locationContactEmail || "")
          : 1 &&
            NewWorkOrder.locationContactFullName &&
            isValidPhone(
              NewWorkOrder.locationContactMobilePhone || "",
              NewWorkOrder.locationContactWorkPhone || ""
            )))
    );
  };

  const isServiceStepValid = (): boolean => {
    const {
      name,
      orderTypeId,
      serviceCategoryId,
      businessSeverity,
      scheduleType,
      startDateTime,
      endDateTime,
      serviceScheduleTimezone,
      accountableUserId,
      responsibleUserIds,
      parentOrderId,
    } = NewWorkOrder;
    let isParentOrderValid = false;
    if (orderTypeId && orderTypes?.orderTypes) {
      const isOrderTypeNew =
        orderTypes.orderTypes
          .find((item) => item.id === orderTypeId)
          ?.name?.toLowerCase() === "new";
      isParentOrderValid = isOrderTypeNew || parentOrderId;
    }
    const isStartDateValid =
      scheduleType !== "STRICT_TIME" || (startDateTime && isStartValid);
    const isEndDateValid = endDateTime && isEndValid;
    return Boolean(
      name &&
        name?.trim()?.length <= 150 &&
        orderTypeId &&
        serviceCategoryId &&
        businessSeverity &&
        scheduleType &&
        serviceScheduleTimezone &&
        accountableUserId &&
        responsibleUserIds?.length &&
        isStartDateValid &&
        isEndDateValid &&
        isParentOrderValid
    );
  };

  const stepsValidation = useCallback(
    (step: number): boolean => {
      switch (step) {
        case 2:
          return isCompanyStepValid();
        case 3:
          return isLocationStepValid();
        case 4:
          return isServiceStepValid();
        case 5:
          return true;
        default:
          return false;
      }
    },
    [
      NewWorkOrder,
      isCompanyStepValid,
      isLocationStepValid,
      isServiceStepValid,
      isStartValid,
      isEndValid,
    ]
  );

  const validateWorkOrder = useCallback(() => {
    let maxValidStep = 1;
    let step = MAX_STEPS_NUMBER;
    while (maxValidStep < step) {
      if (stepsValidation(step)) {
        maxValidStep = step;
      }
      step--;
    }
    return setValidStepsNumber(maxValidStep);
  }, [stepsValidation]);

  const onWorkOrderChange = useCallback(
    (workOrderChanges: WorkOrder) => {
      if (NewWorkOrder) {
        setNewWorkOrder((savedWorkOrder: WorkOrder) => {
          return {
            ...savedWorkOrder,
            ...workOrderChanges,
          };
        });
      } else {
        setNewWorkOrder(initialWorkOrder);
      }
    },
    [NewWorkOrder]
  );

  const setServiceStepData = () => {
    if (!NewWorkOrder.endDateTime) {
      onWorkOrderChange({
        endDateTime: moment().add(2, "week").valueOf(),
      });
    }
  };

  const handleStepChange = (stepNumber: number) => {
    setStepNumber(stepNumber);
    if (stepNumber === WOCreateStepsEnum.SERVICES) {
      setServiceStepData();
    }
  };

  const validateNextButton = (): boolean => {
    return stepNumber < validStepsNumber || stepNumber === MAX_STEPS_NUMBER;
  };

  const onWorkOrderCreate = async () => {
    setLoading(true);
    await workOrderCreateMutation({
      variables: {
        data: {
          ...workOrderInputMapper(NewWorkOrder),
          requestedById: currentUserData?.user.id,
        },
        files: NewWorkOrder.attachments,
      },
    })
      .then(() => {
        setOrderCreationInProgress(false);
        window.onbeforeunload = () => null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isCompanyAvailableToUser = useMemo(() => {
    if (workOrderData?.workOrderCreate && userCompaniesData) {
      const companiesIds = userCompaniesData.user.companyUsers.map(
        (cu) => cu?.company.id
      );
      return companiesIds.includes(workOrderData.workOrderCreate.companyId);
    } else {
      return false;
    }
  }, [workOrderData?.workOrderCreate, userCompaniesData]);

  const navigateToWorkOrder = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    const data = workOrderData?.workOrderCreate;
    if (data && userCompaniesData && isCompanyAvailableToUser) {
      if (data.companyId === currentCompany?.id) {
        navigate(getRoutePath("workOrdersEdit", { workOrderId: data?.id }));
      } else {
        const path = getRoutePath("companyChange", {
          companyId: data?.companyId,
        });
        const query = createSearchParams({
          routerPath: "workOrdersEdit",
          routerParams: JSON.stringify({ workOrderId: data?.id }),
        }).toString();
        navigate({
          pathname: path,
          search: query,
        });
      }
    }
  };

  const handleCopyToClipboard = (workOrderId: string, event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    navigator.clipboard.writeText(
      localUrl + getRoutePath("workOrdersEdit", { workOrderId })
    );
    toastVar([
      ...toasts,
      {
        id: `copyWorkOrderLinkToClipboard${toasts.length + 1}`,
        color: "success",
        text: "Link to work order has been copied",
      },
    ]);
  };

  useEffect(() => {
    if (WOCreateData) {
      setNewWorkOrder(() => {
        return {
          ...NewWorkOrder,
          companyId: WOCreateData?.companyId || NewWorkOrder.companyId,
          locationId: WOCreateData?.locationId || NewWorkOrder.locationId,
        };
      });
      if (WOCreateData?.assetId) {
        handleStepChange(3);
      }
    }
    setTimeout(() => createWORedirectData(null), 300);
  }, [WOCreateData]);

  useEffect(() => {
    if (isOrderCreationInProgress) {
      window.onbeforeunload = () => "Are you sure you want to close the page?";
    }
  }, [isOrderCreationInProgress]);

  useEffect(() => {
    setOrderCreationInProgress(!!NewWorkOrder.companyId);
  }, [NewWorkOrder.companyId]);

  useEffect(() => {
    layoutOptionsVar({
      title: "Create New Work Order",
    });
  }, []);

  useEffect(() => {
    if (NewWorkOrder) {
      validateWorkOrder();
    }
  }, [NewWorkOrder, validateWorkOrder]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const companyId = query.get("companyId");
    const locationId = query.get("locationId");
    const assetId = query.get("assetId");
    companyId && onWorkOrderChange({ companyId });
    locationId && onWorkOrderChange({ locationId });
    assetId && onWorkOrderChange({ assetId });
  }, [location.search, onWorkOrderChange]);

  useEffect(() => {
    const newToasts: Toast[] = [];
    const data = workOrderData?.workOrderCreate;
    if (error) {
      newToasts.push({
        id: `WorkOrderCreateErrorMassage${toasts.length + 1 + Math.random()}`,
        color: "danger",
        text: "Work Order has not been created",
      });
    } else if (data) {
      const text = `Work Order ${data.key} has been created successfully`;
      newToasts.push({
        id: `WorkOrderCreateSuccessMassage${toasts.length + 1 + Math.random()}`,
        color: "success",
        text: (
          <>
            <EuiFlexGroup
              direction={"column"}
              justifyContent={"spaceBetween"}
              alignItems={"stretch"}
              wrap={false}
            >
              <EuiFlexItem>
                <EuiText>{text}</EuiText>
              </EuiFlexItem>
              <EuiFlexItem
                style={{ flexDirection: "row", justifyContent: "flex-start" }}
              >
                <EuiLink
                  disabled={!isCompanyAvailableToUser}
                  style={{ marginRight: euiTheme.size.base }}
                  onClick={(event: any) => navigateToWorkOrder(event)}
                >
                  View Order
                </EuiLink>
                <EuiLink
                  onClick={(event: any) =>
                    handleCopyToClipboard(data.id, event)
                  }
                >
                  Copy Link
                </EuiLink>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        ),
      });
    }
    toastVar([...toasts, ...newToasts]);
    if (!!data) {
      setTimeout(() => navigate(-1), 100);
    }
  }, [workOrderData, error]);

  return (
    <>
      <Helmet>
        <title>Work Order Create</title>
      </Helmet>
      {NewWorkOrder && (
        <div>
          <WorkOrderCreatePresenter
            workOrder={NewWorkOrder}
            onWorkOrderChange={onWorkOrderChange}
            onStepChange={handleStepChange}
            stepNumber={stepNumber}
            validStepsNumber={validStepsNumber}
            isNextButtonActive={validateNextButton()}
            onWorkOrderCreate={onWorkOrderCreate}
            loading={loading}
          />

          <ReactRouterPrompt when={isOrderCreationInProgress}>
            {({ isActive, onConfirm, onCancel }) =>
              isActive && (
                <div className="modal-warning-lightbox">
                  <div className="modal-warning-container">
                    <div className={"modal-warning-header"}>Warning</div>
                    <p className={"modal-warning-message"}>
                      If you leave the WO creation process, you will lose all
                      entered data. Are you sure you want to leave this page?
                    </p>
                    <div className="modal-warning-footer">
                      <button className={"button-cancel"} onClick={onCancel}>
                        No, stay here
                      </button>
                      <button className={"button-ok"} onClick={onConfirm}>
                        Yes, leave
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          </ReactRouterPrompt>
        </div>
      )}
    </>
  );
};

export default WorkOrderCreatePage;
