import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import CreatePayment from "../../../components/elements/createPayment";
import { getRoutePath } from "../../../router";

interface CreatePaymentPageProps {}

const CreatePaymentPage: React.FC<CreatePaymentPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <CreatePayment
      paymentMethodAdded={() => {
        navigate(
          searchParams.has("redirectPath")
            ? (searchParams.get("redirectPath") as string)
            : getRoutePath("companyPaymentMethods")
        );
      }}
      attemptToChargeOpenInvoice
    />
  );
};

export default CreatePaymentPage;
