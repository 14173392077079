import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw/dist/leaflet.draw.js";

import { FeatureCollection } from "geojson";
import L, { LatLng, Layer } from "leaflet";
import React, { useCallback } from "react";
import { GeoJSON } from "react-leaflet";

import defaultTheme from "../../../../chakraTheme";
import { getCustomIcon } from "../../../../components/elements/customLeafletIcons";

const defaultAnnotation: FeatureCollection = {
  type: "FeatureCollection",
  features: [],
};

interface DrawControlProps {
  annotation?: FeatureCollection;
  color?: string;
}

const PlanDrawControl: React.FC<DrawControlProps> = ({
  annotation,
  color = defaultTheme.colors.secondary[500],
}) => {
  const shapeOptions = React.useMemo(() => ({ color }), [color]);
  const defaultIcon = React.useMemo(
    () => getCustomIcon(undefined, color),
    [color]
  );
  const featureRef = React.useRef<any>(null);

  React.useEffect(() => {
    const feature = featureRef.current;
    feature.eachLayer((layer: any) => {
      feature.removeLayer(layer);
    });
    const handler = setTimeout(() => {
      feature?.addData(annotation || defaultAnnotation);
    });
    return () => {
      clearTimeout(handler);
    };
  }, [annotation]);

  const pointToLayer = React.useCallback(
    (feature: any, latlng: LatLng) => {
      const radius = feature.properties?.radius;
      if (radius) {
        return L.circle(latlng, { radius });
      }
      return L.marker(latlng, { icon: defaultIcon });
    },
    [defaultIcon]
  );

  const getStyle = useCallback(
    (feature: any) => {
      if (feature.properties?.color) {
        return { color: feature.properties?.color };
      }
      return shapeOptions;
    },
    [shapeOptions]
  );

  return (
    <GeoJSON
      data={defaultAnnotation}
      onEachFeature={onEachFeature}
      pointToLayer={pointToLayer}
      ref={featureRef}
      style={getStyle}
    />
  );
};

export default PlanDrawControl;

const getTooltipMarkup = (name: string, description?: string) =>
  `<h3 class="leaflet-tooltip-heading">${name}</h3>`;

const onEachFeature = (feature: any, layer: Layer) => {
  if (feature.properties && feature.properties.name) {
    layer.bindTooltip(
      getTooltipMarkup(feature.properties.name, feature.properties.description),
      { sticky: true, direction: "top" }
    );
  }
};
