import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React, { useState } from "react";

import { ASSET_TYPE_MOVED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetCategoriesDocument,
  AssetCategoryWithTypesFragmentFragment,
  AssetTypeFragmentFragment,
  useAssetTypeMoveMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";
import SelectAssetCategory from "./selectAssetCategory";

interface AssetTypeMoveProps {
  assetType: AssetTypeFragmentFragment;
  assetCategories: AssetCategoryWithTypesFragmentFragment[];
}

const AssetTypeMove: React.FC<AssetTypeMoveProps> = ({
  assetType: selectedAssetType,
  assetCategories,
}) => {
  const toast = useToast();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const {
    isOpen: isSelectCategoryOpen,
    onClose: onSelectCategoryClose,
    onOpen: onSelectCategoryOpen,
  } = useDisclosure();
  const [selectedCategory, setSelectedCategory] =
    useState<AssetCategoryWithTypesFragmentFragment>();
  const [assetTypeMoveMutation, { loading }] = useAssetTypeMoveMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AssetCategoriesDocument,
      },
    ],
  });

  const moveAssetType = async () => {
    if (!selectedCategory) return;
    try {
      const { errors } = await assetTypeMoveMutation({
        variables: {
          assetCategoryId: selectedCategory.id,
          ids: [selectedAssetType.id],
        },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        toast({
          description: ASSET_TYPE_MOVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    onSelectCategoryClose();
    setSelectedCategory(undefined);
  };

  return (
    <>
      <Tooltip label="Move asset type" hasArrow placement="bottom">
        <IconButton
          onClick={onSelectCategoryOpen}
          variant="icon"
          aria-label="Move asset type"
          colorScheme="gray"
          padding={2}
        >
          <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
        </IconButton>
      </Tooltip>
      {isSelectCategoryOpen && (
        <>
          <Modal isOpen onClose={() => onSelectCategoryClose()} size="sm">
            <ModalOverlay>
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  <SelectAssetCategory
                    assetCategories={assetCategories}
                    onSuccess={setSelectedCategory}
                    selectedAssetType={selectedAssetType}
                  />
                </ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
          <AlertDialog
            leastDestructiveRef={cancelRef}
            onClose={handleClose}
            isOpen={isSelectCategoryOpen && !!selectedCategory}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogBody textAlign="center">
                  Are you sure you want to move {selectedAssetType.name} to{" "}
                  <strong>{selectedCategory?.name}</strong>?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={handleClose} width="48%">
                    No, Don't Move!
                  </Button>
                  <Button
                    onClick={moveAssetType}
                    colorScheme="red"
                    ml="4%"
                    width="48%"
                    isLoading={loading}
                  >
                    Yes, Move
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </>
  );
};

export default AssetTypeMove;

gql`
  mutation AssetTypeMove($assetCategoryId: UUID!, $ids: [UUID!]!) {
    assetTypeMove(assetCategoryId: $assetCategoryId, ids: $ids)
  }
`;
