import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import AssetPartFields, {
  getAssetPartFieldsInitialValues,
  getAssetPartFieldsValidationSchema,
} from "../../../../components/elements/assetPartFields";
import FormGroup from "../../../../components/elements/formGroup";
import NumberInput from "../../../../components/elements/numberInput";
import { DEFAULT_BREAKER_STARTING_NUMBER } from "../../../../constants/misc";
import {
  AssetPartFieldValuesInput,
  AssetWithRelationsFragmentFragment,
  SpecialAssetFieldTypes,
} from "../../../../graphql/graphql";
import {
  columnsIndexSchema,
  descriptionSchema,
  gridPanelBreakerPoles,
  nameSchema,
  normalPanelBreakerPoles,
  rowsIndexSchema,
  yupObject,
} from "../../../../utils/validation";

interface AssetPartCreatePresenterProps {
  handleSubmit: (
    values: AssetsPartFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  onDrawerClose: () => void;
  isOpen: boolean;
  loading: boolean;
  asset: AssetWithRelationsFragmentFragment;
  rowIndex?: number;
  columnIndex?: number;
}

const AssetPartCreatePresenter: React.FC<AssetPartCreatePresenterProps> = ({
  isOpen,
  handleSubmit,
  onDrawerClose,
  loading,
  asset,
  rowIndex,
  columnIndex,
}) => {
  const {
    assetType,
    config: { breakerStartingNumber = DEFAULT_BREAKER_STARTING_NUMBER },
  } = asset;
  const isGridPanel =
    assetType.specialAssetField === SpecialAssetFieldTypes.ElectricalPanelGrid;

  const validationSchema = React.useMemo(() => {
    let yupShape: any = {
      name: nameSchema.label("Asset part name"),
      poles: isGridPanel ? gridPanelBreakerPoles : normalPanelBreakerPoles,
      rowIndex: rowsIndexSchema,
      columnIndex: columnsIndexSchema,
      description: descriptionSchema,
    };
    if (assetType) {
      yupShape = {
        ...yupShape,
        ...getAssetPartFieldsValidationSchema(assetType),
      };
    }
    return yupObject().shape(yupShape);
  }, [assetType, isGridPanel]);

  const initialValues = React.useMemo(() => {
    let initialValues: any = {
      name: "",
      description: "",
      poles: 1,
      columnIndex,
      rowIndex,
    };
    if (assetType) {
      initialValues = {
        ...initialValues,
        ...getAssetPartFieldsInitialValues(assetType),
      };
    }
    return initialValues;
  }, [assetType, columnIndex, rowIndex]);

  const onSubmit = React.useCallback(
    (data: any, formikHelpers: any) => {
      const formattedData: AssetsPartFormData = {
        name: data.name,
        poles: data.poles,
        rowIndex: data.rowIndex,
        columnIndex: data.columnIndex,
        description: data.description,
        assetPartFieldValuesInput: [],
      };
      assetType.assetPartFields.forEach((assetPartField) => {
        if (data[assetPartField.id]) {
          formattedData.assetPartFieldValuesInput.push({
            assetPartFieldId: assetPartField.id,
            value: `${data[assetPartField.id]}`,
          });
        }
      });
      handleSubmit(formattedData, formikHelpers);
    },
    [assetType, handleSubmit]
  );

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>
          Add New Breaker{" "}
          {assetType.specialAssetField ===
            SpecialAssetFieldTypes.ElectricalPanelNormal &&
          typeof columnIndex === "number" &&
          columnIndex >= 0 &&
          typeof rowIndex === "number" &&
          rowIndex >= 0
            ? `(#${breakerStartingNumber + +rowIndex * 2 + +columnIndex})`
            : ""}
        </title>
      </Helmet>
      <DrawerOverlay zIndex={1600}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Add New Breaker{" "}
            {assetType.specialAssetField ===
              SpecialAssetFieldTypes.ElectricalPanelNormal &&
            typeof columnIndex === "number" &&
            columnIndex >= 0 &&
            typeof rowIndex === "number" &&
            rowIndex >= 0
              ? `(#${breakerStartingNumber + +rowIndex * 2 + +columnIndex})`
              : ""}
          </DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form id="asset_create" noValidate>
                  <FormGroup label="Breaker Name" name="name">
                    <Input
                      autoFocus
                      autoComplete="off"
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  {typeof columnIndex !== "number" && (
                    <NumberInput
                      label="Column Index"
                      name="columnIndex"
                      defaultValue={1}
                      min={0}
                    />
                  )}
                  {typeof rowIndex !== "number" && (
                    <NumberInput
                      label="Row Index"
                      name="rowIndex"
                      defaultValue={1}
                      min={0}
                    />
                  )}
                  {typeof rowIndex === "number" &&
                    rowIndex >= 0 &&
                    typeof columnIndex === "number" &&
                    columnIndex >= 0 && (
                      <NumberInput
                        label="Poles"
                        name="poles"
                        defaultValue={1}
                        min={1}
                        max={isGridPanel ? 10 : 3}
                      />
                    )}
                  <FormGroup label="Description" name="description">
                    <Textarea
                      autoComplete="off"
                      {...getFieldProps("description")}
                    />
                  </FormGroup>
                  <AssetPartFields
                    assetPartFields={assetType.assetPartFields}
                  />
                </Form>
              )}
            </Formik>
          </DrawerBody>
          <DrawerFooter>
            <Button
              width="full"
              type="submit"
              isLoading={loading}
              form="asset_create"
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetPartCreatePresenter;

export type AssetsPartFormData = {
  name: string;
  description: string;
  assetPartFieldValuesInput: AssetPartFieldValuesInput[];
  poles: number;
  columnIndex: number;
  rowIndex: number;
};
