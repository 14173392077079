import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import LabeledMenuButton from "../../../components/elements/labeledMenuButton";
import {
  getFloorPlanDeleteMessage,
  getFloorPlanMoveMessage,
} from "../../../constants/lang/en";
import {
  FloorPlanFragmentFragment,
  FloorPlanIndexDocument,
  LocationDocument,
  LocationFragmentFragment,
  useFloorPlanDeleteMutation,
  useFloorPlanMoveMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";
import LocationTreeSelect from "./locationTreeSelect";

interface FloorPlanBulkActionsProps {
  selectedIds: Array<string | undefined>;
  canEditPlan: boolean;
  canDeletePlan: boolean;
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
  floorPlans: FloorPlanFragmentFragment[];
}

const FloorPlanBulkActions: React.FC<FloorPlanBulkActionsProps> = ({
  selectedIds,
  canEditPlan,
  canDeletePlan,
  toggleAllRowsSelected,
  floorPlans,
}) => {
  const toast = useToast();
  const [processing, setProcessing] = React.useState(false);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isMoveOpen,
    onOpen: onMoveOpen,
    onClose: onMoveClose,
  } = useDisclosure();
  const [selectedLocation, setSelectedLocation] =
    useState<LocationFragmentFragment>();
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null);
  const cancelMoveRef = React.useRef<HTMLButtonElement>(null);
  const [floorPlanMoveMutation] = useFloorPlanMoveMutation();
  const [floorPlanDeleteMutation] = useFloorPlanDeleteMutation();
  const { locationId } = useParams();

  const deleteFloorPlans = async () => {
    setProcessing(true);
    try {
      const { errors } = await floorPlanDeleteMutation({
        variables: { ids: selectedIds },
        awaitRefetchQueries: true,
        refetchQueries: [
          locationId
            ? {
                query: LocationDocument,
                variables: { id: locationId },
              }
            : {
                query: FloorPlanIndexDocument,
              },
        ],
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        toast({
          description: getFloorPlanDeleteMessage(selectedIds.length),
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setProcessing(false);
      onDeleteClose();
    }
  };

  const moveFloorPlans = async () => {
    if (!selectedLocation) return;
    setProcessing(true);
    try {
      await floorPlanMoveMutation({
        variables: { ids: selectedIds, locationId: selectedLocation.id },
        awaitRefetchQueries: true,
        refetchQueries: locationId
          ? [
              {
                query: LocationDocument,
                variables: { id: locationId },
              },
              {
                query: LocationDocument,
                variables: { id: selectedLocation.id },
              },
            ]
          : [{ query: FloorPlanIndexDocument }],
      });
      toast({
        description: getFloorPlanMoveMessage(selectedIds.length),
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setProcessing(false);
      handleClose();
    }
  };

  const handleClose = () => {
    onMoveClose();
    setSelectedLocation(undefined);
  };

  return selectedIds.length ? (
    <Box>
      {(canEditPlan || canDeletePlan) && (
        <Flex
          alignItems="center"
          justifyContent={{ base: "space-between", md: "flex-end" }}
          direction={{ base: "row-reverse", md: "row" }}
          marginTop={{ base: "3", md: "0" }}
        >
          <Flex direction={{ base: "column", sm: "row" }} alignItems="flex-end">
            <Text color="gray.300" fontSize="sm">
              {selectedIds.length} Selected
            </Text>
            <Button
              variant="link"
              marginX={{ base: "0", sm: "4" }}
              marginRight={{ sm: "0", md: "4" }}
              colorScheme="secondary"
              onClick={() =>
                toggleAllRowsSelected(selectedIds.length !== floorPlans.length)
              }
            >
              {selectedIds.length === floorPlans.length
                ? "Deselect All"
                : "Select All"}
            </Button>
          </Flex>
          <Menu>
            <LabeledMenuButton label="Action" value="" />
            <MenuList minWidth="100px">
              {canEditPlan && (
                <MenuItem onClick={onMoveOpen}>
                  Move selected {pluralize("plan", selectedIds.length)}
                </MenuItem>
              )}
              {canDeletePlan && (
                <MenuItem onClick={onDeleteOpen}>
                  Delete selected {pluralize("plan", selectedIds.length)}
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
      )}
      {canEditPlan && (
        <>
          <Modal
            isOpen={isMoveOpen && !selectedLocation}
            onClose={handleClose}
            size="xl"
          >
            <ModalOverlay>
              <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                  Move selected {pluralize("plan", selectedIds.length)} to
                </ModalHeader>
                <ModalBody paddingBottom="6">
                  <LocationTreeSelect
                    handleSelect={setSelectedLocation}
                    activeLocationId={locationId}
                  />
                </ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
          <AlertDialog
            leastDestructiveRef={cancelMoveRef}
            onClose={handleClose}
            isOpen={isMoveOpen && !!selectedLocation}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogBody textAlign="center">
                  Are you sure you want to move {selectedIds.length} selected{" "}
                  {pluralize("plan", selectedIds.length)} to{" "}
                  <strong>{selectedLocation?.name}</strong>?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelMoveRef} onClick={handleClose} width="48%">
                    No, Don't Move!
                  </Button>
                  <Button
                    onClick={moveFloorPlans}
                    colorScheme="red"
                    ml="4%"
                    width="48%"
                    isLoading={processing}
                  >
                    Yes, Move
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
      {canDeletePlan && (
        <AlertDialog
          leastDestructiveRef={cancelDeleteRef}
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogBody textAlign="center">
                Are you sure you want to delete {selectedIds.length} selected{" "}
                {pluralize("plan", selectedIds.length)}?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelDeleteRef}
                  onClick={onDeleteClose}
                  width="48%"
                >
                  No, Don't Delete!
                </Button>
                <Button
                  onClick={deleteFloorPlans}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                  isLoading={processing}
                >
                  Yes, Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </Box>
  ) : null;
};

export default FloorPlanBulkActions;

gql`
  mutation FloorPlanMove($ids: [UUID!]!, $locationId: UUID!) {
    floorPlanMove(ids: $ids, locationId: $locationId)
  }
`;
