import { useReactiveVar } from "@apollo/client";
import { IconButton, Tooltip, useToast } from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { MouseEvent } from "react";

import Resend from "../../../components/icons/resend";
import { TEAM_CREATE_RESEND } from "../../../constants/lang/en";
import {
  CompanyUserWithUserFragmentFragment,
  useCompanyUserInvitationResendMutation,
} from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import { setGenericMessage } from "../../../utils/serverErrors";

interface CompanyTeamResendProps {
  companyUser: CompanyUserWithUserFragmentFragment;
}

const CompanyTeamResend: React.FC<CompanyTeamResendProps> = ({
  companyUser,
}) => {
  const toast = useToast();
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const [companyUserInvitationResend, { loading }] =
    useCompanyUserInvitationResendMutation();

  const handleResend = async (event: MouseEvent) => {
    event.stopPropagation();
    try {
      await companyUserInvitationResend({
        variables: { id: companyUser.id },
      });
      toast({
        description: TEAM_CREATE_RESEND + companyUser.user.email + "!",
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  return !currentCompanyRole.isUser && !companyUser.user.registered ? (
    <Tooltip label="Resend invitation" placement="left" hasArrow>
      <IconButton
        type="button"
        variant="icon"
        aria-label="Resend invitation"
        isLoading={loading}
        onClick={handleResend}
        colorScheme="gray"
        minWidth="0"
      >
        <Resend />
      </IconButton>
    </Tooltip>
  ) : null;
};

export default CompanyTeamResend;

gql`
  mutation CompanyUserInvitationResend($id: UUID!) {
    companyUserInvitationResend(id: $id) {
      ...CompanyUserWithUserFragment
    }
  }
`;
