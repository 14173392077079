import { Alert, AlertDescription, Button, CloseButton } from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

interface BookDemoProps {
  onClose?: () => void;
}

const BookDemo: React.FC<BookDemoProps> = ({ onClose: handleClose }) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <Alert status="info" role="group">
      <AlertDescription position="relative">
        Book free demo with our product specialists{" "}
        <Button
          variant="link"
          colorScheme="white"
          _groupHover={{ backgroundColor: "blue.700" }}
          fontSize="sm"
          marginLeft="2"
          padding="0 4px"
          textTransform="uppercase"
          onClick={() => setSearchParams({ CaAprType: "calendly" })}
        >
          Book Demo
        </Button>
      </AlertDescription>
      {handleClose && (
        <CloseButton
          width={{ base: "auto", md: 8 }}
          position={{ md: "absolute" }}
          right={{ md: "2" }}
          onClick={handleClose}
          size="sm"
        />
      )}
    </Alert>
  );
};

export default BookDemo;
