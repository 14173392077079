import {
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
} from "@chakra-ui/react";
import React from "react";

interface SpinnerProps extends ChakraSpinnerProps {}

const Spinner: React.FC<SpinnerProps> = (props) => (
  <ChakraSpinner thickness="2px" speed="0.65s" color="gray.400" {...props} />
);

export default Spinner;
