import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import AssetIconField from "../../../components/elements/assetIcons/field";
import FormGroup from "../../../components/elements/formGroup";
import { ASSET_CATEGORY_CREATED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetCategoriesDocument,
  IconTypes,
  useAssetCategoryCreateMutation,
} from "../../../graphql/graphql";
import useNextOrRandomAssetCategoryColor from "../../../hooks/useNextOrRandomAssetCategoryColor";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  descriptionSchema,
  iconColorSchema,
  iconNameSchema,
  iconTypeSchema,
  nameSchema,
  yupObject,
} from "../../../utils/validation";

interface AssetCategoriesCreatePresenterProps {}

const AssetCategoriesCreatePresenter: React.FC<
  AssetCategoriesCreatePresenterProps
> = () => {
  const initColor = useNextOrRandomAssetCategoryColor();
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [assetCategoryCreateMutation, { loading }] =
    useAssetCategoryCreateMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: AssetCategoriesDocument,
        },
      ],
    });

  const onSubmit = async (
    data: AssetCategoryFormData,
    { setFieldError }: FormikHelpers<AssetCategoryFormData>
  ) => {
    try {
      const { data: serverData, errors } = await assetCategoryCreateMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: ASSET_CATEGORY_CREATED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetCategories"));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>Add New Asset Category</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex alignItems="center">
              <Box>Add New Asset Category</Box>
              <Tooltip
                label="Create an asset category that you want to track.  After creating an asset category, you can create asset types for this category."
                hasArrow
              >
                <Box color="gray.700" marginLeft="2">
                  <FontAwesomeIcon icon={faInfoCircle} size="xs" />
                </Box>
              </Tooltip>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{
                name: "",
                description: "",
                iconName: "",
                iconColor: initColor,
                iconType: IconTypes.Square,
              }}
              validationSchema={AssetCategoryValidationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form id="asset_category_create" noValidate>
                  <FormGroup label="Category Name" name="name">
                    <Input
                      autoFocus
                      autoComplete="off"
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  <FormGroup label="Description" name="description">
                    <Textarea
                      autoComplete="off"
                      {...getFieldProps("description")}
                    />
                  </FormGroup>
                  <AssetIconField />
                </Form>
              )}
            </Formik>
          </DrawerBody>
          <DrawerFooter>
            <Button
              width="full"
              type="submit"
              isLoading={loading}
              form="asset_category_create"
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetCategoriesCreatePresenter;

gql`
  mutation AssetCategoryCreate($data: AssetCategoryInput!) {
    assetCategoryCreate(data: $data) {
      ...AssetCategoryFragment
    }
  }
`;

export const AssetCategoryValidationSchema = yupObject().shape({
  name: nameSchema.label("Category name"),
  description: descriptionSchema,
  iconName: iconNameSchema,
  iconColor: iconColorSchema.required(),
  iconType: iconTypeSchema,
});

export type AssetCategoryFormData = {
  name: string;
  description: string;
  iconName: string;
  iconColor: string;
  iconType: IconTypes;
};
