import React from "react";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  useStripeBankAccountsQuery,
  useStripeCustomerQuery,
  useStripePaymentMethodsQuery,
} from "../../../graphql/graphql";
import Presenter from "./presenter";

interface PaymentMethodsProps {
  onPaymentSelected: () => void;
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  onPaymentSelected,
}) => {
  const { data: paymentMethodsData } = useStripePaymentMethodsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: bankAccountsData } = useStripeBankAccountsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: customerData } = useStripeCustomerQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return paymentMethodsData && bankAccountsData && customerData ? (
    <Presenter
      onPaymentSelected={onPaymentSelected}
      cards={paymentMethodsData.stripePaymentMethods}
      bankAccounts={bankAccountsData.stripeBankAccounts}
      customer={customerData.stripeCustomer}
    />
  ) : (
    <PageSpinner />
  );
};

export default PaymentMethods;
