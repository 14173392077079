import { Center, CenterProps, Flex } from "@chakra-ui/react";
import React from "react";
import tinycolor2 from "tinycolor2";

import defaultTheme from "../../../chakraTheme";
import { IconTypes } from "../../../graphql/graphql";
import AssetIcons from "../../icons/assets";

interface AssetIconProps extends CenterProps {
  iconName: string;
  iconSize?: "xs" | "sm" | "md" | "lg";
  iconColor?: string;
  iconType: IconTypes;
}

const AssetIcon: React.FC<AssetIconProps> = ({
  iconName,
  iconSize = "md",
  iconColor = defaultTheme.colors.gray[800],
  iconType,
  ...props
}) => {
  const Asset = React.useMemo(() => getIconComponent(iconName), [iconName]);
  const iconTextColor = React.useMemo(
    () => (tinycolor2(iconColor).isDark() ? "white" : "black"),
    [iconColor]
  );
  const boxSize =
    iconSize === "md"
      ? "4rem"
      : iconSize === "sm"
      ? "3rem"
      : iconSize === "xs"
      ? "2rem"
      : "6rem";
  const iconBoxSize =
    iconSize === "md"
      ? "3.2rem"
      : iconSize === "sm"
      ? "2.3rem"
      : iconSize === "xs"
      ? "1.5rem"
      : "5rem";
  let iconTextSize =
    iconSize === "md"
      ? 1.2
      : iconSize === "sm"
      ? 0.9
      : iconSize === "xs"
      ? 0.65
      : 1.8;
  if (iconName.length > 3) {
    iconTextSize -= 0.2;
  }

  return (
    <Center
      boxSize={boxSize}
      backgroundColor={iconColor}
      {...props}
      flexShrink={0}
      borderRadius={iconType === IconTypes.Square ? "0" : "100%"}
    >
      {Asset ? (
        <Asset boxSize={iconBoxSize} color={iconTextColor} />
      ) : (
        <Flex
          align="center"
          justify="center"
          boxSize={boxSize}
          color={iconTextColor}
          fontSize={`${iconTextSize}rem`}
          fontWeight="700"
          wordBreak="break-all"
          overflow="hidden"
        >
          {iconName}
        </Flex>
      )}
    </Center>
  );
};

export const getIconComponent = (icon: string) => AssetIcons[icon];

export default AssetIcon;
