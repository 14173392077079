import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface CompanyBillingPageProps {}

const CompanyBillingPage: React.FC<CompanyBillingPageProps> = ({
  children,
}) => {
  useEffect(() => {
    layoutOptionsVar({
      title: "Plan & Billing",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Plan & Billing</title>
      </Helmet>
      <Presenter />
      {children}
    </>
  );
};

export default CompanyBillingPage;
