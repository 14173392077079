import "react-leaflet-markercluster/dist/styles.min.css";

import { useApolloClient, useReactiveVar } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Feature } from "geojson";
import { LatLngExpression, LeafletMouseEvent } from "leaflet";
import L from "leaflet";
import { uniq } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Marker, Popup, Tooltip, useMapEvents } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useNavigate } from "react-router";

import defaultTheme from "../../../../chakraTheme";
import { ASSET_FLOOR_PLAN_DELETED_MESSAGE } from "../../../../constants/lang/en";
import {
  FloorPlanAssetForMapFragmentFragment,
  FloorPlanDocument,
  FloorPlanForMapFragmentFragment,
  useFloorPlanAssetCreateMutation,
  useFloorPlanAssetDeleteMutation,
} from "../../../../graphql/graphql";
import {
  changePlanEditorOptions,
  isNativeWebViewVar,
  planEditorOptionsVar,
} from "../../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../../router";
import { isTouchDevice } from "../../../../utils/isTouchDevice";
import { setGenericMessage } from "../../../../utils/serverErrors";
import AssetDrawer from "../../assetDrawer";
import { getCustomIconForAsset } from "../../customLeafletIcons";
import Status from "../../status";
import AssetCreate from "./assetCreate";

declare let window: any;

interface MarkersControlProps {
  floorPlan: FloorPlanForMapFragmentFragment;
  floorPlanAssets?: FloorPlanAssetForMapFragmentFragment[];
  clusterAssets: boolean;
  unsavedChanges: boolean;
  handleFloorPlanAssetMove?: (
    floorPlanAssetId: string,
    feature: Feature
  ) => void;
  showAssetStatus: boolean;
  canAddAsset: boolean;
  setLoading: (val: boolean) => void;
}

const MarkersControl: React.FC<MarkersControlProps> = ({
  floorPlan,
  floorPlanAssets,
  clusterAssets,
  unsavedChanges,
  handleFloorPlanAssetMove,
  showAssetStatus,
  canAddAsset,
  setLoading,
}) => {
  const [isTouch] = useState(isTouchDevice());
  const toast = useToast();
  const [mapPosition, setMapPosition] = React.useState<Feature>();
  const [assetTypeIdToAdd, setAssetTypeIdToAdd] = React.useState<string>();
  const [typeToAdd, setTypeToAdd] = React.useState<"new" | "existing">();
  const { planId } = useReactiveVar(planEditorOptionsVar);
  const [popupPosition, setPopupPosition] = useState<LatLngExpression>();
  const [openedAssetId, setOpenedAssetId] = useState("");

  const [assetFloorPlanAssetCreateMutation, { loading }] =
    useFloorPlanAssetCreateMutation({
      refetchQueries: [{ query: FloorPlanDocument, variables: { id: planId } }],
      // awaitRefetchQueries: true,
    });
  const handleFloorPlanAssetCreate = useCallback(
    async (assetId: string, localMapPosition?: Feature) => {
      if (!canAddAsset) return;
      setLoading(true);
      try {
        const { errors, data: serverData } =
          await assetFloorPlanAssetCreateMutation({
            variables: {
              data: [
                {
                  floorPlanId: planId,
                  assetId,
                  mapPosition: localMapPosition || mapPosition,
                },
              ],
            },
          });
        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
        } else if (serverData) {
          setMapPosition(undefined);
          setAssetTypeIdToAdd(undefined);
          setTypeToAdd(undefined);
          const currentAssetIds = planEditorOptionsVar().assetIds;
          if (currentAssetIds.length) {
            changePlanEditorOptions({
              assetIds: [...currentAssetIds, assetId],
              assetPartIds: [],
            });
          }
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      assetFloorPlanAssetCreateMutation,
      canAddAsset,
      mapPosition,
      planId,
      setLoading,
      toast,
    ]
  );

  const onAssetAdd = React.useCallback(
    ({ lng, lat }: { lng: number; lat: number }, extras = {}) => {
      if (!canAddAsset) return;
      const { assetTypeId, assetId, type } = extras;
      const mapPosition: Feature = {
        type: "Feature",
        geometry: { type: "Point", coordinates: [lng, lat] },
        properties: {},
      };
      if (assetId) {
        handleFloorPlanAssetCreate(assetId, mapPosition);
      } else {
        setMapPosition(mapPosition);
        setAssetTypeIdToAdd(assetTypeId);
        setTypeToAdd(type);
      }
    },
    [canAddAsset, handleFloorPlanAssetCreate]
  );

  const map = useMapEvents({
    contextmenu(e: LeafletMouseEvent) {
      setPopupPosition(e.latlng);
    },
    dblclick(e: LeafletMouseEvent) {
      if (isTouch) setPopupPosition(e.latlng);
    },
    click(e: LeafletMouseEvent) {
      if (isNativeWebViewVar()) {
        onAssetAdd(e.latlng, { type: "new" });
      }
    },
  });

  React.useEffect(() => {
    const mapDiv = map.getContainer();
    const handleDragOverEvent = (e: DragEvent) => e.preventDefault();
    const handleDragEnterEvent = (e: DragEvent) => e.preventDefault();
    const handleDropEvent = (e: any) => {
      e.preventDefault();
      if (unsavedChanges) {
        window.alert(
          "Please save changes before you add more assets to the plan."
        );
        return;
      }
      const assetId = e.dataTransfer.getData("assetId");
      const assetTypeId = e.dataTransfer.getData("assetTypeId");
      const x = e.dataTransfer.getData("x") ? +e.dataTransfer.getData("x") : 0;
      const y = e.dataTransfer.getData("y") ? +e.dataTransfer.getData("y") : 0;
      if (!assetId && !assetTypeId) return;
      const mc = document.getElementById("map_container");
      const rect = mc!.getBoundingClientRect();
      onAssetAdd(
        map.containerPointToLatLng(
          L.point(e.clientX + x - rect.left, e.clientY + y - rect.top)
        ),
        {
          assetId,
          assetTypeId,
        }
      );
    };

    mapDiv.addEventListener("dragenter", handleDragEnterEvent);
    mapDiv.addEventListener("dragover", handleDragOverEvent);
    mapDiv.addEventListener("drop", handleDropEvent);
    return () => {
      mapDiv.removeEventListener("dragenter", handleDragEnterEvent);
      mapDiv.removeEventListener("dragover", handleDragOverEvent);
      mapDiv.removeEventListener("drop", handleDropEvent);
    };
  }, [map, onAssetAdd, unsavedChanges]);

  return (
    <>
      {floorPlanAssets ? (
        clusterAssets ? (
          <MarkerClusterGroup
            maxClusterRadius={15}
            showCoverageOnHover={false}
            spiderLegPolylineOptions={{
              weight: 1.5,
              color: defaultTheme.colors.secondary[500],
              opacity: 0.5,
            }}
          >
            {floorPlanAssets.map((floorPlanAsset) =>
              floorPlanAsset.mapPosition ? (
                <MarkerControl
                  key={floorPlanAsset.id}
                  floorPlanAsset={floorPlanAsset}
                  handleFloorPlanAssetMove={handleFloorPlanAssetMove}
                  showAssetStatus={showAssetStatus}
                  unsavedChanges={unsavedChanges}
                  canAddAsset={canAddAsset}
                  setLoading={setLoading}
                  onOpen={setOpenedAssetId}
                />
              ) : null
            )}
          </MarkerClusterGroup>
        ) : (
          <>
            {floorPlanAssets.map((floorPlanAsset) =>
              floorPlanAsset.mapPosition ? (
                <MarkerControl
                  key={floorPlanAsset.id}
                  floorPlanAsset={floorPlanAsset}
                  handleFloorPlanAssetMove={handleFloorPlanAssetMove}
                  showAssetStatus={showAssetStatus}
                  unsavedChanges={unsavedChanges}
                  canAddAsset={canAddAsset}
                  setLoading={setLoading}
                  onOpen={setOpenedAssetId}
                />
              ) : null
            )}
          </>
        )
      ) : null}

      {popupPosition && (
        <Popup position={popupPosition} closeButton={false} keepInView={false}>
          <Box width="200px">
            <Button
              width="full"
              colorScheme="white"
              _hover={{ backgroundColor: "white" }}
              size="xs"
              paddingY={{ base: "6", xl: "5" }}
              textTransform="none"
              justifyContent="flex-start"
              color="gray.800"
              borderBottom="1px solid"
              borderColor="gray.100"
              onClick={() => {
                onAssetAdd(popupPosition as any, { type: "new" });
                setPopupPosition(undefined);
              }}
            >
              Add new asset
            </Button>
            <Button
              width="full"
              colorScheme="white"
              _hover={{ backgroundColor: "white" }}
              size="xs"
              paddingY={{ base: "6", xl: "5" }}
              textTransform="none"
              justifyContent="flex-start"
              color="gray.800"
              onClick={() => {
                onAssetAdd(popupPosition as any, { type: "existing" });
                setPopupPosition(undefined);
              }}
            >
              Add existing asset
            </Button>
          </Box>
        </Popup>
      )}

      {!!mapPosition && (
        <AssetCreate
          floorPlan={floorPlan}
          handleCreateNewAsset={handleFloorPlanAssetCreate}
          handleCancel={() => {
            setMapPosition(undefined);
            setAssetTypeIdToAdd(undefined);
            setTypeToAdd(undefined);
          }}
          loading={loading}
          assetTypeIdToAdd={assetTypeIdToAdd}
          typeToAdd={typeToAdd}
        />
      )}

      <AssetDrawer
        isOpen={!!openedAssetId}
        onClose={() => setOpenedAssetId("")}
        id={openedAssetId}
        currentFlPlanId={planId}
      />
    </>
  );
};

export default MarkersControl;

interface MarkerControlProps {
  floorPlanAsset: FloorPlanAssetForMapFragmentFragment;
  unsavedChanges: boolean;
  handleFloorPlanAssetMove?: (
    floorPlanAssetId: string,
    feature: Feature
  ) => void;
  showAssetStatus: boolean;
  canAddAsset: boolean;
  setLoading: (val: boolean) => void;
  onOpen: (assetId: string) => void;
}

const MarkerControl: React.FC<MarkerControlProps> = ({
  floorPlanAsset,
  unsavedChanges,
  handleFloorPlanAssetMove,
  showAssetStatus,
  canAddAsset,
  setLoading,
  onOpen,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const [isTouch] = useState(isTouchDevice());
  const navigate = useNavigate();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const coordinates = floorPlanAsset.mapPosition.geometry.coordinates;
  const markerRef = useRef<any>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { assetIds } = useReactiveVar(planEditorOptionsVar);
  const isSelectedAsset = useMemo(
    () => !!assetIds.length && assetIds.includes(floorPlanAsset.asset.id),
    [assetIds, floorPlanAsset.asset.id]
  );

  const eventHandlers = React.useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker && handleFloorPlanAssetMove) {
          handleFloorPlanAssetMove(floorPlanAsset.id, marker.toGeoJSON());
        }
      },
      click(e: LeafletMouseEvent) {
        if (isNativeWebViewVar()) {
          if (window.ReactNativeWebView?.postMessage) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                eventType: "CA:ContextMenuOpened",
                payload: {
                  floorPlanAssetId: floorPlanAsset.id,
                  showMoreOptions: !unsavedChanges && canAddAsset,
                  isSelectedAsset,
                },
              })
            );
          }
          e.target.closeTooltip();
          e.target.closePopup();
        } else {
          if (isTouch) {
            e.target.openPopup();
            e.target.closeTooltip();
          } else {
            onOpen(floorPlanAsset.asset.id);
            e.target.closePopup();
          }
        }
      },
      contextmenu(e: LeafletMouseEvent) {
        e.originalEvent.preventDefault();
        if (isNativeWebViewVar()) {
          if (window.ReactNativeWebView?.postMessage) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                eventType: "CA:ContextMenuOpened",
                payload: {
                  floorPlanAssetId: floorPlanAsset.id,
                  showMoreOptions: !unsavedChanges && canAddAsset,
                  isSelectedAsset,
                },
              })
            );
          }
          e.target.closeTooltip();
          e.target.closePopup();
        } else {
          e.target.openPopup();
          e.target.closeTooltip();
        }
      },
    }),
    [
      handleFloorPlanAssetMove,
      floorPlanAsset.id,
      floorPlanAsset.asset.id,
      unsavedChanges,
      canAddAsset,
      isSelectedAsset,
      isTouch,
      onOpen,
    ]
  );

  const handleAssetChange = useCallback(
    (assetId?: string, remove = false) => {
      let newAssetIds: string[] = [];
      let newAssetPartIds: string[] = [];

      if (!assetIds.length) {
        newAssetIds = remove ? [] : assetId ? [assetId] : [];
      } else {
        if (assetId) {
          if (assetIds.find((aid) => aid === assetId)) {
            newAssetIds = assetIds.filter((aid) => aid !== assetId);
          } else {
            newAssetIds = uniq([...assetIds, assetId]);
          }
        } else {
          newAssetIds = assetIds;
        }
      }

      changePlanEditorOptions({
        assetIds: newAssetIds,
        assetPartIds: newAssetPartIds,
      });
    },
    [assetIds]
  );

  const [floorPlanAssetDeleteMutation, { loading }] =
    useFloorPlanAssetDeleteMutation();
  const deleteFloorPlanAsset = useCallback(async () => {
    setLoading(true);
    try {
      await floorPlanAssetDeleteMutation({
        variables: { id: floorPlanAsset.id },
      });
      toast({
        description: ASSET_FLOOR_PLAN_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      client.cache.evict({ id: `FloorPlanAsset:${floorPlanAsset.id}` });
      onDeleteClose();
      handleAssetChange(floorPlanAsset.asset.id, true);
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [
    client.cache,
    floorPlanAsset.asset.id,
    floorPlanAsset.id,
    floorPlanAssetDeleteMutation,
    handleAssetChange,
    onDeleteClose,
    setLoading,
    toast,
  ]);

  const assetType = floorPlanAsset.asset.assetType;

  const icon = React.useMemo(
    () =>
      getCustomIconForAsset({
        iconColor: assetType.misc.resolvedIconColor,
        iconName: assetType.iconName,
        iconType: assetType.iconType,
        iconStatus: showAssetStatus
          ? floorPlanAsset.asset.maintenanceStatus
          : undefined,
        iconSize: isSelectedAsset && assetIds.length === 1 ? "sm" : "xs",
      }),
    [
      assetIds.length,
      assetType.iconName,
      assetType.iconType,
      assetType.misc.resolvedIconColor,
      floorPlanAsset.asset.maintenanceStatus,
      isSelectedAsset,
      showAssetStatus,
    ]
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        !event.data.eventType ||
        !event.data.eventType.startsWith("CA:PlanEditor:FPA") ||
        event.data.payload.floorPlanAssetId !== floorPlanAsset.id
      )
        return;
      if (event.data.eventType === "CA:PlanEditor:FPA:delete")
        deleteFloorPlanAsset();
      if (event.data.eventType === "CA:PlanEditor:FPA:toggle")
        handleAssetChange(floorPlanAsset.asset.id);
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, [
    deleteFloorPlanAsset,
    floorPlanAsset.asset.id,
    floorPlanAsset.id,
    handleAssetChange,
  ]);

  return coordinates ? (
    <>
      <Marker
        key={floorPlanAsset.id}
        draggable={!!handleFloorPlanAssetMove}
        eventHandlers={eventHandlers}
        position={[coordinates[1], coordinates[0]]}
        ref={markerRef}
        icon={icon}
      >
        {!isTouch && (
          <Tooltip direction="top" offset={[10, -5]}>
            <Flex alignItems="center" paddingLeft="4" position="relative">
              <Status status={floorPlanAsset.asset.maintenanceStatus} />
              <Box>{floorPlanAsset.asset.name}</Box>
            </Flex>
          </Tooltip>
        )}
        <Popup closeButton={false} keepInView={false}>
          <Box width="235px">
            {!!isTouch && (
              <Box bg="gray.100">
                <Flex alignItems="center" paddingLeft="4" position="relative">
                  <Status status={floorPlanAsset.asset.maintenanceStatus} />
                  <Box
                    py="2"
                    pr="2"
                    isTruncated
                    fontSize="lg"
                    fontWeight="600"
                    flexGrow={1}
                  >
                    {floorPlanAsset.asset.name}
                  </Box>
                </Flex>
              </Box>
            )}
            <Button
              width="full"
              colorScheme="white"
              _hover={{ backgroundColor: "white" }}
              size="xs"
              paddingY={{ base: "6", xl: "5" }}
              textTransform="none"
              justifyContent="flex-start"
              color="gray.800"
              borderBottom="1px solid"
              borderColor="gray.100"
              onClick={() => {
                onOpen(floorPlanAsset.asset.id);
                markerRef.current?.closePopup();
              }}
            >
              View asset details
            </Button>
            {!unsavedChanges && canAddAsset && (
              <>
                <Button
                  width="full"
                  colorScheme="white"
                  _hover={{ backgroundColor: "white" }}
                  size="xs"
                  paddingY={{ base: "6", xl: "5" }}
                  textTransform="none"
                  justifyContent="flex-start"
                  color="gray.800"
                  borderBottom="1px solid"
                  borderColor="gray.100"
                  onClick={() =>
                    navigate(
                      getRoutePath("assetsShow", {
                        assetId: floorPlanAsset.asset.id,
                      })
                    )
                  }
                >
                  Edit asset details
                </Button>
                <Button
                  width="full"
                  colorScheme="white"
                  _hover={{ backgroundColor: "white" }}
                  size="xs"
                  paddingY={{ base: "6", xl: "5" }}
                  textTransform="none"
                  justifyContent="flex-start"
                  color="gray.800"
                  borderBottom="1px solid"
                  borderColor="gray.100"
                  onClick={() => {
                    onDeleteOpen();
                    markerRef.current?.closePopup();
                  }}
                >
                  Remove this asset from plan
                </Button>
                {(!!floorPlanAsset.mapAffectedArea ||
                  !!floorPlanAsset.asset.assetAffectedAssets.length ||
                  !!floorPlanAsset.asset.assetAffectedByAssets.length) && (
                  <Button
                    width="full"
                    colorScheme="white"
                    _hover={{ backgroundColor: "white" }}
                    size="xs"
                    paddingY={{ base: "6", xl: "5" }}
                    textTransform="none"
                    justifyContent="flex-start"
                    color="gray.800"
                    onClick={() => {
                      handleAssetChange(floorPlanAsset.asset.id);
                      markerRef.current?.closePopup();
                    }}
                  >
                    {isSelectedAsset ? "Hide" : "Show"} affected areas for this
                    asset
                  </Button>
                )}
              </>
            )}
          </Box>
        </Popup>
      </Marker>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
        isOpen={isDeleteOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogBody textAlign="center" paddingRight="12">
              Are you sure you want to remove this asset from plan?
              <br />
              <Text as="strong">{floorPlanAsset.asset.name}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onDeleteClose}
                width="48%"
                isLoading={loading}
              >
                No, Don't Remove!
              </Button>
              <Button
                onClick={deleteFloorPlanAsset}
                colorScheme="red"
                ml="4%"
                width="48%"
                isLoading={loading}
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  ) : null;
};
