import { useCallback, useEffect, useState } from "react";

const useCountdown = (timeToCount = 60) => {
  const [timeLeft, setTimeLeft] = useState(timeToCount);

  const reset = useCallback(() => {
    setTimeLeft(timeToCount);
  }, [timeToCount]);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(intervalId);
  }, [timeLeft]);

  return { timeLeft, reset };
};

export default useCountdown;
