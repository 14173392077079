import { Icon, IconProps } from "@chakra-ui/react";

const FireExtinguisher = (props: IconProps & any) => {
  return (
    <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
      <path
        d="M108.945 15H62.07c-3.82 0-7.075 2.45-8.285 5.86h-3.433c-11.308 0-20.508 9.2-20.508 20.507v9.293c-3.41 1.21-5.86 4.466-5.86 8.285v4.909c0 12.066-1.974 24.367-5.709 35.571a2.93 2.93 0 0 0 2.78 3.856h23.437a2.928 2.928 0 0 0 2.78-3.856c-3.735-11.204-5.71-23.505-5.71-35.571v-4.909c0-3.819-2.449-7.075-5.859-8.285v-9.293c0-8.077 6.571-14.648 14.648-14.648h3.434c1.21 3.41 4.466 5.86 8.285 5.86H65v6.153c-6.678 1.361-11.719 7.28-11.719 14.354v58.984a2.93 2.93 0 0 0 2.93 2.93h35.156a2.93 2.93 0 0 0 2.93-2.93V53.086c0-7.074-5.04-12.993-11.719-14.354v-6.154h6.362c4.561 0 8.85 1.776 12.074 5.001a2.93 2.93 0 0 0 4.144-4.143c-4.061-4.06-9.376-6.411-15.072-6.69.358-.52.683-1.07.972-1.647a7.628 7.628 0 0 1 6.86-4.24h11.027a2.93 2.93 0 1 0 0-5.859Zm-68.44 82.422H25.042c3.148-10.728 4.802-22.249 4.802-33.568v-4.909a2.933 2.933 0 0 1 2.93-2.93 2.933 2.933 0 0 1 2.93 2.93v4.909c0 11.319 1.653 22.84 4.801 33.568Zm18.636-73.633a2.936 2.936 0 0 1 2.93-2.932H86.77c-.35.514-.67 1.055-.953 1.622a7.629 7.629 0 0 1-6.86 4.24H62.07a2.933 2.933 0 0 1-2.93-2.93Zm17.578 8.79v5.858h-5.86v-5.859h5.86ZM59.14 61.874h11.718v29.688H59.141V61.874Zm29.297-8.79v56.056H59.14v-11.72h14.648a2.93 2.93 0 0 0 2.93-2.929V58.945a2.93 2.93 0 0 0-2.93-2.93H59.141v-2.93c0-4.845 3.942-8.788 8.789-8.788h11.718c4.847 0 8.79 3.943 8.79 8.789Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default FireExtinguisher;
