import { useReactiveVar } from "@apollo/client";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import AddressFields from "../../../components/elements/addressFields";
import Autosuggest from "../../../components/elements/autosuggest";
import BillingInactivePlan from "../../../components/elements/billingInactivePlan";
import BillingNoAccess from "../../../components/elements/billingNoAccess";
import BillingProRated from "../../../components/elements/billingProRated";
import ContactCompanyUserField from "../../../components/elements/contactCompanyUserField";
import FormGroup from "../../../components/elements/formGroup";
import FormSectionTitle from "../../../components/elements/formSectionTitle";
import PageSpinner from "../../../components/elements/pageSpinner";
import {
  LOCATION_CREATED_MESSAGE,
  SUBLOCATION_CREATED_MESSAGE,
} from "../../../constants/lang/en";
import { inactivePlanStatus } from "../../../constants/plan";
import {
  CompanyPermissionType,
  LocationsDocument,
  useCompanyQuery,
  useLocationCreateMutation,
  useLocationTypesQuery,
} from "../../../graphql/graphql";
import { currentCompanyPlanVar } from "../../../graphql/reactiveVariables";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  address1Schema,
  address2Schema,
  citySchema,
  companyUserIdsSchema,
  descriptionSchema,
  nameSchema,
  stateSchema,
  typeSchema,
  yupObject,
  zipSchema,
} from "../../../utils/validation";

interface LocationsCreatePresenterProps {
  parentId?: string;
  parentName?: string;
  handleClose?: () => void;
  goBack?: boolean;
}

const LocationsCreatePresenter: React.FC<LocationsCreatePresenterProps> = ({
  parentId,
  parentName,
  handleClose,
  goBack,
}) => {
  const [proRateAllowed, setProRateAllowed] = useState(false);
  const { data: companyData } = useCompanyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const plan = useReactiveVar(currentCompanyPlanVar);
  const toast = useToast();
  const navigate = useNavigate();

  const [locationCreateMutation, { loading }] = useLocationCreateMutation();
  const { data } = useLocationTypesQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (
    data: LocationFormData,
    { setFieldError }: FormikHelpers<LocationFormData>
  ) => {
    try {
      const { data: serverData, errors } = await locationCreateMutation({
        variables: { data: { ...data, parentId } as any },
        refetchQueries: [{ query: LocationsDocument }],
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: parentId
            ? SUBLOCATION_CREATED_MESSAGE
            : LOCATION_CREATED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    if (handleClose) handleClose();
    onClose();
    if (goBack) {
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Add New {parentId ? `Sublocation for ${parentName}` : "Location"}
        </title>
      </Helmet>
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              Add New {parentId ? `Sublocation for ${parentName}` : "Location"}
            </DrawerHeader>
            <DrawerBody>
              {companyData ? (
                <>
                  {!parentId &&
                  inactivePlanStatus.includes(
                    companyData.company.planStripeStatus || ""
                  ) &&
                  plan &&
                  plan.proRateAmount &&
                  companyData.company.canAddLocation ===
                    CompanyPermissionType.ProRated ? (
                    <BillingInactivePlan type="locations" />
                  ) : parentId ||
                    companyData.company.canAddLocation ===
                      CompanyPermissionType.Yes ||
                    proRateAllowed ? (
                    <Formik
                      initialValues={{
                        type: "",
                        name: "",
                        description: "",
                        companyUserIds: [] as string[],
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zip: "",
                        countryAlpha2: "US",
                        coordinates: [0, 0],
                      }}
                      validationSchema={locationFormValidationSchema(parentId)}
                      onSubmit={onSubmit}
                    >
                      {({ getFieldProps }) => (
                        <Form id="location_create" noValidate>
                          {parentId ? (
                            <FormGroup label="Sublocation Type" name="type">
                              <Autosuggest
                                items={data?.locationTypes || []}
                                autoComplete="off"
                                name="type"
                                autoFocus
                              />
                            </FormGroup>
                          ) : (
                            <FormSectionTitle>Name</FormSectionTitle>
                          )}
                          <FormGroup
                            label={
                              parentId ? "Sublocation Name" : "Location Name"
                            }
                            name="name"
                            hint="Corporate Office, Warehouse, Parking Lot, etc."
                          >
                            <Input
                              autoComplete="off"
                              {...getFieldProps("name")}
                              autoFocus={!parentId}
                            />
                          </FormGroup>
                          <FormGroup label="Description" name="description">
                            <Textarea
                              autoComplete="off"
                              {...getFieldProps("description")}
                            />
                          </FormGroup>
                          {!parentId && (
                            <>
                              <FormSectionTitle>Location</FormSectionTitle>
                              <AddressFields />
                              <FormSectionTitle>Contact</FormSectionTitle>
                              <ContactCompanyUserField name="companyUserIds" />
                            </>
                          )}
                        </Form>
                      )}
                    </Formik>
                  ) : plan &&
                    plan.proRateAmount &&
                    companyData.company.canAddLocation ===
                      CompanyPermissionType.ProRated ? (
                    <BillingProRated
                      type="locations"
                      onAccept={() => setProRateAllowed(true)}
                      onCancel={onDrawerClose}
                    />
                  ) : (
                    <BillingNoAccess type="locations" />
                  )}
                </>
              ) : (
                <PageSpinner />
              )}
            </DrawerBody>
            <DrawerFooter>
              {(!!parentId ||
                companyData?.company.canAddLocation ===
                  CompanyPermissionType.Yes ||
                proRateAllowed) && (
                <Button
                  width="full"
                  type="submit"
                  isLoading={loading}
                  form="location_create"
                >
                  Save
                </Button>
              )}
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default LocationsCreatePresenter;

gql`
  mutation LocationCreate($data: LocationInput!) {
    locationCreate(data: $data) {
      ...LocationWithRelationsFragment
    }
  }
`;

export const locationFormValidationSchema = (parentId?: string) => {
  const validation: any = {
    name: nameSchema.label(parentId ? "Sublocation name" : "Location name"),
    description: descriptionSchema,
  };

  if (parentId) {
    validation.type = typeSchema.label("Sublocation type");
  } else {
    validation.address1 = address1Schema;
    validation.address2 = address2Schema;
    validation.city = citySchema;
    validation.state = stateSchema;
    validation.zip = zipSchema;
    validation.companyUserIds = companyUserIdsSchema.optional().min(0);
  }

  return yupObject().shape(validation);
};

export type LocationFormData = {
  type: string;
  name: string;
  description: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  countryAlpha2: string;
  coordinates: number[];
  companyUserIds: string[];
};
