import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { useFolderQuery } from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import Presenter from "./presenter";

interface FolderInfoPageProps {}

const FolderInfoPage: React.FC<FolderInfoPageProps> = () => {
  const { folderId } = useParams();
  const { data } = useFolderQuery({
    variables: { id: folderId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  return data ? (
    <Presenter
      folder={data.folder}
      canEditFolder={ROLES.foldersEdit.includes(currentCompanyRole.role)}
      canDeleteFolder={ROLES.foldersDelete.includes(currentCompanyRole.role)}
    />
  ) : null;
};

export default FolderInfoPage;
