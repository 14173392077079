import { Input, InputProps } from "@chakra-ui/react";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useRef } from "react";

interface AutosuggestProps extends InputProps {
  items: string[];
  name: string;
  autoFocus?: boolean;
}

const Autosuggest: React.FC<AutosuggestProps> = ({
  items,
  name,
  autoFocus = false,
}) => {
  const [filteredItems, setFilteredItems] = React.useState<string[]>(items);
  const inputRef = useRef<any>(null);
  const { values, setFieldValue, getFieldProps } = useFormikContext<any>();
  const value = get(values, name);

  const onItemSelect = (value: string) => {
    setFieldValue(name, value);
    setTimeout(() => {
      inputRef.current.blur();
    });
  };

  React.useEffect(() => {
    if (!value) {
      setFilteredItems(items);
    } else {
      setFilteredItems(
        items.filter((item) =>
          item.toLowerCase().startsWith(value.toLowerCase())
        )
      );
    }
  }, [items, value, setFilteredItems]);

  return (
    <Combobox
      aria-label="Suggestions"
      openOnFocus
      onSelect={onItemSelect}
      className="input-wrapper"
    >
      <ComboboxInput
        autocomplete={false}
        as={Input as any}
        {...getFieldProps(name)}
        autoComplete="off"
        autoFocus={autoFocus && !value}
        ref={inputRef}
      />
      <ComboboxPopover>
        <ComboboxList>
          {filteredItems.map((result) => (
            <ComboboxOption key={result} value={result} />
          ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

export default Autosuggest;
