import {
  EuiBasicTable,
  EuiButtonIcon,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { EuiBasicTableColumn } from "@elastic/eui/src/components/basic_table/basic_table";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import React, { ReactNode, useState } from "react";

import TruncatedText from "../../../../components/truncatedText";
import { WOAsset, WorkOrderAsset } from "../../../../models/workOrder";
import AssetInfoAccordion from "./AssetInfoAccordion";

interface WorkOrderCreateServiceAssetsTablePresenterProps {
  companyId?: string;
  assets?: WorkOrderAsset[] | null;
  onAssetDelete?: (asset: WorkOrderAsset) => void;
  onAddNewAssetClick?: () => void;
  editable?: boolean;
}

const WorkOrderCreateServiceAssetsTablePresenter: React.FC<
  WorkOrderCreateServiceAssetsTablePresenterProps
> = ({ companyId, assets, onAssetDelete, onAddNewAssetClick, editable }) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<{
    [id: string]: ReactNode;
  }>({});
  const [deletedAsset, setDeletedAsset] = useState<WorkOrderAsset | null>(null);

  const handleAssetDelete = () => {
    editable &&
      !!onAssetDelete &&
      !!deletedAsset &&
      onAssetDelete(deletedAsset);
    setDeletedAsset(null);
  };

  const toggleDetails = (item: WorkOrderAsset) => {
    if (item) {
      const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
      if (itemIdToExpandedRowMapValues[item.assetId]) {
        delete itemIdToExpandedRowMapValues[item.assetId];
      } else {
        itemIdToExpandedRowMapValues[item.assetId] = (
          <AssetInfoAccordion asset={item} companyId={companyId} />
        );
      }
      setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
    }
  };

  const columns: EuiBasicTableColumn<WorkOrderAsset>[] = [
    {
      field: "asset",
      name: "Asset`s Name",
      width: "25%",
      render: (asset: WOAsset) => {
        return <TruncatedText text={asset.name} truncate={true} />;
      },
    },
    {
      field: "asset",
      name: "Asset’s Category",
      width: "25%",
      render: (asset: WOAsset) => {
        return (
          <TruncatedText
            text={
              asset.assetCategory?.name || asset.assetType?.assetCategory?.name
            }
            truncate={true}
          />
        );
      },
    },
    {
      field: "asset",
      name: "Asset’s Type",
      width: "20%",
      render: (asset: WOAsset) => {
        return <TruncatedText text={asset.assetType?.name} truncate={true} />;
      },
    },
    {
      field: "serviceType",
      name: "Asset’s Service Type",
      width: "calc(30% - 66px)",
      render: (serviceType: { id: string; name: string }) => {
        return <TruncatedText text={serviceType.name} truncate={true} />;
      },
    },
    {
      name: "Action",
      width: "66px",
      isExpander: true,
      render: (item: WorkOrderAsset) => (
        <EuiFlexGroup
          wrap={false}
          alignItems="center"
          justifyContent={editable ? "flexStart" : "center"}
          gutterSize="m"
        >
          {editable ? (
            <EuiIcon
              type="trash"
              style={{ cursor: "pointer" }}
              onClick={() => setDeletedAsset(item)}
              css={css`
                &:hover {
                  color: ${euiTheme.colors.red["500"]};
                }
              `}
            />
          ) : null}
          <EuiButtonIcon
            color="text"
            onClick={() => toggleDetails(item)}
            aria-label={
              itemIdToExpandedRowMap[item?.assetId] ? "Collapse" : "Expand"
            }
            iconType={
              itemIdToExpandedRowMap[item?.assetId] ? "arrowDown" : "arrowRight"
            }
          />
        </EuiFlexGroup>
      ),
    },
  ];

  return (
    <>
      {!!assets?.length ? (
        <EuiFlexGroup
          direction="column"
          justifyContent="flexStart"
          alignItems="stretch"
          gutterSize="none"
          style={{
            width: "100%",
            marginBottom: euiTheme.size.l,
          }}
        >
          <EuiFlexItem
            grow={1}
            style={{
              minWidth: "360px",
              margin: `${euiTheme.size.s} 0`,
            }}
          >
            <EuiFlexGroup
              direction="row"
              wrap={false}
              justifyContent="flexStart"
              style={{ gap: euiTheme.size.s }}
            >
              <EuiText
                style={{
                  fontSize: euiTheme.size.base,
                  fontWeight: 500,
                  fontFamily: "Inter-medium, sans-serif",
                  lineHeight: "21px",
                }}
                color={euiTheme.colors.title}
              >
                Asset’s List
              </EuiText>
              {!!onAddNewAssetClick ? (
                <EuiText
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EuiIcon
                    type="plusInCircle"
                    size="m"
                    style={{
                      fill: euiTheme.colors.secondary["500"],
                      cursor: "pointer",
                    }}
                    onClick={onAddNewAssetClick}
                  />
                </EuiText>
              ) : null}
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem
            grow={1}
            style={{
              minWidth: "360px",
            }}
          >
            <EuiBasicTable
              tableCaption="Asset`s List"
              items={assets}
              itemId="assetId"
              rowHeader="name"
              columns={columns}
              itemIdToExpandedRowMap={itemIdToExpandedRowMap}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : null}
      {!!deletedAsset && (
        <EuiConfirmModal
          className={"confirm-modal"}
          onCancel={() => setDeletedAsset(null)}
          maxWidth={true}
          onConfirm={handleAssetDelete}
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          buttonColor="danger"
        >
          <EuiFlexGroup
            direction="column"
            justifyContent="flexStart"
            alignItems="stretch"
            style={{ width: "100%", margin: euiTheme.size.base }}
          >
            <EuiFlexItem
              grow={1}
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <EuiIcon type="alert" color="danger" size="l" />
              <EuiText
                style={{
                  fontSize: euiTheme.size.base,
                  marginLeft: euiTheme.size.base,
                }}
                color={euiTheme.colors.title}
              >
                Remove this Asset from Work Order?
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiText
                style={{ fontSize: euiTheme.size.base }}
                color={euiTheme.colors.title}
              >
                Are you sure, you want to remove{" "}
                <span style={{ fontWeight: 600 }}>
                  {deletedAsset.asset?.name}
                </span>{" "}
                from the Work Order?
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiConfirmModal>
      )}
    </>
  );
};

export default WorkOrderCreateServiceAssetsTablePresenter;
