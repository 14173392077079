const typography = {
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
    mono: "monospace",
  },
  fontSizes: {
    xs: ".75rem",
    sm: ".875rem",
    base: "1rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.5rem",
    "5xl": "3.375rem",
    "6xl": "4rem",
    "7xl": "5rem",
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
  },
};

export default typography;
