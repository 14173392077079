import { Icon, IconProps } from "@chakra-ui/react";

const Sprinkler = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M108.246 105.492H72.653v-9.24h6.096a2.754 2.754 0 0 0 2.754-2.754v-11.8a2.754 2.754 0 0 0-2.754-2.753H49.25a2.754 2.754 0 0 0-2.753 2.754v11.799a2.754 2.754 0 0 0 2.753 2.753h6.096v9.241H19.754a2.754 2.754 0 0 0 0 5.508h88.492a2.754 2.754 0 1 0 0-5.508Zm-56.24-14.748v-6.292h23.989v6.292h-23.99Zm15.14 14.748h-6.292v-9.24h6.291v9.24ZM55.811 57.599l3.1 7.484-7.483-3.1a2.754 2.754 0 0 0-2.108 5.088l13.626 5.645a2.754 2.754 0 0 0 2.108 0L78.68 67.07a2.754 2.754 0 0 0-2.108-5.088l-7.484 3.1 3.1-7.484A2.754 2.754 0 1 0 67.1 55.49L64 62.975l-3.1-7.484A2.753 2.753 0 1 0 55.81 57.6ZM37.056 61.992l2.726 1.128c1.768.732 3.181-.484 3.598-1.49a2.754 2.754 0 0 0-1.49-3.598l-2.726-1.13a2.754 2.754 0 0 0-2.108 5.09ZM24.793 56.912l2.726 1.129c1.767.731 3.18-.485 3.598-1.491a2.754 2.754 0 0 0-1.491-3.598L26.9 51.823a2.754 2.754 0 1 0-2.107 5.089ZM100.482 58.04l2.725-1.128a2.754 2.754 0 0 0-2.107-5.089l-2.726 1.13a2.754 2.754 0 0 0-1.49 3.597c.42 1.015 1.852 2.214 3.598 1.49ZM84.62 61.63c.417 1.008 1.847 2.215 3.598 1.49l2.725-1.128a2.754 2.754 0 0 0-2.107-5.09l-2.725 1.13a2.754 2.754 0 0 0-1.491 3.598ZM50.732 45.335l1.128 2.725c.42 1.015 1.852 2.214 3.598 1.492a2.754 2.754 0 0 0 1.491-3.598l-1.128-2.726a2.754 2.754 0 0 0-5.09 2.107ZM41.701 23.534c.42 1.014 1.852 2.213 3.598 1.49a2.754 2.754 0 0 0 1.491-3.597L45.662 18.7a2.754 2.754 0 1 0-5.09 2.107l1.13 2.726ZM45.652 33.071l1.129 2.726c.42 1.014 1.851 2.213 3.598 1.49a2.754 2.754 0 0 0 1.49-3.597l-1.128-2.725a2.754 2.754 0 0 0-5.089 2.106ZM72.542 49.551c1.768.732 3.182-.487 3.598-1.49l1.128-2.726a2.754 2.754 0 1 0-5.089-2.107l-1.128 2.725a2.754 2.754 0 0 0 1.491 3.598ZM82.7 25.024c1.768.732 3.183-.487 3.599-1.49l1.128-2.726a2.754 2.754 0 1 0-5.089-2.107l-1.128 2.726a2.754 2.754 0 0 0 1.49 3.597ZM77.621 37.288c1.768.732 3.183-.487 3.598-1.491l1.129-2.725a2.754 2.754 0 1 0-5.09-2.107L76.13 33.69a2.754 2.754 0 0 0 1.492 3.598Z"
      fill="currentColor"
    />
  </Icon>
);

export default Sprinkler;
