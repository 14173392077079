import React from "react";

import { useLocationQuery } from "../../../graphql/graphql";
import LocationsCreatePresenter from "./presenter";

interface SubLocationsCreatePresenterProps {
  parentId: string;
  handleClose?: () => void;
  goBack?: boolean;
}

const SubLocationsCreatePresenter: React.FC<
  SubLocationsCreatePresenterProps
> = ({ parentId, handleClose, goBack }) => {
  const { data } = useLocationQuery({
    variables: { id: parentId },
  });

  return (
    <LocationsCreatePresenter
      parentId={parentId}
      parentName={data?.location.name}
      handleClose={handleClose}
      goBack={goBack}
    />
  );
};

export default SubLocationsCreatePresenter;
