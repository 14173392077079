import { useToast } from "@chakra-ui/react";
import { zonedTimeToUtc } from "date-fns-tz";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";

import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyStartUpDocument,
  useAssetLazyQuery,
  useMaintenanceUpdateMutation,
  useMaintenanceWithContactsQuery,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { MaintenanceFormData } from "../create/presenter";
import Presenter from "./presenter";

interface MaintenanceEditProps {
  maintenanceId: string;
  assetId?: string;
  onClose: () => void;
  type?: "full" | "recurring";
}

const MaintenanceEdit: React.FC<MaintenanceEditProps> = ({
  maintenanceId,
  assetId,
  onClose,
  type = "full",
}) => {
  const toast = useToast();
  const [maintenanceEditMutation, { loading }] = useMaintenanceUpdateMutation({
    refetchQueries: [{ query: CompanyStartUpDocument }],
  });
  const { data } = useMaintenanceWithContactsQuery({
    variables: { id: maintenanceId },
    fetchPolicy: "cache-and-network",
  });
  const [refetchAsset] = useAssetLazyQuery({ fetchPolicy: "network-only" });
  const maintenance = data?.maintenance;

  const onSubmit = async (
    data: MaintenanceFormData,
    { setFieldError }: FormikHelpers<MaintenanceFormData>
  ) => {
    if (!maintenance) return;

    try {
      const { data: serverData, errors } = await maintenanceEditMutation({
        variables: {
          data: {
            ...data,
            startDateTime: zonedTimeToUtc(
              data.startDateTime,
              data.timezone
            ).getTime(),
            endDateTime: data.endDateTime
              ? zonedTimeToUtc(data.endDateTime, data.timezone).getTime()
              : null,
          },
          id: maintenance.id,
        },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        if (assetId) {
          await refetchAsset({ variables: { id: assetId } });
        }
        onClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return maintenance ? (
    <Presenter
      maintenance={maintenance}
      handleSubmit={onSubmit}
      loading={loading}
      type={type}
    />
  ) : null;
};

export default MaintenanceEdit;

gql`
  query MaintenanceWithContacts($id: UUID!) {
    maintenance(id: $id) {
      ...MaintenanceWithContactsFragment
    }
  }
`;
