import { Icon, IconProps } from "@chakra-ui/react";

const InitiatingDevice = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M103 96h2V52.02H23V96h80Zm11 22c0 3.295-2.705 6-6 6H20c-3.295 0-6-2.705-6-6V10c0-3.295 2.705-6 6-6h88c3.295 0 6 2.705 6 6v108Z"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity="0"
    />
    <path
      d="M47.165 73.171a1.095 1.095 0 0 0-1.582 0l-.808.83.808.828a1.1 1.1 0 0 0 1.304.21l.018.033m.26-1.901-.252 1.917-.008-.016m.26-1.901a1.195 1.195 0 0 1 0 1.658v-1.658Zm-.26 1.901-.018-.034a1.17 1.17 0 0 0 .278-.209l-.017.018-.004.004m-.239.221.24-.221m0 0ZM43.377 74l-1.137 1.164h-5.616c-.61 0-1.125-.509-1.125-1.166 0-.657.514-1.167 1.125-1.167h5.614L43.378 74Zm.26 1.164.438-.449.438.45h-.876Zm-.975 1h3.2l-1.948 1.998a1.1 1.1 0 0 1-.791.337 1.1 1.1 0 0 1-.791-.337 1.195 1.195 0 0 1 0-1.659l.33-.339Zm1.252-6.327 1.945 1.994h-3.2l-.327-.336a1.195 1.195 0 0 1 0-1.658c.439-.45 1.143-.45 1.582 0Zm.602 2.994-.44.452-.441-.452h.88ZM80.835 74.829c.439.45 1.143.45 1.582 0l.808-.83-.808-.828a1.1 1.1 0 0 0-1.304-.21l-.018-.033m-.26 1.901.252-1.917.008.016m-.26 1.901a1.195 1.195 0 0 1 0-1.658v1.658Zm.26-1.901.018.034a1.17 1.17 0 0 0-.278.209l.017-.018.004-.004m.239-.221-.24.221m0 0Zm3.767.85 1.137-1.164h5.616c.61 0 1.125.509 1.125 1.166 0 .657-.514 1.167-1.125 1.167H85.76L84.622 74Zm-.26-1.164-.438.449-.438-.45h.876Zm.975-1h-3.2l1.948-1.998a1.1 1.1 0 0 1 .791-.337c.285 0 .57.111.791.337a1.195 1.195 0 0 1 0 1.659l-.33.339Zm-1.252 6.327-1.945-1.994h3.2l.327.336c.446.457.446 1.2 0 1.658-.439.45-1.143.45-1.582 0Zm-.602-2.994.44-.452.441.452h-.88Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 66.995c3.854 0 7 3.147 7 7.015 0 3.85-3.148 6.995-7 6.995s-7-3.145-7-6.995c0-3.868 3.146-7.015 7-7.015Zm11 7.015C75 67.927 70.067 63 64 63s-11 4.927-11 11.01C53 80.073 57.931 85 64 85s11-4.927 11-10.99Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75 74.01C75 67.927 70.067 63 64 63s-11 4.927-11 11.01C53 80.073 57.931 85 64 85s11-4.927 11-10.99Zm-4 0c0-3.868-3.146-7.015-7-7.015s-7 3.147-7 7.015c0 3.85 3.148 6.995 7 6.995s7-3.145 7-6.995Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 63c6.067 0 11 4.927 11 11.01C75 80.073 70.069 85 64 85s-11-4.927-11-10.99C53 67.927 57.933 63 64 63Zm0 3.995c3.854 0 7 3.147 7 7.015 0 3.85-3.148 6.995-7 6.995s-7-3.145-7-6.995c0-3.868 3.146-7.015 7-7.015Z"
      fill="currentColor"
    />
    <path
      d="M40.09 27.497H22.91c-.8 0-1.41-.615-1.41-1.334 0-.72.61-1.334 1.41-1.334h17.18c.8 0 1.41.615 1.41 1.334 0 .719-.61 1.334-1.41 1.334Zm0 5.501H22.91c-.8 0-1.41-.614-1.41-1.333 0-.72.61-1.334 1.41-1.334h17.18c.8 0 1.41.614 1.41 1.334 0 .719-.61 1.333-1.41 1.333ZM34.364 38.5h-5.728c-.799 0-1.41-.615-1.41-1.334 0-.719.611-1.334 1.41-1.334h5.729c.799 0 1.409.615 1.409 1.334 0 .72-.61 1.334-1.41 1.334Zm-2.807-18.332c-.802 0-1.42-.618-1.42-1.334 0-.723.605-1.334 1.399-1.334h.02c.798 0 1.41.615 1.41 1.334 0 .719-.612 1.334-1.41 1.334ZM105.091 27.497H87.909c-.799 0-1.409-.615-1.409-1.334 0-.72.61-1.334 1.41-1.334h17.181c.799 0 1.409.615 1.409 1.334 0 .719-.61 1.334-1.409 1.334Zm0 5.501H87.909c-.799 0-1.409-.614-1.409-1.333 0-.72.61-1.334 1.41-1.334h17.181c.799 0 1.409.614 1.409 1.334 0 .719-.61 1.333-1.409 1.333ZM99.364 38.5h-5.729c-.799 0-1.41-.615-1.41-1.334 0-.719.611-1.334 1.41-1.334h5.728c.799 0 1.41.615 1.41 1.334 0 .72-.611 1.334-1.41 1.334Zm-2.808-18.332c-.802 0-1.42-.618-1.42-1.334 0-.723.606-1.334 1.399-1.334h.02c.798 0 1.41.615 1.41 1.334 0 .719-.612 1.334-1.41 1.334Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m63.536 41.963.464.008c6.626 0 12-5.411 12-12.124C76 21.392 69.48 14.495 61.237 14c-.47 4.916-4.336 8.842-9.236 9.317L52 29.847c0 6.555 5.126 11.87 11.536 12.116Zm-1.95-3.973c-3.469-1.05-5.997-4.295-6.014-8.12v-3.655l.619-.255a14.029 14.029 0 0 0 7.314-6.985l.404-.855.877.357c4.467 1.817 7.642 6.224 7.642 11.37 0 2.337-.948 4.46-2.457 6.005l-.01.01c-1.53 1.524-3.637 2.484-5.961 2.484a8.324 8.324 0 0 1-2.415-.356Z"
      fill="currentColor"
    />
  </Icon>
);

export default InitiatingDevice;
