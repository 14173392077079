import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import { FILE_DELETED_MESSAGE } from "../../../constants/lang/en";
import {
  FileFragmentFragment,
  useFileDeleteMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import { setGenericMessage } from "../../../utils/serverErrors";

interface FileDeletePresenterProps {
  file: FileFragmentFragment;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const FileDeletePresenter: React.FC<FileDeletePresenterProps> = ({
  file,
  isOpen,
  onOpen,
  onClose,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const navigate = useNavigate();

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [fileDeleteMutation, { loading: deleteLoading }] =
    useFileDeleteMutation();

  const deleteFile = async () => {
    try {
      const { errors } = await fileDeleteMutation({
        variables: { ids: [file.id] },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        client.cache.evict({ id: `File:${file.id}` });
        onClose();
        setTimeout(() => {
          navigate(getRoutePath("foldersShow", { folderId: file.folderId }));
        }, 500);
        toast({
          description: FILE_DELETED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogBody textAlign="center">
            Are you sure you want to delete the following file?
            <br />
            <Text as="strong">{file.name}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              disabled={deleteLoading}
              onClick={onClose}
              width="48%"
            >
              No, Don't Delete!
            </Button>
            <Button onClick={deleteFile} colorScheme="red" ml="4%" width="48%">
              Yes, Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default FileDeletePresenter;

gql`
  mutation FileDelete($ids: [UUID!]!) {
    fileDelete(ids: $ids)
  }
`;
