import React from "react";

import { WorkOrder } from "../../../../models/workOrder";
import WorkOrderCreateServicesAttachmentsPresenter from "./presenter";

export const enum AttachmentsTableModeEnum {
  viewOnly,
  create,
  edit,
}

interface WorkOrderCreateServicesAttachmentsProps {
  workOrder: WorkOrder;
  onFilesAdded?: (files: File[]) => void;
  onFileDelete?: (files: File) => void;
  mode?: AttachmentsTableModeEnum;
}

const WorkOrderCreateServicesAttachments: React.FC<
  WorkOrderCreateServicesAttachmentsProps
> = ({ workOrder, onFilesAdded, onFileDelete, mode }) => {
  return (
    <>
      <WorkOrderCreateServicesAttachmentsPresenter
        workOrder={workOrder}
        onFilesAdded={onFilesAdded}
        onFileDelete={onFileDelete}
        mode={mode}
      />
    </>
  );
};

export default WorkOrderCreateServicesAttachments;
