import React from "react";

interface AssetIconProps {
  style?: any;
}

const AttachmentsIcon = ({ style }: AssetIconProps) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M7.38047 1.51408L2.28354 6.42781C1.54347 7.14127 1.43428 8.31505 2.13464 9.06487C2.84789 9.83066 4.07004 9.89406 4.86438 9.20643C4.89014 9.18415 4.91524 9.16119 4.93973 9.1376L9.18071 5.04908C9.33167 4.90355 9.33167 4.66759 9.18071 4.52203C9.02975 4.3765 8.78499 4.3765 8.634 4.52203L4.43981 8.56545C4.01083 8.97901 3.31132 9.05827 2.84175 8.68799C2.34454 8.29854 2.26896 7.59423 2.67295 7.1149C2.69807 7.0851 2.72477 7.05656 2.75294 7.0294L7.9272 2.04113C8.68203 1.31343 9.90586 1.31343 10.6607 2.04113C11.4155 2.76882 11.4155 3.94866 10.6607 4.67638L5.47484 9.67584C4.47546 10.6393 2.85239 10.7952 1.76266 9.92759C0.607213 9.0134 0.439237 7.36921 1.38757 6.25527C1.44372 6.18932 1.50316 6.12605 1.56574 6.06572L5.90051 1.88676C6.05147 1.74122 6.05147 1.50526 5.90051 1.35971C5.74955 1.21415 5.50479 1.21417 5.3538 1.35971L1.01903 5.53865C-0.339662 6.84848 -0.339686 8.97216 1.01901 10.282L1.01903 10.282V10.2821C2.65915 11.8632 4.92035 11.2644 6.01373 10.2104L11.2074 5.20339C12.2642 4.1846 12.2642 2.53282 11.2074 1.51405C10.1506 0.495312 8.43727 0.495312 7.38047 1.51408Z"
      fill="inherit"
    />
  </svg>
);

export default AttachmentsIcon;
