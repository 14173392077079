import { Box } from "@chakra-ui/react";
import React from "react";
import { Cell, Pie, PieChart, PieProps, Tooltip } from "recharts";

import { COLORS } from "../../../utils/chart";

interface ChartPieProps {
  data: PieProps["data"];
  label: string;
  dataKey?: string;
  boxSize?: number;
  handleClickCurrent?: () => void;
  handleClickDueIn30?: () => void;
  handleClickPastDue?: () => void;
}

const ChartPie: React.FC<ChartPieProps> = ({
  data,
  label,
  dataKey = "value",
  handleClickCurrent,
  handleClickDueIn30,
  handleClickPastDue,
  boxSize = 150,
}) => {
  return (
    <Box position="relative">
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        fontSize=".6rem"
        textAlign="center"
        maxWidth="60px"
        maxHeight="58px"
        overflow="hidden"
      >
        {label}
      </Box>
      <PieChart width={boxSize} height={boxSize}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          innerRadius={40}
          outerRadius={60}
          fill="#8884d8"
          dataKey={dataKey}
        >
          {!!data &&
            data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                onClick={
                  index === 0
                    ? handleClickCurrent
                    : index === 1
                    ? handleClickDueIn30
                    : index === 2
                    ? handleClickPastDue
                    : undefined
                }
              />
            ))}
        </Pie>
        <Tooltip
          contentStyle={{ padding: "5px" }}
          itemStyle={{ fontSize: ".6rem", lineHeight: ".6rem" }}
          separator=""
          formatter={(value: number, label: string) =>
            label === "No data" ? "" : `: ${value}`
          }
        />
      </PieChart>
    </Box>
  );
};

export default ChartPie;
