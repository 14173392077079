import {
  EuiAvatar,
  EuiComment,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownFormat,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import moment from "moment";
import React, { FC } from "react";

import { WOComment } from "../../../../../../../graphql/types/queries/workOrdersQuery";

interface CommentItemPresenterProps {
  comment: WOComment;
  timezone: string;
}

const CommentItemPresenter: FC<CommentItemPresenterProps> = ({
  comment,
  timezone = "",
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const DATE_TIME_FORMAT = "MM/DD/YYYY hh:mmA";
  const textStyle = {
    fontSize: euiTheme.sizes["14px"],
    lineHeight: euiTheme.sizes["21px"],
    fontWeight: euiTheme.font.weight.regular,
  };

  return (
    <EuiFlexGroup
      justifyContent="flexStart"
      direction="column"
      alignItems="stretch"
      gutterSize="none"
      style={{
        marginBottom: euiTheme.sizes["31px"],
      }}
    >
      <EuiComment
        username={comment.user.fullName}
        timelineAvatar={
          <EuiAvatar
            color={euiTheme.colors.schemePrimary["500"]}
            name={comment.user.fullName}
          />
        }
      >
        <EuiFlexItem
          style={{
            flexDirection: "row",
            marginBottom: euiTheme.size.base,
            paddingTop: euiTheme.size.xs,
            ...textStyle,
          }}
        >
          <span
            style={{
              fontWeight: euiTheme.font.weight.bold,
              color: euiTheme.colors.darkShade,
              marginRight: euiTheme.size.base,
            }}
          >
            {comment.user.fullName}
          </span>
          <span style={{ color: euiTheme.colors.text }}>
            {moment(comment.createdAt).tz(timezone).format(DATE_TIME_FORMAT)}
          </span>
        </EuiFlexItem>
      </EuiComment>
      <EuiMarkdownFormat
        style={{
          paddingLeft: euiTheme.size.xxxl,
          ...textStyle,
        }}
      >
        {comment.text}
      </EuiMarkdownFormat>
    </EuiFlexGroup>
  );
};

export default CommentItemPresenter;
