import { Box, Heading } from "@chakra-ui/react";
import { FC } from "react";

interface StepHeaderProps {
  number: string;
  title: string;
}

const StepHeader: FC<StepHeaderProps> = ({ number, title }) => {
  return (
    <Heading as="h3" size="xl" display="flex" alignItems="center">
      <Box flexShrink={0} width="8" marginRight="3" textAlign="center">
        {number}.
      </Box>
      <Box flexGrow={1}>{title}</Box>
    </Heading>
  );
};

export default StepHeader;
