import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { Column, useFlexLayout, useTable } from "react-table";

import AssetIcon from "../../../../components/elements/assetIcons";
import Link from "../../../../components/elements/link";
import QrCodes from "../../../../components/elements/qrCodes";
import Status from "../../../../components/elements/status";
import TooltipName from "../../../../components/elements/tooltipName";
import { AssetWithTypeFragmentFragment } from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";

interface AssetTableProps {
  assets: Array<AssetWithTypeFragmentFragment>;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
}

const AssetTable: React.FC<AssetTableProps> = ({
  assets,
  canEditAsset,
  canDeleteAsset,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const columns = React.useMemo(() => {
    let assetColumns: Column<AssetWithTypeFragmentFragment>[] = [
      {
        Header: "",
        id: "name",
        accessor: (asset: AssetWithTypeFragmentFragment) => asset,
        width: 320,
        Cell: ({ value }: { value: AssetWithTypeFragmentFragment }) => (
          <Flex
            alignItems="center"
            paddingLeft="4"
            position="relative"
            marginLeft={{ base: "-2", md: "-3" }}
          >
            <Status status={value.maintenanceStatus} />
            <Link
              to={getRoutePath("assetsShow", {
                assetId: value.id,
              })}
              variant="unstyled"
              key={value.id}
              borderWidth="1px"
              borderRadius="lg"
              height="auto"
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="left"
              textTransform="none"
              fontSize="sm"
              fontWeight="normal"
              _hover={{ color: "secondary.600" }}
            >
              <AssetIcon
                iconName={value.assetType.iconName}
                iconColor={value.assetType.misc.resolvedIconColor}
                iconType={value.assetType.iconType}
                iconSize="sm"
              />
              <TooltipName marginLeft="2" name={value.name} />
            </Link>
          </Flex>
        ),
      },
    ];

    if (!isMobile) {
      assetColumns.push({
        id: "actions",
        width: 65,
        Header: "",
        accessor: (asset: AssetWithTypeFragmentFragment) => asset,
        Cell: ({ value }: { value: AssetWithTypeFragmentFragment }) => (
          <AssetActions
            asset={value}
            canDeleteAsset={canDeleteAsset}
            canEditAsset={canEditAsset}
          />
        ),
      });
    }
    return assetColumns;
  }, [canDeleteAsset, canEditAsset, isMobile]);

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns,
      data: assets,
    },
    useFlexLayout
  );

  return (
    <Box width="100%" overflowX="auto" marginTop="-4">
      <Box {...getTableProps()}>
        {/* {headerGroups.map((headerGroup) => (
          <Box
            {...headerGroup.getHeaderGroupProps()}
            alignItems="center"
            fontSize="xs"
            color="gray.600"
            textTransform="uppercase"
            backgroundColor="white"
            position="relative"
            zIndex="1"
            marginBottom="-4"
          >
            {headerGroup.headers.map((column) => (
              <Box
                padding={{ base: "2", md: "3" }}
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </Box>
            ))}
          </Box>
        ))} */}
        <Box {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Box
                width="full"
                alignItems="center"
                fontSize="sm"
                backgroundColor="secondary.10"
                marginTop="4"
                _hover={{ boxShadow: "md" }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <Box
                      paddingX={{ base: "2", md: "3" }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default AssetTable;

interface AssetListProps {
  asset: AssetWithTypeFragmentFragment;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
}

const AssetActions: React.FC<AssetListProps> = ({
  asset,
  canEditAsset,
  canDeleteAsset,
}) => {
  return (
    <Flex
      alignItems="center"
      paddingY="3"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      width="full"
      justifyContent="flex-end"
    >
      <QrCodes
        links={[
          {
            path: getRoutePath("assetsShow", { assetId: asset.id }),
            name: asset.name,
          },
        ]}
        printDocumentTitle={asset.name}
      />
    </Flex>
  );
};
