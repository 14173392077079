import { Icon, IconProps } from "@chakra-ui/react";

const TicketsHover = (props: IconProps & any) => (
  <Icon viewBox="0 0 48 48" boxSize={{ base: "6", md: "8" }} {...props}>
    <path
      d="M26.21 47.297H3.867a2.812 2.812 0 0 1-2.812-2.813V3.516A2.812 2.812 0 0 1 3.867.703H26.21a2.812 2.812 0 0 1 2.812 2.813v40.968a2.813 2.813 0 0 1-2.812 2.813Z"
      fill="#C68500"
    />
    <path
      d="M26.21.703h-3.516a2.812 2.812 0 0 1 2.812 2.813v40.968a2.813 2.813 0 0 1-2.812 2.813h3.515a2.813 2.813 0 0 0 2.813-2.813V3.516A2.813 2.813 0 0 0 26.209.703Z"
      fill="#C68500"
    />
    <path
      d="M26.21 48H3.866a3.52 3.52 0 0 1-3.515-3.516V3.516A3.52 3.52 0 0 1 3.867 0H26.21a3.52 3.52 0 0 1 3.516 3.516v40.968A3.52 3.52 0 0 1 26.21 48ZM3.866 1.406c-1.163 0-2.11.947-2.11 2.11v40.968c0 1.163.947 2.11 2.11 2.11H26.21c1.164 0 2.11-.947 2.11-2.11V3.516c0-1.163-.946-2.11-2.11-2.11H3.867Z"
      fill="#FFC84E"
    />
    <path
      d="M20.448 4.501H9.628a.937.937 0 0 1-.937-.937V.704h12.695v2.86c0 .517-.42.937-.938.937ZM24.639 34.067H5.437a.703.703 0 1 1 0-1.406H24.64a.703.703 0 1 1 0 1.406ZM24.639 38.286H5.437a.703.703 0 1 1 0-1.406H24.64a.703.703 0 1 1 0 1.406ZM17.608 42.505H5.438a.703.703 0 1 1 0-1.406h12.17a.703.703 0 1 1 0 1.406Z"
      fill="#FFC84E"
    />
    <path
      d="M44.836 26.04H13.941a.469.469 0 0 1-.346-.785l1.85-2.025a.938.938 0 0 0 .246-.633v-7.831a2.813 2.813 0 0 1 2.812-2.813h26.333a2.812 2.812 0 0 1 2.813 2.813v8.462a2.813 2.813 0 0 1-2.813 2.812Z"
      fill="#DA9906"
    />
    <path
      d="M44.836 11.953h-2.813a2.812 2.812 0 0 1 2.813 2.813v8.462a2.813 2.813 0 0 1-2.813 2.812h2.813a2.813 2.813 0 0 0 2.812-2.812v-8.462a2.812 2.812 0 0 0-2.812-2.813Z"
      fill="#BC7B00"
    />
    <path
      d="M36.461 21.712h-.004c-.591.003-1.228.005-1.69.005v-6.143a.703.703 0 1 0-1.406 0v6.84c0 .343.247.636.584.694.05.008 1.747.014 2.52.01a.703.703 0 0 0-.004-1.406ZM25.479 14.871a.703.703 0 0 0-.704.703v2.603h-3.331v-2.603a.703.703 0 1 0-1.406 0v6.847a.703.703 0 1 0 1.406 0v-2.838h3.331v2.838a.703.703 0 1 0 1.407 0v-6.847a.703.703 0 0 0-.703-.703ZM31.15 16.34a.703.703 0 1 0 0-1.406H28.29a.703.703 0 0 0-.703.703v6.784c0 .388.315.703.703.703h2.858a.703.703 0 1 0 0-1.407h-2.155v-1.985h1.945a.703.703 0 1 0 0-1.407h-1.945V16.34h2.155ZM41.18 14.87h-1.906c-.279-.027-.738.308-.703.704v6.846a.703.703 0 1 0 1.406 0v-2.274c.419-.003.926-.005 1.203-.005 1.474 0 2.673-1.182 2.673-2.635 0-1.453-1.199-2.635-2.673-2.635Zm0 3.865-1.194.004-.007-2.462h1.201c.687 0 1.267.563 1.267 1.229 0 .666-.58 1.229-1.267 1.229Z"
      fill="#FFC84E"
    />
  </Icon>
);

export default TicketsHover;
