import React from "react";

import { WorkOrder } from "../../models/workOrder";
import WorkOrderCreateVerifyAndSavePresenter from "./presenter";

export type WorkOrderCreateVerifyAndSaveProps = {
  workOrder: WorkOrder;
  active: boolean;
};

const WorkOrderCreateVerifyAndSave: React.FC<
  WorkOrderCreateVerifyAndSaveProps
> = ({ workOrder, active = false }: WorkOrderCreateVerifyAndSaveProps) => {
  return (
    <>
      <WorkOrderCreateVerifyAndSavePresenter
        workOrder={workOrder}
        active={active}
      />
    </>
  );
};
export default WorkOrderCreateVerifyAndSave;
