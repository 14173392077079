import { EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { useCallback } from "react";

import Accordion from "../../../components/accordion";
import Label from "../../../components/label";
import ViewEditText from "../../components/viewEditText";

export interface CompanyInfoSectionData {
  companyName?: string | null;
  companyContactFullName?: string | null;
  companyContactEmail?: string | null;
  companyContactMobilePhone?: string | null;
  companyContactWorkPhone?: string | null;
}

interface WorkOrderEditCompanyInfoPresenterProps {
  sectionData?: CompanyInfoSectionData;
  onSectionDataChange: (workOrder: CompanyInfoSectionData) => void;
}

const WorkOrderEditCompanyInfoPresenter: React.FC<
  WorkOrderEditCompanyInfoPresenterProps
> = ({ sectionData, onSectionDataChange, children }) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const minSelectorWidth = "235px";
  const maxSelectorWidth = "235px";

  const handleCompanyContactFullNameChange = useCallback(
    (companyContactFullName: string) => {
      onSectionDataChange({ ...sectionData, companyContactFullName });
    },
    [onSectionDataChange, sectionData]
  );
  const handleCompanyContactEmailChange = useCallback(
    (companyContactEmail: string) => {
      onSectionDataChange({ ...sectionData, companyContactEmail });
    },
    [onSectionDataChange, sectionData]
  );
  const handleCompanyContactWorkPhoneChange = useCallback(
    (phoneNumber: string) => {
      onSectionDataChange({
        ...sectionData,
        companyContactWorkPhone: phoneNumber || null,
      });
    },
    [onSectionDataChange, sectionData]
  );
  const handleCompanyContactMobilePhoneChange = useCallback(
    (phoneNumber: string) => {
      onSectionDataChange({
        ...sectionData,
        companyContactMobilePhone: phoneNumber || null,
      });
    },
    [onSectionDataChange, sectionData]
  );

  return (
    <EuiFlexItem style={{ minWidth: "100%" }} grow={0}>
      <Accordion
        id="wo-company-info"
        label="Company Info"
        required={false}
        initialIsOpen={false}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
          style={{ position: "relative" }}
        >
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Company Name" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <EuiText
                color={euiTheme.colors.text}
                className={"ellipsis"}
                style={{
                  lineHeight: euiTheme.sizes["21px"],
                  fontSize: euiTheme.sizes["14px"],
                }}
              >
                {sectionData?.companyName}
              </EuiText>
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Full Name" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.companyContactFullName}
                onValueChange={handleCompanyContactFullNameChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Email" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.companyContactEmail}
                onValueChange={handleCompanyContactEmailChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Phone No (work)" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.companyContactWorkPhone}
                onValueChange={handleCompanyContactWorkPhoneChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Phone No (mobile)" />
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <ViewEditText
                value={sectionData?.companyContactMobilePhone}
                onValueChange={handleCompanyContactMobilePhoneChange}
                compressed={true}
                placeholder={"None"}
              />
            </div>
          </EuiFlexItem>
          {children}
        </EuiFlexGroup>
      </Accordion>
    </EuiFlexItem>
  );
};

export default WorkOrderEditCompanyInfoPresenter;
