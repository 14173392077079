import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Flex,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React from "react";

import LabeledMenuButton from "../../../components/elements/labeledMenuButton";
import { getAssetsDeletedMessage } from "../../../constants/lang/en";
import {
  AssetIndexFragmentFragment,
  CompanyStartUpDocument,
  useAssetDeleteMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";

interface AssetBulkDeleteProps {
  selectedIds: Array<string | undefined>;
  canDeleteAsset: boolean;
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
  assets: Array<AssetIndexFragmentFragment>;
}

const AssetsBulkDelete: React.FC<AssetBulkDeleteProps> = ({
  selectedIds,
  canDeleteAsset,
  assets,
  toggleAllRowsSelected,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null);
  const [assetDeleteMutation, { loading }] = useAssetDeleteMutation({
    refetchQueries: [{ query: CompanyStartUpDocument }],
  });

  const deleteItems = async () => {
    try {
      const { errors } = await assetDeleteMutation({
        variables: { ids: selectedIds },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        toast({
          description: getAssetsDeletedMessage(selectedIds.length),
          status: "success",
          position: "top",
          isClosable: true,
        });
        selectedIds.forEach((assetId) =>
          client.cache.evict({ id: `Asset:${assetId}` })
        );
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      onDeleteClose();
    }
  };

  return selectedIds.length ? (
    <>
      <Flex
        alignItems="center"
        marginTop={{ base: "3", sm: "0", md: "3", xl: "0" }}
        justify="flex-end"
      >
        <Flex alignItems="flex-end" direction="column" mr="2">
          <Text color="gray.300" fontSize="sm">
            {selectedIds.length} Selected
          </Text>
          <Button
            variant="link"
            colorScheme="secondary"
            onClick={() =>
              toggleAllRowsSelected(selectedIds.length !== assets.length)
            }
          >
            {selectedIds.length === assets.length
              ? "Deselect All"
              : "Select All"}
          </Button>
        </Flex>
        <Menu>
          <LabeledMenuButton label="Action" value="" />
          <MenuList minWidth="100px">
            {canDeleteAsset && (
              <MenuItem onClick={onDeleteOpen}>
                Delete selected {pluralize("asset", selectedIds.length)}
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>
      {canDeleteAsset && (
        <AlertDialog
          leastDestructiveRef={cancelDeleteRef}
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogBody textAlign="center">
                Are you sure you want to delete {selectedIds.length} selected{" "}
                {pluralize("asset", selectedIds.length)}?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelDeleteRef}
                  onClick={onDeleteClose}
                  width="48%"
                >
                  No, Don't Delete!
                </Button>
                <Button
                  onClick={deleteItems}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                  isLoading={loading}
                >
                  Yes, Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  ) : null;
};

export default AssetsBulkDelete;
