import { useEffect, useState } from "react";

import {
  LocationFragmentFragment,
  useLocationIndexQuery,
} from "../graphql/graphql";
import { LocationWithChildren } from "./useTopLocationsTree";

const useLocationTreeRestricted = (locationId: string) => {
  const { data } = useLocationIndexQuery();
  const [locationTree, setLocationTree] =
    useState<LocationWithChildrenAndParent | null>(null);

  useEffect(() => {
    if (!data) return;

    const findLocation = (id: string) =>
      data.locations.find((location) => location.id === id);

    let id = locationId;
    let localLocationTree: LocationWithChildrenAndParent | null = null;

    while (true) {
      const location = findLocation(id);
      if (!location) break;

      const newLocation: any = Object.assign({}, location, { parent: null });
      if (!localLocationTree) {
        localLocationTree = newLocation;
      } else {
        localLocationTree = assignDeepestChildParent(
          localLocationTree,
          newLocation
        );
      }

      if (!newLocation.parentId) {
        setLocationTree(localLocationTree);
        break;
      }
      id = newLocation.parentId;
    }
  }, [data, locationId]);

  return locationTree;
};

export default useLocationTreeRestricted;

const assignDeepestChildParent = (
  locationTree: LocationWithChildrenAndParent,
  location: LocationWithChildrenAndParent
): LocationWithChildrenAndParent => {
  if (locationTree.parent) {
    return Object.assign({}, locationTree, {
      parent: assignDeepestChildParent(locationTree.parent as any, location),
    });
  } else {
    return Object.assign({}, locationTree, { parent: location });
  }
};

export interface LocationWithChildrenAndParent extends LocationWithChildren {
  parent: LocationFragmentFragment[];
}
