import { Icon, IconProps } from "@chakra-ui/react";

const ChillerWaterCooled = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M36.309 77.263c0-4.08 2.036-9.326 6.053-15.591L37.725 58.7c-4.594 7.166-6.924 13.412-6.924 18.563 0 11.517 9.37 20.887 20.887 20.887v-5.508c-8.48 0-15.38-6.899-15.38-15.38Z"
      fill="currentColor"
    />
    <path
      d="M82.512 55.926c-1.125 0-2.234.07-3.325.2a81.69 81.69 0 0 0-2.717-4.546c-6.475-10.1-21.922-30.76-22.577-31.634L51.688 17l-2.205 2.946c-.654.874-16.101 21.534-22.576 31.634-6.027 9.4-8.956 17.741-8.956 25.499 0 18.603 15.134 33.737 33.737 33.737a33.532 33.532 0 0 0 16.098-4.094A27.37 27.37 0 0 0 82.512 111c15.184 0 27.537-12.353 27.537-27.537S97.696 55.926 82.512 55.926Zm-30.824 49.383c-15.566 0-28.23-12.664-28.23-28.23 0-6.672 2.645-14.04 8.085-22.526 4.933-7.696 15.543-22.131 20.145-28.343 4.603 6.212 15.212 20.647 20.145 28.342a79.58 79.58 0 0 1 1.751 2.86C62.77 61.13 54.975 71.402 54.975 83.463c0 7.59 3.087 14.472 8.07 19.458a28.067 28.067 0 0 1-11.357 2.388Zm8.794-21.846c0-12.147 9.883-22.03 22.03-22.03 12.147 0 22.03 9.883 22.03 22.03 0 12.147-9.883 22.03-22.03 22.03-12.147 0-22.03-9.883-22.03-22.03Z"
      fill="currentColor"
    />
    <path
      d="M75.902 86.216h3.856v3.858l-5.321 5.321a1 1 0 0 0 0 1.415l2.48 2.48a1 1 0 0 0 1.414 0l4.181-4.182 4.182 4.182a1 1 0 0 0 1.414 0l2.48-2.48a1 1 0 0 0 0-1.415l-5.322-5.322v-3.856h3.857l5.322 5.321a1 1 0 0 0 1.414 0l2.48-2.48a1 1 0 0 0 0-1.414l-4.181-4.181 4.181-4.181a1 1 0 0 0 0-1.415l-2.48-2.48a1 1 0 0 0-1.414 0l-5.322 5.322h-3.857v-3.857l5.322-5.322a1 1 0 0 0 0-1.414l-2.48-2.48a1 1 0 0 0-1.414 0l-4.182 4.181-4.181-4.181a1 1 0 0 0-1.414 0l-2.48 2.48a1 1 0 0 0 0 1.414l5.322 5.322v3.857H75.9l-5.321-5.322a1 1 0 0 0-1.415 0l-2.48 2.48a1 1 0 0 0 0 1.414l4.182 4.182-4.182 4.181a1 1 0 0 0 0 1.414l2.48 2.48a1 1 0 0 0 1.415 0l5.322-5.322Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChillerWaterCooled;
