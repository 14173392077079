import { useReactiveVar } from "@apollo/client";
import { addDays, format } from "date-fns";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  FloorPlanIndexFragmentFragment,
  MaintenanceReportFragmentFragment,
  MaintenanceStatusType,
  useAssetWithTypeQuery,
  useCurrentMaintenanceReportByCategoryQuery,
  useFloorPlanIndexQuery,
  useMaintenanceIndexQuery,
  useMaintenanceReportQuery,
} from "../../../graphql/graphql";
import {
  calendarOptionsVar,
  layoutOptionsVar,
} from "../../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface DashboardContentPageProps {}

const DashboardContentPage: React.FC<DashboardContentPageProps> = () => {
  const [reportDataCache, setReportDataCache] =
    useState<MaintenanceReportFragmentFragment[]>();
  const [startDateIndex, setStartDateIndex] = useState(0);
  const { startDate, endDate } = useReactiveVar(calendarOptionsVar);
  const { data } = useMaintenanceIndexQuery({
    variables: { startDate: startDate.getTime(), endDate: endDate.getTime() },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: assetsData } = useAssetWithTypeQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: floorPlansData } = useFloorPlanIndexQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: reportByCategoryData } =
    useCurrentMaintenanceReportByCategoryQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const { data: reportData, loading } = useMaintenanceReportQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",

    variables: {
      startDate: format(addDays(new Date(), startDateIndex), "yyyy-MM-dd"),
    },
  });

  useEffect(() => {
    if (reportData) setReportDataCache(reportData.maintenanceReport);
  }, [reportData]);

  const gotoPrevWeek = useCallback(() => {
    setStartDateIndex((i) => i - 7);
  }, [setStartDateIndex]);

  const gotoNextWeek = useCallback(() => {
    setStartDateIndex((i) => i + 7);
  }, [setStartDateIndex]);

  const gotoCurrentWeek = useCallback(() => {
    setStartDateIndex(0);
  }, [setStartDateIndex]);

  useEffect(() => {
    layoutOptionsVar({
      title: "Dashboard",
    });
  }, []);

  const floorPlans = useMemo(() => {
    if (!floorPlansData) return null;

    const parsedFloorPlans: FloorPlanIndexFragmentFragment[] = [];
    const floorPlansCount = floorPlansData.floorPlans.length;
    for (let j = 0; j < floorPlansCount; j++) {
      const floorPlan = floorPlansData.floorPlans[j];
      if (
        floorPlan.floorPlanAssets.length &&
        floorPlan.floorPlanAssets.some(
          (fpa) => fpa.asset.maintenanceStatus !== MaintenanceStatusType.Current
        )
      ) {
        parsedFloorPlans.push(floorPlan);
      }
    }
    return parsedFloorPlans;
  }, [floorPlansData]);

  const dataByCategory = useMemo(
    () =>
      reportByCategoryData?.currentMaintenanceReportByCategory
        ? orderBy(
            reportByCategoryData.currentMaintenanceReportByCategory.filter(
              (c) => c.currentCount + c.dueIn30Count + c.pastDueCount > 0
            ),
            (c) => c.currentCount + c.dueIn30Count + c.pastDueCount,
            "desc"
          )
        : null,
    [reportByCategoryData?.currentMaintenanceReportByCategory]
  );

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      {assetsData && floorPlans && dataByCategory && reportDataCache ? (
        <Presenter
          assets={assetsData.assets}
          maintenances={data?.maintenances}
          floorPlans={floorPlans}
          dataByCategory={dataByCategory}
          dataAllCategories={reportDataCache}
          gotoPrevWeek={gotoPrevWeek}
          gotoNextWeek={gotoNextWeek}
          gotoCurrentWeek={gotoCurrentWeek}
          showThisWeekButton={startDateIndex !== 0}
          loading={loading}
        />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default DashboardContentPage;

gql`
  query CurrentMaintenanceReportByCategory {
    currentMaintenanceReportByCategory {
      ...CategoriesSummaryFragment
    }
  }

  query MaintenanceReport($startDate: Date!, $numberOfDays: Float) {
    maintenanceReport(startDate: $startDate, numberOfDays: $numberOfDays) {
      ...MaintenanceReportFragment
    }
  }
`;
