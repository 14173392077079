import { EuiToolTip, useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { ReactNode, useEffect, useRef, useState } from "react";

interface TextProps {
  text?: string | ReactNode;
  truncate?: boolean;
  maxWidth?: string;
}

const TruncatedText: React.FC<TextProps> = ({ text, truncate, maxWidth }) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const wrapperRef = useRef<any>(null);
  const textRef = useRef<any>(null);

  useEffect(() => {
    const wrapper = wrapperRef?.current;
    const text = textRef.current;
    const isTextTruncated =
      !!wrapper &&
      !!text &&
      wrapper.getBoundingClientRect().width ===
        text.getBoundingClientRect().width;
    setIsTruncated(isTextTruncated);
  }, [wrapperRef, textRef, text]);

  return (
    <div
      ref={wrapperRef}
      style={{ maxWidth: maxWidth ? maxWidth : "100%", width: "100%" }}
    >
      {truncate && isTruncated ? (
        <EuiToolTip content={text} display="block">
          <div
            ref={textRef}
            style={{
              width: "fit-content",
              maxWidth: maxWidth ? maxWidth : "100%",
              whiteSpace: truncate ? "nowrap" : "normal",
              overflow: truncate ? "hidden" : "auto",
              textOverflow: "ellipsis",
              fontSize: euiTheme.sizes["14px"],
              lineHeight: euiTheme.sizes["21px"],
              fontWeight: 400,
            }}
          >
            {text}
          </div>
        </EuiToolTip>
      ) : (
        <div
          ref={textRef}
          style={{
            width: "fit-content",
            maxWidth: maxWidth ? maxWidth : "100%",
            whiteSpace: truncate ? "nowrap" : "normal",
            overflow: truncate ? "hidden" : "auto",
            textOverflow: "ellipsis",
            fontSize: euiTheme.sizes["14px"],
            lineHeight: euiTheme.sizes["21px"],
            fontWeight: 400,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default TruncatedText;
