import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Authentication = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 24 24" boxSize="24px" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.12 5.12A3 3 0 0121 7.24v5.85a8 8 0 01-3.09 6.31l-4.08 3.18a2 2 0 01-1.23.42h-1.2a2 2 0 01-1.23-.42L6.09 19.4A8 8 0 013 13.09V7.24a3 3 0 01.88-2.12l3.53-3.53A2 2 0 018.83 1h6.34a2 2 0 011.42.59l3.53 3.53zm-9.39 9.74l5.62-5.62v-.01a.5.5 0 000-.7L15.82 8a.5.5 0 00-.7 0l-4.74 4.73-1.5-1.49a.48.48 0 00-.7 0l-.53.53a.5.5 0 000 .71l2.38 2.38a.48.48 0 00.7 0z"
    />
    ;
  </Icon>
);

export default Authentication;
