import {
  Box,
  Button,
  Flex,
  Input,
  List,
  ListItem,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../components/elements/formGroup";
import Link from "../../components/elements/link";
import PasswordInput from "../../components/elements/passwordInput";
import {
  useUpdateSessionMutation,
  useUserSignInMutation,
} from "../../graphql/graphql";
import useCountdown from "../../hooks/useCountdown";
import { getRoutePath } from "../../router";
import { getRedirectUrl, removeRedirectUrl } from "../../utils/redirection";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import {
  currentPasswordSchema,
  emailSchema,
  pinSchema,
  yupObject,
} from "../../utils/validation";

interface SignInPagePresenterProps {}

const SignInPagePresenter: React.FC<SignInPagePresenterProps> = () => {
  const [userSignInMutation, { loading }] = useUserSignInMutation();
  const navigate = useNavigate();
  const toast = useToast();
  const [updateSessionMutation] = useUpdateSessionMutation();
  const [data, setData] = useState<SignInFormData>();
  const [show2FAForm, setShow2FAForm] = useState(false);

  const onSubmit = async (
    data: SignInFormData,
    { setFieldError }: FormikHelpers<SignInFormData>
  ) => {
    try {
      const { data: serverData, errors } = await userSignInMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        await updateSessionMutation({
          variables: { jwt: serverData.userSignIn.jwtToken },
        });
        const redirectTo = getRedirectUrl();
        navigate(redirectTo ?? getRoutePath("dashboard"), { replace: true });
        removeRedirectUrl();
        return;
      }
    } catch (error: any) {
      if (error.message.includes("Incorrect code")) {
        setData(data);
        setShow2FAForm(true);
        return;
      }
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const SignInValidationSchema = useMemo(
    () =>
      yupObject().shape({
        email: emailSchema,
        passwordRaw: currentPasswordSchema.label("Password"),
        code: show2FAForm ? pinSchema : pinSchema.notRequired(),
      }),
    [show2FAForm]
  );

  return show2FAForm && data ? (
    <Formik
      initialValues={{
        email: data.email,
        passwordRaw: data.passwordRaw,
        code: undefined,
      }}
      validationSchema={SignInValidationSchema}
      onSubmit={onSubmit as any}
    >
      {({ getFieldProps, values, setFieldError }) => (
        <Form noValidate>
          <Box
            marginX="auto"
            textAlign="center"
            marginTop="4"
            color="gray.600"
            fontSize="lg"
            maxWidth="2xl"
          >
            Enter the one-time PIN sent to your phone
          </Box>
          <Flex
            flexWrap="wrap"
            justifyContent="space-evenly"
            maxWidth="lg"
            padding={{ xl: "12" }}
          >
            <FormGroup label="Enter your PIN" name="code">
              <Input
                autoFocus
                type="number"
                autoComplete="one-time-code"
                {...getFieldProps("code")}
              />
            </FormGroup>
            <Box marginTop="8" marginBottom="4" width="100%">
              <Button width="full" type="submit" isLoading={loading}>
                Sign In
              </Button>
            </Box>
            <Box>
              <Resend
                resend={() =>
                  onSubmit(
                    { email: values.email, passwordRaw: values.passwordRaw },
                    { setFieldError } as any
                  )
                }
              />
              <Links />
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={{
        email: "",
        passwordRaw: "",
      }}
      validationSchema={SignInValidationSchema}
      onSubmit={onSubmit}
    >
      {({ getFieldProps }) => (
        <Form noValidate>
          <Flex
            flexWrap="wrap"
            justifyContent="space-evenly"
            maxWidth="lg"
            padding={{ xl: "12" }}
          >
            <FormGroup label="Email" name="email">
              <Input
                autoFocus
                autoComplete="email"
                type="email"
                {...getFieldProps("email")}
              />
            </FormGroup>
            <FormGroup label="Password" name="passwordRaw">
              <PasswordInput
                autoComplete="current-password"
                name="passwordRaw"
              />
            </FormGroup>
            <Box marginTop="8" marginBottom="4" width="100%">
              <Button width="full" type="submit" isLoading={loading}>
                Sign In
              </Button>
            </Box>
            <Links />
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SignInPagePresenter;

const Resend: React.FC<{ resend: () => void }> = ({ resend }) => {
  const { timeLeft, reset } = useCountdown(60);
  return (
    <List textAlign="center" opacity={timeLeft ? "0" : "1"}>
      <ListItem mt="4" mb="8">
        <Button
          onClick={() => {
            resend();
            reset();
          }}
          variant="link"
          colorScheme="secondary"
          isDisabled={!!timeLeft}
        >
          Send new PIN
        </Button>
      </ListItem>
    </List>
  );
};

const Links = () => (
  <List textAlign="center">
    <ListItem marginBottom="8">
      <Link
        to={getRoutePath("forgotPassword")}
        variant="link"
        colorScheme="secondary"
        color="gray.600"
      >
        Forgot your password?
      </Link>
    </ListItem>
    {/* <ListItem marginBottom="3">
      Don't have an account yet?{" "}
      <Link to={getRoutePath("signUp")} variant="link" colorScheme="secondary">
        Sign up
      </Link>
    </ListItem> */}
  </List>
);
type SignInFormData = {
  email: string;
  passwordRaw: string;
  code?: number;
};

gql`
  mutation userSignIn($data: UserSignInInput!) {
    userSignIn(data: $data) {
      jwtToken
    }
  }
`;
