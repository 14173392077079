import {
  EuiFlexGroup,
  EuiFlexItem,
  useEuiTheme,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React from "react";

import { WorkOrder } from "../models/workOrder";
import WorkOrderEditGeneral from "./general";
import WorkOrderEditRightPanel from "./rightPanel";

interface WorkOrderEditPagePresenterProps {
  workOrder: WorkOrder;
  reloadWorkOrder: () => void;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
}

const WorkOrderEditPagePresenter: React.FC<WorkOrderEditPagePresenterProps> = ({
  workOrder,
  reloadWorkOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const isMobile = useIsWithinBreakpoints(["xs", "s"]);
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();

  return (
    <>
      <EuiFlexGroup
        direction="row"
        justifyContent="spaceBetween"
        alignItems="flexStart"
        gutterSize="xl"
        style={{ height: "calc(100vh - 146px)" }}
      >
        <EuiFlexItem
          grow={3}
          style={{
            minWidth: "360px",
            alignItems: isMobile ? "center" : "flex-start",
            overflow: "auto",
            marginRight: "-30px",
            height: "100%",
            paddingRight: "20px",
          }}
        >
          <WorkOrderEditGeneral
            workOrder={workOrder}
            changeIsEdit={changeIsEdit}
            reloadWorkOrder={reloadWorkOrder}
            onWorkOrderChange={onWorkOrderChange}
          />
        </EuiFlexItem>
        <EuiFlexItem
          grow={false}
          style={{
            width: "416px",
            alignItems: isMobile ? "center" : "flex-end",
            gap: euiTheme.size.s,
            overflow: "auto",
            height: "100%",
            paddingRight: "10px",
            marginTop: euiTheme.size.base,
            paddingBottom: "32px",
          }}
        >
          <WorkOrderEditRightPanel
            workOrder={workOrder}
            changeIsEdit={changeIsEdit}
            onWorkOrderChange={onWorkOrderChange}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default WorkOrderEditPagePresenter;
