import "moment-timezone";

import moment from "moment";
import React, { useEffect } from "react";

import { getUtcDateStringSwitchedBetweenTimezones } from "../../../helpers/dateTime";
import { ScheduleTypeEnum } from "../../../models/enums";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateServicesServiceSchedulePresenter from "./presenter";

interface WorkOrderCreateServicesServiceScheduleInfoProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateServicesServiceScheduleInfo: React.FC<
  WorkOrderCreateServicesServiceScheduleInfoProps
> = ({ workOrder, onWorkOrderChange }) => {
  const handleScheduleTypeSelect = (scheduleType: ScheduleTypeEnum) => {
    onWorkOrderChange({ ...workOrder, scheduleType, startDateTime: null });
  };

  const handleEndDateSelect = (endDate: string) => {
    onWorkOrderChange({
      ...workOrder,
      endDateTime: moment.utc(endDate).valueOf(),
    });
  };

  const handleStartDateSelect = (startDate: string) => {
    onWorkOrderChange({
      ...workOrder,
      startDateTime: moment.utc(startDate).valueOf(),
    });
  };

  const handleTimezoneSelection = (timezone: string) => {
    onWorkOrderChange({
      ...workOrder,
      serviceScheduleTimezone: timezone,
      endDateTime: workOrder.endDateTime
        ? getUtcDateStringSwitchedBetweenTimezones(
            workOrder.endDateTime,
            workOrder.serviceScheduleTimezone,
            timezone
          )
        : null,
      startDateTime: workOrder.startDateTime
        ? getUtcDateStringSwitchedBetweenTimezones(
            workOrder.startDateTime,
            workOrder.serviceScheduleTimezone,
            timezone
          )
        : null,
    });
  };

  useEffect(() => {
    const currentOffset = moment.tz.guess();
    onWorkOrderChange({
      ...workOrder,
      serviceScheduleTimezone: currentOffset,
    });
  }, [workOrder.locationId, workOrder.companyId]);

  return (
    <>
      <WorkOrderCreateServicesServiceSchedulePresenter
        workOrder={workOrder}
        onEndDateSelected={handleEndDateSelect}
        onStartDateSelected={handleStartDateSelect}
        onScheduleTypeSelected={handleScheduleTypeSelect}
        onTimezoneSelected={handleTimezoneSelection}
      />
    </>
  );
};

export default WorkOrderCreateServicesServiceScheduleInfo;
