import { extendTheme } from "@chakra-ui/react";

import foundations from "../theme/foundations";
import components from "./components";
import globalStyles from "./globalStyles";

const customisedTheme = {
  styles: globalStyles,
  ...foundations,
  components,
};

export const theme = extendTheme(customisedTheme);

export default theme;
