import React from "react";

import { WorkOrder, WorkOrderAsset } from "../../../models/workOrder";
import WorkOrderCreateServiceGeneralInfoPresenter from "./presenter";

interface WorkOrderCreateServicesGeneralInfoProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  onFilesAdded: (files: File[]) => void;
  onFileDelete: (files: File) => void;
  onAddAssetClick: () => void;
  onAssetDelete: (asset: WorkOrderAsset) => void;
}

const WorkOrderCreateServicesGeneralInfo: React.FC<
  WorkOrderCreateServicesGeneralInfoProps
> = ({
  workOrder,
  onWorkOrderChange,
  onFilesAdded,
  onFileDelete,
  onAddAssetClick,
  onAssetDelete,
}) => {
  return (
    <>
      <WorkOrderCreateServiceGeneralInfoPresenter
        workOrder={workOrder}
        onWorkOrderChange={onWorkOrderChange}
        onFilesAdded={onFilesAdded}
        onFileDelete={onFileDelete}
        onAddAssetClick={onAddAssetClick}
        onAssetDelete={onAssetDelete}
      />
    </>
  );
};

export default WorkOrderCreateServicesGeneralInfo;
