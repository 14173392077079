import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import Link from "../../../components/elements/link";
import RadioButtonInput from "../../../components/elements/radioButton";
import UserCard from "../../../components/elements/userCard";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyUserRole,
  CompanyUserWithUserFragmentFragment,
  useCompanyUserUpdateMutation,
} from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../router";
import { formatDateFromSecondsTimestamp } from "../../../utils/date";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { roleSchema, yupObject } from "../../../utils/validation";
import { getCompanyRoleOptions } from "../create/form";
import CompanyTeamDelete from "../delete";
import CompanyTeamResend from "../resend";

interface CompanyTeamsUpdatePagePresenterProps {
  companyUser: CompanyUserWithUserFragmentFragment;
  companyUsersLength: number;
}

const CompanyTeamsUpdatePagePresenter: React.FC<
  CompanyTeamsUpdatePagePresenterProps
> = ({ companyUser, companyUsersLength }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [CompanyUserUpdateMutation, { loading }] =
    useCompanyUserUpdateMutation();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  const onSubmit = async (
    data: CompanyUserUpdateFormData,
    { setFieldError }: FormikHelpers<any>
  ) => {
    try {
      const { data: serverData, errors } = await CompanyUserUpdateMutation({
        variables: { ...data, id: companyUser.id },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        navigate(getRoutePath("companyTeams"));
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("companyTeams"));
    }, 500);
  };

  return (
    <>
      <Helmet>
        <title>User Info</title>
      </Helmet>
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>User Info</DrawerHeader>
            <DrawerBody>
              <Flex
                marginTop="4"
                alignItems="center"
                justifyContent="space-between"
              >
                <UserCard
                  user={companyUser.user}
                  invitedDate={
                    companyUser.role !== CompanyUserRole.Owner
                      ? formatDateFromSecondsTimestamp(
                          companyUser.createdAt / 1000
                        )
                      : ""
                  }
                />
                <Box>
                  <CompanyTeamResend companyUser={companyUser} />
                  <Box as="span">
                    <CompanyTeamDelete companyUser={companyUser} />
                  </Box>
                  {companyUser.role === CompanyUserRole.Owner &&
                    currentCompanyRole?.isOwner &&
                    companyUsersLength > 1 && (
                      <Link
                        to={getRoutePath("companyTeamsChangeOwner")}
                        variant="link"
                        colorScheme="secondary"
                        marginLeft="2"
                      >
                        Change Owner
                      </Link>
                    )}
                </Box>
              </Flex>
              {companyUser.role !== CompanyUserRole.Owner && (
                <Formik
                  initialValues={{
                    role: companyUser.role || CompanyUserRole.User,
                  }}
                  validationSchema={CompanyUserUpdateValidationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {() => (
                    <Form noValidate>
                      <RadioButtonInput
                        options={getCompanyRoleOptions(currentCompanyRole.role)}
                        name="role"
                        label="Role"
                      />
                      <Button
                        width="full"
                        type="submit"
                        isLoading={loading}
                        marginTop="10"
                      >
                        Save
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default CompanyTeamsUpdatePagePresenter;

gql`
  mutation CompanyUserUpdate(
    $role: CompanyUserRole!
    $id: UUID!
    $passwordRaw: String
  ) {
    companyUserUpdate(
      id: $id
      data: { role: $role }
      passwordRaw: $passwordRaw
    ) {
      ...CompanyUserWithUserFragment
    }
  }
`;

const CompanyUserUpdateValidationSchema = yupObject().shape({
  role: roleSchema,
});

type CompanyUserUpdateFormData = {
  role: CompanyUserRole;
};
