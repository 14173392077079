import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { useEffect } from "react";

import { WOLocation } from "../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrderCreateRedirectData } from "../../models/createWORedirectData";
import Combobox from "../combobox";
import Modal from "../modal";

interface SublocationSelectModalPresenterProps {
  dataChanged: (data: WorkOrderCreateRedirectData) => void;
  locations: WOLocation[];
  locationsLoading?: boolean;
  availableLocationIds?: string[];
  isOpen?: boolean;
  closeModal: () => void;
  onDataAccepted: () => void;
  WOCreateData?: WorkOrderCreateRedirectData;
}

const SublocationSelectModalPresenter: React.FC<
  SublocationSelectModalPresenterProps
> = ({
  dataChanged,
  WOCreateData,
  locations,
  availableLocationIds,
  locationsLoading,
  isOpen,
  onDataAccepted,
  closeModal,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const modalWidth = "400px";

  const [locationOptions, setLocationOptions] = React.useState(
    [] as EuiComboBoxOptionOption<string>[]
  );

  const handleLocationChange = (sublocationId: string) => {
    dataChanged({ sublocationId });
  };

  const getTopLevelLocation = (
    location: WOLocation,
    locations: WOLocation[]
  ): WOLocation => {
    if (!location?.parentId) {
      return location;
    } else {
      const parentLocation = locations.find(
        (loc) => loc.id === location.parentId
      );
      return parentLocation
        ? getTopLevelLocation(parentLocation, locations)
        : location;
    }
  };

  useEffect(() => {
    let locationOptions: EuiComboBoxOptionOption<string>[] = [];
    if (!locations?.length) {
      setLocationOptions(locationOptions);
      return;
    }
    if (availableLocationIds) {
      const availableLocations = locations.filter((loc) =>
        availableLocationIds.includes(loc.id)
      );
      const topLevelLocations: WOLocation[] = [];
      availableLocations.forEach((location) => {
        const topLevelLocation = getTopLevelLocation(location, locations);
        if (
          !topLevelLocations.map((loc) => loc.id).includes(topLevelLocation.id)
        ) {
          topLevelLocations.push(topLevelLocation);
        }
        locationOptions = topLevelLocations.map((topLevelLocation) => {
          return { label: topLevelLocation.name, value: topLevelLocation.id };
        });
      });
    } else {
      locationOptions = locations
        .filter((loc) => !loc.parentId)
        .map((location) => {
          return { label: location.name, value: location.id };
        });
    }
    setLocationOptions(locationOptions);
  }, [locations, availableLocationIds]);

  return (
    <>
      <Modal isOpen={!!isOpen} closeModal={closeModal} noCloseIcon={true}>
        <EuiFlexGroup
          direction="row"
          wrap={false}
          justifyContent="spaceBetween"
          alignItems="center"
          style={{
            paddingTop: euiTheme.size.l,
            minWidth: modalWidth,
          }}
        >
          <EuiFlexGroup
            justifyContent="flexStart"
            direction="column"
            wrap={false}
            gutterSize="none"
            style={{
              width: "100%",
              flexGrow: 1,
            }}
          >
            <EuiFlexItem
              style={{
                marginBottom: euiTheme.size.l,
                height: euiTheme.size.xxl,
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <EuiIcon
                type="iInCircle"
                size="l"
                style={{ marginRight: euiTheme.size.s }}
                color={euiTheme.colors.secondary["500"]}
              />
              <EuiText
                color={euiTheme.colors.title}
                style={{
                  fontSize: euiTheme.sizes["16px"],
                  lineHeight: euiTheme.sizes["18px"],
                  fontWeight: euiTheme.font.weight.medium,
                }}
              >
                Please, select Location for Work Order
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem
              style={{
                marginBottom: euiTheme.size.m,
                height: euiTheme.size.xxl,
              }}
            >
              <Combobox
                ariaLabel="Work Order Location"
                label="Location"
                placeholder={"Select Location"}
                required={true}
                autoSelect={true}
                isClearable={false}
                options={locationOptions}
                hidePrependIcon={true}
                selectedValue={WOCreateData?.locationId}
                onChange={handleLocationChange}
                loading={locationsLoading}
              />
            </EuiFlexItem>
            <EuiFlexItem
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: euiTheme.size.m,
              }}
            >
              <EuiButtonEmpty
                color="text"
                onClick={closeModal}
                style={{ marginRight: euiTheme.size.m }}
              >
                Cancel
              </EuiButtonEmpty>
              <EuiButton
                fill={true}
                size={"m"}
                onClick={onDataAccepted}
                disabled={!WOCreateData?.sublocationId}
              >
                Accept
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </Modal>
    </>
  );
};

export default SublocationSelectModalPresenter;
