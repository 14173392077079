import { Box, Center } from "@chakra-ui/react";
import { sortBy } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import CenteredFullHeight from "../../../../components/elements/centeredFullHeight";
import ImageSlider from "../../../../components/elements/imageSlider";
import Link from "../../../../components/elements/link";
import {
  AssetFileFragmentFragment,
  AssetFileType,
  AssetWithRelationsFragmentFragment,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import AssetFileDelete from "../../assetFileDelete";

interface AssetImagesProps {
  asset: AssetWithRelationsFragmentFragment;
  canAddAsset: boolean;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
}

const AssetImages: React.FC<AssetImagesProps> = ({
  asset,
  canAddAsset,
  canEditAsset,
  canDeleteAsset,
}) => {
  const assetImages = React.useMemo(
    () =>
      sortBy(
        asset.assetFiles.filter((af) => af.type === AssetFileType.Image),
        (af) => af.order || 9999
      ),
    [asset]
  );
  const navigate = useNavigate();
  const [assetFileToDelete, setAssetFileToDelete] =
    React.useState<AssetFileFragmentFragment>();
  const onEdit = React.useCallback(
    (id: string) => {
      navigate(
        getRoutePath("assetsShowFileEdit", { assetId: asset.id, fileId: id })
      );
    },
    [navigate, asset.id]
  );
  const onDelete = React.useCallback(
    (assetFile: AssetFileFragmentFragment) => {
      setAssetFileToDelete(assetFile);
    },
    [setAssetFileToDelete]
  );

  return (
    <Box>
      {assetImages.length ? (
        <>
          <Center>
            <Box width="full" maxWidth="600px" maxHeight="100vh">
              <ImageSlider
                assetId={asset.id}
                images={assetImages}
                canAddAsset={canAddAsset}
                handleEdit={canEditAsset ? onEdit : undefined}
                handleDelete={canDeleteAsset ? onDelete : undefined}
                canSortAssetImages={canEditAsset}
                previewAvailable
              />
            </Box>
          </Center>
          {assetFileToDelete && (
            <AssetFileDelete
              assetFile={assetFileToDelete}
              assetId={asset.id}
              handleDelete={() => setAssetFileToDelete(undefined)}
            />
          )}
        </>
      ) : canAddAsset ? (
        <CenteredFullHeight>
          <Link
            to={`${getRoutePath("assetsShowFileCreate", {
              assetId: asset.id,
            })}?type=${AssetFileType.Image}`}
          >
            Add an Asset Picture
          </Link>
        </CenteredFullHeight>
      ) : null}
    </Box>
  );
};

export default AssetImages;
