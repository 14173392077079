import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";
import React from "react";

import { useWOFloorPlansQuery } from "../../../../../../graphql/queries/workOrders";
import WorkOrderCreateAddAssetPlansTablePresenter, {
  FloorPlansItem,
} from "./presenter";

interface WorkOrderCreateAddAssetPlansTableProps {
  assetId?: string;
  sublocationId?: string;
  companyId?: string;
  onSelectPlans: (plans: FloorPlansItem[]) => void;
}

const WorkOrderCreateAddAssetPlansTable: React.FC<
  WorkOrderCreateAddAssetPlansTableProps
> = ({ assetId, sublocationId, companyId, onSelectPlans }) => {
  const { data, loading } = useWOFloorPlansQuery({
    variables: {
      assetId,
    },
    skip: !assetId,
  });

  return (
    <>
      {!loading ? (
        <WorkOrderCreateAddAssetPlansTablePresenter
          floorPlans={data?.workOrderFloorPlans}
          assetId={assetId}
          sublocationId={sublocationId}
          companyId={companyId}
          onSelectPlans={onSelectPlans}
        />
      ) : (
        <EuiFlexGroup
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <EuiLoadingSpinner size="xxl" />
        </EuiFlexGroup>
      )}
    </>
  );
};

export default WorkOrderCreateAddAssetPlansTable;
