import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { useSessionQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import { getRoutePath } from "../../router";
import Presenter from "./presenter";

interface SignInPageProps {}

console.log("signIn");

const SignInPage: React.FC<SignInPageProps> = () => {
  const { data } = useSessionQuery();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data?.session?.isLoggedIn) {
      navigate(getRoutePath("dashboard"));
    }
  }, [data, navigate]);

  React.useEffect(() => {
    layoutOptionsVar({ title: "Welcome back!" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Presenter />
    </>
  );
};

export default SignInPage;
