import React, { useEffect, useRef, useState } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (
  ref: React.MutableRefObject<HTMLElement | null>,
  onBlur?: () => void,
  focused?: boolean
) => {
  const [isFocused, setIsFocused] = useState(focused);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (!ref.current) return;
      if (ref?.current?.contains(event.target)) {
        setIsFocused(true);
      }
      if (!ref?.current?.contains(event.target) && isFocused) {
        setIsFocused(false);
        if (onBlur) onBlur();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFocused, onBlur, ref]);
};

/**
 * Component that alerts if you click outside of it
 */
const OutsideAlerter: React.FC<OutsideAlerterProps> = ({
  children,
  onBlur,
  focused,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onBlur, focused);

  return <div ref={wrapperRef}>{children}</div>;
};

interface OutsideAlerterProps {
  children: any;
  onBlur?: () => void;
  focused?: boolean;
}

export default OutsideAlerter;
