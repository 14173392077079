import { Icon, IconProps } from "@chakra-ui/react";

const Generator = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M32.444 108.446h-.4v3.566a2.588 2.588 0 0 1-5.176 0v-15.32H18.57V47.695h-5.147v-9.678c0-7.028 5.718-12.746 12.747-12.746h66.137V18.576H87.795a2.588 2.588 0 0 1 0-5.176h14.2a2.588 2.588 0 0 1 0 5.176H97.483V25.27h4.348c7.028 0 12.746 5.718 12.746 12.746v9.678H109.431v48.998h-7.26l.348.6a11.462 11.462 0 0 1 1.549 5.764c0 6.363-5.177 11.54-11.54 11.54-4.332 0-8.114-2.401-10.087-5.944l-.115-.206H32.444ZM109 42.518h.4V38.016c0-4.174-3.395-7.57-7.57-7.57H26.17c-4.175 0-7.57 3.396-7.57 7.57V42.518H109ZM32.444 96.692h-.4v6.578h48.943l.006-.393c.031-2.032.59-3.936 1.544-5.584l.348-.6H32.444Zm71.41-5.176h.4v-.4l.001-43.022v-.4H23.746V91.516h80.108ZM92.528 109.42a6.37 6.37 0 0 0 6.364-6.364 6.37 6.37 0 0 0-6.364-6.363 6.37 6.37 0 0 0-6.364 6.363 6.37 6.37 0 0 0 6.364 6.364Z"
      fill="currentColor"
    />
    <path
      d="M39.797 60.808v17.594a2.488 2.488 0 1 1-4.976 0V60.808a2.488 2.488 0 1 1 4.976 0ZM51.357 60.808v17.594a2.488 2.488 0 1 1-4.976 0V60.808a2.488 2.488 0 1 1 4.976 0ZM62.917 60.808v17.594a2.488 2.488 0 0 1-4.976 0V60.808a2.488 2.488 0 1 1 4.976 0ZM84.748 66.459l-.22.658h6.614a1 1 0 0 1 .949 1.316L88.1 80.4a1 1 0 0 1-1.264.633l-2.823-.941a1 1 0 0 1-.633-1.265l2.025-6.075.22-.658h-6.614a1 1 0 0 1-.948-1.316l3.988-11.966a1 1 0 0 1 1.265-.633l2.824.941a1 1 0 0 1 .632 1.265l-2.025 6.075Z"
      fill="currentColor"
    />
  </Icon>
);

export default Generator;
