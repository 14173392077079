import React, { useEffect } from "react";

import PageSpinner from "../../components/elements/pageSpinner";
import { useUserWithCompaniesQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface MyCompaniesPageProps {}

const MyCompaniesPage: React.FC<MyCompaniesPageProps> = () => {
  const { data: userCompaniesData } = useUserWithCompaniesQuery();

  useEffect(() => {
    layoutOptionsVar({
      title: "My Companies",
      subTitle: "You have an account with all companies listed below.",
      hideSearch: true,
      styleProps: { maxWidth: "1100px" },
    });
  }, []);

  return (
    <>
      {userCompaniesData ? (
        <Presenter companyUsers={userCompaniesData.user.companyUsers} />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default MyCompaniesPage;
