import { Box, Flex, Text } from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import React from "react";

import { UserFragmentFragment } from "../../../graphql/graphql";
import TooltipName from "../tooltipName";

interface UserCardProps {
  user: UserFragmentFragment;
  invitedDate?: string;
}

const UserCard: React.FC<UserCardProps> = ({ user, invitedDate }) => {
  const name = user.fullName;
  const avatarName = name || `${user.email[0]} ${user.email[1]}`;

  return (
    <Flex alignItems="center">
      <Box flexShrink={0}>
        <Avatar name={avatarName} size="sm" bg="black" color="white" />
      </Box>
      <Box marginLeft="2" textAlign="left">
        {name ? (
          <>
            <TooltipName
              fontWeight="medium"
              fontSize={{ base: "sm", md: "base" }}
              name={name}
            />
            <TooltipName fontSize="sm" color="gray.600" name={user.email} />
          </>
        ) : (
          <TooltipName
            fontWeight="medium"
            fontSize={{ base: "sm", md: "base" }}
            name={user.email}
          />
        )}
        {invitedDate && (
          <Text fontSize="sm" color="gray.600">{`Invited ${invitedDate}`}</Text>
        )}
      </Box>
    </Flex>
  );
};

export default UserCard;
