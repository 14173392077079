import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
} from "@chakra-ui/react";
import React from "react";

import useCompanySetupProgress from "../../hooks/useCompanySetupProgress";

interface OnboardingPercentageProps {
  helperPosition?: "row" | "row-reverse";
}

const OnboardingPercentage: React.FC<OnboardingPercentageProps> = ({
  helperPosition = "row",
}) => {
  const companySetupCompletedPercentage = useCompanySetupProgress();

  return (
    <Box>
      <Flex alignItems="center" flexDirection={helperPosition}>
        <CircularProgress
          value={companySetupCompletedPercentage}
          size="40px"
          color="green.500"
          marginX="1"
        >
          <CircularProgressLabel>
            {companySetupCompletedPercentage}%
          </CircularProgressLabel>
        </CircularProgress>
        <Box>
          {companySetupCompletedPercentage === 0 ||
          companySetupCompletedPercentage === 100
            ? ""
            : " Almost done!"}
        </Box>
      </Flex>
    </Box>
  );
};

export default OnboardingPercentage;
