import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { PHONE_VERIFIED } from "../../../constants/lang/en";
import { useUserQuery } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import ChangePhoneForm from "./components/changePhoneForm";
import VerifyPhoneForm from "./components/verifyPhoneForm";

interface ChangePhonePageProps {}

const ChangePhonePage: React.FC<ChangePhonePageProps> = () => {
  const [verifyPage, setVerifyPage] = useState<boolean>(false);
  const toast = useToast();
  const { data } = useUserQuery();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const handleChangePhoneFormSubmit = (phoneChanged: boolean = true) => {
    if (phoneChanged) {
      setVerifyPage(true);
    } else {
      navigate(getRoutePath("mySettings"));
    }
  };

  const handleVerifyEmailFormSubmit = () => {
    navigate(getRoutePath("mySettings"));
    toast({
      description: PHONE_VERIFIED,
      status: "success",
      position: "top",
      isClosable: true,
    });
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("mySettings"));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>
          {verifyPage ? "Verify your phone number" : "My Phone Number"}
        </title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {verifyPage ? "Verify your phone number" : "My Phone Number"}
          </DrawerHeader>
          <DrawerBody>
            <Text marginBottom="4" textAlign="center" fontSize="lg">
              {verifyPage
                ? "Please enter the one-time PIN sent to your phone"
                : ""}
            </Text>
            {data ? (
              verifyPage ? (
                <VerifyPhoneForm
                  onSubmit={handleVerifyEmailFormSubmit}
                  setVerifyPage={setVerifyPage}
                  user={data.user}
                />
              ) : (
                <ChangePhoneForm
                  onSubmit={handleChangePhoneFormSubmit}
                  user={data.user}
                />
              )
            ) : (
              <PageSpinner />
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ChangePhonePage;
