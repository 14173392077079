import { Checkbox, CheckboxProps, FormControl } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { ReactNode } from "react";

const StyledCheckbox = styled(Checkbox)`
  .chakra-checkbox__label {
    text-align: left;
  }
`;

interface CheckBoxInputProps extends CheckboxProps {
  name: string;
  label?: ReactNode;
  styleProps?: object;
  autoFocus?: boolean;
}

const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  name,
  label,
  styleProps,
  autoFocus = false,
  ...props
}) => {
  const { errors, values, touched, submitCount, getFieldProps } =
    useFormikContext<any>();
  const error = get(errors, name);
  const value = get(values, name);
  const fieldTouched = get(touched, name);
  const invalid = !!error && (!!submitCount || (fieldTouched && !!value));

  return (
    <FormControl
      marginTop="3"
      position="relative"
      isInvalid={invalid}
      {...styleProps}
    >
      <StyledCheckbox
        isChecked={value}
        {...getFieldProps(name)}
        {...props}
        isInvalid={invalid}
        display="flex"
        alignItems="flex-start"
        borderColor="gray.300"
        color={invalid ? "red.500" : "inherit"}
        autoFocus={autoFocus}
      >
        {label}
      </StyledCheckbox>
    </FormControl>
  );
};

export default CheckBoxInput;
