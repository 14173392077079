import { useReactiveVar } from "@apollo/client";
import {
  Avatar,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  faBriefcase,
  faIdCard,
  faIndustry,
  faLink,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import ContactUserInfo from "../../components/elements/contactUserInfo";
import Link from "../../components/elements/link";
import AddressIcon from "../../components/icons/address";
import InviteIcon from "../../components/icons/invite";
import PhoneIcon from "../../components/icons/phone";
import { CompanyWithContactUsersFragmentFragment } from "../../graphql/graphql";
import {
  currentCompanyPlanVar,
  currentCompanyRoleVar,
} from "../../graphql/reactiveVariables";
import useCompanySettingsProgress from "../../hooks/useCompanySettingsProgress";
import ROLES from "../../roles";
import { getRoutePath } from "../../router";
import {
  addressToLinesString,
  addressToNonLinesString,
} from "../../utils/address";

interface CompanySettingsPagePresenterProps {
  currentCompany: CompanyWithContactUsersFragmentFragment;
}

const CompanySettingsPagePresenter: React.FC<
  CompanySettingsPagePresenterProps
> = ({ currentCompany }) => {
  const companySettingsCompletedPercentage = useCompanySettingsProgress();
  const plan = useReactiveVar(currentCompanyPlanVar);
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const [
    initialCompanySettingsCompletedPercentage,
    setInitialCompanySettingsCompletedPercentage,
  ] = useState<number>();
  useEffect(() => {
    if (
      companySettingsCompletedPercentage &&
      !initialCompanySettingsCompletedPercentage
    ) {
      setInitialCompanySettingsCompletedPercentage(
        companySettingsCompletedPercentage
      );
    }
  }, [
    companySettingsCompletedPercentage,
    initialCompanySettingsCompletedPercentage,
  ]);

  const canChangeName =
    !!currentCompanyRole &&
    ROLES.companyNameChange.includes(currentCompanyRole.role);
  const canChangeAddress =
    !!currentCompanyRole &&
    ROLES.companyAddressChange.includes(currentCompanyRole.role);
  const canChangeContact =
    !!currentCompanyRole &&
    ROLES.companyContactChange.includes(currentCompanyRole.role);
  const canChangeEmail =
    !!currentCompanyRole &&
    ROLES.companyEmailChange.includes(currentCompanyRole.role);
  const canChangePhone =
    !!currentCompanyRole &&
    ROLES.companyPhoneChange.includes(currentCompanyRole.role);
  const canChangeWebsite =
    !!currentCompanyRole &&
    ROLES.companyWebsiteChange.includes(currentCompanyRole.role);
  const canChangeBusinessType =
    !!currentCompanyRole &&
    ROLES.companyBusinessTypeChange.includes(currentCompanyRole.role);
  const canChangeIndustry =
    !!currentCompanyRole &&
    ROLES.companyIndustryChange.includes(currentCompanyRole.role);

  return (
    <Box paddingBottom="8">
      <Flex alignItems="center" justifyContent="flex-end" fontSize="sm">
        {(initialCompanySettingsCompletedPercentage !== 100 ||
          companySettingsCompletedPercentage !== 100) && (
          <CircularProgress
            value={companySettingsCompletedPercentage}
            size="40px"
            color="green.500"
            marginX="1"
          >
            <CircularProgressLabel>
              {companySettingsCompletedPercentage}%
            </CircularProgressLabel>
          </CircularProgress>
        )}
        {companySettingsCompletedPercentage === 0 ||
        companySettingsCompletedPercentage === 100
          ? ""
          : " Almost done!"}
      </Flex>
      <List>
        {canChangeName && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyNameChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <Avatar
                    name={currentCompany.name}
                    size="md"
                    backgroundColor="gray.800"
                    color="white"
                  />
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Name
                    </Heading>
                    {currentCompany.name ? (
                      <Box as="span" color="gray.800" marginTop="2">
                        {currentCompany.name}
                      </Box>
                    ) : (
                      <Text fontSize="xs">Add your company name</Text>
                    )}
                  </Box>
                </Box>
                <Tooltip label="Edit Name" hasArrow placement="left">
                  <Box
                    as="span"
                    fontSize="sm"
                    color="gray.500"
                    _groupHover={{ color: "gray.600" }}
                  >
                    {!!currentCompany.name && <FontAwesomeIcon icon={faPen} />}
                  </Box>
                </Tooltip>
              </Flex>
            </Link>
          </ListItem>
        )}
        {canChangeAddress && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyAddressChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <Box width="5" color="secondary.500">
                    <AddressIcon />
                  </Box>
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Address
                    </Heading>
                    {currentCompany.address1 ? (
                      <Box as="span" color="gray.800" marginTop="2">
                        {addressToLinesString(currentCompany)}
                        <br />
                        {addressToNonLinesString(currentCompany)}
                      </Box>
                    ) : (
                      <Text fontSize="xs">Add your company address</Text>
                    )}
                  </Box>
                </Box>
                <Tooltip label="Edit Address" hasArrow placement="left">
                  <Box
                    as="span"
                    fontSize="sm"
                    color="gray.500"
                    _groupHover={{ color: "gray.600" }}
                  >
                    {!!currentCompany.address1 && (
                      <FontAwesomeIcon icon={faPen} />
                    )}
                  </Box>
                </Tooltip>
              </Flex>
            </Link>
          </ListItem>
        )}
        {canChangeContact && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyContactChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <Box width="5" color="secondary.500">
                    <FontAwesomeIcon icon={faIdCard} />
                  </Box>
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Contact Info
                    </Heading>
                    {currentCompany.companyCompanyUsers.length > 0 ? (
                      <ContactUserInfo
                        contactCompanyUsers={currentCompany.companyCompanyUsers.map(
                          (ccu) => ccu.companyUser
                        )}
                        textProps={{ fontWeight: "400", color: "gray.900" }}
                      />
                    ) : (
                      <Text fontSize="xs">Add your company contact info</Text>
                    )}
                  </Box>
                </Box>
                <Tooltip label="Edit Contacts" hasArrow placement="left">
                  <Box
                    as="span"
                    fontSize="sm"
                    color="gray.500"
                    _groupHover={{ color: "gray.600" }}
                  >
                    {currentCompany.companyCompanyUsers.length > 0 && (
                      <FontAwesomeIcon icon={faPen} />
                    )}
                  </Box>
                </Tooltip>
              </Flex>
            </Link>
          </ListItem>
        )}
        {canChangeEmail && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyEmailChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <InviteIcon color="secondary.500" />
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Email
                    </Heading>
                    {currentCompany.email ? (
                      <Box as="span" color="gray.800" marginTop="2">
                        {currentCompany.email}
                      </Box>
                    ) : (
                      <Text fontSize="xs">Add your company email</Text>
                    )}
                  </Box>
                </Box>
                <Box
                  as="span"
                  fontSize="sm"
                  color="gray.500"
                  _groupHover={{ color: "gray.600" }}
                >
                  {!!currentCompany.email && <FontAwesomeIcon icon={faPen} />}
                </Box>
              </Flex>
            </Link>
          </ListItem>
        )}
        {canChangePhone && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyPhoneChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <PhoneIcon color="secondary.500" />
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Phone
                    </Heading>
                    {currentCompany.phone ? (
                      <Box as="span" color="gray.800" marginTop="2">
                        {currentCompany.phone}
                      </Box>
                    ) : (
                      <Text fontSize="xs">Add your company phone</Text>
                    )}
                  </Box>
                </Box>
                <Box
                  as="span"
                  fontSize="sm"
                  color="gray.500"
                  _groupHover={{ color: "gray.600" }}
                >
                  {!!currentCompany.phone && <FontAwesomeIcon icon={faPen} />}
                </Box>
              </Flex>
            </Link>
          </ListItem>
        )}
        {canChangeWebsite && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyWebsiteChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <Box width="5" color="secondary.500">
                    <FontAwesomeIcon icon={faLink} />
                  </Box>
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Website
                    </Heading>
                    {currentCompany.website ? (
                      <Box as="span" color="gray.800" marginTop="2">
                        {currentCompany.website}
                      </Box>
                    ) : (
                      <Text fontSize="xs">Add your company website</Text>
                    )}
                  </Box>
                </Box>
                <Tooltip label="Edit Website" hasArrow placement="left">
                  <Box
                    as="span"
                    fontSize="sm"
                    color="gray.500"
                    _groupHover={{ color: "gray.600" }}
                  >
                    {!!currentCompany.website && (
                      <FontAwesomeIcon icon={faPen} />
                    )}
                  </Box>
                </Tooltip>
              </Flex>
            </Link>
          </ListItem>
        )}
        {canChangeIndustry && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyIndustryChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <Box width="5" color="secondary.500">
                    <FontAwesomeIcon icon={faIndustry} />
                  </Box>
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Industry
                    </Heading>
                    {currentCompany.industry ? (
                      <Box as="span" color="gray.800" marginTop="2">
                        {currentCompany.industry}
                      </Box>
                    ) : (
                      <Text fontSize="xs">Add your industry type</Text>
                    )}
                  </Box>
                </Box>
                <Tooltip label="Edit Industry" hasArrow placement="left">
                  <Box
                    as="span"
                    fontSize="sm"
                    color="gray.500"
                    _groupHover={{ color: "gray.600" }}
                  >
                    {!!currentCompany.industry && (
                      <FontAwesomeIcon icon={faPen} />
                    )}
                  </Box>
                </Tooltip>
              </Flex>
            </Link>
          </ListItem>
        )}
        {plan?.productName === "Professional" && canChangeBusinessType && (
          <ListItem
            backgroundColor="secondary.10"
            _hover={{ boxShadow: "md" }}
            role="group"
            cursor="pointer"
            marginTop="4"
          >
            <Link
              to={getRoutePath("companyBusinessTypeChange")}
              variant="link"
              colorScheme="secondary"
              width="full"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="full"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
              >
                <Box display="inherit" alignItems="center">
                  <Box width="5" color="secondary.500">
                    <FontAwesomeIcon icon={faBriefcase} />
                  </Box>
                  <Box marginX="4">
                    <Heading as="h3" size="sm" color="gray.600">
                      Business Type
                    </Heading>
                    {currentCompany.businessType ? (
                      <Box as="span" color="gray.800" marginTop="2">
                        {currentCompany.businessType}
                      </Box>
                    ) : (
                      <Text fontSize="xs">Add your business type</Text>
                    )}
                  </Box>
                </Box>
                <Tooltip label="Edit Business Type" hasArrow placement="left">
                  <Box
                    as="span"
                    fontSize="sm"
                    color="gray.500"
                    _groupHover={{ color: "gray.600" }}
                  >
                    {!!currentCompany.businessType && (
                      <FontAwesomeIcon icon={faPen} />
                    )}
                  </Box>
                </Tooltip>
              </Flex>
            </Link>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default CompanySettingsPagePresenter;
