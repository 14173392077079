import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  getAffectedAssetCreatedMessage,
  getAffectedByAssetCreatedMessage,
} from "../../../../constants/lang/en";
import {
  AssetDocument,
  useAssetAffectedAssetCreateMutation,
  useAssetQuery,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../../utils/serverErrors";
import Presenter, { AffectedAssetFormData } from "./presenter";

interface AffectedAssetCreatePageProps {
  type?: "affected" | "affectedBy";
}

const AffectedAssetCreatePage: React.FC<AffectedAssetCreatePageProps> = ({
  type = "affected",
}) => {
  const typeAffected = type === "affected";
  const { assetId } = useParams();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const [assetAffectedAssetCreateMutation, { loading }] =
    useAssetAffectedAssetCreateMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: AssetDocument,
          variables: { id: assetId },
        },
      ],
    });
  const { data } = useAssetQuery({
    variables: { id: assetId },
  });
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const navigate = useNavigate();

  const assetIds = React.useMemo(() => {
    if (!data) return [];
    return typeAffected
      ? [
          assetId,
          ...data.asset.assetAffectedAssets.map((aa) => aa.affectedAsset.id),
        ]
      : [assetId, ...data.asset.assetAffectedByAssets.map((aa) => aa.asset.id)];
  }, [typeAffected, assetId, data]);

  const onSubmit = async (
    data: AffectedAssetFormData,
    { setFieldError }: FormikHelpers<AffectedAssetFormData>
  ) => {
    const selectedAssetIds = data.assetIds.filter(
      (aId) => !assetIds.includes(aId)
    );
    try {
      const { data: serverData, errors } =
        await assetAffectedAssetCreateMutation({
          variables: {
            data: selectedAssetIds.map((aId) => ({
              affectedAssetId: typeAffected ? aId : assetId,
              assetId: typeAffected ? assetId : aId,
              assetPartId: data.assetPartId || undefined,
            })),
          },
        });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: typeAffected
            ? getAffectedAssetCreatedMessage(selectedAssetIds.length)
            : getAffectedByAssetCreatedMessage(selectedAssetIds.length),
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetsShow", { assetId }));
    }, 500);
  };

  return data && assetId ? (
    <Presenter
      handleSubmit={onSubmit}
      loading={loading}
      assetIds={assetIds}
      isOpen={isOpen}
      onDrawerClose={onDrawerClose}
      typeAffected={typeAffected}
      asset={data.asset}
      assetPartId={searchParams.get("assetPartId") ?? undefined}
    />
  ) : null;
};

export default AffectedAssetCreatePage;

gql`
  mutation AssetAffectedAssetCreate($data: [AssetAffectedAssetCreateInput!]!) {
    assetAffectedAssetCreate(data: $data)
  }
`;
