import { captureException } from "@sentry/browser";

const handleException = (error: Error): void => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    captureException(error);
  } else {
    console.error(error);
  }
};

export default handleException;

export const logReactProfileInfo = (
  id: any, // the "id" prop of the Profiler tree that has just committed
  phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration: any, // time spent rendering the committed update
  baseDuration: any, // estimated time to render the entire subtree without memoization
  startTime: any, // when React began rendering this update
  commitTime: any, // when React committed this update
  interactions: any // the Set of interactions belonging to this update
): any => {
  console.log({
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
    interactions,
  });
};
