import { Icon, IconProps } from "@chakra-ui/react";

const Sink = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M106.363 50.066H89.23v-8.66a2.637 2.637 0 0 0-2.636-2.637H75.297a2.637 2.637 0 0 0-2.637 2.637v8.66h-6.023V30.11a5.832 5.832 0 0 1 5.836-5.836 5.843 5.843 0 0 1 5.836 5.836 2.637 2.637 0 0 0 5.273 0c0-6.125-4.984-11.109-11.11-11.109-6.135 0-11.109 4.965-11.109 11.11v19.956H55.34v-8.66a2.637 2.637 0 0 0-2.637-2.637H41.406a2.637 2.637 0 0 0-2.637 2.637v8.66H21.637A2.637 2.637 0 0 0 19 52.703v5.648c0 13.935 11.274 25.231 25.23 25.231h11.485v6.024h-.188a2.637 2.637 0 0 0-2.636 2.636v5.649C52.89 104.016 57.874 109 64 109s11.11-4.984 11.11-11.11v-5.648a2.637 2.637 0 0 0-2.637-2.636h-.188v-6.024H83.77c13.934 0 25.23-11.274 25.23-25.23v-5.649a2.637 2.637 0 0 0-2.637-2.637Zm-28.43-6.023h6.024v6.023h-6.023v-6.023Zm-33.89 0h6.023v6.023h-6.023v-6.023Zm25.793 53.848A5.843 5.843 0 0 1 64 103.727a5.843 5.843 0 0 1-5.836-5.836v-3.012h11.672v3.012Zm-8.848-8.285v-6.024h6.024v6.024h-6.024Zm42.739-31.255c0 11.04-8.935 19.958-19.957 19.958H44.23c-11.04 0-19.957-8.935-19.957-19.958V55.34h79.454v3.011Z"
      fill="currentColor"
    />
  </Icon>
);

export default Sink;
