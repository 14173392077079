import { Icon, IconProps } from "@chakra-ui/react";

const SolarPanel = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M64 10.08c-5.209 0-9.446 4.237-9.446 9.445 0 5.209 4.237 9.445 9.446 9.445 5.208 0 9.445-4.237 9.445-9.445S69.208 10.08 64 10.08Zm0 15.12a5.682 5.682 0 0 1-5.676-5.675A5.682 5.682 0 0 1 64 13.85a5.682 5.682 0 0 1 5.675 5.676A5.682 5.682 0 0 1 64 25.201ZM64 0a1.885 1.885 0 0 0-1.885 1.885v2.52a1.885 1.885 0 1 0 3.77 0v-2.52C65.885.844 65.04 0 64 0ZM54.641 7.5 52.859 5.72a1.885 1.885 0 0 0-2.665 2.665l1.781 1.782c.368.368.851.552 1.333.552A1.885 1.885 0 0 0 54.641 7.5ZM48.88 17.64h-2.52a1.885 1.885 0 1 0 0 3.77h2.52a1.885 1.885 0 0 0 0-3.77ZM54.64 28.884a1.886 1.886 0 0 0-2.665 0l-1.782 1.782a1.885 1.885 0 1 0 2.666 2.665l1.782-1.782a1.885 1.885 0 0 0 0-2.665ZM64 32.76a1.885 1.885 0 0 0-1.885 1.886v2.52a1.885 1.885 0 1 0 3.77 0v-2.52c0-1.041-.844-1.885-1.885-1.885ZM77.807 30.666l-1.782-1.781a1.885 1.885 0 0 0-2.666 2.665l1.782 1.782c.368.368.85.552 1.333.552a1.885 1.885 0 0 0 1.333-3.217ZM81.64 17.64h-2.52a1.885 1.885 0 1 0 0 3.77h2.52a1.885 1.885 0 1 0 0-3.77ZM77.806 5.718a1.885 1.885 0 0 0-2.665 0L73.359 7.5a1.885 1.885 0 0 0 2.666 2.666l1.782-1.782a1.885 1.885 0 0 0 0-2.666Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m116.28 96.516-.004-.07a1.584 1.584 0 0 0-.03-.195l-.001-.004-3.356-15.945-.001-.002-.003-.014-3.356-15.944-.001-.003-.003-.014-3.36-15.96a1.884 1.884 0 0 0-1.844-1.496H23.678c-.891 0-1.66.624-1.844 1.497l-3.36 15.957c0 .007-.002.014-.003.02l-3.357 15.944a.224.224 0 0 0-.003.014l-3.356 15.945a1.906 1.906 0 0 0-.032.205v.026l-.002.023v.008c-.004.042-.007.084-.007.127v7.561c0 1.041.844 1.884 1.885 1.884h44.735v10.589H43.84a1.885 1.885 0 0 0-1.885 1.885v5.676H23.678a1.885 1.885 0 1 0 0 3.77h80.64a1.885 1.885 0 0 0 0-3.77H86.045v-5.676a1.885 1.885 0 0 0-1.885-1.885H69.665V106.08H114.4a1.884 1.884 0 0 0 1.885-1.884v-7.56c.001-.021-.001-.042-.002-.062l-.001-.015-.001-.013-.001-.03Zm-4.202-1.766h-29.52l-.856-12.19h27.81l2.566 12.19Zm-5.926-28.15 2.566 12.19h-27.28l-.856-12.19h25.57Zm-3.36-15.961 2.566 12.19h-25.04l-.856-12.19h23.33Zm-50.476 0h23.367l.855 12.19H51.462l.855-12.19Zm-1.12 15.96h25.607l.856 12.191H50.34l.856-12.19Zm-1.12 15.96h27.847l.856 12.191H49.22l.856-12.19ZM25.209 50.64h23.33l-.856 12.19h-25.04l2.566-12.19Zm-3.36 15.96h25.57l-.856 12.191h-27.28l2.566-12.19Zm60.428 53.841v3.791H45.724v-3.791h36.552Zm-20.172-3.77v-10.589h3.791v10.589h-3.79ZM31.462 94.75h-15.54l2.566-12.19h27.81l-.856 12.19H31.463Zm36.319 7.56.021.001h44.713V98.52H15.483v3.791h44.725l.011-.001h7.562Z"
      fill="currentColor"
    />
  </Icon>
);

export default SolarPanel;
