import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  UserFragmentFragment,
  useUserUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  firstNameSchema,
  lastNameSchema,
  yupObject,
} from "../../../utils/validation";

interface ChangeNamePagePresenterProps {
  user: UserFragmentFragment | undefined;
}

const ChangeNamePagePresenter: React.FC<ChangeNamePagePresenterProps> = ({
  user,
}) => {
  const [userUpdateMutation, { loading }] = useUserUpdateMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (
    data: ChangeNameFormData,
    { setFieldError }: FormikHelpers<ChangeNameFormData>
  ) => {
    try {
      const { data: serverData, errors } = await userUpdateMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        navigate(getRoutePath("mySettings"));
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
      }}
      validationSchema={ChangeNameValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ getFieldProps }) => (
        <Form noValidate>
          <FormGroup label="First Name" name="firstName">
            <Input
              autoFocus
              autoComplete="given-name"
              {...getFieldProps("firstName")}
            />
          </FormGroup>
          <FormGroup label="Last Name" name="lastName">
            <Input autoComplete="family-name" {...getFieldProps("lastName")} />
          </FormGroup>
          <Box marginY="10">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeNamePagePresenter;

const ChangeNameValidationSchema = yupObject().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
});

type ChangeNameFormData = {
  firstName: string;
  lastName: string;
};

gql`
  mutation userUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      ...UserFragment
    }
  }
`;
