import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownEditor,
  EuiMarkdownFormat,
  getDefaultEuiMarkdownParsingPlugins,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";

interface TextareaMarkdownPresenterProps extends HTMLAttributes<HTMLElement> {
  value: string;
  onChange: (value: any) => void;
  save: () => void;
  cancel?: () => void;
  description?: string;
  label?: string;
  saveButtonName: string;
}

const TextareaMarkdownPresenter: FC<TextareaMarkdownPresenterProps> = ({
  value = "",
  onChange,
  placeholder = "",
  description = "",
  label = "",
  cancel,
  save,
  style,
  className,
  onBlur = () => null,
  saveButtonName,
}) => {
  const plugins = getDefaultEuiMarkdownParsingPlugins({ exclude: ["tooltip"] });
  const { euiTheme }: UseEuiTheme<{ colors: any }> = useEuiTheme();
  const [isMarkdown, setIsMarkdown] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (value.trim().length && disabled) {
      setDisabled(false);
    }
    if (!value.trim().length && !disabled) {
      setDisabled(true);
    }
  }, [value]);

  const handleSave = () => {
    save();
    setIsMarkdown(false);
  };

  const handleCancel = () => {
    cancel?.();
    setIsMarkdown(false);
  };

  return !isMarkdown ? (
    <EuiFlexItem>
      <EuiMarkdownFormat
        onClick={() => setIsMarkdown(true)}
        className={className}
        style={style}
        css={css`
          background-color: var(--color-hover);
        `}
      >
        {description || placeholder}
      </EuiMarkdownFormat>
    </EuiFlexItem>
  ) : (
    <EuiFlexGroup
      direction="column"
      justifyContent="flexStart"
      alignItems="stretch"
      gutterSize="none"
      style={{ marginBottom: euiTheme.size.l }}
    >
      <EuiMarkdownEditor
        aria-label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        height={135}
        maxHeight={500}
        uiPlugins={[]}
        parsingPluginList={plugins}
        style={{ marginBottom: euiTheme.size.base, wordBreak: "break-all" }}
        css={css`
          min-height: var(--size-135);

          .euiMarkdownEditorFooter .euiPopover {
            display: none;
          }

          .euiMarkdownEditorToolbar .euiMarkdownEditorToolbar__buttons {
            .euiButtonIcon {
              &[aria-label="Ordered list"],
              &[aria-label="Task list"],
              &[aria-label="Quote"] {
                display: none;
              }
            }

            .euiToolTipAnchor:has(> .euiButtonIcon[aria-label="Unordered list"]) {
              margin-right: 0;
            }
          }
        `}
        onBlur={onBlur}
      />
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiButtonEmpty
          className={"critical-asset-button_compressed"}
          color="text"
          onClick={handleCancel}
        >
          Cancel
        </EuiButtonEmpty>
        <EuiButton
          className={"critical-asset-button_compressed"}
          fill={true}
          onClick={handleSave}
          disabled={disabled}
        >
          {saveButtonName}
        </EuiButton>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};

export default TextareaMarkdownPresenter;
