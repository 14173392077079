import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { useMaintenanceIndexQuery } from "../../graphql/graphql";
import {
  calendarOptionsVar,
  layoutOptionsVar,
} from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface MaintenancePageProps {}

const MaintenancePage: React.FC<MaintenancePageProps> = ({ children }) => {
  const { startDate, endDate } = useReactiveVar(calendarOptionsVar);
  const { data } = useMaintenanceIndexQuery({
    variables: { startDate: startDate.getTime(), endDate: endDate.getTime() },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    layoutOptionsVar({
      title: "Service Schedule",
      styleProps: { paddingX: "0" },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Service Schedule</title>
      </Helmet>
      <Presenter maintenances={data?.maintenances} />
      {children}
    </>
  );
};

export default MaintenancePage;

gql`
  fragment MaintenanceIndexFragment on Maintenance {
    id
    name
    intervalType
    intervalValue
    startDateTime
    endDateTime
    endAfter
    durationType
    durationValue
    assetId
    createdAt
    deletedScheduleTimestamps
    misc {
      resolvedName
      assetCategoryId
    }
    maintenanceSchedulesForDateRange(startDate: $startDate, endDate: $endDate) {
      id
      completedAt
      scheduledAt
    }
  }

  query MaintenanceIndex($startDate: Timestamp, $endDate: Timestamp) {
    maintenances {
      ...MaintenanceIndexFragment
    }
  }
`;
