import React from "react";
import { useParams } from "react-router-dom";

import { useFileQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface FileEditProps {}

const FileEdit: React.FC<FileEditProps> = () => {
  const { fileId } = useParams();
  const { data } = useFileQuery({
    variables: { id: fileId },
  });

  return data && fileId ? <Presenter file={data.file} /> : null;
};

export default FileEdit;
