import { useEffect, useState } from "react";

import { useFoldersQuery } from "../graphql/graphql";
import { FolderWithChildren } from "./useFolder";

const useFolderTreeRestricted = (folderId: string) => {
  const { data } = useFoldersQuery();
  const [folderTree, setFolderTree] = useState<FolderWithChildren | null>(null);

  useEffect(() => {
    if (!data) return;

    const findFolder = (id: string) =>
      data.folders.find((folder) => folder.id === id);

    let id = folderId;
    let localFolderTree: FolderWithChildren | null = null;

    while (true) {
      const folder = findFolder(id);
      if (!folder) break;

      const newFolder: any = Object.assign({}, folder, { parent: null });
      if (!localFolderTree) {
        localFolderTree = newFolder;
      } else {
        localFolderTree = assignDeepestChildParent(localFolderTree, newFolder);
      }

      if (!newFolder.parentId) {
        setFolderTree(localFolderTree);
        break;
      }
      id = newFolder.parentId;
    }
  }, [data, folderId]);

  return folderTree;
};

export default useFolderTreeRestricted;

const assignDeepestChildParent = (
  folderTree: FolderWithChildren,
  folder: FolderWithChildren
): FolderWithChildren => {
  if (folderTree.parent) {
    return Object.assign({}, folderTree, {
      parent: assignDeepestChildParent(folderTree.parent as any, folder),
    });
  } else {
    return Object.assign({}, folderTree, { parent: folder });
  }
};
