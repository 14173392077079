import React, { useCallback, useMemo } from "react";

import FilterSelect, {
  FilterOption,
} from "../../../components/elements/filterSelect";
import { useCompletedByUsersQuery } from "../../../graphql/graphql";
import { sortCollator } from "../../../utils/sort";

interface CompletedByFilterProps {
  onChange: (ids: string[] | undefined) => void;
  value: string[] | undefined;
}

const CompletedByFilter: React.FC<CompletedByFilterProps> = ({
  onChange,
  value,
}) => {
  const completedByUsers = useCompletedByUsersQuery();

  const userOptions = useMemo(() => {
    let userOptions: FilterOption[] = [];
    if (completedByUsers?.data?.completedByUsers.length) {
      completedByUsers.data.completedByUsers.forEach((user) => {
        const label = user.fullName ? user.fullName : user.email;
        userOptions.push({
          label,
          value: user.id,
          query: label,
        });
      });
      userOptions.sort((a, b) => sortCollator.compare(a.query, b.query));
    }
    return userOptions;
  }, [completedByUsers?.data?.completedByUsers]);

  const handleChange = useCallback(
    (id: string) => {
      if (!value) {
        onChange([id]);
      } else if (value.includes(id)) {
        onChange(
          value.length === 1 ? undefined : value.filter((v) => v !== id)
        );
      } else {
        onChange([...value, id]);
      }
    },
    [value, onChange]
  );

  return (
    <FilterSelect
      label="Completed By"
      selectedOptions={value}
      allOptions={userOptions}
      handleFilterChange={handleChange}
    />
  );
};

export default CompletedByFilter;
