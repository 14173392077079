import { Icon, IconProps } from "@chakra-ui/react";

const Fuse = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M84.24 42.25c1.525 0 2.76-1.259 2.76-2.813V22.564C87 18.942 84.111 16 80.56 16H47.44C43.889 16 41 18.944 41 22.563v16.875c0 1.553 1.236 2.812 2.76 2.812h.92v43.5h-.92c-1.524 0-2.76 1.259-2.76 2.813v16.875c0 3.618 2.889 6.562 6.44 6.562h33.12c3.551 0 6.44-2.944 6.44-6.562V88.562c0-1.553-1.236-2.812-2.76-2.812h-.92v-43.5h.92ZM46.52 22.562a.93.93 0 0 1 .92-.937h33.12c.507 0 .92.42.92.938v14.062H46.52V22.562ZM50.2 42.25h8.784a11.244 11.244 0 0 0-.504 3.328c0 3.23 1.364 6.251 3.742 8.292 1.13.97 1.778 2.424 1.778 3.99 0 1.565-.648 3.02-1.778 3.989-2.378 2.04-3.742 5.062-3.742 8.292 0 3.229 1.364 6.25 3.742 8.291 1.13.97 1.778 2.424 1.778 3.99a5.314 5.314 0 0 1-1.141 3.328H50.2v-43.5Zm31.28 63.188a.93.93 0 0 1-.92.937H47.44a.93.93 0 0 1-.92-.937V91.375h34.96v14.063ZM77.8 85.75h-8.784c.329-1.06.504-2.18.504-3.328 0-3.23-1.364-6.251-3.742-8.292-1.13-.97-1.778-2.423-1.778-3.99 0-1.565.648-3.02 1.778-3.989 2.378-2.04 3.742-5.062 3.742-8.292 0-3.229-1.364-6.25-3.742-8.291-1.13-.97-1.778-2.424-1.778-3.99 0-1.242.409-2.413 1.141-3.328H77.8v43.5Z"
      fill="currentColor"
    />
  </Icon>
);

export default Fuse;
