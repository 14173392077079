import { Icon, IconProps } from "@chakra-ui/react";

const VrfIndoorUnit = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <circle
      cx={64}
      cy={64}
      r={47}
      stroke="currentColor"
      strokeWidth={6}
      fillOpacity={0}
    />
    <path
      d="M70.203 53.831h3.966v3.966l-5.492 5.492a1 1 0 0 0 0 1.414l2.59 2.59a1 1 0 0 0 1.414 0L77 62.973l4.32 4.32a1 1 0 0 0 1.413 0l2.59-2.59a1 1 0 0 0 0-1.414l-5.492-5.492v-3.966h3.966l5.492 5.492a1 1 0 0 0 1.414 0l2.59-2.59a1 1 0 0 0 0-1.414l-4.32-4.32 4.32-4.318a1 1 0 0 0 0-1.414l-2.59-2.59a1 1 0 0 0-1.414 0l-5.492 5.492h-3.966v-3.966l5.492-5.492a1 1 0 0 0 0-1.414l-2.59-2.59a1 1 0 0 0-1.414 0L77 39.027l-4.318-4.32a1 1 0 0 0-1.414 0l-2.59 2.59a1 1 0 0 0 0 1.414l5.492 5.492v3.966h-3.966l-5.492-5.492a1 1 0 0 0-1.414 0l-2.59 2.59a1 1 0 0 0 0 1.414L65.026 51l-4.319 4.319a1 1 0 0 0 0 1.414l2.59 2.59a1 1 0 0 0 1.414 0l5.492-5.492ZM55.053 93.786c3.848-7.007 2.237-15.825-3.83-20.965l-.01-.009.026.067-.004.05c1.182 3.203 1.017 6.72-.435 9.737l-1.024 2.127-.314-2.35a10.12 10.12 0 0 0-1.702-4.418h-.142l-.075-.218c.01 2.436-.49 4.836-1.471 7.1-1.288 2.963-1.099 6.38.506 9.14l1.108 1.907-1.993-.864c-3.286-1.424-5.913-4.168-7.207-7.528-1.451-3.751-1.182-8.1.719-11.632a20.007 20.007 0 0 0 2.065-5.8l.37-1.97.946 1.758c.451.838.783 1.745.99 2.698l.02.023.023.153.02-.007c2.832-3.86 4.529-8.662 4.776-13.525l.064-1.26 1.03.69c4.205 2.81 7.125 7.32 8.027 12.389l.021.104.01.016.047-.067a6.424 6.424 0 0 0 1.259-3.843v-2.165l1.273 1.726c2.961 4.012 4.479 8.988 4.275 14.013-.251 5.915-3.395 11.106-8.412 13.92L53.837 96l1.216-2.214Z"
      fill="currentColor"
    />
  </Icon>
);

export default VrfIndoorUnit;
