import { useApolloClient, useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Heading,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import AddressFields from "../../components/elements/addressFields";
import AgreementsField from "../../components/elements/agreementsField";
import FormGroup from "../../components/elements/formGroup";
import Link from "../../components/elements/link";
import {
  CompanyFragmentFragment,
  useCompanyCreateMutation,
  useUpdateCurrentCompanyMutation,
} from "../../graphql/graphql";
import {
  layoutOptionsVar,
  mainCompanyVar,
} from "../../graphql/reactiveVariables";
import { getRoutePath } from "../../router";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import {
  address1Schema,
  address2Schema,
  agreementsSchema,
  citySchema,
  nameSchema,
  stateSchema,
  yupObject,
  zipSchema,
} from "../../utils/validation";
import CompanyCreateCancel from "./companyCreateCancel";

interface CompanyCreatePagePresenterProps {
  userCompanies: CompanyFragmentFragment[];
}

const CompanyCreatePagePresenter: React.FC<CompanyCreatePagePresenterProps> = ({
  userCompanies,
}) => {
  const [companyCreateMutation, { loading }] = useCompanyCreateMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const client = useApolloClient();
  const mainUserCompany = useReactiveVar(mainCompanyVar);
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = async (
    data: CompanyFormData,
    { setFieldError }: FormikHelpers<CompanyFormData>
  ) => {
    try {
      if (mainUserCompany?.isMainCompany) {
        data.mainCompanyId = mainUserCompany.id;
        data.isMainCompany = false;
        data.planStripeId = mainUserCompany.planStripeId;
      }
      const { data: serverData, errors } = await companyCreateMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        await updateCurrentCompany({
          variables: { id: serverData.companyCreate?.id },
        });
        await client.resetStore();
        if (serverData.companyCreate.isMainCompany) {
          navigate(getRoutePath("companyPlanCreate"), { replace: true });
        } else {
          layoutOptionsVar({
            currentStep: 0,
          });
          navigate(getRoutePath("dashboard"), { replace: true });
        }
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const cancelCompanyCreate = (values: CompanyFormData) => {
    if (
      values.name ||
      values.address1 ||
      values.address2 ||
      values.city ||
      values.state ||
      values.zip ||
      values.agreements ||
      values.countryAlpha2 !== "US"
    ) {
      onOpen();
    } else {
      navigate(getRoutePath("dashboard"));
    }
  };

  return (
    <>
      <Box marginX="auto" maxWidth="md" marginTop="-2">
        <Heading size="3xl" textAlign="center">
          Add New Company
        </Heading>
        <Formik
          initialValues={{
            name: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            countryAlpha2: "US",
            coordinates: [0, 0],
            agreements: false,
          }}
          validationSchema={CompanyFormValidationSchema}
          onSubmit={onSubmit}
        >
          {({ getFieldProps, values }) => (
            <Form noValidate>
              <FormGroup label="Company Name" name="name">
                <Input
                  autoFocus
                  autoComplete="organization"
                  {...getFieldProps("name")}
                />
              </FormGroup>
              <AddressFields />
              <AgreementsField />
              <Box marginTop="8" marginBottom="4">
                <Button
                  width="full"
                  type="submit"
                  isLoading={loading}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  Save and Continue
                </Button>
              </Box>
              {isOpen && (
                <CompanyCreateCancel
                  isOpen={isOpen}
                  onClose={onClose}
                  onConfirm={() => navigate(getRoutePath("dashboard"))}
                />
              )}
              <Text
                fontSize="sm"
                color="gray.600"
                textAlign="center"
                marginBottom="4"
              >
                Don’t worry if you make a mistake, you can make changes later!
              </Text>
              {userCompanies.length ? (
                <Box textAlign="center" mt="6">
                  <Button
                    colorScheme="red"
                    variant="link"
                    color="gray.600"
                    fontSize="sm"
                    onClick={() => cancelCompanyCreate(values)}
                    type="button"
                  >
                    CANCEL
                  </Button>
                </Box>
              ) : (
                <Box textAlign="center" mt="6">
                  <Link
                    to={getRoutePath("signOut")}
                    variant="link"
                    colorScheme="secondary"
                    color="gray.600"
                    fontSize="sm"
                  >
                    SIGN OUT
                  </Link>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default CompanyCreatePagePresenter;

gql`
  mutation CompanyCreate($data: CompanyInput!) {
    companyCreate(data: $data) {
      ...CompanyWithContactUsersFragment
    }
  }
`;

const CompanyFormValidationSchema = yupObject().shape({
  name: nameSchema.label("Company name"),
  address1: address1Schema,
  address2: address2Schema,
  city: citySchema,
  state: stateSchema,
  zip: zipSchema,
  agreements: agreementsSchema,
});

type CompanyFormData = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  countryAlpha2: string;
  coordinates: number[];
  agreements: boolean;
  mainCompanyId?: string;
  isMainCompany?: boolean;
  planStripeId?: string;
};
