import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";
import React, { useEffect, useState } from "react";

import { useAssetQuery } from "../../../../../../graphql/graphql";
import { WorkOrder } from "../../../../models/workOrder";
import { AssetRelation, AssetToLocations } from "../models/relations";
import WorkOrderCreateAddAssetRelationsPresenter from "./presenter";

export type WorkOrderCreateAddAssetRelationsProps = {
  workOrder?: WorkOrder;
  assetId: any;
  assetToLocationMap: AssetToLocations;
};

const WorkOrderCreateAddAssetRelations = ({
  workOrder,
  assetId,
  assetToLocationMap,
}: WorkOrderCreateAddAssetRelationsProps) => {
  const { data, loading } = useAssetQuery({
    fetchPolicy: "network-only",
    variables: {
      id: assetId,
      companyId: workOrder?.companyId,
    },
    skip: !assetId || !workOrder?.companyId,
  });

  const [assets, setAssets] = useState<AssetRelation[]>([]);

  useEffect(() => {
    if (data) {
      const to: AssetRelation[] = data.asset.assetAffectedAssets.map(
        (item) => ({
          id: `to-${item.affectedAsset.id}`,
          assetId: item.affectedAsset.id,
          assetType: item.affectedAsset.assetType.name,
          assetName: item.affectedAsset.name,
          relationType: "Fed To",
        })
      );
      const from: AssetRelation[] = data.asset.assetAffectedByAssets.map(
        (item) => ({
          id: `from-${item.asset.id}`,
          assetId: item.asset.id,
          assetType: item.asset.assetType.name,
          assetName: item.asset.name,
          relationType: "Fed From",
        })
      );
      const relations = to.concat(from);
      relations.forEach((rel) => {
        const relatedLocations = assetToLocationMap[rel.assetId];
        if (relatedLocations?.length) {
          rel.locationDisplayName = relatedLocations.reduce((acc, item) => {
            const elem = `${item.locationName} ${
              item.locationType ? "(" + item.locationType + ")" : ""
            }`;
            if (!acc) {
              acc = elem;
            } else {
              acc += `, ${elem}`;
            }
            return acc;
          }, "");
        }
      });
      setAssets(relations);
    }
  }, [data]);

  return (
    <>
      {!loading ? (
        <WorkOrderCreateAddAssetRelationsPresenter relationItems={assets} />
      ) : (
        <EuiFlexGroup
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <EuiLoadingSpinner size="xxl" />
        </EuiFlexGroup>
      )}
    </>
  );
};
export default WorkOrderCreateAddAssetRelations;
