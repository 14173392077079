import { Icon, IconProps } from "@chakra-ui/react";

const LightEmergency1 = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M99.156 96.227h-5.86V66.734c0-16.154-13.142-29.296-29.296-29.296S34.703 50.58 34.703 66.733v29.493h-5.86a2.93 2.93 0 0 0-2.929 2.93v11.913a2.93 2.93 0 0 0 2.93 2.93h70.312a2.93 2.93 0 0 0 2.93-2.93V99.156a2.93 2.93 0 0 0-2.93-2.93ZM40.562 66.734c0-12.923 10.515-23.437 23.438-23.437 12.923 0 23.438 10.514 23.438 23.437v29.493H40.562V66.734Zm55.665 41.407H31.773v-6.055h64.454v6.055ZM63.805 31.578a2.93 2.93 0 0 0 2.93-2.93V16.93a2.93 2.93 0 0 0-5.86 0v11.718a2.93 2.93 0 0 0 2.93 2.93ZM40.737 35.72a2.93 2.93 0 1 0 5.074-2.93L39.952 22.64a2.93 2.93 0 1 0-5.074 2.93l5.86 10.148ZM30.834 50.839a2.93 2.93 0 0 0-1.072-4.002l-10.149-5.86a2.93 2.93 0 1 0-2.93 5.075l10.15 5.86a2.93 2.93 0 0 0 4.001-1.073ZM83.261 36.792a2.93 2.93 0 0 0 4.002-1.072l5.86-10.15a2.93 2.93 0 1 0-5.075-2.929l-5.86 10.149a2.93 2.93 0 0 0 1.073 4.002ZM112.389 42.05a2.93 2.93 0 0 0-4.002-1.073l-10.15 5.86a2.93 2.93 0 1 0 2.931 5.074l10.148-5.86a2.93 2.93 0 0 0 1.073-4.001Z"
      fill="currentColor"
    />
  </Icon>
);

export default LightEmergency1;
