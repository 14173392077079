import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import PageSpinner from "../../components/elements/pageSpinner";
import { getRoutePath } from "../../router";

interface WelcomePageProps {}

const WelcomePage: React.FC<WelcomePageProps> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`${getRoutePath("dashboard")}?CaAprType=welcome`);
  }, [navigate]);
  return <PageSpinner />;
};

export default WelcomePage;
