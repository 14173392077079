import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { sortBy } from "lodash";
import React from "react";

import {
  AssetWithRelationsFragmentFragment,
  FieldType,
} from "../../../../graphql/graphql";

interface AssetFieldsProps {
  asset: AssetWithRelationsFragmentFragment;
}

const AssetFields: React.FC<AssetFieldsProps> = ({ asset }) => {
  const fields = sortBy(asset.assetType.assetFields, "order");
  return (
    <SimpleGrid columns={2} spacing={2}>
      {fields.map((af) => {
        const assetFieldValue = asset.assetFieldValues.find(
          (afv) => afv.assetFieldId === af.id
        );
        return assetFieldValue?.value != null ? (
          <Box key={af.id} fontSize="xs">
            <Text fontWeight="semibold" fontSize="sm">
              {af.label}
            </Text>
            <Text>
              {af.type !== FieldType.Boolean ? (
                <Flex alignItems="center" as="span">
                  {assetFieldValue.value}
                  {!!af.unit && (
                    <Text as="span" color="gray.600" ml="1">
                      {af.unit}
                    </Text>
                  )}
                </Flex>
              ) : assetFieldValue.value ? (
                "Yes"
              ) : (
                "No"
              )}
            </Text>
          </Box>
        ) : null;
      })}
    </SimpleGrid>
  );
};

export default AssetFields;
