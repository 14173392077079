import { useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { ReactNode, useEffect, useState } from "react";

import { useLocationIndexQuery } from "../../../graphql/graphql";
import { WOFloorPlan } from "../../../graphql/types/queries/workOrdersQuery";
import { FloorPlansItem } from "../create/services/addAsset/plansTable/presenter";
import { getLocationBreadcrumbs } from "../helpers/breadcrumbs";
import { AssetPlansTypeEnum } from "../models/enums";

const GetLocationBreadcrumbs = (
  sublocationId: string,
  companyId: string,
  plan?: WOFloorPlan | null,
  planItem?: FloorPlansItem | null
) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();
  const { data: locationsData } = useLocationIndexQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });
  const [breadcrumbs, setBreadcrumbs] = useState<ReactNode>(null);

  const getLocationsBreadcrumbs = (stringBreadcrumbs: string[]) => {
    return (
      <span>
        {stringBreadcrumbs.map((breadcrumb, index) => {
          return (
            <span key={`breadcrumb-${breadcrumb}-${index}`}>
              {breadcrumb}
              {index + 1 !== stringBreadcrumbs.length ? (
                <span
                  key={`devider-${breadcrumb}-${index}`}
                  style={{
                    fontWeight: 500,
                    margin: `0 ${euiTheme.size.s}`,
                    color: euiTheme.colors.lightShade,
                  }}
                >
                  /
                </span>
              ) : null}
            </span>
          );
        })}
      </span>
    );
  };

  useEffect(() => {
    const newBreadcrumbs = getLocationBreadcrumbs(
      sublocationId,
      locationsData?.locations
    ).reverse();
    if (plan?.name) newBreadcrumbs.push(plan?.name);
    if (planItem?.type === AssetPlansTypeEnum.AFFECTED_AREA)
      newBreadcrumbs.push(planItem?.name);
    setBreadcrumbs(getLocationsBreadcrumbs(newBreadcrumbs));
  }, [locationsData, plan, planItem, sublocationId]);

  return breadcrumbs;
};

export default GetLocationBreadcrumbs;
