import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { removeCoupon } from "../../../utils/coupon";

interface CompanyCreateCancelPageProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CompanyCreateCancelPage: React.FC<CompanyCreateCancelPageProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleCancel = React.useCallback(() => {
    removeCoupon();
    onConfirm();
  }, [onConfirm]);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader textAlign="center">
            Are you sure want to cancel?
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody textAlign="center">
            This company and all info you have entered will be permanently
            deleted.  Are you sure?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Box>
              <Box>
                <Button onClick={onClose} width="48%">
                  No, Don't Cancel!
                </Button>
                <Button
                  onClick={handleCancel}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                >
                  Yes, Cancel
                </Button>
              </Box>
              <Text marginTop="2" fontSize="xs" textAlign="center">
                If you have questions, please contact{" "}
                <Link
                  href="mailto:support@criticalasset.com?subject=I need help with my account"
                  color="secondary.500"
                  isExternal
                >
                  Customer Support
                </Link>{" "}
                or call us at (833) 744-1010 and we’ll be happy to help!{" "}
              </Text>
            </Box>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CompanyCreateCancelPage;
