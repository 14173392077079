import pluralize from "pluralize";

import { CompanyFragmentFragment } from "../graphql/graphql";

const commonPlanValues = {
  electricalPanelMapping: {
    value: true,
  },
  plumbingMapping: {
    value: true,
  },
  hvac: {
    value: true,
  },
  mechanical: {
    value: true,
  },
  fireLifeSafety: {
    value: true,
  },
  affectedAreaViews: {
    value: true,
  },
  serviceReminders: {
    value: true,
  },
  inAppMessaging: {
    value: true,
  },
  reportingDashboard: {
    value: true,
  },
  mobileApp: {
    value: true,
  },
  offlineMode: {
    value: true,
  },
  visualAssetMapping: {
    value: true,
  },
  qrCodeLabelingAndScanning: {
    value: true,
  },
  dataImportingAndExporting: {
    value: true,
  },
  definableRolesAndPermission: {
    value: true,
  },
  photoCaptureAndAnnotation: {
    value: true,
  },
  inviteContractorsAndTechnicians: {
    value: true,
  },
  emailAndPhoneSupport: {
    value: true,
  },
  wordOrders: {
    value: null,
  },
  instantPartsOrdering: {
    value: null,
  },
  technicianDirectory: {
    value: null,
  },
  iotSensors: {
    value: null,
  },
  integrations: {
    value: null,
  },
};

export const PLANS = {
  Starter: {
    name: "Starter",
    users: {
      value: "1",
      rawValue: 1,
    },
    locations: {
      value: "1",
      rawValue: 1,
    },
    assets: {
      value: "100",
      rawValue: 100,
    },
    fileUploadAndStorage: {
      value: "100GB",
      rawValue: 100,
    },
    multiCompany: {
      value: false,
    },
    ...commonPlanValues,
  },
  Premium: {
    name: "Premium",
    users: {
      value: "5",
      rawValue: 5,
    },
    locations: {
      value: "5",
      rawValue: 5,
    },
    assets: {
      value: "500",
      rawValue: 500,
    },
    fileUploadAndStorage: {
      value: "500GB",
      rawValue: 500,
    },
    multiCompany: {
      value: false,
    },
    ...commonPlanValues,
  },
  Ultimate: {
    name: "Ultimate",
    users: {
      value: "10+",
      rawValue: 10,
      tooltip:
        "Add more users as needed. We always pro-rate, so you never overpay.",
    },
    locations: {
      value: "10+",
      rawValue: 10,
      tooltip:
        "Add more locations as needed. We always pro-rate, so you never overpay.",
    },
    assets: {
      value: "2000+",
      rawValue: 2000,
      tooltip:
        "Add more assets as needed. We always pro-rate, so you never overpay.",
    },
    fileUploadAndStorage: {
      value: "2TB+",
      rawValue: 2048,
      tooltip:
        "Add more storage as needed. We always pro-rate, so you never overpay.",
    },
    multiCompany: {
      value: false,
    },
    ...commonPlanValues,
  },
  Professional: {
    name: "Professional/MSP",
    users: {
      value: "Unlimited",
      rawValue: 99999,
      tooltip:
        "Includes unlimited full access users for your staff and unlimited view-only users for your customers.",
    },
    locations: {
      value: "Unlimited",
      rawValue: 99999,
      tooltip: "Includes unlimited customer locations",
    },
    assets: {
      value: "Unlimited",
      rawValue: 999999,
      tooltip:
        "Add more assets as needed. We always pro-rate, so you never overpay.",
    },
    fileUploadAndStorage: {
      value: "5TB+",
      rawValue: 5120,
      tooltip:
        "Add more storage as needed. We always pro-rate, so you never overpay.",
    },
    multiCompany: {
      value: true,
    },
    ...commonPlanValues,
  },
};

export const PLAN_MAPPINGS = [
  {
    key: "users",
    value: "Users",
    tooltip: "Easily add users as needed at any time.",
  },
  {
    key: "locations",
    value: "Locations",
    tooltip: "Easily add locations as needed at any time.",
  },
  {
    key: "assets",
    value: "Assets",
    tooltip: "Easily add locations as needed at any time.",
  },
  {
    key: "multiCompany",
    value: "Multi-company",
    tooltip:
      "Add and manage additional companies. Perfect for contractors, subcontractors and licensed trade professionals who want to make jobs drastically easier by having instant access to customers’ asset data and plans.",
  },
  {
    key: "electricalPanelMapping",
    value: "Electrical panel mapping",
    tooltip:
      "Map your electrical panels so that you always know what each circuit is connected to.",
  },
  {
    key: "plumbingMapping",
    value: "Plumbing mapping",
    tooltip:
      "Map your plumbing assets so that you always know what everything is connected to.",
  },
  {
    key: "hvac",
    value: "HVAC",
    tooltip: "Manage your air conditioning, heating and other HVAC assets.",
  },
  {
    key: "mechanical",
    value: "Mechanical",
    tooltip: "Manage all your mechanical assets.",
  },
  {
    key: "fireLifeSafety",
    value: "Fire-Life Safety",
    tooltip:
      "Manage your fire extinguishers, smoke detectors and other fire-life safety assets.",
  },
  {
    key: "affectedAreaViews",
    value: "Affected area views",
    tooltip:
      "See what is affected when circuits, plumbing or other assets or break down, go offline or need to be shut down.",
  },
  {
    key: "serviceReminders",
    value: "Service reminders",
    tooltip:
      "Get notified by email and on your phone when assets are due for upcoming service.",
  },
  {
    key: "inAppMessaging",
    value: "In-app messaging",
    tooltip:
      "Instantly communicate with your facilities team, HR, IT and any others involved in your facilities asset management and service process.",
  },
  {
    key: "reportingDashboard",
    value: "Reporting dashboard",
    tooltip:
      "Instantly view important asset information, such as upcoming and past due service requirements.",
  },
  {
    key: "mobileApp",
    value: "Mobile app",
    tooltip: "Access your facilities data from your phone.",
  },
  {
    key: "offlineMode",
    value: "Offline mode",
    tooltip: "Your data is always available, even when your Wi-Fi is not.",
  },
  {
    key: "visualAssetMapping",
    value: "Visual asset mapping",
    tooltip: "View all your assets on visual floor pans and building maps.",
  },
  {
    key: "qrCodeLabelingAndScanning",
    value: "QR code labeling & scanning",
    tooltip:
      "Print and scan QR codes to easily identify assets from your phone.",
  },
  {
    key: "fileUploadAndStorage",
    value: "File uploading & storage",
    tooltip:
      "Securely upload and store all your documents, images, floor plans, maps, plan sheets and user manuals in one place.",
  },
  {
    key: "dataImportingAndExporting",
    value: "Data importing & exporting",
    tooltip:
      "Easily import and export your asset data from and to Excel, Google docs or any other spreadsheet.",
  },
  {
    key: "definableRolesAndPermission",
    value: "Definable roles & permissions",
    tooltip:
      "Customize access for each member of your team and outside technicians.",
  },
  {
    key: "photoCaptureAndAnnotation",
    value: "Photo capture & annotation",
    tooltip: "Upload pictures and add notes to share with your team.",
  },
  {
    key: "inviteContractorsAndTechnicians",
    value: "Invite contactors & technicians",
    tooltip:
      "Invite outside contractors and technicians to securely access your facilities data on a limited one-time or ongoing basis.",
  },
  {
    key: "emailAndPhoneSupport",
    value: "Email & phone support",
    tooltip: "We love to help our customers!",
  },
  {
    key: "wordOrders",
    value: "Work orders (coming soon)",
    tooltip: "Assign and track work orders.",
  },
  {
    key: "instantPartsOrdering",
    value: "Instant parts ordering (coming soon)",
    tooltip:
      "When you need to order replacement parts, CriticalAsset makes it easy!",
  },
  {
    key: "technicianDirectory",
    value: "Technician directory (coming soon)",
    tooltip:
      "Instantly find qualified local technicians and schedule service calls.",
  },
  {
    key: "iotSensors",
    value: "IoT sensors (coming soon)",
    tooltip:
      "Fix problems before they happen and track assets with CriticalAsset IoT sensors.",
  },
  {
    key: "integrations",
    value: "Integrations (coming soon)",
    tooltip:
      "Use CMMS software and don’t want to switch? No worries! CriticalAsset integrates with many CMMS and other software apps.",
  },
];

type PlanType = "Starter" | "Premium" | "Ultimate" | "Professional";

export const getPlanName = (planName: string): string => {
  const plan = PLANS[planName as PlanType];
  if (!plan) return planName;
  return plan.name;
};

export const canAddNewUsersToCompany = (
  planName: PlanType,
  company: CompanyFragmentFragment | null
): boolean => {
  const plan = PLANS[planName];
  if (!plan || !company) return false;
  return company.companyUserCount < plan.users.rawValue;
};

export const canCompanySubscribeToPlan = (
  planName: PlanType,
  company: CompanyFragmentFragment
): boolean => {
  const plan = PLANS[planName];
  if (!plan) return false;
  return (
    company.companyUserCount < plan.users.rawValue &&
    company.locationCount < plan.locations.rawValue &&
    company.assetCount < plan.assets.rawValue &&
    company.fileSizeTotal / 1073741824 < plan.fileUploadAndStorage.rawValue
  );
};

export const planDisabledReason = (
  planName: PlanType,
  company: CompanyFragmentFragment
): string => {
  const plan = PLANS[planName];
  if (!plan) return "";

  return `You have ${pluralize(
    "user",
    company.companyUserCount,
    true
  )}, ${pluralize("location", company.locationCount, true)}, ${pluralize(
    "asset",
    company.assetCount,
    true
  )} and ${Math.round(
    company.fileSizeTotal / 1073741824
  )}GB of file storage but ${planName} plan supports only ${pluralize(
    "user",
    plan.users.rawValue,
    true
  )}, ${pluralize("location", plan.locations.rawValue, true)}, ${pluralize(
    "asset",
    plan.assets.rawValue,
    true
  )} and ${plan.fileUploadAndStorage.value} of file storage`;
};

export const inactivePlanStatus = [
  "incomplete",
  "incomplete_expired",
  "canceled",
  "unpaid",
  "past_due",
];

export const canceledPlanStatus = ["incomplete_expired", "canceled"];
