import { Icon, IconProps } from "@chakra-ui/react";

const Urinal = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M92.036 29.296H72.078v-8.6A2.695 2.695 0 0 0 69.382 18H58.618a2.695 2.695 0 0 0-2.696 2.695v8.6H35.964a2.695 2.695 0 0 0-2.695 2.696v34.777c0 12.815 7.886 23.822 19.06 28.427v3.133C52.328 104.764 57.563 110 64 110c6.436 0 11.672-5.236 11.672-11.672v-3.133c11.173-4.605 19.06-15.612 19.06-28.427V31.99a2.695 2.695 0 0 0-2.696-2.695ZM61.313 23.39h5.374v5.905h-5.374V23.39Zm8.968 74.937A6.288 6.288 0 0 1 64 104.61a6.288 6.288 0 0 1-6.281-6.28v-1.476A30.74 30.74 0 0 0 64 97.5a30.74 30.74 0 0 0 6.281-.647v1.475Zm19.06-31.56c0 13.973-11.368 25.34-25.341 25.34s-25.34-11.367-25.34-25.34V34.686H89.34v32.082Z"
      fill="currentColor"
    />
    <path
      d="M44.462 44.53v20.892c0 10.773 8.765 19.538 19.538 19.538s19.537-8.765 19.537-19.538V44.53a2.695 2.695 0 0 0-2.695-2.695H47.158a2.695 2.695 0 0 0-2.696 2.695Zm5.391 2.695h28.294v18.197c0 7.8-6.346 14.147-14.147 14.147-7.8 0-14.147-6.346-14.147-14.147V47.225Z"
      fill="currentColor"
    />
    <path
      d="M64 71.52c1.41 0 2.76-1.24 2.695-2.695-.065-1.46-1.184-2.696-2.695-2.696-1.41 0-2.76 1.24-2.695 2.695.065 1.46 1.184 2.696 2.695 2.696Z"
      fill="currentColor"
    />
  </Icon>
);

export default Urinal;
