import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { useEffect, useState } from "react";

import Combobox from "../../../../components/combobox";
import Label from "../../../../components/label";
import Select from "../../../../components/select";
import TreeSelect, { TreeNode } from "../../../../components/treeSelect";
import { MessageUtils } from "../../../../helpers/messages";

interface WorkOrderCreateAddAssetHeaderPresenterProps {
  locations: TreeNode[];
  filtersEnabled: boolean;
  categories?: EuiSuperSelectOption<string>[];
  types?: EuiComboBoxOptionOption<string>[];
  assets?: EuiComboBoxOptionOption<string>[];
  onLocationSelected: (id: string) => void;
  onCategorySelected: (id: string) => void;
  onAssetSelected: (id: string) => void;
  onTypeSelected: (id: string) => void;
  onTypeBlur: () => void;
  selectedType?: string;
  selectedCategory?: string;
  selectedAsset?: string | null;
  selectedLocation?: string | null;
}

const WorkOrderCreateAddAssetHeaderPresenter: React.FC<
  WorkOrderCreateAddAssetHeaderPresenterProps
> = ({
  locations,
  filtersEnabled,
  assets,
  categories,
  types,
  selectedCategory,
  selectedType,
  selectedAsset,
  selectedLocation,
  onLocationSelected,
  onCategorySelected,
  onTypeSelected,
  onAssetSelected,
  onTypeBlur,
}) => {
  const [assetTouched, setAssetTouched] = useState(false);
  const [assetError, setAssetError] = useState<string[]>([]);
  const [selectedLocationNode, seSelectedLocationNode] =
    useState<TreeNode | null>(null);

  const getLocationById = (
    locationId: string,
    locationTree: TreeNode[]
  ): TreeNode | null => {
    return locationTree.reduce((result: null | TreeNode, node: TreeNode) => {
      if (result) return result;
      if (node.id === locationId) {
        return node;
      } else if (node.children?.length) {
        return getLocationById(locationId, node.children);
      } else return result;
    }, null);
  };

  useEffect(() => {
    if (!selectedAsset && assetTouched) {
      setAssetError([MessageUtils.commonRequiredFieldErrorMessage]);
    } else {
      setAssetError([]);
    }
  }, [assetTouched, selectedAsset]);

  useEffect(() => {
    if (selectedLocation && locations.length) {
      const selectedNode = getLocationById(selectedLocation, locations);
      seSelectedLocationNode(selectedNode || null);
    }
  }, [selectedLocation, locations]);

  return (
    <>
      <EuiFlexGroup
        direction={"row"}
        gutterSize={"none"}
        className={"asset-select-wrapper"}
      >
        <EuiFlexItem style={{ marginRight: "54px", minWidth: "275px" }}>
          <Label
            label={"Sublocation"}
            required={true}
            style={{ paddingBottom: "5px" }}
          />
          <TreeSelect
            items={locations}
            placeholder={"Select Sublocation"}
            selectedItem={selectedLocationNode}
            onSelect={onLocationSelected}
            required={true}
          />
        </EuiFlexItem>
        <EuiFlexItem className={"border-right-0 ellipsis"}>
          <Select
            label={"Asset Category"}
            selectedValue={selectedCategory}
            disabled={!filtersEnabled}
            placeholder={"Select Asset Category"}
            options={categories || []}
            onSelect={onCategorySelected}
          />
        </EuiFlexItem>
        <EuiFlexItem className={"border-0 ellipsis"}>
          <Combobox
            label={"Asset Type"}
            selectedValue={selectedType}
            disabled={!filtersEnabled}
            placeholder={"Select Asset Type"}
            options={types || []}
            onChange={onTypeSelected}
            onBlur={onTypeBlur}
            hidePrependIcon={true}
          />
        </EuiFlexItem>
        <EuiFlexItem className={"border-left-0"} style={{ maxWidth: "18vw" }}>
          <Combobox
            label={"Asset Name"}
            required={true}
            autoSelect={true}
            errors={assetError}
            invalid={!!assetError?.length}
            selectedValue={selectedAsset}
            disabled={!filtersEnabled}
            placeholder={"Select Asset Name"}
            options={assets || []}
            onChange={onAssetSelected}
            onBlur={() => setAssetTouched(true)}
            hidePrependIcon={true}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default WorkOrderCreateAddAssetHeaderPresenter;
