import { differenceInDays } from "date-fns";
import { FC } from "react";

import PageSpinner from "../../components/elements/pageSpinner";
import { useCompanyQuery } from "../../graphql/graphql";
import OnboardingStepsContent from "../onboarding";
import DashboardContentPage from "./content";

interface DashboardPageProps {}

const DashboardPage: FC<DashboardPageProps> = () => {
  const { data: companyData } = useCompanyQuery();

  return companyData ? (
    <>
      {!companyData.company.config.onboarding?.hidden &&
      !companyData.company.config.onboarding?.completed &&
      30 - differenceInDays(new Date(), companyData.company.createdAt) >= 0 ? (
        <OnboardingStepsContent showSkip showComplete={false} />
      ) : (
        <DashboardContentPage />
      )}
    </>
  ) : (
    <PageSpinner />
  );
};

export default DashboardPage;
