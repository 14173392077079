import Input from "./input";

const NumberInput = {
  baseStyle: {
    field: {
      ...Input.baseStyle.field,
    },
    stepper: {
      border: 0,
    },
  },
  sizes: {
    md: {
      field: Input.sizes.md.field,
    },
    lg: {
      field: Input.sizes.lg.field,
    },
  },
  variants: {
    outline: {
      field: Input.variants.outline.field,
    },
  },
};

export default NumberInput;
