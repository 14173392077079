import { Icon, IconProps } from "@chakra-ui/react";

const MowerRiding = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M107.196 52.541V33.075h-2.867c-7.91 0-14.346 6.435-14.346 14.345v5.121h-8.112L74.22 64.93H56.962V46.027l5.918-5.918 7 6.999 4.054-4.055L55.881 25l-4.055 4.055 7 6.999-5.919 5.918H27.638L15.065 67.12V92.57h11.793C28.173 98.53 33.492 103 39.84 103c6.347 0 11.666-4.471 12.981-10.429H75.18C76.494 98.53 81.814 103 88.161 103c6.347 0 11.666-4.471 12.981-10.429h11.793v-40.03h-5.739Zm-11.478-5.12c0-3.744 2.4-6.937 5.743-8.12v13.24h-5.743v-5.12ZM39.839 97.266c-4.17 0-7.561-3.392-7.561-7.562s3.392-7.562 7.561-7.562c4.17 0 7.562 3.392 7.562 7.562s-3.392 7.562-7.562 7.562Zm48.322 0c-4.17 0-7.562-3.392-7.562-7.562s3.392-7.562 7.562-7.562 7.561 3.392 7.561 7.562-3.392 7.562-7.561 7.562Zm19.04-10.43h-6.059c-1.315-5.957-6.634-10.428-12.982-10.428-6.347 0-11.666 4.471-12.981 10.429H52.82c-1.315-5.958-6.634-10.43-12.982-10.43-6.347 0-11.666 4.472-12.981 10.43h-6.059V68.473l10.384-20.766h20.044v22.957h26.19l7.653-12.388H107.2v28.56Z"
      fill="currentColor"
    />
    <path
      d="M45.487 64.93H34.008v5.734h11.479v-5.735ZM45.487 53.45H34.008v5.735h11.479v-5.734Z"
      fill="currentColor"
    />
  </Icon>
);

export default MowerRiding;
