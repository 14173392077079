import { Icon, IconProps } from "@chakra-ui/react";

const LightEmergency2 = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M73.32 78H55.08V50.44h17.88v5.84h-11.2v5.28h9.84v5.72h-9.84v4.88h11.56V78Z"
      fill="currentColor"
    />
    <circle
      cx={64}
      cy={64}
      r={48}
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
  </Icon>
);

export default LightEmergency2;
