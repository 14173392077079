import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const LogoDarkBg = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    width="166px"
    height="21px"
    viewBox="0 0 166 21"
    aria-label="Critical Asset"
    {...props}
  >
    <path
      d="M0.604499 7.78434C0.604499 7.59788 0.628676 7.41142 0.701208 7.24827L7.61586 11.094C8.317 11.4902 9.16319 11.4902 9.86433 11.094L16.8032 7.22496C16.8757 7.38812 16.8999 7.57458 16.8999 7.76103V9.69555L9.5742 13.7977C9.06649 14.0774 8.4137 14.0774 7.90599 13.7977L0.604499 9.71886V7.78434ZM1.42652 6.3859L7.90599 2.77324C8.4137 2.49355 9.06649 2.49355 9.5742 2.77324L16.0537 6.3859C16.2229 6.47913 16.368 6.59566 16.4889 6.73551L9.5742 10.5812C9.06649 10.8609 8.4137 10.8609 7.90599 10.5812L0.991333 6.73551C1.11222 6.59566 1.25728 6.47913 1.42652 6.3859ZM13.4425 17.8298L9.5742 19.9974C9.06649 20.2771 8.4137 20.2771 7.90599 19.9974L1.42652 16.3848C0.918802 16.1051 0.580322 15.569 0.580322 14.9863V13.5646L7.59168 17.4802C8.29282 17.8765 9.16319 17.8765 9.84015 17.4802L13.4184 15.4991V17.8298H13.4425ZM13.5876 14.7533L9.5742 16.9908C9.06649 17.2705 8.4137 17.2705 7.90599 16.9908L0.604499 12.912V10.3715L7.61586 14.2871C8.317 14.6833 9.16319 14.6833 9.86433 14.2871L16.8757 10.3715V12.912L13.5876 14.7533Z"
      fill="white"
    />
    <path
      d="M32.083 14.8467L33.5094 17.7602C32.4456 18.7624 30.6081 19.2751 28.9399 19.2751C24.3221 19.2751 21.6143 16.2452 21.6143 12.516C21.6143 8.7868 24.3704 5.75684 28.9399 5.75684C30.6081 5.75684 32.3005 6.22298 33.5094 7.27182L32.1071 10.2086C31.2851 9.43941 30.1971 9.01988 29.0608 9.01988C26.5706 9.01988 25.0232 10.5815 25.0232 12.4927C25.0232 14.4039 26.5464 15.9888 29.0608 15.9888C30.173 16.0121 31.2851 15.5926 32.083 14.8467Z"
      fill="white"
    />
    <path
      d="M44.0991 5.7334V9.60243C43.2529 9.39267 42.3825 9.39267 41.5363 9.60243C40.1099 9.99866 38.5625 11.2573 38.5625 13.5181V18.8555H35.1536V6.15293H38.5625V8.83329C39.4571 6.87546 41.7297 5.89655 44.0991 5.7334Z"
      fill="white"
    />
    <path
      d="M45.1387 2.26082C45.1387 1.02553 46.1783 0 47.4839 0C48.7894 0 49.8291 1.00222 49.8291 2.26082C49.8291 3.51942 48.7894 4.52164 47.4839 4.52164C46.2025 4.52164 45.1628 3.51942 45.1387 2.28413V2.26082ZM45.7915 6.15316H49.2004V18.8557H45.7915V6.15316Z"
      fill="white"
    />
    <path
      d="M52.7786 9.02029H51.0862V6.15348H52.7786V1.63184H56.1876V6.15348H58.7262V9.02029H56.1876V14.3577C56.1876 16.3155 57.0579 16.3854 58.7262 15.9892V18.856C57.9283 19.159 57.0821 19.2989 56.2359 19.2756C53.8182 19.2756 52.7786 17.6207 52.7786 15.2434V9.02029Z"
      fill="white"
    />
    <path
      d="M60.3943 2.26082C60.3943 1.02553 61.4339 0 62.7395 0C64.0209 0 65.0847 1.00222 65.0847 2.26082C65.0847 3.49611 64.045 4.52164 62.7395 4.52164C61.4581 4.52164 60.4185 3.51942 60.3943 2.28413V2.26082ZM61.0471 6.15316H64.4561V18.8557H61.0471V6.15316Z"
      fill="white"
    />
    <path
      d="M76.3996 14.8467L77.8019 17.7602C76.7381 18.7624 74.9006 19.2751 73.2324 19.2751C68.6146 19.2751 65.8826 16.2452 65.8826 12.516C65.8826 8.7868 68.6388 5.75684 73.2324 5.75684C74.9006 5.75684 76.593 6.22298 77.8019 7.27182L76.3996 10.2086C75.5776 9.43941 74.4896 9.01988 73.3533 9.01988C70.8631 9.01988 69.3157 10.5815 69.3157 12.4927C69.3157 14.4039 70.8389 15.9888 73.3533 15.9888C74.4896 16.0121 75.5776 15.5926 76.3996 14.8467Z"
      fill="white"
    />
    <path
      d="M92.4531 6.15271V18.8553H89.0442V17.5034C88.0287 18.6455 86.5539 19.2981 85.0066 19.2748C81.3559 19.2748 78.4062 16.2448 78.4062 12.5156C78.4062 8.78645 81.3559 5.75648 85.0066 5.75648C86.5539 5.73318 88.0529 6.36248 89.0442 7.50454V6.15271H92.4531ZM89.0684 12.5156C89.0684 10.5811 87.4485 9.01953 85.4418 9.01953C83.4351 9.01953 81.8152 10.5811 81.8152 12.5156C81.8152 14.4502 83.4351 16.0118 85.4418 16.0118C87.4485 16.0118 89.0684 14.4269 89.0684 12.5156Z"
      fill="white"
    />
    <path d="M98.4009 0.81543V18.8554H94.9919V0.81543H98.4009Z" fill="white" />
    <path
      d="M114.092 6.15369V18.8562H110.683V17.5044C109.667 18.6465 108.193 19.2991 106.645 19.2758C102.995 19.2758 100.045 16.2458 100.045 12.5166C100.045 8.78743 102.995 5.75746 106.645 5.75746C108.193 5.73415 109.692 6.36345 110.683 7.50552V6.15369H114.092ZM110.707 12.5166C110.707 10.5821 109.087 9.0205 107.08 9.0205C105.074 9.0205 103.454 10.5821 103.454 12.5166C103.454 14.4511 105.074 16.0127 107.08 16.0127C109.087 16.0127 110.707 14.4278 110.707 12.5166Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M127.679 14.7534C127.679 17.3405 125.552 19.2517 121.877 19.2517C118.734 19.2517 116.437 17.9698 115.76 17.4104L116.872 14.3338C118.178 15.4293 119.822 16.0586 121.563 16.1285C123.134 16.1518 124.27 15.8255 124.27 15.0331C124.27 14.4737 123.69 14.2639 121.925 13.9842C117.017 13.2151 116.171 11.7001 116.171 9.62574C116.171 7.55138 118.492 5.7334 121.949 5.7334C123.738 5.75671 125.479 6.17624 127.075 6.96869L126.108 9.95204C125.431 9.48589 123.69 8.90321 121.925 8.8799C120.547 8.85659 119.556 9.11298 119.556 9.64905C119.556 10.4182 120.934 10.5347 122.336 10.7678C126.857 11.467 127.679 13.1452 127.679 14.7534Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M140.638 14.7534C140.638 17.3405 138.511 19.2517 134.836 19.2517C131.693 19.2517 129.396 17.9698 128.719 17.4104L129.831 14.3338C131.137 15.4293 132.781 16.0586 134.497 16.1285C136.069 16.1518 137.205 15.8255 137.205 15.0331C137.205 14.4737 136.625 14.2639 134.86 13.9842C129.952 13.2151 129.106 11.7001 129.106 9.62574C129.106 7.55138 131.427 5.7334 134.884 5.7334C136.673 5.75671 138.414 6.17624 140.01 6.96869L139.043 9.95204C138.366 9.48589 136.625 8.90321 134.86 8.8799C133.482 8.85659 132.491 9.11298 132.491 9.64905C132.491 10.4182 133.869 10.5347 135.271 10.7678C139.816 11.467 140.638 13.1452 140.638 14.7534Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M154.588 13.5415H144.99C145.473 15.2197 146.779 16.4083 148.907 16.4083C150.309 16.4083 151.687 15.9888 152.847 15.2197L153.839 18.0632C153.839 18.0632 152.025 19.2751 148.858 19.2751C143.733 19.2751 141.46 16.1986 141.46 12.516C141.46 8.7868 143.66 5.75684 148.278 5.75684C152.388 5.75684 154.661 8.27404 154.661 12.516C154.636 12.7957 154.612 13.1919 154.588 13.5415ZM151.034 11.0709C151.034 10.0221 150.115 8.60034 148.06 8.60034C146.368 8.60034 145.087 9.99879 145.087 11.0709H151.034Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M156.958 9.02029H155.266V6.15348H156.958V1.63184H160.367V6.15348H162.906V9.02029H160.343V14.3577C160.343 16.3155 161.213 16.3854 162.881 15.9892V18.856C162.084 19.159 161.237 19.2989 160.391 19.2756C157.973 19.2756 156.934 17.6207 156.934 15.2434L156.958 9.02029Z"
      fill="white"
      fillOpacity="0.5"
    />
  </Icon>
);

export default LogoDarkBg;
