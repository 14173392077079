import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import { endOfDay, format, startOfDay } from "date-fns";
import { subDays } from "date-fns/esm";
import React, { useMemo, useState } from "react";

import MobileAccordion from "../../../components/elements/mobileAccordion";
import PageSpinner from "../../../components/elements/pageSpinner";
import { ColumnProps } from "../../../components/elements/spreadsheet";
import { defaultShortDateWithTimeAndZoneFormat } from "../../../constants/date";
import {
  ReportMaintenanceFilterInput,
  useReportMaintenanceQuery,
} from "../../../graphql/graphql";
import { timezone } from "../../../utils/date/timezones";
import DownloadReport from "../download";
import ReportFilters from "../filter";
import ReportsTable from "../table";

interface MaintenanceReportPagePresenterProps {}

const MaintenanceReportPagePresenter: React.FC<
  MaintenanceReportPagePresenterProps
> = () => {
  const [filterBy, setFilterBy] = useState<ReportMaintenanceFilterInput>({
    startDate: subDays(startOfDay(new Date()), 7).getTime(),
    endDate: endOfDay(new Date()).getTime(),
  });
  const [isPrintReady, setPrintReady] = useState<boolean>(false);

  const { data, loading } = useReportMaintenanceQuery({
    variables: {
      filterBy,
      timezone,
    },
    fetchPolicy: "no-cache",
  });

  const columns: ColumnProps[] = useMemo(() => {
    const columnData: ColumnProps[] = [];
    Object.keys(
      data?.reportMaintenance.length ? data.reportMaintenance[0] : {}
    ).forEach((colHeading) => {
      if (colHeading !== "__typename") {
        columnData.push({
          field: colHeading,
          cellType: "INPUT",
          headerName:
            colHeading.charAt(0).toUpperCase() +
            colHeading
              .slice(1)
              .replace(/([A-Z])/g, " $1")
              .trim(),
          styleProps: {
            minWidth: ["Maintenance Event", "Asset", "Address"].includes(
              colHeading
            )
              ? 100
              : 70,
          },
        });
      }
    });
    return columnData;
  }, [data?.reportMaintenance]);

  const formattedData = useMemo(() => {
    if (data?.reportMaintenance.length) return data.reportMaintenance;
    else return [];
  }, [data?.reportMaintenance]);

  return (
    <Box px="4">
      <Flex
        direction="row"
        alignItems={{ base: "flex-start", lg: "center" }}
        justifyContent="space-between"
        marginBottom="4"
      >
        <MobileAccordion>
          <ReportFilters
            setFilterBy={setFilterBy}
            filterBy={filterBy}
            enabledFilters={[
              "locations",
              "categories",
              "status",
              "completed",
              "completedBy",
              "date",
            ]}
          />
        </MobileAccordion>
        <DownloadReport
          isDisabled={!formattedData?.length}
          reportType="Maintenance"
          filterBy={filterBy}
          setPrintReady={setPrintReady}
        />
      </Flex>
      {loading ? (
        <PageSpinner />
      ) : data?.reportMaintenance.length ? (
        <Box id="reports_table_wrap">
          {isPrintReady && (
            <Heading size="xl" mb="4">
              Service &amp; Maintenance Report –{" "}
              {format(new Date(), defaultShortDateWithTimeAndZoneFormat)}
            </Heading>
          )}
          <ReportsTable
            columns={columns}
            data={formattedData}
            isPrintReady={isPrintReady}
          />
        </Box>
      ) : (
        <Center height={100} color="gray.700" id="reports_table_wrap">
          No results found
        </Center>
      )}
    </Box>
  );
};

export default MaintenanceReportPagePresenter;
