import React from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../../components/elements/pageSpinner";
import useZendesk, { ZendeskSettings } from "../../../hooks/useZendesk";

interface NativeLiveChatPageProps {}

const NativeLiveChatPage: React.FC<NativeLiveChatPageProps> = () => {
  useZendesk(true);

  return (
    <>
      <Helmet>
        <script type="text/javascript">{ZendeskSettings}</script>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=96e43f2e-1aaa-4367-8c27-5f3600ddafa8"
        ></script>
      </Helmet>
      <PageSpinner />
    </>
  );
};

export default NativeLiveChatPage;
