import breakpoints from "./breakpoints";
import colors from "./colors";
import shadows from "./shadows";
import sizes, { baseSizes as space } from "./sizes";
import typography from "./typography";

const foundationTheme = {
  breakpoints,
  colors,
  sizes,
  space,
  shadows,
  ...typography,
};

export default foundationTheme;
