import * as ApolloReactHooks from "@apollo/client";

import { WOStagesDocument } from "../fragments/workOrderStages";
import { defaultOptions } from "../graphql";
import { WOGetStagesQuery } from "../types/queries/workOrderStatuses";
import { WODefaultQueryVariables } from "../types/workOrdersVariables";

export function useWOGetStagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WOGetStagesQuery,
    WODefaultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WOGetStagesQuery, WODefaultQueryVariables>(
    WOStagesDocument,
    options
  );
}
