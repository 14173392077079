import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import { IconTypes } from "../../../graphql/graphql";
import ColorPicker from "./colorPicker";
import IconPicker from "./iconPicker";
import AssetIcon, { getIconComponent } from "./index";
import TypePicker from "./typePicker";

interface AssetIconFieldProps {
  parentColor?: string;
  renderError?: boolean;
  styleProps?: object;
}

const name = "iconName";
const colorName = "iconColor";
const typeName = "iconType";

const AssetIconField: React.FC<AssetIconFieldProps> = ({
  parentColor,
  renderError = true,
  styleProps,
}) => {
  const { errors, values, setFieldValue, touched, submitCount } =
    useFormikContext<any>();

  const value = get(values, name);
  const error = get(errors, name);
  const fieldTouched = get(touched, name);
  const colorValue = get(values, colorName);
  const typeValue = get(values, typeName);
  const finalColorValue = colorValue || parentColor;

  const Asset = React.useMemo(
    () => (value ? getIconComponent(value) : undefined),
    [value]
  );

  const {
    isOpen: isIconPickerOpen,
    onClose: onIconPickerClose,
    onOpen: onIconPickerOpen,
  } = useDisclosure();
  const {
    isOpen: isColorPickerOpen,
    onClose: onColorPickerClose,
    onOpen: onColorPickerOpen,
  } = useDisclosure();

  return (
    <Box marginTop="3">
      <TypePicker color={finalColorValue} />
      <FormControl
        marginY="6"
        position="relative"
        isInvalid={!!error && (!!submitCount || (fieldTouched && !!value))}
        aria-invalid={!!error && (!!submitCount || (fieldTouched && !!value))}
        textAlign="center"
        {...styleProps}
      >
        <Flex alignItems="center">
          <Box flexShrink={0}>
            {value ? (
              <AssetIcon
                iconName={value}
                iconColor={finalColorValue}
                iconType={typeValue}
              />
            ) : (
              <Box
                boxSize="4rem"
                backgroundColor={finalColorValue}
                borderRadius={typeValue === IconTypes.Square ? "0" : "full"}
              />
            )}
          </Box>
          <Button
            variant="link"
            colorScheme="secondary"
            onClick={onColorPickerOpen}
            marginX="3"
          >
            {finalColorValue ? "Change" : "Select"} Color
          </Button>
          <Button
            variant="link"
            colorScheme="secondary"
            onClick={onIconPickerOpen}
          >
            {value ? "Change" : "Select"} Icon
          </Button>
        </Flex>

        {renderError && (
          <FormErrorMessage>
            <Text as="span" marginRight="1">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Text>
            <Text role="alert">{error}</Text>
          </FormErrorMessage>
        )}

        <ColorPicker
          parentColor={parentColor}
          isColorPickerOpen={isColorPickerOpen}
          onColorPickerClose={onColorPickerClose}
        />

        <IconPicker
          color={finalColorValue}
          setFieldValue={setFieldValue}
          defaultCustomIconText={Asset ? "" : value}
          value={value}
          isIconPickerOpen={isIconPickerOpen}
          onIconPickerClose={onIconPickerClose}
          iconType={typeValue}
        />
      </FormControl>
    </Box>
  );
};

export default AssetIconField;
