import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  Spacer,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import React, { memo, useEffect, useState } from "react";

import { FieldType } from "../../../graphql/graphql";
import FormGroup from "../../elements/formGroup";
import AddCircle from "../../icons/addCircle";
import CheckBoxInput from "../checkbox";
import FormSectionTitle from "../formSectionTitle";
import SelectCombobox from "../selectCombobox";

interface AssetTypeFieldsProps {
  name?: string;
  label?: string;
}

const AssetTypeFields: React.FC<AssetTypeFieldsProps> = memo(
  ({ name = "assetFieldsInput", label = "Custom Fields" }) => {
    const { errors, submitCount } = useFormikContext<any>();
    return (
      <>
        <FormSectionTitle>{label}</FormSectionTitle>
        <FieldArray
          validateOnChange={false}
          name={name}
          component={FieldArrayComponent as any}
        />
        <Box textAlign="center">
          {!!errors[name] && typeof errors[name] === "string" && !!submitCount && (
            <Box color="red.500" fontSize="xs" marginTop="4">
              <Text as="span" marginRight="1">
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Text>
              {errors[name]}
            </Box>
          )}
        </Box>
      </>
    );
  }
);

export default AssetTypeFields;

const FieldArrayComponent: React.FC<FieldArrayRenderProps & "name"> = memo(
  ({
    form: { values, getFieldProps, setFieldValue },
    push,
    move,
    remove,
    name,
  }) => {
    const cancelRef = React.useRef<HTMLButtonElement>(null);
    const [removeIndex, setRemoveIndex] = useState<number>();
    const value = values[name];
    const valueLength = value?.length || 0;

    return (
      <Box>
        {value.map((assetField: AssetField, index: number) => (
          <Field
            key={assetField.id || assetField.key}
            name={name}
            index={index}
            type={assetField.type}
            label={assetField.label}
            move={move}
            remove={remove}
            valueLength={valueLength}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            setRemoveIndex={setRemoveIndex}
          />
        ))}
        <Box textAlign="center">
          <Button
            variant="link"
            colorScheme="secondary"
            type="button"
            leftIcon={<AddCircle />}
            onClick={() => push(getDefaultAssetTypeField())}
          >
            {valueLength > 0 ? "Add another custom field" : "Add custom field"}
          </Button>
        </Box>
        <AlertDialog
          leastDestructiveRef={cancelRef}
          onClose={() => setRemoveIndex(undefined)}
          isOpen={!!removeIndex}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogCloseButton />
              <AlertDialogBody textAlign="center">
                Are you sure you want to delete this custom field?
                <br />
                <Text as="strong">{value[removeIndex as number]?.label}</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => setRemoveIndex(undefined)}
                  width="48%"
                >
                  No, Don't Delete!
                </Button>
                <Button
                  onClick={() => {
                    remove(removeIndex as number);
                    setRemoveIndex(undefined);
                  }}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                >
                  Yes, Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    );
  }
);

interface FieldProps {
  name: string;
  index: number;
  type: FieldType;
  label: string;
  valueLength: number;
  move: any;
  remove: any;
  getFieldProps: any;
  setFieldValue: any;
  setRemoveIndex: (index: number) => void;
}

const Field: React.FC<FieldProps> = memo(
  ({
    name,
    index,
    type,
    label,
    valueLength,
    move,
    remove,
    getFieldProps,
    setFieldValue,
    setRemoveIndex,
  }) => {
    const typeName = `${name}.${index}.type`;
    const labelName = `${name}.${index}.label`;
    const unitName = `${name}.${index}.unit`;
    const selectOptionsName = `${name}.${index}.selectOptions`;
    const requiredName = `${name}.${index}.required`;
    const validationRegExpName = `${name}.${index}.validationRegExp`;
    const validationRegExpMessageName = `${name}.${index}.validationRegExpMessage`;
    const orderName = `${name}.${index}.order`;

    useEffect(() => {
      setFieldValue(orderName, index);
    }, [setFieldValue, orderName, index]);

    return (
      <Box key={index} as="fieldset">
        <SelectCombobox options={FieldTypes} name={typeName} label="Type" />

        <FormGroup label="Label" name={labelName}>
          <Input autoComplete="off" {...getFieldProps(labelName)} />
        </FormGroup>

        {[FieldType.Select, FieldType.Autosuggest].includes(type) && (
          <FormGroup
            label={`${FieldType.Select ? "Select" : "Autosuggest"} Options`}
            name={selectOptionsName}
            hint="Please add one select option per line"
          >
            <Textarea
              autoComplete="off"
              {...getFieldProps(selectOptionsName)}
            />
          </FormGroup>
        )}

        <FormGroup label="Unit" name={unitName}>
          <Input autoComplete="off" {...getFieldProps(unitName)} />
        </FormGroup>

        {[
          FieldType.Input,
          FieldType.Text,
          FieldType.Select,
          FieldType.Autosuggest,
        ].includes(type) && (
          <Box display="none">
            <FormGroup label="Validation RegExp" name={validationRegExpName}>
              <Input
                autoComplete="off"
                {...getFieldProps(validationRegExpName)}
              />
            </FormGroup>

            <FormGroup
              label="Validation Error Message"
              name={validationRegExpMessageName}
            >
              <Input
                autoComplete="off"
                {...getFieldProps(validationRegExpMessageName)}
              />
            </FormGroup>
          </Box>
        )}

        <Flex alignItems="center">
          <Box width="100px">
            <CheckBoxInput label="Required" name={requiredName} />
          </Box>
          <Box marginTop="3">
            <Tooltip
              label="Users will be required to include these values when entering a new asset for this asset type."
              placement="top"
              hasArrow
            >
              <Box color="gray.600">
                <FontAwesomeIcon icon={faExclamationCircle} size="sm" />
              </Box>
            </Tooltip>
          </Box>
          <Spacer />
          <Tooltip label="Delete this custom field" hasArrow placement="left">
            <IconButton
              onClick={() => move(index, index - 1)}
              aria-label="Move custom field up"
              variant="icon"
              colorScheme="gray"
              type="button"
              marginTop="3"
              padding="2"
              icon={<FontAwesomeIcon icon={faChevronUp} />}
              disabled={index === 0}
            />
          </Tooltip>
          <Tooltip label="Delete this custom field" hasArrow placement="left">
            <IconButton
              onClick={() => move(index, index + 1)}
              aria-label="Move custom field down"
              variant="icon"
              colorScheme="gray"
              type="button"
              marginTop="3"
              padding="2"
              icon={<FontAwesomeIcon icon={faChevronDown} />}
              disabled={index + 1 === valueLength}
            />
          </Tooltip>
          <Tooltip label="Delete this custom field" hasArrow placement="left">
            <IconButton
              variant="icon"
              colorScheme="grayRed"
              type="button"
              onClick={() => (label ? setRemoveIndex(index) : remove(index))}
              marginTop="3"
              padding="2"
              aria-label="Remove custom field"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        </Flex>
        <Divider marginY="6" borderColor="gray.300" />
      </Box>
    );
  }
);

const FieldTypes = [
  { label: "Input", query: "input", value: FieldType.Input },
  { label: "Text Area", query: "text area", value: FieldType.Text },
  { label: "Checkbox", query: "checkbox", value: FieldType.Boolean },
  { label: "Select", query: "select", value: FieldType.Select },
  { label: "Autosuggest", query: "autosuggest", value: FieldType.Autosuggest },
];

type AssetField = {
  id?: string;
  label: string;
  type: FieldType;
  selectOptions: string[];
  unit: string;
  required: boolean;
  validationRegExp: string;
  validationRegExpMessage: string;
  order?: number;
  key?: string;
};

const getDefaultAssetTypeField = () => ({
  label: "",
  type: FieldType.Input,
  selectOptions: "",
  unit: "",
  required: false,
  validationRegExp: "",
  validationRegExpMessage: "",
  order: undefined,
  key: Date.now(),
});
