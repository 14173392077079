import React from "react";

import PageSpinner from "../../../../components/elements/pageSpinner";
import { useWOCompanyQuery } from "../../../../graphql/queries/workOrders";
import { WorkOrder } from "../../models/workOrder";
import WorkOrderCreateCompanyPresenter from "./presenter";

interface WorkOrderCreateCompanyPageProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateCompanyPage: React.FC<WorkOrderCreateCompanyPageProps> = ({
  workOrder,
  onWorkOrderChange,
}) => {
  const { data, loading } = useWOCompanyQuery({
    fetchPolicy: "network-only",
    variables: {},
  });

  return (
    <>
      {!loading && data?.workOrdersCompanies?.length ? (
        <WorkOrderCreateCompanyPresenter
          companies={data?.workOrdersCompanies}
          workOrder={workOrder}
          onWorkOrderChange={onWorkOrderChange}
        />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default WorkOrderCreateCompanyPage;
