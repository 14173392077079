import { Icon, IconProps } from "@chakra-ui/react";
import { useState } from "react";

const Logo = (props: IconProps & any) => {
  const [id] = useState(`clip0_logo${Math.random()}`);

  return (
    <Icon
      fill="currentColor"
      width="166px"
      height="21px"
      viewBox="0 0 166 21"
      aria-label="Critical Asset"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M156.958 8.8239H155.266V6.01965H156.958V1.59668H160.367V6.01965H162.905V8.8239H160.343V14.0448C160.343 15.9599 161.213 16.0283 162.881 15.6407V18.445C162.083 18.7414 161.237 18.8782 160.391 18.8554C157.973 18.8554 156.934 17.2367 156.934 14.9112L156.958 8.8239Z"
          fill="#B1B1B1"
        />
        <path
          d="M15.7344 7.2063C15.7123 7.2063 15.7123 7.2063 15.7344 7.2063C15.5574 6.91442 15.2918 6.66423 14.982 6.49743L9.02904 3.26583C8.38727 2.91139 7.61272 2.91139 6.97095 3.26583L1.04011 6.49743C0.75242 6.64338 0.53112 6.87272 0.35408 7.12291C0.30982 7.14376 0.28769 7.18545 0.26556 7.22715C0.24343 7.248 0.2213 7.26885 0.2213 7.31055C0.08852 7.58159 0 7.87347 0 8.18621V10.0001C0 10.0418 0 10.0835 0 10.1252V12.919V14.6286C0 15.3166 0.39834 15.9629 1.04011 16.3174L6.97095 19.549C7.28077 19.7158 7.63485 19.7992 8.01106 19.82C8.36514 19.82 8.74136 19.7158 9.05118 19.549L12.7248 17.5475C12.8133 17.5058 12.8575 17.4224 12.8575 17.339V14.7745L15.8672 13.1274C15.9557 13.0857 16 13.0023 16 12.919V8.18621C16 7.83178 15.9115 7.49819 15.7344 7.2063ZM0.55325 8.18621C0.55325 8.01942 0.59751 7.85263 0.64177 7.70668L6.97095 11.1468C7.28077 11.3136 7.63485 11.397 8.01106 11.4178C8.36514 11.4178 8.71923 11.3344 9.05118 11.1468L15.3582 7.68583C15.4246 7.83178 15.4467 7.99857 15.4467 8.18621V9.91668L8.76348 13.5653C8.29875 13.8155 7.72337 13.8155 7.23651 13.5653L0.55325 9.91668V8.18621ZM1.30567 6.93527L7.23651 3.70366C7.70124 3.45347 8.29875 3.45347 8.76348 3.70366L14.6943 6.93527C14.8492 7.01866 14.982 7.12291 15.0927 7.248L8.76348 10.6881C8.29875 10.9383 7.70124 10.9383 7.23651 10.6881L0.90733 7.248C1.01798 7.12291 1.15076 7.01866 1.30567 6.93527ZM12.3043 17.1722L8.76348 19.1111C8.29875 19.3613 7.70124 19.3613 7.23651 19.1111L1.30567 15.8795C0.840941 15.6293 0.53112 15.1498 0.53112 14.6286V13.3568L6.94882 16.8594C7.59059 17.2139 8.38727 17.2139 9.00692 16.8594L12.2822 15.0873V17.1722H12.3043ZM12.4371 14.4201L8.76348 16.4216C8.29875 16.6718 7.70124 16.6718 7.23651 16.4216L0.55325 12.773V10.5005L6.97095 14.0031C7.28077 14.1699 7.63485 14.2533 8.01106 14.2741C8.36514 14.2741 8.71923 14.1907 9.05118 14.0031L15.4689 10.5005V12.773L12.4371 14.4201Z"
          fill="black"
        />
        <path
          d="M32.083 14.5229L33.5095 17.3727C32.4457 18.3531 30.6082 18.8546 28.94 18.8546C24.3222 18.8546 21.6143 15.8908 21.6143 12.243C21.6143 8.59519 24.3705 5.63135 28.94 5.63135C30.6082 5.63135 32.3006 6.08732 33.5095 7.11327L32.1072 9.98592C31.2852 9.23356 30.1972 8.82318 29.0609 8.82318C26.5706 8.82318 25.0233 10.3507 25.0233 12.2202C25.0233 14.0897 26.5465 15.64 29.0609 15.64C30.173 15.6628 31.2852 15.2524 32.083 14.5229Z"
          fill="black"
        />
        <path
          d="M44.099 5.6084V9.393C43.2528 9.18781 42.3824 9.18781 41.5362 9.393C40.1098 9.78058 38.5625 11.0117 38.5625 13.2232V18.4441H35.1535V6.01878H38.5625V8.64064C39.457 6.72554 41.7297 5.76799 44.099 5.6084Z"
          fill="black"
        />
        <path
          d="M45.1387 2.21148C45.1387 1.00315 46.1783 0 47.4839 0C48.7894 0 49.8291 0.980348 49.8291 2.21148C49.8291 3.44262 48.7894 4.42297 47.4839 4.42297C46.2025 4.42297 45.1628 3.44262 45.1387 2.23428C45.1387 2.23428 45.1387 2.21148 45.1387 2.21148ZM45.7915 6.01888H49.2004V18.4442H45.7915V6.01888Z"
          fill="black"
        />
        <path
          d="M52.7786 8.82317H51.0862V6.01891H52.7786V1.59595H56.1876V6.01891H58.7262V8.82317H56.1876V14.0441C56.1876 15.9592 57.0579 16.0276 58.7262 15.64V18.4443C57.9283 18.7406 57.0821 18.8774 56.2359 18.8546C53.8182 18.8546 52.7786 17.2359 52.7786 14.9104V8.82317Z"
          fill="black"
        />
        <path
          d="M60.3944 2.21148C60.3944 1.00315 61.434 0 62.7396 0C64.021 0 65.0848 0.980348 65.0848 2.21148C65.0848 3.41982 64.0451 4.42297 62.7396 4.42297C61.4582 4.42297 60.4186 3.44262 60.3944 2.23428C60.3944 2.23428 60.3944 2.21148 60.3944 2.21148ZM61.0472 6.01888H64.4561V18.4442H61.0472V6.01888V6.01888Z"
          fill="black"
        />
        <path
          d="M76.3996 14.5229L77.8019 17.3727C76.7381 18.3531 74.9007 18.8546 73.2324 18.8546C68.6146 18.8546 65.8826 15.8908 65.8826 12.243C65.8826 8.59519 68.6388 5.63135 73.2324 5.63135C74.9007 5.63135 76.5931 6.08732 77.8019 7.11327L76.3996 9.98592C75.5776 9.23356 74.4896 8.82318 73.3533 8.82318C70.8631 8.82318 69.3158 10.3507 69.3158 12.2202C69.3158 14.0897 70.8389 15.64 73.3533 15.64C74.4896 15.6628 75.5776 15.2524 76.3996 14.5229Z"
          fill="black"
        />
        <path
          d="M92.4532 6.01881V18.4442H89.0443V17.1218C88.0288 18.239 86.554 18.8773 85.0067 18.8545C81.356 18.8545 78.4063 15.8907 78.4063 12.2429C78.4063 8.59507 81.356 5.63123 85.0067 5.63123C86.554 5.60843 88.053 6.224 89.0443 7.34114V6.01881H92.4532V6.01881ZM89.0684 12.2429C89.0684 10.3506 87.4486 8.82306 85.4419 8.82306C83.4352 8.82306 81.8153 10.3506 81.8153 12.2429C81.8153 14.1352 83.4352 15.6627 85.4419 15.6627C87.4486 15.6627 89.0684 14.1124 89.0684 12.2429Z"
          fill="black"
        />
        <path
          d="M98.4008 0.797852V18.4441H94.9918V0.797852H98.4008Z"
          fill="black"
        />
        <path
          d="M114.092 6.01881V18.4442H110.683V17.1218C109.667 18.239 108.193 18.8773 106.645 18.8545C102.995 18.8545 100.045 15.8907 100.045 12.2429C100.045 8.59507 102.995 5.63123 106.645 5.63123C108.193 5.60843 109.692 6.224 110.683 7.34114V6.01881H114.092V6.01881ZM110.707 12.2429C110.707 10.3506 109.087 8.82306 107.081 8.82306C105.074 8.82306 103.454 10.3506 103.454 12.2429C103.454 14.1352 105.074 15.6627 107.081 15.6627C109.087 15.6627 110.707 14.1124 110.707 12.2429Z"
          fill="#B1B1B1"
        />
        <path
          d="M127.679 14.4318C127.679 16.9624 125.552 18.8319 121.877 18.8319C118.734 18.8319 116.437 17.578 115.76 17.0308L116.872 14.0214C118.178 15.0929 119.822 15.7085 121.562 15.7769C123.134 15.7997 124.27 15.4805 124.27 14.7054C124.27 14.1582 123.69 13.953 121.925 13.6794C117.017 12.9271 116.171 11.4451 116.171 9.41604C116.171 7.38695 118.492 5.60864 121.949 5.60864C123.738 5.63144 125.479 6.04182 127.075 6.81698L126.108 9.73522C125.431 9.27925 123.69 8.70928 121.925 8.68648C120.547 8.66368 119.556 8.91447 119.556 9.43884C119.556 10.1912 120.934 10.3052 122.336 10.5332C126.857 11.2171 127.679 12.8587 127.679 14.4318Z"
          fill="#B1B1B1"
        />
        <path
          d="M140.638 14.4318C140.638 16.9624 138.511 18.8319 134.836 18.8319C131.693 18.8319 129.396 17.578 128.719 17.0308L129.831 14.0214C131.137 15.0929 132.781 15.7085 134.497 15.7769C136.069 15.7997 137.205 15.4805 137.205 14.7054C137.205 14.1582 136.625 13.953 134.86 13.6794C129.952 12.9271 129.106 11.4451 129.106 9.41604C129.106 7.38695 131.427 5.60864 134.884 5.60864C136.673 5.63144 138.414 6.04182 140.01 6.81698L139.043 9.73522C138.366 9.27925 136.625 8.70928 134.86 8.68648C133.482 8.66368 132.491 8.91447 132.491 9.43884C132.491 10.1912 133.869 10.3052 135.271 10.5332C139.816 11.2171 140.638 12.8587 140.638 14.4318Z"
          fill="#B1B1B1"
        />
        <path
          d="M154.588 13.2464H144.99C145.473 14.8879 146.779 16.0506 148.907 16.0506C150.309 16.0506 151.687 15.6403 152.847 14.8879L153.839 17.6694C153.839 17.6694 152.025 18.8549 148.858 18.8549C143.733 18.8549 141.46 15.8455 141.46 12.2432C141.46 8.59544 143.66 5.63159 148.278 5.63159C152.388 5.63159 154.661 8.09386 154.661 12.2432C154.637 12.5168 154.612 12.9044 154.588 13.2464ZM151.034 10.8297C151.034 9.80377 150.115 8.41305 148.06 8.41305C146.368 8.41305 145.087 9.78097 145.087 10.8297H151.034Z"
          fill="#B1B1B1"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="163.147" height="20.3365" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default Logo;
