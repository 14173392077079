import { Icon, IconProps } from "@chakra-ui/react";

const TransmissionTower = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.678 78.155 83.112 62.348l-2.919-23.133h14.1v2.754a2.727 2.727 0 0 0 2.754 2.754 2.727 2.727 0 0 0 2.754-2.754v-5.692c0-.66-.33-1.542-1.047-2.093 0-.055 0-.11-.055-.11L76.668 17.551c-.055 0-.11 0-.11-.055-.44-.276-.992-.496-1.542-.496H52.984c-.55 0-1.101.22-1.542.495 0 .056-.055.056-.11.056L29.301 34.074c-.055 0-.055.055-.055.11a2.763 2.763 0 0 0-1.047 2.093v5.692a2.727 2.727 0 0 0 2.754 2.754 2.727 2.727 0 0 0 2.754-2.754v-2.754h14.1l-2.919 23.133-26.566 15.807c-.661.44-1.267 1.322-1.322 2.313v5.563a2.727 2.727 0 0 0 2.754 2.754 2.727 2.727 0 0 0 2.754-2.754v-2.754h5.691v2.754a2.727 2.727 0 0 0 2.754 2.754 2.727 2.727 0 0 0 2.754-2.754v-2.79h8.592c-.131 1.006-2.002 15.688-2.71 22.251H19.755A2.727 2.727 0 0 0 17 108.246 2.727 2.727 0 0 0 19.754 111h88.492a2.728 2.728 0 0 0 2.754-2.754 2.728 2.728 0 0 0-2.754-2.754H88.434c-.691-6.568-2.602-21.25-2.733-22.215h8.592v2.754a2.727 2.727 0 0 0 2.754 2.754 2.727 2.727 0 0 0 2.754-2.754v-2.754h5.691v2.754a2.727 2.727 0 0 0 2.754 2.754A2.727 2.727 0 0 0 111 86.031v-5.563c-.055-1.046-.606-1.873-1.322-2.313Zm-79.771-.386H42.96l1.102-8.482-14.155 8.482Zm49.625-44.246h9.253l-10.244-7.655.991 7.655Zm-11.456 15.11 8.757 8.152-1.817-14.596-6.94 6.444Zm2.038-9.418H57.995l5.784 5.342.275.276 6.058-5.618Zm3.414 22.031L64 52.38l-.22.22-9.308 8.647h19.056Zm-.936-38.738H55.408L54.03 33.523h19.938l-1.377-11.015ZM59.98 48.633l-6.996-6.554-1.817 14.76 8.813-8.206Zm19.497 29.136L78.1 66.754H49.9l-1.377 11.015h30.954ZM63.78 90.823l11.897-7.546H51.552l12.173 7.601.055-.055ZM49.46 25.868l-10.245 7.655h9.253l.991-7.655Zm9.142 68.315-11.236-7.05-1.927 15.66 13.163-8.61Zm5.178 3.194-12.393 8.115h25.06L63.78 97.377Zm16.799-10.685-11.677 7.436 13.66 8.72-1.983-16.156Zm3.36-17.405 1.1 8.482h13.054l-14.155-8.482Z"
      fill="currentColor"
    />
  </Icon>
);

export default TransmissionTower;
