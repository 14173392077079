import { Box, Heading, Text } from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Link from "../../components/elements/link";
import PageSpinner from "../../components/elements/pageSpinner";
import { useUserEmailVerifyMutation } from "../../graphql/graphql";
import { getRoutePath } from "../../router";

interface EmailVerifyPagePresenterProps {
  loggedIn: boolean;
}

const EmailVerifyPagePresenter: React.FC<EmailVerifyPagePresenterProps> = ({
  loggedIn,
}) => {
  const [userEmailVerify] = useUserEmailVerifyMutation();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const { email: emailEncoded, token: uuid } = useParams();

  useEffect(() => {
    async function verifyEmail() {
      if (!emailEncoded || !uuid) {
        setError(true);
        return;
      }

      try {
        const data = { emailEncoded, uuid };
        const { errors } = await userEmailVerify({ variables: { data } });
        if (!errors) {
          setLoading(false);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    verifyEmail();
  }, [emailEncoded, uuid, userEmailVerify]);

  return loading ? (
    <PageSpinner />
  ) : (
    <Box textAlign="center" marginY="8" maxW="lg" mx="auto">
      {error ? (
        <Text color="red.500" marginBottom="5" fontSize="lg">
          This link is no longer valid.
        </Text>
      ) : (
        <>
          <Heading as="h1" marginBottom="4">
            Thank you!
          </Heading>
          <Text marginBottom="5" color="gray.800" fontSize="lg">
            Your email has been successfully verified.
          </Text>
        </>
      )}
      <Link
        to={loggedIn ? getRoutePath("dashboard") : getRoutePath("signIn")}
        width="full"
      >
        Continue
      </Link>
      {/* {!loggedIn && (
        <Box marginTop="8">
          Don't have an account yet?{" "}
          <Link
            to={getRoutePath("signUp")}
            variant="link"
            colorScheme="secondary"
          >
            Sign up
          </Link>
        </Box>
      )} */}
    </Box>
  );
};

export default EmailVerifyPagePresenter;

gql`
  mutation userEmailVerify($data: UserEmailVerifyInput!) {
    userEmailVerify(data: $data)
  }
`;
