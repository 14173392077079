import "@reach/combobox/styles.css";

import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  Heading,
  Input,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import gql from "graphql-tag";
import { groupBy } from "lodash";
import pluralize from "pluralize";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  SearchFragmentFragment,
  SearchType,
  useSearchLazyQuery,
} from "../../../../graphql/graphql";
import { layoutOptionsVar } from "../../../../graphql/reactiveVariables";
import useDebounce from "../../../../hooks/useDebounce";
import { getRoutePath } from "../../../../router";
import { sortCollator } from "../../../../utils/sort";
import SearchIcon from "../../../icons/search";

interface SearchProps {}

type SearchResultGroup = Array<[string, Array<SearchFragmentFragment>]>;

const ALL_TYPES = [
  SearchType.Asset,
  SearchType.Maintenance,
  SearchType.Location,
  SearchType.FloorPlan,
  SearchType.File,
  SearchType.AssetCategory,
];

const Search: React.FC<SearchProps> = () => {
  const hideSearch = useReactiveVar(layoutOptionsVar).hideSearch || false;
  const navigate = useNavigate();
  const location = useLocation();
  const [results, setResults] = React.useState<SearchResultGroup>([]);
  const [types, setTypes] = React.useState<Array<SearchType>>(ALL_TYPES);
  const [query, setQuery] = React.useState("");
  const debouncedQuery = useDebounce(query, 500);
  const [isDesktop] = useMediaQuery("(min-width: 1023px)");
  const [mobileSearchExpanded, setMobileSearchExpanded] =
    useState<boolean>(false);
  const [getSearchResults, { loading, data }] = useSearchLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (location.pathname.startsWith(getRoutePath("assetCategories"))) {
      setTypes([SearchType.AssetCategory]);
    } else if (location.pathname.startsWith(getRoutePath("assets"))) {
      setTypes([SearchType.Asset]);
    } else if (location.pathname.startsWith(getRoutePath("maintenances"))) {
      setTypes([SearchType.Maintenance]);
    } else if (location.pathname.startsWith(getRoutePath("locations"))) {
      setTypes([SearchType.Location, SearchType.FloorPlan]);
    } else if (location.pathname.startsWith(getRoutePath("folders"))) {
      setTypes([SearchType.File]);
    } else {
      setTypes(ALL_TYPES);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!debouncedQuery || debouncedQuery.length < 2) {
      return;
    }

    const clonedTypes = [...types];
    if (clonedTypes.includes(SearchType.File))
      clonedTypes.push(SearchType.Folder);
    if (clonedTypes.includes(SearchType.AssetCategory))
      clonedTypes.push(SearchType.AssetType);

    getSearchResults({
      variables: {
        query: debouncedQuery,
        type: clonedTypes.length ? clonedTypes : undefined,
      },
    });
  }, [getSearchResults, debouncedQuery, types]);

  useEffect(() => {
    if (data?.search) {
      const searchResultGroup = groupBy(data.search, "__typename");
      const humanizeSearchGroup: SearchResultGroup = [];
      Object.keys(searchResultGroup).forEach((sgk) => {
        humanizeSearchGroup.push([
          getTypeName(sgk),
          searchResultGroup[sgk].sort((a, b) =>
            sortCollator.compare(a.name, b.name)
          ),
        ]);
      });
      setResults(
        humanizeSearchGroup.sort((a, b) => sortCollator.compare(a[0], b[0]))
      );
    } else {
      setResults([]);
    }
  }, [data?.search]);

  const resetSearch = useCallback(() => {
    setQuery("");
    setResults([]);
  }, [setQuery, setResults]);

  const closeAll = useCallback(() => {
    if (mobileSearchExpanded) resetSearch();
    setMobileSearchExpanded(false);
  }, [setMobileSearchExpanded, resetSearch, mobileSearchExpanded]);

  if (hideSearch) return <Box gridArea="search" h="12" />;

  return (
    <Flex gridArea="search">
      <Box
        display={{ lg: "none" }}
        onClick={() => setMobileSearchExpanded(true)}
        tabIndex={0}
        role="button"
      >
        <SearchIcon color="gray.300" />
      </Box>
      {(mobileSearchExpanded || isDesktop) && (
        <Box
          width="100%"
          display="block"
          position={mobileSearchExpanded ? "absolute" : "relative"}
          left={mobileSearchExpanded ? "0px" : undefined}
          top={mobileSearchExpanded ? "0px" : undefined}
          zIndex={mobileSearchExpanded ? "2" : undefined}
          backgroundColor="white"
        >
          <Combobox
            aria-label="Suggestions"
            openOnFocus
            onSelect={(value) => {
              closeAll();
              if (!data) return;
              const result = data.search.find((r) => r.id === value);
              if (result) navigate(getSearchRoute(result));
            }}
          >
            <FormControl>
              <InputLeftElement
                position="absolute"
                paddingLeft="4"
                top="50%"
                transform="translateY(-50%)"
                zIndex="2"
                children={<SearchIcon color="black" />}
              />
              <ComboboxInput
                autocomplete={false}
                as={Input as any}
                size="lg"
                paddingLeft="12"
                height={mobileSearchExpanded ? "14" : "12"}
                fontWeight="normal"
                paddingY="0"
                paddingRight={mobileSearchExpanded ? "12" : undefined}
                autoFocus={mobileSearchExpanded}
                placeholder="Search"
                onChange={(e: any) => setQuery(e.currentTarget.value)}
                value={query}
                autoComplete="off"
              />
              {(!!query || mobileSearchExpanded) && (
                <InputRightElement
                  position="absolute"
                  paddingRight="4"
                  paddingY="2"
                  right={mobileSearchExpanded ? "0" : "-10px"}
                  top={mobileSearchExpanded ? "0" : "-4px"}
                  zIndex="10"
                  onClick={() => {
                    closeAll();
                    resetSearch();
                  }}
                  children={<CloseButton color="black" />}
                  tabIndex={0}
                  role="button"
                />
              )}
            </FormControl>

            <ComboboxPopover>
              <ComboboxList>
                <Box padding="2">
                  <CheckboxGroup value={types} onChange={setTypes as any}>
                    <Checkbox
                      size="sm"
                      marginRight="4"
                      marginY="1"
                      value={SearchType.Asset}
                      width="28%"
                    >
                      Assets
                    </Checkbox>
                    <Checkbox
                      size="sm"
                      marginRight="4"
                      marginY="1"
                      value={SearchType.FloorPlan}
                      width="28%"
                    >
                      Plans
                    </Checkbox>
                    <Checkbox
                      size="sm"
                      marginRight="4"
                      marginY="1"
                      value={SearchType.Location}
                      width="28%"
                    >
                      Locations
                    </Checkbox>
                    <Checkbox
                      size="sm"
                      marginRight="4"
                      marginY="1"
                      value={SearchType.Maintenance}
                      width="28%"
                    >
                      Service Schedule
                    </Checkbox>
                    <Checkbox
                      size="sm"
                      marginRight="4"
                      marginY="1"
                      value={SearchType.File}
                      width="28%"
                    >
                      Files
                    </Checkbox>
                    <Checkbox
                      size="sm"
                      marginRight="4"
                      marginY="1"
                      value={SearchType.AssetCategory}
                      width="28%"
                    >
                      Categories &amp; Types
                    </Checkbox>
                  </CheckboxGroup>
                </Box>
                {debouncedQuery && debouncedQuery.length > 1 && (
                  <>
                    {loading ? (
                      <Stack padding="2">
                        <Skeleton height="30px" />
                        <Skeleton height="30px" />
                        <Skeleton height="30px" />
                      </Stack>
                    ) : results.length > 0 ? (
                      <Box>
                        <Divider marginY="2" />
                        <Heading
                          as="h3"
                          size="sm"
                          textTransform="uppercase"
                          color="gray.700"
                          paddingX="2"
                          mb="2"
                        >
                          Search Results ({data?.search.length})
                        </Heading>
                        {results.map((resultGroup, index) => (
                          <Fragment key={resultGroup[0] + index}>
                            <Heading
                              size="xs"
                              p="2"
                              textTransform="uppercase"
                              bg="gray.200"
                            >
                              {resultGroup[0] === "Service Schedule"
                                ? resultGroup[0]
                                : pluralize(resultGroup[0])}
                            </Heading>
                            {resultGroup[1].map((result) => (
                              <ComboboxOption key={result.id} value={result.id}>
                                <Flex alignItems="center">
                                  <Box flexGrow={1}>
                                    <Box>
                                      {(result as any).misc?.resolvedName ||
                                        result.name}
                                    </Box>
                                    {/* <Box fontSize="xs" color="gray.700">
                                      {getTypeName(result.__typename || "")}
                                    </Box> */}
                                  </Box>
                                  <Box
                                    flexShrink={0}
                                    paddingX="2"
                                    color="gray.700"
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronRight}
                                      size="xs"
                                    />
                                  </Box>
                                </Flex>
                              </ComboboxOption>
                            ))}
                          </Fragment>
                        ))}
                      </Box>
                    ) : query ? (
                      <Box>
                        <Divider marginY="2" />
                        <Text
                          textAlign="center"
                          marginY="12"
                          fontSize="sm"
                          color="gray.700"
                        >
                          No results found
                        </Text>
                      </Box>
                    ) : null}
                  </>
                )}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
        </Box>
      )}
    </Flex>
  );
};

export default Search;

const getTypeName = (type: string) => {
  if (type === "Maintenance") return "Service Schedule";
  if (type === "FloorPlan") return "Plan";
  if (type === "AssetCategory") return "Asset Category";
  if (type === "AssetType") return "Asset Type";
  return type;
};

const getSearchRoute = (result: SearchFragmentFragment): string => {
  if (result.__typename === "Location")
    return getRoutePath("locationsShow", { locationId: result.id });
  if (result.__typename === "FloorPlan")
    return `?CaAprType=planEditor&planId=${result.id}`;
  if (result.__typename === "Asset")
    return getRoutePath("assetsShow", { assetId: result.id });
  if (result.__typename === "Maintenance")
    return `${getRoutePath(
      "maintenances"
    )}?CaAprType=serviceScheduleShow&maintenanceId=${result.id}`;
  if (result.__typename === "Folder")
    return getRoutePath("foldersShow", { folderId: result.id });
  if (result.__typename === "File")
    return getRoutePath("filesShow", {
      folderId: result.folderId,
      fileId: result.id,
    });
  if (result.__typename === "AssetCategory")
    return getRoutePath("assetCategories");
  if (result.__typename === "AssetType") return getRoutePath("assetCategories");

  return "";
};

gql`
  query Search($query: String!, $type: [SearchType!]) {
    search(query: $query, type: $type) {
      ... on Location {
        id
        name
      }
      ... on FloorPlan {
        id
        name
        locationId
      }
      ... on Asset {
        id
        name
      }
      ... on Maintenance {
        id
        name
        misc {
          resolvedName
        }
      }
      ... on Folder {
        id
        name
      }
      ... on File {
        id
        name
        folderId
      }
      ... on AssetCategory {
        id
        name
      }
      ... on AssetType {
        id
        name
        assetCategoryId
      }
    }
  }

  fragment SearchFragment on SearchResult {
    ... on Location {
      id
      name
    }
    ... on FloorPlan {
      id
      name
      locationId
    }
    ... on Asset {
      id
      name
    }
    ... on Maintenance {
      id
      name
      misc {
        resolvedName
      }
    }
    ... on Folder {
      id
      name
    }
    ... on File {
      id
      name
      folderId
    }
    ... on AssetCategory {
      id
      name
    }
    ... on AssetType {
      id
      name
      assetCategoryId
    }
  }
`;
