import React from "react";

import { WorkOrder } from "../../models/workOrder";
import WorkOrderEditRightPanelPresenter from "./presenter";

export type WorkOrderEditRightPanelProps = {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
};

const WorkOrderEditRightPanel: React.FC<WorkOrderEditRightPanelProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}: WorkOrderEditRightPanelProps) => {
  return (
    <>
      <WorkOrderEditRightPanelPresenter
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
    </>
  );
};
export default WorkOrderEditRightPanel;
