import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const UserIcon = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    color="#7A7A7A"
    viewBox="0 0 16 16"
    boxSize="16px"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 6.2319 13.9642 4.53621 12.714 3.28596C11.4637 2.03572 9.76803 1.33334 7.99992 1.33334ZM7.99992 3.33334C9.10449 3.33334 9.99992 4.22877 9.99992 5.33334C9.99992 6.43791 9.10449 7.33334 7.99992 7.33334C6.89535 7.33334 5.99992 6.43791 5.99992 5.33334C5.99992 4.22877 6.89535 3.33334 7.99992 3.33334ZM7.99998 12.6579C9.4767 12.6579 10.8663 11.9589 11.7467 10.7733C11.8831 10.5739 11.9009 10.3163 11.7933 10.1L11.64 9.78668C11.3045 9.10206 10.609 8.66769 9.84665 8.66668H6.15332C5.38007 8.66751 4.67657 9.11401 4.34665 9.81334L4.20665 10.1067C4.10166 10.3212 4.11946 10.5755 4.25332 10.7733C5.13369 11.9589 6.52327 12.6579 7.99998 12.6579Z"
    />
  </Icon>
);

export default UserIcon;
