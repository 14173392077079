import { useApolloClient, useReactiveVar } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import { TEAM_USER_DELETED, TEAM_USER_LEFT } from "../../../constants/lang/en";
import {
  CompanyUserRole,
  CompanyUserWithUserFragmentFragment,
  useCompanyQuery,
  useCompanyUserDeleteMutation,
  useUserQuery,
} from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../router";
import { setGenericMessage } from "../../../utils/serverErrors";

interface CompanyTeamDeleteProps {
  companyUser: CompanyUserWithUserFragmentFragment;
}

const CompanyTeamDelete: React.FC<CompanyTeamDeleteProps> = ({
  companyUser,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const client = useApolloClient();
  const name = companyUser.user.fullName;
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data } = useUserQuery();
  const { data: currentCompanyData } = useCompanyQuery();
  const [companyUserDeleteMutation, { loading }] =
    useCompanyUserDeleteMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleDelete = async () => {
    try {
      await companyUserDeleteMutation({
        variables: { id: companyUser.id },
      });
      toast({
        description: isMe ? TEAM_USER_LEFT : TEAM_USER_DELETED,
        status: "success",
        position: "top",
        isClosable: true,
      });
      client.cache.evict({
        id: `CompanyUser:${companyUser.id}`,
      });
      onClose();
      navigate(getRoutePath("companyTeams"));
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  const isMe = data && companyUser.user.id === data.user.id;

  if (
    ((currentCompanyRole.isUser || currentCompanyRole.isViewOnly) && !isMe) ||
    (currentCompanyRole.isManager &&
      companyUser.role === CompanyUserRole.Admin) ||
    companyUser.role === CompanyUserRole.Owner
  ) {
    return null;
  }

  return (
    <>
      <Tooltip
        label={isMe ? "Leave this company" : "Delete this user"}
        placement="left"
        hasArrow
      >
        <IconButton
          type="button"
          variant="icon"
          aria-label={isMe ? "Leave this company" : "Delete this user"}
          isLoading={loading}
          onClick={onOpen}
          colorScheme="grayRed"
          marginLeft="4"
        >
          {<FontAwesomeIcon icon={isMe ? faSignOutAlt : faTrashAlt} />}
        </IconButton>
      </Tooltip>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              Are you sure you want to{" "}
              {isMe ? (
                <>
                  leave this company?{" "}
                  <Text display="block" as="strong">
                    {currentCompanyData?.company.name}
                  </Text>
                </>
              ) : (
                <>
                  remove the following{" "}
                  {companyUser.role.toLowerCase() === "view_only"
                    ? "view only user"
                    : companyUser.role.toLowerCase()}
                  ?{" "}
                  <Text display="block" as="strong">
                    {name || companyUser.user.email}
                  </Text>
                </>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} width="48%">
                {`${isMe ? "No, Don't Leave!" : "No, Don't Delete!"}`}
              </Button>
              <Button
                onClick={handleDelete}
                colorScheme="red"
                ml="4%"
                width="48%"
                isLoading={loading}
              >
                {`${isMe ? "Yes, Leave" : "Yes, Delete"}`}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CompanyTeamDelete;

gql`
  mutation CompanyUserDelete($id: UUID!) {
    companyUserDelete(id: $id)
  }
`;
