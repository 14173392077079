import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw/dist/leaflet.draw.js";

import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FeatureCollection } from "geojson";
import L, { LatLng, Layer } from "leaflet";
import React, { useCallback, useMemo } from "react";
import { GeoJSON, useMapEvents } from "react-leaflet";

import defaultTheme from "../../../../chakraTheme";
import { AssetForMapFragmentFragment } from "../../../../graphql/graphql";
import { planEditorOptionsVar } from "../../../../graphql/reactiveVariables";
import { getAssetPartOptions } from "../../../../utils/assetPart";
import {
  assetPartIdSchema,
  descriptionSchema,
  nameSchema,
  yupObject,
} from "../../../../utils/validation";
import { getCustomIcon } from "../../customLeafletIcons";
import FormGroup from "../../formGroup";
import SelectCombobox from "../../selectCombobox";

const defaultAnnotation: FeatureCollection = {
  type: "FeatureCollection",
  features: [],
};

interface DrawControlProps {
  annotation?: FeatureCollection;
  quickDraw: boolean;
  handleAnnotationChange: (annotation: FeatureCollection) => void;
  color?: string;
  asset: AssetForMapFragmentFragment;
  canDraw: boolean;
}

const DrawControl: React.FC<DrawControlProps> = ({
  annotation,
  quickDraw,
  handleAnnotationChange,
  color = defaultTheme.colors.secondary[500],
  asset,
  canDraw,
}) => {
  const { assetPartIds } = useReactiveVar(planEditorOptionsVar);
  const assetPartOptions = useMemo(() => getAssetPartOptions(asset), [asset]);
  const assetParts = asset.assetParts;
  const shapeOptions = React.useMemo(() => ({ color }), [color]);
  const defaultIcon = React.useMemo(
    () => getCustomIcon(undefined, color),
    [color]
  );
  const featureRef = React.useRef<any>(null);
  const [activeLayer, setActiveLayer] = React.useState<Layer>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [defaultName, setDefaultName] = React.useState<string>("");

  const closeModal = React.useCallback(() => {
    setActiveLayer(undefined);
    onClose();
  }, [setActiveLayer, onClose]);

  const map = useMapEvents({
    [L.Draw.Event.CREATED]: (e: any) => {
      featureRef.current.addLayer(e.layer);
      setActiveLayer(e.layer);
      const defaultName =
        assetParts.length && assetPartIds.length === 1
          ? getDefaultName(assetPartIds[0] as string)
          : asset.name;
      if (quickDraw) {
        handleSubmit({ name: defaultName }, e.layer);
      } else {
        setDefaultName(defaultName);
        onOpen();
      }
    },
    [L.Draw.Event.EDITED]: () => {
      checkMap();
    },
    [L.Draw.Event.DELETED]: () => {
      checkMap();
    },
  } as any);

  React.useEffect(() => {
    if (!canDraw) return;
    const shapeOptions = { color };
    const options = {
      position: "topright",
      draw: {
        polyline: {
          showLength: false,
          shapeOptions,
          repeatMode: quickDraw,
        },
        polygon: {
          showArea: false,
          shapeOptions,
          repeatMode: quickDraw,
        },
        rectangle: {
          showArea: false,
          shapeOptions,
          repeatMode: quickDraw,
        },
        circle: {
          showRadius: false,
          shapeOptions,
          repeatMode: quickDraw,
        },
        marker: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: featureRef.current,
      },
    };
    const drawControl = new L.Control.Draw(options as any);
    map.addControl(drawControl);

    return () => {
      map.removeControl(drawControl);
    };
  }, [canDraw, color, map, quickDraw]);

  const checkMap = () => {
    if (canDraw) {
      const featureCollection: FeatureCollection = {
        type: "FeatureCollection",
        features: [],
      };
      featureRef.current.eachLayer((layer: any) => {
        const feature = layer.toGeoJSON();
        if (!feature.type) feature.type = "Feature";
        feature.properties = { ...(feature.properties || {}) };
        feature.properties.color = color;
        if (layer.getRadius) {
          feature.properties.radius = layer.getRadius();
        }
        featureCollection.features.push(feature);
      });
      handleAnnotationChange(featureCollection);
    }
  };

  React.useEffect(() => {
    const feature = featureRef.current;
    feature.eachLayer((layer: any) => {
      feature.removeLayer(layer);
    });
    const handler = setTimeout(() => {
      feature?.addData(annotation || defaultAnnotation);
    });
    return () => {
      clearTimeout(handler);
    };
  }, [annotation]);

  const pointToLayer = React.useCallback(
    (feature: any, latlng: LatLng) => {
      const radius = feature.properties?.radius;
      if (radius) {
        return L.circle(latlng, { radius });
      }
      return L.marker(latlng, { icon: defaultIcon });
    },
    [defaultIcon]
  );

  const handleSubmit = (
    {
      name,
      description,
      assetPartId = assetPartIds.length === 1 ? assetPartIds[0] : "",
    }: AnnotationFormData,
    layer?: Layer
  ) => {
    if (!layer) return;

    const feature = ((layer as any).feature = (layer as any).feature || {});
    if (!feature.properties) feature.properties = {};
    if (description) feature.properties.description = description;
    if (assetPartId) feature.properties.assetPartId = assetPartId;
    if (name) {
      layer.bindTooltip(getTooltipMarkup(name, description), {
        sticky: true,
        direction: "top",
      });
      feature.properties.name = name;
    }
    checkMap();
    setTimeout(closeModal, 100);
  };

  const getDefaultName = (assetPartId: string | null) => {
    const assetPartOption = assetPartOptions.find(
      (apo) => apo.value === assetPartId
    );
    return `${assetPartOption?.label || ""} (${asset.name})`;
  };

  const getStyle = useCallback(
    (feature: any) => {
      if (feature.properties?.color) {
        return { color: feature.properties?.color };
      }
      return shapeOptions;
    },
    [shapeOptions]
  );

  return (
    <>
      <GeoJSON
        data={defaultAnnotation}
        onEachFeature={onEachFeature}
        pointToLayer={pointToLayer}
        ref={featureRef}
        style={getStyle}
      />
      {isOpen && (
        <Drawer
          isOpen
          placement="right"
          onClose={() => {
            if (activeLayer) featureRef.current.removeLayer(activeLayer);
            closeModal();
          }}
        >
          <DrawerOverlay zIndex={1500}>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Add info about this area</DrawerHeader>
              <DrawerBody>
                <Formik
                  initialValues={
                    {
                      name: defaultName,
                      description: "",
                      assetPartId: assetParts.length
                        ? assetPartIds.length === 1
                          ? assetPartIds[0]
                          : ""
                        : undefined,
                    } as AnnotationFormData
                  }
                  enableReinitialize
                  onSubmit={(data) => handleSubmit(data, activeLayer)}
                  validationSchema={AnnotationValidationSchema}
                >
                  {({ getFieldProps, setFieldValue }) => (
                    <Form id="asset_type_create" noValidate>
                      <FormGroup label="Name" name="name">
                        <Input
                          autoFocus
                          autoComplete="off"
                          {...getFieldProps("name")}
                        />
                      </FormGroup>
                      <FormGroup label="Description" name="description">
                        <Textarea
                          autoComplete="off"
                          {...getFieldProps("description")}
                        />
                      </FormGroup>
                      {assetPartOptions.length > 0 && (
                        <SelectCombobox
                          label="Breaker"
                          name="assetPartId"
                          options={assetPartOptions}
                          onChange={(assetPartId: string | null) => {
                            setFieldValue("name", getDefaultName(assetPartId));
                          }}
                        />
                      )}
                      <Box marginY="10">
                        <Button width="full" type="submit">
                          Add Info
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}
    </>
  );
};

export default DrawControl;

export const AnnotationValidationSchema = yupObject().shape({
  name: nameSchema.optional(),
  description: descriptionSchema,
  assetPartId: assetPartIdSchema,
});

export type AnnotationFormData = {
  name?: string;
  description?: string;
  assetPartId?: string;
};

const getTooltipMarkup = (name: string, description?: string) =>
  `<h3 class="leaflet-tooltip-heading">${name}</h3>${
    description
      ? `<p class="leaflet-tooltip-description">${description}</p>`
      : ""
  }`;

const onEachFeature = (feature: any, layer: Layer) => {
  if (feature.properties && feature.properties.name) {
    layer.bindTooltip(
      getTooltipMarkup(feature.properties.name, feature.properties.description),
      { sticky: true, direction: "top" }
    );
  }
};
