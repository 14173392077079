import { EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { useCallback } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";

import InputField from "../../../components/inputField";
import Select from "../../../components/select";
import { validateEmail } from "../../../helpers/validationEmail";
import { CompanyContactTypeEnum, WorkOrder } from "../../../models/workOrder";

interface WorkOrderCreateCompanyContactInfoPresenterProps {
  workOrder: WorkOrder;
  selectOptions: EuiSuperSelectOption<string>[];
  onContactInfoChange: (type: CompanyContactTypeEnum) => void;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateCompanyContactInfoPresenter: React.FC<
  WorkOrderCreateCompanyContactInfoPresenterProps
> = ({ onContactInfoChange, workOrder, onWorkOrderChange, selectOptions }) => {
  const { euiTheme } = useEuiTheme();
  const isValidPhone = (phoneMobile: string, phoneWork: string): boolean => {
    return phoneMobile && phoneWork
      ? isValidPhoneNumber(phoneMobile) && isValidPhoneNumber(phoneWork)
      : isValidPhoneNumber(phoneMobile) || isValidPhoneNumber(phoneWork);
  };
  const companyContactFields = [
    {
      label: "Full Name",
      valueField: "companyContactFullName",
      required: true,
      isInvalid: !workOrder.companyContactFullName,
    },
    {
      label: "Role",
      valueField: "companyContactOccupation",
    },
    {
      label: "Team Name",
      valueField: "companyContactTeamName",
    },
    {
      label: "Email",
      valueField: "companyContactEmail",
      isInvalid:
        workOrder.companyContactEmail !== null
          ? !validateEmail(workOrder.companyContactEmail || "")
          : false,
    },
    {
      label: "Phone Number (work)",
      valueField: "companyContactWorkPhone",
      required:
        !workOrder.companyContactWorkPhone &&
        !workOrder.companyContactMobilePhone,
      isInvalid: !(
        isValidPhoneNumber(workOrder.companyContactWorkPhone || "") ||
        (workOrder.companyContactWorkPhone === null &&
          isValidPhoneNumber(workOrder.companyContactMobilePhone || ""))
      ),
    },
    {
      label: "Phone Number (mobile)",
      valueField: "companyContactMobilePhone",
      required:
        !workOrder.companyContactWorkPhone &&
        !workOrder.companyContactMobilePhone,
      isInvalid: !(
        isValidPhoneNumber(workOrder.companyContactMobilePhone || "") ||
        (workOrder.companyContactMobilePhone === null &&
          isValidPhoneNumber(workOrder.companyContactWorkPhone || ""))
      ),
    },
  ];

  const getFieldValue = (valueField: string | null) => {
    if (workOrder && valueField) {
      const entry = Object.entries(workOrder).find(
        ([key, value]) => key === valueField
      );
      return entry ? entry[1] : null;
    }
    return null;
  };

  const onInputChange = useCallback(
    (field, value) => {
      onWorkOrderChange({ ...workOrder, [field]: value });
    },
    [onWorkOrderChange, workOrder]
  );

  return (
    <EuiFlexGroup
      justifyContent="flexStart"
      direction="column"
      wrap={false}
      gutterSize="none"
      style={{
        width: "100%",
        flexGrow: 1,
        paddingBottom: "80px",
      }}
    >
      <EuiFlexItem
        style={{
          width: "100%",
        }}
      >
        <EuiText className={"columnTitle"}>Company Contact Info</EuiText>
      </EuiFlexItem>
      <EuiFlexItem style={{ width: "100%", marginBottom: euiTheme.size.m }}>
        <Select
          label="Contact Company Info"
          required={true}
          options={selectOptions}
          disabled={!workOrder?.companyId}
          selectedValue={workOrder.companyContactType}
          onSelect={onContactInfoChange}
        />
      </EuiFlexItem>
      <EuiFlexGroup
        justifyContent="flexStart"
        direction="column"
        wrap={false}
        gutterSize="none"
        style={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <EuiFlexItem
          style={{
            width: "100%",
            flexGrow: 1,
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {companyContactFields?.length &&
            companyContactFields.map((field) => {
              return (
                <InputField
                  label={field.label}
                  disabled={
                    workOrder.companyContactType !==
                    CompanyContactTypeEnum.ADD_MANUALLY
                  }
                  value={getFieldValue(field.valueField)}
                  key={field.label}
                  onInputChange={(value) => {
                    onInputChange(field.valueField, value);
                  }}
                  required={field.required}
                  isInvalid={field.isInvalid}
                  style={{
                    marginTop: euiTheme.size.m,
                    marginBottom: euiTheme.size.m,
                  }}
                />
              );
            })}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};

export default WorkOrderCreateCompanyContactInfoPresenter;
