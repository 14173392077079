import { useEffect, useState } from "react";
import tinycolor2 from "tinycolor2";

import { defaultAssetCategoryColors } from "../components/elements/assetIcons/colorPicker";
import useAssetCategories from "./useAssetCategories";

const useNextOrRandomAssetCategoryColor = () => {
  const [color, setColor] = useState(tinycolor2.random().toHexString());
  const { data } = useAssetCategories();

  useEffect(() => {
    if (data) {
      const existingColors = data.assetCategories.map((c) => c.iconColor);
      const availableColors = defaultAssetCategoryColors.find(
        (c) => !existingColors.includes(c)
      );

      if (availableColors) setColor(availableColors);
    }
  }, [data]);

  return color;
};

export default useNextOrRandomAssetCategoryColor;
