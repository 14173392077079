export const calculateStripePriceForInterval = (
  amount: number,
  interval: string
) => Math.ceil(amount / 100 / (interval === "year" ? 12 : 1));

export const formatPrice = (
  price: number,
  showDecimals: boolean = true
): string => {
  const decimalPlaces = price % 100;
  return showDecimals && decimalPlaces > 0
    ? `$${price.toFixed(2)}`
    : `$${price}`;
};
