import { Box, Flex, List, ListItem } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";

import { defaultDateWithTimeFormat } from "../../../constants/date";
import { MaintenanceNoteFragmentFragment } from "../../../graphql/graphql";
import MaintenanceNoteActions from "../noteActions";

interface MaintenanceNotesProps {
  notes: MaintenanceNoteFragmentFragment[];
  canEditMaintenance: boolean;
}

const MaintenanceNotes: React.FC<MaintenanceNotesProps> = ({
  notes,
  canEditMaintenance,
}) => {
  return (
    <List borderTop="1px solid" borderColor="gray.100">
      {notes.map((mn) => (
        <ListItem
          key={mn.id}
          borderBottom="1px solid"
          borderColor="gray.100"
          p="3"
          pt="2"
          _hover={{ bgColor: "gray.50" }}
        >
          <Flex alignItems="center">
            <Box flexGrow={1}>
              <Box whiteSpace="pre-wrap">{mn.description}</Box>
              <Flex alignItems="center" fontSize="xs" marginTop="1">
                {format(mn.createdAt, defaultDateWithTimeFormat)}
                <Box as="span" marginX="1">
                  by
                </Box>
                {mn.addedBy.userName || mn.addedBy.userEmail}
              </Flex>
            </Box>
            <MaintenanceNoteActions
              maintenanceNote={mn}
              canEditMaintenance={canEditMaintenance}
            />
          </Flex>
        </ListItem>
      ))}
    </List>
  );
};

export default MaintenanceNotes;
