import React from "react";

import { useFileQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface FileDeleteProps {
  id: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const FileDelete: React.FC<FileDeleteProps> = ({
  id,
  isOpen,
  onOpen,
  onClose,
}) => {
  const { data } = useFileQuery({
    variables: { id },
  });

  return data && id ? (
    <Presenter
      file={data.file}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    />
  ) : null;
};

export default FileDelete;
