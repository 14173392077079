import { Box } from "@chakra-ui/react";
import { format } from "date-fns";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import RRule from "rrule";

import { defaultDateFormat } from "../../../../constants/date";
import useBoxSize from "../../../../hooks/useBoxSize";
import colors from "../../../../theme/foundations/colors";

interface UserActivityChartProps {
  startDate?: number;
  endDate?: number;
  data: any;
}

const UserActivityChart: React.FC<UserActivityChartProps> = ({
  startDate,
  endDate,
  data,
}) => {
  const { containerRef, height } = useBoxSize();
  const formattedData = useMemo(() => {
    const start = startDate ? new Date(startDate) : new Date();
    const end = endDate ? new Date(endDate) : new Date();
    const rule = new RRule({
      freq: RRule.DAILY,
      dtstart: start,
      until: end,
    });
    const groupedDate = groupBy(data.map((d: any) => d.Date.split(" at")[0]));

    return rule.all().map((date) => {
      const dateString = format(date, defaultDateFormat);
      return {
        name: dateString,
        Activities: groupedDate[dateString]?.length || 0,
      };
    });
  }, [data, endDate, startDate]);

  return (
    <Box ref={containerRef}>
      <ResponsiveContainer width="100%" height={height + 32}>
        <BarChart data={formattedData}>
          <Bar
            type="monotone"
            dataKey="Activities"
            fill={colors.secondary[500]}
          />
          <XAxis dataKey="name" fontSize=".65rem" />
          <Tooltip
            contentStyle={{ padding: "5px" }}
            itemStyle={{
              fontSize: ".6rem",
              lineHeight: ".6rem",
              color: colors.gray["800"],
            }}
            separator=""
            formatter={(value: number, label: string) =>
              label === "No data" ? "" : `: ${value}`
            }
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default UserActivityChart;
