import { Icon, IconProps } from "@chakra-ui/react";

const ChillerAirCooled = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M82.2 65.4c0-5.413-4.388-9.8-9.8-9.8H55.6a1.4 1.4 0 0 0 0 2.8h16.8a7 7 0 1 1-7 7 1.4 1.4 0 1 0-2.8 0c0 5.412 4.388 9.8 9.8 9.8 5.412 0 9.8-4.388 9.8-9.8Z"
      fill="currentColor"
    />
    <path
      d="M94.8 50H55.6a1.4 1.4 0 1 0 0 2.8h39.2a7 7 0 1 1-7 7 1.4 1.4 0 1 0-2.8 0c0 5.412 4.388 9.8 9.8 9.8 5.412 0 9.8-4.388 9.8-9.8 0-5.412-4.388-9.8-9.8-9.8ZM55.6 41.6h16.8c5.412 0 9.8-4.388 9.8-9.8 0-5.412-4.388-9.8-9.8-9.8-5.412 0-9.8 4.388-9.8 9.8a1.4 1.4 0 1 0 2.8 0 7 7 0 1 1 7 7H55.6a1.4 1.4 0 0 0 0 2.8Z"
      fill="currentColor"
    />
    <path
      d="M55.6 47.2h39.2c5.412 0 9.8-4.388 9.8-9.8 0-5.412-4.388-9.8-9.8-9.8-5.41.006-9.794 4.39-9.8 9.8a1.4 1.4 0 1 0 2.8 0 7 7 0 1 1 7 7H55.6a1.4 1.4 0 1 0 0 2.8ZM31.8 26.2v77h2.8V24.8h-2.8v1.4ZM23.4 29v70a1.4 1.4 0 0 0 1.4 1.4H29V27.6h-4.2a1.4 1.4 0 0 0-1.4 1.4ZM37.4 76.472v4.441l5.723 5.723a1.568 1.568 0 0 0 2.677-1.108 1.566 1.566 0 0 0-.46-1.12l-7.94-7.936ZM37.4 60.375v4.441l5.723 5.722a1.568 1.568 0 0 0 2.677-1.11 1.564 1.564 0 0 0-.46-1.12l-7.94-7.933ZM37.4 44.276v4.441l5.723 5.724a1.57 1.57 0 0 0 2.218-2.222l-7.94-7.943ZM44.833 38.684a1.573 1.573 0 0 0 .508-2.563L37.4 28.18v4.44l5.722 5.724a1.54 1.54 0 0 0 1.71.34ZM37.4 92.571v4.44l5.723 5.723a1.57 1.57 0 0 0 2.677-1.109 1.582 1.582 0 0 0-.46-1.12l-7.94-7.934ZM104.124 83.6a1.4 1.4 0 0 0-2.1-1.213l-1.213.7-.7-1.212a1.399 1.399 0 1 0-2.424 1.4l.7 1.211L92 88.174V80.8h1.4a1.4 1.4 0 1 0 0-2.8H92v-1.4a1.4 1.4 0 1 0-2.8 0V78h-1.4a1.4 1.4 0 1 0 0 2.8h1.4v7.374l-6.387-3.688.7-1.211a1.4 1.4 0 1 0-2.425-1.401l-.7 1.21-1.212-.7a1.4 1.4 0 0 0-1.4 2.427l1.213.7-.7 1.212a1.4 1.4 0 1 0 2.424 1.4l.7-1.212L87.8 90.6l-6.387 3.686-.7-1.211a1.4 1.4 0 1 0-2.425 1.4l.7 1.212-1.212.7a1.4 1.4 0 0 0 1.4 2.426l1.213-.7.7 1.213a1.4 1.4 0 0 0 2.424-1.4l-.7-1.213 6.387-3.689v7.376h-1.4a1.4 1.4 0 1 0 0 2.799h1.4v1.4a1.4 1.4 0 1 0 2.8 0v-1.4h1.4a1.4 1.4 0 1 0 0-2.799H92v-7.376l6.387 3.688-.7 1.212a1.4 1.4 0 1 0 2.424 1.4l.7-1.212 1.213.7a1.4 1.4 0 0 0 1.4-2.426l-1.213-.7.7-1.213a1.398 1.398 0 1 0-2.424-1.4l-.7 1.211L93.4 90.6l6.387-3.688.7 1.212a1.4 1.4 0 1 0 2.424-1.4l-.7-1.212 1.213-.7a1.4 1.4 0 0 0 .7-1.213Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChillerAirCooled;
