import "leaflet.browser.print/dist/leaflet.browser.print.js";

import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";
import { BoundsLiteral } from "leaflet";
import React, { useCallback, useState } from "react";
import { ImageOverlay, useMapEvents } from "react-leaflet";

interface PlanOverlayProps {
  imageUrl: string;
  onImageLoaded?: () => void;
}

const PlanOverlay: React.FC<PlanOverlayProps> = ({
  imageUrl,
  onImageLoaded,
}) => {
  const [isLoadingImg, setIsLoadingImg] = useState<boolean>(false);

  const map = useMapEvents({} as any);

  const [bounds, setBounds] = React.useState<any>([
    [0, 0],
    [2500, 3500],
  ]);

  const setImgBounds = useCallback(
    (height, width) => {
      const newBounds: BoundsLiteral = [
        [0, 0],
        [height, width],
      ];
      const maxBounds: BoundsLiteral = [
        [-500, -500],
        [height + 500, width + 500],
      ];
      setBounds(newBounds);
      map.setMaxBounds(maxBounds);
      map.fitBounds(newBounds);
    },
    [map]
  );

  React.useEffect(() => {
    setIsLoadingImg(true);
    let img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImgBounds(img.height, img.width);
      setIsLoadingImg(false);
      if (imageUrl && onImageLoaded) {
        onImageLoaded();
      }
    };
  }, [imageUrl, onImageLoaded, setImgBounds]);

  return !isLoadingImg ? (
    <ImageOverlay bounds={bounds} url={imageUrl} />
  ) : (
    <EuiFlexGroup
      justifyContent="center"
      alignItems="center"
      style={{ minWidth: "100%", minHeight: "100%" }}
    >
      <EuiLoadingSpinner size="xxl" />
    </EuiFlexGroup>
  );
};

export default PlanOverlay;
