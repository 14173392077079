import { Box, Button, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import AddressFields from "../../../components/elements/addressFields";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyFragmentFragment,
  useCompanyUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  address1Schema,
  address2Schema,
  citySchema,
  stateSchema,
  yupObject,
  zipSchema,
} from "../../../utils/validation";

interface CompanyAddressChangePagePresenterProps {
  currentCompany: CompanyFragmentFragment;
}

const CompanyAddressChangePagePresenter: React.FC<
  CompanyAddressChangePagePresenterProps
> = ({ currentCompany }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [companyUpdateMutation, { loading }] = useCompanyUpdateMutation();

  const onSubmit = async (
    data: CompanyFormData,
    { setFieldError }: FormikHelpers<CompanyFormData>
  ) => {
    try {
      const { data: serverData, errors } = await companyUpdateMutation({
        variables: { data: { ...data, name: currentCompany.name } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        navigate(getRoutePath("companySettings"));
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        address1: currentCompany.address1 || "",
        address2: currentCompany.address2 || "",
        city: currentCompany.city || "",
        state: currentCompany.state || "",
        zip: currentCompany.zip || "",
        countryAlpha2: currentCompany.countryAlpha2 || "US",
        coordinates: currentCompany.coordinates || [0, 0],
      }}
      validationSchema={CompanyFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate>
          <AddressFields autoFocus />
          <Box marginY="10">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyAddressChangePagePresenter;

const CompanyFormValidationSchema = yupObject().shape({
  address1: address1Schema,
  address2: address2Schema,
  city: citySchema,
  state: stateSchema,
  zip: zipSchema,
});

type CompanyFormData = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  countryAlpha2: string;
  coordinates: number[];
};
