import { Icon } from "@chakra-ui/react";

import Bell from "../../pages/workOrders/components/svgIcons/bell";

const Notification = ({ hasNotification = false, ...props }) => (
  <Icon viewBox="0 0 28 28" boxSize="28px" {...props}>
    <Bell />
    <circle
      cx="20"
      cy="4"
      r="2.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
    />
  </Icon>
);

export default Notification;
