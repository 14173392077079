import {
  Box,
  Button,
  Flex,
  Menu,
  MenuItem,
  MenuList,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useEffect, useState } from "react";

import { MaintenanceIntervalType } from "../../../graphql/graphql";
import DatePicker from "../datePickerField";
import LabeledMenuButton from "../labeledMenuButton";
import NumberInput from "../numberInput";
import SelectCombobox from "../selectCombobox";

interface MaintenanceIntervalFieldProps {
  alwaysOn?: boolean;
  hideEndOption?: boolean;
  namePrefix?: string;
}

const MaintenanceIntervalField: React.FC<MaintenanceIntervalFieldProps> = ({
  alwaysOn = false,
  hideEndOption = false,
  namePrefix = "",
}) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const intervalValueName = `${namePrefix}intervalValue`;
  const intervalTypeName = `${namePrefix}intervalType`;
  const endDateTimeName = `${namePrefix}endDateTime`;
  const endAfterName = `${namePrefix}endAfter`;

  const intervalValueValue = get(values, intervalValueName);
  const intervalTypeValue = get(values, intervalTypeName);
  const endDateTimeValue = get(values, endDateTimeName);
  const endAfterValue = get(values, endAfterName);

  const [endType, setEndType] = useState<"Never" | "On" | "After">(
    endDateTimeValue ? "On" : endAfterValue ? "After" : "Never"
  );
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: !!intervalValueValue && !!intervalTypeValue,
  });

  useEffect(() => {
    if (isOpen) {
      setFieldValue(intervalValueName, intervalValueValue || 1);
      setFieldValue(
        intervalTypeName,
        intervalTypeValue || MaintenanceIntervalType.Months
      );
      if (!hideEndOption) {
        setFieldValue(
          endDateTimeName,
          endDateTimeValue ? new Date(endDateTimeValue) : null
        );
        setFieldValue(endAfterName, endAfterValue || null);
      }
    } else {
      setFieldValue(intervalValueName, null);
      setFieldValue(intervalTypeName, null);
      if (!hideEndOption) {
        setFieldValue(endDateTimeName, null);
        setFieldValue(endAfterName, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!hideEndOption) {
      switch (endType) {
        case "On":
          setFieldValue(endAfterName, null);
          return;
        case "After":
          setFieldValue(endDateTimeName, null);
          return;
        case "Never":
          setFieldValue(endAfterName, null);
          setFieldValue(endDateTimeName, null);
          return;
      }
    }
  }, [endAfterName, endDateTimeName, endType, hideEndOption, setFieldValue]);

  return (
    <Box>
      {!alwaysOn && (
        <Flex alignItems="center">
          <Switch
            isChecked={isOpen}
            onChange={onToggle}
            aria-label="Recurring?"
          />
          <Button
            variant="unstyled"
            onClick={onToggle}
            fontWeight="normal"
            textTransform="none"
            type="button"
            marginLeft="2"
            color="black"
          >
            Recurring?
          </Button>
        </Flex>
      )}
      {isOpen && (
        <>
          <Flex>
            <Box pt="7" fontSize="sm">
              Send reminder every
            </Box>
            <Box marginX="2" width="24">
              <NumberInput
                label=""
                name={intervalValueName}
                defaultValue={1}
                min={1}
              />
            </Box>
            <Box width="32">
              <SelectCombobox
                label=""
                name={intervalTypeName}
                options={[
                  {
                    label: "Years",
                    query: "Years",
                    value: MaintenanceIntervalType.Years,
                  },
                  {
                    label: "Months",
                    query: "Months",
                    value: MaintenanceIntervalType.Months,
                  },
                  {
                    label: "Weeks",
                    query: "Weeks",
                    value: MaintenanceIntervalType.Weeks,
                  },
                  {
                    label: "Days",
                    query: "Days",
                    value: MaintenanceIntervalType.Days,
                  },
                ]}
              />
            </Box>
          </Flex>
          {!hideEndOption && (
            <Flex>
              <Box marginRight="3" pt="7" fontSize="sm">
                Ends
              </Box>
              <Box marginTop="3">
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        value={endType}
                        label=""
                        isSelect
                        styleProps={{
                          maxWidth: "100%",
                          fontSize: "md",
                          color: "black",
                        }}
                      />
                      <MenuList>
                        <MenuItem onClick={() => setEndType("Never")}>
                          Never
                        </MenuItem>
                        <MenuItem onClick={() => setEndType("On")}>On</MenuItem>
                        <MenuItem onClick={() => setEndType("After")}>
                          After
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              {endType === "On" && (
                <Box width="120px" marginLeft="2">
                  <DatePicker name={endDateTimeName} label="End on" />
                </Box>
              )}
              {endType === "After" && (
                <Flex>
                  <Box width="120px" marginX="2">
                    <NumberInput
                      label=""
                      name={endAfterName}
                      defaultValue={1}
                      min={1}
                    />
                  </Box>
                  <Box paddingY="6">Occurrences</Box>
                </Flex>
              )}
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default MaintenanceIntervalField;
