import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const StarShip = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    viewBox="0 0 24 24"
    boxSize="40px"
    color="primary.500"
    {...props}
  >
    <path d="M5.52738 15.8984c-1.68131 2.0252-2.83678 4.581-3.13219 7.3229-.02531.2343.06844.4654.24937.6164.17752.1469.41916.2013.65157.1326l5.14688-1.5426c-1.55391-2.0293-2.46844-4.0707-2.91563-6.5293zM21.6051 23.2213c-.2917-2.7078-1.4239-5.2612-3.1313-7.3229-.4545 2.4926-1.4018 4.5503-2.9142 6.5302l5.1445 1.5417c.2328.0689.4744.014.6516-.1326.1809-.151.2747-.3821.2494-.6164zM17.3906 12c0-4.33555-1.7347-8.52093-4.8846-11.78513A.70313.70313 0 0012 0a.7033.7033 0 00-.506.21488C8.34413 3.47907 6.60938 7.66444 6.60938 12c0 3.8707.79481 6.7739 2.97952 9.6121.4707.6118 1.0177 1.2418 1.708 1.9672v-8.2511c0-.3884.3148-.7032.7032-.7032.3883 0 .7031.3148.7031.7032v8.2514c3.2093-3.3657 4.6874-6.2998 4.6874-11.5796zM12 8.19691c-.5758 0-1.1024.29559-1.4447.81093-.2149.32344-.65136.41147-.97475.19665-.32344-.21488-.41147-.65129-.19664-.97477.5986-.90113 1.57659-1.43907 2.61619-1.43907s2.0175.53799 2.6161 1.43907c.2148.32348.1268.75989-.1966.97477-.3223.21403-.759.12811-.9748-.19665-.3424-.51534-.869-.81093-1.4448-.81093z" />
  </Icon>
);

export default StarShip;
