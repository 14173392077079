import "leaflet-polylinedecorator";
import "leaflet-geometryutil";
import "leaflet-editable";

import { FeatureCollection } from "geojson";
import L, { LatLng, LatLngTuple } from "leaflet";
import { FC, useEffect } from "react";
import { PolylineProps, useMap } from "react-leaflet";
import { Subject, debounceTime } from "rxjs";

import defaultTheme from "../../../../chakraTheme";
import handleException from "../../../../utils/error";

export type Connection = [[number, number], [number, number]][];

export interface ConnectionsData {
  coordinates: Connection;
  fromAssetId: string;
  toAssetId: string;
  editable?: boolean;
}

interface ConnectionLinesProps {
  fedFromConnections?: ConnectionsData[];
  fedToConnections?: ConnectionsData[];
  geoJSON?: FeatureCollection;
  assetCoordinates?: LatLngTuple;
  assetId?: string;
  handleFloorPlanAssetConnectionsChange?: (
    points: Connection,
    fromAssetId: string,
    toAssetId: string
  ) => void;
  editable: boolean;
}

const ConnectionLines: FC<ConnectionLinesProps> = ({
  fedFromConnections,
  fedToConnections,
  geoJSON,
  assetCoordinates,
  handleFloorPlanAssetConnectionsChange,
}) => {
  const map = useMap();
  const handleConnectionChange = (
    points: Connection,
    fromAssetId: string,
    toAssetId: string
  ) => {
    if (handleFloorPlanAssetConnectionsChange) {
      handleFloorPlanAssetConnectionsChange(points, fromAssetId, toAssetId);
    }
  };
  let closestPoint: any[] = [];
  if (geoJSON && assetCoordinates) {
    geoJSON.features.forEach((feature: any) => {
      try {
        if (feature.properties?.radius) {
          closestPoint.push({
            lng: feature.geometry.coordinates[1],
            lat: feature.geometry.coordinates[0],
          });
        } else {
          closestPoint.push(
            L.GeometryUtil.closest(
              map,
              feature.geometry.coordinates,
              assetCoordinates
            )
          );
        }
      } catch (error: any) {
        handleException(error);
      }
    });
  }

  return (
    <>
      {!!assetCoordinates &&
        closestPoint.map((point, i) => (
          <PolylineDecorator
            key={i}
            positions={[
              [assetCoordinates[1], assetCoordinates[0]],
              [point.lng, point.lat],
            ]}
            pathOptions={{ color: defaultTheme.colors.secondary["500"] }}
            tooltip="Affected area"
          />
        ))}
      {!!fedToConnections &&
        fedToConnections.map((c, i) => (
          <PolylineDecorator
            key={i}
            positions={c.coordinates}
            pathOptions={{ color: defaultTheme.colors.secondary["500"] }}
            tooltip="Fed to"
            handleConnectionChange={(points) =>
              handleConnectionChange(points, c?.fromAssetId, c?.toAssetId)
            }
            editable={c.editable}
          />
        ))}
      {!!fedFromConnections &&
        fedFromConnections.map((c, i) => (
          <PolylineDecorator
            key={i}
            positions={c.coordinates}
            pathOptions={{ color: defaultTheme.colors.primary["500"] }}
            tooltip="Fed from"
            handleConnectionChange={(points) =>
              handleConnectionChange(points, c?.fromAssetId, c?.toAssetId)
            }
            editable={c.editable}
          />
        ))}
    </>
  );
};

export default ConnectionLines;

interface PolylineDecoratorProps extends PolylineProps {
  tooltip: string;
  editable?: boolean;
  handleConnectionChange?: (points: Connection) => void;
}

const PolylineDecorator: FC<PolylineDecoratorProps> = (props) => {
  const map = useMap();
  const onConnectionChange = props.handleConnectionChange;

  useEffect(() => {
    const connectionChangesSubject = new Subject<null>();
    const polyline = (L as any).polyline(props.positions, props.pathOptions);
    const polylineDecorator = L.polylineDecorator(polyline, {
      patterns: [
        {
          offset: "50%",
          repeat: 0,
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            pathOptions: { ...props.pathOptions, weight: 4 },
          }),
        },
      ],
    });
    polyline.bindTooltip(props.tooltip);
    polyline.addTo(map);
    polylineDecorator.bindTooltip(props.tooltip);
    polylineDecorator.addTo(map);
    const connectionChangesSubscription = connectionChangesSubject
      .asObservable()
      .pipe(debounceTime(300))
      .subscribe(() => {
        const points: Connection = polyline
          .getLatLngs()
          .map((latLng: LatLng) => [latLng.lat, latLng.lng]);
        onConnectionChange && onConnectionChange(points);
      });

    const handleEditableState = () => {
      if (polyline.editEnabled()) return;
      polyline.enableEdit();
      polylineDecorator.remove();
    };
    polyline.once("click", () => handleEditableState());
    polylineDecorator.once("click", () => handleEditableState());
    polyline.on("editable:editing", () => {
      connectionChangesSubject.next(null);
    });
    props.editable && handleEditableState();

    return () => {
      connectionChangesSubscription.unsubscribe();
      polyline.remove();
      polylineDecorator?.remove();
    };
  }, [props, map, onConnectionChange]);

  return null;
};
