import { Box, Input } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { addYears } from "date-fns";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useCallback } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import defaultTheme from "../../../chakraTheme";
import {
  defaultDateFormat,
  defaultDateWithTimeFormat,
} from "../../../constants/date";
import { timezone } from "../../../utils/date/timezones";
import FormGroup from "../formGroup";

export const DateInputWrap = styled(Box)`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    border-radius: 0;
    color: ${defaultTheme.colors.black};
    background-color: ${defaultTheme.colors.white};
    box-shadow: ${defaultTheme.shadows.md};
    border-color: ${defaultTheme.colors.gray[300]};
  }
  .react-datepicker__header {
    background-color: ${defaultTheme.colors.gray[100]};
    border-color: ${defaultTheme.colors.gray[300]};
  }
  .react-datepicker__header__dropdown {
    top: 8px;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: ${defaultTheme.colors.gray[900]};
    font-weight: 400;
  }
  .react-datepicker__day-names {
    color: ${defaultTheme.colors.gray[900]};
    font-weight: 400;
    font-size: 0.6rem;
    text-transform: uppercase;
  }
  .react-datepicker__day {
    border-radius: 0;
    padding: 0.25rem;
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
  .react-datepicker__day:hover {
    background-color: ${defaultTheme.colors.secondary[100]};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: ${defaultTheme.colors.black};
  }
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected {
    background-color: ${defaultTheme.colors.secondary[500]};
    color: ${defaultTheme.colors.white};
    font-weight: normal;
  }
  .react-datepicker__current-month {
    display: none;
  }
`;

export interface CADatePickerProps
  extends Omit<ReactDatePickerProps, "selected" | "onChange"> {
  name: string;
  label: string;
}

const CADatePicker: React.FC<CADatePickerProps> = ({
  name,
  label,
  ...otherProps
}) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const value = get(values, name);
  const timezoneValue = get(values, "timezone") || timezone;
  const handleChange = useCallback(
    (date: Date) => {
      setFieldValue(name, date, timezoneValue);
    },
    [setFieldValue, name, timezoneValue]
  );

  return (
    <Box position="relative" zIndex={10}>
      <FormGroup name={name} label={label}>
        <DateInputWrap>
          <DatePicker
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={value}
            onChange={handleChange}
            customInput={<Input />}
            minDate={new Date()}
            maxDate={addYears(new Date(), 10)}
            showPopperArrow={false}
            dateFormat={
              otherProps.showTimeSelect
                ? defaultDateWithTimeFormat
                : defaultDateFormat
            }
            {...otherProps}
          />
        </DateInputWrap>
      </FormGroup>
    </Box>
  );
};

export default CADatePicker;
