import { useReactiveVar } from "@apollo/client";
import { Box, Button, Center } from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";

interface BillingNoAccessProps {
  type: string;
}

const BillingNoAccess: React.FC<BillingNoAccessProps> = ({ type }) => {
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const [, setSearchParams] = useSearchParams();

  const changePlan = () => {
    setSearchParams({ CaAprType: "changePlan" });
  };

  return (
    <Center height="40">
      <Box textAlign="center">
        <Box>
          You have reached the maximum{" "}
          {type === "files" ? "amount of file storage" : `number of ${type}`}{" "}
          for your current plan.{" "}
          {ROLES.companyPlan.includes(currentCompanyRole.role) ? (
            <Box as="span" marginTop="2">
              Please{" "}
              <Button
                variant="link"
                colorScheme="secondary"
                onClick={changePlan}
              >
                upgrade your plan
              </Button>{" "}
              to add more {type}.
            </Box>
          ) : (
            <Box as="span" marginTop="2">
              Please contact your account admin to add more{" "}
              {type === "files" ? "storage" : type}.
            </Box>
          )}
        </Box>
      </Box>
    </Center>
  );
};

export default BillingNoAccess;
