import { useEffect, useState } from "react";

import {
  LocationIndexFragmentFragment,
  useLocationIndexQuery,
} from "../graphql/graphql";
import { LocationWithChildren } from "./useTopLocationsTree";

const useTopLocationTree: (
  locationId: string
) => LocationWithChildren | undefined = (locationId) => {
  const { data } = useLocationIndexQuery();
  const [locationsAndSubLocations, setLocationsAndSubLocations] =
    useState<LocationWithChildren>();

  useEffect(() => {
    if (!data) return;

    const findSubLocations = (
      location: LocationIndexFragmentFragment
    ): LocationWithChildren =>
      Object.assign({}, location, {
        children: data.locations
          .filter((l) => l.parentId === location.id)
          .map((sl) => findSubLocations(sl)),
      });

    const location = data.locations.find(
      (location) => location.id === locationId
    );
    if (location) setLocationsAndSubLocations(findSubLocations(location));
  }, [data, locationId]);

  return locationsAndSubLocations;
};

export default useTopLocationTree;
