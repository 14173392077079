import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { cloneDeep, isEqual } from "lodash";
import React, { useEffect, useState } from "react";

import { useWOGetCompanyUsersQuery } from "../../../../../graphql/queries/workOrders";
import { WOGetCompanyUsers } from "../../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderEditSectionFooter from "../../components/sectionFooter";
import WorkOrderEditAssigneePresenter from "./presenter";

interface WorkOrderEditAssigneeProps {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
}

export type SectionDataAssignee = Pick<
  WorkOrder,
  | "accountableUserId"
  | "responsibleUserIds"
  | "informingUserIds"
  | "consultingUserIds"
>;
const WorkOrderEditAssignee: React.FC<WorkOrderEditAssigneeProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const [userOptions, setUserOptions] = React.useState(
    [] as EuiComboBoxOptionOption<string>[]
  );
  const [resetInputMode, setResetInputMode] = useState<{ reset: boolean }>({
    reset: false,
  });
  const [initialSectionData, setInitialSectionData] =
    useState<SectionDataAssignee>({});
  const [sectionData, setSectionData] = useState<SectionDataAssignee>({});
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSectionDataValid, setSectionDataValid] = useState<boolean>(true);
  const { data: companyUsers } = useWOGetCompanyUsersQuery({
    variables: {
      companyId: workOrder?.companyId,
    },
    fetchPolicy: "cache-and-network",
    skip: !workOrder?.companyId,
  });

  useEffect(() => {
    if (companyUsers?.workOrderCompanyUsers) {
      const users = companyUsers?.workOrderCompanyUsers.map((user) => {
        return { label: getUserLabel(user), value: user.id };
      });
      setUserOptions(users);
    } else {
      setUserOptions([]);
    }
  }, [companyUsers]);

  useEffect(() => {
    if (workOrder) {
      const {
        accountableUserId,
        consultingUserIds,
        responsibleUserIds,
        informingUserIds,
      } = workOrder || {};
      setSectionData(
        cloneDeep({
          accountableUserId,
          consultingUserIds: consultingUserIds,
          responsibleUserIds: responsibleUserIds,
          informingUserIds: informingUserIds,
        })
      );
      setInitialSectionData({
        accountableUserId,
        consultingUserIds: consultingUserIds,
        responsibleUserIds: responsibleUserIds,
        informingUserIds: informingUserIds,
      });
    }
  }, [workOrder]);

  const getUserLabel = (user: WOGetCompanyUsers): string => {
    return user.fullName || user.email || user.id;
  };

  const onDataChanged = (data: Partial<SectionDataAssignee>) => {
    const updatedSectionData = { ...sectionData, ...data };
    setSectionData(updatedSectionData);
  };

  const cancel = () => {
    setSectionData(cloneDeep(initialSectionData));
    setResetInputMode({ reset: false });
  };
  const save = () => {
    onWorkOrderChange(sectionData);
    setResetInputMode({ reset: false });
  };

  useEffect(() => {
    const isValid =
      sectionData.accountableUserId &&
      !!sectionData?.responsibleUserIds?.length;
    setSectionDataValid(isValid);
    const changed = !isEqual(initialSectionData, sectionData);
    setIsEditMode(changed);
  }, [sectionData]);

  useEffect(() => {
    changeIsEdit && changeIsEdit(isEditMode);
  }, [isEditMode, changeIsEdit]);

  return (
    <>
      {workOrder && companyUsers ? (
        <WorkOrderEditAssigneePresenter
          resetInputMode={resetInputMode}
          sectionData={sectionData}
          userOptions={userOptions}
          onWorkOrderChange={onDataChanged}
        >
          <WorkOrderEditSectionFooter
            disabled={!isSectionDataValid}
            cancel={cancel}
            save={save}
            visible={isEditMode}
          />
        </WorkOrderEditAssigneePresenter>
      ) : (
        <EuiFlexGroup
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <EuiLoadingSpinner size="xxl" />
        </EuiFlexGroup>
      )}
    </>
  );
};

export default WorkOrderEditAssignee;
