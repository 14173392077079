import pluralize from "pluralize";

export const GENERIC_ERROR_MESSAGE =
  "Uh oh, something went wrong. It's not you, it's us. We'll take a look and get it fixed in a jiffy. Please try again soon.";

export const GENERIC_SAVED_MESSAGE = "Changes saved!";

export const HIGHLIGHTED_ERROR_MESSAGE = "Please fix the highlighted errors";

export const EMAIL_REGEX_MESSAGE = "Please enter a valid email address";

export const PASSWORD_REGEX_MESSAGE =
  "Password should have one letter, one number, one special character and be at least 8 characters. No spaces allowed.";

export const RESET_TOKEN_EXPIRED = "Sorry, this link has expired.";

export const EMAIL_VERIFICATION_LINK_SENT =
  "Email verification link sent to your inbox";
export const EMAIL_VERIFIED = "Email verified!";

export const PHONE_VERIFIED = "Phone number verified!";

export const COUPON_INVALID = "Invalid coupon code";
export const PRIMARY_CARD_NO_DELETE =
  "Your primary payment method can't be deleted";
export const PRIMARY_PAYMENT_CHANGED = "Primary payment method changed";
export const BANK_ACCOUNT_DELETED = "ACH bank account deleted!";
export const CARD_DELETED = "Card deleted!";

export const TEAM_CREATE_MESSAGE = "Invite sent to";
export const TEAM_CREATE_RESEND = "Invite resent to ";
export const TEAM_USER_DELETED = "User deleted!";
export const TEAM_USER_LEFT = "Left from company!";

export const LOCATION_CREATED_MESSAGE = "Location added!";
export const SUBLOCATION_CREATED_MESSAGE = "Sublocation added!";
export const LOCATION_DELETED_MESSAGE = "Location deleted!";
export const FLOORPLAN_CREATED_MESSAGE = "Plan added!";
export const getFloorPlanCreatedMessage = (plans: number) =>
  `${pluralize(
    "plans",
    plans,
    true
  )} uploaded. We will generate files based on the uploaded ${pluralize(
    "plans",
    plans
  )} in background and notify once it's ready for you.`;
export const FLOORPLAN_FILE_UPDATED_MESSAGE =
  "Plan uploaded. We will generate file based on the uploaded plan in background and notify once it's ready for you.;";
export const FLOORPLAN_DELETED_MESSAGE = "Plan deleted!";
export const getFloorPlanDeleteMessage = (plans: number) =>
  `${pluralize("plan", plans, true)} deleted!`;
export const getFloorPlanMoveMessage = (plans: number) =>
  `${pluralize("plan", plans, true)} moved!`;

export const ASSET_CATEGORY_DELETED_MESSAGE = "Asset category deleted!";
export const ASSET_CATEGORY_CREATED_MESSAGE = "Asset category added!";
export const ASSET_CATEGORY_HIDDEN_MESSAGE = "Asset category hidden";
export const ASSET_CATEGORY_SHOWN_MESSAGE = "Asset category unhidden";

export const ASSET_TYPE_DELETED_MESSAGE = "Asset type deleted!";
export const ASSET_TYPE_ADDED_MESSAGE = "Asset type added!";
export const ASSET_TYPE_MOVED_MESSAGE = "Asset type moved!";
export const ASSET_TYPE_HIDDEN_MESSAGE = "Asset type hidden";
export const ASSET_TYPE_SHOWN_MESSAGE = "Asset type unhidden";
export const ASSET_TYPE_MAINTENANCE_CREATED_MESSAGE =
  "New default service event added";
export const ASSET_TYPE_MAINTENANCE_DELETED_MESSAGE =
  "Default service event deleted";

export const ASSET_CREATED_MESSAGE = "Asset added!";
export const ASSET_DELETED_MESSAGE = "Asset deleted!";
export const BREAKER_CREATED_MESSAGE = "Breaker added!";
export const BREAKER_DELETED_MESSAGE = "Breaker deleted!";
export const BREAKER_NO_SPACE_MESSAGE = "Breaker pole falls outside panel";
export const BREAKER_POLE_OVERLAP_MESSAGE =
  "Breaker pole overlap with another breaker";
export const ASSET_FILE_CREATED_MESSAGE = "Document added!";
export const ASSET_FILE_DELETED_MESSAGE = "Document deleted!";
export const ASSET_IMAGE_CREATED_MESSAGE = "Picture added!";
export const getAssetImageCreatedMessage = (images: number) =>
  `${pluralize("picture", images, true)} added!`;
export const getAssetFileCreatedMessage = (images: number) =>
  `${pluralize("document", images, true)} added!`;
export const ASSET_IMAGE_DELETED_MESSAGE = "Picture deleted!";
export const ASSET_IMAGE_REORDER_MESSAGE = "Pictures ordered!";
export const getAssetsDeletedMessage = (assets: number) =>
  `${pluralize("asset", assets, true)} deleted!`;
export const getAssetImageDeletedMessage = (assets: number) =>
  `${pluralize("picture", assets, true)} deleted!`;
export const getAssetFileDeletedMessage = (assets: number) =>
  `${pluralize("document", assets, true)} deleted!`;
export const getAssetContactUserCreatedMessage = (users: number) =>
  `${pluralize("contact user", users, true)} added!`;
export const ASSET_CONTACT_USER_DELETED_MESSAGE = "Contact user deleted!";
export const getAffectedAssetCreatedMessage = (assets: number) =>
  `${pluralize("fed to asset", assets, true)} added!`;
export const getAffectedByAssetCreatedMessage = (assets: number) =>
  `${pluralize("fed from asset", assets, true)} added!`;
export const AFFECTED_ASSET_DELETED_MESSAGE = "Fed to asset deleted!";
export const AFFECTED_BY_ASSET_DELETED_MESSAGE = "Fed from asset deleted!";
export const getAssetFloorPlanCreatedMessage = (floorPlans: number) =>
  `${pluralize("plan", floorPlans, true)} added!`;
export const ASSET_FLOOR_PLAN_DELETED_MESSAGE = "Asset removed from plan!";
export const ASSET_MAINTENANCE_CREATED_MESSAGE = "New service event added";
export const ASSET_MAINTENANCE_SCHEDULE_DELETED_MESSAGE =
  "Service event deleted";
export const ASSET_MAINTENANCE_DELETED_MESSAGE = "Service event series deleted";
export const ASSET_MAINTENANCE_NOTE_CREATED_MESSAGE = "Note added";
export const ASSET_MAINTENANCE_NOTE_DELETED_MESSAGE = "Note deleted!";
export const ASSET_MAINTENANCE_SNOOZE_REMINDER_MESSAGE = "New reminder added!";

export const FOLDER_CREATED_MESSAGE = "Folder added!";
export const FILE_DELETED_MESSAGE = "File deleted!";
export const FOLDER_DELETED_MESSAGE = "Folder deleted!";
export const SAME_FOLDER_MOVE_MESSAGE =
  "The destination folder is the same as selected.";
export const getFilesDeleteMessage = (plans: number) =>
  `${pluralize("file", plans, true)} deleted!`;
export const getItemsMoveMessage = (plans: number) =>
  `${pluralize("item", plans, true)} moved!`;
export const getItemsDeleteMessage = (plans: number) =>
  `${pluralize("item", plans, true)} deleted!`;

export const ALL_NOTIFICATIONS_READ = "All notifications marked as read!";
export const ALL_NOTIFICATIONS_DELETED = "All notifications deleted!";
export const UNREAD_NOTIFICATIONS_DELETED = "Unread notifications deleted!";
export const NOTIFICATION_DELETED = "Notification deleted!";

export const EMAIL_SENT = "Message sent!";

export const PLAN_EDITOR_PROMPT =
  "You have unsaved changes, are you sure you want to leave? Click 'cancel' and save changes if you don't want to loose the changes.";

export const OFFLINE_READY = "CriticalAsset is now offline ready!";

export const REPORT_IN_PROGRESS =
  "Your report is being generated and will be available shortly";
