import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
  useComboboxContext,
} from "@reach/combobox";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import OutsideAlerter from "../../../pages/workOrders/components/outsideClickAlerter";
import { sortCollator } from "../../../utils/sort";
import FormGroup from "../formGroup";

export type SelectComboboxOption = {
  label: ReactNode;
  value: string;
  query: string;
  disabled?: boolean;
};

export const enum SelectComboboxSizes {
  LARGE = "large",
  MEDIUM = "medium",
}

interface SelectComboboxProps {
  options: SelectComboboxOption[];
  name: string;
  label?: string;
  topLabel?: string;
  leftIcon?: ReactNode;
  changeOnBlur?: boolean;
  onBlur?: () => void;
  onScrollEnd?: () => void;
  error?: string;
  onChange?: (value: string | null) => void;
  valueFromParent?: string;
  sortOptions?: boolean;
  autoFocus?: boolean;
  noInput?: boolean;
  errorPositionAbsolute?: boolean;
  size?: SelectComboboxSizes;
}

const SelectCombobox: React.FC<SelectComboboxProps> = ({
  options,
  name,
  label,
  topLabel,
  leftIcon,
  onBlur,
  onScrollEnd,
  changeOnBlur,
  error,
  onChange,
  valueFromParent,
  sortOptions = false,
  autoFocus = false,
  noInput = false,
  errorPositionAbsolute,
  size = SelectComboboxSizes.LARGE,
}) => {
  const ELEMENTS_PER_PAGE = 6;
  const { setFieldValue, values, validateForm } = useFormikContext<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = React.useState("");
  const [initialValuePopulated, setInitialValuePopulated] =
    React.useState(false);
  const [value, setValue] = React.useState(
    valueFromParent || get(values, name)
  );
  const [page, setPage] = React.useState(1);
  const [filteredOptions, setFilteredOptions] = React.useState([] as any[]);

  const selectedOption = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  const handleChange = useCallback(
    (value: string | null) => {
      setValue(value);
      setFieldValue(name, value);
      validateForm().then(() => {
        if (onChange) onChange(value);
      });
      const selectedOption = options.find((option) => option.value === value);
      setQuery(selectedOption?.query || "");
      if (inputRef.current)
        inputRef.current.value = selectedOption?.query || "";
    },
    [name, onChange, options, setFieldValue, validateForm]
  );

  const handleQueryChange = useCallback(
    (newQuery: string) => {
      if (query === newQuery) return;
      setQuery(newQuery);
      if (!newQuery) {
        setValue(null);
        if (inputRef.current) inputRef.current.value = "";
      }
    },
    [query]
  );

  const handleOnBlur = useCallback(() => {
    if (changeOnBlur) {
      const selectedOption = options.find((option) => option.value === value);
      if (!selectedOption || selectedOption.query !== query) {
        setFieldValue(name, null);
        setValue(null);
        validateForm().then(() => {
          if (onChange) onChange(null);
          if (onBlur) onBlur();
        });
      }
    } else {
      if (onBlur) onBlur();
    }
  }, [
    changeOnBlur,
    name,
    onBlur,
    onChange,
    options,
    query,
    setFieldValue,
    validateForm,
    value,
  ]);

  const handleScroll = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      setPage(page + 1);
    }
  };

  const openList = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (autoFocus && !value) openList();
  }, [autoFocus, value]);

  React.useEffect(() => {
    let newFilteredOptions = options
      .filter((option) =>
        option.query.toLowerCase().includes(query.toLowerCase())
      )
      .slice(0, page * ELEMENTS_PER_PAGE);
    if (sortOptions)
      newFilteredOptions = newFilteredOptions.sort((a, b) =>
        sortCollator.compare(a.query, b.query)
      );
    setFilteredOptions(newFilteredOptions);
  }, [page, setFilteredOptions, options, query, sortOptions, value]);

  useEffect(() => {
    if (initialValuePopulated) return;
    if (valueFromParent === value) {
      setInitialValuePopulated(true);
    } else if (valueFromParent) {
      handleChange(valueFromParent);
      setInitialValuePopulated(true);
    }
  }, [handleChange, initialValuePopulated, value, valueFromParent]);

  return (
    <OutsideAlerter onBlur={handleOnBlur}>
      <Box
        className={"select-combobox_wrapper_" + size}
        css={css`
          &.select-combobox_wrapper_medium {
            & .input-wrapper {
              & .chakra-input__left-element,
              .chakra-input,
              .chakra-input__right-element {
                height: 47px !important;
              }

              & button {
                height: 1.5rem;
              }
            }

            & .chakra-form-control {
              padding-bottom: 8px;
            }
          }
        `}
      >
        <FormGroup
          label={label}
          name={name}
          labelUp={!!query || !!value ? true : undefined}
        >
          <Box fontSize="10" marginBottom="1" color="gray.700">
            {topLabel}
          </Box>
          <Combobox
            aria-label={label}
            openOnFocus
            onSelect={handleChange}
            className="input-wrapper"
          >
            <InputGroup>
              {leftIcon && (
                <InputLeftElement cursor="pointer">{leftIcon}</InputLeftElement>
              )}
              <ComboboxInput
                paddingLeft={leftIcon ? "12" : "4"}
                autocomplete={false}
                as={Input as any}
                autoComplete="off"
                onChange={(e: any) =>
                  handleQueryChange(e.currentTarget.value || "")
                }
                value={query}
                ref={inputRef}
              />
              <InputIcon openList={openList} />
            </InputGroup>
            <SelectedOption
              selectedOption={selectedOption}
              openList={openList}
              label={label}
              topLabel={!!topLabel}
              leftIcon={!!leftIcon}
            />
            <ComboboxPopover onScroll={handleScroll} portal={!!handleOnBlur}>
              <ComboboxList>
                {filteredOptions.map((option: any) =>
                  option.disabled ? (
                    <Box
                      padding="2"
                      fontSize="sm"
                      color="gray.700"
                      borderBottom="1px solid"
                      borderBottomColor="gray.100"
                      key={option.label}
                    >
                      {option.label}
                    </Box>
                  ) : (
                    <ComboboxOption key={option.value} value={option.value}>
                      {option.label}
                    </ComboboxOption>
                  )
                )}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
          <Box
            className={
              errorPositionAbsolute ? "chakra-form_error-absolute" : ""
            }
          >
            {error}
          </Box>
        </FormGroup>
      </Box>
    </OutsideAlerter>
  );
};

export default SelectCombobox;

const SelectedOption: React.FC<{
  selectedOption?: SelectComboboxOption;
  label?: string;
  topLabel?: boolean;
  openList: () => void;
  leftIcon?: boolean;
}> = ({ selectedOption, label, topLabel, openList, leftIcon }) => {
  const { isExpanded } = useComboboxContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (selectedOption && !isExpanded) {
      onOpen();
    } else {
      onClose();
    }
  }, [onOpen, onClose, selectedOption, isExpanded]);

  return (
    <>
      {isOpen && (
        <Box
          as="button"
          position="absolute"
          tabIndex={-1}
          top={label ? "4" : topLabel ? "8" : "3"}
          left={leftIcon ? "12" : "4"}
          backgroundColor="white"
          width="calc(100% - 50px)"
          height="8"
          zIndex="1"
          textAlign="left"
          onClick={openList}
          isTruncated
        >
          {selectedOption?.label}
        </Box>
      )}
    </>
  );
};

const InputIcon: React.FC<{ openList: () => void }> = ({ openList }) => {
  const { isExpanded } = useComboboxContext();

  return (
    <InputRightElement onClick={openList} cursor="pointer">
      <FontAwesomeIcon
        icon={isExpanded ? faChevronUp : faChevronDown}
        size="xs"
      />
    </InputRightElement>
  );
};
