import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { ICellEditorParams } from "ag-grid-community";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const AgGridNumberInput = memo(
  forwardRef((props: ICellEditorParams, ref) => {
    const [value, setValue] = useState(parseInt(props.value));
    const refInput = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
    }));

    const onChangeListener = useCallback((_, value) => setValue(value), []);
    const onKeyDownListener = useCallback(
      (event) => {
        if (event.keyCode === 13) {
          props.stopEditing();
        }
      },
      [props]
    );

    useEffect(() => refInput.current?.select(), []);

    return (
      <NumberInput
        onChange={onChangeListener}
        onKeyDown={onKeyDownListener}
        defaultValue={value}
        min={0}
        max={3}
      >
        <NumberInputField
          ref={refInput}
          maxHeight="42px"
          padding="0 0 0 4px"
          _focus={{
            boxShadow: "0 0 2px 0.1rem rgb(33 150 243 / 40%)",
            borderColor:
              "var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))",
          }}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    );
  })
);

export default AgGridNumberInput;
