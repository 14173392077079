import { useReactiveVar } from "@apollo/client";
import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckSquare,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq } from "lodash";
import { FC, Fragment, useCallback, useMemo } from "react";

import { AssetForMapFragmentFragment } from "../../../graphql/graphql";
import {
  changePlanEditorOptions,
  planEditorOptionsVar,
} from "../../../graphql/reactiveVariables";
import colors from "../../../theme/foundations/colors";
import { getAssetPartOptions } from "../../../utils/assetPart";
import AssetIcon from "../assetIcons";
import TooltipName from "../tooltipName";

interface MapAssetProps {
  asset: AssetForMapFragmentFragment;
}

const MapAsset: FC<MapAssetProps> = ({ asset }) => {
  const { assetIds, assetPartIds, assetColors, assetPartColors } =
    useReactiveVar(planEditorOptionsVar);
  const assetSelected = !!assetIds.length && assetIds.includes(asset.id);
  const onlyAssetSelected = assetSelected && !assetPartIds.length;
  const assetPartOptions = useMemo(
    () => (assetSelected ? getAssetPartOptions(asset, true) : []),
    [asset, assetSelected]
  );
  const color = assetColors[asset.id]
    ? assetColors[asset.id]
    : colors.secondary[500];

  const onAssetOrPartChange = useCallback(
    (assetId?: string, assetPartId?: string) => {
      let newAssetIds: string[] = [];
      let newAssetPartIds: string[] = [];

      if (!assetIds.length) {
        newAssetIds = assetId ? [assetId] : [];
        newAssetPartIds = assetId && assetPartId ? [assetPartId] : [];
      } else {
        if (assetId) {
          if (assetIds.find((aid) => aid === assetId)) {
            newAssetIds = assetIds.filter((aid) => aid !== assetId);
          } else {
            newAssetIds = uniq([...assetIds, assetId]);
          }
        } else {
          newAssetIds = assetIds;
        }

        if (newAssetIds && newAssetIds.length === 1) {
          if (!assetPartIds.length) {
            newAssetPartIds = assetPartId ? [assetPartId] : [];
          } else if (assetPartId) {
            if (assetPartIds.find((apid) => apid === assetPartId)) {
              newAssetPartIds = assetPartIds.filter(
                (apid) => apid !== assetPartId
              );
            } else {
              newAssetPartIds = uniq([...assetPartIds, assetPartId]);
            }
          } else {
            newAssetPartIds = assetPartIds;
          }
        }
      }

      changePlanEditorOptions({
        assetIds: newAssetIds,
        assetPartIds: newAssetPartIds,
      });
    },
    [assetIds, assetPartIds]
  );

  const handleSelectAllAssetParts = useCallback(() => {
    const assetPartIds = asset.assetParts.map((ap) => ap.id);
    const { assetPartPositions = {} } = asset.config;
    const newAssetPartIds = uniq(
      Object.entries(assetPartPositions)
        .filter(
          ([key, apid]) => !key.startsWith("-") && assetPartIds.includes(apid)
        )
        .map(([key, apid]: any) => apid)
    );
    changePlanEditorOptions({
      assetPartIds: newAssetPartIds,
    });
  }, [asset.assetParts, asset.config]);

  const handleSelectNoneAssetParts = useCallback(() => {
    changePlanEditorOptions({
      assetPartIds: [],
    });
  }, []);

  const showAssetParts =
    assetIds.length === 1 && assetSelected && assetPartOptions.length > 0;

  const allAssetPartsSelected = assetPartOptions.length === assetPartIds.length;
  const noAssetPartsSelected = !assetPartIds.length;

  return (
    <>
      <Button
        onClick={() => onAssetOrPartChange(asset.id)}
        variant="link"
        colorScheme="gray"
        color="gray.700"
        backgroundColor={assetSelected ? "gray.100" : "gray.50"}
        _hover={{ backgroundColor: "gray.100" }}
        width="full"
        justifyContent="flex-start"
        key={asset.id}
        borderRight="6px solid"
        borderRightColor={assetSelected ? color : "gray.50"}
        paddingY="2"
        marginY="1"
        position="relative"
        sx={{
          "&:after": showAssetParts
            ? {
                content: '""',
                position: "absolute",
                top: "100%",
                backgroundColor: "secondary.100",
                width: "1px",
                height: `${assetPartOptions.length * 53 + 9}px`,
                left: "7px",
              }
            : {},
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          width="full"
          paddingRight="2"
        >
          <AssetIcon
            iconName={asset.assetType.iconName}
            iconColor={asset.assetType.misc.resolvedIconColor}
            iconType={asset.assetType.iconType}
            iconSize="sm"
          />
          <TooltipName marginLeft="1" name={asset.name} />
          <Spacer />
          <Box color={assetSelected ? "secondary.500" : "gray.700"}>
            <FontAwesomeIcon
              icon={
                onlyAssetSelected
                  ? faCheckSquare
                  : assetSelected
                  ? faMinusSquare
                  : faSquare
              }
            />
          </Box>
        </Flex>
      </Button>
      {showAssetParts && (
        <>
          <Flex marginBottom="2" marginRight="14px" justifyContent="flex-end">
            <Button
              colorScheme="secondary"
              variant="link"
              rightIcon={
                <FontAwesomeIcon
                  icon={
                    allAssetPartsSelected
                      ? faCheckSquare
                      : noAssetPartsSelected
                      ? faSquare
                      : faMinusSquare
                  }
                />
              }
              onClick={
                allAssetPartsSelected
                  ? handleSelectNoneAssetParts
                  : handleSelectAllAssetParts
              }
            >
              <Box fontSize="sm">
                {allAssetPartsSelected ? "Unselect All" : "Select All"}
              </Box>
            </Button>
          </Flex>
          <Box paddingLeft="3" marginTop="-1" marginLeft="3" marginBottom="1">
            {assetPartOptions.map((apo) => {
              const assetPart = apo.assetPart;
              const isSelected =
                !!assetPart &&
                !!assetPartIds.length &&
                assetPartIds.includes(assetPart.id);
              return (
                <Fragment key={apo.value}>
                  {!!assetPart && (
                    <Button
                      onClick={() =>
                        onAssetOrPartChange(undefined, assetPart.id)
                      }
                      variant="link"
                      colorScheme="gray"
                      color="gray.700"
                      backgroundColor={isSelected ? "gray.100" : "gray.50"}
                      _hover={{ backgroundColor: "gray.100" }}
                      width="full"
                      justifyContent="flex-start"
                      key={assetPart.id}
                      marginTop="1"
                      paddingY="6px !important"
                      position="relative"
                      sx={{
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          top: "50%",
                          borderTop: "1px solid",
                          borderColor: "secondary.100",
                          width: "12px",
                          left: "-16px",
                        },
                      }}
                      borderRight="6px solid"
                      borderRightColor={
                        assetPart && assetPartColors[assetPart.id]
                          ? assetPartColors[assetPart.id]
                          : "gray.50"
                      }
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="flex-start"
                        width="full"
                        padding="2"
                      >
                        <TooltipName marginLeft="1" name={apo.label || ""} />
                        <Spacer />
                        <Box color="secondary.500">
                          <FontAwesomeIcon
                            icon={isSelected ? faCheckSquare : faSquare}
                          />
                        </Box>
                      </Flex>
                    </Button>
                  )}
                </Fragment>
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};

export default MapAsset;
