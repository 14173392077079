import React from "react";

export type BellProps = {
  style?: any;
  onClick?: () => void;
};

const Bell = ({ style, onClick }: BellProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    style={style}
    onClick={onClick}
  >
    <g clipPath="url(#clip0_1943_255044)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05381 21H22.1964C21.8687 19.7688 21.7066 18.0406 21.5881 15.6861C21.4662 13.2666 21.4076 12.3818 21.2553 11.114C21.2058 10.7019 21.1501 10.3059 21.0873 9.92287C20.5447 6.61384 18.4525 4.80926 14.5986 4.44434C14.7735 4.17192 14.875 3.84781 14.875 3.5C14.875 2.5335 14.0915 1.75 13.125 1.75C12.1585 1.75 11.375 2.5335 11.375 3.5C11.375 3.84782 11.4765 4.17194 11.6514 4.44438C7.7979 4.80945 5.7059 6.61402 5.16335 9.92287C5.10055 10.3059 5.04487 10.7019 4.99534 11.114C4.84302 12.3816 4.78438 13.2661 4.66246 15.6856C4.54379 18.0404 4.38156 19.7687 4.05381 21ZM23.0276 22.75H3.22242C2.68197 23.3682 1.93232 23.625 0.875 23.625C0.391751 23.625 0 23.2332 0 22.75C0 22.2668 0.391751 21.875 0.875 21.875C2.25068 21.875 2.66739 20.5046 2.91467 15.5975C3.03873 13.1357 3.09893 12.2276 3.25784 10.9052C3.31027 10.4689 3.36942 10.0483 3.43641 9.63971C4.02222 6.06704 6.14272 3.84072 9.66003 3.00288C9.90141 1.30533 11.3608 0 13.125 0C14.8892 0 16.3485 1.30525 16.5899 3.00272C20.1077 3.84044 22.2284 6.06681 22.8142 9.63971C22.8812 10.0483 22.9404 10.469 22.9928 10.9052C23.1517 12.2278 23.2119 13.1362 23.3358 15.5981C23.5829 20.5048 23.9995 21.875 25.375 21.875C25.8582 21.875 26.25 22.2668 26.25 22.75C26.25 23.2332 25.8582 23.625 25.375 23.625C24.3177 23.625 23.568 23.3682 23.0276 22.75ZM9.625 24.5H16.625C16.625 26.433 15.058 28 13.125 28C11.192 28 9.625 26.433 9.625 24.5Z"
        fill="#343741"
      />
    </g>
    <defs>
      <clipPath id="clip0_1943_255044">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Bell;
