import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React from "react";

import CheckBoxInput from "../checkbox";
import NumberInput from "../numberInput";
import SelectCombobox from "../selectCombobox";

interface MainBreakerInputProps {}

const MainBreakerInput: React.FC<MainBreakerInputProps> = () => {
  const { values } = useFormikContext<any>();
  return (
    <>
      <CheckBoxInput
        name="mainBreakerOrLug"
        label={
          <Flex alignItems="center">
            <Box marginRight="2">Main breaker?</Box>
            <Tooltip
              label="Check this box if this panel has a main circuit breaker"
              hasArrow
            >
              <Box color="gray.600">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Box>
            </Tooltip>
          </Flex>
        }
      />
      {!!values.mainBreakerOrLug && (
        <>
          <SelectCombobox
            label="Main Breaker Position"
            name="mainBreakerOrLugPosition"
            options={[
              { label: "Top", query: "Top", value: "top" },
              { label: "Bottom", query: "Bottom", value: "bottom" },
            ]}
          />
          <NumberInput
            label="Main Breaker Pole"
            name="mainBreakerOrLugPoles"
            defaultValue={1}
            min={1}
            max={10}
          />
        </>
      )}
    </>
  );
};

export default MainBreakerInput;
