import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { useCallback } from "react";

import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import { useMaintenanceScheduleInCompleteMutation } from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";

interface MaintenanceMarkAsInCompleteProps {
  maintenanceScheduleId: string;
  maintenanceResolvedName: string;
  maintenanceScheduleDateCopy: string;
  maintenanceLocationsCopy: string;
  onClose: () => void;
}

const MaintenanceMarkAsInComplete: React.FC<
  MaintenanceMarkAsInCompleteProps
> = ({
  maintenanceScheduleId,
  maintenanceResolvedName,
  maintenanceScheduleDateCopy,
  maintenanceLocationsCopy,
  onClose,
}) => {
  const toast = useToast();
  const [maintenanceScheduleInComplete] =
    useMaintenanceScheduleInCompleteMutation();
  const cancelRef1 = React.useRef<HTMLButtonElement>(null);

  const inCompleteMaintenanceSchedule = useCallback(async () => {
    try {
      await maintenanceScheduleInComplete({
        variables: { id: maintenanceScheduleId },
      });
      toast({
        description: GENERIC_SAVED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [maintenanceScheduleInComplete, maintenanceScheduleId, toast, onClose]);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef1}
      onClose={onClose}
      isOpen
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogBody textAlign="center">
            <Heading size="lg" mb="0">
              Mark the following event as incomplete?
            </Heading>
            <Text as="strong">{maintenanceResolvedName}</Text>
            <Text size="sm">{maintenanceLocationsCopy}</Text>
            <Text size="sm">{maintenanceScheduleDateCopy}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef1} onClick={onClose} width="48%">
              Cancel
            </Button>
            <Button
              onClick={inCompleteMaintenanceSchedule}
              colorScheme="red"
              ml="4%"
              width="48%"
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default MaintenanceMarkAsInComplete;

gql`
  mutation MaintenanceScheduleInComplete($id: UUID!) {
    maintenanceScheduleInComplete(id: $id) {
      ...MaintenanceScheduleFragment
    }
  }
`;
