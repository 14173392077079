import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useFolderQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface FolderUpdatePageProps {}

const FolderUpdatePage: React.FC<FolderUpdatePageProps> = () => {
  const { folderId } = useParams();
  const [searchParams] = useSearchParams();
  const targetFolder = searchParams.get("targetFolder");

  const { data } = useFolderQuery({
    variables: {
      id: targetFolder ? targetFolder : folderId,
    },
  });

  return data && folderId ? (
    <Presenter
      folderId={targetFolder || folderId}
      folder={data.folder}
      hasTargetFolder={!!targetFolder}
    />
  ) : null;
};

export default FolderUpdatePage;
