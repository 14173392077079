import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { layoutOptionsVar } from "../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface MaintenanceReportPageProps {}

const MaintenanceReportPage: React.FC<MaintenanceReportPageProps> = () => {
  useEffect(() => {
    layoutOptionsVar({
      title: "Service & Maintenance",
      hideSearch: true,
      styleProps: { paddingX: "0", pb: "0" },
      headerStyleProps: { paddingX: { base: "0", lg: "4" } },
      backLink: getRoutePath("reports"),
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Service &amp; Maintenance</title>
      </Helmet>
      <Presenter />
    </>
  );
};

export default MaintenanceReportPage;

gql`
  query ReportMaintenance(
    $filterBy: ReportMaintenanceFilterInput!
    $timezone: String
  ) {
    reportMaintenance(filterBy: $filterBy, timezone: $timezone)
  }
`;

gql`
  mutation CreateReportMaintenance(
    $fileType: ReportFileType!
    $filterBy: ReportMaintenanceFilterInput!
    $timezone: String
  ) {
    createReportMaintenance(
      fileType: $fileType
      filterBy: $filterBy
      timezone: $timezone
    )
  }
`;
