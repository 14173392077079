import { Box, Button } from "@chakra-ui/react";
import { utcToZonedTime } from "date-fns-tz";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import Autosuggest from "../../../components/elements/autosuggest";
import DatePicker from "../../../components/elements/datePickerField";
import FormGroup from "../../../components/elements/formGroup";
import FormSectionTitle from "../../../components/elements/formSectionTitle";
import MaintenanceIntervalField from "../../../components/elements/maintenanceIntervalField";
import MaintenanceRemindBeforeField from "../../../components/elements/maintenanceRemindBeforeField";
import TimezoneField from "../../../components/elements/timezoneField";
import {
  MaintenanceDurationType,
  MaintenanceWithContactsFragmentFragment,
} from "../../../graphql/graphql";
import { timezone } from "../../../utils/date/timezones";
import {
  MaintenanceFormData,
  maintenanceFormValidationSchema,
} from "../create/presenter";

interface MaintenanceEditPresenterProps {
  maintenance: MaintenanceWithContactsFragmentFragment;
  loading: boolean;
  handleSubmit: (
    values: MaintenanceFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  type: "full" | "recurring";
}

const MaintenanceEditPresenter: React.FC<MaintenanceEditPresenterProps> = ({
  maintenance,
  loading,
  handleSubmit,
  type,
}) => {
  return (
    <>
      <Helmet>
        <title>Edit Service Event</title>
      </Helmet>
      <Formik
        initialValues={{
          assetId: maintenance.assetId || undefined,
          name: maintenance.name || "",
          description: maintenance.description || "",
          startDateTime: utcToZonedTime(
            maintenance.startDateTime,
            maintenance.timezone
          ),
          timezone: maintenance.timezone || timezone,
          // TODO: default 1 min for now, we have to change to one hour later on
          durationType:
            maintenance.durationType || MaintenanceDurationType.Minutes,
          durationValue: maintenance.durationValue || 1,
          intervalType: maintenance.intervalType,
          intervalValue: maintenance.intervalValue,
          endDateTime: maintenance.endDateTime
            ? utcToZonedTime(maintenance.endDateTime, maintenance.timezone)
            : undefined,
          endAfter: maintenance.endAfter || undefined,
          remindBeforeType: maintenance.remindBeforeType,
          remindBeforeValue: maintenance.remindBeforeValue,
          companyUserIds: maintenance.maintenanceCompanyUsers
            ? maintenance.maintenanceCompanyUsers.map(
                (mcu) => mcu.companyUserId
              )
            : [],
        }}
        validationSchema={maintenanceFormValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form noValidate>
          {type === "full" && (
            <>
              <FormGroup label="Title" name="name">
                <Autosuggest
                  items={[
                    "Service reminder",
                    "Inspection",
                    "Tune up",
                    "Calibration",
                  ]}
                  autoComplete="off"
                  name="name"
                  autoFocus
                />
              </FormGroup>

              <FormSectionTitle marginBottom="2" mt="6">
                Reminders
              </FormSectionTitle>
              <DatePicker
                name="startDateTime"
                label="Send reminder on"
                showTimeSelect
                timeIntervals={15}
              />
              <TimezoneField />
              {/* <MaintenanceDurationField /> */}

              <Box as="fieldset">
                <MaintenanceRemindBeforeField />
              </Box>
            </>
          )}

          <Box as="fieldset" paddingTop="3" marginTop="3">
            <MaintenanceIntervalField />
          </Box>

          <Box marginTop="8">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      </Formik>
    </>
  );
};

export default MaintenanceEditPresenter;
