import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
  useComboboxContext,
} from "@reach/combobox";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { ReactNode } from "react";

import useDebounce from "../../../hooks/useDebounce";
import { sortCollator } from "../../../utils/sort";
import FormGroup from "../formGroup";
import Spinner from "../spinner";

export interface MultiSelectItem {
  value: string;
  label: ReactNode;
  query: string;
}

interface MultiSelectProps extends InputProps {
  items: MultiSelectItem[];
  name: string;
  label: string;
  loading?: boolean;
  handleQueryChange?: (query: string) => void;
  noResultLabel?: string;
  handleNoResultClick?: (value: string) => void;
  sortOptions?: boolean;
  autoFocus?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  items,
  name,
  label,
  loading,
  handleQueryChange,
  noResultLabel = "No results",
  handleNoResultClick,
  sortOptions = false,
  autoFocus = false,
}) => {
  const [query, setQuery] = React.useState("");
  const debouncedSearchTerm = useDebounce(query, 150);
  const [filteredItems, setFilteredItems] =
    React.useState<MultiSelectItem[]>(items);

  const { values, setFieldValue } = useFormikContext<any>();
  const value = React.useMemo(() => get(values, name, []), [values, name]);

  const onItemSelect = (selectedValue: string) => {
    setFieldValue(name, [...value, selectedValue]);
  };

  const onItemRemove = (selectedValue: string) => {
    setFieldValue(
      name,
      value.filter((i: any) => i !== selectedValue)
    );
  };

  React.useEffect(() => {
    let newItems: MultiSelectItem[];
    if (handleQueryChange || !debouncedSearchTerm) {
      newItems = items.filter((item) => !value.includes(item.value));
    } else {
      newItems = items.filter(
        (item) =>
          !value.includes(item.value) &&
          item.query.toLowerCase().startsWith(debouncedSearchTerm.toLowerCase())
      );
    }
    setFilteredItems(
      sortOptions
        ? newItems.sort((a, b) => sortCollator.compare(a.query, b.query))
        : newItems
    );
  }, [
    items,
    debouncedSearchTerm,
    setFilteredItems,
    value,
    handleQueryChange,
    sortOptions,
  ]);

  const selectedItems = React.useMemo(
    () => items.filter((item) => value.includes(item.value)),
    [value, items]
  );

  const onQueryChange = (query: string) => {
    if (handleQueryChange) handleQueryChange(query);
    setQuery(query);
  };

  return (
    <FormGroup label={label} name={name} labelUp={!!query}>
      <Combobox
        aria-label="Suggestions"
        openOnFocus
        onSelect={(value) => {
          onItemSelect(value);
          setQuery(" ");
          setTimeout(() => setQuery(""), 100);
        }}
        className="input-wrapper"
      >
        <InputGroup>
          <ComboboxInput
            autocomplete={false}
            as={Input as any}
            value={query}
            onChange={(e: any) => onQueryChange(e.target.value)}
            autoComplete="off"
            autoFocus={autoFocus && !value?.length}
          />
          <InputIcon loading={loading} />
        </InputGroup>
        <ComboboxPopover>
          <ComboboxList>
            {filteredItems.length > 0 ? (
              filteredItems.map((result) => (
                <ComboboxOption key={result.value} value={result.value}>
                  {result.label}
                </ComboboxOption>
              ))
            ) : handleNoResultClick ? (
              <ComboboxOption
                value={noResultLabel}
                onClick={() => {
                  if (!handleNoResultClick) return;
                  handleNoResultClick(debouncedSearchTerm);
                }}
              />
            ) : (
              <Box margin="2" fontSize="sm" color="gray.700">
                No results found
              </Box>
            )}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      {!!selectedItems && (
        <Wrap marginTop="2" spacing="2">
          {selectedItems.map((item) => (
            <WrapItem key={item.value}>
              <Tag borderRadius="0">
                <TagLabel>{item.label}</TagLabel>
                <TagCloseButton
                  onClick={() => onItemRemove(item.value)}
                  title={`Close ${item.query}`}
                />
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      )}
    </FormGroup>
  );
};

export default MultiSelect;

interface InputIconProps extends InputProps {
  loading?: boolean;
}

const InputIcon: React.FC<InputIconProps> = ({ loading }) => {
  const { isExpanded } = useComboboxContext();
  return (
    <InputRightElement>
      {loading ? (
        <Spinner />
      ) : (
        <FontAwesomeIcon
          icon={isExpanded ? faChevronUp : faChevronDown}
          size="xs"
        />
      )}
    </InputRightElement>
  );
};
