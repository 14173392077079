import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import gql from "graphql-tag";
import React from "react";
import { formatPhoneNumber } from "react-phone-number-input";

import {
  UserFragmentFragment,
  useUserPhoneVerifyMutation,
} from "../../../../../graphql/graphql";
import { setGenericMessage } from "../../../../../utils/serverErrors";
import { pinSchema, yupObject } from "../../../../../utils/validation";

interface VerifyPhonePageProps {
  onSubmit: () => void;
  setVerifyPage: (data: boolean) => void;
  user: UserFragmentFragment;
}

const VerifyPhonePage: React.FC<VerifyPhonePageProps> = ({
  user,
  onSubmit: handleSubmit,
  setVerifyPage,
}) => {
  const toast = useToast();
  const [userPhoneVerifyMutation, { loading }] = useUserPhoneVerifyMutation();

  const onSubmit = async (data: VerifyPhoneFormData) => {
    try {
      const { data: serverData, errors } = await userPhoneVerifyMutation({
        variables: { data: { pin: data.pin as number } },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else if (serverData) {
        handleSubmit();
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Box marginTop="10">
      <Formik
        initialValues={{
          pin: undefined,
        }}
        validationSchema={VerifyPhoneValidationSchema}
        onSubmit={onSubmit}
      >
        {({ getFieldProps }) => (
          <Form noValidate>
            <Input
              autoFocus
              type="number"
              autoComplete="one-time-code"
              {...getFieldProps("pin")}
            />
            <Box marginTop="10" marginBottom="6">
              <Button width="full" size="lg" type="submit" isLoading={loading}>
                Verify Phone Number
              </Button>
            </Box>
            <Box textAlign="center" marginBottom="6" fontSize="sm">
              This message was sent to {formatPhoneNumber(user.phone || "")}
              <Button
                onClick={() => setVerifyPage(false)}
                variant="link"
                colorScheme="secondary"
                fontSize="sm"
                display="block"
                margin="auto"
              >
                Use a different phone number
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default VerifyPhonePage;

const VerifyPhoneValidationSchema = yupObject().shape({
  pin: pinSchema,
});

type VerifyPhoneFormData = {
  pin?: number;
};

gql`
  mutation userPhoneVerify($data: UserPhoneVerifyInput!) {
    userPhoneVerify(data: $data) {
      ...UserFragment
    }
  }
`;
