import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import Confetti from "../../components/elements/confetti";
import Cup from "../../components/icons/cup";
import { currentCompanyPlanVar } from "../../graphql/reactiveVariables";

const PlanChangedModal: React.FC = () => {
  const currentCompanyPlan = useReactiveVar(currentCompanyPlanVar);
  const [, setSearchParams] = useSearchParams();

  const handleModalClose = () => {
    setSearchParams({});
  };

  return (
    <Modal isOpen onClose={handleModalClose} size="xl">
      <ModalOverlay zIndex={1800}>
        <ModalContent>
          <ModalCloseButton zIndex="1" />
          <ModalBody>
            <Confetti>
              <Box
                position="relative"
                textAlign="center"
                marginY="12"
                zIndex="1"
              >
                <Box marginBottom="6">
                  <Cup />
                </Box>
                <Text fontSize="3xl" lineHeight="10" marginBottom="2">
                  Success!
                </Text>
                <Text fontSize="xl" lineHeight="8" marginBottom="5">
                  You are now on the {currentCompanyPlan?.productName} Plan.
                </Text>
                <Text
                  marginBottom="6"
                  textAlign="center"
                  color="gray.300"
                  lineHeight="7"
                  fontSize="lg"
                >
                  <ChakraLink
                    href="mailto:support@criticalasset.com?subject=I need help with my new account"
                    color="secondary.500"
                    isExternal
                  >
                    Contact our Customer Success Team
                  </ChakraLink>{" "}
                  to learn all about your new plan.
                </Text>
                <Text color="gray.300">
                  P.S. We're sending you an invoice via email.
                </Text>
              </Box>
            </Confetti>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default PlanChangedModal;
