const FormError = {
  baseStyle: () => ({
    text: {
      fontSize: "xs",
      fontWeight: "light",
      marginTop: "1",
    },
  }),
};

export default FormError;
