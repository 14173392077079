import * as ApolloReactHooks from "@apollo/client";

import { WorkOrderComment } from "../../pages/workOrders/models/workOrder";
import { WOCommentCreateDocument } from "../fragments/workOrderComments";
import { defaultOptions } from "../graphql";
import { CommentWorkOrderCreateMutationVariables } from "../types/workOrdersMutations";

export function useCommentWorkOrderCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    { commentCreate: WorkOrderComment },
    CommentWorkOrderCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    { commentCreate: WorkOrderComment },
    CommentWorkOrderCreateMutationVariables
  >(WOCommentCreateDocument, options);
}
