import { Icon, IconProps } from "@chakra-ui/react";

const Image = (props: IconProps & any) => {
  return (
    <Icon width="12" height="auto" viewBox="0 0 48 48" {...props}>
      <rect x={6} y={10} width={36} height={28} rx={2} fill="#CCC" />
      <rect
        x={8.323}
        y={12.24}
        width={31.355}
        height={23.52}
        rx={1}
        fill="#fff"
      />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={8}
        y={12}
        width={32}
        height={24}
      >
        <rect
          x={8.323}
          y={12.24}
          width={31.355}
          height={23.52}
          rx={1}
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M8.223 31.724l6.726-6.487a2.383 2.383 0 013.284 0l2.2 2.123 7.51-7.243a2.383 2.383 0 013.285 0l9.83 9.482v6.161l-1.38 1.332H8.323L6.94 35.76v-2.8l1.282-1.236z"
          fill="#CCC"
        />
      </g>
      <ellipse cx={15.29} cy={17.84} rx={3.484} ry={3.36} fill="#F1F1F1" />
    </Icon>
  );
};

export default Image;
