import { Icon, IconProps } from "@chakra-ui/react";

const AHU = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M13.333 66.667c.003.002 101.334 0 101.334 0 4.41 0 8-3.59 8-8V32c0-4.41-3.59-8-8-8H13.333c-4.41 0-8 3.59-8 8v26.667c0 4.41 3.59 8 8 8Zm11.939-5.334 2.528-6.322a2.658 2.658 0 0 1 2.477-1.678h67.446c1.096 0 2.069.659 2.477 1.678l2.528 6.322H25.272ZM10.667 32a2.671 2.671 0 0 1 2.666-2.667h101.334A2.669 2.669 0 0 1 117.333 32v26.667a2.669 2.669 0 0 1-2.666 2.666h-6.195l-3.323-8.304A7.957 7.957 0 0 0 97.723 48H30.277a7.964 7.964 0 0 0-7.429 5.03l-3.32 8.303h-6.195a2.671 2.671 0 0 1-2.666-2.666V32ZM26.667 74.667v9.408c0 6.338-3.523 12.04-9.192 14.874a2.668 2.668 0 0 0 2.386 4.771C27.347 99.976 32 92.448 32 84.075v-9.408c-.016-3.48-5.317-3.494-5.333 0ZM50.667 74.667v9.408c0 6.338-3.523 12.04-9.192 14.874a2.668 2.668 0 0 0 2.386 4.771C51.347 99.976 56 92.448 56 84.075v-9.408c-.016-3.48-5.317-3.494-5.333 0ZM98.667 72A2.666 2.666 0 0 0 96 74.667v9.408c0 8.373 4.651 15.898 12.141 19.645a2.668 2.668 0 0 0 2.387-4.77c-5.672-2.838-9.195-8.537-9.195-14.875v-9.408A2.666 2.666 0 0 0 98.667 72ZM74.667 72A2.666 2.666 0 0 0 72 74.667v9.408c0 8.373 4.65 15.898 12.141 19.645a2.668 2.668 0 0 0 2.387-4.77c-5.672-2.838-9.195-8.537-9.195-14.875v-9.408A2.666 2.666 0 0 0 74.667 72Z"
      fill="currentColor"
    />
  </Icon>
);

export default AHU;
