import { Icon, IconProps } from "@chakra-ui/react";

const MowerLawn = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M99.338 75.714c-1.082 0-2.135.121-3.151.346a8.777 8.777 0 0 0-7.593-6.764l-3.899-.433V64.94a8.777 8.777 0 0 0-7.922-8.742L59.25 54.9c-5.207-.542-9.698 3.551-9.698 8.738v1.319l-6.51-.723-11.174-39.111A2.928 2.928 0 0 0 29.052 23H16.948a2.928 2.928 0 1 0 0 5.857h9.896L37.838 67.34v8.67a14.652 14.652 0 0 0-2.928-.295c-8.075 0-14.643 6.569-14.643 14.643 0 8.074 6.568 14.643 14.643 14.643 4.78 0 9.032-2.303 11.706-5.857h41.015c2.675 3.554 6.927 5.857 11.707 5.857 8.074 0 14.643-6.569 14.643-14.643 0-8.074-6.569-14.643-14.643-14.643ZM55.41 63.64a2.927 2.927 0 0 1 3.252-2.91l.106.01 17.51 1.296a2.925 2.925 0 0 1 2.56 2.906v3.271L55.41 65.61v-1.97Zm-20.5 35.504c-4.845 0-8.786-3.942-8.786-8.786 0-4.844 3.941-8.786 8.786-8.786 4.844 0 8.785 3.942 8.785 8.786 0 4.844-3.941 8.786-8.785 8.786Zm14.348-5.857c.193-.947.294-1.926.294-2.929 0-4.78-2.303-9.033-5.858-11.707l.001-.007V70.2l44.252 4.917a2.926 2.926 0 0 1 2.605 2.911v.615l.001.007c-3.555 2.674-5.858 6.927-5.858 11.707 0 1.003.102 1.982.295 2.929H49.258Zm50.08 5.857c-4.844 0-8.786-3.942-8.786-8.786 0-4.844 3.942-8.786 8.786-8.786 4.844 0 8.786 3.942 8.786 8.786 0 4.844-3.942 8.786-8.786 8.786Z"
      fill="currentColor"
    />
    <path
      d="M75.91 81.571H58.337a2.928 2.928 0 1 0 0 5.858H75.91a2.928 2.928 0 1 0 0-5.858ZM37.838 90.357a2.928 2.928 0 1 1-5.857 0 2.928 2.928 0 0 1 5.857 0ZM102.267 90.357a2.928 2.928 0 1 1-5.857 0 2.928 2.928 0 0 1 5.857 0Z"
      fill="currentColor"
    />
  </Icon>
);

export default MowerLawn;
