import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import Link from "../../components/elements/link";
import notAuthorized from "../../images/notAuthorized.png";
import { getRoutePath } from "../../router";

interface NotAuthorizedPagePresenterProps {
  isLoggedIn: boolean;
}

const NotAuthorizedPagePresenter: React.FC<NotAuthorizedPagePresenterProps> = ({
  isLoggedIn,
}) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    minHeight={{ base: "500px", md: "700px" }}
  >
    <Image src={notAuthorized} />
    <Text
      fontSize={{ base: "xl", md: "4xl" }}
      fontWeight="semibold"
      textAlign="center"
      width="70%"
      marginTop="5"
    >
      You are not authorized to view this page
    </Text>
    <Text color="gray.600" fontSize={{ base: "base", md: "2xl" }} marginTop="5">
      Please contact Admin / Owner for access
    </Text>
    <Link
      to={isLoggedIn ? getRoutePath("dashboard") : getRoutePath("signIn")}
      width="full"
      marginY="8"
      maxWidth="sm"
    >
      Back to {isLoggedIn ? "Dashboard" : "Sign in"}
    </Link>
  </Flex>
);

export default NotAuthorizedPagePresenter;
