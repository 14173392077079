import { FC } from "react";

import { useSessionQuery } from "../../../graphql/graphql";
import PageSpinner from "../../elements/pageSpinner";
import AuthLayout from "../auth";
import DashboardLayout from "../dashboard";

interface DashboardOrAuthProps {}

const DashboardOrAuth: FC<DashboardOrAuthProps> = ({ children }) => {
  const { data } = useSessionQuery();
  return data ? (
    data.session.isLoggedIn ? (
      <DashboardLayout>{children}</DashboardLayout>
    ) : (
      <AuthLayout>{children}</AuthLayout>
    )
  ) : (
    <PageSpinner />
  );
};

export default DashboardOrAuth;
