import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import { useFloorPlanIndexQuery } from "../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../graphql/reactiveVariables";
import ROLES from "../../roles";
import Presenter from "./presenter";

interface FloorPlansPageProps {}

const FloorPlansPage: React.FC<FloorPlansPageProps> = ({ children }) => {
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data, refetch: refetchFloorPlans } = useFloorPlanIndexQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("reload")) refetchFloorPlans();
  }, [searchParams, refetchFloorPlans]);

  useEffect(() => {
    layoutOptionsVar({
      title: "Plans",
      styleProps: { paddingX: "0" },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Plans</title>
      </Helmet>
      {data ? (
        <Presenter
          floorPlans={data.floorPlans}
          canAddPlan={ROLES.floorPlansCreate.includes(currentCompanyRole.role)}
          canEditPlan={ROLES.floorPlansEdit.includes(currentCompanyRole.role)}
          canDeletePlan={ROLES.floorPlansDelete.includes(
            currentCompanyRole.role
          )}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default FloorPlansPage;

gql`
  fragment FloorPlanIndexFragment on FloorPlan {
    ...FloorPlanFragment

    floorPlanAssetCategories {
      id
      assetCategoryId
    }

    location {
      id
      name
    }

    floorPlanAssets {
      id

      asset {
        id
        maintenanceStatus
      }
    }
  }

  query FloorPlanIndex {
    floorPlans {
      ...FloorPlanIndexFragment
    }
  }
`;
