import { useApolloClient, useReactiveVar } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { FC, useCallback, useEffect } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import PageSpinner from "./components/elements/pageSpinner";
import {
  useCurrentCompanyQuery,
  useSessionQuery,
  useUpdateCurrentCompanyMutation,
  useUserWithCompaniesQuery,
} from "./graphql/graphql";
import { currentCompanyRoleVar } from "./graphql/reactiveVariables";
import ROLES from "./roles";
import { getRoutePath } from "./router";
import { setRedirectUrl } from "./utils/redirection";

interface RouteAuthorizedProps {
  routeKey: string;
}

const RouteAuthorized: FC<RouteAuthorizedProps> = ({ routeKey, children }) => {
  const location = useLocation();
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data } = useSessionQuery();
  const { data: currentCompanyData } = useCurrentCompanyQuery();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();

  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");
  const { isOpen, onOpen } = useDisclosure({ defaultIsOpen: !companyId });

  // If companyId is passed as query params redirect to company
  const setCompanyId = useCallback(
    async (companyId: string) => {
      await updateCurrentCompany({
        variables: { id: companyId },
      });
      await client.resetStore();
      onOpen();
    },
    [client, onOpen, updateCurrentCompany]
  );

  useEffect(() => {
    if (!currentCompanyData) return;
    if (companyId && companyId !== currentCompanyData.currentCompany.id) {
      setCompanyId(companyId);
    } else {
      onOpen();
    }
  }, [companyId, currentCompanyData, onOpen, setCompanyId]);

  useEffect(() => {
    if (data && !data.session.isLoggedIn) {
      setRedirectUrl(`${location.pathname}${location.search}`);
    }
  }, [data, location.pathname, location.search]);

  if (!isOpen || !data || !currentCompanyData) {
    return <PageSpinner />;
  }

  if (!data.session.isLoggedIn) {
    return <Navigate to={getRoutePath("signIn")} />;
  }

  if (ROLES[routeKey] && !ROLES[routeKey].includes(currentCompanyRole.role)) {
    return <Navigate to={getRoutePath("notAuthorized")} />;
  }

  return (
    <RouteAuthorizedPresenter
      currentCompanyId={currentCompanyData.currentCompany.id}
    >
      {children}
    </RouteAuthorizedPresenter>
  );
};

export default RouteAuthorized;

const RouteAuthorizedPresenter: FC<{
  currentCompanyId?: string;
}> = ({ currentCompanyId, children }) => {
  const { data: userCompaniesData } = useUserWithCompaniesQuery();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();
  const [loading, setLoading] = React.useState(true);
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const checkAndSetCurrentCompany = async () => {
      if (!userCompaniesData) return;

      const companyPlanCreatePath = getRoutePath("companyPlanCreate");
      const companyCreatePath = getRoutePath("companyCreate");
      if (location.pathname === companyCreatePath) {
        setLoading(false);
        return;
      }

      const userCompanies = userCompaniesData.user.companyUsers.map(
        (cu) => cu.company
      );
      // No companies for this user, redirect to company creation page
      if (!userCompanies || !userCompanies.length) {
        await updateCurrentCompany();
        await client.resetStore();
        navigate(companyCreatePath);
      } else {
        const selectedCompany = currentCompanyId
          ? userCompanies.find((company) => company.id === currentCompanyId)
          : null;

        // No selected company
        if (!currentCompanyId || !selectedCompany) {
          const companyWithPlan = userCompanies[0];
          // Select first company with plan from companies list
          if (companyWithPlan) {
            await updateCurrentCompany({
              variables: { id: companyWithPlan.id },
            });
            await client.resetStore();

            // Select first company and go to plans page
          } else {
            await updateCurrentCompany({
              variables: { id: userCompanies[0].id },
            });
            await client.resetStore();
            if (location.pathname !== companyPlanCreatePath) {
              navigate(companyPlanCreatePath);
            }
          }

          // No plan for selected company, redirect to subscription page
        } else if (
          selectedCompany.isMainCompany &&
          !selectedCompany.planStripeId
        ) {
          if (location.pathname !== companyPlanCreatePath) {
            navigate(companyPlanCreatePath);
          }
        }
        setLoading(false);
      }
    };
    checkAndSetCurrentCompany();
  }, [
    client,
    currentCompanyId,
    location.pathname,
    navigate,
    updateCurrentCompany,
    userCompaniesData,
  ]);

  return loading ? <PageSpinner /> : <>{children}</>;
};

gql`
  query userWithCompanies {
    user {
      ...UserFragment
      companyUsers {
        ...CompanyUserWithCompanyFragment
      }
    }
  }
`;
