import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/link-error";
import { createUploadLink } from "apollo-upload-client";
import { persistCache } from "apollo3-cache-persist";

import {
  AppSettingsDocument,
  AssetCategoryConfigDocument,
  AssetConfigDocument,
  AssetTypeConfigDocument,
  CurrentCompanyDocument,
  DashboardConfigDocument,
  FloorPlanConfigDocument,
  FolderConfigDocument,
  LocationConfigDocument,
  MaintenanceConfigDocument,
  SessionDocument,
  SortBy,
  SortOrder,
} from "./graphql/graphql";
import resolvers from "./graphql/localResolvers";
import {
  categoriesTableKeysVar,
  isNativeWebViewVar,
  locationLastOpenedPlanKeysVar,
  locationTableKeysVar,
} from "./graphql/reactiveVariables";
import localforage from "./localforage";
import { getRoutePath } from "./router";
import { getCompanyId } from "./utils/companyId";
import { getJwt } from "./utils/jwt";

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      switch (error.extensions?.code) {
        case "UNAUTHENTICATED":
          if (error.message.includes("ERROR: ")) {
            // When unauthenticated and doesn't have error for use, redirect to sign out
            const signOutPath = getRoutePath("signOut");
            if (window.location.pathname !== signOutPath) {
              window.location.href = signOutPath;
            }
          }
          break;
        case "INTERNAL_SERVER_ERROR":
        case "BAD_USER_INPUT":
          if (
            ["Argument Validation Error", "Data passed is invalid"].includes(
              error.message
            )
          ) {
            const parsedErrors = JSON.parse(JSON.stringify(error));
            const validationErrors = parsedErrors?.extensions?.validationErrors;
            if (validationErrors) {
              const errors: any = {};
              validationErrors.forEach((error: any) => {
                errors[error.property] = Object.values(error.constraints).join(
                  ", "
                );
              });
              response = response || {};
              response.errors = errors;
            }
          }
          break;
      }
    });
  if (networkError)
    console.log(`[Network error]: ${JSON.stringify(networkError)}`);
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URL as string,
  options: {
    reconnect: true,
    lazy: true,
    timeout: 60000,
    inactivityTimeout: 30000,
    reconnectionAttempts: 25,
    connectionParams: () => ({
      authorization: `Bearer ${getJwt() || ""}`,
    }),
  },
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GQL_URL,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  // @ts-ignore
  uploadLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getJwt();
  const companyId = getCompanyId();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      company_id: companyId,
    },
  });

  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        asset: {
          read: (_, { args, toReference }) =>
            toReference({ __typename: "Asset", id: (args as any).id }),
        },
        assetCategory: {
          read: (_, { args, toReference }) =>
            toReference({ __typename: "AssetCategory", id: (args as any).id }),
        },
        assetType: {
          read: (_, { args, toReference }) =>
            toReference({ __typename: "AssetType", id: (args as any).id }),
        },
        file: {
          read: (_, { args, toReference }) =>
            toReference({ __typename: "File", id: (args as any).id }),
        },
        folder: {
          read: (_, { args, toReference }) =>
            toReference({ __typename: "Folder", id: (args as any).id }),
        },
        floorPlan: {
          read: (_, { args, toReference }) =>
            toReference({ __typename: "FloorPlan", id: (args as any).id }),
        },
        location: {
          read: (_, { args, toReference }) =>
            toReference({ __typename: "Location", id: (args as any).id }),
        },
      },
    },
  },
});

const initCache = () => {
  cache.writeQuery({
    query: SessionDocument,
    data: {
      session: {
        isLoggedIn: !!getJwt(),
      },
    },
  });

  cache.writeQuery({
    query: CurrentCompanyDocument,
    data: {
      currentCompany: {
        id: getCompanyId(),
      },
    },
  });

  if (!cache.readQuery({ query: AppSettingsDocument })) {
    // If can't read AppSettingsDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: AppSettingsDocument,
      data: {
        appSettings: {
          sideNavMinimized: false,
          plaidOpen: false,
        },
      },
    });
  }

  if (!cache.readQuery({ query: LocationConfigDocument })) {
    // If you can't read LocationConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: LocationConfigDocument,
      data: {
        locationConfig: {
          sortBy: SortBy.Name,
          sortOrder: SortOrder.Asc,
          activeTabIndex: 0,
          activeCollapsible: [],
        },
      },
    });
  }

  if (!cache.readQuery({ query: AssetConfigDocument })) {
    // If can't read AssetConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: AssetConfigDocument,
      data: {
        assetConfig: {
          sortBy: SortBy.Name,
          sortOrder: SortOrder.Asc,
          activeTabIndex: 0,
          displayType: "LIST",
          filter: [],
          filterCategoryId: "",
          activeCollapsible: ["Details"],
        },
      },
    });
  }

  if (!cache.readQuery({ query: MaintenanceConfigDocument })) {
    // If can't read MaintenanceConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: MaintenanceConfigDocument,
      data: {
        maintenanceConfig: {
          sortBy: SortBy.Name,
          sortOrder: SortOrder.Asc,
          displayType: "CALENDAR",
          filterStatus: [],
          filterCategoryId: "",
        },
      },
    });
  }

  if (!cache.readQuery({ query: AssetCategoryConfigDocument })) {
    // If can't read AssetCategoryConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: AssetCategoryConfigDocument,
      data: {
        assetCategoryConfig: {
          sortBy: SortBy.Name,
          sortOrder: SortOrder.Asc,
          displayType: "GRID",
          showHidden: false,
        },
      },
    });
  }

  if (!cache.readQuery({ query: AssetTypeConfigDocument })) {
    // If can't read AssetTypeConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: AssetTypeConfigDocument,
      data: {
        assetTypeConfig: {
          sortBy: SortBy.Name,
          sortOrder: SortOrder.Asc,
          displayType: "GRID",
        },
      },
    });
  }

  if (!cache.readQuery({ query: FolderConfigDocument })) {
    // If can't read FolderConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: FolderConfigDocument,
      data: {
        folderConfig: {
          sortBy: SortBy.Name,
          sortOrder: SortOrder.Asc,
          displayType: "GRID",
        },
      },
    });
  }

  if (!cache.readQuery({ query: FloorPlanConfigDocument })) {
    // If can't read FloorPlanConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: FloorPlanConfigDocument,
      data: {
        floorPlanConfig: {
          sortBy: SortBy.Name,
          sortOrder: SortOrder.Asc,
          displayType: "LIST",
          filterCategoryId: "",
        },
      },
    });
  }

  if (!cache.readQuery({ query: DashboardConfigDocument })) {
    // If can't read DashboardConfigDocument (it is not set) query set it to initial values
    cache.writeQuery({
      query: DashboardConfigDocument,
      data: {
        dashboardConfig: {
          filter: ["PAST_DUE", "DUE_IN_30"],
        },
      },
    });
  }
};

const getClient = async () => {
  await persistCache({
    cache,
    storage: localforage as any,
    maxSize: false,
  });

  const client = new ApolloClient({
    cache,
    link: concat(authMiddleware, concat(errorLink, splitLink)),
    resolvers,
  });

  initCache();
  client.onClearStore(handleStoreClear);
  client.onResetStore(handleStoreClear);
  return client;
};

export default getClient;

const handleStoreClear = async () => {
  sessionStorage.clear();
  locationTableKeysVar({});
  locationLastOpenedPlanKeysVar({});
  categoriesTableKeysVar({});
  isNativeWebViewVar(false);
  initCache();
};
