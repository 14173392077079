import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Billing = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 24 24" boxSize="24px" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 7.02944 7.02944 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM14.25 10.425C14.4985 10.425 14.7 10.2235 14.7 9.975V9.3C14.7 8.30589 13.8941 7.5 12.9 7.5V7.05C12.9 6.80147 12.6985 6.6 12.45 6.6H11.55C11.3015 6.6 11.1 6.80147 11.1 7.05V7.5C10.1059 7.5 9.3 8.30589 9.3 9.3V10.029C9.30135 10.5659 9.54227 11.0741 9.957 11.415L13.044 13.962C13.0983 14.0016 13.1287 14.066 13.125 14.133V14.925H10.875V14.025C10.875 13.7765 10.6735 13.575 10.425 13.575H9.75C9.50147 13.575 9.3 13.7765 9.3 14.025V14.7C9.3 15.6941 10.1059 16.5 11.1 16.5V16.95C11.1 17.1985 11.3015 17.4 11.55 17.4H12.45C12.6985 17.4 12.9 17.1985 12.9 16.95V16.5C13.8941 16.5 14.7 15.6941 14.7 14.7V13.971C14.6987 13.4341 14.4577 12.9259 14.043 12.585L10.956 10.038C10.9017 9.99842 10.8713 9.93405 10.875 9.867V9.075H13.125V9.975C13.125 10.2235 13.3265 10.425 13.575 10.425H14.25Z"
    />
  </Icon>
);

export default Billing;
