import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { useEffect } from "react";

import {
  useWOGetOrderTypesQuery,
  useWOGetServiceCategoriesQuery,
  useWOGetWorkOrdersQuery,
} from "../../../../../graphql/queries/workOrders";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateServicesServicePlanPresenter from "./presenter";

interface WorkOrderCreateServicesServicePlanProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateServicesServicePlanInfo: React.FC<
  WorkOrderCreateServicesServicePlanProps
> = ({ workOrder, onWorkOrderChange }) => {
  const [orderTypesOption, setOrderTypesOption] = React.useState<
    EuiSuperSelectOption<string>[]
  >([]);
  const [workOrdersOption, setWorkOrdersOption] = React.useState<
    EuiComboBoxOptionOption<string>[]
  >([]);
  const [serviceCategoriesOption, setServiceCategoriesOption] = React.useState<
    EuiSuperSelectOption<string>[]
  >([]);

  const defaultOrderTypeLabel = "New";

  const { data: workOrdersData } = useWOGetWorkOrdersQuery({
    variables: {
      companyId: workOrder?.companyId,
    },
    fetchPolicy: "cache-and-network",
    skip: !workOrder?.companyId,
  });
  const { data: orderTypes } = useWOGetOrderTypesQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: serviceCategories } = useWOGetServiceCategoriesQuery({
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let newOrderTypesOptions: EuiSuperSelectOption<string>[] = [];
    if (orderTypes?.orderTypes?.length) {
      newOrderTypesOptions = orderTypes.orderTypes.map((orderType) => {
        const disabled =
          !workOrdersOption?.length &&
          orderType.name?.toLowerCase() === "revisit";
        const element = disabled ? (
          <>
            <strong
              style={{ display: "block" }}
              title={
                "Need to create at least one work order to activate this order type"
              }
            >
              {orderType.name}
            </strong>
          </>
        ) : null;
        return {
          inputDisplay: orderType.name,
          value: orderType.id,
          disabled,
          dropdownDisplay: element,
        };
      });
    }
    setOrderTypesOption(newOrderTypesOptions);
  }, [orderTypes, workOrdersOption]);

  useEffect(() => {
    if (orderTypesOption.length && orderTypes?.orderTypes?.length) {
      const selectedOrderType = orderTypes.orderTypes.find(
        (orderType) => orderType.name === "New"
      );
      selectedOrderType &&
        onWorkOrderChange({
          ...workOrder,
          orderTypeId: selectedOrderType.id,
        });
    }
  }, [orderTypesOption]);

  useEffect(() => {
    let newServiceCategories: EuiSuperSelectOption<string>[] = [];
    if (serviceCategories?.serviceCategories?.length) {
      newServiceCategories = serviceCategories.serviceCategories.map(
        (serviceCategory) => {
          return {
            inputDisplay: serviceCategory.name,
            value: serviceCategory.id,
          };
        }
      );
    }
    setServiceCategoriesOption(newServiceCategories);
  }, [serviceCategories]);

  useEffect(() => {
    if (orderTypesOption?.length && !workOrder.orderTypeId) {
      const selectedOption = orderTypesOption.find(
        (option) => option.inputDisplay === defaultOrderTypeLabel
      );
      selectedOption &&
        onWorkOrderChange({
          ...workOrder,
          orderTypeId: selectedOption.value,
        });
    }
  }, [onWorkOrderChange, orderTypesOption, workOrder, workOrder.orderTypeId]);

  useEffect(() => {
    let workOrders: EuiComboBoxOptionOption<string>[] = [];
    if (workOrdersData?.workOrders?.workOrders?.length) {
      workOrders = workOrdersData.workOrders.workOrders.map((workOrder) => {
        return {
          label: `${workOrder.key} | ${workOrder.name}` || "",
          value: workOrder.id,
        };
      });
    }
    setWorkOrdersOption(workOrders);
  }, [workOrdersData]);

  return (
    <>
      <WorkOrderCreateServicesServicePlanPresenter
        workOrder={workOrder}
        workOrders={workOrdersData?.workOrders?.workOrders}
        onWorkOrderChange={onWorkOrderChange}
        workOrdersOptions={workOrdersOption}
        orderTypesOption={orderTypesOption}
        serviceCategoriesOption={serviceCategoriesOption}
      />
    </>
  );
};

export default WorkOrderCreateServicesServicePlanInfo;
