import { Box, Button, MenuButton, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";

const StyledBox = styled(Box)`
  .chakra-menu__menu-button span,
  .chakra-menu__menuitem {
    display: block;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .chakra-button__icon {
    flex-shrink: 0;
    flex-grow: 0 !important;
  }
`;

interface LabeledMenuButtonProps {
  label?: string;
  value: ReactNode | undefined;
  isSelect?: boolean;
  isOpen?: boolean;
  styleProps?: object;
  error?: boolean;
}

const LabeledMenuButton: React.FC<LabeledMenuButtonProps> = ({
  label = "Sort",
  value,
  isSelect = false,
  isOpen,
  styleProps,
  error,
  ...props
}) => {
  return (
    <StyledBox>
      <MenuButton
        as={Button}
        isActive={isOpen}
        variant="ghost"
        rightIcon={
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            size="xs"
          />
        }
        fontWeight="400"
        colorScheme="gray"
        paddingY="0"
        paddingBottom="1"
        paddingX="4"
        height="14"
        border="1px solid"
        borderColor={error ? "red.500" : "gray.500"}
        boxShadow={error ? "error" : "none"}
        textAlign="left"
        minWidth="32"
        width="full"
        maxWidth={{ base: "auto", md: "40" }}
        _focus={{ borderColor: "primary.500" }}
        _active={{ borderColor: "primary.500" }}
        _hover={{ backgroundColor: "transparent" }}
        role="select"
        textTransform="none"
        {...styleProps}
        {...props}
      >
        {!!label && (
          <Text
            fontSize={value ? (isSelect ? "xs" : ".65rem") : "base"}
            fontWeight={value ? "500" : "normal"}
            color="gray.600"
            marginBottom={value ? "1" : "0"}
            position={value ? "absolute" : "static"}
            top="2px"
            left="4"
          >
            {label}
          </Text>
        )}
        {!!value && (
          <Text as="span" paddingTop={label ? "4" : "0"}>
            {value}
          </Text>
        )}
      </MenuButton>
    </StyledBox>
  );
};

export default LabeledMenuButton;
