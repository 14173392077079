import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import {
  useAssetFilesSignedUrlLazyQuery,
  useFileSignedUrlLazyQuery,
} from "../../../graphql/graphql";

interface FileDownloadProps {}

const FileDownload: React.FC<FileDownloadProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const fileId = searchParams.get("fileId");
  const assetId = searchParams.get("assetId");
  const assetFileId = searchParams.get("assetFileId");
  const [fileDownloadQuery] = useFileSignedUrlLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [assetFileDownloadQuery] = useAssetFilesSignedUrlLazyQuery({
    fetchPolicy: "no-cache",
  });

  const openSignedUrl = useCallback(
    (signedUrl: string) => {
      window.open(signedUrl);
      setSearchParams({});
    },
    [setSearchParams]
  );

  React.useEffect(() => {
    const download = async () => {
      const { data } = await fileDownloadQuery({ variables: { id: fileId } });
      if (data) openSignedUrl(data.file.signedUrl);
    };
    if (fileId) download();
  }, [fileId, fileDownloadQuery, openSignedUrl]);

  React.useEffect(() => {
    const download = async () => {
      const { data } = await assetFileDownloadQuery({
        variables: { id: assetId },
      });
      if (data) {
        const assetFile = data.asset.assetFiles.find(
          (af) => af.id === assetFileId
        );
        if (assetFile) openSignedUrl(assetFile.signedUrl);
      }
    };
    if (assetId && assetFileId) download();
  }, [assetId, assetFileId, assetFileDownloadQuery, openSignedUrl]);

  return null;
};

export default FileDownload;

gql`
  query FileSignedUrl($id: UUID!) {
    file(id: $id) {
      id
      signedUrl
    }
  }

  query AssetFilesSignedUrl($id: UUID!) {
    asset(id: $id) {
      assetFiles {
        id
        signedUrl
      }
    }
  }
`;
