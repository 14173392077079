import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useFlexLayout, useTable } from "react-table";

import LabeledMenuButton from "../../components/elements/labeledMenuButton";
import Link from "../../components/elements/link";
import MobileAccordion from "../../components/elements/mobileAccordion";
import UserCard from "../../components/elements/userCard";
import AddCircle from "../../components/icons/addCircle";
import AdminIcon from "../../components/icons/admin";
import FlagIcon from "../../components/icons/flag";
import Manager from "../../components/icons/manager";
import UserIcon from "../../components/icons/user";
import {
  CompanyUserRole,
  CompanyUserWithUserFragmentFragment,
  SortBy,
  SortOrder,
  UserFragmentFragment,
} from "../../graphql/graphql";
import { CurrentCompanyRole } from "../../graphql/types";
import { getRoutePath } from "../../router";
import { formatDateFromSecondsTimestamp } from "../../utils/date";
import { sortByOptions, sortCollator } from "../../utils/sort";
import CompanyTeamDelete from "./delete";
import CompanyTeamResend from "./resend";

interface CompanyTeamPagePresenterProps {
  companyUsers: CompanyUserWithUserFragmentFragment[];
  currentUser: UserFragmentFragment;
  canAddCompanyUser: boolean;
  canEditCompanyUser: boolean;
  currentCompanyRole: CurrentCompanyRole;
}

const CompanyTeamPagePresenter: React.FC<CompanyTeamPagePresenterProps> = ({
  companyUsers,
  currentUser,
  canAddCompanyUser,
  canEditCompanyUser,
  currentCompanyRole,
}) => {
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const [selectedSort, setSelectedSort] = React.useState<SortOrder>(
    SortOrder.Asc
  );
  const navigate = useNavigate();

  const currentUserHasPermission = React.useCallback(
    (companyUser: CompanyUserWithUserFragmentFragment) => {
      if (!canAddCompanyUser) return false;
      if (
        companyUser.role === CompanyUserRole.Owner &&
        !currentCompanyRole.isOwner
      )
        return false;
      if (
        companyUser.role === CompanyUserRole.Admin &&
        currentCompanyRole.isManager
      )
        return false;
      return true;
    },
    [currentCompanyRole, canAddCompanyUser]
  );

  const handleClick = React.useCallback(
    (companyUser: CompanyUserWithUserFragmentFragment) => {
      if (!currentUserHasPermission(companyUser)) return;
      navigate(getRoutePath("companyTeamsEdit", { teamId: companyUser.id }));
    },
    [currentUserHasPermission, navigate]
  );

  const handleFilterChange = React.useCallback(
    (value) => {
      setSelectedFilter(value || "");
    },
    [setSelectedFilter]
  );

  const handleSortChange = React.useCallback(
    (sort: any) => {
      setSelectedSort(sort);
    },
    [setSelectedSort]
  );

  const selectedCompanyUsers = React.useMemo(() => {
    const filteredCompanyUsers = selectedFilter
      ? companyUsers.filter((cu) => cu.role === selectedFilter)
      : companyUsers;
    const [sortBy, sortOrder] = selectedSort.split("-");
    const sortedCompanyUsers = filteredCompanyUsers
      .slice()
      .sort((a, b) =>
        sortCollator.compare(
          sortBy === SortBy.CreatedAt
            ? a.createdAt
            : a.user.fullName || a.user.email,
          sortBy === SortBy.CreatedAt
            ? b.createdAt
            : b.user.fullName || b.user.email
        )
      );
    return sortOrder === SortOrder.Desc
      ? sortedCompanyUsers.reverse()
      : sortedCompanyUsers;
  }, [companyUsers, selectedFilter, selectedSort]);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: (companyUser: CompanyUserWithUserFragmentFragment) =>
          companyUser,
        id: "details",
        width: 300,
        Cell: ({ value }: { value: CompanyUserWithUserFragmentFragment }) => (
          <Button
            onClick={() => handleClick(value)}
            variant="unstyled"
            cursor={currentUserHasPermission(value) ? "pointer" : "not-allowed"}
            aria-label="View Team Member"
          >
            <UserCard user={value.user} />
          </Button>
        ),
      },
      {
        Header: "",
        accessor: (companyUser: CompanyUserWithUserFragmentFragment) =>
          companyUser,
        id: "actions",
        width: 300,
        Cell: ({ value }: { value: CompanyUserWithUserFragmentFragment }) => (
          <>
            <Flex
              alignItems="center"
              justifyContent={{ base: "flex-start", md: "flex-end" }}
              marginTop={{ base: "4", md: 0 }}
            >
              {currentUser.id !== value.user.id ? (
                value.role === CompanyUserRole.Manager ? (
                  <Manager boxSize="4" />
                ) : value.role === CompanyUserRole.Admin ? (
                  <AdminIcon boxSize="4" />
                ) : (
                  <UserIcon boxSize="4" />
                )
              ) : (
                <FlagIcon />
              )}
              <Text marginX="2" fontSize="sm" textTransform="capitalize">
                {currentUser.id === value.user.id ? (
                  `${value.role.toLowerCase().replace("_", " ")} (Me)`
                ) : (
                  <Text as="span" color="gray.600">
                    {value.role.toLowerCase().replace("_", " ")}
                  </Text>
                )}
              </Text>
              {!value.user.registered && canEditCompanyUser && (
                <>
                  <Box
                    as="span"
                    fontSize="xs"
                    fontWeight="semibold"
                    backgroundColor="green.500"
                    marginLeft={{ base: 0, md: "3" }}
                    padding="4px 12px"
                    borderRadius="lg"
                    color="white"
                  >
                    {!value.user.registered &&
                      `Invited on ${formatDateFromSecondsTimestamp(
                        value.createdAt / 1000
                      )}`}
                  </Box>
                  <Box as="span" marginLeft="3">
                    <CompanyTeamResend companyUser={value} />
                  </Box>
                </>
              )}
              {(canEditCompanyUser || currentUser.id === value.user.id) && (
                <Box
                  as="span"
                  paddingTop="2"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <CompanyTeamDelete companyUser={value} />
                </Box>
              )}
            </Flex>
          </>
        ),
      },
    ],
    [currentUser.id, canEditCompanyUser, currentUserHasPermission, handleClick]
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable(
    { columns, data: selectedCompanyUsers } as any,
    useFlexLayout
  );

  return (
    <Box>
      <Flex
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        marginBottom="6"
      >
        <MobileAccordion>
          <Flex gap="2" wrap={{ base: "wrap", md: "nowrap" }}>
            <Box>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <LabeledMenuButton
                      label="Show"
                      isOpen={isOpen}
                      value={selectedFilter || "All"}
                    />
                    <MenuList>
                      {roleFilterOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => handleFilterChange(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>

            <Box>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <LabeledMenuButton
                      isOpen={isOpen}
                      value={
                        sortByOptions.find((s) => s.value === selectedSort)
                          ?.label || "A-Z"
                      }
                    />
                    <MenuList>
                      {sortByOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => handleSortChange(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
          </Flex>
        </MobileAccordion>
        <Flex width={{ base: "auto", md: "40" }}>
          <Text fontSize={{ base: "base", md: "xl" }} mr="2">
            {pluralize("Member", companyUsers.length || 0, true)}
          </Text>
          {canAddCompanyUser && (
            <Tooltip label="Invite people" hasArrow placement="left">
              <Link
                to={getRoutePath("companyTeamsCreate")}
                aria-label="Invite people"
                variant="icon"
                colorScheme="secondary"
              >
                <AddCircle boxSize="32px" />
              </Link>
            </Tooltip>
          )}
        </Flex>
      </Flex>
      <Box width="full" overflowX="auto">
        {selectedCompanyUsers.length ? (
          <Box {...getTableProps()}>
            <Box {...getTableBodyProps()} paddingBottom="4">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Box
                    alignItems={{ base: "flex-start", md: "center" }}
                    marginTop="3"
                    padding="4"
                    backgroundColor="secondary.10"
                    _hover={{ boxShadow: "md" }}
                    cursor={
                      currentUserHasPermission(
                        row.original as CompanyUserWithUserFragmentFragment
                      )
                        ? "pointer"
                        : ""
                    }
                    role="button"
                    onClick={() =>
                      handleClick(
                        row.original as CompanyUserWithUserFragmentFragment
                      )
                    }
                    {...row.getRowProps()}
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    {row.cells.map((cell) => (
                      <Box {...cell.getCellProps()} wordBreak="break-all">
                        {cell.render("Cell")}
                      </Box>
                    ))}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Center height="100px" color="gray.700">
            You don't have any team members with {selectedFilter} role
          </Center>
        )}
      </Box>
    </Box>
  );
};

export default CompanyTeamPagePresenter;

const roleFilterOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Owner",
    value: CompanyUserRole.Owner,
  },
  {
    label: "Admin",
    value: CompanyUserRole.Admin,
  },
  {
    label: "Manager",
    value: CompanyUserRole.Manager,
  },
  {
    label: "User",
    value: CompanyUserRole.User,
  },
  {
    label: "View Only",
    value: CompanyUserRole.ViewOnly,
  },
];
