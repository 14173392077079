import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import {
  useCompanyQuery,
  useCompanyUpdateMutation,
} from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import OnboardingPresenter from "./presenter";

interface OnboardingPageProps {
  showSkip?: boolean;
  showComplete?: boolean;
}

const OnboardingPage: FC<OnboardingPageProps> = ({
  showSkip = false,
  showComplete = true,
}) => {
  const { data: companyData } = useCompanyQuery();
  const [companyUpdateMutation] = useCompanyUpdateMutation();

  const handleCompanyConfigChange = async (config: any) => {
    if (!companyData) return;

    try {
      await companyUpdateMutation({
        variables: { data: { config, name: companyData.company.name } },
      });
    } catch (error) {}
  };

  useEffect(() => {
    layoutOptionsVar({
      title: "Company Setup",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Company Setup</title>
      </Helmet>
      {companyData ? (
        <OnboardingPresenter
          company={companyData.company}
          showSkip={showSkip}
          showComplete={showComplete}
          handleCompanyConfigChange={handleCompanyConfigChange}
        />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default OnboardingPage;
