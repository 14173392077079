import "./index.scss";

import { useReactiveVar } from "@apollo/client";
import {
  add,
  addDays,
  endOfDay,
  endOfMonth,
  format,
  getDay,
  getHours,
  getMinutes,
  isBefore,
  isPast,
  parse,
  setHours,
  setMinutes,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import React, { useCallback, useEffect } from "react";
import {
  DateRangeFormatFunction,
  Formats,
  Calendar as ReactBigCalendar,
  CalendarProps as ReactBigCalendarProps,
  dateFnsLocalizer,
} from "react-big-calendar";
import { useParams, useSearchParams } from "react-router-dom";

import {
  MaintenanceIndexFragmentFragment,
  MaintenanceStatusType,
  useMaintenanceConfigQuery,
  useUpdateMaintenanceConfigMutation,
} from "../../../graphql/graphql";
import {
  calendarOptionsVar,
  currentCompanyRoleVar,
} from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import { generateRruleSetForMaintenance } from "../../../utils/date/rrule";
import CalendarEvent, { CriticalAssetEvent } from "./event";

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { "en-US": require("date-fns/locale/en-US") },
});

const rangeDateFormatter: DateRangeFormatFunction = (
  { start, end },
  culture,
  localizer
) =>
  localizer
    ? `${localizer.format(
        start,
        "MMM d, yyyy",
        culture || ""
      )} — ${localizer.format(end, "MMM d, yyyy", culture || "")}`
    : "";

const formats: Formats = {
  dateFormat: "d",
  dayFormat: "EEE, MMM d",
  agendaDateFormat: "EEE, MMM d",
  dayHeaderFormat: "EEE, MMM d, yyyy",
  agendaHeaderFormat: rangeDateFormatter,
  dayRangeHeaderFormat: rangeDateFormatter,
};

interface CalendarProps extends Omit<ReactBigCalendarProps, "localizer"> {
  maintenances?: MaintenanceIndexFragmentFragment[];
  createEventOnClick?: boolean;
}

const Calendar: React.FC<CalendarProps> = ({
  maintenances,
  createEventOnClick = true,
  ...otherProps
}) => {
  const { assetId } = useParams();
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { startDate, endDate } = useReactiveVar(calendarOptionsVar);
  const [, setSearchParams] = useSearchParams();
  const { data: maintenanceConfigQuery } = useMaintenanceConfigQuery();
  const [updateMaintenanceConfigMutation] =
    useUpdateMaintenanceConfigMutation();

  const setStartDate = useCallback((startDate: Date) => {
    calendarOptionsVar({
      ...calendarOptionsVar(),
      startDate,
    });
  }, []);

  const setEndDate = useCallback((endDate: Date) => {
    calendarOptionsVar({
      ...calendarOptionsVar(),
      endDate,
    });
  }, []);

  useEffect(() => {
    return () => {
      calendarOptionsVar({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      });
      updateMaintenanceConfigMutation({
        variables: { filterStatus: [], filterCategoryId: "" },
      });
    };
  }, [updateMaintenanceConfigMutation]);

  const events = React.useMemo(() => {
    if (!maintenances) return [];
    const filterStatus =
      maintenanceConfigQuery?.maintenanceConfig.filterStatus || null;
    const completedFilter = filterStatus?.includes("COMPLETED" as any);

    const selectedEvents: CriticalAssetEvent[] = [];
    const maintenanceCount = maintenances.length;
    const today = new Date();
    const monthFromToday = addDays(today, 30);

    for (let j = 0; j < maintenanceCount; j++) {
      const maintenance = maintenances[j];
      const maintenanceScheduleCount =
        maintenance.maintenanceSchedulesForDateRange.length;

      const completedSchedules: string[] = [];
      const schedules: any = {};
      for (let k = 0; k < maintenanceScheduleCount; k++) {
        const ms = maintenance.maintenanceSchedulesForDateRange[k];
        const formattedDate = format(ms.scheduledAt, "P");
        schedules[formattedDate] = ms.id;
        if (ms.completedAt) completedSchedules.push(formattedDate);
      }
      const rruleSet = generateRruleSetForMaintenance(maintenance);
      rruleSet.between(startDate, endDate).forEach((date) => {
        const formattedDate = format(date, "P");
        const isCompleted = completedSchedules.includes(formattedDate);
        let status = MaintenanceStatusType.Current;
        if (date && !isCompleted) {
          if (isBefore(date, today)) {
            status = MaintenanceStatusType.PastDue;
          } else if (isBefore(date, monthFromToday)) {
            status = MaintenanceStatusType.DueIn_30;
          }
        }

        if (
          !filterStatus?.length ||
          filterStatus.length === 4 ||
          (isCompleted ? completedFilter : filterStatus.includes(status as any))
        ) {
          selectedEvents.push({
            start: date,
            end: add(date, {
              [maintenance.durationType.toLowerCase()]:
                maintenance.durationValue,
            }),
            name: maintenance.misc.resolvedName,
            resourceId: maintenance.id,
            resourceAssetId: maintenance.assetId,
            resourceMaintenanceScheduleId: schedules[formattedDate],
            isCompleted,
            status,
          });
        }
      });
    }
    return selectedEvents;
  }, [
    maintenances,
    maintenanceConfigQuery?.maintenanceConfig.filterStatus,
    startDate,
    endDate,
  ]);

  const handleRangeChange = useCallback(
    (dates) => {
      if (Array.isArray(dates)) {
        setStartDate(dates[0]);
        setEndDate(
          dates.length > 1 ? dates[dates.length - 1] : endOfDay(dates[0])
        );
      } else {
        setStartDate(new Date(dates.start));
        setEndDate(new Date(dates.end));
      }
    },
    [setStartDate, setEndDate]
  );

  const handleSelectedTimes = useCallback(
    (info: any) => {
      if (!createEventOnClick || isPast(endOfDay(info.start))) return;
      const isTimeMidnight =
        getHours(info.start) === 0 && getMinutes(info.start) === 0;
      const params: any = {
        CaAprType: "serviceScheduleCreate",
        startTime: isTimeMidnight
          ? setHours(setMinutes(info.start, 0), 9).getTime()
          : info.start.getTime(),
        endTime: isTimeMidnight
          ? setHours(setMinutes(info.end, 0), 10).getTime()
          : info.end.getTime(),
      };
      if (assetId) params.assetId = assetId;
      setSearchParams(params);
    },
    [assetId, createEventOnClick, setSearchParams]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const prev = document.querySelector(
        ".rbc-toolbar .rbc-btn-group:first-child button:nth-child(2)"
      );
      if (prev) prev.setAttribute("aria-label", "Previous");
      const next = document.querySelector(
        ".rbc-toolbar .rbc-btn-group:first-child button:last-child"
      );
      if (next) next.setAttribute("aria-label", "Next");
    }, 750);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ReactBigCalendar
      selectable={ROLES.assetsCreate.includes(currentCompanyRole.role)}
      localizer={localizer}
      formats={formats}
      startAccessor="start"
      endAccessor="end"
      views={["month", "week", "day"]}
      popup
      style={{ height: "100%" }}
      events={events}
      messages={{ next: "›", previous: "‹", today: "Back to today" }}
      onRangeChange={handleRangeChange}
      onSelectSlot={createEventOnClick ? handleSelectedTimes : undefined}
      components={{ event: CalendarEvent as any }}
      {...otherProps}
    />
  );
};

export default Calendar;
