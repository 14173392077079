import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import Calendly from "../../components/elements/calendly";

const CalendlyModal: React.FC = () => {
  const [, setSearchParams] = useSearchParams();
  const handleModalClose = () => {
    setSearchParams({});
  };

  return (
    <Modal isOpen onClose={handleModalClose} size="xl">
      <ModalOverlay>
        <ModalContent margin="calc(var(--vh, 1vh)*2)">
          <ModalHeader>Book a call with Tom</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="0">
            <Calendly />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default CalendlyModal;
