import { MaintenanceStatusType } from "../graphql/graphql";
import colors from "../theme/foundations/colors";

export const getStatusColor = (status: MaintenanceStatusType) => {
  switch (status) {
    case MaintenanceStatusType.PastDue:
      return colors.red[500];
    case MaintenanceStatusType.DueIn_30:
      return colors.orange[500];
    default:
      return colors.green[500];
  }
};
