import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import React, { useCallback } from "react";
import countryList from "react-select-country-list";

import { WOLocation } from "../../../../../graphql/types/queries/workOrdersQuery";
import Combobox from "../../../components/combobox";
import InputField from "../../../components/inputField";
import { MessageUtils } from "../../../helpers/messages";
import { CompanyContactTypeEnum, WorkOrder } from "../../../models/workOrder";

interface LocationFields {
  label: string;
  disabled: boolean;
  valueField: string;
  style?: any;
}

interface WorkOrderCreateLocationDetailsPresenterProps {
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  locations: WOLocation[];
  selectedLocationId?: string;
  locationsLoading?: boolean;
}

const WorkOrderCreateLocationDetailsPresenter: React.FC<
  WorkOrderCreateLocationDetailsPresenterProps
> = ({
  onWorkOrderChange,
  locations,
  selectedLocationId,
  locationsLoading,
}) => {
  const { euiTheme } = useEuiTheme();
  const locationFields = [
    {
      label: "Location Description",
      disabled: true,
      valueField: "description",
      required: true,
    },
    {
      label: "Location Address",
      disabled: true,
      valueField: "address1",
      required: true,
    },
    {
      label: "Location Address 2",
      disabled: true,
      valueField: "address2",
    },
    {
      label: "Location Country",
      disabled: true,
      valueField: "countryAlpha2",
      required: true,
    },
    {
      label: "Location City",
      disabled: true,
      valueField: "city",
      required: true,
    },
    {
      label: "Location State",
      disabled: true,
      valueField: "state",
      required: true,
      style: {
        maxWidth: "50%",
        paddingRight: euiTheme.size.m,
      },
    },
    {
      label: "Location Zip",
      disabled: true,
      valueField: "zip",
      required: true,
      style: {
        maxWidth: "50%",
        paddingLeft: euiTheme.size.m,
      },
    },
  ];

  const [selectedLocation, setSelectedLocation] = React.useState({} as any);
  const [locationOptions, setLocationOptions] = React.useState(
    [] as EuiComboBoxOptionOption<string>[]
  );
  const [errors, setErrors] = React.useState([] as string[]);
  const [formTouched, setFormTouched] = React.useState(false);

  const handleLocationIdChange = (locationId: string | null) => {
    handleOnBlur();
    const selectedWOLocation = locations.find(
      (location) => location.id === locationId
    );
    onWorkOrderChange({
      locationId,
      locationName: selectedWOLocation?.name || null,
      locationContactType: CompanyContactTypeEnum.SAME_AS_COMPANY,
      locationContactUserId: null,
      locationContactEmail: null,
      locationContactOccupation: null,
      locationContactFullName: null,
      locationContactTeamId: null,
      locationContactTeamName: null,
      locationContactMobilePhone: null,
      locationContactWorkPhone: null,
      assetWorkOrders: [],
    });
  };

  const getFieldValue = useCallback(
    (field: LocationFields) => {
      let value = selectedLocation[field.valueField];
      if (value && field.valueField === "countryAlpha2") {
        value = countryList().getLabel(value);
      }
      return value;
    },
    [selectedLocation]
  );

  const handleOnBlur = useCallback(() => {
    if (!formTouched) setFormTouched(true);
  }, [formTouched]);

  React.useEffect(() => {
    if (locations) {
      setLocationOptions(
        locations.map((location) => {
          return { label: location.name, value: location.id };
        })
      );
    } else {
      setLocationOptions([]);
    }
  }, [locations]);

  React.useEffect(() => {
    const selectedWOLocation = locations.find(
      (location) => location.id === selectedLocationId
    );
    setSelectedLocation(selectedWOLocation || {});
  }, [locations, selectedLocationId]);

  React.useEffect(() => {
    const errors = [];
    if (formTouched && !selectedLocation?.id) {
      errors.push(MessageUtils.commonRequiredFieldErrorMessage);
    }
    setErrors(errors);
  }, [formTouched, selectedLocation]);

  return (
    <EuiFlexGroup
      justifyContent="flexStart"
      direction="column"
      wrap={false}
      gutterSize="none"
      style={{
        width: "100%",
        flexGrow: 1,
        paddingBottom: "80px",
      }}
    >
      <EuiFlexItem
        style={{
          width: "100%",
        }}
      >
        <EuiText className={"columnTitle"}>Location Details</EuiText>
      </EuiFlexItem>
      <EuiFlexItem
        style={{ marginBottom: euiTheme.size.m, height: euiTheme.size.xxl }}
      >
        <Combobox
          ariaLabel="Your Location Name"
          label="Location Name"
          placeholder={"Select Location Name"}
          required={true}
          autoSelect={true}
          options={locationOptions}
          selectedValue={selectedLocationId}
          onChange={handleLocationIdChange}
          invalid={!!errors.length}
          loading={locationsLoading}
          onBlur={handleOnBlur}
          errors={errors}
        />
      </EuiFlexItem>
      <EuiFlexGroup
        justifyContent="flexStart"
        direction="column"
        wrap={false}
        gutterSize="none"
        style={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <EuiFlexItem
          style={{
            width: "100%",
            flexGrow: 1,
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {locationFields?.length &&
            locationFields.map((field) => {
              return (
                <InputField
                  label={field.label}
                  disabled={field.disabled}
                  required={field.required}
                  value={getFieldValue(field)}
                  key={field.label}
                  style={{
                    marginTop: euiTheme.size.m,
                    marginBottom: euiTheme.size.m,
                    ...field.style,
                  }}
                />
              );
            })}
          {selectedLocation?.coordinates && (
            <EuiLink
              href={`http://www.google.com/maps/place/${selectedLocation.coordinates[1]},${selectedLocation.coordinates[0]}`}
              target="_blank"
              style={{
                marginBottom: euiTheme.size.s,
                marginTop: euiTheme.size.l,
                overflow: "auto",
              }}
            >
              {selectedLocation.name + " on a Map"}
            </EuiLink>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};

export default WorkOrderCreateLocationDetailsPresenter;
