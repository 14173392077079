import { Box, Flex, Menu, MenuItem, MenuList, Tooltip } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";

import AssetCategorySelectOption from "../../../components/elements/assetCategorySelectOption";
import CenteredFullHeight from "../../../components/elements/centeredFullHeight";
import LabeledMenuButton from "../../../components/elements/labeledMenuButton";
import Link from "../../../components/elements/link";
import AddCircle from "../../../components/icons/addCircle";
import {
  FloorPlanIndexFragmentFragment,
  SortBy,
  SortOrder,
  useFloorPlanConfigQuery,
  useUpdateFloorPlanConfigMutation,
} from "../../../graphql/graphql";
import useAssetCategories from "../../../hooks/useAssetCategories";
import { mainLayoutPaddingX } from "../../../utils/layout";
import orderBy, { sortByOptions } from "../../../utils/sort";
import FloorPlanTable from "./table";

interface FloorPlansProps {
  createFloorPlanPath?: string;
  floorPlans?: FloorPlanIndexFragmentFragment[];
  canAddPlan: boolean;
  canEditPlan: boolean;
  canDeletePlan: boolean;
  virtualizeList?: boolean;
  showLocationColumn?: boolean;
}

const FloorPlans: React.FC<FloorPlansProps> = ({
  createFloorPlanPath,
  floorPlans,
  canAddPlan,
  canEditPlan,
  canDeletePlan,
  virtualizeList = false,
  showLocationColumn = false,
}) => {
  const bulkActionRef = React.useRef<HTMLDivElement>(null);
  const { data: assetCategoriesData } = useAssetCategories();
  const { data } = useFloorPlanConfigQuery();
  const [updateFloorPlanConfigMutation] = useUpdateFloorPlanConfigMutation();
  const floorPlanConfig = data?.floorPlanConfig;
  const assetCategoryId = floorPlanConfig?.filterCategoryId;
  const assetCategory = React.useMemo(
    () =>
      assetCategoriesData
        ? assetCategoriesData.assetCategories.find(
            (ac) => ac.id === assetCategoryId
          )
        : null,
    [assetCategoriesData, assetCategoryId]
  );

  const setAssetCategoryId = React.useCallback(
    (filterCategoryId: string) => {
      updateFloorPlanConfigMutation({
        variables: { filterCategoryId },
      });
    },
    [updateFloorPlanConfigMutation]
  );

  const setSort = React.useCallback(
    (value) => {
      const [sortBy, sortOrder] = value.split("-");
      updateFloorPlanConfigMutation({
        variables: { sortBy, sortOrder },
      });
    },
    [updateFloorPlanConfigMutation]
  );

  const selectedSortOption = React.useMemo(
    () =>
      sortByOptions.find(
        (option) =>
          option.value ===
          `${floorPlanConfig?.sortBy}-${floorPlanConfig?.sortOrder}`
      ),
    [floorPlanConfig]
  );

  const filteredFloorPlans = React.useMemo(
    () =>
      floorPlans && floorPlanConfig
        ? orderBy(
            assetCategory
              ? floorPlans.filter((fp) =>
                  fp.floorPlanAssetCategories.some(
                    (fpac) => fpac.assetCategoryId === assetCategory.id
                  )
                )
              : floorPlans,
            floorPlanConfig.sortBy,
            floorPlanConfig.sortOrder
          )
        : [],
    [floorPlanConfig, floorPlans, assetCategory]
  );

  return (
    <>
      {floorPlans && floorPlans.length > 0 ? (
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom="3"
            flexWrap="wrap"
            px={virtualizeList ? mainLayoutPaddingX : "0"}
          >
            <Flex alignItems="center" order={1}>
              <Box>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        value={
                          selectedSortOption ? selectedSortOption.label : "Sort"
                        }
                      />
                      <MenuList>
                        {sortByOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            onClick={() => setSort(option.value)}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              <Box marginLeft="4" display={{ base: "none", sm: "block" }}>
                {!!assetCategoriesData && (
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <LabeledMenuButton
                          isOpen={isOpen}
                          label="Category"
                          value={
                            assetCategory ? (
                              <AssetCategorySelectOption
                                assetCategory={assetCategory}
                              />
                            ) : (
                              "All"
                            )
                          }
                          styleProps={{
                            maxWidth: "none !important",
                            width: "150px",
                          }}
                        />
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setAssetCategoryId("");
                            }}
                          >
                            All
                          </MenuItem>
                          {orderBy(
                            assetCategoriesData.assetCategories,
                            SortBy.Name,
                            SortOrder.Asc
                          ).map((ac) => (
                            <MenuItem
                              key={ac.id}
                              onClick={() => {
                                if (ac.id === assetCategoryId) return;
                                setAssetCategoryId(ac.id);
                              }}
                            >
                              <AssetCategorySelectOption assetCategory={ac} />
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                )}
              </Box>
            </Flex>
            <Box
              ref={bulkActionRef}
              order={{ base: 3, md: 2 }}
              width={{ base: "100%", md: "auto" }}
              flexGrow={{ sm: 1 }}
            />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              order={{ base: 2, md: 3 }}
            >
              {canAddPlan && !!createFloorPlanPath && (
                <Tooltip label="Add new plan" hasArrow placement="left">
                  <Link
                    to={createFloorPlanPath}
                    aria-label="Add new plan"
                    variant="icon"
                    colorScheme="secondary"
                    marginLeft="4"
                  >
                    <AddCircle boxSize="32px" />
                  </Link>
                </Tooltip>
              )}
            </Flex>
          </Flex>
          {filteredFloorPlans && filteredFloorPlans.length > 0 ? (
            <FloorPlanTable
              floorPlans={filteredFloorPlans}
              canDeletePlan={canDeletePlan}
              canEditPlan={canEditPlan}
              bulkActionRef={bulkActionRef}
              showFiltersAndActions
              virtualizeList={virtualizeList}
              showLocationColumn={showLocationColumn}
            />
          ) : (
            <Box textAlign="center" color="gray.700" paddingY="4">
              Plans empty
            </Box>
          )}
        </>
      ) : (
        canAddPlan &&
        createFloorPlanPath && (
          <CenteredFullHeight>
            <Link to={createFloorPlanPath}>Add a Plan</Link>
          </CenteredFullHeight>
        )
      )}
    </>
  );
};

export default FloorPlans;

gql`
  mutation FloorPlanDelete($ids: [UUID!]!) {
    floorPlanDelete(ids: $ids)
  }
`;
