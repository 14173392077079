import "moment-timezone";

import { useReactiveVar } from "@apollo/client";
import { EuiButtonEmpty, EuiText, useEuiTheme } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";

import Label from "../../../../components/label";
import Select from "../../../../components/select";
import { timezonesList } from "../../../../helpers/timezones";
import { companyChanged } from "../../../../helpers/workOrdersReactiveVariables";

interface WorkOrderTimeZoneSelectorPresenterProps {
  selectedTimeZone?: string | null;
  onIsSelectorVisibleChange?: (value: boolean) => void;
  onTimezoneSelected: (timezone: string) => void;
}

const WorkOrderTimeZoneSelectorPresenter: React.FC<
  WorkOrderTimeZoneSelectorPresenterProps
> = ({ selectedTimeZone, onIsSelectorVisibleChange, onTimezoneSelected }) => {
  const minSelectorWidth = "235px";
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();
  const [isSelectorVisible, setIsSelectorVisible] = useState<boolean>(false);
  const [timezoneOptions, setTimezoneOptions] = useState<
    EuiSuperSelectOption<string>[]
  >([]);

  const handleIsSelectorVisibleChange = (value: any) => {
    setIsSelectorVisible(value);
    onIsSelectorVisibleChange && onIsSelectorVisibleChange(value);
  };

  const getSelectedTimezoneLabel = (timezone?: string | null) => {
    if (!timezone) timezone = moment.tz.guess();
    const selectedTimezone = timezonesList.find(
      (item) => item.timezone === timezone
    );
    return selectedTimezone?.name || "";
  };

  useEffect(() => {
    setTimezoneOptions(
      timezonesList.map((timezone: { name: string; timezone: string }) => {
        return {
          inputDisplay: timezone.name,
          value: timezone.timezone,
        };
      })
    );
  }, []);
  const isCompanyChanged: { changed: boolean } = useReactiveVar(companyChanged);

  useEffect(() => {
    if (isCompanyChanged.changed) {
      handleIsSelectorVisibleChange(false);
    }
  }, [isCompanyChanged]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: isSelectorVisible ? "space-between" : "flex-start",
        alignItems: "center",
        flexWrap: "nowrap",
        gap: euiTheme.size.s,
        marginLeft: !isSelectorVisible ? "132px" : 0,
      }}
    >
      {!isSelectorVisible && (
        <>
          <EuiText style={{ fontSize: euiTheme.sizes["10px"] }}>
            {getSelectedTimezoneLabel(selectedTimeZone)}
          </EuiText>
          <EuiButtonEmpty
            onClick={() => handleIsSelectorVisibleChange(true)}
            size="xs"
            color="primary"
            style={{ fontSize: euiTheme.sizes["10px"] }}
          >
            Change?
          </EuiButtonEmpty>
        </>
      )}
      {isSelectorVisible && (
        <>
          <Label label="Timezone" required={true} />
          <div
            style={{ minWidth: minSelectorWidth, maxWidth: minSelectorWidth }}
          >
            <Select
              selectedValue={selectedTimeZone}
              options={timezoneOptions}
              onSelect={onTimezoneSelected}
              compressed={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default WorkOrderTimeZoneSelectorPresenter;
