const Input = {
  baseStyle: {
    field: {
      color: "black",
      boxShadow: "none",
    },
  },
  sizes: {
    sm: {
      field: {
        borderRadius: "0",
      },
    },
    md: {
      field: {
        borderRadius: "0",
        padding: "13px 16px",
        height: "56px !important",
      },
    },
    lg: {
      field: {
        borderRadius: "0",
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: "gray.300",
        _focus: {
          borderColor: "primary.500",
          boxShadow: "none",
          outline: "none",
        },
        _invalid: {
          borderColor: "red.500",
        },
      },
    },
  },
};

export default Input;
