import {
  Box,
  Button,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import Link from "../../components/elements/link";
import { useUserQuery } from "../../graphql/graphql";
import { getRoutePath } from "../../router";

const WelcomeModal: React.FC = () => {
  const { data: userData } = useUserQuery();
  const [, setSearchParams] = useSearchParams();

  const handleModalClose = () => {
    setSearchParams({});
  };

  return (
    <Modal isOpen onClose={handleModalClose} size="xl">
      <ModalOverlay zIndex={1800}>
        <ModalContent>
          <ModalCloseButton zIndex="1" />
          <ModalBody mt="4">
            <Text mb="4">Hi {userData?.user.firstName},</Text>
            <Text mb="4">Welcome! We're excited to have you on board.</Text>
            <Text mb="4">
              CriticalAsset is the intelligent building software that you and
              your organization will love. It combines the power of asset and
              location management, preventative maintenance, service scheduling
              and work orders in one 360° solution, making it easy to manage
              every aspect of your building operations from your phone, tablet
              or PC. CriticalAsset gives your teams the tools and insights they
              need to keep your facilities safe and environmentally friendly,
              while lowering operating costs.
            </Text>
            <Text mb="4">
              You can explore CriticalAsset for free for the next 14 days. To
              get started, please visit your{" "}
              <Link
                variant="link"
                colorScheme="secondary"
                to={getRoutePath("onboarding")}
              >
                company setup page
              </Link>
              .
            </Text>
            <Box mb="8" p="4" bg="secondary.10">
              <Text mb="4">
                To help you get up to speed, we recommend taking advantage of
                our <strong>FREE Onboarding consultation</strong>. Schedule a
                1:1 consultation with our team to get started.
              </Text>
              <Text fontStyle="italic">
                This consultation is worth $1500 and is being offered to you for
                free for a limited time.
              </Text>
            </Box>
            <Button
              onClick={() => {
                handleModalClose();
                setTimeout(
                  () => setSearchParams({ CaAprType: "calendly" }),
                  100
                );
              }}
              width="full"
              mb="8"
            >
              Book a free onboarding consultation
            </Button>
            <Text mb="4">
              Feel free to check out our knowledge base for how-tos and
              tutorials. If you need our help, please reach out to us anytime at{" "}
              <ChakraLink
                color="secondary.500"
                href="mailto:support@criticalasset.com"
              >
                support@criticalasset.com
              </ChakraLink>
              .
            </Text>
            <Text>Best,</Text>
            <Text>Chris</Text>
            <Text mb="3">Head of Customer Success</Text>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default WelcomeModal;
