import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import {
  useNotificationReadMutation,
  useNotificationsQuery,
} from "../../graphql/graphql";

interface NotificationPageProps {}

const NotificationPage: React.FC<NotificationPageProps> = () => {
  const { notificationId } = useParams();
  const [notificationReadMutation] = useNotificationReadMutation();
  const { data, refetch } = useNotificationsQuery();
  const navigate = useNavigate();
  const reFetched = useRef(false);

  useEffect(() => {
    if (!data) return;
    const notification = data.notifications.find(
      (n) => n.id === notificationId
    );
    if (!notification) {
      if (!reFetched.current) {
        reFetched.current = true;
        refetch();
      }
      return;
    }
    try {
      notificationReadMutation({ variables: { id: notificationId } });
    } catch {}
    navigate(notification.path, { replace: true });
  }, [data, navigate, notificationId, notificationReadMutation, refetch]);

  return <PageSpinner />;
};

export default NotificationPage;
