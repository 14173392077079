import { Icon, IconProps } from "@chakra-ui/react";

const Csv = (props: IconProps & any) => {
  return (
    <Icon width="12" height="auto" viewBox="0 0 48 48" {...props}>
      <path
        d="M40 23.333h-2.667v-2.666L40 23.333zM8 23.333h2.667v-2.666L8 23.333z"
        fill="#005E01"
      />
      <path
        d="M38 40a2 2 0 01-2 2H12a2 2 0 01-2-2V8a2 2 0 012-2h17.172a2 2 0 011.414.586l6.828 6.828A2 2 0 0138 14.828V40z"
        fill="#F1F1F1"
      />
      <path
        stroke="#CCC"
        d="M16 17.5h4M16 19.5h4M22 19.5h4M28 19.5h4M28 21.5h4M22 21.5h4M16 21.5h4M22 17.5h4M28 17.5h4"
      />
      <path d="M38 14h-7a1 1 0 01-1-1V6l8 8z" fill="#CCC" />
      <path
        d="M8 23.333c0-.368.298-.666.667-.666h30.666c.369 0 .667.298.667.666v11.334a.667.667 0 01-.667.666H8.667A.667.667 0 018 34.667V23.333z"
        fill="#008001"
      />
      <path
        d="M20.755 30.178l.914.627a2.936 2.936 0 01-1.076.873 3.35 3.35 0 01-1.456.313c-.88 0-1.624-.29-2.233-.872C16.301 30.53 16 29.825 16 29c0-.836.301-1.545.904-2.127.609-.582 1.353-.873 2.233-.873.518 0 .995.105 1.429.314.44.203.798.483 1.075.838l-.922.62a1.958 1.958 0 00-1.582-.747c-.56 0-1.03.193-1.41.577-.38.384-.57.85-.57 1.398 0 .548.19 1.014.57 1.398.38.379.85.568 1.41.568.326 0 .63-.07.913-.212.284-.141.519-.333.705-.576zM22.287 30.203h1.157c.012.226.114.421.307.585.193.158.45.237.769.237.301 0 .545-.064.732-.194.187-.136.286-.306.298-.509a.571.571 0 00-.199-.483c-.138-.13-.358-.234-.66-.314l-.768-.186c-1.049-.288-1.573-.83-1.573-1.627 0-.503.202-.913.606-1.229.41-.322.907-.483 1.491-.483.603 0 1.097.161 1.483.483.392.316.588.743.588 1.28H25.36a.67.67 0 00-.253-.551c-.162-.141-.389-.212-.678-.212-.265 0-.488.065-.669.195a.576.576 0 00-.262.491c0 .323.247.546.741.67l.769.212c.645.164 1.097.404 1.356.72.26.317.374.67.343 1.06a1.506 1.506 0 01-.669 1.211c-.415.294-.922.441-1.518.441-.657 0-1.194-.172-1.61-.517-.416-.345-.623-.771-.623-1.28zM27.172 26.068h1.203l1.211 4.068 1.212-4.068H32l-1.89 5.839h-1.04l-1.898-5.84z"
        fill="#fff"
      />
    </Icon>
  );
};

export default Csv;
