import * as ApolloReactHooks from "@apollo/client";

import { WorkOrderFile } from "../../pages/workOrders/models/workOrder";
import {
  WorkOrderFileCreateDocument,
  WorkOrderFileDeleteDocument,
} from "../fragments/workOrderFile";
import { defaultOptions } from "../graphql";
import {
  WorkOrderFileCreateMutationVariables,
  WorkOrderFileDeleteMutationVariables,
} from "../types/workOrdersFile/workOrdersFileMutations";

export function useWorkOrderFileCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    { files: WorkOrderFile[] },
    WorkOrderFileCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    { files: WorkOrderFile[] },
    WorkOrderFileCreateMutationVariables
  >(WorkOrderFileCreateDocument, options);
}

export function useWorkOrderFileDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    {},
    WorkOrderFileDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<{}, WorkOrderFileDeleteMutationVariables>(
    WorkOrderFileDeleteDocument,
    options
  );
}
