import {
  EuiFlexGroup,
  EuiFormRow,
  EuiSuperSelect,
  EuiText,
  transparentize,
} from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import { css } from "@emotion/react";
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";

import Label from "./label";

interface SelectProps {
  label?: string;
  placeholder?: string;
  options: Array<EuiSuperSelectOption<any>>;
  onSelect: (value: any) => void;
  onBlur?: () => void;
  onDropdownClosed?: () => void;
  invalid?: boolean;
  selectedValue?: any;
  required?: boolean;
  disabled?: boolean;
  compressed?: boolean;
  errors?: string[];
  className?: string;
  initiallyOpened?: boolean;
  color?: string;
  fullWidth?: boolean;
  noOptionsPlaceholder?: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  options,
  onSelect,
  onBlur,
  onDropdownClosed,
  selectedValue,
  required,
  disabled,
  className = "",
  invalid = false,
  placeholder = "",
  compressed = false,
  errors,
  color,
  fullWidth = true,
  initiallyOpened = false,
  noOptionsPlaceholder,
}) => {
  const [value, setValue] = React.useState("" as any);
  const [placeholderClassName, setPlaceholderClassNAme] = useState<string>("");
  const ref = useRef<EuiSuperSelect<any>>(null);
  const handleChange = (newValue: any) => {
    setValue(newValue);
    onSelect(newValue);
  };

  const noOptions: Array<EuiSuperSelectOption<any>> = [
    {
      value: "",
      disabled: true,
      dropdownDisplay: noOptionsPlaceholder || "There is no available options",
    },
  ];

  React.useEffect(() => {
    if (selectedValue !== value) {
      setValue(selectedValue || "");
    }
  }, [selectedValue]);

  useEffect(() => {
    if (initiallyOpened) {
      ref.current?.openPopover();
    }
  }, []);

  useEffect(() => {
    const classes = `placeholder (${disabled ? "disabled" : ""} ${
      label ? "labeled" : ""
    } ${compressed ? "compressed" : ""}`;
    setPlaceholderClassNAme(classes);
  }, [disabled]);

  const _onBlur = (event: any) => {
    onBlur?.();
    // this hack to fire close dropdown event by click without selecting an option
    setTimeout(() => {
      const isOpen = get(ref, "current.state.isPopoverOpen", null);
      if (isOpen === false) {
        onDropdownClosed?.();
      }
    }, 100);
  };

  return (
    <EuiFormRow
      error={errors}
      isInvalid={!!errors?.length}
      fullWidth={true}
      className={className}
    >
      <EuiFlexGroup
        wrap={true}
        direction="column"
        alignItems="stretch"
        gutterSize="none"
        className={"select-wrapper"}
        style={{
          position: "relative",
          width: "100%",
          minWidth: "100%",
          flexGrow: 1,
        }}
      >
        {label && (
          <Label
            label={label}
            required={required}
            style={{ paddingBottom: "5px" }}
          />
        )}
        <EuiSuperSelect
          ref={ref}
          options={options?.length ? options : noOptions}
          valueOfSelected={value}
          onChange={handleChange}
          disabled={disabled}
          fullWidth={fullWidth}
          hasDividers={true}
          onBlur={_onBlur}
          isInvalid={invalid}
          compressed={compressed}
          className={"work-orders-selector"}
          css={
            color
              ? css`
                  &.work-orders-selector {
                    background-color: ${transparentize(color, 0.1)};
                    color: ${color};

                    & + div {
                      color: ${color};
                    }
                  }
                `
              : null
          }
        />
        {!value && (
          <EuiText className={placeholderClassName}>{placeholder}</EuiText>
        )}
      </EuiFlexGroup>
    </EuiFormRow>
  );
};

export default Select;
