import { Box, Flex, Portal, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, memo } from "react";
import isEqual from "react-fast-compare";
import {
  Column,
  Row,
  useFlexLayout,
  useRowSelect,
  useTable,
} from "react-table";
import { FixedSizeList } from "react-window";

import AssetIcon from "../../components/elements/assetIcons";
import IndeterminateCheckbox from "../../components/elements/indeterminateCheckbox";
import Link from "../../components/elements/link";
import QrCodes from "../../components/elements/qrCodes";
import Status from "../../components/elements/status";
import TooltipName from "../../components/elements/tooltipName";
import {
  AssetIndexFragmentFragment,
  useLocationQuery,
} from "../../graphql/graphql";
import useBoxSize from "../../hooks/useBoxSize";
import { getRoutePath } from "../../router";
import { mainLayoutPaddingX } from "../../utils/layout";
import AssetDelete from "./delete";
import AssetsBulkDelete from "./delete/bulkDelete";

interface AssetTableProps {
  assets: Array<AssetIndexFragmentFragment>;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
  bulkActionRef: any;
}

const AssetTable: React.FC<AssetTableProps> = ({
  assets,
  canEditAsset,
  canDeleteAsset,
  bulkActionRef,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { containerRef, height } = useBoxSize();

  const columns = React.useMemo(() => {
    let assetColumns: Column<AssetIndexFragmentFragment>[] = [];

    assetColumns.push({
      id: "selection",
      width: isMobile ? 25 : 15,
      Cell: ({ row }: { row: any }) => (
        <Box
          as="button"
          visibility={{
            base: "visible",
            md: row.getToggleRowSelectedProps().checked ? "visible" : "hidden",
          }}
          _groupHover={{ visibility: "visible" }}
          marginLeft={{ sm: "2", md: "-2", lg: "-2", xl: "-1" }}
        >
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            title=""
            aria-label="Toggle Select row"
            borderColor="gray.300"
          />
        </Box>
      ),
    });

    assetColumns.push({
      Header: "Name",
      id: "name",
      accessor: (asset: AssetIndexFragmentFragment) => asset,
      width: 320,
      Cell: ({ value }: { value: AssetIndexFragmentFragment }) => (
        <Flex
          alignItems="center"
          position="relative"
          pl="4"
          marginLeft={{ base: "-2", md: "-3" }}
        >
          <Status status={value.maintenanceStatus} />
          <Link
            to={getRoutePath("assetsShow", {
              assetId: value.id,
            })}
            variant="unstyled"
            key={value.id}
            borderWidth="1px"
            borderRadius="lg"
            height="auto"
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="left"
            textTransform="none"
            fontSize="sm"
            fontWeight="normal"
            _hover={{ color: "secondary.600" }}
          >
            <AssetIcon
              iconName={value.assetType.iconName}
              iconColor={value.assetType.misc.resolvedIconColor}
              iconType={value.assetType.iconType}
              iconSize="sm"
            />
            <TooltipName marginLeft="2" name={value.name} />
          </Link>
        </Flex>
      ),
    });

    if (!isMobile) {
      if ("floorPlanAssets" in assets[0]) {
        assetColumns.push({
          Header: "Locations",
          id: "locations",
          accessor: (asset: AssetIndexFragmentFragment) => asset,
          width: 200,
          Cell: ({ value }: { value: AssetIndexFragmentFragment }) => {
            return (
              <>
                {"floorPlanAssets" in value &&
                  value.floorPlanAssets.length > 0 && (
                    <AssetLocations
                      locationId={value.floorPlanAssets[0].floorPlan.locationId}
                    />
                  )}
              </>
            );
          },
        });
      }

      assetColumns.push({
        id: "actions",
        width: 65,
        Header: "",
        accessor: (asset: AssetIndexFragmentFragment) => asset,
        Cell: ({ value }: { value: AssetIndexFragmentFragment }) => (
          <AssetActions
            asset={value}
            canDeleteAsset={canDeleteAsset}
            canEditAsset={canEditAsset}
          />
        ),
      });
    }
    return assetColumns;
  }, [canDeleteAsset, canEditAsset, isMobile, assets]);

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data: assets,
    },
    useRowSelect,
    useFlexLayout
  );
  const selectedIds = selectedFlatRows.map((r) => r.original.id);

  return (
    <Box ref={containerRef}>
      <Portal containerRef={bulkActionRef}>
        <AssetsBulkDelete
          assets={assets}
          selectedIds={selectedIds}
          canDeleteAsset={canDeleteAsset}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
      </Portal>
      <Box width="100%" overflowX="auto" marginTop="-4">
        <Box {...getTableProps()}>
          <Box {...getTableBodyProps()}>
            <FixedSizeList
              height={height}
              width="100%"
              itemCount={rows.length}
              itemSize={64}
              itemData={{ rows, prepareRow, selectedIds }}
              itemKey={(i, data) => data.rows[i].original.id}
              overscanCount={8}
            >
              {RowItem}
            </FixedSizeList>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetTable;

interface AssetListProps {
  asset: AssetIndexFragmentFragment;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
}

const AssetActions: React.FC<AssetListProps> = ({
  asset,
  canEditAsset,
  canDeleteAsset,
}) => {
  return (
    <Flex
      alignItems="center"
      paddingY="3"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      width="full"
      justifyContent="flex-end"
    >
      <QrCodes
        links={[
          {
            path: getRoutePath("assetsShow", { assetId: asset.id }),
            name: asset.name,
          },
        ]}
        printDocumentTitle={asset.name}
      />
      {canEditAsset && (
        <Tooltip label="Edit Asset" hasArrow placement="bottom">
          <Link
            to={getRoutePath("assetsEdit", {
              assetId: asset.id,
            })}
            aria-label="Edit Asset"
            variant="icon"
            colorScheme="gray"
            marginX="3"
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
        </Tooltip>
      )}
      {canDeleteAsset && <AssetDelete asset={asset} />}
    </Flex>
  );
};

interface AssetLocationsProps {
  locationId: string;
}

const AssetLocations: React.FC<AssetLocationsProps> = ({ locationId }) => {
  const { data } = useLocationQuery({
    variables: { id: locationId },
  });

  return data ? (
    <Box fontSize="sm">
      <TooltipName name={data.location.name} marginBottom="1" />
    </Box>
  ) : null;
};

interface Data {
  rows: Row<AssetIndexFragmentFragment>[];
  prepareRow: (row: Row<AssetIndexFragmentFragment>) => void;
  selectedIds: string[];
}

const RowItem: FC<{
  index: number;
  style: CSSProperties;
  data: Data;
}> = memo(({ index, style, data }) => {
  const { rows, prepareRow } = data;
  const row = rows[index];
  prepareRow(row);
  return (
    <Box style={style} paddingX={mainLayoutPaddingX}>
      <Box
        width="full"
        alignItems="center"
        fontSize="sm"
        backgroundColor="secondary.10"
        marginTop="4"
        _hover={{ boxShadow: "md" }}
        {...row.getRowProps()}
        role="group"
      >
        {row.cells.map((cell) => {
          return (
            <Box paddingX={{ base: "2", md: "3" }} {...cell.getCellProps()}>
              {cell.render("Cell")}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}, isEqual);
