import React from "react";

import PageSpinner from "../../../../components/elements/pageSpinner";
import { useWOLocationQuery } from "../../../../graphql/queries/workOrders";
import { WorkOrder } from "../../models/workOrder";
import WorkOrderCreateLocationPresenter from "./presenter";

interface WorkOrderCreateLocationPageProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreatLocationPage: React.FC<
  WorkOrderCreateLocationPageProps
> = ({ workOrder, onWorkOrderChange }) => {
  const { data, loading } = useWOLocationQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: workOrder.companyId,
    },
    skip: !workOrder.companyId,
  });

  return (
    <>
      {!loading && data?.workOrderLocations?.length ? (
        <WorkOrderCreateLocationPresenter
          locations={data.workOrderLocations}
          workOrder={workOrder}
          onWorkOrderChange={onWorkOrderChange}
          locationsLoading={loading}
        />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default WorkOrderCreatLocationPage;
