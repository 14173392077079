import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { WorkOrder } from "../../../models/workOrder";
import WorkOrderEditSectionFooter from "../../components/sectionFooter";
import WorkOrderEditCompanyInfoPresenter, {
  CompanyInfoSectionData,
} from "./presenter";

interface WorkOrderEditCompanyInfoProps {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
}

const WorkOrderEditCompanyInfo: React.FC<WorkOrderEditCompanyInfoProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const [initialSectionData, setInitialSectionData] =
    useState<CompanyInfoSectionData>();
  const [sectionData, setSectionData] = useState<CompanyInfoSectionData>();
  const [changed, setChanged] = useState<boolean>(false);

  const handleSectionDataChange = useCallback(
    (newSectionData: CompanyInfoSectionData) => {
      if (!isEqual(newSectionData, initialSectionData)) {
        setChanged(true);
      } else {
        setChanged(false);
      }
      setSectionData(newSectionData);
    },
    [initialSectionData]
  );

  const cancel = () => {
    setChanged(false);
    setSectionData({
      companyName: workOrder.company?.name,
      companyContactFullName: workOrder.companyContactFullName,
      companyContactEmail: workOrder.companyContactEmail,
      companyContactMobilePhone: workOrder.companyContactMobilePhone,
      companyContactWorkPhone: workOrder.companyContactWorkPhone,
    });
  };
  const save = () => {
    if (sectionData) {
      onWorkOrderChange({
        ...workOrder,
        companyContactFullName: sectionData.companyContactFullName,
        companyContactEmail: sectionData.companyContactEmail,
        companyContactMobilePhone: sectionData.companyContactMobilePhone,
        companyContactWorkPhone: sectionData.companyContactWorkPhone,
      });
    }
  };

  useEffect(() => {
    if (workOrder) {
      const workOrderData = {
        companyName: workOrder.company?.name,
        companyContactFullName: workOrder.companyContactFullName,
        companyContactEmail: workOrder.companyContactEmail,
        companyContactMobilePhone: workOrder.companyContactMobilePhone,
        companyContactWorkPhone: workOrder.companyContactWorkPhone,
      };
      setSectionData({ ...workOrderData });
      setInitialSectionData({ ...workOrderData });
    }
    setChanged(false);
  }, [workOrder]);

  useEffect(() => {
    changeIsEdit && changeIsEdit(changed);
  }, [changed, changeIsEdit]);

  return (
    <>
      <WorkOrderEditCompanyInfoPresenter
        sectionData={sectionData}
        onSectionDataChange={handleSectionDataChange}
      >
        <WorkOrderEditSectionFooter
          disabled={false}
          cancel={cancel}
          save={save}
          visible={changed}
        />
      </WorkOrderEditCompanyInfoPresenter>
    </>
  );
};

export default WorkOrderEditCompanyInfo;
