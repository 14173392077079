import * as ApolloReactHooks from "@apollo/client";

import { WorkOrder } from "../../pages/workOrders/models/workOrder";
import {
  WorkOrderCreateDocument,
  WorkOrderUpdateDocument,
} from "../fragments/workOrders";
import { defaultOptions } from "../graphql";
import {
  WorkOrderCreateMutationVariables,
  WorkOrderUpdateMutationVariables,
} from "../types/workOrdersMutations";

export function useWorkOrderCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    { workOrderCreate: WorkOrder },
    WorkOrderCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    { workOrderCreate: WorkOrder },
    WorkOrderCreateMutationVariables
  >(WorkOrderCreateDocument, options);
}

export function useWorkOrderUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    { workOrderUpdate: WorkOrder },
    WorkOrderUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    { workOrderUpdate: WorkOrder },
    WorkOrderUpdateMutationVariables
  >(WorkOrderUpdateDocument, options);
}
