import React from "react";

import { WorkOrder } from "../../models/workOrder";
import WorkOrderEditAssignee from "./assignee";
import WorkOrderEditCompanyInfo from "./company";
import WorkOrderEditLocationInfo from "./location";
import WorkOrderEditPlanInfo from "./servicePlan";
import WorkOrderEditScheduleInfo from "./serviceSchedule";
import WorkOrderEditWorkflow from "./workflow";

export type WorkOrderEditRightPanelPresenterProps = {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
};

const WorkOrderEditRightPanelPresenter: React.FC<
  WorkOrderEditRightPanelPresenterProps
> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}: WorkOrderEditRightPanelPresenterProps) => {
  return (
    <>
      <WorkOrderEditWorkflow
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
      <WorkOrderEditAssignee
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
      <WorkOrderEditScheduleInfo
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
      <WorkOrderEditPlanInfo
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
      <WorkOrderEditCompanyInfo
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
      <WorkOrderEditLocationInfo
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
    </>
  );
};
export default WorkOrderEditRightPanelPresenter;
