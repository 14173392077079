import { locationLastOpenedPlanKeysVar } from "../graphql/reactiveVariables";

export const setPlanIdForLocation = (
  locationId: string,
  planId: string
): boolean => {
  if (!planId) return false;
  locationLastOpenedPlanKeysVar({
    ...locationLastOpenedPlanKeysVar(),
    [`LastOpenedPlanIdFor-${locationId}`]: planId,
  });
  return true;
};

export const getPlanIdForLocation = (locationId: string) =>
  locationLastOpenedPlanKeysVar()[locationId] || "";
