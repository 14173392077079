import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useCallback } from "react";

import {
  FileFragmentFragment,
  useCompanyQuery,
} from "../../../graphql/graphql";
import FilesUpload from "../filesUpload";
import FileThumb from "./fileThumb";
import Map from "./map";
import SelectFile from "./selectFile";

interface FileSelectInputProps {
  name: string;
  mapUpload?: boolean;
  acceptFile?: string;
  allowMultipleUpload?: boolean;
}

const FileSelectInput: React.FC<FileSelectInputProps> = ({
  name,
  mapUpload = false,
  acceptFile = "image/*, application/pdf",
  allowMultipleUpload = true,
}) => {
  const nameUploading = `${name}Uploading`;
  const { errors, values, setFieldValue, submitCount } =
    useFormikContext<any>();
  const error = get(errors, name);
  const errorUploading = get(errors, nameUploading);
  const value = get(values, name);
  const [view, setView] = React.useState<number>();
  const { data: currentCompanyData } = useCompanyQuery();

  const setNewFileId = useCallback(
    (fileId: string) => {
      setView(undefined);
      setFieldValue(
        name,
        value && allowMultipleUpload ? [...value, fileId] : [fileId]
      );
    },
    [allowMultipleUpload, name, setFieldValue, value]
  );

  const setNewFilesId = useCallback(
    (files: FileFragmentFragment[]) => {
      const newFileIds = files.map((file) => file.id);
      setFieldValue(
        name,
        value && allowMultipleUpload ? [...value, ...newFileIds] : newFileIds
      );
    },
    [value, setFieldValue, name, allowMultipleUpload]
  );

  const removeFile = useCallback(
    (fileId: string) => {
      setFieldValue(
        name,
        value ? value.filter((id: string) => id !== fileId) : []
      );
    },
    [name, setFieldValue, value]
  );

  const setUploading = useCallback(
    (uploading) => {
      setFieldValue(nameUploading, uploading);
    },
    [setFieldValue, nameUploading]
  );

  if (!currentCompanyData) return null;
  const company = currentCompanyData.company;

  return (
    <>
      <FormControl
        my={4}
        isInvalid={(!!error || !!errorUploading) && !!submitCount}
        aria-invalid={(!!error || !!errorUploading) && !!submitCount}
      >
        <FormLabel>Upload from</FormLabel>
        <Flex>
          <Box flexGrow={1}>
            <Button
              variant="outline"
              colorScheme="secondary"
              size="xs"
              onClick={() => setView(0)}
              width="full"
            >
              Files
            </Button>
          </Box>
          <Box flexGrow={1} marginX={2}>
            <FilesUpload
              folderId={company.uploadsFolderId || ""}
              accept={acceptFile}
              allowMultipleUpload={allowMultipleUpload}
              showProgress={false}
              onUpload={setNewFilesId}
              buttonVariant="outline"
              buttonColorScheme="secondary"
              buttonSize="xs"
              buttonText="This Device"
              isButtonTooltipNeeded={false}
              buttonBlock
              setUploading={setUploading}
            />
          </Box>
          {mapUpload && (
            <Box flexGrow={1}>
              <Button
                variant="outline"
                colorScheme="secondary"
                size="xs"
                onClick={() => setView(2)}
                width="full"
              >
                Map
              </Button>
            </Box>
          )}
        </Flex>
        {view === 0 && (
          <Modal isOpen onClose={() => setView(undefined)} size="sm">
            <ModalOverlay>
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  <SelectFile
                    onSuccess={setNewFileId}
                    currentCompany={company}
                    acceptFile={acceptFile}
                  />
                </ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
        )}
        {view === 2 && (
          <Modal isOpen onClose={() => setView(undefined)} size="4xl">
            <ModalOverlay>
              <ModalContent my="2vh" maxHeight="96vh">
                <ModalCloseButton />
                <ModalBody>
                  <Text
                    textAlign="center"
                    fontSize="xs"
                    color="gray.600"
                    marginY="3"
                    marginRight={{ base: "6", md: "0" }}
                  >
                    Search for your location and click CAPTURE IMAGE to save a
                    map of your location
                  </Text>
                  <Map onSuccess={setNewFileId} />
                </ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
        )}
        <FormErrorMessage>
          <Text as="span" marginRight="1">
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Text>
          <Text role="alert">{error || errorUploading}</Text>
        </FormErrorMessage>
      </FormControl>
      {!!value && value.length > 0 && (
        <List marginBottom="6">
          {value.map((v: string) => (
            <ListItem
              marginBottom="2"
              paddingBottom="2"
              borderBottomWidth="1px"
              key={v}
            >
              <FileThumb fileId={v} onRemoveFile={removeFile} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default FileSelectInput;
