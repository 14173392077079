import { Box, Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import { useAssetWithTypeQuery } from "../../../graphql/graphql";
import AssetIcon from "../assetIcons";
import MultiSelect, { MultiSelectItem } from "../multiSelect";
import SelectCombobox from "../selectCombobox";

interface AssetSelectFieldsProps {
  name: string;
  label?: string;
  loading?: boolean;
  excludedAssetIds?: string[];
  autoFocus?: boolean;
  multiSelect?: boolean;
}

const AssetSelectFields: React.FC<AssetSelectFieldsProps> = ({
  name,
  label = "Select Asset(s)",
  loading,
  excludedAssetIds,
  multiSelect = true,
  autoFocus = false,
}) => {
  const { data: assetsData } = useAssetWithTypeQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { values } = useFormikContext<any>();

  const assets = React.useMemo(() => {
    if (!assetsData || loading) return [];

    const assets: MultiSelectItem[] = [];
    const assetsLength = assetsData.assets.length;
    for (let i = 0; i < assetsLength; i++) {
      const asset = assetsData.assets[i];
      if (!excludedAssetIds?.includes(asset.id)) {
        assets.push({
          value: asset.id,
          label: (
            <Flex alignItems="center">
              <Box transform="scale(0.8)">
                <AssetIcon
                  iconName={asset.assetType.iconName}
                  iconColor={asset.assetType.misc.resolvedIconColor}
                  iconType={asset.assetType.iconType}
                  iconSize="xs"
                />
              </Box>
              <Box>{asset.name}</Box>
            </Flex>
          ),
          query: asset.name,
        });
      }
    }
    return assets;
  }, [assetsData, loading, excludedAssetIds]);

  const locationLabel = React.useMemo(() => {
    if (!assetsData) return "";
    const value = get(values, name);
    if (Array.isArray(value)) return "";
    return assetsData.assets.find((a) => a.id === value)?.locationsLabel || "";
  }, [assetsData, name, values]);

  return multiSelect ? (
    <MultiSelect
      sortOptions
      items={assets}
      autoComplete="off"
      name={name}
      label={label}
      loading={loading}
      autoFocus={autoFocus}
    />
  ) : (
    <>
      <SelectCombobox
        sortOptions
        options={assets}
        name={name}
        label={label}
        autoFocus={autoFocus}
      />
      {!!locationLabel && (
        <Box fontSize="sm" mt="1">
          Located at {locationLabel}
        </Box>
      )}
    </>
  );
};

export default AssetSelectFields;
