import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Briefcase = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    viewBox="0 0 24 24"
    boxSize="40px"
    color="primary.500"
    {...props}
  >
    <path d="M14.7719 5.5385c-.5096 0-.9236-.368-.9236-.82092V3.48681h-3.693v1.23077c0 .45292-.41403.82092-.9236.82092s-.92359-.368-.92359-.82092V3.48681c0-.90462.82805-1.64062 1.84579-1.64062h3.693c1.0178 0 1.8458.736 1.8458 1.64062v1.23077c.0028.45415-.4112.82092-.9208.82092z" />
    <path d="M18 12.953v.9487c0 .4225-.3405.7737-.75.7737s-.75-.3512-.75-.7737v-.9487h-9v.9487c0 .4225-.3405.7737-.75.7737S6 14.3242 6 13.9017v-.9487H0v6.364c0 1.5677 1.23 2.8367 2.7495 2.8367H21.249c1.5195 0 2.7495-1.269 2.7495-2.8367v-6.364H18zm3.2505-8.33777H2.7495C1.23 4.61523 0 5.88423 0 7.45345V10.807h6V9.51635c0-.42249.3405-.77378.75-.77378s.75.35129.75.77378v1.28915h9V9.51635c0-.42249.3405-.77378.75-.77378s.75.35129.75.77378v1.28915h6V7.45191c0-1.56768-1.23-2.83668-2.7495-2.83668z" />
  </Icon>
);

export default Briefcase;
