import { Box, Button, DrawerBody, DrawerFooter, Text } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";

import Autosuggest from "../../../components/elements/autosuggest";
import FormGroup from "../../../components/elements/formGroup";
import MaintenanceIntervalField from "../../../components/elements/maintenanceIntervalField";
import { MaintenanceIntervalType } from "../../../graphql/graphql";
import {
  descriptionSchema,
  idSchema,
  maintenanceMiscTypeSchema,
  maintenanceMiscValueSchema,
  nameSchema,
  yupObject,
} from "../../../utils/validation";

interface AssetTypeMaintenancesCreatePresenterProps {
  isLoading: boolean;
  handleSubmit: (
    values: MaintenanceTemplateFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  assetTypeId: string;
}

const AssetTypeMaintenancesCreatePresenter: React.FC<
  AssetTypeMaintenancesCreatePresenterProps
> = ({ isLoading, handleSubmit, assetTypeId }) => {
  return (
    <>
      <DrawerBody>
        <Formik
          initialValues={{
            assetTypeId,
            name: "",
            description: "",
            intervalType: MaintenanceIntervalType.Months,
            intervalValue: 1,
          }}
          validationSchema={maintenanceTemplateFormValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form noValidate id="maintenance_template_create">
            <FormGroup label="Title" name="name">
              <Autosuggest
                items={[
                  "Service reminder",
                  "Inspection",
                  "Tune up",
                  "Calibration",
                ]}
                autoComplete="off"
                name="name"
                autoFocus
              />
            </FormGroup>
            <Box as="fieldset" marginTop="3">
              <MaintenanceIntervalField alwaysOn hideEndOption />
            </Box>
            <Text fontSize="xs" color="gray.600" mt="2">
              A reminder will be sent starting on the day the user selects when
              they create an asset for this asset type
            </Text>
          </Form>
        </Formik>
      </DrawerBody>
      <DrawerFooter>
        <Button
          width="full"
          type="submit"
          isLoading={isLoading}
          form="maintenance_template_create"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
};

export default AssetTypeMaintenancesCreatePresenter;

gql`
  mutation MaintenanceTemplateCreate($data: MaintenanceTemplateInput!) {
    maintenanceTemplateCreate(data: $data) {
      ...MaintenanceTemplateFragment
    }
  }
`;
export const maintenanceTemplateFormValidationSchema = yupObject().shape({
  assetTypeId: idSchema.label("Asset type"),
  name: nameSchema.label("Title"),
  description: descriptionSchema,
  intervalType: maintenanceMiscTypeSchema.label("Interval").required(),
  intervalValue: maintenanceMiscValueSchema.label("Interval").required(),
});

export type MaintenanceTemplateFormData = {
  assetTypeId: string;
  name: string;
  description?: string;
  intervalType: MaintenanceIntervalType;
  intervalValue: number;
};
