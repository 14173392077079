import { Icon, IconProps } from "@chakra-ui/react";

const Printer = (props: IconProps & any) => {
  return (
    <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
      <path
        d="M105.167 90h-13a2.168 2.168 0 0 1 0-4.333h13c3.583 0 6.5-2.917 6.5-6.5V48.833c0-3.583-2.917-6.5-6.5-6.5H22.833a6.508 6.508 0 0 0-6.5 6.5v30.334c0 3.583 2.917 6.5 6.5 6.5h13a2.167 2.167 0 0 1 0 4.333h-13C16.858 90 12 85.138 12 79.167V48.833C12 42.862 16.858 38 22.833 38h82.334C111.142 38 116 42.862 116 48.833v30.334C116 85.138 111.142 90 105.167 90ZM74.833 103h-26a2.168 2.168 0 0 1 0-4.333h26a2.167 2.167 0 0 1 0 4.333ZM74.833 94.333h-26a2.167 2.167 0 0 1 0-4.333h26a2.168 2.168 0 0 1 0 4.333Z"
        fill="currentColor"
      />
      <path
        d="M57.5 85.667h-8.667a2.168 2.168 0 0 1 0-4.334H57.5a2.167 2.167 0 0 1 0 4.334ZM92.167 42.333A2.167 2.167 0 0 1 90 40.167V22.833c0-3.583-2.916-6.5-6.5-6.5h-39a6.508 6.508 0 0 0-6.5 6.5v17.334a2.167 2.167 0 0 1-4.333 0V22.833C33.667 16.862 38.524 12 44.5 12h39c5.976 0 10.833 4.862 10.833 10.833v17.334c0 1.196-.97 2.166-2.166 2.166Z"
        fill="currentColor"
      />
      <path
        d="M83.5 116h-39c-5.976 0-10.833-4.862-10.833-10.833V70.5c0-1.196.97-2.167 2.166-2.167h56.334c1.196 0 2.166.97 2.166 2.167v34.667c0 5.971-4.857 10.833-10.833 10.833ZM38 72.667v32.5c0 3.583 2.916 6.5 6.5 6.5h39c3.584 0 6.5-2.917 6.5-6.5v-32.5H38Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default Printer;
