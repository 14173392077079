import tinycolor from "tinycolor2";

import defaultTheme from "../chakraTheme";

export const COLORS = [
  tinycolor(defaultTheme.colors.green[500]).setAlpha(0.85).toRgbString(),
  tinycolor(defaultTheme.colors.orange[500]).setAlpha(0.85).toRgbString(),
  tinycolor(defaultTheme.colors.red[500]).setAlpha(0.85).toRgbString(),
  tinycolor(defaultTheme.colors.gray[300]).setAlpha(0.85).toRgbString(),
];
