import { Text } from "@chakra-ui/react";
import React from "react";

import AmericanExpress from "../../icons/americanExpressCard";
import CommonCreditCard from "../../icons/commonCreditCard";
import MasterCard from "../../icons/masterCard";
import Visa from "../../icons/visa";

interface CreditCardIconProps {
  brand?: string;
  styleProps?: object;
}

const CreditCardIcon: React.FC<CreditCardIconProps> = ({
  brand,
  styleProps,
}) => {
  let Icon = CommonCreditCard;
  switch (brand) {
    case "amex":
      Icon = AmericanExpress;
      break;
    case "mastercard":
      Icon = MasterCard;
      break;
    case "visa":
      Icon = Visa;
      break;
  }
  return (
    <Text as="span" color="currentColor">
      <Icon fill="currentColor" {...styleProps} />
    </Text>
  );
};

export default CreditCardIcon;
