import { Placement, Text, TextProps, Tooltip } from "@chakra-ui/react";
import { FC, useRef } from "react";

interface TooltipNameProps extends TextProps {
  placement?: Placement;
  name: string;
}

const TooltipName: FC<TooltipNameProps> = ({
  name,
  placement = "auto",
  ...props
}) => {
  const ref = useRef<any>();

  return (
    <Tooltip
      label={
        ref?.current && ref.current.offsetWidth < ref.current.scrollWidth
          ? name
          : undefined
      }
      placement={placement}
      hasArrow
    >
      <Text isTruncated ref={ref} {...props}>
        {name}
      </Text>
    </Tooltip>
  );
};

export default TooltipName;
