import { EuiFieldText, EuiFormRow, EuiTreeView } from "@elastic/eui";
import { Node } from "@elastic/eui/src/components/tree_view/tree_view";
import { cloneDeep } from "lodash";
import React, { BaseSyntheticEvent, useEffect, useRef, useState } from "react";

import { MessageUtils } from "../helpers/messages";
import ArrowDown from "./svgIcons/arrowDown";

export type TreeNode = Node & { disabled?: boolean };
export type TreeProps = {
  items: TreeNode[];
  selectedItem?: TreeNode | null;
  onSelect: (id: string) => void;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
};
type TreeItem = {
  label: string | React.ReactNode;
  id: string;
  disabled?: boolean;
};
const TreeSelect: React.FC<TreeProps> = ({
  items,
  selectedItem,
  placeholder,
  onSelect,
  required,
  disabled,
  errorMessage = MessageUtils.commonRequiredFieldErrorMessage,
}: TreeProps) => {
  const [expanded, setExpanded] = useState(false);
  const [_items, setItems] = useState<TreeNode[]>([]);
  const [value, setValue] = useState<TreeItem>();
  const [userTouched, setUserTouched] = useState<boolean>();
  const [errors, setErrors] = useState<string[]>();
  const [bounds, setBounds] = useState<{ top: number; left: number } | null>(
    null
  );
  const treeWrapper: any = useRef();

  useEffect(() => {
    const clonedItems = cloneDeep(items);
    convertItems(clonedItems);
    setItems(clonedItems);
  }, [items]);

  useEffect(() => {
    if (selectedItem) {
      setValue(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (required && userTouched && !value) {
      setErrors([errorMessage]);
    } else {
      setErrors([]);
    }
  }, [userTouched, value]);

  const toggleTree = (isExpanded: boolean) => {
    if (isExpanded === expanded) return;
    if (isExpanded) {
      const rect = treeWrapper.current?.getBoundingClientRect();
      setBounds(rect ? { top: rect.bottom, left: rect.left } : null);
    } else {
      setExpanded(false);
    }
  };

  const convertItems = (items: TreeNode[]) => {
    items.forEach((item) => {
      replaceLabelWithLabelNode(item);
      if (item.children) {
        convertItems(item.children);
      }
    });
  };

  const onSelectItem = (event: BaseSyntheticEvent) => {
    const {
      target: {
        dataset: { item },
      },
    } = event;
    if (item) {
      const selectedItem = JSON.parse(item);
      if (!selectedItem.disabled) {
        setValue(selectedItem);
        setExpanded(false);
        onSelect(selectedItem.id);
      }
    }
  };

  const onBlur = () => {
    setExpanded(false);
    setUserTouched(true);
  };

  useEffect(() => {
    if (bounds) {
      setExpanded(true);
    }
  }, [bounds]);

  const replaceLabelWithLabelNode = (item: TreeItem) => {
    item.label = (
      <div
        className={"tree-button"}
        data-item={JSON.stringify({
          label: item.label,
          id: item.id,
          disabled: item.disabled,
        })}
        onClick={onSelectItem}
        title={item.label as string}
      >
        {item.label}
      </div>
    );
    return;
  };

  return (
    <>
      {expanded ? <div className={"overlay"} onClick={onBlur} /> : null}
      <div
        className={"tree-wrapper"}
        ref={treeWrapper}
        style={{ position: "relative", maxWidth: "400px" }}
      >
        <EuiFormRow
          error={errors}
          isInvalid={!!errors?.length}
          fullWidth={true}
          isDisabled={disabled}
        >
          <EuiFieldText
            placeholder={placeholder}
            onClick={() => toggleTree(!expanded)}
            onChange={() => {}}
            value={(value?.label as string) || ""}
            style={{
              zIndex: 1000,
              position: "relative",
              width: "100%",
              cursor: disabled ? "not-allowed" : "pointer",
            }}
            disabled={disabled}
            isInvalid={!!errors?.length}
          />
        </EuiFormRow>
        <ArrowDown
          onClick={() => toggleTree(!expanded)}
          style={{
            position: "absolute",
            right: "8px",
            top: "12px",
            zIndex: 1000,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        />
        {expanded ? (
          <EuiTreeView
            className={"tree-dropdown"}
            style={{
              left: bounds ? bounds.left : 0,
              top: bounds ? bounds.top : 0,
            }}
            items={_items}
            aria-label="Sublocation tree"
            showExpansionArrows={true}
          />
        ) : null}
      </div>
    </>
  );
};
export default TreeSelect;
