import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useLayoutEffect, useRef, useState } from "react";

interface Size {
  width: number;
  height: number;
  right?: number;
}

export default function useElementSize<
  T extends HTMLElement = HTMLDivElement
>(): [MutableRefObject<T | null>, Size] {
  const target = useRef<T | null>(null);
  const wrapper = document.getElementById("main_inner");
  const main = document.getElementById("main");

  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
    right: 0,
  });

  const setRoundedSize = ({ width, height }: Size) => {
    let paddingRight: string | number = "62";
    if (wrapper) {
      paddingRight = window
        .getComputedStyle(wrapper, null)
        .getPropertyValue("padding-left");
      paddingRight = paddingRight.split("px")[0];
    }
    let scrollWidth = 0;
    if (main) {
      scrollWidth = main.offsetWidth - main.clientWidth;
    }
    const x = Number(paddingRight) + Number(scrollWidth);
    setSize({
      width: Math.round(width),
      height: Math.round(height),
      right: Math.round(x),
    });
  };

  useLayoutEffect(() => {
    target.current && setRoundedSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setRoundedSize({ width, height });
  });

  return [target, size];
}
