import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import {
  Criteria,
  EuiBasicTableColumn,
} from "@elastic/eui/src/components/basic_table/basic_table";
import {
  EuiTableSelectionType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { RefObject, useEffect, useRef, useState } from "react";

import { WOFloorPlan } from "../../../../../../graphql/types/queries/workOrdersQuery";
import SimpleBadge from "../../../../components/badge";
import TruncatedText from "../../../../components/truncatedText";
import { SortingHelper } from "../../../../helpers/sortingHelper";
import { AssetPlansTypeEnum } from "../../../../models/enums";
import WorkOrderCreatePlanPreviewModal from "../plansPreviewModal";

export interface FloorPlansItem {
  id: string;
  itemId: string;
  name: string;
  type: AssetPlansTypeEnum;
  planId: string;
  sublocation?: string;
  description?: string;
}

interface AffectedAreaFeature {
  properties: {
    name: any;
    description: string;
  };
}

interface WorkOrderCreateAddAssetPlansTablePresenterProps {
  assetId?: string;
  floorPlans?: WOFloorPlan[];
  sublocationId?: string;
  companyId?: string;
  onSelectPlans: (plans: FloorPlansItem[]) => void;
}

const WorkOrderCreateAddAssetPlansTablePresenter: React.FC<
  WorkOrderCreateAddAssetPlansTablePresenterProps
> = ({ assetId, floorPlans, sublocationId, companyId, onSelectPlans }) => {
  const tableMaxHeight = "262px";
  const tableRef: RefObject<EuiBasicTable<FloorPlansItem>> =
    useRef() as RefObject<EuiBasicTable<FloorPlansItem>>;
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();

  const [sortField, setSortField] = useState<keyof FloorPlansItem>("type");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [floorPlansItems, setFloorPlansItems] = useState<FloorPlansItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<FloorPlansItem[]>([]);
  const [previewedPlanItem, setPreviewedPlanItem] =
    useState<FloorPlansItem | null>(null);
  const [previewedPlan, setPreviewedPlan] = useState<WOFloorPlan | null>(null);

  const handleViewClick = (item: FloorPlansItem) => {
    setPreviewedPlanItem(item || null);
    const selectedPlan = floorPlans?.find((plan) => plan.id === item.planId);
    setPreviewedPlan(selectedPlan || null);
  };

  const columns: EuiBasicTableColumn<FloorPlansItem>[] = [
    {
      field: "name",
      name: "Plan Name",
      render: (name: string) => {
        return <TruncatedText text={name} truncate={true} />;
      },
    },
    {
      field: "type",
      name: "Plan Type",
      sortable: true,
      render: (type: string) => {
        return <TruncatedText text={type} truncate={true} />;
      },
    },
    {
      field: "sublocation",
      name: "Sublocation",
      render: (sublocation: string) => {
        return <TruncatedText text={sublocation} truncate={true} />;
      },
    },
    {
      field: "description",
      name: "Plan Description",
      render: (description: string) => {
        return <TruncatedText text={description} truncate={true} />;
      },
    },
    {
      name: "",
      field: "planId",
      width: "80px",
      render: (planId: string, item: FloorPlansItem) => (
        <EuiFlexGroup
          wrap={false}
          alignItems="center"
          justifyContent="center"
          gutterSize="m"
          key={planId}
        >
          <EuiText
            style={{
              fontSize: euiTheme.sizes["14px"],
              lineHeight: euiTheme.sizes["21px"],
              color: euiTheme.colors.secondary["500"],
              fontWeight: 400,
              cursor: "pointer",
            }}
            onClick={() => handleViewClick(item)}
          >
            View
          </EuiText>
        </EuiFlexGroup>
      ),
    },
  ];

  const sorting: EuiTableSortingType<FloorPlansItem> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const handlePlanPreviewModalClose = () => {
    setPreviewedPlan(null);
  };

  const onTableChange = (changes: Criteria<FloorPlansItem>) => {
    if (!!changes?.sort) {
      const { field: sortField, direction: sortDirection } = changes.sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
      sortItems(sortField, sortDirection, floorPlansItems);
    }
  };

  const onSelectionChange = (selectedPlansItems: FloorPlansItem[]) => {
    setSelectedItems(selectedPlansItems);
  };

  const onPlanItemSelect = (selectedPlansItem: FloorPlansItem) => {
    setSelectedItems([...selectedItems, selectedPlansItem]);
  };

  const isPreviewedPlanSelected = () => {
    return (
      !!previewedPlanItem &&
      selectedItems
        .map((item) => item.itemId)
        .includes(previewedPlanItem?.itemId)
    );
  };

  const removeItemFromSelected = (item: FloorPlansItem) => {
    const filteredSelectedItems = selectedItems.filter(
      (selectedItem) => item.id !== selectedItem.id
    );
    setSelectedItems(filteredSelectedItems);
  };

  useEffect(() => {
    tableRef?.current?.setSelection(selectedItems);
    onSelectPlans(selectedItems);
  }, [selectedItems]);

  const selection: EuiTableSelectionType<FloorPlansItem> = {
    selectable: (item: FloorPlansItem) => true,
    onSelectionChange: onSelectionChange,
    selectableMessage: (selectable, item) =>
      !selectable ? "User is currently offline" : "",
  };

  const sortItems = (
    newSortField: keyof FloorPlansItem,
    newSortDirection: "asc" | "desc",
    items: FloorPlansItem[]
  ) => {
    const sortedItems = SortingHelper.sortItems<FloorPlansItem>(
      newSortField,
      newSortDirection,
      items
    );
    setFloorPlansItems(sortedItems);
    const newSelectedItems: FloorPlansItem[] = [];
    selectedItems.forEach((selectedItem) => {
      const newSelectedItem = sortedItems.find(
        (item) => item.id === selectedItem.id
      );
      if (newSelectedItem) newSelectedItems.push(newSelectedItem);
    });
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    if (!!floorPlans?.length) {
      let plans: FloorPlansItem[] = [];
      floorPlans.forEach((plan) => {
        const affectedAreas: FloorPlansItem[] = [];
        if (!!plan.floorPlanAssets?.length) {
          plan.floorPlanAssets
            .filter((fpa) => fpa.assetId === assetId)
            .forEach((fpa) => {
              if (!!fpa.mapAffectedArea?.features?.length) {
                fpa.mapAffectedArea?.features.forEach(
                  (feature: AffectedAreaFeature, index: number) => {
                    affectedAreas.push({
                      id: `${fpa.id}-${index}`,
                      itemId: fpa.id,
                      name: feature.properties?.name,
                      type: AssetPlansTypeEnum.AFFECTED_AREA,
                      planId: plan.id,
                      sublocation: plan.location?.name,
                      description: feature.properties?.description,
                    });
                  }
                );
              }
            });
        }
        plans.push({
          id: plan.id,
          itemId: plan.id,
          name: plan.name,
          type: AssetPlansTypeEnum.ASSET_PLAN,
          planId: plan.id,
          sublocation: plan.location?.name,
          description: plan.description,
        });
        plans = plans.concat(affectedAreas);
      });
      sortItems(sortField, sortDirection, plans);
    } else {
      setFloorPlansItems([]);
    }
  }, [floorPlans, assetId]);

  return (
    <>
      <EuiFlexGroup
        direction="column"
        wrap={false}
        justifyContent="flexStart"
        alignItems="stretch"
        gutterSize="none"
      >
        <>
          {!!floorPlansItems?.length ? (
            <>
              {!!selectedItems?.length ? (
                <EuiFlexItem
                  style={{
                    width: "fit-content",
                    maxWidth: "100%",
                    padding: euiTheme.size.s,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    rowGap: euiTheme.size.s,
                    columnGap: euiTheme.size.base,
                  }}
                >
                  {selectedItems.map((item, index) => (
                    <SimpleBadge
                      key={item.id + index}
                      label={item.name}
                      onIconClick={() => removeItemFromSelected(item)}
                    />
                  ))}
                </EuiFlexItem>
              ) : null}
              <EuiFlexItem
                style={{
                  marginTop: !!selectedItems?.length ? 0 : euiTheme.size.s,
                  maxHeight: tableMaxHeight,
                  overflow: "scroll",
                }}
              >
                <EuiBasicTable
                  className={"table-inner-scroll"}
                  ref={tableRef}
                  tableCaption="Add Asset FloorPlans table"
                  items={floorPlansItems}
                  itemId="id"
                  rowHeader="name"
                  columns={columns}
                  sorting={sorting}
                  isSelectable={true}
                  selection={selection}
                  onChange={onTableChange}
                />
              </EuiFlexItem>
            </>
          ) : (
            <>
              <EuiText
                style={{
                  fontSize: euiTheme.sizes["14px"],
                  lineHeight: euiTheme.sizes["21px"],
                  padding: `${euiTheme.size.s} 0`,
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Selected Asset has not been placed on any plan or associated
                Affected Area yet
              </EuiText>
            </>
          )}
        </>
      </EuiFlexGroup>
      {!!previewedPlan ? (
        <WorkOrderCreatePlanPreviewModal
          isPlanPreviewOpen={!!previewedPlan}
          assetId={assetId}
          companyId={companyId}
          plan={previewedPlan}
          planItem={previewedPlanItem}
          sublocationId={sublocationId}
          closeModal={handlePlanPreviewModalClose}
          selectPlanItem={onPlanItemSelect}
          isPlanItemSelected={isPreviewedPlanSelected()}
        />
      ) : null}
    </>
  );
};

export default WorkOrderCreateAddAssetPlansTablePresenter;
