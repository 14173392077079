import { QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";

import {
  FolderWithRelationsFragmentFragment,
  FoldersQuery,
  FoldersQueryVariables,
  useFoldersQuery,
} from "../graphql/graphql";

const useFolder: (
  folderId: string,
  baseOptions: QueryHookOptions<FoldersQuery, FoldersQueryVariables>
) => {
  folder: FolderWithChildren | undefined;
  refetch: () => void;
  loading: boolean;
} = (folderId, baseOptions = {}) => {
  const { data, refetch, loading } = useFoldersQuery({
    ...baseOptions,
  });
  const [foldersWithChildren, setFoldersWithChildren] =
    useState<FolderWithChildren>();

  useEffect(() => {
    if (!data) return;

    const foldersLength = data.folders.length;

    const findChildren = (
      parentFolder: FolderWithRelationsFragmentFragment
    ): FolderWithChildren => {
      let children = [];
      for (let i = 0; i < foldersLength; i++) {
        const folder = data.folders[i];
        if (folder.parentId === parentFolder.id) children.push(folder);
      }
      return { ...parentFolder, children };
    };

    const folder = data.folders.find((f) => f.id === folderId);
    if (folder) setFoldersWithChildren(findChildren(folder));
  }, [data, folderId]);

  return { folder: foldersWithChildren, refetch, loading };
};

export default useFolder;

gql`
  query Folder($id: UUID!) {
    folder(id: $id) {
      ...FolderWithRelationsFragment
    }
  }
  query Folders {
    folders {
      ...FolderWithRelationsFragment
    }
  }
`;

export interface FolderWithChildren
  extends FolderWithRelationsFragmentFragment {
  children: FolderWithRelationsFragmentFragment[];
}
