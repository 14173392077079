import { useReactiveVar } from "@apollo/client";
import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";
import { isEqual } from "lodash";
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { useDebounce } from "react-use";

import { useUserWithCompaniesQuery } from "../../../graphql/graphql";
import { useWorkOrderUpdateMutation } from "../../../graphql/mutations/workOrders";
import { useWOGetWorkOrderByIdQuery } from "../../../graphql/queries/workOrders";
import { layoutOptionsVar } from "../../../graphql/reactiveVariables";
import { workOrderInputMapper } from "../helpers/workOrderInputMapper";
import { toastVar } from "../helpers/workOrdersReactiveVariables";
import { WorkOrder } from "../models/workOrder";
import WorkOrderEditPagePresenter from "./presenter";

interface WorkOrderEditPageProps {}

const WorkOrderEditPage: React.FC<WorkOrderEditPageProps> = () => {
  const debounceTime = 500;
  const navigate = useNavigate();
  const toasts = useReactiveVar(toastVar);
  const [workOrder, setWorkOrder] = React.useState<WorkOrder>();
  const [initialWorkOrder, setInitialWorkOrder] = React.useState<WorkOrder>();
  const [isOrderEditInProgress, setIsOrderEditInProgress] =
    React.useState<boolean>(false);
  const [workOrderUpdateMutation] = useWorkOrderUpdateMutation();
  const { data: userCompaniesData, loading: userInfoLoading } =
    useUserWithCompaniesQuery({ fetchPolicy: "network-only" });
  useDebounce(
    async () => {
      if (
        workOrder &&
        initialWorkOrder &&
        !isEqual(workOrder, initialWorkOrder)
      ) {
        await workOrderUpdateMutation({
          variables: {
            data: workOrder ? workOrderInputMapper(workOrder) : {},
          },
        })
          .then(({ data }) => {
            setInitialWorkOrder(data?.workOrderUpdate);
            setIsOrderEditInProgress(false);
          })
          .catch((errors) => {
            if (errors && initialWorkOrder) {
              setWorkOrder(initialWorkOrder);
              toastVar([
                ...toasts,
                {
                  id: `WorkOrderEditErrorMassage${
                    toasts.length + 1 + Math.random()
                  }`,
                  color: "danger",
                  text: "Work Order has not been updated",
                },
              ]);
            }
          });
      }
    },
    debounceTime,
    [initialWorkOrder, workOrder]
  );

  let { workOrderId } = useParams();

  const {
    data: workOrderData,
    loading,
    refetch,
  } = useWOGetWorkOrderByIdQuery({
    variables: { workOrderId },
    fetchPolicy: "network-only",
    skip:
      !workOrderId ||
      !userCompaniesData ||
      (!!workOrder?.id && workOrder.id === workOrderId),
  });
  const onWorkOrderChange = (newWorkOrder: Partial<WorkOrder>) => {
    const updatedWorkOrder = { ...workOrder, ...newWorkOrder };
    if (!isEqual(workOrder, updatedWorkOrder)) {
      setWorkOrder((workOrder) => {
        return {
          ...workOrder,
          ...newWorkOrder,
        };
      });
    }
  };

  const reloadWorkOrder = () => {
    refetch({ workOrderId }).then(({ data }) => {
      if (data?.workOrder) {
        setInitialWorkOrder(data.workOrder);
      }
    });
  };

  const handleChangeIsEditMode = useCallback((isEditMode: boolean) => {
    setIsOrderEditInProgress(() => isEditMode);
  }, []);

  useEffect(() => {
    if (workOrderData && userCompaniesData) {
      const isUserHaveAccessToCompany =
        !!userCompaniesData.user.companyUsers.find(
          (cu) => cu.company.id === workOrderData.workOrder?.companyId
        );
      !isUserHaveAccessToCompany && navigate("/*", { replace: true });
    }
    if (!loading && workOrderData?.workOrder) {
      layoutOptionsVar({
        title: `Work Order #${workOrderData?.workOrder?.key}`,
      });
      setInitialWorkOrder(workOrderData?.workOrder);
    }
  }, [loading, workOrderData, userCompaniesData]);

  useEffect(() => {
    if (initialWorkOrder) {
      setWorkOrder(() => initialWorkOrder);
    }
  }, [initialWorkOrder]);

  return (
    <>
      <Helmet>
        <title>Work Order</title>
      </Helmet>
      {!loading && !userInfoLoading && workOrder ? (
        <>
          <WorkOrderEditPagePresenter
            workOrder={workOrder}
            changeIsEdit={handleChangeIsEditMode}
            reloadWorkOrder={reloadWorkOrder}
            onWorkOrderChange={onWorkOrderChange}
          />
          <ReactRouterPrompt when={isOrderEditInProgress}>
            {({ isActive, onConfirm, onCancel }) =>
              isActive && (
                <div className="modal-warning-lightbox">
                  <div className="modal-warning-container">
                    <div className={"modal-warning-header"}>Warning</div>
                    <p className={"modal-warning-message"}>
                      If you leave the WO creation process, you will lose all
                      entered data. Are you sure you want to leave this page?
                    </p>
                    <div className="modal-warning-footer">
                      <button className={"button-cancel"} onClick={onCancel}>
                        No, stay here
                      </button>
                      <button className={"button-ok"} onClick={onConfirm}>
                        Yes, leave
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          </ReactRouterPrompt>
        </>
      ) : (
        <EuiFlexGroup
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "70vh" }}
        >
          <EuiLoadingSpinner size="xxl" />
        </EuiFlexGroup>
      )}
    </>
  );
};

export default WorkOrderEditPage;
