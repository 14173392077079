import { Box, Text } from "@chakra-ui/react";
import React from "react";

import { StripeBankAccountFragmentFragment } from "../../../graphql/graphql";
import BankAccountIcon from "../../icons/bankAccount";

interface BankAccountProps {
  bankAccount: StripeBankAccountFragmentFragment;
}

const BankAccount: React.FC<BankAccountProps> = ({ bankAccount }) => {
  return (
    <>
      <Box marginRight="3">
        <Text as="span" color="gray.700">
          <BankAccountIcon boxSize="50px" />
        </Text>
      </Box>
      <Box
        marginRight="2"
        fontSize="sm"
        color="black"
        flexGrow={1}
        textAlign="left"
      >
        <Box>
          <Box as="span" textTransform="uppercase">
            {bankAccount.bank_name}{" "}
          </Box>
          <Box lineHeight="21px" display={{ md: "inline" }}>
            XX{bankAccount?.last4}
          </Box>
        </Box>
        {!!bankAccount.account_holder_type && (
          <Box marginTop="1" color="gray.600">
            <Box as="span">{bankAccount.account_holder_type}</Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BankAccount;
