import {
  Box,
  Button,
  Flex,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zonedTimeToUtc } from "date-fns-tz";
import { cloneDeep, sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "react-fast-compare";

import {
  getAssetFieldsInitialValues,
  getAssetFieldsValidationSchema,
} from "../../../components/elements/assetFields";
import AssetIcon from "../../../components/elements/assetIcons";
import ModalNewForm from "../../../components/elements/defaultAssetMaintenanceInput/modalNewForm";
import LabeledMenuButton from "../../../components/elements/labeledMenuButton";
import PageSpinner from "../../../components/elements/pageSpinner";
import Spreadsheet, {
  ColumnProps,
} from "../../../components/elements/spreadsheet";
import AddMultiple from "../../../components/icons/addMultiple";
import Logo from "../../../components/icons/logo";
import {
  ASSET_CREATED_MESSAGE,
  HIGHLIGHTED_ERROR_MESSAGE,
} from "../../../constants/lang/en";
import {
  AssetsDocument,
  CompanyStartUpDocument,
  useAssetCreateMutation,
  useAssetTypeLazyQuery,
} from "../../../graphql/graphql";
import useAssetTypes from "../../../hooks/useAssetTypes";
import useCompanyUsers from "../../../hooks/useCompanyUsers";
import { setGenericMessage } from "../../../utils/serverErrors";
import {
  assetMaintenanceFormValidationSchema,
  assetTypeIdSchema,
  companyUserIdsSchema,
  descriptionSchema,
  nameSchema,
  yupArray,
  yupObject,
} from "../../../utils/validation";
import { AssetsFormData } from "./presenter";

interface AssetBulkAddProps {}

interface AssetBulkAddContentProps {
  onClose: () => void;
}

export interface SheetDataRowItem {
  name: string;
  poles: number;
  description: string;
  id: string | undefined;
  isDuplicate: boolean;
  companyUserIds?: string[];
  [key: string]: any;
}

const AssetBulkAdd: React.FC<AssetBulkAddProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box display={{ xs: "none", md: "block" }}>
      <Tooltip label="Bulk add" hasArrow lineHeight="1">
        <Button
          variant="icon"
          colorScheme="gray"
          aria-label="Bulk add"
          onClick={onOpen}
          marginX={3}
          height={8}
        >
          <AddMultiple boxSize={6} />
        </Button>
      </Tooltip>
      {isOpen && <AssetBulkAddContent onClose={onClose} />}
    </Box>
  );
};

const AssetBulkAddContent: React.FC<AssetBulkAddContentProps> = ({
  onClose,
}) => {
  const assetTypes = useAssetTypes();
  const [fetchAssetType, { data }] = useAssetTypeLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  const {
    isOpen: isServiceScheduleOpen,
    onOpen: onServiceScheduleOpen,
    onClose: onServiceScheduleClose,
  } = useDisclosure();

  const [serviceScheduleModalData, setServiceScheduleModalData] =
    useState<any>(null);
  const [errors, setErrors] = useState<any[]>([]);
  const toast = useToast();

  const [assetsCreateMutation, { loading }] = useAssetCreateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: AssetsDocument },
      { query: CompanyStartUpDocument },
    ],
  });

  const [selectedAssetTypeId, setSelectedAssetTypeId] = React.useState<
    string | null
  >();

  useEffect(() => {
    if (assetTypes) setSelectedAssetTypeId((id) => id ?? assetTypes[0].id);
  }, [assetTypes]);

  const selectedAssetType = data?.assetType;
  React.useEffect(() => {
    if (!selectedAssetTypeId) return;
    fetchAssetType({ variables: { id: selectedAssetTypeId } });
  }, [fetchAssetType, selectedAssetTypeId]);

  const companyUsers = useCompanyUsers();

  const initialData: SheetDataRowItem[] = useMemo(() => {
    return Array(30)
      .fill("")
      .map((_, index) => {
        let initialValues: any = {
          number: index + 1,
          name: "",
          description: "",
          companyUserIds: [] as string[],
          assetTypeId: selectedAssetType?.id,
          assetMaintenancesInput: selectedAssetType?.maintenanceTemplates.length
            ? Array.from(Array(selectedAssetType.maintenanceTemplates.length))
            : [],
        };
        if (selectedAssetType) {
          initialValues = {
            ...initialValues,
            ...getAssetFieldsInitialValues(selectedAssetType),
          };
        }

        const serviceSchedules = selectedAssetType?.maintenanceTemplates.reduce(
          (result: { [key: string]: boolean }, maintenanceTemplate) => {
            result[maintenanceTemplate.id] = false;
            return result;
          },
          {}
        );

        if (serviceSchedules) {
          initialValues = {
            ...initialValues,
            ...serviceSchedules,
          };
        }

        return initialValues;
      });
  }, [selectedAssetType]);

  const [clonedData, setClonedData] = useState<SheetDataRowItem[]>();
  useEffect(() => {
    setClonedData(cloneDeep(initialData));
  }, [initialData]);

  const columns: ColumnProps[] = useMemo(() => {
    const columnData: ColumnProps[] = [
      {
        field: "number",
        cellType: "INPUT",
        editable: false,
        styleProps: {
          centerAligned: true,
          minWidth: 70,
          maxWidth: 70,
          hidden: true,
        },
      },
      {
        field: "name",
        headerName: "asset name",
        cellType: "INPUT",
        styleProps: {
          minWidth: 200,
        },
      },
      {
        field: "description",
        cellType: "TEXT",
        styleProps: {
          minWidth: 200,
        },
      },
      ...(!!selectedAssetType
        ? sortBy(selectedAssetType.assetFields, "order").map((assetField) => {
            return {
              headerName: assetField.label,
              cellType: assetField.type,
              field: assetField.id,
              styleProps: {
                minWidth: 150,
              },
              selectOptions: assetField.selectOptions
                ? assetField.selectOptions.split("\n").map((option) => ({
                    label: option,
                    value: option,
                  }))
                : [],
            };
          })
        : []),
      {
        field: "companyUserIds",
        headerName: "Users",
        cellType: "MULTISELECT",
        selectOptions: companyUsers,
        headerTooltip:
          "Select the user(s) responsible for servicing this asset",
        styleProps: {
          minWidth: 150,
        },
      },
    ];
    if (selectedAssetType?.maintenanceTemplates.length) {
      const maintenanceTemplates: ColumnProps[] =
        selectedAssetType.maintenanceTemplates.map((template) => {
          return {
            field: template.id,
            headerName: template.name,
            cellType: "BOOLEAN",
            styleProps: {
              minWidth: 190,
            },
          };
        });
      return [...columnData, ...maintenanceTemplates];
    }
    return columnData;
  }, [companyUsers, selectedAssetType]);

  const handleCellValueChange = useCallback(
    ({ newValueParams, sheetData, refreshCells }) => {
      if (!selectedAssetType) return;
      let currentTemplateIndex: number = 0;
      const selectedMaintenanceTemplate =
        selectedAssetType.maintenanceTemplates.find(
          (currentMaintenanceTemplate, index) => {
            if (currentMaintenanceTemplate.id === newValueParams.colDef.field) {
              currentTemplateIndex = index;
              return true;
            }
            return false;
          }
        );
      if (selectedMaintenanceTemplate) {
        const sheetDatum = sheetData[newValueParams.node.rowIndex];
        if (
          newValueParams.newValue &&
          selectedAssetType.maintenanceTemplates[currentTemplateIndex]
        ) {
          setServiceScheduleModalData({
            data: selectedMaintenanceTemplate,
            field: newValueParams.colDef.field,
            sheetDatum,
            currentTemplateIndex,
            refreshCells: () =>
              refreshCells({ rows: [newValueParams.node.rowIndex] }),
          });
          setTimeout(onServiceScheduleOpen);
        } else {
          sheetDatum.assetMaintenancesInput[currentTemplateIndex] = undefined;
          sheetDatum[newValueParams.colDef.field] = false;
        }
      } else {
        const sheetDatum = sheetData[newValueParams.node.rowIndex];
        sheetDatum[newValueParams.colDef.field] = newValueParams.newValue;
      }
    },
    [onServiceScheduleOpen, selectedAssetType]
  );

  const handleValidation = useCallback(
    (sheetData: SheetDataRowItem[]) => {
      let validationRule: any = {
        name: nameSchema.label("Asset name"),
        description: descriptionSchema,
        assetTypeId: assetTypeIdSchema,
        companyUserIds: companyUserIdsSchema.when("assetMaintenancesInput", {
          is: (assetMaintenancesInput: any[]) =>
            assetMaintenancesInput.some((ami: any) => !!ami),
          then: yupArray().required().min(1),
          otherwise: yupArray().optional().min(0),
        }),
        assetMaintenancesInput: yupArray().of(
          assetMaintenanceFormValidationSchema
        ),
      };
      if (selectedAssetType) {
        validationRule = {
          ...validationRule,
          ...getAssetFieldsValidationSchema(selectedAssetType),
        };
      }
      const validationSchema = yupObject().shape(validationRule);

      let hasError = false;
      const validationStatus = sheetData.map(
        (sheetDatum: SheetDataRowItem, index: number) => {
          if (isEqual(sheetDatum, initialData[index])) return false;

          try {
            validationSchema.validateSync(sheetDatum, { abortEarly: false });
            return false;
          } catch (error) {
            hasError = true;
            return error;
          }
        }
      );
      setErrors(validationStatus);
      return !hasError;
    },
    [initialData, selectedAssetType]
  );

  const handleSubmit = React.useCallback(
    async (sheetData: SheetDataRowItem[]) => {
      const isValidationSuccess = handleValidation(sheetData);
      const formattedData: AssetsFormData[] = [];
      if (isValidationSuccess) {
        sheetData.forEach((sheetDatum) => {
          if (!sheetDatum.name) return;
          const formattedDatum: AssetsFormData = {
            name: sheetDatum.name,
            description: sheetDatum.description,
            assetTypeId: sheetDatum.assetTypeId,
            companyUserIds: sheetDatum.companyUserIds || [],
            assetFieldValuesInput: [],
            assetMaintenancesInput: [],
          };
          formattedDatum.assetMaintenancesInput =
            sheetDatum.assetMaintenancesInput
              .filter((ami: any) => !!ami)
              .map((ami: any) => ({
                ...ami,
                startDateTime: zonedTimeToUtc(
                  ami.startDateTime,
                  ami.timezone
                ).getTime(),
              }));
          if (selectedAssetType) {
            selectedAssetType.assetFields.forEach((assetField) => {
              if (sheetDatum[assetField.id]) {
                formattedDatum.assetFieldValuesInput.push({
                  assetFieldId: assetField.id,
                  value: `${sheetDatum[assetField.id]}`,
                });
              }
            });
          }

          formattedData.push(formattedDatum);
        });
        if (formattedData.length) {
          try {
            const { data: serverData, errors } = await assetsCreateMutation({
              variables: { data: formattedData },
            });
            if (errors) {
              toast({
                description: setGenericMessage(errors),
                status: "error",
                position: "top",
                isClosable: true,
              });
              return;
            } else if (serverData) {
              toast({
                description: ASSET_CREATED_MESSAGE,
                status: "success",
                position: "top",
                isClosable: true,
              });
            }
          } catch (error) {
            toast({
              description: setGenericMessage(error),
              status: "error",
              position: "top",
              isClosable: true,
            });
          }
        }
        onClose();
      } else {
        toast({
          description: HIGHLIGHTED_ERROR_MESSAGE,
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [assetsCreateMutation, handleValidation, onClose, selectedAssetType, toast]
  );

  const modalHeaderContent = useMemo(
    () => (
      <Flex alignItems="center" justifyContent="center" height="56px">
        <Menu>
          {({ isOpen }) => (
            <>
              <LabeledMenuButton
                isOpen={isOpen}
                label="Asset Type"
                value={
                  selectedAssetType ? (
                    <Flex alignItems="center">
                      <AssetIcon
                        iconName={selectedAssetType.iconName}
                        iconColor={selectedAssetType.misc.resolvedIconColor}
                        iconType={selectedAssetType.iconType}
                        iconSize="xs"
                        marginRight="2"
                      />
                      <Text marginLeft="1">
                        {selectedAssetType.misc.resolvedName}
                      </Text>
                    </Flex>
                  ) : null
                }
                styleProps={{ maxWidth: "auto" }}
              />
              {!!assetTypes && (
                <MenuList>
                  {assetTypes.map((assetType) => (
                    <MenuItem
                      key={assetType.id}
                      onClick={() => setSelectedAssetTypeId(assetType.id)}
                    >
                      <AssetIcon
                        iconName={assetType.iconName}
                        iconColor={assetType.misc.resolvedIconColor}
                        iconType={assetType.iconType}
                        iconSize="xs"
                        marginRight="2"
                      />
                      <Text marginLeft="1">{assetType.misc.resolvedName}</Text>
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </>
          )}
        </Menu>
        <Box paddingLeft="1">
          <Tooltip
            label="Save time by using this form to enter multiple assets at once instead of one at a time."
            placement="right"
            shouldWrapChildren
            hasArrow
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </Box>
      </Flex>
    ),
    [assetTypes, selectedAssetType]
  );

  return assetTypes && clonedData ? (
    <>
      <Spreadsheet
        data={clonedData}
        errors={errors}
        columns={columns}
        handleCellValueChange={handleCellValueChange}
        handleSubmit={handleSubmit}
        isLoading={loading}
        onClose={onClose}
        modalHeaderContent={modalHeaderContent}
      />
      {serviceScheduleModalData && (
        <ModalNewForm
          isOpen={isServiceScheduleOpen}
          onClose={() => {
            serviceScheduleModalData.sheetDatum.assetMaintenancesInput[
              serviceScheduleModalData.currentTemplateIndex
            ] = undefined;
            serviceScheduleModalData.sheetDatum[
              serviceScheduleModalData.field
            ] = false;
            serviceScheduleModalData.refreshCells();
            onServiceScheduleClose();
          }}
          handleSubmit={(data) => {
            serviceScheduleModalData.sheetDatum.assetMaintenancesInput[
              serviceScheduleModalData.currentTemplateIndex
            ] = data;
            serviceScheduleModalData.sheetDatum[
              serviceScheduleModalData.field
            ] = true;
            serviceScheduleModalData.refreshCells();
            onServiceScheduleClose();
          }}
          name={serviceScheduleModalData.data.name}
          intervalType={serviceScheduleModalData.data.intervalType}
          intervalValue={serviceScheduleModalData.data.intervalValue}
          startDateTime={serviceScheduleModalData.data.startDateTime}
          timezone={serviceScheduleModalData.data.timezone}
          remindBeforeType={serviceScheduleModalData.data.remindBeforeType}
          remindBeforeValue={serviceScheduleModalData.data.remindBeforeValue}
          durationType={serviceScheduleModalData.data.durationType}
          durationValue={serviceScheduleModalData.data.durationValue}
          title={serviceScheduleModalData.data.name}
        />
      )}
    </>
  ) : (
    <Modal isOpen onClose={onClose} size="full">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Logo />
          </ModalHeader>
          <ModalCloseButton color="gray.300" top="3" right="6" zIndex="10" />
          <ModalBody display="flex" flexDirection="column">
            <PageSpinner />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AssetBulkAdd;
