import { useReactiveVar } from "@apollo/client";
import { Box, Link as ChakraLink, Flex, Spacer, Text } from "@chakra-ui/react";
import { FC } from "react";

import { currentCompanyPlanVar } from "../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../router";
import Link from "../link";

interface FooterProps {
  showSystemStatus?: boolean;
}

const Footer: FC<FooterProps> = ({ showSystemStatus = false }) => {
  const plan = useReactiveVar(currentCompanyPlanVar);

  return (
    <Flex direction={{ base: "column", md: "row" }} mx="4">
      <Box fontSize="xs" color="gray.600" whiteSpace="normal">
        <ChakraLink
          href={`${
            process.env.REACT_APP_LIVE_WEBSITE_URL + getRoutePath("terms")
          }`}
          isExternal
          _hover={{ color: "secondary.500" }}
        >
          Terms
        </ChakraLink>
        <ChakraLink
          href={
            process.env.REACT_APP_LIVE_WEBSITE_URL + getRoutePath("privacy")
          }
          isExternal
          _hover={{ color: "secondary.500" }}
          marginLeft="4"
        >
          Privacy
        </ChakraLink>
        {plan?.productName === "Professional" && (
          <ChakraLink
            href={
              process.env.REACT_APP_LIVE_WEBSITE_URL +
              getRoutePath("mspAgreement")
            }
            isExternal
            _hover={{ color: "secondary.500" }}
            marginLeft="4"
          >
            MSP Agreement
          </ChakraLink>
        )}
        {showSystemStatus && (
          <Link
            variant="link"
            fontSize="xs"
            color="gray.600"
            to={getRoutePath("systemStatus")}
            _hover={{ color: "secondary.500" }}
            marginLeft="4"
          >
            System Status
          </Link>
        )}
      </Box>
      <Spacer />
      <Text fontSize="xs" color="gray.600" mt={{ base: "4", md: "0" }}>
        &copy; 2021-{new Date().getFullYear()} CriticalAsset, Inc.
      </Text>
    </Flex>
  );
};

export default Footer;
