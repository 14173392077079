import { useReactiveVar } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq } from "lodash";
import React from "react";

import {
  ASSET_TYPE_HIDDEN_MESSAGE,
  ASSET_TYPE_SHOWN_MESSAGE,
} from "../../constants/lang/en";
import {
  AssetTypeFragmentFragment,
  useCompanyQuery,
  useCompanyUpdateHiddenCategoryIdsMutation,
} from "../../graphql/graphql";
import { assetTypeIdsWithAssetsVar } from "../../graphql/reactiveVariables";
import { setGenericMessage } from "../../utils/serverErrors";

interface AssetTypeVisibilityToggleProps {
  assetType: AssetTypeFragmentFragment;
}

const AssetTypeVisibilityToggle: React.FC<AssetTypeVisibilityToggleProps> = ({
  assetType,
}) => {
  const { data } = useCompanyQuery();
  const assetTypeIdsWithAssets = useReactiveVar(assetTypeIdsWithAssetsVar);
  const hiddenTypeIds = React.useMemo(
    () => (data ? data.company.hiddenTypeIds : []),
    [data]
  );
  const isHidden = hiddenTypeIds.includes(assetType.id);
  const toast = useToast();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [assetTypeVisibilityToggleMutation, { loading }] =
    useCompanyUpdateHiddenCategoryIdsMutation();

  const actionAllowed =
    isHidden || !assetTypeIdsWithAssets.includes(assetType.id);

  const toggleAssetTypeVisibility = React.useCallback(async () => {
    try {
      const { errors } = await assetTypeVisibilityToggleMutation({
        variables: {
          hiddenTypeIds: uniq(
            isHidden
              ? hiddenTypeIds.filter((i) => i !== assetType.id)
              : [...hiddenTypeIds, assetType.id]
          ),
        },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        onClose();
        toast({
          description: isHidden
            ? ASSET_TYPE_SHOWN_MESSAGE
            : ASSET_TYPE_HIDDEN_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [
    assetTypeVisibilityToggleMutation,
    toast,
    onClose,
    hiddenTypeIds,
    isHidden,
    assetType.id,
  ]);

  return (
    <>
      <Tooltip
        hasArrow
        label={`${isHidden ? "Unhide" : "Hide"} this asset type`}
      >
        <IconButton
          onClick={onOpen}
          variant="icon"
          aria-label={`${isHidden ? "Unhide" : "Hide"} this asset type`}
          colorScheme="graySecondary"
          padding={2}
        >
          <FontAwesomeIcon icon={isHidden ? faEye : faEyeSlash} />
        </IconButton>
      </Tooltip>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              {actionAllowed ? (
                <>
                  Are you sure you want to {isHidden ? "unhide" : "hide"} this
                  asset type?
                  <br />
                  <Text as="strong">{assetType.name}</Text>
                </>
              ) : (
                "Sorry, you can't hide this asset type because there are existing assets for this type"
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              {actionAllowed ? (
                <>
                  <Button
                    ref={cancelRef}
                    disabled={loading}
                    onClick={onClose}
                    width="48%"
                  >
                    No
                  </Button>
                  <Button
                    onClick={toggleAssetTypeVisibility}
                    disabled={loading}
                    colorScheme="red"
                    ml="4%"
                    width="48%"
                  >
                    Yes
                  </Button>
                </>
              ) : (
                <Button
                  ref={cancelRef}
                  disabled={loading}
                  onClick={onClose}
                  width="100%"
                >
                  Ok
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AssetTypeVisibilityToggle;
