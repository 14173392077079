import {
  Box,
  Button,
  Flex,
  Input,
  List,
  ListItem,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../components/elements/formGroup";
import Link from "../../components/elements/link";
import { useUserForgotPasswordMutation } from "../../graphql/graphql";
import { getRoutePath } from "../../router";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import { emailSchema, yupObject } from "../../utils/validation";

interface ForgotPasswordPagePresenterProps {}

const ForgotPasswordPagePresenter: React.FC<
  ForgotPasswordPagePresenterProps
> = () => {
  const toast = useToast();
  const [userForgotPassword, { loading }] = useUserForgotPasswordMutation();
  const navigate = useNavigate();

  const onSubmit = async (
    data: ForgotPasswordFormData,
    { setFieldError }: FormikHelpers<ForgotPasswordFormData>
  ) => {
    try {
      const { errors } = await userForgotPassword({ variables: { data } });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else {
        toast({
          description: `We've sent an email to ${data.email} with a link to reset your password`,
          status: "success",
          position: "top",
          isClosable: true,
        });
        navigate(getRoutePath("signIn"));
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ForgotPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      {({ getFieldProps }) => (
        <Form noValidate>
          <Flex
            flexWrap="wrap"
            justifyContent="space-evenly"
            maxWidth="lg"
            padding={{ xl: 12 }}
          >
            <FormGroup label="Email" name="email">
              <Input
                autoFocus
                autoComplete="email"
                type="email"
                {...getFieldProps("email")}
              />
            </FormGroup>
            <Box marginTop="8" marginBottom="4" width="100%">
              <Button width="full" type="submit" isLoading={loading}>
                Send
              </Button>
            </Box>
            <List color="gray.600" textAlign="center">
              <ListItem marginBottom="3">
                Already have an account?{" "}
                <Link
                  to={getRoutePath("signIn")}
                  variant="link"
                  colorScheme="secondary"
                >
                  Sign in
                </Link>
              </ListItem>
            </List>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordPagePresenter;

const ForgotPasswordValidationSchema = yupObject().shape({
  email: emailSchema,
});

type ForgotPasswordFormData = {
  email: string;
};

gql`
  mutation userForgotPassword($data: UserForgotPasswordInput!) {
    userForgotPassword(data: $data)
  }
`;
