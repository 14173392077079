import { BusinessSeverityEnum, ScheduleTypeEnum } from "../models/enums";
import { CompanyContactTypeEnum, WorkOrder } from "../models/workOrder";

export const initialWorkOrder: WorkOrder = {
  name: "",
  description: "",
  companyId: null,
  locationId: null,
  floorPlanId: "60914b35-3d66-4dac-8775-01dc0cee604b",
  assetId: null,
  requestedById: null,
  companyContactType: CompanyContactTypeEnum.LEAVE_BLANK,
  companyContactEmail: null,
  companyContactOccupation: null,
  companyContactFullName: null,
  companyContactTeamName: null,
  companyContactMobilePhone: null,
  companyContactWorkPhone: null,
  companyContactUserId: null,
  locationContactType: CompanyContactTypeEnum.SAME_AS_COMPANY,
  locationContactEmail: null,
  locationContactOccupation: null,
  locationContactFullName: null,
  locationContactTeamId: null,
  locationContactTeamName: null,
  locationContactMobilePhone: null,
  locationContactWorkPhone: null,
  locationContactUserId: null,
  orderPriority: null,
  businessSeverity: BusinessSeverityEnum.MODERATE,
  parentOrderId: null,
  orderTypeId: null,
  scheduleType: ScheduleTypeEnum.OPEN_DUE,
  startDateTime: null,
  endDateTime: null,
  serviceScheduleTimezone: null,
  consultingUserIds: null,
  informingUserIds: null,
  accountableUserId: null,
  responsibleUserIds: null,
  assetWorkOrders: null,
  attachments: [],
  serviceCategoryId: null,
};
