import { Box, Button, DrawerBody, DrawerFooter, Text } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";

import Autosuggest from "../../../components/elements/autosuggest";
import FormGroup from "../../../components/elements/formGroup";
import MaintenanceIntervalField from "../../../components/elements/maintenanceIntervalField";
import { MaintenanceTemplateFragmentFragment } from "../../../graphql/graphql";
import {
  MaintenanceTemplateFormData,
  maintenanceTemplateFormValidationSchema,
} from "../assetTypesMaintenancesCreate/presenter";

interface AssetTypeMaintenancesEditPresenterProps {
  maintenance: MaintenanceTemplateFragmentFragment;
  isLoading: boolean;
  handleSubmit: (
    values: MaintenanceTemplateFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
}

const AssetTypeMaintenancesEditPresenter: React.FC<
  AssetTypeMaintenancesEditPresenterProps
> = ({ isLoading, handleSubmit, maintenance }) => {
  return (
    <>
      <DrawerBody>
        <Formik
          initialValues={{
            assetTypeId: maintenance.assetTypeId,
            name: maintenance.name,
            description: maintenance.description,
            intervalType: maintenance.intervalType,
            intervalValue: maintenance.intervalValue,
          }}
          validationSchema={maintenanceTemplateFormValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form noValidate id="maintenance_template_edit">
            <FormGroup label="Title" name="name">
              <Autosuggest
                items={[
                  "Service reminder",
                  "Inspection",
                  "Tune up",
                  "Calibration",
                ]}
                autoComplete="off"
                name="name"
                autoFocus
              />
            </FormGroup>
            <Box as="fieldset" marginTop="3">
              <MaintenanceIntervalField alwaysOn hideEndOption />
            </Box>
            <Text fontSize="xs" color="gray.600" mt="2">
              A reminder will be sent starting on the day the user selects when
              they create an asset for this asset type
            </Text>
          </Form>
        </Formik>
      </DrawerBody>
      <DrawerFooter>
        <Button
          width="full"
          type="submit"
          isLoading={isLoading}
          form="maintenance_template_edit"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
};

export default AssetTypeMaintenancesEditPresenter;

gql`
  mutation MaintenanceTemplateUpdate(
    $id: UUID!
    $data: MaintenanceTemplateInput!
  ) {
    maintenanceTemplateUpdate(id: $id, data: $data) {
      ...MaintenanceTemplateFragment
    }
  }
`;
