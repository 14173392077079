import { Icon, IconProps } from "@chakra-ui/react";

const DuckworkInsulation = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M52.086 55.863C55.31 57.562 59.54 58.498 64 58.498c4.46 0 8.692-.936 11.914-2.635 3.71-1.957 5.754-4.734 5.754-7.82 0-3.085-2.044-5.862-5.754-7.818-3.222-1.7-7.453-2.635-11.914-2.635-4.46 0-8.691.936-11.914 2.635-3.71 1.956-5.754 4.733-5.754 7.819 0 3.085 2.044 5.862 5.754 7.819Zm2.245-11.381c2.506-1.322 6.03-2.08 9.669-2.08 3.639 0 7.163.758 9.669 2.08 1.995 1.052 3.186 2.383 3.186 3.561 0 1.179-1.191 2.51-3.186 3.562-2.506 1.322-6.03 2.08-9.669 2.08-3.639 0-7.163-.758-9.669-2.08-1.995-1.052-3.186-2.383-3.186-3.562 0-1.178 1.191-2.51 3.186-3.561Z"
      fill="currentColor"
    />
    <path
      d="M92.022 29.971C84.5 25.476 74.547 23 64 23c-10.547 0-20.5 2.476-28.022 6.971-7.912 4.727-12.268 11.146-12.268 18.073V70.68c0 6.147 2.986 11.459 8.878 15.805V105h4.813c0-5.115 4.161-9.275 9.275-9.275H64c10.547 0 20.5-2.476 28.022-6.972 7.911-4.727 12.268-11.145 12.268-18.072V48.044c0-6.927-4.357-13.346-12.268-18.073Zm-53.576 4.131c6.788-4.055 15.863-6.29 25.554-6.29 9.69 0 18.766 2.235 25.553 6.29 6.4 3.824 9.924 8.775 9.924 13.942 0 5.166-3.524 10.117-9.924 13.94-6.787 4.057-15.862 6.29-25.553 6.29-9.69 0-18.766-2.233-25.553-6.29-6.4-3.823-9.924-8.774-9.924-13.94s3.524-10.118 9.924-13.942Zm-5.858 45.22v.825c-2.728-2.813-4.066-5.939-4.066-9.466V60.034c1.94 2.23 4.438 4.28 7.456 6.082a42.323 42.323 0 0 0 4.915 2.503c-4.77 1.223-8.305 5.558-8.305 10.702Zm66.89-8.641c0 5.166-3.525 10.117-9.924 13.941-6.788 4.056-15.863 6.29-25.554 6.29h-.16v-5.669h-4.813v5.669h-12.35a14.03 14.03 0 0 0-9.275 3.492V79.321a6.241 6.241 0 0 1 6.234-6.234h15.391v5.145h4.813v-5.145H64c10.548 0 20.5-2.475 28.023-6.97 3.017-1.804 5.516-3.854 7.455-6.083V70.68Z"
      fill="currentColor"
    />
  </Icon>
);

export default DuckworkInsulation;
