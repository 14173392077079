import React from "react";

import FloorPlan from "../../../components/elements/floorPlan";

interface LeafletPageProps {}

const NativeLeafletPage: React.FC<LeafletPageProps> = () => {
  return <FloorPlan />;
};

export default NativeLeafletPage;
