import { makeVar } from "@apollo/client";
import { endOfMonth, startOfMonth } from "date-fns";
import { ReactNode } from "react";

import { PLAN_EDITOR_PROMPT } from "../constants/lang/en";
import { getRandomDarkColor } from "../utils/colors";
import {
  CompanyFragmentFragment,
  CompanyUserRole,
  MaintenanceStatusType,
  PlansFragmentFragment,
  UserWithCompaniesQuery,
} from "./graphql";
import { CurrentCompanyRole, PlanEditorOptions } from "./types";

export const locationTableKeysVar = makeVar<{ [key: string]: any }>({});
export const locationLastOpenedPlanKeysVar = makeVar<{ [key: string]: any }>(
  {}
);
export const categoriesTableKeysVar = makeVar<{ [key: string]: any }>({});

export const currentCompanyRoleVar = makeVar<CurrentCompanyRole>({
  role: CompanyUserRole.Owner,
  isOwner: true,
  isAdmin: true,
  isManager: true,
  isUser: true,
  isViewOnly: true,
});

export const currentUserWithCompaniesVar =
  makeVar<UserWithCompaniesQuery | null>(null);
export const currentCompanyPlanVar = makeVar<PlansFragmentFragment | null>(
  null
);
export const currentCompanyVar = makeVar<CompanyFragmentFragment | null>(null);
export const mainCompanyVar = makeVar<CompanyFragmentFragment | null>(null);

export const isNativeWebViewVar = makeVar<boolean>(false);
export const unreadNotificationsCountVar = makeVar(0);
export const assetWorstStatusVar = makeVar<MaintenanceStatusType>(
  MaintenanceStatusType.Current
);
export const assetTypeIdsWithAssetsVar = makeVar<string[]>([]);
export const assetCategoryIdsWithAssetsVar = makeVar<string[]>([]);

export const calendarOptionsVar = makeVar({
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
});

export const planEditorOptionsVar = makeVar<PlanEditorOptions>({
  planId: "",
  assetIds: [],
  assetPartIds: [],
  assetColors: {},
  assetPartColors: {},
  assetCategoryIds: [],
  assetTypeIds: [],
  assetMaintenanceStatus: [],
  assetSearchQuery: "",
  clusterAssets: false,
  quickDraw: false,
  affectedAreaConnections: false,
  showAssetStatus: true,
  unsavedChanges: false,
});

const keyToWarnWhileChanging = [
  "planId",
  "assetIds",
  "assetPartIds",
  "assetCategoryIds",
  "assetTypeIds",
  "assetMaintenanceStatus",
  "assetSearchQuery",
];

export const changePlanEditorOptions = (
  newOptions: Partial<PlanEditorOptions>
) => {
  const planEditorOptions = planEditorOptionsVar();

  let assetColors = planEditorOptions.assetColors;
  if (newOptions.assetIds) {
    const newAssetColors: any = {};
    newOptions.assetIds.forEach((aid) => {
      if (!assetColors[aid]) newAssetColors[aid] = getRandomDarkColor();
    });
    assetColors = { ...assetColors, ...newAssetColors };
  }

  let assetPartColors = planEditorOptions.assetPartColors;
  if (newOptions.assetPartIds) {
    const newAssetPartColors: any = {};
    newOptions.assetPartIds.forEach((aid) => {
      if (!assetPartColors[aid]) newAssetPartColors[aid] = getRandomDarkColor();
    });
    assetPartColors = { ...assetPartColors, ...newAssetPartColors };
  }

  if (
    !planEditorOptions.unsavedChanges ||
    !Object.keys(newOptions).some((k) => keyToWarnWhileChanging.includes(k)) ||
    window.confirm(PLAN_EDITOR_PROMPT)
  ) {
    planEditorOptionsVar({
      ...planEditorOptions,
      ...newOptions,
      assetColors,
      assetPartColors,
    });
  }
};

export interface LayoutOptions {
  backText?: ReactNode;
  title?: ReactNode;
  subTitle?: string;
  backLink?: string;
  styleProps?: object;
  headerStyleProps?: object;
  currentStep?: number;
  hideSearch?: boolean;
}
export const layoutOptionsVar = makeVar<LayoutOptions>({});
