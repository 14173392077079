import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React from "react";

import { useUserQuery } from "../../../../../graphql/graphql";
import { useWOGetCompanyOwnerQuery } from "../../../../../graphql/queries/workOrders";
import { CompanyContactTypeEnum, WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateCompanyContactInfoPresenter from "./presenter";

interface WorkOrderCreateCompanyContactInfoProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateCompanyContactInfo: React.FC<
  WorkOrderCreateCompanyContactInfoProps
> = ({ workOrder, onWorkOrderChange }) => {
  const { data: currentUser } = useUserQuery();

  const { data, loading } = useWOGetCompanyOwnerQuery({
    variables: {
      companyId: workOrder?.companyId,
    },
    fetchPolicy: "cache-and-network",
    skip: !workOrder?.companyId,
  });

  const defaultOptions: EuiSuperSelectOption<string>[] = [
    {
      inputDisplay: "Leave Blank",
      value: CompanyContactTypeEnum.LEAVE_BLANK,
    },
    {
      inputDisplay: "Add Manually",
      value: CompanyContactTypeEnum.ADD_MANUALLY,
    },
  ];
  const currentUserOption = {
    inputDisplay: "Current User",
    value: CompanyContactTypeEnum.CURRENT_USER,
  };
  const companyAdminOption = {
    inputDisplay: "Company Admin",
    value: CompanyContactTypeEnum.COMPANY_ADMIN,
  };

  const handleContactTypeChange = (newType: CompanyContactTypeEnum) => {
    if (newType === CompanyContactTypeEnum.LEAVE_BLANK) {
      onWorkOrderChange({
        companyContactType: CompanyContactTypeEnum.LEAVE_BLANK,
        companyContactUserId: null,
        companyContactEmail: null,
        companyContactOccupation: null,
        companyContactFullName: null,
        companyContactTeamName: null,
        companyContactMobilePhone: null,
        companyContactWorkPhone: null,
      });
    }
    if (newType === CompanyContactTypeEnum.ADD_MANUALLY) {
      onWorkOrderChange({
        companyContactType: CompanyContactTypeEnum.ADD_MANUALLY,
        companyContactUserId: null,
        companyContactEmail: null,
        companyContactOccupation: null,
        companyContactFullName: null,
        companyContactTeamName: null,
        companyContactMobilePhone: null,
        companyContactWorkPhone: null,
      });
    }
    if (newType === CompanyContactTypeEnum.CURRENT_USER && currentUser?.user) {
      onWorkOrderChange({
        companyContactType: CompanyContactTypeEnum.CURRENT_USER,
        companyContactUserId: currentUser.user.id,
        companyContactEmail: currentUser.user.email,
        companyContactOccupation: null,
        companyContactFullName: currentUser.user.fullName,
        companyContactTeamName: null,
        companyContactMobilePhone: currentUser.user.phone,
        companyContactWorkPhone: null,
      });
    }
    if (
      newType === CompanyContactTypeEnum.COMPANY_ADMIN &&
      data?.getCompanyOwner
    ) {
      onWorkOrderChange({
        companyContactType: CompanyContactTypeEnum.COMPANY_ADMIN,
        companyContactUserId: data.getCompanyOwner.id,
        companyContactEmail: data.getCompanyOwner.email,
        companyContactOccupation: null,
        companyContactFullName: data.getCompanyOwner.fullName,
        companyContactTeamName: null,
        companyContactMobilePhone: data.getCompanyOwner.phone,
        companyContactWorkPhone: null,
      });
    }
  };

  const getOptions = () => {
    return [
      ...defaultOptions,
      ...(!!currentUser?.user ? [currentUserOption] : []),
      ...(!loading && !!data?.getCompanyOwner ? [companyAdminOption] : []),
    ];
  };

  return (
    <WorkOrderCreateCompanyContactInfoPresenter
      workOrder={workOrder}
      selectOptions={getOptions()}
      onWorkOrderChange={onWorkOrderChange}
      onContactInfoChange={handleContactTypeChange}
    />
  );
};

export default WorkOrderCreateCompanyContactInfo;
