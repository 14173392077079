import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  BREAKER_NO_SPACE_MESSAGE,
  BREAKER_POLE_OVERLAP_MESSAGE,
  GENERIC_SAVED_MESSAGE,
} from "../../../../constants/lang/en";
import {
  DEFAULT_BREAKER_ROWS,
  getDefaultBreakerColumns,
} from "../../../../constants/misc";
import {
  useAssetPartUpdateMutation,
  useAssetQuery,
  useAssetUpdateConfigMutation,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../../utils/serverErrors";
import Presenter, { AssetPartFormData } from "./presenter";

interface AssetPartEditPageProps {}

const AssetPartEditPage: React.FC<AssetPartEditPageProps> = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { assetId, assetPartId } = useParams();
  const [searchParams] = useSearchParams();

  const { data: assetData } = useAssetQuery({
    variables: { id: assetId },
  });
  const asset = assetData?.asset;
  const assetPart = asset?.assetParts.find(
    (assetPart) => assetPart.id === assetPartId
  );

  const [assetPartUpdateMutation, { loading }] = useAssetPartUpdateMutation();
  const [assetUpdateConfigMutation, { loading: configLoading }] =
    useAssetUpdateConfigMutation();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (
    data: AssetPartFormData,
    { setFieldError }: FormikHelpers<AssetPartFormData>
  ) => {
    const asset = assetData?.asset;
    if (!asset) return;
    const {
      numberOfColumns = getDefaultBreakerColumns(asset.assetType.name),
      numberOfRows = DEFAULT_BREAKER_ROWS,
      assetPartPositions = {},
    } = asset.config;
    const { columnIndex, rowIndex, poles, ...otherData } = data;

    // Check if breaker poles lie outside
    if (columnIndex + 1 > numberOfColumns || rowIndex + poles > numberOfRows) {
      toast({
        description: BREAKER_NO_SPACE_MESSAGE,
        status: "error",
        position: "top",
        isClosable: true,
      });
      return;
    }

    // Check if breaker poles overlap with existing breakers
    let breakerError = false;
    Array.from(Array(poles)).forEach((_, poleIndex) => {
      const id = assetPartPositions[`${rowIndex + poleIndex}-${columnIndex}`];
      if (id && id !== assetPart?.id) {
        toast({
          description: BREAKER_POLE_OVERLAP_MESSAGE,
          status: "error",
          position: "top",
          isClosable: true,
        });
        breakerError = true;
        return;
      }
    });
    if (breakerError) return;

    try {
      const { data: serverData, errors } = await assetPartUpdateMutation({
        variables: { data: [{ ...otherData, id: assetPartId }] },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        const newBreakerAssetPartPositions: any = {};
        Object.keys(assetPartPositions).forEach((key) => {
          if (assetPartPositions[key] !== serverData.assetPartUpdate[0].id) {
            newBreakerAssetPartPositions[key] = assetPartPositions[key];
          }
        });
        Array.from(Array(poles)).forEach((_, poleIndex) => {
          newBreakerAssetPartPositions[
            `${rowIndex + poleIndex}-${columnIndex}`
          ] = serverData.assetPartUpdate[0].id;
        });
        const { data: serverConfigData, errors } =
          await assetUpdateConfigMutation({
            variables: {
              id: asset.id,
              config: {
                ...asset.config,
                assetPartPositions: newBreakerAssetPartPositions,
              },
            },
          });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else if (serverConfigData) {
          onDrawerClose();
          toast({
            description: GENERIC_SAVED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetsShow", { assetId }));
    }, 500);
  };

  if (!asset || !assetPart) return null;

  return (
    <Presenter
      handleSubmit={onSubmit}
      onDrawerClose={onDrawerClose}
      isOpen={isOpen}
      loading={loading || configLoading}
      assetPart={assetPart}
      asset={asset}
      rowIndex={
        searchParams.has("rowIndex")
          ? +(searchParams.get("rowIndex") as string)
          : undefined
      }
      columnIndex={
        searchParams.has("columnIndex")
          ? +(searchParams.get("columnIndex") as string)
          : undefined
      }
    />
  );
};

export default AssetPartEditPage;

gql`
  mutation AssetPartUpdate($data: [AssetPartUpdateInput!]!) {
    assetPartUpdate(data: $data) {
      ...AssetPartWithRelationsFragment
    }
  }
`;
