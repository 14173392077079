import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import Link from "../../../components/elements/link";
import FolderIcon from "../../../components/icons/folder";
import { FolderWithRelationsFragmentFragment } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import FolderDelete from "../folderDelete";

interface FolderInfoPresenterProps {
  folder: FolderWithRelationsFragmentFragment;
  canEditFolder: boolean;
  canDeleteFolder: boolean;
}

const FolderInfoPresenter: React.FC<FolderInfoPresenterProps> = ({
  folder,
  canEditFolder,
  canDeleteFolder,
}) => {
  const { folderId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ isOpen: true });
  const {
    isOpen: isOpenFolderDelete,
    onOpen: onOpenFolderDelete,
    onClose: onCloseFolderDelete,
  } = useDisclosure();
  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("foldersShow", { folderId: folder.id }));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>{folder.name}</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <FolderIcon width="6" height="auto" marginRight="2" />
            {folder.name}
          </DrawerHeader>
          <DrawerBody>
            <Box>
              <Flex marginBottom="6">
                {canEditFolder && (
                  <Link
                    to={getRoutePath("foldersEdit", { folderId })}
                    variant="outline"
                    colorScheme="secondary"
                    size="xs"
                    fontSize="xs"
                    marginRight="2"
                    flexGrow={1}
                    width="20"
                  >
                    Edit Folder
                  </Link>
                )}
                {canDeleteFolder && (
                  <Button
                    variant="outline"
                    colorScheme="secondary"
                    onClick={onOpenFolderDelete}
                    size="xs"
                    flexGrow={1}
                    width="20"
                  >
                    Delete Folder
                  </Button>
                )}
              </Flex>
              <Box textAlign="center" marginBottom="6">
                <Text as="span" color="secondary.500">
                  <FolderIcon width="40" />
                </Text>
              </Box>
              {!!folder.description && (
                <Text marginBottom="4" fontSize="sm" color="gray.800">
                  {folder.description}
                </Text>
              )}
              {isOpenFolderDelete && (
                <FolderDelete
                  id={folder.id}
                  isOpen={isOpenFolderDelete}
                  onOpen={onOpenFolderDelete}
                  onClose={onCloseFolderDelete}
                />
              )}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default FolderInfoPresenter;
