import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import ContactUserInfo from "../../../components/elements/contactUserInfo";
import Link from "../../../components/elements/link";
import AddCircle from "../../../components/icons/addCircle";
import AddressIcon from "../../../components/icons/address";
import UserIcon from "../../../components/icons/user";
import {
  AssetWithTypeFragmentFragment,
  LocationWithRelationsFragmentFragment,
  useLocationConfigQuery,
  useUpdateLocationConfigMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import {
  addressToLinesString,
  addressToNonLinesString,
  googleMapsUrl,
} from "../../../utils/address";
import FloorPlanList from "../../floorPlans/list";
import Assets from "./assets";
import LocationTree from "./tree";

interface LocationsShowPresenterProps {
  location: LocationWithRelationsFragmentFragment;
  parentLocation: LocationWithRelationsFragmentFragment;
  canEditLocation: boolean;
  canAddLocation: boolean;
  canEditPlan: boolean;
  canDeletePlan: boolean;
  canAddPlan: boolean;
}

const LocationsShowPresenter: React.FC<LocationsShowPresenterProps> = ({
  location,
  parentLocation,
  canEditLocation,
  canAddLocation,
  canAddPlan,
  canEditPlan,
  canDeletePlan,
}) => {
  const { data: locationConfigData } = useLocationConfigQuery();
  const [updateLocationConfigMutation] = useUpdateLocationConfigMutation();
  const { isOpen: isDescriptionOpen, onToggle: toggleDescription } =
    useDisclosure();
  const [searchParams] = useSearchParams();

  const assets = useMemo(() => {
    const assets: AssetWithTypeFragmentFragment[] = [];
    const plans = location.floorPlans;
    const plansCount = plans.length;
    for (let i = 0; i < plansCount; i++) {
      const plan = plans[i];
      const fpaCount = plan.floorPlanAssets.length;
      for (let j = 0; j < fpaCount; j++) {
        assets.push(plan.floorPlanAssets[j].asset);
      }
    }
    return uniqBy(assets, "id");
  }, [location.floorPlans]);

  const tabIndex = locationConfigData
    ? locationConfigData.locationConfig.activeTabIndex
    : 0;
  const setTabIndex = React.useCallback(
    (activeTabIndex: number) => {
      updateLocationConfigMutation({
        variables: {
          activeTabIndex,
        },
      });
    },
    [updateLocationConfigMutation]
  );

  useEffect(() => {
    if (searchParams.has("tab")) {
      setTabIndex(+(searchParams.get("tab") || "0"));
    }
  }, [searchParams, setTabIndex]);

  return (
    <Box>
      <Flex flexDirection={{ base: "column", md: "row" }}>
        <Box
          flexGrow={1}
          marginRight={{ base: "0", md: "4" }}
          backgroundColor="secondary.10"
          padding={{ base: "2", md: "3" }}
        >
          <Flex alignItems="baseline">
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              lineHeight={{ base: "156%", md: "120%" }}
              fontWeight="semibold"
            >
              {parentLocation.name}
            </Text>
          </Flex>
          <Grid templateColumns={{ base: "35% 65%", sm: "50% 50%" }} gap={4}>
            {!!parentLocation.address1 && (
              <Flex marginTop="4">
                <Box marginRight={{ base: "2", md: "4" }}>
                  <Button
                    as="a"
                    variant="icon"
                    colorScheme="secondary"
                    href={googleMapsUrl(parentLocation)}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <AddressIcon boxSize={{ base: "4", md: "6" }} />
                  </Button>
                </Box>
                <Box>
                  <Text fontSize="sm" fontWeight="semibold">
                    Address
                  </Text>
                  <Text
                    fontSize={{ base: "xs", md: "base" }}
                    lineHeight={{ base: "162%", md: "6" }}
                    fontWeight="light"
                    marginTop="2"
                  >
                    {addressToLinesString(parentLocation)}
                    <br />
                    {addressToNonLinesString(parentLocation)}
                  </Text>
                </Box>
              </Flex>
            )}

            {parentLocation.locationCompanyUsers.length > 0 && (
              <Flex marginTop="4">
                <Box marginRight={{ base: "2", md: "4" }}>
                  <UserIcon
                    color="secondary.500"
                    boxSize={{ base: "4", md: "6" }}
                  />
                </Box>
                <Box>
                  <Text fontSize="sm" fontWeight="semibold">
                    Contact
                  </Text>
                  <ContactUserInfo
                    contactCompanyUsers={parentLocation.locationCompanyUsers.map(
                      (lcu) => lcu.companyUser
                    )}
                  />
                </Box>
              </Flex>
            )}
          </Grid>
          {!!parentLocation.description && (
            <Box marginTop="4">
              <Collapse in={isDescriptionOpen}>
                <Box fontSize="sm" whiteSpace="pre-wrap" marginBottom="1">
                  {parentLocation.description}
                </Box>
              </Collapse>
              <Button
                size="sm"
                fontSize="sm"
                variant="link"
                colorScheme="gray"
                marginTop="2"
                paddingLeft="0"
                onClick={toggleDescription}
              >
                {isDescriptionOpen ? "Hide" : "Show"} description
                <Box marginLeft="2">
                  <FontAwesomeIcon
                    icon={isDescriptionOpen ? faChevronUp : faChevronDown}
                    size="xs"
                  />
                </Box>
              </Button>
            </Box>
          )}
        </Box>
        <Box width={{ base: "full", md: "400px" }} flex="none">
          <Flex marginBottom="2" marginTop={{ base: "4", md: "0" }}>
            <Text fontSize="sm" fontWeight="semibold" flexGrow={1}>
              Sublocations
            </Text>
            {canAddLocation && (
              <Tooltip label="Add new sublocation" hasArrow>
                <Link
                  to={`${getRoutePath("locationsShowCreate", {
                    locationId: location.id,
                  })}?parentId=${location.id}`}
                  aria-label="Add new sublocation"
                  variant="icon"
                  colorScheme="graySecondary"
                  marginLeft="3"
                >
                  <AddCircle />
                </Link>
              </Tooltip>
            )}
          </Flex>
          <LocationTree id={location.id} canEditLocation={canEditLocation} />
        </Box>
      </Flex>

      <Tabs index={tabIndex} onChange={setTabIndex} isLazy>
        <TabList>
          <Tab>Plans</Tab>
          <Tab>Assets</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <FloorPlanList
              floorPlans={location.floorPlans}
              createFloorPlanPath={getRoutePath("locationsShowPlanCreate", {
                locationId: location.id,
              })}
              canAddPlan={canAddPlan}
              canEditPlan={canEditPlan}
              canDeletePlan={canDeletePlan}
            />
          </TabPanel>
          <TabPanel>
            <Assets assets={assets} locationId={location.id} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default LocationsShowPresenter;
