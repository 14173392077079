import { Icon, IconProps } from "@chakra-ui/react";

const Humidifier = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M64 76.5c-6.894 0-12.5 5.606-12.5 12.5s5.606 12.5 12.5 12.5S76.5 95.894 76.5 89 70.894 76.5 64 76.5Zm0 18.75A6.256 6.256 0 0 1 57.75 89c0-2.303 1.266-4.297 3.125-5.381v2.256a3.126 3.126 0 0 0 6.25 0v-2.256c1.86 1.084 3.125 3.078 3.125 5.381A6.256 6.256 0 0 1 64 95.25Z"
      fill="currentColor"
    />
    <path
      d="M76.5 45.25h-25c-8.616 0-15.625 7.01-15.625 15.625v43.75c0 5.169 4.206 9.375 9.375 9.375h37.5c5.169 0 9.375-4.206 9.375-9.375v-43.75c0-8.616-7.01-15.625-15.625-15.625Zm-25 6.25h25c5.169 0 9.375 4.206 9.375 9.375V64h-43.75v-3.125c0-5.169 4.206-9.375 9.375-9.375Zm31.25 56.25h-37.5a3.13 3.13 0 0 1-3.125-3.125V70.25h43.75v34.375a3.13 3.13 0 0 1-3.125 3.125ZM64 20.25A3.125 3.125 0 1 0 64 14a3.125 3.125 0 0 0 0 6.25ZM64 39a3.125 3.125 0 1 0 0-6.25A3.125 3.125 0 0 0 64 39ZM76.5 20.25a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25ZM51.5 20.25a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25ZM57.75 29.625a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25ZM70.25 29.625a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z"
      fill="currentColor"
    />
  </Icon>
);

export default Humidifier;
