import Input from "./input";

const Textarea = {
  baseStyle: {
    ...Input.baseStyle.field,
    height: "auto",
  },
  sizes: {
    md: Input.sizes.md.field,
    lg: Input.sizes.lg.field,
  },
  variants: {
    outline: Input.variants.outline.field,
  },
};

export default Textarea;
