import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ASSET_DELETED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetIndexFragmentFragment,
  CompanyStartUpDocument,
  useAssetDeleteMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import { setGenericMessage } from "../../../utils/serverErrors";

interface AssetDeleteProps {
  asset: AssetIndexFragmentFragment;
  redirect?: boolean;
}

const AssetDelete: React.FC<AssetDeleteProps> = ({
  asset,
  redirect = false,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [deleting, setDeleting] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [assetDeleteMutation] = useAssetDeleteMutation({
    refetchQueries: [{ query: CompanyStartUpDocument }],
  });

  const deleteAsset = async () => {
    setDeleting(true);
    try {
      await assetDeleteMutation({
        variables: { ids: [asset.id] },
      });
      toast({
        description: ASSET_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      if (redirect) navigate(getRoutePath("assets"));
      client.cache.evict({ id: `Asset:${asset.id}` });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setDeleting(false);
      onClose();
    }
  };

  return (
    <>
      <Tooltip label="Delete Asset" hasArrow placement="bottom">
        <IconButton
          onClick={onOpen}
          aria-label="Delete Asset"
          variant="icon"
          colorScheme="grayRed"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </IconButton>
      </Tooltip>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete the following asset?
              <br />
              <Text as="strong">{asset.name}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                width="48%"
                isLoading={deleting}
              >
                No, Don't Delete!
              </Button>
              <Button
                onClick={deleteAsset}
                colorScheme="red"
                ml="4%"
                width="48%"
                isLoading={deleting}
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AssetDelete;

gql`
  mutation AssetDelete($ids: [UUID!]!) {
    assetDelete(ids: $ids)
  }
`;
