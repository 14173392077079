import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import { FC } from "react";
import { Helmet } from "react-helmet";

import FormGroup from "../../components/elements/formGroup";
import { EMAIL_SENT } from "../../constants/lang/en";
import { useSendEmailMutation } from "../../graphql/graphql";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import { descriptionSchema, yupObject } from "../../utils/validation";

interface ContactFormProps {
  isOpen: boolean;
  onClose: () => void;
  repName?: string;
}

const ContactForm: FC<ContactFormProps> = ({ isOpen, onClose, repName }) => {
  const toast = useToast();
  const [sendEmailMutation, { loading }] = useSendEmailMutation();

  const onSubmit = async (
    data: ContactFormData,
    { setFieldError }: FormikHelpers<ContactFormData>
  ) => {
    try {
      const { data: serverData, errors } = await sendEmailMutation({
        variables: data,
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: EMAIL_SENT,
          status: "success",
          position: "top",
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <Helmet>
        <title>
          {repName ? `Send message to ${repName}` : "Send Feedback"}
        </title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {repName ? `Send message to ${repName}` : "Send Feedback"}
          </DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{ message: "" }}
              validationSchema={ContactFormValidationSchema}
              onSubmit={onSubmit}
            >
              {({ getFieldProps }) => (
                <Form noValidate>
                  {!repName && (
                    <Text>
                      Have questions or comments for our Support Team? We'd love
                      to hear from you!
                    </Text>
                  )}
                  <FormGroup label="" name="message">
                    <Textarea
                      autoComplete="off"
                      placeholder="Type your message here"
                      autoFocus
                      {...getFieldProps("message")}
                    />
                  </FormGroup>
                  <Button
                    width="full"
                    type="submit"
                    isLoading={loading}
                    marginTop="6"
                  >
                    Send
                  </Button>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ContactForm;

export const ContactFormValidationSchema = yupObject().shape({
  message: descriptionSchema
    .label("Message")
    .required()
    .min(5, "Please type more than 5 characters"),
});

export type ContactFormData = {
  message: string;
};

gql`
  mutation SendEmail($message: String!) {
    sendEmail(message: $message)
  }
`;
