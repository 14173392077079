import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { isNativeWebViewVar } from "../../graphql/reactiveVariables";
import { getRoutePath } from "../../router";
import CompanyBillingHistory from "./billingHistory";
import CompanyPaymentMethods from "./paymentMethods";
import CompanyPlan from "./plan";

interface CompanyBillingPagePresenterProps {}

const CompanyBillingPagePresenter: React.FC<
  CompanyBillingPagePresenterProps
> = () => {
  const [isNative] = useState(isNativeWebViewVar());
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex] = React.useState(
    [
      getRoutePath("companyPaymentMethodsCreate"),
      getRoutePath("companyPaymentMethods"),
      getRoutePath("nativePaymentMethods"),
    ].includes(location.pathname)
      ? 1
      : [
          getRoutePath("companyBillingHistory"),
          getRoutePath("nativePayments"),
        ].includes(location.pathname)
      ? 2
      : 0
  );

  return (
    <>
      <Tabs defaultIndex={tabIndex} isLazy>
        {!isNative && (
          <TabList>
            <Tab
              padding="6px 12px 8px 0"
              onClick={() => navigate(getRoutePath("companyPlan"))}
            >
              Plan
            </Tab>
            <Tab
              padding="6px 12px 8px"
              onClick={() => navigate(getRoutePath("companyPaymentMethods"))}
            >
              Payment Methods
            </Tab>
            <Tab
              padding="6px 0 8px 8px"
              onClick={() => navigate(getRoutePath("companyBillingHistory"))}
            >
              Payment History
            </Tab>
          </TabList>
        )}
        <TabPanels marginTop="4" marginBottom="8">
          <TabPanel>
            <CompanyPlan />
          </TabPanel>
          <TabPanel>
            <CompanyPaymentMethods />
          </TabPanel>
          <TabPanel>
            <CompanyBillingHistory />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CompanyBillingPagePresenter;
