import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  memo,
} from "react";
import isEqual from "react-fast-compare";
import { useParams } from "react-router-dom";
import { FixedSizeGrid } from "react-window";

import FileIcon from "../../../components/elements/fileIcon";
import Link from "../../../components/elements/link";
import TooltipName from "../../../components/elements/tooltipName";
import FolderIcon from "../../../components/icons/folder";
import {
  FileFragmentFragment,
  FolderFragmentFragment,
  useCompanyQuery,
} from "../../../graphql/graphql";
import useBoxSize from "../../../hooks/useBoxSize";
import { getRoutePath } from "../../../router";
import { mainLayoutPaddingX } from "../../../utils/layout";
import FileDelete from "../fileDelete";
import FolderDelete from "../folderDelete";

interface GridPresenterProps {
  folderFiles: Array<FolderFragmentFragment | FileFragmentFragment>;
  canEditFile: boolean;
  canDeleteFile: boolean;
  canEditFolder: boolean;
  canDeleteFolder: boolean;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  setSelectedItems: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
}
const columnWidth = 160;
const rowHeight = 160;

const GridPresenter: React.FC<GridPresenterProps> = ({
  folderFiles,
  canEditFile,
  canDeleteFile,
  canEditFolder,
  canDeleteFolder,
  selectedItems,
  setSelectedItems,
}) => {
  const { containerRef, width, height } = useBoxSize();
  const columnCount = Math.floor(width / columnWidth);
  const rowCount = Math.ceil(folderFiles.length / columnCount);

  return (
    <Box paddingX={mainLayoutPaddingX}>
      <Box ref={containerRef}>
        <FixedSizeGrid
          height={height}
          width={width}
          columnWidth={columnWidth}
          rowHeight={rowHeight}
          rowCount={rowCount}
          columnCount={columnCount}
          style={{ overflowX: "hidden" }}
          itemData={{
            folderFiles,
            canDeleteFile,
            canDeleteFolder,
            canEditFile,
            canEditFolder,
            selectedItems,
            setSelectedItems,
            columnCount,
          }}
          itemKey={({ columnIndex, rowIndex, data }) =>
            data.folderFiles[columnCount * rowIndex + columnIndex]?.id ||
            rowIndex * columnIndex
          }
        >
          {RowItem}
        </FixedSizeGrid>
      </Box>
    </Box>
  );
};

interface Data {
  folderFiles: Array<FolderFragmentFragment | FileFragmentFragment>;
  columnCount: number;
  canDeleteFile: boolean;
  canDeleteFolder: boolean;
  canEditFile: boolean;
  canEditFolder: boolean;
  setSelectedItems: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
}

const RowItem: FC<{
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  data: Data;
}> = memo(
  ({
    columnIndex,
    rowIndex,
    style,
    data: {
      folderFiles,
      columnCount,
      canDeleteFile,
      canDeleteFolder,
      canEditFile,
      canEditFolder,
      selectedItems,
      setSelectedItems,
    },
  }) => {
    const item = folderFiles[columnCount * rowIndex + columnIndex];
    return item ? (
      <Box style={style} padding="2" textAlign="center">
        {item.__typename === "File" ? (
          <FileIconName
            item={item}
            canEditFile={canEditFile}
            onSelect={setSelectedItems}
            canDeleteFile={canDeleteFile}
            selectedItems={selectedItems}
          />
        ) : (
          <FolderIconName
            item={item as FolderFragmentFragment}
            onSelect={setSelectedItems}
            selectedItems={selectedItems}
            canEditFolder={canEditFolder}
            canDeleteFolder={canDeleteFolder}
          />
        )}
      </Box>
    ) : null;
  },
  isEqual
);

const FileIconName = ({
  item,
  onSelect,
  canEditFile,
  canDeleteFile,
  selectedItems,
}: {
  item: FileFragmentFragment;
  onSelect: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  canEditFile: boolean;
  canDeleteFile: boolean;
}) => {
  const {
    isOpen: isOpenFileDelete,
    onOpen: onOpenFileDelete,
    onClose: onCloseFileDelete,
  } = useDisclosure();

  return (
    <Box role="group">
      <Link
        to={getRoutePath("filesShow", {
          folderId: item.folderId,
          fileId: item.id,
        })}
        variant="link"
        colorScheme="secondary"
        width="full"
      >
        <Box width="100%">
          <FileIcon
            width={100}
            height={80}
            filePath={item.path}
            fileName={item.name}
          />
          <TooltipName
            fontSize={{ base: "sm", md: "base" }}
            color="gray.800"
            marginTop="2"
            name={item.name}
            placement="bottom"
          />
        </Box>
      </Link>
      {canDeleteFile && (
        <Box
          as="button"
          display={{
            base: "block",
            md: selectedItems.includes(item) ? "block" : "none",
          }}
          position="absolute"
          _groupHover={{ display: "block" }}
          top="0"
          onClick={() => {
            if (!selectedItems.includes(item)) {
              onSelect([...selectedItems, item]);
            } else {
              onSelect((prevSelectedItems) =>
                prevSelectedItems.filter(
                  (selectedItem) => selectedItem !== item
                )
              );
            }
          }}
          color={selectedItems.includes(item) ? "secondary.500" : "gray.500"}
          _hover={{ color: "secondary.500" }}
        >
          <FontAwesomeIcon
            size="lg"
            icon={selectedItems.includes(item) ? faCheckSquare : faSquare}
          />
        </Box>
      )}
      <Flex
        display="none"
        _groupHover={{ display: "flex" }}
        alignItems="center"
        justifyContent="center"
        marginTop="1"
      >
        {canEditFile && (
          <Tooltip label="Edit File" hasArrow placement="left">
            <Link
              to={getRoutePath("filesEdit", {
                folderId: item.folderId,
                fileId: item.id,
              })}
              aria-label="Edit file"
              variant="icon"
              colorScheme="gray"
            >
              <FontAwesomeIcon icon={faPen} />
            </Link>
          </Tooltip>
        )}
        {canDeleteFile && (
          <Tooltip label="Delete file" hasArrow placement="bottom">
            <IconButton
              variant="icon"
              aria-label="Delete file"
              colorScheme="grayRed"
              marginX="4"
              onClick={onOpenFileDelete}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        )}
      </Flex>

      {isOpenFileDelete && (
        <FileDelete
          id={item.id}
          isOpen={isOpenFileDelete}
          onOpen={onOpenFileDelete}
          onClose={onCloseFileDelete}
        />
      )}
    </Box>
  );
};

const FolderIconName = ({
  item,
  onSelect,
  canEditFolder,
  canDeleteFolder,
  selectedItems,
}: {
  item: FolderFragmentFragment;
  onSelect: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  canEditFolder: boolean;
  canDeleteFolder: boolean;
}) => {
  const { data: currentCompanyData } = useCompanyQuery();
  const { folderId } = useParams();
  const {
    isOpen: isOpenFolderDelete,
    onOpen: onOpenFolderDelete,
    onClose: onCloseFolderDelete,
  } = useDisclosure();

  return (
    <Box role="group" position="relative" paddingBottom="8">
      <Link
        to={getRoutePath("foldersShow", { folderId: item.id })}
        variant="link"
        colorScheme="secondary"
        width="full"
        minWidth="none"
        onClick={() => onSelect([])}
      >
        <Box width="100%">
          <Text as="span">
            <FolderIcon width="100px" height="80px" />
          </Text>
          <TooltipName
            fontSize={{ base: "sm", md: "base" }}
            color="gray.800"
            marginTop="2"
            name={item.name}
            placement="bottom"
          />
        </Box>
      </Link>
      {canDeleteFolder &&
        currentCompanyData?.company?.uploadsFolderId !== item.id && (
          <Box
            as="button"
            display={{
              base: "block",
              md: selectedItems.includes(item) ? "block" : "none",
            }}
            position="absolute"
            _groupHover={{ display: "block" }}
            top="0"
            onClick={() => {
              if (!selectedItems.includes(item)) {
                onSelect([...selectedItems, item]);
              } else {
                onSelect((prevSelectedItems) =>
                  prevSelectedItems.filter(
                    (selectedItem) => selectedItem !== item
                  )
                );
              }
            }}
            color={selectedItems.includes(item) ? "secondary.500" : "gray.500"}
            _hover={{ color: "secondary.500" }}
          >
            <FontAwesomeIcon
              size="lg"
              icon={selectedItems.includes(item) ? faCheckSquare : faSquare}
            />
          </Box>
        )}
      <Flex
        display="none"
        _groupHover={{ display: "flex" }}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        width="100%"
        bottom="0"
        left="0"
      >
        {canEditFolder && (
          <Tooltip label="Edit Folder" hasArrow placement="left">
            <Link
              to={`${getRoutePath("foldersEdit", { folderId })}?targetFolder=${
                item.id
              }`}
              aria-label="Edit folder"
              variant="icon"
              colorScheme="gray"
            >
              <FontAwesomeIcon icon={faPen} />
            </Link>
          </Tooltip>
        )}
        {canDeleteFolder &&
          currentCompanyData?.company?.uploadsFolderId !== item.id && (
            <IconButton
              variant="icon"
              aria-label="Delete folder"
              colorScheme="grayRed"
              onClick={onOpenFolderDelete}
              marginX="4"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          )}
      </Flex>

      {isOpenFolderDelete && (
        <FolderDelete
          id={item.id}
          isOpen={isOpenFolderDelete}
          onOpen={onOpenFolderDelete}
          onClose={onCloseFolderDelete}
        />
      )}
    </Box>
  );
};

export default GridPresenter;
