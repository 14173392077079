import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiStepsHorizontal,
} from "@elastic/eui";
import { EuiStepHorizontalProps } from "@elastic/eui/src/components/steps/step_horizontal";
import React, { useCallback } from "react";

import useElementSize from "../hooks/useElementSize";
import { WorkOrder } from "../models/workOrder";
import WorkOrderCreateCompany from "./company";
import WorkOrderCreatLocation from "./location";
import WorkOrderCreatServices from "./services";
import WorkOrderCreateVerifyAndSave from "./verifyAndSave";

interface WorkOrderCreatePresenterProps {
  workOrder: WorkOrder;
  stepNumber: number;
  validStepsNumber: number;
  onStepChange: (stepNumber: number) => void;
  isNextButtonActive?: boolean;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  onWorkOrderCreate: () => void;
  loading?: boolean;
}

const WorkOrderCreatePresenter: React.FC<WorkOrderCreatePresenterProps> = ({
  workOrder,
  onStepChange,
  onWorkOrderChange,
  stepNumber,
  validStepsNumber,
  isNextButtonActive,
  onWorkOrderCreate,

  loading,
}) => {
  const footerPaddingSize = 20;
  const [boxRef, { width, right }] = useElementSize();

  const steps = [
    {
      title: "Company",
      step: 1,
      onClick: () => onStepChange(1),
    },
    {
      title: "Location",
      step: 2,
      onClick: () => onStepChange(2),
    },
    {
      title: "Service",
      step: 3,
      onClick: () => onStepChange(3),
    },
    {
      title: "Verify & Save",
      step: 4,
      onClick: () => onStepChange(4),
    },
  ] as EuiStepHorizontalProps[];

  const getSteps = useCallback((): EuiStepHorizontalProps[] => {
    steps.forEach((step) => {
      step.status =
        validStepsNumber >= (step.step || 0) && stepNumber > (step.step || 0)
          ? "complete"
          : "incomplete";
      step.status = stepNumber === (step.step || 0) ? "current" : step.status;
      step.disabled = validStepsNumber < (step.step || 0);
    });
    return steps;
  }, [stepNumber, validStepsNumber, steps]);

  const handleNextButtonClick = () => {
    if (stepNumber < 4) {
      onStepChange(stepNumber + 1);
    } else {
      onWorkOrderCreate();
    }
  };

  return (
    <>
      <EuiStepsHorizontal
        style={{
          marginLeft: "-32px",
          marginRight: "-32px",
        }}
        steps={getSteps()}
      />
      <EuiFlexGroup
        ref={boxRef}
        direction={"column"}
        justifyContent={"spaceBetween"}
        alignItems={"stretch"}
        wrap={false}
        style={{
          overflow: "hidden",
        }}
      >
        <EuiFlexItem style={stepNumber !== 1 ? { display: "none" } : {}}>
          <WorkOrderCreateCompany
            workOrder={workOrder}
            onWorkOrderChange={onWorkOrderChange}
          />
        </EuiFlexItem>
        <EuiFlexItem style={stepNumber !== 2 ? { display: "none" } : {}}>
          <WorkOrderCreatLocation
            workOrder={workOrder}
            onWorkOrderChange={onWorkOrderChange}
          />
        </EuiFlexItem>
        <EuiFlexItem style={stepNumber !== 3 ? { display: "none" } : {}}>
          <WorkOrderCreatServices
            workOrder={workOrder}
            onWorkOrderChange={onWorkOrderChange}
          />
        </EuiFlexItem>
        <EuiFlexItem style={stepNumber !== 4 ? { display: "none" } : {}}>
          <WorkOrderCreateVerifyAndSave
            workOrder={workOrder}
            active={stepNumber === 4}
          />
        </EuiFlexItem>
        <EuiFlexGroup
          justifyContent={stepNumber > 1 ? "spaceBetween" : "flexEnd"}
          className={"work-order-footer"}
          style={{
            width: `${width + footerPaddingSize * 2}px`,
            right: `${(right || 0) - footerPaddingSize}px`,
          }}
        >
          {stepNumber > 1 && (
            <div>
              <EuiButton
                className={"fullButtonHeight back-button"}
                onClick={() => onStepChange(stepNumber - 1)}
              >
                Back
              </EuiButton>
            </div>
          )}
          <div>
            <EuiButton
              className={"fullButtonHeight next-button"}
              fill={isNextButtonActive}
              disabled={!isNextButtonActive}
              onClick={handleNextButtonClick}
              iconType={stepNumber !== 4 ? "arrowRight" : ""}
              iconSide="right"
              isLoading={loading}
            >
              {stepNumber !== 4 ? "Next" : "Create"}
            </EuiButton>
          </div>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </>
  );
};

export default WorkOrderCreatePresenter;
