import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const boxMinHeight = 300;

const useBoxSize = () => {
  const [ref, setRef] = useState<HTMLDivElement>();
  const containerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) setRef(node);
    },
    [setRef]
  );
  const location = useLocation();
  const [boxSize, setBoxSize] = useState<BoxSize>({
    width: 0,
    height: 0,
    containerRef,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      const main = document.getElementById("main");
      const mainInner = document.getElementById("main_inner");

      if (ref && main && mainInner) {
        const width = ref.clientWidth;
        // Reduce 32px for padding
        const height =
          main.clientHeight - 32 - (mainInner.clientHeight - ref.clientHeight);
        setBoxSize({
          width,
          height: height < boxMinHeight ? boxMinHeight : height,
        });
      }
    }, 250);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [ref, location.pathname]);

  return boxSize;
};

interface BoxSize {
  containerRef?: (node: HTMLDivElement) => void;
  width: number;
  height: number;
}

export default useBoxSize;
