import { useApolloClient } from "@apollo/client";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import { FOLDER_CREATED_MESSAGE } from "../../../constants/lang/en";
import {
  FoldersDocument,
  useFolderCreateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  descriptionSchema,
  nameSchema,
  yupObject,
} from "../../../utils/validation";

interface FolderCreatePresenterProps {
  parentId: string;
}

const FolderCreatePresenter: React.FC<FolderCreatePresenterProps> = ({
  parentId,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const client = useApolloClient();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [folderCreateMutation, { loading }] = useFolderCreateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FoldersDocument }],
  });

  const onSubmit = useCallback(
    async (
      data: FolderCreateFormData,
      { setFieldError }: FormikHelpers<FolderCreateFormData>
    ) => {
      try {
        const { errors } = await folderCreateMutation({
          variables: { data: { ...data, parentId } },
        });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else {
          navigate(getRoutePath("foldersShow", { folderId: parentId }));
          client.cache.evict({
            id: `Folder:${parentId}`,
            fieldName: "children",
          });
          toast({
            description: FOLDER_CREATED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          return;
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [client.cache, folderCreateMutation, navigate, parentId, toast]
  );

  const onDrawerClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("foldersShow", { folderId: parentId }));
    }, 500);
  }, [navigate, onClose, parentId]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>Create New Folder</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create New Folder</DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{
                name: "",
              }}
              validationSchema={FolderCreateValidationSchema}
              onSubmit={onSubmit}
            >
              {({ getFieldProps }) => (
                <Form noValidate>
                  <FormGroup label="Name" name="name">
                    <Input
                      autoComplete="off"
                      autoFocus
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  <Button
                    width="full"
                    type="submit"
                    isLoading={loading}
                    marginTop="10"
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default FolderCreatePresenter;

const FolderCreateValidationSchema = yupObject().shape({
  name: nameSchema,
  description: descriptionSchema,
});

type FolderCreateFormData = {
  name: string;
};

gql`
  mutation FolderCreate($data: FolderCreateInput!) {
    folderCreate(data: $data) {
      ...FolderWithRelationsFragment
    }
  }
`;
