import { Icon, IconProps } from "@chakra-ui/react";

const WaterMeter = (props: IconProps & any) => {
  return (
    <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
      <g fill="currentColor">
        <path d="M64 32c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32-.02-17.665-14.335-31.98-32-32Zm0 59.733c-15.317 0-27.733-12.417-27.733-27.733 0-15.317 12.416-27.734 27.733-27.734S91.733 48.683 91.733 64C91.716 79.31 79.31 91.715 64 91.733Z" />
        <path d="M44.8 51.2V64h38.4V51.2H44.8Zm4.267 8.533v-4.267h12.8v4.267h-12.8Zm17.066 0v-4.267H70.4v4.267h-4.267Zm12.8-4.267v4.267h-4.266v-4.267h4.266ZM76.8 68.267a6.4 6.4 0 1 0 0 12.8 6.4 6.4 0 0 0 0-12.8Zm0 8.533a2.133 2.133 0 1 1 0-4.267 2.133 2.133 0 0 1 0 4.267ZM59.733 42.666h8.534v4.267h-8.534v-4.267ZM72.533 42.666H76.8v4.267h-4.267v-4.267ZM51.2 42.666h4.267v4.267H51.2v-4.267Z" />
        <path d="M125.867 51.2h-6.4v-2.133a2.134 2.134 0 0 0-2.134-2.134H108.8a2.133 2.133 0 0 0-2.133 2.134V51.2h-4.828C96.627 34.69 81.313 23.467 64 23.467S31.373 34.69 26.162 51.2h-4.829v-2.133a2.133 2.133 0 0 0-2.133-2.134h-8.533a2.133 2.133 0 0 0-2.134 2.134V51.2h-6.4A2.133 2.133 0 0 0 0 53.333v21.334C0 75.845.955 76.8 2.133 76.8h6.4v2.133c0 1.178.956 2.134 2.134 2.134H19.2a2.133 2.133 0 0 0 2.133-2.134V76.8h4.829c5.21 16.51 20.526 27.733 37.838 27.733 17.313 0 32.627-11.224 37.839-27.733h4.828v2.133c0 1.178.955 2.134 2.133 2.134h8.533a2.134 2.134 0 0 0 2.134-2.134V76.8h6.4A2.133 2.133 0 0 0 128 74.667V53.333a2.133 2.133 0 0 0-2.133-2.133ZM4.267 72.533V55.467h4.266v17.066H4.267Zm12.8 4.267H12.8V51.2h4.267v25.6Zm83.2-4.267c-.951 0-1.787.63-2.05 1.543-4.18 15.457-18.204 26.193-34.217 26.193-16.013 0-30.036-10.736-34.217-26.193a2.133 2.133 0 0 0-2.05-1.543h-6.4v-4.266H25.6V64h-4.267v-8.533h6.4c.951 0 1.787-.63 2.05-1.542C33.963 38.467 47.987 27.73 64 27.73c16.013 0 30.037 10.736 34.217 26.194a2.134 2.134 0 0 0 2.05 1.542h6.4v17.066h-6.4ZM115.2 76.8h-4.267V51.2h4.267v25.6Zm8.533-4.267h-4.266V55.467h4.266v17.066Z" />
        <path d="M42.667 68.267H64v4.266H42.667v-4.266ZM46.933 76.8h19.2v4.267h-19.2V76.8Z" />
      </g>
    </Icon>
  );
};

export default WaterMeter;
