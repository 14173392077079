import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import React, { useCallback } from "react";
import countryList from "react-select-country-list";

import { WOCompany } from "../../../../../graphql/types/queries/workOrdersQuery";
import Combobox from "../../../components/combobox";
import InputField from "../../../components/inputField";
import { initialWorkOrder } from "../../../helpers/initialWorkOrder";
import { MessageUtils } from "../../../helpers/messages";
import { companyChanged } from "../../../helpers/workOrdersReactiveVariables";
import { WorkOrder } from "../../../models/workOrder";

interface WorkOrderCreateCompanyDetailsPresenterProps {
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  companies: WOCompany[];
  selectedCompanyId?: string;
  workOrder: WorkOrder;
}

interface CompanyFields {
  label: string;
  disabled: boolean;
  valueField: string;
}

const WorkOrderCreateCompanyDetailsPresenter: React.FC<
  WorkOrderCreateCompanyDetailsPresenterProps
> = ({ onWorkOrderChange, companies, selectedCompanyId, workOrder }) => {
  const { euiTheme } = useEuiTheme();
  const companyFields = [
    {
      label: "Company Email",
      disabled: true,
      valueField: "email",
    },
    {
      label: "Company Address",
      disabled: true,
      valueField: "address1",
    },
    {
      label: "Company Country",
      disabled: true,
      valueField: "countryAlpha2",
      required: true,
    },
    {
      label: "Company City",
      disabled: true,
      valueField: "city",
    },
    {
      label: "Company Zip",
      disabled: true,
      valueField: "zip",
      required: false,
    },
  ];

  const [selectedCompany, setSelectedCompany] = React.useState({} as any);
  const [companyOptions, setCompanyOptions] = React.useState(
    [] as EuiComboBoxOptionOption<string>[]
  );
  const [errors, setErrors] = React.useState([] as string[]);
  const [formTouched, setFormTouched] = React.useState(false);

  const handleCompanyIdChange = (companyId: string | null) => {
    if (companyId !== workOrder.companyId) {
      if (workOrder.companyId) {
        companyChanged({ changed: true });
      }
      const selectedWOCompany = companies.find(
        (company) => company.id === selectedCompanyId
      );
      onWorkOrderChange({
        ...initialWorkOrder,
        companyId,
        companyName: selectedWOCompany?.name || null,
      });
    }
  };

  const getFieldValue = useCallback(
    (field: CompanyFields) => {
      let value = selectedCompany[field.valueField];
      if (value && field.valueField === "countryAlpha2") {
        value = countryList().getLabel(value);
      }
      return value;
    },
    [selectedCompany]
  );

  const handleOnBlur = useCallback(() => {
    if (!formTouched) setFormTouched(true);
  }, [formTouched]);

  React.useEffect(() => {
    const selectedWOCompany = companies.find(
      (company) => company.id === selectedCompanyId
    );
    setSelectedCompany(selectedWOCompany || {});
  }, [companies, selectedCompanyId]);

  React.useEffect(() => {
    if (companies) {
      setCompanyOptions(
        companies.map((company) => {
          const disabled = !company.locationCount;
          return {
            label: `${company.name} ${
              disabled
                ? "(there is no Location yet for creating a Work Order)"
                : ""
            }`,
            value: company.id,
            disabled,
          };
        })
      );
    } else {
      setCompanyOptions([]);
    }
  }, [companies]);

  React.useEffect(() => {
    const errors = [];
    if (formTouched && !selectedCompany?.id) {
      errors.push(MessageUtils.commonRequiredFieldErrorMessage);
    }
    setErrors(errors);
  }, [formTouched, selectedCompany]);

  return (
    <EuiFlexGroup
      justifyContent="flexStart"
      direction="column"
      wrap={false}
      gutterSize="none"
      style={{
        width: "100%",
        flexGrow: 1,
        paddingBottom: "80px",
      }}
    >
      <EuiFlexItem
        style={{
          width: "100%",
        }}
      >
        <EuiText className={"columnTitle"}>Company Details</EuiText>
      </EuiFlexItem>
      <EuiFlexItem
        style={{ marginBottom: euiTheme.size.m, height: euiTheme.size.xxl }}
      >
        <Combobox
          ariaLabel="Company Name"
          label="Company Name"
          placeholder="Select Company Name"
          required={true}
          options={companyOptions}
          selectedValue={selectedCompanyId}
          onChange={handleCompanyIdChange}
          invalid={!!errors.length}
          loading={!companies}
          onBlur={handleOnBlur}
          autoSelect={true}
          errors={errors}
          autoFocus={companyOptions?.length > 1}
        />
      </EuiFlexItem>
      <EuiFlexGroup
        justifyContent="flexStart"
        direction="column"
        wrap={false}
        gutterSize="none"
        style={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <EuiFlexItem
          style={{
            width: "100%",
            flexGrow: 1,
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {companyFields?.length &&
            companyFields.map((field) => {
              return (
                <InputField
                  label={field.label}
                  disabled={field.disabled}
                  value={getFieldValue(field)}
                  key={field.label}
                  required={field.required}
                  style={{
                    marginTop: euiTheme.size.m,
                    marginBottom: euiTheme.size.m,
                  }}
                />
              );
            })}
          {selectedCompany?.coordinates && (
            <EuiLink
              href={`http://www.google.com/maps/place/${selectedCompany.coordinates[1]},${selectedCompany.coordinates[0]}`}
              target="_blank"
              style={{
                marginBottom: euiTheme.size.s,
                marginTop: euiTheme.size.l,
                overflow: "auto",
              }}
            >
              {selectedCompany.name + " on a Map"}
            </EuiLink>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};

export default WorkOrderCreateCompanyDetailsPresenter;
