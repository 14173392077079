import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Exclamation = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    viewBox="0 0 20 20"
    boxSize="20px"
    color="#AEAEAE"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.666 9.9993c0-4.6023 3.731-8.3333 8.3333-8.3333a8.3333 8.3333 0 018.3334 8.3333c0 4.6024-3.731 8.3334-8.3333 8.3334-4.6024 0-8.3334-3.731-8.3334-8.3333zm1.6667 0c0 3.6819 2.9848 6.6667 6.6666 6.6667a6.6667 6.6667 0 006.6667-6.6667c0-3.6818-2.9848-6.6666-6.6667-6.6666-3.6818 0-6.6666 2.9848-6.6666 6.6666zm7.5.4167a.4167.4167 0 00-.4167-.4167h-.8333a.4167.4167 0 00-.4167.4167v2.5c0 .2301.1866.4167.4167.4167h.8333a.4167.4167 0 00.4167-.4167v-2.5zm-.4167-3.75a.4167.4167 0 01.4167.4167v.8333a.4167.4167 0 01-.4167.4167h-.8333a.4167.4167 0 01-.4167-.4167v-.8333a.4167.4167 0 01.4167-.4167h.8333z"
    />
  </Icon>
);

export default Exclamation;
