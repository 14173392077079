import { useApolloClient } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import {
  useUpdateCurrentCompanyMutation,
  useUpdateSessionMutation,
} from "../../graphql/graphql";
import { getRoutePath } from "../../router";
import { removeCoupon } from "../../utils/coupon";
import { setUserData } from "../../utils/ga";

interface SignOutPageProps {}

const SignOutPage: React.FC<SignOutPageProps> = () => {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [updateSessionMutation] = useUpdateSessionMutation();
  const [updateCompanyMutation] = useUpdateCurrentCompanyMutation();

  useEffect(() => {
    const signOut = async () => {
      removeCoupon();
      await updateSessionMutation();
      await updateCompanyMutation();
      await client.resetStore();
      setUserData();
      navigate(getRoutePath("signIn"), { replace: true });

      if (searchParams.has("message")) {
        toast({
          description: searchParams.getAll("message")[0],
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    };
    signOut();
  }, [
    navigate,
    searchParams,
    client,
    toast,
    updateSessionMutation,
    updateCompanyMutation,
  ]);

  return <PageSpinner />;
};

export default SignOutPage;
