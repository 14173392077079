import { Box, Flex } from "@chakra-ui/react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { FC, useEffect } from "react";

import {
  MaintenanceRemindBeforeType,
  useAssetLazyQuery,
} from "../../../graphql/graphql";
import ContactCompanyUserField from "../contactCompanyUserField";
import NumberInput from "../numberInput";
import SelectCombobox from "../selectCombobox";

interface MaintenanceRemindBeforeProps {
  type?: "before" | "after";
  namePrefix?: string;
  hideContactUsersField?: boolean;
  populateCompanyUserIdsBasedOnAsset?: boolean;
}

const MaintenanceRemindBefore: FC<MaintenanceRemindBeforeProps> = ({
  type = "before",
  namePrefix = "",
  hideContactUsersField = false,
  populateCompanyUserIdsBasedOnAsset = false,
}) => {
  const [assetQuery] = useAssetLazyQuery();

  const isBefore = type === "before";
  const typeName = `${namePrefix}${
    isBefore ? "remindBeforeType" : "remindAfterType"
  }`;
  const valueName = `${namePrefix}${
    isBefore ? "remindBeforeValue" : "remindAfterValue"
  }`;

  const { values, setFieldValue } = useFormikContext<any>();
  const assetId = values.assetId;

  useEffect(() => {
    const getUsers = async () => {
      const { data } = await assetQuery({ variables: { id: assetId } });
      if (data) {
        setFieldValue(
          "companyUserIds",
          data.asset.assetCompanyUsers.map((acu) => acu.companyUser.id)
        );
      }
    };
    if (populateCompanyUserIdsBasedOnAsset && assetId) getUsers();
  }, [assetId, assetQuery, populateCompanyUserIdsBasedOnAsset, setFieldValue]);

  return (
    <Box>
      {!isBefore ? (
        <Flex>
          <Flex py="6" alignItems="center">
            <Box as="span" color="gray.600">
              <FontAwesomeIcon icon={faBell} />
            </Box>
            <Box as="span" marginLeft="2" fontSize="sm">
              Send notification
              <Box as="span" display={{ base: "block", sm: "none" }}>
                {isBefore ? "before" : "from now"}
              </Box>
            </Box>
          </Flex>
          <Box marginX="2" width="24">
            <NumberInput label="" name={valueName} defaultValue={1} min={1} />
          </Box>
          <Box width="32">
            <SelectCombobox
              label=""
              name={typeName}
              options={[
                {
                  label: "Minutes",
                  query: "Minutes",
                  value: MaintenanceRemindBeforeType.Minutes,
                },
                {
                  label: "Hours",
                  query: "Hours",
                  value: MaintenanceRemindBeforeType.Hours,
                },
                {
                  label: "Days",
                  query: "Days",
                  value: MaintenanceRemindBeforeType.Days,
                },
              ]}
            />
          </Box>
          <Box
            as="span"
            marginLeft="2"
            pt="7"
            fontSize="sm"
            display={{ base: "none", sm: "block" }}
          >
            {isBefore ? "before" : "from now"}
          </Box>
        </Flex>
      ) : (
        !hideContactUsersField && (
          <ContactCompanyUserField
            name="companyUserIds"
            label="Send reminders to"
            excludedCompanyUserIds={[]}
          />
        )
      )}
    </Box>
  );
};

export default MaintenanceRemindBefore;
