import { Icon, IconProps } from "@chakra-ui/react";

const Escalator = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M108.019 35.085H79.897c-.835 0-1.635.33-2.226.917l-43.778 43.55H17.148A3.14 3.14 0 0 0 14 82.685v23.904c0 1.73 1.41 3.131 3.148 3.131h28.226c.835 0 1.635-.329 2.226-.917l43.675-43.446h16.744a3.14 3.14 0 0 0 3.148-3.131V38.217a3.14 3.14 0 0 0-3.148-3.132Zm-3.148 24.008h-14.9c-.836 0-1.635.33-2.226.918L44.07 103.457H20.296V85.816h14.9c.834 0 1.635-.33 2.226-.918L81.2 41.348h23.671v17.745Z"
      fill="currentColor"
    />
    <path
      d="M53.14 38.217c-8.332 0-15.11 6.743-15.11 15.032V76.63h6.295V53.25c0-4.836 3.955-8.769 8.814-8.769 4.86 0 8.815 3.934 8.815 8.769h6.296c0-8.289-6.78-15.032-15.11-15.032Z"
      fill="currentColor"
    />
    <path
      d="M53.14 14c-6.886 0-12.487 5.572-12.487 12.422 0 6.85 5.601 12.421 12.487 12.421 6.885 0 12.486-5.572 12.486-12.421 0-6.85-5.6-12.422-12.486-12.422Zm0 18.58c-3.415 0-6.191-2.761-6.191-6.158 0-3.397 2.777-6.159 6.19-6.159 3.414 0 6.191 2.762 6.191 6.159 0 3.396-2.776 6.159-6.19 6.159ZM96.053 107.739l-13.296-.427-.342-13.225c-.045-1.729-1.521-3.129-3.227-3.05a3.14 3.14 0 0 0-3.067 3.21l.419 16.18a3.139 3.139 0 0 0 3.045 3.049l16.265.522.103.002a3.14 3.14 0 0 0 3.144-3.03 3.136 3.136 0 0 0-3.044-3.231Z"
      fill="currentColor"
    />
    <path
      d="M113.082 77.238a3.162 3.162 0 0 0-4.453-.007l-31.06 30.793a3.12 3.12 0 0 0-.007 4.429c.615.614 1.422.92 2.23.92.804 0 1.608-.304 2.223-.913l31.059-30.793a3.12 3.12 0 0 0 .008-4.43Z"
      fill="currentColor"
    />
  </Icon>
);

export default Escalator;
