import { Icon, IconProps } from "@chakra-ui/react";

const AirAdmittanceValve = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M86.458 31.027h-4.083v-5.819h8.167v-6.125H37.458v6.125h8.167v5.819h-4.083V29.29h-8.167V15h61.25v14.291h-8.167v1.736ZM94.625 113h-61.25V98.708h8.167v-1.735h4.083v5.819h-8.167v6.125h53.084v-6.125h-8.167v-5.82h4.083v1.736h8.167V113Z"
      fill="currentColor"
    />
    <path
      d="M64 98.708c-19.138 0-34.708-15.57-34.708-34.708 0-19.138 15.57-34.709 34.708-34.709 19.138 0 34.709 15.57 34.709 34.709 0 19.138-15.57 34.708-34.709 34.708Zm0-65.333c-16.887 0-30.625 13.738-30.625 30.625S47.114 94.625 64 94.625c16.887 0 30.625-13.738 30.625-30.625S80.887 33.375 64 33.375Z"
      fill="currentColor"
    />
    <path
      d="M64 88.5c-13.51 0-24.5-10.99-24.5-24.5S50.49 39.5 64 39.5 88.5 50.49 88.5 64 77.51 88.5 64 88.5Zm0-44.917c-11.258 0-20.416 9.16-20.416 20.417 0 11.258 9.158 20.417 20.416 20.417 11.258 0 20.417-9.159 20.417-20.417 0-11.258-9.159-20.417-20.417-20.417Z"
      fill="currentColor"
    />
    <path
      d="M64 74.208c-5.629 0-10.208-4.58-10.208-10.208 0-5.63 4.58-10.209 10.208-10.209 5.63 0 10.208 4.58 10.208 10.209S69.63 74.208 64 74.208Zm0-16.333A6.132 6.132 0 0 0 57.875 64 6.132 6.132 0 0 0 64 70.125 6.132 6.132 0 0 0 70.125 64 6.132 6.132 0 0 0 64 57.875Z"
      fill="currentColor"
    />
    <path
      d="M61.958 47.666h4.084v8.167h-4.084v-8.166ZM48.8 70.388l7.081-4.08 2.038 3.536-7.082 4.08-2.037-3.536ZM70.004 69.857l2.037-3.537 7.082 4.08-2.038 3.537-7.081-4.08Z"
      fill="currentColor"
    />
  </Icon>
);

export default AirAdmittanceValve;
