import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";

import AddressFields from "../../components/elements/addressFields";
import ContactCompanyUserField from "../../components/elements/contactCompanyUserField";
import FormGroup from "../../components/elements/formGroup";
import PhoneInput from "../../components/elements/phoneInput";
import SelectCombobox from "../../components/elements/selectCombobox";
import { GENERIC_SAVED_MESSAGE } from "../../constants/lang/en";
import { BUSINESS_TYPES, INDUSTRIES } from "../../constants/options";
import {
  CompanyWithContactUsersFragmentFragment,
  useCompanyUpdateMutation,
} from "../../graphql/graphql";
import { currentCompanyPlanVar } from "../../graphql/reactiveVariables";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import {
  address1Schema,
  address2Schema,
  citySchema,
  companyBusinessTypeSchema,
  companyIndustrySchema,
  companyUserIdsSchema,
  emailSchema,
  nameSchema,
  phoneSchema,
  stateSchema,
  websiteSchema,
  yupObject,
  zipSchema,
} from "../../utils/validation";

interface CompanyInfoFormProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyWithContactUsersFragmentFragment;
}

const CompanyInfoForm: FC<CompanyInfoFormProps> = ({
  isOpen,
  onClose,
  company,
}) => {
  const toast = useToast();
  const [companyUpdateMutation, { loading }] = useCompanyUpdateMutation();
  const plan = useReactiveVar(currentCompanyPlanVar);
  const isProfessionalPlan = plan?.productName === "Professional";

  const onSubmit = async (
    data: CompanyInfoFormData,
    { setFieldError }: FormikHelpers<CompanyInfoFormData>
  ) => {
    let websiteData = data.website.trim();
    if (
      websiteData &&
      !websiteData.startsWith("http://") &&
      !websiteData.startsWith("https://")
    ) {
      data.website = "https://" + websiteData;
    }

    try {
      const { data: serverData, errors } = await companyUpdateMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        onClose();
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  const initialValues: CompanyInfoFormData = useMemo(() => {
    const values: CompanyInfoFormData = {
      name: company.name,
      address1: company.address1 || "",
      address2: company.address2 || "",
      city: company.city || "",
      state: company.state || "",
      zip: company.zip || "",
      countryAlpha2: company.countryAlpha2 || "US",
      coordinates: company.coordinates || [0, 0],
      email: company.email || "",
      phone: company.phone || "",
      website: company.website || "",
      industry: company.industry || "",
      companyUserIds: company.companyCompanyUsers
        ? company.companyCompanyUsers.map((cu) => cu.companyUser.id)
        : ([] as string[]),
    };

    if (isProfessionalPlan) {
      values.businessType = company.businessType || "";
    }
    return values;
  }, [company, isProfessionalPlan]);

  const CompanyInfoFormValidationSchema = useMemo(
    () =>
      yupObject().shape({
        name: nameSchema.label("Company name"),
        address1: address1Schema,
        address2: address2Schema,
        city: citySchema,
        state: stateSchema,
        zip: zipSchema,
        companyUserIds: companyUserIdsSchema.required(),
        email: emailSchema,
        phone: phoneSchema,
        industry: companyIndustrySchema.required(),
        businessType: isProfessionalPlan
          ? companyBusinessTypeSchema.required()
          : companyBusinessTypeSchema,
        website: websiteSchema.required(),
      }),
    [isProfessionalPlan]
  );

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <Helmet>
        <title>Company Info</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Company Info</DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={initialValues}
              validationSchema={CompanyInfoFormValidationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form noValidate>
                  <FormGroup label="Company Name" name="name">
                    <Input
                      autoFocus
                      autoComplete="organization"
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  <AddressFields />
                  <ContactCompanyUserField name="companyUserIds" />
                  <FormGroup label="Email" name="email">
                    <Input
                      autoFocus
                      autoComplete="email"
                      {...getFieldProps("email")}
                    />
                  </FormGroup>
                  <FormGroup label="Phone" name="phone">
                    <PhoneInput autoFocus autoComplete="tel" name="phone" />
                  </FormGroup>
                  <FormGroup label="Company Website" name="website">
                    <Input
                      autoFocus
                      autoComplete="url"
                      {...getFieldProps("website")}
                    />
                  </FormGroup>
                  <SelectCombobox
                    options={INDUSTRIES}
                    name="industry"
                    label="Select Your Industry"
                  />
                  {isProfessionalPlan && (
                    <SelectCombobox
                      options={BUSINESS_TYPES}
                      name="businessType"
                      label="Select Your Business Type"
                    />
                  )}
                  <Box marginY="10">
                    <Button width="full" type="submit" isLoading={loading}>
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CompanyInfoForm;

export type CompanyInfoFormData = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  countryAlpha2: string;
  coordinates: number[];
  companyUserIds: string[];
  email: string;
  phone: string;
  industry: string;
  businessType?: string;
  website: string;
};
