import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import isEqual from "react-fast-compare";
import { ReactSortable } from "react-sortablejs";

import ImageSlider, {
  ImageType,
} from "../../../../../components/elements/imageSlider";
import PageSpinner from "../../../../../components/elements/pageSpinner";
import {
  ASSET_IMAGE_REORDER_MESSAGE,
  GENERIC_ERROR_MESSAGE,
} from "../../../../../constants/lang/en";
import { useAssetFileChangeOrderMutation } from "../../../../../graphql/graphql";
import { getFilesUrl } from "../../../../../utils/image";
import { setGenericMessage } from "../../../../../utils/serverErrors";

interface ImagesReorderProps {
  onClose: () => void;
  assetImages: ImageType[];
  initialImageIndex?: number;
  assetId: string;
  canSortAssetImages?: boolean;
}

const CustomComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <Flex ref={ref} wrap="wrap" justifyContent="center">
      {props.children}
    </Flex>
  );
});

const ImagesReorder: React.FC<ImagesReorderProps> = ({
  assetImages: initialAssetImages,
  onClose,
  assetId,
  initialImageIndex = 0,
  canSortAssetImages = false,
}) => {
  const toast = useToast();
  const [initialSliderTransition, setInitialSliderTransition] = React.useState<
    "NOT_STARTED" | "IN_PROGRESS" | "COMPLETED"
  >("NOT_STARTED");
  const [showGallery, setShowGallery] = React.useState<boolean>(true);
  const [assetImages, setAssetImages] = useState<ImageType[]>();
  const [assetFileChangeOrder, { loading }] = useAssetFileChangeOrderMutation();

  useEffect(() => {
    setAssetImages(initialAssetImages);
  }, [initialAssetImages]);

  useEffect(() => {
    if (initialImageIndex) setInitialSliderTransition("IN_PROGRESS");
  }, [initialImageIndex]);

  const handleAssetImagesReorder = useCallback(
    (newAssetImages: ImageType[]) => {
      setAssetImages(newAssetImages);
    },
    []
  );

  const isOrderChanged = useMemo(() => {
    if (!assetImages) return false;
    const oldImageIds = initialAssetImages.map((ai) => ai.id);
    const newImageIds = assetImages.map((ai) => ai.id);
    return !isEqual(oldImageIds, newImageIds);
  }, [assetImages, initialAssetImages]);

  const saveNewOrder = useCallback(async () => {
    if (!isOrderChanged || !assetImages) return;
    const assetFileIds = assetImages.map((ai) => ai.id);
    try {
      const { errors } = await assetFileChangeOrder({
        variables: { assetFileIds },
      });
      if (errors) {
        toast({
          description: GENERIC_ERROR_MESSAGE,
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        toast({
          description: ASSET_IMAGE_REORDER_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [assetFileChangeOrder, assetImages, isOrderChanged, toast]);

  return (
    <Modal isOpen onClose={onClose} isCentered size="full">
      <ModalOverlay>
        <ModalContent backgroundColor="white">
          <ModalCloseButton zIndex="2" top={0} right={0} />
          <ModalBody>
            <Box
              display={
                initialSliderTransition === "COMPLETED" ||
                initialImageIndex === 0
                  ? "block"
                  : "none"
              }
            >
              {showGallery && assetImages && (
                <ImageSlider
                  isPreview
                  assetId={assetId}
                  images={assetImages}
                  canSortAssetImages={canSortAssetImages}
                  initialImageIndex={initialImageIndex}
                  setPreviewTransitionState={setInitialSliderTransition}
                />
              )}
              {!showGallery && assetImages && (
                <>
                  <Box overflow="auto" height="calc(100vh - 115px)">
                    <ReactSortable
                      tag={CustomComponent}
                      list={assetImages.map((ai) => ({ ...ai, chosen: true }))}
                      setList={handleAssetImagesReorder}
                    >
                      {assetImages.map((assetImage) => (
                        <Image
                          key={assetImage.id}
                          src={getFilesUrl(assetImage.path, 250)}
                          alt={assetImage.name}
                          boxSize={{ base: "100px", md: "250px" }}
                          objectFit="cover"
                          m="2"
                          fallback={
                            <Center
                              boxSize="250px"
                              margin="auto"
                              bgColor="gray.100"
                            />
                          }
                        />
                      ))}
                    </ReactSortable>
                  </Box>
                  <Center w="full" paddingY="2">
                    <Button
                      maxWidth="250px"
                      isDisabled={!isOrderChanged}
                      isLoading={loading}
                      onClick={saveNewOrder}
                    >
                      Save New Order
                    </Button>
                  </Center>
                </>
              )}
              <Flex justify="center">
                <Button
                  variant="link"
                  colorScheme="secondary"
                  onClick={() => setShowGallery((prevState) => !prevState)}
                  fontSize="sm"
                >
                  {showGallery ? "Reorder" : "Gallery"}
                </Button>
              </Flex>
            </Box>
            {initialSliderTransition === "IN_PROGRESS" && <PageSpinner />}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ImagesReorder;

gql`
  mutation AssetFileChangeOrder($assetFileIds: [UUID!]!) {
    assetFileChangeOrder(assetFileIds: $assetFileIds) {
      ...AssetFileFragment
    }
  }
`;
