import { Icon, IconProps } from "@chakra-ui/react";

const File = (props: IconProps & any) => {
  return (
    <Icon width="12" height="auto" viewBox="0 0 48 48" {...props}>
      <path
        d="M38 40a2 2 0 01-2 2H12a2 2 0 01-2-2V8a2 2 0 012-2h17.172a2 2 0 011.414.586l6.828 6.828A2 2 0 0138 14.828V40z"
        fill="#F1F1F1"
      />
      <path d="M38 14h-7a1 1 0 01-1-1V6l8 8z" fill="#CCC" />
    </Icon>
  );
};

export default File;
