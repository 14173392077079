import React, { useEffect } from "react";

import { useLocationIndexQuery } from "../../../../../graphql/graphql";
import { WorkOrder, WorkOrderAsset } from "../../../models/workOrder";
import { AssetToLocations } from "./models/relations";
import WorkOrderCreateAddAssetModalPresenter from "./presenter";

interface WorkOrderCreateAddAssetModalProps {
  isAddAssetModalVisible?: boolean;
  addAssetModalVisibleChange: (isAddAssetModalVisible: boolean) => void;
  onAddNewAsset: (asset: WorkOrderAsset) => void;
  workOrder: WorkOrder;
}

const WorkOrderCreateAddAssetModal: React.FC<
  WorkOrderCreateAddAssetModalProps
> = ({
  isAddAssetModalVisible,
  addAssetModalVisibleChange,
  workOrder,
  onAddNewAsset,
}) => {
  const { data: locationsWrapper } = useLocationIndexQuery({
    variables: {
      companyId: workOrder.companyId,
    },
    skip: !workOrder?.companyId,
    fetchPolicy: "network-only",
  });
  const [newAsset, setNewAsset] = React.useState<WorkOrderAsset>({});
  const [isAddAssetInfoValid, setIsAddAssetInfoValid] =
    React.useState<boolean>(false);
  const [assetToLocationsMap, setAssetToLocationsMap] =
    React.useState<AssetToLocations>({});

  const handleNewAssetChange = (asset: WorkOrderAsset | null) => {
    if (!asset) {
      setNewAsset((oldAsset) => ({
        ...oldAsset,
        ...{ asset: null, assetId: null },
      }));
      return;
    }
    if (!newAsset || (asset.assetId && newAsset.assetId !== asset.assetId)) {
      setNewAsset((oldAsset) => asset);
    } else {
      setNewAsset((oldAsset) => ({ ...oldAsset, ...asset }));
    }
  };

  const handleAddAsset = () => {
    onAddNewAsset(newAsset);
  };

  useEffect(() => {
    setNewAsset({});
  }, [isAddAssetModalVisible]);

  useEffect(() => {
    setIsAddAssetInfoValid(checkAssetValid());
  }, [newAsset]);

  function convertLocationToAssetLocationsMap() {
    const map: AssetToLocations = {};
    locationsWrapper?.locations.forEach((loc) => {
      const { id, name, type, floorPlans } = loc;
      const planAssets = floorPlans.flatMap((item) => item.floorPlanAssets);
      planAssets.forEach((asset) => {
        const location = {
          locationId: id,
          locationName: name,
          locationType: type ? type : "",
        };
        if (map[asset.assetId]) {
          map[asset.assetId].push(location);
        } else {
          map[asset.assetId] = [location];
        }
      });
    });
    setAssetToLocationsMap(map);
  }

  useEffect(() => {
    if (locationsWrapper) {
      convertLocationToAssetLocationsMap();
    }
  }, [locationsWrapper]);

  const checkAssetValid = (): boolean =>
    newAsset?.assetId &&
    newAsset.sublocationId &&
    newAsset?.serviceTypeId &&
    (!newAsset?.comment || newAsset.comment?.trim().length <= 200);

  return (
    <>
      <WorkOrderCreateAddAssetModalPresenter
        isAddAssetModalVisible={isAddAssetModalVisible}
        addAssetModalVisibleChange={addAssetModalVisibleChange}
        isAddAssetInfoValid={isAddAssetInfoValid}
        workOrder={workOrder}
        asset={newAsset}
        onAssetChange={handleNewAssetChange}
        onAddAsset={handleAddAsset}
        assetToLocationMap={assetToLocationsMap}
      />
    </>
  );
};

export default WorkOrderCreateAddAssetModal;
