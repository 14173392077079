import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React from "react";

import {
  useFloorPlanQuery,
  useLocationIndexQuery,
} from "../../../../../../graphql/graphql";
import { WOFloorPlan } from "../../../../../../graphql/types/queries/workOrdersQuery";
import Modal from "../../../../components/modal";
import PlanPreview from "../../../../components/planPreview";
import TruncatedText from "../../../../components/truncatedText";
import GetLocationBreadcrumbs from "../../../../hooks/getLocationBreadcrumbs";
import { AssetPlansTypeEnum } from "../../../../models/enums";
import { FloorPlansItem } from "../plansTable/presenter";

interface WorkOrderCreatePlanPreviewModalPresenterProps {
  isPlanPreviewOpen?: boolean;
  assetId?: string;
  companyId?: string;
  plan?: WOFloorPlan | null;
  planItem?: FloorPlansItem | null;
  sublocationId?: string;
  closeModal: () => void;
  isPlanItemSelected?: boolean;
  selectPlanItem?: (selectedPlansItem: FloorPlansItem) => void;
}

const WorkOrderCreatePlanPreviewModalPresenter: React.FC<
  WorkOrderCreatePlanPreviewModalPresenterProps
> = ({
  isPlanPreviewOpen,
  assetId,
  companyId,
  plan,
  planItem,
  sublocationId,
  closeModal,
  selectPlanItem,
  isPlanItemSelected,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const { data: locationsData } = useLocationIndexQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });
  const { data: floorPlan } = useFloorPlanQuery({
    variables: { id: plan?.id, companyId: companyId },
    skip: !plan?.id || !companyId || !locationsData,
  });
  const breadcrumbs = companyId
    ? GetLocationBreadcrumbs(plan?.location?.id, companyId, plan, planItem)
    : "";

  return (
    <>
      <Modal
        isOpen={!!isPlanPreviewOpen}
        closeModal={closeModal}
        headerLabel="Plan Overview"
      >
        <>
          <EuiFlexGroup
            direction="row"
            wrap={false}
            justifyContent="spaceBetween"
            alignItems="center"
          >
            <EuiFlexItem style={{ width: "fit-content", flexGrow: 1 }}>
              <div style={{ maxWidth: "400px" }}>
                <TruncatedText truncate={true} text={breadcrumbs} />
              </div>
            </EuiFlexItem>
            {!!selectPlanItem ? (
              <EuiFlexItem style={{ width: "fit-content", flexGrow: 0 }}>
                <EuiButton
                  disabled={isPlanItemSelected}
                  onClick={() =>
                    planItem && selectPlanItem && selectPlanItem(planItem)
                  }
                  color="primary"
                  style={{ color: euiTheme.colors.secondary["500"] }}
                  fullWidth={false}
                >
                  <EuiText
                    size="s"
                    style={{
                      fontWeight: 500,
                      lineHeight: euiTheme.sizes["21px"],
                      fontSize: euiTheme.sizes["14px"],
                      width: "fit-content",
                    }}
                    color={euiTheme.colors.secondary["500"]}
                  >
                    Add Plan to Asset’s Service
                  </EuiText>
                </EuiButton>
              </EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
          <div
            style={{
              border: euiTheme.border.thin,
              borderRadius: euiTheme.border.radius.medium,
              marginTop: euiTheme.size.s,
              minHeight: "40vh",
              maxHeight: "calc(75vh - 100px)",
              height: "fit-content",
              minWidth: "60vw",
            }}
          >
            <PlanPreview
              plan={floorPlan?.floorPlan}
              assetId={assetId}
              companyId={companyId}
              showAffectedAreas={
                planItem?.type === AssetPlansTypeEnum.AFFECTED_AREA
              }
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default WorkOrderCreatePlanPreviewModalPresenter;
