import { GENERIC_ERROR_MESSAGE } from "../constants/lang/en";

const setServerErrors = (errors: any, setFieldError: any) => {
  Object.keys(errors).forEach((errorKey: string) => {
    setFieldError(errorKey, errors[errorKey]);
  });
};

export const setGenericMessage = (error: any): string => {
  return !error ||
    !error.message ||
    error.message.includes("ERROR: ") ||
    error.message.includes("Response not successful: ")
    ? GENERIC_ERROR_MESSAGE
    : error.message.replace("GraphQL error: ", "");
};

export default setServerErrors;
