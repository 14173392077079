import React from "react";

import { WorkOrder } from "../../../../models/workOrder";
import WorkOrderVerifyAndSaveServicesTitlePresenter from "./presenter";

interface WorkOrderVerifyAndSaveServicesTitleProps {
  workOrder: WorkOrder;
}

const WorkOrderVerifyAndSaveServicesTitle: React.FC<
  WorkOrderVerifyAndSaveServicesTitleProps
> = ({ workOrder }) => {
  return (
    <>
      <WorkOrderVerifyAndSaveServicesTitlePresenter workOrder={workOrder} />
    </>
  );
};

export default WorkOrderVerifyAndSaveServicesTitle;
