import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import SelectFolder from "../../../components/elements/folderSelect";
import LabeledMenuButton from "../../../components/elements/labeledMenuButton";
import {
  SAME_FOLDER_MOVE_MESSAGE,
  getItemsDeleteMessage,
  getItemsMoveMessage,
} from "../../../constants/lang/en";
import {
  FileFragmentFragment,
  FolderFragmentFragment,
  useFileDeleteMutation,
  useFileMoveMutation,
  useFolderDeleteMutation,
  useFolderMoveMutation,
  useFoldersLazyQuery,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import { setGenericMessage } from "../../../utils/serverErrors";

interface FilesBulkActionsProps {
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  setSelectedItems: React.Dispatch<
    React.SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
  folderId: string;
  canDeleteFolder: boolean;
}

const FilesBulkActions: React.FC<FilesBulkActionsProps> = ({
  selectedItems,
  folderId,
  canDeleteFolder,
  setSelectedItems,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isMoveOpen,
    onOpen: onMoveOpen,
    onClose: onMoveClose,
  } = useDisclosure();
  const [selectedFolder, setSelectedFolder] =
    useState<FolderFragmentFragment>();
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null);
  const cancelMoveRef = React.useRef<HTMLButtonElement>(null);
  const [refetchFolders] = useFoldersLazyQuery({
    fetchPolicy: "network-only",
  });
  const [fileDeleteMutation, { loading: fileDeleteLoading }] =
    useFileDeleteMutation();
  const [fileMoveMutation, { loading: fileMoveLoading }] =
    useFileMoveMutation();
  const [folderDeleteMutation, { loading: folderDeleteLoading }] =
    useFolderDeleteMutation();
  const [folderMoveMutation, { loading: folderMoveLoading }] =
    useFolderMoveMutation();
  const navigate = useNavigate();

  const deleteItems = async () => {
    try {
      let fileIds: Array<string> = [];
      let folderIds: Array<string> = [];
      selectedItems.forEach((item) => {
        if (item?.__typename === "File") {
          fileIds.push(item.id);
        } else {
          folderIds.push(item?.id);
        }
      });
      if (fileIds.length) {
        const { errors } = await fileDeleteMutation({
          variables: { ids: fileIds },
        });
        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
      if (folderIds.length) {
        const { errors } = await folderDeleteMutation({
          variables: { ids: folderIds },
        });
        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
      selectedItems.forEach((item) =>
        client.cache.evict({
          id:
            item?.__typename === "File"
              ? `File:${item.id}`
              : `Folder:${item?.id}`,
        })
      );
      toast({
        description: getItemsDeleteMessage(selectedItems.length),
        status: "success",
        position: "top",
        isClosable: true,
      });
      setSelectedItems([]);
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      onDeleteClose();
    }
  };

  const moveItems = async () => {
    if (!selectedFolder) return;
    if (selectedFolder.id === folderId) {
      toast({
        description: SAME_FOLDER_MOVE_MESSAGE,
        status: "error",
        position: "top",
        isClosable: true,
      });
      return;
    }
    try {
      let fileIds: Array<string> = [];
      let folderIds: Array<string> = [];
      selectedItems.forEach((item) => {
        if (item?.__typename === "File") {
          fileIds.push(item.id);
        } else {
          folderIds.push(item?.id);
        }
      });
      if (fileIds.length) {
        const { errors } = await fileMoveMutation({
          variables: {
            folderId: selectedFolder.id,
            ids: fileIds,
          },
        });
        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
      if (folderIds.length) {
        const { errors } = await folderMoveMutation({
          variables: {
            parentFolderId: selectedFolder.id,
            ids: folderIds,
          },
        });
        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
      await refetchFolders();
      toast({
        description: getItemsMoveMessage(selectedItems.length),
        status: "success",
        position: "top",
        isClosable: true,
      });
      setSelectedItems([]);
      navigate(getRoutePath("foldersShow", { folderId: selectedFolder.id }));
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    onMoveClose();
    setSelectedFolder(undefined);
  };

  return selectedItems.length ? (
    <Box position="static" right="0" top="-56px">
      <Menu>
        <LabeledMenuButton label="Action" value="" />
        <MenuList minWidth="100px">
          <MenuItem onClick={onMoveOpen}>
            Move selected {pluralize("item", selectedItems.length)}
          </MenuItem>
          {canDeleteFolder && (
            <MenuItem onClick={onDeleteOpen}>
              Delete selected {pluralize("item", selectedItems.length)}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      {isMoveOpen && (
        <>
          <Modal isOpen onClose={() => onMoveClose()} size="sm">
            <ModalOverlay>
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  <SelectFolder
                    onSuccess={setSelectedFolder}
                    selectedItems={selectedItems}
                  />
                </ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
          <AlertDialog
            leastDestructiveRef={cancelMoveRef}
            onClose={handleClose}
            isOpen={isMoveOpen && !!selectedFolder}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogBody textAlign="center">
                  Are you sure you want to move {selectedItems.length} selected{" "}
                  {pluralize("item", selectedItems.length)} to{" "}
                  <strong>{selectedFolder?.name}</strong>?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelMoveRef} onClick={handleClose} width="48%">
                    No, Don't Move!
                  </Button>
                  <Button
                    onClick={moveItems}
                    colorScheme="red"
                    ml="4%"
                    width="48%"
                    isLoading={fileMoveLoading || folderMoveLoading}
                  >
                    Yes, Move
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
      {canDeleteFolder && (
        <AlertDialog
          leastDestructiveRef={cancelDeleteRef}
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogBody textAlign="center">
                Are you sure you want to delete {selectedItems.length} selected{" "}
                {pluralize("item", selectedItems.length)}?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelDeleteRef}
                  onClick={onDeleteClose}
                  width="48%"
                >
                  No, Don't Delete!
                </Button>
                <Button
                  onClick={deleteItems}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                  isLoading={fileDeleteLoading || folderDeleteLoading}
                >
                  Yes, Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </Box>
  ) : null;
};

export default FilesBulkActions;

gql`
  mutation FileMove($folderId: UUID!, $ids: [UUID!]!) {
    fileMove(folderId: $folderId, ids: $ids)
  }
`;

gql`
  mutation FolderMove($parentFolderId: UUID!, $ids: [UUID!]!) {
    folderMove(parentFolderId: $parentFolderId, ids: $ids)
  }
`;
