import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  faAddressBook,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faBell, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";

import ContactUserInfo from "../../../components/elements/contactUserInfo";
import Link from "../../../components/elements/link";
import PageSpinner from "../../../components/elements/pageSpinner";
import AddCircle from "../../../components/icons/addCircle";
import { defaultDateWithTimeAndZoneFormat } from "../../../constants/date";
import { useMaintenanceQuery } from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import { getRoutePath } from "../../../router";
import { generateRruleSetForMaintenance } from "../../../utils/date/rrule";
import { getDateBgColor, getScheduleBgColor } from "../../../utils/status";
import MaintenanceActions from "../actions";
import MaintenanceDelete from "../delete";
import MaintenanceEdit from "../edit";
import MaintenanceMarkAsComplete from "../markAsComplete";
import MaintenanceMarkAsInComplete from "../markAsInComplete";
import MaintenanceNoteCreate from "../noteCreate";
import CompletedBy from "./completedBy";
import MaintenanceNotes from "./notes";
import MaintenanceRecurring from "./recurring";

interface MaintenanceShowProps {}

const MaintenanceShow: React.FC<MaintenanceShowProps> = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const maintenanceId = searchParams.get("maintenanceId");
  const maintenanceScheduleId = searchParams.get("maintenanceScheduleId");
  const maintenanceTimestampQuery = searchParams.get("maintenanceTimestamp");
  const maintenanceTimestamp = useMemo(
    () =>
      maintenanceTimestampQuery
        ? new Date(+maintenanceTimestampQuery)
        : new Date(),
    [maintenanceTimestampQuery]
  );
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data } = useMaintenanceQuery({
    variables: {
      id: maintenanceId,
      startDate: maintenanceTimestamp.getTime(),
      endDate: maintenanceTimestamp.getTime(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const maintenance = data?.maintenance;
  const maintenanceSchedule = useMemo(
    () =>
      maintenance && maintenanceScheduleId
        ? maintenance.maintenanceSchedulesForDateRange.find(
            (ms) => ms.id === maintenanceScheduleId
          )
        : null,
    [maintenance, maintenanceScheduleId]
  );
  const maintenanceRrule = useMemo(
    () => (maintenance ? generateRruleSetForMaintenance(maintenance) : null),
    [maintenance]
  );
  const [subPage, setSubPage] = useState<
    | "serviceSchedulesMarkAsComplete"
    | "serviceSchedulesMarkAsInComplete"
    | "serviceSchedulesDelete"
    | "serviceSchedulesEdit"
    | "serviceSchedulesEditRecurring"
    | "serviceSchedulesRecurring"
    | "notesCreate"
    | ""
  >("");
  const notes = useMemo(
    () =>
      maintenance
        ? orderBy(maintenance.maintenanceNotes, "createdAt", "desc")
        : [],
    [maintenance]
  );
  const canAddMaintenance = ROLES.maintenancesCreate.includes(
    currentCompanyRole.role
  );
  const canDeleteMaintenance = ROLES.maintenancesDelete.includes(
    currentCompanyRole.role
  );
  const canEditMaintenance = ROLES.maintenancesEdit.includes(
    currentCompanyRole.role
  );
  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSubPageClose = useCallback(() => {
    setSubPage("");
  }, []);

  const toggleCompletion = useCallback(() => {
    if (!maintenanceId || !maintenanceSchedule) return;
    setSubPage(
      maintenanceSchedule.completedAt
        ? "serviceSchedulesMarkAsInComplete"
        : "serviceSchedulesMarkAsComplete"
    );
  }, [maintenanceId, maintenanceSchedule]);

  const handleRecurring = useCallback(() => {
    if (!maintenance) return;
    setSubPage("serviceSchedulesRecurring");
  }, [maintenance]);

  const handleEdit = useCallback(() => {
    if (!maintenance) return;
    setSubPage("serviceSchedulesEdit");
  }, [maintenance]);

  const handleEditRecurring = useCallback(() => {
    if (!maintenance) return;
    setSubPage("serviceSchedulesEditRecurring");
  }, [maintenance]);

  const handleDelete = useCallback(() => {
    if (!maintenance) return;
    setSubPage("serviceSchedulesDelete");
  }, [maintenance]);

  const handleNoteCreation = useCallback(() => {
    if (!maintenance) return;
    setSubPage("notesCreate");
  }, [maintenance]);

  const maintenanceContactIds = useMemo(
    () =>
      maintenance?.maintenanceCompanyUsers.map(
        (mcu) => mcu.companyUser.user.id
      ) || [],
    [maintenance]
  );

  return (
    <Drawer isOpen onClose={onClose}>
      <Helmet>
        <title>Service Event</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          {subPage === "serviceSchedulesRecurring" && maintenance ? (
            <>
              <DrawerHeader display="flex" alignItems="center">
                <IconButton
                  px="3"
                  minW="none"
                  variant="link"
                  colorScheme="gray"
                  color="gray.600"
                  onClick={handleSubPageClose}
                  aria-label="Back"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
                <Box ml="3">{maintenance.name} Series</Box>
              </DrawerHeader>
              <DrawerBody>
                <MaintenanceRecurring
                  maintenanceId={maintenance.id}
                  maintenanceContactIds={maintenanceContactIds}
                  maintenanceResolvedName={maintenance.misc.resolvedName}
                  maintenanceLocationsCopy={
                    maintenance.asset?.locationsLabel || ""
                  }
                />
              </DrawerBody>
            </>
          ) : [
              "serviceSchedulesEdit",
              "serviceSchedulesEditRecurring",
            ].includes(subPage) && maintenance ? (
            <>
              <DrawerHeader display="flex" alignItems="center">
                <IconButton
                  px="3"
                  minW="none"
                  variant="link"
                  colorScheme="gray"
                  color="gray.600"
                  onClick={handleSubPageClose}
                  aria-label="Back"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
                <Box ml="3">Edit Service Event</Box>
              </DrawerHeader>
              <DrawerBody>
                <MaintenanceEdit
                  maintenanceId={maintenance.id}
                  assetId={maintenance.assetId}
                  onClose={handleSubPageClose}
                  type={
                    "serviceSchedulesEdit" === subPage ? "full" : "recurring"
                  }
                />
              </DrawerBody>
            </>
          ) : (
            <>
              <DrawerCloseButton color="white" />
              <DrawerHeader
                display="flex"
                alignItems="center"
                py="3"
                px="3"
                color="white"
                bgColor={
                  maintenanceSchedule
                    ? getScheduleBgColor(maintenanceSchedule)
                    : maintenanceTimestampQuery
                    ? getDateBgColor(maintenanceTimestamp)
                    : "black"
                }
              >
                <FontAwesomeIcon icon={faBell} />
                <Box ml="3">Service Event</Box>
              </DrawerHeader>
              <DrawerBody p="0">
                {maintenance ? (
                  <>
                    <Box
                      bgColor="gray.50"
                      px="3"
                      pt="2"
                      pb="4"
                      borderBottom="1px solid"
                      borderBottomColor="gray.200"
                    >
                      <Flex align="center">
                        <Heading size="xl" fontWeight="600" flexGrow={1}>
                          {maintenance.misc.resolvedName}
                        </Heading>
                        <MaintenanceActions
                          handleDelete={
                            canDeleteMaintenance ? handleDelete : undefined
                          }
                          handleEdit={
                            canEditMaintenance ? handleEdit : undefined
                          }
                        />
                      </Flex>
                      {!!maintenance.asset?.locationsLabel && (
                        <Box mt="1">
                          Located at {maintenance.asset?.locationsLabel}
                        </Box>
                      )}
                      <Text mt="2">
                        {maintenanceSchedule ? (
                          <>
                            {maintenanceSchedule.completedAt ? (
                              <Flex align="center">
                                <Box flexShrink={0} color="green.500" mr="2">
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    size="2x"
                                  />
                                </Box>
                                <CompletedBy
                                  maintenanceSchedule={maintenanceSchedule}
                                />
                              </Flex>
                            ) : (
                              format(
                                maintenanceSchedule.scheduledAt,
                                defaultDateWithTimeAndZoneFormat
                              )
                            )}
                          </>
                        ) : maintenanceTimestampQuery ? (
                          format(
                            maintenanceTimestamp,
                            defaultDateWithTimeAndZoneFormat
                          )
                        ) : null}
                      </Text>
                      <Flex align="center" justify="space-between" mt="4">
                        {maintenanceSchedule ? (
                          <Button
                            colorScheme={
                              maintenanceSchedule.completedAt
                                ? "gray"
                                : "secondary"
                            }
                            onClick={toggleCompletion}
                            size="sm"
                          >
                            {maintenanceSchedule.completedAt
                              ? "Mark as Incomplete"
                              : "Mark as Complete"}
                          </Button>
                        ) : (
                          <Box />
                        )}
                        {!!maintenance?.assetId && (
                          <Link
                            to={getRoutePath("assetsShow", {
                              assetId: maintenance.assetId,
                            })}
                            colorScheme="secondary"
                            variant="link"
                          >
                            View Asset
                          </Link>
                        )}
                      </Flex>
                    </Box>
                    {!!maintenance.maintenanceCompanyUsers.length && (
                      <Box px="3">
                        <Heading
                          as="h3"
                          pt="3"
                          size="md"
                          display="flex"
                          alignItems="center"
                        >
                          Who is responsible for this service?
                        </Heading>
                        <Flex align="center" mt="-1">
                          <Box color="gray.600" mr="2" mt="2">
                            <FontAwesomeIcon icon={faAddressBook} size="lg" />
                          </Box>
                          <ContactUserInfo
                            contactCompanyUsers={maintenance.maintenanceCompanyUsers.map(
                              (mcu) => mcu.companyUser
                            )}
                          />
                        </Flex>
                      </Box>
                    )}
                    <Box px="3" pt="4">
                      {maintenance &&
                      maintenance.intervalType &&
                      maintenance.intervalValue &&
                      maintenanceRrule ? (
                        <>
                          <Text>
                            A reminder for this service recurs{" "}
                            {maintenanceRrule.toText()}
                            {!maintenance.endAfter && !maintenance.endDateTime
                              ? ", no end date."
                              : "."}
                          </Text>
                          <Button
                            variant="link"
                            fontSize="sm"
                            colorScheme="secondary"
                            onClick={handleRecurring}
                          >
                            View Series (complete and incomplete)
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="link"
                          fontSize="sm"
                          colorScheme="secondary"
                          onClick={handleEditRecurring}
                        >
                          Make this service event recurring
                        </Button>
                      )}
                    </Box>
                    <Box pt="4">
                      <Heading
                        as="h3"
                        size="md"
                        fontWeight="600"
                        px="3"
                        mb="2"
                        display="flex"
                        alignItems="center"
                      >
                        Notes
                        {!!notes.length && canAddMaintenance && (
                          <Tooltip
                            label="Add new note"
                            hasArrow
                            placement="left"
                          >
                            <IconButton
                              aria-label="Add new note"
                              variant="icon"
                              colorScheme="secondary"
                              ml="2"
                              onClick={handleNoteCreation}
                            >
                              <AddCircle boxSize="6" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Heading>
                      {notes.length ? (
                        <MaintenanceNotes
                          notes={notes}
                          canEditMaintenance={canEditMaintenance}
                        />
                      ) : canAddMaintenance ? (
                        <Button
                          aria-label="Add new asset"
                          variant="link"
                          colorScheme="secondary"
                          p="3"
                          pl="2"
                          ml="1"
                          mt="-3"
                          fontSize="sm"
                          onClick={handleNoteCreation}
                        >
                          Add Note
                        </Button>
                      ) : (
                        <Box p="3" fontSize="sm">
                          Notes Empty
                        </Box>
                      )}
                    </Box>
                    {subPage === "serviceSchedulesMarkAsComplete" &&
                    maintenance &&
                    maintenanceSchedule ? (
                      <MaintenanceMarkAsComplete
                        maintenanceResolvedName={maintenance.misc.resolvedName}
                        maintenanceLocationsCopy={
                          maintenance.asset?.locationsLabel || ""
                        }
                        maintenanceScheduleDateCopy={format(
                          maintenanceSchedule.scheduledAt,
                          defaultDateWithTimeAndZoneFormat
                        )}
                        maintenanceScheduleId={maintenanceSchedule.id}
                        onClose={handleSubPageClose}
                        contactIds={maintenanceContactIds}
                      />
                    ) : subPage === "serviceSchedulesMarkAsInComplete" &&
                      maintenanceSchedule ? (
                      <MaintenanceMarkAsInComplete
                        maintenanceResolvedName={maintenance.misc.resolvedName}
                        maintenanceLocationsCopy={
                          maintenance.asset?.locationsLabel || ""
                        }
                        maintenanceScheduleDateCopy={format(
                          maintenanceSchedule.scheduledAt,
                          defaultDateWithTimeAndZoneFormat
                        )}
                        maintenanceScheduleId={maintenanceSchedule.id}
                        onClose={handleSubPageClose}
                      />
                    ) : subPage === "serviceSchedulesDelete" && maintenance ? (
                      <MaintenanceDelete
                        maintenanceId={maintenance.id}
                        assetId={maintenance.assetId}
                        onClose={handleSubPageClose}
                        maintenanceScheduleTimestamp={
                          maintenanceSchedule?.scheduledAt
                        }
                        recurring={
                          !!(
                            maintenance.intervalType &&
                            maintenance.intervalValue
                          )
                        }
                      />
                    ) : subPage === "notesCreate" && maintenance ? (
                      <MaintenanceNoteCreate
                        maintenance={maintenance}
                        onClose={handleSubPageClose}
                      />
                    ) : null}
                  </>
                ) : (
                  <PageSpinner />
                )}
              </DrawerBody>
            </>
          )}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MaintenanceShow;

gql`
  query Maintenance($id: UUID!, $startDate: Timestamp, $endDate: Timestamp) {
    maintenance(id: $id) {
      ...MaintenanceWithRelationsFragment
    }
  }

  query MaintenanceWithSchedules($id: UUID!) {
    maintenance(id: $id) {
      ...MaintenanceWithSchedulesFragment
    }
  }
`;
