import { Center } from "@chakra-ui/react";
import { ICellEditorParams } from "ag-grid-community";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

const AgGridCheckbox = memo(
  forwardRef((props: ICellEditorParams, ref: any) => {
    const [checked, setChecked] = useState(
      typeof props.value === "boolean" ? props.value : props.value === "true"
    );
    const refInput = useRef<HTMLInputElement>(null);

    const checkedHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const colId = props.column.getColId();
        props.node.setDataValue(colId, checked);
        setChecked(checked);
      },
      [props.column, props.node]
    );

    useEffect(() => refInput.current?.focus(), []);

    return (
      <Center height="100%">
        <input
          ref={refInput}
          type="checkbox"
          onChange={checkedHandler}
          checked={checked}
          style={{ pointerEvents: props.data.isDuplicate ? "none" : "unset" }}
        />
      </Center>
    );
  })
);

export default AgGridCheckbox;
