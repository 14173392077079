import "slick-carousel/slick/slick.css";

import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { sortBy } from "lodash";
import React from "react";
import Slider from "react-slick";

import SubscriptionPrice from "../../../components/elements/subscriptionPrice";
import ExclamationIcon from "../../../components/icons/exclamation";
import NoCircleIcon from "../../../components/icons/noCircle";
import PlanIcon from "../../../components/icons/planIcon";
import YesCircleIcon from "../../../components/icons/yesCircle";
import {
  PLANS,
  PLAN_MAPPINGS,
  canCompanySubscribeToPlan,
  getPlanName,
  planDisabledReason,
} from "../../../constants/plan";
import {
  CompanyFragmentFragment,
  PlansFragmentFragment,
  useCompanyQuery,
} from "../../../graphql/graphql";
import { currentCompanyPlanVar } from "../../../graphql/reactiveVariables";
import usePaymentsAvailable from "../../../hooks/usePaymentsAvailable";

interface PlansPresenterProps {
  header?: string;
  onPlanSelected: (planStripeId: string) => void;
  plans: PlansFragmentFragment[];
  currentCompany: CompanyFragmentFragment | undefined;
  mainUserCompany: CompanyFragmentFragment | undefined;
}

const PlansPresenter: React.FC<PlansPresenterProps> = ({
  header,
  onPlanSelected,
  plans,
  currentCompany,
  mainUserCompany,
}) => {
  const currentPlan = useReactiveVar(currentCompanyPlanVar);
  const { data: companyData } = useCompanyQuery();
  const trialAvailable = !companyData?.company.planStripeStatus;
  const paymentsAvailable = usePaymentsAvailable();
  const [selectedPlanInterval, setSelectedPlanInterval] =
    React.useState("year");
  const monthlyPlans = React.useMemo(
    () => plans.filter((plan) => plan.active && plan.interval === "month"),
    [plans]
  );
  const yearlyPlans = React.useMemo(
    () => plans.filter((plan) => plan.active && plan.interval === "year"),
    [plans]
  );
  const activePlanId = React.useMemo(
    () =>
      plans.find((plan) => plan.stripeId === currentPlan?.stripeId)?.stripeId,
    [plans, currentPlan]
  );
  const isPlanGood =
    !mainUserCompany?.planStripeCancelAt &&
    (mainUserCompany?.planStripeStatus === "active" ||
      (mainUserCompany?.planStripeStatus === "trialing" && paymentsAvailable));
  const visibleSortedPlans = React.useMemo(
    () =>
      sortBy(
        selectedPlanInterval === "month" ? monthlyPlans : yearlyPlans,
        "amount"
      ),
    [selectedPlanInterval, monthlyPlans, yearlyPlans]
  );

  return (
    <>
      <Heading as="h1" marginBottom="8" textAlign="center">
        {header}
      </Heading>
      <Container maxWidth="72rem" margin="auto" color="black" padding="0">
        <Flex border="1px solid" borderColor="gray.100">
          <Box width={{ base: "55%", lg: "22rem" }}>
            <PlanHeader>
              <Center height="100%">
                <Box>
                  <Heading size="3xl">Choose your plan</Heading>
                  <Text paddingTop="4">
                    {trialAvailable ? "14-day free trial for all plans. " : ""}
                    No risk! Upgrade, downgrade or cancel any time.
                  </Text>
                </Box>
              </Center>
            </PlanHeader>
            {PLAN_MAPPINGS.map((pm) => (
              <PlanItem
                key={pm.key}
                value={pm.value}
                tooltip={pm.tooltip}
                placement="flex-start"
              />
            ))}
            <PlanFooter />
          </Box>
          <Box width={{ base: "45%", lg: "50rem" }}>
            <Slider
              dots
              arrows={false}
              infinite={false}
              slidesToShow={4}
              responsive={[
                { breakpoint: 1024, settings: { slidesToShow: 2 } },
                { breakpoint: 768, settings: { slidesToShow: 1 } },
              ]}
              className="slick-with-dots"
            >
              {visibleSortedPlans.map((plan, i) => (
                <Box
                  key={plan.id}
                  backgroundColor={i % 2 === 0 ? "gray.50" : "white"}
                >
                  <PlanHeader>
                    <Box textAlign="center">
                      <Center
                        height="3.5rem"
                        width="calc(100% + 1.5rem)"
                        marginLeft="-3"
                        position="relative"
                        marginBottom="2"
                      >
                        <Box
                          backgroundColor="secondary.500"
                          position="absolute"
                          opacity={i % 2 === 0 ? ".1" : ".05"}
                          width="100%"
                          height="100%"
                          left="0"
                          top="0"
                        />
                        {plan.productName === "Premium" && (
                          <Box
                            backgroundColor="secondary.500"
                            color="white"
                            fontSize=".6rem"
                            textTransform="uppercase"
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                          >
                            Most Popular
                          </Box>
                        )}
                        {plan.productName === "Professional" && (
                          <Box
                            backgroundColor="black"
                            color="white"
                            fontSize=".6rem"
                            textTransform="uppercase"
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                          >
                            For Contractors
                          </Box>
                        )}
                        <PlanIcon planName={plan.productName} boxSize="24px" />
                        <Heading as="h2" size="md" marginLeft="2">
                          {getPlanName(plan.productName)}
                        </Heading>
                      </Center>
                      <Box height="5.75rem">
                        {plan.productName !== "Professional" && (
                          <SubscriptionPrice
                            name={plan.productName}
                            amount={plan.amount}
                            interval={plan.interval}
                          />
                        )}
                        {plan.productName === "Professional" && (
                          <>
                            <Box>
                              <Heading as="h2" size="md" fontWeight="700">
                                Contact Sales
                              </Heading>
                            </Box>
                            <Box>
                              <Link href="tel:833-744-1010" isExternal>
                                833-744-1010
                              </Link>
                            </Box>
                            <Box>
                              <Link
                                href="mailto:sales@criticalasset.com"
                                isExternal
                              >
                                sales@criticalasset.com
                              </Link>
                            </Box>
                          </>
                        )}
                      </Box>
                      {currentCompany ? (
                        <PlanButton
                          trialAvailable={trialAvailable}
                          name={plan.productName}
                          planId={plan.stripeId}
                          onPlanSelected={onPlanSelected}
                          activePlanId={isPlanGood ? activePlanId : undefined}
                          company={currentCompany}
                        />
                      ) : (
                        ""
                      )}
                      <Text
                        color="gray.800"
                        fontWeight="bold"
                        fontSize=".6rem"
                        marginY="3"
                        width="full"
                      >
                        {plan.productName === "Professional"
                          ? "For Approved Contractors only"
                          : ""}
                      </Text>
                    </Box>
                  </PlanHeader>
                  {PLAN_MAPPINGS.map((pm) => {
                    const planDetail = (PLANS as any)[plan.productName][pm.key];
                    return (
                      <PlanItem
                        key={pm.key}
                        value={planDetail.value}
                        tooltip={planDetail.tooltip}
                      />
                    );
                  })}
                  <PlanFooter>
                    <Box textAlign="center">
                      <Heading as="h2" size="md" marginTop="2">
                        {plan.productName}
                      </Heading>
                      {currentCompany ? (
                        <PlanButton
                          trialAvailable={trialAvailable}
                          name={plan.productName}
                          planId={plan.stripeId}
                          onPlanSelected={onPlanSelected}
                          activePlanId={isPlanGood ? activePlanId : undefined}
                          company={currentCompany}
                        />
                      ) : (
                        ""
                      )}
                      <Text
                        color="gray.800"
                        fontWeight="bold"
                        fontSize=".6rem"
                        marginY="3"
                        width="full"
                      >
                        {plan.productName === "Professional"
                          ? "For Approved Contractors only"
                          : ""}
                      </Text>
                    </Box>
                  </PlanFooter>
                </Box>
              ))}
            </Slider>
          </Box>
        </Flex>
        <Text fontSize="sm" color="gray.600" marginY="4">
          Need data onboarding or plan scanning services? We do it all!{" "}
          <Link
            href={`${process.env.REACT_APP_LIVE_WEBSITE_URL}/services/`}
            isExternal
            textDecoration="underline"
          >
            Click here
          </Link>{" "}
          to learn more.
        </Text>
      </Container>
    </>
  );
};

PlansPresenter.defaultProps = {
  header: "Select your plan",
};

export default PlansPresenter;

const PlanItem = ({
  value,
  tooltip,
  placement = "center",
}: {
  value: string | boolean;
  tooltip?: string;
  placement?: "center" | "flex-start";
}) => {
  return (
    <Flex
      paddingX="3"
      height="2.5rem"
      borderBottom="1px solid"
      borderColor="gray.100"
      alignItems="center"
      justifyContent={placement}
    >
      <Box fontSize={{ base: "xs", sm: "md" }}>
        {typeof value === "boolean" ? (
          value ? (
            <YesCircleIcon />
          ) : (
            <NoCircleIcon />
          )
        ) : value == null ? (
          <Box backgroundColor="secondary.500" height="1px" width="3" />
        ) : (
          value
        )}
      </Box>
      {!!tooltip && (
        <Box marginLeft="1">
          <Tooltip
            label={tooltip}
            hasArrow
            placement={placement === "center" ? "left" : "right"}
          >
            <Box>
              <ExclamationIcon />
            </Box>
          </Tooltip>
        </Box>
      )}
    </Flex>
  );
};

const PlanHeader: React.FC = ({ children }) => {
  return (
    <Box
      height="15rem"
      paddingX="3"
      borderBottom="1px solid"
      borderColor="gray.100"
    >
      {children}
    </Box>
  );
};

const PlanFooter: React.FC = ({ children }) => {
  return (
    <Box height="6.5rem" paddingX="3">
      {children}
    </Box>
  );
};

const PlanButton: React.FC<{
  name: string;
  planId: string;
  onPlanSelected: (planId: string) => void;
  activePlanId?: string;
  trialAvailable: boolean;
  company: CompanyFragmentFragment;
}> = ({
  name,
  planId,
  onPlanSelected,
  activePlanId,
  trialAvailable,
  company,
}) => {
  const disabled = !canCompanySubscribeToPlan(name as any, company);

  return name === "Professional" ? (
    <Button
      as={Link}
      href={`${process.env.REACT_APP_LIVE_WEBSITE_URL}/partners/`}
      width="full"
      _hover={{ textDecoration: "none" }}
      size="xs"
      fontSize=".6rem"
      target="_blank"
      rel="noopener noreferrer"
      marginTop="2"
    >
      Apply
    </Button>
  ) : (
    <Tooltip
      label={disabled ? planDisabledReason(name as any, company) : undefined}
      hasArrow
    >
      <Button
        size="xs"
        fontSize=".6rem"
        // Tooltip doesn't work for disabled button so hacking in onClick, cursor, boxShadow and opacity to make it work
        onClick={disabled ? undefined : () => onPlanSelected(planId)}
        cursor={disabled ? "not-allowed" : undefined}
        opacity={disabled ? 0.4 : undefined}
        boxShadow={disabled ? "none" : undefined}
        isDisabled={activePlanId === planId}
        width="full"
        marginTop="2"
      >
        {activePlanId === planId
          ? "Your Current Plan"
          : trialAvailable
          ? "Start your free trial"
          : "Select Plan"}
      </Button>
    </Tooltip>
  );
};
