import { useEffect, useState } from "react";

import { useCompanyQuery } from "../graphql/graphql";
import useCompanySettingsProgress from "./useCompanySettingsProgress";

const useCompanySetupProgress = () => {
  const companySettingsCompletedPercentage = useCompanySettingsProgress();
  const { data } = useCompanyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const onboarding = data?.company.config.onboarding || {};
    let localPercent = 0;
    localPercent += onboarding.companyInfo?.completed
      ? 12.5
      : (12.5 / 100) * companySettingsCompletedPercentage;
    if (onboarding.locationAdded?.completed) localPercent += 12.5;
    if (onboarding.teamMemberAdded?.completed) localPercent += 12.5;
    if (onboarding.planSubscribed?.completed) localPercent += 12.5;
    if (onboarding.paymentAdded?.completed) localPercent += 12.5;
    if (onboarding.planUploaded?.completed) localPercent += 12.5;
    if (onboarding.categoryAndTypeAdded?.completed) localPercent += 12.5;
    if (onboarding.assetAdded?.completed) localPercent += 12.5;

    setPercent(Math.round(localPercent));
  }, [companySettingsCompletedPercentage, data?.company.config.onboarding]);

  return percent;
};

export default useCompanySetupProgress;
