import { Box } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  AssetFileFragmentFragment,
  AssetFileType,
  AssetWithRelationsFragmentFragment,
  FolderDisplayType,
  SortBy,
  SortOrder,
  useFolderConfigQuery,
  useUpdateFolderConfigMutation,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import orderBy from "../../../../utils/sort";
import AssetFileDelete from "../../assetFileDelete";
import Presenter from "./presenter";

interface AssetFilesProps {
  asset: AssetWithRelationsFragmentFragment;
  canAddAsset: boolean;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
}

const AssetFiles: React.FC<AssetFilesProps> = ({
  asset,
  canAddAsset,
  canEditAsset,
  canDeleteAsset,
}) => {
  const navigate = useNavigate();
  const [assetFileToDelete, setAssetFileToDelete] =
    React.useState<AssetFileFragmentFragment>();
  const { data: folderConfigData } = useFolderConfigQuery();
  const folderConfig = folderConfigData?.folderConfig;
  const sortBy = folderConfig?.sortBy || SortBy.Name;
  const sortOrder = folderConfig?.sortOrder || SortOrder.Asc;
  const displayType = folderConfig?.displayType || FolderDisplayType.Grid;
  const [updateFolderConfigMutation] = useUpdateFolderConfigMutation();

  const setFileView = React.useCallback(
    (displayType) => {
      if (!folderConfig) return;
      updateFolderConfigMutation({
        variables: { displayType },
      });
    },
    [folderConfig, updateFolderConfigMutation]
  );

  const setSort = React.useCallback(
    (value) => {
      const [sortBy, sortOrder] = value.split("-");
      updateFolderConfigMutation({
        variables: { sortBy, sortOrder },
      });
    },
    [updateFolderConfigMutation]
  );

  const assetFiles = React.useMemo(
    () =>
      asset
        ? orderBy(
            asset.assetFiles.filter((af) => af.type !== AssetFileType.Image),
            sortBy,
            sortOrder
          )
        : undefined,
    [asset, sortBy, sortOrder]
  );

  const onEdit = React.useCallback(
    (id: string) => {
      navigate(
        getRoutePath("assetsShowFileEdit", { assetId: asset.id, fileId: id })
      );
    },
    [navigate, asset.id]
  );

  const onDelete = React.useCallback(
    (assetFile: AssetFileFragmentFragment) => {
      setAssetFileToDelete(assetFile);
    },
    [setAssetFileToDelete]
  );

  return assetFiles ? (
    <Box>
      <Presenter
        assetId={asset.id}
        assetFiles={assetFiles}
        canAddAsset={canAddAsset}
        canEditAsset={canEditAsset}
        canDeleteAsset={canDeleteAsset}
        handleEdit={canEditAsset ? onEdit : undefined}
        handleDelete={canDeleteAsset ? onDelete : undefined}
        sortBy={sortBy}
        sortOrder={sortOrder}
        displayType={displayType}
        setFileView={setFileView}
        setSort={setSort}
      />
      {assetFileToDelete && (
        <AssetFileDelete
          assetFile={assetFileToDelete}
          assetId={asset.id}
          handleDelete={() => setAssetFileToDelete(undefined)}
        />
      )}
    </Box>
  ) : null;
};

export default AssetFiles;
