const Drawer = {
  baseStyle: () => ({
    header: {
      paddingRight: "11",
    },
    dialog: {
      maxHeight: "screenFull",
    },
    footer: {
      flexBasis: "auto",
    },
  }),
  defaultProps: {
    size: "md",
  },
};

export default Drawer;
