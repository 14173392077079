import { Box } from "@chakra-ui/react";
import { toJpeg } from "html-to-image";
import jsPDF from "jspdf";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useAssetQuery } from "../../../graphql/graphql";
import { PanelPrintContent } from "../../assets/show/details/panelPrint";

declare let window: any;

interface NativeAssetPanelPrintPageProps {}

const NativeAssetPanelPrintPage: React.FC<
  NativeAssetPanelPrintPageProps
> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { assetId: id } = useParams();
  const { data } = useAssetQuery({
    variables: { id },
  });

  useEffect(() => {
    if (data) {
      setTimeout(async () => {
        if (!window.ReactNativeWebView?.postMessage || !ref.current) return;
        const dataUrl = await toJpeg(ref.current, {
          backgroundColor: "white",
        });
        const pdf = new jsPDF("p", "in", [5, 8], true);
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "JPEG", 0, 0, pdfWidth, pdfHeight);
        const base64 = pdf
          .output("datauristring")
          .replace("filename=generated.pdf;", "");
        window.location.replace(base64);
        window.ReactNativeWebView.postMessage({
          eventType: "CA:AssetPanelPDF",
          payload: { base64 },
        });
      }, 1000);
    }
  }, [data]);

  return data ? (
    <Box ref={ref}>
      <PanelPrintContent asset={data.asset} autoPrintOnLoad={false} />
    </Box>
  ) : (
    <PageSpinner />
  );
};

export default NativeAssetPanelPrintPage;
