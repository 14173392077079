import React from "react";

import { WOCompany } from "../../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateCompanyDetailsPresenter from "./presenter";

interface WorkOrderCreateCompanyDetailsProps {
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  companies: WOCompany[];
  selectedCompanyId?: string;
  workOrder: WorkOrder;
}

const WorkOrderCreateCompanyDetails: React.FC<
  WorkOrderCreateCompanyDetailsProps
> = ({ onWorkOrderChange, companies, selectedCompanyId, workOrder }) => {
  return (
    <>
      <WorkOrderCreateCompanyDetailsPresenter
        companies={companies}
        onWorkOrderChange={onWorkOrderChange}
        selectedCompanyId={selectedCompanyId}
        workOrder={workOrder}
      />
    </>
  );
};

export default WorkOrderCreateCompanyDetails;
