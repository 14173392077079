import {
  Alert,
  AlertDescription,
  Avatar,
  Box,
  Button,
  Container,
  Flex,
} from "@chakra-ui/react";
import { differenceInDays } from "date-fns";
import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  CompanyFragmentFragment,
  useLocationSimpleQuery,
} from "../../graphql/graphql";
import useCompanySettingsProgress from "../../hooks/useCompanySettingsProgress";
import useFirstFloorPlan from "../../hooks/useFirstFloorPlan";
import OnboardingManagerImage from "../../images/onboarding/onboarding_manager_Chris.jpg";
import { getRoutePath } from "../../router";
import OnboardingPercentage from "./percentage";
import Step from "./step";
import StepHeader from "./stepHeader";

interface OnboardingPresenterProps {
  company: CompanyFragmentFragment;
  showSkip: boolean;
  showComplete: boolean;
  handleCompanyConfigChange: (config: any) => void;
}

const OnboardingPresenter: FC<OnboardingPresenterProps> = ({
  company,
  showSkip,
  showComplete,
  handleCompanyConfigChange,
}) => {
  const [searchParams] = useSearchParams();
  const { data: locationsData, refetch: refetchLocations } =
    useLocationSimpleQuery();
  const floorPlan = useFirstFloorPlan();
  const navigate = useNavigate();
  const remainingDays = 30 - differenceInDays(new Date(), company.createdAt);
  const onboarding = company.config.onboarding || {};
  const companySettingsCompletedPercentage = useCompanySettingsProgress();

  useEffect(() => {
    if (searchParams.get("reload")) refetchLocations();
  }, [searchParams, refetchLocations]);

  const hideOnboarding = () => {
    handleCompanyConfigChange({
      ...company.config,
      onboarding: { ...onboarding, hidden: true },
    });
  };

  const completeOnboarding = () => {
    handleCompanyConfigChange({
      ...company.config,
      onboarding: { ...onboarding, completed: true },
    });
    navigate(getRoutePath("dashboard"));
  };

  const [, setSearchParams] = useSearchParams();

  const locations = locationsData ? locationsData.locations : [];

  return (
    <Container maxWidth="56rem" padding="0">
      {remainingDays >= 0 && false && (
        <Alert
          status={
            remainingDays < 8
              ? "error"
              : remainingDays < 20
              ? "warning"
              : "success"
          }
          role="group"
          marginBottom="6"
        >
          <AlertDescription>
            You have {remainingDays} days left of free onboarding support with
            Chris
          </AlertDescription>
        </Alert>
      )}
      <Flex marginBottom="6">
        <Avatar
          name="Chris"
          size="lg"
          backgroundColor="gray.800"
          color="white"
          marginRight="3"
          src={OnboardingManagerImage}
        />
        <Box>
          <Box marginBottom="1">
            Hi, I’m Chris, your onboarding manager. I'm here to help.
          </Box>
          <Flex>
            <Button
              colorScheme="secondary"
              variant="outline"
              size="xs"
              marginRight="2"
              onClick={() =>
                setSearchParams({ CaAprType: "contactForm", repName: "Chris" })
              }
            >
              Send me an Email
            </Button>
            <Button
              colorScheme="secondary"
              variant="outline"
              size="xs"
              onClick={() => setSearchParams({ CaAprType: "calendly" })}
            >
              Book a Call
            </Button>
          </Flex>
        </Box>
      </Flex>

      <Flex
        marginBottom="3"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box marginBottom={{ base: "4", md: 0 }}>
          Complete the steps below to get up and running quickly.
        </Box>
        <OnboardingPercentage />
      </Flex>

      <Box boxShadow="box" padding="4" marginBottom="8">
        <StepHeader number="1" title="Setup your company" />
        <Box>
          <Step
            completed={onboarding.companyInfo?.completed}
            percentageCompleted={companySettingsCompletedPercentage}
            title="Add your company info"
            subtitle1="Add your company name, address and contact information."
            completedBy={onboarding.companyInfo?.completedBy}
            type="companyInfo"
            youtubeId="ZjWRK1kLhf8"
            locations={locations}
            floorPlan={floorPlan}
          />
          <Step
            completed={onboarding.locationAdded?.completed}
            title="Add locations"
            subtitle1="If you have more than one physical location, add them here."
            completedBy={onboarding.locationAdded?.completedBy}
            type="locationAdded"
            youtubeId="ZjWRK1kLhf8"
            locations={locations}
            floorPlan={floorPlan}
          />
          <Step
            completed={onboarding.teamMemberAdded?.completed}
            title="Invite team members"
            subtitle1="Add colleagues, contractors and other team members and assign their roles."
            completedBy={onboarding.teamMemberAdded?.completedBy}
            type="teamMemberAdded"
            youtubeId="ZjWRK1kLhf8"
            locations={locations}
            floorPlan={floorPlan}
          />
          {company.isMainCompany ? (
            <>
              <Step
                completed={onboarding.planSubscribed?.completed}
                title="Select a subscription plan"
                subtitle1="Select the subscription plan that best meets your needs."
                completedBy={onboarding.planSubscribed?.completedBy}
                type="planSubscribed"
                youtubeId="ZjWRK1kLhf8"
                locations={locations}
                floorPlan={floorPlan}
              />
              <Step
                completed={onboarding.paymentAdded?.completed}
                title="Enter payment info"
                subtitle1="Enter a credit card or your bank account info."
                completedBy={onboarding.paymentAdded?.completedBy}
                type="paymentAdded"
                youtubeId="ZjWRK1kLhf8"
                locations={locations}
                floorPlan={floorPlan}
              />
            </>
          ) : null}
        </Box>
      </Box>

      <Box boxShadow="box" padding="4" marginY="8">
        <StepHeader number="2" title="Upload your plans" />
        <Box>
          <Step
            completed={onboarding.planUploaded?.completed}
            title="Upload your floor plans"
            subtitle1="Add floor plans so that you can add and track assets by location."
            completedBy={onboarding.planUploaded?.completedBy}
            type="planUploaded"
            youtubeId="ZjWRK1kLhf8"
            locations={locations}
            floorPlan={floorPlan}
          />
        </Box>
      </Box>

      <Box boxShadow="box" padding="4" marginY="8">
        <StepHeader number="3" title="Create asset categories and types" />
        <Box>
          <Step
            completed={onboarding.categoryAndTypeAdded?.completed}
            title="Create asset categories and types"
            subtitle1="Select the asset categories and types you want to track or create your own"
            completedBy={onboarding.categoryAndTypeAdded?.completedBy}
            type="categoryAndTypeAdded"
            youtubeId="ZjWRK1kLhf8"
            locations={locations}
            floorPlan={floorPlan}
          />
        </Box>
      </Box>

      <Box boxShadow="box" padding="4" marginTop="8">
        <StepHeader number="4" title="Add your assets" />
        <Box>
          <Step
            completed={onboarding.assetAdded?.completed}
            title="Add assets to your floor plans"
            subtitle1="Add your assets to your plans, add asset information, user manuals and service information"
            completedBy={onboarding.assetAdded?.completedBy}
            type="assetAdded"
            youtubeId="ZjWRK1kLhf8"
            locations={locations}
            floorPlan={floorPlan}
          />
        </Box>
      </Box>

      <Box textAlign="right" marginY="4">
        {showSkip && (
          <Button
            variant="link"
            colorScheme="secondary"
            onClick={hideOnboarding}
          >
            Hide this page
          </Button>
        )}
        {showComplete && !onboarding.completed && (
          <Button
            variant="link"
            colorScheme="secondary"
            onClick={completeOnboarding}
          >
            Hide this page
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default OnboardingPresenter;
