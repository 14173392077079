import { useReactiveVar } from "@apollo/client";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useAssetTypeQuery } from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import { getRoutePath } from "../../../router";
import AssetTypeMaintenancesCreate from "../assetTypesMaintenancesCreate";
import AssetTypeMaintenancesEdit from "../assetTypesMaintenancesEdit";
import Presenter from "./presenter";

interface AssetTypeMaintenanceShowPageProps {}

const AssetTypeMaintenanceShowPage: React.FC<
  AssetTypeMaintenanceShowPageProps
> = () => {
  const {
    isOpen: isCreateModalOpen,
    onClose: onCreateModalClose,
    onOpen: onCreateModalOpen,
  } = useDisclosure();
  const [selectedMaintenanceId, setSelectedMaintenanceId] = useState("");
  const { assetTypeId } = useParams();
  const { data } = useAssetTypeQuery({
    variables: { id: assetTypeId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onDrawerClose = () => {
    if (!data) return;
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetCategories"));
    }, 500);
  };

  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const canAddAssetTypeMaintenance = currentCompanyRole
    ? ROLES.assetTypesMaintenancesCreate.includes(currentCompanyRole.role)
    : false;
  const canEditAssetTypeMaintenance = currentCompanyRole
    ? ROLES.assetTypesMaintenancesEdit.includes(currentCompanyRole.role)
    : false;

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            {data ? (
              <Presenter
                assetType={data.assetType}
                canAddAssetTypeMaintenance={canAddAssetTypeMaintenance}
                canEditAssetTypeMaintenance={canEditAssetTypeMaintenance}
                canDeleteAssetTypeMaintenance={ROLES.assetTypesMaintenancesDelete.includes(
                  currentCompanyRole.role
                )}
                openCreateModal={onCreateModalOpen}
                setSelectedMaintenanceId={setSelectedMaintenanceId}
              />
            ) : (
              <DrawerBody>
                <PageSpinner />
              </DrawerBody>
            )}
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {data &&
        canAddAssetTypeMaintenance &&
        isCreateModalOpen &&
        assetTypeId && (
          <AssetTypeMaintenancesCreate
            onClose={onCreateModalClose}
            assetCategoryId={data.assetType.assetCategoryId}
            assetTypeId={assetTypeId}
          />
        )}

      {data &&
        selectedMaintenanceId &&
        canEditAssetTypeMaintenance &&
        assetTypeId && (
          <AssetTypeMaintenancesEdit
            onClose={() => setSelectedMaintenanceId("")}
            assetCategoryId={data.assetType.assetCategoryId}
            assetTypeId={assetTypeId}
            maintenanceId={selectedMaintenanceId}
          />
        )}
    </>
  );
};

export default AssetTypeMaintenanceShowPage;
