export const getLocationBreadcrumbs = (
  locationId?: string,
  locations: any[] = [],
  breadcrumb: string[] = []
) => {
  if (!locationId || !locations.length) return breadcrumb;

  const location = locations.find((l) => l.id === locationId);
  if (location) {
    breadcrumb.push(location.name);
  }
  if (!!location?.parentId) {
    breadcrumb.concat(
      getLocationBreadcrumbs(location.parentId, locations, breadcrumb)
    );
  }

  return breadcrumb;
};
