import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ASSET_TYPE_MAINTENANCE_CREATED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetTypeDocument,
  useAssetTypeQuery,
  useMaintenanceTemplateCreateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import AssetTypeMaintenancesCreatePresenter, {
  MaintenanceTemplateFormData,
} from "./presenter";

interface AssetTypeMaintenancesCreateProps {
  onClose: () => void;
  assetTypeId: string;
  assetCategoryId: string;
}

const AssetTypeMaintenancesCreate: React.FC<
  AssetTypeMaintenancesCreateProps
> = ({ onClose, assetTypeId, assetCategoryId }) => {
  const navigate = useNavigate();
  const { data } = useAssetTypeQuery({
    variables: { id: assetTypeId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [maintenanceTemplateCreateMutation, { loading }] =
    useMaintenanceTemplateCreateMutation({
      refetchQueries: [
        {
          query: AssetTypeDocument,
          variables: { id: assetTypeId },
        },
      ],
      awaitRefetchQueries: true,
    });
  const toast = useToast();

  const onSubmit = async (
    data: MaintenanceTemplateFormData,
    { setFieldError }: FormikHelpers<MaintenanceTemplateFormData>
  ) => {
    try {
      const { data: serverData, errors } =
        await maintenanceTemplateCreateMutation({
          variables: { data },
        });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onModalClose();
        toast({
          description: ASSET_TYPE_MAINTENANCE_CREATED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onModalClose = () => {
    onClose();
    setTimeout(() => {
      navigate(
        getRoutePath("assetTypesMaintenancesShow", {
          assetCategoryId,
          assetTypeId,
        })
      );
    }, 500);
  };

  return (
    <Modal isOpen onClose={onModalClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader textAlign="left" pr="12">
            Create New Default Service Schedule for {data?.assetType.name}
          </ModalHeader>
          <AssetTypeMaintenancesCreatePresenter
            handleSubmit={onSubmit}
            isLoading={loading}
            assetTypeId={assetTypeId}
          />
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AssetTypeMaintenancesCreate;
