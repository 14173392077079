import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import FileDownload from "../components/elements/fileDownload";
import FloorPlan from "../components/elements/floorPlan";
import { useCurrentCompanyQuery, useSessionQuery } from "../graphql/graphql";
import MaintenanceCreate from "../pages/maintenances/create";
import MaintenanceScheduleSnoozeReminder from "../pages/maintenances/maintenanceScheduleSnoozeReminder";
import MaintenanceShow from "../pages/maintenances/show";
import ContactForm from "../pages/onboarding/contactForm";
import CalendlyModal from "./components/calendly";
import ChangePlanModal from "./components/changePlan";
import CompanyCreatedModal from "./components/companyCreated";
import PlanChangedModal from "./components/planChanged";
import WelcomeModal from "./components/welcome";

const RouterAllPage: React.FC<{}> = () => {
  const { data } = useCurrentCompanyQuery();
  const { data: sessionData } = useSessionQuery();
  const currentCompanyId = data?.currentCompany.id;

  return !!sessionData?.session.isLoggedIn && currentCompanyId ? (
    <Modals currentCompanyId={currentCompanyId} />
  ) : null;
};

export default RouterAllPage;

const Modals: React.FC<{ currentCompanyId: string }> = ({
  currentCompanyId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const CaAprType = searchParams.get("CaAprType");
  const handleModalClose = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return CaAprType ? (
    <>
      {["changePlan", "upgradePlan"].includes(CaAprType) && (
        <ChangePlanModal currentCompanyId={currentCompanyId} />
      )}
      {CaAprType === "planChanged" && <PlanChangedModal />}
      {CaAprType === "companyCreated" && <CompanyCreatedModal />}
      {CaAprType === "welcome" && <WelcomeModal />}
      {CaAprType === "calendly" && <CalendlyModal />}
      {CaAprType === "contactForm" && (
        <ContactForm
          isOpen
          onClose={handleModalClose}
          repName={searchParams.get("repName") || undefined}
        />
      )}
      {CaAprType === "planEditor" && <FloorPlan goBack />}
      {CaAprType === "serviceScheduleShow" && <MaintenanceShow />}
      {CaAprType === "serviceScheduleCreate" && <MaintenanceCreate />}
      {CaAprType === "serviceScheduleAlarm" && (
        <MaintenanceScheduleSnoozeReminder />
      )}
      {CaAprType === "fileDownload" && <FileDownload />}
    </>
  ) : null;
};
