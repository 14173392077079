import { capitalize } from "lodash";

export enum WOCreateStepsEnum {
  COMPANY = 1,
  LOCATION = 2,
  SERVICES = 3,
  VERIFY = 4,
}

export enum BusinessSeverityEnum {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  MODERATE = "MODERATE",
  LOW = "LOW",
}

export enum OrderPriorityEnum {
  HIGHEST = "HIGHEST",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  LOWEST = "LOWEST",
}

export enum ScheduleTypeEnum {
  OPEN_DUE = "OPEN_DUE",
  STRICT_DUE = "STRICT_DUE",
  STRICT_TIME = "STRICT_TIME",
}

export const scheduleTypeLabels = {
  [ScheduleTypeEnum.STRICT_DUE]: "Strict Due Date",
  [ScheduleTypeEnum.OPEN_DUE]: "Open Due Date",
  [ScheduleTypeEnum.STRICT_TIME]: "Strict Time Frame",
};

export const businessSeverityLabels = {
  [BusinessSeverityEnum.LOW]: "Low",
  [BusinessSeverityEnum.MODERATE]: "Moderate",
  [BusinessSeverityEnum.HIGH]: "High",
  [BusinessSeverityEnum.CRITICAL]: "Critical",
};

export const orderPriorityLabels = {
  [OrderPriorityEnum.LOWEST]: "Lowest",
  [OrderPriorityEnum.LOW]: "Low",
  [OrderPriorityEnum.HIGH]: "High",
  [OrderPriorityEnum.HIGHEST]: "Highest",
  [OrderPriorityEnum.MEDIUM]: "Medium",
};

export enum AssetPlansTypeEnum {
  ASSET_PLAN = "ASSET_PLAN",
  AFFECTED_AREA = "AFFECTED_AREA",
}

export const orderPriorityOption: {
  value: keyof typeof OrderPriorityEnum;
  inputDisplay: string;
}[] = Object.entries(OrderPriorityEnum).map(([key, label]) => ({
  value: key as keyof typeof OrderPriorityEnum,
  inputDisplay: capitalize(label),
}));

export const businessSeverityOptions: {
  value: keyof typeof BusinessSeverityEnum;
  inputDisplay: string;
}[] = Object.entries(BusinessSeverityEnum).map(([key, label]) => {
  return {
    value: key as keyof typeof BusinessSeverityEnum,
    inputDisplay: capitalize(label),
  };
});
