import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  useCompanyQuery,
  useStripeInvoiceUpcomingQuery,
} from "../../../graphql/graphql";
import { currentCompanyPlanVar } from "../../../graphql/reactiveVariables";
import usePaymentsAvailable from "../../../hooks/usePaymentsAvailable";
import Presenter from "./presenter";

interface CompanyPlanPageProps {}

const CompanyPlanPage: React.FC<CompanyPlanPageProps> = () => {
  const { data: currentCompanyData } = useCompanyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const currentCompanyPlan = useReactiveVar(currentCompanyPlanVar);
  const paymentsAvailable = usePaymentsAvailable();
  const { data: invoiceUpcoming } = useStripeInvoiceUpcomingQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return (
    <>
      {currentCompanyData && currentCompanyPlan ? (
        <Presenter
          currentCompany={currentCompanyData.company}
          currentCompanyPlan={currentCompanyPlan}
          paymentsAvailable={paymentsAvailable}
          invoiceUpcoming={invoiceUpcoming}
        />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default CompanyPlanPage;

gql`
  query StripeInvoiceUpcoming {
    stripeInvoiceUpcoming {
      ...StripeInvoiceFragment
    }
  }
`;
