import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";

import FormGroup from "../../../../../components/elements/formGroup";
import PhoneInput from "../../../../../components/elements/phoneInput";
import {
  UserFragmentFragment,
  useUserPhoneConfirmMutation,
} from "../../../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../../../utils/serverErrors";
import { phoneSchema, yupObject } from "../../../../../utils/validation";

interface ChangePhonePageProps {
  onSubmit: (phoneChanged: boolean) => void;
  user: UserFragmentFragment;
}

const ChangePhonePage: React.FC<ChangePhonePageProps> = ({
  user,
  onSubmit: handleSubmit,
}) => {
  const toast = useToast();
  const [userPhoneConfirmMutation, { loading }] = useUserPhoneConfirmMutation();

  const onSubmit = async (
    data: ChangePhoneFormData,
    { setFieldError }: FormikHelpers<ChangePhoneFormData>
  ) => {
    if (data.phone === user.phone && user.phoneConfirmed) {
      handleSubmit(false);
      return;
    }

    try {
      const { data: serverData, errors } = await userPhoneConfirmMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        handleSubmit(true);
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Box marginTop="10">
      <Formik
        initialValues={{
          phone: user.phone || "",
        }}
        validationSchema={ChangePhoneValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values }) => {
          const phoneChangedOrNotVerified =
            !user.phoneConfirmed || values.phone !== user.phone;
          return (
            <Form noValidate>
              <FormGroup label="Phone" name="phone">
                <PhoneInput autoFocus autoComplete="tel" name="phone" />
              </FormGroup>
              {phoneChangedOrNotVerified && (
                <>
                  <Text textAlign="center" marginTop="4" fontSize="md">
                    Verify your phone number
                  </Text>
                  <Box textAlign="center" fontSize="xs" marginTop="4">
                    We'll send you a one-time PIN to verify this phone number
                  </Box>
                </>
              )}
              <Box marginY="4">
                <Button width="full" type="submit" isLoading={loading}>
                  {phoneChangedOrNotVerified
                    ? "Send Verification Code"
                    : "Save"}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ChangePhonePage;

const ChangePhoneValidationSchema = yupObject().shape({
  phone: phoneSchema,
});

type ChangePhoneFormData = {
  phone: string;
};

gql`
  mutation userPhoneConfirm($data: UserPhoneConfirmInput) {
    userPhoneConfirm(data: $data) {
      ...UserFragment
    }
  }
`;
