import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const LogoSquare = (props: IconProps & any) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 240 240"
    style={{
      enableBackground: "new 0 0 240 240",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M68.51 70.47c0-1.24.24-2.44.65-3.57l43.72 25.24c2.2 1.27 4.66 1.91 7.13 1.91s4.93-.64 7.13-1.91l43.71-25.24c.41 1.13.65 2.33.65 3.57v12.71l-46.21 26.68c-3.25 1.87-7.29 1.88-10.54 0L68.51 83.18V70.47zm5.27-9.14 40.95-23.64c3.25-1.88 7.29-1.88 10.54 0l40.94 23.64a10.4 10.4 0 0 1 2.76 2.34l-43.71 25.24a10.554 10.554 0 0 1-10.54 0l-43.7-25.23c.77-.92 1.69-1.73 2.76-2.35zm75.96 75.07-24.46 14.13a10.554 10.554 0 0 1-10.54 0l-40.95-23.65a10.582 10.582 0 0 1-5.27-9.13v-9.34l44.36 25.6c4.4 2.55 9.87 2.55 14.27 0l22.6-13.04v15.43zm.93-20.26s-.01 0 0 0l-25.39 14.65a10.554 10.554 0 0 1-10.54 0l-46.23-26.68V87.47l44.37 25.61c2.2 1.27 4.66 1.9 7.13 1.9 2.47 0 4.93-.63 7.13-1.9l44.35-25.61v16.64l-20.82 12.03z"
      style={{ fill: "#fff" }}
    />
    <path d="M120 94.05c-2.47 0-4.93-.63-7.13-1.91L67.38 65.87a1.86 1.86 0 0 1-.68-2.54c.51-.89 1.65-1.2 2.54-.68l45.49 26.27c3.25 1.88 7.29 1.88 10.54 0l45.48-26.27a1.86 1.86 0 0 1 2.54.68c.51.89.21 2.03-.68 2.54l-45.48 26.27a14.222 14.222 0 0 1-7.13 1.91z" />
    <path d="M120.01 114.98c-2.47 0-4.93-.63-7.13-1.9L65.72 85.86a1.86 1.86 0 0 1-.68-2.54c.51-.89 1.65-1.2 2.54-.68l47.16 27.22c3.25 1.87 7.29 1.87 10.54 0l47.14-27.22a1.86 1.86 0 0 1 2.54.68c.51.89.21 2.03-.68 2.54l-47.14 27.22c-2.2 1.27-4.67 1.9-7.13 1.9z" />
    <path d="M151.6 119.61a1.86 1.86 0 0 1-.93-3.47l20.82-12.03V70.46c0-3.75-2.02-7.25-5.27-9.13L125.28 37.7a10.554 10.554 0 0 0-10.54 0L73.78 61.33a10.582 10.582 0 0 0-5.27 9.13v34.72c0 1.03-.83 1.86-1.86 1.86s-1.86-.83-1.86-1.86V70.47c0-5.08 2.73-9.81 7.13-12.35l40.95-23.64c4.4-2.54 9.86-2.54 14.26 0l40.94 23.64c4.4 2.54 7.13 7.27 7.13 12.35v34.72c0 .66-.35 1.28-.93 1.61l-21.75 12.56c-.29.17-.61.25-.92.25z" />
    <path d="M120.01 155.65c-2.47 0-4.93-.64-7.13-1.91L71.92 130.1c-4.4-2.54-7.13-7.27-7.13-12.35v-12.56c0-.66.35-1.28.93-1.61.58-.33 1.28-.33 1.86 0l47.16 27.21c3.25 1.88 7.29 1.88 10.54 0l25.39-14.65c.58-.33 1.29-.33 1.86 0 .58.33.93.95.93 1.61v19.72c0 .66-.35 1.28-.93 1.61l-25.39 14.66c-2.2 1.28-4.67 1.91-7.13 1.91zm-51.5-47.24v9.34c0 3.75 2.02 7.25 5.27 9.13l40.95 23.65c3.25 1.88 7.29 1.88 10.54 0l24.46-14.13v-15.43l-22.6 13.04c-4.4 2.55-9.86 2.55-14.27 0l-44.35-25.6zM29.06 195.58l2.13 4.58c-1.61 1.57-4.38 2.37-6.91 2.37-6.99 0-11.09-4.74-11.09-10.61 0-5.87 4.18-10.61 11.09-10.61 2.53 0 5.06.72 6.91 2.37l-2.13 4.62c-1.13-1.12-2.81-1.85-4.62-1.85-3.78 0-6.11 2.45-6.11 5.47 0 3.01 2.29 5.47 6.11 5.47 1.81-.01 3.5-.69 4.62-1.81zM47.23 181.31v6.07c-1.29-.32-2.69-.32-3.86 0-2.17.6-4.5 2.61-4.5 6.15v8.36h-5.14v-19.93h5.14v4.22c1.32-3.1 4.74-4.63 8.36-4.87zM48.79 175.85c0-1.97 1.57-3.54 3.54-3.54s3.54 1.57 3.54 3.54-1.57 3.54-3.54 3.54-3.54-1.58-3.54-3.54zm.97 6.1h5.14v19.93h-5.14v-19.93zM60.33 186.45h-2.57v-4.5h2.57v-7.07h5.14v7.07h3.86v4.5h-3.86v8.36c0 3.05 1.33 3.17 3.86 2.57v4.5c-1 .4-2.09.64-3.78.64-3.66 0-5.22-2.61-5.22-6.31v-9.76zM71.86 175.85c0-1.97 1.57-3.54 3.54-3.54s3.54 1.57 3.54 3.54-1.57 3.54-3.54 3.54a3.534 3.534 0 0 1-3.54-3.54zm.96 6.1h5.14v19.93h-5.14v-19.93zM96.05 195.58l2.13 4.58c-1.61 1.57-4.38 2.37-6.91 2.37-6.99 0-11.09-4.74-11.09-10.61 0-5.87 4.18-10.61 11.09-10.61 2.53 0 5.06.72 6.91 2.37l-2.13 4.62c-1.13-1.12-2.81-1.85-4.62-1.85-3.78 0-6.11 2.45-6.11 5.47 0 3.01 2.29 5.47 6.11 5.47 1.81-.01 3.5-.69 4.62-1.81zM120.32 181.95v19.93h-5.14v-2.13c-1.61 1.85-3.7 2.77-6.11 2.77-5.5 0-9.97-4.74-9.97-10.61 0-5.87 4.46-10.61 9.97-10.61 2.41 0 4.5.92 6.11 2.77v-2.13h5.14zm-5.14 9.97c0-3.01-2.45-5.47-5.47-5.47-3.01 0-5.47 2.45-5.47 5.47 0 3.01 2.45 5.47 5.47 5.47 3.02-.01 5.47-2.46 5.47-5.47zM129.28 173.6v28.29h-5.14V173.6h5.14z" />
    <path
      style={{ fill: "#b0b1b1" }}
      d="M153.03 181.95v19.93h-5.14v-2.13c-1.61 1.85-3.7 2.77-6.11 2.77-5.5 0-9.97-4.74-9.97-10.61 0-5.87 4.46-10.61 9.97-10.61 2.41 0 4.5.92 6.11 2.77v-2.13h5.14zm-5.14 9.97c0-3.01-2.45-5.47-5.47-5.47s-5.47 2.45-5.47 5.47c0 3.01 2.45 5.47 5.47 5.47s5.47-2.46 5.47-5.47zM173.56 195.46c0 4.06-3.21 7.07-8.76 7.07-4.74 0-8.24-2.01-9.24-2.89l1.69-4.82c1.73 1.61 4.66 2.77 7.07 2.81 2.37.04 4.1-.48 4.1-1.73 0-.88-.88-1.21-3.54-1.65-7.43-1.21-8.68-3.58-8.68-6.83 0-3.21 3.5-6.11 8.72-6.11 1.97 0 4.86.44 7.76 1.93l-1.45 4.66c-1-.72-3.66-1.65-6.31-1.69-2.09-.04-3.58.36-3.58 1.21 0 1.21 2.09 1.41 4.22 1.77 6.76 1.08 8 3.73 8 6.27zM193.13 195.46c0 4.06-3.22 7.07-8.76 7.07-4.74 0-8.24-2.01-9.24-2.89l1.69-4.82c1.73 1.61 4.66 2.77 7.07 2.81 2.37.04 4.1-.48 4.1-1.73 0-.88-.88-1.21-3.54-1.65-7.43-1.21-8.68-3.58-8.68-6.83 0-3.21 3.5-6.11 8.72-6.11 1.97 0 4.86.44 7.76 1.93l-1.45 4.66c-1-.72-3.66-1.65-6.31-1.69-2.09-.04-3.58.36-3.58 1.21 0 1.21 2.09 1.41 4.22 1.77 6.76 1.08 8 3.73 8 6.27zM214.23 193.53h-14.51c.72 2.61 2.69 4.5 5.91 4.5 2.09 0 4.3-.68 5.95-1.85l1.49 4.46s-2.73 1.89-7.51 1.89c-7.76 0-11.17-4.82-11.17-10.61 0-5.87 3.34-10.61 10.29-10.61 6.23 0 9.64 3.94 9.64 10.61-.01.44-.05 1.08-.09 1.61zm-5.38-3.86c0-1.65-1.41-3.86-4.5-3.86-2.57 0-4.5 2.21-4.5 3.86h9zM217.81 186.45h-2.57v-4.5h2.57v-7.07h5.14v7.07h3.86v4.5h-3.86v8.36c0 3.05 1.33 3.17 3.86 2.57v4.5c-1 .4-2.09.64-3.78.64-3.66 0-5.22-2.61-5.22-6.31v-9.76z"
    />
  </Icon>
);

export default LogoSquare;
