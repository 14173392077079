import { Icon, IconProps } from "@chakra-ui/react";

const Call = (props: IconProps & any) => (
  <Icon viewBox="0 0 48 48" boxSize={{ base: "6", md: "8" }} {...props}>
    <path
      d="M38.063 2.906h-11.25c-5.428 0-9.938 4.417-9.938 9.844 0 4.95 3.767 9.06 8.531 9.744v4.319a1.405 1.405 0 0 0 2.4.994l4.631-4.631.583-.582h5.042c5.428 0 9.938-4.417 9.938-9.844s-4.51-9.844-9.938-9.844Z"
      fill="#646464"
    />
    <path
      d="M38.063 22.594c5.427 0 9.937-4.417 9.937-9.844s-4.51-9.844-9.938-9.844h-5.624v20.27l.582-.582h5.042Z"
      fill="#646464"
    />
    <path
      d="M35.813 35.25v5.625c0 2.447-1.998 4.219-4.2 4.219-8.1 0-16.004-3.83-21.882-9.685C3.853 29.555 0 21.675 0 13.574c0-2.327 1.893-4.199 4.219-4.199h5.625c.605 0 1.143.387 1.333.96l2.812 8.419c.138.412.077.864-.163 1.225l-2.385 3.579c.904 1.895 2.503 3.967 4.35 5.791 1.848 1.824 3.943 3.4 5.839 4.304l3.578-2.386c.363-.239.813-.301 1.226-.163l8.437 2.813c.574.19.941.727.941 1.333Z"
      fill="#404040"
    />
    <path
      d="M35.813 35.25v5.625c0 2.447-1.998 4.219-4.2 4.219-8.1 0-16.003-3.83-21.882-9.684l6.06-6.06c1.848 1.823 3.944 3.399 5.84 4.303l3.578-2.385a1.408 1.408 0 0 1 1.225-.163l8.437 2.812c.574.19.942.728.942 1.333Z"
      fill="#404040"
    />
    <path
      d="M26.813 14.156a1.406 1.406 0 1 0 0-2.812 1.406 1.406 0 0 0 0 2.812ZM32.438 14.156a1.406 1.406 0 1 0 0-2.812 1.406 1.406 0 0 0 0 2.812Z"
      fill="#C8C8C8"
    />
    <path
      d="M33.844 12.75c0-.777-.63-1.406-1.407-1.406v2.812c.777 0 1.407-.63 1.407-1.406ZM38.063 14.156a1.406 1.406 0 1 0 0-2.812 1.406 1.406 0 0 0 0 2.812Z"
      fill="#C8C8C8"
    />
  </Icon>
);

export default Call;
