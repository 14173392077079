import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import { useSessionQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface EmailVerifyPageProps {}

const EmailVerifyPage: React.FC<EmailVerifyPageProps> = () => {
  const { data } = useSessionQuery();

  useEffect(() => {
    layoutOptionsVar({});
  }, []);

  return (
    <>
      <Helmet>
        <title>Verify Your Email</title>
      </Helmet>
      {data ? (
        <Presenter loggedIn={data.session.isLoggedIn} />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default EmailVerifyPage;
