import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useStripeInvoicesQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface CompanyBillingHistoryPageProps {}

const CompanyBillingHistoryPage: React.FC<
  CompanyBillingHistoryPageProps
> = () => {
  const { data: invoices } = useStripeInvoicesQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return (
    <>
      <Helmet>
        <title>Payment History</title>
      </Helmet>
      {invoices ? (
        <Presenter invoices={invoices.stripeInvoices} />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default CompanyBillingHistoryPage;

gql`
  query StripeInvoices {
    stripeInvoices {
      ...StripeInvoiceFragment
    }
  }
`;
