import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Settings = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 24 24" boxSize="24px" {...props}>
    <path d="M16.758 17.25c-.39.3-.78.525-1.248.75-.312.15-.624.3-1.014.45-.078.075-.156.075-.156.225v1.575c0 .3-.156.45-.468.525-1.326.3-2.652.3-3.978 0-.234-.075-.39-.225-.39-.45v-1.65c0-.075 0-.15-.156-.15-.78-.3-1.404-.675-2.028-1.125-.078-.075-.156-.075-.234 0-.468.3-.936.525-1.405.75-.312.15-.468.15-.702-.15-.936-.975-1.56-2.025-1.95-3.3-.078-.3 0-.45.234-.6.468-.3.936-.525 1.404-.825.078 0 .078-.075.078-.15a5.833 5.833 0 010-2.325c0-.075 0-.15-.078-.225-.468-.225-.936-.525-1.404-.75-.234-.225-.312-.375-.156-.675.39-1.2 1.092-2.325 1.95-3.3.234-.225.39-.225.702-.075.469.3.937.525 1.405.825.078.075.156.075.234 0a5.684 5.684 0 012.106-1.125c.078 0 .156-.075.156-.15V3.75c0-.3.156-.45.468-.525 1.326-.3 2.652-.3 3.978 0 .312.075.39.225.39.45v1.65c0 .075 0 .15.078.15.78.3 1.482.675 2.106 1.2.078.075.078.075.156 0 .468-.3 1.015-.525 1.483-.825.233-.15.468-.075.624.075.936.975 1.56 2.025 2.028 3.3.078.3 0 .45-.234.6-.468.3-.936.525-1.404.825-.078.075-.078.075-.078.15.156.75.156 1.575 0 2.325 0 .075 0 .15.078.15.468.3.936.525 1.404.825.234.15.312.375.234.6-.39 1.2-1.092 2.325-1.95 3.3-.234.225-.39.225-.703.075-.546-.3-1.092-.525-1.56-.825zm-1.716-5.325c0-1.575-1.326-2.85-2.964-2.925-1.716 0-3.042 1.275-3.042 2.925 0 1.575 1.326 2.925 2.964 2.925s2.964-1.275 3.042-2.925z" />
  </Icon>
);

export default Settings;
