import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import PasswordInput from "../../../components/elements/passwordInput";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import { useUserUpdateMutation } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  currentPasswordSchema,
  newPasswordConfirmSchema,
  newPasswordSchema,
  yupObject,
} from "../../../utils/validation";

interface ChangePasswordPagePresenterProps {}

const ChangePasswordPagePresenter: React.FC<
  ChangePasswordPagePresenterProps
> = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [userUpdateMutation, { loading }] = useUserUpdateMutation();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (
    { currentPasswordRaw, passwordRaw }: ChangePasswordFormData,
    { setFieldError }: FormikHelpers<ChangePasswordFormData>
  ) => {
    try {
      const { data: serverData, errors } = await userUpdateMutation({
        variables: { data: { currentPasswordRaw, passwordRaw } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        navigate(getRoutePath("mySettings"));
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("mySettings"));
    }, 500);
  };
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>Edit Your Password</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Your Password</DrawerHeader>
          <DrawerBody>
            <Box marginTop="10">
              <Formik
                initialValues={{
                  currentPasswordRaw: "",
                  passwordRaw: "",
                  passwordRawConfirm: "",
                }}
                validationSchema={ChangePasswordValidationSchema}
                onSubmit={onSubmit}
              >
                {() => (
                  <Form noValidate>
                    <FormGroup
                      label="Current password"
                      name="currentPasswordRaw"
                    >
                      <PasswordInput
                        autoComplete="current-password"
                        name="currentPasswordRaw"
                        autoFocus
                      />
                    </FormGroup>
                    <FormGroup
                      label="New password"
                      name="passwordRaw"
                      renderError={false}
                    >
                      <PasswordInput
                        showPasswordHint
                        autoComplete="new-password"
                        name="passwordRaw"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Confirm new password"
                      name="passwordRawConfirm"
                    >
                      <PasswordInput
                        autoComplete="new-password"
                        name="passwordRawConfirm"
                      />
                    </FormGroup>
                    <Box marginY="10">
                      <Button width="full" type="submit" isLoading={loading}>
                        Change Password
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ChangePasswordPagePresenter;

const ChangePasswordValidationSchema = yupObject().shape({
  currentPasswordRaw: currentPasswordSchema,
  passwordRaw: newPasswordSchema,
  passwordRawConfirm: newPasswordConfirmSchema,
});

type ChangePasswordFormData = {
  currentPasswordRaw: string;
  passwordRaw: string;
  passwordRawConfirm: string;
};
