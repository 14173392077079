import { Icon, IconProps } from "@chakra-ui/react";

const WirelessRouter = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M104.358 69.642h-7.317l15.5-36.167a2.868 2.868 0 1 0-5.274-2.26L90.798 69.642H37.202l-16.47-38.427a2.869 2.869 0 1 0-5.273 2.26l15.5 36.167h-7.317c-4.745 0-8.607 3.862-8.607 8.608v17.214c0 4.746 3.861 8.607 8.607 8.607h3.06v3.06a2.869 2.869 0 1 0 5.739 0v-3.06h63.118v3.06a2.869 2.869 0 1 0 5.738 0v-3.06h3.061c4.746 0 8.607-3.861 8.607-8.607V78.25c0-4.746-3.861-8.608-8.607-8.608Zm2.869 25.822a2.872 2.872 0 0 1-2.869 2.869H23.642a2.872 2.872 0 0 1-2.869-2.87V78.25a2.872 2.872 0 0 1 2.87-2.87h80.715a2.872 2.872 0 0 1 2.869 2.87v17.214Z"
      fill="currentColor"
    />
    <path
      d="M57.925 54.953a2.869 2.869 0 1 0 4.058 4.057A2.85 2.85 0 0 1 64 58.166a2.85 2.85 0 0 1 2.017.844 2.869 2.869 0 1 0 4.058-4.057c-.943-.943-2.986-2.525-6.075-2.525s-5.132 1.582-6.075 2.525ZM37.625 34.676C44.673 27.622 54.04 23.738 64 23.738s19.327 3.884 26.375 10.938a2.869 2.869 0 1 0 4.059-4.056C86.302 22.482 75.494 18 64 18s-22.302 4.482-30.434 12.62a2.869 2.869 0 1 0 4.059 4.056Z"
      fill="currentColor"
    />
    <path
      d="M86.313 42.788a2.87 2.87 0 0 0 .002-4.057C80.353 32.763 72.428 29.476 64 29.476s-16.353 3.287-22.315 9.255a2.869 2.869 0 0 0 4.06 4.055c4.878-4.883 11.36-7.572 18.255-7.572 6.894 0 13.377 2.69 18.255 7.572a2.87 2.87 0 0 0 4.058.002Z"
      fill="currentColor"
    />
    <path
      d="M78.196 50.897a2.87 2.87 0 0 0 0-4.057c-3.797-3.797-8.839-5.888-14.196-5.888-5.357 0-10.399 2.091-14.195 5.888a2.869 2.869 0 1 0 4.057 4.057A14.247 14.247 0 0 1 64 46.69c3.825 0 7.425 1.494 10.138 4.207a2.87 2.87 0 0 0 4.058 0ZM75.476 81.118a2.87 2.87 0 0 0-2.869 2.87v5.738a2.869 2.869 0 0 0 5.738 0v-5.738a2.87 2.87 0 0 0-2.869-2.87ZM86.952 81.118a2.87 2.87 0 0 0-2.869 2.87v5.738a2.869 2.869 0 0 0 5.738 0v-5.738a2.87 2.87 0 0 0-2.869-2.87ZM98.428 81.118a2.87 2.87 0 0 0-2.869 2.87v5.738a2.869 2.869 0 0 0 5.738 0v-5.738a2.869 2.869 0 0 0-2.869-2.87Z"
      fill="currentColor"
    />
  </Icon>
);

export default WirelessRouter;
