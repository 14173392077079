import { Icon, IconProps } from "@chakra-ui/react";

const PDF = (props: IconProps & any) => {
  return (
    <Icon width="12" height="auto" viewBox="0 0 48 48" {...props}>
      <path
        d="M40 23.333h-2.667v-2.666L40 23.333zM8 23.333h2.667v-2.666L8 23.333z"
        fill="#8D1008"
      />
      <path
        d="M38 40a2 2 0 01-2 2H12a2 2 0 01-2-2V8a2 2 0 012-2h17.172a2 2 0 011.414.586l6.828 6.828A2 2 0 0138 14.828V40z"
        fill="#F1F1F1"
      />
      <path d="M38 14h-7a1 1 0 01-1-1V6l8 8z" fill="#CCC" />
      <path
        d="M8 23.333c0-.368.298-.666.667-.666h30.666c.369 0 .667.298.667.666v11.334a.667.667 0 01-.667.666H8.667A.667.667 0 018 34.667V23.333z"
        fill="#B30B00"
      />
      <path stroke="#CCC" d="M15 17.5h18M15 19.5h18M15 21.5h18" />
      <path
        d="M18.546 26.993h-1.374v1.88h1.337c.281 0 .498-.092.65-.278a.996.996 0 00.239-.653c0-.244-.074-.462-.22-.653-.147-.198-.357-.296-.632-.296zM16 32v-6h2.564c.66 0 1.157.192 1.493.575.342.377.513.833.513 1.367s-.171.99-.513 1.367c-.336.372-.833.557-1.493.557h-1.392V32H16zM22.78 27.019v3.962h1.29c.538 0 .95-.186 1.237-.557.293-.372.44-.842.44-1.41 0-.576-.147-1.052-.44-1.43-.287-.377-.7-.565-1.236-.565h-1.292zM24.07 32h-2.463v-6h2.464c.879 0 1.572.285 2.078.853.513.563.77 1.283.77 2.16 0 .87-.257 1.588-.77 2.151-.506.557-1.2.836-2.078.836zM28.145 32v-6H32v.993h-2.683v1.559h2.124v.992h-2.124V32h-1.172z"
        fill="#fff"
      />
    </Icon>
  );
};

export default PDF;
