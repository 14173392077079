import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React, { Dispatch, SetStateAction, useCallback } from "react";

import DateRangeFilter, {
  DateRange,
} from "../../../components/elements/dateRangePicker";
import {
  MaintenanceStatusType,
  ReportAssetFilterInput,
  ReportMaintenanceFilterInput,
  ReportPlanFilterInput,
  ReportUserFilterInput,
} from "../../../graphql/graphql";
import CategoriesFilter from "./categories";
import CompletedByFilter from "./completedBy";
import LocationsFilter from "./locations";
import MaintenanceCompletedFilter from "./maintenanceCompleted";
import StatusFilter from "./maintenanceStatus";
import UsersFilter from "./users";

export type ReportsFilterByUnion = ReportUserFilterInput &
  ReportAssetFilterInput &
  ReportPlanFilterInput &
  ReportMaintenanceFilterInput;

interface ReportFiltersProps {
  enabledFilters: Array<
    | "locations"
    | "status"
    | "categories"
    | "contacts"
    | "completed"
    | "completedBy"
    | "date"
  >;
  filterBy: ReportsFilterByUnion;
  setFilterBy: Dispatch<SetStateAction<ReportsFilterByUnion>>;
}

const ReportFilters: React.FC<ReportFiltersProps> = ({
  filterBy,
  setFilterBy,
  enabledFilters,
}) => {
  const handleLocationChange = useCallback(
    (locationIds: string[] | undefined) => {
      setFilterBy((filters) => ({ ...filters, locationIds }));
    },
    [setFilterBy]
  );

  const handleStatusChange = useCallback(
    (status: MaintenanceStatusType[] | undefined) => {
      setFilterBy((filters) => ({ ...filters, status }));
    },
    [setFilterBy]
  );

  const handleCategoryChange = useCallback(
    (categoryIds: string[] | undefined) => {
      setFilterBy((filters) => ({ ...filters, categoryIds }));
    },
    [setFilterBy]
  );

  const handleUserChange = useCallback(
    (userIds: string[] | undefined) => {
      setFilterBy((filters) => ({ ...filters, userIds }));
    },
    [setFilterBy]
  );

  const handleCompletedByChange = useCallback(
    (completedByUserIds: string[] | undefined) => {
      setFilterBy((filters) => ({ ...filters, completedByUserIds }));
    },
    [setFilterBy]
  );

  const handleMaintainedCompletedChange = useCallback(
    (completed: boolean | undefined) => {
      setFilterBy((filters) => ({ ...filters, completed }));
    },
    [setFilterBy]
  );

  const handleDateRangeChange = useCallback(
    (range: DateRange) => {
      setFilterBy((filters) => {
        const newFilters: ReportsFilterByUnion = { ...filters };
        if (range[0] && range[0].getTime() !== newFilters.startDate) {
          newFilters.startDate = range[0].getTime();
        }
        if (range[1] && range[1].getTime() !== newFilters.endDate) {
          newFilters.endDate = range[1].getTime();
        }
        return newFilters;
      });
    },
    [setFilterBy]
  );

  const resetFilters = useCallback(() => {
    setFilterBy((filters) => ({
      startDate: filters.startDate,
      endDate: filters.endDate,
    }));
  }, [setFilterBy]);

  const boxProps = {
    w: { base: "48%", lg: "100%" },
    minW: { base: "0", lg: "40" },
    flexGrow: 1,
  };

  const isFiltered = Object.keys(filterBy)
    .map((key) =>
      ["startDate", "endDate"].includes(key)
        ? undefined
        : (filterBy as any)[key]
    )
    .filter((value) => value).length;

  return (
    <Flex
      direction="row"
      alignItems="space-between"
      justifyContent="flex-start"
      wrap={{ base: "wrap", lg: "nowrap" }}
      gap="2"
    >
      {enabledFilters.includes("locations") && (
        <Box {...boxProps}>
          <LocationsFilter
            onChange={handleLocationChange}
            value={filterBy.locationIds}
          />
        </Box>
      )}
      {enabledFilters.includes("categories") && (
        <Box {...boxProps}>
          <CategoriesFilter
            onChange={handleCategoryChange}
            value={filterBy.categoryIds}
          />
        </Box>
      )}
      {enabledFilters.includes("status") && (
        <Box {...boxProps}>
          <StatusFilter onChange={handleStatusChange} value={filterBy.status} />
        </Box>
      )}
      {enabledFilters.includes("contacts") && (
        <Box {...boxProps}>
          <UsersFilter onChange={handleUserChange} value={filterBy.userIds} />
        </Box>
      )}
      {enabledFilters.includes("completed") && (
        <Box {...boxProps}>
          <MaintenanceCompletedFilter
            onChange={handleMaintainedCompletedChange}
            value={filterBy.completed}
          />
        </Box>
      )}
      {enabledFilters.includes("completedBy") && (
        <Box {...boxProps}>
          <CompletedByFilter
            onChange={handleCompletedByChange}
            value={filterBy.completedByUserIds}
          />
        </Box>
      )}
      {enabledFilters.includes("date") && (
        <Box {...boxProps} minW={{ base: "0", lg: "48" }}>
          <DateRangeFilter
            startDateTime={
              filterBy.startDate ? new Date(filterBy.startDate) : null
            }
            endDateTime={filterBy.endDate ? new Date(filterBy.endDate) : null}
            onChange={handleDateRangeChange}
          />
        </Box>
      )}
      {isFiltered > 0 && (
        <Tooltip label="Clear all filters" hasArrow placement="bottom">
          <IconButton
            variant="icon"
            colorScheme="gray"
            aria-label="Clear all filters"
            onClick={resetFilters}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </IconButton>
        </Tooltip>
      )}
    </Flex>
  );
};

export default ReportFilters;

gql`
  query CompletedByUsers {
    completedByUsers {
      id
      fullName
      email
    }
  }
`;
