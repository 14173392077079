import { Icon, IconProps } from "@chakra-ui/react";

const Fence = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M115.462 57.538c1.187 0 2.144-.96 2.144-2.144v-8.577a2.143 2.143 0 0 0-2.144-2.144h-6.433v-6.819c0-2.903-.858-5.78-2.505-8.354l-6.432-9.606c-.798-1.192-2.77-1.192-3.564 0L90.07 29.54c-1.626 2.535-2.483 5.412-2.483 8.315v6.819H74.72v-6.819c0-2.903-.858-5.78-2.504-8.354l-6.433-9.606c-.797-1.192-2.77-1.192-3.564 0l-6.458 9.645c-1.626 2.535-2.483 5.412-2.483 8.316v6.818H40.413v-6.819c0-2.903-.857-5.78-2.504-8.353l-6.433-9.607c-.797-1.192-2.77-1.192-3.563 0l-6.459 9.645c-1.625 2.535-2.483 5.412-2.483 8.316v6.818H12.54c-1.184 0-2.145.961-2.145 2.145v8.577c0 1.183.96 2.144 2.145 2.144h6.432v25.73H12.54c-1.184 0-2.145.961-2.145 2.145v8.577c0 1.183.96 2.144 2.145 2.144h6.432v10.721c0 1.184.96 2.144 2.144 2.144H38.27c1.188 0 2.145-.96 2.145-2.144V96.135h12.865v10.721c0 1.184.96 2.144 2.144 2.144h17.154c1.188 0 2.144-.96 2.144-2.144V96.135h12.865v10.721c0 1.184.961 2.144 2.145 2.144h17.154c1.188 0 2.144-.96 2.144-2.144V96.135h6.433c1.187 0 2.144-.96 2.144-2.144v-8.577a2.143 2.143 0 0 0-2.144-2.145h-6.433V57.54h6.433Zm-6.433-8.577h4.288v4.289h-4.288V48.96ZM18.971 91.846h-4.288v-4.289h4.288v4.289Zm0-38.596h-4.288V48.96h4.288v4.289Zm17.154-6.433V104.71H23.26V37.854c0-2.08.626-4.16 1.784-5.965l4.648-6.952 4.628 6.913c1.183 1.844 1.805 3.924 1.805 6.004v8.963Zm17.154 45.029H40.413v-4.289H53.28v4.289Zm0-8.577H40.413v-25.73H53.28v25.73Zm0-30.02H40.413v-4.288H53.28v4.289Zm17.154-6.432V104.71H57.567V37.854c0-2.08.626-4.16 1.784-5.965L64 24.937l4.627 6.913c1.184 1.844 1.806 3.924 1.806 6.004v8.963Zm17.153 45.029H74.722v-4.289h12.865v4.289Zm0-8.577H74.722v-25.73h12.865v25.73Zm0-30.02H74.722v-4.288h12.865v4.289Zm17.154-6.432V104.71H91.875V37.854c0-2.08.626-4.16 1.784-5.965l4.649-6.952 4.627 6.913c1.183 1.844 1.805 3.924 1.805 6.004v8.963Zm8.577 40.74v4.289h-4.288v-4.289h4.288Z"
      fill="currentColor"
    />
  </Icon>
);

export default Fence;
