import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import { useUserQuery } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import Presenter from "./presenter";

interface MySettingsPageProps {}

const MySettingsPage: React.FC<MySettingsPageProps> = ({ children }) => {
  const { data } = useUserQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    layoutOptionsVar({
      title: "My Settings",
      hideSearch: true,
      styleProps: { maxWidth: "1100px" },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>My Settings</title>
      </Helmet>
      {data ? <Presenter user={data.user} /> : <PageSpinner />}
      {children}
    </>
  );
};

export default MySettingsPage;

gql`
  query user {
    user {
      ...UserFragment
    }
  }
`;
