import {
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import Spinner from "../../../components/elements/spinner";
import { GENERIC_ERROR_MESSAGE } from "../../../constants/lang/en";
import {
  ReportAssetFilterInput,
  ReportFileType,
  ReportUserFilterInput,
  useCreateReportAssetMutation,
  useCreateReportMaintenanceMutation,
  useCreateReportPlanMutation,
  useCreateReportUserActivityMutation,
} from "../../../graphql/graphql";
import { timezone } from "../../../utils/date/timezones";
import { setGenericMessage } from "../../../utils/serverErrors";

interface ExportReportProps {
  userId?: string;
  filterBy: ReportAssetFilterInput & ReportUserFilterInput;
  setPrintReady: Dispatch<SetStateAction<boolean>>;
  reportType: "Asset" | "Plan" | "Maintenance" | "UserActivity";
  isDisabled: boolean;
}

const ExportReport: React.FC<ExportReportProps> = ({
  userId,
  filterBy,
  reportType,
  setPrintReady,
  isDisabled,
}) => {
  const [createPlanReport] = useCreateReportPlanMutation();
  const [createAssetReport] = useCreateReportAssetMutation();
  const [createUserReport] = useCreateReportUserActivityMutation();
  const [createMaintenanceReport] = useCreateReportMaintenanceMutation();
  const toast = useToast();
  const [isLoading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("reload")) setLoading(false);
  }, [searchParams]);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reports_table_wrap"),
    documentTitle: `${reportType} Report`,
    onBeforeGetContent: () =>
      new Promise((resolve) => setTimeout(resolve, 1000)),
    onAfterPrint: () => {
      setLoading(false);
      setPrintReady(false);
    },
    onPrintError: () => {
      setLoading(false);
      setPrintReady(false);
    },
  });

  const exportReport = async (type: ReportFileType | "PDF") => {
    setLoading(true);
    if (type === "PDF") {
      setPrintReady(true);
      setTimeout(handlePrint, 250);
    } else if (type) {
      try {
        let data;
        let errors;
        switch (reportType) {
          case "Asset":
            const { data: assetReportData, errors: assetReportErrors } =
              await createAssetReport({
                variables: {
                  fileType: type,
                  filterBy,
                  timezone,
                },
              });
            data = assetReportData;
            errors = assetReportErrors;
            break;
          case "Plan":
            const { data: planReportData, errors: planReportErrors } =
              await createPlanReport({
                variables: {
                  fileType: type,
                  filterBy,
                  timezone,
                },
              });
            data = planReportData;
            errors = planReportErrors;
            break;
          case "Maintenance":
            const {
              data: maintenanceReportData,
              errors: maintenanceReportErrors,
            } = await createMaintenanceReport({
              variables: {
                fileType: type,
                filterBy,
                timezone,
              },
            });
            data = maintenanceReportData;
            errors = maintenanceReportErrors;
            break;
          case "UserActivity":
            const { data: userReportData, errors: userReportErrors } =
              await createUserReport({
                variables: {
                  userId: userId ? userId : "",
                  fileType: type,
                  filterBy,
                  timezone,
                },
              });
            data = userReportData;
            errors = userReportErrors;
            break;
        }
        if (errors) {
          throw errors;
        } else if (!data) {
          toast({
            description: GENERIC_ERROR_MESSAGE,
            status: "error",
            position: "top",
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    }
  };

  return isLoading ? (
    <Center boxSize="8">
      <Spinner size="sm" />
    </Center>
  ) : (
    <Menu placement="bottom-end">
      <Tooltip label="Download and Print" placement="left" hasArrow>
        <MenuButton
          as={IconButton}
          variant="link"
          size="sm"
          colorScheme="gray"
          color="gray.400"
          aria-label="Download Report"
          isDisabled={isDisabled}
        >
          <FontAwesomeIcon icon={faDownload} />
        </MenuButton>
      </Tooltip>
      <MenuList>
        {exportTypes.map((exportType) => (
          <MenuItem
            key={exportType.value}
            onClick={() => exportReport(exportType.value)}
          >
            {exportType.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ExportReport;

const exportTypes: { label: string; value: ReportFileType | "PDF" }[] = [
  {
    label: "PDF",
    value: "PDF",
  },
  {
    label: "CSV",
    value: ReportFileType.Csv,
  },
  {
    label: "XLSX",
    value: ReportFileType.Xlsx,
  },
];
