import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback } from "react";

import FormGroup from "../../../components/elements/formGroup";
import {
  ASSET_MAINTENANCE_NOTE_DELETED_MESSAGE,
  GENERIC_SAVED_MESSAGE,
} from "../../../constants/lang/en";
import {
  MaintenanceNoteFragmentFragment,
  useMaintenanceNoteDeleteMutation,
  useMaintenanceNoteUpdateMutation,
  useUserQuery,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { descriptionSchema, yupObject } from "../../../utils/validation";

interface MaintenanceNoteActionsProps {
  maintenanceNote: MaintenanceNoteFragmentFragment;
  canEditMaintenance: boolean;
}

const MaintenanceNoteActions: React.FC<MaintenanceNoteActionsProps> = ({
  maintenanceNote,
  canEditMaintenance,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [maintenanceNoteDeleteMutation, { loading: deleting }] =
    useMaintenanceNoteDeleteMutation();
  const [maintenanceNoteUpdateMutation, { loading }] =
    useMaintenanceNoteUpdateMutation();
  const { data: currentUserData } = useUserQuery();

  const deleteMaintenance = useCallback(async () => {
    try {
      await maintenanceNoteDeleteMutation({
        variables: { id: maintenanceNote.id },
      });
      toast({
        description: ASSET_MAINTENANCE_NOTE_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      client.cache.evict({ id: `MaintenanceNote:${maintenanceNote.id}` });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      onDeleteClose();
    }
  }, [
    maintenanceNoteDeleteMutation,
    toast,
    client.cache,
    maintenanceNote,
    onDeleteClose,
  ]);

  const onSubmit = useCallback(
    async (
      data: MaintenanceFormData,
      { setFieldError }: FormikHelpers<MaintenanceFormData>
    ) => {
      try {
        const { data: serverData, errors } =
          await maintenanceNoteUpdateMutation({
            variables: {
              id: maintenanceNote.id,
              data: { ...data, maintenanceId: maintenanceNote.maintenanceId },
            },
          });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else if (serverData) {
          onEditClose();
          toast({
            description: GENERIC_SAVED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          return;
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [toast, maintenanceNoteUpdateMutation, maintenanceNote, onEditClose]
  );

  return (
    <Flex alignItems="center">
      {canEditMaintenance &&
        maintenanceNote.addedBy.userId === currentUserData?.user.id && (
          <>
            <IconButton
              variant="icon"
              colorScheme="gray"
              aria-label="Edit Service Note"
              onClick={onEditOpen}
              padding={2}
            >
              <FontAwesomeIcon icon={faPen} />
            </IconButton>
            <IconButton
              variant="icon"
              colorScheme="grayRed"
              aria-label="Delete Service Note"
              onClick={onDeleteOpen}
              padding={2}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
            <AlertDialog
              leastDestructiveRef={cancelRef}
              onClose={onDeleteClose}
              isOpen={isDeleteOpen}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogBody textAlign="center">
                    Are you sure you want to delete the following service note?
                    <br />
                    <Text as="strong">{maintenanceNote.description}</Text>
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button
                      ref={cancelRef}
                      onClick={onDeleteClose}
                      width="48%"
                      isLoading={deleting}
                    >
                      No, Don't Delete!
                    </Button>
                    <Button
                      onClick={deleteMaintenance}
                      colorScheme="red"
                      ml="4%"
                      width="48%"
                      isLoading={deleting}
                    >
                      Yes, Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
            <Modal isOpen={isEditOpen} onClose={onEditClose} size="lg">
              <ModalOverlay>
                <ModalContent>
                  <ModalCloseButton />
                  <ModalHeader>Edit Service Note</ModalHeader>
                  <ModalBody padding="6">
                    <Formik
                      initialValues={{
                        description: maintenanceNote.description || "",
                      }}
                      validationSchema={maintenanceFormValidationSchema}
                      onSubmit={onSubmit}
                      enableReinitialize
                    >
                      {({ getFieldProps }) => (
                        <Form noValidate>
                          <FormGroup label="" name="description">
                            <Textarea
                              rows={4}
                              autoComplete="off"
                              {...getFieldProps("description")}
                            />
                          </FormGroup>
                          <Box marginTop="2">
                            <Button
                              width="full"
                              type="submit"
                              isLoading={loading}
                            >
                              Save Changes
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </ModalBody>
                </ModalContent>
              </ModalOverlay>
            </Modal>
          </>
        )}
    </Flex>
  );
};

export default MaintenanceNoteActions;

const maintenanceFormValidationSchema = yupObject().shape({
  description: descriptionSchema.required(),
});

export type MaintenanceFormData = {
  description: string;
};
