import React, { useCallback, useMemo } from "react";

import FilterSelect, {
  FilterOption,
} from "../../../components/elements/filterSelect";
import useTopLocationsTree, {
  LocationWithChildren,
} from "../../../hooks/useTopLocationsTree";
import { sortCollator } from "../../../utils/sort";

interface LocationsFilterProps {
  onChange: (ids: string[] | undefined) => void;
  value: string[] | undefined;
}

const LocationsFilter: React.FC<LocationsFilterProps> = ({
  onChange,
  value,
}) => {
  const [topLocationsTree] = useTopLocationsTree();

  const locationOptions = useMemo(() => {
    let locationOptions: FilterOption[] = [];
    const getAllLocations = (topLocation: LocationWithChildren) => {
      locationOptions.push({
        label: topLocation.name,
        value: topLocation.id,
        query: topLocation.name,
      });
      if (topLocation.children && topLocation.children.length) {
        topLocation.children.forEach((location: LocationWithChildren) =>
          getAllLocations(location)
        );
      }
    };
    if (topLocationsTree) {
      topLocationsTree.forEach((topLocation) => {
        getAllLocations(topLocation);
      });
      locationOptions.sort((a, b) => sortCollator.compare(a.query, b.query));
    }
    return locationOptions;
  }, [topLocationsTree]);

  const handleChange = useCallback(
    (id: string) => {
      if (!value) {
        onChange([id]);
      } else if (value.includes(id)) {
        onChange(
          value.length === 1 ? undefined : value.filter((v) => v !== id)
        );
      } else {
        onChange([...value, id]);
      }
    },
    [value, onChange]
  );

  return (
    <FilterSelect
      label="Location"
      selectedOptions={value}
      allOptions={locationOptions}
      handleFilterChange={handleChange}
    />
  );
};

export default LocationsFilter;
