import { Icon, IconProps } from "@chakra-ui/react";

const HubDrain = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M86.547 101H41.453C33.483 101 27 94.515 27 86.543V56.762c-.026-8.308 7.06-14.9 15.32-14.457a2.89 2.89 0 0 1 0 5.782h-.867c-4.782 0-8.672 3.892-8.672 8.675v29.781c0 4.783 3.89 8.674 8.672 8.674h45.094c4.782 0 8.672-3.891 8.672-8.674V56.762c.024-5.14-4.47-9.08-9.54-8.674-3.836-.143-3.821-5.647 0-5.783 8.26-.441 15.349 6.148 15.321 14.457v29.781C101 94.515 94.516 101 86.547 101ZM49.258 86.543V56.762a2.89 2.89 0 1 0-5.781 0v29.781c.138 3.823 5.637 3.838 5.781 0Zm11.563 0v-21.54c-.14-3.826-5.639-3.837-5.782 0v21.54a2.89 2.89 0 1 0 5.782 0Zm11.562 0v-21.54c-.14-3.826-5.638-3.837-5.781 0v21.54a2.891 2.891 0 1 0 5.78 0Zm11.562 0V56.762a2.891 2.891 0 1 0-5.781 0v29.781c.138 3.823 5.638 3.838 5.781 0ZM64 54.34c-7.172 0-13.008-5.837-13.008-13.011 0-2.062.926-4.62 2.83-7.822 1.242-2.09 2.884-4.43 4.878-6.958 2.56-3.398 8.04-3.4 10.6 0 3.418 4.42 7.624 10.17 7.708 14.78 0 7.174-5.835 13.011-13.008 13.011Zm0-24.577a.954.954 0 0 0-.762.368c-4.771 6.048-6.464 9.812-6.464 11.198 0 3.985 3.241 7.228 7.226 7.228s7.227-3.243 7.227-7.228c0-1.386-1.694-5.15-6.465-11.198a.955.955 0 0 0-.762-.368Z"
      fill="currentColor"
    />
  </Icon>
);

export default HubDrain;
