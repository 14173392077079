import {
  Box,
  Button,
  Collapse,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faChevronDown,
  faChevronUp,
  faExternalLinkAlt,
  faPlus,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React from "react";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  AssetFileType,
  SpecialAssetFieldTypes,
  useAssetQuery,
} from "../../../graphql/graphql";
import AssetFields from "../../../pages/assets/show/details/fields";
import AssetPanel from "../../../pages/assets/show/details/panel";
import WorkOrderCreateButton from "../../../pages/workOrders/dashboard/components/createWorkOrderButton";
import { getRoutePath } from "../../../router";
import colors from "../../../theme/foundations/colors";
import AssetIcon from "../assetIcons";
import ContactUserInfo from "../contactUserInfo";
import ImageSlider from "../imageSlider";
import Link from "../link";
import Status from "../status";
import TooltipName from "../tooltipName";

interface AssetsDrawerPresenterProps {
  assetId: string;
  currentFloorPlanId?: string;
}

const AssetsDrawerPresenter: React.FC<AssetsDrawerPresenterProps> = ({
  assetId,
  currentFloorPlanId,
}) => {
  const { data } = useAssetQuery({
    variables: { id: assetId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { isOpen: isDescriptionOpen, onToggle: toggleDescription } =
    useDisclosure();
  const asset = data?.asset;
  const assetImages = React.useMemo(
    () =>
      asset
        ? sortBy(
            asset.assetFiles.filter((af) => af.type === AssetFileType.Image),
            (af) => af.order || 9999
          )
        : [],
    [asset]
  );
  const floorPlanAssetsWithAffectedAreas = asset
    ? asset.floorPlanAssets.filter((fpa) => fpa.mapAffectedArea)
    : [];
  const isSpecial = asset
    ? asset.assetType.specialAssetField !== SpecialAssetFieldTypes.Na
    : false;

  const currentlocation = asset?.floorPlanAssets.reduce((acc, curr) => {
    if (curr.floorPlan.id === currentFloorPlanId)
      acc = curr.floorPlan.locationId;
    return acc;
  }, "");

  return (
    <>
      <DrawerHeader>
        {!!asset && (
          <Flex
            alignItems="center"
            flexWrap={{ base: "wrap", md: "nowrap" }}
            position="relative"
            pl="4"
            ml="-4"
          >
            <Status status={asset.maintenanceStatus} />
            <AssetIcon
              iconName={asset.assetType.iconName}
              iconColor={asset.assetType.misc.resolvedIconColor}
              iconType={asset.assetType.iconType}
              iconSize="sm"
            />
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              lineHeight={{ base: "156%", md: "120%" }}
              fontWeight="semibold"
              marginX="2"
            >
              {asset.name} ({asset.assetType.name})
            </Text>
          </Flex>
        )}
      </DrawerHeader>
      <DrawerBody>
        {asset ? (
          <Box>
            <AssetFields asset={asset} />
            {asset.assetCompanyUsers.length > 0 && (
              <Box marginTop="6">
                <Text fontSize="sm" fontWeight="semibold" marginBottom="1">
                  Contact
                </Text>
                <ContactUserInfo
                  contactCompanyUsers={asset.assetCompanyUsers.map(
                    (acu) => acu.companyUser
                  )}
                />
              </Box>
            )}
            {asset.floorPlanAssets.length > 0 && (
              <Box marginTop="6">
                <Flex
                  alignItems="center"
                  fontSize="sm"
                  fontWeight="semibold"
                  marginBottom="1"
                >
                  Plans
                  <Tooltip
                    label="This is a list of plans on which this asset appears"
                    hasArrow
                  >
                    <Box color="gray.500" marginLeft="2">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </Box>
                  </Tooltip>
                </Flex>
                {asset.floorPlanAssets.map((fpa) => (
                  <Link
                    key={fpa.id}
                    alignItems="center"
                    justifyContent="flex-start"
                    to={`?CaAprType=planEditor&assetId=${assetId}&planId=${fpa.floorPlan.id}`}
                    variant="link"
                    colorScheme="secondary"
                    width="full"
                    fontSize="sm"
                  >
                    <TooltipName name={fpa.floorPlan.name} />
                  </Link>
                ))}
              </Box>
            )}
            {floorPlanAssetsWithAffectedAreas.length > 0 && (
              <Box marginTop="6">
                <Text fontSize="sm" fontWeight="semibold" marginBottom="1">
                  Affected Areas
                </Text>
                {floorPlanAssetsWithAffectedAreas.map((fpa) => (
                  <Link
                    key={fpa.id}
                    alignItems="center"
                    justifyContent="flex-start"
                    to={`?CaAprType=planEditor&assetId=${assetId}&planId=${fpa.floorPlan.id}`}
                    variant="link"
                    colorScheme="secondary"
                    width="full"
                    fontSize="sm"
                  >
                    <TooltipName name={fpa.floorPlan.name} />
                  </Link>
                ))}
              </Box>
            )}
            {asset.assetAffectedByAssets.length > 0 && (
              <Box marginTop="6">
                <Text fontSize="sm" fontWeight="semibold" marginBottom="1">
                  Fed From
                </Text>
                {asset.assetAffectedByAssets.map(({ asset }) => (
                  <Link
                    key={asset.id}
                    to={getRoutePath("assetsShow", { assetId: asset.id })}
                    justifyContent="flex-start"
                    variant="link"
                    colorScheme="secondary"
                    width="full"
                    fontSize="sm"
                  >
                    <TooltipName name={asset.name} />
                  </Link>
                ))}
              </Box>
            )}
            {asset.assetAffectedAssets.length > 0 && (
              <Box marginTop="6">
                <Text fontSize="sm" fontWeight="semibold" marginBottom="1">
                  Fed To
                </Text>
                {asset.assetAffectedAssets.map(({ affectedAsset: asset }) => (
                  <Link
                    key={asset.id}
                    to={getRoutePath("assetsShow", { assetId: asset.id })}
                    justifyContent="flex-start"
                    variant="link"
                    colorScheme="secondary"
                    width="full"
                    fontSize="sm"
                  >
                    <TooltipName name={asset.name} />
                  </Link>
                ))}
              </Box>
            )}
            {!!asset.description && (
              <Box marginTop="6">
                <Collapse in={isDescriptionOpen}>
                  <Box fontSize="sm" whiteSpace="pre-wrap" marginBottom="1">
                    {asset.description}
                  </Box>
                </Collapse>
                <Button
                  size="sm"
                  fontSize="sm"
                  variant="link"
                  colorScheme="gray"
                  marginTop="2"
                  paddingLeft="0"
                  onClick={toggleDescription}
                >
                  {isDescriptionOpen ? "Hide" : "Show"} description
                  <Box marginLeft="2">
                    <FontAwesomeIcon
                      icon={isDescriptionOpen ? faChevronUp : faChevronDown}
                      size="xs"
                    />
                  </Box>
                </Button>
              </Box>
            )}
            <Box marginTop="6">
              {assetImages.length > 0 && (
                <ImageSlider images={assetImages} assetId={asset.id} />
              )}
            </Box>
            {isSpecial && (
              <>
                <Box marginTop="6">
                  <Text fontSize="sm" fontWeight="semibold" marginBottom="1">
                    Electrical Panel
                  </Text>
                  <AssetPanel
                    asset={asset}
                    canEditAsset={false}
                    canAddAsset={false}
                    showHeader={false}
                    canInteract={false}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : (
          <PageSpinner />
        )}
      </DrawerBody>
      <DrawerFooter>
        {!!asset && (
          <Flex grow={1} justifyContent="space-between" gap={"24px"}>
            <div style={{ width: "calc(100% - 12px)" }}>
              <WorkOrderCreateButton
                title={"Create Work Order"}
                assetId={asset.id}
                locationId={currentlocation}
                button={
                  <Link
                    to={""}
                    onClick={(event: any) => {
                      event.preventDefault();
                    }}
                    width="full"
                    size="sm"
                    variant="outline"
                    colorScheme="secondary"
                    style={{ fontSize: "14px" }}
                  >
                    <IconButton
                      aria-label="Create New Work Order"
                      variant="icon"
                      style={{ marginRight: "6px" }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        color={colors.secondary["500"]}
                      />
                    </IconButton>
                    Create Work Order
                  </Link>
                }
              />
            </div>
            <div style={{ width: "calc(100% - 12px)" }}>
              <Link
                to={getRoutePath("assetsShow", { assetId: asset.id })}
                width="full"
                size="sm"
                variant="outline"
                colorScheme="secondary"
                style={{ fontSize: "14px" }}
              >
                View Asset Page
              </Link>
            </div>
          </Flex>
        )}
      </DrawerFooter>
    </>
  );
};

export default AssetsDrawerPresenter;
