import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faChevronLeft,
  faChevronRight,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import AssetStatusCount from "../../../components/elements/assetStatusCount";
import Calendar from "../../../components/elements/calendar";
import ChartBar from "../../../components/elements/charts/bar";
import ChartPie from "../../../components/elements/charts/pie";
import {
  AssetWithTypeFragmentFragment,
  CategoriesSummaryFragmentFragment,
  FloorPlanIndexFragmentFragment,
  MaintenanceIndexFragmentFragment,
  MaintenanceReportFragmentFragment,
  MaintenanceStatusType,
  useAssetConfigQuery,
  useDashboardConfigQuery,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import orderBy from "../../../utils/sort";
import FloorPlanList from "../../floorPlans/list/table";
import AssetsList from "../../locations/show/assets/list";

interface DashboardContentPresenterProps {
  assets: AssetWithTypeFragmentFragment[];
  maintenances?: MaintenanceIndexFragmentFragment[];
  floorPlans: FloorPlanIndexFragmentFragment[];
  dataByCategory: CategoriesSummaryFragmentFragment[];
  dataAllCategories: MaintenanceReportFragmentFragment[];
  gotoPrevWeek: () => void;
  gotoNextWeek: () => void;
  gotoCurrentWeek: () => void;
  showThisWeekButton: boolean;
  loading: boolean;
}

const DashboardContentPresenter: React.FC<DashboardContentPresenterProps> = ({
  assets,
  maintenances,
  floorPlans,
  dataAllCategories,
  dataByCategory,
  gotoPrevWeek,
  gotoNextWeek,
  gotoCurrentWeek,
  showThisWeekButton,
  loading,
}) => {
  const {
    isOpen: isCalendarOpen,
    onOpen: onCalendarOpen,
    onClose: onCalendarClose,
  } = useDisclosure();
  const navigate = useNavigate();

  const { data: dashboardConfigData } = useDashboardConfigQuery();
  const dashboardConfig = dashboardConfigData?.dashboardConfig;

  const { data: assetConfigData } = useAssetConfigQuery();
  const assetConfig = assetConfigData?.assetConfig;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClickCurrent = useCallback(() => {
    navigate(
      `${getRoutePath("assets")}?filter=${MaintenanceStatusType.Current}`
    );
  }, [navigate]);

  const handleClickDueIn30 = useCallback(() => {
    navigate(
      `${getRoutePath("assets")}?filter=${MaintenanceStatusType.DueIn_30}`
    );
  }, [navigate]);

  const handleClickPastDue = useCallback(() => {
    navigate(
      `${getRoutePath("assets")}?filter=${MaintenanceStatusType.PastDue}`
    );
  }, [navigate]);

  const filteredAndSortedAssets = React.useMemo(() => {
    const filter = dashboardConfig?.filter;
    if (!filter || !assetConfig) return [];
    const assetConfigFilter = filter && filter.length > 0 ? filter : undefined;
    let filteredAssets = assets;
    if (assetConfigFilter) {
      filteredAssets = filteredAssets.filter((asset) =>
        assetConfigFilter.includes(asset.maintenanceStatus)
      );
    }
    return orderBy(filteredAssets, assetConfig.sortBy, assetConfig.sortOrder);
  }, [dashboardConfig?.filter, assetConfig, assets]);

  const floorPlansPresent = !!floorPlans.length;
  const assetsPresent = !!filteredAndSortedAssets.length;

  return (
    <Box>
      {dataByCategory.length > 0 && dataAllCategories.length > 0 && (
        <>
          <Flex flexWrap="wrap">
            <Box
              width={{ base: "100%", lg: "35%" }}
              flexShrink={0}
              flexGrow={0}
            >
              <Container maxWidth="400px">
                <Heading as="h2" size="md" textAlign="center" marginBottom="2">
                  Status
                </Heading>
                <Flex flexWrap="wrap" justifyContent="space-around">
                  {dataByCategory.slice(0, 4).map((categoryData) => (
                    <ChartPie
                      key={categoryData.categoryId}
                      data={[
                        {
                          name: "Lookin’ good!",
                          value: categoryData.currentCount,
                        },
                        {
                          name: "Need attention soon",
                          value: categoryData.dueIn30Count,
                        },
                        {
                          name: "Need immediate attention!",
                          value: categoryData.pastDueCount,
                        },
                        {
                          name: "No data",
                          value:
                            categoryData.currentCount +
                              categoryData.dueIn30Count +
                              categoryData.pastDueCount <
                            1
                              ? 1
                              : 0,
                        },
                      ]}
                      label={categoryData.categoryName}
                      handleClickCurrent={handleClickCurrent}
                      handleClickDueIn30={handleClickDueIn30}
                      handleClickPastDue={handleClickPastDue}
                    />
                  ))}
                </Flex>
                <AssetStatusCount assets={assets} />
                {dataByCategory.length > 4 && (
                  <>
                    <Box textAlign="center">
                      <Button
                        onClick={onOpen}
                        variant="link"
                        colorScheme="gray"
                        marginX="3"
                      >
                        Show more
                      </Button>
                    </Box>
                    <Modal isOpen={isOpen} onClose={onClose} size="full">
                      <ModalOverlay>
                        <ModalContent
                          margin="0"
                          height="screenFull"
                          overflowY="auto"
                          backgroundColor="white"
                        >
                          <ModalCloseButton />
                          <ModalHeader>Categories</ModalHeader>
                          <ModalBody>
                            <Flex flexWrap="wrap" justifyContent="space-around">
                              {dataByCategory.map((categoryData) => (
                                <ChartPie
                                  key={categoryData.categoryId}
                                  data={[
                                    {
                                      name: "Lookin’ good!",
                                      value: categoryData.currentCount,
                                    },
                                    {
                                      name: "Need attention soon",
                                      value: categoryData.dueIn30Count,
                                    },
                                    {
                                      name: "Need immediate attention!",
                                      value: categoryData.pastDueCount,
                                    },
                                  ]}
                                  label={categoryData.categoryName}
                                  handleClickCurrent={handleClickCurrent}
                                  handleClickDueIn30={handleClickDueIn30}
                                  handleClickPastDue={handleClickPastDue}
                                />
                              ))}
                            </Flex>
                          </ModalBody>
                        </ModalContent>
                      </ModalOverlay>
                    </Modal>
                  </>
                )}
              </Container>
            </Box>
            <Box
              width={{ base: "100%", lg: "65%" }}
              flexShrink={0}
              flexGrow={0}
              marginTop={{ base: "4", lg: 0 }}
              marginLeft={{ base: "-5", lg: 0 }}
            >
              <Heading as="h2" size="md" textAlign="center" marginBottom="2">
                Preventative Maintenance Overview
              </Heading>
              <Box height="300px">
                <ChartBar
                  data={dataAllCategories.map((category) => ({
                    name: format(category.date, "MMM d"),
                    Current: category.currentCount,
                    "Due within 30 days": category.dueIn30Count,
                    "Past Due": category.pastDueCount,
                  }))}
                  handleClickCurrent={handleClickCurrent}
                  handleClickDueIn30={handleClickDueIn30}
                  handleClickPastDue={handleClickPastDue}
                />
              </Box>
              <Flex alignItems="center" marginLeft="10" justifyContent="center">
                <Box width="120px" textAlign="right">
                  {loading && (
                    <Spinner
                      thickness="2px"
                      speed="0.65s"
                      color="gray.400"
                      size="xs"
                    />
                  )}
                </Box>
                <Button
                  onClick={gotoPrevWeek}
                  variant="link"
                  colorScheme="gray"
                  marginX="3"
                  aria-label="Previous Week"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <Button
                  onClick={gotoNextWeek}
                  variant="link"
                  colorScheme="gray"
                  marginX="3"
                  aria-label="Next Week"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                <Box width="120px">
                  {showThisWeekButton && (
                    <Button
                      onClick={gotoCurrentWeek}
                      variant="link"
                      colorScheme="gray"
                      marginX="3"
                    >
                      Current week
                    </Button>
                  )}
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Divider borderColor="gray.300" marginY="6" />
        </>
      )}
      <Box>
        <Flex alignItems="center" marginBottom="6">
          <Heading size="lg" marginBottom="1">
            Service Schedule
          </Heading>
          <Spacer />
          <Tooltip label="Full screen calendar" hasArrow>
            <IconButton
              onClick={onCalendarOpen}
              variant="icon"
              aria-label="Full screen calendar"
              colorScheme="secondary"
              fontSize="xl"
              color="gray.300"
              marginRight="4"
              minWidth="auto"
              _hover={{ color: "gray.800" }}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </IconButton>
          </Tooltip>
        </Flex>
        <Box height="500px">
          <Calendar maintenances={maintenances} />
        </Box>
      </Box>

      {(floorPlansPresent || assetsPresent) && (
        <>
          <Divider borderColor="gray.300" marginY="6" />
          <Heading size="lg" marginBottom="4">
            What needs service
          </Heading>
          {floorPlansPresent && assetsPresent ? (
            <Tabs isLazy>
              <TabList>
                <Tab>Locations</Tab>
                <Tab>Assets</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FloorPlanList
                    floorPlans={floorPlans}
                    canEditPlan={false}
                    canDeletePlan={false}
                    showMaintenanceNeededAssets
                    showFiltersAndActions={false}
                  />
                </TabPanel>
                <TabPanel>
                  <AssetsList
                    assets={filteredAndSortedAssets}
                    canDeleteAsset={false}
                    canEditAsset={false}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : floorPlansPresent ? (
            <FloorPlanList
              floorPlans={floorPlans}
              canEditPlan={false}
              canDeletePlan={false}
              showMaintenanceNeededAssets
              showFiltersAndActions={false}
            />
          ) : assetsPresent ? (
            <AssetsList
              assets={filteredAndSortedAssets}
              canDeleteAsset={false}
              canEditAsset={false}
            />
          ) : null}
        </>
      )}

      <Modal isOpen={isCalendarOpen} onClose={onCalendarClose} size="full">
        <ModalOverlay>
          <ModalContent margin="0" height="screenFull">
            <ModalCloseButton />
            <ModalHeader>Service Schedule</ModalHeader>
            <ModalBody height="100%">
              <Calendar maintenances={maintenances} />
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
};

export default DashboardContentPresenter;
