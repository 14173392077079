import { EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from "@elastic/eui";
import React from "react";

import FileInput from "../../../components/fileInput";
import Label from "../../../components/label";
import AssetIcon from "../../../components/svgIcons/assetIcon";
import AttachmentsIcon from "../../../components/svgIcons/attachmentsIcon";
import {
  workOrdersAcceptedFileTypes,
  workOrdersFileMaxSize,
} from "../../../helpers/filesValidation";
import { WorkOrder, WorkOrderAsset } from "../../../models/workOrder";
import WorkOrderCreateServiceAssetsTable from "./assetsTable";
import WorkOrderCreateServicesAttachments, {
  AttachmentsTableModeEnum,
} from "./attachments";
import WorkOrderEditor from "./editor";
import WorkOrderServicesTitle from "./serviceTitle";

interface WorkOrderCreateServicesServicePlanPresenterProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  onFilesAdded: (files: File[]) => void;
  onFileDelete: (files: File) => void;
  onAddAssetClick: () => void;
  onAssetDelete: (asset: WorkOrderAsset) => void;
}

const WorkOrderCreateServiceGeneralInfoPresenter: React.FC<
  WorkOrderCreateServicesServicePlanPresenterProps
> = ({
  workOrder,
  onWorkOrderChange,
  onFilesAdded,
  onFileDelete,
  onAddAssetClick,
  onAssetDelete,
}) => {
  const { euiTheme } = useEuiTheme();
  const onTitleChanged = (name: string) =>
    onWorkOrderChange({ ...workOrder, name });

  return (
    <EuiFlexGroup
      direction="column"
      justifyContent="flexStart"
      alignItems="stretch"
      style={{ width: "100%" }}
    >
      <EuiFlexItem
        grow={0}
        style={{
          minWidth: "360px",
        }}
      >
        <EuiFlexItem
          style={{
            width: "100%",
          }}
        >
          <EuiText className={"columnTitle"}>Service Details</EuiText>
        </EuiFlexItem>
        <WorkOrderServicesTitle
          workOrder={workOrder}
          placeholder={"Add Service Title"}
          onTitleChange={onTitleChanged}
        />
      </EuiFlexItem>
      <EuiFlexItem
        grow={0}
        style={{
          minWidth: "360px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "nowrap",
          gap: euiTheme.size.base,
        }}
      >
        <button onClick={onAddAssetClick} className={"button-asset"}>
          <EuiText
            style={{ fontSize: euiTheme.size.m, fontWeight: 500 }}
            color={euiTheme.colors.darkShade}
          >
            <AssetIcon />
          </EuiText>
          <EuiText
            style={{
              fontSize: euiTheme.size.m,
              fontWeight: 500,
              lineHeight: "21px",
            }}
            color={euiTheme.colors.darkShade}
          >
            Add Asset
          </EuiText>
        </button>
        <FileInput
          multiple={true}
          accept={workOrdersAcceptedFileTypes}
          maxSize={workOrdersFileMaxSize}
          acceptErrorMassage="Only JPEG, PNG, PDF file are allowed"
          maxSizeErrorMassage="Max size of the file must be no more than 10 MB"
          disableDragAndDrop={true}
          onChange={onFilesAdded}
        >
          <button onClick={() => {}} className={"button-attachments"}>
            <EuiText
              style={{ fontSize: euiTheme.size.m, fontWeight: 500 }}
              color={euiTheme.colors.darkShade}
            >
              <AttachmentsIcon />
            </EuiText>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: 500,
                lineHeight: "21px",
              }}
              color={euiTheme.colors.darkShade}
            >
              Attach Files
            </EuiText>
          </button>
        </FileInput>
      </EuiFlexItem>
      <EuiFlexItem
        grow={0}
        style={{
          minWidth: "360px",
        }}
      >
        <Label label="Description" style={{ paddingBottom: "5px" }} />
        <WorkOrderEditor
          workOrder={workOrder}
          onWorkOrderChange={onWorkOrderChange}
        />
      </EuiFlexItem>
      <EuiFlexItem
        grow={0}
        style={{
          minWidth: "360px",
        }}
      >
        {!!workOrder?.attachments?.length && (
          <WorkOrderCreateServicesAttachments
            workOrder={workOrder}
            onFilesAdded={onFilesAdded}
            onFileDelete={onFileDelete}
            mode={AttachmentsTableModeEnum.create}
          />
        )}
      </EuiFlexItem>
      <EuiFlexItem
        grow={0}
        style={{
          minWidth: "360px",
        }}
      >
        {!!workOrder?.assetWorkOrders?.length && (
          <WorkOrderCreateServiceAssetsTable
            companyId={workOrder.companyId}
            assets={workOrder.assetWorkOrders}
            editable={true}
            onAssetDelete={onAssetDelete}
            onAddNewAssetClick={onAddAssetClick}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default WorkOrderCreateServiceGeneralInfoPresenter;
