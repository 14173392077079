import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import React, { useEffect } from "react";

import { useWOGetCompanyUsersQuery } from "../../../../../graphql/queries/workOrders";
import { WOGetCompanyUsers } from "../../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateServicesServiceSchedulePresenter from "./presenter";

interface WorkOrderCreateServicesServiceAssigneeInfoProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateServicesServiceAssigneeInfo: React.FC<
  WorkOrderCreateServicesServiceAssigneeInfoProps
> = ({ workOrder, onWorkOrderChange }) => {
  const [userOptions, setUserOptions] = React.useState(
    [] as EuiComboBoxOptionOption<string>[]
  );
  const { data: companyUsers, loading } = useWOGetCompanyUsersQuery({
    variables: {
      companyId: workOrder?.companyId,
    },
    fetchPolicy: "cache-and-network",
    skip: !workOrder?.companyId,
  });

  useEffect(() => {
    if (companyUsers?.workOrderCompanyUsers) {
      const users = companyUsers?.workOrderCompanyUsers.map((user) => {
        return { label: getUserLabel(user), value: user.id };
      });
      setUserOptions(users);
    } else {
      setUserOptions([]);
    }
  }, [companyUsers]);

  const getUserLabel = (user: WOGetCompanyUsers): string => {
    return user.fullName || user.email || user.id;
  };

  return (
    <>
      <WorkOrderCreateServicesServiceSchedulePresenter
        workOrder={workOrder}
        userOptions={userOptions}
        onWorkOrderChange={onWorkOrderChange}
      />
    </>
  );
};

export default WorkOrderCreateServicesServiceAssigneeInfo;
