import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { flatten } from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import {
  SortBy,
  SortOrder,
  useAssetWithTypeQuery,
  useFloorPlanQuery,
} from "../../../../graphql/graphql";
import {
  isNativeWebViewVar,
  planEditorOptionsVar,
} from "../../../../graphql/reactiveVariables";
import useAssetCategories from "../../../../hooks/useAssetCategories";
import { getRoutePath } from "../../../../router";
import orderBy from "../../../../utils/sort";
import AddCircle from "../../../icons/addCircle";
import AssetIcon from "../../assetIcons";
import Link from "../../link";
import Spinner from "../../spinner";
import TooltipName from "../../tooltipName";

declare let window: any;

interface AssetListsProps {}

const AssetLists: React.FC<AssetListsProps> = memo(() => {
  const {
    planId,
    assetCategoryIds,
    assetTypeIds,
    assetSearchQuery,
    assetMaintenanceStatus,
  } = useReactiveVar(planEditorOptionsVar);
  const [isNative] = useState(isNativeWebViewVar());
  const {
    isOpen: isExistingAssets,
    onOpen: setExistingAssets,
    onClose: setNewAssets,
  } = useDisclosure();

  const { data: floorPlanData } = useFloorPlanQuery({
    variables: { id: planId },
  });
  const { data: assetCategoriesData, refetch: refetchCategories } =
    useAssetCategories({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const { data: assetsData } = useAssetWithTypeQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const draggableAssetTypes = useMemo(() => {
    const assetCategories = assetCategoriesData?.assetCategories;
    if (!assetCategories) return [];
    const selectedAssetCategories = orderBy(
      assetCategoryIds.length
        ? assetCategories.filter((ac) => assetCategoryIds.includes(ac.id))
        : assetCategories,
      SortBy.Name,
      SortOrder.Asc
    );
    return flatten(
      selectedAssetCategories.map((ac) =>
        ac.assetTypes
          ? orderBy(
              assetTypeIds.length
                ? ac.assetTypes.filter((at) => assetTypeIds.includes(at.id))
                : ac.assetTypes,
              SortBy.Name,
              SortOrder.Asc
            )
          : []
      )
    );
  }, [assetCategoriesData?.assetCategories, assetCategoryIds, assetTypeIds]);

  const draggableAssets = useMemo(() => {
    const assets = assetsData?.assets;
    const floorPlan = floorPlanData?.floorPlan;
    if (!assets || !floorPlan) return [];

    const assetTypesPresent = assetTypeIds.length;
    const assetCategoriesPresent = assetCategoryIds.length;
    const assetMaintenanceStatusesPresent = assetMaintenanceStatus.length;
    const assetSearchQueryLowerCased = assetSearchQuery
      ? assetSearchQuery.toLowerCase()
      : undefined;
    return orderBy(
      assets.filter((asset) => {
        if (
          floorPlan.floorPlanAssets.some(
            (fpa) => fpa.asset.id === asset.id && fpa.mapPosition
          )
        ) {
          return false;
        }
        if (assetTypesPresent && !assetTypeIds.includes(asset.assetType.id)) {
          return false;
        }
        if (
          assetCategoriesPresent &&
          !assetCategoryIds.includes(asset.assetType.assetCategoryId)
        ) {
          return false;
        }
        if (
          assetMaintenanceStatusesPresent &&
          !assetMaintenanceStatus.includes(asset.maintenanceStatus)
        ) {
          return false;
        }
        if (
          assetSearchQueryLowerCased &&
          !asset.name.toLowerCase().includes(assetSearchQueryLowerCased)
        ) {
          return false;
        }
        return true;
      }),
      SortBy.Name,
      SortOrder.Asc
    );
  }, [
    assetCategoryIds,
    assetMaintenanceStatus,
    assetSearchQuery,
    assetTypeIds,
    assetsData?.assets,
    floorPlanData?.floorPlan,
  ]);

  const handleTypeCreate = useCallback(() => {
    if (window.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ eventType: "CA:CreateAssetType" })
      );
    }
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.data.eventType &&
        event.data.eventType === "CA:PlanEditor:RefetchCategories"
      ) {
        refetchCategories();
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, [refetchCategories]);

  return draggableAssetTypes && draggableAssets ? (
    <Flex borderBottom="1px solid" borderColor="gray.100">
      <Flex
        width="125px"
        height="80px"
        flexShrink={0}
        borderX="1px solid"
        borderColor="gray.100"
        flexDirection="column"
        justifyContent="stretch"
      >
        <Tooltip
          label="Select this to create new assets and add them to this plan"
          openDelay={750}
          hasArrow
          placement="right"
        >
          <Button
            width="full"
            colorScheme="gray"
            color={isExistingAssets ? "gray.500" : "black"}
            _hover={{ color: "secondary.500" }}
            size="xs"
            height="40px"
            onClick={isExistingAssets ? setNewAssets : undefined}
          >
            Asset Types
          </Button>
        </Tooltip>
        <Tooltip
          label="Select this to add your existing assets to this plan"
          openDelay={750}
          hasArrow
          placement="right"
        >
          <Button
            width="full"
            colorScheme="gray"
            color={isExistingAssets ? "black" : "gray.500"}
            _hover={{ color: "secondary.500" }}
            size="xs"
            height="40px"
            onClick={isExistingAssets ? undefined : setExistingAssets}
          >
            Assets
          </Button>
        </Tooltip>
      </Flex>
      <Box
        overflow="auto"
        width={{
          base: `calc(100vw - ${isNative ? 126 : 175}px)`,
          md: `calc(100vw - ${isNative ? 525 : 585}px)`,
        }}
        height="80px"
      >
        <Flex flexWrap="nowrap" height="full">
          {isExistingAssets ? (
            <>
              {draggableAssets.length ? (
                <>
                  {draggableAssets.map((asset) => (
                    <Center
                      key={asset.id}
                      paddingTop="2"
                      paddingX="2"
                      textAlign="center"
                      borderRight="1px solid"
                      flex="0 0 65px"
                      width="65px"
                      borderColor="gray.100"
                      height="full"
                    >
                      <Box width="full">
                        <Button
                          draggable
                          onDragStart={(e) => {
                            e.dataTransfer.setData("assetId", asset.id);
                            const client = (
                              e.target as any
                            ).getBoundingClientRect();
                            e.dataTransfer.setData(
                              "x",
                              `${client.x + client.width / 2 - e.pageX}`
                            );
                            e.dataTransfer.setData(
                              "y",
                              `${client.y + client.height / 2 - e.pageY}`
                            );
                          }}
                          variant="link"
                          cursor="grab"
                          minWidth="auto"
                          aria-label={`Add ${asset.name} to plan (drag & drop)`}
                        >
                          <AssetIcon
                            iconName={asset.assetType.iconName}
                            iconColor={asset.assetType.misc.resolvedIconColor}
                            iconType={asset.assetType.iconType}
                            iconSize="xs"
                          />
                        </Button>
                        <TooltipName
                          name={asset.name}
                          fontSize="xs"
                          marginTop="1"
                        />
                      </Box>
                    </Center>
                  ))}
                </>
              ) : (
                <Center color="gray.700" paddingX="5" height="full">
                  Assets empty
                </Center>
              )}
            </>
          ) : (
            <>
              {draggableAssetTypes.map((assetType) => (
                <Center
                  key={assetType.id}
                  paddingTop="2"
                  paddingX="2"
                  textAlign="center"
                  borderRight="1px solid"
                  flex="0 0 65px"
                  width="65px"
                  borderColor="gray.100"
                  height="full"
                >
                  <Box width="full">
                    <Button
                      draggable
                      onDragStart={(e) => {
                        e.dataTransfer.setData("assetTypeId", assetType.id);
                        const client = (
                          e.target as any
                        ).getBoundingClientRect();
                        e.dataTransfer.setData(
                          "x",
                          `${client.x + client.width / 2 - e.pageX}`
                        );
                        e.dataTransfer.setData(
                          "y",
                          `${client.y + client.height / 2 - e.pageY}`
                        );
                      }}
                      variant="link"
                      cursor="grab"
                      minWidth="auto"
                      aria-label={`Add ${assetType.name} to plan (drag & drop)`}
                    >
                      <AssetIcon
                        iconName={assetType.iconName}
                        iconColor={assetType.misc.resolvedIconColor}
                        iconType={assetType.iconType}
                        iconSize="xs"
                      />
                    </Button>
                    <TooltipName
                      name={assetType.name}
                      fontSize="xs"
                      marginTop="1"
                    />
                  </Box>
                </Center>
              ))}
              <Center
                paddingX="2"
                textAlign="center"
                borderRight="1px solid"
                flex="0 0 65px"
                width="65px"
                borderColor="gray.100"
                paddingY="4"
                height="full"
              >
                <Tooltip
                  hasArrow
                  label="Add new asset type"
                  placement="bottom"
                  zIndex={2000}
                >
                  {isNative ? (
                    <IconButton
                      onClick={handleTypeCreate}
                      variant="link"
                      colorScheme="secondary"
                      draggable={false}
                      aria-label="Add new asset type"
                    >
                      <AddCircle boxSize="32px" />
                    </IconButton>
                  ) : (
                    <Link
                      to={getRoutePath("assetCategories")}
                      variant="link"
                      colorScheme="secondary"
                      draggable={false}
                      aria-label="Add new asset type"
                    >
                      <AddCircle boxSize="32px" />
                    </Link>
                  )}
                </Tooltip>
              </Center>
            </>
          )}
        </Flex>
      </Box>
    </Flex>
  ) : (
    <Center height="80px">
      <Spinner />
    </Center>
  );
});

export default AssetLists;
