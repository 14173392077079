import { Center } from "@chakra-ui/react";
import React from "react";

import useBoxSize from "../../../hooks/useBoxSize";

interface CenteredFullHeightProps {}

const CenteredFullHeight: React.FC<CenteredFullHeightProps> = ({
  children,
}) => {
  const { containerRef, height } = useBoxSize();
  return (
    <Center ref={containerRef} height={height}>
      {height ? children : null}
    </Center>
  );
};

export default CenteredFullHeight;
