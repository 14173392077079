import React from "react";

import { WOLocation } from "../../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrder } from "../../../models/workOrder";
import WorkOrderCreateCompanyDetailsPresenter from "./presenter";

interface WorkOrderCreateLocationDetailsProps {
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  locations: WOLocation[];
  selectedLocationId?: string;
  locationsLoading?: boolean;
}

const WorkOrderCreateLocationDetails: React.FC<
  WorkOrderCreateLocationDetailsProps
> = ({
  onWorkOrderChange,
  locations,
  selectedLocationId,
  locationsLoading,
}) => {
  return (
    <>
      <WorkOrderCreateCompanyDetailsPresenter
        locations={locations}
        onWorkOrderChange={onWorkOrderChange}
        selectedLocationId={selectedLocationId}
        locationsLoading={locationsLoading}
      />
    </>
  );
};

export default WorkOrderCreateLocationDetails;
