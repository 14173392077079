import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import { useCompanyQuery } from "../../graphql/graphql";
import { getRoutePath } from "../../router";

interface FilesPageProps {}

const FilesPage: React.FC<FilesPageProps> = () => {
  const { data: currentCompanyData } = useCompanyQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentCompanyData) {
      navigate(
        getRoutePath("foldersShow", {
          folderId: currentCompanyData.company.defaultFolderId,
        })
      );
    }
  }, [currentCompanyData, navigate]);

  return <PageSpinner />;
};

export default FilesPage;
