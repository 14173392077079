import {
  Box,
  Button,
  Center,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";

import { IconTypes } from "../../../../graphql/graphql";
import AssetIcons from "../../../icons/assets";
import FormGroup from "../../formGroup";
import AssetIcon from "..";

interface IconPickerProps {
  defaultCustomIconText: string;
  value: string;
  isIconPickerOpen: boolean;
  onIconPickerClose: () => void;
  setFieldValue: (name: string, value: string) => void;
  color: string;
  iconType: IconTypes;
}

const name = "iconName";

const IconPicker: React.FC<IconPickerProps> = ({
  defaultCustomIconText,
  value,
  isIconPickerOpen,
  onIconPickerClose,
  setFieldValue,
  color,
  iconType,
}) => {
  const [customIconText, setCustomIconText] = React.useState(
    defaultCustomIconText
  );

  const setIconValue = React.useCallback(
    (icon) => {
      setFieldValue(name, icon);
      setCustomIconText("");
      onIconPickerClose();
    },
    [setFieldValue, onIconPickerClose]
  );

  React.useEffect(() => {
    setCustomIconText(defaultCustomIconText);
  }, [defaultCustomIconText, setCustomIconText]);

  return (
    <Modal isOpen={isIconPickerOpen} onClose={onIconPickerClose} size="xl">
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton />
          <ModalBody paddingBottom="6">
            <Tabs defaultIndex={customIconText ? 1 : 0} isLazy>
              <TabList>
                <Tab>Select Icon</Tab>
                <Tab>Create Text Icon</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Grid
                    rowGap="6"
                    columnGap="4"
                    gridTemplateColumns="repeat(auto-fill, minmax(60px, 1fr))"
                    marginTop="4"
                  >
                    {Object.keys(AssetIcons).map((key, i) => {
                      return (
                        <IconButton
                          key={i}
                          variant="icon"
                          aria-label={`Choose ${key}`}
                          colorScheme="gray"
                          width="60px"
                          height="60px"
                          outline="3px solid transparent"
                          _hover={{ outlineColor: "secondary.500" }}
                          _active={{ outlineColor: "secondary.500" }}
                          onClick={() => setIconValue(key)}
                          isActive={value === key}
                        >
                          <AssetIcon
                            iconName={key}
                            iconColor={color}
                            iconType={iconType}
                          />
                        </IconButton>
                      );
                    })}
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Box marginTop="2" textAlign="center">
                    <Center height="100px">
                      <AssetIcon
                        iconName={customIconText}
                        iconColor={color}
                        iconType={iconType}
                      />
                    </Center>
                    <FormGroup name={name} label="">
                      <FormLabel
                        textAlign="center"
                        marginBottom="2"
                        marginRight="0"
                        color="gray.600"
                        fontSize="xs"
                      >
                        Enter three letters for your icon
                      </FormLabel>
                      <Box width="120px" margin="auto" textAlign="center">
                        <Input
                          autoComplete="off"
                          maxLength={4}
                          value={customIconText}
                          onChange={(e) => setCustomIconText(e.target.value)}
                          textAlign="center"
                        />
                      </Box>
                    </FormGroup>
                    <Button
                      marginTop="3"
                      onClick={() => setIconValue(customIconText)}
                      variant="outline"
                      paddingX="6"
                      colorScheme="secondary"
                    >
                      Save
                    </Button>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default IconPicker;
