import Input from "./input";

const { baseStyle, sizes, variants } = Input;

const Select = {
  baseStyle: {
    ...baseStyle,
    field: {
      minHeight: "56px",
    },
  },
  sizes,
  variants,
};

export default Select;
