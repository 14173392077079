import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  useAssetQuery,
  useAssetUpdateMutation,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import Presenter, { AssetsFormData } from "./presenter";

interface AssetsEditPageProps {}

const AssetsEditPage: React.FC<AssetsEditPageProps> = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { assetId: id } = useParams();

  const [assetsUpdateMutation, { loading }] = useAssetUpdateMutation();
  const { data } = useAssetQuery({
    variables: { id },
  });

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (
    data: AssetsFormData,
    { setFieldError }: FormikHelpers<AssetsFormData>
  ) => {
    try {
      const { data: serverData, errors } = await assetsUpdateMutation({
        variables: { id, data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  return data ? (
    <Presenter
      asset={data.asset}
      handleSubmit={onSubmit}
      onDrawerClose={onDrawerClose}
      isOpen={isOpen}
      loading={loading}
    />
  ) : null;
};

export default AssetsEditPage;

gql`
  mutation AssetUpdate($id: UUID!, $data: AssetUpdateInput!) {
    assetUpdate(id: $id, data: $data) {
      ...AssetWithRelationsFragment
    }
  }
`;
