import { Icon, IconProps } from "@chakra-ui/react";

const DisconnectSwitch = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="m43.65 67.226-8.568-4.284 3.165-3.164c2.903-2.903-1.485-7.3-4.392-4.392l-6.211 6.211c-1.497 1.497-1.088 4.026.807 4.973l8.568 4.285-3.164 3.164c-2.914 2.914 1.524 7.26 4.392 4.392l6.21-6.211c1.497-1.497 1.089-4.026-.806-4.974Z"
      fill="currentColor"
    />
    <path
      d="M104.371 29.633c5.137 0 9.317-4.18 9.317-9.317 0-5.137-4.18-9.316-9.317-9.316-5.137 0-9.316 4.18-9.316 9.316a9.26 9.26 0 0 0 .91 4.015L75.325 44.97c-4.457-4.884-10.62-8.185-17.536-8.953v-9.49c0-5.137-4.18-9.316-9.316-9.316h-27.95v-3.105a3.106 3.106 0 0 0-6.21 0v99.789a3.105 3.105 0 0 0 6.21 0v-3.52h27.95c5.137 0 9.316-4.179 9.316-9.316v-9.49c13.955-1.55 24.844-13.415 24.844-27.776 0-4.988-1.315-9.674-3.614-13.732l21.338-21.339a9.26 9.26 0 0 0 4.014.91ZM57.789 55.01C61.404 56.293 64 59.744 64 63.793c0 4.048-2.596 7.5-6.21 8.782V55.011Zm-6.21 46.048a3.109 3.109 0 0 1-3.106 3.105h-27.95V23.422h27.95a3.109 3.109 0 0 1 3.105 3.105v74.532Zm6.21-15.75v-6.301c7.079-1.443 12.422-7.716 12.422-15.215s-5.343-13.772-12.422-15.215v-6.3c10.52 1.511 18.633 10.583 18.633 21.515S68.31 83.797 57.789 85.308Zm46.582-68.098a3.11 3.11 0 0 1 3.106 3.105 3.11 3.11 0 0 1-3.106 3.106 3.109 3.109 0 0 1-3.105-3.106 3.109 3.109 0 0 1 3.105-3.105Z"
      fill="currentColor"
    />
    <path
      d="M42.262 35.844a3.106 3.106 0 1 0 0-6.211 3.106 3.106 0 0 0 0 6.21ZM42.262 97.953a3.105 3.105 0 1 0 0-6.21 3.105 3.105 0 0 0 0 6.21Z"
      fill="currentColor"
    />
  </Icon>
);

export default DisconnectSwitch;
