import { Styles } from "@chakra-ui/theme-tools";

import colors from "../theme/foundations/colors";

const styles: Styles = {
  global: {
    "@media print": {
      "html, body": {
        width: "210mm",
        height: "297mm",
      },
      ".print-no-break": {
        breakInside: "avoid",
      },
    },
    body: {
      fontFamily: "Inter, sans-serif",
      overflow: "hidden",
      minWidth: "350px",

      ".chakra-tabs__tablist .chakra-tabs__tab:focus, .chakra-link:focus": {
        boxShadow: "none",
      },
    },

    "*::-webkit-scrollbar": {
      width: "0.4em",
    },

    "*::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "gray.300",
      borderRadius: "0.4em",
    },

    ".chakra-toast__inner": {
      minWidth: "auto !important",
    },

    ".chakra-menu__icon-wrapper": {
      color: "secondary.500",
    },

    ".chakra-radio:hover .chakra-radio__control, .chakra-checkbox:hover .chakra-checkbox__control":
      {
        borderColor: "secondary.500",
      },

    ".chakra-form_error-absolute + .chakra-form__error-message": {
      position: "absolute",
    },

    "[data-reach-combobox-popover]": {
      position: "absolute",
      backgroundColor: "white",
      borderWidth: "1px",
      borderColor: "gray.100",
      borderBottomWidth: "0p",
      shadow: "lg",
      fontSize: "sm",
      width: "100%",
      fontWeight: "300",
      overflowY: "auto",
      marginLeft: "0",
      maxHeight: "200px",
      zIndex: 3000,
    },

    "[data-reach-combobox-option]": {
      borderBottomWidth: "1px",
      borderColor: "gray.100",
      padding: "2",
      cursor: "pointer",
    },

    "[data-reach-combobox-option]:hover": {
      backgroundColor: "gray.100",
    },

    "[data-suggested-value]": {
      fontWeight: "light",
    },

    ".disabled-bg": {
      background: `repeating-linear-gradient(
        45deg,
        #F7F9FC,
        #F7F9FC 10px,
        white 10px,
        white 20px
      )`,
    },

    ".StripeElement": {
      border: "1px solid",
      borderColor: "gray.300",
      paddingY: 1,
      paddingX: 2,
    },
    ".StripeElement--focus": {
      borderColor: "primary.500",
    },
    ".StripeElement--invalid": {
      borderColor: "red.500",
      boxShadow: "error",
    },

    ".chakra-tabs__tablist": {
      "@media screen and (max-width: 40em)": {
        button: {
          fontSize: "sm",
        },
      },
    },

    ".slick-list": {
      transitionDuration: "0.5s",
    },
    ".slick-prev, .slick-next": {
      cursor: "pointer",
      left: "0",
      position: "absolute",
      zIndex: 1,
      top: "-69px",
    },
    ".slick-prev": {
      left: "3px",
    },
    ".slick-next": {
      left: "auto",
      right: "3px",
    },
    ".slick-inner-arrow": {
      borderBottom: "3px solid",
      borderRight: "3px solid",
      boxSize: "1rem",
      position: "relative",
      transform: "rotate(-45deg)",
      color: "gray.300",
    },
    ".slick-prev .slick-inner-arrow": {
      right: "auto",
      transform: "rotate(135deg)",
    },
    ".slick-dots::-webkit-scrollbar, .previewThumbsnails::-webkit-scrollbar": {
      display: "none",
    },
    ".slick-dots": {
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      position: "absolute",
      top: "-108px",
    },
    ".slick-dots, .slick-dots ul": {
      display: "flex !important",
      flexShrink: "0",
      listStyleType: "none",
      w: "100%",
    },
    ".slick-dots li": {
      padding: 2,
    },
    ".slick-with-paging .slick-dots": {
      flex: "1",
      overflow: "auto",
      mx: "8",
      w: "calc(100% - 64px)",
      scrollSnapType: "x mandatory",
    },
    ".slick-with-paging .slick-dots ul": {
      flexGrow: 1,
      justifyContent: "center",
    },
    ".slick-with-paging .slick-dots li": {
      scrollSnapAlign: "start",
    },
    ".slick-with-paging .slick-dots li:not(.slick-active) button": {
      filter: "grayscale(100%)",
      opacity: 0.75,
    },
    ".slick-with-dots .slick-dots": {
      justifyContent: "center",
    },
    ".slick-with-dots .slick-dots li button": {
      outline: "none",
      color: "transparent",
      fontSize: 0,
    },
    ".slick-with-dots .slick-dots li button:before": {
      backgroundColor: "gray.300",
      height: 5,
      width: 5,
      display: "inline-block",
      borderRadius: "50%",
      content: "''",
    },
    ".slick-with-dots .slick-dots li.slick-active button:before": {
      backgroundColor: "primary.500",
    },
    "li.slick-active": {
      border: "1px solid",
      borderColor: "gray.300",
    },
    "li.slick-active > button": {
      outline: "none",
      boxShadow: "none",
    },
    ".leaflet-div-icon.custom-icon": {
      background: "transparent",
      border: 0,
    },
    ".leaflet-marker-icon.custom-icon": {
      width: "32px !important",
      height: "32px !important",
      marginLeft: "-16px !important",
      marginTop: "-16px !important",
    },
    ".leaflet-tooltip": {
      background: "gray.900",
      borderColor: "gray.900",
      borderRadius: "0",
      color: "gray.100",
      fontSize: "sm",
      width: "200px",
      whiteSpace: "normal",
    },
    ".leaflet-tooltip-left:before": {
      borderLeftColor: "gray.900",
    },
    ".leaflet-tooltip-right:before": {
      borderRightColor: "gray.900",
    },
    ".leaflet-tooltip-top:before": {
      borderTopColor: "gray.900",
    },
    ".leaflet-tooltip-bottom:before": {
      borderBottomColor: "gray.900",
    },
    ".leaflet-popup-content-wrapper": {
      borderRadius: "0",
      backgroundColor: "gray.50",
      border: "1px solid",
      borderColor: "gray.100",
      boxShadow: "md",
    },
    ".leaflet-popup-tip": {
      backgroundColor: "gray.100",
    },
    ".leaflet-popup-content": {
      margin: "0 !important",
    },
    ".marker-cluster-small, .marker-cluster-medium, .marker-cluster-large": {
      backgroundColor: "rgba(69, 155, 254, .35)",
    },
    ".marker-cluster-small div, .marker-cluster-medium div, .marker-cluster-large div":
      {
        backgroundColor: "rgba(69, 155, 254, .8)",
        color: "white",
        fontWeight: "bold",
      },
    ".react-transform-wrapper, .react-transform-component": {
      width: "100%",
    },

    // Ag Grid styles
    ".ag-root-wrapper": {
      border: "none !important",
    },
    ".ag-header": {
      backgroundColor: `transparent !important`,
      border: "none !important",
    },
    ".ag-header-container": {
      backgroundColor: `${colors.gray[100]}`,
    },
    ".ag-header-viewport": {
      justifyContent: "flex-start",
    },
    ".ag-header-container, .ag-row": {
      border: "1px solid #dde2eb !important",
    },
    ".ag-row-hover": {
      backgroundColor: "rgba(69, 155, 254, .1) !important",
    },
    ".ag-row": {
      borderTop: "none !important",
    },
    ".ag-cell, .ag-header-cell": {
      paddingX: "4px !important",
      fontSize: "xs",
      borderRight: "1px solid #dde2eb !important",
    },
    ".ag-cell-wrapper": {
      lineHeight: 1.5,
    },
    ".ag-cell": {
      justifyContent: "flex-start !important",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    ".ag-header-cell-resize": {
      right: "0 !important",
      width: "4px !important",
      ":after": {
        backgroundColor: `${colors.gray[600]} !important`,
        width: "4px !important",
      },
    },
    ".ag-header-cell-label, .ag-header-cell-text": {
      flexGrow: 1,
    },
    ".ag-header-center .ag-header-cell-text": {
      textAlign: "center",
    },
    ".ag-spanned-row": {
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
    },
    ".ag-large-text-input": {
      padding: "0px !important",
    },
    ".ag-text-area-input": {
      paddingTop: "10px",
    },
    ".ag-picker-field-display": {
      height: "100%",
      padding: "4px 0",
    },
    ".ag-input-field-input, .ag-text-field-input": {
      paddingLeft: "4px !important",
    },
    ".ag-cell-focus input, .ag-cell-focus textarea": {
      borderRadius: "0 !important",
      _focus: {
        outline: "0 !important",
        border: "1px solid !important",
        borderColor: "#ffbd29 !important",
      },
    },
    ".ag-header-icon .ag-icon-asc::before": {
      content: "''",
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: `8px solid ${colors.gray[700]}`,
      position: "relative",
      top: "-12px",
      right: "4px",
    },
    ".ag-header-icon .ag-icon-desc::before": {
      content: "''",
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderTop: `8px solid ${colors.gray[700]}`,
      position: "relative",
      top: "12px",
      right: "4px",
    },
    ".spreadsheet-header": {
      fontFamily: "Sofia Pro,sans-serif",
      textTransform: "capitalize",
    },
    ".spreadsheet-header.hidden": {
      display: "none",
    },
  },
};

export default styles;
