import { makeVar } from "@apollo/client";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";

import { WorkOrderCreateRedirectData } from "../models/createWORedirectData";

export const toastVar = makeVar<Toast[]>([]);
export const startDateValid = makeVar<boolean>(true);
export const endDateValid = makeVar<boolean>(true);
export const companyChanged = makeVar<{ changed: boolean }>({ changed: false });
export const createWORedirectData = makeVar<WorkOrderCreateRedirectData | null>(
  null
);
