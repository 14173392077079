import { Icon, IconProps } from "@chakra-ui/react";

const Other = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M113.092 97.034 71.91 22.51A8.745 8.745 0 0 0 64.261 18h-.522a8.743 8.743 0 0 0-7.65 4.513L14.91 97.034a8.752 8.752 0 0 0-1.091 4.227c0 4.819 3.92 8.739 8.74 8.739h82.884c4.819 0 8.74-3.92 8.74-8.739a8.76 8.76 0 0 0-1.09-4.227Zm-7.649 8.784H22.557a4.563 4.563 0 0 1-3.99-6.763L59.75 24.536a4.564 4.564 0 0 1 3.99-2.354h.523c1.658 0 3.185.902 3.99 2.352l41.18 74.522c.372.674.568 1.437.568 2.205a4.563 4.563 0 0 1-4.557 4.557Z"
      fill="currentColor"
    />
    <path
      d="M77.035 68.886H68.61l6.427-16.395c.23-.584.16-1.248-.185-1.77a1.853 1.853 0 0 0-1.542-.835H60.275c-.786 0-1.488.505-1.753 1.262L49.21 77.747a1.94 1.94 0 0 0 .229 1.733c.349.504.916.805 1.524.805h8.423L52.96 96.679a1.92 1.92 0 0 0 .764 2.33c.298.186.633.275.964.275.527 0 1.048-.23 1.415-.664L78.45 72.02a1.927 1.927 0 0 0 .276-2.03 1.858 1.858 0 0 0-1.691-1.105Zm-16.44 18.548 3.27-8.344c.229-.584.16-1.248-.186-1.77a1.853 1.853 0 0 0-1.542-.835h-8.53l7.98-22.799h8.974l-6.427 16.395c-.23.584-.16 1.248.185 1.77.346.521.924.835 1.542.835h7.126L60.595 87.434Z"
      fill="currentColor"
    />
  </Icon>
);

export default Other;
