import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import AssetIcon from "../../../components/elements/assetIcons";
import Autosuggest from "../../../components/elements/autosuggest";
import CheckBoxInput from "../../../components/elements/checkbox";
import FileSelectInput from "../../../components/elements/fileSelectInput";
import FormGroup from "../../../components/elements/formGroup";
import MultiSelect from "../../../components/elements/multiSelect";
import {
  FLOORPLAN_FILE_UPDATED_MESSAGE,
  GENERIC_SAVED_MESSAGE,
} from "../../../constants/lang/en";
import {
  AssetCategoryFragmentFragment,
  FloorPlanWithRelationsFragmentFragment,
  useFloorPlanTypesQuery,
  useFloorPlanUpdateMutation,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  assetCategoryIdsSchema,
  createdBySchema,
  createdDateSchema,
  descriptionSchema,
  fileIdsSchema,
  fileIdsUploadingSchema,
  nameSchema,
  typeSchema,
  updatePositionScheme,
  yupObject,
} from "../../../utils/validation";

interface FloorPlanEditPresenterProps {
  floorPlan: FloorPlanWithRelationsFragmentFragment;
  assetCategories: AssetCategoryFragmentFragment[];
}

const FloorPlanEditPresenter: React.FC<FloorPlanEditPresenterProps> = ({
  floorPlan,
  assetCategories,
}) => {
  const { locationId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [floorPlanUpdateMutation, { loading }] = useFloorPlanUpdateMutation();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { data } = useFloorPlanTypesQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const onSubmit = async (
    data: FloorPlanFormData,
    { setFieldError }: FormikHelpers<FloorPlanFormData>
  ) => {
    const fileId =
      data.fileIds && data.fileIds.length > 0 ? data.fileIds[0] : undefined;
    try {
      const { data: serverData, errors } = await floorPlanUpdateMutation({
        variables: {
          id: floorPlan.id,
          data: {
            ...data,
            locationId: locationId
              ? (locationId as string)
              : floorPlan.locationId,
            fileId,
            fileIds: undefined,
            fileIdsUploading: undefined,
            updateAssetLocationsAndAffectedAreas: undefined,
          } as any,
          updateAssetLocationsAndAffectedAreas:
            data.updateAssetLocationsAndAffectedAreas,
        },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: fileId
            ? FLOORPLAN_FILE_UPDATED_MESSAGE
            : GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  const assetCategoryItems = React.useMemo(
    () =>
      assetCategories.map((assetCategory) => ({
        value: assetCategory.id,
        label: (
          <Flex alignItems="center">
            <AssetIcon
              iconName={assetCategory.iconName}
              iconColor={assetCategory.iconColor}
              iconType={assetCategory.iconType}
              iconSize="xs"
            />
            <Box marginLeft="1">{assetCategory.name}</Box>
          </Flex>
        ),
        query: assetCategory.name,
      })),
    [assetCategories]
  );

  return (
    <>
      <Helmet>
        <title>Edit {floorPlan.name}</title>
      </Helmet>
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit {floorPlan.name}</DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{
                  name: floorPlan.name,
                  description: floorPlan.description || "",
                  type: floorPlan.type || "",
                  createdBy: floorPlan.createdBy || "",
                  createdDate: floorPlan.createdDate || "",
                  assetCategoryIds:
                    floorPlan.floorPlanAssetCategories.map(
                      (fpac) => fpac.assetCategory.id
                    ) || ([] as string[]),
                  fileIds: [],
                  fileIdsUploading: false,
                  updateAssetLocationsAndAffectedAreas: false,
                }}
                validationSchema={planFormValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ getFieldProps }) => (
                  <Form id="floor_plan_edit" noValidate>
                    <FormGroup label="Name" name="name">
                      <Input
                        autoFocus
                        autoComplete="off"
                        {...getFieldProps("name")}
                      />
                    </FormGroup>
                    <FormGroup label="Type" name="type">
                      <Autosuggest
                        items={data?.floorPlanTypes || []}
                        autoComplete="off"
                        name="type"
                      />
                    </FormGroup>
                    <MultiSelect
                      sortOptions
                      items={assetCategoryItems}
                      autoComplete="off"
                      name="assetCategoryIds"
                      label="Categories"
                    />
                    <FormGroup label="Description" name="description">
                      <Textarea
                        autoComplete="off"
                        {...getFieldProps("description")}
                      />
                    </FormGroup>
                    <FileSelectInput
                      name="fileIds"
                      mapUpload
                      allowMultipleUpload={false}
                    />
                    <CheckBoxInput
                      name="updateAssetLocationsAndAffectedAreas"
                      label={
                        <Flex alignItems="center">
                          <Box marginRight="2">
                            Update the position of assets and affected areas
                          </Box>
                          <Tooltip
                            label="Check this option if you want to update the position of all assets and affected areas in this plan automatically"
                            hasArrow
                          >
                            <Box color="gray.600">
                              <FontAwesomeIcon icon={faQuestionCircle} />
                            </Box>
                          </Tooltip>
                        </Flex>
                      }
                    />
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button
                width="full"
                type="submit"
                isLoading={loading}
                form="floor_plan_edit"
              >
                Save
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default FloorPlanEditPresenter;

gql`
  mutation FloorPlanUpdate(
    $id: UUID!
    $data: floorPlanInput!
    $updateAssetLocationsAndAffectedAreas: Boolean
  ) {
    floorPlanUpdate(
      id: $id
      data: $data
      updateAssetLocationsAndAffectedAreas: $updateAssetLocationsAndAffectedAreas
    ) {
      ...FloorPlanWithRelationsFragment
    }
  }

  query FloorPlanTypes {
    floorPlanTypes
  }
`;

const planFormValidationSchema = yupObject().shape({
  name: nameSchema.label("Plan name"),
  description: descriptionSchema,
  fileIds: fileIdsSchema.notRequired(),
  fileIdsUploading: fileIdsUploadingSchema,
  type: typeSchema.optional(),
  createdBy: createdBySchema,
  createdDate: createdDateSchema,
  assetCategoryIds: assetCategoryIdsSchema,
  updateAssetLocationsAndAffectedAreas: updatePositionScheme,
});

type FloorPlanFormData = {
  name: string;
  description: string;
  fileIds: string[];
  fileIdsUploading: boolean;
  type: string;
  createdBy: string;
  createdDate: string;
  assetCategoryIds: string[];
  updateAssetLocationsAndAffectedAreas: boolean;
};
