import { Box } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  useStripeBankAccountsQuery,
  useStripeCustomerQuery,
  useStripePaymentMethodsQuery,
} from "../../../graphql/graphql";
import Presenter from "./presenter";

interface CompanyPaymentMethodsProps {}

const CompanyPaymentMethods: React.FC<CompanyPaymentMethodsProps> = () => {
  const { data: paymentMethodsData } = useStripePaymentMethodsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: bankAccountsData } = useStripeBankAccountsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: customerData } = useStripeCustomerQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return paymentMethodsData && bankAccountsData && customerData ? (
    <Box marginTop="-10">
      <Helmet>
        <title>Payment Method</title>
      </Helmet>
      <Presenter
        cards={paymentMethodsData.stripePaymentMethods}
        bankAccounts={bankAccountsData.stripeBankAccounts}
        customer={customerData.stripeCustomer}
      />
    </Box>
  ) : (
    <PageSpinner />
  );
};

export default CompanyPaymentMethods;

gql`
  query StripeCustomer {
    stripeCustomer {
      ...StripeCustomerFragment
    }
  }
`;
