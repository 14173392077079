import { QueryHookOptions } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  LocationFragmentFragment,
  LocationIndexQuery,
  LocationIndexQueryVariables,
  useLocationIndexQuery,
} from "../graphql/graphql";

const useTopLocationsTree: (
  baseOptions?: QueryHookOptions<
    LocationIndexQuery,
    LocationIndexQueryVariables
  >
) => [LocationWithChildren[] | undefined, () => void, boolean] = (
  baseOptions
) => {
  const { data, refetch, loading } = useLocationIndexQuery({
    ...baseOptions,
    variables: { parentId: undefined },
  });
  const [locationsAndSubLocations, setLocationsAndSubLocations] =
    useState<LocationWithChildren[]>();

  useEffect(() => {
    if (!data) return;

    const findSubLocations = (
      location: LocationFragmentFragment
    ): LocationWithChildren =>
      Object.assign({}, location, {
        children: data.locations
          .filter((l) => l.parentId === location.id)
          .map((sl) => findSubLocations(sl)),
      });

    const locations = data.locations
      .filter((location) => !location.parentId)
      .map((location) => findSubLocations(location));
    setLocationsAndSubLocations(locations);
  }, [data]);

  return [locationsAndSubLocations, refetch, loading];
};

export default useTopLocationsTree;

export interface LocationWithChildren extends LocationFragmentFragment {
  children: LocationWithChildren[];
}
