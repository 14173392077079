import { SortBy, SortOrder } from "../graphql/graphql";

export const sortCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

export const sortByOptions = [
  {
    label: "A-Z",
    value: `${SortBy.Name}-${SortOrder.Asc}`,
  },
  {
    label: "Z-A",
    value: `${SortBy.Name}-${SortOrder.Desc}`,
  },
  {
    label: "Newest",
    value: `${SortBy.CreatedAt}-${SortOrder.Desc}`,
  },
  {
    label: "Oldest",
    value: `${SortBy.CreatedAt}-${SortOrder.Asc}`,
  },
];

interface SortByAttrs {
  name: string;
  createdAt: any;
}

function orderBy<T extends SortByAttrs>(
  items: T[],
  sortBy: SortBy,
  sortOrder: SortOrder,
  deepSortLabel?: string
): T[] {
  if (!items) return [];
  let sortedItems = items
    .slice()
    .sort((a, b) => sortCollator.compare(a[sortBy], b[sortBy]));
  sortedItems =
    sortOrder === SortOrder.Desc ? sortedItems.reverse() : sortedItems;

  if (!deepSortLabel) return sortedItems;

  return sortedItems.map((item) => {
    const deepItems: T[] = (item as any)[deepSortLabel];
    if (!deepItems || deepItems.length === 0) return item;

    let sortedDeepItems = deepItems
      .slice()
      .sort((a, b) => sortCollator.compare(a[sortBy], b[sortBy]));
    sortedDeepItems =
      sortOrder === SortOrder.Desc
        ? sortedDeepItems.reverse()
        : sortedDeepItems;
    return { ...item, [deepSortLabel]: sortedDeepItems };
  });
}

export default orderBy;
