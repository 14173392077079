import React, { useCallback } from "react";

import FilterSelect from "../../../components/elements/filterSelect";

interface MaintenanceCompletedFilterProps {
  onChange: (ids: boolean | undefined) => void;
  value: boolean | undefined;
}

const MaintenanceCompletedFilter: React.FC<MaintenanceCompletedFilterProps> = ({
  onChange,
  value,
}) => {
  const handleChange = useCallback(
    (id: string) => {
      switch (id) {
        case "completed":
          onChange(true);
          break;
        case "notCompleted":
          onChange(false);
          break;
        default:
          onChange(undefined);
      }
    },
    [onChange]
  );

  return (
    <FilterSelect
      label="Completed/Open"
      selectedOptions={
        value === false ? ["notCompleted"] : value ? ["completed"] : undefined
      }
      allOptions={MaintenanceCompletedFilterOptions}
      handleFilterChange={handleChange}
    />
  );
};

const MaintenanceCompletedFilterOptions = [
  {
    label: "Show Completed",
    value: "completed",
    query: "Show Completed",
  },
  {
    label: "Show Open",
    value: "notCompleted",
    query: "Show Open",
  },
];

export default MaintenanceCompletedFilter;
