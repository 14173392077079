import { Box, Flex, Tooltip } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddPaymentMethod from "../../../components/elements/addPaymentMethod";
import BankAccountPaymentMethod from "../../../components/elements/bankAccountPaymentMethod";
import CreditCardPaymentMethod from "../../../components/elements/creditCardPaymentMethod";
import Link from "../../../components/elements/link";
import AddCircle from "../../../components/icons/addCircle";
import {
  StripeBankAccountFragmentFragment,
  StripeCustomerFragmentFragment,
  StripePaymentMethodTypeFragmentFragment,
} from "../../../graphql/graphql";
import { isNativeWebViewVar } from "../../../graphql/reactiveVariables";
import usePaymentsAvailable from "../../../hooks/usePaymentsAvailable";
import { getRoutePath } from "../../../router";

interface CompanyPaymentMethodsPresenterProps {
  cards: StripePaymentMethodTypeFragmentFragment[];
  bankAccounts: StripeBankAccountFragmentFragment[];
  customer: StripeCustomerFragmentFragment;
}

const CompanyPaymentMethodsPresenter: React.FC<
  CompanyPaymentMethodsPresenterProps
> = ({ cards, bankAccounts, customer }) => {
  const [isNative] = useState(isNativeWebViewVar());
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentsAvailable = usePaymentsAvailable();

  const handlePaymentMethodAdded = useCallback(() => {
    if (searchParams.has("redirectPath"))
      navigate(searchParams.get("redirectPath") as string);
  }, [navigate, searchParams]);

  const defaultSource =
    customer.invoice_settings?.default_payment_method ||
    customer.default_source;

  const bankAccountsHasDefault = bankAccounts.find(
    (bankAccount) => bankAccount.id === defaultSource
  );

  if (bankAccountsHasDefault) {
    bankAccounts = bankAccounts
      .slice()
      .sort((a, b) =>
        defaultSource === a.id ? -1 : defaultSource === b.id ? 1 : 0
      );
  } else {
    cards = cards
      .slice()
      .sort((a, b) =>
        defaultSource === a.id ? -1 : defaultSource === b.id ? 1 : 0
      );
  }

  return (
    <Box marginTop="8">
      {!paymentsAvailable ? (
        <AddPaymentMethod
          paymentMethodAdded={handlePaymentMethodAdded}
          attemptToChargeOpenInvoice
        />
      ) : (
        <Box>
          {!isNative && (
            <Flex
              justifyContent="flex-end"
              marginBottom={{ base: "4", sm: "8" }}
              marginTop={{ sm: "-100px" }}
            >
              <Tooltip label="Add new payment method" hasArrow placement="left">
                <Link
                  to={`${getRoutePath("companyPaymentMethodsCreate")}${
                    searchParams.has("redirectPath")
                      ? `?redirectPath=${
                          searchParams.get("redirectPath") as string
                        }`
                      : ""
                  }`}
                  aria-label="Add new payment method"
                  variant="icon"
                  colorScheme="secondary"
                >
                  <AddCircle boxSize="32px" />
                </Link>
              </Tooltip>
            </Flex>
          )}
          {bankAccountsHasDefault ? (
            <>
              {bankAccounts.map((bankAccount) => (
                <BankAccountPaymentMethod
                  key={bankAccount.id}
                  bankAccount={bankAccount}
                  defaultSource={defaultSource}
                />
              ))}
              {cards.map((card) => (
                <CreditCardPaymentMethod
                  key={card.id}
                  card={card}
                  defaultSource={defaultSource}
                />
              ))}
            </>
          ) : (
            <>
              {cards.map((card) => (
                <CreditCardPaymentMethod
                  key={card.id}
                  card={card}
                  defaultSource={defaultSource}
                />
              ))}
              {bankAccounts.map((bankAccount) => (
                <BankAccountPaymentMethod
                  key={bankAccount.id}
                  bankAccount={bankAccount}
                  defaultSource={defaultSource}
                />
              ))}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CompanyPaymentMethodsPresenter;
