import { useReactiveVar } from "@apollo/client";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import Calendly from "../../components/elements/calendly";
import Footer from "../../components/elements/footer";
import Link from "../../components/elements/link";
import PageSpinner from "../../components/elements/pageSpinner";
import Call from "../../components/icons/call";
import CallHover from "../../components/icons/callHover";
import Feedback from "../../components/icons/feedback";
import FeedbackHover from "../../components/icons/feedbackHover";
import SearchArticles from "../../components/icons/searchArticles";
import SearchArticlesHover from "../../components/icons/searchArticlesHover";
import SubmitTicket from "../../components/icons/submitTicket";
import SubmitTicketHover from "../../components/icons/submitTicketHover";
import SupportAgent from "../../components/icons/supportAgent";
import SupportAgentHover from "../../components/icons/supportAgentHover";
import Tickets from "../../components/icons/tickets";
import TicketsHover from "../../components/icons/ticketsHover";
import { useCompanyQuery } from "../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../graphql/reactiveVariables";
import useBoxSize from "../../hooks/useBoxSize";
import useZendesk, { ZendeskSettings } from "../../hooks/useZendesk";
import ROLES from "../../roles";
import { getRoutePath } from "../../router";

interface HelpAndSupportPageProps {}

const HelpAndSupportPage: React.FC<HelpAndSupportPageProps> = () => {
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data } = useCompanyQuery();
  const { containerRef, height } = useBoxSize();
  const { isOpen, onClose, onOpen } = useDisclosure();
  useZendesk();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    layoutOptionsVar({
      title: "Help & Support",
      hideSearch: true,
    });
  }, []);

  if (!data) return <PageSpinner />;

  const boxProps = {
    variant: "unstyled",
    display: "flex",
    flexDir: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "full",
    p: { base: 4, md: 8 },
    height: "auto",
    bgColor: "gray.50",
    _hover: { boxShadow: "lg" },
    textAlign: "left",
    fontSize: "md",
    whiteSpace: "normal",
    fontWeight: "400",
    role: "group",
    sx: {
      ".hover-icon": {
        display: "none",
      },
      ".non-hover-icon": {
        display: "block",
      },
      ":hover": {
        ".hover-icon": {
          display: "block",
        },
        ".non-hover-icon": {
          display: "none",
        },
      },
    },
  } as any;

  const iconProps = {
    color: "gray.800",
    _groupHover: { color: "primary.500" },
  };

  const textProps = {
    mt: "3",
    fontSize: "15px",
  } as any;

  return (
    <>
      <Helmet>
        <title>Help & Support</title>
        <script type="text/javascript">{ZendeskSettings}</script>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=96e43f2e-1aaa-4367-8c27-5f3600ddafa8"
        ></script>
      </Helmet>
      <Box ref={containerRef}>
        <Flex height={height} direction="column">
          {ROLES.onboarding.includes(currentCompanyRole.role) &&
            data.company.config.onboarding &&
            !data.company.config.onboarding.completed && (
              <Alert status="info" role="group" flexShrink="0" my="6">
                <AlertDescription w="full" py="1">
                  <Link
                    variant="link"
                    colorScheme="white"
                    width="full"
                    to={getRoutePath("onboarding")}
                    fontSize="lg"
                  >
                    Complete your setup
                  </Link>
                </AlertDescription>
              </Alert>
            )}
          <Box flexGrow="1">
            <Heading size="2xl" textAlign="center" mb="8" fontWeight="300">
              Looking for help? You came to the right place.
            </Heading>
            <Box maxW="1000px" mx="auto">
              <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={6} w="full">
                <Link
                  {...boxProps}
                  to="/zendesk-sign-in?return_to=https%3A%2F%2Fsupport.criticalasset.com"
                  target="_blank"
                >
                  <SearchArticles {...iconProps} className="non-hover-icon" />
                  <SearchArticlesHover {...iconProps} className="hover-icon" />
                  <Box {...textProps}>Search help articles</Box>
                </Link>
                <Link
                  {...boxProps}
                  to="/zendesk-sign-in?return_to=https%3A%2F%2Fsupport.criticalasset.com%2Fhc%2Fen-us%2Frequests%2Fnew"
                  target="_blank"
                >
                  <SubmitTicket {...iconProps} className="non-hover-icon" />
                  <SubmitTicketHover {...iconProps} className="hover-icon" />
                  <Box {...textProps}>Submit new support request</Box>
                </Link>
                <Link
                  {...boxProps}
                  to="/zendesk-sign-in?return_to=https%3A%2F%2Fsupport.criticalasset.com%2Fhc%2Fen-us%2Frequests"
                  target="_blank"
                >
                  <Tickets {...iconProps} className="non-hover-icon" />
                  <TicketsHover {...iconProps} className="hover-icon" />
                  <Box {...textProps}>View open support request tickets</Box>
                </Link>
                <Button {...boxProps} onClick={onOpen}>
                  <SupportAgent {...iconProps} className="non-hover-icon" />
                  <SupportAgentHover {...iconProps} className="hover-icon" />
                  <Box {...textProps}>
                    Book a call with our customer success team
                  </Box>
                </Button>
                <Button {...boxProps} as="a" href="tel:+18337441010">
                  <Call {...iconProps} className="non-hover-icon" />
                  <CallHover {...iconProps} className="hover-icon" />
                  <Flex {...textProps} flexWrap="wrap">
                    <Box mr="1" flexShrink="0" flexGrow="0">
                      Call us at
                    </Box>
                    <Box whiteSpace="nowrap" flexShrink="0" flexGrow="0">
                      (833) 744-1010
                    </Box>
                  </Flex>
                </Button>
                <Button
                  {...boxProps}
                  onClick={() => setSearchParams({ CaAprType: "contactForm" })}
                >
                  <Feedback {...iconProps} className="non-hover-icon" />
                  <FeedbackHover {...iconProps} className="hover-icon" />
                  <Box {...textProps}>Send feedback</Box>
                </Button>
              </SimpleGrid>
            </Box>
          </Box>
          <Box mt="6" flexShrink="0">
            <Footer showSystemStatus />
          </Box>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay>
          <ModalContent margin="calc(var(--vh, 1vh)*2)">
            <ModalHeader>
              Book a Call with our Customer Success Team
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody padding="0">{isOpen && <Calendly />}</ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default HelpAndSupportPage;
