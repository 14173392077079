import { Icon, IconProps } from "@chakra-ui/react";

const CarbonDioxideSensor = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <circle
      cx={56}
      cy={72}
      r={42}
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
    <path
      d="M90.03 25c-2.705.107-2.708 3.97 0 4.079 4.901 0 8.888 3.984 8.888 8.882a2.04 2.04 0 0 0 4.082 0C103 30.814 97.182 25 90.03 25Z"
      fill="currentColor"
    />
    <path
      d="M90.052 16c-2.733.108-2.74 4.013 0 4.124 9.827 0 17.822 7.991 17.822 17.814a2.063 2.063 0 0 0 4.126 0C112 25.841 102.154 16 90.052 16ZM44.642 75.472l3.232 2.368c-.981 1.387-2.25 2.485-3.808 3.296-1.557.79-3.274 1.184-5.152 1.184-3.114 0-5.749-1.099-7.904-3.296-2.133-2.219-3.2-4.885-3.2-8 0-3.157 1.067-5.835 3.2-8.032 2.155-2.197 4.79-3.296 7.904-3.296 1.835 0 3.52.395 5.056 1.184 1.558.768 2.827 1.824 3.808 3.168l-3.264 2.336c-1.45-1.877-3.317-2.816-5.6-2.816-1.984 0-3.648.725-4.992 2.176-1.344 1.45-2.016 3.21-2.016 5.28 0 2.07.672 3.83 2.016 5.28 1.344 1.43 3.008 2.144 4.992 2.144 1.152 0 2.23-.267 3.232-.8a7.21 7.21 0 0 0 2.496-2.176Zm11.468-9.6c-1.365 1.408-2.048 3.125-2.048 5.152s.683 3.744 2.048 5.152c1.386 1.408 3.072 2.112 5.056 2.112s3.659-.704 5.024-2.112c1.386-1.408 2.08-3.125 2.08-5.152s-.694-3.744-2.08-5.152c-1.365-1.43-3.04-2.144-5.024-2.144s-3.67.715-5.056 2.144Zm-6.112 5.152c0-3.136 1.077-5.803 3.232-8 2.155-2.219 4.8-3.328 7.936-3.328s5.781 1.11 7.936 3.328c2.176 2.197 3.264 4.864 3.264 8 0 3.115-1.088 5.781-3.264 8-2.176 2.197-4.822 3.296-7.936 3.296-3.115 0-5.76-1.099-7.936-3.296-2.155-2.219-3.232-4.885-3.232-8ZM83.238 82h-8.36v-1.26l5.34-6.4c.533-.627.8-1.2.8-1.72 0-.44-.18-.807-.54-1.1-.347-.307-.794-.46-1.34-.46-.534 0-.987.18-1.36.54-.374.347-.6.8-.68 1.36l-2.28-.42c.186-1.187.68-2.127 1.48-2.82.8-.693 1.78-1.04 2.94-1.04 1.173 0 2.166.373 2.98 1.12.826.733 1.24 1.673 1.24 2.82 0 1.173-.467 2.287-1.4 3.34l-3.24 3.7h4.42V82Z"
      fill="currentColor"
    />
  </Icon>
);

export default CarbonDioxideSensor;
