import { Checkbox, CheckboxProps } from "@chakra-ui/react";
import { FC } from "react";

interface IndeterminateCheckboxProps extends CheckboxProps {
  indeterminate?: boolean;
  checked?: boolean;
}

const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = ({
  indeterminate,
  checked,
  ...rest
}) => (
  <Checkbox isIndeterminate={indeterminate} isChecked={checked} {...rest} />
);

export default IndeterminateCheckbox;
