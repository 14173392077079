import React, { useCallback, useEffect, useRef } from "react";

import {
  useWorkOrderFileCreateMutation,
  useWorkOrderFileDeleteMutation,
} from "../../../../graphql/mutations/workOrderFile";
import {
  WorkOrder,
  WorkOrderAsset,
  WorkOrderFile,
} from "../../models/workOrder";
import WorkOrderEditGeneralPresenter from "./presenter";

export type WorkOrderEditGeneralProps = {
  workOrder: WorkOrder;
  reloadWorkOrder: () => void;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
};

const WorkOrderEditGeneral: React.FC<WorkOrderEditGeneralProps> = ({
  workOrder,
  reloadWorkOrder,
  changeIsEdit,
  onWorkOrderChange,
}: WorkOrderEditGeneralProps) => {
  const [workOrderFileCreateMutation] = useWorkOrderFileCreateMutation();
  const [workOrderFileDeleteMutation] = useWorkOrderFileDeleteMutation();
  const someAssets = useRef<WorkOrderAsset[]>();

  const onFilesAdded = async (files: File[]) => {
    const { data, errors } = await workOrderFileCreateMutation({
      variables: {
        files,
        workOrderId: workOrder.id,
      },
    });
    reloadWorkOrder();
  };
  const onFileDelete = async (deletedFile: WorkOrderFile) => {
    const { data, errors } = await workOrderFileDeleteMutation({
      variables: {
        workOrderFileId: deletedFile.id,
      },
    });
    reloadWorkOrder();
  };

  const handleAssetDelete = (deletedAsset: WorkOrderAsset) => {
    const filteredAssets = workOrder.assetWorkOrders?.filter(
      (asset) => asset.assetId !== deletedAsset.assetId
    );
    onWorkOrderChange({
      assetWorkOrders: filteredAssets,
    });
  };

  const handleAddNewAsset = useCallback(
    (asset: WorkOrderAsset) => {
      const newAssets = someAssets.current?.length
        ? [...someAssets.current, asset]
        : [asset];
      onWorkOrderChange({
        assetWorkOrders: newAssets,
      });
    },
    [someAssets.current, workOrder]
  );

  useEffect(() => {
    someAssets.current = workOrder?.assetWorkOrders || [];
  }, [workOrder]);

  return (
    <>
      <WorkOrderEditGeneralPresenter
        workOrder={workOrder}
        onWorkOrderChange={onWorkOrderChange}
        onFilesAdded={onFilesAdded}
        onFileDelete={onFileDelete}
        onAssetDelete={handleAssetDelete}
        onAssetAdd={handleAddNewAsset}
        changeIsEdit={changeIsEdit}
      />
    </>
  );
};
export default WorkOrderEditGeneral;
