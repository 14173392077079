import { Icon, IconProps } from "@chakra-ui/react";

const Projector = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M104.253 68.574h-6.505a19.096 19.096 0 0 0-34.562 0h-39.44a10.073 10.073 0 0 0-10.063 10.064v18.297a10.071 10.071 0 0 0 10.064 10.063h80.506a10.073 10.073 0 0 0 10.064-10.063V78.638a10.074 10.074 0 0 0-10.064-10.064Zm-23.786-5.481a13.722 13.722 0 1 1-13.722 13.722 13.74 13.74 0 0 1 13.722-13.722Zm28.361 33.842a4.581 4.581 0 0 1-4.575 4.574H23.747a4.576 4.576 0 0 1-4.575-4.574V78.638a4.578 4.578 0 0 1 4.575-4.575h37.787c-.16.91-.253 1.83-.279 2.752a19.212 19.212 0 1 0 38.424 0 18.959 18.959 0 0 0-.278-2.752h4.852a4.58 4.58 0 0 1 4.575 4.575v18.297ZM64.94 32.906a21.996 21.996 0 0 1 31.055 0 2.744 2.744 0 0 0 3.879-3.881 27.482 27.482 0 0 0-38.817 0 2.745 2.745 0 0 0 3.879 3.88h.004Z"
      fill="currentColor"
    />
    <path
      d="M90.17 41.472a2.745 2.745 0 0 0 1.94-4.684 16.487 16.487 0 0 0-23.286 0 2.744 2.744 0 1 0 3.881 3.88 11 11 0 0 1 15.524 0 2.733 2.733 0 0 0 1.94.804ZM80.467 85.049a8.234 8.234 0 1 0-8.233-8.234 8.245 8.245 0 0 0 8.233 8.234Zm0-10.978a2.745 2.745 0 1 1 0 5.489 2.745 2.745 0 0 1 0-5.49ZM38.384 79.552H27.406a2.744 2.744 0 1 0 0 5.49h10.978a2.745 2.745 0 0 0 0-5.49ZM80.482 53.933a5.489 5.489 0 1 0 0-10.978 5.489 5.489 0 0 0 0 10.978Z"
      fill="currentColor"
    />
  </Icon>
);

export default Projector;
