import { Icon, IconProps } from "@chakra-ui/react";

const CoolingTower = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M70.14 51.72H29.208a2.047 2.047 0 0 1-2.047-2.046v-8.186c0-1.13.917-2.046 2.047-2.046h40.93c1.13 0 2.047.917 2.047 2.046v8.186c0 1.13-.917 2.047-2.047 2.047Zm-38.885-4.092h36.838v-4.093H31.255v4.093Z"
      fill="currentColor"
    />
    <path
      d="M82.418 113.116H16.93c-.741 0-1.425-.401-1.789-1.048a2.04 2.04 0 0 1 .045-2.071c.16-.262 16.07-26.555 16.07-60.323a2.047 2.047 0 0 1 4.092 0c0 28.484-10.613 51.274-14.915 59.349h58.482C74.613 100.948 64 78.158 64 49.674a2.047 2.047 0 0 1 4.093 0c0 33.821 15.91 60.061 16.069 60.323a2.05 2.05 0 0 1 .045 2.071 2.05 2.05 0 0 1-1.789 1.048Z"
      fill="currentColor"
    />
    <path
      d="M111.069 113.116h-28.65a2.047 2.047 0 0 1 0-4.093h26.604V64H78.325a2.047 2.047 0 0 1 0-4.093h32.744c1.13 0 2.047.917 2.047 2.047v49.116a2.047 2.047 0 0 1-2.047 2.046ZM45.581 80.372h-15.84a2.047 2.047 0 0 1 0-4.093h15.84a2.047 2.047 0 0 1 0 4.093ZM45.581 92.651H26.127a2.047 2.047 0 0 1 0-4.093H45.58a2.047 2.047 0 0 1 0 4.093Z"
      fill="currentColor"
    />
    <path
      d="M98.79 76.279H86.511a2.047 2.047 0 0 1 0-4.093h12.28a2.047 2.047 0 0 1 0 4.093ZM98.79 88.558H86.511a2.047 2.047 0 0 1 0-4.093h12.28a2.047 2.047 0 0 1 0 4.093ZM98.79 100.837H86.511a2.047 2.047 0 0 1 0-4.093h12.28a2.047 2.047 0 0 1 0 4.093ZM88.558 39.442c-3.524 0-6.852-1.547-9.156-4.163-4.093 3.156-10.503 2.489-13.86-1.375-3.073 2.116-7.571 1.744-10.26-.819-3.832 1.09-7.654-2.026-7.654-5.922 0-3.897 3.818-7.032 7.654-5.923 2.689-2.562 7.187-2.935 10.26-.818 3.357-3.868 9.767-4.536 13.86-1.376 2.304-2.615 5.632-4.162 9.156-4.162 6.77 0 12.279 5.509 12.279 12.279s-5.51 12.279-12.28 12.279Zm-8.89-9.349a2.055 2.055 0 0 1 1.785 1.044c1.46 2.595 4.182 4.212 7.105 4.212 4.514 0 8.186-3.672 8.186-8.186 0-4.515-3.672-8.186-8.186-8.186-2.923 0-5.645 1.616-7.106 4.211a2.046 2.046 0 0 1-3.405.25c-1.183-1.535-2.947-2.415-4.838-2.415-2.337 0-4.437 1.322-5.485 3.45a2.043 2.043 0 0 1-1.662 1.134 2.073 2.073 0 0 1-1.83-.835c-1.595-2.202-5.189-2.128-6.691.099a2.045 2.045 0 0 1-2.755.597c-.4-.241-.724-.352-1.019-.352a2.05 2.05 0 0 0-2.047 2.047 2.05 2.05 0 0 0 2.047 2.046c.295 0 .618-.11 1.02-.356a2.051 2.051 0 0 1 2.754.598c1.502 2.222 5.092 2.3 6.692.098a2.067 2.067 0 0 1 1.83-.835 2.043 2.043 0 0 1 1.661 1.134c1.048 2.128 3.148 3.45 5.485 3.45 1.89 0 3.655-.88 4.838-2.415.389-.5.99-.79 1.62-.79Z"
      fill="currentColor"
    />
  </Icon>
);

export default CoolingTower;
