import { useToast } from "@chakra-ui/react";
import gql from "graphql-tag";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import { GENERIC_ERROR_MESSAGE } from "../../constants/lang/en";
import { useZendeskSignInMutation } from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import handleException from "../../utils/error";
import { setGenericMessage } from "../../utils/serverErrors";

interface ZendeskSignInPageProps {}

const ZendeskSignInPage: React.FC<ZendeskSignInPageProps> = () => {
  const [zendeskSignInMutation] = useZendeskSignInMutation();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  useEffect(() => {
    layoutOptionsVar({ title: "" });
  }, []);

  useEffect(() => {
    const getZendeskToken = async () => {
      try {
        const { data, errors } = await zendeskSignInMutation();
        if (data) {
          window.location.replace(
            `${process.env.REACT_APP_ZENDESK_SUB_DOMAIN}/access/jwt?jwt=${
              data.zendeskSignIn.jwtToken
            }${
              searchParams.has("return_to")
                ? `&return_to=${searchParams.get("return_to")}`
                : ""
            }`
          );
        } else {
          toast({
            description: errors
              ? setGenericMessage(errors)
              : GENERIC_ERROR_MESSAGE,
            status: "error",
            position: "top",
            isClosable: true,
          });
        }
      } catch (error: any) {
        handleException(error);
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    };
    getZendeskToken();
  }, [searchParams, toast, zendeskSignInMutation]);

  return (
    <>
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <PageSpinner />
    </>
  );
};

export default ZendeskSignInPage;

gql`
  mutation zendeskSignIn {
    zendeskSignIn {
      jwtToken
    }
  }
`;
