const TOKEN = "jwt";

export const setJwt = (jwt: string): boolean => {
  if (!jwt) return false;
  localStorage.setItem(TOKEN, jwt);
  return true;
};

export const getJwt = () => localStorage.getItem(TOKEN);

export const removeJwt = () => localStorage.removeItem(TOKEN);
