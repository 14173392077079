import React from "react";
import { useParams } from "react-router-dom";

import { useFloorPlanQuery } from "../../../graphql/graphql";
import useAssetCategories from "../../../hooks/useAssetCategories";
import Presenter from "./presenter";

interface FloorPlanEditPageProps {}

const FloorPlanEditPage: React.FC<FloorPlanEditPageProps> = () => {
  const { planId } = useParams();
  const { data } = useFloorPlanQuery({
    variables: { id: planId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: assetCategoriesData } = useAssetCategories({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return data && assetCategoriesData ? (
    <Presenter
      floorPlan={data?.floorPlan}
      assetCategories={assetCategoriesData.assetCategories}
    />
  ) : null;
};

export default FloorPlanEditPage;
