import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TooltipName from "../../../components/elements/tooltipName";
import {
  LocationFragmentFragment,
  SortBy,
  SortOrder,
} from "../../../graphql/graphql";
import useTopLocationsTree, {
  LocationWithChildren,
} from "../../../hooks/useTopLocationsTree";
import orderBy from "../../../utils/sort";

const LocationTreeSelect = ({
  handleSelect,
  activeLocationId,
}: {
  handleSelect: (location: LocationFragmentFragment) => void;
  activeLocationId?: string;
}) => {
  const [topLocationsTree] = useTopLocationsTree();
  return topLocationsTree ? (
    <>
      {topLocationsTree.map((location) => (
        <Location
          key={location.id}
          location={location}
          activeLocationId={activeLocationId}
          handleSelect={handleSelect}
        />
      ))}
    </>
  ) : null;
};

export default LocationTreeSelect;

const Location = ({
  location,
  activeLocationId,
  handleSelect,
}: {
  location: LocationWithChildren;
  activeLocationId?: string;
  handleSelect: (location: LocationFragmentFragment) => void;
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const isActive = activeLocationId === location.id;
  return (
    <Box
      position="relative"
      sx={
        location.parentId
          ? {
              "&:last-of-type:before": {
                content: '""',
                position: "absolute",
                top: "19px",
                backgroundColor: "white",
                width: "3px",
                height: "calc(100% - 20px)",
                left: "-18px",
                zIndex: 1,
              },
            }
          : undefined
      }
    >
      <Flex
        alignItems="center"
        cursor={isActive ? "not-allowed" : "default"}
        justifyContent="space-between"
        marginBottom="2"
        backgroundColor="secondary.10"
        padding="2"
        position="relative"
        role="group"
        sx={
          location.parentId
            ? {
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: "50%",
                  borderTop: "1px solid",
                  borderColor: "secondary.100",
                  width: "16px",
                  left: "-16px",
                },
              }
            : undefined
        }
      >
        <Flex alignItems="center">
          {location.children.length > 0 && (
            <IconButton
              variant="icon"
              aria-label={`${isOpen ? "Close" : "Open"} location children`}
              colorScheme="secondary"
              color="secondary.500"
              _hover={{ color: "secondary.600" }}
              size="xxs"
              onClick={onToggle}
              marginRight="2"
            >
              <FontAwesomeIcon
                icon={isOpen ? faChevronUp : faChevronDown}
                size="xs"
              />
            </IconButton>
          )}
          <Text
            color="secondary.500"
            flexGrow={1}
            opacity={isActive ? "0.4" : "1"}
          >
            <TooltipName name={location.name} />
          </Text>
        </Flex>
        {isActive ? (
          <Tooltip
            label="The destination and selected locations can not be the same."
            hasArrow
          >
            <Box color="gray.300" marginRight="2" cursor="default">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Box>
          </Tooltip>
        ) : (
          <Button
            variant="outline"
            onClick={() => handleSelect(location)}
            visibility="hidden"
            _groupHover={{ visibility: "visible" }}
            height="fit-content"
            colorScheme="secondary"
            marginX="2"
            width="70px"
            padding="1"
          >
            Move
          </Button>
        )}
      </Flex>
      {isOpen && location.children.length > 0 && (
        <Box
          paddingLeft="8"
          position="relative"
          sx={
            location.children.length && isOpen
              ? {
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    top: "-8px",
                    backgroundColor: "secondary.100",
                    width: "1px",
                    height: "calc(100% - 10px)",
                    left: "15px",
                  },
                }
              : undefined
          }
        >
          {orderBy(
            location.children,
            SortBy.Name,
            SortOrder.Asc,
            "children"
          ).map((l) => (
            <Location
              location={l}
              key={l.id}
              activeLocationId={activeLocationId}
              handleSelect={handleSelect}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
