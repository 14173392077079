const Tabs = {
  baseStyle: {
    tabpanel: {
      padding: 0,
      paddingTop: 3,
    },
    tab: {
      zIndex: 1,
    },
  },
  variants: {
    line: () => ({
      tab: {
        color: "gray.600",
      },
    }),
  },
  defaultProps: {
    colorScheme: "primary",
  },
};

export default Tabs;
