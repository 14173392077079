import { Box, Flex } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";

import { useLocationSimpleQuery } from "../../../graphql/graphql";
import MultiSelect, { MultiSelectItem } from "../multiSelect";
import SelectCombobox from "../selectCombobox";

interface LocationSelectFieldProps {
  name: string;
  label?: string;
  loading?: boolean;
  excludedLocationIds?: string[];
  multiSelect?: boolean;
  autoFocus?: boolean;
}

const LocationSelectField: React.FC<LocationSelectFieldProps> = ({
  name,
  label = "Location",
  loading,
  excludedLocationIds,
  multiSelect = true,
  autoFocus,
}) => {
  const { data } = useLocationSimpleQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const locations = React.useMemo(() => {
    if (!data) return [];
    const locations: MultiSelectItem[] = [];

    const locationsLength = data.locations.length;
    for (let i = 0; i < locationsLength; i++) {
      const location = data.locations[i];
      if (!excludedLocationIds?.includes(location.id)) {
        const label = location.name;
        locations.push({
          value: location.id,
          label: (
            <Flex alignItems="center">
              <Box marginLeft="1">{label}</Box>
            </Flex>
          ),
          query: label,
        });
      }
    }
    return locations;
  }, [data, excludedLocationIds]);

  return multiSelect ? (
    <MultiSelect
      sortOptions
      items={locations}
      autoComplete="off"
      name={name}
      label={label}
      loading={loading}
      autoFocus
    />
  ) : (
    <SelectCombobox
      sortOptions
      options={locations}
      name={name}
      label={label}
      autoFocus
    />
  );
};

export default LocationSelectField;

gql`
  query LocationSimple {
    locations {
      id
      name
    }
  }
`;
