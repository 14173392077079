import { gql } from "@apollo/client";

export const WorkOrderFileCreateDocument = gql`
  mutation workOrderFileCreate($workOrderId: UUID!, $files: [Upload!]) {
    workOrderFilesCreate(workOrderId: $workOrderId, files: $files) {
      id
      createdAt
      updatedAt
      path
      extension
      fileSize
      name
      signedUrl
    }
  }
`;

export const WorkOrderFileDeleteDocument = gql`
  mutation workOrderFileDelete($workOrderFileId: UUID!) {
    workOrderFileDelete(workOrderFileId: $workOrderFileId)
  }
`;
