import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import LogoIcon from "../../icons/logoDarkBg";
import OnboardingSteps from "./onboardingSteps";

interface ProgressLayoutProps {}

const ProgressLayout: React.FC<ProgressLayoutProps> = ({ children }) => {
  return (
    <Flex
      width="100%"
      height="screenFull"
      flexDirection={{ base: "column", lg: "row" }}
    >
      <Flex
        width={{ base: "100%", lg: "250px" }}
        position="relative"
        padding={{ base: "2", lg: "4" }}
        backgroundColor="black"
        flexDirection="column"
        alignItems="center"
      >
        <Box marginBottom={{ lg: "2" }} marginTop={{ base: "4", lg: "0" }}>
          <LogoIcon />
        </Box>
        <OnboardingSteps />
      </Flex>
      <Box
        padding={{ base: "4", lg: "6" }}
        flexGrow={1}
        height="screenFull"
        overflowY="auto"
        overflowX="hidden"
      >
        <Box>{children}</Box>
      </Box>
    </Flex>
  );
};

export default ProgressLayout;
