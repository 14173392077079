import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  FileFragmentFragment,
  useFileUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  descriptionSchema,
  nameSchema,
  yupObject,
} from "../../../utils/validation";

interface FileEditPresenterProps {
  file: FileFragmentFragment;
}

const FileEditPresenter: React.FC<FileEditPresenterProps> = ({ file }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [fileUpdateMutation, { loading: updateLoading }] =
    useFileUpdateMutation();

  const onSubmit = async (
    data: FileUpdateFormData,
    { setFieldError }: FormikHelpers<FileUpdateFormData>
  ) => {
    try {
      const { errors } = await fileUpdateMutation({
        variables: { id: file.id, data: { ...data } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else {
        onDrawerClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("foldersShow", { folderId: file.folderId }));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>Edit {file.name}</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit {file.name}</DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{
                name: file.name,
                description: file.description || "",
              }}
              validationSchema={FileUpdateValidationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form noValidate>
                  <FormGroup label="Name" name="name">
                    <Input
                      autoComplete="off"
                      autoFocus
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  <Box marginTop="6" textAlign="center">
                    <Button
                      width="full"
                      type="submit"
                      isLoading={updateLoading}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box marginTop="4" textAlign="center">
                    <Button
                      type="button"
                      variant="link"
                      colorScheme="secondary"
                      onClick={onDrawerClose}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default FileEditPresenter;

const FileUpdateValidationSchema = yupObject().shape({
  name: nameSchema,
  description: descriptionSchema,
});

type FileUpdateFormData = {
  name: string;
  description: string;
};

gql`
  mutation FileUpdate($id: UUID!, $data: FileUpdateInput!) {
    fileUpdate(id: $id, data: $data) {
      ...FileFragment
    }
  }
`;
