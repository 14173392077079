const Alert = {
  baseStyle: {
    container: {
      paddingBottom: "0.35rem",
      paddingTop: 1,
      justifyContent: "center",
      "a, button": {
        whiteSpace: "normal",
      },
    },
    description: {
      alignItems: "center",
      display: "inline-flex",
      fontWeight: "light",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  defaultProps: {
    variant: "solid",
  },
};

export default Alert;
