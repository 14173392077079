import { mode } from "@chakra-ui/theme-tools";

const Menu = {
  baseStyle: (props: Record<string, any>) => ({
    list: {
      borderRadius: 0,
      borderColor: "gray.100",
      boxShadow: mode("lg", "dark-lg")(props),
      padding: 0,
      zIndex: 5,
      overflow: "auto",
      maxHeight: "80",
    },
  }),
};

export default Menu;
