import { useReactiveVar } from "@apollo/client";
import { Box, List, ListItem, Text } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useCompanyQuery } from "../../../../graphql/graphql";
import { layoutOptionsVar } from "../../../../graphql/reactiveVariables";

interface OnboardingStepsProps {}

const OnboardingSteps: React.FC<OnboardingStepsProps> = () => {
  const currentStep = useReactiveVar(layoutOptionsVar).currentStep || 1;
  const { data: currentCompanyData } = useCompanyQuery();
  const steps =
    currentCompanyData?.company.isMainCompany ||
    currentCompanyData?.company.mainCompanyId
      ? ["Add New Company"]
      : ["Add New Company", "Select Plan", "Payment"];

  return (
    <Box marginTop={{ base: "8", lg: "2" }} marginBottom="4" width="full">
      <List display={{ base: "flex", lg: "block" }} marginTop={{ lg: "-8" }}>
        {steps.map((s, i) => (
          <ListItem
            key={i}
            css={css`
              & {
                position: relative;
                width: 33.3%;

                @media (min-width: 1024px) {
                  width: 100%;
                }
              }
              &:after {
                content: "";
                position: absolute;
                top: 20px;
                border-bottom: 1px dashed;
                border-bottom-color: ${currentStep === i + 1
                  ? "#ffbd29"
                  : currentStep < i + 1
                  ? "white"
                  : "#2ec14e"};
                width: calc(100% - 50px);
                left: calc(50% + 25px);
                display: ${i === steps.length - 1 && "none"};

                @media (min-width: 1024px) {
                  height: 45px;
                  top: 48px;
                  left: 20px;
                  border-left: 1px dashed;
                  border-left-color: ${currentStep === i + 1
                    ? "#ffbd29"
                    : currentStep < i + 1
                    ? "white"
                    : "#2ec14e"};
                  border-bottom: none;
                  margin: 10px 0px;
                }
              }
            `}
          >
            <Box position="relative" marginTop={{ lg: "14" }}>
              <Box
                width="10"
                height="10"
                margin="auto"
                textAlign="center"
                lineHeight="10"
                color={currentStep === i + 1 ? "black" : "gray.600"}
                backgroundColor={
                  currentStep === i + 1
                    ? "primary.500"
                    : currentStep < i + 1
                    ? "white"
                    : "green.500"
                }
                borderColor={currentStep === i + 1 ? "white" : "gray.100"}
                borderRadius="full"
                display={{ lg: "inline-block" }}
              >
                {i + 1 < currentStep ? (
                  <Text as="span" color="white">
                    <FontAwesomeIcon icon={faCheck} />
                  </Text>
                ) : (
                  i + 1
                )}
              </Box>
              <Box
                textAlign="center"
                color={currentStep === i + 1 ? "gray.100" : "gray.300"}
                display={{ lg: "inline-block" }}
                marginLeft={{ lg: "4" }}
                marginTop="4"
                fontSize={{ base: "xs", lg: "base" }}
              >
                {s}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default OnboardingSteps;
