import { Box, Center, Flex, Grid, Link, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import BankAccount from "../../../components/elements/bankAccount";
import CreditCard from "../../../components/elements/creditCard";
import Download from "../../../components/icons/download";
import { StripeInvoiceFragmentFragment } from "../../../graphql/graphql";
import { isNativeWebViewVar } from "../../../graphql/reactiveVariables";
import { formatDateFromSecondsTimestamp } from "../../../utils/date";
import { formatPrice } from "../../../utils/price";

interface CompanyBillingHistoryPagePresenterProps {
  invoices: StripeInvoiceFragmentFragment[];
}

const CompanyBillingHistoryPagePresenter: React.FC<
  CompanyBillingHistoryPagePresenterProps
> = ({ invoices }) => {
  const [isNative] = useState(isNativeWebViewVar());
  const invoicesWithCharge = invoices.filter((invoice) => invoice.charge);
  return (
    <>
      {invoicesWithCharge.length ? (
        <>
          <Grid
            fontSize="sm"
            color="gray.600"
            paddingX="4"
            display={{ base: "none", md: "grid" }}
            gridTemplateColumns="42% 17% 15% 10% 8%"
            gridColumnGap="2%"
            gridRowGap={{ base: "4", md: "0" }}
            justifyContent="space-between"
            fontWeight="medium"
            textAlign="center"
          >
            <Text textAlign="left">Payment Method</Text>
            <Text>Date</Text>
            <Text>Status</Text>
            <Text>Amount</Text>
            <Text>Invoice</Text>
          </Grid>
          <Box>
            {invoicesWithCharge.map((invoice) => (
              <Grid
                alignItems="center"
                backgroundColor="secondary.10"
                fontSize="sm"
                color="black"
                paddingX={{ base: "2", md: "7" }}
                paddingY={{ base: "6", md: "7" }}
                marginTop="4"
                gridTemplateColumns={{
                  base: "auto 1fr 1fr",
                  md: "42% 17% 15% 10% 8%",
                }}
                gridColumnGap={{ base: "32px", md: "2%" }}
                gridTemplateRows={{ base: "auto auto auto", md: "auto" }}
                gridRowGap={{ base: "4", md: "0" }}
                justifyContent="space-between"
                textAlign="center"
                key={invoice.id}
              >
                <Flex
                  gridArea={{ base: "1/1/2/4", md: "1/1" }}
                  alignItems="center"
                >
                  {invoice.charge?.payment_method_details?.type === "card" &&
                    !!invoice.charge.payment_method_details?.card && (
                      <CreditCard
                        card={{
                          card: invoice.charge.payment_method_details?.card,
                        }}
                        showExpiry={false}
                      />
                    )}
                  {invoice.charge?.payment_method_details?.type ===
                    "ach_debit" &&
                    !!invoice.charge.payment_method_details?.ach_debit && (
                      <BankAccount
                        bankAccount={
                          invoice.charge.payment_method_details?.ach_debit
                        }
                      />
                    )}
                </Flex>

                <Text>
                  {invoice.created
                    ? formatDateFromSecondsTimestamp(invoice.created)
                    : ""}
                </Text>
                <Text>Paid</Text>
                <Text fontWeight="semibold">
                  {formatPrice(
                    invoice.charge?.amount_refunded
                      ? invoice.amount_due
                        ? (invoice.amount_due -
                            invoice.charge.amount_refunded) /
                          100
                        : 0
                      : invoice.amount_due
                      ? invoice.amount_due / 100
                      : 0
                  )}
                </Text>

                <Box
                  marginTop={{ base: "1", md: "0" }}
                  paddingTop={{ base: "3", md: "0" }}
                  borderTop={{ base: "1px", md: "0" }}
                  borderTopColor="gray.300"
                  textAlign="center"
                  gridArea={{ base: "3/1/4/4", md: "1/5" }}
                >
                  {!!invoice.charge?.receipt_url || invoice.invoice_pdf ? (
                    <Link
                      href={
                        invoice.charge?.receipt_url
                          ? invoice.charge.receipt_url
                          : invoice.invoice_pdf
                      }
                      variant="link"
                      colorScheme="secondary"
                      target={isNative ? "_self" : "_blank"}
                      rel="noreferer"
                      size="sm"
                      aria-label="Download Invoice"
                    >
                      <Text as="span" color="secondary.500">
                        <Download />
                      </Text>
                      <Text as="span" display={{ md: "none" }} paddingLeft="1">
                        Invoice
                      </Text>
                    </Link>
                  ) : null}
                </Box>
              </Grid>
            ))}
          </Box>
        </>
      ) : (
        <Center height="100px" color="gray.700">
          Billing history empty
        </Center>
      )}
    </>
  );
};

export default CompanyBillingHistoryPagePresenter;
