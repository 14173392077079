import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { layoutOptionsVar } from "../../../graphql/reactiveVariables";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface UserActivityReportPageProps {}

const UserActivityReportPage: React.FC<UserActivityReportPageProps> = () => {
  useEffect(() => {
    layoutOptionsVar({
      title: "User Activity",
      hideSearch: true,
      styleProps: { paddingX: "0", pb: "0" },
      headerStyleProps: { paddingX: { base: "0", lg: "4" } },
      backLink: getRoutePath("reports"),
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>User Activity</title>
      </Helmet>
      <Presenter />
    </>
  );
};

export default UserActivityReportPage;

gql`
  query ReportUserActivity(
    $userId: String!
    $filterBy: ReportUserFilterInput!
    $timezone: String
  ) {
    reportUser(userId: $userId, filterBy: $filterBy, timezone: $timezone)
  }
`;

gql`
  mutation CreateReportUserActivity(
    $fileType: ReportFileType!
    $filterBy: ReportUserFilterInput!
    $userId: String!
    $timezone: String
  ) {
    createReportUser(
      fileType: $fileType
      filterBy: $filterBy
      userId: $userId
      timezone: $timezone
    )
  }
`;
