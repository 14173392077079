import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const PhoneIcon = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 24 24" boxSize="24px" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4.49998V3.49998C12.9977 3.36139 13.0518 3.22781 13.15 3.12998C13.2597 3.03333 13.4045 2.98629 13.55 2.99998C17.5489 3.26663 20.7334 6.45111 21 10.45C21.0082 10.5888 20.9615 10.7253 20.87 10.83C20.7722 10.9282 20.6386 10.9823 20.5 10.98H19.5C19.2388 10.9808 19.0209 10.7804 19 10.52C18.7722 7.57089 16.4291 5.22782 13.48 4.99998C13.2117 4.98924 12.9998 4.7685 13 4.49998ZM13.17 7.13998C13.0608 7.23591 12.9988 7.37462 13 7.51998V8.51998C12.996 8.75903 13.165 8.9661 13.4 9.00998C14.2041 9.15717 14.8376 9.77885 15 10.58C15.0439 10.815 15.251 10.984 15.49 10.98H16.49C16.6354 10.9812 16.7741 10.9192 16.87 10.81C16.9642 10.6993 17.0077 10.5542 16.99 10.41C16.7289 8.64982 15.3494 7.2663 13.59 6.99998C13.4429 6.98773 13.2974 7.03863 13.19 7.13998H13.17ZM19.09 20.41C18.7142 20.788 18.203 21.0004 17.67 21H17C15.3293 20.9929 13.6866 20.5702 12.22 19.77L11.77 19.52C8.66075 17.8483 6.11169 15.2992 4.44 12.19L4.19 11.74C3.41014 10.2817 3.00144 8.65373 3 6.99998V6.32998C2.99958 5.79694 3.21196 5.28578 3.59 4.90998L5.28 3.21998C5.44413 3.05458 5.67581 2.97486 5.90696 3.00424C6.13811 3.03362 6.34248 3.16878 6.46 3.36998L8.71 7.22998C8.93753 7.62287 8.87183 8.11974 8.55 8.43998L6.66 10.33C6.50305 10.4852 6.46647 10.725 6.57 10.92L6.92 11.58C8.17704 13.9084 10.0893 15.8172 12.42 17.07L13.08 17.43C13.275 17.5335 13.5148 17.4969 13.67 17.34L15.56 15.45C15.8802 15.1282 16.3771 15.0624 16.77 15.29L20.63 17.54C20.8312 17.6575 20.9664 17.8619 20.9957 18.093C21.0251 18.3242 20.9454 18.5559 20.78 18.72L19.09 20.41Z"
    />
  </Icon>
);

export default PhoneIcon;
