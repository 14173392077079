import { Icon, IconProps } from "@chakra-ui/react";

const Plumbing = (props: IconProps & any) => {
  return (
    <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
      <path
        d="M85.667 35.667a28.38 28.38 0 0 0-27.078 20h-6.255c-.92 0-1.667-.747-1.667-1.667V24a3.333 3.333 0 0 0 3.334-3.333v-3.334A3.333 3.333 0 0 0 50.666 14H17.334A3.333 3.333 0 0 0 14 17.333v3.334A3.333 3.333 0 0 0 17.334 24v80A3.333 3.333 0 0 0 14 107.333v3.334A3.333 3.333 0 0 0 17.334 114h33.333a3.333 3.333 0 0 0 3.334-3.333v-3.334A3.333 3.333 0 0 0 50.666 104V74c0-.92.746-1.667 1.667-1.667h6.255C62.87 86.248 77 94.653 91.27 91.773c14.271-2.878 24.038-16.103 22.591-30.589-1.447-14.486-13.637-25.518-28.195-25.517ZM17.334 17.333h33.333v3.334H17.334v-3.334Zm33.333 93.334H17.334v-3.334h33.333v3.334ZM52.334 69a5 5 0 0 0-5 5v30H20.667V24h26.667v30a5 5 0 0 0 5 5h5.468a26.922 26.922 0 0 0 0 10h-5.468Zm33.333 20c-13.807 0-25-11.193-25-25s11.193-25 25-25 25 11.193 25 25c-.015 13.8-11.2 24.984-25 25Z"
        fill="currentColor"
      />
      <path
        d="M85.667 42.334c-11.966 0-21.666 9.7-21.666 21.666 0 11.966 9.7 21.667 21.666 21.667 11.966 0 21.667-9.7 21.667-21.667-.014-11.96-9.706-21.653-21.667-21.666Zm0 40c-10.125 0-18.333-8.209-18.333-18.334s8.208-18.333 18.333-18.333C95.792 45.667 104 53.875 104 64c-.011 10.12-8.213 18.322-18.333 18.334Z"
        fill="currentColor"
      />
      <path
        d="M87.334 59.307v-6.974a1.667 1.667 0 1 0-3.334 0v6.974a4.97 4.97 0 0 0 0 9.386v3.64a1.667 1.667 0 1 0 3.334 0v-3.64a4.97 4.97 0 0 0 0-9.386Zm-1.667 6.36a1.667 1.667 0 1 1 0-3.333 1.667 1.667 0 0 1 0 3.333Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default Plumbing;
