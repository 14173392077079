import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import AssetCategorySelectOption from "../../components/elements/assetCategorySelectOption";
import Calendar from "../../components/elements/calendar";
import LabeledMenuButton from "../../components/elements/labeledMenuButton";
import MobileAccordion from "../../components/elements/mobileAccordion";
import AddCircle from "../../components/icons/addCircle";
import {
  MaintenanceIndexFragmentFragment,
  MaintenanceStatusType,
  SortBy,
  SortOrder,
  useMaintenanceConfigQuery,
  useUpdateMaintenanceConfigMutation,
} from "../../graphql/graphql";
import { currentCompanyRoleVar } from "../../graphql/reactiveVariables";
import useAssetCategories from "../../hooks/useAssetCategories";
import useBoxSize from "../../hooks/useBoxSize";
import ROLES from "../../roles";
import { mainLayoutPaddingX } from "../../utils/layout";
import orderBy from "../../utils/sort";
import { maintenanceStatusTypeOptions } from "../assets/presenter";

interface MaintenanceProps {
  maintenances?: MaintenanceIndexFragmentFragment[];
  showSortFilter?: boolean;
  virtualizeList?: boolean;
}

const Maintenance: React.FC<MaintenanceProps> = ({
  maintenances,
  showSortFilter = true,
  virtualizeList = true,
}) => {
  const { data: assetCategoriesData } = useAssetCategories();
  const { data: maintenanceConfigQuery } = useMaintenanceConfigQuery();
  const { assetId } = useParams();
  const [, setSearchParams] = useSearchParams();
  const [updateMaintenanceConfigMutation] =
    useUpdateMaintenanceConfigMutation();
  const maintenanceConfig = maintenanceConfigQuery?.maintenanceConfig;
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const canAddMaintenance = ROLES.maintenancesCreate.includes(
    currentCompanyRole.role
  );
  const filter = maintenanceConfig?.filterStatus || [];
  const selectedFilterOptions = calendarMaintenanceStatusTypeOptions.filter(
    (option) => filter.includes(option.value as any)
  );
  const selectedFilterValues = selectedFilterOptions.map(({ value }) => value);
  const selectedFilterLabels =
    selectedFilterOptions.length < 1 || selectedFilterOptions.length > 3
      ? "All"
      : selectedFilterOptions.map(({ label }) => label);
  const { containerRef, height } = useBoxSize();
  const assetCategoryId = maintenanceConfig?.filterCategoryId;
  const assetCategory = useMemo(
    () =>
      assetCategoriesData
        ? assetCategoriesData.assetCategories.find(
            (ac) => ac.id === assetCategoryId
          )
        : null,
    [assetCategoriesData, assetCategoryId]
  );

  const setFilter = React.useCallback(
    (filterStatus) => {
      updateMaintenanceConfigMutation({ variables: { filterStatus } });
    },
    [updateMaintenanceConfigMutation]
  );

  const handleCreate = useCallback(() => {
    const params: any = { CaAprType: "serviceScheduleCreate" };
    if (assetId) params.assetId = assetId;
    setSearchParams(params);
  }, [assetId, setSearchParams]);

  const sortedAssetCategories = React.useMemo(
    () =>
      assetCategoriesData
        ? orderBy(
            assetCategoriesData.assetCategories,
            SortBy.Name,
            SortOrder.Asc
          )
        : [],
    [assetCategoriesData]
  );

  const setAssetCategoryId = React.useCallback(
    (filterCategoryId: string) => {
      updateMaintenanceConfigMutation({
        variables: { filterCategoryId },
      });
    },
    [updateMaintenanceConfigMutation]
  );

  const resetFilters = React.useCallback(() => {
    updateMaintenanceConfigMutation({
      variables: { filterStatus: [], filterCategoryId: "" },
    });
  }, [updateMaintenanceConfigMutation]);

  const sortedAndFilteredMaintenances = useMemo(() => {
    if (!maintenanceConfig || !maintenances) return [];
    const assetCategoryId = assetCategory?.id;
    let filteredMaintenances = maintenances;
    if (assetCategoryId) {
      filteredMaintenances = filteredMaintenances.filter((maintenance) => {
        if (assetCategoryId) {
          if (maintenance.misc.assetCategoryId !== assetCategoryId)
            return false;
        }
        return true;
      });
    }
    return orderBy(
      filteredMaintenances,
      maintenanceConfig.sortBy,
      maintenanceConfig.sortOrder
    );
  }, [assetCategory?.id, maintenanceConfig, maintenances]);

  return (
    <Box>
      <Flex
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        marginBottom="6"
        paddingX={virtualizeList ? mainLayoutPaddingX : 0}
        marginX={{ base: "2", md: "0" }}
        pos="relative"
      >
        {showSortFilter && (
          <MobileAccordion>
            <Flex gap="2" wrap={{ base: "wrap", md: "nowrap" }}>
              <Box>
                <Menu closeOnSelect={false}>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        value={
                          Array.isArray(selectedFilterLabels) ? (
                            <Flex align="center">
                              {selectedFilterLabels.map((fl: any, index) => (
                                <Box key={index} mr="2">
                                  {fl}
                                </Box>
                              ))}
                            </Flex>
                          ) : (
                            selectedFilterLabels
                          )
                        }
                        label="Status"
                        styleProps={{
                          maxWidth: "none !important",
                          width: "180px",
                        }}
                      />
                      <MenuList>
                        <MenuOptionGroup
                          type="checkbox"
                          onChange={(value) =>
                            setFilter(
                              Array.isArray(value)
                                ? value.includes("All")
                                  ? [
                                      MaintenanceStatusType.Current,
                                      MaintenanceStatusType.DueIn_30,
                                      MaintenanceStatusType.PastDue,
                                      "COMPLETED",
                                    ]
                                  : value
                                : [value]
                            )
                          }
                          value={selectedFilterValues}
                        >
                          <MenuItemOption value="All">All</MenuItemOption>
                          {calendarMaintenanceStatusTypeOptions.map(
                            (option) => (
                              <MenuItemOption
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItemOption>
                            )
                          )}
                        </MenuOptionGroup>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              <Box>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        label="Category"
                        value={
                          assetCategory ? (
                            <AssetCategorySelectOption
                              assetCategory={assetCategory}
                            />
                          ) : (
                            "All"
                          )
                        }
                        styleProps={{
                          maxWidth: "none !important",
                          width: "180px",
                        }}
                      />
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            setAssetCategoryId("");
                          }}
                        >
                          All
                        </MenuItem>
                        {sortedAssetCategories.map((ac) => (
                          <MenuItem
                            key={ac.id}
                            onClick={() => {
                              if (ac.id === assetCategory?.id) return;
                              setAssetCategoryId(ac.id);
                            }}
                          >
                            <AssetCategorySelectOption assetCategory={ac} />
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              {!!(assetCategoryId || (filter?.length && filter.length < 4)) && (
                <Tooltip label="Clear all filters" hasArrow placement="bottom">
                  <IconButton
                    variant="icon"
                    colorScheme="gray"
                    aria-label="Clear all filters"
                    onClick={resetFilters}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </IconButton>
                </Tooltip>
              )}
            </Flex>
          </MobileAccordion>
        )}
        <Spacer />
        {canAddMaintenance && (
          <Tooltip label="Create new service event" hasArrow placement="left">
            <Button
              onClick={handleCreate}
              variant="icon"
              colorScheme="secondary"
              aria-label="Create new service event"
            >
              <AddCircle boxSize="32px" />
            </Button>
          </Tooltip>
        )}
      </Flex>
      <Box ref={containerRef}>
        <Box height={height} paddingX={virtualizeList ? mainLayoutPaddingX : 0}>
          <Calendar maintenances={sortedAndFilteredMaintenances} />
        </Box>
      </Box>
    </Box>
  );
};

export default Maintenance;

const calendarMaintenanceStatusTypeOptions = [
  {
    label: "Completed",
    value: "COMPLETED",
    query: "Completed",
  },
  ...maintenanceStatusTypeOptions,
];
