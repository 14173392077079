import { Icon, IconProps } from "@chakra-ui/react";

const AirCompressor = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M106 34.833c-4.289 0-7.778 3.49-7.778 7.778 0 3.257 2.014 6.052 4.861 7.208v30.709a6.813 6.813 0 0 1-6.805 6.805H79.556v-3.889h7.777v-.046c5.443-.493 9.723-5.08 9.723-10.648V61.083c0-5.569-4.28-10.155-9.723-10.648v-.046H84.3a13.532 13.532 0 0 0 2.451-7.778C86.75 35.106 80.644 29 73.139 29c-6.504 0-11.954 4.587-13.293 10.694h-17.04A6.813 6.813 0 0 0 36 46.5v3.889H23.945v.046c-5.443.493-9.723 5.08-9.723 10.648V72.75c0 5.57 4.28 10.155 9.723 10.648v.046h3.117a10.625 10.625 0 0 0-1.173 4.862C25.889 94.203 30.686 99 36.583 99c5.897 0 10.695-4.797 10.695-10.694 0-1.75-.425-3.402-1.174-4.862H61.42L69.198 99h10.358v-5.833h16.722c6.969 0 12.639-5.67 12.639-12.64V49.82c2.847-1.156 4.861-3.95 4.861-7.208 0-4.289-3.489-7.778-7.778-7.778ZM87.333 56.32a4.87 4.87 0 0 1 3.89 4.763V72.75a4.87 4.87 0 0 1-3.89 4.763V56.32ZM23.945 77.513a4.87 4.87 0 0 1-3.89-4.763V61.083a4.87 4.87 0 0 1 3.89-4.763v21.193Zm49.194-42.68c4.289 0 7.778 3.49 7.778 7.778 0 4.289-3.49 7.778-7.778 7.778-4.289 0-7.778-3.49-7.778-7.778 0-4.289 3.49-7.778 7.778-7.778ZM41.833 46.5c0-.536.437-.972.973-.972h17.04a13.54 13.54 0 0 0 2.133 4.86H41.833V46.5Zm-5.25 46.667a4.867 4.867 0 0 1-4.86-4.861c0-2.68 2.18-4.862 4.86-4.862s4.861 2.181 4.861 4.862c0 2.68-2.18 4.86-4.86 4.86Zm37.14 0h-.92l-4.861-9.723h5.78v9.723ZM29.777 77.61V56.222H81.5v21.39H29.778ZM106 44.556a1.946 1.946 0 0 1-1.944-1.945c0-1.072.872-1.944 1.944-1.944s1.944.872 1.944 1.944A1.946 1.946 0 0 1 106 44.556Z"
      fill="currentColor"
    />
  </Icon>
);

export default AirCompressor;
