import {
  EuiFlexGroup,
  EuiFlexItem,
  useEuiTheme,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { useState } from "react";

import { WorkOrder } from "../../models/workOrder";
import WorkOrderVerifyAndSaveGeneral from "./general";
import WorkOrderVerifyAndSaveRightColumn from "./rightPanel";

interface WorkOrderCreateVerifyAndSavePresenterProps {
  workOrder: WorkOrder;
  active: boolean;
}

const WorkOrderCreateVerifyAndSavePresenter: React.FC<
  WorkOrderCreateVerifyAndSavePresenterProps
> = ({ workOrder, active = false }) => {
  const isMobile = useIsWithinBreakpoints(["xs", "s"]);
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();

  return (
    <>
      <EuiFlexGroup
        direction="row"
        justifyContent="spaceBetween"
        alignItems="flexStart"
        gutterSize="xl"
        style={{ height: "calc(100vh - 300px)" }}
      >
        <EuiFlexItem
          grow={3}
          style={{
            minWidth: "360px",
            alignItems: isMobile ? "center" : "flex-start",
            overflow: "auto",
            marginRight: "-30px",
            height: "100%",
            paddingRight: "20px",
          }}
        >
          <WorkOrderVerifyAndSaveGeneral workOrder={workOrder} />
        </EuiFlexItem>
        <EuiFlexItem
          grow={false}
          style={{
            width: "416px",
            alignItems: isMobile ? "center" : "flex-end",
            gap: euiTheme.size.s,
            overflow: "auto",
            height: "100%",
            paddingRight: "10px",
            marginTop: euiTheme.size.base,
            paddingBottom: "32px",
          }}
        >
          <WorkOrderVerifyAndSaveRightColumn
            workOrder={workOrder}
            active={active}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default WorkOrderCreateVerifyAndSavePresenter;
