import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";

import CenteredFullHeight from "../../../../components/elements/centeredFullHeight";
import LabeledMenuButton from "../../../../components/elements/labeledMenuButton";
import Link from "../../../../components/elements/link";
import AddCircle from "../../../../components/icons/addCircle";
import ListViewIcon from "../../../../components/icons/listView";
import TilesViewIcon from "../../../../components/icons/tilesView";
import {
  AssetFileFragmentFragment,
  FolderDisplayType,
  SortBy,
  SortOrder,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import { sortByOptions } from "../../../../utils/sort";
import AssetFilesBulkAction from "./bulkActions";
import GridPresenter from "./grid";
import ListPresenter from "./list";

interface AssetPresenterFilesProps {
  assetId: string;
  assetFiles: AssetFileFragmentFragment[];
  handleEdit?: (id: string) => void;
  handleDelete?: (image: any) => void;
  canAddAsset: boolean;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
  sortBy: SortBy;
  sortOrder: SortOrder;
  displayType: FolderDisplayType;
  setFileView: (displayType: FolderDisplayType) => void;
  setSort: (value: string) => void;
}

const AssetPresenterFiles: React.FC<AssetPresenterFilesProps> = ({
  assetId,
  assetFiles,
  canAddAsset,
  canEditAsset,
  canDeleteAsset,
  handleEdit,
  handleDelete,
  sortBy,
  sortOrder,
  displayType,
  setFileView,
  setSort,
}) => {
  const [selectedFiles, setSelectedFiles] = React.useState<
    Array<string | undefined>
  >([]);
  const selectedSortOption = sortByOptions.find(
    (o) => o.value === `${sortBy}-${sortOrder}`
  );
  return (
    <>
      {assetFiles.length ? (
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom={{
              base: selectedFiles.length > 0 ? "2" : "6",
              sm: "6",
            }}
          >
            <Flex alignItems="center">
              <IconButton
                onClick={() => setFileView(FolderDisplayType.List)}
                variant="icon"
                aria-label="List File View"
                colorScheme={
                  displayType === FolderDisplayType.Grid ? "secondary" : "gray"
                }
                fontSize="xl"
                marginRight="4"
                minWidth="auto"
                _hover={{ color: "gray.800" }}
              >
                <ListViewIcon />
              </IconButton>
              <IconButton
                onClick={() => setFileView(FolderDisplayType.Grid)}
                variant="icon"
                aria-label="Grid File View"
                colorScheme={
                  displayType === FolderDisplayType.List ? "secondary" : "gray"
                }
                fontSize="xl"
                marginRight="4"
                minWidth="auto"
                _hover={{ color: "gray.800" }}
              >
                <TilesViewIcon />
              </IconButton>
              <Box>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        value={
                          selectedSortOption ? selectedSortOption.label : "Sort"
                        }
                      />
                      <MenuList>
                        {sortByOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            onClick={() => setSort(option.value)}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              {selectedFiles.length > 0 && (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  display={{ base: "none", sm: "flex" }}
                >
                  <Text color="gray.300" fontSize="sm">
                    {selectedFiles.length} Selected
                  </Text>
                  <Button
                    variant="link"
                    marginX="4"
                    colorScheme="secondary"
                    onClick={() => {
                      if (selectedFiles.length === assetFiles.length) {
                        setSelectedFiles([]);
                      } else {
                        setSelectedFiles(
                          assetFiles.map((assetFile) => assetFile.id)
                        );
                      }
                    }}
                  >
                    {selectedFiles.length === assetFiles.length
                      ? "Deselect All"
                      : "Select All"}
                  </Button>
                  <AssetFilesBulkAction
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    canDeleteAsset={canDeleteAsset}
                  />
                </Flex>
              )}
              {canAddAsset && (
                <Tooltip label="Add new asset file" placement="left" hasArrow>
                  <Link
                    to={`${getRoutePath("assetsShowFileCreate", { assetId })}`}
                    variant="icon"
                    colorScheme="graySecondary"
                    size="xs"
                    ml="5"
                  >
                    <AddCircle boxSize="32px" />
                  </Link>
                </Tooltip>
              )}
            </Flex>
          </Flex>
          {selectedFiles.length > 0 && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom="6"
              display={{ base: "flex", sm: "none" }}
            >
              <AssetFilesBulkAction
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                canDeleteAsset={canDeleteAsset}
              />
              <Flex direction="column" alignItems="flex-end">
                <Text color="gray.300" fontSize="sm">
                  {selectedFiles.length} Selected
                </Text>
                <Button
                  variant="link"
                  colorScheme="secondary"
                  onClick={() => {
                    if (selectedFiles.length === assetFiles.length) {
                      setSelectedFiles([]);
                    } else {
                      setSelectedFiles(
                        assetFiles.map((assetFile) => assetFile.id)
                      );
                    }
                  }}
                >
                  {selectedFiles.length === assetFiles.length
                    ? "Deselect All"
                    : "Select All"}
                </Button>
              </Flex>
            </Flex>
          )}
          <Box width="full">
            {displayType === FolderDisplayType.Grid ? (
              <GridPresenter
                assetFiles={assetFiles}
                assetId={assetId}
                canEditAsset={canEditAsset}
                canDeleteAsset={canDeleteAsset}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            ) : (
              <ListPresenter
                assetFiles={assetFiles}
                assetId={assetId}
                canEditAsset={canEditAsset}
                canDeleteAsset={canDeleteAsset}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            )}
          </Box>
        </>
      ) : (
        canAddAsset && (
          <CenteredFullHeight>
            <Link to={getRoutePath("assetsShowFileCreate", { assetId })}>
              Upload document
            </Link>
          </CenteredFullHeight>
        )
      )}
    </>
  );
};

export default AssetPresenterFiles;
