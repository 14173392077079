import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import AddPaymentMethod from "../../../components/elements/addPaymentMethod";
import BankAccountPaymentMethod from "../../../components/elements/bankAccountPaymentMethod";
import CreatePayment from "../../../components/elements/createPayment";
import CreditCardPaymentMethod from "../../../components/elements/creditCardPaymentMethod";
import AddCircle from "../../../components/icons/addCircle";
import {
  StripeBankAccountFragmentFragment,
  StripeCustomerFragmentFragment,
  StripePaymentMethodTypeFragmentFragment,
} from "../../../graphql/graphql";
import usePaymentsAvailable from "../../../hooks/usePaymentsAvailable";

interface PaymentMethodsPresenterProps {
  onPaymentSelected: () => void;
  cards: StripePaymentMethodTypeFragmentFragment[];
  bankAccounts: StripeBankAccountFragmentFragment[];
  customer: StripeCustomerFragmentFragment;
}

const PaymentMethodsPresenter: React.FC<PaymentMethodsPresenterProps> = ({
  cards,
  bankAccounts,
  customer,
  onPaymentSelected,
}) => {
  const {
    isOpen: isAddPaymentOpen,
    onClose: onAddPaymentClose,
    onOpen: onAddPaymentOpen,
  } = useDisclosure();
  const {
    isOpen: isSelectPaymentOpen,
    onClose: onSelectPaymentClose,
    onOpen: onSelectPaymentOpen,
  } = useDisclosure();

  const paymentsAvailable = usePaymentsAvailable();

  const defaultSource =
    customer.invoice_settings?.default_payment_method ||
    customer.default_source;

  const bankAccountsHasDefault = bankAccounts.find(
    (bankAccount) => bankAccount.id === defaultSource
  );

  const creditCardHasDefault = cards.find(
    (cards) => cards.id === defaultSource
  );

  if (bankAccountsHasDefault) {
    bankAccounts = bankAccounts
      .slice()
      .sort((a, b) =>
        defaultSource === a.id ? -1 : defaultSource === b.id ? 1 : 0
      );
  } else {
    cards = cards
      .slice()
      .sort((a, b) =>
        defaultSource === a.id ? -1 : defaultSource === b.id ? 1 : 0
      );
  }

  return (
    <>
      {!paymentsAvailable ? (
        <AddPaymentMethod
          paymentMethodAdded={() => {}}
          creditCardAdded={onPaymentSelected}
          ctaCopy="Pay Now"
        />
      ) : (
        <Box>
          <Flex justifyContent="space-between" marginBottom="4">
            <Text fontSize="2xl">Payment Method</Text>
          </Flex>

          {bankAccountsHasDefault ? (
            <BankAccountPaymentMethod
              bankAccount={bankAccountsHasDefault}
              defaultSource={defaultSource}
              type="simple"
              onSetPrimarySuccess={onSelectPaymentClose}
              showPrimary={false}
            />
          ) : creditCardHasDefault ? (
            <CreditCardPaymentMethod
              card={creditCardHasDefault}
              defaultSource={defaultSource}
              type="simple"
              onSetPrimarySuccess={onSelectPaymentClose}
              showPrimary={false}
            />
          ) : null}

          <Flex
            textAlign={{ base: "center", md: "left" }}
            marginY="4"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box marginBottom={{ base: "4", md: "0" }}>
              {cards.length + bankAccounts.length > 1 && (
                <Button
                  variant="link"
                  colorScheme="secondary"
                  onClick={onSelectPaymentOpen}
                  fontSize="sm"
                >
                  Use a different payment method
                </Button>
              )}
            </Box>
            <Box>
              <Button
                variant="link"
                colorScheme="secondary"
                onClick={onAddPaymentOpen}
                fontSize="sm"
                leftIcon={<AddCircle />}
              >
                Add new payment method
              </Button>
            </Box>
          </Flex>

          <Box marginX="auto" maxWidth="sm" marginTop="8">
            <Button width="full" type="submit" onClick={onPaymentSelected}>
              Pay Now
            </Button>
          </Box>
        </Box>
      )}

      {isAddPaymentOpen && (
        <CreatePayment paymentMethodAdded={onAddPaymentClose} />
      )}

      <Modal
        isOpen={isSelectPaymentOpen}
        onClose={onSelectPaymentClose}
        size="xl"
      >
        <ModalOverlay zIndex={2000}>
          <ModalContent>
            <ModalHeader>Please choose your payment method</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {bankAccountsHasDefault ? (
                <>
                  {bankAccounts.map((bankAccount) => (
                    <BankAccountPaymentMethod
                      key={bankAccount.id}
                      bankAccount={bankAccount}
                      defaultSource={defaultSource}
                      type="simple"
                      onSetPrimarySuccess={onSelectPaymentClose}
                    />
                  ))}
                  {cards.map((card) => (
                    <CreditCardPaymentMethod
                      key={card.id}
                      card={card}
                      defaultSource={defaultSource}
                      type="simple"
                      onSetPrimarySuccess={onSelectPaymentClose}
                    />
                  ))}
                </>
              ) : (
                <>
                  {cards.map((card) => (
                    <CreditCardPaymentMethod
                      key={card.id}
                      card={card}
                      defaultSource={defaultSource}
                      type="simple"
                      onSetPrimarySuccess={onSelectPaymentClose}
                    />
                  ))}
                  {bankAccounts.map((bankAccount) => (
                    <BankAccountPaymentMethod
                      key={bankAccount.id}
                      bankAccount={bankAccount}
                      defaultSource={defaultSource}
                      type="simple"
                      onSetPrimarySuccess={onSelectPaymentClose}
                    />
                  ))}
                </>
              )}
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default PaymentMethodsPresenter;
