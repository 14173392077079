import { Icon, IconProps } from "@chakra-ui/react";

const BatteryInverter = (props: IconProps & any) => {
  return (
    <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
      <path
        d="M69.963 54.783a2.5 2.5 0 0 0-2.259-1.427h-7.76l3.705-6.117a2.493 2.493 0 0 0 .039-2.518 2.5 2.5 0 0 0-2.178-1.27H49.838a2.5 2.5 0 0 0-2.213 1.335l-9.723 18.487a2.494 2.494 0 0 0 .073 2.452 2.5 2.5 0 0 0 2.14 1.206h7.345L46.15 78.885a2.496 2.496 0 0 0 2.484 2.768c.734 0 1.451-.323 1.937-.916l19.07-23.303a2.495 2.495 0 0 0 .323-2.65ZM52.038 71.051l.696-6.345a2.495 2.495 0 0 0-2.485-2.77h-5.997l7.097-13.492h5.727l-3.705 6.117a2.494 2.494 0 0 0-.039 2.518 2.5 2.5 0 0 0 2.178 1.27h6.922L52.038 71.052Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.729 55.3V37.9h3.59c1.38 0 2.5-1.118 2.5-2.497V24.342c0-1.38-1.12-2.497-2.5-2.497h-7.401V12.99c0-1.38-1.12-2.497-2.5-2.497H76.529c-1.38 0-2.5 1.118-2.5 2.497v8.856H33.79V12.99c0-1.38-1.12-2.497-2.5-2.497H12.401c-1.38 0-2.5 1.118-2.5 2.497v8.856H2.5c-1.38 0-2.5 1.118-2.5 2.497v11.06c0 1.38 1.12 2.498 2.5 2.498h3.59v48.45H2.5c-1.38 0-2.5 1.118-2.5 2.497v11.061a2.498 2.498 0 0 0 2.5 2.497h67.338c5.502 9.047 15.463 15.103 26.813 15.103 17.286 0 31.349-14.045 31.349-31.31 0-15.537-11.392-28.468-26.271-30.898Zm1.09-28.461H5v6.067h97.819v-6.067Zm-23.79-11.353v6.36h13.889v-6.36H79.029Zm-50.24 0H14.902v6.36H28.79v-6.36ZM96.73 37.9H11.09v48.45h54.213V86.2c0-17.265 14.063-31.31 31.348-31.31h.078V37.9ZM5 91.344v6.067h62.381a31.014 31.014 0 0 1-1.655-6.067H5ZM70.303 86.2c0 14.51 11.82 26.316 26.348 26.316 14.529 0 26.349-11.806 26.349-26.316 0-14.511-11.82-26.316-26.349-26.316-14.528 0-26.348 11.805-26.348 26.316Z"
        fill="currentColor"
      />
      <path
        d="M104.746 78.785a2.495 2.495 0 0 0 .836-3.431l-3.978-6.53a5.8 5.8 0 0 0-4.953-2.749 5.798 5.798 0 0 0-4.952 2.749l-3.256 5.229a2.495 2.495 0 0 0-1.837-.281 2.497 2.497 0 0 0-1.868 2.997l.795 3.413a2.5 2.5 0 0 0 3.001 1.866l3.417-.794a2.497 2.497 0 0 0 .737-4.563l3.257-5.23a.809.809 0 0 1 .706-.392c.173 0 .495.051.694.371l3.965 6.51a2.503 2.503 0 0 0 3.436.835ZM114.797 90.007l-1.546-2.506a2.496 2.496 0 0 0-.718-4.49l-3.383-.927a2.504 2.504 0 0 0-1.898.238c-.577.327-.999.87-1.174 1.509l-.929 3.378a2.496 2.496 0 0 0 2.412 3.159c.483 0 .948-.141 1.344-.393l1.642 2.66a.801.801 0 0 1 .02.843.802.802 0 0 1-.727.428h-6.394c-1.38 0-2.5 1.117-2.5 2.497a2.499 2.499 0 0 0 2.5 2.496h6.394a5.836 5.836 0 0 0 5.098-2.995 5.816 5.816 0 0 0-.141-5.897ZM93.258 92.16a2.502 2.502 0 0 0-4.267 1.746h-5.528a.804.804 0 0 1-.727-.427.802.802 0 0 1 .02-.843l2.147-3.447c.73-1.17.37-2.71-.802-3.439a2.502 2.502 0 0 0-3.443.802l-2.147 3.446a5.822 5.822 0 0 0-.146 5.906 5.837 5.837 0 0 0 5.098 2.996h5.528a2.496 2.496 0 0 0 2.5 2.477c.64 0 1.28-.244 1.767-.731l2.481-2.478a2.496 2.496 0 0 0 0-3.53l-2.48-2.478Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default BatteryInverter;
