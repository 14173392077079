import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";

import AssetIconField from "../../../components/elements/assetIcons/field";
import FormGroup from "../../../components/elements/formGroup";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetCategoryFragmentFragment,
  useAssetCategoryUpdateMutation,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  AssetCategoryFormData,
  AssetCategoryValidationSchema,
} from "../create";

interface AssetCategoriesCreatePresenterProps {
  assetCategory: AssetCategoryFragmentFragment;
  onDrawerClose: () => void;
}

const AssetCategoriesCreatePresenter: React.FC<
  AssetCategoriesCreatePresenterProps
> = ({ assetCategory, onDrawerClose }) => {
  const toast = useToast();

  const [assetCategoryUpdateMutation, { loading }] =
    useAssetCategoryUpdateMutation();

  const onSubmit = async (
    data: AssetCategoryFormData,
    { setFieldError }: FormikHelpers<AssetCategoryFormData>
  ) => {
    try {
      const { data: serverData, errors } = await assetCategoryUpdateMutation({
        variables: { data, id: assetCategory.id },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit {assetCategory.name}</title>
      </Helmet>
      <DrawerCloseButton />
      <DrawerHeader>Edit {assetCategory.name}</DrawerHeader>
      <DrawerBody>
        <Formik
          initialValues={{
            name: assetCategory.name,
            description: assetCategory.description || "",
            iconName: assetCategory.iconName,
            iconColor: assetCategory.iconColor,
            iconType: assetCategory.iconType,
          }}
          validationSchema={AssetCategoryValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ getFieldProps, values }) => (
            <Form id="asset_category_edit" noValidate>
              <FormGroup label="Category Name" name="name">
                <Input
                  autoFocus
                  autoComplete="off"
                  {...getFieldProps("name")}
                />
              </FormGroup>
              <FormGroup label="Description" name="description">
                <Textarea
                  autoComplete="off"
                  {...getFieldProps("description")}
                />
              </FormGroup>
              <AssetIconField />
            </Form>
          )}
        </Formik>
      </DrawerBody>
      <DrawerFooter>
        <Button
          width="full"
          type="submit"
          isLoading={loading}
          form="asset_category_edit"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
};

export default AssetCategoriesCreatePresenter;

gql`
  mutation AssetCategoryUpdate($id: UUID!, $data: AssetCategoryInput!) {
    assetCategoryUpdate(id: $id, data: $data) {
      ...AssetCategoryWithTypesFragment
    }
  }
`;
