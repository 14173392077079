import { EuiText, useEuiTheme } from "@elastic/eui";
import { css } from "@emotion/react";
import React from "react";

import Label from "../../../../components/label";
import { WorkOrder } from "../../../../models/workOrder";

interface WorkOrderVerifyAndSaveServicesTitlePresenterProps {
  workOrder: WorkOrder;
}

const WorkOrderVerifyAndSaveServicesTitlePresenter: React.FC<
  WorkOrderVerifyAndSaveServicesTitlePresenterProps
> = ({ workOrder }) => {
  const { euiTheme } = useEuiTheme();

  let style = {
    fontSize: "22px",
    fontWeight: 500,
  };
  return (
    <>
      <Label label="Title" style={{}} />
      <EuiText
        title={workOrder.name as string}
        className={"ellipsis"}
        style={{
          ...style,
          borderRadius: "0",
          marginBottom: "0",
          height: "40px",
          letterSpacing: "normal",
          lineHeight: euiTheme.size.xxl,
        }}
        css={css`
          color: var(--color-text);
        `}
      >
        {workOrder.name}
      </EuiText>
    </>
  );
};
export default WorkOrderVerifyAndSaveServicesTitlePresenter;
