import { useReactiveVar } from "@apollo/client";
import {
  EuiBasicTable,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { EuiBasicTableColumn } from "@elastic/eui/src/components/basic_table/basic_table";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import moment from "moment";
import React, { useEffect, useState } from "react";

import FileInput from "../../../../components/fileInput";
import AttachmentsIcon from "../../../../components/svgIcons/attachmentsIcon";
import { fileDownload } from "../../../../helpers/fileDownload";
import {
  getFileSizeString,
  workOrdersAcceptedFileTypes,
  workOrdersFileMaxSize,
} from "../../../../helpers/filesValidation";
import { toastVar } from "../../../../helpers/workOrdersReactiveVariables";
import { WorkOrder, WorkOrderFile } from "../../../../models/workOrder";
import { AttachmentsTableModeEnum } from "./index";

interface FileItem {
  name: string;
  size: string;
  url: string | null;
  file: File | WorkOrderFile;
  createdAt: Date;
}

interface WorkOrderCreateServicesAttachmentsPresenterProps {
  workOrder: WorkOrder;
  onFilesAdded?: (files: File[]) => void;
  onFileDelete?: (files: File) => void;
  mode?: AttachmentsTableModeEnum;
}

const WorkOrderCreateServicesAttachmentsPresenter: React.FC<
  WorkOrderCreateServicesAttachmentsPresenterProps
> = ({ workOrder, onFilesAdded, onFileDelete, mode }) => {
  const { euiTheme }: UseEuiTheme<{ colors: any }> = useEuiTheme();
  const toasts = useReactiveVar(toastVar);
  const [columns, setColumns] = useState<EuiBasicTableColumn<FileItem>[]>([]);
  const [fileItems, setFileItems] = useState<FileItem[]>([]);
  const [deletedFile, setDeletedFile] = useState<File | null>(null);

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toastVar([
      ...toasts,
      {
        id: `copyToClipboard${Math.random()}`,
        color: "success",
        text: "The value has been copied",
      },
    ]);
  };

  const handleDownloadFile = (url: string, name: string) => {
    fileDownload(url, name);
  };

  const handleFileDeleteClick = (file: File) => {
    setDeletedFile(file);
  };

  const onCloseModal = () => {
    setDeletedFile(null);
  };

  const handleFileDelete = () => {
    if (deletedFile) {
      onFileDelete && onFileDelete(deletedFile);
    }
    setDeletedFile(null);
  };

  useEffect(() => {
    if (mode !== AttachmentsTableModeEnum.edit) {
      let newFileItems: FileItem[] = [];
      const oldFiles = fileItems.filter((fileItem) =>
        workOrder.attachments?.includes(fileItem.file as File)
      );
      if (workOrder?.attachments?.length) {
        const addedFiles = fileItems.map((fileItem) => fileItem.file);
        newFileItems = workOrder.attachments
          .filter((workOrderFile) => !addedFiles.includes(workOrderFile))
          .map((file) => {
            return {
              name: file.name,
              size: getFileSizeString(file.size),
              createdAt: new Date(),
              lastModified: file.lastModified,
              file: file,
              url: null,
            };
          });
      }
      setFileItems(oldFiles.concat(newFileItems));
    }
  }, [workOrder, mode]);

  useEffect(() => {
    if (mode === AttachmentsTableModeEnum.edit && workOrder.files) {
      const filesItems: FileItem[] = workOrder.files.map((file) => {
        return {
          name: file.name || "",
          size: getFileSizeString(Number(file.fileSize)),
          createdAt: new Date(file.createdAt || ""),
          lastModified: file.updatedAt,
          file: file,
          url: file.signedUrl || "",
        };
      });
      setFileItems(filesItems);
    }
  }, [workOrder, mode]);

  useEffect(() => {
    const newColumns: EuiBasicTableColumn<FileItem>[] = [
      {
        field: "name",
        name: "File Name",
        sortable: true,
        width: "50%",
      },
      {
        field: "size",
        name: "Size",
        width: "100px",
        truncateText: true,
      },
      {
        field: "createdAt",
        name: "Date Added",
        width: "120px",
        render: (date: string) => (
          <span>{moment(date).format("MMM D, YYYY h:mm A")}</span>
        ),
      },
    ];
    if (
      mode === AttachmentsTableModeEnum.create ||
      mode === AttachmentsTableModeEnum.edit
    ) {
      newColumns.push({
        name: "Actions",
        field: "file",
        width: mode === AttachmentsTableModeEnum.create ? "36px" : "80px",
        render: (file: any, item: any) => (
          <EuiFlexGroup
            wrap={false}
            alignItems="center"
            justifyContent="center"
            gutterSize="m"
            style={{ maxWidth: "100%" }}
          >
            {mode === AttachmentsTableModeEnum.edit && (
              <EuiIcon
                type="link"
                style={{ cursor: "pointer" }}
                onClick={() => handleCopyToClipboard(item.url)}
                css={css`
                  &:hover {
                    color: ${euiTheme.colors.red["500"]};
                  }
                `}
              />
            )}
            {mode === AttachmentsTableModeEnum.edit && (
              <EuiIcon
                type="download"
                style={{ cursor: "pointer" }}
                onClick={() => handleDownloadFile(item.url, item.name)}
                css={css`
                  &:hover {
                    color: ${euiTheme.colors.red["500"]};
                  }
                `}
              />
            )}
            <EuiIcon
              type="trash"
              style={{ cursor: "pointer" }}
              onClick={() => handleFileDeleteClick(file)}
              css={css`
                &:hover {
                  color: ${euiTheme.colors.red["500"]};
                }
              `}
            />
          </EuiFlexGroup>
        ),
      });
    }
    setColumns(newColumns);
  }, [mode, workOrder]);

  return (
    <>
      <EuiFlexGroup
        direction="column"
        justifyContent="flexStart"
        alignItems="stretch"
        gutterSize="none"
        style={{ width: "100%" }}
      >
        <EuiFlexItem
          grow={1}
          style={{
            minWidth: "360px",
          }}
        >
          <EuiFlexGroup
            direction="row"
            wrap={false}
            justifyContent="flexStart"
            gutterSize="s"
          >
            <EuiText
              style={{
                fontSize: euiTheme.size.base,
                fontWeight: 500,
                fontFamily: "Inter-medium, sans-serif",
                lineHeight: "21px",
              }}
              color={euiTheme.colors.title}
            >
              Attachments ({fileItems?.length || 0})
            </EuiText>
            {mode !== AttachmentsTableModeEnum.viewOnly ? (
              <EuiText
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FileInput
                  multiple={true}
                  accept={workOrdersAcceptedFileTypes}
                  maxSize={workOrdersFileMaxSize}
                  acceptErrorMassage="Only JPEG, PNG, PDF file are allowed"
                  maxSizeErrorMassage="Max size of the file must be no more than 10 MB"
                  disableDragAndDrop={true}
                  onChange={onFilesAdded}
                >
                  <AttachmentsIcon
                    style={{
                      width: euiTheme.size.base,
                      height: "14px",
                      fill: euiTheme.colors.secondary["500"],
                      cursor: "pointer",
                    }}
                  />
                </FileInput>
              </EuiText>
            ) : null}
          </EuiFlexGroup>
        </EuiFlexItem>
        {mode !== AttachmentsTableModeEnum.viewOnly ? (
          <EuiFlexItem
            grow={1}
            style={{
              minWidth: "360px",
            }}
          >
            <EuiText
              style={{ fontSize: "10px", lineHeight: euiTheme.size.base }}
              color={euiTheme.colors.darkShade}
            >
              Select or drag and drop multiple files*
            </EuiText>
          </EuiFlexItem>
        ) : null}
        <EuiFlexItem
          grow={1}
          style={{
            minWidth: "360px",
            marginTop:
              mode === AttachmentsTableModeEnum.viewOnly ? euiTheme.size.m : 0,
          }}
        >
          <EuiBasicTable
            tableCaption="Attachments Table"
            items={fileItems}
            rowHeader="firstName"
            columns={columns}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      {!!deletedFile && (
        <EuiConfirmModal
          className={"confirm-modal"}
          onCancel={onCloseModal}
          maxWidth={true}
          onConfirm={handleFileDelete}
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          buttonColor="danger"
        >
          <EuiFlexGroup
            direction="column"
            justifyContent="flexStart"
            alignItems="stretch"
            style={{ width: "100%", margin: euiTheme.size.base }}
          >
            <EuiFlexItem
              grow={1}
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <EuiIcon type="alert" color="danger" size="l" />
              <EuiText
                style={{
                  fontSize: euiTheme.size.base,
                  marginLeft: euiTheme.size.base,
                }}
                color={euiTheme.colors.title}
              >
                Remove this Attachment from Work Order?
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiText
                style={{ fontSize: euiTheme.size.base }}
                color={euiTheme.colors.title}
              >
                Are you sure, you want to remove this Attachment from the Work
                Order?
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiConfirmModal>
      )}
    </>
  );
};

export default WorkOrderCreateServicesAttachmentsPresenter;
