import { useReactiveVar } from "@apollo/client";
import { EuiFlexGroup, EuiFlexItem, useEuiTheme } from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { useEffect, useMemo, useState } from "react";

import Accordion from "../../../components/accordion";
import Combobox from "../../../components/combobox";
import Label from "../../../components/label";
import Select from "../../../components/select";
import { MessageUtils } from "../../../helpers/messages";
import { companyChanged } from "../../../helpers/workOrdersReactiveVariables";
import {
  BusinessSeverityEnum,
  OrderPriorityEnum,
  businessSeverityOptions,
  orderPriorityOption,
} from "../../../models/enums";
import { WorkOrder } from "../../../models/workOrder";

interface WorkOrderCreateServicesGeneralInfoPresenterProps {
  workOrder: WorkOrder;
  workOrders?: WorkOrder[];
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  orderTypesOption: EuiSuperSelectOption<string>[];
  serviceCategoriesOption: EuiSuperSelectOption<string>[];
  workOrdersOptions: EuiComboBoxOptionOption<string>[];
}

const WorkOrderCreateServicesServicePlanPresenter: React.FC<
  WorkOrderCreateServicesGeneralInfoPresenterProps
> = ({
  workOrder,
  workOrders,
  onWorkOrderChange,
  orderTypesOption = [],
  serviceCategoriesOption = [],
  workOrdersOptions = [],
}) => {
  const { euiTheme } = useEuiTheme();
  const [parentOrderErrors, setParentOrderErrors] = useState<string[]>([]);
  const [parentOrderTouched, setParentOrderTouched] = useState<boolean>(false);
  const [serviceCategoryTouched, setServiceCategoryTouched] =
    useState<boolean>(false);

  const orderTypeRevisitId = useMemo(
    () =>
      orderTypesOption?.find(
        (item) => (item.inputDisplay as string)?.toLowerCase() === "revisit"
      )?.value,
    [orderTypesOption]
  );
  const minSelectorWidth = "235px";
  const onOrderTypeSelect = (orderTypeId: string) => {
    onWorkOrderChange({
      ...workOrder,
      orderTypeId,
    });
    setParentOrderTouched(false);
  };

  const onOrderPrioritySelect = (orderPriority: OrderPriorityEnum) =>
    onWorkOrderChange({
      ...workOrder,
      orderPriority,
    });
  const onServiceCategorySelect = (serviceCategoryId: string) => {
    setServiceCategoryTouched(true);
    onWorkOrderChange({
      ...workOrder,
      serviceCategoryId,
    });
  };
  const onBusinessSeveritySelect = (businessSeverity: BusinessSeverityEnum) =>
    onWorkOrderChange({
      ...workOrder,
      businessSeverity,
    });

  const onParentOrderSelected = (orderId: string) => {
    const parentOrder = workOrders?.find((order) => order.id === orderId);
    setParentOrderTouched(true);
    onWorkOrderChange({ ...workOrder, parentOrderId: orderId, parentOrder });
  };

  const onParentOrderBlur = () => {
    setParentOrderTouched(true);
  };

  useEffect(() => {
    if (
      parentOrderTouched &&
      orderTypeRevisitId === workOrder.orderTypeId &&
      !workOrder.parentOrderId
    ) {
      setParentOrderErrors([MessageUtils.commonRequiredFieldErrorMessage]);
    } else {
      setParentOrderErrors([]);
    }
  }, [parentOrderTouched, workOrder]);
  const isCompanyChanged: { changed: boolean } = useReactiveVar(companyChanged);

  useEffect(() => {
    if (isCompanyChanged.changed) {
      resetErrors();
    }
  }, [isCompanyChanged]);

  const resetErrors = () => {
    setParentOrderTouched(false);
    setServiceCategoryTouched(false);
  };
  return (
    <div>
      <Accordion
        id="wo-service-plan"
        label="Service Plan"
        required={true}
        initialIsOpen={true}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
        >
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Order Type" required={true} />
            <div style={{ minWidth: minSelectorWidth }}>
              <Select
                selectedValue={workOrder.orderTypeId}
                options={orderTypesOption}
                onSelect={onOrderTypeSelect}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Service Category" required={true} />
            <div style={{ minWidth: minSelectorWidth }}>
              <Select
                placeholder={"Select Service Category"}
                selectedValue={workOrder.serviceCategoryId}
                options={serviceCategoriesOption}
                onSelect={onServiceCategorySelect}
                compressed={true}
                onBlur={() => setServiceCategoryTouched(true)}
                invalid={serviceCategoryTouched && !workOrder.serviceCategoryId}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Business Severity" required={true} />
            <div style={{ minWidth: minSelectorWidth }}>
              <Select
                options={businessSeverityOptions}
                selectedValue={workOrder?.businessSeverity}
                onSelect={onBusinessSeveritySelect}
                placeholder={"Select Business Severity"}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Execution Priority" />
            <div style={{ minWidth: minSelectorWidth }}>
              <Select
                selectedValue={workOrder?.orderPriority}
                options={orderPriorityOption}
                onSelect={onOrderPrioritySelect}
                placeholder={"Select Execution Priority"}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label
              label="Parent Order"
              required={orderTypeRevisitId === workOrder.orderTypeId}
            />
            <div style={{ minWidth: minSelectorWidth, maxWidth: "235px" }}>
              <Combobox
                options={workOrdersOptions}
                selectedValue={workOrder?.parentOrderId}
                onChange={onParentOrderSelected}
                onBlur={onParentOrderBlur}
                invalid={!!parentOrderErrors.length}
                placeholder={"Search Parent Order"}
                errors={parentOrderErrors}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Accordion>
    </div>
  );
};

export default WorkOrderCreateServicesServicePlanPresenter;
