import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";

import Link from "../../../../components/elements/link";
import TooltipName from "../../../../components/elements/tooltipName";
import Close from "../../../../components/icons/close";
import { ASSET_FLOOR_PLAN_DELETED_MESSAGE } from "../../../../constants/lang/en";
import {
  FloorPlanAssetWithFloorPlanFragmentFragment,
  useFloorPlanAssetDeleteMutation,
} from "../../../../graphql/graphql";
import { setGenericMessage } from "../../../../utils/serverErrors";

interface AssetFloorPlanProps {
  floorPlanAsset: FloorPlanAssetWithFloorPlanFragmentFragment;
  assetId: string;
}

const AssetFloorPlan: React.FC<AssetFloorPlanProps> = ({
  floorPlanAsset,
  assetId,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const [deleting, setDeleting] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [floorPlanAssetDeleteMutation] = useFloorPlanAssetDeleteMutation();

  const deleteAsset = async () => {
    setDeleting(true);
    try {
      await floorPlanAssetDeleteMutation({
        variables: { id: floorPlanAsset.id },
      });
      toast({
        description: ASSET_FLOOR_PLAN_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      client.cache.evict({ id: `FloorPlanAsset:${floorPlanAsset.id}` });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setDeleting(false);
      onClose();
    }
  };
  return (
    <Flex
      alignItems="center"
      paddingY="1"
      width="full"
      _hover={{ backgroundColor: "gray.200" }}
    >
      <Link
        alignItems="center"
        justifyContent="flex-start"
        to={`?CaAprType=planEditor&assetId=${assetId}&planId=${floorPlanAsset.floorPlan.id}`}
        variant="link"
        colorScheme="secondary"
        width="full"
        fontSize="sm"
      >
        <TooltipName name={floorPlanAsset.floorPlan.name} />
      </Link>
      <Box flexShrink={0}>
        <IconButton
          onClick={onOpen}
          aria-label="Delete Asset FloorPlan"
          variant="icon"
          colorScheme="grayRed"
          mx="2"
        >
          <Close boxSize="10px" />
        </IconButton>
        <AlertDialog
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogBody textAlign="center">
                Are you sure you want to delete the following asset plan?
                <br />
                <Text as="strong">{floorPlanAsset.floorPlan.name}</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={onClose}
                  width="48%"
                  isLoading={deleting}
                >
                  No, Don't Delete!
                </Button>
                <Button
                  onClick={deleteAsset}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                  isLoading={deleting}
                >
                  Yes, Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Flex>
  );
};

export default AssetFloorPlan;

gql`
  mutation FloorPlanAssetDelete($id: UUID!) {
    floorPlanAssetDelete(id: $id)
  }
`;
