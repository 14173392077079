import { Icon, IconProps } from "@chakra-ui/react";

const WiringLowVoltage = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="m83.474 67.342-7.012 7.64a2.93 2.93 0 0 0 1.85 4.895l-3.77 4.212c-2.541 2.866 1.794 6.752 4.366 3.908l8.072-9.018a2.93 2.93 0 0 0-1.776-4.855l2.587-2.82a2.93 2.93 0 0 0-4.317-3.962Z"
      fill="currentColor"
    />
    <path
      d="M81.179 53.033c-2.774 0-5.443.461-7.933 1.31v-5.236H78.4a2.93 2.93 0 0 0 2.93-2.93V34.149a2.93 2.93 0 0 0-2.93-2.93h-7.995V16.93a2.93 2.93 0 0 0-5.86 0v14.29h-14.53V16.93a2.93 2.93 0 0 0-5.859 0v14.29h-8.724a2.93 2.93 0 0 0-2.93 2.93v12.028a2.93 2.93 0 0 0 2.93 2.93h5.154v30.94a2.929 2.929 0 0 0 2.673 2.916c1.093 6.105 5.549 10.909 11.17 12.02 1.19 2.119 3.832 7.711.34 10.445-.356.279-2.039.913-4.72.981-2.6.068-4.973-.428-6.51-1.355-2.242-1.095-1.928-3.561-1.596-5.772.53-3.852-1.101-7.526-4.363-9.828-3.137-2.215-7.15-2.618-10.223-1.027-1.935 1.003-5.181 3.684-5.181 10.376 0 5.912-.005 15.194-.005 15.195l5.86.002s.004-9.284.004-15.197c0-2.662.716-4.5 2.017-5.173 1.133-.587 2.8-.342 4.149.61.69.488 2.262 1.878 1.937 4.243-.931 4.74.142 9.172 4.373 11.587 5.329 3.217 14.432 2.663 17.87-.029 3.662-2.867 4.863-7.362 3.383-12.657a22.119 22.119 0 0 0-.978-2.75 13.124 13.124 0 0 0 1.841-.737c4.522 5.167 11.162 8.436 18.551 8.436 13.592 0 24.65-11.058 24.65-24.65 0-13.592-11.057-24.65-24.65-24.65ZM38.36 37.078h37.11v6.17H38.36v-6.17Zm29.026 12.029v8.155C60.986 61.6 56.723 68.863 56.536 77.116H46.445v-28.01h20.942ZM56.916 89.37c-3.56 0-6.584-2.684-7.648-6.395h7.834a24.467 24.467 0 0 0 2.162 5.985 7.038 7.038 0 0 1-2.348.41Zm24.263 7.103c-10.362 0-18.791-8.43-18.791-18.791s8.43-18.79 18.79-18.79c10.362 0 18.791 8.429 18.791 18.79 0 10.361-8.429 18.79-18.79 18.79Z"
      fill="currentColor"
    />
  </Icon>
);

export default WiringLowVoltage;
