import { useApolloClient } from "@apollo/client";
import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import { useUpdateCurrentCompanyMutation } from "../../graphql/graphql";
import { getRoutePath } from "../../router";

interface CompanyChangePageProps {}

const CompanyChangePage: React.FC<CompanyChangePageProps> = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { companyId } = useParams();
  const params = useSearchParams();
  const routerPath = params[0].get("routerPath");
  const routerParams = params[0].get("routerParams");
  const [updateCompanyMutation] = useUpdateCurrentCompanyMutation();
  useEffect(() => {
    const changeCompany = async () => {
      await updateCompanyMutation({
        variables: { id: companyId },
      });
      await client.resetStore();
      navigate(
        getRoutePath(
          routerPath || "dashboard",
          routerParams ? JSON.parse(routerParams) : {}
        )
      );
    };
    changeCompany();
  }, [client, companyId, navigate, updateCompanyMutation]);

  return <PageSpinner />;
};

export default CompanyChangePage;
