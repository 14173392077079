type Dict = Record<string, any>;

const Button = {
  baseStyle: {
    borderRadius: 0,
    border: 0,
    fontWeight: "700",
    textTransform: "uppercase",
  },
  sizes: {
    xs: {
      px: "2",
      py: "1",
      h: "32px",
    },
    sm: {
      px: "2",
      py: "1",
      h: "40px",
    },
    md: {
      px: "4",
      py: "2",
      h: "48px",
    },
    lg: {
      px: "4",
      py: "2",
      h: "56px",
      fontSize: "sm",
    },
  },
  variants: {
    unstyled: {
      textTransform: "inherit",
    },
    solid: ({ colorScheme }: Dict) => ({
      color: `${
        colorScheme === "primary"
          ? "black"
          : colorScheme === "gray"
          ? "gray.800"
          : "white"
      }`,
      letterSpacing: "0.7px",
    }),
    outline: {
      letterSpacing: "0.7px",
    },
    link: ({ colorScheme }: Dict) => ({
      fontWeight: "normal",
      fontSize: "base",
      textTransform: "normal",
      verticalAlign: "initial",
      _hover: {
        color: `${colorScheme}.600`,
        textDecoration: "none",
      },
    }),
    select: ({ colorScheme }: Dict) => ({
      border: "1px solid",
      borderColor: `${colorScheme}.500`,
      fontWeight: "400",
      textAlign: "left",
      width: "full",
      color: "black",
      _hover: {
        borderColor: `${colorScheme}.600`,
      },
    }),
    icon: ({ colorScheme }: Dict) => ({
      fontWeight: "normal",
      fontSize: "base",
      textTransform: "normal",
      verticalAlign: "initial",
      minWidth: "auto",
      height: "auto",
      padding: 0,
      color: ["grayRed", "graySecondary"].includes(colorScheme)
        ? "gray.500"
        : `${colorScheme}.500`,
      _hover: {
        color:
          colorScheme === "grayRed"
            ? "red.600"
            : colorScheme === "graySecondary"
            ? "secondary.600"
            : `${colorScheme}.600`,
        textDecoration: "none",
      },
      _active: {
        color: colorScheme === "grayRed" ? "red.700" : `${colorScheme}.700`,
      },
    }),
  },
  defaultProps: {
    colorScheme: "primary",
    size: "lg",
  },
};

export default Button;
