import { EuiText, useEuiTheme } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";

import Label from "../../components/label";
import Select from "../../components/select";

interface ViewEditSelectProps {
  selectedValue?: string | null;
  label?: string | null;
  options: Array<EuiSuperSelectOption<any>>;
  onSelect: (value: any) => void;
  required?: boolean;
  placeholder?: string;
  style?: any;
  className?: string;
  compressed?: boolean;
  disabled?: boolean;
}

const ViewEditSelect: React.FC<ViewEditSelectProps> = ({
  selectedValue,
  label,
  onSelect,
  className = "",
  placeholder,
  options,
  compressed = false,
  style = {},
  required = false,
  disabled,
}) => {
  const [errors, setErrors] = useState<string[]>();
  const [value, setValue] = useState<string>(selectedValue || "");
  const [displayText, setDisplayText] = useState<string>("");
  const [isInput, setIsInput] = useState<boolean>(false);

  const checkErrors = () => {
    if (required && !value) {
      setErrors([`${label || "Value"} is required`]);
      return true;
    }
    setErrors([]);
    return false;
  };

  const _onSelect = (selectedId: string) => {
    setValue(selectedId);
    onSelect(selectedId);
    setIsInput(false);
  };

  useEffect(() => {
    if (selectedValue !== value) {
      setValue(selectedValue || "");
    }
  }, [selectedValue]);

  useEffect(() => {
    if (options?.length && value) {
      const text = options.find((i) => i.value === value)?.inputDisplay || "";
      if (typeof text === "string") {
        setDisplayText(text);
      } else {
        console.log("need to handle the case");
      }
    } else {
      setDisplayText("");
    }
  }, [value, options]);

  const onDropdownClosed = () => {
    const isErrors = checkErrors();
    setIsInput(isErrors);
  };

  style = {
    fontSize: "14px",
    fontWeight: 500,
    ...style,
  };
  return (
    <>
      <div style={{ position: "relative" }} className={className}>
        {label && (
          <Label
            label={label || ""}
            required={required}
            style={{ paddingBottom: "5px" }}
          />
        )}
        {isInput && (
          <Select
            placeholder={placeholder}
            options={options}
            selectedValue={value}
            invalid={!!errors?.length}
            errors={errors}
            onSelect={_onSelect}
            onDropdownClosed={onDropdownClosed}
            compressed={compressed}
            initiallyOpened={true}
            disabled={disabled}
          />
        )}
        {!isInput && (
          <EuiText
            title={value}
            className={"ellipsis"}
            style={{
              ...style,
              cursor: disabled ? "default" : "pointer",
              pointerEvents: disabled ? "none" : "auto",
              borderRadius: "0",
              marginBottom: "0",
              height: !compressed ? "40px" : "30px",
              letterSpacing: "normal",
              lineHeight: !compressed ? "40px" : "30px",
            }}
            onClick={() => setIsInput(true)}
            css={css`
              color: var(--color-text);

              &:hover {
                background: var(--color-hover);
              }
            `}
          >
            {displayText || ""}
          </EuiText>
        )}
        {!displayText && placeholder && !isInput && (
          <EuiText
            className={"placeholder"}
            style={{
              top: "unset",
              left: "4px",
              bottom: compressed ? "6px" : "10px",
              lineHeight: "21px",
            }}
          >
            {placeholder}
          </EuiText>
        )}
      </div>
    </>
  );
};

export default ViewEditSelect;
