import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";

import AssetPartFields, {
  getAssetPartFieldsInitialValues,
  getAssetPartFieldsValidationSchema,
} from "../../../../components/elements/assetPartFields";
import FormGroup from "../../../../components/elements/formGroup";
import NumberInput from "../../../../components/elements/numberInput";
import { BREAKER_DELETED_MESSAGE } from "../../../../constants/lang/en";
import { DEFAULT_BREAKER_STARTING_NUMBER } from "../../../../constants/misc";
import {
  AssetPartFieldValuesInput,
  AssetPartWithRelationsFragmentFragment,
  AssetWithRelationsFragmentFragment,
  SpecialAssetFieldTypes,
  useAssetPartDeleteMutation,
  useAssetUpdateConfigMutation,
} from "../../../../graphql/graphql";
import { setGenericMessage } from "../../../../utils/serverErrors";
import {
  columnsIndexSchema,
  descriptionSchema,
  gridPanelBreakerPoles,
  nameSchema,
  normalPanelBreakerPoles,
  rowsIndexSchema,
  yupObject,
} from "../../../../utils/validation";

interface AssetPartEditPresenterProps {
  handleSubmit: (
    values: AssetPartFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  onDrawerClose: () => void;
  isOpen: boolean;
  loading: boolean;
  assetPart: AssetPartWithRelationsFragmentFragment;
  asset: AssetWithRelationsFragmentFragment;
  rowIndex?: number;
  columnIndex?: number;
}

const AssetPartEditPresenter: React.FC<AssetPartEditPresenterProps> = ({
  isOpen,
  handleSubmit,
  onDrawerClose,
  loading,
  assetPart,
  asset,
  rowIndex,
  columnIndex,
}) => {
  const toast = useToast();
  const {
    assetType,
    config: { breakerStartingNumber = DEFAULT_BREAKER_STARTING_NUMBER },
  } = asset;
  const isGridPanel =
    assetType.specialAssetField === SpecialAssetFieldTypes.ElectricalPanelGrid;
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: openDelete,
    onClose: closeDelete,
  } = useDisclosure();
  const { assetPartPositions = {} } = asset.config;

  const [assetPartDeleteMutation, { loading: deleteLoading }] =
    useAssetPartDeleteMutation();
  const [assetUpdateConfigMutation, { loading: configLoading }] =
    useAssetUpdateConfigMutation();

  const validationSchema = React.useMemo(
    () =>
      yupObject().shape({
        name: nameSchema.label("Asset part name"),
        poles: isGridPanel ? gridPanelBreakerPoles : normalPanelBreakerPoles,
        rowIndex: rowsIndexSchema,
        columnIndex: columnsIndexSchema,
        description: descriptionSchema,
        ...getAssetPartFieldsValidationSchema(assetType),
      }),
    [assetType, isGridPanel]
  );

  const initialValues = React.useMemo(
    () => ({
      name: assetPart.name,
      description: assetPart.description,
      poles: Object.values(assetPartPositions).filter(
        (id) => id === assetPart.id
      ).length,
      columnIndex,
      rowIndex,
      ...getAssetPartFieldsInitialValues(assetType, assetPart),
    }),
    [assetType, assetPart, columnIndex, rowIndex, assetPartPositions]
  );

  const onSubmit = React.useCallback(
    (data: any, formikHelpers: any) => {
      const formattedData: AssetPartFormData = {
        name: data.name,
        poles: data.poles,
        rowIndex: data.rowIndex,
        columnIndex: data.columnIndex,
        description: data.description,
        assetPartFieldValuesInput: [],
      };
      assetType.assetPartFields.forEach((assetPartField) => {
        if (data[assetPartField.id]) {
          formattedData.assetPartFieldValuesInput.push({
            assetPartFieldId: assetPartField.id,
            value: `${data[assetPartField.id]}`,
          });
        }
      });
      handleSubmit(formattedData, formikHelpers);
    },
    [assetType, handleSubmit]
  );

  const deleteAssetPart = React.useCallback(async () => {
    try {
      const { errors } = await assetPartDeleteMutation({
        variables: { ids: [assetPart.id] },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        const newBreakerAssetPartPositions: any = {};
        Object.keys(assetPartPositions).forEach((app) => {
          if (assetPartPositions[app] !== assetPart.id) {
            newBreakerAssetPartPositions[app] = assetPartPositions[app];
          }
        });
        const { data: serverConfigData, errors } =
          await assetUpdateConfigMutation({
            variables: {
              id: asset.id,
              config: {
                ...asset.config,
                assetPartPositions: newBreakerAssetPartPositions,
              },
            },
          });

        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
        } else if (serverConfigData) {
          onDrawerClose();
          toast({
            description: BREAKER_DELETED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [
    assetPartDeleteMutation,
    assetUpdateConfigMutation,
    toast,
    asset,
    onDrawerClose,
    assetPart.id,
    assetPartPositions,
  ]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>
          Edit {assetPart.name}{" "}
          {assetType.specialAssetField ===
            SpecialAssetFieldTypes.ElectricalPanelNormal &&
          typeof columnIndex === "number" &&
          columnIndex >= 0 &&
          typeof rowIndex === "number" &&
          rowIndex >= 0
            ? `(#${breakerStartingNumber + +rowIndex * 2 + +columnIndex})`
            : ""}
        </title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Edit {assetPart.name}{" "}
            {assetType.specialAssetField ===
              SpecialAssetFieldTypes.ElectricalPanelNormal &&
            typeof columnIndex === "number" &&
            columnIndex >= 0 &&
            typeof rowIndex === "number" &&
            rowIndex >= 0
              ? `(#${breakerStartingNumber + +rowIndex * 2 + +columnIndex})`
              : ""}
          </DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form id="asset_update" noValidate>
                  <FormGroup label="Breaker Name" name="name">
                    <Input
                      autoFocus
                      autoComplete="off"
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  {typeof columnIndex !== "number" && (
                    <NumberInput
                      label="Column Index"
                      name="columnIndex"
                      defaultValue={1}
                      min={0}
                    />
                  )}
                  {typeof rowIndex !== "number" && (
                    <NumberInput
                      label="Row Index"
                      name="rowIndex"
                      defaultValue={1}
                      min={0}
                    />
                  )}
                  {typeof rowIndex === "number" &&
                    rowIndex >= 0 &&
                    typeof columnIndex === "number" &&
                    columnIndex >= 0 && (
                      <NumberInput
                        label="Poles"
                        name="poles"
                        defaultValue={1}
                        min={1}
                        max={isGridPanel ? 10 : 3}
                      />
                    )}
                  <FormGroup label="Description" name="description">
                    <Textarea
                      autoComplete="off"
                      {...getFieldProps("description")}
                    />
                  </FormGroup>
                  {!!assetType && (
                    <AssetPartFields
                      assetPartFields={assetType.assetPartFields}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </DrawerBody>
          <DrawerFooter flexDirection="column">
            <Button
              width="full"
              type="submit"
              isLoading={loading}
              form="asset_update"
            >
              Save
            </Button>
            <Button
              type="button"
              variant="link"
              colorScheme="red"
              color="gray.500"
              marginTop="2"
              onClick={openDelete}
              fontSize="12px"
            >
              Delete this breaker
            </Button>
            <AlertDialog
              leastDestructiveRef={cancelRef}
              onClose={closeDelete}
              isOpen={isDeleteOpen}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogBody textAlign="center">
                    <Text marginBottom="4">
                      Are you sure you want to delete the following breaker?
                      <br />
                      <Text as="strong">{assetPart.name}</Text>
                    </Text>
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button
                      ref={cancelRef}
                      onClick={closeDelete}
                      width="48%"
                      isLoading={deleteLoading || configLoading}
                    >
                      No, Don't Delete!
                    </Button>
                    <Button
                      onClick={deleteAssetPart}
                      colorScheme="red"
                      ml="4%"
                      width="48%"
                      isLoading={deleteLoading || configLoading}
                    >
                      Yes, Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetPartEditPresenter;

export type AssetPartFormData = {
  name: string;
  description: string;
  assetPartFieldValuesInput: AssetPartFieldValuesInput[];
  poles: number;
  columnIndex: number;
  rowIndex: number;
};

gql`
  mutation AssetPartDelete($ids: [UUID!]!) {
    assetPartDelete(ids: $ids)
  }
`;
