import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ASSET_CREATED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetIndexDocument,
  CompanyStartUpDocument,
  useAssetCreateMutation,
} from "../../../graphql/graphql";
import useAssetTypes from "../../../hooks/useAssetTypes";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import Presenter, { AssetsFormData } from "./presenter";

interface AssetsCreatePageProps {
  afterCreate?: (assetId: string) => void;
  afterCancel?: () => void;
  goBack?: boolean;
  loading?: boolean;
  assetTypeIdToAdd?: string;
}

const AssetsCreatePage: React.FC<AssetsCreatePageProps> = ({
  afterCreate,
  afterCancel,
  goBack = false,
  loading: loadingProp = false,
  assetTypeIdToAdd,
}) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [assetsCreateMutation, { loading }] = useAssetCreateMutation({
    // awaitRefetchQueries: true,
    refetchQueries: [
      { query: AssetIndexDocument },
      { query: CompanyStartUpDocument },
    ],
  });

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (
    data: AssetsFormData,
    { setFieldError }: FormikHelpers<AssetsFormData>
  ) => {
    try {
      const { data: serverData, errors } = await assetsCreateMutation({
        variables: { data: [data] },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        if (afterCreate) afterCreate(serverData.assetCreate[0].id);
        onDrawerClose();
        toast({
          description: ASSET_CREATED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    if (afterCancel) afterCancel();
    if (goBack) {
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
  };

  const assetTypes = useAssetTypes();

  return assetTypes ? (
    <Presenter
      handleSubmit={onSubmit}
      onDrawerClose={onDrawerClose}
      isOpen={isOpen}
      loading={loading || loadingProp}
      assetTypes={assetTypes}
      assetTypeIdToAdd={assetTypeIdToAdd}
    />
  ) : null;
};

export default AssetsCreatePage;

gql`
  mutation AssetCreate($data: [AssetCreateInput!]!) {
    assetCreate(data: $data) {
      ...AssetWithRelationsFragment
    }
  }
`;
