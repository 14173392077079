import { Center, Image } from "@chakra-ui/react";
import React, { Fragment } from "react";

import { getFilesUrl } from "../../../utils/image";
import AudioIcon from "../../icons/fileTypes/audio";
import CSVIcon from "../../icons/fileTypes/csv";
import DocxIcon from "../../icons/fileTypes/docx";
import DefaultFileIcon from "../../icons/fileTypes/file";
import ImageIcon from "../../icons/fileTypes/image";
import PDFIcon from "../../icons/fileTypes/pdf";
import PptIcon from "../../icons/fileTypes/ppt";
import VideoIcon from "../../icons/fileTypes/video";
import XlsIcon from "../../icons/fileTypes/xls";
import ZipIcon from "../../icons/fileTypes/zip";
import Pdf from "../pdf";
import ZoomWrapper from "../zoomWrapper";

interface FileIconProps {
  signedUrl?: string;
  filePath: string;
  fileName: string;
  forceIcon?: boolean;
  width: number;
  height?: number;
  imageWidth?: number;
}

const FileIcon: React.FC<FileIconProps> = ({
  signedUrl,
  filePath,
  fileName,
  forceIcon,
  width,
  imageWidth,
  height = "auto",
}) => {
  let ext = filePath.split(".").pop();
  ext = ext ? ext.toLowerCase() : ext;
  const heightString = height === "auto" ? height : `${height}px`;

  if (!forceIcon && ext) {
    if (["jpg", "jpeg", "png", "gif", "webp"].includes(ext)) {
      const Component = width > 200 ? ZoomWrapper : Fragment;
      const resolvedWidth = width > 200 ? "full" : `${width}px`;
      return (
        <Component>
          <Image
            src={getFilesUrl(filePath, imageWidth || width)}
            alt={fileName}
            width={resolvedWidth}
            height={heightString}
            display="inline-block"
            fallback={
              <Center
                height={heightString}
                width={resolvedWidth}
                bgColor="gray.100"
                margin="auto"
              />
            }
            fit={height ? "cover" : "none"}
          />
        </Component>
      );
    }

    if (ext === "pdf" && signedUrl && width > 200) {
      return (
        <Pdf
          showNavAndInfo={false}
          signedUrl={signedUrl}
          width={width}
          height={typeof height === "number" ? height : undefined}
        />
      );
    }
  }

  let Icon = fileFromExt(ext);
  return <Icon width={`${width}px`} height={heightString} />;
};

export default FileIcon;

const fileFromExt = (ext?: string) => {
  if (!ext) return DefaultFileIcon;
  switch (ext) {
    case "pdf":
      return PDFIcon;
    case "jpg":
      return ImageIcon;
    case "jpeg":
    case "png":
    case "gif":
    case "webp":
    case "jfif":
    case "tiff":
    case "bmp":
    case "ppm":
    case "pgm":
      return ImageIcon;
    case "csv":
      return CSVIcon;
    case "docx":
    case "doc":
    case "docm":
    case "dotx":
    case "dotm":
    case "docb":
      return DocxIcon;
    case "ppt":
    case "pptx":
    case "pptm":
    case "potx":
    case "potm":
    case "ppsx":
    case "ppsm":
    case "sldx":
    case "sldm":
      return PptIcon;
    case "mp4":
    case "webm":
    case "mkv":
    case "flv":
    case "vob":
    case "ogv":
    case "ogg":
    case "avi":
    case "mov":
    case "wmv":
    case "m4v":
    case "m4p":
    case "mpg":
    case "mpeg":
    case "3gp":
      return VideoIcon;
    case "mp3":
    case "oga":
    case "mogg":
    case "wav":
    case "mpga":
      return AudioIcon;
    case "xls":
    case "xlt":
    case "xlm":
    case "xlsx":
    case "xlsm":
    case "xltx":
    case "xltm":
    case "xlsb":
    case "xla":
    case "xlam":
    case "xll":
    case "xlw":
      return XlsIcon;
    case "zip":
    case "x-zip-compressed":
    case "iso":
    case "bz2":
    case "gz":
    case "tar":
    case "7z":
    case "dmg":
    case "rar":
      return ZipIcon;
    default:
      return DefaultFileIcon;
  }
};
