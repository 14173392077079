import { useApolloClient } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { zonedTimeToUtc } from "date-fns-tz";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ASSET_MAINTENANCE_CREATED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyStartUpDocument,
  MaintenanceIndexDocument,
  useMaintenanceCreateMutation,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import Presenter, { MaintenanceFormData } from "./presenter";

interface MaintenanceCreateProps {}

const MaintenanceCreate: React.FC<MaintenanceCreateProps> = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const client = useApolloClient();
  const startTime = searchParams.get("startTime");
  const endTime = searchParams.get("endTime");
  const assetId = searchParams.get("assetId");
  const toast = useToast();
  const [maintenanceCreateMutation, { loading }] = useMaintenanceCreateMutation(
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: MaintenanceIndexDocument,
        },
        {
          query: CompanyStartUpDocument,
        },
      ],
    }
  );

  const onSubmit = async (
    data: MaintenanceFormData,
    { setFieldError }: FormikHelpers<MaintenanceFormData>
  ) => {
    try {
      const { data: serverData, errors } = await maintenanceCreateMutation({
        variables: {
          data: {
            ...data,
            startDateTime: zonedTimeToUtc(
              data.startDateTime,
              data.timezone
            ).getTime(),
            endDateTime: data.endDateTime
              ? zonedTimeToUtc(data.endDateTime, data.timezone).getTime()
              : null,
          },
        },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        if (assetId) {
          client.cache.evict({
            id: `Asset:${assetId}`,
            fieldName: "maintenances",
          });
        }
        onDrawerClose();
        toast({
          description: ASSET_MAINTENANCE_CREATED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Presenter
      handleSubmit={onSubmit}
      loading={loading}
      isOpen
      onDrawerClose={onDrawerClose}
      assetId={assetId}
      startTime={startTime ? new Date(+startTime) : undefined}
      endTime={endTime ? new Date(+endTime) : undefined}
    />
  );
};

export default MaintenanceCreate;

gql`
  mutation MaintenanceCreate($data: MaintenanceInput!) {
    maintenanceCreate(data: $data) {
      ...MaintenanceWithContactsFragment
    }
  }
  mutation MaintenanceUpdate($id: UUID!, $data: MaintenanceInput!) {
    maintenanceUpdate(id: $id, data: $data) {
      ...MaintenanceWithContactsFragment
    }
  }

  mutation MaintenanceNoteCreate($data: MaintenanceNoteInput!) {
    maintenanceNoteCreate(data: $data) {
      ...MaintenanceNoteFragment
    }
  }
  mutation MaintenanceNoteUpdate($id: UUID!, $data: MaintenanceNoteInput!) {
    maintenanceNoteUpdate(id: $id, data: $data) {
      ...MaintenanceNoteFragment
    }
  }
  mutation MaintenanceNoteDelete($id: UUID!) {
    maintenanceNoteDelete(id: $id)
  }
`;
