import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { useCallback, useEffect, useState } from "react";

import { useWOFloorPlansQuery } from "../../../../../../graphql/queries/workOrders";
import { WOFloorPlan } from "../../../../../../graphql/types/queries/workOrdersQuery";
import TruncatedText from "../../../../components/truncatedText";
import GetLocationBreadcrumbs from "../../../../hooks/getLocationBreadcrumbs";
import { AssetPlansTypeEnum } from "../../../../models/enums";
import {
  WorkOrderAsset,
  WorkOrderAssetPlan,
} from "../../../../models/workOrder";
import WorkOrderCreatePlanPreviewModal from "../../addAsset/plansPreviewModal";
import { FloorPlansItem } from "../../addAsset/plansTable/presenter";

interface AssetInfoAccordionProps {
  asset: WorkOrderAsset;
  companyId?: string;
}

const AssetInfoAccordion: React.FC<AssetInfoAccordionProps> = ({
  asset,
  companyId,
}) => {
  const breadcrumbsMaxWidth = "250px";
  const { data, loading } = useWOFloorPlansQuery({
    fetchPolicy: "cache-first",
    variables: {
      assetId: asset.assetId,
    },
    skip: !asset.assetId || !asset.plans?.length,
  });
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const [plans, setPlans] = useState<WorkOrderAssetPlan[]>([]);
  const [areas, setAreas] = useState<WorkOrderAssetPlan[]>([]);
  const [previewedPlan, setPreviewedPlan] = useState<WOFloorPlan | null>(null);
  const [previewedPlanItem, setPreviewedPlanItem] =
    useState<FloorPlansItem | null>(null);

  const handlePlanPreview = useCallback(
    (selectedPlan: WorkOrderAssetPlan) => {
      setPreviewedPlanItem(selectedPlan as FloorPlansItem);
      const previewedPlan = data?.workOrderFloorPlans.find(
        (plan) => plan.id === selectedPlan.planId
      );
      setPreviewedPlan(previewedPlan || null);
    },
    [data]
  );

  const handlePlanPreviewModalClose = () => {
    setPreviewedPlan(null);
    setPreviewedPlanItem(null);
  };

  const subLocationBreadcrumbs =
    asset.sublocationId && companyId
      ? GetLocationBreadcrumbs(asset.sublocationId, companyId)
      : "";

  useEffect(() => {
    if (asset && asset.plans?.length) {
      setPlans(
        asset.plans.filter(
          (plan) => plan.type === AssetPlansTypeEnum.ASSET_PLAN
        )
      );
      setAreas(
        asset.plans.filter(
          (plan) => plan.type === AssetPlansTypeEnum.AFFECTED_AREA
        )
      );
    } else {
      setPlans([]);
      setAreas([]);
    }
  }, [asset]);

  return (
    <>
      <EuiFlexGroup
        direction="column"
        wrap={false}
        justifyContent="flexStart"
        style={{ minWidth: "100%" }}
      >
        <EuiFlexGroup
          direction="row"
          wrap={false}
          justifyContent="spaceBetween"
          style={{ minWidth: "100%", gap: euiTheme.size.s }}
        >
          <EuiFlexItem style={{ flexGrow: 1, flexDirection: "column" }}>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: euiTheme.font.weight.bold,
                lineHeight: euiTheme.sizes["21px"],
              }}
              color={euiTheme.colors.darkShade}
            >
              Asset Plan
            </EuiText>
            {!!plans?.length ? (
              <EuiFlexGroup
                direction={"column"}
                wrap={false}
                style={{ gap: 0 }}
              >
                {plans.map((plan, index) => (
                  <EuiLink
                    key={"plan" + plan.planId + index}
                    disabled={loading}
                    onClick={() => handlePlanPreview(plan)}
                  >
                    {plan.name}
                  </EuiLink>
                ))}
              </EuiFlexGroup>
            ) : null}
          </EuiFlexItem>
          <EuiFlexItem style={{ flexGrow: 1, flexDirection: "column" }}>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: euiTheme.font.weight.bold,
                lineHeight: euiTheme.sizes["21px"],
              }}
              color={euiTheme.colors.darkShade}
            >
              Affected Area
            </EuiText>
            {!!areas?.length ? (
              <EuiFlexGroup
                direction={"column"}
                wrap={false}
                style={{ gap: 0 }}
              >
                {areas.map((area, index) => (
                  <EuiLink
                    key={"area" + area.planId + index}
                    disabled={loading}
                    onClick={() => handlePlanPreview(area)}
                  >
                    {area.name}
                  </EuiLink>
                ))}
              </EuiFlexGroup>
            ) : null}
          </EuiFlexItem>
          <EuiFlexItem style={{ flexGrow: 2, flexDirection: "column" }}>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: euiTheme.font.weight.bold,
                lineHeight: euiTheme.sizes["21px"],
              }}
              color={euiTheme.colors.darkShade}
            >
              Sublocation
            </EuiText>
            <TruncatedText
              text={subLocationBreadcrumbs}
              truncate={true}
              maxWidth={breadcrumbsMaxWidth}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup
          direction="row"
          wrap={false}
          justifyContent="spaceBetween"
          style={{ minWidth: "100%", gap: euiTheme.size.s }}
        >
          <EuiFlexItem style={{ flexGrow: 1, flexDirection: "column" }}>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: euiTheme.font.weight.bold,
                lineHeight: euiTheme.sizes["21px"],
              }}
              color={euiTheme.colors.darkShade}
            >
              Fed From
            </EuiText>

            {!!asset.asset?.fedFrom?.length ? (
              <EuiFlexGroup
                direction={"column"}
                wrap={false}
                style={{ gap: 0 }}
              >
                {asset.asset.fedFrom.map((fedFrom) => (
                  <TruncatedText key={fedFrom.id} text={fedFrom.name} />
                ))}
              </EuiFlexGroup>
            ) : null}
            {!!asset.asset?.assetAffectedByAssets?.length ? (
              <EuiFlexGroup
                direction={"column"}
                wrap={false}
                style={{ gap: 0 }}
              >
                {asset.asset.assetAffectedByAssets.map((fedFrom) => (
                  <TruncatedText key={fedFrom.id} text={fedFrom.asset?.name} />
                ))}
              </EuiFlexGroup>
            ) : null}
          </EuiFlexItem>
          <EuiFlexItem style={{ flexGrow: 1, flexDirection: "column" }}>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: euiTheme.font.weight.bold,
                lineHeight: euiTheme.sizes["21px"],
              }}
              color={euiTheme.colors.darkShade}
            >
              Fed To
            </EuiText>
            {!!asset.asset?.fedTo?.length ? (
              <EuiFlexGroup
                direction={"column"}
                wrap={false}
                style={{ gap: 0 }}
              >
                {asset.asset.fedTo.map((fedTo) => (
                  <TruncatedText key={fedTo.id} text={fedTo.name} />
                ))}
              </EuiFlexGroup>
            ) : null}
            {!!asset.asset?.assetAffectedAssets?.length ? (
              <EuiFlexGroup
                direction={"column"}
                wrap={false}
                style={{ gap: 0 }}
              >
                {asset.asset.assetAffectedAssets.map((fedTo) => (
                  <TruncatedText
                    key={fedTo.id}
                    text={fedTo.affectedAsset?.name}
                  />
                ))}
              </EuiFlexGroup>
            ) : null}
          </EuiFlexItem>
          <EuiFlexItem style={{ flexGrow: 2, flexDirection: "column" }}>
            <EuiText
              style={{
                fontSize: euiTheme.size.m,
                fontWeight: euiTheme.font.weight.bold,
                lineHeight: euiTheme.sizes["21px"],
              }}
              color={euiTheme.colors.darkShade}
            >
              Comment
            </EuiText>
            <EuiText
              style={{
                fontSize: euiTheme.sizes["14px"],
                lineHeight: euiTheme.sizes["21px"],
              }}
              color={euiTheme.colors.text}
            >
              {asset.comment}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
      <WorkOrderCreatePlanPreviewModal
        isPlanPreviewOpen={!!previewedPlan}
        assetId={asset.assetId}
        companyId={companyId}
        plan={previewedPlan}
        planItem={previewedPlanItem}
        sublocationId={asset.sublocationId}
        closeModal={handlePlanPreviewModalClose}
      />
    </>
  );
};

export default AssetInfoAccordion;
