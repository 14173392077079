import { Icon, IconProps } from "@chakra-ui/react";

const CardReader = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M89.002 14h-49.98c-7.121 0-12.915 5.794-12.915 12.914v50.253c0 7.121 5.794 12.914 12.914 12.914h1.153v14.928c0 4.682 3.809 8.491 8.491 8.491h30.693c4.682 0 8.491-3.809 8.491-8.491V90.081h1.153c7.122 0 12.914-5.793 12.914-12.914V26.914c0-7.12-5.792-12.914-12.914-12.914Zm-42.997 91.009V90.081h4.124v17.589h-1.464a2.663 2.663 0 0 1-2.66-2.661Zm9.954 2.661V90.081h7.767v17.589H55.96Zm26.06-2.661a2.664 2.664 0 0 1-2.66 2.661h-9.803V90.081H82.02v14.928Zm14.067-27.842a7.092 7.092 0 0 1-7.084 7.084h-49.98a7.092 7.092 0 0 1-7.085-7.084V26.914a7.092 7.092 0 0 1 7.084-7.084h49.981a7.092 7.092 0 0 1 7.084 7.084v50.253Z"
      fill="currentColor"
    />
    <path
      d="M85.484 22.205H42.54a2.915 2.915 0 0 0-2.915 2.915V39.1a2.915 2.915 0 0 0 2.915 2.915h42.945a2.915 2.915 0 0 0 2.916-2.915V25.12a2.915 2.915 0 0 0-2.916-2.915ZM82.57 36.186H45.454v-8.151H82.57v8.151ZM52.77 50.787a2.978 2.978 0 0 0-2.981-2.975 2.977 2.977 0 0 0-2.98 2.975 2.977 2.977 0 0 0 2.98 2.975 2.977 2.977 0 0 0 2.98-2.975ZM65.52 50.787a2.978 2.978 0 0 0-2.98-2.975 2.978 2.978 0 0 0-2.98 2.975 2.978 2.978 0 0 0 2.98 2.975 2.978 2.978 0 0 0 2.98-2.975ZM78.273 50.787a2.978 2.978 0 0 0-2.98-2.975 2.978 2.978 0 0 0-2.981 2.975 2.978 2.978 0 0 0 2.98 2.975 2.978 2.978 0 0 0 2.98-2.975ZM52.77 62.42a2.977 2.977 0 0 0-2.981-2.975 2.977 2.977 0 0 0-2.98 2.975 2.977 2.977 0 0 0 2.98 2.975 2.978 2.978 0 0 0 2.98-2.975ZM65.52 62.42a2.978 2.978 0 0 0-2.98-2.975 2.978 2.978 0 0 0-2.98 2.975 2.978 2.978 0 0 0 2.98 2.975 2.978 2.978 0 0 0 2.98-2.975ZM78.273 62.42a2.978 2.978 0 0 0-2.98-2.975 2.978 2.978 0 0 0-2.981 2.975 2.978 2.978 0 0 0 2.98 2.975 2.978 2.978 0 0 0 2.98-2.975ZM52.77 74.052a2.978 2.978 0 0 0-2.981-2.975 2.977 2.977 0 0 0-2.98 2.975 2.977 2.977 0 0 0 2.98 2.975 2.977 2.977 0 0 0 2.98-2.975ZM65.52 74.052a2.978 2.978 0 0 0-2.98-2.975 2.978 2.978 0 0 0-2.98 2.975 2.978 2.978 0 0 0 2.98 2.975 2.978 2.978 0 0 0 2.98-2.975ZM78.273 74.052a2.978 2.978 0 0 0-2.98-2.975 2.978 2.978 0 0 0-2.981 2.975 2.978 2.978 0 0 0 2.98 2.975 2.978 2.978 0 0 0 2.98-2.975Z"
      fill="currentColor"
    />
  </Icon>
);

export default CardReader;
