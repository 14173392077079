import { useCallback, useEffect } from "react";

import { useCompanyQuery, useUserQuery } from "../graphql/graphql";

const useZendesk = (alwaysOpen = false) => {
  const { data } = useCompanyQuery();
  const { data: userData } = useUserQuery();

  const initializeZendesk = useCallback(
    (name = "", email = "", phone = "", organization = "") => {
      const ze = (window as any).zE;
      if (ze) {
        ze("webWidget", "show");
        ze("webWidget", "identify", { name, email, organization });
        ze("webWidget", "prefill", {
          name: { value: name, readOnly: false },
          email: { value: email, readOnly: false },
          phone: { value: phone, readOnly: false },
        });
        if (alwaysOpen) {
          ze("webWidget", "open");
          ze("webWidget:on", "close", () =>
            setTimeout(ze("webWidget", "open"), 250)
          );
        }
      } else {
        setTimeout(() => initializeZendesk(name, email, organization), 500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!userData || !data) return;
    initializeZendesk(
      userData.user.fullName,
      userData.user.email,
      userData.user.phone,
      data.company.name
    );
    return () => (window as any).zE("webWidget", "hide");
  }, [data, initializeZendesk, userData]);
};

export default useZendesk;

export const ZendeskSettings = `
window.zESettings = {
  webWidget: {
    zIndex: 40,
    offset: { vertical: "25px" },
    answerBot: {
      contactOnlyAfterQuery: true,
      avatar: { url: 'https://app.criticalasset.com/icon.png' }
    },
  }
}
`;
