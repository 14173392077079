import RRule, { RRuleSet } from "rrule";

import { MaintenanceIntervalType } from "../../graphql/graphql";

export const getRruleFrequency = (intervalType: MaintenanceIntervalType) => {
  switch (intervalType) {
    case MaintenanceIntervalType.Years:
      return 0;
    case MaintenanceIntervalType.Months:
      return 1;
    case MaintenanceIntervalType.Weeks:
      return 2;
    case MaintenanceIntervalType.Days:
      return 3;
  }
};

interface MaintenanceWithIntervalValues {
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  startDateTime: any;
  endDateTime?: any;
  endAfter?: number;
  deletedScheduleTimestamps: Array<string>;
}

export const generateRruleSetForMaintenance = (
  maintenance: MaintenanceWithIntervalValues,
  withExceptions = true
) => {
  const rruleSet = new RRuleSet();
  if (maintenance.intervalType && maintenance.intervalValue) {
    rruleSet.rrule(
      new RRule({
        freq: getRruleFrequency(maintenance.intervalType),
        interval: maintenance.intervalValue,
        dtstart: new Date(maintenance.startDateTime),
        until: maintenance.endDateTime
          ? new Date(maintenance.endDateTime)
          : undefined,
        count: maintenance.endAfter,
      })
    );
  } else {
    rruleSet.rrule(
      new RRule({
        dtstart: new Date(maintenance.startDateTime),
        count: 1,
      })
    );
  }
  if (withExceptions) {
    maintenance.deletedScheduleTimestamps.forEach((timestamp) => {
      if (timestamp) rruleSet.exdate(new Date(+timestamp));
    });
  }
  return rruleSet;
};
