import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CellClassParams,
  EditableCallbackParams,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  NewValueParams,
  RowNode,
} from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FieldType } from "../../../graphql/graphql";
import { SheetDataRowItem } from "../../../pages/assets/show/details/assetPartBulkAdd";
import Logo from "../../icons/logo";
import AgGridCheckbox from "./components/editors/checkbox";
import AgGridNumberInput from "./components/editors/numberInput";
import AgGridSelect from "./components/editors/select";
import AgGridCheckboxRenderer from "./components/renderer/checkbox";

interface ColumnStyleProps {
  bgColor?: string;
  minWidth?: number;
  maxWidth?: number;
  centerAligned?: boolean;
  hidden?: boolean;
}
export interface ColumnProps {
  field: string;
  headerName?: string;
  headerTooltip?: string;
  cellType: string;
  editable?: boolean;
  styleProps?: ColumnStyleProps;
  min?: number;
  max?: number;
  assetPartId?: string;
  selectOptions?: { label: string; value: string }[];
}

interface SpreadsheetProps {
  columns: ColumnProps[];
  errors: boolean[];
  data: SheetDataRowItem[];
  handleCellValueChange?: (props: {
    newValueParams: NewValueParams;
    sheetData: SheetDataRowItem[];
    refreshCells: any;
  }) => void;
  handleSubmit: (values: SheetDataRowItem[]) => void;
  isLoading?: boolean;
  onClose: () => void;
  modalHeaderContent?: React.ReactElement;
}

const Spreadsheet: React.FC<SpreadsheetProps> = ({
  columns,
  data,
  errors,
  handleCellValueChange,
  handleSubmit,
  isLoading = false,
  onClose,
  modalHeaderContent,
}) => {
  const [gridController, setGridController] = useState<GridReadyEvent>();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const isValueChanged = React.useRef<boolean>(false);

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const cellRenderer = useCallback(
    (props: ICellRendererParams) => (
      <Box
        textAlign={
          props.colDef?.cellEditorParams.centerAligned ? "center" : "left"
        }
        bgColor={props.colDef?.cellEditorParams.bgColor ?? "transparent"}
      >
        {props.colDef?.cellEditorParams.values?.length && props.value
          ? props.colDef?.cellEditorParams.cellType === FieldType.Select
            ? props.colDef?.cellEditorParams.values.find(
                (option: any) => option.value === props.value
              )?.label
            : props.colDef?.cellEditorParams.cellType === "MULTISELECT"
            ? props.colDef?.cellEditorParams.values.find(
                (option: any) => option.value === props.value[0]
              )?.label
            : props.value
          : props.value}
      </Box>
    ),
    []
  );

  const refreshCells = useCallback(
    ({ rows, columns }: { rows: number[]; columns?: string[] }) => {
      const rowNodes: RowNode[] = [];
      rows.forEach((row) => {
        const rowNode = gridController?.api.getDisplayedRowAtIndex(row);
        if (rowNode) {
          rowNodes.push(rowNode);
        }
      });
      gridController?.api.refreshCells({
        force: true,
        rowNodes,
        columns,
      });
    },
    [gridController?.api]
  );

  const getColumnDefs = useCallback(
    (column: ColumnProps, errors: any) => ({
      field: column.field,
      headerName: column.headerName ? column.headerName : column.field,
      minWidth: column.styleProps?.minWidth ? column.styleProps.minWidth : 150,
      maxWidth: column.styleProps?.maxWidth,
      headerTooltip: column.headerTooltip,
      headerClass: `spreadsheet-header ${
        column.styleProps?.hidden ? "hidden" : ""
      } ${
        column.styleProps?.centerAligned ||
        [FieldType.Boolean, "NUMBER_INPUT"].includes(column.cellType)
          ? "ag-header-center"
          : ""
      }`,

      cellEditorParams: {
        cellType: column.cellType,
        centerAligned: column.styleProps?.centerAligned,
        values: column?.selectOptions ? column.selectOptions : [],
        min: column?.min,
        max: column?.max,
      },
      cellEditorPopup: false,

      cellStyle: (cellStyleProps: CellClassParams) => {
        const identifier = cellStyleProps?.colDef?.cellEditorParams?.assetPartId
          ? cellStyleProps.colDef.cellEditorParams.assetPartId
          : cellStyleProps.colDef.field;

        const hasError = errors[cellStyleProps.rowIndex]
          ? errors[cellStyleProps.rowIndex]?.inner?.find(
              (error: any) => error.path === identifier
            )
          : false;

        return {
          opacity:
            (typeof column.editable === "boolean" && !column.editable) ||
            cellStyleProps.data?.isDuplicate
              ? "0.4"
              : "1",
          cursor:
            (typeof column.editable === "boolean" && !column.editable) ||
            cellStyleProps.data?.isDuplicate
              ? "not-allowed"
              : "default",
          border: hasError ? "1px solid red" : "none",
        };
      },

      editable: (params: EditableCallbackParams) =>
        typeof column.editable === "boolean" && !column.editable
          ? false
          : !params.data?.isDuplicate,

      cellEditorSelector: (cellEditorSelectorParams: ICellEditorParams) => {
        switch (cellEditorSelectorParams.colDef.cellEditorParams.cellType) {
          case FieldType.Select:
            return { component: "customSelect" };

          case "MULTISELECT":
            return { component: "customSelect" };

          case FieldType.Text:
            return { component: "agLargeTextCellEditor" };

          case FieldType.Autosuggest:
            return { component: "agTextCellEditor" };

          case FieldType.Boolean:
            return { component: "customCheckbox" };

          case "NUMBER_INPUT":
            return { component: "customNumberInput" };

          default:
            return { component: "agTextCellEditor" };
        }
      },

      cellRendererSelector: (
        cellRendererSelectorParams: ICellRendererParams
      ) => {
        if (
          cellRendererSelectorParams.colDef?.cellEditorParams.cellType ===
          FieldType.Boolean
        ) {
          return {
            component: "customCheckboxRenderer",
          };
        } else {
          return {
            component: "customCellRenderer",
          };
        }
      },

      onCellValueChanged: (newValueParams: NewValueParams) => {
        isValueChanged.current = true;
        if (handleCellValueChange) {
          handleCellValueChange({
            newValueParams,
            sheetData: data,
            refreshCells,
          });
        }
      },
    }),
    [data, handleCellValueChange, refreshCells]
  );

  const Columns = useMemo(
    () =>
      columns.map((column, index) => (
        <AgGridColumn {...getColumnDefs(column, errors)} key={index} />
      )),
    [columns, errors, getColumnDefs]
  );

  useEffect(() => {
    setTimeout(() => gridController?.api.sizeColumnsToFit());
  }, [columns, gridController?.api]);

  return (
    <>
      <Modal isOpen onClose={onClose} size="full">
        <ModalOverlay>
          <ModalContent height="screenFull">
            <ModalHeader>
              <Flex>
                <Box flex={1} textAlign="left">
                  <Logo />
                </Box>
                {modalHeaderContent && <Box flex={1}>{modalHeaderContent}</Box>}
                <Box flex={1}>
                  <ModalCloseButton
                    onClick={(event) => {
                      if (isValueChanged.current) {
                        event.preventDefault();
                        onAlertOpen();
                      }
                    }}
                    color="gray.300"
                    top="3"
                    right="6"
                    zIndex="10"
                  />
                </Box>
              </Flex>
            </ModalHeader>
            <ModalBody paddingY={0}>
              <Box boxSize="full">
                <AgGridReact
                  singleClickEdit
                  undoRedoCellEditing
                  stopEditingWhenCellsLoseFocus
                  components={{
                    customCellRenderer: cellRenderer,
                    customCheckboxRenderer: AgGridCheckboxRenderer,
                    customCheckbox: AgGridCheckbox,
                    customNumberInput: AgGridNumberInput,
                    customSelect: AgGridSelect,
                  }}
                  defaultColDef={{
                    resizable: true,
                  }}
                  tooltipShowDelay={0}
                  className="ag-theme-alpine"
                  rowData={data}
                  onGridReady={(gridData: GridReadyEvent) => {
                    setGridController(gridData);
                  }}
                >
                  {Columns}
                </AgGridReact>
              </Box>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button
                width="250px"
                type="submit"
                isLoading={isLoading}
                onClick={() => handleSubmit(data)}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
        isOpen={isAlertOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogBody textAlign="center">
              Are you sure you want to close without saving your changes?
            </AlertDialogBody>
            <AlertDialogFooter flexDirection="column">
              <Button
                onClick={() => {
                  handleSubmit(data);
                  onAlertClose();
                }}
                colorScheme="secondary"
                width="100%"
                isLoading={isLoading}
              >
                Save changes and close
              </Button>
              <Button
                variant="link"
                onClick={onClose}
                colorScheme="red"
                width="100%"
                marginTop="4"
              >
                Close without saving changes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Spreadsheet;
