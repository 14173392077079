import React from "react";

import WorkOrderVerifyAndSaveRightColumnSection from "./section/section";
import { SectionItem } from "./sectionsHandler";

interface WorkOrderVerifyAndSaveRightColumnPresenterProps {
  sections: SectionItem[];
}

const WorkOrderVerifyAndSaveRightColumnPresenter: React.FC<
  WorkOrderVerifyAndSaveRightColumnPresenterProps
> = ({ sections }) => {
  return (
    <>
      {sections.map((section) => (
        <WorkOrderVerifyAndSaveRightColumnSection
          section={section}
          key={section.label}
        />
      ))}
    </>
  );
};

export default WorkOrderVerifyAndSaveRightColumnPresenter;
