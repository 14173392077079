import { useReactiveVar } from "@apollo/client";
import React from "react";

import PageSpinner from "../../../../components/elements/pageSpinner";
import {
  AssetWithTypeFragmentFragment,
  SortBy,
  SortOrder,
  useAssetConfigQuery,
  useUpdateAssetConfigMutation,
} from "../../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../../graphql/reactiveVariables";
import useAssetCategories from "../../../../hooks/useAssetCategories";
import orderBy from "../../../../utils/sort";
import AssetPresenter from "./presenter";

interface AssetsPageProps {
  locationId: string;
  assets: AssetWithTypeFragmentFragment[];
}

const AssetsPage: React.FC<AssetsPageProps> = ({ assets, locationId }) => {
  const { data: assetCategoriesData } = useAssetCategories();
  const { data: assetConfigData } = useAssetConfigQuery();
  const assetConfig = assetConfigData?.assetConfig;
  const sortBy = assetConfig?.sortBy || SortBy.Name;
  const sortOrder = assetConfig?.sortOrder || SortOrder.Asc;
  const filter = assetConfig?.filter || [];
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const [updateAssetConfigMutation] = useUpdateAssetConfigMutation();
  const assetCategoryId = assetConfig?.filterCategoryId;
  const assetCategory = assetCategoriesData
    ? assetCategoriesData.assetCategories.find(
        (ac) => ac.id === assetCategoryId
      )
    : null;

  const setAssetCategoryId = React.useCallback(
    (filterCategoryId: string) => {
      updateAssetConfigMutation({
        variables: { filterCategoryId },
      });
    },
    [updateAssetConfigMutation]
  );

  const setSort = React.useCallback(
    (value) => {
      const [sortBy, sortOrder] = value.split("-");
      updateAssetConfigMutation({
        variables: { sortBy, sortOrder },
      });
    },
    [updateAssetConfigMutation]
  );

  const setFilter = React.useCallback(
    (filter) => {
      updateAssetConfigMutation({ variables: { filter } });
    },
    [updateAssetConfigMutation]
  );

  const filteredAndSortedAssets = React.useMemo(() => {
    if (!assetConfig) return [];
    const assetConfigFilter =
      assetConfig.filter && assetConfig.filter.length > 0
        ? assetConfig.filter
        : undefined;
    const assetCategoryId = assetCategory?.id;
    let filteredAssets = assets;
    if (assetConfigFilter || assetCategoryId) {
      filteredAssets = filteredAssets.filter((asset) => {
        if (assetConfigFilter) {
          if (!assetConfigFilter.includes(asset.maintenanceStatus))
            return false;
        }
        if (assetCategoryId) {
          if (asset.assetType.assetCategoryId !== assetCategoryId) return false;
        }
        return true;
      });
    }
    return orderBy(filteredAssets, assetConfig.sortBy, assetConfig.sortOrder);
  }, [assets, assetConfig, assetCategory]);

  return assetCategoriesData ? (
    <AssetPresenter
      setSort={setSort}
      sortBy={sortBy}
      sortOrder={sortOrder}
      filter={filter}
      setFilter={setFilter}
      currentCompanyRole={currentCompanyRole}
      assets={filteredAndSortedAssets}
      hideCreate
      unfilteredAssetsPresent={assets.length > 0}
      assetCategories={orderBy(
        assetCategoriesData.assetCategories,
        SortBy.Name,
        SortOrder.Asc
      )}
      assetCategory={assetCategory || undefined}
      setAssetCategoryId={setAssetCategoryId}
    />
  ) : (
    <PageSpinner />
  );
};

export default AssetsPage;
