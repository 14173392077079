import {
  Box,
  Button,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { cloneDeep, omit } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "react-fast-compare";

import {
  getAssetPartFieldsInitialValues,
  getAssetPartFieldsValidationSchema,
} from "../../../../components/elements/assetPartFields";
import Spreadsheet, {
  ColumnProps,
} from "../../../../components/elements/spreadsheet";
import AddMultiple from "../../../../components/icons/addMultiple";
import {
  BREAKER_NO_SPACE_MESSAGE,
  BREAKER_POLE_OVERLAP_MESSAGE,
  GENERIC_SAVED_MESSAGE,
  HIGHLIGHTED_ERROR_MESSAGE,
} from "../../../../constants/lang/en";
import { getDefaultBreakerColumns } from "../../../../constants/misc";
import {
  AssetDocument,
  AssetPartCreateInput,
  AssetPartFieldValuesInput,
  AssetPartUpdateInput,
  AssetWithRelationsFragmentFragment,
  FieldType,
  SpecialAssetFieldTypes,
  useAssetPartCreateMutation,
  useAssetPartDeleteMutation,
  useAssetPartUpdateMutation,
  useAssetUpdateConfigMutation,
} from "../../../../graphql/graphql";
import { setGenericMessage } from "../../../../utils/serverErrors";
import {
  columnsIndexSchema,
  descriptionSchema,
  gridPanelBreakerPoles,
  nameSchema,
  normalPanelBreakerPoles,
  yupObject,
} from "../../../../utils/validation";
import { PanelItem } from "./panel";

interface BulkAddProps {
  asset: AssetWithRelationsFragmentFragment;
  rowData: PanelItem[];
  mainBreakerId?: string;
}

const AssetPartBulkAdd: React.FC<BulkAddProps> = ({
  asset,
  rowData,
  mainBreakerId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box display={{ xs: "none", md: "block" }}>
      <Tooltip label="Bulk add" hasArrow lineHeight="1">
        <Button
          variant="icon"
          colorScheme="gray"
          aria-label="Bulk add"
          onClick={onOpen}
          marginX={3}
          height={6}
        >
          <AddMultiple />
        </Button>
      </Tooltip>
      {isOpen && (
        <AssetPartBulkAddPresenter
          asset={asset}
          rowData={rowData}
          mainBreakerId={mainBreakerId}
          onClose={onClose}
        />
      )}
    </Box>
  );
};

export interface SheetDataRowItem {
  name: string;
  poles: number;
  description: string;
  id: string | undefined;
  isDuplicate: boolean;
  companyUserIds?: string[];
  [key: string]: any;
}

const AssetPartBulkAddPresenter: React.FC<
  BulkAddProps & { onClose: () => void }
> = ({ asset, rowData, mainBreakerId, onClose }) => {
  const [errors, setErrors] = useState<any[]>([]);
  const toast = useToast();
  const [assetPartCreateMutation, { loading: createLoading }] =
    useAssetPartCreateMutation();
  const [assetPartUpdateMutation, { loading: updateLoading }] =
    useAssetPartUpdateMutation();
  const [assetPartDeleteMutation, { loading: deleteLoading }] =
    useAssetPartDeleteMutation();
  const [assetUpdateConfigMutation, { loading: configLoading }] =
    useAssetUpdateConfigMutation({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: AssetDocument, variables: { id: asset.id } }],
    });

  const isGridPanel: boolean =
    asset.assetType.specialAssetField ===
    SpecialAssetFieldTypes.ElectricalPanelGrid;

  const initialData: SheetDataRowItem[] = useMemo(() => {
    const { assetPartPositions = {} } = asset.config;
    let constructedData: SheetDataRowItem[] = [];
    rowData.forEach(({ breakerNumbers, assetPart, columnIndex }) => {
      Array.from(Array(breakerNumbers?.length)).forEach((_, index) => {
        constructedData.push({
          number: breakerNumbers && breakerNumbers[index],
          name: assetPart?.name || "",
          poles:
            index === 0
              ? Object.values(assetPartPositions).filter(
                  (id) => id === assetPart?.id
                ).length || 1
              : 0,
          description: assetPart?.description || "",
          columnIndex: columnIndex,
          id: assetPart?.id || "",
          isDuplicate: index !== 0,
          ...getAssetPartFieldsInitialValues(asset.assetType, assetPart),
        });
      });
    });
    return constructedData;
  }, [asset.assetType, asset.config, rowData]);

  const [clonedData, setClonedData] = useState<SheetDataRowItem[]>();
  useEffect(() => {
    setClonedData(cloneDeep(initialData));
  }, [initialData]);

  const columns: ColumnProps[] = useMemo(() => {
    return [
      {
        field: "number",
        cellType: "INPUT",
        editable: false,
        styleProps: {
          centerAligned: true,
          minWidth: 100,
        },
      },
      {
        field: "name",
        headerName: "breaker name",
        cellType: "INPUT",
        styleProps: {
          minWidth: 200,
        },
      },
      {
        field: "poles",
        cellType: "NUMBER_INPUT",
        min: 0,
        max: isGridPanel ? 10 : 3,
        styleProps: {
          centerAligned: true,
          minWidth: 100,
        },
      },
      {
        field: "description",
        cellType: "TEXT",
        styleProps: {
          minWidth: 150,
        },
      },
      ...asset.assetType.assetPartFields.map((assetPartField) => {
        return {
          headerName: assetPartField.label,
          cellType: assetPartField.type,
          field: assetPartField.id,
          styleProps: {
            minWidth: 150,
          },
          selectOptions: assetPartField.selectOptions
            ? assetPartField.selectOptions.split("\n").map((option) => ({
                label: option,
                value: option,
              }))
            : [],
        };
      }),
    ];
  }, [asset.assetType.assetPartFields, isGridPanel]);

  const checkPolesOverlap: ({
    rowIndex,
    poles,
    sheetData,
  }: {
    rowIndex: number;
    poles: number;
    sheetData: SheetDataRowItem[];
  }) => boolean = useCallback(
    ({ rowIndex, poles, sheetData }) => {
      let breakerError = false;
      // Check if breaker poles lie outside
      if (rowIndex + poles > sheetData.length) {
        toast({
          description: BREAKER_NO_SPACE_MESSAGE,
          status: "error",
          position: "top",
          isClosable: true,
        });
        return true;
      }

      // Check if breaker poles overlap with existing breakers
      if (poles > 1) {
        Array.from(Array(poles - 1)).forEach((_, poleIndex) => {
          const currentRowIndex = rowIndex + poleIndex + 1;
          if (!breakerError && !sheetData[currentRowIndex]?.isDuplicate) {
            Object.keys(sheetData[rowIndex]).forEach((key) => {
              if (
                !breakerError &&
                ![
                  "poles",
                  "number",
                  "columnIndex",
                  "isDuplicate",
                  "id",
                ].includes(key) &&
                columns.find((column: ColumnProps) => column.field === key)
                  ?.cellType !== FieldType.Boolean &&
                sheetData[currentRowIndex][key]
              ) {
                toast({
                  description: BREAKER_POLE_OVERLAP_MESSAGE,
                  status: "error",
                  position: "top",
                  isClosable: true,
                });
                breakerError = true;
              }
            });
          }
        });
      }
      return breakerError;
    },
    [columns, toast]
  );

  const handleCellValueChange = useCallback(
    ({ newValueParams, sheetData, refreshCells }) => {
      if (newValueParams.colDef.field === "poles") {
        const rowIndexes: number[] = [];
        if (newValueParams.oldValue < newValueParams.newValue) {
          const rowIndex = newValueParams.node?.rowIndex;
          if (typeof rowIndex === "number") {
            let hasPolesOverlap = checkPolesOverlap({
              rowIndex,
              poles: newValueParams.newValue,
              sheetData,
            });

            if (!hasPolesOverlap) {
              Array.from(Array(newValueParams.newValue - 1)).forEach(
                (_, index) => {
                  const currentIndex = rowIndex + index + 1;
                  if (sheetData[currentIndex].isDuplicate) return;
                  const sheetDatum = sheetData[currentIndex];
                  Object.keys(sheetDatum).forEach((key: string) => {
                    if (key === "poles") {
                      sheetDatum[key] = 0;
                    } else if (key === "isDuplicate") {
                      sheetDatum[key] = true;
                    } else if (key !== "columnIndex" && key !== "number") {
                      sheetDatum[key] = newValueParams.data[key];
                    }
                  });
                  rowIndexes.push(currentIndex);
                }
              );
            } else {
              sheetData[rowIndex].poles = newValueParams.oldValue;
              rowIndexes.push(rowIndex);
            }
          }
        } else if (newValueParams.oldValue > newValueParams.newValue) {
          Array.from(
            Array(newValueParams.oldValue - newValueParams.newValue)
          ).forEach((_, index) => {
            const sheetDatum =
              sheetData[
                newValueParams.node?.rowIndex +
                  newValueParams.oldValue -
                  index -
                  1
              ];
            Object.keys(sheetDatum).forEach((key: string) => {
              if (key === "number" || key === "columnIndex") return;

              if (key === "poles") {
                sheetDatum[key] = 1;
              } else if (key === "isDuplicate") {
                sheetDatum[key] = false;
              } else if (typeof sheetDatum[key] === "boolean") {
                sheetDatum[key] = false;
              } else {
                sheetDatum[key] = "";
              }
            });

            rowIndexes.push(
              newValueParams.node?.rowIndex +
                newValueParams.oldValue -
                index -
                1
            );
          });
        }
        refreshCells({ rows: rowIndexes });
      } else if (newValueParams.data.poles > 1 && newValueParams.colDef.field) {
        const rowIndexes: number[] = [];
        const columnField: string = newValueParams.colDef.field;
        Array.from(Array(newValueParams.data.poles - 1)).forEach((_, index) => {
          if (typeof newValueParams.node?.rowIndex === "number") {
            const currentIndex = newValueParams.node.rowIndex + index + 1;
            sheetData[currentIndex][columnField] = newValueParams.newValue;
            rowIndexes.push(currentIndex);
          }
        });
        refreshCells({ rows: rowIndexes, columns: [columnField] });
      } else {
        const sheetDatum = sheetData[newValueParams.node.rowIndex];
        sheetDatum[newValueParams.colDef.field] = newValueParams.newValue;
      }
    },
    [checkPolesOverlap]
  );

  const handleValidation = useCallback(
    (sheetData: SheetDataWithType[]) => {
      const validationSchema = yupObject().shape({
        name: nameSchema.label("Asset part name"),
        poles: isGridPanel ? gridPanelBreakerPoles : normalPanelBreakerPoles,
        columnIndex: columnsIndexSchema,
        description: descriptionSchema,
        ...getAssetPartFieldsValidationSchema(asset.assetType),
      });
      let hasError = false;
      const validationStatus = sheetData.map(
        (sheetDatum: SheetDataRowItem, index: number) => {
          if (isEqual(sheetDatum, initialData[index])) return false;
          let isDeleted = true;
          Object.keys(sheetDatum).forEach((key: string) => {
            if (
              isDeleted &&
              !["poles", "number", "columnIndex", "isDuplicate", "id"].includes(
                key
              ) &&
              columns.find((column: ColumnProps) => column.field === key)
                ?.cellType !== FieldType.Boolean &&
              sheetDatum[key]
            ) {
              isDeleted = false;
            }
          });

          if (!isDeleted) {
            try {
              validationSchema.validateSync(sheetDatum, { abortEarly: false });
              return false;
            } catch (error) {
              hasError = true;
              return error;
            }
          }
          return false;
        }
      );
      setErrors(validationStatus);
      return !hasError;
    },
    [asset.assetType, columns, initialData, isGridPanel]
  );

  const handleSubmit = React.useCallback(
    async (sheetData: SheetDataRowItem[]) => {
      const isValidationSuccess = handleValidation(sheetData);
      if (!isValidationSuccess) {
        toast({
          description: HIGHLIGHTED_ERROR_MESSAGE,
          status: "error",
          position: "top",
          isClosable: true,
        });
        return;
      }

      const getFormattedField = (index: number) => {
        const {
          name,
          description,
          columnIndex,
          poles,
          id,
          isDuplicate,
          ...otherFields
        }: SheetDataRowItem = sheetData[index];

        const formattedField: {
          name: string;
          description: string;
          assetPartFieldValuesInput: AssetPartFieldValuesInput[];
        } = {
          name,
          description,
          assetPartFieldValuesInput: [],
        };

        asset.assetType.assetPartFields.forEach((assetPartField) => {
          if (otherFields[assetPartField.id]) {
            formattedField.assetPartFieldValuesInput.push({
              assetPartFieldId: assetPartField.id,
              value: `${otherFields[assetPartField.id]}`,
            });
          }
        });

        return formattedField;
      };

      let updatedFields: AssetPartUpdateInput[] = [];
      let createdFields: AssetPartCreateInput[] = [];
      let deletedFieldIds: string[] = [];

      initialData.forEach((initialDatum: SheetDataRowItem, index) => {
        if (isEqual(initialDatum, sheetData[index])) return;
        else {
          const sheetDatum = sheetData[index];

          if (!initialDatum.name && sheetDatum.name) {
            // Created parts
            if (!sheetDatum.isDuplicate) {
              createdFields.push({
                ...getFormattedField(index),
                assetId: asset.id,
              });
              sheetDatum.type = "CREATE";
            }
          } else if (initialDatum.name && !sheetDatum.name) {
            // Deleted parts
            if (!initialDatum.isDuplicate && initialDatum.id) {
              deletedFieldIds.push(initialDatum.id);
              sheetDatum.type = "DELETE";
            }
          } else {
            // Duplicate value not changed, update part
            if (sheetDatum.isDuplicate === initialDatum.isDuplicate) {
              // Changed parts
              if (
                !sheetDatum.isDuplicate &&
                !isEqual(
                  omit(initialDatum, ["poles"]),
                  omit(sheetData[index], ["poles"])
                )
              ) {
                updatedFields.push({
                  id: sheetDatum.id,
                  ...getFormattedField(index),
                });
                sheetDatum.type = "UPDATE";
              }

              // Duplicate value changed
            } else {
              // Duplicate changed from true to false so we have create part
              if (initialDatum.isDuplicate) {
                createdFields.push({
                  ...getFormattedField(index),
                  assetId: asset.id,
                });
                sheetDatum.type = "CREATE";

                // Duplicate changed from false to true so we have to delete part
              } else if (initialDatum.id) {
                deletedFieldIds.push(initialDatum.id);
                sheetDatum.type = "DELETE";
              }
            }
          }
        }
      });

      if (createdFields.length) {
        try {
          const { data, errors } = await assetPartCreateMutation({
            variables: { data: createdFields },
          });

          if (errors) {
            toast({
              description: setGenericMessage(errors),
              status: "error",
              position: "top",
              isClosable: true,
            });
            return;
          } else {
            const newAssetParts = data?.assetPartCreate;
            if (newAssetParts && newAssetParts.length) {
              let createdDataIndex = 0;
              sheetData.forEach((sheetDatum: SheetDataRowItem, index) => {
                if (sheetDatum.type === "CREATE") {
                  const assetPartId = newAssetParts[createdDataIndex]?.id;
                  // Update id for all poles to the one returned from server
                  Array.from(Array(sheetDatum.poles)).forEach(
                    (_, poleIndex) => {
                      const sheetDatumToUpdate = sheetData[index + poleIndex];
                      if (sheetDatumToUpdate)
                        sheetDatumToUpdate.id = assetPartId;
                    }
                  );
                  createdDataIndex++;
                }
              });
            }
          }
        } catch (error) {
          toast({
            description: setGenericMessage(error),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }

      if (updatedFields.length) {
        try {
          const { errors } = await assetPartUpdateMutation({
            variables: { data: updatedFields },
          });
          if (errors) {
            toast({
              description: setGenericMessage(errors),
              status: "error",
              position: "top",
              isClosable: true,
            });
            return;
          }
        } catch (error) {
          toast({
            description: setGenericMessage(error),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }

      if (deletedFieldIds.length) {
        try {
          const { errors } = await assetPartDeleteMutation({
            variables: { ids: deletedFieldIds },
          });
          if (errors) {
            toast({
              description: setGenericMessage(errors),
              status: "error",
              position: "top",
              isClosable: true,
            });
            return;
          } else {
            sheetData.forEach((sheetDatum: SheetDataRowItem) => {
              if (sheetDatum.type === "DELETE") {
                sheetDatum.id = undefined;
              }
            });
          }
        } catch (error) {
          toast({
            description: setGenericMessage(error),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }

      const {
        numberOfColumns = getDefaultBreakerColumns(asset.assetType.name),
      } = asset.config;
      const length = initialData.length;
      const partPositions = sheetData.reduce(
        (
          result: { [key: string]: string },
          sheetDatum: SheetDataRowItem,
          index: number
        ) => {
          if (sheetDatum.id) {
            result[
              `${index % (length / numberOfColumns)}-${sheetDatum.columnIndex}`
            ] = sheetDatum.id;
          }
          return result;
        },
        {}
      );

      if (mainBreakerId) {
        partPositions["-1--1"] = mainBreakerId;
      }

      try {
        const { data: serverConfigData, errors } =
          await assetUpdateConfigMutation({
            variables: {
              id: asset.id,
              config: {
                ...asset.config,
                assetPartPositions: partPositions,
              },
            },
          });
        if (errors) {
          toast({
            description: setGenericMessage(errors),
            status: "error",
            position: "top",
            isClosable: true,
          });
          return;
        } else if (serverConfigData) {
          toast({
            description: GENERIC_SAVED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
        return;
      }
      onClose();
    },
    [
      asset,
      assetPartCreateMutation,
      assetPartDeleteMutation,
      assetPartUpdateMutation,
      assetUpdateConfigMutation,
      handleValidation,
      initialData,
      mainBreakerId,
      onClose,
      toast,
    ]
  );

  return clonedData ? (
    <Spreadsheet
      data={clonedData}
      errors={errors}
      columns={columns}
      handleCellValueChange={handleCellValueChange}
      handleSubmit={handleSubmit}
      isLoading={
        createLoading || updateLoading || deleteLoading || configLoading
      }
      onClose={onClose}
    />
  ) : null;
};

export default AssetPartBulkAdd;

interface SheetDataWithType extends SheetDataRowItem {
  type?: "CREATE" | "UPDATE" | "DELETE";
}
