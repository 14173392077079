import { useReactiveVar } from "@apollo/client";
import moment from "moment/moment";
import { FC, useEffect, useState } from "react";

import { useCommentWorkOrderCreateMutation } from "../../../../../../graphql/mutations/commentWorkOrders";
import { useWOGetCommentsWorkOrderQuery } from "../../../../../../graphql/queries/workOrderComments";
import { currentUserWithCompaniesVar } from "../../../../../../graphql/reactiveVariables";
import { WOComment } from "../../../../../../graphql/types/queries/workOrdersQuery";
import CommentsPresenter from "./presenter";

interface CommentsProps {
  workOrderId: string;
}

const Comments: FC<CommentsProps> = ({ workOrderId }) => {
  const [comments, setComments] = useState<WOComment[]>([]);
  const [comment, setComment] = useState("");
  const [timezone, setTimezone] = useState<string>("");
  const currentUser = useReactiveVar(currentUserWithCompaniesVar);
  const { data } = useWOGetCommentsWorkOrderQuery({
    fetchPolicy: "network-only",
    variables: { workOrderId },
    skip: !workOrderId,
  });
  const [commentWorkOrderCreateMutation] = useCommentWorkOrderCreateMutation({
    variables: {
      data: {
        text: comment,
        userId: currentUser!.user.id,
        workOrderId: workOrderId,
      },
    },
  });

  useEffect(() => {
    setTimezone(moment.tz.guess());
  }, []);

  useEffect(() => {
    if (data?.workOrderComments) {
      setComments(data.workOrderComments);
    }
  }, [data]);
  const save = () => {
    commentWorkOrderCreateMutation().then((data) => {
      if (data.data?.commentCreate) {
        setComments((prevState) => [
          data.data!.commentCreate as WOComment,
          ...prevState,
        ]);
      }
    });
    setComment("");
  };

  return (
    <CommentsPresenter
      comments={comments}
      timezone={timezone}
      currentUser={currentUser!.user}
      onChange={setComment}
      save={save}
      value={comment}
      cancel={() => setComment("")}
    />
  );
};

export default Comments;
