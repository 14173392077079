import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import {
  AssetWithRelationsFragmentFragment,
  SpecialAssetFieldTypes,
} from "../../../../graphql/graphql";
import AssetImages from "./images";
import AssetPanel from "./panel";

interface AssetDetailsProps {
  asset: AssetWithRelationsFragmentFragment;
  canAddAsset: boolean;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
}

const AssetDetails: React.FC<AssetDetailsProps> = ({
  asset,
  canAddAsset,
  canEditAsset,
  canDeleteAsset,
}) => {
  return asset.assetType.specialAssetField === SpecialAssetFieldTypes.Na ? (
    <AssetImages
      asset={asset}
      canAddAsset={canAddAsset}
      canEditAsset={canEditAsset}
      canDeleteAsset={canDeleteAsset}
    />
  ) : (
    <SimpleGrid
      columns={{ base: 1, lg: 2 }}
      templateColumns={{
        base: "repeat(1, minmax(0, 1fr))",
        lg: "repeat(2, minmax(0, 1fr))",
      }}
      spacing={10}
    >
      <Box>
        <AssetPanel
          asset={asset}
          canAddAsset={canAddAsset}
          canEditAsset={canEditAsset}
          showHeader
        />
      </Box>
      <AssetImages
        asset={asset}
        canAddAsset={canAddAsset}
        canEditAsset={canEditAsset}
        canDeleteAsset={canDeleteAsset}
      />
    </SimpleGrid>
  );
};

export default AssetDetails;
