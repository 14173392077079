const Radio = {
  baseStyle: {
    control: {
      marginTop: 1,
      _checked: {
        _hover: { backgroundColor: "secondary.500" },
        backgroundColor: "secondary.500",
        border: "none",
        _before: {
          content: `url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%228%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9.35%201.73L3.73%207.35a.48.48%200%2001-.7%200L.65%205a.5.5%200%20010-.71l.53-.53a.48.48%200%2001.7%200l1.5%201.49L8.12.51a.5.5%200%2001.7%200l.53.53a.5.5%200%20010%20.69z%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E")`,
          backgroundColor: "transparent",
          width: "auto",
          height: "auto",
          fontSize: "11px",
        },
      },
    },
  },
};

export default Radio;
