import {
  Box,
  Button,
  Flex,
  FlexProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { addYears, format, subYears } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";

import PageSpinner from "../../../components/elements/pageSpinner";
import { defaultDateWithTimeAndZoneFormat } from "../../../constants/date";
import {
  MaintenanceScheduleFragmentFragment,
  useMaintenanceWithSchedulesQuery,
} from "../../../graphql/graphql";
import { generateRruleSetForMaintenance } from "../../../utils/date/rrule";
import MaintenanceMarkAsComplete from "../markAsComplete";
import MaintenanceMarkAsInComplete from "../markAsInComplete";
import CompletedBy from "./completedBy";

interface MaintenanceRecurringProps {
  maintenanceId: string;
  maintenanceContactIds: string[];
  maintenanceResolvedName: string;
  maintenanceLocationsCopy: string;
}

interface ServiceSchedule {
  date: Date;
  schedule?: MaintenanceScheduleFragmentFragment;
}

const serviceScheduleStyles: FlexProps = {
  align: "center",
  wrap: "wrap",
  justify: "space-between",
  borderBottom: "1px solid",
  borderBottomColor: "gray.200",
  py: "3",
  fontSize: "sm",
};

const MaintenanceRecurring: React.FC<MaintenanceRecurringProps> = ({
  maintenanceId,
  maintenanceContactIds,
  maintenanceResolvedName,
  maintenanceLocationsCopy,
}) => {
  const { data } = useMaintenanceWithSchedulesQuery({
    variables: { id: maintenanceId },
  });
  const maintenance = data?.maintenance;
  const [completeSchedule, setCompleteSchedule] = useState<
    MaintenanceScheduleFragmentFragment | undefined
  >(undefined);
  const [incompleteSchedule, setIncompleteSchedule] = useState<
    MaintenanceScheduleFragmentFragment | undefined
  >(undefined);
  const [alertCopy, setAlertCopy] = useState<string | undefined>(undefined);
  const serviceSchedules: ServiceSchedule[] | null = useMemo(() => {
    if (!maintenance) return null;
    const today = new Date();
    const schedules: { [key: string]: MaintenanceScheduleFragmentFragment } =
      {};
    maintenance.maintenanceSchedules.forEach((sc) => {
      schedules[format(sc.scheduledAt, "yyyy-MM-dd")] = sc;
    });
    const rruleSet = generateRruleSetForMaintenance(maintenance);
    return rruleSet
      .between(subYears(today, 1), addYears(today, 2)) // TODO: Showing only for 3 years
      .map((date) => {
        const schedule = schedules[format(date, "yyyy-MM-dd")];
        return {
          date,
          schedule,
        };
      });
  }, [maintenance]);

  const [completedServiceSchedules, upcomingServiceSchedules] = useMemo(() => {
    if (!serviceSchedules) return [[], []];
    const completedServiceSchedules: ServiceSchedule[] | null = [];
    const upcomingServiceSchedules: ServiceSchedule[] | null = [];
    serviceSchedules.forEach((serviceSchedule) => {
      if (serviceSchedule.schedule?.completedAt) {
        completedServiceSchedules.push(serviceSchedule);
      } else {
        upcomingServiceSchedules.push(serviceSchedule);
      }
    });
    return [completedServiceSchedules, upcomingServiceSchedules];
  }, [serviceSchedules]);

  const handleComplete = useCallback(
    (
      maintenanceSchedule: MaintenanceScheduleFragmentFragment,
      alertCopy: string
    ) => {
      setCompleteSchedule(maintenanceSchedule);
      setAlertCopy(alertCopy);
    },
    []
  );

  const handleInComplete = useCallback(
    (
      maintenanceSchedule: MaintenanceScheduleFragmentFragment,
      alertCopy: string
    ) => {
      setIncompleteSchedule(maintenanceSchedule);
      setAlertCopy(alertCopy);
    },
    []
  );

  const setCloseCompleteDialogs = useCallback(() => {
    setCompleteSchedule(undefined);
    setIncompleteSchedule(undefined);
    setAlertCopy(undefined);
  }, []);

  const completedServiceSchedulesPresent = !!completedServiceSchedules.length;
  const upcomingServiceSchedulesPresent = !!upcomingServiceSchedules.length;

  return maintenance ? (
    <Box>
      {upcomingServiceSchedulesPresent || completedServiceSchedulesPresent ? (
        <Tabs isLazy>
          <TabList>
            {upcomingServiceSchedulesPresent && <Tab>Upcoming</Tab>}
            {completedServiceSchedulesPresent && <Tab>Completed</Tab>}
          </TabList>
          <TabPanels>
            {upcomingServiceSchedulesPresent && (
              <TabPanel>
                {upcomingServiceSchedules.map((serviceSchedule) => (
                  <Flex
                    key={serviceSchedule.date.getTime()}
                    {...serviceScheduleStyles}
                  >
                    <Box>
                      {format(
                        serviceSchedule.date,
                        defaultDateWithTimeAndZoneFormat
                      )}
                    </Box>
                    {!!serviceSchedule.schedule && (
                      <Button
                        size="xs"
                        ml="2"
                        colorScheme="secondary"
                        variant="link"
                        onClick={() =>
                          handleComplete(
                            serviceSchedule.schedule!,
                            format(
                              serviceSchedule.date,
                              defaultDateWithTimeAndZoneFormat
                            )
                          )
                        }
                      >
                        Mark as complete
                      </Button>
                    )}
                  </Flex>
                ))}
              </TabPanel>
            )}
            {completedServiceSchedulesPresent && (
              <TabPanel>
                {completedServiceSchedules.map((serviceSchedule) => (
                  <Flex
                    key={serviceSchedule.date.getTime()}
                    {...serviceScheduleStyles}
                  >
                    <Box>
                      {format(
                        serviceSchedule.date,
                        defaultDateWithTimeAndZoneFormat
                      )}
                    </Box>
                    <Button
                      size="xs"
                      ml="2"
                      variant="link"
                      colorScheme="red"
                      onClick={() =>
                        handleInComplete(
                          serviceSchedule.schedule!,
                          format(
                            serviceSchedule.date,
                            defaultDateWithTimeAndZoneFormat
                          )
                        )
                      }
                    >
                      Mark as Incomplete
                    </Button>
                    {!!serviceSchedule.schedule?.completedAt && (
                      <Box
                        w="full"
                        flexShrink="0"
                        color="gray.700"
                        fontSize="xs"
                        mt="2"
                      >
                        <CompletedBy
                          maintenanceSchedule={serviceSchedule.schedule}
                        />
                      </Box>
                    )}
                  </Flex>
                ))}
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      ) : null}
      {completeSchedule && alertCopy ? (
        <MaintenanceMarkAsComplete
          maintenanceLocationsCopy={maintenanceLocationsCopy}
          maintenanceResolvedName={maintenanceResolvedName}
          maintenanceScheduleDateCopy={format(
            completeSchedule.scheduledAt,
            defaultDateWithTimeAndZoneFormat
          )}
          maintenanceScheduleId={completeSchedule.id}
          onClose={setCloseCompleteDialogs}
          contactIds={maintenanceContactIds}
        />
      ) : null}
      {incompleteSchedule && alertCopy ? (
        <MaintenanceMarkAsInComplete
          maintenanceLocationsCopy={maintenanceLocationsCopy}
          maintenanceResolvedName={maintenanceResolvedName}
          maintenanceScheduleDateCopy={format(
            incompleteSchedule.scheduledAt,
            defaultDateWithTimeAndZoneFormat
          )}
          maintenanceScheduleId={incompleteSchedule.id}
          onClose={setCloseCompleteDialogs}
        />
      ) : null}
    </Box>
  ) : (
    <PageSpinner />
  );
};

export default MaintenanceRecurring;
