const Checkbox = {
  baseStyle: {
    control: {
      marginTop: ".25rem",
      _checked: {
        _hover: { backgroundColor: "secondary.500" },
        backgroundColor: "secondary.500",
        border: "none",
      },
    },
  },
};

export default Checkbox;
