import { Box, Flex, IconButton } from "@chakra-ui/react";
import {
  faSearchMinus,
  faSearchPlus,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

interface ZoomWrapperProps {
  isPreview?: boolean;
}

const ZoomWrapper: React.FC<ZoomWrapperProps> = ({ isPreview, children }) => {
  return (
    <TransformWrapper wheel={{ disabled: true }}>
      {({ zoomIn, zoomOut, resetTransform, state: { scale } }) => (
        <Box position="relative" role="group">
          <TransformComponent>{children}</TransformComponent>
          <Flex
            align="center"
            position="absolute"
            left={isPreview ? "45%" : "1"}
            top={isPreview ? "30px" : "2"}
            zIndex={1}
            opacity="0"
            _groupHover={{ opacity: 1 }}
          >
            <IconButton
              mx="1"
              size="xs"
              colorScheme="gray"
              aria-label="Zoom In"
              onClick={() => zoomIn()}
              icon={<FontAwesomeIcon icon={faSearchPlus} />}
            />
            <IconButton
              mx="1"
              size="xs"
              colorScheme="gray"
              aria-label="Zoom Out"
              onClick={() => zoomOut()}
              icon={<FontAwesomeIcon icon={faSearchMinus} />}
              disabled={scale <= 1}
            />
            <IconButton
              mx="1"
              size="xs"
              colorScheme="gray"
              aria-label="Reset Zoom"
              onClick={() => resetTransform()}
              icon={<FontAwesomeIcon icon={faUndo} />}
              disabled={scale === 1}
            />
          </Flex>
        </Box>
      )}
    </TransformWrapper>
  );
};

export default ZoomWrapper;
