import { Icon, IconProps } from "@chakra-ui/react";

const IrrigationSystem = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M88.636 104.226h-15.59V91.523h1.732a2.887 2.887 0 0 0 2.887-2.887V77.858a2.887 2.887 0 0 0-2.887-2.887h-1.732v-5.004h.193a2.887 2.887 0 0 0 0-5.775H54.762a2.887 2.887 0 0 0 0 5.775h.333l-.09 5.004h-1.783a2.887 2.887 0 0 0-2.887 2.887v10.778a2.887 2.887 0 0 0 2.887 2.887h1.732v12.703h-15.59a2.887 2.887 0 0 0 0 5.774h49.272a2.887 2.887 0 0 0 0-5.774ZM56.11 85.749v-5.004H71.89v5.004H56.11Zm4.76-15.782h6.403v5.004H60.78l.09-5.004Zm-.14 21.556h6.543v12.703h-6.544V91.523ZM64 49.95a2.887 2.887 0 0 0 2.887-2.887V20.887a2.887 2.887 0 0 0-5.774 0v26.176A2.887 2.887 0 0 0 64 49.95ZM110.385 64.192H84.017a2.887 2.887 0 0 0 0 5.775h26.368a2.887 2.887 0 0 0 0-5.775ZM46.87 67.08a2.887 2.887 0 0 0-2.887-2.888H17.615a2.887 2.887 0 1 0 0 5.775h26.368a2.887 2.887 0 0 0 2.887-2.888ZM80.195 54.967l18.51-18.509a2.887 2.887 0 1 0-4.083-4.083l-18.51 18.51a2.887 2.887 0 1 0 4.083 4.082ZM47.805 54.967a2.887 2.887 0 1 0 4.082-4.083L33.38 32.375a2.887 2.887 0 0 0-4.083 4.083l18.509 18.51Z"
      fill="currentColor"
    />
  </Icon>
);

export default IrrigationSystem;
