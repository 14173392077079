import { Alert, AlertDescription } from "@chakra-ui/react";
import React from "react";

import OnboardingPercentage from "../../../../../pages/onboarding/percentage";
import { getRoutePath } from "../../../../../router";
import Link from "../../../../elements/link";
import Rocket from "../../../../icons/rocket";

interface CompanySetupProps {}

const CompanySetup: React.FC<CompanySetupProps> = () => {
  return (
    <Alert variant="solid" justifyContent="center" status="info" role="group">
      <AlertDescription color="white">
        <OnboardingPercentage helperPosition="row-reverse" />
        <Link
          variant="link"
          colorScheme="white"
          to={getRoutePath("onboarding")}
          fontSize="sm"
          marginLeft="2"
          padding="0 4px"
          _groupHover={{ backgroundColor: "secondary.700" }}
          textTransform="uppercase"
          leftIcon={<Rocket />}
        >
          Complete your setup
        </Link>
      </AlertDescription>
    </Alert>
  );
};

export default CompanySetup;
