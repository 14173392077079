import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import FormGroup from "../../../components/elements/formGroup";
import { AssetFileFragmentFragment } from "../../../graphql/graphql";
import {
  descriptionSchema,
  nameSchema,
  yupObject,
} from "../../../utils/validation";

interface AssetFileEditPresenterProps {
  assetFile: AssetFileFragmentFragment;
  handleSubmit: (
    values: AssetFileFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  loading: boolean;
  isOpen: boolean;
  onDrawerClose: () => void;
}

const AssetFileEditPresenter: React.FC<AssetFileEditPresenterProps> = ({
  assetFile,
  handleSubmit,
  loading,
  isOpen,
  onDrawerClose,
}) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>
          {assetFile.type === "IMAGE" ? "Edit Picture Info" : "Edit Document"}
        </title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {assetFile.type === "IMAGE" ? "Edit Picture Info" : "Edit Document"}
          </DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{
                name: assetFile.name,
                description: assetFile.description || "",
              }}
              validationSchema={assetFileValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form id="asset_file_edit" noValidate>
                  <FormGroup
                    label={assetFile.type === "IMAGE" ? "Picture Name" : "Name"}
                    name="name"
                  >
                    <Input
                      autoFocus
                      autoComplete="off"
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  <FormGroup label="Description" name="description">
                    <Textarea
                      autoComplete="off"
                      {...getFieldProps("description")}
                    />
                  </FormGroup>
                  <Box marginY="10">
                    <Button
                      width="full"
                      type="submit"
                      isLoading={loading}
                      form="asset_file_edit"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AssetFileEditPresenter;

const assetFileValidationSchema = yupObject().shape({
  name: nameSchema.label("Plan name"),
  description: descriptionSchema,
});

export type AssetFileFormData = {
  name: string;
  description: string;
};
