import { SectionItem } from "./sectionsHandler";

export const sectionsConfig: SectionItem[] = [
  {
    label: "Assignee",
    fields: [
      {
        label: "Accountable",
        fieldId: "accountableUserId",
        value: "",
      },
      {
        label: "Responsible",
        fieldId: "responsibleUserIds",
        value: [],
      },
      {
        label: "Consulted",
        fieldId: "consultingUserIds",
        value: [],
      },
      {
        label: "Informed",
        fieldId: "informingUserIds",
        value: [],
      },
      {
        label: "Reporter",
        fieldId: "reporter",
        value: "TBD",
        className: "reporter",
      },
    ],
  },
  {
    label: "Service Plan",
    fields: [
      {
        label: "Order Type",
        fieldId: "orderTypeId",
        value: "",
      },
      {
        label: "Service Category",
        fieldId: "serviceCategoryId",
        value: "",
      },
      {
        label: "Business Severity",
        fieldId: "businessSeverity",
        value: "",
      },
      {
        label: "Execution Priority",
        fieldId: "orderPriority",
        value: "",
      },
      {
        label: "Parent Order",
        fieldId: "parentOrderId",
        value: "",
      },
    ],
  },
  {
    label: "Service Schedule",
    fields: [
      {
        label: "Schedule Type",
        fieldId: "scheduleType",
        value: "",
      },
      {
        label: "Timezone",
        fieldId: "serviceScheduleTimezone",
        value: "",
      },
      {
        label: "Start Date",
        fieldId: "startDateTime",
        value: "",
      },
      {
        label: "End Date",
        fieldId: "endDateTime",
        value: "",
      },
      {
        label: "Recurrence",
        fieldId: "recurrence",
        value: "Does not repeat",
      },
    ],
  },
  {
    label: "Company Contact Info",
    collapsed: true,
    fields: [
      {
        label: "Company Name",
        fieldId: "companyId",
        value: "",
      },
      {
        label: "Full Name",
        fieldId: "companyContactFullName",
        value: "",
      },
      {
        label: "Email",
        fieldId: "companyContactEmail",
        value: "",
      },
      {
        label: "Phone No (work)",
        fieldId: "companyContactWorkPhone",
        value: "",
      },
      {
        label: "Phone No (mobile)",
        fieldId: "companyContactMobilePhone",
        value: "",
      },
    ],
  },
  {
    label: "Location Contact Info",
    collapsed: true,
    fields: [
      {
        label: "Location Name",
        fieldId: "locationId",
        value: "",
      },
      {
        label: "Full Name",
        fieldId: "locationContactFullName",
        value: "",
      },
      {
        label: "Email",
        fieldId: "locationContactEmail",
        value: "",
      },
      {
        label: "Phone No (work)",
        fieldId: "locationContactWorkPhone",
        value: "",
      },
      {
        label: "Phone No (mobile)",
        fieldId: "locationContactMobilePhone",
        value: "",
      },
    ],
  },
];
