import { Flex } from "@chakra-ui/react";

import { AssetCategoryFragmentFragment } from "../../../graphql/graphql";
import AssetIcon from "../assetIcons";

const AssetCategorySelectOption: React.FC<{
  assetCategory?: AssetCategoryFragmentFragment;
}> = ({ assetCategory }) =>
  assetCategory ? (
    <Flex alignItems="center">
      <AssetIcon
        iconName={assetCategory.iconName}
        iconColor={assetCategory.iconColor}
        iconType={assetCategory.iconType}
        iconSize="xs"
        marginRight="2"
      />
      {assetCategory.name}
    </Flex>
  ) : (
    <>All</>
  );

export default AssetCategorySelectOption;
