import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React from "react";

import { ASSET_TYPE_MAINTENANCE_DELETED_MESSAGE } from "../../../constants/lang/en";
import { useMaintenanceTemplateDeleteMutation } from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";

interface AssetTypeMaintenanceDeleteProps {
  id: string;
  name: string;
}

const AssetTypeMaintenanceDelete: React.FC<AssetTypeMaintenanceDeleteProps> = ({
  id,
  name,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const {
    isOpen: isDeleteModalOpen,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useDisclosure();
  const [maintenanceDeleteMutation, { loading: deleteLoading }] =
    useMaintenanceTemplateDeleteMutation();

  const deleteAssetType = React.useCallback(async () => {
    try {
      const { errors } = await maintenanceDeleteMutation({
        variables: { id },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        client.cache.evict({
          id: `MaintenanceTemplate:${id}`,
        });
        onDeleteModalClose();
        toast({
          description: ASSET_TYPE_MAINTENANCE_DELETED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [client.cache, id, maintenanceDeleteMutation, onDeleteModalClose, toast]);

  return (
    <>
      <IconButton
        onClick={onDeleteModalOpen}
        variant="icon"
        aria-label="Delete asset"
        colorScheme="grayRed"
        padding={2}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </IconButton>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onDeleteModalClose}
        isOpen={isDeleteModalOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete the following default service
              schedule?
              <br />
              <Text as="strong">{name}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={deleteLoading}
                onClick={onDeleteModalClose}
                width="48%"
              >
                No, Don't Delete!
              </Button>
              <Button
                onClick={deleteAssetType}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AssetTypeMaintenanceDelete;

gql`
  mutation MaintenanceTemplateDelete($id: UUID!) {
    maintenanceTemplateDelete(id: $id)
  }
`;
