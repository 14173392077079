import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const FlagIcon = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    color="#FFBD29"
    viewBox="0 0 16 16"
    boxSize="16px"
    {...props}
  >
    <path d="M2.65632 2.00001C2.47973 2.00277 2.31146 2.07548 2.18842 2.20217C2.06539 2.32887 1.99765 2.4992 2.00007 2.67579V9.34246V13.3425C1.99882 13.4308 2.01514 13.5185 2.04808 13.6005C2.08102 13.6825 2.12993 13.7571 2.19196 13.82C2.25399 13.8829 2.3279 13.9329 2.40941 13.967C2.49091 14.0011 2.57838 14.0186 2.66673 14.0186C2.75508 14.0186 2.84255 14.0011 2.92406 13.967C3.00556 13.9329 3.07948 13.8829 3.14151 13.82C3.20354 13.7571 3.25244 13.6825 3.28539 13.6005C3.31833 13.5185 3.33465 13.4308 3.3334 13.3425V9.34246H7.84251C8.09518 9.34246 8.32554 9.48495 8.43887 9.71095L8.73705 10.3073C8.84971 10.5333 9.08073 10.6758 9.3334 10.6758H13.3334C13.7014 10.6758 14.0001 10.3771 14.0001 10.0091V4.00912C14.0001 3.64112 13.7014 3.34246 13.3334 3.34246H10.1576C9.90495 3.34246 9.6746 3.19997 9.56126 2.97397L9.26309 2.37761C9.15042 2.15161 8.9194 2.00912 8.66673 2.00912H2.7696C2.73217 2.00287 2.69427 1.99982 2.65632 2.00001Z" />
  </Icon>
);

export default FlagIcon;
