import {
  EuiFlexGroup,
  EuiFlexItem,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import React from "react";

import { WOLocation } from "../../../../graphql/types/queries/workOrdersQuery";
import { WorkOrder } from "../../models/workOrder";
import WorkOrderCreateLocationContactInfo from "./contactInfo";
import WorkOrderCreateLocationDetails from "./details";

interface WorkOrderCreateLocationPresenterProps {
  locations: WOLocation[];
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  workOrder: WorkOrder;
  locationsLoading?: boolean;
}

const WorkOrderCreateLocationPresenter: React.FC<
  WorkOrderCreateLocationPresenterProps
> = ({ locations, onWorkOrderChange, workOrder, locationsLoading }) => {
  const isMobile = useIsWithinBreakpoints(["xs", "s"]);
  return (
    <EuiFlexGroup
      direction="row"
      justifyContent="spaceBetween"
      alignItems="flexStart"
      gutterSize="xl"
    >
      <EuiFlexItem
        grow={1}
        style={{
          width: "50%",
          minWidth: "360px",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <WorkOrderCreateLocationDetails
          locations={locations}
          onWorkOrderChange={onWorkOrderChange}
          selectedLocationId={workOrder?.locationId}
        />
      </EuiFlexItem>
      <EuiFlexItem
        grow={1}
        style={{
          width: "50%",
          minWidth: "360px",
          alignItems: isMobile ? "center" : "flex-end",
        }}
      >
        <WorkOrderCreateLocationContactInfo
          workOrder={workOrder}
          onWorkOrderChange={onWorkOrderChange}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default WorkOrderCreateLocationPresenter;
