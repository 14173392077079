import { Icon, IconProps } from "@chakra-ui/react";

const Security = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M59.077 83.202 57.762 95.02a2.084 2.084 0 0 0 2.071 2.313h8.334a2.084 2.084 0 0 0 2.07-2.313l-1.313-11.818a8.242 8.242 0 0 0 3.41-6.702c0-4.596-3.738-8.334-8.334-8.334s-8.333 3.738-8.333 8.334a8.243 8.243 0 0 0 3.41 6.702ZM64 72.333a4.172 4.172 0 0 1 4.167 4.167c0 1.597-.908 3.027-2.369 3.735a2.085 2.085 0 0 0-1.163 2.106l1.204 10.826h-3.678l1.204-10.826a2.085 2.085 0 0 0-1.163-2.106c-1.461-.708-2.368-2.138-2.368-3.735A4.172 4.172 0 0 1 64 72.333Z"
      fill="currentColor"
    />
    <path
      d="M99.417 51.5h-6.25v-8.333C93.167 27.084 80.083 14 64 14c-16.083 0-29.167 13.084-29.167 29.167V51.5h-6.25a2.082 2.082 0 0 0-2.083 2.083v52.084c0 4.596 3.737 8.333 8.333 8.333h58.334c4.596 0 8.333-3.737 8.333-8.333V53.583a2.082 2.082 0 0 0-2.083-2.083ZM39 43.167c0-13.786 11.214-25 25-25s25 11.214 25 25V51.5h-4.167v-8.333c0-11.487-9.346-20.834-20.833-20.834-11.487 0-20.833 9.347-20.833 20.834V51.5H39v-8.333Zm41.667 0V51.5H47.333v-8.333C47.333 33.977 54.81 26.5 64 26.5c9.19 0 16.667 7.477 16.667 16.667Zm16.666 62.5a4.172 4.172 0 0 1-4.166 4.166H34.833a4.172 4.172 0 0 1-4.166-4.166v-50h66.666v50Z"
      fill="currentColor"
    />
  </Icon>
);

export default Security;
