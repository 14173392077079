import "./fonts/inter/inter.css";

import { Global, css } from "@emotion/react";
import React from "react";

import SofiaProBoldTtf from "./fonts/sofia-pro/SofiaProBold.ttf";
import SofiaProBoldWoff from "./fonts/sofia-pro/SofiaProBold.woff";
import SofiaProBoldWoff2 from "./fonts/sofia-pro/SofiaProBold.woff2";
import SofiaProLightTtf from "./fonts/sofia-pro/SofiaProLight.ttf";
import SofiaProLightWoff from "./fonts/sofia-pro/SofiaProLight.woff";
import SofiaProLightWoff2 from "./fonts/sofia-pro/SofiaProLight.woff2";
import SofiaProRegularTtf from "./fonts/sofia-pro/SofiaProRegular.ttf";
import SofiaProRegularWoff from "./fonts/sofia-pro/SofiaProRegular.woff";
import SofiaProRegularWoff2 from "./fonts/sofia-pro/SofiaProRegular.woff2";
import SofiaProSemiBoldTtf from "./fonts/sofia-pro/SofiaProSemiBold.ttf";
import SofiaProSemiBoldWoff from "./fonts/sofia-pro/SofiaProSemiBold.woff";
import SofiaProSemiBoldWoff2 from "./fonts/sofia-pro/SofiaProSemiBold.woff2";

const FontFaces = () => (
  <>
    <Global
      styles={css`
        @font-face {
          font-family: "Sofia Pro";
          src: url(${SofiaProLightWoff2}) format("woff2"),
            url(${SofiaProLightWoff}) format("woff"),
            url(${SofiaProLightTtf}) format("truetype");
          font-weight: 300;
          font-style: normal;
        }

        @font-face {
          font-family: "Sofia Pro";
          src: url(${SofiaProRegularWoff2}) format("woff2"),
            url(${SofiaProRegularWoff}) format("woff"),
            url(${SofiaProRegularTtf}) format("truetype");
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: "Sofia Pro";
          src: url(${SofiaProSemiBoldWoff2}) format("woff2"),
            url(${SofiaProSemiBoldWoff}) format("woff"),
            url(${SofiaProSemiBoldTtf}) format("truetype");
          font-weight: 600;
          font-style: normal;
        }

        @font-face {
          font-family: "Sofia Pro";
          src: url(${SofiaProBoldWoff2}) format("woff2"),
            url(${SofiaProBoldWoff}) format("woff"),
            url(${SofiaProBoldTtf}) format("truetype");
          font-weight: 700;
          font-style: normal;
        }
      `}
    />
  </>
);

export default FontFaces;
