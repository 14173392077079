import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import Confetti from "../../components/elements/confetti";
import Link from "../../components/elements/link";
import { useCompanyQuery } from "../../graphql/graphql";
import { getRoutePath } from "../../router";

const CompanyCreatedModal: React.FC = () => {
  const [, setSearchParams] = useSearchParams();
  const { data: companyData } = useCompanyQuery();

  const handleModalClose = () => {
    setSearchParams({});
  };

  return (
    <Modal isOpen onClose={handleModalClose} size="xl">
      <ModalOverlay zIndex={1800}>
        <ModalContent>
          <ModalCloseButton zIndex="1" />
          <ModalBody>
            <Confetti>
              <Box
                position="relative"
                textAlign="center"
                marginY="12"
                zIndex="1"
              >
                <Heading as="h1" size="3xl" marginTop="8" textAlign="center">
                  Success!
                </Heading>
                <Box textAlign="center" marginTop="4">
                  <Text marginBottom="4">
                    You have successfully added
                    <Text
                      as="span"
                      fontWeight="bold"
                    >{` ${companyData?.company.name}`}</Text>
                    .
                  </Text>
                </Box>
                <Link
                  to={getRoutePath("dashboard")}
                  marginTop={{ base: "8", lg: "12" }}
                  marginBottom={{ base: "12", lg: "0" }}
                  width="full"
                  fontSize="sm"
                >
                  CONTINUE
                </Link>
              </Box>
            </Confetti>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default CompanyCreatedModal;
