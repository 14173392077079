import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyFragmentFragment,
  useCompanyUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { nameSchema, yupObject } from "../../../utils/validation";

interface CompanyNameChangePagePresenterProps {
  currentCompany: CompanyFragmentFragment;
}

const CompanyNameChangePagePresenter: React.FC<
  CompanyNameChangePagePresenterProps
> = ({ currentCompany }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [companyUpdateMutation, { loading }] = useCompanyUpdateMutation();

  const onSubmit = async (
    data: CompanyFormData,
    { setFieldError }: FormikHelpers<CompanyFormData>
  ) => {
    try {
      const { data: serverData, errors } = await companyUpdateMutation({
        variables: { data },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        navigate(getRoutePath("companySettings"));
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Formik
      initialValues={{ name: currentCompany.name }}
      validationSchema={CompanyFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ getFieldProps }) => (
        <Form noValidate>
          <FormGroup label="Company Name" name="name">
            <Input
              autoFocus
              autoComplete="organization"
              {...getFieldProps("name")}
            />
          </FormGroup>

          <Box marginY="10">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyNameChangePagePresenter;

const CompanyFormValidationSchema = yupObject().shape({
  name: nameSchema.label("Company name"),
});

type CompanyFormData = {
  name: string;
};
