import { EuiMarkdownFormat, useEuiTheme } from "@elastic/eui";
import React from "react";

import Label from "../../../../components/label";
import { WorkOrderVerifyAndSaveDescriptionProps } from "./index";

const WorkOrderVerifyAndSaveDescriptionPresenter: React.FC<
  WorkOrderVerifyAndSaveDescriptionProps
> = ({ workOrder }) => {
  const { euiTheme }: any = useEuiTheme();

  return (
    <>
      <Label label="Description" style={{ paddingBottom: "5px" }} />
      <EuiMarkdownFormat
        style={{
          padding: euiTheme.size.s,
          paddingTop: "0",
          borderRadius: "0",
          marginLeft: "-8px",
          maxHeight: "500px",
          overflow: "auto",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "17px",
          color: workOrder.description ? "#343741" : "#69707D",
        }}
      >
        {workOrder.description || "None"}
      </EuiMarkdownFormat>
    </>
  );
};

export default WorkOrderVerifyAndSaveDescriptionPresenter;
