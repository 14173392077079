import { Icon, IconProps } from "@chakra-ui/react";

const MainDistributionPanel = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <rect
      x={19.371}
      y={17}
      width={89.258}
      height={94}
      rx={2}
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
    <circle
      cx={50.565}
      cy={46.613}
      r={18.548}
      fillOpacity={0}
      stroke="currentColor"
      strokeWidth={4}
    />
    <path
      d="M63.407 60.312c0-7.093-5.75-12.843-12.842-12.843-7.093 0-12.843 5.75-12.843 12.843M50.852 38.051v10.274"
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
    <rect
      x={30.016}
      y={72.694}
      width={41.097}
      height={18.968}
      rx={2}
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
    <rect
      x={30.806}
      y={94.823}
      width={6.323}
      height={6.323}
      rx={2}
      fill="currentColor"
    />
    <rect
      x={41.871}
      y={94.823}
      width={6.323}
      height={6.323}
      rx={2}
      fill="currentColor"
    />
    <rect
      x={52.935}
      y={94.823}
      width={6.323}
      height={6.323}
      rx={2}
      fill="currentColor"
    />
    <rect
      x={64}
      y={94.823}
      width={6.323}
      height={6.323}
      rx={2}
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={5}
      d="M86.258 40.291V87.71M87.206 64.919l9.8-9.799"
    />
    <circle cx={98.498} cy={53.173} r={6.323} fill="currentColor" />
  </Icon>
);

export default MainDistributionPanel;
