import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";

import {
  ASSET_FILE_DELETED_MESSAGE,
  ASSET_IMAGE_DELETED_MESSAGE,
} from "../../../constants/lang/en";
import {
  AssetFileFragmentFragment,
  AssetFileType,
  useAssetFileDeleteMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";

interface AssetFileDeleteProps {
  assetFile: AssetFileFragmentFragment;
  assetId: string;
  handleDelete: () => void;
}

const AssetFileDelete: React.FC<AssetFileDeleteProps> = ({
  assetFile,
  assetId,
  handleDelete,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const [deleting, setDeleting] = React.useState(false);
  const { isOpen, onClose: onDrawerClose } = useDisclosure({
    defaultIsOpen: true,
  });
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [assetFileDeleteMutation] = useAssetFileDeleteMutation();

  const onClose = () => {
    onDrawerClose();
    handleDelete();
  };

  const deleteAsset = async () => {
    setDeleting(true);
    try {
      await assetFileDeleteMutation({
        variables: { ids: [assetFile.id] },
      });
      toast({
        description:
          assetFile.type === AssetFileType.Image
            ? ASSET_IMAGE_DELETED_MESSAGE
            : ASSET_FILE_DELETED_MESSAGE,
        status: "success",
        position: "top",
        isClosable: true,
      });
      client.cache.evict({ id: `AssetFile:${assetFile.id}` });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setDeleting(false);
      onClose();
    }
  };

  return (
    <>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete the following{" "}
              {assetFile.type === "IMAGE" ? "picture" : "document"}?
              <br />
              <Text as="strong">{assetFile.name}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                width="48%"
                isLoading={deleting}
              >
                No, Don't Delete!
              </Button>
              <Button
                onClick={deleteAsset}
                colorScheme="red"
                ml="4%"
                width="48%"
                isLoading={deleting}
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AssetFileDelete;

gql`
  mutation AssetFileDelete($ids: [UUID!]!) {
    assetFileDelete(ids: $ids)
  }
`;
