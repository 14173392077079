import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  BREAKER_CREATED_MESSAGE,
  BREAKER_NO_SPACE_MESSAGE,
  BREAKER_POLE_OVERLAP_MESSAGE,
} from "../../../../constants/lang/en";
import {
  DEFAULT_BREAKER_ROWS,
  getDefaultBreakerColumns,
} from "../../../../constants/misc";
import {
  AssetDocument,
  useAssetPartCreateMutation,
  useAssetQuery,
  useAssetUpdateConfigMutation,
} from "../../../../graphql/graphql";
import { getRoutePath } from "../../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../../utils/serverErrors";
import Presenter, { AssetsPartFormData } from "./presenter";

interface AssetPartCreatePageProps {}

const AssetPartCreatePage: React.FC<AssetPartCreatePageProps> = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { assetId } = useParams();
  const [searchParams] = useSearchParams();

  const [assetPartCreateMutation, { loading }] = useAssetPartCreateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AssetDocument,
        variables: { id: assetId },
      },
    ],
  });
  const [assetUpdateConfigMutation, { loading: configLoading }] =
    useAssetUpdateConfigMutation();
  const { data: assetData } = useAssetQuery({
    variables: { id: assetId },
  });

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onSubmit = async (
    data: AssetsPartFormData,
    { setFieldError }: FormikHelpers<AssetsPartFormData>
  ) => {
    const asset = assetData?.asset;
    if (!asset) return;
    const {
      numberOfColumns = getDefaultBreakerColumns(asset.assetType.name),
      numberOfRows = DEFAULT_BREAKER_ROWS,
      assetPartPositions = {},
    } = asset.config;
    const { columnIndex, rowIndex, poles, ...otherData } = data;

    // Check if breaker poles lie outside
    if (columnIndex + 1 > numberOfColumns || rowIndex + poles > numberOfRows) {
      toast({
        description: BREAKER_NO_SPACE_MESSAGE,
        status: "error",
        position: "top",
        isClosable: true,
      });
      return;
    }

    // Check if breaker poles overlap with existing breakers
    let breakerError = false;
    Array.from(Array(poles)).forEach((_, poleIndex) => {
      if (assetPartPositions[`${rowIndex + poleIndex}-${columnIndex}`]) {
        toast({
          description: BREAKER_POLE_OVERLAP_MESSAGE,
          status: "error",
          position: "top",
          isClosable: true,
        });
        breakerError = true;
        return;
      }
    });
    if (breakerError) return;

    try {
      const { data: serverData, errors } = await assetPartCreateMutation({
        variables: { data: [{ ...otherData, assetId }] },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        const newBreakerAssetPartPositions = { ...assetPartPositions };
        Array.from(Array(poles)).forEach((_, poleIndex) => {
          newBreakerAssetPartPositions[
            `${rowIndex + poleIndex}-${columnIndex}`
          ] = serverData.assetPartCreate[0].id;
        });
        const { data: serverConfigData, errors } =
          await assetUpdateConfigMutation({
            variables: {
              id: asset.id,
              config: {
                ...asset.config,
                assetPartPositions: newBreakerAssetPartPositions,
              },
            },
          });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else if (serverConfigData) {
          onDrawerClose();
          toast({
            description: BREAKER_CREATED_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetsShow", { assetId }));
    }, 500);
  };

  return assetData ? (
    <Presenter
      handleSubmit={onSubmit}
      onDrawerClose={onDrawerClose}
      isOpen={isOpen}
      loading={loading || configLoading}
      asset={assetData.asset}
      rowIndex={
        searchParams.has("rowIndex")
          ? +(searchParams.get("rowIndex") as string)
          : undefined
      }
      columnIndex={
        searchParams.has("columnIndex")
          ? +(searchParams.get("columnIndex") as string)
          : undefined
      }
    />
  ) : null;
};

export default AssetPartCreatePage;

gql`
  mutation AssetPartCreate($data: [AssetPartCreateInput!]!) {
    assetPartCreate(data: $data) {
      ...AssetPartWithRelationsFragment
    }
  }
`;
