import {
  Box,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";

import AssetIconField from "../../../components/elements/assetIcons/field";
import AssetTypeFields from "../../../components/elements/assetTypeFields";
import FormGroup from "../../../components/elements/formGroup";
import SelectCombobox from "../../../components/elements/selectCombobox";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetTypeWithRelationsFragmentFragment,
  SpecialAssetFieldTypes,
  useAssetTypeUpdateMutation,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  AssetTypeFormData,
  AssetTypeValidationSchema,
  SpecialAssetFieldTypeOptions,
  getFieldsInitialValues,
} from "../assetTypesCreate/presenter";

interface AssetTypeEditPresenterProps {
  assetCategoryIconColor: string;
  assetType: AssetTypeWithRelationsFragmentFragment;
  onDrawerClose: () => void;
}

const AssetTypeEditPresenter: React.FC<AssetTypeEditPresenterProps> = ({
  assetCategoryIconColor,
  assetType,
  onDrawerClose,
}) => {
  const toast = useToast();

  const [assetTypeUpdateMutation, { loading: isLoading }] =
    useAssetTypeUpdateMutation();

  const onSubmit = async (
    data: AssetTypeFormData,
    { setFieldError }: FormikHelpers<AssetTypeFormData>
  ) => {
    try {
      const { data: serverData, errors } = await assetTypeUpdateMutation({
        variables: {
          data: { ...data, assetCategoryId: assetType.assetCategoryId },
          id: assetType.id,
        },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit {assetType.name}</title>
      </Helmet>
      <DrawerCloseButton />
      <DrawerHeader>Edit {assetType.name}</DrawerHeader>
      <DrawerBody>
        <Formik
          initialValues={
            {
              name: assetType.name,
              description: assetType.description,
              iconName: assetType.iconName,
              iconColor: assetType.iconColor,
              iconType: assetType.iconType,
              specialAssetField:
                assetType.specialAssetField || SpecialAssetFieldTypes.Na,
              assetFieldsInput: getFieldsInitialValues(assetType.assetFields),
              assetPartFieldsInput: getFieldsInitialValues(
                assetType.assetPartFields
              ),
            } as AssetTypeFormData
          }
          onSubmit={onSubmit}
          validationSchema={AssetTypeValidationSchema}
          enableReinitialize
        >
          {({ getFieldProps, values }) => (
            <Form id="asset_type_edit" noValidate>
              <FormGroup label="Asset Type Name" name="name">
                <Input
                  autoFocus
                  autoComplete="off"
                  {...getFieldProps("name")}
                />
              </FormGroup>
              <FormGroup label="Description" name="description">
                <Textarea
                  autoComplete="off"
                  {...getFieldProps("description")}
                />
              </FormGroup>
              <AssetIconField parentColor={assetCategoryIconColor} />
              <Box display="none">
                <SelectCombobox
                  label="Special Asset Field"
                  name="specialAssetField"
                  options={SpecialAssetFieldTypeOptions}
                />
              </Box>
              <AssetTypeFields />
              {values.specialAssetField !== SpecialAssetFieldTypes.Na && (
                <AssetTypeFields
                  name="assetPartFieldsInput"
                  label="Breaker Custom Fields"
                />
              )}
            </Form>
          )}
        </Formik>
      </DrawerBody>
      <DrawerFooter>
        <Button
          width="full"
          type="submit"
          isLoading={isLoading}
          form="asset_type_edit"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
};

export default AssetTypeEditPresenter;

gql`
  mutation AssetTypeUpdate($id: UUID!, $data: AssetTypeInput!) {
    assetTypeUpdate(id: $id, data: $data) {
      ...AssetTypeWithRelationsFragment
    }
  }
`;
