import { cloneDeep } from "lodash";
import moment from "moment-timezone";

import { getUtcOffsetFromTimezoneName } from "../../../helpers/dateTime";
import { timezonesList } from "../../../helpers/timezones";
import {
  BusinessSeverityEnum,
  OrderPriorityEnum,
  ScheduleTypeEnum,
  businessSeverityLabels,
  orderPriorityLabels,
  scheduleTypeLabels,
} from "../../../models/enums";
import { WorkOrder } from "../../../models/workOrder";
import { sectionsConfig } from "./sectionsConfig";

export type SectionItem = {
  label: string;
  collapsed?: boolean;
  fields: SectionField[];
};
export type SectionField = {
  label: string;
  fieldId: keyof WorkOrder | "reporter" | "recurrence";
  value: string | string[];
  className?: string;
};
type EntityItem = {
  id: string;
  name: string;
};
type User = { id: string; fullName: string };
export type WorkOrderMappingArgs = {
  workOrder: WorkOrder;
  users: User[];
  orderTypes: EntityItem[];
  serviceCategories: EntityItem[];
  locations: EntityItem[];
  companies: EntityItem[];
  currentUser: { fullName: string };
};

export class WorkOrderMappingHelper {
  static format = "MM/DD/YYYY hh:mm A";

  static getMappedWorkOrderSections(workOrderData: WorkOrderMappingArgs) {
    const config = cloneDeep(sectionsConfig);
    return config.map((section) => {
      const fields = section.fields.map((field) =>
        this.handleField(field, workOrderData)
      );
      return { ...section, fields };
    });
  }

  static getDateTimeValue(
    dateTimeValue: number,
    timeZone?: string | null
  ): string {
    const offset = getUtcOffsetFromTimezoneName(timeZone);
    if (offset) {
      return moment
        .utc(dateTimeValue)
        .subtract(offset, "minutes")
        .format(this.format);
    } else {
      return moment(dateTimeValue).format(this.format);
    }
  }

  private static handleField(
    field: SectionField,
    {
      workOrder,
      users,
      orderTypes,
      serviceCategories,
      locations,
      companies,
      currentUser,
    }: WorkOrderMappingArgs
  ) {
    const userField = "accountableUserId";
    const multiUsersFields = [
      "informingUserIds",
      "consultingUserIds",
      "responsibleUserIds",
    ];
    let value: string | string[] = "";

    if (["reporter", "recurrence"].includes(field.fieldId)) {
      if (field.fieldId === "recurrence") {
        value = "Does not repeat";
      }
      if (field.fieldId === "reporter") {
        value = currentUser.fullName;
      }
      return { ...field, value };
    }
    const inputValue = workOrder[field.fieldId as keyof WorkOrder];
    const isInputValuePresent =
      inputValue instanceof Array ? !!inputValue?.length : !!inputValue;
    if (isInputValuePresent) {
      if (multiUsersFields.includes(field.fieldId)) {
        value = users
          .filter((user) => inputValue.includes(user.id))
          .map((user) => user.fullName);
      } else if (field.fieldId === userField) {
        value = users.find((user) => user.id === inputValue)?.fullName || "";
      } else if (field.fieldId === "serviceCategoryId") {
        value =
          serviceCategories.find((user) => user.id === inputValue)?.name || "";
      } else if (field.fieldId === "orderTypeId") {
        value = orderTypes.find((user) => user.id === inputValue)?.name || "";
      } else if (field.fieldId === "companyId") {
        value = companies.find((item) => item.id === inputValue)?.name || "";
      } else if (field.fieldId === "locationId") {
        value = locations.find((item) => item.id === inputValue)?.name || "";
      } else if (field.fieldId === "businessSeverity") {
        value = businessSeverityLabels[inputValue as BusinessSeverityEnum];
      } else if (field.fieldId === "scheduleType") {
        value = scheduleTypeLabels[inputValue as ScheduleTypeEnum];
      } else if (field.fieldId === "orderPriority") {
        value = orderPriorityLabels[inputValue as OrderPriorityEnum];
      } else if (field.fieldId === "parentOrderId") {
        value =
          `${workOrder.parentOrder?.key} | ${workOrder.parentOrder?.name}` ||
          "";
      } else if (
        field.fieldId === "startDateTime" ||
        field.fieldId === "endDateTime"
      ) {
        value = this.getDateTimeValue(
          inputValue,
          workOrder?.serviceScheduleTimezone
        );
      } else if (field.fieldId === "serviceScheduleTimezone") {
        value =
          timezonesList.find((item) => item.timezone === inputValue)?.name ||
          inputValue;
      } else {
        value = inputValue;
      }
    } else {
      value = "None";
      field.className ||= "none";
    }

    return { ...field, value };
  }
}
