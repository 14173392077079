import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import useBoxSize from "../../hooks/useBoxSize";
import { getRoutePath } from "../../router";

interface SystemStatusPageProps {}

const SystemStatusPage: React.FC<SystemStatusPageProps> = () => {
  const { containerRef, height } = useBoxSize();

  useEffect(() => {
    layoutOptionsVar({
      title: "System Status",
      styleProps: { paddingX: "0" },
      backLink: getRoutePath("helpAndSupport"),
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>System Status</title>
      </Helmet>
      <Box ref={containerRef}>
        <Box height={height}>
          <iframe
            src="https://status.criticalasset.com"
            frameBorder="0"
            title="CriticalAsset Status"
            height="100%"
            width="100%"
            allowFullScreen
          />
        </Box>
      </Box>
    </>
  );
};

export default SystemStatusPage;
