import { EuiBadge, transparentize, useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import React from "react";

interface SimpleBadgeProps {
  label: string;
  onIconClick: () => void;
  iconOnClickAriaLabel?: string;
}

const SimpleBadge: React.FC<SimpleBadgeProps> = ({
  label,
  onIconClick,
  iconOnClickAriaLabel = "unselect",
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const labelMaxWidth = "80px";
  return (
    <EuiBadge
      iconType="cross"
      iconSide="right"
      color="hollow"
      iconOnClick={onIconClick}
      iconOnClickAriaLabel={iconOnClickAriaLabel}
      css={css`
        &.euiBadge {
          color: ${euiTheme.colors.secondary["500"]};
          background-color: ${transparentize(
            euiTheme.colors.secondary["500"],
            0.1
          )};
          border: 1px solid ${euiTheme.colors.secondary["500"]};
          border-radius: 6px;
          padding: 0 ${euiTheme.size.xs};
        }
      `}
    >
      <div
        style={{
          fontWeight: 500,
          fontSize: euiTheme.size.m,
          lineHeight: euiTheme.sizes["20px"],
          maxWidth: labelMaxWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {label}
      </div>
    </EuiBadge>
  );
};

export default SimpleBadge;
