import {
  Box,
  Center,
  Flex,
  Menu,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";

import AssetCategorySelectOption from "../../../../components/elements/assetCategorySelectOption";
import CenteredFullHeight from "../../../../components/elements/centeredFullHeight";
import LabeledMenuButton from "../../../../components/elements/labeledMenuButton";
import Link from "../../../../components/elements/link";
import QrCodes from "../../../../components/elements/qrCodes";
import AddCircle from "../../../../components/icons/addCircle";
import {
  AssetCategoryWithTypesFragmentFragment,
  AssetWithTypeFragmentFragment,
  MaintenanceStatusType,
  SortBy,
  SortOrder,
} from "../../../../graphql/graphql";
import { CurrentCompanyRole } from "../../../../graphql/types";
import ROLES from "../../../../roles";
import { getRoutePath } from "../../../../router";
import { sortByOptions } from "../../../../utils/sort";
import { maintenanceStatusTypeOptions } from "../../../assets/presenter";
import List from "./list";

interface AssetsPagePresenterProps {
  setSort: (value: string) => void;
  sortBy: SortBy;
  sortOrder: SortOrder;
  setFilter: (value: string[]) => void;
  filter: MaintenanceStatusType[];
  currentCompanyRole: CurrentCompanyRole;
  assets: Array<AssetWithTypeFragmentFragment>;
  hideCreate?: boolean;
  hideDisplayType?: boolean;
  unfilteredAssetsPresent: boolean;
  assetCategories: AssetCategoryWithTypesFragmentFragment[];
  assetCategory?: AssetCategoryWithTypesFragmentFragment;
  setAssetCategoryId: (id: string) => void;
}

const AssetsPagePresenter: React.FC<AssetsPagePresenterProps> = ({
  setSort,
  sortBy,
  sortOrder,
  setFilter,
  filter,
  currentCompanyRole,
  assets,
  hideCreate = false,
  hideDisplayType = false,
  unfilteredAssetsPresent,
  assetCategories,
  assetCategory,
  setAssetCategoryId,
}) => {
  const selectedSortOption = React.useMemo(
    () =>
      sortByOptions.find((option) => option.value === `${sortBy}-${sortOrder}`),
    [sortBy, sortOrder]
  );
  const selectedFilterOptions = maintenanceStatusTypeOptions.filter((option) =>
    filter.includes(option.value)
  );
  const selectedFilterValues = selectedFilterOptions.map(({ value }) => value);
  const selectedFilterLabels =
    selectedFilterOptions.length < 1 || selectedFilterOptions.length > 2
      ? "All"
      : selectedFilterOptions.map(({ label }) => label);

  const canAddAsset = ROLES.assetsCreate.includes(currentCompanyRole.role);
  const canEditAsset = ROLES.assetsEdit.includes(currentCompanyRole.role);
  const canDeleteAsset = ROLES.assetsDelete.includes(currentCompanyRole.role);

  return (
    <Box>
      {unfilteredAssetsPresent ? (
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom="6"
            marginX={{ base: "2", md: "0" }}
          >
            <Flex alignItems="center">
              <Box marginRight="4">
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        value={
                          selectedSortOption ? selectedSortOption.label : "Sort"
                        }
                      />
                      <MenuList>
                        {sortByOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            onClick={() => setSort(option.value)}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              <Box marginRight="4" display={{ base: "none", md: "block" }}>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        label="Category"
                        value={
                          assetCategory ? (
                            <AssetCategorySelectOption
                              assetCategory={assetCategory}
                            />
                          ) : (
                            "All"
                          )
                        }
                        styleProps={{
                          maxWidth: "none !important",
                          width: "150px",
                        }}
                      />
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            setAssetCategoryId("");
                          }}
                        >
                          All
                        </MenuItem>
                        {assetCategories.map((ac) => (
                          <MenuItem
                            key={ac.id}
                            onClick={() => {
                              if (ac.id === assetCategory?.id) return;
                              setAssetCategoryId(ac.id);
                            }}
                          >
                            <AssetCategorySelectOption assetCategory={ac} />
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              <Box display={{ base: "none", md: "block" }} marginRight="4">
                <Menu closeOnSelect={false}>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        value={
                          Array.isArray(selectedFilterLabels) ? (
                            <Flex align="center">
                              {selectedFilterLabels.map((fl: any, index) => (
                                <Box key={index} mr="2">
                                  {fl}
                                </Box>
                              ))}
                            </Flex>
                          ) : (
                            selectedFilterLabels
                          )
                        }
                        label="Status"
                        styleProps={{
                          maxWidth: "none !important",
                          width: "180px",
                        }}
                      />
                      <MenuList>
                        <MenuOptionGroup
                          type="checkbox"
                          onChange={(value) =>
                            setFilter(
                              Array.isArray(value)
                                ? value.includes("All")
                                  ? [
                                      MaintenanceStatusType.Current,
                                      MaintenanceStatusType.DueIn_30,
                                      MaintenanceStatusType.PastDue,
                                    ]
                                  : value
                                : [value]
                            )
                          }
                          value={selectedFilterValues}
                        >
                          <MenuItemOption value="All">All</MenuItemOption>
                          {maintenanceStatusTypeOptions.map((option) => (
                            <MenuItemOption
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItemOption>
                          ))}
                        </MenuOptionGroup>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              <QrCodes
                links={assets.map((a: any) => ({
                  path: getRoutePath("assetsShow", { assetId: a.id }),
                  name: a.name,
                }))}
                printDocumentTitle="Assets QR Codes"
                colorScheme="secondary"
                fontSize="xl"
                color="gray.700"
              />
            </Flex>
            <Box>
              {!hideCreate && canAddAsset && (
                <Tooltip label="Add new asset" hasArrow placement="left">
                  <Link
                    to={getRoutePath("assetsCreate")}
                    aria-label="Add new asset"
                    variant="icon"
                    colorScheme="secondary"
                  >
                    <AddCircle boxSize="32px" />
                  </Link>
                </Tooltip>
              )}
            </Box>
          </Flex>
          {assets.length > 0 ? (
            <List
              assets={assets}
              canDeleteAsset={canDeleteAsset}
              canEditAsset={canEditAsset}
            />
          ) : (
            <Center height="100px" color="gray.700">
              Assets empty
            </Center>
          )}
        </>
      ) : (
        <CenteredFullHeight>
          {canAddAsset && !hideCreate ? (
            <Link to={getRoutePath("assetsCreate")}>Add an Asset</Link>
          ) : (
            <Box color="gray.700">
              Assets empty
              {canAddAsset && (
                <>
                  .{" "}
                  <Link
                    to={getRoutePath("assetsCreate")}
                    variant="link"
                    colorScheme="secondary"
                  >
                    {" "}
                    Add new asset
                  </Link>
                </>
              )}
            </Box>
          )}
        </CenteredFullHeight>
      )}
    </Box>
  );
};

export default AssetsPagePresenter;
