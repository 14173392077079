import { Box, Button, Flex, List, ListItem, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import FormGroup from "../../components/elements/formGroup";
import Link from "../../components/elements/link";
import PasswordInput from "../../components/elements/passwordInput";
import {
  GENERIC_SAVED_MESSAGE,
  RESET_TOKEN_EXPIRED,
} from "../../constants/lang/en";
import { useUserResetPasswordMutation } from "../../graphql/graphql";
import { getRoutePath } from "../../router";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import {
  newPasswordConfirmSchema,
  newPasswordSchema,
  yupObject,
} from "../../utils/validation";

interface ResetPasswordPagePresenterProps {}

const ResetPasswordPagePresenter: React.FC<
  ResetPasswordPagePresenterProps
> = () => {
  const toast = useToast();
  const [userResetPassword, { loading }] = useUserResetPasswordMutation();
  const { email: emailEncoded, token: uuid } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (
    { passwordRaw }: ResetPasswordFormData,
    { setFieldError }: FormikHelpers<ResetPasswordFormData>
  ) => {
    try {
      if (!emailEncoded || !uuid) {
        toast({
          description: RESET_TOKEN_EXPIRED,
          status: "error",
          position: "top",
          isClosable: true,
        });
        navigate(getRoutePath("forgotPassword"));
        return;
      }
      const { errors } = await userResetPassword({
        variables: { data: { passwordRaw, emailEncoded, uuid } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        navigate(getRoutePath("signIn"));
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        passwordRaw: "",
        passwordRawConfirm: "",
      }}
      validationSchema={ForgotPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form noValidate>
          <Flex
            flexWrap="wrap"
            justifyContent="space-evenly"
            maxWidth="lg"
            padding={{ xl: 12 }}
          >
            <FormGroup
              label="New Password"
              name="passwordRaw"
              renderError={false}
            >
              <PasswordInput
                showPasswordHint
                autoComplete="new-password"
                name="passwordRaw"
              />
            </FormGroup>
            <FormGroup label="Confirm new password" name="passwordRawConfirm">
              <PasswordInput
                autoComplete="new-password"
                name="passwordRawConfirm"
              />
            </FormGroup>
            <Box marginTop="8" marginBottom="4" width="100%">
              <Button width="full" type="submit" isLoading={loading}>
                Save New Password
              </Button>
            </Box>
            <List color="gray.600" textAlign="center">
              <ListItem marginBottom="3">
                Already have an account?{" "}
                <Link
                  to={getRoutePath("signIn")}
                  variant="link"
                  colorScheme="secondary"
                >
                  Sign in
                </Link>
              </ListItem>
            </List>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordPagePresenter;

const ForgotPasswordValidationSchema = yupObject().shape({
  passwordRaw: newPasswordSchema,
  passwordRawConfirm: newPasswordConfirmSchema,
});

type ResetPasswordFormData = {
  passwordRaw: string;
  passwordRawConfirm: string;
};

gql`
  mutation userResetPassword($data: UserResetPasswordInput!) {
    userResetPassword(data: $data)
  }
`;
