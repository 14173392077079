import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";
import { useNavigate } from "react-router-dom";

import Link from "../../components/elements/link";
import PageSpinner from "../../components/elements/pageSpinner";
import {
  CompanyDocument,
  CompanyFragmentFragment,
  PlansFragmentFragment,
  StripeInvoiceUpcomingDocument,
  UserWithCompaniesDocument,
  useCompanyDeleteMutation,
  useStripeSubscriptionCreateOrUpdateMutation,
} from "../../graphql/graphql";
import { getRoutePath } from "../../router";
import { getCoupon, removeCoupon } from "../../utils/coupon";
import { setGenericMessage } from "../../utils/serverErrors";
import CompanyCreateCancel from "../companyCreate/companyCreateCancel";
import CheckoutHeader from "./checkout";
import PaymentMethods from "./paymentMethods";
import Plans from "./plans";

interface CompanyPlanCreatePagePresenterProps {
  plans: PlansFragmentFragment[];
  userCompanies: CompanyFragmentFragment[];
  selectedCompanyId?: string;
  redirectLink?: string;
  showCancelLink?: boolean;
  header?: string;
  setCurrentTab?: (tab: number) => void;
  onSuccess?: () => void;
}

const CompanyPlanCreatePagePresenter: React.FC<
  CompanyPlanCreatePagePresenterProps
> = ({
  plans,
  userCompanies,
  selectedCompanyId,
  header,
  redirectLink = getRoutePath("dashboard"),
  showCancelLink = true,
  setCurrentTab,
  onSuccess,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activePlan, setActivePlan] = React.useState<PlansFragmentFragment>();
  const [subscriptionLoading, setSubscriptionLoading] = React.useState(false);
  const navigate = useNavigate();
  const [companyDeleteMutation] = useCompanyDeleteMutation();
  const [stripeSubscriptionCreateOrUpdateMutation] =
    useStripeSubscriptionCreateOrUpdateMutation({
      refetchQueries: [
        { query: UserWithCompaniesDocument },
        { query: CompanyDocument },
        { query: StripeInvoiceUpcomingDocument },
      ],
      awaitRefetchQueries: true,
    });

  React.useEffect(() => {
    // Redirect if there is no selected company
    if (!selectedCompanyId) {
      navigate(getRoutePath("companyCreate"));
    }
  }, [navigate, selectedCompanyId]);

  React.useEffect(() => {
    setCurrentTab && setCurrentTab(activePlan ? 3 : 2);
  }, [activePlan, setCurrentTab]);

  const handlePlanSelected = (planStripeId: string) => {
    const plan = plans.find((plan) => plan.stripeId === planStripeId);
    if (!plan) return;
    setActivePlan(plan);
    setCurrentTab && setCurrentTab(3);
  };

  const handlePaymentSelected = async () => {
    if (!activePlan) return;
    try {
      setSubscriptionLoading(true);
      await stripeSubscriptionCreateOrUpdateMutation({
        variables: {
          planId: activePlan.stripeId,
          couponCode: getCoupon() || undefined,
        },
      });
      removeCoupon();
      if (onSuccess) {
        onSuccess();
      } else {
        navigate(redirectLink);
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      setSubscriptionLoading(false);
    }
  };

  const otherCompaniesWithPlan = userCompanies.filter(
    (company) => company.planStripeId
  );

  const deleteCompanyAndRedirect = React.useCallback(async () => {
    try {
      const { errors } = await companyDeleteMutation();
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        navigate(getRoutePath("resetCompany"));
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [companyDeleteMutation, navigate, toast]);

  return subscriptionLoading ? (
    <PageSpinner />
  ) : (
    <>
      {!activePlan ? (
        <>
          <Plans onPlanSelected={handlePlanSelected} header={header} />
        </>
      ) : (
        <Box maxWidth="xl" margin="auto">
          <CheckoutHeader
            setActivePlan={setActivePlan}
            activePlan={activePlan}
          />
          <PaymentMethods onPaymentSelected={handlePaymentSelected} />
        </Box>
      )}
      {showCancelLink && otherCompaniesWithPlan.length > 0 && (
        <>
          <Box textAlign="center" marginTop="4">
            <Button
              variant="link"
              colorScheme="red"
              onClick={onOpen}
              color="gray.600"
              fontSize="sm"
            >
              CANCEL
            </Button>
          </Box>
          {isOpen && (
            <CompanyCreateCancel
              isOpen={isOpen}
              onClose={onClose}
              onConfirm={deleteCompanyAndRedirect}
            />
          )}
        </>
      )}
      {showCancelLink && otherCompaniesWithPlan.length === 0 && (
        <Box textAlign="center" mt="6">
          <Link
            to={getRoutePath("signOut")}
            variant="link"
            colorScheme="secondary"
            color="gray.600"
            fontSize="sm"
          >
            SIGN OUT
          </Link>
        </Box>
      )}
    </>
  );
};

export default CompanyPlanCreatePagePresenter;

gql`
  mutation StripeSubscriptionCreateOrUpdate(
    $planId: String!
    $couponCode: String
  ) {
    stripeSubscriptionCreateOrUpdate(planId: $planId, couponCode: $couponCode) {
      ...StripeSubscriptionFragment
    }
  }

  mutation CompanyDelete {
    companyDelete
  }
`;
