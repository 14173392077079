import { EuiBasicTableColumn } from "@elastic/eui/src/components/basic_table/basic_table";
import React from "react";

import TruncatedText from "../../../../components/truncatedText";
import { AssetRelation } from "../models/relations";

export const tableConfig: EuiBasicTableColumn<AssetRelation>[] = [
  {
    field: "relationType",
    name: "Relation Type",
    truncateText: true,
    sortable: true,
    render: (relationType: string) => {
      return <TruncatedText text={relationType} truncate={true} />;
    },
  },
  {
    field: "assetName",
    name: "Related Asset",
    truncateText: true,
    render: (assetName: string) => {
      return <TruncatedText text={assetName} truncate={true} />;
    },
  },
  {
    field: "locationDisplayName",
    name: "Related Asset's Sublocations",
    truncateText: true,
    render: (assetType: string) => {
      return <TruncatedText text={assetType} truncate={true} />;
    },
  },
];
