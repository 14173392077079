import { Box, Button, Flex, Heading, Tooltip } from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import AssetIcon from "../../../components/elements/assetIcons";
import {
  AssetCategoryWithTypesFragmentFragment,
  AssetTypeFragmentFragment,
} from "../../../graphql/graphql";

interface SelectAssetCategoryProps {
  onSuccess: React.Dispatch<
    React.SetStateAction<AssetCategoryWithTypesFragmentFragment | undefined>
  >;
  selectedAssetType: AssetTypeFragmentFragment;
  assetCategories: AssetCategoryWithTypesFragmentFragment[];
}

const SelectAssetCategory: React.FC<SelectAssetCategoryProps> = ({
  onSuccess,
  selectedAssetType,
  assetCategories,
}) => {
  return (
    <>
      <Heading
        as="h4"
        size="base"
        isTruncated
        textAlign="center"
        paddingBottom="2"
        marginBottom="2"
        borderBottomColor="gray.100"
        borderBottomWidth="1px"
      >
        Select Asset Category
      </Heading>
      {assetCategories.map((ac) => (
        <Box key={ac.id} role="group" _hover={{ bgColor: "gray.100" }}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex
              width="342px"
              _groupHover={{ width: "calc(100% - 95px)" }}
              marginLeft="2"
              alignItems="center"
              justifyContent="start"
              paddingY=".5rem !important"
              borderBottom="1px solid"
              borderColor="gray.100"
              height="auto"
              opacity={
                ac.id === selectedAssetType.assetCategoryId ? "0.4" : "1"
              }
              cursor={
                ac.id === selectedAssetType.assetCategoryId
                  ? "not-allowed"
                  : "default"
              }
            >
              <AssetIcon
                iconName={ac.iconName}
                iconColor={ac.iconColor}
                iconType={ac.iconType}
                iconSize="xs"
              />
              <Box
                marginLeft="2"
                color="gray.800"
                flexGrow={1}
                textAlign="left"
                isTruncated
              >
                {ac.name}
              </Box>
            </Flex>
            {ac.id === selectedAssetType.assetCategoryId ? (
              <Tooltip
                label="The destination and selected asset category can not be the same."
                hasArrow
              >
                <Box color="gray.300" marginRight="2">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Box>
              </Tooltip>
            ) : (
              <Button
                variant="outline"
                onClick={() => onSuccess(ac)}
                visibility="hidden"
                _groupHover={{ visibility: "visible" }}
                height="fit-content"
                colorScheme="secondary"
                marginX="2"
                width="70px"
                padding="1"
              >
                Move
              </Button>
            )}
          </Flex>
        </Box>
      ))}
    </>
  );
};

export default SelectAssetCategory;
