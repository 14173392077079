import { BusinessSeverityEnum, businessSeverityLabels } from "../models/enums";

export class SeverityHelper {
  static getWorkOrderTableLabel(severity: BusinessSeverityEnum): string {
    switch (severity) {
      case BusinessSeverityEnum.CRITICAL:
        return `S0 - ${
          businessSeverityLabels[severity as BusinessSeverityEnum]
        }`;
      case BusinessSeverityEnum.HIGH:
        return `S1 - ${
          businessSeverityLabels[severity as BusinessSeverityEnum]
        }`;
      case BusinessSeverityEnum.MODERATE:
        return `S2 - ${
          businessSeverityLabels[severity as BusinessSeverityEnum]
        }`;
      case BusinessSeverityEnum.LOW:
        return `S3 - ${
          businessSeverityLabels[severity as BusinessSeverityEnum]
        }`;
    }
    return "";
  }
}
