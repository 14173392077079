import React from "react";

import { useCompanyUsersQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface CompanyChangeOwnerPageProps {}

const CompanyChangeOwnerPage: React.FC<CompanyChangeOwnerPageProps> = () => {
  const { data: companyUsersData } = useCompanyUsersQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return companyUsersData ? (
    <Presenter companyUsers={companyUsersData.companyUsers} />
  ) : null;
};

export default CompanyChangeOwnerPage;
