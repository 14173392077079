import { EuiFieldText, EuiFlexGroup } from "@elastic/eui";
import React from "react";

import Label from "./label";

interface InputFieldProps {
  disabled?: boolean;
  label?: string;
  value?: string | null;
  required?: boolean;
  isInvalid?: boolean;
  onInputChange?: (value: string | null) => void;
  style?: any;
  labelAlwaysEnabled?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  disabled,
  label,
  value,
  required,
  isInvalid,
  onInputChange,
  style,
  labelAlwaysEnabled,
}) => {
  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="none"
      wrap={false}
      style={{
        width: "100%",
        ...style,
      }}
    >
      <Label
        label={label}
        required={required}
        disabled={disabled}
        labelAlwaysEnabled={labelAlwaysEnabled}
        style={{
          fontWeight: 700,
          paddingBottom: "5px",
          lineHeight: "18px",
        }}
      />
      <EuiFieldText
        disabled={disabled}
        value={value || ""}
        onChange={(e) =>
          onInputChange && onInputChange(e?.target?.value || null)
        }
        style={{ lineHeight: "40px" }}
        fullWidth={true}
        isInvalid={isInvalid}
      />
    </EuiFlexGroup>
  );
};

export default InputField;
