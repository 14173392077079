import { Icon, IconProps } from "@chakra-ui/react";

const Valve = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m85.525 67.212.148.221h23.023V64a2.433 2.433 0 1 1 4.866 0v35.2a2.433 2.433 0 1 1-4.866 0v-3.433H85.674l-.15.221C80.873 102.926 72.96 107.5 64 107.5s-16.872-4.574-21.525-11.512l-.148-.221H19.305V99.2a2.433 2.433 0 0 1-4.867 0V64a2.433 2.433 0 0 1 4.867 0v3.433h23.022l.148-.221a26.118 26.118 0 0 1 7.137-7.137l.221-.149v-16.96H46.4a2.433 2.433 0 0 1 0-4.866h3.433V22.933a2.433 2.433 0 0 1 2.434-2.433h23.466a2.433 2.433 0 0 1 2.434 2.433V38.1H81.6a2.433 2.433 0 0 1 0 4.867h-3.433v16.96l.221.148a26.116 26.116 0 0 1 7.137 7.137ZM73.3 57.418l-.667-.237A25.78 25.78 0 0 0 64 55.7a25.78 25.78 0 0 0-8.633 1.481l-.667.237V42.967h18.6v14.45Zm0-32.051H54.7V38.1h18.6V25.367ZM64 102.633c-11.598 0-21.033-9.435-21.033-21.033S52.402 60.567 64 60.567 85.033 70.002 85.033 81.6 75.598 102.633 64 102.633Zm24.419-12.4-.237.667h20.514V72.3H88.182l.237.667A25.779 25.779 0 0 1 89.9 81.6a25.78 25.78 0 0 1-1.481 8.633Zm-68.786.667h20.185l-.237-.667A25.777 25.777 0 0 1 38.1 81.6c0-3.026.523-5.932 1.481-8.633l.237-.667H19.305v18.6h.328Z"
      fill="currentColor"
    />
    <path
      d="m68.987 73.65-1.948 1.84a7.353 7.353 0 0 0-3.04-.65c-3.888 0-7.052 2.987-7.052 6.66 0 1.027.248 2 .69 2.87l-1.949 1.84a2.134 2.134 0 0 0 0 3.14c.918.867 2.407.867 3.325 0l1.948-1.84c.92.417 1.951.65 3.039.65 3.889 0 7.052-2.987 7.052-6.66 0-1.027-.247-2-.689-2.87l1.948-1.84a2.134 2.134 0 0 0 0-3.14c-.918-.867-2.406-.867-3.324 0ZM64 83.72c-1.296 0-2.351-.996-2.351-2.22 0-1.224 1.055-2.22 2.35-2.22 1.297 0 2.352.996 2.352 2.22 0 1.224-1.055 2.22-2.351 2.22Z"
      fill="currentColor"
    />
  </Icon>
);

export default Valve;
