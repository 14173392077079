import {
  Box,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
} from "@chakra-ui/react";
import React from "react";

import AddCircle from "../../../components/icons/addCircle";
import { AssetTypeWithRelationsFragmentFragment } from "../../../graphql/graphql";
import AssetTypeMaintenance from "./assetTypeMaintenance";

interface AssetTypeMaintenanceShowPresenterProps {
  assetType: AssetTypeWithRelationsFragmentFragment;
  canAddAssetTypeMaintenance: boolean;
  canEditAssetTypeMaintenance: boolean;
  canDeleteAssetTypeMaintenance: boolean;
  openCreateModal: () => void;
  setSelectedMaintenanceId: (id: string) => void;
}

const AssetTypeMaintenanceShowPresenter: React.FC<
  AssetTypeMaintenanceShowPresenterProps
> = ({
  assetType,
  canAddAssetTypeMaintenance,
  canEditAssetTypeMaintenance,
  canDeleteAssetTypeMaintenance,
  openCreateModal,
  setSelectedMaintenanceId,
}) => {
  return (
    <>
      <DrawerCloseButton />
      <DrawerHeader>
        <Box>Default service schedule for {assetType.name}</Box>
        {canAddAssetTypeMaintenance && (
          <Button
            onClick={openCreateModal}
            aria-label="Create new default service schedule"
            variant="icon"
            colorScheme="secondary"
            mt={4}
            rightIcon={<AddCircle boxSize="24px" />}
          >
            Create a new default service schedule
          </Button>
        )}
      </DrawerHeader>
      <DrawerBody>
        {assetType.maintenanceTemplates.length ? (
          <Box>
            {assetType.maintenanceTemplates.map((mt) => (
              <AssetTypeMaintenance
                key={mt.id}
                id={mt.id}
                assetTypeId={mt.assetTypeId}
                name={mt.name}
                intervalType={mt.intervalType}
                intervalValue={mt.intervalValue}
                canEditAssetTypeMaintenance={canEditAssetTypeMaintenance}
                canDeleteAssetTypeMaintenance={canDeleteAssetTypeMaintenance}
                setSelectedMaintenanceId={setSelectedMaintenanceId}
              />
            ))}
          </Box>
        ) : (
          <Box color="gray.700" mt="4">
            Default service schedule empty.
          </Box>
        )}
      </DrawerBody>
    </>
  );
};

export default AssetTypeMaintenanceShowPresenter;
