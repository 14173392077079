import { Icon, IconProps } from "@chakra-ui/react";

const WallHydrant = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M111 32v64a5 5 0 0 1-5 5H22a5 5 0 0 1-5-5V32a5 5 0 0 1 5-5h84a5 5 0 0 1 5 5Z"
      stroke="currentColor"
      strokeWidth={6}
      fillOpacity={0}
    />
    <circle
      cx={44}
      cy={64}
      r={11}
      stroke="currentColor"
      strokeWidth={5}
      fillOpacity={0}
    />
    <circle cx={44} cy={64} r={4} fill="currentColor" />
    <circle cx={82} cy={64} r={5} fill="currentColor" />
    <circle
      cx={82}
      cy={64}
      r={14}
      stroke="currentColor"
      strokeWidth={5}
      fillOpacity={0}
    />
    <path
      d="m29 49 7.059 7.059M51.941 71.941 59 79M29 79l7.059-7.059M51.941 56.059 59 49"
      stroke="currentColor"
      strokeWidth={5}
    />
  </Icon>
);

export default WallHydrant;
