import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import {
  PASSWORD_REGEX_8_CHARS,
  PASSWORD_REGEX_ALPHABET,
  PASSWORD_REGEX_NUMBER,
} from "../../../constants/regex";

interface PasswordInputProps extends InputProps {
  name: string;
  showPasswordHint?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  showPasswordHint,
  ...props
}) => {
  const { values, submitCount, getFieldProps } = useFormikContext<any>();
  const value = get(values, name);

  const { isOpen: passwordType, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });
  const { isOpen: showHint, onOpen } = useDisclosure();

  const handleKeyUp = () => {
    if (!showPasswordHint) return;
    onOpen();
  };

  React.useEffect(() => {
    if (submitCount) onOpen();
  }, [submitCount, onOpen]);

  return (
    <Box className="input-wrapper">
      <InputGroup>
        <Input
          type={passwordType ? "password" : "text"}
          onKeyUp={handleKeyUp}
          {...getFieldProps(name)}
          {...props}
        />
        <InputRightElement>
          <IconButton
            variant="icon"
            colorScheme="secondary"
            color="gray.800"
            size="sm"
            onClick={onToggle}
            type="button"
            aria-label={passwordType ? "Show Password" : "Hide Password"}
          >
            <FontAwesomeIcon icon={passwordType ? faEyeSlash : faEye} />
          </IconButton>
        </InputRightElement>
      </InputGroup>
      {showPasswordHint && showHint && (
        <List
          display="flex"
          flexWrap="wrap"
          marginTop="1"
          marginLeft="0"
          fontSize="xs"
          color="gray.300"
          fontWeight="300"
        >
          <Hint text="1 letter" regex={PASSWORD_REGEX_ALPHABET} value={value} />
          <Hint text="1 number" regex={PASSWORD_REGEX_NUMBER} value={value} />
          <Hint
            text="8 characters"
            regex={PASSWORD_REGEX_8_CHARS}
            value={value}
          />
        </List>
      )}
    </Box>
  );
};

export default PasswordInput;

interface HintProps {
  value?: string;
  text: string;
  regex: RegExp;
}

const Hint: React.FC<HintProps> = ({ text, value, regex }) => {
  const valid = value && regex.test(value);

  return (
    <ListItem paddingRight="4" color={valid ? "green.500" : "red.500"}>
      <Box as="span" width="4" display="inline-block">
        <FontAwesomeIcon icon={valid ? faCheckCircle : faTimesCircle} />
      </Box>
      {text}
    </ListItem>
  );
};
