import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import FileIcon from "../../../components/elements/fileIcon";
import { FileFragmentFragment } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import FileDelete from "../fileDelete";

interface FilePresenterProps {
  file: FileFragmentFragment;
  fileSignedUrl?: string;
  canEditFile: boolean;
  canDeleteFile: boolean;
}

const FilePresenter: React.FC<FilePresenterProps> = ({
  file,
  fileSignedUrl,
  canEditFile,
  canDeleteFile,
}) => {
  const navigate = useNavigate();
  const { fileId, folderId } = useParams();
  const [, setSearchParams] = useSearchParams();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isOpenFileDelete,
    onClose: onCloseFileDelete,
    onOpen: onOpenFileDelete,
  } = useDisclosure();

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("foldersShow", { folderId: file.folderId }));
    }, 500);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>{file.name}</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{file.name}</DrawerHeader>
          <DrawerBody>
            <>
              <Flex marginBottom="6" align="center" justify="center">
                {canEditFile && (
                  <IconButton
                    onClick={() =>
                      navigate(getRoutePath("filesEdit", { folderId, fileId }))
                    }
                    aria-label="Edit File"
                    variant="icon"
                    colorScheme="gray"
                    marginX="4"
                  >
                    <FontAwesomeIcon icon={faPen} size="sm" />
                  </IconButton>
                )}
                {canDeleteFile && (
                  <IconButton
                    onClick={onOpenFileDelete}
                    aria-label="Delete File"
                    variant="icon"
                    colorScheme="grayRed"
                    marginX="4"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </IconButton>
                )}
                <IconButton
                  onClick={() =>
                    setSearchParams({
                      CaAprType: "fileDownload",
                      fileId: file.id,
                    })
                  }
                  aria-label="View File"
                  variant="icon"
                  colorScheme="gray"
                  marginX="4"
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </IconButton>
              </Flex>
              <Box marginBottom="6" textAlign="center">
                <FileIcon
                  width={400}
                  signedUrl={fileSignedUrl}
                  filePath={file.path}
                  fileName={file.name}
                />
              </Box>
              {!!file.description && (
                <Text marginBottom="4" fontSize="sm" color="gray.800">
                  {file.description}
                </Text>
              )}
            </>

            {isOpenFileDelete && (
              <FileDelete
                id={file.id}
                isOpen={isOpenFileDelete}
                onOpen={onOpenFileDelete}
                onClose={onCloseFileDelete}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default FilePresenter;
