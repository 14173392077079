import React, { useCallback } from "react";

import FilterSelect from "../../../components/elements/filterSelect";
import { MaintenanceStatusType } from "../../../graphql/graphql";
import { maintenanceStatusTypeOptions } from "../../assets/presenter";

interface StatusFilterProps {
  onChange: (ids: MaintenanceStatusType[] | undefined) => void;
  value: MaintenanceStatusType[] | undefined;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ onChange, value }) => {
  const handleChange = useCallback(
    (id: any) => {
      if (!value) {
        onChange([id]);
      } else if (value.includes(id)) {
        onChange(
          value.length === 1 ? undefined : value.filter((v) => v !== id)
        );
      } else {
        onChange([...value, id]);
      }
    },
    [value, onChange]
  );

  return (
    <FilterSelect
      label="Status"
      selectedOptions={value}
      allOptions={maintenanceStatusTypeOptions}
      handleFilterChange={handleChange}
    />
  );
};

export default StatusFilter;
