import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";

import { TEAM_USER_LEFT } from "../../../constants/lang/en";
import {
  CompanyUserWithCompanyFragmentFragment,
  UserWithCompaniesDocument,
  useCompanyQuery,
  useCompanyUserDeleteMutation,
  useUpdateCurrentCompanyMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import { setGenericMessage } from "../../../utils/serverErrors";

interface MyCompanyDeleteProps {
  companyUsers: CompanyUserWithCompanyFragmentFragment[];
  companyUser: CompanyUserWithCompanyFragmentFragment;
}

const MyCompanyDelete: React.FC<MyCompanyDeleteProps> = ({
  companyUsers,
  companyUser,
}) => {
  const client = useApolloClient();
  const toast = useToast();
  const navigate = useNavigate();
  const [companyUserDeleteMutation, { loading }] =
    useCompanyUserDeleteMutation();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: currentCompanyData } = useCompanyQuery();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const handleDelete = async () => {
    try {
      const { errors } = await companyUserDeleteMutation({
        variables: { id: companyUser.id },
        refetchQueries: [{ query: UserWithCompaniesDocument }],
        awaitRefetchQueries: true,
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          description: TEAM_USER_LEFT,
          status: "success",
          position: "top",
          isClosable: true,
        });
        if (companyUser.company.id === currentCompanyData?.company.id) {
          const filteredCompanyUser = companyUsers.find(
            (cu) => cu.company.id !== companyUser.company.id
          );
          if (!filteredCompanyUser) {
            await updateCurrentCompany();
            navigate(getRoutePath("companyCreate"));
          } else {
            await updateCurrentCompany({
              variables: { id: filteredCompanyUser.company.id },
            });
          }
          await client.resetStore();
        } else {
          client.cache.evict({
            id: `Company:${companyUser.company.id}`,
          });
        }
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Tooltip
        label="This will permanently remove your access to this company, select this only if you are no longer working with this company."
        hasArrow
        placement="left"
      >
        <Button
          onClick={onOpen}
          variant="link"
          colorScheme="gray"
          marginTop="1"
          fontSize="14px"
        >
          Leave this company
        </Button>
      </Tooltip>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              <Text>
                This action will permanently remove you from{" "}
                <Text as="strong">{companyUser.company.name}</Text> and you will
                lose all access to this account.
              </Text>
              <Text>This action is irreversible. Are you sure?</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} width="48%">
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                colorScheme="red"
                ml="4%"
                width="48%"
                isLoading={loading}
              >
                Yes, Remove me
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default MyCompanyDelete;
