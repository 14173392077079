import { Icon, IconProps } from "@chakra-ui/react";

const FireSafety = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M103.5 76.51c0 18.73-13.212 34.377-30.827 38.132 6.199-2.877 11.3-8.991 11.3-17.604 0-3.215-.455-5.615-2.58-9.429-.555-.996-1.338-2.113-2.207-3.355l-.012-.017c-3.014-4.306-7.235-10.346-7.976-19.278l-.056-.677-.63.255.188.463-.188-.463h-.001l-.003.001-.008.003-.028.012-.106.046c-.092.04-.225.099-.394.178-.338.16-.82.399-1.405.723a28.667 28.667 0 0 0-4.385 3.005c-3.796 3.166-7.975 8.386-8.203 16.101l-7.123-2.082-.422-.124-.176.402c-2.402 5.473-3.273 10.21-3.225 14.235v.005c.003 7.729 4.513 14.406 11.044 17.546C38.588 110.738 25.5 95.151 25.5 76.511c0-7.09 1.891-13.739 5.202-19.465.141.248.316.543.524.87.62.973 1.543 2.24 2.75 3.4 2.413 2.323 6.033 4.27 10.607 2.435 1.774-.711 3.708-2.078 4.715-4.14 1.022-2.093 1.041-4.805-.849-8.077-3.8-6.575-1.78-15.841 6.586-19.391 5.026-2.133 7.508-6.721 8.735-10.692a28.823 28.823 0 0 0 1.193-6.53c1.337.951 4.145 3.137 6.48 6.189 2.985 3.899 5.137 9.12 2.617 14.983l.417.18-.417-.18c-1.587 3.693-2.492 7.81-2.152 11.282.341 3.49 1.96 6.412 5.475 7.466 4.185 1.255 7.354-1.05 9.407-3.52 1.031-1.242 1.808-2.553 2.326-3.55.218-.42.391-.787.52-1.07 8.478 7.151 13.864 17.85 13.864 29.81Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
);

export default FireSafety;
