import { Box, Flex } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";

import { useFloorPlanSimpleQuery } from "../../../graphql/graphql";
import MultiSelect, { MultiSelectItem } from "../multiSelect";

interface FloorPlanSelectFieldProps {
  name: string;
  label?: string;
  loading?: boolean;
  excludedFloorPlanIds?: string[];
  autoFocus?: boolean;
}

const FloorPlanSelectField: React.FC<FloorPlanSelectFieldProps> = ({
  name,
  label = "Select Plan(s)",
  loading,
  excludedFloorPlanIds,
  autoFocus = false,
}) => {
  const { data } = useFloorPlanSimpleQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const floorPlans = React.useMemo(() => {
    if (!data) return [];
    const floorPlans: MultiSelectItem[] = [];

    const plansLength = data.floorPlans.length;
    for (let j = 0; j < plansLength; j++) {
      const floorPlan = data.floorPlans[j];

      if (!excludedFloorPlanIds?.includes(floorPlan.id)) {
        const label = floorPlan.name;
        floorPlans.push({
          value: floorPlan.id,
          label: (
            <Flex alignItems="center">
              <Box marginLeft="1">{label}</Box>
            </Flex>
          ),
          query: label,
        });
      }
    }

    return floorPlans;
  }, [data, excludedFloorPlanIds]);

  return (
    <MultiSelect
      sortOptions
      items={floorPlans}
      autoComplete="off"
      name={name}
      label={label}
      loading={loading}
      autoFocus={autoFocus}
    />
  );
};

export default FloorPlanSelectField;

gql`
  query FloorPlanSimple {
    floorPlans {
      id
      name
    }
  }
`;
