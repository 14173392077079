import { useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ASSET_MAINTENANCE_SNOOZE_REMINDER_MESSAGE } from "../../../constants/lang/en";
import {
  useMaintenanceQuery,
  useMaintenanceScheduleSnoozeReminderMutation,
} from "../../../graphql/graphql";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import Presenter, { MaintenanceFormData } from "./presenter";

interface MaintenanceScheduleSnoozeReminderProps {}

const MaintenanceScheduleSnoozeReminder: React.FC<
  MaintenanceScheduleSnoozeReminderProps
> = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const maintenanceId = searchParams.get("maintenanceId");
  const toast = useToast();
  const [maintenanceScheduleSnoozeReminderMutation, { loading }] =
    useMaintenanceScheduleSnoozeReminderMutation();
  const { data } = useMaintenanceQuery({
    variables: { id: maintenanceId },
  });
  const maintenance = data?.maintenance;
  const maintenanceSchedule =
    maintenance && searchParams.has("maintenanceScheduleId")
      ? maintenance.maintenanceSchedulesForDateRange.find(
          (ms) => ms.id === searchParams.get("maintenanceScheduleId")
        )
      : undefined;

  const onDrawerClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = useCallback(
    async (
      data: MaintenanceFormData,
      { setFieldError }: FormikHelpers<MaintenanceFormData>
    ) => {
      if (!searchParams.has("maintenanceScheduleId")) return;
      try {
        const { data: serverData, errors } =
          await maintenanceScheduleSnoozeReminderMutation({
            variables: {
              id: searchParams.get("maintenanceScheduleId"),
              data,
            },
          });
        if (errors) {
          setServerErrors(errors, setFieldError);
        } else if (serverData) {
          onDrawerClose();
          toast({
            description: ASSET_MAINTENANCE_SNOOZE_REMINDER_MESSAGE,
            status: "success",
            position: "top",
            isClosable: true,
          });
          return;
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [
      toast,
      maintenanceScheduleSnoozeReminderMutation,
      searchParams,
      onDrawerClose,
    ]
  );

  return !!maintenance && !!maintenanceSchedule ? (
    <Presenter
      handleSnoozeSubmit={onSubmit}
      loading={loading}
      onDrawerClose={onDrawerClose}
      maintenanceName={maintenance.misc.resolvedName}
      maintenanceLocationLabel={maintenance.asset?.locationsLabel || ""}
      maintenanceSchedule={maintenanceSchedule}
      maintenanceContactIds={maintenance.maintenanceCompanyUsers.map(
        (mcu) => mcu.companyUser.user.id
      )}
    />
  ) : null;
};

export default MaintenanceScheduleSnoozeReminder;

gql`
  mutation MaintenanceScheduleSnoozeReminder(
    $id: UUID!
    $data: MaintenanceScheduleSnoozeReminderInput!
  ) {
    maintenanceScheduleSnoozeReminder(id: $id, data: $data) {
      ...MaintenanceScheduleFragment
    }
  }
`;
