import { Box, Button, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import SelectCombobox from "../../../components/elements/selectCombobox";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import { INDUSTRIES } from "../../../constants/options";
import {
  CompanyFragmentFragment,
  useCompanyUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { companyIndustrySchema, yupObject } from "../../../utils/validation";

interface CompanyIndustryChangePagePresenterProps {
  currentCompany: CompanyFragmentFragment;
}

const CompanyIndustryChangePagePresenter: React.FC<
  CompanyIndustryChangePagePresenterProps
> = ({ currentCompany }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [companyUpdateMutation, { loading }] = useCompanyUpdateMutation();

  const onSubmit = async (
    data: CompanyFormData,
    { setFieldError }: FormikHelpers<CompanyFormData>
  ) => {
    try {
      const { data: serverData, errors } = await companyUpdateMutation({
        variables: { data: { ...data, name: currentCompany.name } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        navigate(getRoutePath("companySettings"));
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        industry: currentCompany.industry || "",
      }}
      validationSchema={CompanyFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate>
          <SelectCombobox
            options={INDUSTRIES}
            name="industry"
            label="Select Your Industry"
            autoFocus
          />
          <Box marginY="10">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyIndustryChangePagePresenter;

const CompanyFormValidationSchema = yupObject().shape({
  industry: companyIndustrySchema,
});

type CompanyFormData = {
  industry: string;
};
