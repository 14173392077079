import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { faChevronLeft, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, memo } from "react";
import isEqual from "react-fast-compare";
import { FixedSizeList } from "react-window";

import FolderIcon from "../../../components/icons/folder";
import {
  FileFragmentFragment,
  FolderFragmentFragment,
  FolderWithRelationsFragmentFragment,
  SortBy,
  SortOrder,
  useCompanyQuery,
} from "../../../graphql/graphql";
import useFolder from "../../../hooks/useFolder";
import orderBy from "../../../utils/sort";

interface SelectFolderProps {
  onSuccess: React.Dispatch<
    React.SetStateAction<FolderFragmentFragment | undefined>
  >;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
}

const gridItemHeight = 41;

const SelectFolder: React.FC<SelectFolderProps> = ({
  onSuccess,
  selectedItems,
}) => {
  const { data: currentCompanyData } = useCompanyQuery();
  const [folderId, setFolderId] = React.useState(
    currentCompanyData?.company.defaultFolderId || ""
  );
  const { folder } = useFolder(folderId, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const subFolders = React.useMemo(() => {
    if (!folder) return [];
    return orderBy(folder.children, SortBy.Name, SortOrder.Asc);
  }, [folder]);

  return folder ? (
    <>
      <Heading
        as="h4"
        size="base"
        isTruncated
        textAlign="center"
        paddingBottom="2"
        marginBottom="2"
        borderBottomColor="gray.100"
        borderBottomWidth="1px"
      >
        Select Folder
      </Heading>
      {!!folder.parentId && (
        <Flex
          alignItems="center"
          isTruncated
          paddingBottom="2"
          marginBottom="2"
          borderBottomColor="gray.100"
          borderBottomWidth="1px"
        >
          <IconButton
            variant="icon"
            aria-label="Back to Parent folder"
            colorScheme="secondary"
            size="xxs"
            onClick={() => setFolderId(folder.parentId || "")}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
          <Heading marginLeft={2} as="h6" size="sm" isTruncated>
            {folder.name}
          </Heading>
        </Flex>
      )}
      {subFolders.length > 0 ? (
        <FixedSizeList
          height={300}
          width={352}
          itemSize={gridItemHeight}
          itemCount={subFolders.length}
          itemData={{ subFolders, selectedItems, onSuccess, setFolderId }}
          itemKey={(i, data) => data.subFolders[i].id}
          overscanCount={8}
        >
          {Row}
        </FixedSizeList>
      ) : (
        <Center height={300} width={352} color="gray.700">
          Folder empty
        </Center>
      )}
    </>
  ) : null;
};

export default SelectFolder;

interface Data {
  subFolders: FolderWithRelationsFragmentFragment[];
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  setFolderId: (id: string) => void;
  onSuccess: (item: FolderWithRelationsFragmentFragment) => void;
}

const Row: FC<{
  index: number;
  style: CSSProperties;
  data: Data;
}> = memo(
  ({
    index,
    style,
    data: { subFolders, selectedItems, onSuccess, setFolderId },
  }) => {
    const item = subFolders[index];
    return (
      <Box style={style} role="group" _hover={{ bgColor: "gray.100" }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Button
            onClick={() => setFolderId(item.id)}
            variant="link"
            size="sm"
            width="100%"
            alignItems="center"
            justifyContent="start"
            paddingY=".5rem !important"
            borderBottom="1px solid"
            borderColor="gray.100"
            height="auto"
            opacity={selectedItems.includes(item) ? "0.4" : "1"}
            cursor={selectedItems.includes(item) ? "not-allowed" : "pointer"}
          >
            <FolderIcon width="20px" />
            <Box
              marginLeft="2"
              color="gray.800"
              flexGrow={1}
              textAlign="left"
              isTruncated
            >
              {item.name}
            </Box>
          </Button>
          {selectedItems.includes(item) ? (
            <Tooltip
              label="The destination and selected folder can not be the same."
              hasArrow
            >
              <Box color="gray.300" marginRight="2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Box>
            </Tooltip>
          ) : (
            <Button
              variant="outline"
              onClick={() => onSuccess(item)}
              display="none"
              _groupHover={{ display: "block" }}
              height="fit-content"
              colorScheme="secondary"
              marginX="2"
              width="90px"
              padding="1"
            >
              Move
            </Button>
          )}
        </Flex>
      </Box>
    );
  },
  isEqual
);
