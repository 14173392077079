import { Icon, IconProps } from "@chakra-ui/react";

const SecurityCamera = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M109.531 70.065a2.931 2.931 0 0 0-4.002 1.062l-7.544-4.374.656-1.142c2.144-3.73.867-8.514-2.846-10.667L40.771 23.042A7.733 7.733 0 0 0 36.897 22a7.801 7.801 0 0 0-6.748 3.9L18.04 46.962c-2.143 3.729-.867 8.513 2.846 10.666l26.044 15.1v16.31H34.77c-1.353-6.313-6.953-11.061-13.64-11.061a2.944 2.944 0 0 0-2.937 2.95v22.123a2.944 2.944 0 0 0 2.938 2.95c6.686 0 12.286-4.748 13.639-11.062h15.097a2.944 2.944 0 0 0 2.938-2.95V76.134L75.91 89.53a7.734 7.734 0 0 0 3.874 1.042 7.801 7.801 0 0 0 6.747-3.9l.394-.686 7.637 4.428a2.954 2.954 0 0 0 1.37 3.301c.463.269.968.396 1.466.396 1.015 0 2.003-.53 2.547-1.475l1.08-1.88h.001v-.001l9.579-16.662a2.957 2.957 0 0 0-1.075-4.03Zm-85.463 29.48V84.433a8.125 8.125 0 0 1 0 15.114Zm55.717-14.872c-.333 0-.648-.084-.937-.252L23.824 52.52c-.892-.517-1.211-1.711-.696-2.607L35.237 28.85c.337-.587.973-.95 1.66-.95.333 0 .648.084.936.252l55.025 31.902c.892.517 1.21 1.711.696 2.607l-2.124 3.694-.002.003-.002.003-8.117 14.12-.002.002-.001.003-1.861 3.237c-.338.586-.974.95-1.66.95Zm15.262-12.81 7.544 4.373-5.183 9.016-7.544-4.374 5.183-9.016ZM53.08 46.658a2.934 2.934 0 0 1-4.012 1.08l-8.624-5a2.956 2.956 0 0 1-1.076-4.03 2.93 2.93 0 0 1 4.013-1.08l8.624 5a2.957 2.957 0 0 1 1.075 4.03Z"
      fill="currentColor"
    />
  </Icon>
);

export default SecurityCamera;
