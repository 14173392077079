import { Box, useMediaQuery } from "@chakra-ui/react";
import {
  CellClassParams,
  GridReadyEvent,
  ICellRendererParams,
  RowSpanParams,
} from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ColumnProps } from "../../../components/elements/spreadsheet";
import useBoxSize from "../../../hooks/useBoxSize";
import colors from "../../../theme/foundations/colors";

interface ReportsTableProps {
  columns: ColumnProps[];
  data: any[];
  isPrintReady: boolean;
}

const ReportsTable: React.FC<ReportsTableProps> = ({
  data,
  columns,
  isPrintReady,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { containerRef, height } = useBoxSize();
  const [gridController, setGridController] = useState<GridReadyEvent>();
  const getColumnDefs = useCallback(
    (column: ColumnProps) => ({
      field: column.field,
      headerName: column.headerName || column.field,
      minWidth: column.styleProps?.minWidth || 70,
      maxWidth: column.styleProps?.maxWidth,

      rowSpan: (params: RowSpanParams) => params.data.rowSpan,
      cellClassRules: {
        "ag-spanned-row": (params: CellClassParams) =>
          params.data.rowSpan > 1 && params.colDef.field !== "assets",
      },

      cellEditorParams: {
        cellType: column.cellType,
      },

      cellStyle: (cellRendererSelectorParams: ICellRendererParams) => {
        let backgroundColor = column.styleProps?.bgColor ?? "transparent";
        let color: string = "var(--ag-data-color)";
        if (cellRendererSelectorParams?.colDef?.headerName === "Status") {
          color = "white";
          if (cellRendererSelectorParams.data.Status === "Past Due") {
            backgroundColor = colors.red[500];
          } else if (cellRendererSelectorParams.data.Status === "Due In 30") {
            backgroundColor = colors.orange[500];
          } else {
            backgroundColor = colors.green[500];
          }
        }
        return {
          display: "flex",
          alignItems: "center",
          whiteSpace: "pre-line",
          backgroundColor,
          color,
        };
      },
      suppressMovable: isMobile,
      editable: false,
      sortable: true,
      resizable: !isMobile,
    }),
    [isMobile]
  );

  const Columns = useMemo(() => {
    return columns.map((column, index: number) => {
      return <AgGridColumn {...getColumnDefs(column)} key={index} />;
    });
  }, [columns, getColumnDefs]);

  useEffect(() => {
    setTimeout(() => {
      const viewport = document.querySelector(
        ".ag-header-viewport"
      ) as HTMLElement;
      const container = document.querySelector(
        ".ag-header-container"
      ) as HTMLElement;
      if (
        viewport &&
        container &&
        container.offsetWidth < viewport.offsetWidth
      ) {
        gridController?.api.sizeColumnsToFit();
      }
    });
  }, [gridController?.api]);

  useEffect(() => {
    if (isPrintReady) {
      gridController?.api.setDomLayout("print");
    } else {
      gridController?.api.setDomLayout("normal");
    }
  }, [gridController, isPrintReady]);

  return (
    <Box ref={containerRef}>
      <AgGridReact
        tooltipShowDelay={0}
        className="ag-theme-alpine"
        rowData={data}
        suppressRowTransform
        onGridReady={(gridData: GridReadyEvent) => {
          setGridController(gridData);
        }}
        defaultColDef={{
          resizable: true,
          wrapText: true,
          autoHeight: true,
        }}
        containerStyle={{ height: height + 30 }}
      >
        {Columns}
      </AgGridReact>
    </Box>
  );
};

export default ReportsTable;
