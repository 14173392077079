import { useEffect, useState } from "react";

import {
  LocationWithRelationsFragmentFragment,
  useLocationIndexQuery,
  useLocationLazyQuery,
} from "../graphql/graphql";

const useTopLocation = (locationId: string) => {
  const { data } = useLocationIndexQuery();
  const [getLocation] = useLocationLazyQuery();
  const [topLocation, setTopLocation] =
    useState<LocationWithRelationsFragmentFragment | null>(null);

  useEffect(() => {
    if (!data) return;

    const fetchTopLocation = async () => {
      const findLocation = (id: string) =>
        data.locations.find((location) => location.id === id);

      let id = locationId;
      while (true) {
        const location = findLocation(id);
        if (!location) break;
        if (!location.parentId) {
          const { data } = await getLocation({
            variables: { id: location.id },
          });
          if (data) setTopLocation(data.location);
          break;
        }
        id = location.parentId;
      }
    };
    fetchTopLocation();
  }, [data, getLocation, locationId]);

  return topLocation;
};

export default useTopLocation;
