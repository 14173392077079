import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { ReactNode } from "react";

interface RadioButtonInputProps extends Omit<RadioGroupProps, "children"> {
  name: string;
  label: ReactNode;
  options: RadioButtonOption[];
  renderError?: boolean;
  styleProps?: object;
  type?: "row" | "column";
}

interface RadioButtonOption {
  label: ReactNode;
  value: string;
}

const RadioButtonInput: React.FC<RadioButtonInputProps> = ({
  name,
  label,
  options,
  renderError = true,
  styleProps,
  type = "column",
  ...props
}) => {
  const { errors, values, touched, submitCount, getFieldProps, setFieldValue } =
    useFormikContext<any>();
  const error = get(errors, name);
  const value = get(values, name);
  const fieldTouched = get(touched, name);

  return (
    <FormControl
      marginTop="3"
      position="relative"
      isInvalid={!!error && (!!submitCount || (fieldTouched && !!value))}
      aria-invalid={!!error && (!!submitCount || (fieldTouched && !!value))}
      {...styleProps}
    >
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        {...getFieldProps(name)}
        onChange={(value) => setFieldValue(name, value)}
        {...props}
      >
        <Stack direction={type} spacing={type === "column" ? 2 : 4}>
          {options.map((option) => (
            <Radio
              value={option.value}
              cursor="pointer"
              key={option.value}
              alignItems="flex-start"
              lineHeight="1"
            >
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {renderError && (
        <FormErrorMessage>
          <Text as="span" marginRight="1">
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Text>
          <Text role="alert">{error}</Text>
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default RadioButtonInput;
