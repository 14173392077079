import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Team = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 24 24" boxSize="24px" {...props}>
    <path d="M4 11.313a2 2 0 100-4 2 2 0 000 4zM6.67 12.713a4.192 4.192 0 00-1.669 3.349v.25H.75c-.41 0-.75-.34-.75-.75v-.499c0-1.52 1.23-2.75 2.75-2.75h2.5c.52 0 1.01.15 1.42.4zM20 11.313a2 2 0 100-4 2 2 0 000 4zM24 15.063v.5c0 .409-.34.75-.75.75H19v-.251c0-1.37-.66-2.591-1.67-3.35.41-.25.9-.4 1.421-.4h2.5A2.75 2.75 0 0124 15.062zM12 12a3 3 0 100-6 3 3 0 000 6zM14.75 13.5h-5.5a2.752 2.752 0 00-2.75 2.75v1.5c0 .414.336.75.75.75h9.5a.75.75 0 00.75-.75v-1.5a2.752 2.752 0 00-2.75-2.75z" />
  </Icon>
);

export default Team;
