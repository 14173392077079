import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Admin = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    color="#2EC14E"
    viewBox="0 0 24 24"
    boxSize="24"
    {...props}
  >
    <path d="M11 2C9.93913 2 8.92172 2.42143 8.17157 3.17157C7.42143 3.92172 7 4.93913 7 6C7 7.06087 7.42143 8.07828 8.17157 8.82843C8.92172 9.57857 9.93913 10 11 10C12.0609 10 13.0783 9.57857 13.8284 8.82843C14.5786 8.07828 15 7.06087 15 6C15 4.93913 14.5786 3.92172 13.8284 3.17157C13.0783 2.42143 12.0609 2 11 2ZM11 13C7.859 13 2 14.545 2 17.5V19C2 19.552 2.448 20 3 20H11.2949C11.1059 19.366 11 18.695 11 18C11 16.119 11.7451 14.4132 12.9531 13.1562C12.2451 13.0522 11.577 13 11 13ZM17.0645 13C16.9355 13 16.8275 13.0966 16.8125 13.2246L16.6953 14.2363C16.2113 14.4043 15.7745 14.6593 15.3945 14.9883L14.457 14.582C14.339 14.531 14.2027 14.5775 14.1387 14.6895L13.2012 16.3105C13.1372 16.4225 13.1665 16.5646 13.2695 16.6406L14.0762 17.2383C14.0292 17.4863 14 17.74 14 18C14 18.26 14.0282 18.5128 14.0762 18.7598L13.2715 19.3594C13.1685 19.4364 13.1381 19.5785 13.2031 19.6895L14.1387 21.3105C14.2027 21.4225 14.341 21.467 14.459 21.416L15.3945 21.0117C15.7735 21.3397 16.2113 21.5957 16.6953 21.7637L16.8125 22.7754C16.8265 22.9044 16.9355 23 17.0645 23H18.9355C19.0645 23 19.1725 22.9034 19.1875 22.7754L19.3047 21.7637C19.7887 21.5957 20.2255 21.3407 20.6055 21.0117L21.543 21.418C21.661 21.469 21.7973 21.4215 21.8613 21.3105L22.7988 19.6895C22.8628 19.5775 22.8335 19.4354 22.7305 19.3594L21.9238 18.7598C21.9718 18.5128 22 18.26 22 18C22 17.74 21.9718 17.4872 21.9238 17.2402L22.7285 16.6406C22.8315 16.5636 22.8619 16.4215 22.7969 16.3105L21.8613 14.6895C21.7973 14.5775 21.659 14.533 21.541 14.584L20.6055 14.9883C20.2265 14.6603 19.7887 14.4043 19.3047 14.2363L19.1875 13.2246C19.1735 13.0956 19.0645 13 18.9355 13H17.0645ZM18 16.25C18.966 16.25 19.75 17.033 19.75 18C19.75 18.966 18.966 19.75 18 19.75C17.034 19.75 16.25 18.966 16.25 18C16.25 17.033 17.034 16.25 18 16.25Z" />
  </Icon>
);

export default Admin;
