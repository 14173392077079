import {
  Box,
  Center,
  Heading,
  IconButton,
  IconButtonProps,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pluralize from "pluralize";
import QRCode from "qrcode.react";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { useCurrentCompanyQuery } from "../../../graphql/graphql";
import Spinner from "../spinner";

interface PrintLink {
  path: string;
  name?: string;
}

interface QrCodesProps extends Partial<IconButtonProps> {
  printDocumentTitle?: string;
  links: PrintLink[];
}

const QrCodes: React.FC<QrCodesProps> = ({
  links,
  printDocumentTitle: documentTitle,
  ...otherProps
}) => {
  const { data } = useCurrentCompanyQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const printRef = useRef(null);
  const label = `Print ${pluralize("QR Code", links.length)}`;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle,
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        onOpen();
        const timer = links.length * 200;
        setTimeout(resolve, timer < 1000 ? 1000 : timer > 5000 ? 5000 : timer);
      }),
    onAfterPrint: onClose,
    onPrintError: onClose,
  });

  return (
    <>
      <Tooltip label={label} hasArrow>
        <IconButton
          variant="icon"
          aria-label={label}
          colorScheme="gray"
          {...otherProps}
          onClick={handlePrint}
        >
          {isOpen ? (
            <Box>
              <Spinner size="sm" />
            </Box>
          ) : (
            <FontAwesomeIcon icon={faQrcode} />
          )}
        </IconButton>
      </Tooltip>
      {isOpen && (
        <Box display="none">
          <Box ref={printRef}>
            {links.map((link) => (
              <Center
                flexDirection="column"
                width="full"
                height="full"
                key={link.path}
                className="print-no-break"
                py="4"
              >
                <QRCode
                  value={`${process.env.REACT_APP_WEB_APP_URL}${link.path}${
                    link.path.includes("?") ? "&" : "?"
                  }companyId=${data?.currentCompany.id}`}
                  size={256}
                  imageSettings={{
                    src: "/icon.png",
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
                {!!link.name && (
                  <Heading as="h2" mt="2" mx="4">
                    {link.name}
                  </Heading>
                )}
              </Center>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default QrCodes;
