import { useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import {
  useCompanyQuery,
  useFolderConfigQuery,
} from "../../../graphql/graphql";
import {
  currentCompanyRoleVar,
  layoutOptionsVar,
} from "../../../graphql/reactiveVariables";
import useFolder from "../../../hooks/useFolder";
import ROLES from "../../../roles";
import { getRoutePath } from "../../../router";
import FolderPresenter from "./presenter";

interface FolderPageProps {}

const FolderPage: React.FC<FolderPageProps> = ({ children }) => {
  const { data: companyData } = useCompanyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { folderId } = useParams();
  const { data: folderConfigData } = useFolderConfigQuery();
  const company = companyData?.company;
  const { folder } = useFolder(
    folderId!,
    folderId === company?.defaultFolderId
      ? {
          fetchPolicy: "cache-and-network",
          nextFetchPolicy: "cache-first",
        }
      : {}
  );

  useEffect(() => {
    layoutOptionsVar({
      title: folder?.parentId ? undefined : "Files",
      backLink:
        folder?.parentId && company?.defaultFolderId
          ? getRoutePath("foldersShow", {
              folderId: company.defaultFolderId,
            })
          : undefined,
      backText: folder?.parentId ? "Back to all folders" : "",
      styleProps: { paddingX: "0" },
    });
  }, [company?.defaultFolderId, folder?.parentId]);

  return (
    <>
      <Helmet>
        <title>
          {folder?.name !== "Company Folders" ? folder?.name : "Files"}
        </title>
      </Helmet>
      {folderConfigData && folder && company ? (
        <FolderPresenter
          folder={folder}
          currentCompany={company}
          folderConfigData={folderConfigData}
          canAddFolder={ROLES.foldersCreate.includes(currentCompanyRole.role)}
          canAddFile={ROLES.filesCreate.includes(currentCompanyRole.role)}
          canEditFile={ROLES.filesEdit.includes(currentCompanyRole.role)}
          canDeleteFile={ROLES.filesDelete.includes(currentCompanyRole.role)}
          canEditFolder={ROLES.foldersEdit.includes(currentCompanyRole.role)}
          canDeleteFolder={ROLES.foldersDelete.includes(
            currentCompanyRole.role
          )}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default FolderPage;
