import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  memo,
} from "react";
import isEqual from "react-fast-compare";
import { useParams } from "react-router-dom";
import { FixedSizeList } from "react-window";

import FileIcon from "../../../components/elements/fileIcon";
import Link from "../../../components/elements/link";
import TooltipName from "../../../components/elements/tooltipName";
import FolderIcon from "../../../components/icons/folder";
import {
  FileFragmentFragment,
  FolderFragmentFragment,
  useCompanyQuery,
} from "../../../graphql/graphql";
import useBoxSize from "../../../hooks/useBoxSize";
import { getRoutePath } from "../../../router";
import { mainLayoutPaddingX } from "../../../utils/layout";
import FileDelete from "../fileDelete";
import FolderDelete from "../folderDelete";

interface ListPresenterProps {
  folderFiles: Array<FolderFragmentFragment | FileFragmentFragment>;
  canEditFile: boolean;
  canDeleteFile: boolean;
  canEditFolder: boolean;
  canDeleteFolder: boolean;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  setSelectedItems: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
}

const ListPresenter: React.FC<ListPresenterProps> = ({
  folderFiles,
  canEditFile,
  canDeleteFile,
  canEditFolder,
  canDeleteFolder,
  selectedItems,
  setSelectedItems,
}) => {
  const { containerRef, height } = useBoxSize();

  return (
    <Box ref={containerRef}>
      <FixedSizeList
        height={height}
        width="100%"
        itemSize={41}
        itemCount={folderFiles.length}
        itemData={{
          folderFiles,
          canDeleteFile,
          canDeleteFolder,
          canEditFile,
          canEditFolder,
          selectedItems,
          setSelectedItems,
        }}
        itemKey={(i, data) => data.folderFiles[i].id}
        overscanCount={8}
      >
        {RowItem}
      </FixedSizeList>
    </Box>
  );
};

interface Data {
  folderFiles: Array<FolderFragmentFragment | FileFragmentFragment>;
  canDeleteFile: boolean;
  canDeleteFolder: boolean;
  canEditFile: boolean;
  canEditFolder: boolean;
  setSelectedItems: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
}

const RowItem: FC<{
  index: number;
  style: CSSProperties;
  data: Data;
}> = memo(
  ({
    index,
    style,
    data: {
      folderFiles,
      canDeleteFile,
      canDeleteFolder,
      canEditFile,
      canEditFolder,
      selectedItems,
      setSelectedItems,
    },
  }) => {
    const item = folderFiles[index];
    return item ? (
      <Box style={style} paddingX={mainLayoutPaddingX}>
        {item.__typename === "File" ? (
          <FileIconName
            item={item}
            canEditFile={canEditFile}
            canDeleteFile={canDeleteFile}
            onSelect={setSelectedItems}
            selectedItems={selectedItems}
          />
        ) : (
          <FolderIconName
            item={item as FolderFragmentFragment}
            onSelect={setSelectedItems}
            selectedItems={selectedItems}
            canEditFolder={canEditFolder}
            canDeleteFolder={canDeleteFolder}
          />
        )}
      </Box>
    ) : null;
  },
  isEqual
);

const FileIconName = ({
  item,
  onSelect,
  canEditFile,
  canDeleteFile,
  selectedItems,
}: {
  item: FileFragmentFragment;
  onSelect: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  canEditFile: boolean;
  canDeleteFile: boolean;
}) => {
  const {
    isOpen: isOpenFileDelete,
    onOpen: onOpenFileDelete,
    onClose: onCloseFileDelete,
  } = useDisclosure();

  return (
    <Flex
      width="full"
      justifyContent="space-between"
      _hover={{ bgColor: "gray.100" }}
      role="group"
      alignItems="center"
      paddingY="2"
      borderBottom="1px solid"
      borderColor="gray.100"
    >
      {canDeleteFile && (
        <Box
          as="button"
          display="block"
          visibility={{
            base: "visible",
            md: selectedItems.includes(item) ? "visible" : "hidden",
          }}
          _groupHover={{ visibility: "visible" }}
          marginRight="4"
          marginLeft="2"
          onClick={() => {
            if (!selectedItems.includes(item)) {
              onSelect([...selectedItems, item]);
            } else {
              onSelect((prevSelectedItems) =>
                prevSelectedItems.filter(
                  (selectedItem) => selectedItem !== item
                )
              );
            }
          }}
          color={selectedItems.includes(item) ? "secondary.500" : "gray.500"}
          _hover={{ color: "secondary.500" }}
        >
          <FontAwesomeIcon
            size="lg"
            icon={selectedItems.includes(item) ? faCheckSquare : faSquare}
          />
        </Box>
      )}
      <Link
        to={getRoutePath("filesShow", {
          folderId: item.folderId,
          fileId: item.id,
        })}
        variant="link"
        size="sm"
        width="100%"
        justifyContent="start"
      >
        <FileIcon
          filePath={item.path}
          fileName={item.name}
          width={20}
          forceIcon
        />
        <TooltipName
          as="span"
          marginLeft="2"
          color="gray.800"
          flexGrow={1}
          isTruncated
          fontWeight="normal"
          maxWidth="calc(100% - 32px)"
          display="inline-block"
          name={item.name}
        />
      </Link>
      <Flex
        opacity="0"
        _groupHover={{ opacity: 1 }}
        _groupFocus={{ opacity: 1 }}
        alignItems="center"
      >
        {canEditFile && (
          <Tooltip label="Edit File" hasArrow placement="left">
            <Link
              to={getRoutePath("filesEdit", {
                folderId: item.folderId,
                fileId: item.id,
              })}
              aria-label="Edit file"
              variant="icon"
              colorScheme="gray"
              marginRight="4"
            >
              <FontAwesomeIcon icon={faPen} />
            </Link>
          </Tooltip>
        )}
        {canDeleteFile && (
          <Tooltip label="Delete file" hasArrow placement="bottom">
            <IconButton
              aria-label="Delete file"
              variant="icon"
              colorScheme="grayRed"
              marginRight="4"
              onClick={onOpenFileDelete}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        )}
      </Flex>

      {isOpenFileDelete && (
        <FileDelete
          id={item.id}
          isOpen={isOpenFileDelete}
          onOpen={onOpenFileDelete}
          onClose={onCloseFileDelete}
        />
      )}
    </Flex>
  );
};

const FolderIconName = ({
  item,
  onSelect,
  canEditFolder,
  canDeleteFolder,
  selectedItems,
}: {
  item: FolderFragmentFragment;
  canEditFolder: boolean;
  onSelect: Dispatch<
    SetStateAction<
      (FolderFragmentFragment | FileFragmentFragment | undefined)[]
    >
  >;
  selectedItems: Array<
    FolderFragmentFragment | FileFragmentFragment | undefined
  >;
  canDeleteFolder: boolean;
}) => {
  const { data: currentCompanyData } = useCompanyQuery();
  const { folderId } = useParams();
  const {
    isOpen: isOpenFolderDelete,
    onOpen: onOpenFolderDelete,
    onClose: onCloseFolderDelete,
  } = useDisclosure();

  return (
    <>
      <Flex
        width="full"
        justifyContent="space-between"
        _hover={{ bgColor: "gray.100" }}
        role="group"
        alignItems="center"
        paddingY="2"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        {canDeleteFolder &&
        currentCompanyData?.company?.uploadsFolderId !== item.id ? (
          <Box
            as="button"
            display="block"
            visibility={{
              base: "visible",
              md: selectedItems.includes(item) ? "visible" : "hidden",
            }}
            _groupHover={{ visibility: "visible" }}
            marginRight="4"
            marginLeft="2"
            onClick={() => {
              if (!selectedItems.includes(item)) {
                onSelect([...selectedItems, item]);
              } else {
                onSelect((prevSelectedItems) =>
                  prevSelectedItems.filter(
                    (selectedItem) => selectedItem !== item
                  )
                );
              }
            }}
            color={selectedItems.includes(item) ? "secondary.500" : "gray.500"}
            _hover={{ color: "secondary.500" }}
          >
            <FontAwesomeIcon
              size="lg"
              icon={selectedItems.includes(item) ? faCheckSquare : faSquare}
            />
          </Box>
        ) : (
          currentCompanyData?.company?.uploadsFolderId === item.id &&
          canDeleteFolder && <Box w="11" />
        )}

        <Link
          to={getRoutePath("foldersShow", { folderId: item.id })}
          variant="link"
          colorScheme="secondary"
          size="sm"
          justifyContent="start"
          width="100%"
          onClick={() => onSelect([])}
        >
          <FolderIcon width="20px" />
          <TooltipName
            as="span"
            marginLeft="2"
            color="gray.800"
            flexGrow={1}
            isTruncated
            fontWeight="normal"
            maxWidth="calc(100% - 32px)"
            display="inline-block"
            name={item.name}
          />
        </Link>

        <Flex
          opacity="0"
          _groupHover={{ opacity: 1 }}
          _groupFocus={{ opacity: 1 }}
          alignItems="center"
        >
          {canEditFolder && (
            <Tooltip label="Edit Folder" hasArrow placement="left">
              <Link
                to={`${getRoutePath("foldersEdit", {
                  folderId,
                })}?targetFolder=${item.id}`}
                aria-label="Edit folder"
                variant="icon"
                colorScheme="gray"
                marginRight="4"
              >
                <FontAwesomeIcon icon={faPen} />
              </Link>
            </Tooltip>
          )}
          {canDeleteFolder &&
            currentCompanyData?.company.uploadsFolderId !== item.id && (
              <IconButton
                aria-label="Delete folder"
                variant="icon"
                colorScheme="grayRed"
                marginRight="4"
                onClick={onOpenFolderDelete}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
            )}
        </Flex>
      </Flex>

      {isOpenFolderDelete && (
        <FolderDelete
          id={item.id}
          isOpen={isOpenFolderDelete}
          onOpen={onOpenFolderDelete}
          onClose={onCloseFolderDelete}
        />
      )}
    </>
  );
};

export default ListPresenter;
