import { format } from "date-fns";
import React from "react";

import { defaultDateWithTimeAndZoneFormat } from "../../../constants/date";
import { MaintenanceScheduleFragmentFragment } from "../../../graphql/graphql";

interface CompletedByProps {
  maintenanceSchedule: MaintenanceScheduleFragmentFragment;
}

const CompletedBy: React.FC<CompletedByProps> = ({ maintenanceSchedule }) => {
  return (
    <>
      {maintenanceSchedule.completedByLabel
        ? `Completed by ${maintenanceSchedule.completedByLabel} on`
        : "Completed on"}{" "}
      {format(
        maintenanceSchedule.completedAt,
        defaultDateWithTimeAndZoneFormat
      )}
    </>
  );
};

export default CompletedBy;
