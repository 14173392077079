import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import { isNativeWebViewVar } from "../../../graphql/reactiveVariables";

interface NativeLayoutProps {
  noPadding?: boolean;
}

const WrapperBox = styled(Box)`
  .wrapper::-webkit-scrollbar {
    display: none;
  }
  .wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const NativeLayout: React.FC<NativeLayoutProps> = ({ noPadding, children }) => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    isNativeWebViewVar(true);
    setOpen(true);

    return () => {
      isNativeWebViewVar(false);
    };
  }, []);

  return isOpen ? (
    <WrapperBox>
      <Box
        p={!noPadding ? "2" : "0"}
        height="screenFull"
        overflow="auto"
        backgroundColor="white"
        className="wrapper"
      >
        {children}
      </Box>
    </WrapperBox>
  ) : null;
};

export default NativeLayout;
