import { Icon, IconProps } from "@chakra-ui/react";

const VAVBox = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M107.453 18.438a2.11 2.11 0 0 0 0 4.218 2.11 2.11 0 0 0 0-4.218ZM107.453 105.344a2.11 2.11 0 0 0 0 4.218 2.11 2.11 0 0 0 0-4.218ZM20.547 22.656a2.11 2.11 0 1 0-.002-4.22 2.11 2.11 0 0 0 .002 4.22ZM20.547 105.344a2.11 2.11 0 0 0 0 4.218 2.11 2.11 0 0 0 0-4.218Z"
      fill="currentColor"
    />
    <path
      d="M107.453 10H20.547C14.73 10 10 14.731 10 20.547v86.906C10 113.269 14.731 118 20.547 118h86.906c5.816 0 10.547-4.731 10.547-10.547V20.547C118 14.73 113.269 10 107.453 10Zm6.328 97.453a6.334 6.334 0 0 1-6.328 6.328H20.547a6.335 6.335 0 0 1-6.328-6.328V20.547a6.335 6.335 0 0 1 6.328-6.328h86.906a6.335 6.335 0 0 1 6.328 6.328v86.906Z"
      fill="currentColor"
    />
    <path
      d="M87.203 47.125c0-10.468-8.517-18.984-18.984-18.984H64a2.11 2.11 0 0 0-2.11 2.109v17.59a18.979 18.979 0 0 0-14.765-7.043c-10.468 0-18.984 8.517-18.984 18.984V64c0 1.165.944 2.107 2.109 2.107h17.59a18.985 18.985 0 0 0-7.043 14.768c0 10.468 8.517 18.984 18.984 18.984H64a2.11 2.11 0 0 0 2.11-2.109V80.16a18.979 18.979 0 0 0 14.765 7.043c10.468 0 18.984-8.517 18.984-18.984V64a2.11 2.11 0 0 0-2.109-2.11H80.16a18.979 18.979 0 0 0 7.043-14.765ZM68.22 32.359c8.141 0 14.765 6.624 14.765 14.766a14.755 14.755 0 0 1-8.965 13.582 10.58 10.58 0 0 0-7.91-7.04V32.36h2.11ZM64.4 70.315a6.33 6.33 0 0 1-6.716-5.914A6.33 6.33 0 0 1 64 57.671c3.463 0 6.328 2.81 6.328 6.329 0 3.336-2.605 6.11-5.927 6.315ZM32.36 59.78c0-8.141 6.624-14.765 14.766-14.765a14.755 14.755 0 0 1 13.582 8.965 10.58 10.58 0 0 0-7.04 7.91H32.36v-2.11Zm27.422 35.86c-8.141 0-14.765-6.624-14.765-14.766a14.755 14.755 0 0 1 8.965-13.582 10.58 10.58 0 0 0 7.91 7.04V95.64h-2.11Zm35.86-27.422c0 8.141-6.624 14.765-14.766 14.765a14.755 14.755 0 0 1-13.582-8.965 10.58 10.58 0 0 0 7.04-7.91H95.64v2.11Z"
      fill="currentColor"
    />
    <circle
      cx={64}
      cy={64}
      r={43}
      stroke="currentColor"
      strokeWidth={4}
      fillOpacity={0}
    />
  </Icon>
);

export default VAVBox;
