import { gql } from "@apollo/client";

import { WorkOrderStageFragment } from "./workOrderStages";

export const WOCompanyFragment = gql`
  fragment CompanyFragment on WOCompany {
    id
    name
    email
    phone
    address1
    address2
    city
    state
    zip
    countryAlpha2
    coordinates
    businessType
    website
    industry
    locationCount
  }
`;

export const WOUserFragment = gql`
  fragment UserFragment on WOUser {
    id
    firstName
    lastName
    fullName
    email
    phone
    emailConfirmed
    phoneConfirmed
    twoFactorEnabled
    jobTitle
    adminRole
    registered
  }
`;
export const WOWorkOrdersFragment = gql`
  fragment WorkOrderFragment on WorkOrder {
    id
    createdAt
    name
    key
    description
    businessSeverity
    orderPriority
    scheduleType
    startDateTime
    endDateTime
    locationName
    serviceScheduleTimezone
    serviceCategory {
      id
      name
    }
  }
`;

export const WOAssetFragment = gql`
  fragment WOAssetFragment on Asset {
    id
    name
    assetType {
      id
      name
      assetCategory {
        name
      }
    }
    assetAffectedAssets {
      id
      affectedAsset {
        id
        name
      }
    }
    assetAffectedByAssets {
      id
      asset {
        id
        name
      }
    }
  }
`;

export const WOWorkOrderByIdFragment = gql`
  fragment WorkOrderByIdFragment on WorkOrder {
    id
    name
    key
    description
    companyId
    locationId
    requestedById
    companyContactEmail
    companyContactOccupation
    companyContactFullName
    companyContactType
    companyContactTeamName
    companyContactMobilePhone
    companyContactWorkPhone
    companyContactUserId
    locationContactEmail
    locationContactOccupation
    locationContactFullName
    locationContactType
    locationContactTeamName
    locationContactTeamId
    locationContactMobilePhone
    locationContactWorkPhone
    locationContactUserId
    serviceCategoryId
    businessSeverity
    orderPriority
    parentOrderId
    scheduleType
    startDateTime
    endDateTime
    orderType {
      id
      name
    }
    orderTypeId
    serviceScheduleTimezone
    accountableUserId
    consultingUserIds
    informingUserIds
    responsibleUserIds
    assetWorkOrders {
      id
      comment
      assetId
      asset {
        ...WOAssetFragment
      }
      serviceTypeId
      serviceType {
        id
        name
      }
      sublocationId
      plans {
        id
        name
        comment
        planId
        itemId
        type
        sublocation
        description
      }
    }
    company {
      id
      name
    }
    location {
      id
      name
    }
    files {
      id
      name
      createdAt
      updatedAt
      path
      extension
      fileSize
      signedUrl
    }
    workOrderStageId
    transitionReasonId
    transitionReason {
      id
      name
    }
    workOrderStage {
      ...WorkOrderStageFragment
    }
  }
  ${WOAssetFragment}
  ${WorkOrderStageFragment}
`;

export const WOLocationFragment = gql`
  fragment LocationFragment on WOLocation {
    id
    name
    description
    type
    createdAt
    updatedAt
    address1
    address2
    city
    state
    zip
    countryAlpha2
    coordinates
    parentId
    companyId
    parent {
      id
      name
      description
      type
    }
    children {
      id
      name
      description
      type
    }
  }
`;

export const WOFlorePlanFragment = gql`
  fragment FloorPlanFragment on FloorPlan {
    id
    name
    description
    path
    createdAt
    updatedAt
    type
    location {
      id
      name
    }
    signedUrl
    floorPlanAssets {
      id
      assetId
      mapAffectedArea
    }
  }
`;

export const WOCompanyDocument = gql`
  query workOrdersCompanies($assetId: UUID, $locationId: UUID, $userId: UUID) {
    workOrdersCompanies(
      assetId: $assetId
      locationId: $locationId
      userId: $userId
    ) {
      ...CompanyFragment
    }
  }
  ${WOCompanyFragment}
`;

export const WOLocationDocument = gql`
  query workOrderLocations(
    $assetId: UUID
    $locationId: UUID
    $companyId: UUID
  ) {
    workOrderLocations(
      assetId: $assetId
      locationId: $locationId
      companyId: $companyId
    ) {
      ...LocationFragment
    }
  }
  ${WOLocationFragment}
`;

export const WOFloorPlanDocument = gql`
  query workOrderFloorPlans(
    $assetId: UUID
    $locationId: UUID
    $companyId: UUID
    $floorPlanId: UUID
  ) {
    workOrderFloorPlans(
      assetId: $assetId
      locationId: $locationId
      companyId: $companyId
      floorPlanId: $floorPlanId
    ) {
      ...FloorPlanFragment
    }
  }
  ${WOFlorePlanFragment}
`;

export const WOUserDocument = gql`
  query getCompanyOwner($companyId: UUID!) {
    getCompanyOwner(companyId: $companyId) {
      ...UserFragment
    }
  }
  ${WOUserFragment}
`;

export const WOLocationUsersDocument = gql`
  query workOrderCompanyUsers($companyId: UUID!) {
    workOrderCompanyUsers(companyId: $companyId) {
      ...UserFragment
    }
  }
  ${WOUserFragment}
`;

export const WOWorkOrdersDocument = gql`
  query workOrders(
    $companyId: UUID!
    $take: Float
    $skip: Float
    $sort: String
    $order: String
  ) {
    workOrders(
      companyId: $companyId
      take: $take
      skip: $skip
      sort: $sort
      order: $order
    ) {
      workOrders {
        ...WorkOrderFragment
      }
      totalCount
    }
  }
  ${WOWorkOrdersFragment}
`;

export const WOWorkOrderByIdDocument = gql`
  query workOrder($workOrderId: UUID!) {
    workOrder(workOrderId: $workOrderId) {
      ...WorkOrderByIdFragment
    }
  }
  ${WOWorkOrderByIdFragment}
`;

export const WorkOrderCreateDocument = gql`
  mutation WorkOrderCreate($data: WorkOrderInput!, $files: [Upload!]) {
    workOrderCreate(data: $data, files: $files) {
      ...WorkOrderByIdFragment
    }
  }
  ${WOWorkOrderByIdFragment}
`;

export const WorkOrderUpdateDocument = gql`
  mutation WorkOrderUpdate($data: WorkOrderUpdateInput!) {
    workOrderUpdate(data: $data) {
      ...WorkOrderByIdFragment
    }
  }
  ${WOWorkOrderByIdFragment}
`;

export const WOOrderTypesDocument = gql`
  query orderTypes($orderTypeId: UUID) {
    orderTypes(orderTypeId: $orderTypeId) {
      id
      name
    }
  }
`;

export const WOOrderAssetServiceTypesDocument = gql`
  query assetServiceType($assetServiceTypeId: UUID) {
    assetServiceType(assetServiceTypeId: $assetServiceTypeId) {
      id
      name
    }
  }
`;

export const WOServiceCategoriesDocument = gql`
  query serviceCategories($serviceCategoryId: UUID) {
    serviceCategories(serviceCategoryId: $serviceCategoryId) {
      id
      name
    }
  }
`;
