import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React from "react";

import LabeledMenuButton from "../../../../components/elements/labeledMenuButton";
import { getAssetFileDeletedMessage } from "../../../../constants/lang/en";
import { useAssetFileDeleteMutation } from "../../../../graphql/graphql";
import { setGenericMessage } from "../../../../utils/serverErrors";

interface AssetFilesBulkActionProps {
  selectedFiles: Array<string | undefined>;
  setSelectedFiles: React.Dispatch<
    React.SetStateAction<(string | undefined)[]>
  >;
  canDeleteAsset: boolean;
}

const AssetFilesBulkAction: React.FC<AssetFilesBulkActionProps> = ({
  selectedFiles,
  setSelectedFiles,
  canDeleteAsset,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null);
  const [assetFileDeleteMutation, { loading }] = useAssetFileDeleteMutation();

  const deleteItems = async () => {
    try {
      const { errors } = await assetFileDeleteMutation({
        variables: { ids: selectedFiles },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        toast({
          description: getAssetFileDeletedMessage(selectedFiles.length),
          status: "success",
          position: "top",
          isClosable: true,
        });
        selectedFiles.forEach((file) =>
          client.cache.evict({ id: `AssetFile:${file}` })
        );
        setSelectedFiles([]);
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } finally {
      onDeleteClose();
    }
  };

  return selectedFiles.length ? (
    <Box position="static" right="0" top="-56px">
      <Menu>
        <LabeledMenuButton label="Action" value="" />
        <MenuList minWidth="100px">
          {canDeleteAsset && (
            <MenuItem onClick={onDeleteOpen}>
              Delete selected {pluralize("file", selectedFiles.length)}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      {canDeleteAsset && (
        <AlertDialog
          leastDestructiveRef={cancelDeleteRef}
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogBody textAlign="center">
                Are you sure you want to delete {selectedFiles.length} selected{" "}
                {pluralize("file", selectedFiles.length)}?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelDeleteRef}
                  onClick={onDeleteClose}
                  width="48%"
                >
                  No, Don't Delete!
                </Button>
                <Button
                  onClick={deleteItems}
                  colorScheme="red"
                  ml="4%"
                  width="48%"
                  isLoading={loading}
                >
                  Yes, Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </Box>
  ) : null;
};

export default AssetFilesBulkAction;
