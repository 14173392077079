import { useReactiveVar } from "@apollo/client";
import { Box, Button, IconButton, Input, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import gql from "graphql-tag";
import React from "react";

import CloseIcon from "../../../components/icons/close";
import { COUPON_INVALID } from "../../../constants/lang/en";
import {
  StripeCouponFragmentFragment,
  useStripeCouponMutation,
} from "../../../graphql/graphql";
import { currentCompanyPlanVar } from "../../../graphql/reactiveVariables";
import {
  discountedAmount,
  discountedSaving,
  getCoupon,
  removeCoupon,
  setCoupon,
} from "../../../utils/coupon";
import { formatPrice } from "../../../utils/price";
import { setGenericMessage } from "../../../utils/serverErrors";
import { couponSchema, yupObject } from "../../../utils/validation";

interface CouponFormProps {
  planAmount: number;
  prorationAmount: number;
  selectedPlanName: string;
}

const CouponForm: React.FC<CouponFormProps> = ({
  planAmount,
  prorationAmount,
  selectedPlanName,
}) => {
  const currentPlan = useReactiveVar(currentCompanyPlanVar);
  const [couponCode, setCouponCode] = React.useState<string>(getCoupon() || "");
  const [stripeCoupon, setStripeCoupon] =
    React.useState<StripeCouponFragmentFragment>();
  const [showCouponForm, setShowCouponForm] = React.useState(false);
  const [stripeCouponMutation, { loading }] = useStripeCouponMutation();
  const [formError, setFormError] = React.useState<string>();

  const handleRemoveCoupon = () => {
    setShowCouponForm(false);
    setCouponCode("");
    setStripeCoupon(undefined);
    removeCoupon();
  };

  const onCouponFormSubmit = async (data: CouponFormData) => {
    const code = data.code.trim().toUpperCase();
    try {
      setFormError("");

      const { data: serverData, errors } = await stripeCouponMutation({
        variables: { code },
      });

      if (errors) {
        setFormError(setGenericMessage(errors));
      } else if (serverData) {
        const coupon = serverData.stripeCoupon;
        if (!coupon.valid) {
          setFormError(COUPON_INVALID);
          return;
        }
        setCouponCode(code);
        setCoupon(code);
        setStripeCoupon(coupon);
        return;
      }
    } catch (error: any) {
      setFormError(
        error.message.includes("No such coupon")
          ? COUPON_INVALID
          : setGenericMessage(error)
      );
    }
  };

  React.useEffect(() => {
    if (couponCode) onCouponFormSubmit({ code: couponCode });
    // eslint-disable-next-line
  }, []);

  return (
    <Box marginBottom="8">
      {prorationAmount < 0 && (
        <Box textAlign="center" marginY="5">
          You will be refunded {formatPrice(Math.abs(prorationAmount) / 100)}{" "}
          for your current {currentPlan?.productName} plan and you will be
          billed{" "}
          {stripeCoupon ? (
            <Text as="span">
              <Box as="span" textDecoration="line-through">
                {formatPrice(planAmount / 100)}
              </Box>{" "}
              <Text as="span">
                {discountedAmount(planAmount / 100, stripeCoupon)}
              </Text>
            </Text>
          ) : (
            <Text as="span">{formatPrice(planAmount / 100)}</Text>
          )}{" "}
          today for your new {selectedPlanName} plan
        </Box>
      )}

      {couponCode && stripeCoupon ? (
        <Box
          color="gray.800"
          textAlign="center"
          fontSize="lg"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text>
            Coupon <Text as="strong">{couponCode}</Text> applied!{" "}
            <Box as="span" color="green.500">
              (You save {discountedSaving(planAmount / 100, stripeCoupon)}!)
            </Box>
          </Text>
          <IconButton
            onClick={handleRemoveCoupon}
            variant="icon"
            colorScheme="gray"
            aria-label="Remove coupon"
            marginLeft="2"
            marginTop="1"
          >
            <CloseIcon width="10px" />
          </IconButton>
        </Box>
      ) : showCouponForm ? (
        <Box marginX="auto" maxWidth="xs">
          <Formik
            initialValues={{
              code: "",
            }}
            validationSchema={CouponValidationSchema}
            onSubmit={onCouponFormSubmit}
          >
            {({ getFieldProps }) => (
              <Form noValidate>
                <Box display={{ sm: "flex" }} textAlign="center">
                  <Input
                    autoFocus
                    placeholder="Add coupon code"
                    size="sm"
                    {...getFieldProps("code")}
                  />
                  <Button
                    type="submit"
                    isLoading={loading}
                    marginTop={{ base: "2", sm: "0" }}
                    marginLeft={{ base: "0", sm: "2" }}
                    size="xs"
                    variant="outline"
                    colorScheme="secondary"
                    paddingX="3"
                  >
                    Apply
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
          {!!formError && (
            <Text
              color="red.500"
              textAlign={{ base: "center", sm: "left" }}
              marginBottom="4"
              marginTop="1"
              fontSize="sm"
            >
              {formError}
            </Text>
          )}
        </Box>
      ) : (
        <Box textAlign="center">
          <Button
            variant="link"
            colorScheme="secondary"
            color="gray.600"
            fontSize="sm"
            onClick={() => setShowCouponForm(true)}
          >
            Add coupon
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CouponForm;

const CouponValidationSchema = yupObject().shape({
  code: couponSchema,
});

type CouponFormData = {
  code: string;
};

gql`
  mutation StripeCoupon($code: String!) {
    stripeCoupon(code: $code) {
      ...StripeCouponFragment
    }
  }
`;
