import { Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";

import AssetIcon from "../../../components/elements/assetIcons";
import FilterSelect, {
  FilterOption,
} from "../../../components/elements/filterSelect";
import useAssetCategories from "../../../hooks/useAssetCategories";

interface CategoriesFilterProps {
  onChange: (ids: string[] | undefined) => void;
  value: string[] | undefined;
}

const CategoriesFilter: React.FC<CategoriesFilterProps> = ({
  onChange,
  value,
}) => {
  const { data } = useAssetCategories();

  const categoryOptions = useMemo(() => {
    let categoryOptions: FilterOption[] = [];
    if (data?.assetCategories.length) {
      data?.assetCategories.forEach((category) => {
        categoryOptions.push({
          label: (
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <AssetIcon
                iconName={category.iconName}
                iconColor={category.iconColor}
                iconType={category.iconType}
                marginRight="2"
                iconSize="xs"
              />
              <Text
                fontSize="16px"
                textAlign="left"
                _hover={{ color: "secondary.500" }}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {category.name}
              </Text>
            </Flex>
          ),
          value: category.id,
          query: category.name,
        });
      });
    }
    return categoryOptions;
  }, [data?.assetCategories]);

  const handleChange = useCallback(
    (id: string) => {
      if (!value) {
        onChange([id]);
      } else if (value.includes(id)) {
        onChange(
          value.length === 1 ? undefined : value.filter((v) => v !== id)
        );
      } else {
        onChange([...value, id]);
      }
    },
    [value, onChange]
  );

  return (
    <FilterSelect
      label="Category"
      selectedOptions={value}
      allOptions={categoryOptions}
      handleFilterChange={handleChange}
    />
  );
};

export default CategoriesFilter;
