import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useWOGetStagesQuery } from "../../../../../graphql/queries/workOrderStatuses";
import {
  WOStage,
  WOTransitionReason,
} from "../../../../../graphql/types/queries/workOrderStatuses";
import { BaseEntityType, WorkOrder } from "../../../models/workOrder";
import WorkOrderEditSectionFooter from "../../components/sectionFooter";
import WorkOrderEditWorkflowPresenter, {
  WorkflowSectionData,
} from "./presenter";

interface WorkOrderEditWorkflowProps {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
}

const WorkOrderEditWorkflow: React.FC<WorkOrderEditWorkflowProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  const [sectionData, setSectionData] = useState<WorkflowSectionData>();
  const [changed, setChanged] = useState<boolean>(false);
  const [disableReasonsSelection, setDisableReasonsSelection] =
    useState<boolean>(true);
  const [stageOptions, setStageOptions] = useState<WOStage[]>([]);
  const [reasonOptions, setReasonOptions] = useState<WOTransitionReason[]>([]);

  const { data: stages } = useWOGetStagesQuery({
    fetchPolicy: "network-only",
  });

  const isSectionDataValid = useMemo(() => {
    return (
      workOrder.workOrderStageId !== sectionData?.stageId &&
      !!(sectionData?.reasonId || !reasonOptions.length)
    );
  }, [workOrder, sectionData]);

  const handleSectionDataChange = useCallback(
    (newSectionData: WorkflowSectionData) => {
      if (!isEqual(newSectionData, sectionData)) {
        setChanged(true);
      }
      setSectionData(newSectionData);
    },
    [setChanged, setSectionData, sectionData]
  );

  const handleStageChange = useCallback(
    (stage?: WOStage | null) => {
      if (workOrder.workOrderStageId === stage?.id) {
        setReasonOptions(
          workOrder.transitionReason ? [workOrder.transitionReason] : []
        );
        handleSectionDataChange({
          reasonId: workOrder.transitionReasonId,
          stageId: workOrder.workOrderStageId,
        });
        setChanged(false);
        setDisableReasonsSelection(true);
      } else {
        const currentStage = stages?.workOrderStages.find(
          (status) => status.id === workOrder.workOrderStageId
        );
        const transitionalStage = currentStage?.transitionStages?.find(
          (ts) => ts.availableStageId === stage?.id
        );
        const options = transitionalStage?.transitionReasons
          ? transitionalStage.transitionReasons
          : [];
        setReasonOptions(options);
        setDisableReasonsSelection(false);
        handleSectionDataChange({ reasonId: null, stageId: stage?.id || null });
      }
    },
    [workOrder, stages, handleSectionDataChange]
  );

  const handleReasonChange = useCallback(
    (reason?: WOTransitionReason | null) => {
      handleSectionDataChange({
        reasonId: reason?.id || null,
        stageId: sectionData?.stageId || null,
      });
    },
    [handleSectionDataChange, sectionData]
  );

  const cancel = () => {
    setChanged(false);
    setSectionData({
      stageId: workOrder.workOrderStageId,
      reasonId: workOrder.transitionReasonId,
    });
    setReasonOptions(
      workOrder.transitionReason ? [workOrder.transitionReason] : []
    );
    setDisableReasonsSelection(true);
  };

  const save = () => {
    if (sectionData) {
      const reason = reasonOptions.find(
        (option) => option.id === sectionData.reasonId
      );
      const transitionReason: BaseEntityType = {
        id: reason?.id || "",
        name: reason?.name || "",
      };
      onWorkOrderChange({
        ...workOrder,
        transitionReason,
        workOrderStageId: sectionData.stageId,
        transitionReasonId: sectionData.reasonId,
      });
    }
  };

  useEffect(() => {
    if (workOrder) {
      setSectionData({
        stageId: workOrder.workOrderStageId,
        reasonId: workOrder.transitionReasonId,
      });
    }
    setChanged(false);
    setDisableReasonsSelection(true);
  }, [workOrder]);

  useEffect(() => {
    changeIsEdit && changeIsEdit(changed);
  }, [changed, changeIsEdit]);

  useEffect(() => {
    if (stages?.workOrderStages) {
      const currentStage = stages?.workOrderStages.find(
        (status) => status.id === workOrder.workOrderStageId
      );
      if (currentStage) {
        const stagesOptions = currentStage.transitionStages?.map(
          (subsequentStage) => subsequentStage.availableStage
        ) as WOStage[];

        setStageOptions([currentStage, ...stagesOptions]);
      } else {
        setStageOptions(stages?.workOrderStages || []);
      }
    }
  }, [stages, workOrder]);

  useEffect(() => {
    if (workOrder.transitionReasonId) {
      setReasonOptions([
        {
          id: workOrder.transitionReason?.id || "",
          name: workOrder.transitionReason?.name || "",
        },
      ]);
    }
  }, [workOrder]);

  return (
    <>
      <WorkOrderEditWorkflowPresenter
        sectionData={sectionData}
        stages={stageOptions}
        reasons={reasonOptions}
        disableReasonsSelection={disableReasonsSelection}
        onReasonChange={handleReasonChange}
        onStageChange={handleStageChange}
      >
        <WorkOrderEditSectionFooter
          disabled={!isSectionDataValid}
          cancel={cancel}
          save={save}
          visible={changed}
        />
      </WorkOrderEditWorkflowPresenter>
    </>
  );
};

export default WorkOrderEditWorkflow;
