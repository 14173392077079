export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const timezones = [
  {
    value: "Pacific/Niue",
    query: "(GMT-11:00) Niue Time",
    label: "(GMT-11:00) Niue Time",
  },
  {
    value: "Pacific/Pago_Pago",
    query: "(GMT-11:00) Samoa Standard Time",
    label: "(GMT-11:00) Samoa Standard Time",
  },
  {
    value: "Pacific/Rarotonga",
    query: "(GMT-10:00) Cook Islands Standard Time",
    label: "(GMT-10:00) Cook Islands Standard Time",
  },
  {
    value: "Pacific/Honolulu",
    query: "(GMT-10:00) Hawaii-Aleutian Standard Time",
    label: "(GMT-10:00) Hawaii-Aleutian Standard Time",
  },
  {
    value: "Pacific/Tahiti",
    query: "(GMT-10:00) Tahiti Time",
    label: "(GMT-10:00) Tahiti Time",
  },
  {
    value: "Pacific/Marquesas",
    query: "(GMT-09:30) Marquesas Time",
    label: "(GMT-09:30) Marquesas Time",
  },
  {
    value: "Pacific/Gambier",
    query: "(GMT-09:00) Gambier Time",
    label: "(GMT-09:00) Gambier Time",
  },
  {
    value: "America/Adak",
    query: "(GMT-09:00) Hawaii-Aleutian Time (Adak)",
    label: "(GMT-09:00) Hawaii-Aleutian Time (Adak)",
  },
  {
    value: "America/Anchorage",
    query: "(GMT-08:00) Alaska Time - Anchorage",
    label: "(GMT-08:00) Alaska Time - Anchorage",
  },
  {
    value: "America/Juneau",
    query: "(GMT-08:00) Alaska Time - Juneau",
    label: "(GMT-08:00) Alaska Time - Juneau",
  },
  {
    value: "America/Metlakatla",
    query: "(GMT-08:00) Alaska Time - Metlakatla",
    label: "(GMT-08:00) Alaska Time - Metlakatla",
  },
  {
    value: "America/Nome",
    query: "(GMT-08:00) Alaska Time - Nome",
    label: "(GMT-08:00) Alaska Time - Nome",
  },
  {
    value: "America/Sitka",
    query: "(GMT-08:00) Alaska Time - Sitka",
    label: "(GMT-08:00) Alaska Time - Sitka",
  },
  {
    value: "America/Yakutat",
    query: "(GMT-08:00) Alaska Time - Yakutat",
    label: "(GMT-08:00) Alaska Time - Yakutat",
  },
  {
    value: "Pacific/Pitcairn",
    query: "(GMT-08:00) Pitcairn Time",
    label: "(GMT-08:00) Pitcairn Time",
  },
  {
    value: "America/Hermosillo",
    query: "(GMT-07:00) Mexican Pacific Standard Time",
    label: "(GMT-07:00) Mexican Pacific Standard Time",
  },
  {
    value: "America/Creston",
    query: "(GMT-07:00) Mountain Standard Time - Creston",
    label: "(GMT-07:00) Mountain Standard Time - Creston",
  },
  {
    value: "America/Dawson_Creek",
    query: "(GMT-07:00) Mountain Standard Time - Dawson Creek",
    label: "(GMT-07:00) Mountain Standard Time - Dawson Creek",
  },
  {
    value: "America/Fort_Nelson",
    query: "(GMT-07:00) Mountain Standard Time - Fort Nelson",
    label: "(GMT-07:00) Mountain Standard Time - Fort Nelson",
  },
  {
    value: "America/Phoenix",
    query: "(GMT-07:00) Mountain Standard Time - Phoenix",
    label: "(GMT-07:00) Mountain Standard Time - Phoenix",
  },
  {
    value: "America/Dawson",
    query: "(GMT-07:00) Mountain Time (Dawson)",
    label: "(GMT-07:00) Mountain Time (Dawson)",
  },
  {
    value: "America/Whitehorse",
    query: "(GMT-07:00) Mountain Time (Whitehorse)",
    label: "(GMT-07:00) Mountain Time (Whitehorse)",
  },
  {
    value: "America/Los_Angeles",
    query: "(GMT-07:00) Pacific Time - Los Angeles",
    label: "(GMT-07:00) Pacific Time - Los Angeles",
  },
  {
    value: "America/Tijuana",
    query: "(GMT-07:00) Pacific Time - Tijuana",
    label: "(GMT-07:00) Pacific Time - Tijuana",
  },
  {
    value: "America/Vancouver",
    query: "(GMT-07:00) Pacific Time - Vancouver",
    label: "(GMT-07:00) Pacific Time - Vancouver",
  },
  {
    value: "America/Belize",
    query: "(GMT-06:00) Central Standard Time - Belize",
    label: "(GMT-06:00) Central Standard Time - Belize",
  },
  {
    value: "America/Costa_Rica",
    query: "(GMT-06:00) Central Standard Time - Costa Rica",
    label: "(GMT-06:00) Central Standard Time - Costa Rica",
  },
  {
    value: "America/El_Salvador",
    query: "(GMT-06:00) Central Standard Time - El Salvador",
    label: "(GMT-06:00) Central Standard Time - El Salvador",
  },
  {
    value: "America/Guatemala",
    query: "(GMT-06:00) Central Standard Time - Guatemala",
    label: "(GMT-06:00) Central Standard Time - Guatemala",
  },
  {
    value: "America/Managua",
    query: "(GMT-06:00) Central Standard Time - Managua",
    label: "(GMT-06:00) Central Standard Time - Managua",
  },
  {
    value: "America/Regina",
    query: "(GMT-06:00) Central Standard Time - Regina",
    label: "(GMT-06:00) Central Standard Time - Regina",
  },
  {
    value: "America/Swift_Current",
    query: "(GMT-06:00) Central Standard Time - Swift Current",
    label: "(GMT-06:00) Central Standard Time - Swift Current",
  },
  {
    value: "America/Tegucigalpa",
    query: "(GMT-06:00) Central Standard Time - Tegucigalpa",
    label: "(GMT-06:00) Central Standard Time - Tegucigalpa",
  },
  {
    value: "Pacific/Easter",
    query: "(GMT-06:00) Easter Island Time",
    label: "(GMT-06:00) Easter Island Time",
  },
  {
    value: "Pacific/Galapagos",
    query: "(GMT-06:00) Galapagos Time",
    label: "(GMT-06:00) Galapagos Time",
  },
  {
    value: "America/Chihuahua",
    query: "(GMT-06:00) Mexican Pacific Time - Chihuahua",
    label: "(GMT-06:00) Mexican Pacific Time - Chihuahua",
  },
  {
    value: "America/Mazatlan",
    query: "(GMT-06:00) Mexican Pacific Time - Mazatlan",
    label: "(GMT-06:00) Mexican Pacific Time - Mazatlan",
  },
  {
    value: "America/Boise",
    query: "(GMT-06:00) Mountain Time - Boise",
    label: "(GMT-06:00) Mountain Time - Boise",
  },
  {
    value: "America/Cambridge_Bay",
    query: "(GMT-06:00) Mountain Time - Cambridge Bay",
    label: "(GMT-06:00) Mountain Time - Cambridge Bay",
  },
  {
    value: "America/Denver",
    query: "(GMT-06:00) Mountain Time - Denver",
    label: "(GMT-06:00) Mountain Time - Denver",
  },
  {
    value: "America/Edmonton",
    query: "(GMT-06:00) Mountain Time - Edmonton",
    label: "(GMT-06:00) Mountain Time - Edmonton",
  },
  {
    value: "America/Inuvik",
    query: "(GMT-06:00) Mountain Time - Inuvik",
    label: "(GMT-06:00) Mountain Time - Inuvik",
  },
  {
    value: "America/Ojinaga",
    query: "(GMT-06:00) Mountain Time - Ojinaga",
    label: "(GMT-06:00) Mountain Time - Ojinaga",
  },
  {
    value: "America/Yellowknife",
    query: "(GMT-06:00) Mountain Time - Yellowknife",
    label: "(GMT-06:00) Mountain Time - Yellowknife",
  },
  {
    value: "America/Eirunepe",
    query: "(GMT-05:00) Acre Standard Time - Eirunepe",
    label: "(GMT-05:00) Acre Standard Time - Eirunepe",
  },
  {
    value: "America/Rio_Branco",
    query: "(GMT-05:00) Acre Standard Time - Rio Branco",
    label: "(GMT-05:00) Acre Standard Time - Rio Branco",
  },
  {
    value: "America/Bahia_Banderas",
    query: "(GMT-05:00) Central Time - Bahia Banderas",
    label: "(GMT-05:00) Central Time - Bahia Banderas",
  },
  {
    value: "America/North_Dakota/Beulah",
    query: "(GMT-05:00) Central Time - Beulah, North Dakota",
    label: "(GMT-05:00) Central Time - Beulah, North Dakota",
  },
  {
    value: "America/North_Dakota/Center",
    query: "(GMT-05:00) Central Time - Center, North Dakota",
    label: "(GMT-05:00) Central Time - Center, North Dakota",
  },
  {
    value: "America/Chicago",
    query: "(GMT-05:00) Central Time - Chicago",
    label: "(GMT-05:00) Central Time - Chicago",
  },
  {
    value: "America/Indiana/Knox",
    query: "(GMT-05:00) Central Time - Knox, Indiana",
    label: "(GMT-05:00) Central Time - Knox, Indiana",
  },
  {
    value: "America/Matamoros",
    query: "(GMT-05:00) Central Time - Matamoros",
    label: "(GMT-05:00) Central Time - Matamoros",
  },
  {
    value: "America/Menominee",
    query: "(GMT-05:00) Central Time - Menominee",
    label: "(GMT-05:00) Central Time - Menominee",
  },
  {
    value: "America/Merida",
    query: "(GMT-05:00) Central Time - Merida",
    label: "(GMT-05:00) Central Time - Merida",
  },
  {
    value: "America/Mexico_City",
    query: "(GMT-05:00) Central Time - Mexico City",
    label: "(GMT-05:00) Central Time - Mexico City",
  },
  {
    value: "America/Monterrey",
    query: "(GMT-05:00) Central Time - Monterrey",
    label: "(GMT-05:00) Central Time - Monterrey",
  },
  {
    value: "America/North_Dakota/New_Salem",
    query: "(GMT-05:00) Central Time - New Salem, North Dakota",
    label: "(GMT-05:00) Central Time - New Salem, North Dakota",
  },
  {
    value: "America/Rainy_River",
    query: "(GMT-05:00) Central Time - Rainy River",
    label: "(GMT-05:00) Central Time - Rainy River",
  },
  {
    value: "America/Rankin_Inlet",
    query: "(GMT-05:00) Central Time - Rankin Inlet",
    label: "(GMT-05:00) Central Time - Rankin Inlet",
  },
  {
    value: "America/Resolute",
    query: "(GMT-05:00) Central Time - Resolute",
    label: "(GMT-05:00) Central Time - Resolute",
  },
  {
    value: "America/Indiana/Tell_City",
    query: "(GMT-05:00) Central Time - Tell City, Indiana",
    label: "(GMT-05:00) Central Time - Tell City, Indiana",
  },
  {
    value: "America/Winnipeg",
    query: "(GMT-05:00) Central Time - Winnipeg",
    label: "(GMT-05:00) Central Time - Winnipeg",
  },
  {
    value: "America/Bogota",
    query: "(GMT-05:00) Colombia Standard Time",
    label: "(GMT-05:00) Colombia Standard Time",
  },
  {
    value: "America/Atikokan",
    query: "(GMT-05:00) Eastern Standard Time - Atikokan",
    label: "(GMT-05:00) Eastern Standard Time - Atikokan",
  },
  {
    value: "America/Cancun",
    query: "(GMT-05:00) Eastern Standard Time - Cancun",
    label: "(GMT-05:00) Eastern Standard Time - Cancun",
  },
  {
    value: "America/Jamaica",
    query: "(GMT-05:00) Eastern Standard Time - Jamaica",
    label: "(GMT-05:00) Eastern Standard Time - Jamaica",
  },
  {
    value: "America/Panama",
    query: "(GMT-05:00) Eastern Standard Time - Panama",
    label: "(GMT-05:00) Eastern Standard Time - Panama",
  },
  {
    value: "America/Guayaquil",
    query: "(GMT-05:00) Ecuador Time",
    label: "(GMT-05:00) Ecuador Time",
  },
  {
    value: "America/Lima",
    query: "(GMT-05:00) Peru Standard Time",
    label: "(GMT-05:00) Peru Standard Time",
  },
  {
    value: "America/Boa_Vista",
    query: "(GMT-04:00) Amazon Standard Time - Boa Vista",
    label: "(GMT-04:00) Amazon Standard Time - Boa Vista",
  },
  {
    value: "America/Campo_Grande",
    query: "(GMT-04:00) Amazon Standard Time - Campo Grande",
    label: "(GMT-04:00) Amazon Standard Time - Campo Grande",
  },
  {
    value: "America/Cuiaba",
    query: "(GMT-04:00) Amazon Standard Time - Cuiaba",
    label: "(GMT-04:00) Amazon Standard Time - Cuiaba",
  },
  {
    value: "America/Manaus",
    query: "(GMT-04:00) Amazon Standard Time - Manaus",
    label: "(GMT-04:00) Amazon Standard Time - Manaus",
  },
  {
    value: "America/Porto_Velho",
    query: "(GMT-04:00) Amazon Standard Time - Porto Velho",
    label: "(GMT-04:00) Amazon Standard Time - Porto Velho",
  },
  {
    value: "America/Barbados",
    query: "(GMT-04:00) Atlantic Standard Time - Barbados",
    label: "(GMT-04:00) Atlantic Standard Time - Barbados",
  },
  {
    value: "America/Blanc-Sablon",
    query: "(GMT-04:00) Atlantic Standard Time - Blanc-Sablon",
    label: "(GMT-04:00) Atlantic Standard Time - Blanc-Sablon",
  },
  {
    value: "America/Curacao",
    query: "(GMT-04:00) Atlantic Standard Time - Curaçao",
    label: "(GMT-04:00) Atlantic Standard Time - Curaçao",
  },
  {
    value: "America/Martinique",
    query: "(GMT-04:00) Atlantic Standard Time - Martinique",
    label: "(GMT-04:00) Atlantic Standard Time - Martinique",
  },
  {
    value: "America/Port_of_Spain",
    query: "(GMT-04:00) Atlantic Standard Time - Port of Spain",
    label: "(GMT-04:00) Atlantic Standard Time - Port of Spain",
  },
  {
    value: "America/Puerto_Rico",
    query: "(GMT-04:00) Atlantic Standard Time - Puerto Rico",
    label: "(GMT-04:00) Atlantic Standard Time - Puerto Rico",
  },
  {
    value: "America/Santo_Domingo",
    query: "(GMT-04:00) Atlantic Standard Time - Santo Domingo",
    label: "(GMT-04:00) Atlantic Standard Time - Santo Domingo",
  },
  {
    value: "America/La_Paz",
    query: "(GMT-04:00) Bolivia Time",
    label: "(GMT-04:00) Bolivia Time",
  },
  {
    value: "America/Santiago",
    query: "(GMT-04:00) Chile Time",
    label: "(GMT-04:00) Chile Time",
  },
  {
    value: "America/Havana",
    query: "(GMT-04:00) Cuba Time",
    label: "(GMT-04:00) Cuba Time",
  },
  {
    value: "America/Detroit",
    query: "(GMT-04:00) Eastern Time - Detroit",
    label: "(GMT-04:00) Eastern Time - Detroit",
  },
  {
    value: "America/Grand_Turk",
    query: "(GMT-04:00) Eastern Time - Grand Turk",
    label: "(GMT-04:00) Eastern Time - Grand Turk",
  },
  {
    value: "America/Indiana/Indianapolis",
    query: "(GMT-04:00) Eastern Time - Indianapolis",
    label: "(GMT-04:00) Eastern Time - Indianapolis",
  },
  {
    value: "America/Iqaluit",
    query: "(GMT-04:00) Eastern Time - Iqaluit",
    label: "(GMT-04:00) Eastern Time - Iqaluit",
  },
  {
    value: "America/Kentucky/Louisville",
    query: "(GMT-04:00) Eastern Time - Louisville",
    label: "(GMT-04:00) Eastern Time - Louisville",
  },
  {
    value: "America/Indiana/Marengo",
    query: "(GMT-04:00) Eastern Time - Marengo, Indiana",
    label: "(GMT-04:00) Eastern Time - Marengo, Indiana",
  },
  {
    value: "America/Kentucky/Monticello",
    query: "(GMT-04:00) Eastern Time - Monticello, Kentucky",
    label: "(GMT-04:00) Eastern Time - Monticello, Kentucky",
  },
  {
    value: "America/Nassau",
    query: "(GMT-04:00) Eastern Time - Nassau",
    label: "(GMT-04:00) Eastern Time - Nassau",
  },
  {
    value: "America/New_York",
    query: "(GMT-04:00) Eastern Time - New York",
    label: "(GMT-04:00) Eastern Time - New York",
  },
  {
    value: "America/Nipigon",
    query: "(GMT-04:00) Eastern Time - Nipigon",
    label: "(GMT-04:00) Eastern Time - Nipigon",
  },
  {
    value: "America/Pangnirtung",
    query: "(GMT-04:00) Eastern Time - Pangnirtung",
    label: "(GMT-04:00) Eastern Time - Pangnirtung",
  },
  {
    value: "America/Indiana/Petersburg",
    query: "(GMT-04:00) Eastern Time - Petersburg, Indiana",
    label: "(GMT-04:00) Eastern Time - Petersburg, Indiana",
  },
  {
    value: "America/Port-au-Prince",
    query: "(GMT-04:00) Eastern Time - Port-au-Prince",
    label: "(GMT-04:00) Eastern Time - Port-au-Prince",
  },
  {
    value: "America/Thunder_Bay",
    query: "(GMT-04:00) Eastern Time - Thunder Bay",
    label: "(GMT-04:00) Eastern Time - Thunder Bay",
  },
  {
    value: "America/Toronto",
    query: "(GMT-04:00) Eastern Time - Toronto",
    label: "(GMT-04:00) Eastern Time - Toronto",
  },
  {
    value: "America/Indiana/Vevay",
    query: "(GMT-04:00) Eastern Time - Vevay, Indiana",
    label: "(GMT-04:00) Eastern Time - Vevay, Indiana",
  },
  {
    value: "America/Indiana/Vincennes",
    query: "(GMT-04:00) Eastern Time - Vincennes, Indiana",
    label: "(GMT-04:00) Eastern Time - Vincennes, Indiana",
  },
  {
    value: "America/Indiana/Winamac",
    query: "(GMT-04:00) Eastern Time - Winamac, Indiana",
    label: "(GMT-04:00) Eastern Time - Winamac, Indiana",
  },
  {
    value: "America/Guyana",
    query: "(GMT-04:00) Guyana Time",
    label: "(GMT-04:00) Guyana Time",
  },
  {
    value: "America/Asuncion",
    query: "(GMT-04:00) Paraguay Time",
    label: "(GMT-04:00) Paraguay Time",
  },
  {
    value: "America/Caracas",
    query: "(GMT-04:00) Venezuela Time",
    label: "(GMT-04:00) Venezuela Time",
  },
  {
    value: "America/Argentina/Buenos_Aires",
    query: "(GMT-03:00) Argentina Standard Time - Buenos Aires",
    label: "(GMT-03:00) Argentina Standard Time - Buenos Aires",
  },
  {
    value: "America/Argentina/Catamarca",
    query: "(GMT-03:00) Argentina Standard Time - Catamarca",
    label: "(GMT-03:00) Argentina Standard Time - Catamarca",
  },
  {
    value: "America/Argentina/Cordoba",
    query: "(GMT-03:00) Argentina Standard Time - Cordoba",
    label: "(GMT-03:00) Argentina Standard Time - Cordoba",
  },
  {
    value: "America/Argentina/Jujuy",
    query: "(GMT-03:00) Argentina Standard Time - Jujuy",
    label: "(GMT-03:00) Argentina Standard Time - Jujuy",
  },
  {
    value: "America/Argentina/La_Rioja",
    query: "(GMT-03:00) Argentina Standard Time - La Rioja",
    label: "(GMT-03:00) Argentina Standard Time - La Rioja",
  },
  {
    value: "America/Argentina/Mendoza",
    query: "(GMT-03:00) Argentina Standard Time - Mendoza",
    label: "(GMT-03:00) Argentina Standard Time - Mendoza",
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    query: "(GMT-03:00) Argentina Standard Time - Rio Gallegos",
    label: "(GMT-03:00) Argentina Standard Time - Rio Gallegos",
  },
  {
    value: "America/Argentina/Salta",
    query: "(GMT-03:00) Argentina Standard Time - Salta",
    label: "(GMT-03:00) Argentina Standard Time - Salta",
  },
  {
    value: "America/Argentina/San_Juan",
    query: "(GMT-03:00) Argentina Standard Time - San Juan",
    label: "(GMT-03:00) Argentina Standard Time - San Juan",
  },
  {
    value: "America/Argentina/San_Luis",
    query: "(GMT-03:00) Argentina Standard Time - San Luis",
    label: "(GMT-03:00) Argentina Standard Time - San Luis",
  },
  {
    value: "America/Argentina/Tucuman",
    query: "(GMT-03:00) Argentina Standard Time - Tucuman",
    label: "(GMT-03:00) Argentina Standard Time - Tucuman",
  },
  {
    value: "America/Argentina/Ushuaia",
    query: "(GMT-03:00) Argentina Standard Time - Ushuaia",
    label: "(GMT-03:00) Argentina Standard Time - Ushuaia",
  },
  {
    value: "Atlantic/Bermuda",
    query: "(GMT-03:00) Atlantic Time - Bermuda",
    label: "(GMT-03:00) Atlantic Time - Bermuda",
  },
  {
    value: "America/Glace_Bay",
    query: "(GMT-03:00) Atlantic Time - Glace Bay",
    label: "(GMT-03:00) Atlantic Time - Glace Bay",
  },
  {
    value: "America/Goose_Bay",
    query: "(GMT-03:00) Atlantic Time - Goose Bay",
    label: "(GMT-03:00) Atlantic Time - Goose Bay",
  },
  {
    value: "America/Halifax",
    query: "(GMT-03:00) Atlantic Time - Halifax",
    label: "(GMT-03:00) Atlantic Time - Halifax",
  },
  {
    value: "America/Moncton",
    query: "(GMT-03:00) Atlantic Time - Moncton",
    label: "(GMT-03:00) Atlantic Time - Moncton",
  },
  {
    value: "America/Thule",
    query: "(GMT-03:00) Atlantic Time - Thule",
    label: "(GMT-03:00) Atlantic Time - Thule",
  },
  {
    value: "America/Araguaina",
    query: "(GMT-03:00) Brasilia Standard Time - Araguaina",
    label: "(GMT-03:00) Brasilia Standard Time - Araguaina",
  },
  {
    value: "America/Bahia",
    query: "(GMT-03:00) Brasilia Standard Time - Bahia",
    label: "(GMT-03:00) Brasilia Standard Time - Bahia",
  },
  {
    value: "America/Belem",
    query: "(GMT-03:00) Brasilia Standard Time - Belem",
    label: "(GMT-03:00) Brasilia Standard Time - Belem",
  },
  {
    value: "America/Fortaleza",
    query: "(GMT-03:00) Brasilia Standard Time - Fortaleza",
    label: "(GMT-03:00) Brasilia Standard Time - Fortaleza",
  },
  {
    value: "America/Maceio",
    query: "(GMT-03:00) Brasilia Standard Time - Maceio",
    label: "(GMT-03:00) Brasilia Standard Time - Maceio",
  },
  {
    value: "America/Recife",
    query: "(GMT-03:00) Brasilia Standard Time - Recife",
    label: "(GMT-03:00) Brasilia Standard Time - Recife",
  },
  {
    value: "America/Santarem",
    query: "(GMT-03:00) Brasilia Standard Time - Santarem",
    label: "(GMT-03:00) Brasilia Standard Time - Santarem",
  },
  {
    value: "America/Sao_Paulo",
    query: "(GMT-03:00) Brasilia Standard Time - Sao Paulo",
    label: "(GMT-03:00) Brasilia Standard Time - Sao Paulo",
  },
  {
    value: "Atlantic/Stanley",
    query: "(GMT-03:00) Falkland Islands Standard Time",
    label: "(GMT-03:00) Falkland Islands Standard Time",
  },
  {
    value: "America/Cayenne",
    query: "(GMT-03:00) French Guiana Time",
    label: "(GMT-03:00) French Guiana Time",
  },
  {
    value: "Antarctica/Palmer",
    query: "(GMT-03:00) Palmer Time",
    label: "(GMT-03:00) Palmer Time",
  },
  {
    value: "America/Punta_Arenas",
    query: "(GMT-03:00) Punta Arenas Time",
    label: "(GMT-03:00) Punta Arenas Time",
  },
  {
    value: "Antarctica/Rothera",
    query: "(GMT-03:00) Rothera Time",
    label: "(GMT-03:00) Rothera Time",
  },
  {
    value: "America/Paramaribo",
    query: "(GMT-03:00) Suriname Time",
    label: "(GMT-03:00) Suriname Time",
  },
  {
    value: "America/Montevideo",
    query: "(GMT-03:00) Uruguay Standard Time",
    label: "(GMT-03:00) Uruguay Standard Time",
  },
  {
    value: "America/St_Johns",
    query: "(GMT-02:30) Newfoundland Time",
    label: "(GMT-02:30) Newfoundland Time",
  },
  {
    value: "America/Noronha",
    query: "(GMT-02:00) Fernando de Noronha Standard Time",
    label: "(GMT-02:00) Fernando de Noronha Standard Time",
  },
  {
    value: "Atlantic/South_Georgia",
    query: "(GMT-02:00) South Georgia Time",
    label: "(GMT-02:00) South Georgia Time",
  },
  {
    value: "America/Miquelon",
    query: "(GMT-02:00) St. Pierre &amp; Miquelon Time",
    label: "(GMT-02:00) St. Pierre &amp; Miquelon Time",
  },
  {
    value: "America/Nuuk",
    query: "(GMT-02:00) West Greenland Time",
    label: "(GMT-02:00) West Greenland Time",
  },
  {
    value: "Atlantic/Cape_Verde",
    query: "(GMT-01:00) Cape Verde Standard Time",
    label: "(GMT-01:00) Cape Verde Standard Time",
  },
  {
    value: "Atlantic/Azores",
    query: "(GMT+00:00) Azores Time",
    label: "(GMT+00:00) Azores Time",
  },
  {
    value: "UTC",
    query: "(GMT+00:00) Coordinated Universal Time",
    label: "(GMT+00:00) Coordinated Universal Time",
  },
  {
    value: "America/Scoresbysund",
    query: "(GMT+00:00) East Greenland Time",
    label: "(GMT+00:00) East Greenland Time",
  },
  {
    value: "Etc/GMT",
    query: "(GMT+00:00) Greenwich Mean Time",
    label: "(GMT+00:00) Greenwich Mean Time",
  },
  {
    value: "Africa/Abidjan",
    query: "(GMT+00:00) Greenwich Mean Time - Abidjan",
    label: "(GMT+00:00) Greenwich Mean Time - Abidjan",
  },
  {
    value: "Africa/Accra",
    query: "(GMT+00:00) Greenwich Mean Time - Accra",
    label: "(GMT+00:00) Greenwich Mean Time - Accra",
  },
  {
    value: "Africa/Bissau",
    query: "(GMT+00:00) Greenwich Mean Time - Bissau",
    label: "(GMT+00:00) Greenwich Mean Time - Bissau",
  },
  {
    value: "America/Danmarkshavn",
    query: "(GMT+00:00) Greenwich Mean Time - Danmarkshavn",
    label: "(GMT+00:00) Greenwich Mean Time - Danmarkshavn",
  },
  {
    value: "Africa/Monrovia",
    query: "(GMT+00:00) Greenwich Mean Time - Monrovia",
    label: "(GMT+00:00) Greenwich Mean Time - Monrovia",
  },
  {
    value: "Atlantic/Reykjavik",
    query: "(GMT+00:00) Greenwich Mean Time - Reykjavik",
    label: "(GMT+00:00) Greenwich Mean Time - Reykjavik",
  },
  {
    value: "Africa/Sao_Tome",
    query: "(GMT+00:00) Greenwich Mean Time - São Tomé",
    label: "(GMT+00:00) Greenwich Mean Time - São Tomé",
  },
  {
    value: "Africa/Casablanca",
    query: "(GMT+00:00) Morocco Time",
    label: "(GMT+00:00) Morocco Time",
  },
  {
    value: "Africa/El_Aaiun",
    query: "(GMT+00:00) Western Sahara Time",
    label: "(GMT+00:00) Western Sahara Time",
  },
  {
    value: "Africa/Algiers",
    query: "(GMT+01:00) Central European Standard Time - Algiers",
    label: "(GMT+01:00) Central European Standard Time - Algiers",
  },
  {
    value: "Africa/Tunis",
    query: "(GMT+01:00) Central European Standard Time - Tunis",
    label: "(GMT+01:00) Central European Standard Time - Tunis",
  },
  {
    value: "Europe/Dublin",
    query: "(GMT+01:00) Ireland Time",
    label: "(GMT+01:00) Ireland Time",
  },
  {
    value: "Europe/London",
    query: "(GMT+01:00) United Kingdom Time",
    label: "(GMT+01:00) United Kingdom Time",
  },
  {
    value: "Africa/Lagos",
    query: "(GMT+01:00) West Africa Standard Time - Lagos",
    label: "(GMT+01:00) West Africa Standard Time - Lagos",
  },
  {
    value: "Africa/Ndjamena",
    query: "(GMT+01:00) West Africa Standard Time - Ndjamena",
    label: "(GMT+01:00) West Africa Standard Time - Ndjamena",
  },
  {
    value: "Atlantic/Canary",
    query: "(GMT+01:00) Western European Time - Canary",
    label: "(GMT+01:00) Western European Time - Canary",
  },
  {
    value: "Atlantic/Faroe",
    query: "(GMT+01:00) Western European Time - Faroe",
    label: "(GMT+01:00) Western European Time - Faroe",
  },
  {
    value: "Europe/Lisbon",
    query: "(GMT+01:00) Western European Time - Lisbon",
    label: "(GMT+01:00) Western European Time - Lisbon",
  },
  {
    value: "Atlantic/Madeira",
    query: "(GMT+01:00) Western European Time - Madeira",
    label: "(GMT+01:00) Western European Time - Madeira",
  },
  {
    value: "Africa/Juba",
    query: "(GMT+02:00) Central Africa Time - Juba",
    label: "(GMT+02:00) Central Africa Time - Juba",
  },
  {
    value: "Africa/Khartoum",
    query: "(GMT+02:00) Central Africa Time - Khartoum",
    label: "(GMT+02:00) Central Africa Time - Khartoum",
  },
  {
    value: "Africa/Maputo",
    query: "(GMT+02:00) Central Africa Time - Maputo",
    label: "(GMT+02:00) Central Africa Time - Maputo",
  },
  {
    value: "Africa/Windhoek",
    query: "(GMT+02:00) Central Africa Time - Windhoek",
    label: "(GMT+02:00) Central Africa Time - Windhoek",
  },
  {
    value: "Europe/Amsterdam",
    query: "(GMT+02:00) Central European Time - Amsterdam",
    label: "(GMT+02:00) Central European Time - Amsterdam",
  },
  {
    value: "Europe/Andorra",
    query: "(GMT+02:00) Central European Time - Andorra",
    label: "(GMT+02:00) Central European Time - Andorra",
  },
  {
    value: "Europe/Belgrade",
    query: "(GMT+02:00) Central European Time - Belgrade",
    label: "(GMT+02:00) Central European Time - Belgrade",
  },
  {
    value: "Europe/Berlin",
    query: "(GMT+02:00) Central European Time - Berlin",
    label: "(GMT+02:00) Central European Time - Berlin",
  },
  {
    value: "Europe/Brussels",
    query: "(GMT+02:00) Central European Time - Brussels",
    label: "(GMT+02:00) Central European Time - Brussels",
  },
  {
    value: "Europe/Budapest",
    query: "(GMT+02:00) Central European Time - Budapest",
    label: "(GMT+02:00) Central European Time - Budapest",
  },
  {
    value: "Africa/Ceuta",
    query: "(GMT+02:00) Central European Time - Ceuta",
    label: "(GMT+02:00) Central European Time - Ceuta",
  },
  {
    value: "Europe/Copenhagen",
    query: "(GMT+02:00) Central European Time - Copenhagen",
    label: "(GMT+02:00) Central European Time - Copenhagen",
  },
  {
    value: "Europe/Gibraltar",
    query: "(GMT+02:00) Central European Time - Gibraltar",
    label: "(GMT+02:00) Central European Time - Gibraltar",
  },
  {
    value: "Europe/Luxembourg",
    query: "(GMT+02:00) Central European Time - Luxembourg",
    label: "(GMT+02:00) Central European Time - Luxembourg",
  },
  {
    value: "Europe/Madrid",
    query: "(GMT+02:00) Central European Time - Madrid",
    label: "(GMT+02:00) Central European Time - Madrid",
  },
  {
    value: "Europe/Malta",
    query: "(GMT+02:00) Central European Time - Malta",
    label: "(GMT+02:00) Central European Time - Malta",
  },
  {
    value: "Europe/Monaco",
    query: "(GMT+02:00) Central European Time - Monaco",
    label: "(GMT+02:00) Central European Time - Monaco",
  },
  {
    value: "Europe/Oslo",
    query: "(GMT+02:00) Central European Time - Oslo",
    label: "(GMT+02:00) Central European Time - Oslo",
  },
  {
    value: "Europe/Paris",
    query: "(GMT+02:00) Central European Time - Paris",
    label: "(GMT+02:00) Central European Time - Paris",
  },
  {
    value: "Europe/Prague",
    query: "(GMT+02:00) Central European Time - Prague",
    label: "(GMT+02:00) Central European Time - Prague",
  },
  {
    value: "Europe/Rome",
    query: "(GMT+02:00) Central European Time - Rome",
    label: "(GMT+02:00) Central European Time - Rome",
  },
  {
    value: "Europe/Stockholm",
    query: "(GMT+02:00) Central European Time - Stockholm",
    label: "(GMT+02:00) Central European Time - Stockholm",
  },
  {
    value: "Europe/Tirane",
    query: "(GMT+02:00) Central European Time - Tirane",
    label: "(GMT+02:00) Central European Time - Tirane",
  },
  {
    value: "Europe/Vienna",
    query: "(GMT+02:00) Central European Time - Vienna",
    label: "(GMT+02:00) Central European Time - Vienna",
  },
  {
    value: "Europe/Warsaw",
    query: "(GMT+02:00) Central European Time - Warsaw",
    label: "(GMT+02:00) Central European Time - Warsaw",
  },
  {
    value: "Europe/Zurich",
    query: "(GMT+02:00) Central European Time - Zurich",
    label: "(GMT+02:00) Central European Time - Zurich",
  },
  {
    value: "Africa/Cairo",
    query: "(GMT+02:00) Eastern European Standard Time - Cairo",
    label: "(GMT+02:00) Eastern European Standard Time - Cairo",
  },
  {
    value: "Europe/Kaliningrad",
    query: "(GMT+02:00) Eastern European Standard Time - Kaliningrad",
    label: "(GMT+02:00) Eastern European Standard Time - Kaliningrad",
  },
  {
    value: "Africa/Tripoli",
    query: "(GMT+02:00) Eastern European Standard Time - Tripoli",
    label: "(GMT+02:00) Eastern European Standard Time - Tripoli",
  },
  {
    value: "Africa/Johannesburg",
    query: "(GMT+02:00) South Africa Standard Time",
    label: "(GMT+02:00) South Africa Standard Time",
  },
  {
    value: "Antarctica/Troll",
    query: "(GMT+02:00) Troll Time",
    label: "(GMT+02:00) Troll Time",
  },
  {
    value: "Asia/Baghdad",
    query: "(GMT+03:00) Arabian Standard Time - Baghdad",
    label: "(GMT+03:00) Arabian Standard Time - Baghdad",
  },
  {
    value: "Asia/Qatar",
    query: "(GMT+03:00) Arabian Standard Time - Qatar",
    label: "(GMT+03:00) Arabian Standard Time - Qatar",
  },
  {
    value: "Asia/Riyadh",
    query: "(GMT+03:00) Arabian Standard Time - Riyadh",
    label: "(GMT+03:00) Arabian Standard Time - Riyadh",
  },
  {
    value: "Africa/Nairobi",
    query: "(GMT+03:00) East Africa Time",
    label: "(GMT+03:00) East Africa Time",
  },
  {
    value: "Asia/Amman",
    query: "(GMT+03:00) Eastern European Time - Amman",
    label: "(GMT+03:00) Eastern European Time - Amman",
  },
  {
    value: "Europe/Athens",
    query: "(GMT+03:00) Eastern European Time - Athens",
    label: "(GMT+03:00) Eastern European Time - Athens",
  },
  {
    value: "Asia/Beirut",
    query: "(GMT+03:00) Eastern European Time - Beirut",
    label: "(GMT+03:00) Eastern European Time - Beirut",
  },
  {
    value: "Europe/Bucharest",
    query: "(GMT+03:00) Eastern European Time - Bucharest",
    label: "(GMT+03:00) Eastern European Time - Bucharest",
  },
  {
    value: "Europe/Chisinau",
    query: "(GMT+03:00) Eastern European Time - Chisinau",
    label: "(GMT+03:00) Eastern European Time - Chisinau",
  },
  {
    value: "Asia/Damascus",
    query: "(GMT+03:00) Eastern European Time - Damascus",
    label: "(GMT+03:00) Eastern European Time - Damascus",
  },
  {
    value: "Asia/Gaza",
    query: "(GMT+03:00) Eastern European Time - Gaza",
    label: "(GMT+03:00) Eastern European Time - Gaza",
  },
  {
    value: "Asia/Hebron",
    query: "(GMT+03:00) Eastern European Time - Hebron",
    label: "(GMT+03:00) Eastern European Time - Hebron",
  },
  {
    value: "Europe/Helsinki",
    query: "(GMT+03:00) Eastern European Time - Helsinki",
    label: "(GMT+03:00) Eastern European Time - Helsinki",
  },
  {
    value: "Europe/Kiev",
    query: "(GMT+03:00) Eastern European Time - Kiev",
    label: "(GMT+03:00) Eastern European Time - Kiev",
  },
  {
    value: "Asia/Nicosia",
    query: "(GMT+03:00) Eastern European Time - Nicosia",
    label: "(GMT+03:00) Eastern European Time - Nicosia",
  },
  {
    value: "Europe/Riga",
    query: "(GMT+03:00) Eastern European Time - Riga",
    label: "(GMT+03:00) Eastern European Time - Riga",
  },
  {
    value: "Europe/Sofia",
    query: "(GMT+03:00) Eastern European Time - Sofia",
    label: "(GMT+03:00) Eastern European Time - Sofia",
  },
  {
    value: "Europe/Tallinn",
    query: "(GMT+03:00) Eastern European Time - Tallinn",
    label: "(GMT+03:00) Eastern European Time - Tallinn",
  },
  {
    value: "Europe/Uzhgorod",
    query: "(GMT+03:00) Eastern European Time - Uzhhorod",
    label: "(GMT+03:00) Eastern European Time - Uzhhorod",
  },
  {
    value: "Europe/Vilnius",
    query: "(GMT+03:00) Eastern European Time - Vilnius",
    label: "(GMT+03:00) Eastern European Time - Vilnius",
  },
  {
    value: "Europe/Zaporozhye",
    query: "(GMT+03:00) Eastern European Time - Zaporozhye",
    label: "(GMT+03:00) Eastern European Time - Zaporozhye",
  },
  {
    value: "Asia/Famagusta",
    query: "(GMT+03:00) Famagusta Time",
    label: "(GMT+03:00) Famagusta Time",
  },
  {
    value: "Asia/Jerusalem",
    query: "(GMT+03:00) Israel Time",
    label: "(GMT+03:00) Israel Time",
  },
  {
    value: "Europe/Kirov",
    query: "(GMT+03:00) Kirov Time",
    label: "(GMT+03:00) Kirov Time",
  },
  {
    value: "Europe/Minsk",
    query: "(GMT+03:00) Moscow Standard Time - Minsk",
    label: "(GMT+03:00) Moscow Standard Time - Minsk",
  },
  {
    value: "Europe/Moscow",
    query: "(GMT+03:00) Moscow Standard Time - Moscow",
    label: "(GMT+03:00) Moscow Standard Time - Moscow",
  },
  {
    value: "Europe/Simferopol",
    query: "(GMT+03:00) Moscow Standard Time - Simferopol",
    label: "(GMT+03:00) Moscow Standard Time - Simferopol",
  },
  {
    value: "Antarctica/Syowa",
    query: "(GMT+03:00) Syowa Time",
    label: "(GMT+03:00) Syowa Time",
  },
  {
    value: "Europe/Istanbul",
    query: "(GMT+03:00) Turkey Time",
    label: "(GMT+03:00) Turkey Time",
  },
  {
    value: "Europe/Volgograd",
    query: "(GMT+03:00) Volgograd Standard Time",
    label: "(GMT+03:00) Volgograd Standard Time",
  },
  {
    value: "Asia/Yerevan",
    query: "(GMT+04:00) Armenia Standard Time",
    label: "(GMT+04:00) Armenia Standard Time",
  },
  {
    value: "Europe/Astrakhan",
    query: "(GMT+04:00) Astrakhan Time",
    label: "(GMT+04:00) Astrakhan Time",
  },
  {
    value: "Asia/Baku",
    query: "(GMT+04:00) Azerbaijan Standard Time",
    label: "(GMT+04:00) Azerbaijan Standard Time",
  },
  {
    value: "Asia/Tbilisi",
    query: "(GMT+04:00) Georgia Standard Time",
    label: "(GMT+04:00) Georgia Standard Time",
  },
  {
    value: "Asia/Dubai",
    query: "(GMT+04:00) Gulf Standard Time",
    label: "(GMT+04:00) Gulf Standard Time",
  },
  {
    value: "Indian/Mauritius",
    query: "(GMT+04:00) Mauritius Standard Time",
    label: "(GMT+04:00) Mauritius Standard Time",
  },
  {
    value: "Indian/Reunion",
    query: "(GMT+04:00) Réunion Time",
    label: "(GMT+04:00) Réunion Time",
  },
  {
    value: "Europe/Samara",
    query: "(GMT+04:00) Samara Standard Time",
    label: "(GMT+04:00) Samara Standard Time",
  },
  {
    value: "Europe/Saratov",
    query: "(GMT+04:00) Saratov Time",
    label: "(GMT+04:00) Saratov Time",
  },
  {
    value: "Indian/Mahe",
    query: "(GMT+04:00) Seychelles Time",
    label: "(GMT+04:00) Seychelles Time",
  },
  {
    value: "Europe/Ulyanovsk",
    query: "(GMT+04:00) Ulyanovsk Time",
    label: "(GMT+04:00) Ulyanovsk Time",
  },
  {
    value: "Asia/Kabul",
    query: "(GMT+04:30) Afghanistan Time",
    label: "(GMT+04:30) Afghanistan Time",
  },
  {
    value: "Asia/Tehran",
    query: "(GMT+04:30) Iran Time",
    label: "(GMT+04:30) Iran Time",
  },
  {
    value: "Indian/Kerguelen",
    query: "(GMT+05:00) French Southern &amp; Antarctic Time",
    label: "(GMT+05:00) French Southern &amp; Antarctic Time",
  },
  {
    value: "Indian/Maldives",
    query: "(GMT+05:00) Maldives Time",
    label: "(GMT+05:00) Maldives Time",
  },
  {
    value: "Antarctica/Mawson",
    query: "(GMT+05:00) Mawson Time",
    label: "(GMT+05:00) Mawson Time",
  },
  {
    value: "Asia/Karachi",
    query: "(GMT+05:00) Pakistan Standard Time",
    label: "(GMT+05:00) Pakistan Standard Time",
  },
  {
    value: "Asia/Dushanbe",
    query: "(GMT+05:00) Tajikistan Time",
    label: "(GMT+05:00) Tajikistan Time",
  },
  {
    value: "Asia/Ashgabat",
    query: "(GMT+05:00) Turkmenistan Standard Time",
    label: "(GMT+05:00) Turkmenistan Standard Time",
  },
  {
    value: "Asia/Samarkand",
    query: "(GMT+05:00) Uzbekistan Standard Time - Samarkand",
    label: "(GMT+05:00) Uzbekistan Standard Time - Samarkand",
  },
  {
    value: "Asia/Tashkent",
    query: "(GMT+05:00) Uzbekistan Standard Time - Tashkent",
    label: "(GMT+05:00) Uzbekistan Standard Time - Tashkent",
  },
  {
    value: "Asia/Aqtau",
    query: "(GMT+05:00) West Kazakhstan Time - Aqtau",
    label: "(GMT+05:00) West Kazakhstan Time - Aqtau",
  },
  {
    value: "Asia/Aqtobe",
    query: "(GMT+05:00) West Kazakhstan Time - Aqtobe",
    label: "(GMT+05:00) West Kazakhstan Time - Aqtobe",
  },
  {
    value: "Asia/Atyrau",
    query: "(GMT+05:00) West Kazakhstan Time - Atyrau",
    label: "(GMT+05:00) West Kazakhstan Time - Atyrau",
  },
  {
    value: "Asia/Oral",
    query: "(GMT+05:00) West Kazakhstan Time - Oral",
    label: "(GMT+05:00) West Kazakhstan Time - Oral",
  },
  {
    value: "Asia/Qyzylorda",
    query: "(GMT+05:00) West Kazakhstan Time - Qyzylorda",
    label: "(GMT+05:00) West Kazakhstan Time - Qyzylorda",
  },
  {
    value: "Asia/Yekaterinburg",
    query: "(GMT+05:00) Yekaterinburg Standard Time",
    label: "(GMT+05:00) Yekaterinburg Standard Time",
  },
  {
    value: "Asia/Colombo",
    query: "(GMT+05:30) India Standard Time - Colombo",
    label: "(GMT+05:30) India Standard Time - Colombo",
  },
  {
    value: "Asia/Kolkata",
    query: "(GMT+05:30) India Standard Time - Kolkata",
    label: "(GMT+05:30) India Standard Time - Kolkata",
  },
  {
    value: "Asia/Kathmandu",
    query: "(GMT+05:45) Nepal Time",
    label: "(GMT+05:45) Nepal Time",
  },
  {
    value: "Asia/Dhaka",
    query: "(GMT+06:00) Bangladesh Standard Time",
    label: "(GMT+06:00) Bangladesh Standard Time",
  },
  {
    value: "Asia/Thimphu",
    query: "(GMT+06:00) Bhutan Time",
    label: "(GMT+06:00) Bhutan Time",
  },
  {
    value: "Asia/Almaty",
    query: "(GMT+06:00) East Kazakhstan Time - Almaty",
    label: "(GMT+06:00) East Kazakhstan Time - Almaty",
  },
  {
    value: "Asia/Qostanay",
    query: "(GMT+06:00) East Kazakhstan Time - Kostanay",
    label: "(GMT+06:00) East Kazakhstan Time - Kostanay",
  },
  {
    value: "Indian/Chagos",
    query: "(GMT+06:00) Indian Ocean Time",
    label: "(GMT+06:00) Indian Ocean Time",
  },
  {
    value: "Asia/Bishkek",
    query: "(GMT+06:00) Kyrgyzstan Time",
    label: "(GMT+06:00) Kyrgyzstan Time",
  },
  {
    value: "Asia/Omsk",
    query: "(GMT+06:00) Omsk Standard Time",
    label: "(GMT+06:00) Omsk Standard Time",
  },
  {
    value: "Asia/Urumqi",
    query: "(GMT+06:00) Urumqi Time",
    label: "(GMT+06:00) Urumqi Time",
  },
  {
    value: "Antarctica/Vostok",
    query: "(GMT+06:00) Vostok Time",
    label: "(GMT+06:00) Vostok Time",
  },
  {
    value: "Indian/Cocos",
    query: "(GMT+06:30) Cocos Islands Time",
    label: "(GMT+06:30) Cocos Islands Time",
  },
  {
    value: "Asia/Yangon",
    query: "(GMT+06:30) Myanmar Time",
    label: "(GMT+06:30) Myanmar Time",
  },
  {
    value: "Asia/Barnaul",
    query: "(GMT+07:00) Barnaul Time",
    label: "(GMT+07:00) Barnaul Time",
  },
  {
    value: "Indian/Christmas",
    query: "(GMT+07:00) Christmas Island Time",
    label: "(GMT+07:00) Christmas Island Time",
  },
  {
    value: "Antarctica/Davis",
    query: "(GMT+07:00) Davis Time",
    label: "(GMT+07:00) Davis Time",
  },
  {
    value: "Asia/Hovd",
    query: "(GMT+07:00) Hovd Standard Time",
    label: "(GMT+07:00) Hovd Standard Time",
  },
  {
    value: "Asia/Bangkok",
    query: "(GMT+07:00) Indochina Time - Bangkok",
    label: "(GMT+07:00) Indochina Time - Bangkok",
  },
  {
    value: "Asia/Ho_Chi_Minh",
    query: "(GMT+07:00) Indochina Time - Ho Chi Minh City",
    label: "(GMT+07:00) Indochina Time - Ho Chi Minh City",
  },
  {
    value: "Asia/Krasnoyarsk",
    query: "(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk",
    label: "(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk",
  },
  {
    value: "Asia/Novokuznetsk",
    query: "(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk",
    label: "(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk",
  },
  {
    value: "Asia/Novosibirsk",
    query: "(GMT+07:00) Novosibirsk Standard Time",
    label: "(GMT+07:00) Novosibirsk Standard Time",
  },
  {
    value: "Asia/Tomsk",
    query: "(GMT+07:00) Tomsk Time",
    label: "(GMT+07:00) Tomsk Time",
  },
  {
    value: "Asia/Jakarta",
    query: "(GMT+07:00) Western Indonesia Time - Jakarta",
    label: "(GMT+07:00) Western Indonesia Time - Jakarta",
  },
  {
    value: "Asia/Pontianak",
    query: "(GMT+07:00) Western Indonesia Time - Pontianak",
    label: "(GMT+07:00) Western Indonesia Time - Pontianak",
  },
  {
    value: "Australia/Perth",
    query: "(GMT+08:00) Australian Western Standard Time",
    label: "(GMT+08:00) Australian Western Standard Time",
  },
  {
    value: "Asia/Brunei",
    query: "(GMT+08:00) Brunei Darussalam Time",
    label: "(GMT+08:00) Brunei Darussalam Time",
  },
  {
    value: "Asia/Makassar",
    query: "(GMT+08:00) Central Indonesia Time",
    label: "(GMT+08:00) Central Indonesia Time",
  },
  {
    value: "Asia/Macau",
    query: "(GMT+08:00) China Standard Time - Macao",
    label: "(GMT+08:00) China Standard Time - Macao",
  },
  {
    value: "Asia/Shanghai",
    query: "(GMT+08:00) China Standard Time - Shanghai",
    label: "(GMT+08:00) China Standard Time - Shanghai",
  },
  {
    value: "Asia/Hong_Kong",
    query: "(GMT+08:00) Hong Kong Standard Time",
    label: "(GMT+08:00) Hong Kong Standard Time",
  },
  {
    value: "Asia/Irkutsk",
    query: "(GMT+08:00) Irkutsk Standard Time",
    label: "(GMT+08:00) Irkutsk Standard Time",
  },
  {
    value: "Asia/Kuala_Lumpur",
    query: "(GMT+08:00) Malaysia Time - Kuala Lumpur",
    label: "(GMT+08:00) Malaysia Time - Kuala Lumpur",
  },
  {
    value: "Asia/Kuching",
    query: "(GMT+08:00) Malaysia Time - Kuching",
    label: "(GMT+08:00) Malaysia Time - Kuching",
  },
  {
    value: "Asia/Manila",
    query: "(GMT+08:00) Philippine Standard Time",
    label: "(GMT+08:00) Philippine Standard Time",
  },
  {
    value: "Asia/Singapore",
    query: "(GMT+08:00) Singapore Standard Time",
    label: "(GMT+08:00) Singapore Standard Time",
  },
  {
    value: "Asia/Taipei",
    query: "(GMT+08:00) Taipei Standard Time",
    label: "(GMT+08:00) Taipei Standard Time",
  },
  {
    value: "Asia/Choibalsan",
    query: "(GMT+08:00) Ulaanbaatar Standard Time - Choibalsan",
    label: "(GMT+08:00) Ulaanbaatar Standard Time - Choibalsan",
  },
  {
    value: "Asia/Ulaanbaatar",
    query: "(GMT+08:00) Ulaanbaatar Standard Time - Ulaanbaatar",
    label: "(GMT+08:00) Ulaanbaatar Standard Time - Ulaanbaatar",
  },
  {
    value: "Australia/Eucla",
    query: "(GMT+08:45) Australian Central Western Standard Time",
    label: "(GMT+08:45) Australian Central Western Standard Time",
  },
  {
    value: "Asia/Dili",
    query: "(GMT+09:00) East Timor Time",
    label: "(GMT+09:00) East Timor Time",
  },
  {
    value: "Asia/Jayapura",
    query: "(GMT+09:00) Eastern Indonesia Time",
    label: "(GMT+09:00) Eastern Indonesia Time",
  },
  {
    value: "Asia/Tokyo",
    query: "(GMT+09:00) Japan Standard Time",
    label: "(GMT+09:00) Japan Standard Time",
  },
  {
    value: "Asia/Pyongyang",
    query: "(GMT+09:00) Korean Standard Time - Pyongyang",
    label: "(GMT+09:00) Korean Standard Time - Pyongyang",
  },
  {
    value: "Asia/Seoul",
    query: "(GMT+09:00) Korean Standard Time - Seoul",
    label: "(GMT+09:00) Korean Standard Time - Seoul",
  },
  {
    value: "Pacific/Palau",
    query: "(GMT+09:00) Palau Time",
    label: "(GMT+09:00) Palau Time",
  },
  {
    value: "Asia/Chita",
    query: "(GMT+09:00) Yakutsk Standard Time - Chita",
    label: "(GMT+09:00) Yakutsk Standard Time - Chita",
  },
  {
    value: "Asia/Khandyga",
    query: "(GMT+09:00) Yakutsk Standard Time - Khandyga",
    label: "(GMT+09:00) Yakutsk Standard Time - Khandyga",
  },
  {
    value: "Asia/Yakutsk",
    query: "(GMT+09:00) Yakutsk Standard Time - Yakutsk",
    label: "(GMT+09:00) Yakutsk Standard Time - Yakutsk",
  },
  {
    value: "Australia/Darwin",
    query: "(GMT+09:30) Australian Central Standard Time",
    label: "(GMT+09:30) Australian Central Standard Time",
  },
  {
    value: "Australia/Adelaide",
    query: "(GMT+09:30) Central Australia Time - Adelaide",
    label: "(GMT+09:30) Central Australia Time - Adelaide",
  },
  {
    value: "Australia/Broken_Hill",
    query: "(GMT+09:30) Central Australia Time - Broken Hill",
    label: "(GMT+09:30) Central Australia Time - Broken Hill",
  },
  {
    value: "Australia/Brisbane",
    query: "(GMT+10:00) Australian Eastern Standard Time - Brisbane",
    label: "(GMT+10:00) Australian Eastern Standard Time - Brisbane",
  },
  {
    value: "Australia/Lindeman",
    query: "(GMT+10:00) Australian Eastern Standard Time - Lindeman",
    label: "(GMT+10:00) Australian Eastern Standard Time - Lindeman",
  },
  {
    value: "Pacific/Guam",
    query: "(GMT+10:00) Chamorro Standard Time",
    label: "(GMT+10:00) Chamorro Standard Time",
  },
  {
    value: "Pacific/Chuuk",
    query: "(GMT+10:00) Chuuk Time",
    label: "(GMT+10:00) Chuuk Time",
  },
  {
    value: "Antarctica/DumontDUrville",
    query: "(GMT+10:00) Dumont-d’Urville Time",
    label: "(GMT+10:00) Dumont-d’Urville Time",
  },
  {
    value: "Australia/Hobart",
    query: "(GMT+10:00) Eastern Australia Time - Hobart",
    label: "(GMT+10:00) Eastern Australia Time - Hobart",
  },
  {
    value: "Antarctica/Macquarie",
    query: "(GMT+10:00) Eastern Australia Time - Macquarie",
    label: "(GMT+10:00) Eastern Australia Time - Macquarie",
  },
  {
    value: "Australia/Melbourne",
    query: "(GMT+10:00) Eastern Australia Time - Melbourne",
    label: "(GMT+10:00) Eastern Australia Time - Melbourne",
  },
  {
    value: "Australia/Sydney",
    query: "(GMT+10:00) Eastern Australia Time - Sydney",
    label: "(GMT+10:00) Eastern Australia Time - Sydney",
  },
  {
    value: "Pacific/Port_Moresby",
    query: "(GMT+10:00) Papua New Guinea Time",
    label: "(GMT+10:00) Papua New Guinea Time",
  },
  {
    value: "Asia/Ust-Nera",
    query: "(GMT+10:00) Vladivostok Standard Time - Ust-Nera",
    label: "(GMT+10:00) Vladivostok Standard Time - Ust-Nera",
  },
  {
    value: "Asia/Vladivostok",
    query: "(GMT+10:00) Vladivostok Standard Time - Vladivostok",
    label: "(GMT+10:00) Vladivostok Standard Time - Vladivostok",
  },
  {
    value: "Australia/Lord_Howe",
    query: "(GMT+10:30) Lord Howe Time",
    label: "(GMT+10:30) Lord Howe Time",
  },
  {
    value: "Pacific/Bougainville",
    query: "(GMT+11:00) Bougainville Time",
    label: "(GMT+11:00) Bougainville Time",
  },
  {
    value: "Antarctica/Casey",
    query: "(GMT+11:00) Casey Time",
    label: "(GMT+11:00) Casey Time",
  },
  {
    value: "Pacific/Kosrae",
    query: "(GMT+11:00) Kosrae Time",
    label: "(GMT+11:00) Kosrae Time",
  },
  {
    value: "Asia/Magadan",
    query: "(GMT+11:00) Magadan Standard Time",
    label: "(GMT+11:00) Magadan Standard Time",
  },
  {
    value: "Pacific/Noumea",
    query: "(GMT+11:00) New Caledonia Standard Time",
    label: "(GMT+11:00) New Caledonia Standard Time",
  },
  {
    value: "Pacific/Norfolk",
    query: "(GMT+11:00) Norfolk Island Time",
    label: "(GMT+11:00) Norfolk Island Time",
  },
  {
    value: "Pacific/Pohnpei",
    query: "(GMT+11:00) Ponape Time",
    label: "(GMT+11:00) Ponape Time",
  },
  {
    value: "Asia/Sakhalin",
    query: "(GMT+11:00) Sakhalin Standard Time",
    label: "(GMT+11:00) Sakhalin Standard Time",
  },
  {
    value: "Pacific/Guadalcanal",
    query: "(GMT+11:00) Solomon Islands Time",
    label: "(GMT+11:00) Solomon Islands Time",
  },
  {
    value: "Asia/Srednekolymsk",
    query: "(GMT+11:00) Srednekolymsk Time",
    label: "(GMT+11:00) Srednekolymsk Time",
  },
  {
    value: "Pacific/Efate",
    query: "(GMT+11:00) Vanuatu Standard Time",
    label: "(GMT+11:00) Vanuatu Standard Time",
  },
  {
    value: "Asia/Anadyr",
    query: "(GMT+12:00) Anadyr Standard Time",
    label: "(GMT+12:00) Anadyr Standard Time",
  },
  {
    value: "Pacific/Fiji",
    query: "(GMT+12:00) Fiji Time",
    label: "(GMT+12:00) Fiji Time",
  },
  {
    value: "Pacific/Tarawa",
    query: "(GMT+12:00) Gilbert Islands Time",
    label: "(GMT+12:00) Gilbert Islands Time",
  },
  {
    value: "Pacific/Kwajalein",
    query: "(GMT+12:00) Marshall Islands Time - Kwajalein",
    label: "(GMT+12:00) Marshall Islands Time - Kwajalein",
  },
  {
    value: "Pacific/Majuro",
    query: "(GMT+12:00) Marshall Islands Time - Majuro",
    label: "(GMT+12:00) Marshall Islands Time - Majuro",
  },
  {
    value: "Pacific/Nauru",
    query: "(GMT+12:00) Nauru Time",
    label: "(GMT+12:00) Nauru Time",
  },
  {
    value: "Pacific/Auckland",
    query: "(GMT+12:00) New Zealand Time",
    label: "(GMT+12:00) New Zealand Time",
  },
  {
    value: "Asia/Kamchatka",
    query: "(GMT+12:00) Petropavlovsk-Kamchatski Standard Time",
    label: "(GMT+12:00) Petropavlovsk-Kamchatski Standard Time",
  },
  {
    value: "Pacific/Funafuti",
    query: "(GMT+12:00) Tuvalu Time",
    label: "(GMT+12:00) Tuvalu Time",
  },
  {
    value: "Pacific/Wake",
    query: "(GMT+12:00) Wake Island Time",
    label: "(GMT+12:00) Wake Island Time",
  },
  {
    value: "Pacific/Wallis",
    query: "(GMT+12:00) Wallis &amp; Futuna Time",
    label: "(GMT+12:00) Wallis &amp; Futuna Time",
  },
  {
    value: "Pacific/Chatham",
    query: "(GMT+12:45) Chatham Time",
    label: "(GMT+12:45) Chatham Time",
  },
  {
    value: "Pacific/Apia",
    query: "(GMT+13:00) Apia Time",
    label: "(GMT+13:00) Apia Time",
  },
  {
    value: "Pacific/Enderbury",
    query: "(GMT+13:00) Phoenix Islands Time",
    label: "(GMT+13:00) Phoenix Islands Time",
  },
  {
    value: "Pacific/Fakaofo",
    query: "(GMT+13:00) Tokelau Time",
    label: "(GMT+13:00) Tokelau Time",
  },
  {
    value: "Pacific/Tongatapu",
    query: "(GMT+13:00) Tonga Standard Time",
    label: "(GMT+13:00) Tonga Standard Time",
  },
  {
    value: "Pacific/Kiritimati",
    query: "(GMT+14:00) Line Islands Time",
    label: "(GMT+14:00) Line Islands Time",
  },
];

// const timezones = [
//   "Africa/Abidjan",
//   "Africa/Accra",
//   "Africa/Addis_Ababa",
//   "Africa/Algiers",
//   "Africa/Asmara",
//   "Africa/Bamako",
//   "Africa/Bangui",
//   "Africa/Banjul",
//   "Africa/Bissau",
//   "Africa/Blantyre",
//   "Africa/Brazzaville",
//   "Africa/Bujumbura",
//   "Africa/Cairo",
//   "Africa/Casablanca",
//   "Africa/Ceuta",
//   "Africa/Conakry",
//   "Africa/Dakar",
//   "Africa/Dar_es_Salaam",
//   "Africa/Djibouti",
//   "Africa/Douala",
//   "Africa/El_Aaiun",
//   "Africa/Freetown",
//   "Africa/Gaborone",
//   "Africa/Harare",
//   "Africa/Johannesburg",
//   "Africa/Juba",
//   "Africa/Kampala",
//   "Africa/Khartoum",
//   "Africa/Kigali",
//   "Africa/Kinshasa",
//   "Africa/Lagos",
//   "Africa/Libreville",
//   "Africa/Lome",
//   "Africa/Luanda",
//   "Africa/Lubumbashi",
//   "Africa/Lusaka",
//   "Africa/Malabo",
//   "Africa/Maputo",
//   "Africa/Maseru",
//   "Africa/Mbabane",
//   "Africa/Mogadishu",
//   "Africa/Monrovia",
//   "Africa/Nairobi",
//   "Africa/Ndjamena",
//   "Africa/Niamey",
//   "Africa/Nouakchott",
//   "Africa/Ouagadougou",
//   "Africa/Porto-Novo",
//   "Africa/Sao_Tome",
//   "Africa/Tripoli",
//   "Africa/Tunis",
//   "Africa/Windhoek",
//   "America/Adak",
//   "America/Anchorage",
//   "America/Anguilla",
//   "America/Antigua",
//   "America/Araguaina",
//   "America/Argentina/Buenos_Aires",
//   "America/Argentina/Catamarca",
//   "America/Argentina/Cordoba",
//   "America/Argentina/Jujuy",
//   "America/Argentina/La_Rioja",
//   "America/Argentina/Mendoza",
//   "America/Argentina/Rio_Gallegos",
//   "America/Argentina/Salta",
//   "America/Argentina/San_Juan",
//   "America/Argentina/San_Luis",
//   "America/Argentina/Tucuman",
//   "America/Argentina/Ushuaia",
//   "America/Aruba",
//   "America/Asuncion",
//   "America/Atikokan",
//   "America/Bahia",
//   "America/Bahia_Banderas",
//   "America/Barbados",
//   "America/Belem",
//   "America/Belize",
//   "America/Blanc-Sablon",
//   "America/Boa_Vista",
//   "America/Bogota",
//   "America/Boise",
//   "America/Cambridge_Bay",
//   "America/Campo_Grande",
//   "America/Cancun",
//   "America/Caracas",
//   "America/Cayenne",
//   "America/Cayman",
//   "America/Chicago",
//   "America/Chihuahua",
//   "America/Costa_Rica",
//   "America/Creston",
//   "America/Cuiaba",
//   "America/Curacao",
//   "America/Danmarkshavn",
//   "America/Dawson",
//   "America/Dawson_Creek",
//   "America/Denver",
//   "America/Detroit",
//   "America/Dominica",
//   "America/Edmonton",
//   "America/Eirunepe",
//   "America/El_Salvador",
//   "America/Fort_Nelson",
//   "America/Fortaleza",
//   "America/Glace_Bay",
//   "America/Godthab",
//   "America/Goose_Bay",
//   "America/Grand_Turk",
//   "America/Grenada",
//   "America/Guadeloupe",
//   "America/Guatemala",
//   "America/Guayaquil",
//   "America/Guyana",
//   "America/Halifax",
//   "America/Havana",
//   "America/Hermosillo",
//   "America/Indiana/Indianapolis",
//   "America/Indiana/Knox",
//   "America/Indiana/Marengo",
//   "America/Indiana/Petersburg",
//   "America/Indiana/Tell_City",
//   "America/Indiana/Vevay",
//   "America/Indiana/Vincennes",
//   "America/Indiana/Winamac",
//   "America/Inuvik",
//   "America/Iqaluit",
//   "America/Jamaica",
//   "America/Juneau",
//   "America/Kentucky/Louisville",
//   "America/Kentucky/Monticello",
//   "America/Kralendijk",
//   "America/La_Paz",
//   "America/Lima",
//   "America/Los_Angeles",
//   "America/Lower_Princes",
//   "America/Maceio",
//   "America/Managua",
//   "America/Manaus",
//   "America/Marigot",
//   "America/Martinique",
//   "America/Matamoros",
//   "America/Mazatlan",
//   "America/Menominee",
//   "America/Merida",
//   "America/Metlakatla",
//   "America/Mexico_City",
//   "America/Miquelon",
//   "America/Moncton",
//   "America/Monterrey",
//   "America/Montevideo",
//   "America/Montserrat",
//   "America/Nassau",
//   "America/New_York",
//   "America/Nipigon",
//   "America/Nome",
//   "America/Noronha",
//   "America/North_Dakota/Beulah",
//   "America/North_Dakota/Center",
//   "America/North_Dakota/New_Salem",
//   "America/Ojinaga",
//   "America/Panama",
//   "America/Pangnirtung",
//   "America/Paramaribo",
//   "America/Phoenix",
//   "America/Port-au-Prince",
//   "America/Port_of_Spain",
//   "America/Porto_Velho",
//   "America/Puerto_Rico",
//   "America/Punta_Arenas",
//   "America/Rainy_River",
//   "America/Rankin_Inlet",
//   "America/Recife",
//   "America/Regina",
//   "America/Resolute",
//   "America/Rio_Branco",
//   "America/Santarem",
//   "America/Santiago",
//   "America/Santo_Domingo",
//   "America/Sao_Paulo",
//   "America/Scoresbysund",
//   "America/Sitka",
//   "America/St_Barthelemy",
//   "America/St_Johns",
//   "America/St_Kitts",
//   "America/St_Lucia",
//   "America/St_Thomas",
//   "America/St_Vincent",
//   "America/Swift_Current",
//   "America/Tegucigalpa",
//   "America/Thule",
//   "America/Thunder_Bay",
//   "America/Tijuana",
//   "America/Toronto",
//   "America/Tortola",
//   "America/Vancouver",
//   "America/Whitehorse",
//   "America/Winnipeg",
//   "America/Yakutat",
//   "America/Yellowknife",
//   "Antarctica/Casey",
//   "Antarctica/Davis",
//   "Antarctica/DumontDUrville",
//   "Antarctica/Macquarie",
//   "Antarctica/Mawson",
//   "Antarctica/McMurdo",
//   "Antarctica/Palmer",
//   "Antarctica/Rothera",
//   "Antarctica/Syowa",
//   "Antarctica/Troll",
//   "Antarctica/Vostok",
//   "Arctic/Longyearbyen",
//   "Asia/Aden",
//   "Asia/Almaty",
//   "Asia/Amman",
//   "Asia/Anadyr",
//   "Asia/Aqtau",
//   "Asia/Aqtobe",
//   "Asia/Ashgabat",
//   "Asia/Atyrau",
//   "Asia/Baghdad",
//   "Asia/Bahrain",
//   "Asia/Baku",
//   "Asia/Bangkok",
//   "Asia/Barnaul",
//   "Asia/Beirut",
//   "Asia/Bishkek",
//   "Asia/Brunei",
//   "Asia/Chita",
//   "Asia/Choibalsan",
//   "Asia/Colombo",
//   "Asia/Damascus",
//   "Asia/Dhaka",
//   "Asia/Dili",
//   "Asia/Dubai",
//   "Asia/Dushanbe",
//   "Asia/Famagusta",
//   "Asia/Gaza",
//   "Asia/Hebron",
//   "Asia/Ho_Chi_Minh",
//   "Asia/Hong_Kong",
//   "Asia/Hovd",
//   "Asia/Irkutsk",
//   "Asia/Jakarta",
//   "Asia/Jayapura",
//   "Asia/Jerusalem",
//   "Asia/Kabul",
//   "Asia/Kamchatka",
//   "Asia/Karachi",
//   "Asia/Kathmandu",
//   "Asia/Khandyga",
//   "Asia/Kolkata",
//   "Asia/Krasnoyarsk",
//   "Asia/Kuala_Lumpur",
//   "Asia/Kuching",
//   "Asia/Kuwait",
//   "Asia/Macau",
//   "Asia/Magadan",
//   "Asia/Makassar",
//   "Asia/Manila",
//   "Asia/Muscat",
//   "Asia/Nicosia",
//   "Asia/Novokuznetsk",
//   "Asia/Novosibirsk",
//   "Asia/Omsk",
//   "Asia/Oral",
//   "Asia/Phnom_Penh",
//   "Asia/Pontianak",
//   "Asia/Pyongyang",
//   "Asia/Qatar",
//   "Asia/Qostanay",
//   "Asia/Qyzylorda",
//   "Asia/Riyadh",
//   "Asia/Sakhalin",
//   "Asia/Samarkand",
//   "Asia/Seoul",
//   "Asia/Shanghai",
//   "Asia/Singapore",
//   "Asia/Srednekolymsk",
//   "Asia/Taipei",
//   "Asia/Tashkent",
//   "Asia/Tbilisi",
//   "Asia/Tehran",
//   "Asia/Thimphu",
//   "Asia/Tokyo",
//   "Asia/Tomsk",
//   "Asia/Ulaanbaatar",
//   "Asia/Urumqi",
//   "Asia/Ust-Nera",
//   "Asia/Vientiane",
//   "Asia/Vladivostok",
//   "Asia/Yakutsk",
//   "Asia/Yangon",
//   "Asia/Yekaterinburg",
//   "Asia/Yerevan",
//   "Atlantic/Azores",
//   "Atlantic/Bermuda",
//   "Atlantic/Canary",
//   "Atlantic/Cape_Verde",
//   "Atlantic/Faroe",
//   "Atlantic/Madeira",
//   "Atlantic/Reykjavik",
//   "Atlantic/South_Georgia",
//   "Atlantic/St_Helena",
//   "Atlantic/Stanley",
//   "Australia/Adelaide",
//   "Australia/Brisbane",
//   "Australia/Broken_Hill",
//   "Australia/Currie",
//   "Australia/Darwin",
//   "Australia/Eucla",
//   "Australia/Hobart",
//   "Australia/Lindeman",
//   "Australia/Lord_Howe",
//   "Australia/Melbourne",
//   "Australia/Perth",
//   "Australia/Sydney",
//   "Europe/Amsterdam",
//   "Europe/Andorra",
//   "Europe/Astrakhan",
//   "Europe/Athens",
//   "Europe/Belgrade",
//   "Europe/Berlin",
//   "Europe/Bratislava",
//   "Europe/Brussels",
//   "Europe/Bucharest",
//   "Europe/Budapest",
//   "Europe/Busingen",
//   "Europe/Chisinau",
//   "Europe/Copenhagen",
//   "Europe/Dublin",
//   "Europe/Gibraltar",
//   "Europe/Guernsey",
//   "Europe/Helsinki",
//   "Europe/Isle_of_Man",
//   "Europe/Istanbul",
//   "Europe/Jersey",
//   "Europe/Kaliningrad",
//   "Europe/Kiev",
//   "Europe/Kirov",
//   "Europe/Lisbon",
//   "Europe/Ljubljana",
//   "Europe/London",
//   "Europe/Luxembourg",
//   "Europe/Madrid",
//   "Europe/Malta",
//   "Europe/Mariehamn",
//   "Europe/Minsk",
//   "Europe/Monaco",
//   "Europe/Moscow",
//   "Europe/Oslo",
//   "Europe/Paris",
//   "Europe/Podgorica",
//   "Europe/Prague",
//   "Europe/Riga",
//   "Europe/Rome",
//   "Europe/Samara",
//   "Europe/San_Marino",
//   "Europe/Sarajevo",
//   "Europe/Saratov",
//   "Europe/Simferopol",
//   "Europe/Skopje",
//   "Europe/Sofia",
//   "Europe/Stockholm",
//   "Europe/Tallinn",
//   "Europe/Tirane",
//   "Europe/Ulyanovsk",
//   "Europe/Uzhgorod",
//   "Europe/Vaduz",
//   "Europe/Vatican",
//   "Europe/Vienna",
//   "Europe/Vilnius",
//   "Europe/Volgograd",
//   "Europe/Warsaw",
//   "Europe/Zagreb",
//   "Europe/Zaporozhye",
//   "Europe/Zurich",
//   "Indian/Antananarivo",
//   "Indian/Chagos",
//   "Indian/Christmas",
//   "Indian/Cocos",
//   "Indian/Comoro",
//   "Indian/Kerguelen",
//   "Indian/Mahe",
//   "Indian/Maldives",
//   "Indian/Mauritius",
//   "Indian/Mayotte",
//   "Indian/Reunion",
//   "Pacific/Apia",
//   "Pacific/Auckland",
//   "Pacific/Bougainville",
//   "Pacific/Chatham",
//   "Pacific/Chuuk",
//   "Pacific/Easter",
//   "Pacific/Efate",
//   "Pacific/Enderbury",
//   "Pacific/Fakaofo",
//   "Pacific/Fiji",
//   "Pacific/Funafuti",
//   "Pacific/Galapagos",
//   "Pacific/Gambier",
//   "Pacific/Guadalcanal",
//   "Pacific/Guam",
//   "Pacific/Honolulu",
//   "Pacific/Kiritimati",
//   "Pacific/Kosrae",
//   "Pacific/Kwajalein",
//   "Pacific/Majuro",
//   "Pacific/Marquesas",
//   "Pacific/Midway",
//   "Pacific/Nauru",
//   "Pacific/Niue",
//   "Pacific/Norfolk",
//   "Pacific/Noumea",
//   "Pacific/Pago_Pago",
//   "Pacific/Palau",
//   "Pacific/Pitcairn",
//   "Pacific/Pohnpei",
//   "Pacific/Port_Moresby",
//   "Pacific/Rarotonga",
//   "Pacific/Saipan",
//   "Pacific/Tahiti",
//   "Pacific/Tarawa",
//   "Pacific/Tongatapu",
//   "Pacific/Wake",
//   "Pacific/Wallis",
// ];

export default timezones;
