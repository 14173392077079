import { AspectRatio } from "@chakra-ui/react";
import { FC } from "react";

interface YoutubeIframeProps {
  youtubeId: string;
  autoplay?: boolean;
}

const YoutubeIframe: FC<YoutubeIframeProps> = ({ youtubeId, autoplay }) => {
  return (
    <AspectRatio ratio={1.778}>
      <iframe
        src={`https://www.youtube.com/embed/${youtubeId}?feature=oembed&controls=1&hd=1&autohide=1&modestbranding=1&rel=0&autoplay=${
          autoplay ? 1 : 0
        }`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video"
      />
    </AspectRatio>
  );
};

export default YoutubeIframe;
