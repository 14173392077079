import { useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";

import { useCompanyQuery } from "../graphql/graphql";
import { currentCompanyPlanVar } from "../graphql/reactiveVariables";

const useCompanySettingsProgress = () => {
  const { data } = useCompanyQuery();
  const [percent, setPercent] = useState(0);
  const plan = useReactiveVar(currentCompanyPlanVar);
  const isProfessionalPlan = plan?.productName === "Professional";

  useEffect(() => {
    if (data) {
      let localPercent = 0;
      const unitPercentage = isProfessionalPlan ? 12.5 : 14.28;
      const company = data.company;
      if (company.name) localPercent += unitPercentage;
      if (company.address1) localPercent += unitPercentage;
      if (company.companyCompanyUsers.length > 0)
        localPercent += unitPercentage;
      if (company.email) localPercent += unitPercentage;
      if (company.phone) localPercent += unitPercentage;
      if (company.website) localPercent += unitPercentage;
      if (company.industry) localPercent += unitPercentage;
      if (isProfessionalPlan && company.businessType)
        localPercent += unitPercentage;
      setPercent(Math.round(localPercent));
    }
  }, [data, percent, isProfessionalPlan]);

  return percent;
};

export default useCompanySettingsProgress;
