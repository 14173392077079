import {
  Box,
  BoxProps,
  Button,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import { formatPhoneNumber } from "react-phone-number-input";

import InviteIcon from "../../../components/icons/invite";
import PhoneIcon from "../../../components/icons/phone";
import UserIcon from "../../../components/icons/user";
import { CompanyUserWithUserFragmentFragment } from "../../../graphql/graphql";
import TooltipName from "../tooltipName";

interface ContactUserInfoProps {
  contactCompanyUsers: CompanyUserWithUserFragmentFragment[];
  textProps?: BoxProps;
}

const ContactUserInfo: React.FC<ContactUserInfoProps> = ({
  contactCompanyUsers,
  textProps = { fontWeight: "600", color: "gray.900" },
}) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {contactCompanyUsers.map((cu, i) => {
        return (
          <Flex align="center" marginTop="2" key={cu.id}>
            <Popover
              key={cu.id}
              trigger="hover"
              size="sm"
              isLazy
              returnFocusOnClose
              autoFocus
            >
              <PopoverTrigger>
                <Button
                  fontSize="md"
                  variant="link"
                  colorScheme="gray"
                  maxW="160px"
                  color="gray.900"
                  {...(textProps as any)}
                >
                  <TooltipName name={cu.user.fullName || cu.user.email} />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody fontSize="xs">
                  {cu.user.fullName ? (
                    <Box marginY="2">
                      <UserIcon boxSize="4" color="gray.700" marginRight="2" />
                      {cu.user.fullName}{" "}
                      {cu.user.jobTitle ? `(${cu.user.jobTitle})` : ""}
                    </Box>
                  ) : (
                    !!cu.user.jobTitle && (
                      <Box marginY="2">
                        <UserIcon
                          boxSize="4"
                          color="gray.700"
                          marginRight="2"
                        />
                        {cu.user.jobTitle}
                      </Box>
                    )
                  )}
                  {!!cu.user.email && (
                    <Box marginY="2">
                      <Link href={`mailto:${cu.user.email}`} isExternal>
                        <InviteIcon
                          boxSize="4"
                          color="gray.700"
                          marginRight="2"
                        />
                        {cu.user.email}
                      </Link>
                    </Box>
                  )}
                  {!!cu.user.phone && (
                    <Box marginY="2">
                      <Link href={`tel:${cu.user.phone}`} isExternal>
                        <PhoneIcon
                          boxSize="4"
                          color="gray.700"
                          marginRight="2"
                        />
                        {cu.user.phone ? formatPhoneNumber(cu.user.phone) : ""}
                      </Link>
                    </Box>
                  )}
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {i + 1 < contactCompanyUsers.length ? (
              i + 2 === contactCompanyUsers.length ? (
                <Box mx="1" {...textProps}>
                  and
                </Box>
              ) : (
                <Box mr="1" {...textProps}>
                  ,
                </Box>
              )
            ) : null}
          </Flex>
        );
      })}
    </Box>
  );
};

export default ContactUserInfo;
