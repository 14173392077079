import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  List,
  ListItem,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";

import Footer from "../../components/elements/footer";
import Link from "../../components/elements/link";
import Spinner from "../../components/elements/spinner";
import AuthenticationIcon from "../../components/icons/authentication";
import InviteIcon from "../../components/icons/invite";
import LockIcon from "../../components/icons/lock";
import PhoneIcon from "../../components/icons/phone";
import {
  EMAIL_VERIFICATION_LINK_SENT,
  EMAIL_VERIFIED,
  GENERIC_SAVED_MESSAGE,
} from "../../constants/lang/en";
import {
  UserFragmentFragment,
  useToggleTwoFactorMutation,
  useUserEmailConfirmMutation,
} from "../../graphql/graphql";
import useUserSettingsProgress from "../../hooks/useUserSettingsProgress";
import { getRoutePath } from "../../router";
import { setGenericMessage } from "../../utils/serverErrors";

interface CompanySettingsPagePresenterProps {
  user: UserFragmentFragment;
}

const CompanySettingsPagePresenter: React.FC<
  CompanySettingsPagePresenterProps
> = ({ user }) => {
  const profileCompletedPercentage = useUserSettingsProgress();
  const toast = useToast();
  const [userEmailConfirmMutation, { loading }] = useUserEmailConfirmMutation();
  const name = user.fullName;
  const [
    initialProfileCompletedPercentage,
    setInitialProfileCompletedPercentage,
  ] = useState(profileCompletedPercentage);
  useEffect(() => {
    if (profileCompletedPercentage && !initialProfileCompletedPercentage) {
      setInitialProfileCompletedPercentage(profileCompletedPercentage);
    }
  }, [profileCompletedPercentage, initialProfileCompletedPercentage]);

  const handleEmailConfirmClick = async () => {
    try {
      const { errors, data } = await userEmailConfirmMutation();
      if (errors) {
        throw errors;
      } else {
        toast({
          description: data?.userEmailConfirm.emailConfirmed
            ? EMAIL_VERIFIED
            : EMAIL_VERIFICATION_LINK_SENT,
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const [toggleTwoFactorMutation, { loading: twoFactorLoading }] =
    useToggleTwoFactorMutation();

  const toggleTwoFactor = useCallback(async () => {
    try {
      const { errors } = await toggleTwoFactorMutation({
        variables: { twoFactorEnabled: !user.twoFactorEnabled },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  }, [toast, toggleTwoFactorMutation, user.twoFactorEnabled]);

  return (
    <Box paddingBottom="8">
      <Flex alignItems="center" justifyContent="flex-end">
        {(initialProfileCompletedPercentage !== 100 ||
          profileCompletedPercentage !== 100) && (
          <CircularProgress
            value={profileCompletedPercentage}
            size="40px"
            color="green.500"
            marginX="1"
          >
            <CircularProgressLabel>
              {profileCompletedPercentage}%
            </CircularProgressLabel>
          </CircularProgress>
        )}
        {profileCompletedPercentage === 0 || profileCompletedPercentage === 100
          ? ""
          : " Almost done!"}
      </Flex>
      <List paddingBottom="4">
        <ListItem
          backgroundColor="secondary.10"
          _hover={{ boxShadow: "md" }}
          role="group"
          cursor="pointer"
          marginTop="4"
        >
          <Link
            to={getRoutePath("mySettingsChangeName")}
            variant="link"
            colorScheme="secondary"
            width="full"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="full"
              paddingX={{ base: "2", md: "7" }}
              paddingY={{ base: "6", md: "7" }}
            >
              <Box display="inherit" alignItems="center">
                <Avatar
                  name={name}
                  size="md"
                  backgroundColor="gray.800"
                  color="white"
                />
                <Box marginX="4">
                  <Heading as="h2" size="sm" color="gray.600">
                    Name
                  </Heading>
                  {name ? (
                    <Box as="span" color="gray.800" marginTop="2">
                      {name}
                    </Box>
                  ) : (
                    <Text fontSize="xs">Add your name</Text>
                  )}
                </Box>
              </Box>
              <Tooltip label="Edit Name" hasArrow placement="left">
                <Box
                  as="span"
                  fontSize="sm"
                  color="gray.500"
                  _groupHover={{ color: "gray.600" }}
                >
                  {!!name && <FontAwesomeIcon icon={faPen} />}
                </Box>
              </Tooltip>
            </Flex>
          </Link>
        </ListItem>
        <ListItem
          backgroundColor="secondary.10"
          _hover={{ boxShadow: "md" }}
          role="group"
          cursor="pointer"
          marginTop="4"
          position="relative"
        >
          <Link
            to={getRoutePath("mySettingsChangeEmail")}
            variant="link"
            colorScheme="secondary"
            width="full"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="full"
              paddingX={{ base: "2", md: "7" }}
              paddingY={{ base: "6", md: "7" }}
            >
              <Box display="inherit" alignItems="center">
                <InviteIcon color="secondary.500" />
                <Box marginX="4">
                  <Heading as="h2" size="sm" color="gray.600">
                    Email
                  </Heading>
                  <Box
                    color="gray.800"
                    marginBottom={user && !user.emailConfirmed ? "27px;" : ""}
                  >
                    {user.email}
                  </Box>
                </Box>
              </Box>
              <Tooltip label="Edit Email" hasArrow placement="left">
                <Box
                  as="span"
                  fontSize="sm"
                  color="gray.500"
                  _groupHover={{ color: "gray.600" }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Box>
              </Tooltip>
            </Flex>
          </Link>
          {user && !user.emailConfirmed && (
            <Button
              variant="link"
              colorScheme="red"
              fontSize="xs"
              onClick={handleEmailConfirmClick}
              isLoading={loading}
              position="absolute"
              bottom="35px"
              left={{ base: "48px", md: "68px" }}
            >
              Verify your email
            </Button>
          )}
        </ListItem>
        <ListItem
          backgroundColor="secondary.10"
          _hover={{ boxShadow: "md" }}
          role="group"
          cursor="pointer"
          marginTop="4"
        >
          <Link
            to={getRoutePath("mySettingsChangePhone")}
            variant="link"
            colorScheme="secondary"
            width="full"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="full"
              paddingX={{ base: "2", md: "7" }}
              paddingY={{ base: "6", md: "7" }}
            >
              <Box display="inherit" alignItems="center">
                <PhoneIcon color="secondary.500" />
                <Box marginX="4">
                  <Heading as="h2" size="sm" color="gray.600">
                    Phone
                  </Heading>
                  {user.phone ? (
                    <>
                      <Box color="gray.800">
                        {formatPhoneNumber(user.phone)}
                      </Box>
                      {user && !user.phoneConfirmed && (
                        <Text
                          fontSize="xs"
                          color="red.500"
                          _hover={{ color: "red.600" }}
                        >
                          Verify your phone number
                        </Text>
                      )}
                    </>
                  ) : (
                    <Text fontSize="xs">Add your phone</Text>
                  )}
                </Box>
              </Box>
              <Tooltip label="Edit Phone" hasArrow placement="left">
                <Box
                  as="span"
                  fontSize="sm"
                  color="gray.500"
                  _groupHover={{ color: "gray.600" }}
                >
                  {!!user.phone && <FontAwesomeIcon icon={faPen} />}
                </Box>
              </Tooltip>
            </Flex>
          </Link>
        </ListItem>
        <ListItem
          backgroundColor="secondary.10"
          _hover={{ boxShadow: "md" }}
          role="group"
          cursor="pointer"
          marginTop="4"
        >
          <Link
            to={getRoutePath("mySettingsChangePassword")}
            variant="link"
            colorScheme="gray"
            width="full"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="full"
              paddingX={{ base: "2", md: "7" }}
              paddingY={{ base: "6", md: "7" }}
            >
              <Box display="inherit" alignItems="center">
                <LockIcon color="secondary.500" />
                <Box marginX="4">
                  <Heading as="h2" size="sm" color="gray.600">
                    Password
                  </Heading>
                  <Box as="span" color="gray.800" marginTop="2">
                    * * * * *
                  </Box>
                </Box>
              </Box>
              <Tooltip label="Change Password" hasArrow placement="left">
                <Box
                  as="span"
                  fontSize="sm"
                  color="gray.500"
                  _groupHover={{ color: "gray.600" }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Box>
              </Tooltip>
            </Flex>
          </Link>
        </ListItem>
        <ListItem
          paddingX={{ base: "2", md: "7" }}
          paddingY={{ base: "6", md: "7" }}
          backgroundColor="secondary.10"
          marginTop="4"
        >
          <Flex alignItems="center" justifyContent="space-between" minH="50px">
            <Box display="inherit" alignItems="center" marginRight="4">
              <AuthenticationIcon color="secondary.500" />
              <Box marginX="4">
                <Heading as="h3" size="md" color="gray.800">
                  {user.twoFactorEnabled
                    ? "Two Factor Authentication Enabled"
                    : "Enable Two Factor Authentication"}
                </Heading>
                {user.twoFactorEnabled && (
                  <Text fontSize="xs" color="gray.600" mt="2">
                    For additional security, we'll send a PIN to your mobile
                    phone number when you sign in
                  </Text>
                )}
              </Box>
            </Box>
            <Box as="span" fontSize="sm">
              {twoFactorLoading ? (
                <Spinner />
              ) : (
                <Switch
                  colorScheme="secondary"
                  isChecked={user.twoFactorEnabled}
                  onChange={toggleTwoFactor}
                  aria-label={`${
                    user.twoFactorEnabled ? "Disable" : "Enable"
                  } two factor authentication`}
                />
              )}
            </Box>
          </Flex>
        </ListItem>
        {/* <ListItem
            paddingX={{ base: "2", md: "7" }}
            paddingY={{ base: "6", md: "7" }}
            backgroundColor="secondary.10"
            marginTop="4"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Box display="inherit" alignItems="center" marginRight="4">
                <BellIcon color="secondary.500" />
                <Box marginX="4">
                  <Heading as="h2" size="sm" color="gray.600">
                    Notifications
                  </Heading>
                  <Box color="gray.800" marginTop="2">
                    Enabled
                  </Box>
                </Box>
              </Box>
              <Box fontSize="sm">
                <Switch colorScheme="secondary"></Switch>
              </Box>
            </Flex>
          </ListItem> */}
      </List>
      <Footer />
    </Box>
  );
};

export default CompanySettingsPagePresenter;

gql`
  mutation ToggleTwoFactor($twoFactorEnabled: Boolean!) {
    toggleTwoFactor(twoFactorEnabled: $twoFactorEnabled) {
      ...UserFragment
    }
  }
`;
