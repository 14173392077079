import { FC } from "react";

const ElasticUIWrapper: FC = ({ children }) => {
  const elasticStyles = {
    fontFamily: "Inter, sans-serif",
  };

  return <div style={elasticStyles}>{children}</div>;
};

export default ElasticUIWrapper;
