import { EuiBasicTable, EuiFlexItem, useEuiTheme } from "@elastic/eui";
import { Criteria } from "@elastic/eui/src/components/basic_table/basic_table";
import { EuiTableSortingType } from "@elastic/eui/src/components/basic_table/table_types";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { RefObject, useEffect, useRef, useState } from "react";

import { SortingHelper } from "../../../../helpers/sortingHelper";
import { AssetRelation } from "../models/relations";
import { tableConfig } from "./tableConfig";

export type WorkOrderCreateAddAssetRelationsPresenterProps = {
  relationItems: AssetRelation[];
};

const WorkOrderCreateAddAssetRelationsPresenter = ({
  relationItems,
}: WorkOrderCreateAddAssetRelationsPresenterProps) => {
  const tableMaxHeight = "252px";
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const tableRef: RefObject<EuiBasicTable<AssetRelation>> =
    useRef() as RefObject<EuiBasicTable<AssetRelation>>;
  const [sortField, setSortField] =
    useState<keyof AssetRelation>("relationType");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortedItems, setSortedItems] = useState<AssetRelation[]>([]);

  useEffect(() => {
    const sortedItems = SortingHelper.sortItems<AssetRelation>(
      sortField,
      sortDirection,
      relationItems
    );
    setSortedItems(sortedItems);
  }, [relationItems]);

  const sorting: EuiTableSortingType<AssetRelation> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const onTableChange = (changes: Criteria<AssetRelation>) => {
    if (!!changes?.sort) {
      const { field: sortField, direction: sortDirection } = changes.sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
      const sortedItems = SortingHelper.sortItems<AssetRelation>(
        sortField,
        sortDirection,
        relationItems
      );
      setSortedItems(sortedItems);
    }
  };

  return (
    <>
      <EuiFlexItem
        style={{
          maxHeight: tableMaxHeight,
          overflow: "scroll",
        }}
      >
        <EuiBasicTable
          className={"table-inner-scroll"}
          ref={tableRef}
          tableCaption="Asset Relations"
          items={sortedItems}
          itemId="id"
          rowHeader="relationType"
          columns={tableConfig}
          sorting={sorting}
          isSelectable={false}
          noItemsMessage="Selected Asset has no Fed From / Fed To relations"
          onChange={onTableChange}
        />
      </EuiFlexItem>
    </>
  );
};
export default WorkOrderCreateAddAssetRelationsPresenter;
