import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

import ReaderIcon from "../../icons/fileTypes/pdf";
import Spinner from "../spinner";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfProps {
  signedUrl: string;
  showNavAndInfo?: boolean;
  width: number;
  height?: number;
}

const Pdf: React.FC<PdfProps> = ({
  signedUrl,
  showNavAndInfo = true,
  width,
  height,
}) => {
  const [showIcon, setShowIcon] = React.useState(false);
  const [numPages, setNumPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () =>
    setPageNumber((pn) => (pn >= numPages ? numPages : pn + 1));
  const goToPrevPage = () => setPageNumber((pn) => (pn <= 1 ? 1 : pn - 1));

  return showIcon ? (
    <ReaderIcon width={`${width}px`} />
  ) : (
    <Center position="relative" role="group">
      <Document
        file={signedUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <Center height="80px">
            <Spinner />
          </Center>
        }
        error={<ReaderIcon width={`${width}px`} />}
        onPassword={() => setShowIcon(true)}
      >
        <Page
          pageNumber={pageNumber}
          height={height}
          width={height ? undefined : width}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          loading={
            <Center height="80px">
              <Spinner />
            </Center>
          }
          error={<ReaderIcon width={`${width}px`} />}
        />
      </Document>
      {showNavAndInfo && (
        <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          bottom="5px"
          left="50%"
          fontSize="sm"
          opacity="0"
          paddingTop="1"
          _groupHover={{ opacity: 1 }}
          boxShadow="md"
          backgroundColor="white"
          transform="translateX(-50%)"
        >
          <Box width="12">
            <Button
              variant="link"
              colorScheme="gray"
              onClick={goToPrevPage}
              disabled={pageNumber <= 1}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          </Box>
          <Text marginBottom="2">
            {pageNumber} of {numPages}
          </Text>
          <Box width="12">
            <Button
              variant="link"
              colorScheme="gray"
              onClick={goToNextPage}
              disabled={pageNumber >= numPages}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </Box>
        </Flex>
      )}
    </Center>
  );
};

export default Pdf;
