import { Icon, IconProps } from "@chakra-ui/react";

const Landscape = (props: IconProps & any) => {
  return (
    <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.243 95.976C21.736 95.976 14 88.169 14 78.574c0-6.15 3.155-11.712 8.23-14.838l.613-.378-.203-.696a18.245 18.245 0 0 1-.725-5.125c0-6.192 3.058-11.841 8.103-15.188l.454-.3-.033-.547c-.024-.392-.036-.781-.036-1.168 0-10.768 8.683-19.53 19.353-19.53 2.322 0 4.55.399 6.657 1.182l.657.244.419-.566C61.367 16.407 67.569 13 74.541 13c11.717 0 21.251 9.622 21.251 21.447 0 .485-.017.976-.05 1.467l-.04.558.466.303c5.628 3.659 9.047 9.91 9.047 16.767a19.98 19.98 0 0 1-.867 5.859l-.217.711.635.38c5.687 3.404 9.233 9.572 9.233 16.4 0 10.522-8.484 19.084-18.91 19.084H76.624V107a2 2 0 0 1-2 2h-.164a2 2 0 0 1-2-2V95.976H51.838V107a2 2 0 0 1-2 2h-.164a2 2 0 0 1-2-2V95.976h-16.43ZM63.076 67.57a2.092 2.092 0 0 0-2.971 2.944l12.354 12.468v8.792H53.993c-8.132 0-14.746-6.675-14.746-14.882 0-6.074 3.605-11.486 9.18-13.782l2.035-.838-.939-2.005a15.744 15.744 0 0 1-1.492-6.726c0-5.957 3.277-11.343 8.546-14.05l1.358-.698-.248-1.517a17.587 17.587 0 0 1-.233-2.83c0-9.509 7.665-17.244 17.088-17.244 9.422 0 17.087 7.735 17.087 17.245 0 .933-.079 1.885-.233 2.83l-.248 1.516 1.358.698c5.269 2.707 8.545 8.093 8.545 14.05a15.75 15.75 0 0 1-1.491 6.726l-.94 2.005 2.035.839c5.575 2.295 9.181 7.707 9.181 13.781 0 8.207-6.615 14.882-14.747 14.882H76.624v-8.907l12.24-12.353a2.092 2.092 0 0 0-2.971-2.944l-9.27 9.354v-14.25l8.91-8.992a2.091 2.091 0 1 0-2.971-2.944l-5.938 5.993V46.637a2.082 2.082 0 0 0-4.164 0v10.21l-6.053-6.109a2.092 2.092 0 0 0-2.971 2.944l9.024 9.107v14.25l-9.384-9.47ZM33.664 45.064l1.358-.697-.248-1.517a15.639 15.639 0 0 1-.207-2.516c0-8.452 6.813-15.328 15.188-15.328 1.481 0 2.918.207 4.296.617l.97.288-.358.954a21.508 21.508 0 0 0-1.322 9.05l.038.557-.465.303c-5.628 3.659-9.047 9.91-9.047 16.767 0 2.013.291 3.974.867 5.86l.217.71-.635.38c-5.687 3.404-9.233 9.572-9.233 16.4 0 5.155 2.037 9.839 5.343 13.278l1.54 1.604H31.244c-7.212 0-13.079-5.92-13.079-13.2 0-5.387 3.198-10.187 8.143-12.223l2.035-.838-.94-2.006a13.977 13.977 0 0 1-1.324-5.97c0-5.288 2.91-10.07 7.586-12.473Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default Landscape;
