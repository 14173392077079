import { Icon, IconProps } from "@chakra-ui/react";

const DrinkingFountain = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M108.721 59.996s-6.127-12.248-10.306-20.612c-4.492-9.011-13.709-9.087-14.702-9.087-1.491 0-2.972.206-4.398.6.018-.261.03-.523.03-.788C79.346 23.984 74.363 19 68.237 19s-11.109 4.984-11.109 11.11c0 6.075 4.904 11.025 10.961 11.105a16.628 16.628 0 0 0-1.034 5.743v3.108h-.145c-6.772 0-12.448 4.867-13.711 11.297h-11.98V47.055a3.015 3.015 0 0 1 3.012-3.012 3.015 3.015 0 0 1 3.012 3.012 2.637 2.637 0 1 0 5.274 0c0-4.569-3.717-8.285-8.286-8.285A8.253 8.253 0 0 0 38.582 41a8.253 8.253 0 0 0-5.648-2.231c-4.569 0-8.285 3.715-8.285 8.285a2.636 2.636 0 1 0 5.273-.003 3.014 3.014 0 0 1 3.012-3.009 3.015 3.015 0 0 1 3.011 3.012v14.308H21.637A2.637 2.637 0 0 0 19 64v8.473c0 6.125 4.984 11.109 11.11 11.109h16.945c6.126 0 11.11-4.984 11.11-11.11v-5.835h22.823a2.637 2.637 0 0 0 2.636-2.652l-.033-5.693 6.028 11.982-.014 36.088A2.638 2.638 0 0 0 92.242 109h14.121a2.637 2.637 0 0 0 2.637-2.637V61.176c0-.41-.095-.814-.279-1.18Zm-55.83 12.477a5.843 5.843 0 0 1-5.836 5.835H30.109a5.843 5.843 0 0 1-5.836-5.835v-5.836h28.618v5.836Zm9.51-42.364a5.843 5.843 0 0 1 5.835-5.836 5.843 5.843 0 0 1 5.836 5.836 5.843 5.843 0 0 1-5.836 5.836A5.842 5.842 0 0 1 62.4 30.11Zm41.326 73.617H94.88l.013-34.077c0-.412-.096-.818-.281-1.186L83.244 45.87a2.637 2.637 0 0 0-4.992 1.2l.084 14.293H58.63c1.137-3.49 4.423-6.023 8.278-6.023h2.782a2.637 2.637 0 0 0 2.637-2.637v-5.745c0-6.233 5.067-11.388 11.385-11.388 4.261 0 8.086 2.363 9.982 6.167l.001.002c3.65 7.305 8.788 17.575 10.031 20.06v41.927Z"
      fill="currentColor"
    />
  </Icon>
);

export default DrinkingFountain;
