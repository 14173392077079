import {
  Box,
  Button,
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useMediaQuery,
} from "@chakra-ui/react";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import {
  MaintenanceStatusType,
  useAssetLocationQuery,
} from "../../../graphql/graphql";
import { getStatusColor } from "../../../utils/maintenanceStatus";
import Spinner from "../spinner";

export interface CriticalAssetEvent {
  start: Date;
  end: Date;
  name: string;
  resourceId: string;
  resourceAssetId?: string;
  resourceMaintenanceScheduleId?: string;
  isCompleted: boolean;
  status: MaintenanceStatusType;
}

interface CalendarEventProp {
  event: CriticalAssetEvent;
}

const CalendarEvent: React.FC<CalendarEventProp> = ({ event }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [searchParams, setSearchParams] = useSearchParams();
  const maintenanceId = searchParams.get("maintenanceId");
  const maintenanceScheduleId = searchParams.get("maintenanceScheduleId");
  const maintenanceTimestamp = searchParams.get("maintenanceTimestamp")
    ? +searchParams.get("maintenanceTimestamp")!
    : null;

  const handleClick = useCallback(() => {
    const params: any = {
      CaAprType: "serviceScheduleShow",
      maintenanceId: event.resourceId,
      maintenanceTimestamp: event.start.getTime(),
    };
    if (event.resourceMaintenanceScheduleId)
      params.maintenanceScheduleId = event.resourceMaintenanceScheduleId;
    setSearchParams(params);
  }, [
    event.resourceId,
    event.resourceMaintenanceScheduleId,
    event.start,
    setSearchParams,
  ]);

  const isActive = useMemo(
    () =>
      maintenanceId === event.resourceId &&
      (maintenanceScheduleId === event.resourceMaintenanceScheduleId ||
        maintenanceTimestamp === event.start.getTime()),
    [
      event.resourceId,
      event.resourceMaintenanceScheduleId,
      event.start,
      maintenanceId,
      maintenanceScheduleId,
      maintenanceTimestamp,
    ]
  );

  return (
    <Popover trigger="hover" isLazy returnFocusOnClose>
      <PopoverTrigger>
        <Button
          variant="unstyled"
          textDecorationColor={
            event.isCompleted && !isActive ? "black" : "white"
          }
          textDecorationThickness="2px"
          textDecorationLine={event.isCompleted ? "line-through" : undefined}
          height="auto"
          bgColor={
            isActive
              ? "secondary.500"
              : event.isCompleted
              ? undefined
              : getStatusColor(event.status)
          }
          color={event.isCompleted && !isActive ? "gray.800" : "white"}
          onClick={handleClick}
          w="full"
          d="block"
          fontWeight="400"
          px="2"
          py="0"
          fontSize="xs"
          textAlign="left"
          lineHeight={1.5}
          isTruncated
        >
          <Flex align="center">
            {event.isCompleted ? (
              <Box color={isActive ? "white" : "gray.700"} mr="1">
                <FontAwesomeIcon icon={faCheckCircle} size="lg" />
              </Box>
            ) : undefined}
            {event.name}
          </Flex>
        </Button>
      </PopoverTrigger>
      {!isMobile && (
        <Portal>
          <PopoverContent borderRadius="0">
            <PopoverArrow />
            <PopoverHeader p="0">
              <Flex
                px="2"
                py="1"
                align="center"
                height="auto"
                bgColor={getStatusColor(event.status)}
              >
                {event.isCompleted && (
                  <Box color="white" mr="1">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </Box>
                )}
                <Box color="white" fontSize="sm">
                  {event.name}
                </Box>
              </Flex>
            </PopoverHeader>
            {!!event.resourceAssetId && <EventPopover event={event} />}
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  );
};

export default CalendarEvent;

const EventPopover: React.FC<CalendarEventProp> = ({ event }) => {
  const { data } = useAssetLocationQuery({
    variables: { id: event.resourceAssetId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  return data ? (
    <>
      {!!data.asset.locationsLabel && (
        <PopoverBody fontSize="xs" px="2" py="1">
          {data.asset.locationsLabel}
        </PopoverBody>
      )}
    </>
  ) : (
    <Center h="20">
      <Spinner />
    </Center>
  );
};

gql`
  query AssetLocation($id: UUID!) {
    asset(id: $id) {
      id
      locationsLabel
    }
  }
`;
