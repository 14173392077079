import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  useCompanyQuery,
  useUpdateCurrentCompanyMutation,
  useUserWithCompaniesQuery,
} from "../../../../graphql/graphql";
import {
  currentCompanyPlanVar,
  currentCompanyRoleVar,
} from "../../../../graphql/reactiveVariables";
import ROLES from "../../../../roles";
import { getRoutePath } from "../../../../router";
import Link from "../../../elements/link";
import Add from "../../../icons/add";
import CompanyOption from "./companyOption";

interface CompanySelectorProps {
  onChange?: () => void;
}

const NEW_COMPANY = "NEW_COMPANY";

const StyledBox = styled(Box)`
  .chakra-menu__menu-button span,
  .chakra-menu__menuitem {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
  }
`;

const CompanySelector: React.FC<CompanySelectorProps> = ({ onChange }) => {
  const plan = useReactiveVar(currentCompanyPlanVar);
  const navigate = useNavigate();
  const { data: userCompaniesData } = useUserWithCompaniesQuery();
  const { data: currentCompanyData } = useCompanyQuery();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const ref = React.useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  React.useEffect(() => {
    if (!ref.current) return;
    if (ref.current.offsetWidth < ref.current.scrollWidth) {
      onOpen();
    } else {
      onClose();
    }
  }, [ref, onOpen, onClose, isOpen]);

  const options: { value: string; label: string }[] = useMemo(
    () =>
      userCompaniesData
        ? sortBy(
            userCompaniesData.user.companyUsers.map((cu) => ({
              value: cu.company.id,
              label: cu.company.name,
            })),
            "label"
          )
        : [],
    [userCompaniesData]
  );

  const company = currentCompanyData?.company;

  const handleChange = useCallback(
    async (value: string) => {
      if (onChange) onChange();

      if (value === company?.id) return;

      if (value === NEW_COMPANY) {
        await updateCurrentCompany();
        navigate(getRoutePath("companyCreate"));
        return;
      }

      navigate(getRoutePath("companyChange", { companyId: value }));
    },
    [company?.id, navigate, onChange, updateCurrentCompany]
  );

  return (
    <StyledBox>
      <Flex alignItems="center">
        <Box
          flexGrow={1}
          color="gray.300"
          fontSize="xs"
          lineHeight="32px"
          textTransform="uppercase"
        >
          Company
        </Box>
        {plan?.productName === "Professional" &&
          ROLES.companyCreate.includes(currentCompanyRole.role) && (
            <Box>
              <Link
                to={getRoutePath("companyCreate")}
                variant="link"
                colorScheme="secondary"
                justifyContent="flex-end"
              >
                <Text
                  as="span"
                  display={{ base: "block", md: "none" }}
                  textTransform="uppercase"
                  color="primary.500"
                >
                  Add
                </Text>
                <Tooltip
                  label="Add new company"
                  placement="right"
                  hasArrow
                  shouldWrapChildren
                >
                  <Add
                    display={{ base: "none", md: "block" }}
                    color="primary.500"
                  />
                </Tooltip>
              </Link>
            </Box>
          )}
      </Flex>
      <Menu>
        {options.length > 1 ? (
          ({ isOpen: isMenuOpen, onClose: onMenuClose }) => (
            <>
              <Tooltip
                label={isOpen && company?.name ? company.name : undefined}
                placement="right"
                hasArrow
              >
                <MenuButton
                  as={Button}
                  width="full"
                  textAlign="left"
                  variant="link"
                  color="white"
                  colorScheme="secondary"
                  rightIcon={
                    <FontAwesomeIcon
                      icon={isMenuOpen ? faChevronUp : faChevronDown}
                      size="xs"
                    />
                  }
                  _hover={{}}
                  _active={{}}
                >
                  <Box as="span" ref={ref}>
                    {company ? company.name : "Companies"}
                  </Box>
                </MenuButton>
              </Tooltip>
              <MenuList
                backgroundColor="gray.800"
                border="none"
                borderRadius="none"
                minWidth="200px"
              >
                {options.map((option) =>
                  option.value === company?.id ? null : (
                    <CompanyOption
                      option={option}
                      handleChange={(val: string) => {
                        onMenuClose();
                        handleChange(val);
                      }}
                      key={option.value}
                    />
                  )
                )}
              </MenuList>
            </>
          )
        ) : (
          <Tooltip
            label={isOpen && company?.name ? company.name : undefined}
            placement="right"
            hasArrow
          >
            <MenuButton
              as={Button}
              width="full"
              textAlign="left"
              variant="link"
              color="white"
              colorScheme="secondary"
              _hover={{}}
              _active={{}}
            >
              <Box as="span" ref={ref}>
                {company ? company.name : "Companies"}
              </Box>
            </MenuButton>
          </Tooltip>
        )}
      </Menu>
    </StyledBox>
  );
};

export default CompanySelector;
