import theme from "@chakra-ui/theme";

const colors = {
  black: "#0b0b0b",
  white: "#ffffff",
  whiteAlpha: theme.colors.whiteAlpha,
  blackAlpha: theme.colors.blackAlpha,
  primary: {
    50: "#fffcf5",
    100: "#ffdf99",
    200: "#ffd780",
    300: "#ffcf66",
    400: "#ffc64d",
    500: "#ffbd29",
    600: "#f1af1b",
    700: "#f0a90f",
    800: "#d8980e",
    900: "#c0870c",
  },
  schemePrimary: {
    50: "#fffcf5",
    100: "#ffdf99",
    200: "#ffd780",
    300: "#ffcf66",
    400: "#ffc64d",
    500: "#ffbd29",
    600: "#f1af1b",
    700: "#f0a90f",
    800: "#d8980e",
    900: "#c0870c",
  },
  secondary: {
    10: "#F7F9FC",
    50: "#dde6f3",
    75: "#cad8ec",
    100: "#b3d6ff",
    200: "#9ac9fe",
    300: "#80bbfe",
    400: "#67adfe",
    500: "#459bfe",
    600: "#1676ee",
    700: "#1069d5",
    800: "#0e5dbe",
    900: "#0c52a6",
  },
  red: {
    50: "#ffe6e6",
    100: "#feb3b3",
    200: "#fe9a9a",
    300: "#fe8181",
    400: "#fd6868",
    500: "#fd3d3d",
    600: "#EB5757",
    700: "#e73232",
    800: "#e41b1b",
    900: "#cd1818",
  },
  green: {
    50: "#acecba",
    100: "#83e298",
    200: "#6edd86",
    300: "#5ad875",
    400: "#45d364",
    500: "#2ec14e",
    600: "#27a542",
    700: "#22913a",
    800: "#1d7c32",
    900: "#186729",
  },
  orange: {
    50: "#ffdb99",
    100: "#ffc966",
    200: "#ffc14d",
    300: "#ffb833",
    400: "#ffaf1a",
    500: "#ffa500",
    600: "#e69500",
    700: "#cc8500",
    800: "#b37400",
    900: "#996300",
  },
  gray: {
    50: "#FBFBFB",
    100: "#F5F5F5",
    200: "#F0F0F0",

    300: "#CACACA",
    400: "#CACACA",
    500: "#CACACA",

    600: "#7A7A7A",
    700: "#7A7A7A",

    800: "#444444",
    900: "#444444",
    950: "#24252f",
  },
};

export default colors;
