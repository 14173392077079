import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../components/elements/pageSpinner";
import { canAddNewUsersToCompany } from "../../constants/plan";
import { useCompanyUsersQuery, useUserQuery } from "../../graphql/graphql";
import {
  currentCompanyPlanVar,
  currentCompanyRoleVar,
  currentCompanyVar,
  layoutOptionsVar,
} from "../../graphql/reactiveVariables";
import ROLES from "../../roles";
import Presenter from "./presenter";

interface CompanyTeamPageProps {}

const CompanyTeamPage: React.FC<CompanyTeamPageProps> = ({ children }) => {
  const currentCompany = useReactiveVar(currentCompanyVar);
  const currentCompanyPlan = useReactiveVar(currentCompanyPlanVar);
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);
  const { data: companyUsersData } = useCompanyUsersQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data } = useUserQuery();
  const canAddNewUsers = canAddNewUsersToCompany(
    currentCompanyPlan?.productName as any,
    currentCompany
  );

  useEffect(() => {
    layoutOptionsVar({
      title: "Team",
      styleProps: { maxWidth: "1100px" },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Team</title>
      </Helmet>
      {companyUsersData && data ? (
        <Presenter
          companyUsers={companyUsersData.companyUsers}
          currentUser={data.user}
          canAddCompanyUser={
            ROLES.companyTeamsCreate.includes(currentCompanyRole.role) &&
            canAddNewUsers
          }
          currentCompanyRole={currentCompanyRole}
          canEditCompanyUser={ROLES.companyTeamsEdit.includes(
            currentCompanyRole.role
          )}
        />
      ) : (
        <PageSpinner />
      )}
      {children}
    </>
  );
};

export default CompanyTeamPage;

gql`
  query CompanyUsers {
    companyUsers {
      ...CompanyUserWithUserFragment
    }
  }
`;
