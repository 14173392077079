import { Icon, IconProps } from "@chakra-ui/react";

const SolarPanelInverter = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.724 24.936a18.353 18.353 0 0 1 4.85-.031l.073.009c1.749.221 3.459.686 5.079 1.381l.321.138a17.2 17.2 0 0 1 6.187 4.51l.799.92.778 1.045a12.402 12.402 0 0 1 2.389 6.18l.165 1.675a18.099 18.099 0 0 1-2.652 11.346l-.034.054a18.448 18.448 0 0 1-1.637 2.234l-.362.424a16.346 16.346 0 0 1-9.361 5.431l-1.15.22a16.56 16.56 0 0 1-11.494-1.994l-.578-.34a16.916 16.916 0 0 1-6.211-6.367l-.215-.386a18.155 18.155 0 0 1-1.873-4.973l-.068-.316a16.197 16.197 0 0 1 .79-9.444l.113-.28a18.44 18.44 0 0 1 2.446-4.318l.166-.219a18.807 18.807 0 0 1 3.683-3.657l.372-.279a15.292 15.292 0 0 1 7.057-2.911l.367-.052Zm5.603-5.984a24.346 24.346 0 0 0-6.435.042l-.367.052a21.297 21.297 0 0 0-9.825 4.053l-.372.279a24.81 24.81 0 0 0-4.858 4.824l-.166.219c-.433.569-.84 1.155-1.22 1.759H18.016c-6.075 0-11 4.925-11 11V92c0 6.076 4.925 11 11 11H22.5v3a6.5 6.5 0 1 0 13 0v-3h3v3a6.5 6.5 0 1 0 13 0v-3H99c6.075 0 11-4.924 11-11V63.208a22.347 22.347 0 0 0 5.239-4.487l.363-.424a24.486 24.486 0 0 0 2.17-2.96l.033-.054a24.099 24.099 0 0 0 3.531-15.107l-.165-1.674a18.378 18.378 0 0 0-3.546-9.175l-.847-1.138-.068-.092-.075-.086-.875-1.005a23.156 23.156 0 0 0-8.348-6.087l-.321-.138a24.9 24.9 0 0 0-6.69-1.82l-.074-.009ZM27.5 103h3v3a1.5 1.5 0 1 1-3 0v-3Zm16 3v-3h3v3a1.5 1.5 0 1 1-3 0ZM104 65.791c-.515.137-1.035.255-1.559.355l-1.15.22a22.556 22.556 0 0 1-15.658-2.717l-.578-.34a22.923 22.923 0 0 1-8.414-8.624l-.215-.386a24.162 24.162 0 0 1-2.492-6.617l-.068-.315a22.195 22.195 0 0 1 .465-11.187H18.017a5 5 0 0 0-5 5V92a5 5 0 0 0 5 5H99a5 5 0 0 0 5-5V65.791ZM99.704 32.95l-7.013 7.64a2.93 2.93 0 0 0 1.85 4.894l-3.77 4.213c-2.541 2.865 1.795 6.751 4.366 3.908l8.073-9.019a2.93 2.93 0 0 0-1.777-4.855l2.587-2.82a2.93 2.93 0 0 0-4.316-3.961ZM28 66V56h33v10H28Zm-6-14a2 2 0 0 1 2-2h41a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H24a2 2 0 0 1-2-2V52Z"
      fill="currentColor"
    />
  </Icon>
);

export default SolarPanelInverter;
