/* eslint no-template-curly-in-string: 0 */

import { isValid } from "date-fns";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import tinycolor2 from "tinycolor2";
import * as yup from "yup";

import {
  EMAIL_REGEX_MESSAGE,
  PASSWORD_REGEX_MESSAGE,
} from "../constants/lang/en";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../constants/regex";
import { MaintenanceRemindBeforeType } from "../graphql/graphql";
import timezones from "./date/timezones";

yup.setLocale({
  mixed: {
    required: "${path} is required",
  },
  string: {
    email: "Please enter a valid email address",
  },
});

export default yup;

export const yupObject = yup.object;
export const yupArray = yup.array;
export const yupString = yup.string;
export const yupNumber = yup.number;
export const yupBoolean = yup.boolean;
export const yupDate = yup.date;

export const idSchema = yupString().label("Id").trim().required();

export const emailSchema = yupString()
  .label("Email")
  .trim()
  .required("Please enter a valid email address")
  .matches(EMAIL_REGEX, { message: EMAIL_REGEX_MESSAGE });

export const firstNameSchema = yupString()
  .label("First name")
  .trim()
  .required();

export const lastNameSchema = yupString().label("Last name").trim().required();

export const currentPasswordSchema = yupString()
  .label("Password")
  .trim()
  .required();

export const newPasswordSchema = yupString()
  .label("New password")
  .trim()
  .required()
  .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE });

export const newPasswordConfirmSchema = yupString()
  .label("Password confirmation")
  .trim()
  .required()
  .oneOf([yup.ref("passwordRaw")], "Passwords do not match");

export const jobTitleSchema = yupString().label("Job title").trim();

export const companyIndustrySchema = yupString()
  .label("Industry")
  .trim()
  .optional();

export const companyBusinessTypeSchema = yupString()
  .label("Business type")
  .trim()
  .optional();

export const phoneSchema = yupString()
  .label("Phone number")
  .trim()
  .required()
  .test("isValidPhone", "Please enter a valid phone number", (value) =>
    value ? isPossiblePhoneNumber(value) : true
  );

export const pinSchema = yupNumber()
  .label("One-time PIN")
  .required("Please enter your PIN");

export const address1Schema = yupString()
  .label("Address")
  .trim()
  .required("This field is required");

export const address2Schema = yupString().label("Address 2").trim();

export const citySchema = yupString()
  .label("City")
  .trim()
  .required("This field is required");

export const stateSchema = yupString()
  .label("State")
  .trim()
  .required("This field is required");

export const zipSchema = yupString()
  .label("Zip")
  .trim()
  .required("This field is required");

export const couponSchema = yupString().label("Coupon code").trim().required();

export const roleSchema = yupString()
  .label("Role")
  .trim()
  .required("Please select a role");

export const nameSchema = yupString().label("Name").trim().required();

export const descriptionSchema = yupString()
  .trim()
  .label("Description")
  .nullable();

export const customDataSchema = yupArray().of(
  yupObject().shape({
    label: yupString().trim().required("Required"),
    value: yupString().trim().required("Required"),
    unit: yupString().trim(),
  })
);

export const typeSchema = yupString().label("Type").trim().required();

export const fileIdsSchema = yupArray()
  .of(yupString().label("Plan").trim().required("Please upload a plan"))
  .required("Please upload a plan");

export const fileIdsUploadingSchema = yupBoolean()
  .label("File Uploading")
  .required("Please wait for upload to complete")
  .oneOf([false], "Please wait for upload to complete");

export const iconNameSchema = yupString().trim().label("Icon").required();
export const iconColorSchema = yupString()
  .trim()
  .label("Color")
  .test("isValidColor", "Please select a valid color", (value) =>
    value ? tinycolor2(value).isValid() : true
  );
export const iconTypeSchema = yupString()
  .trim()
  .required()
  .label("Please select a style")
  .required();
export const assetFieldsSchema = yupArray()
  .of(
    yupObject().shape({
      id: yupString().label("Id").optional(),
      label: yupString().label("Label").required(),
      type: yupString().label("Type").required(),
      selectOptions: yupString().label("Select options").when("type", {
        is: "SELECT",
        then: yupString().required(),
      }),
      unit: yupString().label("Unit"),
      required: yupBoolean().label("Required").required(),
      validationRegExp: yupString()
        .label("Validation RegExp")
        .test(
          "isValidRegExp",
          "Please enter a valid JS Regular Expression",
          (value?: string): boolean => {
            if (!value) return true;
            try {
              new RegExp(value);
              return true;
            } catch (e) {
              return false;
            }
          }
        ),
      validationRegExpMessage: yupString()
        .label("Validation Error Message")
        .when("validationRegExp", {
          is: (v?: string) => !!v,
          then: yupString().required("Validation Error Message is required"),
        }),
    })
  )
  .label("Asset Fields");

export const specialAssetFieldSchema = yupString()
  .label("Special Asset Field")
  .trim()
  .optional();

export const websiteSchema = yupString()
  .label("Website")
  .trim()
  .optional()
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Please enter a valid website"
  );

export const createdBySchema = yupString()
  .label("Created by")
  .trim()
  .optional();

export const createdDateSchema = yupString()
  .label("Created date")
  .trim()
  .optional();

export const assetCategoryIdsSchema = yupArray()
  .of(yupString().required().trim())
  .label("Categories")
  .optional();

export const companyUserIdsSchema = yupArray()
  .of(yupString().required().trim())
  .label("Contact users")
  .required()
  .min(1);

export const assetIdsSchema = yupArray()
  .of(yupString().required().trim())
  .label("Asset")
  .required()
  .min(1);

export const locationIdsSchema = yupArray()
  .of(yupString().required().trim())
  .label("Location")
  .required()
  .min(1);

export const floorPlanIdsSchema = yupArray()
  .of(yupString().required().trim())
  .label("Plan")
  .required()
  .min(1);

export const extractAllSheetsScheme = yupBoolean()
  .label("Extract all sheets")
  .required();

export const updatePositionScheme = yupBoolean()
  .label("Update the position of assets and affected areas")
  .required();

export const agreementsSchema = yupBoolean()
  .label("Agreements")
  .required("Please accept CriticalAsset's agreements")
  .oneOf([true], "Please accept CriticalAsset's agreements");

export const assetTypeIdSchema = yupString()
  .label("Asset type")
  .trim()
  .required("Please choose an asset type to continue");

export const assetPartIdSchema = yupString().label("Breaker").trim();

export const numberOfColumnsSchema = yupNumber()
  .label("Number of columns")
  .required();

export const numberOfRowsSchema = yupNumber()
  .label("Number of rows")
  .required();

export const numberOfEvenRowsSchema = yupNumber()
  .label("Number of breakers")
  .required()
  .test(
    "Is even number?",
    "Number of breakers should be an even number",
    (value?: number) => !value || value % 2 === 0
  );

export const breakerStartingNumberSchema = yupNumber()
  .label("Breaker starting number")
  .required()
  .test(
    "Is greater than zero?",
    "Breaker starting number should be greater than zero",
    (value?: number) => !value || value > 0
  )
  .test(
    "Is odd number?",
    "Number of breakers should be an odd number",
    (value?: number) => !value || value % 2 === 1
  );

export const mainBreakerOrLugSchema = yupBoolean()
  .label("Main breaker")
  .required();

export const normalPanelBreakerPoles = yupNumber()
  .label("Breaker Poles")
  .required()
  .test(
    "Is less than 3?",
    "Breaker poles should be less than 3",
    (value?: number) => !value || value <= 3
  );

export const gridPanelBreakerPoles = yupNumber()
  .label("Breaker Poles")
  .required()
  .test(
    "Is less than 10?",
    "Breaker poles should be less than 10",
    (value?: number) => !value || value <= 10
  );

export const columnsIndexSchema = yupNumber().label("Column Index").required();

export const rowsIndexSchema = yupNumber().label("Row Index").required();

export const maintenanceMiscTypeSchema = yupString()
  .label("Type")
  .trim()
  .nullable()
  .optional();

export const maintenanceMiscValueSchema = yupNumber()
  .label("Value")
  .positive()
  .nullable()
  .integer();

export const dateSchema = yupDate()
  .label("Date")
  .optional()
  .nullable()
  .test("isValidDate", "Please choose a valid date", (value) => {
    return value ? isValid(value) : true;
  });

export const timezoneSchema = yupString()
  .label("Timezone")
  .required()
  .test(
    "Is valid",
    "Timezone is invalid",
    (value) => !value || timezones.some((timezone) => timezone.value === value)
  );

export const endAfterSchema = yupNumber().label("End after").nullable();

export const maintenanceCompanyUserIdsSchema = yupArray()
  .of(yupString().required().trim())
  .label("Users");

export const assetMaintenanceFormValidationSchema = yupObject()
  .default(null)
  .nullable()
  .shape({
    name: nameSchema.label("Title"),
    description: descriptionSchema,
    startDateTime: dateSchema.label("Start Date").required(),
    timezone: timezoneSchema,
    durationType: maintenanceMiscTypeSchema
      .label("Duration")
      .required("Required"),
    durationValue: maintenanceMiscValueSchema
      .label("Duration")
      .required("Required"),
    intervalType: maintenanceMiscTypeSchema.label("Interval"),
    intervalValue: maintenanceMiscValueSchema.label("Interval"),
    remindBeforeType: maintenanceMiscTypeSchema.label("Reminder"),
    remindBeforeValue: maintenanceMiscValueSchema
      .label("Reminder")
      .when("remindBeforeType", {
        is: MaintenanceRemindBeforeType.Weeks,
        then: yupNumber().max(1, "Should be less than 1 week"),
      })
      .when("remindBeforeType", {
        is: MaintenanceRemindBeforeType.Days,
        then: yupNumber().max(14, "Should be less than 14 days"),
      })
      .when("remindBeforeType", {
        is: MaintenanceRemindBeforeType.Hours,
        then: yupNumber().max(24, "Should be less than 24 hours"),
      })
      .when("remindBeforeType", {
        is: MaintenanceRemindBeforeType.Minutes,
        then: yupNumber().max(1440, "Should be less than 1440 minutes"),
      }),
  });
