import { useApolloClient } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React from "react";

import { ASSET_CATEGORY_DELETED_MESSAGE } from "../../constants/lang/en";
import {
  AssetCategoryFragmentFragment,
  useAssetCategoryDeleteMutation,
} from "../../graphql/graphql";
import { setGenericMessage } from "../../utils/serverErrors";

interface AssetCategoryDeleteProps {
  assetCategory: AssetCategoryFragmentFragment;
}

const AssetCategoryDelete: React.FC<AssetCategoryDeleteProps> = ({
  assetCategory,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [assetCategoryDeleteMutation, { loading: deleteLoading }] =
    useAssetCategoryDeleteMutation();

  const deleteAssetCategory = React.useCallback(async () => {
    try {
      const { errors } = await assetCategoryDeleteMutation({
        variables: { id: assetCategory.id },
      });
      if (errors) {
        toast({
          description: setGenericMessage(errors),
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        client.cache.evict({
          id: `AssetCategory:${assetCategory.id}`,
        });
        onClose();
        toast({
          description: ASSET_CATEGORY_DELETED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [
    assetCategoryDeleteMutation,
    assetCategory.id,
    toast,
    client.cache,
    onClose,
  ]);

  return (
    <>
      <Tooltip label="Delete Category" hasArrow placement="bottom">
        <IconButton
          onClick={onOpen}
          variant="icon"
          aria-label="Delete category"
          colorScheme="grayRed"
          padding={2}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </IconButton>
      </Tooltip>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete the following category?
              <br />
              <Text as="strong">{assetCategory.name}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={deleteLoading}
                onClick={onClose}
                width="48%"
              >
                No, Don't Delete!
              </Button>
              <Button
                onClick={deleteAssetCategory}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AssetCategoryDelete;

gql`
  mutation AssetCategoryDelete($id: UUID!) {
    assetCategoryDelete(id: $id)
  }
`;
