import { Icon, IconProps } from "@chakra-ui/react";

const BackflowPreventer = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M100.196 70.406h-4.805a4.81 4.81 0 0 0-4.33 2.723h-8.963v-3.203a2.402 2.402 0 0 0-2.403-2.403h-3.683v-7.207a2.402 2.402 0 0 0-2.402-2.402h-2.403V50.29L87.41 37.414h6.38c3.974 0 7.207-3.233 7.207-7.207 0-3.974-3.233-7.207-7.207-7.207H34.211c-3.974 0-7.207 3.233-7.207 7.207 0 3.974 3.233 7.207 7.207 7.207h6.38L56.793 50.29v7.624h-2.402a2.402 2.402 0 0 0-2.403 2.402v7.207h-3.683a2.402 2.402 0 0 0-2.403 2.403v3.203H36.94a4.811 4.811 0 0 0-4.33-2.723h-4.804A4.81 4.81 0 0 0 23 75.211v23.703a4.81 4.81 0 0 0 4.805 4.805h4.804a4.81 4.81 0 0 0 4.33-2.723h11.857A11.665 11.665 0 0 0 57.594 105h12.812c3.505 0 6.653-1.553 8.798-4.004H91.06a4.81 4.81 0 0 0 4.33 2.723h4.804A4.81 4.81 0 0 0 105 98.914V75.211a4.81 4.81 0 0 0-4.804-4.805ZM32.609 98.914h-4.804V75.211h4.804v23.703Zm38.598-54.761v-6.739h8.48l-8.48 6.739ZM34.211 32.609a2.405 2.405 0 0 1-2.402-2.402 2.405 2.405 0 0 1 2.402-2.402h59.578a2.405 2.405 0 0 1 2.403 2.402 2.405 2.405 0 0 1-2.403 2.402H34.211Zm27.387 9.61v-4.805h4.805v4.805h-4.805Zm4.805 4.804v10.891h-4.805v-10.89h4.805Zm-18.09-9.609h8.48v6.739l-8.48-6.739Zm8.48 25.305h14.414v4.804H56.793V62.72ZM37.414 96.19V77.934h8.488v15.375c0 .994.126 1.96.36 2.882h-8.848Zm32.992 4.004H57.594a6.894 6.894 0 0 1-6.887-6.886v-20.98h26.586v20.98a6.894 6.894 0 0 1-6.887 6.886Zm11.692-6.886V77.934h8.488V96.19h-8.849c.235-.922.36-1.888.36-2.882Zm18.098 5.605h-4.805V75.211h4.805v23.703Z"
      fill="currentColor"
    />
  </Icon>
);

export default BackflowPreventer;
