import { useReactiveVar } from "@apollo/client";
import { EuiPanel, EuiText, useEuiTheme } from "@elastic/eui";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { DragEvent, useState } from "react";

import { toastVar } from "../helpers/workOrdersReactiveVariables";

interface FileValidationErrors {
  maxSize: boolean;
  accept: boolean;
}

interface FileInputProps {
  onChange?: (files: File[]) => void;
  accept?: string[];
  multiple?: boolean;
  maxSize?: number;
  disableClick?: boolean;
  disableDragAndDrop?: boolean;
  maxSizeErrorMassage?: string;
  acceptErrorMassage?: string;
  showDropArea?: boolean;
  dropAreaMargin?: number;
  fullWidth?: boolean;
  style?: any;
}

let draggingCount = 0;

const FileInput: React.FC<FileInputProps> = ({
  onChange,
  accept,
  multiple,
  maxSize,
  disableClick,
  disableDragAndDrop,
  maxSizeErrorMassage,
  acceptErrorMassage,
  showDropArea,
  dropAreaMargin = 0,
  fullWidth,
  style,
  children,
}) => {
  const { euiTheme }: UseEuiTheme<{ colors: any }> = useEuiTheme();
  const inputRef = React.useRef(null);
  const toasts = useReactiveVar(toastVar);
  const [dragAreaColored, setDragAreaColored] = useState<boolean>(false);

  const handleClick = () => {
    if (disableClick) return;
    (inputRef?.current as any).click();
  };

  const handleChange = (files: File[]) => {
    const newFiles: File[] = [];
    const fileErrors: FileValidationErrors = {
      maxSize: false,
      accept: false,
    };

    files.forEach((newFile) => {
      if (
        (!maxSize || (maxSize && newFile.size <= maxSize)) &&
        (!accept?.length || (accept?.length && accept.includes(newFile.type)))
      ) {
        newFiles.push(newFile);
        return;
      }
      if (maxSize && newFile.size > maxSize) {
        fileErrors.maxSize = true;
      }
      if (accept?.length && !accept.includes(newFile.type)) {
        fileErrors.accept = true;
      }
    });
    if (fileErrors.maxSize || fileErrors.accept) {
      handleErrors(fileErrors);
    }
    newFiles?.length && onChange && onChange(newFiles);
    (inputRef.current as any).value = "";
  };

  const handleInputChange = (event: any) => {
    const filesList = event.target.files;
    if (filesList) handleChange(Array.from(filesList));
  };

  const handleErrors = (fileErrors: FileValidationErrors) => {
    const newErrors: Toast[] = [];
    if (fileErrors.maxSize && maxSizeErrorMassage) {
      newErrors.push({
        id: `maxSizeErrorMassage${toasts.length + 1 + Math.random()}`,
        color: "danger",
        text: maxSizeErrorMassage,
      });
    }
    if (fileErrors.accept && acceptErrorMassage) {
      newErrors.push({
        id: `acceptErrorMassage${toasts.length + 2 + Math.random()}`,
        color: "danger",
        text: acceptErrorMassage,
      });
    }
    if (newErrors.length) {
      toastVar([...toasts, ...newErrors]);
    }
  };

  const handleDragAreaColored = (value: boolean) => {
    if (value !== dragAreaColored) {
      setDragAreaColored(value);
    }
  };

  const handleDragIn = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (disableDragAndDrop) return;
    draggingCount++;
    if (ev.dataTransfer?.items?.length > 0) {
      handleDragAreaColored(true);
    }
  };

  const handleDragOut = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (disableDragAndDrop) return;
    draggingCount--;
    if (draggingCount > 0) return;
    handleDragAreaColored(false);
  };

  const handleDragOver = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (disableDragAndDrop) return;
    ev.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (disableDragAndDrop) return;
    draggingCount = 0;
    handleDragAreaColored(false);
    handleChange(Array.from(ev.dataTransfer.files as FileList));
  };

  return (
    <div
      style={{
        position: disableDragAndDrop ? "static" : "relative",
        minWidth: fullWidth ? "100%" : "0",
        maxWidth: "100%",
      }}
      onDragEnter={handleDragIn}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragOut}
    >
      {!disableDragAndDrop && (
        <>
          <div
            style={{
              position: "absolute",
              minWidth: `calc(100% + ${dropAreaMargin * 2}px)`,
              minHeight: `calc(100% + ${dropAreaMargin * 2}px)`,
              opacity: showDropArea && dragAreaColored ? 0.3 : 0,
              margin: `${-dropAreaMargin}px`,
              backgroundColor: "#DAEBFF",
              pointerEvents: "none",
              zIndex: 1,
              border: "2px dashed rgba(69, 155, 254, 0.5)",
              borderRadius: euiTheme.border.radius.medium,
            }}
          ></div>
          <EuiPanel
            element="div"
            paddingSize="m"
            style={{
              position: "absolute",
              top: "calc(50% - 50px)",
              left: "calc(50% - 160px)",
              pointerEvents: "none",
              visibility:
                showDropArea && dragAreaColored ? "visible" : "hidden",
            }}
          >
            <EuiText
              color={euiTheme.colors.secondary["500"]}
              size="m"
              style={{ maxWidth: "300px", pointerEvents: "none" }}
              textAlign="center"
            >
              Drop file anywhere to upload to this issue
            </EuiText>
          </EuiPanel>
        </>
      )}
      <div onClick={handleClick} style={style}>
        {children}
      </div>
      <input
        type="file"
        aria-label="add files"
        ref={inputRef}
        multiple={multiple}
        onChange={handleInputChange}
        accept={(accept || ["*"]).join(",")}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileInput;
