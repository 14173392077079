import { EuiFieldText, EuiFormRow, EuiText, useEuiTheme } from "@elastic/eui";
import { css } from "@emotion/react";
import React, { ChangeEvent, useEffect, useState } from "react";

import Label from "../../components/label";

interface ViewEditTextProps {
  value?: string | null;
  label?: string | null;
  onValueChange: (value: string) => void;
  required?: boolean;
  placeholder?: string;
  style?: any;
  className?: string;
  compressed?: boolean;
}

const ViewEditText: React.FC<ViewEditTextProps> = ({
  value,
  label,
  onValueChange,
  className,
  placeholder,
  compressed = false,
  style = {},
  required = false,
}) => {
  const { euiTheme } = useEuiTheme();
  const [errors, setErrors] = useState<string[]>();
  const [name, setName] = useState<string>(value || "");
  const [isInput, setIsInput] = useState<boolean>(false);
  const checkErrors = (title: string | null) => {
    if (required && !title?.trim()) {
      setErrors([`${label || "Value"} is required`]);
      return true;
    }
    setErrors([]);
    return false;
  };

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const name: string = e.target.value as string;
    setName(name);
    checkErrors(name);
    onValueChange(name);
  };

  const onSaveChanges = (e: ChangeEvent<HTMLInputElement>) => {
    if (!errors?.length) {
      setIsInput(false);
    }
  };

  useEffect(() => {
    setName(value || "");
  }, [value]);

  style = {
    fontSize: "14px",
    fontWeight: 500,
    ...style,
  };
  return (
    <>
      <EuiFormRow
        className={`view-edit-text borderless ${
          required ? "validated-control " : ""
        } ${className ? className : ""}`}
        isInvalid={!!errors?.length}
        error={errors}
        style={{ fontWeight: 700, maxWidth: "unset" }}
      >
        <div style={{ position: "relative" }}>
          {label && (
            <Label
              label={label || ""}
              required={required}
              style={{ paddingBottom: "5px" }}
            />
          )}
          {isInput && (
            <EuiFieldText
              name="name"
              isInvalid={!!errors?.length}
              onChange={onChangeValue}
              onBlur={onSaveChanges}
              fullWidth={true}
              style={{
                ...style,
                paddingLeft: "0",
                paddingRight: !!errors?.length
                  ? euiTheme.size.xl
                  : euiTheme.size.s,
              }}
              value={name}
              autoFocus={true}
              compressed={compressed}
              css={css`
                color: var(--color-text);
              `}
            />
          )}
          {!isInput && (
            <EuiText
              title={name}
              className={"ellipsis"}
              style={{
                ...style,
                cursor: "pointer",
                borderRadius: "0",
                marginBottom: "0",
                height: !compressed ? "40px" : "30px",
                letterSpacing: "normal",
                lineHeight: !compressed ? "40px" : "30px",
              }}
              onClick={() => setIsInput(true)}
              css={css`
                color: var(--color-text);

                &:hover {
                  background: var(--color-hover);
                }
              `}
            >
              {value || ""}
            </EuiText>
          )}
          {!name && placeholder && (
            <EuiText
              className={"placeholder"}
              style={{
                top: "unset",
                left: "4px",
                bottom: compressed ? "6px" : "10px",
                lineHeight: "21px",
              }}
            >
              {placeholder}
            </EuiText>
          )}
        </div>
      </EuiFormRow>
    </>
  );
};

export default ViewEditText;
