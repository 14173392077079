import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../components/elements/pageSpinner";
import {
  useCurrentCompanyQuery,
  usePlansQuery,
  useUserWithCompaniesQuery,
} from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import { getRoutePath } from "../../router";
import Presenter from "./presenter";

declare let window: any;

interface CompanyPlanCreatePageProps {}

const CompanyPlanCreatePage: React.FC<CompanyPlanCreatePageProps> = () => {
  const { data: plansData } = usePlansQuery();
  const { data: companyData } = useCurrentCompanyQuery();
  const { data: companiesData } = useUserWithCompaniesQuery();
  const [currentTab, setCurrentTab] = useState(2);
  const navigate = useNavigate();

  const handleSuccess = () => {
    if (window.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ eventType: "CA:CompanyCreated" })
      );
    } else {
      navigate(`${getRoutePath("dashboard")}?CaAprType=companyCreated`);
    }
  };

  useEffect(() => {
    layoutOptionsVar({
      currentStep: currentTab,
    });
  }, [currentTab]);

  return (
    <>
      {plansData && companiesData && companyData ? (
        <Presenter
          plans={plansData.plans}
          userCompanies={companiesData.user.companyUsers.map(
            (cu) => cu.company
          )}
          selectedCompanyId={companyData.currentCompany.id}
          setCurrentTab={setCurrentTab}
          onSuccess={handleSuccess}
        />
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default CompanyPlanCreatePage;
