import { gql } from "@apollo/client";

export const WorkOrderTransitionReasonFragment = gql`
  fragment WorkOrderTransitionReasonFragment on TransitionReason {
    id
    name
  }
`;

export const WorkOrderTransitionStageFragment = gql`
  fragment WorkOrderTransitionStageFragment on TransitionStage {
    id
    currentStage {
      id
      name
    }
    currentStageId
    availableStage {
      id
      name
      color
    }
    availableStageId
    transitionReasons {
      ...WorkOrderTransitionReasonFragment
    }
  }
  ${WorkOrderTransitionReasonFragment}
`;

export const WorkOrderStageFragment = gql`
  fragment WorkOrderStageFragment on WorkOrderStage {
    id
    name
    isDefault
    color
    createdAt
    updatedAt
    transitionStages {
      ...WorkOrderTransitionStageFragment
    }
  }
  ${WorkOrderTransitionStageFragment}
`;

export const WOStagesDocument = gql`
  query workOrderStages {
    workOrderStages {
      ...WorkOrderStageFragment
    }
  }
  ${WorkOrderStageFragment}
`;
