import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuItem,
  MenuList,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronDown,
  faPen,
  faPlus,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { useExpanded, useFlexLayout, useTable } from "react-table";

import AssetIcon from "../../components/elements/assetIcons";
import CenteredFullHeight from "../../components/elements/centeredFullHeight";
import LabeledMenuButton from "../../components/elements/labeledMenuButton";
import Link from "../../components/elements/link";
import TooltipName from "../../components/elements/tooltipName";
import AddCircle from "../../components/icons/addCircle";
import {
  AssetCategoryConfigQuery,
  AssetCategoryWithTypesFragmentFragment,
  AssetTypeFragmentFragment,
  CompanyFragmentFragment,
  useCurrentCompanyQuery,
  useUpdateAssetCategoryConfigMutation,
} from "../../graphql/graphql";
import { categoriesTableKeysVar } from "../../graphql/reactiveVariables";
import { getRoutePath } from "../../router";
import { sortByOptions } from "../../utils/sort";
import AssetCategoryDelete from "./assetCategoryDelete";
import AssetCategoryVisibilityToggle from "./assetCategoryVisibilityToggle";
import AssetTypeDelete from "./assetTypeDelete/assetTypeDelete";
import AssetTypeMove from "./assetTypeMove";
import AssetTypeVisibilityToggle from "./assetTypeVisibilityToggle";

const assetCategoriesTableKey = "assetCategoriesTableKey";

type AssetCategoryOrType =
  | AssetCategoryWithTypesFragmentFragment
  | AssetTypeFragmentFragment;

interface AssetCategoriesPresenterProps {
  assetCategories: AssetCategoryWithTypesFragmentFragment[];
  assetCategoryConfigData: AssetCategoryConfigQuery;
  company: CompanyFragmentFragment;
  canAddAssetCategory: boolean;
  canEditAssetCategory: boolean;
  canDeleteAssetCategory: boolean;
  canAddAssetType: boolean;
  canEditAssetType: boolean;
  canDeleteAssetType: boolean;
}

const AssetCategoriesPresenter: React.FC<AssetCategoriesPresenterProps> = ({
  assetCategories,
  assetCategoryConfigData,
  canAddAssetCategory,
  canEditAssetCategory,
  canDeleteAssetCategory,
  canAddAssetType,
  canEditAssetType,
  canDeleteAssetType,
  company,
}) => {
  const { hiddenCategoryIds = [], hiddenTypeIds = [] } = company;
  const assetCategoryConfig = assetCategoryConfigData.assetCategoryConfig;
  const [updateAssetCategoryConfigMutation] =
    useUpdateAssetCategoryConfigMutation();

  const toggleHidden = React.useCallback(() => {
    updateAssetCategoryConfigMutation({
      variables: { showHidden: !assetCategoryConfig.showHidden },
    });
  }, [updateAssetCategoryConfigMutation, assetCategoryConfig]);

  const setSort = React.useCallback(
    (value) => {
      const [sortBy, sortOrder] = value.split("-");
      updateAssetCategoryConfigMutation({
        variables: { sortBy, sortOrder },
      });
    },
    [updateAssetCategoryConfigMutation]
  );

  const selectedSortOption = React.useMemo(
    () =>
      sortByOptions.find(
        (option) =>
          option.value ===
          `${assetCategoryConfig.sortBy}-${assetCategoryConfig.sortOrder}`
      ),
    [assetCategoryConfig]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: (assetCategoryOrType: AssetCategoryOrType) =>
          assetCategoryOrType,
        width: 220,
        Cell: ({ value, row }: { value: AssetCategoryOrType; row: any }) =>
          value.__typename === "AssetCategory" ? (
            <Flex
              height="full"
              backgroundColor="secondary.10"
              className={
                hiddenCategoryIds.includes(value.id) ? "disabled-bg" : ""
              }
              paddingX="2"
              paddingY="3"
              alignItems="center"
              position="relative"
              sx={{
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: "100%",
                  backgroundColor: "secondary.100",
                  width: "1px",
                  height: `${
                    value.assetTypes?.length && row.isExpanded
                      ? (value.assetTypes.length + 1) * 88 -
                        (canAddAssetType ? 16 : 98)
                      : canAddAssetType
                      ? 46
                      : 0
                  }px`,
                  left: "15px",
                },
              }}
            >
              <Box width="20px">
                {(value.assetTypes || []).length > 0 ? (
                  <button
                    {...row.getToggleRowExpandedProps({
                      style: { opacity: 0.5 },
                      title: undefined,
                    })}
                    aria-label={`${
                      row.isExpanded ? "Close" : "Open"
                    } asset types`}
                  >
                    <Box transform={row.isExpanded ? "rotate(180deg)" : ""}>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        aria-label="Collapse Row"
                      />
                    </Box>
                  </button>
                ) : null}
              </Box>
              <AssetCategoryName assetCategory={value} />
            </Flex>
          ) : value.__typename === "AssetType" ? (
            <Flex
              height="full"
              backgroundColor="secondary.10"
              className={hiddenTypeIds.includes(value.id) ? "disabled-bg" : ""}
              marginLeft="2rem"
              paddingX="2"
              paddingY="3"
              alignItems="center"
              position="relative"
              sx={{
                "&:before": row.depth
                  ? {
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      borderTop: "1px solid",
                      borderColor: "secondary.100",
                      width: "16px",
                      left: "-16px",
                    }
                  : {},
              }}
            >
              <AssetTypeName assetType={value} />
            </Flex>
          ) : (
            canAddAssetType && (
              <Box marginLeft="8">
                <Flex
                  alignItems="center"
                  backgroundColor="secondary.10"
                  width="full"
                  fontSize="sm"
                  paddingY="3"
                  paddingRight="2"
                  position="relative"
                  sx={{
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      borderTop: "1px solid",
                      borderColor: "secondary.100",
                      width: "16px",
                      left: "-16px",
                    },
                  }}
                >
                  <Link
                    to={getRoutePath("assetTypesCreate", {
                      assetCategoryId: value.id,
                    })}
                    variant="link"
                    colorScheme="secondary"
                    color="gray.800"
                    padding={2}
                    fontSize="sm"
                  >
                    <Box color="gray.600" marginRight="1">
                      <FontAwesomeIcon icon={faPlus} />
                    </Box>
                    <TooltipName
                      name={`Add your own custom asset type for ${value.name}`}
                    />
                  </Link>
                  <Tooltip
                    label="Use this option to add a new custom asset type. You can add your own custom fields, duplicate or import fields from other asset types, choose your own icons and change icon colors."
                    hasArrow
                    placement="bottom"
                  >
                    <Box color="gray.600">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </Box>
                  </Tooltip>
                </Flex>
              </Box>
            )
          ),
      },
      {
        id: "actions",
        width: 100,
        Header: "",
        accessor: (assetCategoryOrType: AssetCategoryOrType) =>
          assetCategoryOrType,
        Cell: ({ value }: { value: AssetCategoryOrType }) => (
          <Flex
            height="full"
            backgroundColor="secondary.10"
            className={
              (value.__typename === "AssetCategory" &&
                hiddenCategoryIds.includes(value.id)) ||
              (value.__typename === "AssetType" &&
                hiddenTypeIds.includes(value.id))
                ? "disabled-bg"
                : ""
            }
            paddingX="2"
            alignItems="center"
            justifyContent="flex-end"
            paddingY="3"
          >
            {value.__typename === "AssetCategory" ? (
              <AssetCategoryActions
                assetCategory={value}
                canDeleteAssetCategory={canDeleteAssetCategory}
                canEditAssetCategory={canEditAssetCategory}
              />
            ) : value.__typename === "AssetType" ? (
              <AssetTypeActions
                assetCategories={assetCategories}
                assetType={value}
                canDeleteAssetType={canDeleteAssetType}
                canEditAssetType={canEditAssetType}
              />
            ) : null}
          </Flex>
        ),
      },
    ],
    [
      assetCategories,
      canAddAssetType,
      canDeleteAssetCategory,
      canDeleteAssetType,
      canEditAssetCategory,
      canEditAssetType,
      hiddenCategoryIds,
      hiddenTypeIds,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    state,
    // toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data: assetCategories,
      initialState: {
        expanded: categoriesTableKeysVar()[assetCategoriesTableKey] || {},
      },
      getSubRows: (assetCategory) =>
        assetCategory.assetTypes
          ? ([
              ...assetCategory.assetTypes,
              {
                __typename: "ADD_ASSET_TYPE",
                id: assetCategory.id,
                name: assetCategory.name,
              },
            ] as any)
          : null,
    },
    useFlexLayout,
    useExpanded
  );

  // React.useEffect(() => {
  //   if (!Object.keys(categoriesTableKeysVar()).length)
  //     toggleAllRowsExpanded(true);
  // }, [toggleAllRowsExpanded]);

  React.useEffect(() => {
    categoriesTableKeysVar({
      ...categoriesTableKeysVar(),
      [assetCategoriesTableKey]: state.expanded,
    });
  }, [state.expanded]);

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" marginBottom="6">
        <Flex alignItems="center">
          <Menu>
            {({ isOpen }) => (
              <>
                <LabeledMenuButton
                  isOpen={isOpen}
                  label="Sort"
                  value={selectedSortOption ? selectedSortOption.label : "Sort"}
                />
                <MenuList>
                  {sortByOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => setSort(option.value)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
          <FormControl display="flex" alignItems="center" marginLeft="2">
            <Switch
              id="showHidden"
              size="sm"
              isChecked={assetCategoryConfig.showHidden}
              onChange={toggleHidden}
            />
            <FormLabel
              htmlFor="showHidden"
              marginBottom="0"
              marginRight="0"
              marginLeft="2"
              fontSize="xs"
            >
              Show hidden categories &amp; types
            </FormLabel>
          </FormControl>
        </Flex>
        <Box>
          {canAddAssetCategory && (
            <Tooltip label="Add new asset category" hasArrow placement="left">
              <Link
                to={getRoutePath("assetCategoriesCreate")}
                aria-label="Add new asset category"
                variant="icon"
                colorScheme="secondary"
              >
                <AddCircle boxSize="32px" />
              </Link>
            </Tooltip>
          )}
        </Box>
      </Flex>
      {assetCategories.length > 0 ? (
        <Box width="100%" overflowX="auto" marginTop="-4">
          <Box {...getTableProps()}>
            <Box {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Fragment key={row.original.id + index}>
                    <Box
                      width="full"
                      alignItems="stretch"
                      fontSize="sm"
                      position="relative"
                      paddingBottom="4"
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <Box {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Box>
                      ))}
                    </Box>
                    {canAddAssetType &&
                      row.original.__typename === "AssetCategory" &&
                      !row.isExpanded && (
                        <Box marginBottom="4" marginLeft="8">
                          <Flex
                            alignItems="center"
                            backgroundColor="secondary.10"
                            width="full"
                            fontSize="sm"
                            paddingY="3"
                            paddingRight="2"
                            position="relative"
                            sx={{
                              "&:before": {
                                content: '""',
                                position: "absolute",
                                top: "50%",
                                borderTop: "1px solid",
                                borderColor: "secondary.100",
                                width: "16px",
                                left: "-16px",
                              },
                            }}
                          >
                            <Link
                              to={getRoutePath("assetTypesCreate", {
                                assetCategoryId: row.original.id,
                              })}
                              variant="link"
                              colorScheme="secondary"
                              color="gray.800"
                              padding={2}
                              fontSize="sm"
                            >
                              <Box color="gray.600" marginRight="1">
                                <FontAwesomeIcon icon={faPlus} />
                              </Box>
                              <TooltipName
                                name={`Add your own custom asset type for ${row.original.name}`}
                              />
                            </Link>
                            <Tooltip
                              label="Use this option to add a new custom asset type. You can add your own custom fields, duplicate or import fields from other asset types, choose your own icons and change icon colors."
                              hasArrow
                              placement="bottom"
                            >
                              <Box color="gray.600">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                              </Box>
                            </Tooltip>
                          </Flex>
                        </Box>
                      )}
                    {row.isExpanded &&
                      !!row.original.assetTypes?.length &&
                      row.original.__typename === "AssetCategory" && (
                        <Box
                          marginBottom="2"
                          marginLeft="8"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          Asset Types
                        </Box>
                      )}
                  </Fragment>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        canAddAssetCategory && (
          <CenteredFullHeight>
            <Link to={getRoutePath("assetCategoriesCreate")}>
              Add an Asset Category
            </Link>
          </CenteredFullHeight>
        )
      )}
    </Box>
  );
};

export default AssetCategoriesPresenter;

const AssetCategoryName = ({
  assetCategory,
}: {
  assetCategory: AssetCategoryWithTypesFragmentFragment;
}) => {
  return (
    <Flex alignItems="center" width="full" flexGrow={1}>
      <AssetIcon
        iconName={assetCategory.iconName}
        iconColor={assetCategory.iconColor}
        iconType={assetCategory.iconType}
        marginRight="2"
      />
      <Box width="full" minWidth="0">
        <TooltipName fontSize="sm" maxWidth="full" name={assetCategory.name} />
      </Box>
    </Flex>
  );
};

const AssetCategoryActions = ({
  assetCategory,
  canEditAssetCategory,
  canDeleteAssetCategory,
}: {
  assetCategory: AssetCategoryWithTypesFragmentFragment;
  canEditAssetCategory: boolean;
  canDeleteAssetCategory: boolean;
}) => {
  const { data } = useCurrentCompanyQuery();

  return (
    <Flex alignItems="center" justifyContent="flex-end">
      {!!assetCategory.companyId &&
        assetCategory.companyId === data?.currentCompany.id && (
          <>
            {canEditAssetCategory && (
              <Tooltip label="Edit This Category" hasArrow placement="bottom">
                <Link
                  to={getRoutePath("assetCategoriesEdit", {
                    assetCategoryId: assetCategory.id,
                  })}
                  aria-label="Edit category"
                  variant="icon"
                  colorScheme="gray"
                  padding={2}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Link>
              </Tooltip>
            )}
            {canDeleteAssetCategory && (
              <AssetCategoryDelete assetCategory={assetCategory} />
            )}
          </>
        )}
      {canEditAssetCategory && (
        <AssetCategoryVisibilityToggle assetCategory={assetCategory} />
      )}
    </Flex>
  );
};

const AssetTypeName = ({
  assetType,
}: {
  assetType: AssetTypeFragmentFragment;
}) => {
  return (
    <Flex alignItems="center" flexGrow={1} width="full">
      <AssetIcon
        iconName={assetType.iconName}
        iconColor={assetType.misc.resolvedIconColor}
        iconType={assetType.iconType}
        marginRight="2"
        iconSize="sm"
      />
      <Link
        to={getRoutePath("assetTypesShow", {
          assetCategoryId: assetType.assetCategoryId,
          assetTypeId: assetType.id,
        })}
        variant="link"
        colorScheme="secondary"
        width="full"
        justifyContent="flex-start"
      >
        <TooltipName fontSize="sm" maxWidth="full" name={assetType.name} />
      </Link>
    </Flex>
  );
};

const AssetTypeActions = ({
  assetType,
  assetCategories,
  canEditAssetType,
  canDeleteAssetType,
}: {
  assetType: AssetTypeFragmentFragment;
  canEditAssetType: boolean;
  canDeleteAssetType: boolean;
  assetCategories: AssetCategoryWithTypesFragmentFragment[];
}) => {
  const { data } = useCurrentCompanyQuery();
  const isAuthorized =
    !!assetType.companyId && assetType.companyId === data?.currentCompany.id;

  return (
    <Flex alignItems="center" justifyContent="flex-end">
      {isAuthorized && (
        <Tooltip label="Default service schedule">
          <Link
            to={getRoutePath("assetTypesMaintenancesShow", {
              assetCategoryId: assetType.assetCategoryId,
              assetTypeId: assetType.id,
            })}
            aria-label="Default service schedule"
            variant="icon"
            colorScheme="gray"
            padding={2}
          >
            <FontAwesomeIcon icon={faBell} />
          </Link>
        </Tooltip>
      )}
      {isAuthorized && canEditAssetType && (
        <Tooltip label="Edit This Asset Type" hasArrow placement="bottom">
          <Link
            to={getRoutePath("assetTypesEdit", {
              assetCategoryId: assetType.assetCategoryId,
              assetTypeId: assetType.id,
            })}
            aria-label="Edit asset type"
            variant="icon"
            colorScheme="gray"
            padding={2}
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
        </Tooltip>
      )}
      {isAuthorized && canEditAssetType && (
        <AssetTypeMove
          assetType={assetType}
          assetCategories={assetCategories}
        />
      )}
      {isAuthorized && canDeleteAssetType && (
        <AssetTypeDelete assetType={assetType} />
      )}
      {canEditAssetType && <AssetTypeVisibilityToggle assetType={assetType} />}
    </Flex>
  );
};
