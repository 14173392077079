import React from "react";

import { useSessionQuery } from "../../../graphql/graphql";
import Presenter from "./presenter";

interface UserDropdownProps {
  simple?: boolean;
}

const UserDropdown: React.FC<UserDropdownProps> = ({ simple }) => {
  const { data } = useSessionQuery();

  if (!data || !data.session.isLoggedIn) return null;

  return <Presenter simple={simple} />;
};

export default UserDropdown;
