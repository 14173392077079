import React, { useCallback, useMemo } from "react";

import FilterSelect, {
  FilterOption,
} from "../../../components/elements/filterSelect";
import { useCompanyUsersQuery } from "../../../graphql/graphql";
import { sortCollator } from "../../../utils/sort";

interface UsersFilterProps {
  onChange: (ids: string[] | undefined) => void;
  value: string[] | undefined;
}

const UsersFilter: React.FC<UsersFilterProps> = ({ onChange, value }) => {
  const { data } = useCompanyUsersQuery();

  const userOptions = useMemo(() => {
    let userOptions: FilterOption[] = [];
    if (data?.companyUsers.length) {
      data.companyUsers.forEach((companyUser) => {
        const label = companyUser.user.fullName || companyUser.user.email;
        userOptions.push({
          label,
          value: companyUser.user.id,
          query: label,
        });
      });
      userOptions.sort((a, b) => sortCollator.compare(a.query, b.query));
    }
    return userOptions;
  }, [data?.companyUsers]);

  const handleChange = useCallback(
    (id: string) => {
      if (!value) {
        onChange([id]);
      } else if (value.includes(id)) {
        onChange(
          value.length === 1 ? undefined : value.filter((v) => v !== id)
        );
      } else {
        onChange([...value, id]);
      }
    },
    [value, onChange]
  );

  return (
    <FilterSelect
      label="Contact"
      selectedOptions={value}
      allOptions={userOptions}
      handleFilterChange={handleChange}
    />
  );
};

export default UsersFilter;
