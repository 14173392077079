import React from "react";

import { FloorPlanIndexFragmentFragment } from "../../graphql/graphql";
import { getRoutePath } from "../../router";
import FloorPlanList from "./list";

interface FloorPlansPresenterProps {
  floorPlans: FloorPlanIndexFragmentFragment[];
  canAddPlan: boolean;
  canEditPlan: boolean;
  canDeletePlan: boolean;
}

const FloorPlansPresenter: React.FC<FloorPlansPresenterProps> = ({
  floorPlans,
  canAddPlan,
  canEditPlan,
  canDeletePlan,
}) => {
  return (
    <FloorPlanList
      createFloorPlanPath={getRoutePath("floorPlansCreate")}
      floorPlans={floorPlans}
      canAddPlan={canAddPlan}
      canEditPlan={canEditPlan}
      canDeletePlan={canDeletePlan}
      virtualizeList
      showLocationColumn
    />
  );
};

export default FloorPlansPresenter;
