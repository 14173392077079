import {
  Box,
  Center,
  Flex,
  Menu,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";

import AssetCategorySelectOption from "../../components/elements/assetCategorySelectOption";
import CenteredFullHeight from "../../components/elements/centeredFullHeight";
import LabeledMenuButton from "../../components/elements/labeledMenuButton";
import Link from "../../components/elements/link";
import MobileAccordion from "../../components/elements/mobileAccordion";
import QrCodes from "../../components/elements/qrCodes";
import AddCircle from "../../components/icons/addCircle";
import {
  AssetCategoryWithTypesFragmentFragment,
  AssetIndexFragmentFragment,
  MaintenanceStatusType,
  SortBy,
  SortOrder,
} from "../../graphql/graphql";
import { CurrentCompanyRole } from "../../graphql/types";
import ROLES from "../../roles";
import { getRoutePath } from "../../router";
import { mainLayoutPaddingX } from "../../utils/layout";
import { sortByOptions } from "../../utils/sort";
import AssetBulkAdd from "./create/assetBulkCreate";
import List from "./list";

interface AssetsPagePresenterProps {
  setSort: (value: string) => void;
  sortBy: SortBy;
  sortOrder: SortOrder;
  setFilter: (value: string[]) => void;
  filter: MaintenanceStatusType[];
  currentCompanyRole: CurrentCompanyRole;
  assets: Array<AssetIndexFragmentFragment>;
  hideCreate?: boolean;
  hideDisplayType?: boolean;
  unfilteredAssetsPresent: boolean;
  assetCategories: AssetCategoryWithTypesFragmentFragment[];
  assetCategory?: AssetCategoryWithTypesFragmentFragment;
  setAssetCategoryId: (id: string) => void;
}

const AssetsPagePresenter: React.FC<AssetsPagePresenterProps> = ({
  setSort,
  sortBy,
  sortOrder,
  setFilter,
  filter,
  currentCompanyRole,
  assets,
  hideCreate = false,
  hideDisplayType = false,
  unfilteredAssetsPresent,
  assetCategories,
  assetCategory,
  setAssetCategoryId,
}) => {
  const selectedSortOption = React.useMemo(
    () =>
      sortByOptions.find((option) => option.value === `${sortBy}-${sortOrder}`),
    [sortBy, sortOrder]
  );
  const bulkActionRef = React.useRef<HTMLDivElement>(null);
  const selectedFilterOptions = maintenanceStatusTypeOptions.filter((option) =>
    filter.includes(option.value)
  );
  const selectedFilterValues = selectedFilterOptions.map(({ value }) => value);
  const selectedFilterLabels =
    selectedFilterOptions.length < 1 || selectedFilterOptions.length > 2
      ? "All"
      : selectedFilterOptions.map(({ label }) => label);

  const canAddAsset = ROLES.assetsCreate.includes(currentCompanyRole.role);
  const canEditAsset = ROLES.assetsEdit.includes(currentCompanyRole.role);
  const canDeleteAsset = ROLES.assetsDelete.includes(currentCompanyRole.role);

  return (
    <Box>
      {unfilteredAssetsPresent ? (
        <>
          <Flex
            alignItems={{ base: "flex-start", md: "center" }}
            marginBottom="6"
            paddingX={mainLayoutPaddingX}
            marginX={{ base: "2", md: "0" }}
          >
            <MobileAccordion>
              <Flex gap="2" wrap={{ base: "wrap", md: "nowrap" }}>
                <Box>
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <LabeledMenuButton
                          isOpen={isOpen}
                          value={
                            selectedSortOption
                              ? selectedSortOption.label
                              : "Sort"
                          }
                        />
                        <MenuList>
                          {sortByOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              onClick={() => setSort(option.value)}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </Box>
                <Box>
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <LabeledMenuButton
                          isOpen={isOpen}
                          label="Category"
                          value={
                            assetCategory ? (
                              <AssetCategorySelectOption
                                assetCategory={assetCategory}
                              />
                            ) : (
                              "All"
                            )
                          }
                          styleProps={{
                            maxWidth: "none !important",
                            width: "150px",
                          }}
                        />
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setAssetCategoryId("");
                            }}
                          >
                            All
                          </MenuItem>
                          {assetCategories.map((ac) => (
                            <MenuItem
                              key={ac.id}
                              onClick={() => {
                                if (ac.id === assetCategory?.id) return;
                                setAssetCategoryId(ac.id);
                              }}
                            >
                              <AssetCategorySelectOption assetCategory={ac} />
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </Box>
                <Box>
                  <Menu closeOnSelect={false}>
                    {({ isOpen }) => (
                      <>
                        <LabeledMenuButton
                          isOpen={isOpen}
                          value={
                            Array.isArray(selectedFilterLabels) ? (
                              <Flex align="center">
                                {selectedFilterLabels.map((fl: any, index) => (
                                  <Box key={index} mr="2">
                                    {fl}
                                  </Box>
                                ))}
                              </Flex>
                            ) : (
                              selectedFilterLabels
                            )
                          }
                          label="Status"
                          styleProps={{
                            maxWidth: "none !important",
                            width: "180px",
                          }}
                        />
                        <MenuList>
                          <MenuOptionGroup
                            type="checkbox"
                            onChange={(value) =>
                              setFilter(
                                Array.isArray(value)
                                  ? value.includes("All")
                                    ? [
                                        MaintenanceStatusType.Current,
                                        MaintenanceStatusType.DueIn_30,
                                        MaintenanceStatusType.PastDue,
                                      ]
                                    : value
                                  : [value]
                              )
                            }
                            value={selectedFilterValues}
                          >
                            <MenuItemOption value="All">All</MenuItemOption>
                            {maintenanceStatusTypeOptions.map((option) => (
                              <MenuItemOption
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItemOption>
                            ))}
                          </MenuOptionGroup>
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </Box>
              </Flex>
            </MobileAccordion>
            <QrCodes
              links={assets.map((a: any) => ({
                path: getRoutePath("assetsShow", { assetId: a.id }),
                name: a.name,
              }))}
              printDocumentTitle="Assets QR Codes"
              colorScheme="secondary"
              fontSize="xl"
              color="gray.700"
              ml="2"
              display={{ base: "none", md: "block" }}
            />
            <Spacer />
            <Box
              ref={bulkActionRef}
              width={{ base: "100%", sm: "auto", md: "100%", xl: "auto" }}
              flexGrow={{ sm: 1 }}
            />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginLeft={{ base: "4", md: "0" }}
            >
              {!hideCreate && canAddAsset && (
                <>
                  <AssetBulkAdd />
                  <Tooltip label="Add new asset" hasArrow placement="left">
                    <Link
                      to={getRoutePath("assetsCreate")}
                      aria-label="Add new asset"
                      variant="icon"
                      colorScheme="secondary"
                    >
                      <AddCircle boxSize="32px" />
                    </Link>
                  </Tooltip>
                </>
              )}
            </Flex>
          </Flex>
          {assets.length > 0 ? (
            <List
              assets={assets}
              canDeleteAsset={canDeleteAsset}
              canEditAsset={canEditAsset}
              bulkActionRef={bulkActionRef}
            />
          ) : (
            <Center height="100px" color="gray.700">
              Assets empty
            </Center>
          )}
        </>
      ) : (
        <CenteredFullHeight>
          {canAddAsset && !hideCreate ? (
            <Link to={getRoutePath("assetsCreate")}>Add an Asset</Link>
          ) : (
            <Box color="gray.700">
              Assets empty
              {canAddAsset && (
                <>
                  .{" "}
                  <Link
                    to={getRoutePath("assetsCreate")}
                    variant="link"
                    colorScheme="secondary"
                  >
                    {" "}
                    Add new asset
                  </Link>
                </>
              )}
            </Box>
          )}
        </CenteredFullHeight>
      )}
    </Box>
  );
};

export default AssetsPagePresenter;

export const maintenanceStatusTypeOptions = [
  {
    label: (
      <Flex align="center">
        <Box width="2" height="5" mr="1" backgroundColor="red.500" />
        <Text>Past Due</Text>
      </Flex>
    ),
    value: MaintenanceStatusType.PastDue,
    query: "Past Due",
  },
  {
    label: (
      <Flex align="center">
        <Box width="2" height="5" mr="1" backgroundColor="orange.500" />
        <Text>Due Soon</Text>
      </Flex>
    ),
    value: MaintenanceStatusType.DueIn_30,
    query: "Due Soon",
  },
  {
    label: (
      <Flex align="center">
        <Box width="2" height="5" mr="1" backgroundColor="green.500" />
        <Text>Current</Text>
      </Flex>
    ),
    value: MaintenanceStatusType.Current,
    query: "Current",
  },
];
