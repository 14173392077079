import { useReactiveVar } from "@apollo/client";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { PLAN_EDITOR_PROMPT } from "../../../constants/lang/en";
import { useFloorPlanQuery } from "../../../graphql/graphql";
import {
  changePlanEditorOptions,
  isNativeWebViewVar,
  planEditorOptionsVar,
} from "../../../graphql/reactiveVariables";
import PageSpinner from "../pageSpinner";
import Presenter from "./presenter";

interface FloorPlanProps {
  goBack?: boolean;
}

const FloorPlan: React.FC<FloorPlanProps> = ({ goBack }) => {
  const [isNative] = useState(isNativeWebViewVar());
  const [, setSearchParams] = useSearchParams();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { unsavedChanges } = useReactiveVar(planEditorOptionsVar);

  const handleClose = useCallback(() => {
    if (!unsavedChanges || window.confirm(PLAN_EDITOR_PROMPT)) {
      changePlanEditorOptions({ unsavedChanges: false });
      onClose();
      if (goBack) {
        setTimeout(() => {
          setSearchParams({});
        }, 500);
      }
    }
  }, [onClose, goBack, setSearchParams, unsavedChanges]);

  return (
    <>
      {!isNative ? (
        <Modal isOpen={isOpen} onClose={handleClose} size="full">
          <ModalOverlay>
            <ModalContent
              margin="0"
              height="screenFull"
              backgroundColor="gray.100"
              overflowY="auto"
            >
              <ModalCloseButton zIndex="10" />
              <ModalBody padding="0" backgroundColor="white">
                <LeafletPresenter />
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      ) : (
        <LeafletPresenter />
      )}
    </>
  );
};

const LeafletPresenter: React.FC = () => {
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("planId");
  const assetId = searchParams.get("assetId");
  const assetPartId = searchParams.get("assetPartId");
  const maintenanceStatuses = searchParams.get("maintenanceStatuses");
  const {
    assetCategoryIds,
    assetTypeIds,
    assetSearchQuery,
    assetMaintenanceStatus,
  } = useReactiveVar(planEditorOptionsVar);
  const { isOpen, onOpen } = useDisclosure();
  const { data } = useFloorPlanQuery({
    variables: { id: planId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const floorPlan = data?.floorPlan;

  useEffect(() => {
    changePlanEditorOptions({
      planId: planId as string,
      assetIds: assetId ? [assetId as string] : [],
      assetPartIds: assetPartId ? [assetPartId as string] : [],
      assetMaintenanceStatus: maintenanceStatuses
        ? (maintenanceStatuses as string).split(",")
        : [],
      assetColors: {},
      assetPartColors: {},
      unsavedChanges: false,
    });
    onOpen();
  }, [assetId, assetPartId, onOpen, planId, maintenanceStatuses]);

  const floorPlanAssets = useMemo(() => {
    if (!floorPlan) return [];

    const assetTypesPresent = assetTypeIds.length;
    const assetCategoriesPresent = assetCategoryIds.length;
    const assetMaintenanceStatusesPresent = assetMaintenanceStatus.length;
    const assetSearchQueryLowerCased = assetSearchQuery
      ? assetSearchQuery.toLowerCase()
      : undefined;
    return floorPlan.floorPlanAssets.filter((fpa) => {
      if (assetTypesPresent && !assetTypeIds.includes(fpa.asset.assetType.id)) {
        return false;
      }
      if (
        assetCategoriesPresent &&
        !assetCategoryIds.includes(fpa.asset.assetType.assetCategoryId)
      ) {
        return false;
      }
      if (
        assetMaintenanceStatusesPresent &&
        !assetMaintenanceStatus.includes(fpa.asset.maintenanceStatus)
      ) {
        return false;
      }
      if (
        assetSearchQueryLowerCased &&
        !fpa.asset.name.toLowerCase().includes(assetSearchQueryLowerCased)
      ) {
        return false;
      }
      return true;
    });
  }, [
    assetCategoryIds,
    assetMaintenanceStatus,
    assetSearchQuery,
    assetTypeIds,
    floorPlan,
  ]);

  return isOpen && floorPlan ? (
    <Presenter floorPlan={floorPlan} floorPlanAssets={floorPlanAssets} />
  ) : (
    <PageSpinner />
  );
};

export default FloorPlan;

gql`
  query FloorPlan($id: UUID!) {
    floorPlan(id: $id) {
      ...FloorPlanForMapFragment
    }
  }
`;
