import { Input, Textarea } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { sortBy } from "lodash";
import React from "react";

import {
  AssetPartFieldFragmentFragment,
  AssetPartWithRelationsFragmentFragment,
  AssetTypeWithRelationsFragmentFragment,
  FieldType,
} from "../../../graphql/graphql";
import { yupBoolean, yupString } from "../../../utils/validation";
import FormGroup from "../../elements/formGroup";
import Autosuggest from "../autosuggest";
import CheckBoxInput from "../checkbox";
import SelectCombobox from "../selectCombobox";

interface AssetPartFieldsProps {
  assetPartFields: AssetPartFieldFragmentFragment[];
}

const AssetPartFields: React.FC<AssetPartFieldsProps> = ({
  assetPartFields,
}) => {
  const fields = sortBy(assetPartFields, "order");
  const { getFieldProps } = useFormikContext();
  return (
    <>
      {fields.map((assetPartField) =>
        assetPartField.type === FieldType.Boolean ? (
          <CheckBoxInput
            key={assetPartField.id}
            label={assetPartField.label}
            name={assetPartField.id}
          />
        ) : assetPartField.type === FieldType.Select ? (
          <SelectCombobox
            key={assetPartField.id}
            label={assetPartField.label}
            name={assetPartField.id}
            options={
              assetPartField.selectOptions
                ? assetPartField.selectOptions
                    .split("\n")
                    .map((so) => ({ label: so, query: so, value: so }))
                : []
            }
          />
        ) : (
          <FormGroup
            key={assetPartField.id}
            label={assetPartField.label}
            name={assetPartField.id}
          >
            {assetPartField.type === FieldType.Text ? (
              <Textarea
                autoComplete="off"
                {...getFieldProps(assetPartField.id)}
              />
            ) : assetPartField.type === FieldType.Autosuggest &&
              assetPartField.selectOptions ? (
              <Autosuggest
                items={assetPartField.selectOptions.split("\n")}
                autoComplete="off"
                {...getFieldProps(assetPartField.id)}
              />
            ) : (
              <Input autoComplete="off" {...getFieldProps(assetPartField.id)} />
            )}
          </FormGroup>
        )
      )}
    </>
  );
};

export default AssetPartFields;

export const getAssetPartFieldsValidationSchema = (
  assetType: AssetTypeWithRelationsFragmentFragment
) => {
  const yupShape: any = {};

  assetType.assetPartFields.forEach((assetPartField) => {
    let schema =
      assetPartField.type === FieldType.Boolean ? yupBoolean() : yupString();
    schema = schema.label(assetPartField.label);
    if (assetPartField.required) schema = schema.required();
    if (schema.isType("string") && assetPartField.validationRegExp) {
      try {
        schema = (schema as any).matches(
          new RegExp(assetPartField.validationRegExp),
          {
            message:
              assetPartField.validationRegExpMessage ||
              "Please enter correct value",
            excludeEmptyString: true,
          }
        );
      } catch (e) {}
    }
    yupShape[assetPartField.id] = schema;
  });

  return yupShape;
};

export const getAssetPartFieldsInitialValues = (
  assetType: AssetTypeWithRelationsFragmentFragment,
  assetPart?: AssetPartWithRelationsFragmentFragment
) => {
  const initialValues: any = {};
  assetType.assetPartFields.forEach((assetPartField) => {
    initialValues[assetPartField.id] =
      assetPartField.type === FieldType.Boolean ? false : "";
    if (assetPart) {
      const assetPartFieldValue = assetPart.assetPartFieldValues.find(
        (apft) => apft.assetPartFieldId === assetPartField.id
      );
      if (assetPartFieldValue && assetPartFieldValue.value)
        initialValues[assetPartField.id] = assetPartFieldValue.value;
    }
  });
  return initialValues;
};
