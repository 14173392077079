import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  CompanyPlanInterval,
  CompanyPlanName,
  useSessionQuery,
} from "../../graphql/graphql";
import { layoutOptionsVar } from "../../graphql/reactiveVariables";
import { getRoutePath } from "../../router";
import Presenter from "./presenter";

interface SignUpPageProps {}

const SignUpPage: React.FC<SignUpPageProps> = () => {
  const { data } = useSessionQuery();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (data?.session?.isLoggedIn) {
      navigate(getRoutePath("dashboard"));
    }
  }, [data, navigate]);

  React.useEffect(() => {
    layoutOptionsVar({
      title: "Start your free trial",
      subTitle: "No credit card required. Cancel anytime.",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Start your free trial</title>
      </Helmet>
      <Presenter
        companyPlanName={
          searchParams.has("planName")
            ? CompanyPlanName[
                searchParams.get("planName") as keyof typeof CompanyPlanName
              ]
            : undefined
        }
        companyPlanInterval={
          searchParams.has("planInterval")
            ? CompanyPlanInterval[
                searchParams.get(
                  "planInterval"
                ) as keyof typeof CompanyPlanInterval
              ]
            : undefined
        }
      />
    </>
  );
};

export default SignUpPage;
