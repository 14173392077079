import { EuiFieldText, EuiFormRow, EuiText, useEuiTheme } from "@elastic/eui";
import { css } from "@emotion/react";
import React, { ChangeEvent, useEffect, useState } from "react";

import Label from "../../../../components/label";
import { WorkOrder } from "../../../../models/workOrder";

interface WorkOrderServicesTitlePresenterProps {
  workOrder: WorkOrder;
  placeholder?: string;
  hideRequiredMark?: boolean;
  changeIsEdit?: (isEditMode: boolean) => void;
  onTitleChange: (title: string) => void;
}

const WorkOrderServicesTitlePresenter: React.FC<
  WorkOrderServicesTitlePresenterProps
> = ({
  workOrder,
  placeholder,
  hideRequiredMark,
  onTitleChange,
  changeIsEdit,
}) => {
  const { euiTheme } = useEuiTheme();
  const [titleErrors, setTitleErrors] = useState<string[]>();
  const [name, setName] = useState<string>(workOrder?.name || "");
  const [isInput, setIsInput] = useState<boolean>(false);
  const [initialName] = useState<string>(workOrder?.name || "");

  const checkTitleErrors = (title: string | null) => {
    if (!title?.trim()) {
      setTitleErrors(["Service Title is required"]);
      return true;
    }
    if (title.trim().length > 150) {
      setTitleErrors(["The max number of characters is 150"]);
      return true;
    }
    setTitleErrors([]);
    return false;
  };

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    changeIsEdit && changeIsEdit(true);
    const name: string = e.target.value as string;
    setName(name);
    checkTitleErrors(name);
  };

  const onSaveChanges = (e: ChangeEvent<HTMLInputElement>) => {
    changeIsEdit && changeIsEdit(false);
    if (!titleErrors?.length) {
      onTitleChange(name);
      setIsInput(false);
    }
    if (!name.length) {
      onTitleChange(initialName);
    }
  };

  useEffect(() => {
    setName(workOrder?.name || "");
  }, [workOrder.name]);

  let style = {
    fontSize: "22px",
    fontWeight: 500,
  };
  return (
    <>
      <EuiFormRow
        className="validated-control service-title"
        isInvalid={!!titleErrors?.length}
        error={titleErrors}
        style={{ fontWeight: 700, maxWidth: "unset" }}
      >
        <div style={{ position: "relative" }}>
          <Label
            label="Title"
            required={!hideRequiredMark}
            style={{ paddingBottom: "5px" }}
          />
          {isInput && (
            <EuiFieldText
              name="name"
              isInvalid={!!titleErrors?.length}
              onChange={onChangeTitle}
              onBlur={onSaveChanges}
              fullWidth={true}
              style={{
                ...style,
                paddingLeft: euiTheme.size.xs,
                paddingRight: !!titleErrors?.length
                  ? euiTheme.size.xl
                  : euiTheme.size.s,
              }}
              value={name}
              autoFocus={true}
              css={css`
                color: var(--color-text);
              `}
            />
          )}
          {!isInput && (
            <EuiText
              title={name}
              className={"ellipsis"}
              style={{
                ...style,
                cursor: "pointer",
                marginBottom: "-0px",
                height: "40px",
                letterSpacing: "normal",
                lineHeight: euiTheme.size.xxl,
              }}
              onClick={() => setIsInput(true)}
              css={css`
                color: var(--color-text);

                &:hover {
                  background: var(--color-hover);
                }
              `}
            >
              {workOrder.name}
            </EuiText>
          )}
          {!name && (
            <EuiText
              className={"placeholder"}
              style={{
                top: "unset",
                left: "4px",
                bottom: "10px",
                lineHeight: "21px",
              }}
            >
              {placeholder}
            </EuiText>
          )}
        </div>
      </EuiFormRow>
    </>
  );
};
export default WorkOrderServicesTitlePresenter;
