import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";

import PageSpinner from "../../../components/elements/pageSpinner";
import SubscriptionPrice from "../../../components/elements/subscriptionPrice";
import PlanIcon from "../../../components/icons/planIcon";
import {
  PlansFragmentFragment,
  useStripeNewPlanAmountQuery,
  useStripeProrationAmountQuery,
} from "../../../graphql/graphql";
import CouponForm from "../couponForm";

interface CheckoutHeaderProps {
  setActivePlan: (plan?: PlansFragmentFragment) => void;
  activePlan: PlansFragmentFragment;
}

const CheckoutHeader: React.FC<CheckoutHeaderProps> = ({
  activePlan,
  setActivePlan,
}) => {
  const { data: currentPlanCancelProration } = useStripeProrationAmountQuery({
    variables: { newQuantity: 0 },
    fetchPolicy: "no-cache",
  });
  const { data: newPlanAmount } = useStripeNewPlanAmountQuery({
    variables: { newPlan: activePlan.stripeId },
    fetchPolicy: "no-cache",
  });

  return (
    <>
      <Helmet>
        <title>Your Selected Plan</title>
      </Helmet>
      <Heading as="h1" marginBottom="4" textAlign="center">
        Your Selected Plan
      </Heading>
      <Box
        paddingY="2"
        paddingX="5"
        backgroundColor="secondary.10"
        marginBottom="4"
      >
        <Flex
          alignItems="center"
          flexDirection={{ base: "column", xs: "row" }}
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <PlanIcon planName={activePlan.productName} boxSize="24px" />
            <Heading as="h2" size="lg" marginLeft="2">
              {activePlan.productName}
            </Heading>
          </Flex>
          <Heading paddingY="2" color="gray.800" textAlign="center">
            <SubscriptionPrice
              name={activePlan.productName}
              amount={activePlan.amount}
              interval={activePlan.interval}
            />
          </Heading>
          <Button
            size="xs"
            variant="outline"
            colorScheme="secondary"
            marginY="2"
            onClick={() => setActivePlan(undefined)}
          >
            Change
          </Button>
        </Flex>
      </Box>
      {currentPlanCancelProration && newPlanAmount ? (
        <CouponForm
          planAmount={newPlanAmount.stripeNewPlanAmount}
          prorationAmount={
            currentPlanCancelProration.stripeProrationAmount.amount
          }
          selectedPlanName={activePlan.productName}
        />
      ) : (
        <PageSpinner height={100} />
      )}
    </>
  );
};

export default CheckoutHeader;

gql`
  query StripeProrationAmount($newPlan: String, $newQuantity: Float) {
    stripeProrationAmount(newPlan: $newPlan, newQuantity: $newQuantity) {
      amount
      dueDate
    }
  }
  query StripeNewPlanAmount($newPlan: String!) {
    stripeNewPlanAmount(newPlan: $newPlan)
  }
`;
