import { Icon, IconProps } from "@chakra-ui/react";

const Elevator1 = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M105.959 14H22.022c-4.43 0-8.032 3.602-8.032 8.032v83.956c0 4.41 3.602 8.012 8.032 8.012h83.956c4.43 0 8.032-3.602 8.032-8.031V22.031c-.02-4.429-3.622-8.031-8.051-8.031Zm-44.744 94.449H22.022a2.461 2.461 0 0 1-2.461-2.461V22.031a2.461 2.461 0 0 1 2.46-2.46h39.194v88.878Zm44.763-.02H66.766V19.551h39.193a2.461 2.461 0 0 1 2.46 2.46v83.958h.02a2.461 2.461 0 0 1-2.461 2.46Z"
      fill="currentColor"
    />
    <path
      d="M51.136 66.146a2.792 2.792 0 0 0-3.937 0l-4.036 4.035V51.106a2.772 2.772 0 0 0-2.775-2.775 2.772 2.772 0 0 0-2.776 2.775v19.075l-4.035-4.016a2.792 2.792 0 0 0-3.937 0 2.792 2.792 0 0 0 0 3.938l8.78 8.76c.55.55 1.259.806 1.968.806.708 0 1.417-.275 1.968-.807l8.78-8.78a2.792 2.792 0 0 0 0-3.936ZM98.34 57.917l-8.78-8.78a2.754 2.754 0 0 0-1.968-.806c-.708 0-1.417.275-1.968.807l-8.78 8.78a2.792 2.792 0 0 0 0 3.937 2.792 2.792 0 0 0 3.937 0l4.036-4.036v19.075a2.772 2.772 0 0 0 2.775 2.775 2.772 2.772 0 0 0 2.776-2.775V57.819l4.035 4.035a2.792 2.792 0 0 0 3.938 0 2.792 2.792 0 0 0 0-3.937Z"
      fill="currentColor"
    />
  </Icon>
);

export default Elevator1;
