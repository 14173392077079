import { useReactiveVar } from "@apollo/client";
import {
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiToolTip,
  useEuiTheme,
} from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import React, { useEffect, useState } from "react";

import Accordion from "../../../components/accordion";
import Combobox from "../../../components/combobox";
import Label from "../../../components/label";
import Select from "../../../components/select";
import { MessageUtils } from "../../../helpers/messages";
import { companyChanged } from "../../../helpers/workOrdersReactiveVariables";
import { WorkOrder } from "../../../models/workOrder";

interface WorkOrderCreateServicesServiceAssigneeProps {
  workOrder: WorkOrder;
  userOptions: EuiComboBoxOptionOption<string>[];
  onWorkOrderChange: (workOrder: WorkOrder) => void;
}

const WorkOrderCreateServicesServiceAssigneePresenter: React.FC<
  WorkOrderCreateServicesServiceAssigneeProps
> = ({ workOrder, userOptions, onWorkOrderChange }) => {
  const { euiTheme } = useEuiTheme();
  const minSelectorWidth = "235px";
  const maxSelectorWidth = "235px";
  const teamsAvailable = false;
  const [accountableErrors, setAccountableErrors] = useState<string[]>([]);
  const [accountableTouched, setAccountableTouched] = useState<boolean>(false);
  const [responsibleErrors, setResponsibleErrors] = useState<string[]>([]);
  const [responsibleTouched, setResponsibleTouched] = useState<boolean>(false);
  const [resetControl, setResetControl] = useState<{ reset: boolean }>({
    reset: false,
  });
  useEffect(() => {
    if (accountableTouched && !workOrder.accountableUserId) {
      setAccountableErrors([MessageUtils.commonRequiredFieldErrorMessage]);
    } else {
      setAccountableErrors([]);
    }
  }, [accountableTouched, workOrder]);
  useEffect(() => {
    if (responsibleTouched && !workOrder.responsibleUserIds?.length) {
      setResponsibleErrors([MessageUtils.commonRequiredFieldErrorMessage]);
    } else {
      setResponsibleErrors([]);
    }
  }, [responsibleTouched, workOrder]);

  const onAccountableSelected = (value: string) => {
    onWorkOrderChange({ accountableUserId: value });
  };
  const onResponsibleSelected = (value: string[]) => {
    onWorkOrderChange({ responsibleUserIds: value });
  };
  const onInformedSelected = (value: string[]) => {
    onWorkOrderChange({ informingUserIds: value });
  };
  const onConsultedSelected = (value: string[]) => {
    onWorkOrderChange({ consultingUserIds: value });
  };

  const onAccountableBlur = () => {
    setAccountableTouched(true);
  };

  const onResponsibleBlur = () => {
    setResponsibleTouched(true);
  };

  const isCompanyChanged: { changed: boolean } = useReactiveVar(companyChanged);

  useEffect(() => {
    if (isCompanyChanged.changed) {
      resetErrors();
    }
  }, [isCompanyChanged]);

  const resetErrors = () => {
    setAccountableTouched(false);
    setResponsibleTouched(false);
    setResetControl({ reset: true });
  };

  return (
    <div>
      <Accordion
        id="wo-service-plan"
        label="Service Assignee"
        required={true}
        initialIsOpen={true}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
        >
          {teamsAvailable ? (
            <>
              <EuiFlexItem
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  gap: euiTheme.size.s,
                }}
              >
                <Label label="Team" disabled={true} />
                <div
                  style={{
                    minWidth: minSelectorWidth,
                    maxWidth: maxSelectorWidth,
                  }}
                >
                  <Select
                    disabled={true}
                    options={[]}
                    placeholder={"Select Team"}
                    onSelect={() => {}}
                    compressed={true}
                  />
                </div>
              </EuiFlexItem>
              <EuiFlexItem
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  marginLeft: "132px",
                  gap: euiTheme.size.s,
                }}
              >
                <EuiCheckbox
                  disabled={true}
                  id={"team"}
                  label="Assign to the whole Team"
                  onChange={() => {}}
                />
              </EuiFlexItem>
            </>
          ) : null}
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
              minHeight: "34px",
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Accountable person is the one who delegates and reviews
                  that work related to the execution of the Work Order
                </div>
              }
            >
              <Label label="Accountable" required={true} />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <Combobox
                options={userOptions}
                selectedValue={workOrder.accountableUserId}
                required={true}
                autoSelect={true}
                onChange={onAccountableSelected}
                placeholder={"Select Accountable"}
                invalid={!!accountableErrors?.length}
                errors={accountableErrors}
                onBlur={onAccountableBlur}
                compressed={true}
                height={"auto"}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>

          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Responsible person is the one who actually does the work
                </div>
              }
            >
              <Label label="Responsible" required={true} />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <Combobox
                options={userOptions}
                selectedValue={workOrder.responsibleUserIds}
                required={true}
                autoSelect={true}
                placeholder={"Select Responsible"}
                singleSelect={false}
                invalid={!!responsibleErrors?.length}
                errors={responsibleErrors}
                onBlur={onResponsibleBlur}
                onChange={onResponsibleSelected}
                height={"auto"}
                compressed={true}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Consulted person is the one whose opinion is sought and
                  who provides input and feedback on the work
                </div>
              }
            >
              <Label label="Consulted" />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <Combobox
                options={userOptions}
                selectedValue={workOrder.consultingUserIds}
                singleSelect={false}
                autoSelect={false}
                placeholder={"Select Consulted"}
                onChange={onConsultedSelected}
                height={"auto"}
                compressed={true}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <EuiToolTip
              className={"tooltip-wrapper"}
              content={
                <div className={"tooltip"}>
                  The Informed person is the one who is kept up-to-date on
                  progress
                </div>
              }
            >
              <Label label="Informed" />
            </EuiToolTip>
            <div
              style={{ minWidth: minSelectorWidth, maxWidth: maxSelectorWidth }}
            >
              <Combobox
                options={userOptions}
                selectedValue={workOrder?.informingUserIds}
                singleSelect={false}
                autoSelect={false}
                placeholder={"Select Informed"}
                onChange={onInformedSelected}
                height={"auto"}
                compressed={true}
                resetControl={resetControl}
              />
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Accordion>
    </div>
  );
};

export default WorkOrderCreateServicesServiceAssigneePresenter;
