import { useEffect, useState } from "react";

import {
  AssetWithRelationsFragmentFragment,
  LocationIndexFragmentFragment,
  useLocationIndexQuery,
} from "../graphql/graphql";

const useAssetLocations = (asset: AssetWithRelationsFragmentFragment) => {
  const { data } = useLocationIndexQuery();
  const [locations, setLocations] = useState<LocationIndexFragmentFragment[]>();

  useEffect(() => {
    const locationIds = asset.floorPlanAssets.map(
      (fpa) => fpa.floorPlan.locationId
    );
    setLocations(
      data ? data.locations.filter((l) => locationIds.includes(l.id)) : []
    );
  }, [asset.floorPlanAssets, data, setLocations]);

  return locations;
};

export default useAssetLocations;
