import { Icon, IconProps } from "@chakra-ui/react";

const Faucet = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M107.875 35.313c-18.57 0-18.603-.221-20.925.663-.02.021 1.023-.39-29.7 11.315v-3.54a3.375 3.375 0 0 0-3.375-3.375h-1.688V25.292l34.569-8.642a3.375 3.375 0 0 0-1.637-6.549c-65.384 16.346-61.09 15.15-61.88 15.71a3.339 3.339 0 0 0-1.194 1.53c-.326.833-.232.323-.232 13.035h-1.688a3.375 3.375 0 0 0-3.375 3.375v54.58c-3.928 1.393-6.75 5.144-6.75 9.544v6.75A3.375 3.375 0 0 0 13.377 118h47.25A3.375 3.375 0 0 0 64 114.625v-6.75c0-4.4-2.822-8.151-6.75-9.544V80.994l30.375-20.25v8.319A3.375 3.375 0 0 0 91 72.438h20.25a3.375 3.375 0 0 0 3.375-3.375V58.938A3.375 3.375 0 0 0 118 55.563V45.438c0-5.583-4.542-10.125-10.125-10.125ZM23.5 47.125h27V97.75h-27V47.125Zm5.063-15.927 16.875-4.219v13.396H28.563v-9.177ZM57.25 111.25h-40.5v-3.375a3.379 3.379 0 0 1 3.375-3.375h33.75a3.379 3.379 0 0 1 3.375 3.375v3.375Zm0-38.369V54.514l32.103-12.23c.815-.31-.306-.221 18.522-.221a3.379 3.379 0 0 1 3.375 3.375v6.75H89.312c-.666 0-1.317.197-1.872.566L57.25 72.882Zm50.625-7.193h-13.5v-6.75h13.5v6.75Z"
      fill="currentColor"
    />
    <path
      d="M103.087 81.504a3.376 3.376 0 0 0-3.924 0c-.54.386-13.225 9.582-13.225 21.309 0 8.374 6.813 15.187 15.187 15.187 8.374 0 15.187-6.813 15.187-15.187 0-11.727-12.685-20.923-13.225-21.31Zm-1.962 29.746c-4.653 0-8.438-3.785-8.438-8.437 0-5.599 5.032-11.237 8.443-14.238 3.411 2.99 8.432 8.61 8.432 14.238 0 4.652-3.784 8.437-8.437 8.437Z"
      fill="currentColor"
    />
  </Icon>
);

export default Faucet;
