import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  GeoJSONFeatureCollectionScalar: any;
  GeoJSONFeatureScalar: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** JS RegExp represented as string */
  RegExp: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: any;
};

export enum AssetCategoryDisplayType {
  Grid = "GRID",
  List = "LIST",
}

export enum AssetDisplayType {
  Grid = "GRID",
  List = "LIST",
}

export enum AssetTypeDisplayType {
  Grid = "GRID",
  List = "LIST",
}

export type SimpleEntity = {
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
};

export type AddedBy = {
  __typename?: "AddedBy";
  userEmail: Scalars["String"];
  userId: Scalars["String"];
  userName?: Maybe<Scalars["String"]>;
  userPhone?: Maybe<Scalars["String"]>;
};

export type Android = {
  __typename?: "Android";
  minimumSupportedVersion: Scalars["String"];
};

export type AppSettings = {
  __typename?: "AppSettings";
  plaidOpen: Scalars["Boolean"];
  sideNavMinimized: Scalars["Boolean"];
};

export type Asset = {
  __typename?: "Asset";
  assetAffectedAssets: Array<AssetAffectedAsset>;
  assetAffectedByAssets: Array<AssetAffectedAsset>;
  /** Contact company users */
  assetCompanyUsers: Array<AssetCompanyUser>;
  assetFieldValues: Array<AssetFieldValue>;
  assetFiles: Array<AssetFile>;
  assetParts: Array<AssetPart>;
  assetType: AssetType;
  assetTypeId: Scalars["UUID"];
  company: Company;
  companyId: Scalars["UUID"];
  config: Scalars["JSONObject"];
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  floorPlanAssets: Array<FloorPlanAsset>;
  id: Scalars["UUID"];
  locationsLabel: Scalars["String"];
  maintenanceStatus: MaintenanceStatusType;
  maintenances: Array<Maintenance>;
  name: Scalars["String"];
  updatedAt: Scalars["Timestamp"];
};

export type AssetAffectedAsset = {
  __typename?: "AssetAffectedAsset";
  affectedAsset: Asset;
  affectedAssetId: Scalars["UUID"];
  asset: Asset;
  assetId: Scalars["UUID"];
  assetPart?: Maybe<AssetPart>;
  assetPartId?: Maybe<Scalars["UUID"]>;
  pathPoints?: number[];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type AssetAffectedAssetCreateInput = {
  affectedAssetId: Scalars["UUID"];
  assetId: Scalars["UUID"];
  assetPartId?: InputMaybe<Scalars["UUID"]>;
  pathPoints?: number[];
};

export type AssetAffectedAssetUpdateInput = {
  id: Scalars["UUID"];
  assetId?: InputMaybe<Scalars["UUID"]>;
  affectedAssetId?: InputMaybe<Scalars["UUID"]>;
  assetPartId?: InputMaybe<Scalars["UUID"]>;
  pathPoints?: number[];
};

export type AssetCategory = {
  __typename?: "AssetCategory";
  assetTypes?: Maybe<Array<AssetType>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["Timestamp"];
  default: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  floorPlanAssetCategories: Array<FloorPlanAssetCategory>;
  iconColor: Scalars["String"];
  iconName: Scalars["String"];
  iconType: IconTypes;
  id: Scalars["UUID"];
  name: Scalars["String"];
  updatedAt: Scalars["Timestamp"];
};

export type AssetCategoryConfig = {
  __typename?: "AssetCategoryConfig";
  displayType: AssetCategoryDisplayType;
  showHidden: Scalars["Boolean"];
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export type AssetCategoryInput = {
  description?: InputMaybe<Scalars["String"]>;
  iconColor: Scalars["String"];
  iconName: Scalars["String"];
  iconType: IconTypes;
  name: Scalars["String"];
};

export type AssetCompanyUser = {
  __typename?: "AssetCompanyUser";
  asset: Asset;
  assetId: Scalars["UUID"];
  companyUser: CompanyUser;
  companyUserId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type AssetConfig = {
  __typename?: "AssetConfig";
  activeCollapsible: Array<Scalars["String"]>;
  activeTabIndex: Scalars["Int"];
  displayType: AssetDisplayType;
  filter: Array<Maybe<MaintenanceStatusType>>;
  filterCategoryId: Scalars["String"];
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export type AssetCreateInput = {
  assetFieldValuesInput: Array<AssetFieldValuesInput>;
  assetMaintenancesInput?: InputMaybe<Array<AssetMaintenanceInput>>;
  assetTypeId: Scalars["UUID"];
  /** Company user IDs to be added as contacts */
  companyUserIds?: InputMaybe<Array<Scalars["UUID"]>>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type AssetField = {
  __typename?: "AssetField";
  assetFieldValues: Array<AssetFieldValue>;
  assetType: AssetType;
  assetTypeId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  key?: Maybe<Scalars["Float"]>;
  label: Scalars["String"];
  order?: Maybe<Scalars["Float"]>;
  required: Scalars["Boolean"];
  selectOptions?: Maybe<Scalars["String"]>;
  type: FieldType;
  unit?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Timestamp"];
  validationRegExp?: Maybe<Scalars["RegExp"]>;
  validationRegExpMessage?: Maybe<Scalars["String"]>;
};

export type AssetFieldValue = {
  __typename?: "AssetFieldValue";
  asset: Asset;
  assetField: AssetField;
  assetFieldId: Scalars["UUID"];
  assetId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
  value?: Maybe<Scalars["String"]>;
};

export type AssetFieldValuesInput = {
  assetFieldId: Scalars["UUID"];
  id?: InputMaybe<Scalars["UUID"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type AssetFile = {
  __typename?: "AssetFile";
  asset: Asset;
  assetId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  /** Asset file size in bytes */
  fileSize: Scalars["Float"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  order?: Maybe<Scalars["Float"]>;
  path: Scalars["String"];
  signedUrl: Scalars["String"];
  type: AssetFileType;
  updatedAt: Scalars["Timestamp"];
};

export type AssetFileCreateInput = {
  assetId: Scalars["UUID"];
  description?: InputMaybe<Scalars["String"]>;
  fileId: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  type: AssetFileType;
};

export enum AssetFileType {
  Image = "IMAGE",
  Other = "OTHER",
}

export type AssetFileUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type AssetMaintenanceInput = {
  description?: InputMaybe<Scalars["String"]>;
  durationType: MaintenanceDurationType;
  durationValue: Scalars["Float"];
  intervalType: MaintenanceIntervalType;
  intervalValue: Scalars["Float"];
  name: Scalars["String"];
  remindBeforeType?: InputMaybe<MaintenanceRemindBeforeType>;
  remindBeforeValue?: InputMaybe<Scalars["Float"]>;
  startDateTime: Scalars["Timestamp"];
  timezone: Scalars["String"];
};

export type AssetPart = {
  __typename?: "AssetPart";
  asset: Asset;
  assetAffectedAssets: Array<AssetAffectedAsset>;
  assetId: Scalars["UUID"];
  assetPartFieldValues: Array<AssetPartFieldValue>;
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  updatedAt: Scalars["Timestamp"];
};

export type AssetPartCreateInput = {
  assetId: Scalars["UUID"];
  assetPartFieldValuesInput: Array<AssetPartFieldValuesInput>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type AssetPartField = {
  __typename?: "AssetPartField";
  assetPartFieldValues: Array<AssetPartFieldValue>;
  assetType: AssetType;
  assetTypeId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  key?: Maybe<Scalars["Float"]>;
  label: Scalars["String"];
  order?: Maybe<Scalars["Float"]>;
  required: Scalars["Boolean"];
  selectOptions?: Maybe<Scalars["String"]>;
  showInPanel: Scalars["Boolean"];
  type: FieldType;
  unit?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Timestamp"];
  validationRegExp?: Maybe<Scalars["RegExp"]>;
  validationRegExpMessage?: Maybe<Scalars["String"]>;
};

export type AssetPartFieldValue = {
  __typename?: "AssetPartFieldValue";
  assetPart: AssetPart;
  assetPartField: AssetPartField;
  assetPartFieldId: Scalars["UUID"];
  assetPartId: Scalars["UUID"];
  pathPoints?: number[];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
  value?: Maybe<Scalars["String"]>;
};

export type AssetPartFieldValuesInput = {
  assetPartFieldId: Scalars["UUID"];
  id?: InputMaybe<Scalars["UUID"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type AssetPartUpdateInput = {
  assetPartFieldValuesInput: Array<AssetPartFieldValuesInput>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export type AssetType = {
  __typename?: "AssetType";
  assetCategory: AssetCategory;
  assetCategoryId: Scalars["UUID"];
  assetFields: Array<AssetField>;
  assetPartFields: Array<AssetPartField>;
  assets: Array<Asset>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["Timestamp"];
  default: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  iconColor?: Maybe<Scalars["String"]>;
  iconName: Scalars["String"];
  iconType: IconTypes;
  id: Scalars["UUID"];
  maintenanceTemplates: Array<MaintenanceTemplate>;
  misc: AssetTypeMiscData;
  name: Scalars["String"];
  specialAssetField?: Maybe<SpecialAssetFieldTypes>;
  updatedAt: Scalars["Timestamp"];
};

export type AssetTypeConfig = {
  __typename?: "AssetTypeConfig";
  displayType: AssetTypeDisplayType;
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export type AssetTypeCopyInput = {
  copyFromId: Scalars["UUID"];
  copyToAssetCategoryId?: InputMaybe<Scalars["UUID"]>;
};

export type AssetTypeInput = {
  assetCategoryId: Scalars["UUID"];
  assetFieldsInput: Array<FieldsInput>;
  assetPartFieldsInput: Array<FieldsInput>;
  description?: InputMaybe<Scalars["String"]>;
  iconColor?: InputMaybe<Scalars["String"]>;
  iconName: Scalars["String"];
  iconType: IconTypes;
  name: Scalars["String"];
  specialAssetField?: InputMaybe<SpecialAssetFieldTypes>;
};

export type AssetTypeMiscData = {
  __typename?: "AssetTypeMiscData";
  resolvedIconColor: Scalars["String"];
  resolvedName: Scalars["String"];
};

export type AssetUpdateInput = {
  assetFieldValuesInput: Array<AssetFieldValuesInput>;
  /** Company user IDs to be added as contacts */
  companyUserIds?: InputMaybe<Array<Scalars["UUID"]>>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CategoriesSummary = {
  __typename?: "CategoriesSummary";
  categoryId?: Maybe<Scalars["String"]>;
  categoryName: Scalars["String"];
  currentAssetIds?: Maybe<Array<Scalars["String"]>>;
  currentCount: Scalars["Float"];
  dueIn30AssetIds?: Maybe<Array<Scalars["String"]>>;
  dueIn30Count: Scalars["Float"];
  pastDueAssetIds?: Maybe<Array<Scalars["String"]>>;
  pastDueCount: Scalars["Float"];
};

export type Company = {
  __typename?: "Company";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  assetCategories: Array<AssetCategory>;
  assetCount: Scalars["Float"];
  assetTypes: Array<AssetType>;
  assets: Array<Asset>;
  businessType?: Maybe<Scalars["String"]>;
  canAddLocation: CompanyPermissionType;
  city?: Maybe<Scalars["String"]>;
  /** Contact company users */
  companyCompanyUsers: Array<CompanyCompanyUser>;
  companyUserCount: Scalars["Float"];
  companyUsers: Array<CompanyUser>;
  config: Scalars["JSONObject"];
  coordinates?: Maybe<Array<Scalars["Float"]>>;
  /** Alpha 2 Country Code */
  countryAlpha2?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Timestamp"];
  defaultFolder?: Maybe<Folder>;
  defaultFolderId?: Maybe<Scalars["UUID"]>;
  email?: Maybe<Scalars["String"]>;
  /** Total of all file size in bytes that belongs to company */
  fileSizeTotal: Scalars["Float"];
  files: Array<File>;
  floorPlans: Array<FloorPlan>;
  folders: Array<Folder>;
  hiddenCategoryIds: Array<Scalars["String"]>;
  hiddenTypeIds: Array<Scalars["String"]>;
  id: Scalars["UUID"];
  industry?: Maybe<Scalars["String"]>;
  locationCount: Scalars["Float"];
  locations: Array<Location>;
  maintenanceSummaries: Array<MaintenanceSummary>;
  maintenances: Array<Maintenance>;
  misc: CompanyMiscData;
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  plan?: Maybe<Plan>;
  planId?: Maybe<Scalars["String"]>;
  planStripeCancelAt?: Maybe<Scalars["Timestamp"]>;
  planStripeCurrentPeriodEnd?: Maybe<Scalars["Timestamp"]>;
  planStripeEndedAt?: Maybe<Scalars["Timestamp"]>;
  planStripeId?: Maybe<Scalars["String"]>;
  planStripeStatus?: Maybe<Scalars["String"]>;
  planStripeTrialEnd?: Maybe<Scalars["Timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  stripeId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Timestamp"];
  uploadsFolder?: Maybe<Folder>;
  uploadsFolderId?: Maybe<Scalars["UUID"]>;
  website?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type CompanyCompanyUser = {
  __typename?: "CompanyCompanyUser";
  company: Company;
  companyId: Scalars["UUID"];
  companyUser: CompanyUser;
  companyUserId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type CompanyInput = {
  address1?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  agreements?: InputMaybe<Scalars["Boolean"]>;
  businessType?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  /** Company user IDs to be added as contacts */
  companyUserIds?: InputMaybe<Array<Scalars["UUID"]>>;
  config?: InputMaybe<Scalars["JSONObject"]>;
  coordinates?: InputMaybe<Array<Scalars["Float"]>>;
  /** Alpha 2 Country Code */
  countryAlpha2?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  industry?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type CompanyMiscData = {
  __typename?: "CompanyMiscData";
  assetCategoryIds: Array<Scalars["String"]>;
  assetStatus: MaintenanceStatusType;
  assetTypeIds: Array<Scalars["String"]>;
};

export enum CompanyPermissionType {
  No = "NO",
  ProRated = "PRO_RATED",
  Yes = "YES",
}

export enum CompanyPlanInterval {
  Month = "MONTH",
  Year = "YEAR",
}

export enum CompanyPlanName {
  Premium = "PREMIUM",
  Professional = "PROFESSIONAL",
  Starter = "STARTER",
  Ultimate = "ULTIMATE",
}

export type CompanyUser = {
  __typename?: "CompanyUser";
  assetCompanyUsers: Array<AssetCompanyUser>;
  company: Company;
  companyCompanyUsers: Array<CompanyCompanyUser>;
  companyId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  locationCompanyUsers: Array<LocationCompanyUser>;
  maintenanceCompanyUsers: Array<MaintenanceCompanyUser>;
  role: CompanyUserRole;
  updatedAt: Scalars["Timestamp"];
  user: User;
  userId: Scalars["UUID"];
};

export type CompanyUserCreateInput = {
  email: Scalars["String"];
  role: CompanyUserRole;
};

export enum CompanyUserRole {
  Admin = "ADMIN",
  Manager = "MANAGER",
  Owner = "OWNER",
  User = "USER",
  ViewOnly = "VIEW_ONLY",
}

export type CompanyUserUpdateInput = {
  role: CompanyUserRole;
};

export type CurrentCompany = {
  __typename?: "CurrentCompany";
  id?: Maybe<Scalars["UUID"]>;
};

export type DashboardConfig = {
  __typename?: "DashboardConfig";
  filter: Array<Maybe<MaintenanceStatusType>>;
};

export enum FloorPlanDisplayType {
  Grid = "GRID",
  List = "LIST",
}

export enum FloorPlanSortBy {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
}

export enum FolderDisplayType {
  Grid = "GRID",
  List = "LIST",
}

export enum FieldType {
  AssetLink = "ASSET_LINK",
  Autosuggest = "AUTOSUGGEST",
  Boolean = "BOOLEAN",
  Input = "INPUT",
  Select = "SELECT",
  Text = "TEXT",
}

export type FieldsInput = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  key?: InputMaybe<Scalars["Float"]>;
  label: Scalars["String"];
  order?: InputMaybe<Scalars["Float"]>;
  required: Scalars["Boolean"];
  selectOptions?: InputMaybe<Scalars["String"]>;
  type: FieldType;
  unit?: InputMaybe<Scalars["String"]>;
  validationRegExp?: InputMaybe<Scalars["RegExp"]>;
  validationRegExpMessage?: InputMaybe<Scalars["String"]>;
};

export type File = {
  __typename?: "File";
  company: Company;
  companyId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  /** File size in bytes */
  fileSize: Scalars["Float"];
  folder: Folder;
  folderId: Scalars["UUID"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  path: Scalars["String"];
  signedUrl: Scalars["String"];
  updatedAt: Scalars["Timestamp"];
};

export type FileCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  folderId: Scalars["UUID"];
  name: Scalars["String"];
  path: Scalars["String"];
};

export type FileUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  folderId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type FloorPlan = {
  __typename?: "FloorPlan";
  company: Company;
  companyId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** Floor plan map size in bytes */
  fileSize: Scalars["Float"];
  floorPlanAssetCategories: Array<FloorPlanAssetCategory>;
  floorPlanAssets: Array<FloorPlanAsset>;
  id: Scalars["UUID"];
  location: Location;
  locationId: Scalars["UUID"];
  name: Scalars["String"];
  path: Scalars["String"];
  signedUrl: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Timestamp"];
};

export type FloorPlanAsset = {
  __typename?: "FloorPlanAsset";
  asset: Asset;
  assetId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  floorPlan: FloorPlan;
  floorPlanId: Scalars["UUID"];
  id: Scalars["UUID"];
  mapAffectedArea?: Maybe<Scalars["GeoJSONFeatureCollectionScalar"]>;
  mapPosition?: Maybe<Scalars["GeoJSONFeatureScalar"]>;
  updatedAt: Scalars["Timestamp"];
};

export type FloorPlanAssetCategory = {
  __typename?: "FloorPlanAssetCategory";
  assetCategory: AssetCategory;
  assetCategoryId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  floorPlan: FloorPlan;
  floorPlanId: Scalars["UUID"];
  id: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type FloorPlanAssetCreateInput = {
  assetId: Scalars["UUID"];
  floorPlanId: Scalars["UUID"];
  mapAffectedArea?: InputMaybe<Scalars["GeoJSONFeatureCollectionScalar"]>;
  mapPosition?: InputMaybe<Scalars["GeoJSONFeatureScalar"]>;
};

export type FloorPlanAssetUpdateInput = {
  mapAffectedArea?: InputMaybe<Scalars["GeoJSONFeatureCollectionScalar"]>;
  mapPosition?: InputMaybe<Scalars["GeoJSONFeatureScalar"]>;
};

export type FloorPlanAssetUpdateManyInput = {
  data: FloorPlanAssetUpdateInput;
  id: Scalars["UUID"];
};

export type FloorPlanConfig = {
  __typename?: "FloorPlanConfig";
  displayType: FloorPlanDisplayType;
  filterCategoryId: Scalars["String"];
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export type Folder = {
  __typename?: "Folder";
  children: Array<Folder>;
  company: Company;
  companyId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  files: Array<File>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  parent?: Maybe<Folder>;
  parentId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["Timestamp"];
};

export type FolderConfig = {
  __typename?: "FolderConfig";
  displayType: FolderDisplayType;
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export type FolderCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  parentId?: InputMaybe<Scalars["UUID"]>;
};

export type FolderUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["UUID"]>;
};

export type Ios = {
  __typename?: "IOS";
  minimumSupportedVersion: Scalars["String"];
};

export enum IconTypes {
  Circle = "CIRCLE",
  Square = "SQUARE",
}

export type Location = {
  __typename?: "Location";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  children: Array<Location>;
  city?: Maybe<Scalars["String"]>;
  company: Company;
  companyId: Scalars["UUID"];
  coordinates?: Maybe<Array<Scalars["Float"]>>;
  /** Alpha 2 Country Code */
  countryAlpha2?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  floorPlans: Array<FloorPlan>;
  id: Scalars["UUID"];
  /** Contact company users */
  locationCompanyUsers: Array<LocationCompanyUser>;
  name: Scalars["String"];
  parent?: Maybe<Location>;
  parentId?: Maybe<Scalars["UUID"]>;
  state?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Timestamp"];
  zip?: Maybe<Scalars["String"]>;
};

export type LocationCompanyUser = {
  __typename?: "LocationCompanyUser";
  companyUser: CompanyUser;
  companyUserId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  location: Location;
  locationId: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type LocationConfig = {
  __typename?: "LocationConfig";
  activeCollapsible: Array<Scalars["String"]>;
  activeTabIndex: Scalars["Int"];
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export type LocationInput = {
  address1?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  /** Company user IDs to be added as contacts */
  companyUserIds?: InputMaybe<Array<Scalars["UUID"]>>;
  coordinates?: InputMaybe<Array<Scalars["Float"]>>;
  /** Alpha 2 Country Code */
  countryAlpha2?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  parentId?: InputMaybe<Scalars["UUID"]>;
  state?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export enum MaintenanceDisplayType {
  Calendar = "CALENDAR",
  List = "LIST",
}

export type MaintainedBy = {
  __typename?: "MaintainedBy";
  userEmail: Scalars["String"];
  userId: Scalars["String"];
  userName?: Maybe<Scalars["String"]>;
  userPhone?: Maybe<Scalars["String"]>;
};

export type Maintenance = {
  __typename?: "Maintenance";
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars["UUID"]>;
  company: Company;
  companyId: Scalars["UUID"];
  contactUserIds: Array<Scalars["UUID"]>;
  createdAt: Scalars["Timestamp"];
  /** Array of timestamps */
  deletedScheduleTimestamps: Array<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  durationType: MaintenanceDurationType;
  durationValue: Scalars["Float"];
  endAfter?: Maybe<Scalars["Float"]>;
  endDateTime?: Maybe<Scalars["Timestamp"]>;
  id: Scalars["UUID"];
  intervalType?: Maybe<MaintenanceIntervalType>;
  intervalValue?: Maybe<Scalars["Float"]>;
  /** Contact company users */
  maintenanceCompanyUsers: Array<MaintenanceCompanyUser>;
  maintenanceNotes: Array<MaintenanceNote>;
  maintenanceNotesCount: Scalars["Float"];
  maintenanceSchedules: Array<MaintenanceSchedule>;
  maintenanceSchedulesForDateRange: Array<MaintenanceSchedule>;
  misc: MaintenanceMiscData;
  name: Scalars["String"];
  remindBeforeType?: Maybe<MaintenanceRemindBeforeType>;
  remindBeforeValue?: Maybe<Scalars["Float"]>;
  startDateTime: Scalars["Timestamp"];
  status: MaintenanceStatusType;
  timezone: Scalars["String"];
  updatedAt: Scalars["Timestamp"];
};

export type MaintenanceMaintenanceSchedulesForDateRangeArgs = {
  endDate?: InputMaybe<Scalars["Timestamp"]>;
  startDate?: InputMaybe<Scalars["Timestamp"]>;
};

export type MaintenanceCompanyUser = {
  __typename?: "MaintenanceCompanyUser";
  companyUser: CompanyUser;
  companyUserId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  maintenance: Maintenance;
  maintenanceId: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type MaintenanceConfig = {
  __typename?: "MaintenanceConfig";
  displayType: MaintenanceDisplayType;
  filterCategoryId: Scalars["String"];
  filterStatus: Array<Maybe<MaintenanceFilterStatus>>;
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export enum MaintenanceDurationType {
  Days = "DAYS",
  Hours = "HOURS",
  Minutes = "MINUTES",
  Weeks = "WEEKS",
}

export enum MaintenanceFilterStatus {
  Completed = "COMPLETED",
  Current = "CURRENT",
  DueIn_30 = "DUE_IN_30",
  PastDue = "PAST_DUE",
}

export type MaintenanceInput = {
  assetId?: InputMaybe<Scalars["UUID"]>;
  /** Company user IDs to be added as contacts */
  companyUserIds: Array<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  durationType: MaintenanceDurationType;
  durationValue: Scalars["Float"];
  endAfter?: InputMaybe<Scalars["Float"]>;
  endDateTime?: InputMaybe<Scalars["Timestamp"]>;
  intervalType?: InputMaybe<MaintenanceIntervalType>;
  intervalValue?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
  remindBeforeType?: InputMaybe<MaintenanceRemindBeforeType>;
  remindBeforeValue?: InputMaybe<Scalars["Float"]>;
  startDateTime: Scalars["Timestamp"];
  timezone: Scalars["String"];
};

export enum MaintenanceIntervalType {
  Days = "DAYS",
  Months = "MONTHS",
  Weeks = "WEEKS",
  Years = "YEARS",
}

export type MaintenanceMiscData = {
  __typename?: "MaintenanceMiscData";
  assetCategoryId?: Maybe<Scalars["String"]>;
  resolvedName: Scalars["String"];
};

export type MaintenanceNote = {
  __typename?: "MaintenanceNote";
  addedBy: AddedBy;
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  maintenance: Maintenance;
  maintenanceId: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type MaintenanceNoteInput = {
  description?: InputMaybe<Scalars["String"]>;
  maintenanceId: Scalars["UUID"];
};

export enum MaintenanceRemindBeforeType {
  Days = "DAYS",
  Hours = "HOURS",
  Minutes = "MINUTES",
  Weeks = "WEEKS",
}

export type MaintenanceReport = {
  __typename?: "MaintenanceReport";
  currentCount: Scalars["Float"];
  date: Scalars["Timestamp"];
  dueIn30Count: Scalars["Float"];
  pastDueCount: Scalars["Float"];
};

export type MaintenanceSchedule = {
  __typename?: "MaintenanceSchedule";
  completedAt?: Maybe<Scalars["Timestamp"]>;
  completedBy: Array<Scalars["String"]>;
  completedByLabel: Scalars["String"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  jobId?: Maybe<Scalars["String"]>;
  maintainedBy?: Maybe<Array<MaintainedBy>>;
  maintenance: Maintenance;
  maintenanceId: Scalars["UUID"];
  maintenanceScheduleReminders: Array<MaintenanceScheduleReminder>;
  scheduledAt: Scalars["Timestamp"];
  updatedAt: Scalars["Timestamp"];
};

export type MaintenanceScheduleReminder = {
  __typename?: "MaintenanceScheduleReminder";
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  jobId?: Maybe<Scalars["String"]>;
  maintenanceSchedule: MaintenanceSchedule;
  maintenanceScheduleId: Scalars["UUID"];
  notified: Scalars["Boolean"];
  notifyAt: Scalars["Timestamp"];
  updatedAt: Scalars["Timestamp"];
};

export type MaintenanceScheduleSnoozeReminderInput = {
  remindAfterType: MaintenanceRemindBeforeType;
  remindAfterValue: Scalars["Float"];
};

export enum MaintenanceStatusType {
  Current = "CURRENT",
  DueIn_30 = "DUE_IN_30",
  PastDue = "PAST_DUE",
}

export type MaintenanceSummary = {
  __typename?: "MaintenanceSummary";
  categoriesSummary: Array<CategoriesSummary>;
  company: Company;
  companyId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  updatedAt: Scalars["Timestamp"];
};

export type MaintenanceTemplate = {
  __typename?: "MaintenanceTemplate";
  assetType: AssetType;
  assetTypeId: Scalars["UUID"];
  createdAt: Scalars["Timestamp"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  intervalType: MaintenanceIntervalType;
  intervalValue: Scalars["Float"];
  name: Scalars["String"];
  updatedAt: Scalars["Timestamp"];
};

export type MaintenanceTemplateInput = {
  assetTypeId: Scalars["UUID"];
  description?: InputMaybe<Scalars["String"]>;
  intervalType: MaintenanceIntervalType;
  intervalValue: Scalars["Float"];
  name: Scalars["String"];
};

export type MobileAppInfo = {
  __typename?: "MobileAppInfo";
  android: Android;
  ios: Ios;
};

export type Mutation = {
  __typename?: "Mutation";
  assetAffectedAssetCreate: Scalars["Boolean"];
  assetAffectedAssetDelete: Scalars["Boolean"];
  assetCategoryCreate: AssetCategory;
  assetCategoryDelete: Scalars["Boolean"];
  assetCategoryUpdate: AssetCategory;
  assetCreate: Array<Asset>;
  assetDelete: Scalars["Boolean"];
  assetFileChangeOrder: Array<AssetFile>;
  assetFileCreate: AssetFile;
  assetFileDelete: Scalars["Boolean"];
  assetFileUpdate: AssetFile;
  assetPartCreate: Array<AssetPart>;
  assetPartDelete: Scalars["Boolean"];
  assetPartUpdate: Array<AssetPart>;
  assetTypeCopy: AssetType;
  assetTypeCreate: AssetType;
  assetTypeDelete: Scalars["Boolean"];
  assetTypeMove: Scalars["Boolean"];
  assetTypeUpdate: AssetType;
  assetUpdate: Asset;
  assetUpdateConfig: Asset;
  companyCreate: Company;
  companyDelete: Scalars["Boolean"];
  companyUpdate: Company;
  companyUpdateHiddenCategoryIds: Company;
  companyUserCreate: CompanyUser;
  companyUserDelete: Scalars["Boolean"];
  companyUserInvitationResend: CompanyUser;
  companyUserUpdate: CompanyUser;
  createReportAsset: Scalars["Boolean"];
  createReportMaintenance: Scalars["Boolean"];
  createReportPlan: Scalars["Boolean"];
  createReportUser: Scalars["Boolean"];
  expoPushNotificationTokenCreate: Scalars["Boolean"];
  fileCreate: File;
  fileDelete: Scalars["Boolean"];
  fileMove: Scalars["Boolean"];
  fileUpdate: File;
  floorPlanAssetCreate: Scalars["Boolean"];
  floorPlanAssetDelete: Scalars["Boolean"];
  floorPlanAssetUpdate: FloorPlanAsset;
  floorPlanAssetUpdateMany: Array<FloorPlanAsset>;
  assetAffectedAssetUpdateMany: Array<AssetAffectedAsset>;
  floorPlanCreate: Scalars["Boolean"];
  floorPlanDelete: Scalars["Boolean"];
  floorPlanMove: Scalars["Boolean"];
  floorPlanUpdate: FloorPlan;
  folderCreate: Folder;
  folderDelete: Scalars["Boolean"];
  folderMove: Scalars["Boolean"];
  folderUpdate: Folder;
  locationCreate: Location;
  locationDelete: Scalars["Boolean"];
  locationUpdate: Location;
  maintenanceCreate: Maintenance;
  maintenanceDelete: Scalars["Boolean"];
  maintenanceNoteCreate: MaintenanceNote;
  maintenanceNoteDelete: Scalars["Boolean"];
  maintenanceNoteUpdate: MaintenanceNote;
  maintenanceScheduleComplete: MaintenanceSchedule;
  maintenanceScheduleInComplete: MaintenanceSchedule;
  maintenanceScheduleSnoozeReminder: MaintenanceSchedule;
  maintenanceTemplateCreate: MaintenanceTemplate;
  maintenanceTemplateDelete: Scalars["Boolean"];
  maintenanceTemplateUpdate: MaintenanceTemplate;
  maintenanceToggleDeletedScheduleTimestamp: Maintenance;
  maintenanceUpdate: Maintenance;
  notificationDelete: Scalars["Boolean"];
  notificationDeleteAll: Scalars["Boolean"];
  notificationDeleteAllRead: Scalars["Boolean"];
  notificationRead: Notification;
  notificationReadAll: Scalars["Boolean"];
  notificationUnread: Notification;
  sendEmail: Scalars["Boolean"];
  signedUrlCreate: Array<SignedUrl>;
  stripeBankAccountCreate: StripeBankAccount;
  stripeBankAccountDelete: Scalars["Boolean"];
  stripeBankAccountMakePrimary: Scalars["Boolean"];
  stripeCoupon: StripeCoupon;
  stripePaymentMethodCreate: StripePaymentMethodType;
  stripePaymentMethodDelete: Scalars["Boolean"];
  stripePaymentMethodMakePrimary: Scalars["Boolean"];
  stripePaymentMethodUpdate: StripePaymentMethodType;
  stripeSubscriptionCreateOrUpdate: StripeSubscription;
  stripeSubscriptionDelete: StripeSubscription;
  toggleTwoFactor: User;
  updateAppSettings: Scalars["Boolean"];
  updateAssetCategoryConfig: Scalars["Boolean"];
  updateAssetConfig: Scalars["Boolean"];
  updateAssetTypeConfig: Scalars["Boolean"];
  updateCurrentCompany: Scalars["Boolean"];
  updateDashboardConfig: Scalars["Boolean"];
  updateFloorPlanConfig: Scalars["Boolean"];
  updateFolderConfig: Scalars["Boolean"];
  updateLocationConfig: Scalars["Boolean"];
  updateMaintenanceConfig: Scalars["Boolean"];
  updateSession: Scalars["Boolean"];
  userAcceptInvite: UserJwtTokenType;
  userCreate: UserJwtTokenType;
  userEmailConfirm: User;
  userEmailVerify: Scalars["Boolean"];
  userForgotPassword: Scalars["Boolean"];
  userPhoneConfirm: User;
  userPhoneVerify: User;
  userResetPassword: Scalars["Boolean"];
  userSignIn: UserJwtTokenType;
  userUpdate: User;
  webPushNotificationSubscriptionCreate: Scalars["Boolean"];
  zendeskSignIn: UserJwtTokenType;
};

export type MutationAssetAffectedAssetCreateArgs = {
  data: Array<AssetAffectedAssetCreateInput>;
};

export type MutationAssetAffectedAssetDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationAssetCategoryCreateArgs = {
  data: AssetCategoryInput;
};

export type MutationAssetCategoryDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationAssetCategoryUpdateArgs = {
  data: AssetCategoryInput;
  id: Scalars["UUID"];
};

export type MutationAssetCreateArgs = {
  data: Array<AssetCreateInput>;
};

export type MutationAssetDeleteArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationAssetFileChangeOrderArgs = {
  assetFileIds: Array<Scalars["UUID"]>;
};

export type MutationAssetFileCreateArgs = {
  data: AssetFileCreateInput;
};

export type MutationAssetFileDeleteArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationAssetFileUpdateArgs = {
  data: AssetFileUpdateInput;
  id: Scalars["UUID"];
};

export type MutationAssetPartCreateArgs = {
  data: Array<AssetPartCreateInput>;
};

export type MutationAssetPartDeleteArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationAssetPartUpdateArgs = {
  data: Array<AssetPartUpdateInput>;
};

export type MutationAssetTypeCopyArgs = {
  data: AssetTypeCopyInput;
};

export type MutationAssetTypeCreateArgs = {
  data: AssetTypeInput;
};

export type MutationAssetTypeDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationAssetTypeMoveArgs = {
  assetCategoryId: Scalars["UUID"];
  ids: Array<Scalars["UUID"]>;
};

export type MutationAssetTypeUpdateArgs = {
  data: AssetTypeInput;
  id: Scalars["UUID"];
};

export type MutationAssetUpdateArgs = {
  data: AssetUpdateInput;
  id: Scalars["UUID"];
};

export type MutationAssetUpdateConfigArgs = {
  config: Scalars["JSONObject"];
  id: Scalars["UUID"];
};

export type MutationCompanyCreateArgs = {
  data: CompanyInput;
};

export type MutationCompanyDeleteArgs = {
  passwordRaw?: InputMaybe<Scalars["String"]>;
};

export type MutationCompanyUpdateArgs = {
  data: CompanyInput;
};

export type MutationCompanyUpdateHiddenCategoryIdsArgs = {
  hiddenCategoryIds?: InputMaybe<Array<Scalars["String"]>>;
  hiddenTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type MutationCompanyUserCreateArgs = {
  data: CompanyUserCreateInput;
};

export type MutationCompanyUserDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationCompanyUserInvitationResendArgs = {
  id: Scalars["UUID"];
};

export type MutationCompanyUserUpdateArgs = {
  data: CompanyUserUpdateInput;
  id: Scalars["UUID"];
  passwordRaw?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateReportAssetArgs = {
  fileType: ReportFileType;
  filterBy: ReportAssetFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateReportMaintenanceArgs = {
  fileType: ReportFileType;
  filterBy: ReportMaintenanceFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateReportPlanArgs = {
  fileType: ReportFileType;
  filterBy: ReportPlanFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateReportUserArgs = {
  fileType: ReportFileType;
  filterBy: ReportUserFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type MutationExpoPushNotificationTokenCreateArgs = {
  token: Scalars["String"];
};

export type MutationFileCreateArgs = {
  data: FileCreateInput;
};

export type MutationFileDeleteArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationFileMoveArgs = {
  folderId: Scalars["UUID"];
  ids: Array<Scalars["UUID"]>;
};

export type MutationFileUpdateArgs = {
  data: FileUpdateInput;
  id: Scalars["UUID"];
};

export type MutationFloorPlanAssetCreateArgs = {
  data: Array<FloorPlanAssetCreateInput>;
};

export type MutationFloorPlanAssetDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationFloorPlanAssetUpdateArgs = {
  data: FloorPlanAssetUpdateInput;
  id: Scalars["UUID"];
};

export type MutationFloorPlanAssetUpdateManyArgs = {
  data: Array<FloorPlanAssetUpdateManyInput>;
};

export type MutationFloorPlanCreateArgs = {
  data: FloorPlanInput;
};

export type MutationFloorPlanDeleteArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationFloorPlanMoveArgs = {
  ids: Array<Scalars["UUID"]>;
  locationId: Scalars["UUID"];
};

export type MutationFloorPlanUpdateArgs = {
  data: FloorPlanInput;
  id: Scalars["UUID"];
  updateAssetLocationsAndAffectedAreas?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationFolderCreateArgs = {
  data: FolderCreateInput;
};

export type MutationFolderDeleteArgs = {
  ids: Array<Scalars["UUID"]>;
};

export type MutationFolderMoveArgs = {
  ids: Array<Scalars["UUID"]>;
  parentFolderId: Scalars["UUID"];
};

export type MutationFolderUpdateArgs = {
  data: FolderUpdateInput;
  id: Scalars["UUID"];
};

export type MutationLocationCreateArgs = {
  data: LocationInput;
};

export type MutationLocationDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationLocationUpdateArgs = {
  data: LocationInput;
  id: Scalars["UUID"];
};

export type MutationMaintenanceCreateArgs = {
  data: MaintenanceInput;
};

export type MutationMaintenanceDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationMaintenanceNoteCreateArgs = {
  data: MaintenanceNoteInput;
};

export type MutationMaintenanceNoteDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationMaintenanceNoteUpdateArgs = {
  data: MaintenanceNoteInput;
  id: Scalars["UUID"];
};

export type MutationMaintenanceScheduleCompleteArgs = {
  id: Scalars["UUID"];
  userIds: Array<Scalars["UUID"]>;
};

export type MutationMaintenanceScheduleInCompleteArgs = {
  id: Scalars["UUID"];
};

export type MutationMaintenanceScheduleSnoozeReminderArgs = {
  data: MaintenanceScheduleSnoozeReminderInput;
  id: Scalars["UUID"];
};

export type MutationMaintenanceTemplateCreateArgs = {
  data: MaintenanceTemplateInput;
};

export type MutationMaintenanceTemplateDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationMaintenanceTemplateUpdateArgs = {
  data: MaintenanceTemplateInput;
  id: Scalars["UUID"];
};

export type MutationMaintenanceToggleDeletedScheduleTimestampArgs = {
  id: Scalars["UUID"];
  timestamp: Scalars["Timestamp"];
};

export type MutationMaintenanceUpdateArgs = {
  data: MaintenanceInput;
  id: Scalars["UUID"];
};

export type MutationNotificationDeleteArgs = {
  id: Scalars["UUID"];
};

export type MutationNotificationReadArgs = {
  id: Scalars["UUID"];
};

export type MutationNotificationUnreadArgs = {
  id: Scalars["UUID"];
};

export type MutationSendEmailArgs = {
  message: Scalars["String"];
};

export type MutationSignedUrlCreateArgs = {
  data: Array<SignedUrlInput>;
};

export type MutationStripeBankAccountCreateArgs = {
  attemptToChargeOpenInvoice?: InputMaybe<Scalars["Boolean"]>;
  plaidAccountId: Scalars["String"];
  plaidLinkPublicToken: Scalars["String"];
};

export type MutationStripeBankAccountDeleteArgs = {
  stripeBankAccountId: Scalars["String"];
};

export type MutationStripeBankAccountMakePrimaryArgs = {
  stripeBankAccountId: Scalars["String"];
};

export type MutationStripeCouponArgs = {
  code: Scalars["String"];
};

export type MutationStripePaymentMethodCreateArgs = {
  attemptToChargeOpenInvoice?: InputMaybe<Scalars["Boolean"]>;
  stripePaymentMethodId: Scalars["String"];
};

export type MutationStripePaymentMethodDeleteArgs = {
  stripePaymentMethodId: Scalars["String"];
};

export type MutationStripePaymentMethodMakePrimaryArgs = {
  stripePaymentMethodId: Scalars["String"];
};

export type MutationStripePaymentMethodUpdateArgs = {
  expMonth: Scalars["Float"];
  expYear: Scalars["Float"];
  stripePaymentMethodId: Scalars["String"];
};

export type MutationStripeSubscriptionCreateOrUpdateArgs = {
  couponCode?: InputMaybe<Scalars["String"]>;
  planId?: InputMaybe<Scalars["String"]>;
};

export type MutationStripeSubscriptionDeleteArgs = {
  passwordRaw: Scalars["String"];
};

export type MutationToggleTwoFactorArgs = {
  twoFactorEnabled: Scalars["Boolean"];
};

export type MutationUpdateAppSettingsArgs = {
  plaidOpen?: InputMaybe<Scalars["Boolean"]>;
  sideNavMinimized?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateAssetCategoryConfigArgs = {
  displayType?: InputMaybe<AssetCategoryDisplayType>;
  showHidden?: InputMaybe<Scalars["Boolean"]>;
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MutationUpdateAssetConfigArgs = {
  activeCollapsible?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  activeTabIndex?: InputMaybe<Scalars["Int"]>;
  displayType?: InputMaybe<AssetDisplayType>;
  filter?: InputMaybe<Array<InputMaybe<MaintenanceStatusType>>>;
  filterCategoryId?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MutationUpdateAssetTypeConfigArgs = {
  displayType?: InputMaybe<AssetTypeDisplayType>;
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MutationUpdateCurrentCompanyArgs = {
  id?: InputMaybe<Scalars["UUID"]>;
};

export type MutationUpdateDashboardConfigArgs = {
  filter?: InputMaybe<Array<InputMaybe<MaintenanceStatusType>>>;
};

export type MutationUpdateFloorPlanConfigArgs = {
  displayType?: InputMaybe<FloorPlanDisplayType>;
  filterCategoryId?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<FloorPlanSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MutationUpdateFolderConfigArgs = {
  displayType?: InputMaybe<FolderDisplayType>;
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MutationUpdateLocationConfigArgs = {
  activeCollapsible?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  activeTabIndex?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MutationUpdateMaintenanceConfigArgs = {
  displayType?: InputMaybe<MaintenanceDisplayType>;
  filterCategoryId?: InputMaybe<Scalars["String"]>;
  filterStatus?: InputMaybe<Array<InputMaybe<MaintenanceFilterStatus>>>;
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MutationUpdateSessionArgs = {
  jwt?: InputMaybe<Scalars["String"]>;
};

export type MutationUserAcceptInviteArgs = {
  data: UserAcceptInviteInput;
};

export type MutationUserCreateArgs = {
  data: UserCreateInput;
};

export type MutationUserEmailConfirmArgs = {
  data?: InputMaybe<UserEmailConfirmInput>;
};

export type MutationUserEmailVerifyArgs = {
  data: UserEmailVerifyInput;
};

export type MutationUserForgotPasswordArgs = {
  data: UserForgotPasswordInput;
};

export type MutationUserPhoneConfirmArgs = {
  data?: InputMaybe<UserPhoneConfirmInput>;
};

export type MutationUserPhoneVerifyArgs = {
  data: UserPhoneVerifyInput;
};

export type MutationUserResetPasswordArgs = {
  data: UserResetPasswordInput;
};

export type MutationUserSignInArgs = {
  data: UserSignInInput;
};

export type MutationUserUpdateArgs = {
  data: UserUpdateInput;
};

export type MutationWebPushNotificationSubscriptionCreateArgs = {
  subscription: Scalars["JSONObject"];
};

export type Notification = {
  __typename?: "Notification";
  createdAt: Scalars["Timestamp"];
  id: Scalars["UUID"];
  message: Scalars["String"];
  path: Scalars["String"];
  readAt?: Maybe<Scalars["Timestamp"]>;
  updatedAt: Scalars["Timestamp"];
  user: User;
  userId: Scalars["UUID"];
};

export type Plan = {
  __typename?: "Plan";
  active: Scalars["Boolean"];
  amount: Scalars["Float"];
  createdAt: Scalars["Timestamp"];
  currency: Scalars["String"];
  default: Scalars["Boolean"];
  id: Scalars["UUID"];
  interval: Scalars["String"];
  interval_count: Scalars["Float"];
  proRateAmount?: Maybe<Scalars["Float"]>;
  productName: Scalars["String"];
  stripeId: Scalars["String"];
  stripeProductId: Scalars["String"];
  updatedAt: Scalars["Timestamp"];
};

export type Query = {
  __typename?: "Query";
  appSettings: AppSettings;
  asset: Asset;
  assetCategories: Array<AssetCategory>;
  assetCategory: AssetCategory;
  assetCategoryConfig: AssetCategoryConfig;
  assetConfig: AssetConfig;
  assetType: AssetType;
  assetTypeConfig: AssetTypeConfig;
  assetTypes: Array<AssetType>;
  assets: Array<Asset>;
  company: Company;
  companyUsers: Array<CompanyUser>;
  completedByUsers: Array<User>;
  currentCompany: CurrentCompany;
  currentMaintenanceReportByCategory: Array<CategoriesSummary>;
  dashboardConfig: DashboardConfig;
  file: File;
  files: Array<File>;
  floorPlan: FloorPlan;
  floorPlanConfig: FloorPlanConfig;
  floorPlanTypes?: Maybe<Array<Scalars["String"]>>;
  floorPlans: Array<FloorPlan>;
  folder: Folder;
  folderConfig: FolderConfig;
  folders: Array<Folder>;
  location: Location;
  locationConfig: LocationConfig;
  locationTypes?: Maybe<Array<Scalars["String"]>>;
  locations: Array<Location>;
  maintenance: Maintenance;
  maintenanceConfig: MaintenanceConfig;
  maintenanceReport: Array<MaintenanceReport>;
  maintenanceSchedule: MaintenanceSchedule;
  maintenances: Array<Maintenance>;
  mobileAppInfo: MobileAppInfo;
  notifications: Array<Notification>;
  plans: Array<Plan>;
  reportAsset: Scalars["JSON"];
  reportMaintenance: Scalars["JSON"];
  reportPlan: Scalars["JSON"];
  reportUser: Scalars["JSON"];
  search: Array<SearchResult>;
  session: Session;
  stripeBankAccounts: Array<StripeBankAccount>;
  stripeCustomer: StripeCustomer;
  stripeInvoiceUpcoming: StripeInvoice;
  stripeInvoices: Array<StripeInvoice>;
  stripeNewPlanAmount: Scalars["Float"];
  stripePaymentMethods: Array<StripePaymentMethodType>;
  stripeProrationAmount: StripeProration;
  stripeSubscription: StripeSubscription;
  user: User;
};

export type QueryAssetArgs = {
  id: Scalars["UUID"];
};

export type QueryAssetCategoryArgs = {
  id: Scalars["UUID"];
};

export type QueryAssetTypeArgs = {
  id: Scalars["UUID"];
};

export type QueryAssetTypesArgs = {
  assetCategoryId?: InputMaybe<Scalars["UUID"]>;
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryAssetsArgs = {
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryFileArgs = {
  id: Scalars["UUID"];
};

export type QueryFilesArgs = {
  folderId?: InputMaybe<Scalars["UUID"]>;
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryFloorPlanArgs = {
  id: Scalars["UUID"];
};

export type QueryFolderArgs = {
  id: Scalars["UUID"];
};

export type QueryLocationArgs = {
  id: Scalars["UUID"];
};

export type QueryLocationsArgs = {
  parentId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryMaintenanceArgs = {
  id: Scalars["UUID"];
};

export type QueryMaintenanceReportArgs = {
  numberOfDays?: InputMaybe<Scalars["Float"]>;
  startDate: Scalars["Date"];
};

export type QueryMaintenanceScheduleArgs = {
  id: Scalars["UUID"];
};

export type QueryReportAssetArgs = {
  filterBy: ReportAssetFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type QueryReportMaintenanceArgs = {
  filterBy: ReportMaintenanceFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type QueryReportPlanArgs = {
  filterBy: ReportPlanFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type QueryReportUserArgs = {
  filterBy: ReportUserFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type QuerySearchArgs = {
  query: Scalars["String"];
  type?: InputMaybe<Array<SearchType>>;
};

export type QueryStripeNewPlanAmountArgs = {
  newPlan: Scalars["String"];
};

export type QueryStripeProrationAmountArgs = {
  newPlan?: InputMaybe<Scalars["String"]>;
  newQuantity?: InputMaybe<Scalars["Float"]>;
};

export type ReportAssetFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars["UUID"]>>;
  locationIds?: InputMaybe<Array<Scalars["UUID"]>>;
  status?: InputMaybe<Array<MaintenanceStatusType>>;
  userIds?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum ReportFileType {
  Csv = "CSV",
  Xlsx = "XLSX",
}

export type ReportMaintenanceFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars["UUID"]>>;
  completed?: InputMaybe<Scalars["Boolean"]>;
  completedByUserIds?: InputMaybe<Array<Scalars["UUID"]>>;
  endDate?: InputMaybe<Scalars["Timestamp"]>;
  locationIds?: InputMaybe<Array<Scalars["UUID"]>>;
  startDate?: InputMaybe<Scalars["Timestamp"]>;
  status?: InputMaybe<Array<MaintenanceStatusType>>;
};

export type ReportPlanFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars["UUID"]>>;
  locationIds?: InputMaybe<Array<Scalars["UUID"]>>;
  status?: InputMaybe<Array<MaintenanceStatusType>>;
};

export type ReportUserFilterInput = {
  endDate?: InputMaybe<Scalars["Timestamp"]>;
  startDate?: InputMaybe<Scalars["Timestamp"]>;
};

export type SearchResult =
  | Asset
  | AssetCategory
  | AssetType
  | File
  | FloorPlan
  | Folder
  | Location
  | Maintenance;

export enum SearchType {
  All = "All",
  Asset = "Asset",
  AssetCategory = "AssetCategory",
  AssetType = "AssetType",
  File = "File",
  FloorPlan = "FloorPlan",
  Folder = "Folder",
  Location = "Location",
  Maintenance = "Maintenance",
}

export type Session = {
  __typename?: "Session";
  isLoggedIn: Scalars["Boolean"];
};

export type SignedUrl = {
  __typename?: "SignedUrl";
  path: Scalars["String"];
  signedUrl: Scalars["String"];
};

export type SignedUrlInput = {
  contentType: Scalars["String"];
  key?: InputMaybe<Scalars["String"]>;
};

export enum SortBy {
  CreatedAt = "createdAt",
  Name = "name",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export enum SpecialAssetFieldTypes {
  ElectricalPanelGrid = "ELECTRICAL_PANEL_GRID",
  ElectricalPanelNormal = "ELECTRICAL_PANEL_NORMAL",
  Na = "NA",
}

export type StripeBankAccount = {
  __typename?: "StripeBankAccount";
  account_holder_name?: Maybe<Scalars["String"]>;
  account_holder_type?: Maybe<Scalars["String"]>;
  bank_name?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last4?: Maybe<Scalars["String"]>;
};

export type StripeCharge = {
  __typename?: "StripeCharge";
  amount_refunded?: Maybe<Scalars["Float"]>;
  payment_method_details?: Maybe<StripeChargePaymentMethodDetails>;
  receipt_url?: Maybe<Scalars["String"]>;
};

export type StripeChargePaymentMethodDetails = {
  __typename?: "StripeChargePaymentMethodDetails";
  ach_debit?: Maybe<StripeBankAccount>;
  card?: Maybe<StripePaymentMethodTypeCard>;
  type: Scalars["String"];
};

export type StripeCoupon = {
  __typename?: "StripeCoupon";
  amount_off?: Maybe<Scalars["Float"]>;
  currency?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["String"]>;
  duration_in_months?: Maybe<Scalars["Float"]>;
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  percent_off?: Maybe<Scalars["Float"]>;
  valid: Scalars["Boolean"];
};

export type StripeCustomer = {
  __typename?: "StripeCustomer";
  created?: Maybe<Scalars["Float"]>;
  default_source?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  invoice_settings?: Maybe<StripeInvoiceSettings>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type StripeDiscount = {
  __typename?: "StripeDiscount";
  coupon: StripeCoupon;
};

export type StripeInvoice = {
  __typename?: "StripeInvoice";
  amount_due?: Maybe<Scalars["Float"]>;
  amount_paid?: Maybe<Scalars["Float"]>;
  amount_remaining?: Maybe<Scalars["Float"]>;
  attempted: Scalars["Boolean"];
  auto_advance?: Maybe<Scalars["Boolean"]>;
  billing_reason?: Maybe<Scalars["String"]>;
  charge?: Maybe<StripeCharge>;
  collection_method?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Float"]>;
  currency?: Maybe<Scalars["String"]>;
  discount?: Maybe<StripeDiscount>;
  due_date?: Maybe<Scalars["Float"]>;
  hosted_invoice_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invoice_pdf?: Maybe<Scalars["String"]>;
  next_payment_attempt?: Maybe<Scalars["Float"]>;
  paid: Scalars["Boolean"];
  payment_intent?: Maybe<StripePaymentIntent>;
  receipt_number?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type StripeInvoiceSettings = {
  __typename?: "StripeInvoiceSettings";
  default_payment_method?: Maybe<Scalars["String"]>;
};

export type StripePaymentIntent = {
  __typename?: "StripePaymentIntent";
  client_secret: Scalars["String"];
  status: Scalars["String"];
};

export type StripePaymentMethodType = {
  __typename?: "StripePaymentMethodType";
  card?: Maybe<StripePaymentMethodTypeCard>;
  id: Scalars["String"];
  type: Scalars["String"];
};

export type StripePaymentMethodTypeCard = {
  __typename?: "StripePaymentMethodTypeCard";
  brand?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  exp_month?: Maybe<Scalars["Float"]>;
  exp_year?: Maybe<Scalars["Float"]>;
  last4?: Maybe<Scalars["String"]>;
};

export type StripeProration = {
  __typename?: "StripeProration";
  amount: Scalars["Float"];
  dueDate?: Maybe<Scalars["Float"]>;
};

export type StripeSubscription = {
  __typename?: "StripeSubscription";
  cancel_at?: Maybe<Scalars["Float"]>;
  cancel_at_period_end: Scalars["Boolean"];
  canceled_at?: Maybe<Scalars["Float"]>;
  created: Scalars["Float"];
  days_until_due?: Maybe<Scalars["Float"]>;
  ended_at?: Maybe<Scalars["Float"]>;
  id: Scalars["String"];
  start_date: Scalars["Float"];
  status: Scalars["String"];
  trial_end?: Maybe<Scalars["Float"]>;
  trial_start?: Maybe<Scalars["Float"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  deleteNotification: Notification;
  newNotification: Notification;
  updateNotification: Notification;
};

export type User = {
  __typename?: "User";
  adminRole?: Maybe<UserAdminRole>;
  companyUsers: Array<CompanyUser>;
  config: Scalars["JSONObject"];
  createdAt: Scalars["Timestamp"];
  email: Scalars["String"];
  emailConfirmed?: Maybe<Scalars["Boolean"]>;
  firstName?: Maybe<Scalars["String"]>;
  fullName: Scalars["String"];
  id: Scalars["UUID"];
  invitedBy?: Maybe<User>;
  invitedById?: Maybe<Scalars["UUID"]>;
  invitees: Array<User>;
  jobTitle?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  notifications: Array<Notification>;
  phone?: Maybe<Scalars["String"]>;
  phoneConfirmed?: Maybe<Scalars["Boolean"]>;
  registered: Scalars["Boolean"];
  twoFactorEnabled?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["Timestamp"];
};

export type UserAcceptInviteInput = {
  agreements?: InputMaybe<Scalars["Boolean"]>;
  emailEncoded: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  jobTitle?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  passwordRaw?: InputMaybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export enum UserAdminRole {
  SuperAdmin = "SUPER_ADMIN",
}

export type UserCreateInput = {
  agreements: Scalars["Boolean"];
  businessType?: InputMaybe<Scalars["String"]>;
  companyIndustry?: InputMaybe<Scalars["String"]>;
  companyName: Scalars["String"];
  companyPlanInterval?: InputMaybe<CompanyPlanInterval>;
  companyPlanName?: InputMaybe<CompanyPlanName>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  jobTitle?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  mspAgreement?: InputMaybe<Scalars["Boolean"]>;
  passwordRaw: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

export type UserEmailConfirmInput = {
  email?: InputMaybe<Scalars["String"]>;
};

export type UserEmailVerifyInput = {
  emailEncoded: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type UserForgotPasswordInput = {
  email: Scalars["String"];
};

export type UserJwtTokenType = {
  __typename?: "UserJwtTokenType";
  jwtToken: Scalars["String"];
};

export type UserPhoneConfirmInput = {
  phone?: InputMaybe<Scalars["String"]>;
};

export type UserPhoneVerifyInput = {
  pin: Scalars["Float"];
};

export type UserResetPasswordInput = {
  emailEncoded: Scalars["String"];
  passwordRaw?: InputMaybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type UserSignInInput = {
  /** Two factor authentication code */
  code?: InputMaybe<Scalars["Float"]>;
  email: Scalars["String"];
  passwordRaw: Scalars["String"];
};

export type UserUpdateInput = {
  config?: InputMaybe<Scalars["JSONObject"]>;
  currentPasswordRaw?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  passwordRaw?: InputMaybe<Scalars["String"]>;
};

export type FloorPlanInput = {
  assetCategoryIds?: InputMaybe<Array<Scalars["UUID"]>>;
  createdBy?: InputMaybe<Scalars["String"]>;
  createdDate?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  extractAllSheets?: InputMaybe<Scalars["Boolean"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
  locationId: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type StripeBankAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type StripeBankAccountsQuery = {
  __typename?: "Query";
  stripeBankAccounts: Array<{
    __typename?: "StripeBankAccount";
    id?: string;
    account_holder_name?: string;
    account_holder_type?: string;
    bank_name?: string;
    country?: string;
    currency?: string;
    last4?: string;
  }>;
};

export type StripeBankAccountCreateMutationVariables = Exact<{
  plaidAccountId: Scalars["String"];
  plaidLinkPublicToken: Scalars["String"];
  attemptToChargeOpenInvoice?: InputMaybe<Scalars["Boolean"]>;
}>;

export type StripeBankAccountCreateMutation = {
  __typename?: "Mutation";
  stripeBankAccountCreate: {
    __typename?: "StripeBankAccount";
    id?: string;
    account_holder_name?: string;
    account_holder_type?: string;
    bank_name?: string;
    country?: string;
    currency?: string;
    last4?: string;
  };
};

export type StripeBankAccountDeleteMutationVariables = Exact<{
  stripeBankAccountId: Scalars["String"];
}>;

export type StripeBankAccountDeleteMutation = {
  __typename?: "Mutation";
  stripeBankAccountDelete: boolean;
};

export type StripeBankAccountMakePrimaryMutationVariables = Exact<{
  stripeBankAccountId: Scalars["String"];
}>;

export type StripeBankAccountMakePrimaryMutation = {
  __typename?: "Mutation";
  stripeBankAccountMakePrimary: boolean;
};

export type AssetLocationQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AssetLocationQuery = {
  __typename?: "Query";
  asset: { __typename?: "Asset"; id: any; locationsLabel: string };
};

export type StripePaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type StripePaymentMethodsQuery = {
  __typename?: "Query";
  stripePaymentMethods: Array<{
    __typename?: "StripePaymentMethodType";
    id: string;
    type: string;
    card?: {
      __typename?: "StripePaymentMethodTypeCard";
      brand?: string;
      country?: string;
      last4?: string;
      exp_month?: number;
      exp_year?: number;
    };
  }>;
};

export type StripePaymentMethodCreateMutationVariables = Exact<{
  stripePaymentMethodId: Scalars["String"];
  attemptToChargeOpenInvoice?: InputMaybe<Scalars["Boolean"]>;
}>;

export type StripePaymentMethodCreateMutation = {
  __typename?: "Mutation";
  stripePaymentMethodCreate: {
    __typename?: "StripePaymentMethodType";
    id: string;
    type: string;
    card?: {
      __typename?: "StripePaymentMethodTypeCard";
      brand?: string;
      country?: string;
      last4?: string;
      exp_month?: number;
      exp_year?: number;
    };
  };
};

export type StripePaymentMethodDeleteMutationVariables = Exact<{
  stripePaymentMethodId: Scalars["String"];
}>;

export type StripePaymentMethodDeleteMutation = {
  __typename?: "Mutation";
  stripePaymentMethodDelete: boolean;
};

export type StripePaymentMethodMakePrimaryMutationVariables = Exact<{
  stripePaymentMethodId: Scalars["String"];
}>;

export type StripePaymentMethodMakePrimaryMutation = {
  __typename?: "Mutation";
  stripePaymentMethodMakePrimary: boolean;
};

export type StripePaymentMethodUpdateMutationVariables = Exact<{
  stripePaymentMethodId: Scalars["String"];
  expYear: Scalars["Float"];
  expMonth: Scalars["Float"];
}>;

export type StripePaymentMethodUpdateMutation = {
  __typename?: "Mutation";
  stripePaymentMethodUpdate: {
    __typename?: "StripePaymentMethodType";
    id: string;
    type: string;
    card?: {
      __typename?: "StripePaymentMethodTypeCard";
      brand?: string;
      country?: string;
      last4?: string;
      exp_month?: number;
      exp_year?: number;
    };
  };
};

export type FileSignedUrlQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FileSignedUrlQuery = {
  __typename?: "Query";
  file: { __typename?: "File"; id: any; signedUrl: string };
};

export type AssetFilesSignedUrlQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AssetFilesSignedUrlQuery = {
  __typename?: "Query";
  asset: {
    __typename?: "Asset";
    assetFiles: Array<{ __typename?: "AssetFile"; id: any; signedUrl: string }>;
  };
};

export type SignedUrlCreateMutationVariables = Exact<{
  data: Array<SignedUrlInput> | SignedUrlInput;
}>;

export type SignedUrlCreateMutation = {
  __typename?: "Mutation";
  signedUrlCreate: Array<{
    __typename?: "SignedUrl";
    path: string;
    signedUrl: string;
  }>;
};

export type FileCreateMutationVariables = Exact<{
  data: FileCreateInput;
}>;

export type FileCreateMutation = {
  __typename?: "Mutation";
  fileCreate: {
    __typename?: "File";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    folderId: any;
  };
};

export type FloorPlanQueryVariables = Exact<{
  id: Scalars["UUID"];
  companyId?: Scalars["UUID"];
}>;

export type FloorPlanQuery = {
  __typename?: "Query";
  floorPlan: {
    __typename?: "FloorPlan";
    signedUrl: string;
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    type?: string;
    createdBy?: string;
    createdDate?: string;
    locationId: any;
    location: {
      __typename?: "Location";
      id: any;
      name: string;
      description?: string;
      type?: string;
      createdAt: any;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      countryAlpha2?: string;
      coordinates?: Array<number>;
      parentId?: any;
      floorPlans: Array<{
        __typename?: "FloorPlan";
        id: any;
        name: string;
        description?: string;
        path: string;
        createdAt: any;
        fileSize: number;
        type?: string;
        createdBy?: string;
        createdDate?: string;
        locationId: any;
      }>;
    };
    floorPlanAssetCategories: Array<{
      __typename?: "FloorPlanAssetCategory";
      id: any;
      createdAt: any;
      assetCategoryId: any;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
    }>;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      createdAt: any;
      mapPosition?: any;
      mapAffectedArea?: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          assetCategory: {
            __typename?: "AssetCategory";
            id: any;
            name: string;
            description?: string;
            createdAt: any;
            iconName: string;
            iconColor: string;
            iconType: IconTypes;
            default: boolean;
            companyId?: any;
          };
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
        assetAffectedAssets: Array<{
          __typename?: "AssetAffectedAsset";
          id: any;
          assetPartId?: any;
          pathPoints?: any;
          createdAt: any;
          affectedAsset: {
            __typename?: "Asset";
            id: any;
            description?: string;
            createdAt: any;
            config: any;
            name: string;
            maintenanceStatus: MaintenanceStatusType;
            assetType: {
              __typename?: "AssetType";
              id: any;
              name: string;
              description?: string;
              createdAt: any;
              iconName: string;
              iconColor?: string;
              iconType: IconTypes;
              default: boolean;
              specialAssetField?: SpecialAssetFieldTypes;
              assetCategoryId: any;
              companyId?: any;
              misc: {
                __typename?: "AssetTypeMiscData";
                resolvedName: string;
                resolvedIconColor: string;
              };
            };
          };
        }>;
        assetAffectedByAssets: Array<{
          __typename?: "AssetAffectedAsset";
          id: any;
          assetPartId?: any;
          pathPoints?: any;
          createdAt: any;
          asset: {
            __typename?: "Asset";
            id: any;
            description?: string;
            createdAt: any;
            config: any;
            name: string;
            maintenanceStatus: MaintenanceStatusType;
            assetType: {
              __typename?: "AssetType";
              id: any;
              name: string;
              description?: string;
              createdAt: any;
              iconName: string;
              iconColor?: string;
              iconType: IconTypes;
              default: boolean;
              specialAssetField?: SpecialAssetFieldTypes;
              assetCategoryId: any;
              companyId?: any;
              misc: {
                __typename?: "AssetTypeMiscData";
                resolvedName: string;
                resolvedIconColor: string;
              };
            };
          };
        }>;
        assetParts: Array<{
          __typename?: "AssetPart";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          assetId: any;
          assetPartFieldValues: Array<{
            __typename?: "AssetPartFieldValue";
            id: any;
            createdAt: any;
            value?: string;
            assetPartFieldId: any;
          }>;
        }>;
      };
    }>;
  };
};

export type FloorPlanAssetUpdateManyMutationVariables = Exact<{
  data: Array<FloorPlanAssetUpdateManyInput> | FloorPlanAssetUpdateManyInput;
}>;

export type AssetAffectedAssetUpdateManyMutationVariables = Exact<{
  data: Array<AssetAffectedAssetUpdateInput> | AssetAffectedAssetUpdateInput;
}>;

export type FloorPlanAssetUpdateManyMutation = {
  __typename?: "Mutation";
  floorPlanAssetUpdateMany: Array<{
    __typename?: "FloorPlanAsset";
    id: any;
    createdAt: any;
    mapPosition?: any;
    mapAffectedArea?: any;
  }>;
};

export type AssetAffectedAssetUpdateManyMutation = {
  __typename?: "Mutation";
  assetAffectedAssetUpdateMany: Array<{
    __typename?: "AssetAffectedAsset";
    id: any;
    pathPoints?: any;
    assetId?: any;
    affectedAssetId?: any;
    assetPartId?: any;
  }>;
};

export type FloorPlanSimpleQueryVariables = Exact<{ [key: string]: never }>;

export type FloorPlanSimpleQuery = {
  __typename?: "Query";
  floorPlans: Array<{ __typename?: "FloorPlan"; id: any; name: string }>;
};

export type LocationSimpleQueryVariables = Exact<{ [key: string]: never }>;

export type LocationSimpleQuery = {
  __typename?: "Query";
  locations: Array<{ __typename?: "Location"; id: any; name: string }>;
};

export type AssetWithTypeQueryVariables = Exact<{ [key: string]: never }>;

export type AssetWithTypeQuery = {
  __typename?: "Query";
  assets: Array<{
    __typename?: "Asset";
    locationsLabel: string;
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
  }>;
};

export type CompanyStartUpQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyStartUpQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    id: any;
    planId?: string;
    planStripeId?: string;
    isMainCompany: boolean;
    mainCompanyId?: string;
    misc: {
      __typename?: "CompanyMiscData";
      assetCategoryIds: Array<string>;
      assetTypeIds: Array<string>;
      assetStatus: MaintenanceStatusType;
    };
    mainCompany: CompanyFragmentFragment;
  };
};

export type CompanyByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type CompanyByIdQuery = {
  __typename?: "Query";
  company: CompanyFragmentFragment;
};

export type UserEmailConfirmMutationVariables = Exact<{
  data?: InputMaybe<UserEmailConfirmInput>;
}>;

export type UserEmailConfirmMutation = {
  __typename?: "Mutation";
  userEmailConfirm: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
  };
};

export type WebPushNotificationSubscriptionCreateMutationVariables = Exact<{
  subscription: Scalars["JSONObject"];
}>;

export type WebPushNotificationSubscriptionCreateMutation = {
  __typename?: "Mutation";
  webPushNotificationSubscriptionCreate: boolean;
};

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationsQuery = {
  __typename?: "Query";
  notifications: Array<{
    __typename?: "Notification";
    id: any;
    path: string;
    createdAt: any;
    updatedAt: any;
    readAt?: any;
    message: string;
  }>;
};

export type NewNotificationSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NewNotificationSubscription = {
  __typename?: "Subscription";
  newNotification: {
    __typename?: "Notification";
    id: any;
    path: string;
    createdAt: any;
    updatedAt: any;
    readAt?: any;
    message: string;
  };
};

export type UpdateNotificationSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateNotificationSubscription = {
  __typename?: "Subscription";
  updateNotification: {
    __typename?: "Notification";
    id: any;
    path: string;
    createdAt: any;
    updatedAt: any;
    readAt?: any;
    message: string;
  };
};

export type DeleteNotificationSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteNotificationSubscription = {
  __typename?: "Subscription";
  deleteNotification: {
    __typename?: "Notification";
    id: any;
    path: string;
    createdAt: any;
    updatedAt: any;
    readAt?: any;
    message: string;
  };
};

export type NotificationReadMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type NotificationReadMutation = {
  __typename?: "Mutation";
  notificationRead: {
    __typename?: "Notification";
    id: any;
    path: string;
    createdAt: any;
    updatedAt: any;
    readAt?: any;
    message: string;
  };
};

export type NotificationUnreadMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type NotificationUnreadMutation = {
  __typename?: "Mutation";
  notificationUnread: {
    __typename?: "Notification";
    id: any;
    path: string;
    createdAt: any;
    updatedAt: any;
    readAt?: any;
    message: string;
  };
};

export type NotificationDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type NotificationDeleteMutation = {
  __typename?: "Mutation";
  notificationDelete: boolean;
};

export type NotificationDeleteAllMutationVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationDeleteAllMutation = {
  __typename?: "Mutation";
  notificationDeleteAll: boolean;
};

export type NotificationDeleteAllReadMutationVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationDeleteAllReadMutation = {
  __typename?: "Mutation";
  notificationDeleteAllRead: boolean;
};

export type NotificationReadAllMutationVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationReadAllMutation = {
  __typename?: "Mutation";
  notificationReadAll: boolean;
};

export type SearchQueryVariables = Exact<{
  query: Scalars["String"];
  type?: InputMaybe<Array<SearchType> | SearchType>;
}>;

export type SearchQuery = {
  __typename?: "Query";
  search: Array<
    | { __typename?: "Asset"; id: any; name: string }
    | { __typename?: "AssetCategory"; id: any; name: string }
    | { __typename?: "AssetType"; id: any; name: string; assetCategoryId: any }
    | { __typename?: "File"; id: any; name: string; folderId: any }
    | { __typename?: "FloorPlan"; id: any; name: string; locationId: any }
    | { __typename?: "Folder"; id: any; name: string }
    | { __typename?: "Location"; id: any; name: string }
    | {
        __typename?: "Maintenance";
        id: any;
        name: string;
        misc: { __typename?: "MaintenanceMiscData"; resolvedName: string };
      }
  >;
};

type SearchFragmentAssetFragment = {
  __typename?: "Asset";
  id: any;
  name: string;
};

type SearchFragmentAssetCategoryFragment = {
  __typename?: "AssetCategory";
  id: any;
  name: string;
};

type SearchFragmentAssetTypeFragment = {
  __typename?: "AssetType";
  id: any;
  name: string;
  assetCategoryId: any;
};

type SearchFragmentFileFragment = {
  __typename?: "File";
  id: any;
  name: string;
  folderId: any;
};

type SearchFragmentFloorPlanFragment = {
  __typename?: "FloorPlan";
  id: any;
  name: string;
  locationId: any;
};

type SearchFragmentFolderFragment = {
  __typename?: "Folder";
  id: any;
  name: string;
};

type SearchFragmentLocationFragment = {
  __typename?: "Location";
  id: any;
  name: string;
};

type SearchFragmentMaintenanceFragment = {
  __typename?: "Maintenance";
  id: any;
  name: string;
  misc: { __typename?: "MaintenanceMiscData"; resolvedName: string };
};

export type SearchFragmentFragment =
  | SearchFragmentAssetFragment
  | SearchFragmentAssetCategoryFragment
  | SearchFragmentAssetTypeFragment
  | SearchFragmentFileFragment
  | SearchFragmentFloorPlanFragment
  | SearchFragmentFolderFragment
  | SearchFragmentLocationFragment
  | SearchFragmentMaintenanceFragment;

export type AssetFragmentFragment = {
  __typename?: "Asset";
  id: any;
  description?: string;
  createdAt: any;
  config: any;
  name: string;
  maintenanceStatus: MaintenanceStatusType;
};

export type AssetWithTypeFragmentFragment = {
  __typename?: "Asset";
  id: any;
  description?: string;
  createdAt: any;
  config: any;
  name: string;
  maintenanceStatus: MaintenanceStatusType;
  assetType: {
    __typename?: "AssetType";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    default: boolean;
    specialAssetField?: SpecialAssetFieldTypes;
    assetCategoryId: any;
    companyId?: any;
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  };
};

export type AssetWithRelationsFragmentFragment = {
  __typename?: "Asset";
  id: any;
  description?: string;
  createdAt: any;
  config: any;
  name: string;
  maintenanceStatus: MaintenanceStatusType;
  assetType: {
    __typename?: "AssetType";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    default: boolean;
    specialAssetField?: SpecialAssetFieldTypes;
    assetCategoryId: any;
    companyId?: any;
    assetFields: Array<{
      __typename?: "AssetField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
    }>;
    assetPartFields: Array<{
      __typename?: "AssetPartField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
      showInPanel: boolean;
    }>;
    maintenanceTemplates: Array<{
      __typename?: "MaintenanceTemplate";
      id: any;
      createdAt: any;
      description?: string;
      name: string;
      intervalType: MaintenanceIntervalType;
      intervalValue: number;
      assetTypeId: any;
    }>;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  };
  assetParts: Array<{
    __typename?: "AssetPart";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    assetId: any;
    assetPartFieldValues: Array<{
      __typename?: "AssetPartFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetPartFieldId: any;
    }>;
  }>;
  assetFieldValues: Array<{
    __typename?: "AssetFieldValue";
    id: any;
    createdAt: any;
    value?: string;
    assetFieldId: any;
  }>;
  assetFiles: Array<{
    __typename?: "AssetFile";
    id: any;
    name: string;
    description?: string;
    path: string;
    type: AssetFileType;
    fileSize: number;
    order?: number;
    createdAt: any;
    assetId: any;
  }>;
  assetCompanyUsers: Array<{
    __typename?: "AssetCompanyUser";
    id: any;
    createdAt: any;
    companyUser: {
      __typename?: "CompanyUser";
      id: any;
      role: CompanyUserRole;
      createdAt: any;
      user: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string;
        lastName?: string;
        fullName: string;
        jobTitle?: string;
        phone?: string;
        emailConfirmed?: boolean;
        phoneConfirmed?: boolean;
        twoFactorEnabled?: boolean;
        config: any;
        createdAt: any;
        registered: boolean;
        adminRole?: UserAdminRole;
      };
    };
  }>;
  floorPlanAssets: Array<{
    __typename?: "FloorPlanAsset";
    id: any;
    createdAt: any;
    mapPosition?: any;
    mapAffectedArea?: any;
    floorPlan: {
      __typename?: "FloorPlan";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      type?: string;
      createdBy?: string;
      createdDate?: string;
      locationId: any;
    };
  }>;
  assetAffectedAssets: Array<{
    __typename?: "AssetAffectedAsset";
    id: any;
    assetPartId?: any;
    pathPoints?: any;
    createdAt: any;
    affectedAsset: {
      __typename?: "Asset";
      id: any;
      description?: string;
      createdAt: any;
      config: any;
      name: string;
      maintenanceStatus: MaintenanceStatusType;
      assetType: {
        __typename?: "AssetType";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor?: string;
        iconType: IconTypes;
        default: boolean;
        specialAssetField?: SpecialAssetFieldTypes;
        assetCategoryId: any;
        companyId?: any;
        misc: {
          __typename?: "AssetTypeMiscData";
          resolvedName: string;
          resolvedIconColor: string;
        };
      };
    };
  }>;
  assetAffectedByAssets: Array<{
    __typename?: "AssetAffectedAsset";
    id: any;
    assetPartId?: any;
    pathPoints?: any;
    createdAt: any;
    asset: {
      __typename?: "Asset";
      id: any;
      description?: string;
      createdAt: any;
      config: any;
      name: string;
      maintenanceStatus: MaintenanceStatusType;
      assetType: {
        __typename?: "AssetType";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor?: string;
        iconType: IconTypes;
        default: boolean;
        specialAssetField?: SpecialAssetFieldTypes;
        assetCategoryId: any;
        companyId?: any;
        misc: {
          __typename?: "AssetTypeMiscData";
          resolvedName: string;
          resolvedIconColor: string;
        };
      };
    };
  }>;
};

export type AssetForMapFragmentFragment = {
  __typename?: "Asset";
  id: any;
  description?: string;
  createdAt: any;
  config: any;
  name: string;
  maintenanceStatus: MaintenanceStatusType;
  assetType: {
    __typename?: "AssetType";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    default: boolean;
    specialAssetField?: SpecialAssetFieldTypes;
    assetCategoryId: any;
    companyId?: any;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  };
  assetAffectedAssets: Array<{
    __typename?: "AssetAffectedAsset";
    id: any;
    assetPartId?: any;
    pathPoints?: any;
    createdAt: any;
    affectedAsset: {
      __typename?: "Asset";
      id: any;
      description?: string;
      createdAt: any;
      config: any;
      name: string;
      maintenanceStatus: MaintenanceStatusType;
      assetType: {
        __typename?: "AssetType";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor?: string;
        iconType: IconTypes;
        default: boolean;
        specialAssetField?: SpecialAssetFieldTypes;
        assetCategoryId: any;
        companyId?: any;
        misc: {
          __typename?: "AssetTypeMiscData";
          resolvedName: string;
          resolvedIconColor: string;
        };
      };
    };
  }>;
  assetAffectedByAssets: Array<{
    __typename?: "AssetAffectedAsset";
    id: any;
    assetPartId?: any;
    pathPoints?: any;
    createdAt: any;
    asset: {
      __typename?: "Asset";
      id: any;
      description?: string;
      createdAt: any;
      config: any;
      name: string;
      maintenanceStatus: MaintenanceStatusType;
      assetType: {
        __typename?: "AssetType";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor?: string;
        iconType: IconTypes;
        default: boolean;
        specialAssetField?: SpecialAssetFieldTypes;
        assetCategoryId: any;
        companyId?: any;
        misc: {
          __typename?: "AssetTypeMiscData";
          resolvedName: string;
          resolvedIconColor: string;
        };
      };
    };
  }>;
  assetParts: Array<{
    __typename?: "AssetPart";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    assetId: any;
    assetPartFieldValues: Array<{
      __typename?: "AssetPartFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetPartFieldId: any;
    }>;
  }>;
};

export type AssetCompanyUserFragmentFragment = {
  __typename?: "AssetCompanyUser";
  id: any;
  createdAt: any;
  companyUser: {
    __typename?: "CompanyUser";
    id: any;
    role: CompanyUserRole;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      jobTitle?: string;
      phone?: string;
      emailConfirmed?: boolean;
      phoneConfirmed?: boolean;
      twoFactorEnabled?: boolean;
      config: any;
      createdAt: any;
      registered: boolean;
      adminRole?: UserAdminRole;
    };
  };
};

export type AssetPartFragmentFragment = {
  __typename?: "AssetPart";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  assetId: any;
};

export type AssetPartWithRelationsFragmentFragment = {
  __typename?: "AssetPart";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  assetId: any;
  assetPartFieldValues: Array<{
    __typename?: "AssetPartFieldValue";
    id: any;
    createdAt: any;
    value?: string;
    assetPartFieldId: any;
  }>;
};

export type AssetPartFieldValueFragmentFragment = {
  __typename?: "AssetPartFieldValue";
  id: any;
  createdAt: any;
  value?: string;
  assetPartFieldId: any;
};

export type AssetFieldValueFragmentFragment = {
  __typename?: "AssetFieldValue";
  id: any;
  createdAt: any;
  value?: string;
  assetFieldId: any;
};

export type AssetFileFragmentFragment = {
  __typename?: "AssetFile";
  id: any;
  name: string;
  description?: string;
  path: string;
  type: AssetFileType;
  fileSize: number;
  order?: number;
  createdAt: any;
  assetId: any;
};

export type FloorPlanAssetFragmentFragment = {
  __typename?: "FloorPlanAsset";
  id: any;
  createdAt: any;
  mapPosition?: any;
  mapAffectedArea?: any;
};

export type FloorPlanAssetWithFloorPlanFragmentFragment = {
  __typename?: "FloorPlanAsset";
  id: any;
  createdAt: any;
  mapPosition?: any;
  mapAffectedArea?: any;
  floorPlan: {
    __typename?: "FloorPlan";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    type?: string;
    createdBy?: string;
    createdDate?: string;
    locationId: any;
  };
};

export type FloorPlanAssetWithAssetFragmentFragment = {
  __typename?: "FloorPlanAsset";
  id: any;
  createdAt: any;
  mapPosition?: any;
  mapAffectedArea?: any;
  asset: {
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
  };
};

export type AssetAffectedAssetFragmentFragment = {
  __typename?: "AssetAffectedAsset";
  id: any;
  assetPartId?: any;
  pathPoints?: any;
  createdAt: any;
};

export type AffectedAssetWithAffectedAssetFragmentFragment = {
  __typename?: "AssetAffectedAsset";
  id: any;
  assetPartId?: any;
  pathPoints?: any;
  createdAt: any;
  affectedAsset: {
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
  };
};

export type AffectedByAssetWithAssetFragmentFragment = {
  __typename?: "AssetAffectedAsset";
  id: any;
  assetPartId?: any;
  pathPoints?: any;
  createdAt: any;
  asset: {
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
  };
};

export type AssetCategoryFragmentFragment = {
  __typename?: "AssetCategory";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  iconName: string;
  iconColor: string;
  iconType: IconTypes;
  default: boolean;
  companyId?: any;
};

export interface AssetTypeItem {
  __typename?: "AssetType";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  iconName: string;
  iconColor?: string;
  iconType: IconTypes;
  default: boolean;
  specialAssetField?: SpecialAssetFieldTypes;
  assetCategoryId: any;
  companyId?: any;
  misc: {
    __typename?: "AssetTypeMiscData";
    resolvedName: string;
    resolvedIconColor: string;
  };
}

export interface AssetCategoryItem {
  __typename?: "AssetCategory";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  iconName: string;
  iconColor: string;
  iconType: IconTypes;
  default: boolean;
  companyId?: any;
  assetTypes?: Array<AssetTypeItem>;
}

export type AssetCategoryWithTypesFragmentFragment = AssetCategoryItem;

export type AssetCategoryWithRelationsFragmentFragment = {
  __typename?: "AssetCategory";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  iconName: string;
  iconColor: string;
  iconType: IconTypes;
  default: boolean;
  companyId?: any;
  assetTypes?: Array<{
    __typename?: "AssetType";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    default: boolean;
    specialAssetField?: SpecialAssetFieldTypes;
    assetCategoryId: any;
    companyId?: any;
    assetFields: Array<{
      __typename?: "AssetField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
    }>;
    assetPartFields: Array<{
      __typename?: "AssetPartField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
      showInPanel: boolean;
    }>;
    maintenanceTemplates: Array<{
      __typename?: "MaintenanceTemplate";
      id: any;
      createdAt: any;
      description?: string;
      name: string;
      intervalType: MaintenanceIntervalType;
      intervalValue: number;
      assetTypeId: any;
    }>;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  }>;
};

export type AssetTypeFragmentFragment = {
  __typename?: "AssetType";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  iconName: string;
  iconColor?: string;
  iconType: IconTypes;
  default: boolean;
  specialAssetField?: SpecialAssetFieldTypes;
  assetCategoryId: any;
  companyId?: any;
  misc: {
    __typename?: "AssetTypeMiscData";
    resolvedName: string;
    resolvedIconColor: string;
  };
};

export type AssetTypeWithCategoryFragmentFragment = {
  __typename?: "AssetType";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  iconName: string;
  iconColor?: string;
  iconType: IconTypes;
  default: boolean;
  specialAssetField?: SpecialAssetFieldTypes;
  assetCategoryId: any;
  companyId?: any;
  assetCategory: {
    __typename?: "AssetCategory";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor: string;
    iconType: IconTypes;
    default: boolean;
    companyId?: any;
  };
  misc: {
    __typename?: "AssetTypeMiscData";
    resolvedName: string;
    resolvedIconColor: string;
  };
};

export type AssetTypeWithRelationsFragmentFragment = {
  __typename?: "AssetType";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  iconName: string;
  iconColor?: string;
  iconType: IconTypes;
  default: boolean;
  specialAssetField?: SpecialAssetFieldTypes;
  assetCategoryId: any;
  companyId?: any;
  assetFields: Array<{
    __typename?: "AssetField";
    id: any;
    description?: string;
    createdAt: any;
    label: string;
    type: FieldType;
    selectOptions?: string;
    unit?: string;
    required: boolean;
    validationRegExp?: any;
    validationRegExpMessage?: string;
    order?: number;
  }>;
  assetPartFields: Array<{
    __typename?: "AssetPartField";
    id: any;
    description?: string;
    createdAt: any;
    label: string;
    type: FieldType;
    selectOptions?: string;
    unit?: string;
    required: boolean;
    validationRegExp?: any;
    validationRegExpMessage?: string;
    order?: number;
    showInPanel: boolean;
  }>;
  maintenanceTemplates: Array<{
    __typename?: "MaintenanceTemplate";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    intervalType: MaintenanceIntervalType;
    intervalValue: number;
    assetTypeId: any;
  }>;
  assetCategory: {
    __typename?: "AssetCategory";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor: string;
    iconType: IconTypes;
    default: boolean;
    companyId?: any;
  };
  misc: {
    __typename?: "AssetTypeMiscData";
    resolvedName: string;
    resolvedIconColor: string;
  };
};

export type AssetFieldFragmentFragment = {
  __typename?: "AssetField";
  id: any;
  description?: string;
  createdAt: any;
  label: string;
  type: FieldType;
  selectOptions?: string;
  unit?: string;
  required: boolean;
  validationRegExp?: any;
  validationRegExpMessage?: string;
  order?: number;
};

export type AssetPartFieldFragmentFragment = {
  __typename?: "AssetPartField";
  id: any;
  description?: string;
  createdAt: any;
  label: string;
  type: FieldType;
  selectOptions?: string;
  unit?: string;
  required: boolean;
  validationRegExp?: any;
  validationRegExpMessage?: string;
  order?: number;
  showInPanel: boolean;
};

export type MaintenanceTemplateFragmentFragment = {
  __typename?: "MaintenanceTemplate";
  id: any;
  createdAt: any;
  description?: string;
  name: string;
  intervalType: MaintenanceIntervalType;
  intervalValue: number;
  assetTypeId: any;
};

export type FileFragmentFragment = {
  __typename?: "File";
  id: any;
  name: string;
  description?: string;
  path: string;
  createdAt: any;
  fileSize: number;
  folderId: any;
};

export type FolderFragmentFragment = {
  __typename?: "Folder";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  parentId?: any;
};

export type FolderWithRelationsFragmentFragment = {
  __typename?: "Folder";
  id: any;
  name: string;
  description?: string;
  createdAt: any;
  parentId?: any;
  parent?: {
    __typename?: "Folder";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    parentId?: any;
  };
  files: Array<{
    __typename?: "File";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    folderId: any;
  }>;
};

export type LocationFragmentFragment = {
  __typename?: "Location";
  id: any;
  name: string;
  description?: string;
  type?: string;
  createdAt: any;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  countryAlpha2?: string;
  coordinates?: Array<number>;
  parentId?: any;
};

export type LocationWithRelationsFragmentFragment = {
  __typename?: "Location";
  id: any;
  name: string;
  description?: string;
  type?: string;
  createdAt: any;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  countryAlpha2?: string;
  coordinates?: Array<number>;
  parentId?: any;
  parent?: {
    __typename?: "Location";
    id: any;
    name: string;
    description?: string;
    type?: string;
    createdAt: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
  };
  floorPlans: Array<{
    __typename?: "FloorPlan";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    type?: string;
    createdBy?: string;
    createdDate?: string;
    locationId: any;
    location: {
      __typename?: "Location";
      id: any;
      name: string;
      description?: string;
      type?: string;
      createdAt: any;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      countryAlpha2?: string;
      coordinates?: Array<number>;
      parentId?: any;
    };
    floorPlanAssetCategories: Array<{
      __typename?: "FloorPlanAssetCategory";
      id: any;
      createdAt: any;
      assetCategoryId: any;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
    }>;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      createdAt: any;
      mapPosition?: any;
      mapAffectedArea?: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
  }>;
  locationCompanyUsers: Array<{
    __typename?: "LocationCompanyUser";
    id: any;
    createdAt: any;
    companyUser: {
      __typename?: "CompanyUser";
      id: any;
      role: CompanyUserRole;
      createdAt: any;
      user: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string;
        lastName?: string;
        fullName: string;
        jobTitle?: string;
        phone?: string;
        emailConfirmed?: boolean;
        phoneConfirmed?: boolean;
        twoFactorEnabled?: boolean;
        config: any;
        createdAt: any;
        registered: boolean;
        adminRole?: UserAdminRole;
      };
    };
  }>;
};

export type LocationCompanyUserFragmentFragment = {
  __typename?: "LocationCompanyUser";
  id: any;
  createdAt: any;
  companyUser: {
    __typename?: "CompanyUser";
    id: any;
    role: CompanyUserRole;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      jobTitle?: string;
      phone?: string;
      emailConfirmed?: boolean;
      phoneConfirmed?: boolean;
      twoFactorEnabled?: boolean;
      config: any;
      createdAt: any;
      registered: boolean;
      adminRole?: UserAdminRole;
    };
  };
};

export type FloorPlanFragmentFragment = {
  __typename?: "FloorPlan";
  id: any;
  name: string;
  description?: string;
  path: string;
  createdAt: any;
  fileSize: number;
  type?: string;
  createdBy?: string;
  createdDate?: string;
  locationId: any;
};

export type FloorPlanWithRelationsFragmentFragment = {
  __typename?: "FloorPlan";
  id: any;
  name: string;
  description?: string;
  path: string;
  createdAt: any;
  fileSize: number;
  type?: string;
  createdBy?: string;
  createdDate?: string;
  locationId: any;
  location: {
    __typename?: "Location";
    id: any;
    name: string;
    description?: string;
    type?: string;
    createdAt: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
  };
  floorPlanAssetCategories: Array<{
    __typename?: "FloorPlanAssetCategory";
    id: any;
    createdAt: any;
    assetCategoryId: any;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
  }>;
  floorPlanAssets: Array<{
    __typename?: "FloorPlanAsset";
    id: any;
    createdAt: any;
    mapPosition?: any;
    mapAffectedArea?: any;
    asset: {
      __typename?: "Asset";
      id: any;
      description?: string;
      createdAt: any;
      config: any;
      name: string;
      maintenanceStatus: MaintenanceStatusType;
      assetType: {
        __typename?: "AssetType";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor?: string;
        iconType: IconTypes;
        default: boolean;
        specialAssetField?: SpecialAssetFieldTypes;
        assetCategoryId: any;
        companyId?: any;
        misc: {
          __typename?: "AssetTypeMiscData";
          resolvedName: string;
          resolvedIconColor: string;
        };
      };
    };
  }>;
};

export type FloorPlanForMapFragmentFragment = {
  __typename?: "FloorPlan";
  signedUrl: string;
  id: any;
  name: string;
  description?: string;
  path: string;
  createdAt: any;
  fileSize: number;
  type?: string;
  createdBy?: string;
  createdDate?: string;
  locationId: any;
  location: {
    __typename?: "Location";
    id: any;
    name: string;
    description?: string;
    type?: string;
    createdAt: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
    floorPlans: Array<{
      __typename?: "FloorPlan";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      type?: string;
      createdBy?: string;
      createdDate?: string;
      locationId: any;
    }>;
  };
  floorPlanAssetCategories: Array<{
    __typename?: "FloorPlanAssetCategory";
    id: any;
    createdAt: any;
    assetCategoryId: any;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
  }>;
  floorPlanAssets: Array<{
    __typename?: "FloorPlanAsset";
    id: any;
    createdAt: any;
    mapPosition?: any;
    mapAffectedArea?: any;
    asset: {
      __typename?: "Asset";
      id: any;
      description?: string;
      createdAt: any;
      config: any;
      name: string;
      maintenanceStatus: MaintenanceStatusType;
      assetType: {
        __typename?: "AssetType";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor?: string;
        iconType: IconTypes;
        default: boolean;
        specialAssetField?: SpecialAssetFieldTypes;
        assetCategoryId: any;
        companyId?: any;
        assetCategory: {
          __typename?: "AssetCategory";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor: string;
          iconType: IconTypes;
          default: boolean;
          companyId?: any;
        };
        misc: {
          __typename?: "AssetTypeMiscData";
          resolvedName: string;
          resolvedIconColor: string;
        };
      };
      assetAffectedAssets: Array<{
        __typename?: "AssetAffectedAsset";
        id: any;
        assetPartId?: any;
        pathPoints?: any;
        createdAt: any;
        affectedAsset: {
          __typename?: "Asset";
          id: any;
          description?: string;
          createdAt: any;
          config: any;
          name: string;
          maintenanceStatus: MaintenanceStatusType;
          assetType: {
            __typename?: "AssetType";
            id: any;
            name: string;
            description?: string;
            createdAt: any;
            iconName: string;
            iconColor?: string;
            iconType: IconTypes;
            default: boolean;
            specialAssetField?: SpecialAssetFieldTypes;
            assetCategoryId: any;
            companyId?: any;
            misc: {
              __typename?: "AssetTypeMiscData";
              resolvedName: string;
              resolvedIconColor: string;
            };
          };
        };
      }>;
      assetAffectedByAssets: Array<{
        __typename?: "AssetAffectedAsset";
        id: any;
        assetPartId?: any;
        pathPoints?: any;
        createdAt: any;
        asset: {
          __typename?: "Asset";
          id: any;
          description?: string;
          createdAt: any;
          config: any;
          name: string;
          maintenanceStatus: MaintenanceStatusType;
          assetType: {
            __typename?: "AssetType";
            id: any;
            name: string;
            description?: string;
            createdAt: any;
            iconName: string;
            iconColor?: string;
            iconType: IconTypes;
            default: boolean;
            specialAssetField?: SpecialAssetFieldTypes;
            assetCategoryId: any;
            companyId?: any;
            misc: {
              __typename?: "AssetTypeMiscData";
              resolvedName: string;
              resolvedIconColor: string;
            };
          };
        };
      }>;
      assetParts: Array<{
        __typename?: "AssetPart";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        assetId: any;
        assetPartFieldValues: Array<{
          __typename?: "AssetPartFieldValue";
          id: any;
          createdAt: any;
          value?: string;
          assetPartFieldId: any;
        }>;
      }>;
    };
  }>;
};

export type FloorPlanAssetForMapFragmentFragment = {
  __typename?: "FloorPlanAsset";
  id: any;
  createdAt: any;
  mapPosition?: any;
  mapAffectedArea?: any;
  asset: {
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
    assetAffectedAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any[];
      createdAt: any;
      affectedAsset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
    assetAffectedByAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
    assetParts: Array<{
      __typename?: "AssetPart";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      assetId: any;
      assetPartFieldValues: Array<{
        __typename?: "AssetPartFieldValue";
        id: any;
        createdAt: any;
        value?: string;
        assetPartFieldId: any;
      }>;
    }>;
  };
};

export type FloorPlanAssetCategoryFragmentFragment = {
  __typename?: "FloorPlanAssetCategory";
  id: any;
  createdAt: any;
  assetCategoryId: any;
  assetCategory: {
    __typename?: "AssetCategory";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor: string;
    iconType: IconTypes;
    default: boolean;
    companyId?: any;
  };
};

export type CategoriesSummaryFragmentFragment = {
  __typename?: "CategoriesSummary";
  categoryId?: string;
  categoryName: string;
  currentAssetIds?: Array<string>;
  currentCount: number;
  pastDueAssetIds?: Array<string>;
  pastDueCount: number;
  dueIn30AssetIds?: Array<string>;
  dueIn30Count: number;
};

export type MaintenanceReportFragmentFragment = {
  __typename?: "MaintenanceReport";
  date: any;
  currentCount: number;
  pastDueCount: number;
  dueIn30Count: number;
};

export type SimpleMaintenanceFragmentFragment = {
  __typename?: "Maintenance";
  id: any;
  createdAt: any;
  description?: string;
  name: string;
  startDateTime: any;
  timezone: string;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  durationType: MaintenanceDurationType;
  durationValue: number;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
  endAfter?: number;
  endDateTime?: any;
  updatedAt: any;
  assetId?: any;
  deletedScheduleTimestamps: Array<string>;
};

export type MaintenanceFragmentFragment = {
  __typename?: "Maintenance";
  id: any;
  createdAt: any;
  description?: string;
  name: string;
  startDateTime: any;
  timezone: string;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  durationType: MaintenanceDurationType;
  durationValue: number;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
  endAfter?: number;
  endDateTime?: any;
  updatedAt: any;
  assetId?: any;
  deletedScheduleTimestamps: Array<string>;
  misc: {
    __typename?: "MaintenanceMiscData";
    resolvedName: string;
    assetCategoryId?: string;
  };
};

export type MaintenanceWithContactsFragmentFragment = {
  __typename?: "Maintenance";
  id: any;
  createdAt: any;
  description?: string;
  name: string;
  startDateTime: any;
  timezone: string;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  durationType: MaintenanceDurationType;
  durationValue: number;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
  endAfter?: number;
  endDateTime?: any;
  updatedAt: any;
  assetId?: any;
  deletedScheduleTimestamps: Array<string>;
  maintenanceCompanyUsers: Array<{
    __typename?: "MaintenanceCompanyUser";
    id: any;
    companyUserId: any;
    companyUser: {
      __typename?: "CompanyUser";
      id: any;
      role: CompanyUserRole;
      createdAt: any;
      user: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string;
        lastName?: string;
        fullName: string;
        jobTitle?: string;
        phone?: string;
        emailConfirmed?: boolean;
        phoneConfirmed?: boolean;
        twoFactorEnabled?: boolean;
        config: any;
        createdAt: any;
        registered: boolean;
        adminRole?: UserAdminRole;
      };
    };
  }>;
  misc: {
    __typename?: "MaintenanceMiscData";
    resolvedName: string;
    assetCategoryId?: string;
  };
};

export type MaintenanceWithSchedulesFragmentFragment = {
  __typename?: "Maintenance";
  id: any;
  createdAt: any;
  description?: string;
  name: string;
  startDateTime: any;
  timezone: string;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  durationType: MaintenanceDurationType;
  durationValue: number;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
  endAfter?: number;
  endDateTime?: any;
  updatedAt: any;
  assetId?: any;
  deletedScheduleTimestamps: Array<string>;
  maintenanceSchedules: Array<{
    __typename?: "MaintenanceSchedule";
    id: any;
    createdAt: any;
    updatedAt: any;
    maintenanceId: any;
    completedAt?: any;
    scheduledAt: any;
    completedByLabel: string;
  }>;
};

export type MaintenanceWithRelationsFragmentFragment = {
  __typename?: "Maintenance";
  id: any;
  createdAt: any;
  description?: string;
  name: string;
  startDateTime: any;
  timezone: string;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  durationType: MaintenanceDurationType;
  durationValue: number;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
  endAfter?: number;
  endDateTime?: any;
  updatedAt: any;
  assetId?: any;
  deletedScheduleTimestamps: Array<string>;
  asset?: { __typename?: "Asset"; id: any; locationsLabel: string };
  maintenanceCompanyUsers: Array<{
    __typename?: "MaintenanceCompanyUser";
    id: any;
    companyUserId: any;
    companyUser: {
      __typename?: "CompanyUser";
      id: any;
      role: CompanyUserRole;
      createdAt: any;
      user: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string;
        lastName?: string;
        fullName: string;
        jobTitle?: string;
        phone?: string;
        emailConfirmed?: boolean;
        phoneConfirmed?: boolean;
        twoFactorEnabled?: boolean;
        config: any;
        createdAt: any;
        registered: boolean;
        adminRole?: UserAdminRole;
      };
    };
  }>;
  maintenanceNotes: Array<{
    __typename?: "MaintenanceNote";
    id: any;
    createdAt: any;
    description?: string;
    updatedAt: any;
    maintenanceId: any;
    addedBy: {
      __typename?: "AddedBy";
      userId: string;
      userName?: string;
      userEmail: string;
      userPhone?: string;
    };
  }>;
  maintenanceSchedulesForDateRange: Array<{
    __typename?: "MaintenanceSchedule";
    id: any;
    createdAt: any;
    updatedAt: any;
    maintenanceId: any;
    completedAt?: any;
    scheduledAt: any;
    completedByLabel: string;
  }>;
  misc: {
    __typename?: "MaintenanceMiscData";
    resolvedName: string;
    assetCategoryId?: string;
  };
};

export type MaintenanceNoteFragmentFragment = {
  __typename?: "MaintenanceNote";
  id: any;
  createdAt: any;
  description?: string;
  updatedAt: any;
  maintenanceId: any;
  addedBy: {
    __typename?: "AddedBy";
    userId: string;
    userName?: string;
    userEmail: string;
    userPhone?: string;
  };
};

export type MaintenanceScheduleFragmentFragment = {
  __typename?: "MaintenanceSchedule";
  id: any;
  createdAt: any;
  updatedAt: any;
  maintenanceId: any;
  completedAt?: any;
  scheduledAt: any;
  completedByLabel: string;
};

export type SignedUrlFragmentFragment = {
  __typename?: "SignedUrl";
  path: string;
  signedUrl: string;
};

export type NotificationFragmentFragment = {
  __typename?: "Notification";
  id: any;
  path: string;
  createdAt: any;
  updatedAt: any;
  readAt?: any;
  message: string;
};

export type PlansFragmentFragment = {
  __typename?: "Plan";
  id: any;
  productName: string;
  stripeId: string;
  amount: number;
  currency: string;
  interval: string;
  interval_count: number;
  active: boolean;
  default: boolean;
  proRateAmount?: number;
  createdAt: any;
};

export type StripeBankAccountFragmentFragment = {
  __typename?: "StripeBankAccount";
  id?: string;
  account_holder_name?: string;
  account_holder_type?: string;
  bank_name?: string;
  country?: string;
  currency?: string;
  last4?: string;
};

export type StripePaymentMethodTypeCardFragmentFragment = {
  __typename?: "StripePaymentMethodTypeCard";
  brand?: string;
  country?: string;
  last4?: string;
  exp_month?: number;
  exp_year?: number;
};

export type StripePaymentMethodTypeFragmentFragment = {
  __typename?: "StripePaymentMethodType";
  id: string;
  type: string;
  card?: {
    __typename?: "StripePaymentMethodTypeCard";
    brand?: string;
    country?: string;
    last4?: string;
    exp_month?: number;
    exp_year?: number;
  };
};

export type StripeCustomerFragmentFragment = {
  __typename?: "StripeCustomer";
  id: string;
  email?: string;
  name?: string;
  phone?: string;
  created?: number;
  default_source?: string;
  invoice_settings?: {
    __typename?: "StripeInvoiceSettings";
    default_payment_method?: string;
  };
};

export type StripeInvoiceFragmentFragment = {
  __typename?: "StripeInvoice";
  id?: string;
  amount_due?: number;
  amount_paid?: number;
  amount_remaining?: number;
  attempted: boolean;
  auto_advance?: boolean;
  billing_reason?: string;
  collection_method?: string;
  currency?: string;
  hosted_invoice_url?: string;
  invoice_pdf?: string;
  status: string;
  paid: boolean;
  created?: number;
  due_date?: number;
  next_payment_attempt?: number;
  receipt_number?: string;
  discount?: {
    __typename?: "StripeDiscount";
    coupon: {
      __typename?: "StripeCoupon";
      id: string;
      valid: boolean;
      currency?: string;
      duration?: string;
      duration_in_months?: number;
      name?: string;
      percent_off?: number;
      amount_off?: number;
    };
  };
  charge?: {
    __typename?: "StripeCharge";
    receipt_url?: string;
    amount_refunded?: number;
    payment_method_details?: {
      __typename?: "StripeChargePaymentMethodDetails";
      type: string;
      ach_debit?: {
        __typename?: "StripeBankAccount";
        id?: string;
        account_holder_name?: string;
        account_holder_type?: string;
        bank_name?: string;
        country?: string;
        currency?: string;
        last4?: string;
      };
      card?: {
        __typename?: "StripePaymentMethodTypeCard";
        brand?: string;
        country?: string;
        last4?: string;
        exp_month?: number;
        exp_year?: number;
      };
    };
  };
  payment_intent?: {
    __typename?: "StripePaymentIntent";
    status: string;
    client_secret: string;
  };
};

export type StripeProrationFragmentFragment = {
  __typename?: "StripeProration";
  dueDate?: number;
  amount: number;
};

export type StripeSubscriptionFragmentFragment = {
  __typename?: "StripeSubscription";
  id: string;
  cancel_at_period_end: boolean;
  status: string;
  cancel_at?: number;
  canceled_at?: number;
  created: number;
  ended_at?: number;
  start_date: number;
  trial_end?: number;
  trial_start?: number;
  days_until_due?: number;
};

export type StripeCouponFragmentFragment = {
  __typename?: "StripeCoupon";
  id: string;
  valid: boolean;
  currency?: string;
  percent_off?: number;
  amount_off?: number;
  duration?: string;
  duration_in_months?: number;
};

export type UserFragmentFragment = {
  __typename?: "User";
  id: any;
  email: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  jobTitle?: string;
  phone?: string;
  emailConfirmed?: boolean;
  phoneConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  config: any;
  createdAt: any;
  registered: boolean;
  adminRole?: UserAdminRole;
};

export type CompanyUserFragmentFragment = {
  __typename?: "CompanyUser";
  id: any;
  role: CompanyUserRole;
  createdAt: any;
};

export type CompanyUserWithUserFragmentFragment = {
  __typename?: "CompanyUser";
  id: any;
  role: CompanyUserRole;
  createdAt: any;
  user: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
  };
};

export type CompanyUserWithCompanyFragmentFragment = {
  __typename?: "CompanyUser";
  id: any;
  role: CompanyUserRole;
  createdAt: any;
  company: {
    __typename?: "Company";
    id: any;
    name: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    businessType?: string;
    website?: string;
    industry?: string;
    planStripeId?: string;
    planStripeCancelAt?: any;
    planStripeStatus?: string;
    planStripeTrialEnd?: any;
    planStripeEndedAt?: any;
    planStripeCurrentPeriodEnd?: any;
    defaultFolderId?: any;
    uploadsFolderId?: any;
    locationCount: number;
    canAddLocation: CompanyPermissionType;
    assetCount: number;
    companyUserCount: number;
    fileSizeTotal: number;
    createdAt: any;
    config: any;
    planId?: string;
    hiddenCategoryIds: Array<string>;
    hiddenTypeIds: Array<string>;
  };
};

export type CompanyFragmentFragment = {
  __typename?: "Company";
  id: any;
  name: string;
  email?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  countryAlpha2?: string;
  coordinates?: Array<number>;
  businessType?: string;
  website?: string;
  industry?: string;
  planStripeId?: string;
  planStripeCancelAt?: any;
  planStripeStatus?: string;
  planStripeTrialEnd?: any;
  planStripeEndedAt?: any;
  planStripeCurrentPeriodEnd?: any;
  defaultFolderId?: any;
  uploadsFolderId?: any;
  locationCount: number;
  canAddLocation: CompanyPermissionType;
  assetCount: number;
  companyUserCount: number;
  fileSizeTotal: number;
  createdAt: any;
  config: any;
  planId?: string;
  isMainCompany?: boolean;
  mainCompanyId?: string;
  hiddenCategoryIds: Array<string>;
  hiddenTypeIds: Array<string>;
};

export type CompanyWithContactUsersFragmentFragment = {
  __typename?: "Company";
  id: any;
  name: string;
  email?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  countryAlpha2?: string;
  coordinates?: Array<number>;
  businessType?: string;
  website?: string;
  industry?: string;
  planStripeId?: string;
  planStripeCancelAt?: any;
  planStripeStatus?: string;
  planStripeTrialEnd?: any;
  planStripeEndedAt?: any;
  planStripeCurrentPeriodEnd?: any;
  defaultFolderId?: any;
  uploadsFolderId?: any;
  locationCount: number;
  canAddLocation: CompanyPermissionType;
  assetCount: number;
  companyUserCount: number;
  fileSizeTotal: number;
  createdAt: any;
  config: any;
  planId?: string;
  hiddenCategoryIds: Array<string>;
  hiddenTypeIds: Array<string>;
  companyCompanyUsers: Array<{
    __typename?: "CompanyCompanyUser";
    id: any;
    createdAt: any;
    companyUser: {
      __typename?: "CompanyUser";
      id: any;
      role: CompanyUserRole;
      createdAt: any;
      user: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string;
        lastName?: string;
        fullName: string;
        jobTitle?: string;
        phone?: string;
        emailConfirmed?: boolean;
        phoneConfirmed?: boolean;
        twoFactorEnabled?: boolean;
        config: any;
        createdAt: any;
        registered: boolean;
        adminRole?: UserAdminRole;
      };
    };
  }>;
};

export type CompanyCompanyUserFragmentFragment = {
  __typename?: "CompanyCompanyUser";
  id: any;
  createdAt: any;
  companyUser: {
    __typename?: "CompanyUser";
    id: any;
    role: CompanyUserRole;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      jobTitle?: string;
      phone?: string;
      emailConfirmed?: boolean;
      phoneConfirmed?: boolean;
      twoFactorEnabled?: boolean;
      config: any;
      createdAt: any;
      registered: boolean;
      adminRole?: UserAdminRole;
    };
  };
};

export type UpdateSessionMutationVariables = Exact<{
  jwt?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateSessionMutation = {
  __typename?: "Mutation";
  updateSession: boolean;
};

export type UpdateCurrentCompanyMutationVariables = Exact<{
  id?: InputMaybe<Scalars["UUID"]>;
}>;

export type UpdateCurrentCompanyMutation = {
  __typename?: "Mutation";
  updateCurrentCompany: boolean;
};

export type UpdateAppSettingsMutationVariables = Exact<{
  sideNavMinimized?: InputMaybe<Scalars["Boolean"]>;
  plaidOpen?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateAppSettingsMutation = {
  __typename?: "Mutation";
  updateAppSettings: boolean;
};

export type UpdateFolderConfigMutationVariables = Exact<{
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  displayType?: InputMaybe<FolderDisplayType>;
}>;

export type UpdateFolderConfigMutation = {
  __typename?: "Mutation";
  updateFolderConfig: boolean;
};

export type UpdateLocationConfigMutationVariables = Exact<{
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  activeTabIndex?: InputMaybe<Scalars["Int"]>;
  activeCollapsible?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type UpdateLocationConfigMutation = {
  __typename?: "Mutation";
  updateLocationConfig: boolean;
};

export type UpdateAssetConfigMutationVariables = Exact<{
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  activeTabIndex?: InputMaybe<Scalars["Int"]>;
  displayType?: InputMaybe<AssetDisplayType>;
  filter?: InputMaybe<
    Array<InputMaybe<MaintenanceStatusType>> | InputMaybe<MaintenanceStatusType>
  >;
  filterCategoryId?: InputMaybe<Scalars["String"]>;
  activeCollapsible?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type UpdateAssetConfigMutation = {
  __typename?: "Mutation";
  updateAssetConfig: boolean;
};

export type UpdateMaintenanceConfigMutationVariables = Exact<{
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  displayType?: InputMaybe<MaintenanceDisplayType>;
  filterStatus?: InputMaybe<
    | Array<InputMaybe<MaintenanceFilterStatus>>
    | InputMaybe<MaintenanceFilterStatus>
  >;
  filterCategoryId?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateMaintenanceConfigMutation = {
  __typename?: "Mutation";
  updateMaintenanceConfig: boolean;
};

export type UpdateAssetCategoryConfigMutationVariables = Exact<{
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  displayType?: InputMaybe<AssetCategoryDisplayType>;
  showHidden?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateAssetCategoryConfigMutation = {
  __typename?: "Mutation";
  updateAssetCategoryConfig: boolean;
};

export type UpdateAssetTypeConfigMutationVariables = Exact<{
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  displayType?: InputMaybe<AssetTypeDisplayType>;
}>;

export type UpdateAssetTypeConfigMutation = {
  __typename?: "Mutation";
  updateAssetTypeConfig: boolean;
};

export type UpdateFloorPlanConfigMutationVariables = Exact<{
  sortBy?: InputMaybe<FloorPlanSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  displayType?: InputMaybe<FloorPlanDisplayType>;
  filterCategoryId?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateFloorPlanConfigMutation = {
  __typename?: "Mutation";
  updateFloorPlanConfig: boolean;
};

export type UpdateDashboardConfigMutationVariables = Exact<{
  filter?: InputMaybe<
    Array<InputMaybe<MaintenanceStatusType>> | InputMaybe<MaintenanceStatusType>
  >;
}>;

export type UpdateDashboardConfigMutation = {
  __typename?: "Mutation";
  updateDashboardConfig: boolean;
};

export type SessionQueryVariables = Exact<{ [key: string]: never }>;

export type SessionQuery = {
  __typename?: "Query";
  session: { __typename?: "Session"; isLoggedIn: boolean };
};

export type CurrentCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCompanyQuery = {
  __typename?: "Query";
  currentCompany: { __typename?: "CurrentCompany"; id?: any };
};

export type AppSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AppSettingsQuery = {
  __typename?: "Query";
  appSettings: {
    __typename?: "AppSettings";
    sideNavMinimized: boolean;
    plaidOpen: boolean;
  };
};

export type FolderConfigQueryVariables = Exact<{ [key: string]: never }>;

export type FolderConfigQuery = {
  __typename?: "Query";
  folderConfig: {
    __typename?: "FolderConfig";
    sortBy: SortBy;
    sortOrder: SortOrder;
    displayType: FolderDisplayType;
  };
};

export type LocationConfigQueryVariables = Exact<{ [key: string]: never }>;

export type LocationConfigQuery = {
  __typename?: "Query";
  locationConfig: {
    __typename?: "LocationConfig";
    sortBy: SortBy;
    sortOrder: SortOrder;
    activeTabIndex: number;
    activeCollapsible: Array<string>;
  };
};

export type AssetConfigQueryVariables = Exact<{ [key: string]: never }>;

export type AssetConfigQuery = {
  __typename?: "Query";
  assetConfig: {
    __typename?: "AssetConfig";
    sortBy: SortBy;
    sortOrder: SortOrder;
    activeTabIndex: number;
    displayType: AssetDisplayType;
    filter: Array<MaintenanceStatusType>;
    filterCategoryId: string;
    activeCollapsible: Array<string>;
  };
};

export type MaintenanceConfigQueryVariables = Exact<{ [key: string]: never }>;

export type MaintenanceConfigQuery = {
  __typename?: "Query";
  maintenanceConfig: {
    __typename?: "MaintenanceConfig";
    sortBy: SortBy;
    sortOrder: SortOrder;
    displayType: MaintenanceDisplayType;
    filterStatus: Array<MaintenanceFilterStatus>;
    filterCategoryId: string;
  };
};

export type AssetCategoryConfigQueryVariables = Exact<{ [key: string]: never }>;

export type AssetCategoryConfigQuery = {
  __typename?: "Query";
  assetCategoryConfig: {
    __typename?: "AssetCategoryConfig";
    sortBy: SortBy;
    sortOrder: SortOrder;
    displayType: AssetCategoryDisplayType;
    showHidden: boolean;
  };
};

export type AssetTypeConfigQueryVariables = Exact<{ [key: string]: never }>;

export type AssetTypeConfigQuery = {
  __typename?: "Query";
  assetTypeConfig: {
    __typename?: "AssetTypeConfig";
    sortBy: SortBy;
    sortOrder: SortOrder;
    displayType: AssetTypeDisplayType;
  };
};

export type FloorPlanConfigQueryVariables = Exact<{ [key: string]: never }>;

export type FloorPlanConfigQuery = {
  __typename?: "Query";
  floorPlanConfig: {
    __typename?: "FloorPlanConfig";
    sortBy: SortBy;
    sortOrder: SortOrder;
    displayType: FloorPlanDisplayType;
    filterCategoryId: string;
  };
};

export type DashboardConfigQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardConfigQuery = {
  __typename?: "Query";
  dashboardConfig: {
    __typename?: "DashboardConfig";
    filter: Array<MaintenanceStatusType>;
  };
};

export type AssetCategoriesQueryVariables = {
  __typename?: "Query";
  companyId?: string;
};
export type AssetCategoriesQuery = {
  __typename?: "Query";
  assetCategories: Array<AssetCategoryItem>;
};

export type AssetCategoriesWithRelationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AssetCategoriesWithRelationsQuery = {
  __typename?: "Query";
  assetCategories: Array<{
    __typename?: "AssetCategory";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor: string;
    iconType: IconTypes;
    default: boolean;
    companyId?: any;
    assetTypes?: Array<{
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      assetFields: Array<{
        __typename?: "AssetField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
      }>;
      assetPartFields: Array<{
        __typename?: "AssetPartField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
        showInPanel: boolean;
      }>;
      maintenanceTemplates: Array<{
        __typename?: "MaintenanceTemplate";
        id: any;
        createdAt: any;
        description?: string;
        name: string;
        intervalType: MaintenanceIntervalType;
        intervalValue: number;
        assetTypeId: any;
      }>;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    }>;
  }>;
};

export type CompanyQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    id: any;
    name: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    businessType?: string;
    website?: string;
    industry?: string;
    planStripeId?: string;
    planStripeCancelAt?: any;
    planStripeStatus?: string;
    planStripeTrialEnd?: any;
    planStripeEndedAt?: any;
    planStripeCurrentPeriodEnd?: any;
    defaultFolderId?: any;
    uploadsFolderId?: any;
    locationCount: number;
    canAddLocation: CompanyPermissionType;
    assetCount: number;
    companyUserCount: number;
    fileSizeTotal: number;
    createdAt: any;
    config: any;
    planId?: string;
    isMainCompany: boolean;
    mainCompanyId?: string;
    hiddenCategoryIds: Array<string>;
    hiddenTypeIds: Array<string>;
    companyCompanyUsers: Array<{
      __typename?: "CompanyCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
  };
};

export type FloorPlanPathFragmentFragment = {
  __typename?: "FloorPlan";
  id: any;
  name: string;
  path: string;
};

export type FloorPlanSignedUrlQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FloorPlanSignedUrlQuery = {
  __typename?: "Query";
  floorPlan: {
    __typename?: "FloorPlan";
    id: any;
    name: string;
    signedUrl: string;
  };
};

export type FolderQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FolderQuery = {
  __typename?: "Query";
  folder: {
    __typename?: "Folder";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    parentId?: any;
    parent?: {
      __typename?: "Folder";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      parentId?: any;
    };
    files: Array<{
      __typename?: "File";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      folderId: any;
    }>;
  };
};

export type FoldersQueryVariables = Exact<{ [key: string]: never }>;

export type FoldersQuery = {
  __typename?: "Query";
  folders: Array<{
    __typename?: "Folder";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    parentId?: any;
    parent?: {
      __typename?: "Folder";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      parentId?: any;
    };
    files: Array<{
      __typename?: "File";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      folderId: any;
    }>;
  }>;
};

export type UserAcceptInviteMutationVariables = Exact<{
  data: UserAcceptInviteInput;
}>;

export type UserAcceptInviteMutation = {
  __typename?: "Mutation";
  userAcceptInvite: { __typename?: "UserJwtTokenType"; jwtToken: string };
};

export type AssetCategoryDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AssetCategoryDeleteMutation = {
  __typename?: "Mutation";
  assetCategoryDelete: boolean;
};

export type CompanyUpdateHiddenCategoryIdsMutationVariables = Exact<{
  hiddenCategoryIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  hiddenTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CompanyUpdateHiddenCategoryIdsMutation = {
  __typename?: "Mutation";
  companyUpdateHiddenCategoryIds: {
    __typename?: "Company";
    id: any;
    name: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    businessType?: string;
    website?: string;
    industry?: string;
    planStripeId?: string;
    planStripeCancelAt?: any;
    planStripeStatus?: string;
    planStripeTrialEnd?: any;
    planStripeEndedAt?: any;
    planStripeCurrentPeriodEnd?: any;
    defaultFolderId?: any;
    uploadsFolderId?: any;
    locationCount: number;
    canAddLocation: CompanyPermissionType;
    assetCount: number;
    companyUserCount: number;
    fileSizeTotal: number;
    createdAt: any;
    config: any;
    planId?: string;
    hiddenCategoryIds: Array<string>;
    hiddenTypeIds: Array<string>;
  };
};

export type AssetTypeDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AssetTypeDeleteMutation = {
  __typename?: "Mutation";
  assetTypeDelete: boolean;
};

export type AssetTypeMoveMutationVariables = Exact<{
  assetCategoryId: Scalars["UUID"];
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type AssetTypeMoveMutation = {
  __typename?: "Mutation";
  assetTypeMove: boolean;
};

export type AssetTypeCreateMutationVariables = Exact<{
  data: AssetTypeInput;
}>;

export type AssetTypeCreateMutation = {
  __typename?: "Mutation";
  assetTypeCreate: {
    __typename?: "AssetType";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    default: boolean;
    specialAssetField?: SpecialAssetFieldTypes;
    assetCategoryId: any;
    companyId?: any;
    assetFields: Array<{
      __typename?: "AssetField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
    }>;
    assetPartFields: Array<{
      __typename?: "AssetPartField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
      showInPanel: boolean;
    }>;
    maintenanceTemplates: Array<{
      __typename?: "MaintenanceTemplate";
      id: any;
      createdAt: any;
      description?: string;
      name: string;
      intervalType: MaintenanceIntervalType;
      intervalValue: number;
      assetTypeId: any;
    }>;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  };
};

export type AssetTypeQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AssetTypeQuery = {
  __typename?: "Query";
  assetType: {
    __typename?: "AssetType";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    default: boolean;
    specialAssetField?: SpecialAssetFieldTypes;
    assetCategoryId: any;
    companyId?: any;
    assetFields: Array<{
      __typename?: "AssetField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
    }>;
    assetPartFields: Array<{
      __typename?: "AssetPartField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
      showInPanel: boolean;
    }>;
    maintenanceTemplates: Array<{
      __typename?: "MaintenanceTemplate";
      id: any;
      createdAt: any;
      description?: string;
      name: string;
      intervalType: MaintenanceIntervalType;
      intervalValue: number;
      assetTypeId: any;
    }>;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  };
};

export type AssetTypeUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: AssetTypeInput;
}>;

export type AssetTypeUpdateMutation = {
  __typename?: "Mutation";
  assetTypeUpdate: {
    __typename?: "AssetType";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    default: boolean;
    specialAssetField?: SpecialAssetFieldTypes;
    assetCategoryId: any;
    companyId?: any;
    assetFields: Array<{
      __typename?: "AssetField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
    }>;
    assetPartFields: Array<{
      __typename?: "AssetPartField";
      id: any;
      description?: string;
      createdAt: any;
      label: string;
      type: FieldType;
      selectOptions?: string;
      unit?: string;
      required: boolean;
      validationRegExp?: any;
      validationRegExpMessage?: string;
      order?: number;
      showInPanel: boolean;
    }>;
    maintenanceTemplates: Array<{
      __typename?: "MaintenanceTemplate";
      id: any;
      createdAt: any;
      description?: string;
      name: string;
      intervalType: MaintenanceIntervalType;
      intervalValue: number;
      assetTypeId: any;
    }>;
    assetCategory: {
      __typename?: "AssetCategory";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor: string;
      iconType: IconTypes;
      default: boolean;
      companyId?: any;
    };
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  };
};

export type MaintenanceTemplateCreateMutationVariables = Exact<{
  data: MaintenanceTemplateInput;
}>;

export type MaintenanceTemplateCreateMutation = {
  __typename?: "Mutation";
  maintenanceTemplateCreate: {
    __typename?: "MaintenanceTemplate";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    intervalType: MaintenanceIntervalType;
    intervalValue: number;
    assetTypeId: any;
  };
};

export type MaintenanceTemplateUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: MaintenanceTemplateInput;
}>;

export type MaintenanceTemplateUpdateMutation = {
  __typename?: "Mutation";
  maintenanceTemplateUpdate: {
    __typename?: "MaintenanceTemplate";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    intervalType: MaintenanceIntervalType;
    intervalValue: number;
    assetTypeId: any;
  };
};

export type MaintenanceTemplateDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type MaintenanceTemplateDeleteMutation = {
  __typename?: "Mutation";
  maintenanceTemplateDelete: boolean;
};

export type AssetCategoryCreateMutationVariables = Exact<{
  data: AssetCategoryInput;
}>;

export type AssetCategoryCreateMutation = {
  __typename?: "Mutation";
  assetCategoryCreate: {
    __typename?: "AssetCategory";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor: string;
    iconType: IconTypes;
    default: boolean;
    companyId?: any;
  };
};

export type AssetCategoryQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AssetCategoryQuery = {
  __typename?: "Query";
  assetCategory: {
    __typename?: "AssetCategory";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    iconName: string;
    iconColor: string;
    iconType: IconTypes;
    default: boolean;
    companyId?: any;
  };
};

export type AssetCategoryUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: AssetCategoryInput;
}>;

export type AssetCategoryUpdateMutation = {
  __typename?: "Mutation";
  assetCategoryUpdate: AssetCategoryItem;
};

export type AssetFileCreateMutationVariables = Exact<{
  data: AssetFileCreateInput;
}>;

export type AssetFileCreateMutation = {
  __typename?: "Mutation";
  assetFileCreate: {
    __typename?: "AssetFile";
    id: any;
    name: string;
    description?: string;
    path: string;
    type: AssetFileType;
    fileSize: number;
    order?: number;
    createdAt: any;
    assetId: any;
  };
};

export type AssetFileDeleteMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type AssetFileDeleteMutation = {
  __typename?: "Mutation";
  assetFileDelete: boolean;
};

export type AssetFileUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: AssetFileUpdateInput;
}>;

export type AssetFileUpdateMutation = {
  __typename?: "Mutation";
  assetFileUpdate: {
    __typename?: "AssetFile";
    id: any;
    name: string;
    description?: string;
    path: string;
    type: AssetFileType;
    fileSize: number;
    order?: number;
    createdAt: any;
    assetId: any;
  };
};

export type AssetCreateMutationVariables = Exact<{
  data: Array<AssetCreateInput> | AssetCreateInput;
}>;

export type AssetCreateMutation = {
  __typename?: "Mutation";
  assetCreate: Array<{
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      assetFields: Array<{
        __typename?: "AssetField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
      }>;
      assetPartFields: Array<{
        __typename?: "AssetPartField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
        showInPanel: boolean;
      }>;
      maintenanceTemplates: Array<{
        __typename?: "MaintenanceTemplate";
        id: any;
        createdAt: any;
        description?: string;
        name: string;
        intervalType: MaintenanceIntervalType;
        intervalValue: number;
        assetTypeId: any;
      }>;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
    assetParts: Array<{
      __typename?: "AssetPart";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      assetId: any;
      assetPartFieldValues: Array<{
        __typename?: "AssetPartFieldValue";
        id: any;
        createdAt: any;
        value?: string;
        assetPartFieldId: any;
      }>;
    }>;
    assetFieldValues: Array<{
      __typename?: "AssetFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetFieldId: any;
    }>;
    assetFiles: Array<{
      __typename?: "AssetFile";
      id: any;
      name: string;
      description?: string;
      path: string;
      type: AssetFileType;
      fileSize: number;
      order?: number;
      createdAt: any;
      assetId: any;
    }>;
    assetCompanyUsers: Array<{
      __typename?: "AssetCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      createdAt: any;
      mapPosition?: any;
      mapAffectedArea?: any;
      floorPlan: {
        __typename?: "FloorPlan";
        id: any;
        name: string;
        description?: string;
        path: string;
        createdAt: any;
        fileSize: number;
        type?: string;
        createdBy?: string;
        createdDate?: string;
        locationId: any;
      };
    }>;
    assetAffectedAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      affectedAsset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
    assetAffectedByAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
  }>;
};

export type AssetDeleteMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type AssetDeleteMutation = {
  __typename?: "Mutation";
  assetDelete: boolean;
};

export type AssetUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: AssetUpdateInput;
}>;

export type AssetUpdateMutation = {
  __typename?: "Mutation";
  assetUpdate: {
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      assetFields: Array<{
        __typename?: "AssetField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
      }>;
      assetPartFields: Array<{
        __typename?: "AssetPartField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
        showInPanel: boolean;
      }>;
      maintenanceTemplates: Array<{
        __typename?: "MaintenanceTemplate";
        id: any;
        createdAt: any;
        description?: string;
        name: string;
        intervalType: MaintenanceIntervalType;
        intervalValue: number;
        assetTypeId: any;
      }>;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
    assetParts: Array<{
      __typename?: "AssetPart";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      assetId: any;
      assetPartFieldValues: Array<{
        __typename?: "AssetPartFieldValue";
        id: any;
        createdAt: any;
        value?: string;
        assetPartFieldId: any;
      }>;
    }>;
    assetFieldValues: Array<{
      __typename?: "AssetFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetFieldId: any;
    }>;
    assetFiles: Array<{
      __typename?: "AssetFile";
      id: any;
      name: string;
      description?: string;
      path: string;
      type: AssetFileType;
      fileSize: number;
      order?: number;
      createdAt: any;
      assetId: any;
    }>;
    assetCompanyUsers: Array<{
      __typename?: "AssetCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      createdAt: any;
      mapPosition?: any;
      mapAffectedArea?: any;
      floorPlan: {
        __typename?: "FloorPlan";
        id: any;
        name: string;
        description?: string;
        path: string;
        createdAt: any;
        fileSize: number;
        type?: string;
        createdBy?: string;
        createdDate?: string;
        locationId: any;
      };
    }>;
    assetAffectedAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      affectedAsset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
    assetAffectedByAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
  };
};

export type AssetsQueryVariables = Exact<{ [key: string]: never }>;

export type AssetsQuery = {
  __typename?: "Query";
  assets: Array<{
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      assetFields: Array<{
        __typename?: "AssetField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
      }>;
      assetPartFields: Array<{
        __typename?: "AssetPartField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
        showInPanel: boolean;
      }>;
      maintenanceTemplates: Array<{
        __typename?: "MaintenanceTemplate";
        id: any;
        createdAt: any;
        description?: string;
        name: string;
        intervalType: MaintenanceIntervalType;
        intervalValue: number;
        assetTypeId: any;
      }>;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
    assetParts: Array<{
      __typename?: "AssetPart";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      assetId: any;
      assetPartFieldValues: Array<{
        __typename?: "AssetPartFieldValue";
        id: any;
        createdAt: any;
        value?: string;
        assetPartFieldId: any;
      }>;
    }>;
    assetFieldValues: Array<{
      __typename?: "AssetFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetFieldId: any;
    }>;
    assetFiles: Array<{
      __typename?: "AssetFile";
      id: any;
      name: string;
      description?: string;
      path: string;
      type: AssetFileType;
      fileSize: number;
      order?: number;
      createdAt: any;
      assetId: any;
    }>;
    assetCompanyUsers: Array<{
      __typename?: "AssetCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      createdAt: any;
      mapPosition?: any;
      mapAffectedArea?: any;
      floorPlan: {
        __typename?: "FloorPlan";
        id: any;
        name: string;
        description?: string;
        path: string;
        createdAt: any;
        fileSize: number;
        type?: string;
        createdBy?: string;
        createdDate?: string;
        locationId: any;
      };
    }>;
    assetAffectedAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      affectedAsset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
    assetAffectedByAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
  }>;
};

export type AssetIndexFragmentFragment = {
  __typename?: "Asset";
  id: any;
  name: string;
  maintenanceStatus: MaintenanceStatusType;
  createdAt: any;
  floorPlanAssets: Array<{
    __typename?: "FloorPlanAsset";
    id: any;
    floorPlan: { __typename?: "FloorPlan"; id: any; locationId: any };
  }>;
  assetType: {
    __typename?: "AssetType";
    id: any;
    name: string;
    iconName: string;
    iconColor?: string;
    iconType: IconTypes;
    assetCategoryId: any;
    misc: {
      __typename?: "AssetTypeMiscData";
      resolvedName: string;
      resolvedIconColor: string;
    };
  };
};

export type AssetIndexQueryVariables = Exact<{ [key: string]: never }>;

export type AssetIndexQuery = {
  __typename?: "Query";
  assets: Array<{
    __typename?: "Asset";
    id: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    createdAt: any;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      floorPlan: { __typename?: "FloorPlan"; id: any; locationId: any };
    }>;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      assetCategoryId: any;
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
  }>;
};

export type AssetAffectedAssetDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AssetAffectedAssetDeleteMutation = {
  __typename?: "Mutation";
  assetAffectedAssetDelete: boolean;
};

export type AssetAffectedAssetCreateMutationVariables = Exact<{
  data: Array<AssetAffectedAssetCreateInput> | AssetAffectedAssetCreateInput;
}>;

export type AssetAffectedAssetCreateMutation = {
  __typename?: "Mutation";
  assetAffectedAssetCreate: boolean;
};

export type AssetPartCreateMutationVariables = Exact<{
  data: Array<AssetPartCreateInput> | AssetPartCreateInput;
}>;

export type AssetPartCreateMutation = {
  __typename?: "Mutation";
  assetPartCreate: Array<{
    __typename?: "AssetPart";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    assetId: any;
    assetPartFieldValues: Array<{
      __typename?: "AssetPartFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetPartFieldId: any;
    }>;
  }>;
};

export type AssetPartUpdateMutationVariables = Exact<{
  data: Array<AssetPartUpdateInput> | AssetPartUpdateInput;
}>;

export type AssetPartUpdateMutation = {
  __typename?: "Mutation";
  assetPartUpdate: Array<{
    __typename?: "AssetPart";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    assetId: any;
    assetPartFieldValues: Array<{
      __typename?: "AssetPartFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetPartFieldId: any;
    }>;
  }>;
};

export type AssetPartDeleteMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type AssetPartDeleteMutation = {
  __typename?: "Mutation";
  assetPartDelete: boolean;
};

export type AssetFileChangeOrderMutationVariables = Exact<{
  assetFileIds: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type AssetFileChangeOrderMutation = {
  __typename?: "Mutation";
  assetFileChangeOrder: Array<{
    __typename?: "AssetFile";
    id: any;
    name: string;
    description?: string;
    path: string;
    type: AssetFileType;
    fileSize: number;
    order?: number;
    createdAt: any;
    assetId: any;
  }>;
};

export type AssetUpdateConfigMutationVariables = Exact<{
  id: Scalars["UUID"];
  config: Scalars["JSONObject"];
}>;

export type AssetUpdateConfigMutation = {
  __typename?: "Mutation";
  assetUpdateConfig: {
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
  };
};

export type FloorPlanAssetDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FloorPlanAssetDeleteMutation = {
  __typename?: "Mutation";
  floorPlanAssetDelete: boolean;
};

export type FloorPlanAssetCreateMutationVariables = Exact<{
  data: Array<FloorPlanAssetCreateInput> | FloorPlanAssetCreateInput;
}>;

export type FloorPlanAssetCreateMutation = {
  __typename?: "Mutation";
  floorPlanAssetCreate: boolean;
};

export type AssetQueryVariables = Exact<{
  id: Scalars["UUID"];
  companyId?: Scalars["UUID"];
}>;

export type AssetQuery = {
  __typename?: "Query";
  asset: {
    __typename?: "Asset";
    id: any;
    description?: string;
    createdAt: any;
    config: any;
    name: string;
    maintenanceStatus: MaintenanceStatusType;
    assetType: {
      __typename?: "AssetType";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      iconName: string;
      iconColor?: string;
      iconType: IconTypes;
      default: boolean;
      specialAssetField?: SpecialAssetFieldTypes;
      assetCategoryId: any;
      companyId?: any;
      assetFields: Array<{
        __typename?: "AssetField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
      }>;
      assetPartFields: Array<{
        __typename?: "AssetPartField";
        id: any;
        description?: string;
        createdAt: any;
        label: string;
        type: FieldType;
        selectOptions?: string;
        unit?: string;
        required: boolean;
        validationRegExp?: any;
        validationRegExpMessage?: string;
        order?: number;
        showInPanel: boolean;
      }>;
      maintenanceTemplates: Array<{
        __typename?: "MaintenanceTemplate";
        id: any;
        createdAt: any;
        description?: string;
        name: string;
        intervalType: MaintenanceIntervalType;
        intervalValue: number;
        assetTypeId: any;
      }>;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
      misc: {
        __typename?: "AssetTypeMiscData";
        resolvedName: string;
        resolvedIconColor: string;
      };
    };
    assetParts: Array<{
      __typename?: "AssetPart";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      assetId: any;
      assetPartFieldValues: Array<{
        __typename?: "AssetPartFieldValue";
        id: any;
        createdAt: any;
        value?: string;
        assetPartFieldId: any;
      }>;
    }>;
    assetFieldValues: Array<{
      __typename?: "AssetFieldValue";
      id: any;
      createdAt: any;
      value?: string;
      assetFieldId: any;
    }>;
    assetFiles: Array<{
      __typename?: "AssetFile";
      id: any;
      name: string;
      description?: string;
      path: string;
      type: AssetFileType;
      fileSize: number;
      order?: number;
      createdAt: any;
      assetId: any;
    }>;
    assetCompanyUsers: Array<{
      __typename?: "AssetCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      createdAt: any;
      mapPosition?: any;
      mapAffectedArea?: any;
      floorPlan: {
        __typename?: "FloorPlan";
        id: any;
        name: string;
        description?: string;
        path: string;
        createdAt: any;
        fileSize: number;
        type?: string;
        createdBy?: string;
        createdDate?: string;
        locationId: any;
      };
    }>;
    assetAffectedAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      affectedAsset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
    assetAffectedByAssets: Array<{
      __typename?: "AssetAffectedAsset";
      id: any;
      assetPartId?: any;
      pathPoints?: any;
      createdAt: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
  };
};

export type AssetMaintenancesQueryVariables = Exact<{
  id: Scalars["UUID"];
  startDate?: InputMaybe<Scalars["Timestamp"]>;
  endDate?: InputMaybe<Scalars["Timestamp"]>;
}>;

export type AssetMaintenancesQuery = {
  __typename?: "Query";
  asset: {
    __typename?: "Asset";
    id: any;
    name: string;
    maintenances: Array<{
      __typename?: "Maintenance";
      id: any;
      name: string;
      intervalType?: MaintenanceIntervalType;
      intervalValue?: number;
      startDateTime: any;
      endDateTime?: any;
      endAfter?: number;
      durationType: MaintenanceDurationType;
      durationValue: number;
      assetId?: any;
      createdAt: any;
      deletedScheduleTimestamps: Array<string>;
      misc: {
        __typename?: "MaintenanceMiscData";
        resolvedName: string;
        assetCategoryId?: string;
      };
      maintenanceSchedulesForDateRange: Array<{
        __typename?: "MaintenanceSchedule";
        id: any;
        completedAt?: any;
        scheduledAt: any;
      }>;
    }>;
  };
};

export type StripeInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type StripeInvoicesQuery = {
  __typename?: "Query";
  stripeInvoices: Array<{
    __typename?: "StripeInvoice";
    id?: string;
    amount_due?: number;
    amount_paid?: number;
    amount_remaining?: number;
    attempted: boolean;
    auto_advance?: boolean;
    billing_reason?: string;
    collection_method?: string;
    currency?: string;
    hosted_invoice_url?: string;
    invoice_pdf?: string;
    status: string;
    paid: boolean;
    created?: number;
    due_date?: number;
    next_payment_attempt?: number;
    receipt_number?: string;
    discount?: {
      __typename?: "StripeDiscount";
      coupon: {
        __typename?: "StripeCoupon";
        id: string;
        valid: boolean;
        currency?: string;
        duration?: string;
        duration_in_months?: number;
        name?: string;
        percent_off?: number;
        amount_off?: number;
      };
    };
    charge?: {
      __typename?: "StripeCharge";
      receipt_url?: string;
      amount_refunded?: number;
      payment_method_details?: {
        __typename?: "StripeChargePaymentMethodDetails";
        type: string;
        ach_debit?: {
          __typename?: "StripeBankAccount";
          id?: string;
          account_holder_name?: string;
          account_holder_type?: string;
          bank_name?: string;
          country?: string;
          currency?: string;
          last4?: string;
        };
        card?: {
          __typename?: "StripePaymentMethodTypeCard";
          brand?: string;
          country?: string;
          last4?: string;
          exp_month?: number;
          exp_year?: number;
        };
      };
    };
    payment_intent?: {
      __typename?: "StripePaymentIntent";
      status: string;
      client_secret: string;
    };
  }>;
};

export type StripeCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type StripeCustomerQuery = {
  __typename?: "Query";
  stripeCustomer: {
    __typename?: "StripeCustomer";
    id: string;
    email?: string;
    name?: string;
    phone?: string;
    created?: number;
    default_source?: string;
    invoice_settings?: {
      __typename?: "StripeInvoiceSettings";
      default_payment_method?: string;
    };
  };
};

export type StripeInvoiceUpcomingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type StripeInvoiceUpcomingQuery = {
  __typename?: "Query";
  stripeInvoiceUpcoming: {
    __typename?: "StripeInvoice";
    id?: string;
    amount_due?: number;
    amount_paid?: number;
    amount_remaining?: number;
    attempted: boolean;
    auto_advance?: boolean;
    billing_reason?: string;
    collection_method?: string;
    currency?: string;
    hosted_invoice_url?: string;
    invoice_pdf?: string;
    status: string;
    paid: boolean;
    created?: number;
    due_date?: number;
    next_payment_attempt?: number;
    receipt_number?: string;
    discount?: {
      __typename?: "StripeDiscount";
      coupon: {
        __typename?: "StripeCoupon";
        id: string;
        valid: boolean;
        currency?: string;
        duration?: string;
        duration_in_months?: number;
        name?: string;
        percent_off?: number;
        amount_off?: number;
      };
    };
    charge?: {
      __typename?: "StripeCharge";
      receipt_url?: string;
      amount_refunded?: number;
      payment_method_details?: {
        __typename?: "StripeChargePaymentMethodDetails";
        type: string;
        ach_debit?: {
          __typename?: "StripeBankAccount";
          id?: string;
          account_holder_name?: string;
          account_holder_type?: string;
          bank_name?: string;
          country?: string;
          currency?: string;
          last4?: string;
        };
        card?: {
          __typename?: "StripePaymentMethodTypeCard";
          brand?: string;
          country?: string;
          last4?: string;
          exp_month?: number;
          exp_year?: number;
        };
      };
    };
    payment_intent?: {
      __typename?: "StripePaymentIntent";
      status: string;
      client_secret: string;
    };
  };
};

export type StripeSubscriptionDeleteMutationVariables = Exact<{
  passwordRaw: Scalars["String"];
}>;

export type StripeSubscriptionDeleteMutation = {
  __typename?: "Mutation";
  stripeSubscriptionDelete: {
    __typename?: "StripeSubscription";
    id: string;
    cancel_at_period_end: boolean;
    status: string;
    cancel_at?: number;
    canceled_at?: number;
    created: number;
    ended_at?: number;
    start_date: number;
    trial_end?: number;
    trial_start?: number;
    days_until_due?: number;
  };
};

export type CompanyCreateMutationVariables = Exact<{
  data: CompanyInput;
}>;

export type CompanyCreateMutation = {
  __typename?: "Mutation";
  companyCreate: {
    __typename?: "Company";
    id: any;
    name: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    businessType?: string;
    website?: string;
    industry?: string;
    planStripeId?: string;
    planStripeCancelAt?: any;
    planStripeStatus?: string;
    planStripeTrialEnd?: any;
    planStripeEndedAt?: any;
    planStripeCurrentPeriodEnd?: any;
    defaultFolderId?: any;
    uploadsFolderId?: any;
    locationCount: number;
    canAddLocation: CompanyPermissionType;
    assetCount: number;
    companyUserCount: number;
    fileSizeTotal: number;
    createdAt: any;
    config: any;
    planId?: string;
    isMainCompany: boolean;
    mainCompanyId?: string;
    hiddenCategoryIds: Array<string>;
    hiddenTypeIds: Array<string>;
    companyCompanyUsers: Array<{
      __typename?: "CompanyCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
  };
};

export type StripeProrationAmountQueryVariables = Exact<{
  newPlan?: InputMaybe<Scalars["String"]>;
  newQuantity?: InputMaybe<Scalars["Float"]>;
}>;

export type StripeProrationAmountQuery = {
  __typename?: "Query";
  stripeProrationAmount: {
    __typename?: "StripeProration";
    amount: number;
    dueDate?: number;
  };
};

export type StripeNewPlanAmountQueryVariables = Exact<{
  newPlan: Scalars["String"];
}>;

export type StripeNewPlanAmountQuery = {
  __typename?: "Query";
  stripeNewPlanAmount: number;
};

export type StripeCouponMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type StripeCouponMutation = {
  __typename?: "Mutation";
  stripeCoupon: {
    __typename?: "StripeCoupon";
    id: string;
    valid: boolean;
    currency?: string;
    percent_off?: number;
    amount_off?: number;
    duration?: string;
    duration_in_months?: number;
  };
};

export type PlansQueryVariables = Exact<{ [key: string]: never }>;

export type PlansQuery = {
  __typename?: "Query";
  plans: Array<{
    __typename?: "Plan";
    id: any;
    productName: string;
    stripeId: string;
    amount: number;
    currency: string;
    interval: string;
    interval_count: number;
    active: boolean;
    default: boolean;
    proRateAmount?: number;
    createdAt: any;
  }>;
};

export type StripeSubscriptionCreateOrUpdateMutationVariables = Exact<{
  planId: Scalars["String"];
  couponCode?: InputMaybe<Scalars["String"]>;
}>;

export type StripeSubscriptionCreateOrUpdateMutation = {
  __typename?: "Mutation";
  stripeSubscriptionCreateOrUpdate: {
    __typename?: "StripeSubscription";
    id: string;
    cancel_at_period_end: boolean;
    status: string;
    cancel_at?: number;
    canceled_at?: number;
    created: number;
    ended_at?: number;
    start_date: number;
    trial_end?: number;
    trial_start?: number;
    days_until_due?: number;
  };
};

export type CompanyDeleteMutationVariables = Exact<{ [key: string]: never }>;

export type CompanyDeleteMutation = {
  __typename?: "Mutation";
  companyDelete: boolean;
};

export type CompanyUpdateMutationVariables = Exact<{
  data: CompanyInput;
}>;

export type CompanyUpdateMutation = {
  __typename?: "Mutation";
  companyUpdate: {
    __typename?: "Company";
    id: any;
    name: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    businessType?: string;
    website?: string;
    industry?: string;
    planStripeId?: string;
    planStripeCancelAt?: any;
    planStripeStatus?: string;
    planStripeTrialEnd?: any;
    planStripeEndedAt?: any;
    planStripeCurrentPeriodEnd?: any;
    defaultFolderId?: any;
    uploadsFolderId?: any;
    locationCount: number;
    canAddLocation: CompanyPermissionType;
    assetCount: number;
    companyUserCount: number;
    fileSizeTotal: number;
    createdAt: any;
    config: any;
    planId?: string;
    hiddenCategoryIds: Array<string>;
    hiddenTypeIds: Array<string>;
    companyCompanyUsers: Array<{
      __typename?: "CompanyCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
  };
};

export type CurrentMaintenanceReportByCategoryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentMaintenanceReportByCategoryQuery = {
  __typename?: "Query";
  currentMaintenanceReportByCategory: Array<{
    __typename?: "CategoriesSummary";
    categoryId?: string;
    categoryName: string;
    currentAssetIds?: Array<string>;
    currentCount: number;
    pastDueAssetIds?: Array<string>;
    pastDueCount: number;
    dueIn30AssetIds?: Array<string>;
    dueIn30Count: number;
  }>;
};

export type MaintenanceReportQueryVariables = Exact<{
  startDate: Scalars["Date"];
  numberOfDays?: InputMaybe<Scalars["Float"]>;
}>;

export type MaintenanceReportQuery = {
  __typename?: "Query";
  maintenanceReport: Array<{
    __typename?: "MaintenanceReport";
    date: any;
    currentCount: number;
    pastDueCount: number;
    dueIn30Count: number;
  }>;
};

export type UserEmailVerifyMutationVariables = Exact<{
  data: UserEmailVerifyInput;
}>;

export type UserEmailVerifyMutation = {
  __typename?: "Mutation";
  userEmailVerify: boolean;
};

export type FileQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FileQuery = {
  __typename?: "Query";
  file: {
    __typename?: "File";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    folderId: any;
  };
};

export type FileDeleteMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type FileDeleteMutation = {
  __typename?: "Mutation";
  fileDelete: boolean;
};

export type FileUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: FileUpdateInput;
}>;

export type FileUpdateMutation = {
  __typename?: "Mutation";
  fileUpdate: {
    __typename?: "File";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    folderId: any;
  };
};

export type FileMoveMutationVariables = Exact<{
  folderId: Scalars["UUID"];
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type FileMoveMutation = { __typename?: "Mutation"; fileMove: boolean };

export type FolderMoveMutationVariables = Exact<{
  parentFolderId: Scalars["UUID"];
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type FolderMoveMutation = {
  __typename?: "Mutation";
  folderMove: boolean;
};

export type FolderCreateMutationVariables = Exact<{
  data: FolderCreateInput;
}>;

export type FolderCreateMutation = {
  __typename?: "Mutation";
  folderCreate: {
    __typename?: "Folder";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    parentId?: any;
    parent?: {
      __typename?: "Folder";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      parentId?: any;
    };
    files: Array<{
      __typename?: "File";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      folderId: any;
    }>;
  };
};

export type FolderDeleteMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type FolderDeleteMutation = {
  __typename?: "Mutation";
  folderDelete: boolean;
};

export type FolderUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: FolderUpdateInput;
}>;

export type FolderUpdateMutation = {
  __typename?: "Mutation";
  folderUpdate: {
    __typename?: "Folder";
    id: any;
    name: string;
    description?: string;
    createdAt: any;
    parentId?: any;
    parent?: {
      __typename?: "Folder";
      id: any;
      name: string;
      description?: string;
      createdAt: any;
      parentId?: any;
    };
    files: Array<{
      __typename?: "File";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      folderId: any;
    }>;
  };
};

export type FloorPlanCreateMutationVariables = Exact<{
  data: FloorPlanInput;
}>;

export type FloorPlanCreateMutation = {
  __typename?: "Mutation";
  floorPlanCreate: boolean;
};

export type FloorPlanUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: FloorPlanInput;
  updateAssetLocationsAndAffectedAreas?: InputMaybe<Scalars["Boolean"]>;
}>;

export type FloorPlanUpdateMutation = {
  __typename?: "Mutation";
  floorPlanUpdate: {
    __typename?: "FloorPlan";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    type?: string;
    createdBy?: string;
    createdDate?: string;
    locationId: any;
    location: {
      __typename?: "Location";
      id: any;
      name: string;
      description?: string;
      type?: string;
      createdAt: any;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      countryAlpha2?: string;
      coordinates?: Array<number>;
      parentId?: any;
    };
    floorPlanAssetCategories: Array<{
      __typename?: "FloorPlanAssetCategory";
      id: any;
      createdAt: any;
      assetCategoryId: any;
      assetCategory: {
        __typename?: "AssetCategory";
        id: any;
        name: string;
        description?: string;
        createdAt: any;
        iconName: string;
        iconColor: string;
        iconType: IconTypes;
        default: boolean;
        companyId?: any;
      };
    }>;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      createdAt: any;
      mapPosition?: any;
      mapAffectedArea?: any;
      asset: {
        __typename?: "Asset";
        id: any;
        description?: string;
        createdAt: any;
        config: any;
        name: string;
        maintenanceStatus: MaintenanceStatusType;
        assetType: {
          __typename?: "AssetType";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor?: string;
          iconType: IconTypes;
          default: boolean;
          specialAssetField?: SpecialAssetFieldTypes;
          assetCategoryId: any;
          companyId?: any;
          misc: {
            __typename?: "AssetTypeMiscData";
            resolvedName: string;
            resolvedIconColor: string;
          };
        };
      };
    }>;
  };
};

export type FloorPlanTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FloorPlanTypesQuery = {
  __typename?: "Query";
  floorPlanTypes?: Array<string>;
};

export type FloorPlanIndexFragmentFragment = {
  __typename?: "FloorPlan";
  id: any;
  name: string;
  description?: string;
  path: string;
  createdAt: any;
  fileSize: number;
  type?: string;
  createdBy?: string;
  createdDate?: string;
  locationId: any;
  floorPlanAssetCategories: Array<{
    __typename?: "FloorPlanAssetCategory";
    id: any;
    assetCategoryId: any;
  }>;
  location: { __typename?: "Location"; id: any; name: string };
  floorPlanAssets: Array<{
    __typename?: "FloorPlanAsset";
    id: any;
    asset: {
      __typename?: "Asset";
      id: any;
      name?: string;
      assetTypeId?: string;
      maintenanceStatus: MaintenanceStatusType;
    };
  }>;
};

export type FloorPlanIndexQueryVariables = Exact<{ [key: string]: never }>;

export type FloorPlanIndexQuery = {
  __typename?: "Query";
  floorPlans: Array<{
    __typename?: "FloorPlan";
    id: any;
    name: string;
    description?: string;
    path: string;
    createdAt: any;
    fileSize: number;
    type?: string;
    createdBy?: string;
    createdDate?: string;
    locationId: any;
    floorPlanAssetCategories: Array<{
      __typename?: "FloorPlanAssetCategory";
      id: any;
      assetCategoryId: any;
    }>;
    location: { __typename?: "Location"; id: any; name: string };
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      asset: {
        __typename?: "Asset";
        id: any;
        maintenanceStatus: MaintenanceStatusType;
      };
    }>;
  }>;
};

export type FloorPlanMoveMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
  locationId: Scalars["UUID"];
}>;

export type FloorPlanMoveMutation = {
  __typename?: "Mutation";
  floorPlanMove: boolean;
};

export type FloorPlanDeleteMutationVariables = Exact<{
  ids: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type FloorPlanDeleteMutation = {
  __typename?: "Mutation";
  floorPlanDelete: boolean;
};

export type UserForgotPasswordMutationVariables = Exact<{
  data: UserForgotPasswordInput;
}>;

export type UserForgotPasswordMutation = {
  __typename?: "Mutation";
  userForgotPassword: boolean;
};

export type LocationCreateMutationVariables = Exact<{
  data: LocationInput;
}>;

export type LocationCreateMutation = {
  __typename?: "Mutation";
  locationCreate: {
    __typename?: "Location";
    id: any;
    name: string;
    description?: string;
    type?: string;
    createdAt: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
    parent?: {
      __typename?: "Location";
      id: any;
      name: string;
      description?: string;
      type?: string;
      createdAt: any;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      countryAlpha2?: string;
      coordinates?: Array<number>;
      parentId?: any;
    };
    floorPlans: Array<{
      __typename?: "FloorPlan";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      type?: string;
      createdBy?: string;
      createdDate?: string;
      locationId: any;
      location: {
        __typename?: "Location";
        id: any;
        name: string;
        description?: string;
        type?: string;
        createdAt: any;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zip?: string;
        countryAlpha2?: string;
        coordinates?: Array<number>;
        parentId?: any;
      };
      floorPlanAssetCategories: Array<{
        __typename?: "FloorPlanAssetCategory";
        id: any;
        createdAt: any;
        assetCategoryId: any;
        assetCategory: {
          __typename?: "AssetCategory";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor: string;
          iconType: IconTypes;
          default: boolean;
          companyId?: any;
        };
      }>;
      floorPlanAssets: Array<{
        __typename?: "FloorPlanAsset";
        id: any;
        createdAt: any;
        mapPosition?: any;
        mapAffectedArea?: any;
        asset: {
          __typename?: "Asset";
          id: any;
          description?: string;
          createdAt: any;
          config: any;
          name: string;
          maintenanceStatus: MaintenanceStatusType;
          assetType: {
            __typename?: "AssetType";
            id: any;
            name: string;
            description?: string;
            createdAt: any;
            iconName: string;
            iconColor?: string;
            iconType: IconTypes;
            default: boolean;
            specialAssetField?: SpecialAssetFieldTypes;
            assetCategoryId: any;
            companyId?: any;
            misc: {
              __typename?: "AssetTypeMiscData";
              resolvedName: string;
              resolvedIconColor: string;
            };
          };
        };
      }>;
    }>;
    locationCompanyUsers: Array<{
      __typename?: "LocationCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
  };
};

export type LocationUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: LocationInput;
}>;

export type LocationUpdateMutation = {
  __typename?: "Mutation";
  locationUpdate: {
    __typename?: "Location";
    id: any;
    name: string;
    description?: string;
    type?: string;
    createdAt: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
    parent?: {
      __typename?: "Location";
      id: any;
      name: string;
      description?: string;
      type?: string;
      createdAt: any;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      countryAlpha2?: string;
      coordinates?: Array<number>;
      parentId?: any;
    };
    floorPlans: Array<{
      __typename?: "FloorPlan";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      type?: string;
      createdBy?: string;
      createdDate?: string;
      locationId: any;
      location: {
        __typename?: "Location";
        id: any;
        name: string;
        description?: string;
        type?: string;
        createdAt: any;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zip?: string;
        countryAlpha2?: string;
        coordinates?: Array<number>;
        parentId?: any;
      };
      floorPlanAssetCategories: Array<{
        __typename?: "FloorPlanAssetCategory";
        id: any;
        createdAt: any;
        assetCategoryId: any;
        assetCategory: {
          __typename?: "AssetCategory";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor: string;
          iconType: IconTypes;
          default: boolean;
          companyId?: any;
        };
      }>;
      floorPlanAssets: Array<{
        __typename?: "FloorPlanAsset";
        id: any;
        createdAt: any;
        mapPosition?: any;
        mapAffectedArea?: any;
        asset: {
          __typename?: "Asset";
          id: any;
          description?: string;
          createdAt: any;
          config: any;
          name: string;
          maintenanceStatus: MaintenanceStatusType;
          assetType: {
            __typename?: "AssetType";
            id: any;
            name: string;
            description?: string;
            createdAt: any;
            iconName: string;
            iconColor?: string;
            iconType: IconTypes;
            default: boolean;
            specialAssetField?: SpecialAssetFieldTypes;
            assetCategoryId: any;
            companyId?: any;
            misc: {
              __typename?: "AssetTypeMiscData";
              resolvedName: string;
              resolvedIconColor: string;
            };
          };
        };
      }>;
    }>;
    locationCompanyUsers: Array<{
      __typename?: "LocationCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
  };
};

export type LocationsQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars["UUID"]>;
}>;

export type LocationsQuery = {
  __typename?: "Query";
  locations: Array<{
    __typename?: "Location";
    id: any;
    name: string;
    description?: string;
    type?: string;
    createdAt: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
    parent?: {
      __typename?: "Location";
      id: any;
      name: string;
      description?: string;
      type?: string;
      createdAt: any;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      countryAlpha2?: string;
      coordinates?: Array<number>;
      parentId?: any;
    };
    floorPlans: Array<{
      __typename?: "FloorPlan";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      type?: string;
      createdBy?: string;
      createdDate?: string;
      locationId: any;
      location: {
        __typename?: "Location";
        id: any;
        name: string;
        description?: string;
        type?: string;
        createdAt: any;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zip?: string;
        countryAlpha2?: string;
        coordinates?: Array<number>;
        parentId?: any;
      };
      floorPlanAssetCategories: Array<{
        __typename?: "FloorPlanAssetCategory";
        id: any;
        createdAt: any;
        assetCategoryId: any;
        assetCategory: {
          __typename?: "AssetCategory";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor: string;
          iconType: IconTypes;
          default: boolean;
          companyId?: any;
        };
      }>;
      floorPlanAssets: Array<{
        __typename?: "FloorPlanAsset";
        id: any;
        createdAt: any;
        mapPosition?: any;
        mapAffectedArea?: any;
        asset: {
          __typename?: "Asset";
          id: any;
          description?: string;
          createdAt: any;
          config: any;
          name: string;
          maintenanceStatus: MaintenanceStatusType;
          assetType: {
            __typename?: "AssetType";
            id: any;
            name: string;
            description?: string;
            createdAt: any;
            iconName: string;
            iconColor?: string;
            iconType: IconTypes;
            default: boolean;
            specialAssetField?: SpecialAssetFieldTypes;
            assetCategoryId: any;
            companyId?: any;
            misc: {
              __typename?: "AssetTypeMiscData";
              resolvedName: string;
              resolvedIconColor: string;
            };
          };
        };
      }>;
    }>;
    locationCompanyUsers: Array<{
      __typename?: "LocationCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
  }>;
};

export type LocationIndexFragmentFragment = {
  __typename?: "Location";
  id: any;
  name: string;
  type?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  countryAlpha2?: string;
  coordinates?: Array<number>;
  parentId?: any;
  createdAt: any;
  floorPlans: Array<{
    __typename?: "FloorPlan";
    id: any;
    name: string;
    floorPlanAssets: Array<{
      __typename?: "FloorPlanAsset";
      id: any;
      assetId: any;
      asset: {
        __typename?: "Asset";
        id: any;
        maintenanceStatus: MaintenanceStatusType;
      };
    }>;
  }>;
};

export type LocationIndexQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars["UUID"]>;
  companyId?: InputMaybe<Scalars["UUID"]>;
}>;

export interface FloorPlanAssetItem {
  __typename?: "FloorPlanAsset";
  id: any;
  assetId: any;
  asset: {
    __typename?: "Asset";
    id: any;
    name?: string;
    assetTypeId?: string;
    assetType?: {
      id: any;
      name?: string;
      assetCategoryId?: any;
      assetCategory: {
        id: any;
        name?: string;
      };
    };
    assetAffectedAssets?: {
      id: any;
      affectedAsset: SimpleEntity;
    }[];
    assetAffectedByAssets?: {
      id: any;
      asset: SimpleEntity;
    }[];
    maintenanceStatus: MaintenanceStatusType;
  };
}

export interface LocationFloorPlan {
  __typename?: "FloorPlan";
  id: any;
  name: string;
  floorPlanAssets: Array<FloorPlanAssetItem>;
}

export type LocationIndexQuery = {
  __typename?: "Query";
  locations: Array<{
    __typename?: "Location";
    id: any;
    name: string;
    type?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
    companyId?: any;
    createdAt: any;
    floorPlans: Array<LocationFloorPlan>;
  }>;
};

export type LocationTypesQueryVariables = Exact<{ [key: string]: never }>;

export type LocationTypesQuery = {
  __typename?: "Query";
  locationTypes?: Array<string>;
};

export type LocationQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LocationQuery = {
  __typename?: "Query";
  location: {
    __typename?: "Location";
    id: any;
    name: string;
    description?: string;
    type?: string;
    createdAt: any;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryAlpha2?: string;
    coordinates?: Array<number>;
    parentId?: any;
    parent?: {
      __typename?: "Location";
      id: any;
      name: string;
      description?: string;
      type?: string;
      createdAt: any;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      countryAlpha2?: string;
      coordinates?: Array<number>;
      parentId?: any;
    };
    floorPlans: Array<{
      __typename?: "FloorPlan";
      id: any;
      name: string;
      description?: string;
      path: string;
      createdAt: any;
      fileSize: number;
      type?: string;
      createdBy?: string;
      createdDate?: string;
      locationId: any;
      location: {
        __typename?: "Location";
        id: any;
        name: string;
        description?: string;
        type?: string;
        createdAt: any;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zip?: string;
        countryAlpha2?: string;
        coordinates?: Array<number>;
        parentId?: any;
      };
      floorPlanAssetCategories: Array<{
        __typename?: "FloorPlanAssetCategory";
        id: any;
        createdAt: any;
        assetCategoryId: any;
        assetCategory: {
          __typename?: "AssetCategory";
          id: any;
          name: string;
          description?: string;
          createdAt: any;
          iconName: string;
          iconColor: string;
          iconType: IconTypes;
          default: boolean;
          companyId?: any;
        };
      }>;
      floorPlanAssets: Array<{
        __typename?: "FloorPlanAsset";
        id: any;
        createdAt: any;
        mapPosition?: any;
        mapAffectedArea?: any;
        asset: {
          __typename?: "Asset";
          id: any;
          description?: string;
          createdAt: any;
          config: any;
          name: string;
          maintenanceStatus: MaintenanceStatusType;
          assetType: {
            __typename?: "AssetType";
            id: any;
            name: string;
            description?: string;
            createdAt: any;
            iconName: string;
            iconColor?: string;
            iconType: IconTypes;
            default: boolean;
            specialAssetField?: SpecialAssetFieldTypes;
            assetCategoryId: any;
            companyId?: any;
            misc: {
              __typename?: "AssetTypeMiscData";
              resolvedName: string;
              resolvedIconColor: string;
            };
          };
        };
      }>;
    }>;
    locationCompanyUsers: Array<{
      __typename?: "LocationCompanyUser";
      id: any;
      createdAt: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
  };
};

export type LocationDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LocationDeleteMutation = {
  __typename?: "Mutation";
  locationDelete: boolean;
};

export type MaintenanceCreateMutationVariables = Exact<{
  data: MaintenanceInput;
}>;

export type MaintenanceCreateMutation = {
  __typename?: "Mutation";
  maintenanceCreate: {
    __typename?: "Maintenance";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    startDateTime: any;
    timezone: string;
    intervalType?: MaintenanceIntervalType;
    intervalValue?: number;
    durationType: MaintenanceDurationType;
    durationValue: number;
    remindBeforeType?: MaintenanceRemindBeforeType;
    remindBeforeValue?: number;
    endAfter?: number;
    endDateTime?: any;
    updatedAt: any;
    assetId?: any;
    deletedScheduleTimestamps: Array<string>;
    maintenanceCompanyUsers: Array<{
      __typename?: "MaintenanceCompanyUser";
      id: any;
      companyUserId: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    misc: {
      __typename?: "MaintenanceMiscData";
      resolvedName: string;
      assetCategoryId?: string;
    };
  };
};

export type MaintenanceUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: MaintenanceInput;
}>;

export type MaintenanceUpdateMutation = {
  __typename?: "Mutation";
  maintenanceUpdate: {
    __typename?: "Maintenance";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    startDateTime: any;
    timezone: string;
    intervalType?: MaintenanceIntervalType;
    intervalValue?: number;
    durationType: MaintenanceDurationType;
    durationValue: number;
    remindBeforeType?: MaintenanceRemindBeforeType;
    remindBeforeValue?: number;
    endAfter?: number;
    endDateTime?: any;
    updatedAt: any;
    assetId?: any;
    deletedScheduleTimestamps: Array<string>;
    maintenanceCompanyUsers: Array<{
      __typename?: "MaintenanceCompanyUser";
      id: any;
      companyUserId: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    misc: {
      __typename?: "MaintenanceMiscData";
      resolvedName: string;
      assetCategoryId?: string;
    };
  };
};

export type MaintenanceNoteCreateMutationVariables = Exact<{
  data: MaintenanceNoteInput;
}>;

export type MaintenanceNoteCreateMutation = {
  __typename?: "Mutation";
  maintenanceNoteCreate: {
    __typename?: "MaintenanceNote";
    id: any;
    createdAt: any;
    description?: string;
    updatedAt: any;
    maintenanceId: any;
    addedBy: {
      __typename?: "AddedBy";
      userId: string;
      userName?: string;
      userEmail: string;
      userPhone?: string;
    };
  };
};

export type MaintenanceNoteUpdateMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: MaintenanceNoteInput;
}>;

export type MaintenanceNoteUpdateMutation = {
  __typename?: "Mutation";
  maintenanceNoteUpdate: {
    __typename?: "MaintenanceNote";
    id: any;
    createdAt: any;
    description?: string;
    updatedAt: any;
    maintenanceId: any;
    addedBy: {
      __typename?: "AddedBy";
      userId: string;
      userName?: string;
      userEmail: string;
      userPhone?: string;
    };
  };
};

export type MaintenanceNoteDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type MaintenanceNoteDeleteMutation = {
  __typename?: "Mutation";
  maintenanceNoteDelete: boolean;
};

export type MaintenanceToggleDeletedScheduleTimestampMutationVariables = Exact<{
  id: Scalars["UUID"];
  timestamp: Scalars["Timestamp"];
}>;

export type MaintenanceToggleDeletedScheduleTimestampMutation = {
  __typename?: "Mutation";
  maintenanceToggleDeletedScheduleTimestamp: {
    __typename?: "Maintenance";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    startDateTime: any;
    timezone: string;
    intervalType?: MaintenanceIntervalType;
    intervalValue?: number;
    durationType: MaintenanceDurationType;
    durationValue: number;
    remindBeforeType?: MaintenanceRemindBeforeType;
    remindBeforeValue?: number;
    endAfter?: number;
    endDateTime?: any;
    updatedAt: any;
    assetId?: any;
    deletedScheduleTimestamps: Array<string>;
    misc: {
      __typename?: "MaintenanceMiscData";
      resolvedName: string;
      assetCategoryId?: string;
    };
  };
};

export type MaintenanceDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type MaintenanceDeleteMutation = {
  __typename?: "Mutation";
  maintenanceDelete: boolean;
};

export type MaintenanceWithContactsQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type MaintenanceWithContactsQuery = {
  __typename?: "Query";
  maintenance: {
    __typename?: "Maintenance";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    startDateTime: any;
    timezone: string;
    intervalType?: MaintenanceIntervalType;
    intervalValue?: number;
    durationType: MaintenanceDurationType;
    durationValue: number;
    remindBeforeType?: MaintenanceRemindBeforeType;
    remindBeforeValue?: number;
    endAfter?: number;
    endDateTime?: any;
    updatedAt: any;
    assetId?: any;
    deletedScheduleTimestamps: Array<string>;
    maintenanceCompanyUsers: Array<{
      __typename?: "MaintenanceCompanyUser";
      id: any;
      companyUserId: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    misc: {
      __typename?: "MaintenanceMiscData";
      resolvedName: string;
      assetCategoryId?: string;
    };
  };
};

export type MaintenanceIndexFragmentFragment = {
  __typename?: "Maintenance";
  id: any;
  name: string;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  startDateTime: any;
  endDateTime?: any;
  endAfter?: number;
  durationType: MaintenanceDurationType;
  durationValue: number;
  assetId?: any;
  createdAt: any;
  deletedScheduleTimestamps: Array<string>;
  misc: {
    __typename?: "MaintenanceMiscData";
    resolvedName: string;
    assetCategoryId?: string;
  };
  maintenanceSchedulesForDateRange: Array<{
    __typename?: "MaintenanceSchedule";
    id: any;
    completedAt?: any;
    scheduledAt: any;
  }>;
};

export type MaintenanceIndexQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Timestamp"]>;
  endDate?: InputMaybe<Scalars["Timestamp"]>;
}>;

export type MaintenanceIndexQuery = {
  __typename?: "Query";
  maintenances: Array<{
    __typename?: "Maintenance";
    id: any;
    name: string;
    intervalType?: MaintenanceIntervalType;
    intervalValue?: number;
    startDateTime: any;
    endDateTime?: any;
    endAfter?: number;
    durationType: MaintenanceDurationType;
    durationValue: number;
    assetId?: any;
    createdAt: any;
    deletedScheduleTimestamps: Array<string>;
    misc: {
      __typename?: "MaintenanceMiscData";
      resolvedName: string;
      assetCategoryId?: string;
    };
    maintenanceSchedulesForDateRange: Array<{
      __typename?: "MaintenanceSchedule";
      id: any;
      completedAt?: any;
      scheduledAt: any;
    }>;
  }>;
};

export type MaintenanceScheduleSnoozeReminderMutationVariables = Exact<{
  id: Scalars["UUID"];
  data: MaintenanceScheduleSnoozeReminderInput;
}>;

export type MaintenanceScheduleSnoozeReminderMutation = {
  __typename?: "Mutation";
  maintenanceScheduleSnoozeReminder: {
    __typename?: "MaintenanceSchedule";
    id: any;
    createdAt: any;
    updatedAt: any;
    maintenanceId: any;
    completedAt?: any;
    scheduledAt: any;
    completedByLabel: string;
  };
};

export type MaintenanceScheduleCompleteMutationVariables = Exact<{
  id: Scalars["UUID"];
  userIds: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type MaintenanceScheduleCompleteMutation = {
  __typename?: "Mutation";
  maintenanceScheduleComplete: {
    __typename?: "MaintenanceSchedule";
    id: any;
    createdAt: any;
    updatedAt: any;
    maintenanceId: any;
    completedAt?: any;
    scheduledAt: any;
    completedByLabel: string;
  };
};

export type MaintenanceScheduleInCompleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type MaintenanceScheduleInCompleteMutation = {
  __typename?: "Mutation";
  maintenanceScheduleInComplete: {
    __typename?: "MaintenanceSchedule";
    id: any;
    createdAt: any;
    updatedAt: any;
    maintenanceId: any;
    completedAt?: any;
    scheduledAt: any;
    completedByLabel: string;
  };
};

export type MaintenanceQueryVariables = Exact<{
  id: Scalars["UUID"];
  startDate?: InputMaybe<Scalars["Timestamp"]>;
  endDate?: InputMaybe<Scalars["Timestamp"]>;
}>;

export type MaintenanceQuery = {
  __typename?: "Query";
  maintenance: {
    __typename?: "Maintenance";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    startDateTime: any;
    timezone: string;
    intervalType?: MaintenanceIntervalType;
    intervalValue?: number;
    durationType: MaintenanceDurationType;
    durationValue: number;
    remindBeforeType?: MaintenanceRemindBeforeType;
    remindBeforeValue?: number;
    endAfter?: number;
    endDateTime?: any;
    updatedAt: any;
    assetId?: any;
    deletedScheduleTimestamps: Array<string>;
    asset?: { __typename?: "Asset"; id: any; locationsLabel: string };
    maintenanceCompanyUsers: Array<{
      __typename?: "MaintenanceCompanyUser";
      id: any;
      companyUserId: any;
      companyUser: {
        __typename?: "CompanyUser";
        id: any;
        role: CompanyUserRole;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string;
          lastName?: string;
          fullName: string;
          jobTitle?: string;
          phone?: string;
          emailConfirmed?: boolean;
          phoneConfirmed?: boolean;
          twoFactorEnabled?: boolean;
          config: any;
          createdAt: any;
          registered: boolean;
          adminRole?: UserAdminRole;
        };
      };
    }>;
    maintenanceNotes: Array<{
      __typename?: "MaintenanceNote";
      id: any;
      createdAt: any;
      description?: string;
      updatedAt: any;
      maintenanceId: any;
      addedBy: {
        __typename?: "AddedBy";
        userId: string;
        userName?: string;
        userEmail: string;
        userPhone?: string;
      };
    }>;
    maintenanceSchedulesForDateRange: Array<{
      __typename?: "MaintenanceSchedule";
      id: any;
      createdAt: any;
      updatedAt: any;
      maintenanceId: any;
      completedAt?: any;
      scheduledAt: any;
      completedByLabel: string;
    }>;
    misc: {
      __typename?: "MaintenanceMiscData";
      resolvedName: string;
      assetCategoryId?: string;
    };
  };
};

export type MaintenanceWithSchedulesQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type MaintenanceWithSchedulesQuery = {
  __typename?: "Query";
  maintenance: {
    __typename?: "Maintenance";
    id: any;
    createdAt: any;
    description?: string;
    name: string;
    startDateTime: any;
    timezone: string;
    intervalType?: MaintenanceIntervalType;
    intervalValue?: number;
    durationType: MaintenanceDurationType;
    durationValue: number;
    remindBeforeType?: MaintenanceRemindBeforeType;
    remindBeforeValue?: number;
    endAfter?: number;
    endDateTime?: any;
    updatedAt: any;
    assetId?: any;
    deletedScheduleTimestamps: Array<string>;
    maintenanceSchedules: Array<{
      __typename?: "MaintenanceSchedule";
      id: any;
      createdAt: any;
      updatedAt: any;
      maintenanceId: any;
      completedAt?: any;
      scheduledAt: any;
      completedByLabel: string;
    }>;
  };
};

export type UserUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
}>;

export type UserUpdateMutation = {
  __typename?: "Mutation";
  userUpdate: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
  };
};

export type UserPhoneConfirmMutationVariables = Exact<{
  data?: InputMaybe<UserPhoneConfirmInput>;
}>;

export type UserPhoneConfirmMutation = {
  __typename?: "Mutation";
  userPhoneConfirm: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
  };
};

export type UserPhoneVerifyMutationVariables = Exact<{
  data: UserPhoneVerifyInput;
}>;

export type UserPhoneVerifyMutation = {
  __typename?: "Mutation";
  userPhoneVerify: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
  };
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
  };
};

export type ToggleTwoFactorMutationVariables = Exact<{
  twoFactorEnabled: Scalars["Boolean"];
}>;

export type ToggleTwoFactorMutation = {
  __typename?: "Mutation";
  toggleTwoFactor: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
  };
};

export type SendEmailMutationVariables = Exact<{
  message: Scalars["String"];
}>;

export type SendEmailMutation = { __typename?: "Mutation"; sendEmail: boolean };

export type ReportAssetQueryVariables = Exact<{
  filterBy: ReportAssetFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type ReportAssetQuery = { __typename?: "Query"; reportAsset: any };

export type CreateReportAssetMutationVariables = Exact<{
  fileType: ReportFileType;
  filterBy: ReportAssetFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type CreateReportAssetMutation = {
  __typename?: "Mutation";
  createReportAsset: boolean;
};

export type CompletedByUsersQueryVariables = Exact<{ [key: string]: never }>;

export type CompletedByUsersQuery = {
  __typename?: "Query";
  completedByUsers: Array<{
    __typename?: "User";
    id: any;
    fullName: string;
    email: string;
  }>;
};

export type ReportMaintenanceQueryVariables = Exact<{
  filterBy: ReportMaintenanceFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type ReportMaintenanceQuery = {
  __typename?: "Query";
  reportMaintenance: any;
};

export type CreateReportMaintenanceMutationVariables = Exact<{
  fileType: ReportFileType;
  filterBy: ReportMaintenanceFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type CreateReportMaintenanceMutation = {
  __typename?: "Mutation";
  createReportMaintenance: boolean;
};

export type ReportPlanQueryVariables = Exact<{
  filterBy: ReportPlanFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type ReportPlanQuery = { __typename?: "Query"; reportPlan: any };

export type CreateReportPlanMutationVariables = Exact<{
  fileType: ReportFileType;
  filterBy: ReportPlanFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type CreateReportPlanMutation = {
  __typename?: "Mutation";
  createReportPlan: boolean;
};

export type ReportUserActivityQueryVariables = Exact<{
  userId: Scalars["String"];
  filterBy: ReportUserFilterInput;
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type ReportUserActivityQuery = { __typename?: "Query"; reportUser: any };

export type CreateReportUserActivityMutationVariables = Exact<{
  fileType: ReportFileType;
  filterBy: ReportUserFilterInput;
  userId: Scalars["String"];
  timezone?: InputMaybe<Scalars["String"]>;
}>;

export type CreateReportUserActivityMutation = {
  __typename?: "Mutation";
  createReportUser: boolean;
};

export type UserResetPasswordMutationVariables = Exact<{
  data: UserResetPasswordInput;
}>;

export type UserResetPasswordMutation = {
  __typename?: "Mutation";
  userResetPassword: boolean;
};

export type UserSignInMutationVariables = Exact<{
  data: UserSignInInput;
}>;

export type UserSignInMutation = {
  __typename?: "Mutation";
  userSignIn: { __typename?: "UserJwtTokenType"; jwtToken: string };
};

export type UserCreateMutationVariables = Exact<{
  data: UserCreateInput;
}>;

export type UserCreateMutation = {
  __typename?: "Mutation";
  userCreate: { __typename?: "UserJwtTokenType"; jwtToken: string };
};

export type CompanyUserCreateMutationVariables = Exact<{
  email: Scalars["String"];
  role: CompanyUserRole;
}>;

export type CompanyUserCreateMutation = {
  __typename?: "Mutation";
  companyUserCreate: {
    __typename?: "CompanyUser";
    id: any;
    role: CompanyUserRole;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      jobTitle?: string;
      phone?: string;
      emailConfirmed?: boolean;
      phoneConfirmed?: boolean;
      twoFactorEnabled?: boolean;
      config: any;
      createdAt: any;
      registered: boolean;
      adminRole?: UserAdminRole;
    };
  };
};

export type CompanyUserDeleteMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type CompanyUserDeleteMutation = {
  __typename?: "Mutation";
  companyUserDelete: boolean;
};

export type CompanyUsersQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyUsersQuery = {
  __typename?: "Query";
  companyUsers: Array<{
    __typename?: "CompanyUser";
    id: any;
    role: CompanyUserRole;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      jobTitle?: string;
      phone?: string;
      emailConfirmed?: boolean;
      phoneConfirmed?: boolean;
      twoFactorEnabled?: boolean;
      config: any;
      createdAt: any;
      registered: boolean;
      adminRole?: UserAdminRole;
    };
  }>;
};

export type CompanyUserInvitationResendMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type CompanyUserInvitationResendMutation = {
  __typename?: "Mutation";
  companyUserInvitationResend: {
    __typename?: "CompanyUser";
    id: any;
    role: CompanyUserRole;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      jobTitle?: string;
      phone?: string;
      emailConfirmed?: boolean;
      phoneConfirmed?: boolean;
      twoFactorEnabled?: boolean;
      config: any;
      createdAt: any;
      registered: boolean;
      adminRole?: UserAdminRole;
    };
  };
};

export type CompanyUserUpdateMutationVariables = Exact<{
  role: CompanyUserRole;
  id: Scalars["UUID"];
  passwordRaw?: InputMaybe<Scalars["String"]>;
}>;

export type CompanyUserUpdateMutation = {
  __typename?: "Mutation";
  companyUserUpdate: {
    __typename?: "CompanyUser";
    id: any;
    role: CompanyUserRole;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      jobTitle?: string;
      phone?: string;
      emailConfirmed?: boolean;
      phoneConfirmed?: boolean;
      twoFactorEnabled?: boolean;
      config: any;
      createdAt: any;
      registered: boolean;
      adminRole?: UserAdminRole;
    };
  };
};

export type ZendeskSignInMutationVariables = Exact<{ [key: string]: never }>;

export type ZendeskSignInMutation = {
  __typename?: "Mutation";
  zendeskSignIn: { __typename?: "UserJwtTokenType"; jwtToken: string };
};

export type UserWithCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type UserWithCompaniesQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    jobTitle?: string;
    phone?: string;
    emailConfirmed?: boolean;
    phoneConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    config: any;
    createdAt: any;
    registered: boolean;
    adminRole?: UserAdminRole;
    companyUsers: Array<{
      __typename?: "CompanyUser";
      id: any;
      role: CompanyUserRole;
      createdAt: any;
      company: {
        __typename?: "Company";
        id: any;
        name: string;
        email?: string;
        phone?: string;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zip?: string;
        countryAlpha2?: string;
        coordinates?: Array<number>;
        businessType?: string;
        website?: string;
        industry?: string;
        planStripeId?: string;
        planStripeCancelAt?: any;
        planStripeStatus?: string;
        planStripeTrialEnd?: any;
        planStripeEndedAt?: any;
        planStripeCurrentPeriodEnd?: any;
        defaultFolderId?: any;
        uploadsFolderId?: any;
        locationCount: number;
        canAddLocation: CompanyPermissionType;
        assetCount: number;
        companyUserCount: number;
        fileSizeTotal: number;
        createdAt: any;
        config: any;
        planId?: string;
        isMainCompany: boolean;
        mainCompanyId?: string;
        hiddenCategoryIds: Array<string>;
        hiddenTypeIds: Array<string>;
      };
    }>;
  };
};

export const SearchFragmentFragmentDoc = gql`
  fragment SearchFragment on SearchResult {
    ... on Location {
      id
      name
    }
    ... on FloorPlan {
      id
      name
      locationId
    }
    ... on Asset {
      id
      name
    }
    ... on Maintenance {
      id
      name
      misc {
        resolvedName
      }
    }
    ... on Folder {
      id
      name
    }
    ... on File {
      id
      name
      folderId
    }
    ... on AssetCategory {
      id
      name
    }
    ... on AssetType {
      id
      name
      assetCategoryId
    }
  }
`;
export const AssetFragmentFragmentDoc = gql`
  fragment AssetFragment on Asset {
    id
    description
    createdAt
    config
    name
    maintenanceStatus
  }
`;
export const AssetTypeFragmentFragmentDoc = gql`
  fragment AssetTypeFragment on AssetType {
    id
    name
    description
    createdAt
    iconName
    iconColor
    iconType
    default
    specialAssetField
    assetCategoryId
    companyId
    misc {
      resolvedName
      resolvedIconColor
    }
  }
`;
export const AssetCategoryFragmentFragmentDoc = gql`
  fragment AssetCategoryFragment on AssetCategory {
    id
    name
    description
    createdAt
    iconName
    iconColor
    iconType
    default
    companyId
  }
`;
export const AssetTypeWithCategoryFragmentFragmentDoc = gql`
  fragment AssetTypeWithCategoryFragment on AssetType {
    ...AssetTypeFragment
    assetCategory {
      ...AssetCategoryFragment
    }
  }
  ${AssetTypeFragmentFragmentDoc}
  ${AssetCategoryFragmentFragmentDoc}
`;
export const AssetFieldFragmentFragmentDoc = gql`
  fragment AssetFieldFragment on AssetField {
    id
    description
    createdAt
    label
    type
    selectOptions
    unit
    required
    validationRegExp
    validationRegExpMessage
    order
  }
`;
export const AssetPartFieldFragmentFragmentDoc = gql`
  fragment AssetPartFieldFragment on AssetPartField {
    id
    description
    createdAt
    label
    type
    selectOptions
    unit
    required
    validationRegExp
    validationRegExpMessage
    order
    showInPanel
  }
`;
export const MaintenanceTemplateFragmentFragmentDoc = gql`
  fragment MaintenanceTemplateFragment on MaintenanceTemplate {
    id
    createdAt
    description
    name
    intervalType
    intervalValue
    assetTypeId
  }
`;
export const AssetTypeWithRelationsFragmentFragmentDoc = gql`
  fragment AssetTypeWithRelationsFragment on AssetType {
    ...AssetTypeWithCategoryFragment
    assetFields {
      ...AssetFieldFragment
    }
    assetPartFields {
      ...AssetPartFieldFragment
    }
    maintenanceTemplates {
      ...MaintenanceTemplateFragment
    }
  }
  ${AssetTypeWithCategoryFragmentFragmentDoc}
  ${AssetFieldFragmentFragmentDoc}
  ${AssetPartFieldFragmentFragmentDoc}
  ${MaintenanceTemplateFragmentFragmentDoc}
`;
export const AssetPartFragmentFragmentDoc = gql`
  fragment AssetPartFragment on AssetPart {
    id
    name
    description
    createdAt
    assetId
  }
`;
export const AssetPartFieldValueFragmentFragmentDoc = gql`
  fragment AssetPartFieldValueFragment on AssetPartFieldValue {
    id
    createdAt
    value
    assetPartFieldId
  }
`;
export const AssetPartWithRelationsFragmentFragmentDoc = gql`
  fragment AssetPartWithRelationsFragment on AssetPart {
    ...AssetPartFragment
    assetPartFieldValues {
      ...AssetPartFieldValueFragment
    }
  }
  ${AssetPartFragmentFragmentDoc}
  ${AssetPartFieldValueFragmentFragmentDoc}
`;
export const AssetFieldValueFragmentFragmentDoc = gql`
  fragment AssetFieldValueFragment on AssetFieldValue {
    id
    createdAt
    value
    assetFieldId
  }
`;
export const AssetFileFragmentFragmentDoc = gql`
  fragment AssetFileFragment on AssetFile {
    id
    name
    description
    path
    type
    fileSize
    order
    createdAt
    assetId
  }
`;
export const CompanyUserFragmentFragmentDoc = gql`
  fragment CompanyUserFragment on CompanyUser {
    id
    role
    createdAt
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    fullName
    jobTitle
    phone
    emailConfirmed
    phoneConfirmed
    twoFactorEnabled
    config
    createdAt
    registered
    adminRole
  }
`;
export const CompanyUserWithUserFragmentFragmentDoc = gql`
  fragment CompanyUserWithUserFragment on CompanyUser {
    ...CompanyUserFragment
    user {
      ...UserFragment
    }
  }
  ${CompanyUserFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const AssetCompanyUserFragmentFragmentDoc = gql`
  fragment AssetCompanyUserFragment on AssetCompanyUser {
    id
    createdAt
    companyUser {
      ...CompanyUserWithUserFragment
    }
  }
  ${CompanyUserWithUserFragmentFragmentDoc}
`;
export const FloorPlanAssetFragmentFragmentDoc = gql`
  fragment FloorPlanAssetFragment on FloorPlanAsset {
    id
    createdAt
    mapPosition
    mapAffectedArea
  }
`;
export const FloorPlanFragmentFragmentDoc = gql`
  fragment FloorPlanFragment on FloorPlan {
    id
    name
    description
    path
    createdAt
    fileSize
    type
    createdBy
    createdDate
    locationId
  }
`;
export const FloorPlanAssetWithFloorPlanFragmentFragmentDoc = gql`
  fragment FloorPlanAssetWithFloorPlanFragment on FloorPlanAsset {
    ...FloorPlanAssetFragment
    floorPlan {
      ...FloorPlanFragment
    }
  }
  ${FloorPlanAssetFragmentFragmentDoc}
  ${FloorPlanFragmentFragmentDoc}
`;
export const AssetAffectedAssetFragmentFragmentDoc = gql`
  fragment AssetAffectedAssetFragment on AssetAffectedAsset {
    id
    assetPartId
    pathPoints
    createdAt
  }
`;
export const AssetWithTypeFragmentFragmentDoc = gql`
  fragment AssetWithTypeFragment on Asset {
    ...AssetFragment
    assetType {
      ...AssetTypeFragment
    }
  }
  ${AssetFragmentFragmentDoc}
  ${AssetTypeFragmentFragmentDoc}
`;
export const AffectedAssetWithAffectedAssetFragmentFragmentDoc = gql`
  fragment AffectedAssetWithAffectedAssetFragment on AssetAffectedAsset {
    ...AssetAffectedAssetFragment
    affectedAsset {
      ...AssetWithTypeFragment
    }
  }
  ${AssetAffectedAssetFragmentFragmentDoc}
  ${AssetWithTypeFragmentFragmentDoc}
`;
export const AffectedByAssetWithAssetFragmentFragmentDoc = gql`
  fragment AffectedByAssetWithAssetFragment on AssetAffectedAsset {
    ...AssetAffectedAssetFragment
    asset {
      ...AssetWithTypeFragment
    }
  }
  ${AssetAffectedAssetFragmentFragmentDoc}
  ${AssetWithTypeFragmentFragmentDoc}
`;
export const AssetWithRelationsFragmentFragmentDoc = gql`
  fragment AssetWithRelationsFragment on Asset {
    ...AssetFragment
    assetType {
      ...AssetTypeWithRelationsFragment
    }
    assetParts {
      ...AssetPartWithRelationsFragment
    }
    assetFieldValues {
      ...AssetFieldValueFragment
    }
    assetFiles {
      ...AssetFileFragment
    }
    assetCompanyUsers {
      ...AssetCompanyUserFragment
    }
    floorPlanAssets {
      ...FloorPlanAssetWithFloorPlanFragment
    }
    assetAffectedAssets {
      ...AffectedAssetWithAffectedAssetFragment
    }
    assetAffectedByAssets {
      ...AffectedByAssetWithAssetFragment
    }
  }
  ${AssetFragmentFragmentDoc}
  ${AssetTypeWithRelationsFragmentFragmentDoc}
  ${AssetPartWithRelationsFragmentFragmentDoc}
  ${AssetFieldValueFragmentFragmentDoc}
  ${AssetFileFragmentFragmentDoc}
  ${AssetCompanyUserFragmentFragmentDoc}
  ${FloorPlanAssetWithFloorPlanFragmentFragmentDoc}
  ${AffectedAssetWithAffectedAssetFragmentFragmentDoc}
  ${AffectedByAssetWithAssetFragmentFragmentDoc}
`;
export const AssetCategoryWithTypesFragmentFragmentDoc = gql`
  fragment AssetCategoryWithTypesFragment on AssetCategory {
    ...AssetCategoryFragment
    assetTypes {
      ...AssetTypeFragment
    }
  }
  ${AssetCategoryFragmentFragmentDoc}
  ${AssetTypeFragmentFragmentDoc}
`;
export const AssetCategoryWithRelationsFragmentFragmentDoc = gql`
  fragment AssetCategoryWithRelationsFragment on AssetCategory {
    ...AssetCategoryFragment
    assetTypes {
      ...AssetTypeWithRelationsFragment
    }
  }
  ${AssetCategoryFragmentFragmentDoc}
  ${AssetTypeWithRelationsFragmentFragmentDoc}
`;
export const FolderFragmentFragmentDoc = gql`
  fragment FolderFragment on Folder {
    id
    name
    description
    createdAt
    parentId
  }
`;
export const FileFragmentFragmentDoc = gql`
  fragment FileFragment on File {
    id
    name
    description
    path
    createdAt
    fileSize
    folderId
  }
`;
export const FolderWithRelationsFragmentFragmentDoc = gql`
  fragment FolderWithRelationsFragment on Folder {
    ...FolderFragment
    parent {
      ...FolderFragment
    }
    files {
      ...FileFragment
    }
  }
  ${FolderFragmentFragmentDoc}
  ${FileFragmentFragmentDoc}
`;
export const LocationFragmentFragmentDoc = gql`
  fragment LocationFragment on Location {
    id
    name
    description
    type
    createdAt
    address1
    address2
    city
    state
    zip
    countryAlpha2
    coordinates
    parentId
  }
`;
export const FloorPlanAssetCategoryFragmentFragmentDoc = gql`
  fragment FloorPlanAssetCategoryFragment on FloorPlanAssetCategory {
    id
    createdAt
    assetCategoryId
    assetCategory {
      ...AssetCategoryFragment
    }
  }
  ${AssetCategoryFragmentFragmentDoc}
`;
export const FloorPlanAssetWithAssetFragmentFragmentDoc = gql`
  fragment FloorPlanAssetWithAssetFragment on FloorPlanAsset {
    ...FloorPlanAssetFragment
    asset {
      ...AssetWithTypeFragment
    }
  }
  ${FloorPlanAssetFragmentFragmentDoc}
  ${AssetWithTypeFragmentFragmentDoc}
`;
export const FloorPlanWithRelationsFragmentFragmentDoc = gql`
  fragment FloorPlanWithRelationsFragment on FloorPlan {
    ...FloorPlanFragment
    location {
      ...LocationFragment
    }
    floorPlanAssetCategories {
      ...FloorPlanAssetCategoryFragment
    }
    floorPlanAssets {
      ...FloorPlanAssetWithAssetFragment
    }
  }
  ${FloorPlanFragmentFragmentDoc}
  ${LocationFragmentFragmentDoc}
  ${FloorPlanAssetCategoryFragmentFragmentDoc}
  ${FloorPlanAssetWithAssetFragmentFragmentDoc}
`;
export const LocationCompanyUserFragmentFragmentDoc = gql`
  fragment LocationCompanyUserFragment on LocationCompanyUser {
    id
    createdAt
    companyUser {
      ...CompanyUserWithUserFragment
    }
  }
  ${CompanyUserWithUserFragmentFragmentDoc}
`;
export const LocationWithRelationsFragmentFragmentDoc = gql`
  fragment LocationWithRelationsFragment on Location {
    ...LocationFragment
    parent {
      ...LocationFragment
    }
    floorPlans {
      ...FloorPlanWithRelationsFragment
    }
    locationCompanyUsers {
      ...LocationCompanyUserFragment
    }
  }
  ${LocationFragmentFragmentDoc}
  ${FloorPlanWithRelationsFragmentFragmentDoc}
  ${LocationCompanyUserFragmentFragmentDoc}
`;
export const AssetForMapFragmentFragmentDoc = gql`
  fragment AssetForMapFragment on Asset {
    ...AssetFragment
    assetType {
      ...AssetTypeWithCategoryFragment
    }
    assetAffectedAssets {
      ...AffectedAssetWithAffectedAssetFragment
    }
    assetAffectedByAssets {
      ...AffectedByAssetWithAssetFragment
    }
    assetParts {
      ...AssetPartWithRelationsFragment
    }
  }
  ${AssetFragmentFragmentDoc}
  ${AssetTypeWithCategoryFragmentFragmentDoc}
  ${AffectedAssetWithAffectedAssetFragmentFragmentDoc}
  ${AffectedByAssetWithAssetFragmentFragmentDoc}
  ${AssetPartWithRelationsFragmentFragmentDoc}
`;
export const FloorPlanAssetForMapFragmentFragmentDoc = gql`
  fragment FloorPlanAssetForMapFragment on FloorPlanAsset {
    ...FloorPlanAssetFragment
    asset {
      ...AssetForMapFragment
    }
  }
  ${FloorPlanAssetFragmentFragmentDoc}
  ${AssetForMapFragmentFragmentDoc}
`;
export const FloorPlanForMapFragmentFragmentDoc = gql`
  fragment FloorPlanForMapFragment on FloorPlan {
    ...FloorPlanFragment
    signedUrl
    location {
      ...LocationFragment
      floorPlans {
        ...FloorPlanFragment
      }
    }
    floorPlanAssetCategories {
      ...FloorPlanAssetCategoryFragment
    }
    floorPlanAssets {
      ...FloorPlanAssetForMapFragment
    }
  }
  ${FloorPlanFragmentFragmentDoc}
  ${LocationFragmentFragmentDoc}
  ${FloorPlanAssetCategoryFragmentFragmentDoc}
  ${FloorPlanAssetForMapFragmentFragmentDoc}
`;
export const CategoriesSummaryFragmentFragmentDoc = gql`
  fragment CategoriesSummaryFragment on CategoriesSummary {
    categoryId
    categoryName
    currentAssetIds
    currentCount
    pastDueAssetIds
    pastDueCount
    dueIn30AssetIds
    dueIn30Count
  }
`;
export const MaintenanceReportFragmentFragmentDoc = gql`
  fragment MaintenanceReportFragment on MaintenanceReport {
    date
    currentCount
    pastDueCount
    dueIn30Count
  }
`;
export const SimpleMaintenanceFragmentFragmentDoc = gql`
  fragment SimpleMaintenanceFragment on Maintenance {
    id
    createdAt
    description
    name
    startDateTime
    timezone
    intervalType
    intervalValue
    durationType
    durationValue
    remindBeforeType
    remindBeforeValue
    endAfter
    endDateTime
    updatedAt
    assetId
    deletedScheduleTimestamps
  }
`;
export const MaintenanceFragmentFragmentDoc = gql`
  fragment MaintenanceFragment on Maintenance {
    ...SimpleMaintenanceFragment
    misc {
      resolvedName
      assetCategoryId
    }
  }
  ${SimpleMaintenanceFragmentFragmentDoc}
`;
export const MaintenanceWithContactsFragmentFragmentDoc = gql`
  fragment MaintenanceWithContactsFragment on Maintenance {
    ...MaintenanceFragment
    maintenanceCompanyUsers {
      id
      companyUserId
      companyUser {
        ...CompanyUserWithUserFragment
      }
    }
  }
  ${MaintenanceFragmentFragmentDoc}
  ${CompanyUserWithUserFragmentFragmentDoc}
`;
export const MaintenanceScheduleFragmentFragmentDoc = gql`
  fragment MaintenanceScheduleFragment on MaintenanceSchedule {
    id
    createdAt
    updatedAt
    maintenanceId
    completedAt
    scheduledAt
    completedByLabel
  }
`;
export const MaintenanceWithSchedulesFragmentFragmentDoc = gql`
  fragment MaintenanceWithSchedulesFragment on Maintenance {
    ...SimpleMaintenanceFragment
    maintenanceSchedules {
      ...MaintenanceScheduleFragment
    }
  }
  ${SimpleMaintenanceFragmentFragmentDoc}
  ${MaintenanceScheduleFragmentFragmentDoc}
`;
export const MaintenanceNoteFragmentFragmentDoc = gql`
  fragment MaintenanceNoteFragment on MaintenanceNote {
    id
    createdAt
    description
    updatedAt
    maintenanceId
    addedBy {
      userId
      userName
      userEmail
      userPhone
    }
  }
`;
export const MaintenanceWithRelationsFragmentFragmentDoc = gql`
  fragment MaintenanceWithRelationsFragment on Maintenance {
    ...MaintenanceFragment
    asset {
      id
      locationsLabel
    }
    maintenanceCompanyUsers {
      id
      companyUserId
      companyUser {
        ...CompanyUserWithUserFragment
      }
    }
    maintenanceNotes {
      ...MaintenanceNoteFragment
    }
    maintenanceSchedulesForDateRange(startDate: $startDate, endDate: $endDate) {
      ...MaintenanceScheduleFragment
    }
  }
  ${MaintenanceFragmentFragmentDoc}
  ${CompanyUserWithUserFragmentFragmentDoc}
  ${MaintenanceNoteFragmentFragmentDoc}
  ${MaintenanceScheduleFragmentFragmentDoc}
`;
export const SignedUrlFragmentFragmentDoc = gql`
  fragment SignedUrlFragment on SignedUrl {
    path
    signedUrl
  }
`;
export const NotificationFragmentFragmentDoc = gql`
  fragment NotificationFragment on Notification {
    id
    path
    createdAt
    updatedAt
    readAt
    message
  }
`;
export const PlansFragmentFragmentDoc = gql`
  fragment PlansFragment on Plan {
    id
    productName
    stripeId
    amount
    currency
    interval
    interval_count
    active
    default
    proRateAmount
    createdAt
  }
`;
export const StripePaymentMethodTypeCardFragmentFragmentDoc = gql`
  fragment StripePaymentMethodTypeCardFragment on StripePaymentMethodTypeCard {
    brand
    country
    last4
    exp_month
    exp_year
  }
`;
export const StripePaymentMethodTypeFragmentFragmentDoc = gql`
  fragment StripePaymentMethodTypeFragment on StripePaymentMethodType {
    id
    type
    card {
      ...StripePaymentMethodTypeCardFragment
    }
  }
  ${StripePaymentMethodTypeCardFragmentFragmentDoc}
`;
export const StripeCustomerFragmentFragmentDoc = gql`
  fragment StripeCustomerFragment on StripeCustomer {
    id
    email
    name
    phone
    created
    default_source
    invoice_settings {
      default_payment_method
    }
  }
`;
export const StripeBankAccountFragmentFragmentDoc = gql`
  fragment StripeBankAccountFragment on StripeBankAccount {
    id
    account_holder_name
    account_holder_type
    bank_name
    country
    currency
    last4
  }
`;
export const StripeInvoiceFragmentFragmentDoc = gql`
  fragment StripeInvoiceFragment on StripeInvoice {
    id
    amount_due
    amount_paid
    amount_remaining
    attempted
    auto_advance
    billing_reason
    collection_method
    currency
    hosted_invoice_url
    invoice_pdf
    status
    paid
    created
    due_date
    next_payment_attempt
    receipt_number
    discount {
      coupon {
        id
        valid
        currency
        duration
        duration_in_months
        name
        percent_off
        amount_off
      }
    }
    charge {
      receipt_url
      amount_refunded
      payment_method_details {
        type
        ach_debit {
          ...StripeBankAccountFragment
        }
        card {
          ...StripePaymentMethodTypeCardFragment
        }
      }
    }
    payment_intent {
      status
      client_secret
    }
  }
  ${StripeBankAccountFragmentFragmentDoc}
  ${StripePaymentMethodTypeCardFragmentFragmentDoc}
`;
export const StripeProrationFragmentFragmentDoc = gql`
  fragment StripeProrationFragment on StripeProration {
    dueDate
    amount
  }
`;
export const StripeSubscriptionFragmentFragmentDoc = gql`
  fragment StripeSubscriptionFragment on StripeSubscription {
    id
    cancel_at_period_end
    status
    cancel_at
    canceled_at
    created
    ended_at
    start_date
    trial_end
    trial_start
    days_until_due
  }
`;
export const StripeCouponFragmentFragmentDoc = gql`
  fragment StripeCouponFragment on StripeCoupon {
    id
    valid
    currency
    percent_off
    amount_off
    duration
    duration_in_months
  }
`;
export const CompanyFragmentFragmentDoc = gql`
  fragment CompanyFragment on Company {
    id
    name
    email
    phone
    address1
    address2
    city
    state
    zip
    countryAlpha2
    coordinates
    businessType
    website
    industry
    planStripeId
    planStripeCancelAt
    planStripeStatus
    planStripeTrialEnd
    planStripeEndedAt
    planStripeCurrentPeriodEnd
    defaultFolderId
    uploadsFolderId
    locationCount
    canAddLocation
    assetCount
    companyUserCount
    fileSizeTotal
    createdAt
    config
    planId
    isMainCompany
    mainCompanyId
    hiddenCategoryIds
    hiddenTypeIds
  }
`;
export const CompanyUserWithCompanyFragmentFragmentDoc = gql`
  fragment CompanyUserWithCompanyFragment on CompanyUser {
    ...CompanyUserFragment
    company {
      ...CompanyFragment
    }
  }
  ${CompanyUserFragmentFragmentDoc}
  ${CompanyFragmentFragmentDoc}
`;
export const CompanyCompanyUserFragmentFragmentDoc = gql`
  fragment CompanyCompanyUserFragment on CompanyCompanyUser {
    id
    createdAt
    companyUser {
      ...CompanyUserWithUserFragment
    }
  }
  ${CompanyUserWithUserFragmentFragmentDoc}
`;
export const CompanyWithContactUsersFragmentFragmentDoc = gql`
  fragment CompanyWithContactUsersFragment on Company {
    ...CompanyFragment
    companyCompanyUsers {
      ...CompanyCompanyUserFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
  ${CompanyCompanyUserFragmentFragmentDoc}
`;
export const FloorPlanPathFragmentFragmentDoc = gql`
  fragment FloorPlanPathFragment on FloorPlan {
    id
    name
    path
  }
`;
export const AssetIndexFragmentFragmentDoc = gql`
  fragment AssetIndexFragment on Asset {
    id
    name
    maintenanceStatus
    createdAt
    floorPlanAssets {
      id
      floorPlan {
        id
        locationId
      }
    }
    assetType {
      id
      name
      iconName
      iconColor
      iconType
      assetCategoryId
      misc {
        resolvedName
        resolvedIconColor
      }
    }
  }
`;
export const FloorPlanIndexFragmentFragmentDoc = gql`
  fragment FloorPlanIndexFragment on FloorPlan {
    ...FloorPlanFragment
    floorPlanAssetCategories {
      id
      assetCategoryId
    }
    location {
      id
      name
    }
    floorPlanAssets {
      id
      asset {
        id
        maintenanceStatus
      }
    }
  }
  ${FloorPlanFragmentFragmentDoc}
`;
export const LocationIndexFragmentFragmentDoc = gql`
  fragment LocationIndexFragment on Location {
    id
    name
    type
    address1
    address2
    city
    state
    zip
    countryAlpha2
    coordinates
    parentId
    companyId
    createdAt
    floorPlans {
      id
      name
      floorPlanAssets {
        id
        assetId
        asset {
          id
          name
          assetTypeId
          assetType {
            id
            name
            assetCategoryId
            assetCategory {
              id
              name
            }
          }
          assetAffectedAssets {
            id
            affectedAsset {
              id
              name
            }
          }
          assetAffectedByAssets {
            id
            asset {
              id
              name
            }
          }
          maintenanceStatus
        }
      }
    }
  }
`;
export const MaintenanceIndexFragmentFragmentDoc = gql`
  fragment MaintenanceIndexFragment on Maintenance {
    id
    name
    intervalType
    intervalValue
    startDateTime
    endDateTime
    endAfter
    durationType
    durationValue
    assetId
    createdAt
    deletedScheduleTimestamps
    misc {
      resolvedName
      assetCategoryId
    }
    maintenanceSchedulesForDateRange(startDate: $startDate, endDate: $endDate) {
      id
      completedAt
      scheduledAt
    }
  }
`;
export const StripeBankAccountsDocument = gql`
  query StripeBankAccounts {
    stripeBankAccounts {
      ...StripeBankAccountFragment
    }
  }
  ${StripeBankAccountFragmentFragmentDoc}
`;

/**
 * __useStripeBankAccountsQuery__
 *
 * To run a query within a React component, call `useStripeBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeBankAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StripeBankAccountsQuery,
    StripeBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StripeBankAccountsQuery,
    StripeBankAccountsQueryVariables
  >(StripeBankAccountsDocument, options);
}

export function useStripeBankAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripeBankAccountsQuery,
    StripeBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StripeBankAccountsQuery,
    StripeBankAccountsQueryVariables
  >(StripeBankAccountsDocument, options);
}

export type StripeBankAccountsQueryHookResult = ReturnType<
  typeof useStripeBankAccountsQuery
>;
export type StripeBankAccountsLazyQueryHookResult = ReturnType<
  typeof useStripeBankAccountsLazyQuery
>;
export type StripeBankAccountsQueryResult = ApolloReactCommon.QueryResult<
  StripeBankAccountsQuery,
  StripeBankAccountsQueryVariables
>;
export const StripeBankAccountCreateDocument = gql`
  mutation StripeBankAccountCreate(
    $plaidAccountId: String!
    $plaidLinkPublicToken: String!
    $attemptToChargeOpenInvoice: Boolean
  ) {
    stripeBankAccountCreate(
      plaidAccountId: $plaidAccountId
      plaidLinkPublicToken: $plaidLinkPublicToken
      attemptToChargeOpenInvoice: $attemptToChargeOpenInvoice
    ) {
      ...StripeBankAccountFragment
    }
  }
  ${StripeBankAccountFragmentFragmentDoc}
`;
export type StripeBankAccountCreateMutationFn =
  ApolloReactCommon.MutationFunction<
    StripeBankAccountCreateMutation,
    StripeBankAccountCreateMutationVariables
  >;

/**
 * __useStripeBankAccountCreateMutation__
 *
 * To run a mutation, you first call `useStripeBankAccountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeBankAccountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeBankAccountCreateMutation, { data, loading, error }] = useStripeBankAccountCreateMutation({
 *   variables: {
 *      plaidAccountId: // value for 'plaidAccountId'
 *      plaidLinkPublicToken: // value for 'plaidLinkPublicToken'
 *      attemptToChargeOpenInvoice: // value for 'attemptToChargeOpenInvoice'
 *   },
 * });
 */
export function useStripeBankAccountCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripeBankAccountCreateMutation,
    StripeBankAccountCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripeBankAccountCreateMutation,
    StripeBankAccountCreateMutationVariables
  >(StripeBankAccountCreateDocument, options);
}

export type StripeBankAccountCreateMutationHookResult = ReturnType<
  typeof useStripeBankAccountCreateMutation
>;
export type StripeBankAccountCreateMutationResult =
  ApolloReactCommon.MutationResult<StripeBankAccountCreateMutation>;
export type StripeBankAccountCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripeBankAccountCreateMutation,
    StripeBankAccountCreateMutationVariables
  >;
export const StripeBankAccountDeleteDocument = gql`
  mutation StripeBankAccountDelete($stripeBankAccountId: String!) {
    stripeBankAccountDelete(stripeBankAccountId: $stripeBankAccountId)
  }
`;
export type StripeBankAccountDeleteMutationFn =
  ApolloReactCommon.MutationFunction<
    StripeBankAccountDeleteMutation,
    StripeBankAccountDeleteMutationVariables
  >;

/**
 * __useStripeBankAccountDeleteMutation__
 *
 * To run a mutation, you first call `useStripeBankAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeBankAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeBankAccountDeleteMutation, { data, loading, error }] = useStripeBankAccountDeleteMutation({
 *   variables: {
 *      stripeBankAccountId: // value for 'stripeBankAccountId'
 *   },
 * });
 */
export function useStripeBankAccountDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripeBankAccountDeleteMutation,
    StripeBankAccountDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripeBankAccountDeleteMutation,
    StripeBankAccountDeleteMutationVariables
  >(StripeBankAccountDeleteDocument, options);
}

export type StripeBankAccountDeleteMutationHookResult = ReturnType<
  typeof useStripeBankAccountDeleteMutation
>;
export type StripeBankAccountDeleteMutationResult =
  ApolloReactCommon.MutationResult<StripeBankAccountDeleteMutation>;
export type StripeBankAccountDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripeBankAccountDeleteMutation,
    StripeBankAccountDeleteMutationVariables
  >;
export const StripeBankAccountMakePrimaryDocument = gql`
  mutation StripeBankAccountMakePrimary($stripeBankAccountId: String!) {
    stripeBankAccountMakePrimary(stripeBankAccountId: $stripeBankAccountId)
  }
`;
export type StripeBankAccountMakePrimaryMutationFn =
  ApolloReactCommon.MutationFunction<
    StripeBankAccountMakePrimaryMutation,
    StripeBankAccountMakePrimaryMutationVariables
  >;

/**
 * __useStripeBankAccountMakePrimaryMutation__
 *
 * To run a mutation, you first call `useStripeBankAccountMakePrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeBankAccountMakePrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeBankAccountMakePrimaryMutation, { data, loading, error }] = useStripeBankAccountMakePrimaryMutation({
 *   variables: {
 *      stripeBankAccountId: // value for 'stripeBankAccountId'
 *   },
 * });
 */
export function useStripeBankAccountMakePrimaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripeBankAccountMakePrimaryMutation,
    StripeBankAccountMakePrimaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripeBankAccountMakePrimaryMutation,
    StripeBankAccountMakePrimaryMutationVariables
  >(StripeBankAccountMakePrimaryDocument, options);
}

export type StripeBankAccountMakePrimaryMutationHookResult = ReturnType<
  typeof useStripeBankAccountMakePrimaryMutation
>;
export type StripeBankAccountMakePrimaryMutationResult =
  ApolloReactCommon.MutationResult<StripeBankAccountMakePrimaryMutation>;
export type StripeBankAccountMakePrimaryMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripeBankAccountMakePrimaryMutation,
    StripeBankAccountMakePrimaryMutationVariables
  >;
export const AssetLocationDocument = gql`
  query AssetLocation($id: UUID!) {
    asset(id: $id) {
      id
      locationsLabel
    }
  }
`;

/**
 * __useAssetLocationQuery__
 *
 * To run a query within a React component, call `useAssetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetLocationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AssetLocationQuery,
    AssetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetLocationQuery,
    AssetLocationQueryVariables
  >(AssetLocationDocument, options);
}

export function useAssetLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetLocationQuery,
    AssetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetLocationQuery,
    AssetLocationQueryVariables
  >(AssetLocationDocument, options);
}

export type AssetLocationQueryHookResult = ReturnType<
  typeof useAssetLocationQuery
>;
export type AssetLocationLazyQueryHookResult = ReturnType<
  typeof useAssetLocationLazyQuery
>;
export type AssetLocationQueryResult = ApolloReactCommon.QueryResult<
  AssetLocationQuery,
  AssetLocationQueryVariables
>;
export const StripePaymentMethodsDocument = gql`
  query StripePaymentMethods {
    stripePaymentMethods {
      ...StripePaymentMethodTypeFragment
    }
  }
  ${StripePaymentMethodTypeFragmentFragmentDoc}
`;

/**
 * __useStripePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useStripePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripePaymentMethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StripePaymentMethodsQuery,
    StripePaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StripePaymentMethodsQuery,
    StripePaymentMethodsQueryVariables
  >(StripePaymentMethodsDocument, options);
}

export function useStripePaymentMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripePaymentMethodsQuery,
    StripePaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StripePaymentMethodsQuery,
    StripePaymentMethodsQueryVariables
  >(StripePaymentMethodsDocument, options);
}

export type StripePaymentMethodsQueryHookResult = ReturnType<
  typeof useStripePaymentMethodsQuery
>;
export type StripePaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useStripePaymentMethodsLazyQuery
>;
export type StripePaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
  StripePaymentMethodsQuery,
  StripePaymentMethodsQueryVariables
>;
export const StripePaymentMethodCreateDocument = gql`
  mutation StripePaymentMethodCreate(
    $stripePaymentMethodId: String!
    $attemptToChargeOpenInvoice: Boolean
  ) {
    stripePaymentMethodCreate(
      stripePaymentMethodId: $stripePaymentMethodId
      attemptToChargeOpenInvoice: $attemptToChargeOpenInvoice
    ) {
      ...StripePaymentMethodTypeFragment
    }
  }
  ${StripePaymentMethodTypeFragmentFragmentDoc}
`;
export type StripePaymentMethodCreateMutationFn =
  ApolloReactCommon.MutationFunction<
    StripePaymentMethodCreateMutation,
    StripePaymentMethodCreateMutationVariables
  >;

/**
 * __useStripePaymentMethodCreateMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodCreateMutation, { data, loading, error }] = useStripePaymentMethodCreateMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *      attemptToChargeOpenInvoice: // value for 'attemptToChargeOpenInvoice'
 *   },
 * });
 */
export function useStripePaymentMethodCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripePaymentMethodCreateMutation,
    StripePaymentMethodCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripePaymentMethodCreateMutation,
    StripePaymentMethodCreateMutationVariables
  >(StripePaymentMethodCreateDocument, options);
}

export type StripePaymentMethodCreateMutationHookResult = ReturnType<
  typeof useStripePaymentMethodCreateMutation
>;
export type StripePaymentMethodCreateMutationResult =
  ApolloReactCommon.MutationResult<StripePaymentMethodCreateMutation>;
export type StripePaymentMethodCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripePaymentMethodCreateMutation,
    StripePaymentMethodCreateMutationVariables
  >;
export const StripePaymentMethodDeleteDocument = gql`
  mutation StripePaymentMethodDelete($stripePaymentMethodId: String!) {
    stripePaymentMethodDelete(stripePaymentMethodId: $stripePaymentMethodId)
  }
`;
export type StripePaymentMethodDeleteMutationFn =
  ApolloReactCommon.MutationFunction<
    StripePaymentMethodDeleteMutation,
    StripePaymentMethodDeleteMutationVariables
  >;

/**
 * __useStripePaymentMethodDeleteMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodDeleteMutation, { data, loading, error }] = useStripePaymentMethodDeleteMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripePaymentMethodDeleteMutation,
    StripePaymentMethodDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripePaymentMethodDeleteMutation,
    StripePaymentMethodDeleteMutationVariables
  >(StripePaymentMethodDeleteDocument, options);
}

export type StripePaymentMethodDeleteMutationHookResult = ReturnType<
  typeof useStripePaymentMethodDeleteMutation
>;
export type StripePaymentMethodDeleteMutationResult =
  ApolloReactCommon.MutationResult<StripePaymentMethodDeleteMutation>;
export type StripePaymentMethodDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripePaymentMethodDeleteMutation,
    StripePaymentMethodDeleteMutationVariables
  >;
export const StripePaymentMethodMakePrimaryDocument = gql`
  mutation StripePaymentMethodMakePrimary($stripePaymentMethodId: String!) {
    stripePaymentMethodMakePrimary(
      stripePaymentMethodId: $stripePaymentMethodId
    )
  }
`;
export type StripePaymentMethodMakePrimaryMutationFn =
  ApolloReactCommon.MutationFunction<
    StripePaymentMethodMakePrimaryMutation,
    StripePaymentMethodMakePrimaryMutationVariables
  >;

/**
 * __useStripePaymentMethodMakePrimaryMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodMakePrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodMakePrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodMakePrimaryMutation, { data, loading, error }] = useStripePaymentMethodMakePrimaryMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodMakePrimaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripePaymentMethodMakePrimaryMutation,
    StripePaymentMethodMakePrimaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripePaymentMethodMakePrimaryMutation,
    StripePaymentMethodMakePrimaryMutationVariables
  >(StripePaymentMethodMakePrimaryDocument, options);
}

export type StripePaymentMethodMakePrimaryMutationHookResult = ReturnType<
  typeof useStripePaymentMethodMakePrimaryMutation
>;
export type StripePaymentMethodMakePrimaryMutationResult =
  ApolloReactCommon.MutationResult<StripePaymentMethodMakePrimaryMutation>;
export type StripePaymentMethodMakePrimaryMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripePaymentMethodMakePrimaryMutation,
    StripePaymentMethodMakePrimaryMutationVariables
  >;
export const StripePaymentMethodUpdateDocument = gql`
  mutation StripePaymentMethodUpdate(
    $stripePaymentMethodId: String!
    $expYear: Float!
    $expMonth: Float!
  ) {
    stripePaymentMethodUpdate(
      stripePaymentMethodId: $stripePaymentMethodId
      expYear: $expYear
      expMonth: $expMonth
    ) {
      ...StripePaymentMethodTypeFragment
    }
  }
  ${StripePaymentMethodTypeFragmentFragmentDoc}
`;
export type StripePaymentMethodUpdateMutationFn =
  ApolloReactCommon.MutationFunction<
    StripePaymentMethodUpdateMutation,
    StripePaymentMethodUpdateMutationVariables
  >;

/**
 * __useStripePaymentMethodUpdateMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodUpdateMutation, { data, loading, error }] = useStripePaymentMethodUpdateMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *      expYear: // value for 'expYear'
 *      expMonth: // value for 'expMonth'
 *   },
 * });
 */
export function useStripePaymentMethodUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripePaymentMethodUpdateMutation,
    StripePaymentMethodUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripePaymentMethodUpdateMutation,
    StripePaymentMethodUpdateMutationVariables
  >(StripePaymentMethodUpdateDocument, options);
}

export type StripePaymentMethodUpdateMutationHookResult = ReturnType<
  typeof useStripePaymentMethodUpdateMutation
>;
export type StripePaymentMethodUpdateMutationResult =
  ApolloReactCommon.MutationResult<StripePaymentMethodUpdateMutation>;
export type StripePaymentMethodUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripePaymentMethodUpdateMutation,
    StripePaymentMethodUpdateMutationVariables
  >;
export const FileSignedUrlDocument = gql`
  query FileSignedUrl($id: UUID!) {
    file(id: $id) {
      id
      signedUrl
    }
  }
`;

/**
 * __useFileSignedUrlQuery__
 *
 * To run a query within a React component, call `useFileSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileSignedUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileSignedUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FileSignedUrlQuery,
    FileSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FileSignedUrlQuery,
    FileSignedUrlQueryVariables
  >(FileSignedUrlDocument, options);
}

export function useFileSignedUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FileSignedUrlQuery,
    FileSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FileSignedUrlQuery,
    FileSignedUrlQueryVariables
  >(FileSignedUrlDocument, options);
}

export type FileSignedUrlQueryHookResult = ReturnType<
  typeof useFileSignedUrlQuery
>;
export type FileSignedUrlLazyQueryHookResult = ReturnType<
  typeof useFileSignedUrlLazyQuery
>;
export type FileSignedUrlQueryResult = ApolloReactCommon.QueryResult<
  FileSignedUrlQuery,
  FileSignedUrlQueryVariables
>;
export const AssetFilesSignedUrlDocument = gql`
  query AssetFilesSignedUrl($id: UUID!) {
    asset(id: $id) {
      assetFiles {
        id
        signedUrl
      }
    }
  }
`;

/**
 * __useAssetFilesSignedUrlQuery__
 *
 * To run a query within a React component, call `useAssetFilesSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetFilesSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetFilesSignedUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetFilesSignedUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AssetFilesSignedUrlQuery,
    AssetFilesSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetFilesSignedUrlQuery,
    AssetFilesSignedUrlQueryVariables
  >(AssetFilesSignedUrlDocument, options);
}

export function useAssetFilesSignedUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetFilesSignedUrlQuery,
    AssetFilesSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetFilesSignedUrlQuery,
    AssetFilesSignedUrlQueryVariables
  >(AssetFilesSignedUrlDocument, options);
}

export type AssetFilesSignedUrlQueryHookResult = ReturnType<
  typeof useAssetFilesSignedUrlQuery
>;
export type AssetFilesSignedUrlLazyQueryHookResult = ReturnType<
  typeof useAssetFilesSignedUrlLazyQuery
>;
export type AssetFilesSignedUrlQueryResult = ApolloReactCommon.QueryResult<
  AssetFilesSignedUrlQuery,
  AssetFilesSignedUrlQueryVariables
>;
export const SignedUrlCreateDocument = gql`
  mutation SignedUrlCreate($data: [SignedUrlInput!]!) {
    signedUrlCreate(data: $data) {
      ...SignedUrlFragment
    }
  }
  ${SignedUrlFragmentFragmentDoc}
`;
export type SignedUrlCreateMutationFn = ApolloReactCommon.MutationFunction<
  SignedUrlCreateMutation,
  SignedUrlCreateMutationVariables
>;

/**
 * __useSignedUrlCreateMutation__
 *
 * To run a mutation, you first call `useSignedUrlCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignedUrlCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signedUrlCreateMutation, { data, loading, error }] = useSignedUrlCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignedUrlCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignedUrlCreateMutation,
    SignedUrlCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SignedUrlCreateMutation,
    SignedUrlCreateMutationVariables
  >(SignedUrlCreateDocument, options);
}

export type SignedUrlCreateMutationHookResult = ReturnType<
  typeof useSignedUrlCreateMutation
>;
export type SignedUrlCreateMutationResult =
  ApolloReactCommon.MutationResult<SignedUrlCreateMutation>;
export type SignedUrlCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SignedUrlCreateMutation,
    SignedUrlCreateMutationVariables
  >;
export const FileCreateDocument = gql`
  mutation FileCreate($data: FileCreateInput!) {
    fileCreate(data: $data) {
      ...FileFragment
    }
  }
  ${FileFragmentFragmentDoc}
`;
export type FileCreateMutationFn = ApolloReactCommon.MutationFunction<
  FileCreateMutation,
  FileCreateMutationVariables
>;

/**
 * __useFileCreateMutation__
 *
 * To run a mutation, you first call `useFileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileCreateMutation, { data, loading, error }] = useFileCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFileCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FileCreateMutation,
    FileCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FileCreateMutation,
    FileCreateMutationVariables
  >(FileCreateDocument, options);
}

export type FileCreateMutationHookResult = ReturnType<
  typeof useFileCreateMutation
>;
export type FileCreateMutationResult =
  ApolloReactCommon.MutationResult<FileCreateMutation>;
export type FileCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FileCreateMutation,
  FileCreateMutationVariables
>;
export const FloorPlanDocument = gql`
  query FloorPlan($id: UUID!, $companyId: UUID) {
    floorPlan(id: $id, companyId: $companyId) {
      ...FloorPlanForMapFragment
    }
  }
  ${FloorPlanForMapFragmentFragmentDoc}
`;

/**
 * __useFloorPlanQuery__
 *
 * To run a query within a React component, call `useFloorPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFloorPlanQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FloorPlanQuery,
    FloorPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FloorPlanQuery, FloorPlanQueryVariables>(
    FloorPlanDocument,
    options
  );
}

export function useFloorPlanLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FloorPlanQuery,
    FloorPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FloorPlanQuery, FloorPlanQueryVariables>(
    FloorPlanDocument,
    options
  );
}

export type FloorPlanQueryHookResult = ReturnType<typeof useFloorPlanQuery>;
export type FloorPlanLazyQueryHookResult = ReturnType<
  typeof useFloorPlanLazyQuery
>;
export type FloorPlanQueryResult = ApolloReactCommon.QueryResult<
  FloorPlanQuery,
  FloorPlanQueryVariables
>;
export const FloorPlanAssetUpdateManyDocument = gql`
  mutation FloorPlanAssetUpdateMany($data: [FloorPlanAssetUpdateManyInput!]!) {
    floorPlanAssetUpdateMany(data: $data) {
      ...FloorPlanAssetFragment
    }
  }
  ${FloorPlanAssetFragmentFragmentDoc}
`;
export const AssetAffectedAssetUpdateManyDocument = gql`
  mutation AssetAffectedAssetUpdateMany(
    $data: [AssetAffectedAssetUpdateInput!]!
  ) {
    assetAffectedAssetUpdateMany(data: $data) {
      ...AssetAffectedAssetFragment
    }
  }
  ${AssetAffectedAssetFragmentFragmentDoc}
`;
export type FloorPlanAssetUpdateManyMutationFn =
  ApolloReactCommon.MutationFunction<
    FloorPlanAssetUpdateManyMutation,
    FloorPlanAssetUpdateManyMutationVariables
  >;

/**
 * __useFloorPlanAssetUpdateManyMutation__
 *
 * To run a mutation, you first call `useFloorPlanAssetUpdateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanAssetUpdateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanAssetUpdateManyMutation, { data, loading, error }] = useFloorPlanAssetUpdateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFloorPlanAssetUpdateManyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FloorPlanAssetUpdateManyMutation,
    FloorPlanAssetUpdateManyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FloorPlanAssetUpdateManyMutation,
    FloorPlanAssetUpdateManyMutationVariables
  >(FloorPlanAssetUpdateManyDocument, options);
}

/**
 * __assetAffectedAssetUpdateManyMutation__
 *
 * To run a mutation, you first call `assetAffectedAssetUpdateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `assetAffectedAssetUpdateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetAffectedAssetUpdateManyMutation, { data, loading, error }] = assetAffectedAssetUpdateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetAffectedAssetUpdateManyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetAffectedAssetUpdateManyMutation,
    AssetAffectedAssetUpdateManyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetAffectedAssetUpdateManyMutation,
    AssetAffectedAssetUpdateManyMutationVariables
  >(AssetAffectedAssetUpdateManyDocument, options);
}

export type FloorPlanAssetUpdateManyMutationHookResult = ReturnType<
  typeof useFloorPlanAssetUpdateManyMutation
>;
export type FloorPlanAssetUpdateManyMutationResult =
  ApolloReactCommon.MutationResult<FloorPlanAssetUpdateManyMutation>;
export type FloorPlanAssetUpdateManyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FloorPlanAssetUpdateManyMutation,
    FloorPlanAssetUpdateManyMutationVariables
  >;
export const FloorPlanSimpleDocument = gql`
  query FloorPlanSimple {
    floorPlans {
      id
      name
    }
  }
`;

/**
 * __useFloorPlanSimpleQuery__
 *
 * To run a query within a React component, call `useFloorPlanSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useFloorPlanSimpleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FloorPlanSimpleQuery,
    FloorPlanSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FloorPlanSimpleQuery,
    FloorPlanSimpleQueryVariables
  >(FloorPlanSimpleDocument, options);
}

export function useFloorPlanSimpleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FloorPlanSimpleQuery,
    FloorPlanSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FloorPlanSimpleQuery,
    FloorPlanSimpleQueryVariables
  >(FloorPlanSimpleDocument, options);
}

export type FloorPlanSimpleQueryHookResult = ReturnType<
  typeof useFloorPlanSimpleQuery
>;
export type FloorPlanSimpleLazyQueryHookResult = ReturnType<
  typeof useFloorPlanSimpleLazyQuery
>;
export type FloorPlanSimpleQueryResult = ApolloReactCommon.QueryResult<
  FloorPlanSimpleQuery,
  FloorPlanSimpleQueryVariables
>;
export const LocationSimpleDocument = gql`
  query LocationSimple {
    locations {
      id
      name
    }
  }
`;

/**
 * __useLocationSimpleQuery__
 *
 * To run a query within a React component, call `useLocationSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationSimpleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationSimpleQuery,
    LocationSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LocationSimpleQuery,
    LocationSimpleQueryVariables
  >(LocationSimpleDocument, options);
}

export function useLocationSimpleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationSimpleQuery,
    LocationSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LocationSimpleQuery,
    LocationSimpleQueryVariables
  >(LocationSimpleDocument, options);
}

export type LocationSimpleQueryHookResult = ReturnType<
  typeof useLocationSimpleQuery
>;
export type LocationSimpleLazyQueryHookResult = ReturnType<
  typeof useLocationSimpleLazyQuery
>;
export type LocationSimpleQueryResult = ApolloReactCommon.QueryResult<
  LocationSimpleQuery,
  LocationSimpleQueryVariables
>;
export const AssetWithTypeDocument = gql`
  query AssetWithType {
    assets {
      ...AssetWithTypeFragment
      locationsLabel
    }
  }
  ${AssetWithTypeFragmentFragmentDoc}
`;

/**
 * __useAssetWithTypeQuery__
 *
 * To run a query within a React component, call `useAssetWithTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetWithTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetWithTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetWithTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetWithTypeQuery,
    AssetWithTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetWithTypeQuery,
    AssetWithTypeQueryVariables
  >(AssetWithTypeDocument, options);
}

export function useAssetWithTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetWithTypeQuery,
    AssetWithTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetWithTypeQuery,
    AssetWithTypeQueryVariables
  >(AssetWithTypeDocument, options);
}

export type AssetWithTypeQueryHookResult = ReturnType<
  typeof useAssetWithTypeQuery
>;
export type AssetWithTypeLazyQueryHookResult = ReturnType<
  typeof useAssetWithTypeLazyQuery
>;
export type AssetWithTypeQueryResult = ApolloReactCommon.QueryResult<
  AssetWithTypeQuery,
  AssetWithTypeQueryVariables
>;

export const CompanyByIdDocument = gql`
  query Company($id: UUID!) {
    company(id: $id) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;

export const CompanyStartUpDocument = gql`
  query CompanyStartUp {
    company {
      id
      planId
      planStripeId
      isMainCompany
      mainCompanyId
      assetCount
      locationCount
      companyUserCount
      fileSizeTotal
      misc {
        assetCategoryIds
        assetTypeIds
        assetStatus
      }
      mainCompany {
        ...CompanyFragment
      }
    }
  }
  ${CompanyFragmentFragmentDoc}
`;

/**
 * __useCompanyStartUpQuery__
 *
 * To run a query within a React component, call `useCompanyStartUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyStartUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyStartUpQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyStartUpQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyStartUpQuery,
    CompanyStartUpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CompanyStartUpQuery,
    CompanyStartUpQueryVariables
  >(CompanyStartUpDocument, options);
}

export function useCompanyStartUpLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompanyStartUpQuery,
    CompanyStartUpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CompanyStartUpQuery,
    CompanyStartUpQueryVariables
  >(CompanyStartUpDocument, options);
}

export function useCompanyByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyByIdQuery,
    CompanyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CompanyByIdQuery, CompanyByIdQueryVariables>(
    CompanyByIdDocument,
    options
  );
}

export type CompanyStartUpQueryHookResult = ReturnType<
  typeof useCompanyStartUpQuery
>;
export type CompanyStartUpLazyQueryHookResult = ReturnType<
  typeof useCompanyStartUpLazyQuery
>;
export type CompanyStartUpQueryResult = ApolloReactCommon.QueryResult<
  CompanyStartUpQuery,
  CompanyStartUpQueryVariables
>;
export const UserEmailConfirmDocument = gql`
  mutation userEmailConfirm($data: UserEmailConfirmInput) {
    userEmailConfirm(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UserEmailConfirmMutationFn = ApolloReactCommon.MutationFunction<
  UserEmailConfirmMutation,
  UserEmailConfirmMutationVariables
>;

/**
 * __useUserEmailConfirmMutation__
 *
 * To run a mutation, you first call `useUserEmailConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEmailConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEmailConfirmMutation, { data, loading, error }] = useUserEmailConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserEmailConfirmMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserEmailConfirmMutation,
    UserEmailConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserEmailConfirmMutation,
    UserEmailConfirmMutationVariables
  >(UserEmailConfirmDocument, options);
}

export type UserEmailConfirmMutationHookResult = ReturnType<
  typeof useUserEmailConfirmMutation
>;
export type UserEmailConfirmMutationResult =
  ApolloReactCommon.MutationResult<UserEmailConfirmMutation>;
export type UserEmailConfirmMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UserEmailConfirmMutation,
    UserEmailConfirmMutationVariables
  >;
export const WebPushNotificationSubscriptionCreateDocument = gql`
  mutation WebPushNotificationSubscriptionCreate($subscription: JSONObject!) {
    webPushNotificationSubscriptionCreate(subscription: $subscription)
  }
`;
export type WebPushNotificationSubscriptionCreateMutationFn =
  ApolloReactCommon.MutationFunction<
    WebPushNotificationSubscriptionCreateMutation,
    WebPushNotificationSubscriptionCreateMutationVariables
  >;

/**
 * __useWebPushNotificationSubscriptionCreateMutation__
 *
 * To run a mutation, you first call `useWebPushNotificationSubscriptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebPushNotificationSubscriptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webPushNotificationSubscriptionCreateMutation, { data, loading, error }] = useWebPushNotificationSubscriptionCreateMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useWebPushNotificationSubscriptionCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WebPushNotificationSubscriptionCreateMutation,
    WebPushNotificationSubscriptionCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    WebPushNotificationSubscriptionCreateMutation,
    WebPushNotificationSubscriptionCreateMutationVariables
  >(WebPushNotificationSubscriptionCreateDocument, options);
}

export type WebPushNotificationSubscriptionCreateMutationHookResult =
  ReturnType<typeof useWebPushNotificationSubscriptionCreateMutation>;
export type WebPushNotificationSubscriptionCreateMutationResult =
  ApolloReactCommon.MutationResult<WebPushNotificationSubscriptionCreateMutation>;
export type WebPushNotificationSubscriptionCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    WebPushNotificationSubscriptionCreateMutation,
    WebPushNotificationSubscriptionCreateMutationVariables
  >;
export const NotificationsDocument = gql`
  query Notifications {
    notifications {
      ...NotificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, options);
}

export function useNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, options);
}

export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const NewNotificationDocument = gql`
  subscription NewNotification {
    newNotification {
      ...NotificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewNotificationSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    NewNotificationSubscription,
    NewNotificationSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<
    NewNotificationSubscription,
    NewNotificationSubscriptionVariables
  >(NewNotificationDocument, options);
}

export type NewNotificationSubscriptionHookResult = ReturnType<
  typeof useNewNotificationSubscription
>;
export type NewNotificationSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<NewNotificationSubscription>;
export const UpdateNotificationDocument = gql`
  subscription UpdateNotification {
    updateNotification {
      ...NotificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;

/**
 * __useUpdateNotificationSubscription__
 *
 * To run a query within a React component, call `useUpdateNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUpdateNotificationSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    UpdateNotificationSubscription,
    UpdateNotificationSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<
    UpdateNotificationSubscription,
    UpdateNotificationSubscriptionVariables
  >(UpdateNotificationDocument, options);
}

export type UpdateNotificationSubscriptionHookResult = ReturnType<
  typeof useUpdateNotificationSubscription
>;
export type UpdateNotificationSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<UpdateNotificationSubscription>;
export const DeleteNotificationDocument = gql`
  subscription DeleteNotification {
    deleteNotification {
      ...NotificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;

/**
 * __useDeleteNotificationSubscription__
 *
 * To run a query within a React component, call `useDeleteNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useDeleteNotificationSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    DeleteNotificationSubscription,
    DeleteNotificationSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<
    DeleteNotificationSubscription,
    DeleteNotificationSubscriptionVariables
  >(DeleteNotificationDocument, options);
}

export type DeleteNotificationSubscriptionHookResult = ReturnType<
  typeof useDeleteNotificationSubscription
>;
export type DeleteNotificationSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<DeleteNotificationSubscription>;
export const NotificationReadDocument = gql`
  mutation NotificationRead($id: UUID!) {
    notificationRead(id: $id) {
      ...NotificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;
export type NotificationReadMutationFn = ApolloReactCommon.MutationFunction<
  NotificationReadMutation,
  NotificationReadMutationVariables
>;

/**
 * __useNotificationReadMutation__
 *
 * To run a mutation, you first call `useNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationReadMutation, { data, loading, error }] = useNotificationReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationReadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotificationReadMutation,
    NotificationReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotificationReadMutation,
    NotificationReadMutationVariables
  >(NotificationReadDocument, options);
}

export type NotificationReadMutationHookResult = ReturnType<
  typeof useNotificationReadMutation
>;
export type NotificationReadMutationResult =
  ApolloReactCommon.MutationResult<NotificationReadMutation>;
export type NotificationReadMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    NotificationReadMutation,
    NotificationReadMutationVariables
  >;
export const NotificationUnreadDocument = gql`
  mutation NotificationUnread($id: UUID!) {
    notificationUnread(id: $id) {
      ...NotificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;
export type NotificationUnreadMutationFn = ApolloReactCommon.MutationFunction<
  NotificationUnreadMutation,
  NotificationUnreadMutationVariables
>;

/**
 * __useNotificationUnreadMutation__
 *
 * To run a mutation, you first call `useNotificationUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationUnreadMutation, { data, loading, error }] = useNotificationUnreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationUnreadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotificationUnreadMutation,
    NotificationUnreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotificationUnreadMutation,
    NotificationUnreadMutationVariables
  >(NotificationUnreadDocument, options);
}

export type NotificationUnreadMutationHookResult = ReturnType<
  typeof useNotificationUnreadMutation
>;
export type NotificationUnreadMutationResult =
  ApolloReactCommon.MutationResult<NotificationUnreadMutation>;
export type NotificationUnreadMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    NotificationUnreadMutation,
    NotificationUnreadMutationVariables
  >;
export const NotificationDeleteDocument = gql`
  mutation NotificationDelete($id: UUID!) {
    notificationDelete(id: $id)
  }
`;
export type NotificationDeleteMutationFn = ApolloReactCommon.MutationFunction<
  NotificationDeleteMutation,
  NotificationDeleteMutationVariables
>;

/**
 * __useNotificationDeleteMutation__
 *
 * To run a mutation, you first call `useNotificationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationDeleteMutation, { data, loading, error }] = useNotificationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotificationDeleteMutation,
    NotificationDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotificationDeleteMutation,
    NotificationDeleteMutationVariables
  >(NotificationDeleteDocument, options);
}

export type NotificationDeleteMutationHookResult = ReturnType<
  typeof useNotificationDeleteMutation
>;
export type NotificationDeleteMutationResult =
  ApolloReactCommon.MutationResult<NotificationDeleteMutation>;
export type NotificationDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    NotificationDeleteMutation,
    NotificationDeleteMutationVariables
  >;
export const NotificationDeleteAllDocument = gql`
  mutation NotificationDeleteAll {
    notificationDeleteAll
  }
`;
export type NotificationDeleteAllMutationFn =
  ApolloReactCommon.MutationFunction<
    NotificationDeleteAllMutation,
    NotificationDeleteAllMutationVariables
  >;

/**
 * __useNotificationDeleteAllMutation__
 *
 * To run a mutation, you first call `useNotificationDeleteAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationDeleteAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationDeleteAllMutation, { data, loading, error }] = useNotificationDeleteAllMutation({
 *   variables: {
 *   },
 * });
 */
export function useNotificationDeleteAllMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotificationDeleteAllMutation,
    NotificationDeleteAllMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotificationDeleteAllMutation,
    NotificationDeleteAllMutationVariables
  >(NotificationDeleteAllDocument, options);
}

export type NotificationDeleteAllMutationHookResult = ReturnType<
  typeof useNotificationDeleteAllMutation
>;
export type NotificationDeleteAllMutationResult =
  ApolloReactCommon.MutationResult<NotificationDeleteAllMutation>;
export type NotificationDeleteAllMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    NotificationDeleteAllMutation,
    NotificationDeleteAllMutationVariables
  >;
export const NotificationDeleteAllReadDocument = gql`
  mutation NotificationDeleteAllRead {
    notificationDeleteAllRead
  }
`;
export type NotificationDeleteAllReadMutationFn =
  ApolloReactCommon.MutationFunction<
    NotificationDeleteAllReadMutation,
    NotificationDeleteAllReadMutationVariables
  >;

/**
 * __useNotificationDeleteAllReadMutation__
 *
 * To run a mutation, you first call `useNotificationDeleteAllReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationDeleteAllReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationDeleteAllReadMutation, { data, loading, error }] = useNotificationDeleteAllReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useNotificationDeleteAllReadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotificationDeleteAllReadMutation,
    NotificationDeleteAllReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotificationDeleteAllReadMutation,
    NotificationDeleteAllReadMutationVariables
  >(NotificationDeleteAllReadDocument, options);
}

export type NotificationDeleteAllReadMutationHookResult = ReturnType<
  typeof useNotificationDeleteAllReadMutation
>;
export type NotificationDeleteAllReadMutationResult =
  ApolloReactCommon.MutationResult<NotificationDeleteAllReadMutation>;
export type NotificationDeleteAllReadMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    NotificationDeleteAllReadMutation,
    NotificationDeleteAllReadMutationVariables
  >;
export const NotificationReadAllDocument = gql`
  mutation NotificationReadAll {
    notificationReadAll
  }
`;
export type NotificationReadAllMutationFn = ApolloReactCommon.MutationFunction<
  NotificationReadAllMutation,
  NotificationReadAllMutationVariables
>;

/**
 * __useNotificationReadAllMutation__
 *
 * To run a mutation, you first call `useNotificationReadAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationReadAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationReadAllMutation, { data, loading, error }] = useNotificationReadAllMutation({
 *   variables: {
 *   },
 * });
 */
export function useNotificationReadAllMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotificationReadAllMutation,
    NotificationReadAllMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotificationReadAllMutation,
    NotificationReadAllMutationVariables
  >(NotificationReadAllDocument, options);
}

export type NotificationReadAllMutationHookResult = ReturnType<
  typeof useNotificationReadAllMutation
>;
export type NotificationReadAllMutationResult =
  ApolloReactCommon.MutationResult<NotificationReadAllMutation>;
export type NotificationReadAllMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    NotificationReadAllMutation,
    NotificationReadAllMutationVariables
  >;
export const SearchDocument = gql`
  query Search($query: String!, $type: [SearchType!]) {
    search(query: $query, type: $type) {
      ... on Location {
        id
        name
      }
      ... on FloorPlan {
        id
        name
        locationId
      }
      ... on Asset {
        id
        name
      }
      ... on Maintenance {
        id
        name
        misc {
          resolvedName
        }
      }
      ... on Folder {
        id
        name
      }
      ... on File {
        id
        name
        folderId
      }
      ... on AssetCategory {
        id
        name
      }
      ... on AssetType {
        id
        name
        assetCategoryId
      }
    }
  }
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SearchQuery,
    SearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}

export function useSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchQuery,
    SearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}

export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export const UpdateSessionDocument = gql`
  mutation UpdateSession($jwt: String) {
    updateSession(jwt: $jwt) @client
  }
`;
export type UpdateSessionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSessionMutation,
  UpdateSessionMutationVariables
>;

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      jwt: // value for 'jwt'
 *   },
 * });
 */
export function useUpdateSessionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >(UpdateSessionDocument, options);
}

export type UpdateSessionMutationHookResult = ReturnType<
  typeof useUpdateSessionMutation
>;
export type UpdateSessionMutationResult =
  ApolloReactCommon.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >;
export const UpdateCurrentCompanyDocument = gql`
  mutation UpdateCurrentCompany($id: UUID) {
    updateCurrentCompany(id: $id) @client
  }
`;
export type UpdateCurrentCompanyMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCurrentCompanyMutation,
  UpdateCurrentCompanyMutationVariables
>;

/**
 * __useUpdateCurrentCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentCompanyMutation, { data, loading, error }] = useUpdateCurrentCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCurrentCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCurrentCompanyMutation,
    UpdateCurrentCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCurrentCompanyMutation,
    UpdateCurrentCompanyMutationVariables
  >(UpdateCurrentCompanyDocument, options);
}

export type UpdateCurrentCompanyMutationHookResult = ReturnType<
  typeof useUpdateCurrentCompanyMutation
>;
export type UpdateCurrentCompanyMutationResult =
  ApolloReactCommon.MutationResult<UpdateCurrentCompanyMutation>;
export type UpdateCurrentCompanyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCurrentCompanyMutation,
    UpdateCurrentCompanyMutationVariables
  >;
export const UpdateAppSettingsDocument = gql`
  mutation UpdateAppSettings($sideNavMinimized: Boolean, $plaidOpen: Boolean) {
    updateAppSettings(
      sideNavMinimized: $sideNavMinimized
      plaidOpen: $plaidOpen
    ) @client
  }
`;
export type UpdateAppSettingsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAppSettingsMutation,
  UpdateAppSettingsMutationVariables
>;

/**
 * __useUpdateAppSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAppSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppSettingsMutation, { data, loading, error }] = useUpdateAppSettingsMutation({
 *   variables: {
 *      sideNavMinimized: // value for 'sideNavMinimized'
 *      plaidOpen: // value for 'plaidOpen'
 *   },
 * });
 */
export function useUpdateAppSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAppSettingsMutation,
    UpdateAppSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateAppSettingsMutation,
    UpdateAppSettingsMutationVariables
  >(UpdateAppSettingsDocument, options);
}

export type UpdateAppSettingsMutationHookResult = ReturnType<
  typeof useUpdateAppSettingsMutation
>;
export type UpdateAppSettingsMutationResult =
  ApolloReactCommon.MutationResult<UpdateAppSettingsMutation>;
export type UpdateAppSettingsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateAppSettingsMutation,
    UpdateAppSettingsMutationVariables
  >;
export const UpdateFolderConfigDocument = gql`
  mutation UpdateFolderConfig(
    $sortBy: SortBy
    $sortOrder: SortOrder
    $displayType: FOLDER_DISPLAY_TYPE
  ) {
    updateFolderConfig(
      sortBy: $sortBy
      sortOrder: $sortOrder
      displayType: $displayType
    ) @client
  }
`;
export type UpdateFolderConfigMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFolderConfigMutation,
  UpdateFolderConfigMutationVariables
>;

/**
 * __useUpdateFolderConfigMutation__
 *
 * To run a mutation, you first call `useUpdateFolderConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderConfigMutation, { data, loading, error }] = useUpdateFolderConfigMutation({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      displayType: // value for 'displayType'
 *   },
 * });
 */
export function useUpdateFolderConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFolderConfigMutation,
    UpdateFolderConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateFolderConfigMutation,
    UpdateFolderConfigMutationVariables
  >(UpdateFolderConfigDocument, options);
}

export type UpdateFolderConfigMutationHookResult = ReturnType<
  typeof useUpdateFolderConfigMutation
>;
export type UpdateFolderConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateFolderConfigMutation>;
export type UpdateFolderConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateFolderConfigMutation,
    UpdateFolderConfigMutationVariables
  >;
export const UpdateLocationConfigDocument = gql`
  mutation UpdateLocationConfig(
    $sortBy: SortBy
    $sortOrder: SortOrder
    $activeTabIndex: Int
    $activeCollapsible: [String]
  ) {
    updateLocationConfig(
      sortBy: $sortBy
      sortOrder: $sortOrder
      activeTabIndex: $activeTabIndex
      activeCollapsible: $activeCollapsible
    ) @client
  }
`;
export type UpdateLocationConfigMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationConfigMutation,
  UpdateLocationConfigMutationVariables
>;

/**
 * __useUpdateLocationConfigMutation__
 *
 * To run a mutation, you first call `useUpdateLocationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationConfigMutation, { data, loading, error }] = useUpdateLocationConfigMutation({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      activeTabIndex: // value for 'activeTabIndex'
 *      activeCollapsible: // value for 'activeCollapsible'
 *   },
 * });
 */
export function useUpdateLocationConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationConfigMutation,
    UpdateLocationConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateLocationConfigMutation,
    UpdateLocationConfigMutationVariables
  >(UpdateLocationConfigDocument, options);
}

export type UpdateLocationConfigMutationHookResult = ReturnType<
  typeof useUpdateLocationConfigMutation
>;
export type UpdateLocationConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateLocationConfigMutation>;
export type UpdateLocationConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateLocationConfigMutation,
    UpdateLocationConfigMutationVariables
  >;
export const UpdateAssetConfigDocument = gql`
  mutation UpdateAssetConfig(
    $sortBy: SortBy
    $sortOrder: SortOrder
    $activeTabIndex: Int
    $displayType: ASSET_DISPLAY_TYPE
    $filter: [MaintenanceStatusType]
    $filterCategoryId: String
    $activeCollapsible: [String]
  ) {
    updateAssetConfig(
      sortBy: $sortBy
      sortOrder: $sortOrder
      activeTabIndex: $activeTabIndex
      displayType: $displayType
      filter: $filter
      filterCategoryId: $filterCategoryId
      activeCollapsible: $activeCollapsible
    ) @client
  }
`;
export type UpdateAssetConfigMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAssetConfigMutation,
  UpdateAssetConfigMutationVariables
>;

/**
 * __useUpdateAssetConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAssetConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetConfigMutation, { data, loading, error }] = useUpdateAssetConfigMutation({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      activeTabIndex: // value for 'activeTabIndex'
 *      displayType: // value for 'displayType'
 *      filter: // value for 'filter'
 *      filterCategoryId: // value for 'filterCategoryId'
 *      activeCollapsible: // value for 'activeCollapsible'
 *   },
 * });
 */
export function useUpdateAssetConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAssetConfigMutation,
    UpdateAssetConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateAssetConfigMutation,
    UpdateAssetConfigMutationVariables
  >(UpdateAssetConfigDocument, options);
}

export type UpdateAssetConfigMutationHookResult = ReturnType<
  typeof useUpdateAssetConfigMutation
>;
export type UpdateAssetConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateAssetConfigMutation>;
export type UpdateAssetConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateAssetConfigMutation,
    UpdateAssetConfigMutationVariables
  >;
export const UpdateMaintenanceConfigDocument = gql`
  mutation UpdateMaintenanceConfig(
    $sortBy: SortBy
    $sortOrder: SortOrder
    $displayType: MAINTENANCE_DISPLAY_TYPE
    $filterStatus: [MaintenanceFilterStatus]
    $filterCategoryId: String
  ) {
    updateMaintenanceConfig(
      sortBy: $sortBy
      sortOrder: $sortOrder
      displayType: $displayType
      filterStatus: $filterStatus
      filterCategoryId: $filterCategoryId
    ) @client
  }
`;
export type UpdateMaintenanceConfigMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateMaintenanceConfigMutation,
    UpdateMaintenanceConfigMutationVariables
  >;

/**
 * __useUpdateMaintenanceConfigMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceConfigMutation, { data, loading, error }] = useUpdateMaintenanceConfigMutation({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      displayType: // value for 'displayType'
 *      filterStatus: // value for 'filterStatus'
 *      filterCategoryId: // value for 'filterCategoryId'
 *   },
 * });
 */
export function useUpdateMaintenanceConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMaintenanceConfigMutation,
    UpdateMaintenanceConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateMaintenanceConfigMutation,
    UpdateMaintenanceConfigMutationVariables
  >(UpdateMaintenanceConfigDocument, options);
}

export type UpdateMaintenanceConfigMutationHookResult = ReturnType<
  typeof useUpdateMaintenanceConfigMutation
>;
export type UpdateMaintenanceConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateMaintenanceConfigMutation>;
export type UpdateMaintenanceConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateMaintenanceConfigMutation,
    UpdateMaintenanceConfigMutationVariables
  >;
export const UpdateAssetCategoryConfigDocument = gql`
  mutation UpdateAssetCategoryConfig(
    $sortBy: SortBy
    $sortOrder: SortOrder
    $displayType: ASSET_CATEGORY_DISPLAY_TYPE
    $showHidden: Boolean
  ) {
    updateAssetCategoryConfig(
      sortBy: $sortBy
      sortOrder: $sortOrder
      displayType: $displayType
      showHidden: $showHidden
    ) @client
  }
`;
export type UpdateAssetCategoryConfigMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateAssetCategoryConfigMutation,
    UpdateAssetCategoryConfigMutationVariables
  >;

/**
 * __useUpdateAssetCategoryConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAssetCategoryConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetCategoryConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetCategoryConfigMutation, { data, loading, error }] = useUpdateAssetCategoryConfigMutation({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      displayType: // value for 'displayType'
 *      showHidden: // value for 'showHidden'
 *   },
 * });
 */
export function useUpdateAssetCategoryConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAssetCategoryConfigMutation,
    UpdateAssetCategoryConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateAssetCategoryConfigMutation,
    UpdateAssetCategoryConfigMutationVariables
  >(UpdateAssetCategoryConfigDocument, options);
}

export type UpdateAssetCategoryConfigMutationHookResult = ReturnType<
  typeof useUpdateAssetCategoryConfigMutation
>;
export type UpdateAssetCategoryConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateAssetCategoryConfigMutation>;
export type UpdateAssetCategoryConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateAssetCategoryConfigMutation,
    UpdateAssetCategoryConfigMutationVariables
  >;
export const UpdateAssetTypeConfigDocument = gql`
  mutation UpdateAssetTypeConfig(
    $sortBy: SortBy
    $sortOrder: SortOrder
    $displayType: ASSET_TYPE_DISPLAY_TYPE
  ) {
    updateAssetTypeConfig(
      sortBy: $sortBy
      sortOrder: $sortOrder
      displayType: $displayType
    ) @client
  }
`;
export type UpdateAssetTypeConfigMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateAssetTypeConfigMutation,
    UpdateAssetTypeConfigMutationVariables
  >;

/**
 * __useUpdateAssetTypeConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAssetTypeConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetTypeConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetTypeConfigMutation, { data, loading, error }] = useUpdateAssetTypeConfigMutation({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      displayType: // value for 'displayType'
 *   },
 * });
 */
export function useUpdateAssetTypeConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAssetTypeConfigMutation,
    UpdateAssetTypeConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateAssetTypeConfigMutation,
    UpdateAssetTypeConfigMutationVariables
  >(UpdateAssetTypeConfigDocument, options);
}

export type UpdateAssetTypeConfigMutationHookResult = ReturnType<
  typeof useUpdateAssetTypeConfigMutation
>;
export type UpdateAssetTypeConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateAssetTypeConfigMutation>;
export type UpdateAssetTypeConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateAssetTypeConfigMutation,
    UpdateAssetTypeConfigMutationVariables
  >;
export const UpdateFloorPlanConfigDocument = gql`
  mutation UpdateFloorPlanConfig(
    $sortBy: FLOOR_PLAN_SortBy
    $sortOrder: SortOrder
    $displayType: FLOOR_PLAN_DISPLAY_TYPE
    $filterCategoryId: String
  ) {
    updateFloorPlanConfig(
      sortBy: $sortBy
      sortOrder: $sortOrder
      displayType: $displayType
      filterCategoryId: $filterCategoryId
    ) @client
  }
`;
export type UpdateFloorPlanConfigMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateFloorPlanConfigMutation,
    UpdateFloorPlanConfigMutationVariables
  >;

/**
 * __useUpdateFloorPlanConfigMutation__
 *
 * To run a mutation, you first call `useUpdateFloorPlanConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFloorPlanConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFloorPlanConfigMutation, { data, loading, error }] = useUpdateFloorPlanConfigMutation({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      displayType: // value for 'displayType'
 *      filterCategoryId: // value for 'filterCategoryId'
 *   },
 * });
 */
export function useUpdateFloorPlanConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFloorPlanConfigMutation,
    UpdateFloorPlanConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateFloorPlanConfigMutation,
    UpdateFloorPlanConfigMutationVariables
  >(UpdateFloorPlanConfigDocument, options);
}

export type UpdateFloorPlanConfigMutationHookResult = ReturnType<
  typeof useUpdateFloorPlanConfigMutation
>;
export type UpdateFloorPlanConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateFloorPlanConfigMutation>;
export type UpdateFloorPlanConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateFloorPlanConfigMutation,
    UpdateFloorPlanConfigMutationVariables
  >;
export const UpdateDashboardConfigDocument = gql`
  mutation UpdateDashboardConfig($filter: [MaintenanceStatusType]) {
    updateDashboardConfig(filter: $filter) @client
  }
`;
export type UpdateDashboardConfigMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateDashboardConfigMutation,
    UpdateDashboardConfigMutationVariables
  >;

/**
 * __useUpdateDashboardConfigMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardConfigMutation, { data, loading, error }] = useUpdateDashboardConfigMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateDashboardConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDashboardConfigMutation,
    UpdateDashboardConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateDashboardConfigMutation,
    UpdateDashboardConfigMutationVariables
  >(UpdateDashboardConfigDocument, options);
}

export type UpdateDashboardConfigMutationHookResult = ReturnType<
  typeof useUpdateDashboardConfigMutation
>;
export type UpdateDashboardConfigMutationResult =
  ApolloReactCommon.MutationResult<UpdateDashboardConfigMutation>;
export type UpdateDashboardConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateDashboardConfigMutation,
    UpdateDashboardConfigMutationVariables
  >;
export const SessionDocument = gql`
  query Session {
    session @client {
      isLoggedIn
    }
  }
`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SessionQuery,
    SessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SessionQuery, SessionQueryVariables>(
    SessionDocument,
    options
  );
}

export function useSessionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SessionQuery,
    SessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SessionQuery, SessionQueryVariables>(
    SessionDocument,
    options
  );
}

export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = ApolloReactCommon.QueryResult<
  SessionQuery,
  SessionQueryVariables
>;
export const CurrentCompanyDocument = gql`
  query CurrentCompany {
    currentCompany @client {
      id
    }
  }
`;

/**
 * __useCurrentCompanyQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  >(CurrentCompanyDocument, options);
}

export function useCurrentCompanyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrentCompanyQuery,
    CurrentCompanyQueryVariables
  >(CurrentCompanyDocument, options);
}

export type CurrentCompanyQueryHookResult = ReturnType<
  typeof useCurrentCompanyQuery
>;
export type CurrentCompanyLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyLazyQuery
>;
export type CurrentCompanyQueryResult = ApolloReactCommon.QueryResult<
  CurrentCompanyQuery,
  CurrentCompanyQueryVariables
>;
export const AppSettingsDocument = gql`
  query AppSettings {
    appSettings @client {
      sideNavMinimized
      plaidOpen
    }
  }
`;

/**
 * __useAppSettingsQuery__
 *
 * To run a query within a React component, call `useAppSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AppSettingsQuery,
    AppSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AppSettingsQuery, AppSettingsQueryVariables>(
    AppSettingsDocument,
    options
  );
}

export function useAppSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AppSettingsQuery,
    AppSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AppSettingsQuery,
    AppSettingsQueryVariables
  >(AppSettingsDocument, options);
}

export type AppSettingsQueryHookResult = ReturnType<typeof useAppSettingsQuery>;
export type AppSettingsLazyQueryHookResult = ReturnType<
  typeof useAppSettingsLazyQuery
>;
export type AppSettingsQueryResult = ApolloReactCommon.QueryResult<
  AppSettingsQuery,
  AppSettingsQueryVariables
>;
export const FolderConfigDocument = gql`
  query FolderConfig {
    folderConfig @client {
      sortBy
      sortOrder
      displayType
    }
  }
`;

/**
 * __useFolderConfigQuery__
 *
 * To run a query within a React component, call `useFolderConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useFolderConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FolderConfigQuery,
    FolderConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FolderConfigQuery,
    FolderConfigQueryVariables
  >(FolderConfigDocument, options);
}

export function useFolderConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FolderConfigQuery,
    FolderConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FolderConfigQuery,
    FolderConfigQueryVariables
  >(FolderConfigDocument, options);
}

export type FolderConfigQueryHookResult = ReturnType<
  typeof useFolderConfigQuery
>;
export type FolderConfigLazyQueryHookResult = ReturnType<
  typeof useFolderConfigLazyQuery
>;
export type FolderConfigQueryResult = ApolloReactCommon.QueryResult<
  FolderConfigQuery,
  FolderConfigQueryVariables
>;
export const LocationConfigDocument = gql`
  query LocationConfig {
    locationConfig @client {
      sortBy
      sortOrder
      activeTabIndex
      activeCollapsible
    }
  }
`;

/**
 * __useLocationConfigQuery__
 *
 * To run a query within a React component, call `useLocationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationConfigQuery,
    LocationConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LocationConfigQuery,
    LocationConfigQueryVariables
  >(LocationConfigDocument, options);
}

export function useLocationConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationConfigQuery,
    LocationConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LocationConfigQuery,
    LocationConfigQueryVariables
  >(LocationConfigDocument, options);
}

export type LocationConfigQueryHookResult = ReturnType<
  typeof useLocationConfigQuery
>;
export type LocationConfigLazyQueryHookResult = ReturnType<
  typeof useLocationConfigLazyQuery
>;
export type LocationConfigQueryResult = ApolloReactCommon.QueryResult<
  LocationConfigQuery,
  LocationConfigQueryVariables
>;
export const AssetConfigDocument = gql`
  query assetConfig {
    assetConfig @client {
      sortBy
      sortOrder
      activeTabIndex
      displayType
      filter
      filterCategoryId
      activeCollapsible
    }
  }
`;

/**
 * __useAssetConfigQuery__
 *
 * To run a query within a React component, call `useAssetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetConfigQuery,
    AssetConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssetConfigQuery, AssetConfigQueryVariables>(
    AssetConfigDocument,
    options
  );
}

export function useAssetConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetConfigQuery,
    AssetConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetConfigQuery,
    AssetConfigQueryVariables
  >(AssetConfigDocument, options);
}

export type AssetConfigQueryHookResult = ReturnType<typeof useAssetConfigQuery>;
export type AssetConfigLazyQueryHookResult = ReturnType<
  typeof useAssetConfigLazyQuery
>;
export type AssetConfigQueryResult = ApolloReactCommon.QueryResult<
  AssetConfigQuery,
  AssetConfigQueryVariables
>;
export const MaintenanceConfigDocument = gql`
  query maintenanceConfig {
    maintenanceConfig @client {
      sortBy
      sortOrder
      displayType
      filterStatus
      filterCategoryId
    }
  }
`;

/**
 * __useMaintenanceConfigQuery__
 *
 * To run a query within a React component, call `useMaintenanceConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaintenanceConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MaintenanceConfigQuery,
    MaintenanceConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MaintenanceConfigQuery,
    MaintenanceConfigQueryVariables
  >(MaintenanceConfigDocument, options);
}

export function useMaintenanceConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaintenanceConfigQuery,
    MaintenanceConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaintenanceConfigQuery,
    MaintenanceConfigQueryVariables
  >(MaintenanceConfigDocument, options);
}

export type MaintenanceConfigQueryHookResult = ReturnType<
  typeof useMaintenanceConfigQuery
>;
export type MaintenanceConfigLazyQueryHookResult = ReturnType<
  typeof useMaintenanceConfigLazyQuery
>;
export type MaintenanceConfigQueryResult = ApolloReactCommon.QueryResult<
  MaintenanceConfigQuery,
  MaintenanceConfigQueryVariables
>;
export const AssetCategoryConfigDocument = gql`
  query AssetCategoryConfig {
    assetCategoryConfig @client {
      sortBy
      sortOrder
      displayType
      showHidden
    }
  }
`;

/**
 * __useAssetCategoryConfigQuery__
 *
 * To run a query within a React component, call `useAssetCategoryConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoryConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCategoryConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetCategoryConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetCategoryConfigQuery,
    AssetCategoryConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetCategoryConfigQuery,
    AssetCategoryConfigQueryVariables
  >(AssetCategoryConfigDocument, options);
}

export function useAssetCategoryConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetCategoryConfigQuery,
    AssetCategoryConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetCategoryConfigQuery,
    AssetCategoryConfigQueryVariables
  >(AssetCategoryConfigDocument, options);
}

export type AssetCategoryConfigQueryHookResult = ReturnType<
  typeof useAssetCategoryConfigQuery
>;
export type AssetCategoryConfigLazyQueryHookResult = ReturnType<
  typeof useAssetCategoryConfigLazyQuery
>;
export type AssetCategoryConfigQueryResult = ApolloReactCommon.QueryResult<
  AssetCategoryConfigQuery,
  AssetCategoryConfigQueryVariables
>;
export const AssetTypeConfigDocument = gql`
  query AssetTypeConfig {
    assetTypeConfig @client {
      sortBy
      sortOrder
      displayType
    }
  }
`;

/**
 * __useAssetTypeConfigQuery__
 *
 * To run a query within a React component, call `useAssetTypeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetTypeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetTypeConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetTypeConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetTypeConfigQuery,
    AssetTypeConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetTypeConfigQuery,
    AssetTypeConfigQueryVariables
  >(AssetTypeConfigDocument, options);
}

export function useAssetTypeConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetTypeConfigQuery,
    AssetTypeConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetTypeConfigQuery,
    AssetTypeConfigQueryVariables
  >(AssetTypeConfigDocument, options);
}

export type AssetTypeConfigQueryHookResult = ReturnType<
  typeof useAssetTypeConfigQuery
>;
export type AssetTypeConfigLazyQueryHookResult = ReturnType<
  typeof useAssetTypeConfigLazyQuery
>;
export type AssetTypeConfigQueryResult = ApolloReactCommon.QueryResult<
  AssetTypeConfigQuery,
  AssetTypeConfigQueryVariables
>;
export const FloorPlanConfigDocument = gql`
  query FloorPlanConfig {
    floorPlanConfig @client {
      sortBy
      sortOrder
      displayType
      filterCategoryId
    }
  }
`;

/**
 * __useFloorPlanConfigQuery__
 *
 * To run a query within a React component, call `useFloorPlanConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useFloorPlanConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FloorPlanConfigQuery,
    FloorPlanConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FloorPlanConfigQuery,
    FloorPlanConfigQueryVariables
  >(FloorPlanConfigDocument, options);
}

export function useFloorPlanConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FloorPlanConfigQuery,
    FloorPlanConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FloorPlanConfigQuery,
    FloorPlanConfigQueryVariables
  >(FloorPlanConfigDocument, options);
}

export type FloorPlanConfigQueryHookResult = ReturnType<
  typeof useFloorPlanConfigQuery
>;
export type FloorPlanConfigLazyQueryHookResult = ReturnType<
  typeof useFloorPlanConfigLazyQuery
>;
export type FloorPlanConfigQueryResult = ApolloReactCommon.QueryResult<
  FloorPlanConfigQuery,
  FloorPlanConfigQueryVariables
>;
export const DashboardConfigDocument = gql`
  query DashboardConfig {
    dashboardConfig @client {
      filter
    }
  }
`;

/**
 * __useDashboardConfigQuery__
 *
 * To run a query within a React component, call `useDashboardConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DashboardConfigQuery,
    DashboardConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DashboardConfigQuery,
    DashboardConfigQueryVariables
  >(DashboardConfigDocument, options);
}

export function useDashboardConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DashboardConfigQuery,
    DashboardConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DashboardConfigQuery,
    DashboardConfigQueryVariables
  >(DashboardConfigDocument, options);
}

export type DashboardConfigQueryHookResult = ReturnType<
  typeof useDashboardConfigQuery
>;
export type DashboardConfigLazyQueryHookResult = ReturnType<
  typeof useDashboardConfigLazyQuery
>;
export type DashboardConfigQueryResult = ApolloReactCommon.QueryResult<
  DashboardConfigQuery,
  DashboardConfigQueryVariables
>;
export const AssetCategoriesDocument = gql`
  query AssetCategories($companyId: UUID) {
    assetCategories(companyId: $companyId) {
      ...AssetCategoryWithTypesFragment
    }
  }
  ${AssetCategoryWithTypesFragmentFragmentDoc}
`;

/**
 * __useAssetCategoriesQuery__
 *
 * To run a query within a React component, call `useAssetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetCategoriesQuery,
    AssetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetCategoriesQuery,
    AssetCategoriesQueryVariables
  >(AssetCategoriesDocument, options);
}

export function useAssetCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetCategoriesQuery,
    AssetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetCategoriesQuery,
    AssetCategoriesQueryVariables
  >(AssetCategoriesDocument, options);
}

export type AssetCategoriesQueryHookResult = ReturnType<
  typeof useAssetCategoriesQuery
>;
export type AssetCategoriesLazyQueryHookResult = ReturnType<
  typeof useAssetCategoriesLazyQuery
>;
export type AssetCategoriesQueryResult = ApolloReactCommon.QueryResult<
  AssetCategoriesQuery,
  AssetCategoriesQueryVariables
>;
export const AssetCategoriesWithRelationsDocument = gql`
  query AssetCategoriesWithRelations {
    assetCategories {
      ...AssetCategoryWithRelationsFragment
    }
  }
  ${AssetCategoryWithRelationsFragmentFragmentDoc}
`;

/**
 * __useAssetCategoriesWithRelationsQuery__
 *
 * To run a query within a React component, call `useAssetCategoriesWithRelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoriesWithRelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCategoriesWithRelationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetCategoriesWithRelationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetCategoriesWithRelationsQuery,
    AssetCategoriesWithRelationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetCategoriesWithRelationsQuery,
    AssetCategoriesWithRelationsQueryVariables
  >(AssetCategoriesWithRelationsDocument, options);
}

export function useAssetCategoriesWithRelationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetCategoriesWithRelationsQuery,
    AssetCategoriesWithRelationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetCategoriesWithRelationsQuery,
    AssetCategoriesWithRelationsQueryVariables
  >(AssetCategoriesWithRelationsDocument, options);
}

export type AssetCategoriesWithRelationsQueryHookResult = ReturnType<
  typeof useAssetCategoriesWithRelationsQuery
>;
export type AssetCategoriesWithRelationsLazyQueryHookResult = ReturnType<
  typeof useAssetCategoriesWithRelationsLazyQuery
>;
export type AssetCategoriesWithRelationsQueryResult =
  ApolloReactCommon.QueryResult<
    AssetCategoriesWithRelationsQuery,
    AssetCategoriesWithRelationsQueryVariables
  >;
export const CompanyDocument = gql`
  query Company {
    company {
      ...CompanyWithContactUsersFragment
    }
  }
  ${CompanyWithContactUsersFragmentFragmentDoc}
`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyQuery,
    CompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyDocument,
    options
  );
}

export function useCompanyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompanyQuery,
    CompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyDocument,
    options
  );
}

export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = ApolloReactCommon.QueryResult<
  CompanyQuery,
  CompanyQueryVariables
>;
export const FloorPlanSignedUrlDocument = gql`
  query FloorPlanSignedUrl($id: UUID!) {
    floorPlan(id: $id) {
      id
      name
      signedUrl
    }
  }
`;

/**
 * __useFloorPlanSignedUrlQuery__
 *
 * To run a query within a React component, call `useFloorPlanSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanSignedUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFloorPlanSignedUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FloorPlanSignedUrlQuery,
    FloorPlanSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FloorPlanSignedUrlQuery,
    FloorPlanSignedUrlQueryVariables
  >(FloorPlanSignedUrlDocument, options);
}

export function useFloorPlanSignedUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FloorPlanSignedUrlQuery,
    FloorPlanSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FloorPlanSignedUrlQuery,
    FloorPlanSignedUrlQueryVariables
  >(FloorPlanSignedUrlDocument, options);
}

export type FloorPlanSignedUrlQueryHookResult = ReturnType<
  typeof useFloorPlanSignedUrlQuery
>;
export type FloorPlanSignedUrlLazyQueryHookResult = ReturnType<
  typeof useFloorPlanSignedUrlLazyQuery
>;
export type FloorPlanSignedUrlQueryResult = ApolloReactCommon.QueryResult<
  FloorPlanSignedUrlQuery,
  FloorPlanSignedUrlQueryVariables
>;
export const FolderDocument = gql`
  query Folder($id: UUID!) {
    folder(id: $id) {
      ...FolderWithRelationsFragment
    }
  }
  ${FolderWithRelationsFragmentFragmentDoc}
`;

/**
 * __useFolderQuery__
 *
 * To run a query within a React component, call `useFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFolderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FolderQuery,
    FolderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FolderQuery, FolderQueryVariables>(
    FolderDocument,
    options
  );
}

export function useFolderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FolderQuery,
    FolderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FolderQuery, FolderQueryVariables>(
    FolderDocument,
    options
  );
}

export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>;
export type FolderLazyQueryHookResult = ReturnType<typeof useFolderLazyQuery>;
export type FolderQueryResult = ApolloReactCommon.QueryResult<
  FolderQuery,
  FolderQueryVariables
>;
export const FoldersDocument = gql`
  query Folders {
    folders {
      ...FolderWithRelationsFragment
    }
  }
  ${FolderWithRelationsFragmentFragmentDoc}
`;

/**
 * __useFoldersQuery__
 *
 * To run a query within a React component, call `useFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFoldersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FoldersQuery,
    FoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FoldersQuery, FoldersQueryVariables>(
    FoldersDocument,
    options
  );
}

export function useFoldersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FoldersQuery,
    FoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FoldersQuery, FoldersQueryVariables>(
    FoldersDocument,
    options
  );
}

export type FoldersQueryHookResult = ReturnType<typeof useFoldersQuery>;
export type FoldersLazyQueryHookResult = ReturnType<typeof useFoldersLazyQuery>;
export type FoldersQueryResult = ApolloReactCommon.QueryResult<
  FoldersQuery,
  FoldersQueryVariables
>;
export const UserAcceptInviteDocument = gql`
  mutation UserAcceptInvite($data: UserAcceptInviteInput!) {
    userAcceptInvite(data: $data) {
      jwtToken
    }
  }
`;
export type UserAcceptInviteMutationFn = ApolloReactCommon.MutationFunction<
  UserAcceptInviteMutation,
  UserAcceptInviteMutationVariables
>;

/**
 * __useUserAcceptInviteMutation__
 *
 * To run a mutation, you first call `useUserAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAcceptInviteMutation, { data, loading, error }] = useUserAcceptInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserAcceptInviteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserAcceptInviteMutation,
    UserAcceptInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserAcceptInviteMutation,
    UserAcceptInviteMutationVariables
  >(UserAcceptInviteDocument, options);
}

export type UserAcceptInviteMutationHookResult = ReturnType<
  typeof useUserAcceptInviteMutation
>;
export type UserAcceptInviteMutationResult =
  ApolloReactCommon.MutationResult<UserAcceptInviteMutation>;
export type UserAcceptInviteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UserAcceptInviteMutation,
    UserAcceptInviteMutationVariables
  >;
export const AssetCategoryDeleteDocument = gql`
  mutation AssetCategoryDelete($id: UUID!) {
    assetCategoryDelete(id: $id)
  }
`;
export type AssetCategoryDeleteMutationFn = ApolloReactCommon.MutationFunction<
  AssetCategoryDeleteMutation,
  AssetCategoryDeleteMutationVariables
>;

/**
 * __useAssetCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useAssetCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetCategoryDeleteMutation, { data, loading, error }] = useAssetCategoryDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetCategoryDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetCategoryDeleteMutation,
    AssetCategoryDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetCategoryDeleteMutation,
    AssetCategoryDeleteMutationVariables
  >(AssetCategoryDeleteDocument, options);
}

export type AssetCategoryDeleteMutationHookResult = ReturnType<
  typeof useAssetCategoryDeleteMutation
>;
export type AssetCategoryDeleteMutationResult =
  ApolloReactCommon.MutationResult<AssetCategoryDeleteMutation>;
export type AssetCategoryDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetCategoryDeleteMutation,
    AssetCategoryDeleteMutationVariables
  >;
export const CompanyUpdateHiddenCategoryIdsDocument = gql`
  mutation CompanyUpdateHiddenCategoryIds(
    $hiddenCategoryIds: [String!]
    $hiddenTypeIds: [String!]
  ) {
    companyUpdateHiddenCategoryIds(
      hiddenCategoryIds: $hiddenCategoryIds
      hiddenTypeIds: $hiddenTypeIds
    ) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;
export type CompanyUpdateHiddenCategoryIdsMutationFn =
  ApolloReactCommon.MutationFunction<
    CompanyUpdateHiddenCategoryIdsMutation,
    CompanyUpdateHiddenCategoryIdsMutationVariables
  >;

/**
 * __useCompanyUpdateHiddenCategoryIdsMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateHiddenCategoryIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateHiddenCategoryIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateHiddenCategoryIdsMutation, { data, loading, error }] = useCompanyUpdateHiddenCategoryIdsMutation({
 *   variables: {
 *      hiddenCategoryIds: // value for 'hiddenCategoryIds'
 *      hiddenTypeIds: // value for 'hiddenTypeIds'
 *   },
 * });
 */
export function useCompanyUpdateHiddenCategoryIdsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyUpdateHiddenCategoryIdsMutation,
    CompanyUpdateHiddenCategoryIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyUpdateHiddenCategoryIdsMutation,
    CompanyUpdateHiddenCategoryIdsMutationVariables
  >(CompanyUpdateHiddenCategoryIdsDocument, options);
}

export type CompanyUpdateHiddenCategoryIdsMutationHookResult = ReturnType<
  typeof useCompanyUpdateHiddenCategoryIdsMutation
>;
export type CompanyUpdateHiddenCategoryIdsMutationResult =
  ApolloReactCommon.MutationResult<CompanyUpdateHiddenCategoryIdsMutation>;
export type CompanyUpdateHiddenCategoryIdsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyUpdateHiddenCategoryIdsMutation,
    CompanyUpdateHiddenCategoryIdsMutationVariables
  >;
export const AssetTypeDeleteDocument = gql`
  mutation AssetTypeDelete($id: UUID!) {
    assetTypeDelete(id: $id)
  }
`;
export type AssetTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<
  AssetTypeDeleteMutation,
  AssetTypeDeleteMutationVariables
>;

/**
 * __useAssetTypeDeleteMutation__
 *
 * To run a mutation, you first call `useAssetTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetTypeDeleteMutation, { data, loading, error }] = useAssetTypeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetTypeDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetTypeDeleteMutation,
    AssetTypeDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetTypeDeleteMutation,
    AssetTypeDeleteMutationVariables
  >(AssetTypeDeleteDocument, options);
}

export type AssetTypeDeleteMutationHookResult = ReturnType<
  typeof useAssetTypeDeleteMutation
>;
export type AssetTypeDeleteMutationResult =
  ApolloReactCommon.MutationResult<AssetTypeDeleteMutation>;
export type AssetTypeDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetTypeDeleteMutation,
    AssetTypeDeleteMutationVariables
  >;
export const AssetTypeMoveDocument = gql`
  mutation AssetTypeMove($assetCategoryId: UUID!, $ids: [UUID!]!) {
    assetTypeMove(assetCategoryId: $assetCategoryId, ids: $ids)
  }
`;
export type AssetTypeMoveMutationFn = ApolloReactCommon.MutationFunction<
  AssetTypeMoveMutation,
  AssetTypeMoveMutationVariables
>;

/**
 * __useAssetTypeMoveMutation__
 *
 * To run a mutation, you first call `useAssetTypeMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetTypeMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetTypeMoveMutation, { data, loading, error }] = useAssetTypeMoveMutation({
 *   variables: {
 *      assetCategoryId: // value for 'assetCategoryId'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAssetTypeMoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetTypeMoveMutation,
    AssetTypeMoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetTypeMoveMutation,
    AssetTypeMoveMutationVariables
  >(AssetTypeMoveDocument, options);
}

export type AssetTypeMoveMutationHookResult = ReturnType<
  typeof useAssetTypeMoveMutation
>;
export type AssetTypeMoveMutationResult =
  ApolloReactCommon.MutationResult<AssetTypeMoveMutation>;
export type AssetTypeMoveMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetTypeMoveMutation,
    AssetTypeMoveMutationVariables
  >;
export const AssetTypeCreateDocument = gql`
  mutation AssetTypeCreate($data: AssetTypeInput!) {
    assetTypeCreate(data: $data) {
      ...AssetTypeWithRelationsFragment
    }
  }
  ${AssetTypeWithRelationsFragmentFragmentDoc}
`;
export type AssetTypeCreateMutationFn = ApolloReactCommon.MutationFunction<
  AssetTypeCreateMutation,
  AssetTypeCreateMutationVariables
>;

/**
 * __useAssetTypeCreateMutation__
 *
 * To run a mutation, you first call `useAssetTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetTypeCreateMutation, { data, loading, error }] = useAssetTypeCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetTypeCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetTypeCreateMutation,
    AssetTypeCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetTypeCreateMutation,
    AssetTypeCreateMutationVariables
  >(AssetTypeCreateDocument, options);
}

export type AssetTypeCreateMutationHookResult = ReturnType<
  typeof useAssetTypeCreateMutation
>;
export type AssetTypeCreateMutationResult =
  ApolloReactCommon.MutationResult<AssetTypeCreateMutation>;
export type AssetTypeCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetTypeCreateMutation,
    AssetTypeCreateMutationVariables
  >;
export const AssetTypeDocument = gql`
  query AssetType($id: UUID!) {
    assetType(id: $id) {
      ...AssetTypeWithRelationsFragment
    }
  }
  ${AssetTypeWithRelationsFragmentFragmentDoc}
`;

/**
 * __useAssetTypeQuery__
 *
 * To run a query within a React component, call `useAssetTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetTypeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AssetTypeQuery,
    AssetTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssetTypeQuery, AssetTypeQueryVariables>(
    AssetTypeDocument,
    options
  );
}

export function useAssetTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetTypeQuery,
    AssetTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AssetTypeQuery, AssetTypeQueryVariables>(
    AssetTypeDocument,
    options
  );
}

export type AssetTypeQueryHookResult = ReturnType<typeof useAssetTypeQuery>;
export type AssetTypeLazyQueryHookResult = ReturnType<
  typeof useAssetTypeLazyQuery
>;
export type AssetTypeQueryResult = ApolloReactCommon.QueryResult<
  AssetTypeQuery,
  AssetTypeQueryVariables
>;
export const AssetTypeUpdateDocument = gql`
  mutation AssetTypeUpdate($id: UUID!, $data: AssetTypeInput!) {
    assetTypeUpdate(id: $id, data: $data) {
      ...AssetTypeWithRelationsFragment
    }
  }
  ${AssetTypeWithRelationsFragmentFragmentDoc}
`;
export type AssetTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<
  AssetTypeUpdateMutation,
  AssetTypeUpdateMutationVariables
>;

/**
 * __useAssetTypeUpdateMutation__
 *
 * To run a mutation, you first call `useAssetTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetTypeUpdateMutation, { data, loading, error }] = useAssetTypeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetTypeUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetTypeUpdateMutation,
    AssetTypeUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetTypeUpdateMutation,
    AssetTypeUpdateMutationVariables
  >(AssetTypeUpdateDocument, options);
}

export type AssetTypeUpdateMutationHookResult = ReturnType<
  typeof useAssetTypeUpdateMutation
>;
export type AssetTypeUpdateMutationResult =
  ApolloReactCommon.MutationResult<AssetTypeUpdateMutation>;
export type AssetTypeUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetTypeUpdateMutation,
    AssetTypeUpdateMutationVariables
  >;
export const MaintenanceTemplateCreateDocument = gql`
  mutation MaintenanceTemplateCreate($data: MaintenanceTemplateInput!) {
    maintenanceTemplateCreate(data: $data) {
      ...MaintenanceTemplateFragment
    }
  }
  ${MaintenanceTemplateFragmentFragmentDoc}
`;
export type MaintenanceTemplateCreateMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceTemplateCreateMutation,
    MaintenanceTemplateCreateMutationVariables
  >;

/**
 * __useMaintenanceTemplateCreateMutation__
 *
 * To run a mutation, you first call `useMaintenanceTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceTemplateCreateMutation, { data, loading, error }] = useMaintenanceTemplateCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMaintenanceTemplateCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceTemplateCreateMutation,
    MaintenanceTemplateCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceTemplateCreateMutation,
    MaintenanceTemplateCreateMutationVariables
  >(MaintenanceTemplateCreateDocument, options);
}

export type MaintenanceTemplateCreateMutationHookResult = ReturnType<
  typeof useMaintenanceTemplateCreateMutation
>;
export type MaintenanceTemplateCreateMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceTemplateCreateMutation>;
export type MaintenanceTemplateCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceTemplateCreateMutation,
    MaintenanceTemplateCreateMutationVariables
  >;
export const MaintenanceTemplateUpdateDocument = gql`
  mutation MaintenanceTemplateUpdate(
    $id: UUID!
    $data: MaintenanceTemplateInput!
  ) {
    maintenanceTemplateUpdate(id: $id, data: $data) {
      ...MaintenanceTemplateFragment
    }
  }
  ${MaintenanceTemplateFragmentFragmentDoc}
`;
export type MaintenanceTemplateUpdateMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceTemplateUpdateMutation,
    MaintenanceTemplateUpdateMutationVariables
  >;

/**
 * __useMaintenanceTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useMaintenanceTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceTemplateUpdateMutation, { data, loading, error }] = useMaintenanceTemplateUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMaintenanceTemplateUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceTemplateUpdateMutation,
    MaintenanceTemplateUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceTemplateUpdateMutation,
    MaintenanceTemplateUpdateMutationVariables
  >(MaintenanceTemplateUpdateDocument, options);
}

export type MaintenanceTemplateUpdateMutationHookResult = ReturnType<
  typeof useMaintenanceTemplateUpdateMutation
>;
export type MaintenanceTemplateUpdateMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceTemplateUpdateMutation>;
export type MaintenanceTemplateUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceTemplateUpdateMutation,
    MaintenanceTemplateUpdateMutationVariables
  >;
export const MaintenanceTemplateDeleteDocument = gql`
  mutation MaintenanceTemplateDelete($id: UUID!) {
    maintenanceTemplateDelete(id: $id)
  }
`;
export type MaintenanceTemplateDeleteMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceTemplateDeleteMutation,
    MaintenanceTemplateDeleteMutationVariables
  >;

/**
 * __useMaintenanceTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useMaintenanceTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceTemplateDeleteMutation, { data, loading, error }] = useMaintenanceTemplateDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceTemplateDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceTemplateDeleteMutation,
    MaintenanceTemplateDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceTemplateDeleteMutation,
    MaintenanceTemplateDeleteMutationVariables
  >(MaintenanceTemplateDeleteDocument, options);
}

export type MaintenanceTemplateDeleteMutationHookResult = ReturnType<
  typeof useMaintenanceTemplateDeleteMutation
>;
export type MaintenanceTemplateDeleteMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceTemplateDeleteMutation>;
export type MaintenanceTemplateDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceTemplateDeleteMutation,
    MaintenanceTemplateDeleteMutationVariables
  >;
export const AssetCategoryCreateDocument = gql`
  mutation AssetCategoryCreate($data: AssetCategoryInput!) {
    assetCategoryCreate(data: $data) {
      ...AssetCategoryFragment
    }
  }
  ${AssetCategoryFragmentFragmentDoc}
`;
export type AssetCategoryCreateMutationFn = ApolloReactCommon.MutationFunction<
  AssetCategoryCreateMutation,
  AssetCategoryCreateMutationVariables
>;

/**
 * __useAssetCategoryCreateMutation__
 *
 * To run a mutation, you first call `useAssetCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetCategoryCreateMutation, { data, loading, error }] = useAssetCategoryCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetCategoryCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetCategoryCreateMutation,
    AssetCategoryCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetCategoryCreateMutation,
    AssetCategoryCreateMutationVariables
  >(AssetCategoryCreateDocument, options);
}

export type AssetCategoryCreateMutationHookResult = ReturnType<
  typeof useAssetCategoryCreateMutation
>;
export type AssetCategoryCreateMutationResult =
  ApolloReactCommon.MutationResult<AssetCategoryCreateMutation>;
export type AssetCategoryCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetCategoryCreateMutation,
    AssetCategoryCreateMutationVariables
  >;
export const AssetCategoryDocument = gql`
  query AssetCategory($id: UUID!) {
    assetCategory(id: $id) {
      ...AssetCategoryFragment
    }
  }
  ${AssetCategoryFragmentFragmentDoc}
`;

/**
 * __useAssetCategoryQuery__
 *
 * To run a query within a React component, call `useAssetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetCategoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AssetCategoryQuery,
    AssetCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetCategoryQuery,
    AssetCategoryQueryVariables
  >(AssetCategoryDocument, options);
}

export function useAssetCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetCategoryQuery,
    AssetCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetCategoryQuery,
    AssetCategoryQueryVariables
  >(AssetCategoryDocument, options);
}

export type AssetCategoryQueryHookResult = ReturnType<
  typeof useAssetCategoryQuery
>;
export type AssetCategoryLazyQueryHookResult = ReturnType<
  typeof useAssetCategoryLazyQuery
>;
export type AssetCategoryQueryResult = ApolloReactCommon.QueryResult<
  AssetCategoryQuery,
  AssetCategoryQueryVariables
>;
export const AssetCategoryUpdateDocument = gql`
  mutation AssetCategoryUpdate($id: UUID!, $data: AssetCategoryInput!) {
    assetCategoryUpdate(id: $id, data: $data) {
      ...AssetCategoryWithTypesFragment
    }
  }
  ${AssetCategoryWithTypesFragmentFragmentDoc}
`;
export type AssetCategoryUpdateMutationFn = ApolloReactCommon.MutationFunction<
  AssetCategoryUpdateMutation,
  AssetCategoryUpdateMutationVariables
>;

/**
 * __useAssetCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useAssetCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetCategoryUpdateMutation, { data, loading, error }] = useAssetCategoryUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetCategoryUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetCategoryUpdateMutation,
    AssetCategoryUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetCategoryUpdateMutation,
    AssetCategoryUpdateMutationVariables
  >(AssetCategoryUpdateDocument, options);
}

export type AssetCategoryUpdateMutationHookResult = ReturnType<
  typeof useAssetCategoryUpdateMutation
>;
export type AssetCategoryUpdateMutationResult =
  ApolloReactCommon.MutationResult<AssetCategoryUpdateMutation>;
export type AssetCategoryUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetCategoryUpdateMutation,
    AssetCategoryUpdateMutationVariables
  >;
export const AssetFileCreateDocument = gql`
  mutation AssetFileCreate($data: AssetFileCreateInput!) {
    assetFileCreate(data: $data) {
      ...AssetFileFragment
    }
  }
  ${AssetFileFragmentFragmentDoc}
`;
export type AssetFileCreateMutationFn = ApolloReactCommon.MutationFunction<
  AssetFileCreateMutation,
  AssetFileCreateMutationVariables
>;

/**
 * __useAssetFileCreateMutation__
 *
 * To run a mutation, you first call `useAssetFileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetFileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetFileCreateMutation, { data, loading, error }] = useAssetFileCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetFileCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetFileCreateMutation,
    AssetFileCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetFileCreateMutation,
    AssetFileCreateMutationVariables
  >(AssetFileCreateDocument, options);
}

export type AssetFileCreateMutationHookResult = ReturnType<
  typeof useAssetFileCreateMutation
>;
export type AssetFileCreateMutationResult =
  ApolloReactCommon.MutationResult<AssetFileCreateMutation>;
export type AssetFileCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetFileCreateMutation,
    AssetFileCreateMutationVariables
  >;
export const AssetFileDeleteDocument = gql`
  mutation AssetFileDelete($ids: [UUID!]!) {
    assetFileDelete(ids: $ids)
  }
`;
export type AssetFileDeleteMutationFn = ApolloReactCommon.MutationFunction<
  AssetFileDeleteMutation,
  AssetFileDeleteMutationVariables
>;

/**
 * __useAssetFileDeleteMutation__
 *
 * To run a mutation, you first call `useAssetFileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetFileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetFileDeleteMutation, { data, loading, error }] = useAssetFileDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAssetFileDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetFileDeleteMutation,
    AssetFileDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetFileDeleteMutation,
    AssetFileDeleteMutationVariables
  >(AssetFileDeleteDocument, options);
}

export type AssetFileDeleteMutationHookResult = ReturnType<
  typeof useAssetFileDeleteMutation
>;
export type AssetFileDeleteMutationResult =
  ApolloReactCommon.MutationResult<AssetFileDeleteMutation>;
export type AssetFileDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetFileDeleteMutation,
    AssetFileDeleteMutationVariables
  >;
export const AssetFileUpdateDocument = gql`
  mutation AssetFileUpdate($id: UUID!, $data: AssetFileUpdateInput!) {
    assetFileUpdate(id: $id, data: $data) {
      ...AssetFileFragment
    }
  }
  ${AssetFileFragmentFragmentDoc}
`;
export type AssetFileUpdateMutationFn = ApolloReactCommon.MutationFunction<
  AssetFileUpdateMutation,
  AssetFileUpdateMutationVariables
>;

/**
 * __useAssetFileUpdateMutation__
 *
 * To run a mutation, you first call `useAssetFileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetFileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetFileUpdateMutation, { data, loading, error }] = useAssetFileUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetFileUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetFileUpdateMutation,
    AssetFileUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetFileUpdateMutation,
    AssetFileUpdateMutationVariables
  >(AssetFileUpdateDocument, options);
}

export type AssetFileUpdateMutationHookResult = ReturnType<
  typeof useAssetFileUpdateMutation
>;
export type AssetFileUpdateMutationResult =
  ApolloReactCommon.MutationResult<AssetFileUpdateMutation>;
export type AssetFileUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetFileUpdateMutation,
    AssetFileUpdateMutationVariables
  >;
export const AssetCreateDocument = gql`
  mutation AssetCreate($data: [AssetCreateInput!]!) {
    assetCreate(data: $data) {
      ...AssetWithRelationsFragment
    }
  }
  ${AssetWithRelationsFragmentFragmentDoc}
`;
export type AssetCreateMutationFn = ApolloReactCommon.MutationFunction<
  AssetCreateMutation,
  AssetCreateMutationVariables
>;

/**
 * __useAssetCreateMutation__
 *
 * To run a mutation, you first call `useAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetCreateMutation, { data, loading, error }] = useAssetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetCreateMutation,
    AssetCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetCreateMutation,
    AssetCreateMutationVariables
  >(AssetCreateDocument, options);
}

export type AssetCreateMutationHookResult = ReturnType<
  typeof useAssetCreateMutation
>;
export type AssetCreateMutationResult =
  ApolloReactCommon.MutationResult<AssetCreateMutation>;
export type AssetCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssetCreateMutation,
  AssetCreateMutationVariables
>;
export const AssetDeleteDocument = gql`
  mutation AssetDelete($ids: [UUID!]!) {
    assetDelete(ids: $ids)
  }
`;
export type AssetDeleteMutationFn = ApolloReactCommon.MutationFunction<
  AssetDeleteMutation,
  AssetDeleteMutationVariables
>;

/**
 * __useAssetDeleteMutation__
 *
 * To run a mutation, you first call `useAssetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetDeleteMutation, { data, loading, error }] = useAssetDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAssetDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetDeleteMutation,
    AssetDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetDeleteMutation,
    AssetDeleteMutationVariables
  >(AssetDeleteDocument, options);
}

export type AssetDeleteMutationHookResult = ReturnType<
  typeof useAssetDeleteMutation
>;
export type AssetDeleteMutationResult =
  ApolloReactCommon.MutationResult<AssetDeleteMutation>;
export type AssetDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssetDeleteMutation,
  AssetDeleteMutationVariables
>;
export const AssetUpdateDocument = gql`
  mutation AssetUpdate($id: UUID!, $data: AssetUpdateInput!) {
    assetUpdate(id: $id, data: $data) {
      ...AssetWithRelationsFragment
    }
  }
  ${AssetWithRelationsFragmentFragmentDoc}
`;
export type AssetUpdateMutationFn = ApolloReactCommon.MutationFunction<
  AssetUpdateMutation,
  AssetUpdateMutationVariables
>;

/**
 * __useAssetUpdateMutation__
 *
 * To run a mutation, you first call `useAssetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetUpdateMutation, { data, loading, error }] = useAssetUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetUpdateMutation,
    AssetUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetUpdateMutation,
    AssetUpdateMutationVariables
  >(AssetUpdateDocument, options);
}

export type AssetUpdateMutationHookResult = ReturnType<
  typeof useAssetUpdateMutation
>;
export type AssetUpdateMutationResult =
  ApolloReactCommon.MutationResult<AssetUpdateMutation>;
export type AssetUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssetUpdateMutation,
  AssetUpdateMutationVariables
>;
export const AssetsDocument = gql`
  query Assets {
    assets {
      ...AssetWithRelationsFragment
    }
  }
  ${AssetWithRelationsFragmentFragmentDoc}
`;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetsQuery,
    AssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssetsQuery, AssetsQueryVariables>(
    AssetsDocument,
    options
  );
}

export function useAssetsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetsQuery,
    AssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AssetsQuery, AssetsQueryVariables>(
    AssetsDocument,
    options
  );
}

export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = ApolloReactCommon.QueryResult<
  AssetsQuery,
  AssetsQueryVariables
>;
export const AssetIndexDocument = gql`
  query AssetIndex {
    assets {
      ...AssetIndexFragment
    }
  }
  ${AssetIndexFragmentFragmentDoc}
`;

/**
 * __useAssetIndexQuery__
 *
 * To run a query within a React component, call `useAssetIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetIndexQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssetIndexQuery,
    AssetIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssetIndexQuery, AssetIndexQueryVariables>(
    AssetIndexDocument,
    options
  );
}

export function useAssetIndexLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetIndexQuery,
    AssetIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetIndexQuery,
    AssetIndexQueryVariables
  >(AssetIndexDocument, options);
}

export type AssetIndexQueryHookResult = ReturnType<typeof useAssetIndexQuery>;
export type AssetIndexLazyQueryHookResult = ReturnType<
  typeof useAssetIndexLazyQuery
>;
export type AssetIndexQueryResult = ApolloReactCommon.QueryResult<
  AssetIndexQuery,
  AssetIndexQueryVariables
>;
export const AssetAffectedAssetDeleteDocument = gql`
  mutation AssetAffectedAssetDelete($id: UUID!) {
    assetAffectedAssetDelete(id: $id)
  }
`;
export type AssetAffectedAssetDeleteMutationFn =
  ApolloReactCommon.MutationFunction<
    AssetAffectedAssetDeleteMutation,
    AssetAffectedAssetDeleteMutationVariables
  >;

/**
 * __useAssetAffectedAssetDeleteMutation__
 *
 * To run a mutation, you first call `useAssetAffectedAssetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetAffectedAssetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetAffectedAssetDeleteMutation, { data, loading, error }] = useAssetAffectedAssetDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetAffectedAssetDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetAffectedAssetDeleteMutation,
    AssetAffectedAssetDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetAffectedAssetDeleteMutation,
    AssetAffectedAssetDeleteMutationVariables
  >(AssetAffectedAssetDeleteDocument, options);
}

export type AssetAffectedAssetDeleteMutationHookResult = ReturnType<
  typeof useAssetAffectedAssetDeleteMutation
>;
export type AssetAffectedAssetDeleteMutationResult =
  ApolloReactCommon.MutationResult<AssetAffectedAssetDeleteMutation>;
export type AssetAffectedAssetDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetAffectedAssetDeleteMutation,
    AssetAffectedAssetDeleteMutationVariables
  >;
export const AssetAffectedAssetCreateDocument = gql`
  mutation AssetAffectedAssetCreate($data: [AssetAffectedAssetCreateInput!]!) {
    assetAffectedAssetCreate(data: $data)
  }
`;
export type AssetAffectedAssetCreateMutationFn =
  ApolloReactCommon.MutationFunction<
    AssetAffectedAssetCreateMutation,
    AssetAffectedAssetCreateMutationVariables
  >;

/**
 * __useAssetAffectedAssetCreateMutation__
 *
 * To run a mutation, you first call `useAssetAffectedAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetAffectedAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetAffectedAssetCreateMutation, { data, loading, error }] = useAssetAffectedAssetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetAffectedAssetCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetAffectedAssetCreateMutation,
    AssetAffectedAssetCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetAffectedAssetCreateMutation,
    AssetAffectedAssetCreateMutationVariables
  >(AssetAffectedAssetCreateDocument, options);
}

export type AssetAffectedAssetCreateMutationHookResult = ReturnType<
  typeof useAssetAffectedAssetCreateMutation
>;
export type AssetAffectedAssetCreateMutationResult =
  ApolloReactCommon.MutationResult<AssetAffectedAssetCreateMutation>;
export type AssetAffectedAssetCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetAffectedAssetCreateMutation,
    AssetAffectedAssetCreateMutationVariables
  >;
export const AssetPartCreateDocument = gql`
  mutation AssetPartCreate($data: [AssetPartCreateInput!]!) {
    assetPartCreate(data: $data) {
      ...AssetPartWithRelationsFragment
    }
  }
  ${AssetPartWithRelationsFragmentFragmentDoc}
`;
export type AssetPartCreateMutationFn = ApolloReactCommon.MutationFunction<
  AssetPartCreateMutation,
  AssetPartCreateMutationVariables
>;

/**
 * __useAssetPartCreateMutation__
 *
 * To run a mutation, you first call `useAssetPartCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetPartCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetPartCreateMutation, { data, loading, error }] = useAssetPartCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetPartCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetPartCreateMutation,
    AssetPartCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetPartCreateMutation,
    AssetPartCreateMutationVariables
  >(AssetPartCreateDocument, options);
}

export type AssetPartCreateMutationHookResult = ReturnType<
  typeof useAssetPartCreateMutation
>;
export type AssetPartCreateMutationResult =
  ApolloReactCommon.MutationResult<AssetPartCreateMutation>;
export type AssetPartCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetPartCreateMutation,
    AssetPartCreateMutationVariables
  >;
export const AssetPartUpdateDocument = gql`
  mutation AssetPartUpdate($data: [AssetPartUpdateInput!]!) {
    assetPartUpdate(data: $data) {
      ...AssetPartWithRelationsFragment
    }
  }
  ${AssetPartWithRelationsFragmentFragmentDoc}
`;
export type AssetPartUpdateMutationFn = ApolloReactCommon.MutationFunction<
  AssetPartUpdateMutation,
  AssetPartUpdateMutationVariables
>;

/**
 * __useAssetPartUpdateMutation__
 *
 * To run a mutation, you first call `useAssetPartUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetPartUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetPartUpdateMutation, { data, loading, error }] = useAssetPartUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssetPartUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetPartUpdateMutation,
    AssetPartUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetPartUpdateMutation,
    AssetPartUpdateMutationVariables
  >(AssetPartUpdateDocument, options);
}

export type AssetPartUpdateMutationHookResult = ReturnType<
  typeof useAssetPartUpdateMutation
>;
export type AssetPartUpdateMutationResult =
  ApolloReactCommon.MutationResult<AssetPartUpdateMutation>;
export type AssetPartUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetPartUpdateMutation,
    AssetPartUpdateMutationVariables
  >;
export const AssetPartDeleteDocument = gql`
  mutation AssetPartDelete($ids: [UUID!]!) {
    assetPartDelete(ids: $ids)
  }
`;
export type AssetPartDeleteMutationFn = ApolloReactCommon.MutationFunction<
  AssetPartDeleteMutation,
  AssetPartDeleteMutationVariables
>;

/**
 * __useAssetPartDeleteMutation__
 *
 * To run a mutation, you first call `useAssetPartDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetPartDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetPartDeleteMutation, { data, loading, error }] = useAssetPartDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAssetPartDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetPartDeleteMutation,
    AssetPartDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetPartDeleteMutation,
    AssetPartDeleteMutationVariables
  >(AssetPartDeleteDocument, options);
}

export type AssetPartDeleteMutationHookResult = ReturnType<
  typeof useAssetPartDeleteMutation
>;
export type AssetPartDeleteMutationResult =
  ApolloReactCommon.MutationResult<AssetPartDeleteMutation>;
export type AssetPartDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetPartDeleteMutation,
    AssetPartDeleteMutationVariables
  >;
export const AssetFileChangeOrderDocument = gql`
  mutation AssetFileChangeOrder($assetFileIds: [UUID!]!) {
    assetFileChangeOrder(assetFileIds: $assetFileIds) {
      ...AssetFileFragment
    }
  }
  ${AssetFileFragmentFragmentDoc}
`;
export type AssetFileChangeOrderMutationFn = ApolloReactCommon.MutationFunction<
  AssetFileChangeOrderMutation,
  AssetFileChangeOrderMutationVariables
>;

/**
 * __useAssetFileChangeOrderMutation__
 *
 * To run a mutation, you first call `useAssetFileChangeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetFileChangeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetFileChangeOrderMutation, { data, loading, error }] = useAssetFileChangeOrderMutation({
 *   variables: {
 *      assetFileIds: // value for 'assetFileIds'
 *   },
 * });
 */
export function useAssetFileChangeOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetFileChangeOrderMutation,
    AssetFileChangeOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetFileChangeOrderMutation,
    AssetFileChangeOrderMutationVariables
  >(AssetFileChangeOrderDocument, options);
}

export type AssetFileChangeOrderMutationHookResult = ReturnType<
  typeof useAssetFileChangeOrderMutation
>;
export type AssetFileChangeOrderMutationResult =
  ApolloReactCommon.MutationResult<AssetFileChangeOrderMutation>;
export type AssetFileChangeOrderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetFileChangeOrderMutation,
    AssetFileChangeOrderMutationVariables
  >;
export const AssetUpdateConfigDocument = gql`
  mutation AssetUpdateConfig($id: UUID!, $config: JSONObject!) {
    assetUpdateConfig(id: $id, config: $config) {
      ...AssetFragment
    }
  }
  ${AssetFragmentFragmentDoc}
`;
export type AssetUpdateConfigMutationFn = ApolloReactCommon.MutationFunction<
  AssetUpdateConfigMutation,
  AssetUpdateConfigMutationVariables
>;

/**
 * __useAssetUpdateConfigMutation__
 *
 * To run a mutation, you first call `useAssetUpdateConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetUpdateConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetUpdateConfigMutation, { data, loading, error }] = useAssetUpdateConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useAssetUpdateConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssetUpdateConfigMutation,
    AssetUpdateConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssetUpdateConfigMutation,
    AssetUpdateConfigMutationVariables
  >(AssetUpdateConfigDocument, options);
}

export type AssetUpdateConfigMutationHookResult = ReturnType<
  typeof useAssetUpdateConfigMutation
>;
export type AssetUpdateConfigMutationResult =
  ApolloReactCommon.MutationResult<AssetUpdateConfigMutation>;
export type AssetUpdateConfigMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AssetUpdateConfigMutation,
    AssetUpdateConfigMutationVariables
  >;
export const FloorPlanAssetDeleteDocument = gql`
  mutation FloorPlanAssetDelete($id: UUID!) {
    floorPlanAssetDelete(id: $id)
  }
`;
export type FloorPlanAssetDeleteMutationFn = ApolloReactCommon.MutationFunction<
  FloorPlanAssetDeleteMutation,
  FloorPlanAssetDeleteMutationVariables
>;

/**
 * __useFloorPlanAssetDeleteMutation__
 *
 * To run a mutation, you first call `useFloorPlanAssetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanAssetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanAssetDeleteMutation, { data, loading, error }] = useFloorPlanAssetDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFloorPlanAssetDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FloorPlanAssetDeleteMutation,
    FloorPlanAssetDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FloorPlanAssetDeleteMutation,
    FloorPlanAssetDeleteMutationVariables
  >(FloorPlanAssetDeleteDocument, options);
}

export type FloorPlanAssetDeleteMutationHookResult = ReturnType<
  typeof useFloorPlanAssetDeleteMutation
>;
export type FloorPlanAssetDeleteMutationResult =
  ApolloReactCommon.MutationResult<FloorPlanAssetDeleteMutation>;
export type FloorPlanAssetDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FloorPlanAssetDeleteMutation,
    FloorPlanAssetDeleteMutationVariables
  >;
export const FloorPlanAssetCreateDocument = gql`
  mutation FloorPlanAssetCreate($data: [FloorPlanAssetCreateInput!]!) {
    floorPlanAssetCreate(data: $data)
  }
`;
export type FloorPlanAssetCreateMutationFn = ApolloReactCommon.MutationFunction<
  FloorPlanAssetCreateMutation,
  FloorPlanAssetCreateMutationVariables
>;

/**
 * __useFloorPlanAssetCreateMutation__
 *
 * To run a mutation, you first call `useFloorPlanAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanAssetCreateMutation, { data, loading, error }] = useFloorPlanAssetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFloorPlanAssetCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FloorPlanAssetCreateMutation,
    FloorPlanAssetCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FloorPlanAssetCreateMutation,
    FloorPlanAssetCreateMutationVariables
  >(FloorPlanAssetCreateDocument, options);
}

export type FloorPlanAssetCreateMutationHookResult = ReturnType<
  typeof useFloorPlanAssetCreateMutation
>;
export type FloorPlanAssetCreateMutationResult =
  ApolloReactCommon.MutationResult<FloorPlanAssetCreateMutation>;
export type FloorPlanAssetCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FloorPlanAssetCreateMutation,
    FloorPlanAssetCreateMutationVariables
  >;
export const AssetDocument = gql`
  query Asset($id: UUID!, $companyId: UUID) {
    asset(id: $id, companyId: $companyId) {
      ...AssetWithRelationsFragment
    }
  }
  ${AssetWithRelationsFragmentFragmentDoc}
`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AssetQuery,
    AssetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssetQuery, AssetQueryVariables>(
    AssetDocument,
    options
  );
}

export function useAssetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetQuery,
    AssetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AssetQuery, AssetQueryVariables>(
    AssetDocument,
    options
  );
}

export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetQueryResult = ApolloReactCommon.QueryResult<
  AssetQuery,
  AssetQueryVariables
>;
export const AssetMaintenancesDocument = gql`
  query AssetMaintenances(
    $id: UUID!
    $startDate: Timestamp
    $endDate: Timestamp
  ) {
    asset(id: $id) {
      id
      name
      maintenances {
        ...MaintenanceIndexFragment
      }
    }
  }
  ${MaintenanceIndexFragmentFragmentDoc}
`;

/**
 * __useAssetMaintenancesQuery__
 *
 * To run a query within a React component, call `useAssetMaintenancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetMaintenancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetMaintenancesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAssetMaintenancesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AssetMaintenancesQuery,
    AssetMaintenancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AssetMaintenancesQuery,
    AssetMaintenancesQueryVariables
  >(AssetMaintenancesDocument, options);
}

export function useAssetMaintenancesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssetMaintenancesQuery,
    AssetMaintenancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AssetMaintenancesQuery,
    AssetMaintenancesQueryVariables
  >(AssetMaintenancesDocument, options);
}

export type AssetMaintenancesQueryHookResult = ReturnType<
  typeof useAssetMaintenancesQuery
>;
export type AssetMaintenancesLazyQueryHookResult = ReturnType<
  typeof useAssetMaintenancesLazyQuery
>;
export type AssetMaintenancesQueryResult = ApolloReactCommon.QueryResult<
  AssetMaintenancesQuery,
  AssetMaintenancesQueryVariables
>;
export const StripeInvoicesDocument = gql`
  query StripeInvoices {
    stripeInvoices {
      ...StripeInvoiceFragment
    }
  }
  ${StripeInvoiceFragmentFragmentDoc}
`;

/**
 * __useStripeInvoicesQuery__
 *
 * To run a query within a React component, call `useStripeInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StripeInvoicesQuery,
    StripeInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StripeInvoicesQuery,
    StripeInvoicesQueryVariables
  >(StripeInvoicesDocument, options);
}

export function useStripeInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripeInvoicesQuery,
    StripeInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StripeInvoicesQuery,
    StripeInvoicesQueryVariables
  >(StripeInvoicesDocument, options);
}

export type StripeInvoicesQueryHookResult = ReturnType<
  typeof useStripeInvoicesQuery
>;
export type StripeInvoicesLazyQueryHookResult = ReturnType<
  typeof useStripeInvoicesLazyQuery
>;
export type StripeInvoicesQueryResult = ApolloReactCommon.QueryResult<
  StripeInvoicesQuery,
  StripeInvoicesQueryVariables
>;
export const StripeCustomerDocument = gql`
  query StripeCustomer {
    stripeCustomer {
      ...StripeCustomerFragment
    }
  }
  ${StripeCustomerFragmentFragmentDoc}
`;

/**
 * __useStripeCustomerQuery__
 *
 * To run a query within a React component, call `useStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeCustomerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StripeCustomerQuery,
    StripeCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StripeCustomerQuery,
    StripeCustomerQueryVariables
  >(StripeCustomerDocument, options);
}

export function useStripeCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripeCustomerQuery,
    StripeCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StripeCustomerQuery,
    StripeCustomerQueryVariables
  >(StripeCustomerDocument, options);
}

export type StripeCustomerQueryHookResult = ReturnType<
  typeof useStripeCustomerQuery
>;
export type StripeCustomerLazyQueryHookResult = ReturnType<
  typeof useStripeCustomerLazyQuery
>;
export type StripeCustomerQueryResult = ApolloReactCommon.QueryResult<
  StripeCustomerQuery,
  StripeCustomerQueryVariables
>;
export const StripeInvoiceUpcomingDocument = gql`
  query StripeInvoiceUpcoming {
    stripeInvoiceUpcoming {
      ...StripeInvoiceFragment
    }
  }
  ${StripeInvoiceFragmentFragmentDoc}
`;

/**
 * __useStripeInvoiceUpcomingQuery__
 *
 * To run a query within a React component, call `useStripeInvoiceUpcomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeInvoiceUpcomingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeInvoiceUpcomingQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeInvoiceUpcomingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StripeInvoiceUpcomingQuery,
    StripeInvoiceUpcomingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StripeInvoiceUpcomingQuery,
    StripeInvoiceUpcomingQueryVariables
  >(StripeInvoiceUpcomingDocument, options);
}

export function useStripeInvoiceUpcomingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripeInvoiceUpcomingQuery,
    StripeInvoiceUpcomingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StripeInvoiceUpcomingQuery,
    StripeInvoiceUpcomingQueryVariables
  >(StripeInvoiceUpcomingDocument, options);
}

export type StripeInvoiceUpcomingQueryHookResult = ReturnType<
  typeof useStripeInvoiceUpcomingQuery
>;
export type StripeInvoiceUpcomingLazyQueryHookResult = ReturnType<
  typeof useStripeInvoiceUpcomingLazyQuery
>;
export type StripeInvoiceUpcomingQueryResult = ApolloReactCommon.QueryResult<
  StripeInvoiceUpcomingQuery,
  StripeInvoiceUpcomingQueryVariables
>;
export const StripeSubscriptionDeleteDocument = gql`
  mutation StripeSubscriptionDelete($passwordRaw: String!) {
    stripeSubscriptionDelete(passwordRaw: $passwordRaw) {
      ...StripeSubscriptionFragment
    }
  }
  ${StripeSubscriptionFragmentFragmentDoc}
`;
export type StripeSubscriptionDeleteMutationFn =
  ApolloReactCommon.MutationFunction<
    StripeSubscriptionDeleteMutation,
    StripeSubscriptionDeleteMutationVariables
  >;

/**
 * __useStripeSubscriptionDeleteMutation__
 *
 * To run a mutation, you first call `useStripeSubscriptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeSubscriptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeSubscriptionDeleteMutation, { data, loading, error }] = useStripeSubscriptionDeleteMutation({
 *   variables: {
 *      passwordRaw: // value for 'passwordRaw'
 *   },
 * });
 */
export function useStripeSubscriptionDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripeSubscriptionDeleteMutation,
    StripeSubscriptionDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripeSubscriptionDeleteMutation,
    StripeSubscriptionDeleteMutationVariables
  >(StripeSubscriptionDeleteDocument, options);
}

export type StripeSubscriptionDeleteMutationHookResult = ReturnType<
  typeof useStripeSubscriptionDeleteMutation
>;
export type StripeSubscriptionDeleteMutationResult =
  ApolloReactCommon.MutationResult<StripeSubscriptionDeleteMutation>;
export type StripeSubscriptionDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripeSubscriptionDeleteMutation,
    StripeSubscriptionDeleteMutationVariables
  >;
export const CompanyCreateDocument = gql`
  mutation CompanyCreate($data: CompanyInput!) {
    companyCreate(data: $data) {
      ...CompanyWithContactUsersFragment
    }
  }
  ${CompanyWithContactUsersFragmentFragmentDoc}
`;
export type CompanyCreateMutationFn = ApolloReactCommon.MutationFunction<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >(CompanyCreateDocument, options);
}

export type CompanyCreateMutationHookResult = ReturnType<
  typeof useCompanyCreateMutation
>;
export type CompanyCreateMutationResult =
  ApolloReactCommon.MutationResult<CompanyCreateMutation>;
export type CompanyCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >;
export const StripeProrationAmountDocument = gql`
  query StripeProrationAmount($newPlan: String, $newQuantity: Float) {
    stripeProrationAmount(newPlan: $newPlan, newQuantity: $newQuantity) {
      amount
      dueDate
    }
  }
`;

/**
 * __useStripeProrationAmountQuery__
 *
 * To run a query within a React component, call `useStripeProrationAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeProrationAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeProrationAmountQuery({
 *   variables: {
 *      newPlan: // value for 'newPlan'
 *      newQuantity: // value for 'newQuantity'
 *   },
 * });
 */
export function useStripeProrationAmountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StripeProrationAmountQuery,
    StripeProrationAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StripeProrationAmountQuery,
    StripeProrationAmountQueryVariables
  >(StripeProrationAmountDocument, options);
}

export function useStripeProrationAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripeProrationAmountQuery,
    StripeProrationAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StripeProrationAmountQuery,
    StripeProrationAmountQueryVariables
  >(StripeProrationAmountDocument, options);
}

export type StripeProrationAmountQueryHookResult = ReturnType<
  typeof useStripeProrationAmountQuery
>;
export type StripeProrationAmountLazyQueryHookResult = ReturnType<
  typeof useStripeProrationAmountLazyQuery
>;
export type StripeProrationAmountQueryResult = ApolloReactCommon.QueryResult<
  StripeProrationAmountQuery,
  StripeProrationAmountQueryVariables
>;
export const StripeNewPlanAmountDocument = gql`
  query StripeNewPlanAmount($newPlan: String!) {
    stripeNewPlanAmount(newPlan: $newPlan)
  }
`;

/**
 * __useStripeNewPlanAmountQuery__
 *
 * To run a query within a React component, call `useStripeNewPlanAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeNewPlanAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeNewPlanAmountQuery({
 *   variables: {
 *      newPlan: // value for 'newPlan'
 *   },
 * });
 */
export function useStripeNewPlanAmountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    StripeNewPlanAmountQuery,
    StripeNewPlanAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StripeNewPlanAmountQuery,
    StripeNewPlanAmountQueryVariables
  >(StripeNewPlanAmountDocument, options);
}

export function useStripeNewPlanAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripeNewPlanAmountQuery,
    StripeNewPlanAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StripeNewPlanAmountQuery,
    StripeNewPlanAmountQueryVariables
  >(StripeNewPlanAmountDocument, options);
}

export type StripeNewPlanAmountQueryHookResult = ReturnType<
  typeof useStripeNewPlanAmountQuery
>;
export type StripeNewPlanAmountLazyQueryHookResult = ReturnType<
  typeof useStripeNewPlanAmountLazyQuery
>;
export type StripeNewPlanAmountQueryResult = ApolloReactCommon.QueryResult<
  StripeNewPlanAmountQuery,
  StripeNewPlanAmountQueryVariables
>;
export const StripeCouponDocument = gql`
  mutation StripeCoupon($code: String!) {
    stripeCoupon(code: $code) {
      ...StripeCouponFragment
    }
  }
  ${StripeCouponFragmentFragmentDoc}
`;
export type StripeCouponMutationFn = ApolloReactCommon.MutationFunction<
  StripeCouponMutation,
  StripeCouponMutationVariables
>;

/**
 * __useStripeCouponMutation__
 *
 * To run a mutation, you first call `useStripeCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCouponMutation, { data, loading, error }] = useStripeCouponMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useStripeCouponMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripeCouponMutation,
    StripeCouponMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripeCouponMutation,
    StripeCouponMutationVariables
  >(StripeCouponDocument, options);
}

export type StripeCouponMutationHookResult = ReturnType<
  typeof useStripeCouponMutation
>;
export type StripeCouponMutationResult =
  ApolloReactCommon.MutationResult<StripeCouponMutation>;
export type StripeCouponMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StripeCouponMutation,
  StripeCouponMutationVariables
>;
export const PlansDocument = gql`
  query Plans {
    plans {
      ...PlansFragment
    }
  }
  ${PlansFragmentFragmentDoc}
`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PlansQuery,
    PlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PlansQuery, PlansQueryVariables>(
    PlansDocument,
    options
  );
}

export function usePlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlansQuery,
    PlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PlansQuery, PlansQueryVariables>(
    PlansDocument,
    options
  );
}

export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = ApolloReactCommon.QueryResult<
  PlansQuery,
  PlansQueryVariables
>;
export const StripeSubscriptionCreateOrUpdateDocument = gql`
  mutation StripeSubscriptionCreateOrUpdate(
    $planId: String!
    $couponCode: String
  ) {
    stripeSubscriptionCreateOrUpdate(planId: $planId, couponCode: $couponCode) {
      ...StripeSubscriptionFragment
    }
  }
  ${StripeSubscriptionFragmentFragmentDoc}
`;
export type StripeSubscriptionCreateOrUpdateMutationFn =
  ApolloReactCommon.MutationFunction<
    StripeSubscriptionCreateOrUpdateMutation,
    StripeSubscriptionCreateOrUpdateMutationVariables
  >;

/**
 * __useStripeSubscriptionCreateOrUpdateMutation__
 *
 * To run a mutation, you first call `useStripeSubscriptionCreateOrUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeSubscriptionCreateOrUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeSubscriptionCreateOrUpdateMutation, { data, loading, error }] = useStripeSubscriptionCreateOrUpdateMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useStripeSubscriptionCreateOrUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripeSubscriptionCreateOrUpdateMutation,
    StripeSubscriptionCreateOrUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StripeSubscriptionCreateOrUpdateMutation,
    StripeSubscriptionCreateOrUpdateMutationVariables
  >(StripeSubscriptionCreateOrUpdateDocument, options);
}

export type StripeSubscriptionCreateOrUpdateMutationHookResult = ReturnType<
  typeof useStripeSubscriptionCreateOrUpdateMutation
>;
export type StripeSubscriptionCreateOrUpdateMutationResult =
  ApolloReactCommon.MutationResult<StripeSubscriptionCreateOrUpdateMutation>;
export type StripeSubscriptionCreateOrUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StripeSubscriptionCreateOrUpdateMutation,
    StripeSubscriptionCreateOrUpdateMutationVariables
  >;
export const CompanyDeleteDocument = gql`
  mutation CompanyDelete {
    companyDelete
  }
`;
export type CompanyDeleteMutationFn = ApolloReactCommon.MutationFunction<
  CompanyDeleteMutation,
  CompanyDeleteMutationVariables
>;

/**
 * __useCompanyDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDeleteMutation, { data, loading, error }] = useCompanyDeleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompanyDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyDeleteMutation,
    CompanyDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyDeleteMutation,
    CompanyDeleteMutationVariables
  >(CompanyDeleteDocument, options);
}

export type CompanyDeleteMutationHookResult = ReturnType<
  typeof useCompanyDeleteMutation
>;
export type CompanyDeleteMutationResult =
  ApolloReactCommon.MutationResult<CompanyDeleteMutation>;
export type CompanyDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyDeleteMutation,
    CompanyDeleteMutationVariables
  >;
export const CompanyUpdateDocument = gql`
  mutation CompanyUpdate($data: CompanyInput!) {
    companyUpdate(data: $data) {
      ...CompanyWithContactUsersFragment
    }
  }
  ${CompanyWithContactUsersFragmentFragmentDoc}
`;
export type CompanyUpdateMutationFn = ApolloReactCommon.MutationFunction<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >(CompanyUpdateDocument, options);
}

export type CompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyUpdateMutation
>;
export type CompanyUpdateMutationResult =
  ApolloReactCommon.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >;
export const CurrentMaintenanceReportByCategoryDocument = gql`
  query CurrentMaintenanceReportByCategory {
    currentMaintenanceReportByCategory {
      ...CategoriesSummaryFragment
    }
  }
  ${CategoriesSummaryFragmentFragmentDoc}
`;

/**
 * __useCurrentMaintenanceReportByCategoryQuery__
 *
 * To run a query within a React component, call `useCurrentMaintenanceReportByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentMaintenanceReportByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentMaintenanceReportByCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentMaintenanceReportByCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentMaintenanceReportByCategoryQuery,
    CurrentMaintenanceReportByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CurrentMaintenanceReportByCategoryQuery,
    CurrentMaintenanceReportByCategoryQueryVariables
  >(CurrentMaintenanceReportByCategoryDocument, options);
}

export function useCurrentMaintenanceReportByCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentMaintenanceReportByCategoryQuery,
    CurrentMaintenanceReportByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrentMaintenanceReportByCategoryQuery,
    CurrentMaintenanceReportByCategoryQueryVariables
  >(CurrentMaintenanceReportByCategoryDocument, options);
}

export type CurrentMaintenanceReportByCategoryQueryHookResult = ReturnType<
  typeof useCurrentMaintenanceReportByCategoryQuery
>;
export type CurrentMaintenanceReportByCategoryLazyQueryHookResult = ReturnType<
  typeof useCurrentMaintenanceReportByCategoryLazyQuery
>;
export type CurrentMaintenanceReportByCategoryQueryResult =
  ApolloReactCommon.QueryResult<
    CurrentMaintenanceReportByCategoryQuery,
    CurrentMaintenanceReportByCategoryQueryVariables
  >;
export const MaintenanceReportDocument = gql`
  query MaintenanceReport($startDate: Date!, $numberOfDays: Float) {
    maintenanceReport(startDate: $startDate, numberOfDays: $numberOfDays) {
      ...MaintenanceReportFragment
    }
  }
  ${MaintenanceReportFragmentFragmentDoc}
`;

/**
 * __useMaintenanceReportQuery__
 *
 * To run a query within a React component, call `useMaintenanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      numberOfDays: // value for 'numberOfDays'
 *   },
 * });
 */
export function useMaintenanceReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MaintenanceReportQuery,
    MaintenanceReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MaintenanceReportQuery,
    MaintenanceReportQueryVariables
  >(MaintenanceReportDocument, options);
}

export function useMaintenanceReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaintenanceReportQuery,
    MaintenanceReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaintenanceReportQuery,
    MaintenanceReportQueryVariables
  >(MaintenanceReportDocument, options);
}

export type MaintenanceReportQueryHookResult = ReturnType<
  typeof useMaintenanceReportQuery
>;
export type MaintenanceReportLazyQueryHookResult = ReturnType<
  typeof useMaintenanceReportLazyQuery
>;
export type MaintenanceReportQueryResult = ApolloReactCommon.QueryResult<
  MaintenanceReportQuery,
  MaintenanceReportQueryVariables
>;
export const UserEmailVerifyDocument = gql`
  mutation userEmailVerify($data: UserEmailVerifyInput!) {
    userEmailVerify(data: $data)
  }
`;
export type UserEmailVerifyMutationFn = ApolloReactCommon.MutationFunction<
  UserEmailVerifyMutation,
  UserEmailVerifyMutationVariables
>;

/**
 * __useUserEmailVerifyMutation__
 *
 * To run a mutation, you first call `useUserEmailVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEmailVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEmailVerifyMutation, { data, loading, error }] = useUserEmailVerifyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserEmailVerifyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserEmailVerifyMutation,
    UserEmailVerifyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserEmailVerifyMutation,
    UserEmailVerifyMutationVariables
  >(UserEmailVerifyDocument, options);
}

export type UserEmailVerifyMutationHookResult = ReturnType<
  typeof useUserEmailVerifyMutation
>;
export type UserEmailVerifyMutationResult =
  ApolloReactCommon.MutationResult<UserEmailVerifyMutation>;
export type UserEmailVerifyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UserEmailVerifyMutation,
    UserEmailVerifyMutationVariables
  >;
export const FileDocument = gql`
  query File($id: UUID!) {
    file(id: $id) {
      ...FileFragment
    }
  }
  ${FileFragmentFragmentDoc}
`;

/**
 * __useFileQuery__
 *
 * To run a query within a React component, call `useFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<FileQuery, FileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FileQuery, FileQueryVariables>(
    FileDocument,
    options
  );
}

export function useFileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FileQuery,
    FileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FileQuery, FileQueryVariables>(
    FileDocument,
    options
  );
}

export type FileQueryHookResult = ReturnType<typeof useFileQuery>;
export type FileLazyQueryHookResult = ReturnType<typeof useFileLazyQuery>;
export type FileQueryResult = ApolloReactCommon.QueryResult<
  FileQuery,
  FileQueryVariables
>;
export const FileDeleteDocument = gql`
  mutation FileDelete($ids: [UUID!]!) {
    fileDelete(ids: $ids)
  }
`;
export type FileDeleteMutationFn = ApolloReactCommon.MutationFunction<
  FileDeleteMutation,
  FileDeleteMutationVariables
>;

/**
 * __useFileDeleteMutation__
 *
 * To run a mutation, you first call `useFileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileDeleteMutation, { data, loading, error }] = useFileDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFileDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FileDeleteMutation,
    FileDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FileDeleteMutation,
    FileDeleteMutationVariables
  >(FileDeleteDocument, options);
}

export type FileDeleteMutationHookResult = ReturnType<
  typeof useFileDeleteMutation
>;
export type FileDeleteMutationResult =
  ApolloReactCommon.MutationResult<FileDeleteMutation>;
export type FileDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FileDeleteMutation,
  FileDeleteMutationVariables
>;
export const FileUpdateDocument = gql`
  mutation FileUpdate($id: UUID!, $data: FileUpdateInput!) {
    fileUpdate(id: $id, data: $data) {
      ...FileFragment
    }
  }
  ${FileFragmentFragmentDoc}
`;
export type FileUpdateMutationFn = ApolloReactCommon.MutationFunction<
  FileUpdateMutation,
  FileUpdateMutationVariables
>;

/**
 * __useFileUpdateMutation__
 *
 * To run a mutation, you first call `useFileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUpdateMutation, { data, loading, error }] = useFileUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFileUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FileUpdateMutation,
    FileUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FileUpdateMutation,
    FileUpdateMutationVariables
  >(FileUpdateDocument, options);
}

export type FileUpdateMutationHookResult = ReturnType<
  typeof useFileUpdateMutation
>;
export type FileUpdateMutationResult =
  ApolloReactCommon.MutationResult<FileUpdateMutation>;
export type FileUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FileUpdateMutation,
  FileUpdateMutationVariables
>;
export const FileMoveDocument = gql`
  mutation FileMove($folderId: UUID!, $ids: [UUID!]!) {
    fileMove(folderId: $folderId, ids: $ids)
  }
`;
export type FileMoveMutationFn = ApolloReactCommon.MutationFunction<
  FileMoveMutation,
  FileMoveMutationVariables
>;

/**
 * __useFileMoveMutation__
 *
 * To run a mutation, you first call `useFileMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileMoveMutation, { data, loading, error }] = useFileMoveMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFileMoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FileMoveMutation,
    FileMoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FileMoveMutation,
    FileMoveMutationVariables
  >(FileMoveDocument, options);
}

export type FileMoveMutationHookResult = ReturnType<typeof useFileMoveMutation>;
export type FileMoveMutationResult =
  ApolloReactCommon.MutationResult<FileMoveMutation>;
export type FileMoveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FileMoveMutation,
  FileMoveMutationVariables
>;
export const FolderMoveDocument = gql`
  mutation FolderMove($parentFolderId: UUID!, $ids: [UUID!]!) {
    folderMove(parentFolderId: $parentFolderId, ids: $ids)
  }
`;
export type FolderMoveMutationFn = ApolloReactCommon.MutationFunction<
  FolderMoveMutation,
  FolderMoveMutationVariables
>;

/**
 * __useFolderMoveMutation__
 *
 * To run a mutation, you first call `useFolderMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolderMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folderMoveMutation, { data, loading, error }] = useFolderMoveMutation({
 *   variables: {
 *      parentFolderId: // value for 'parentFolderId'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFolderMoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FolderMoveMutation,
    FolderMoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FolderMoveMutation,
    FolderMoveMutationVariables
  >(FolderMoveDocument, options);
}

export type FolderMoveMutationHookResult = ReturnType<
  typeof useFolderMoveMutation
>;
export type FolderMoveMutationResult =
  ApolloReactCommon.MutationResult<FolderMoveMutation>;
export type FolderMoveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FolderMoveMutation,
  FolderMoveMutationVariables
>;
export const FolderCreateDocument = gql`
  mutation FolderCreate($data: FolderCreateInput!) {
    folderCreate(data: $data) {
      ...FolderWithRelationsFragment
    }
  }
  ${FolderWithRelationsFragmentFragmentDoc}
`;
export type FolderCreateMutationFn = ApolloReactCommon.MutationFunction<
  FolderCreateMutation,
  FolderCreateMutationVariables
>;

/**
 * __useFolderCreateMutation__
 *
 * To run a mutation, you first call `useFolderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folderCreateMutation, { data, loading, error }] = useFolderCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFolderCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FolderCreateMutation,
    FolderCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FolderCreateMutation,
    FolderCreateMutationVariables
  >(FolderCreateDocument, options);
}

export type FolderCreateMutationHookResult = ReturnType<
  typeof useFolderCreateMutation
>;
export type FolderCreateMutationResult =
  ApolloReactCommon.MutationResult<FolderCreateMutation>;
export type FolderCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FolderCreateMutation,
  FolderCreateMutationVariables
>;
export const FolderDeleteDocument = gql`
  mutation FolderDelete($ids: [UUID!]!) {
    folderDelete(ids: $ids)
  }
`;
export type FolderDeleteMutationFn = ApolloReactCommon.MutationFunction<
  FolderDeleteMutation,
  FolderDeleteMutationVariables
>;

/**
 * __useFolderDeleteMutation__
 *
 * To run a mutation, you first call `useFolderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folderDeleteMutation, { data, loading, error }] = useFolderDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFolderDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FolderDeleteMutation,
    FolderDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FolderDeleteMutation,
    FolderDeleteMutationVariables
  >(FolderDeleteDocument, options);
}

export type FolderDeleteMutationHookResult = ReturnType<
  typeof useFolderDeleteMutation
>;
export type FolderDeleteMutationResult =
  ApolloReactCommon.MutationResult<FolderDeleteMutation>;
export type FolderDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FolderDeleteMutation,
  FolderDeleteMutationVariables
>;
export const FolderUpdateDocument = gql`
  mutation FolderUpdate($id: UUID!, $data: FolderUpdateInput!) {
    folderUpdate(id: $id, data: $data) {
      ...FolderWithRelationsFragment
    }
  }
  ${FolderWithRelationsFragmentFragmentDoc}
`;
export type FolderUpdateMutationFn = ApolloReactCommon.MutationFunction<
  FolderUpdateMutation,
  FolderUpdateMutationVariables
>;

/**
 * __useFolderUpdateMutation__
 *
 * To run a mutation, you first call `useFolderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folderUpdateMutation, { data, loading, error }] = useFolderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFolderUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FolderUpdateMutation,
    FolderUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FolderUpdateMutation,
    FolderUpdateMutationVariables
  >(FolderUpdateDocument, options);
}

export type FolderUpdateMutationHookResult = ReturnType<
  typeof useFolderUpdateMutation
>;
export type FolderUpdateMutationResult =
  ApolloReactCommon.MutationResult<FolderUpdateMutation>;
export type FolderUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FolderUpdateMutation,
  FolderUpdateMutationVariables
>;
export const FloorPlanCreateDocument = gql`
  mutation FloorPlanCreate($data: floorPlanInput!) {
    floorPlanCreate(data: $data)
  }
`;
export type FloorPlanCreateMutationFn = ApolloReactCommon.MutationFunction<
  FloorPlanCreateMutation,
  FloorPlanCreateMutationVariables
>;

/**
 * __useFloorPlanCreateMutation__
 *
 * To run a mutation, you first call `useFloorPlanCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanCreateMutation, { data, loading, error }] = useFloorPlanCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFloorPlanCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FloorPlanCreateMutation,
    FloorPlanCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FloorPlanCreateMutation,
    FloorPlanCreateMutationVariables
  >(FloorPlanCreateDocument, options);
}

export type FloorPlanCreateMutationHookResult = ReturnType<
  typeof useFloorPlanCreateMutation
>;
export type FloorPlanCreateMutationResult =
  ApolloReactCommon.MutationResult<FloorPlanCreateMutation>;
export type FloorPlanCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FloorPlanCreateMutation,
    FloorPlanCreateMutationVariables
  >;
export const FloorPlanUpdateDocument = gql`
  mutation FloorPlanUpdate(
    $id: UUID!
    $data: floorPlanInput!
    $updateAssetLocationsAndAffectedAreas: Boolean
  ) {
    floorPlanUpdate(
      id: $id
      data: $data
      updateAssetLocationsAndAffectedAreas: $updateAssetLocationsAndAffectedAreas
    ) {
      ...FloorPlanWithRelationsFragment
    }
  }
  ${FloorPlanWithRelationsFragmentFragmentDoc}
`;
export type FloorPlanUpdateMutationFn = ApolloReactCommon.MutationFunction<
  FloorPlanUpdateMutation,
  FloorPlanUpdateMutationVariables
>;

/**
 * __useFloorPlanUpdateMutation__
 *
 * To run a mutation, you first call `useFloorPlanUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanUpdateMutation, { data, loading, error }] = useFloorPlanUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      updateAssetLocationsAndAffectedAreas: // value for 'updateAssetLocationsAndAffectedAreas'
 *   },
 * });
 */
export function useFloorPlanUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FloorPlanUpdateMutation,
    FloorPlanUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FloorPlanUpdateMutation,
    FloorPlanUpdateMutationVariables
  >(FloorPlanUpdateDocument, options);
}

export type FloorPlanUpdateMutationHookResult = ReturnType<
  typeof useFloorPlanUpdateMutation
>;
export type FloorPlanUpdateMutationResult =
  ApolloReactCommon.MutationResult<FloorPlanUpdateMutation>;
export type FloorPlanUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FloorPlanUpdateMutation,
    FloorPlanUpdateMutationVariables
  >;
export const FloorPlanTypesDocument = gql`
  query FloorPlanTypes {
    floorPlanTypes
  }
`;

/**
 * __useFloorPlanTypesQuery__
 *
 * To run a query within a React component, call `useFloorPlanTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFloorPlanTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FloorPlanTypesQuery,
    FloorPlanTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FloorPlanTypesQuery,
    FloorPlanTypesQueryVariables
  >(FloorPlanTypesDocument, options);
}

export function useFloorPlanTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FloorPlanTypesQuery,
    FloorPlanTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FloorPlanTypesQuery,
    FloorPlanTypesQueryVariables
  >(FloorPlanTypesDocument, options);
}

export type FloorPlanTypesQueryHookResult = ReturnType<
  typeof useFloorPlanTypesQuery
>;
export type FloorPlanTypesLazyQueryHookResult = ReturnType<
  typeof useFloorPlanTypesLazyQuery
>;
export type FloorPlanTypesQueryResult = ApolloReactCommon.QueryResult<
  FloorPlanTypesQuery,
  FloorPlanTypesQueryVariables
>;
export const FloorPlanIndexDocument = gql`
  query FloorPlanIndex {
    floorPlans {
      ...FloorPlanIndexFragment
    }
  }
  ${FloorPlanIndexFragmentFragmentDoc}
`;

/**
 * __useFloorPlanIndexQuery__
 *
 * To run a query within a React component, call `useFloorPlanIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useFloorPlanIndexQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FloorPlanIndexQuery,
    FloorPlanIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FloorPlanIndexQuery,
    FloorPlanIndexQueryVariables
  >(FloorPlanIndexDocument, options);
}

export function useFloorPlanIndexLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FloorPlanIndexQuery,
    FloorPlanIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FloorPlanIndexQuery,
    FloorPlanIndexQueryVariables
  >(FloorPlanIndexDocument, options);
}

export type FloorPlanIndexQueryHookResult = ReturnType<
  typeof useFloorPlanIndexQuery
>;
export type FloorPlanIndexLazyQueryHookResult = ReturnType<
  typeof useFloorPlanIndexLazyQuery
>;
export type FloorPlanIndexQueryResult = ApolloReactCommon.QueryResult<
  FloorPlanIndexQuery,
  FloorPlanIndexQueryVariables
>;
export const FloorPlanMoveDocument = gql`
  mutation FloorPlanMove($ids: [UUID!]!, $locationId: UUID!) {
    floorPlanMove(ids: $ids, locationId: $locationId)
  }
`;
export type FloorPlanMoveMutationFn = ApolloReactCommon.MutationFunction<
  FloorPlanMoveMutation,
  FloorPlanMoveMutationVariables
>;

/**
 * __useFloorPlanMoveMutation__
 *
 * To run a mutation, you first call `useFloorPlanMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanMoveMutation, { data, loading, error }] = useFloorPlanMoveMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useFloorPlanMoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FloorPlanMoveMutation,
    FloorPlanMoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FloorPlanMoveMutation,
    FloorPlanMoveMutationVariables
  >(FloorPlanMoveDocument, options);
}

export type FloorPlanMoveMutationHookResult = ReturnType<
  typeof useFloorPlanMoveMutation
>;
export type FloorPlanMoveMutationResult =
  ApolloReactCommon.MutationResult<FloorPlanMoveMutation>;
export type FloorPlanMoveMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FloorPlanMoveMutation,
    FloorPlanMoveMutationVariables
  >;
export const FloorPlanDeleteDocument = gql`
  mutation FloorPlanDelete($ids: [UUID!]!) {
    floorPlanDelete(ids: $ids)
  }
`;
export type FloorPlanDeleteMutationFn = ApolloReactCommon.MutationFunction<
  FloorPlanDeleteMutation,
  FloorPlanDeleteMutationVariables
>;

/**
 * __useFloorPlanDeleteMutation__
 *
 * To run a mutation, you first call `useFloorPlanDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanDeleteMutation, { data, loading, error }] = useFloorPlanDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFloorPlanDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FloorPlanDeleteMutation,
    FloorPlanDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FloorPlanDeleteMutation,
    FloorPlanDeleteMutationVariables
  >(FloorPlanDeleteDocument, options);
}

export type FloorPlanDeleteMutationHookResult = ReturnType<
  typeof useFloorPlanDeleteMutation
>;
export type FloorPlanDeleteMutationResult =
  ApolloReactCommon.MutationResult<FloorPlanDeleteMutation>;
export type FloorPlanDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FloorPlanDeleteMutation,
    FloorPlanDeleteMutationVariables
  >;
export const UserForgotPasswordDocument = gql`
  mutation userForgotPassword($data: UserForgotPasswordInput!) {
    userForgotPassword(data: $data)
  }
`;
export type UserForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UserForgotPasswordMutation,
  UserForgotPasswordMutationVariables
>;

/**
 * __useUserForgotPasswordMutation__
 *
 * To run a mutation, you first call `useUserForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userForgotPasswordMutation, { data, loading, error }] = useUserForgotPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserForgotPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserForgotPasswordMutation,
    UserForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserForgotPasswordMutation,
    UserForgotPasswordMutationVariables
  >(UserForgotPasswordDocument, options);
}

export type UserForgotPasswordMutationHookResult = ReturnType<
  typeof useUserForgotPasswordMutation
>;
export type UserForgotPasswordMutationResult =
  ApolloReactCommon.MutationResult<UserForgotPasswordMutation>;
export type UserForgotPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UserForgotPasswordMutation,
    UserForgotPasswordMutationVariables
  >;
export const LocationCreateDocument = gql`
  mutation LocationCreate($data: LocationInput!) {
    locationCreate(data: $data) {
      ...LocationWithRelationsFragment
    }
  }
  ${LocationWithRelationsFragmentFragmentDoc}
`;
export type LocationCreateMutationFn = ApolloReactCommon.MutationFunction<
  LocationCreateMutation,
  LocationCreateMutationVariables
>;

/**
 * __useLocationCreateMutation__
 *
 * To run a mutation, you first call `useLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationCreateMutation, { data, loading, error }] = useLocationCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LocationCreateMutation,
    LocationCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    LocationCreateMutation,
    LocationCreateMutationVariables
  >(LocationCreateDocument, options);
}

export type LocationCreateMutationHookResult = ReturnType<
  typeof useLocationCreateMutation
>;
export type LocationCreateMutationResult =
  ApolloReactCommon.MutationResult<LocationCreateMutation>;
export type LocationCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    LocationCreateMutation,
    LocationCreateMutationVariables
  >;
export const LocationUpdateDocument = gql`
  mutation LocationUpdate($id: UUID!, $data: LocationInput!) {
    locationUpdate(id: $id, data: $data) {
      ...LocationWithRelationsFragment
    }
  }
  ${LocationWithRelationsFragmentFragmentDoc}
`;
export type LocationUpdateMutationFn = ApolloReactCommon.MutationFunction<
  LocationUpdateMutation,
  LocationUpdateMutationVariables
>;

/**
 * __useLocationUpdateMutation__
 *
 * To run a mutation, you first call `useLocationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationUpdateMutation, { data, loading, error }] = useLocationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LocationUpdateMutation,
    LocationUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    LocationUpdateMutation,
    LocationUpdateMutationVariables
  >(LocationUpdateDocument, options);
}

export type LocationUpdateMutationHookResult = ReturnType<
  typeof useLocationUpdateMutation
>;
export type LocationUpdateMutationResult =
  ApolloReactCommon.MutationResult<LocationUpdateMutation>;
export type LocationUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    LocationUpdateMutation,
    LocationUpdateMutationVariables
  >;
export const LocationsDocument = gql`
  query Locations($parentId: UUID) {
    locations(parentId: $parentId) {
      ...LocationWithRelationsFragment
    }
  }
  ${LocationWithRelationsFragmentFragmentDoc}
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  );
}

export function useLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  );
}

export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<
  typeof useLocationsLazyQuery
>;
export type LocationsQueryResult = ApolloReactCommon.QueryResult<
  LocationsQuery,
  LocationsQueryVariables
>;
export const LocationIndexDocument = gql`
  query LocationIndex($parentId: UUID, $companyId: UUID) {
    locations(parentId: $parentId, companyId: $companyId) {
      ...LocationIndexFragment
    }
  }
  ${LocationIndexFragmentFragmentDoc}
`;

/**
 * __useLocationIndexQuery__
 *
 * To run a query within a React component, call `useLocationIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationIndexQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useLocationIndexQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationIndexQuery,
    LocationIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LocationIndexQuery,
    LocationIndexQueryVariables
  >(LocationIndexDocument, options);
}

export function useLocationIndexLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationIndexQuery,
    LocationIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LocationIndexQuery,
    LocationIndexQueryVariables
  >(LocationIndexDocument, options);
}

export type LocationIndexQueryHookResult = ReturnType<
  typeof useLocationIndexQuery
>;
export type LocationIndexLazyQueryHookResult = ReturnType<
  typeof useLocationIndexLazyQuery
>;
export type LocationIndexQueryResult = ApolloReactCommon.QueryResult<
  LocationIndexQuery,
  LocationIndexQueryVariables
>;
export const LocationTypesDocument = gql`
  query LocationTypes {
    locationTypes
  }
`;

/**
 * __useLocationTypesQuery__
 *
 * To run a query within a React component, call `useLocationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationTypesQuery,
    LocationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LocationTypesQuery,
    LocationTypesQueryVariables
  >(LocationTypesDocument, options);
}

export function useLocationTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationTypesQuery,
    LocationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LocationTypesQuery,
    LocationTypesQueryVariables
  >(LocationTypesDocument, options);
}

export type LocationTypesQueryHookResult = ReturnType<
  typeof useLocationTypesQuery
>;
export type LocationTypesLazyQueryHookResult = ReturnType<
  typeof useLocationTypesLazyQuery
>;
export type LocationTypesQueryResult = ApolloReactCommon.QueryResult<
  LocationTypesQuery,
  LocationTypesQueryVariables
>;
export const LocationDocument = gql`
  query Location($id: UUID!) {
    location(id: $id) {
      ...LocationWithRelationsFragment
    }
  }
  ${LocationWithRelationsFragmentFragmentDoc}
`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LocationQuery,
    LocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LocationQuery, LocationQueryVariables>(
    LocationDocument,
    options
  );
}

export function useLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationQuery,
    LocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LocationQuery, LocationQueryVariables>(
    LocationDocument,
    options
  );
}

export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<
  typeof useLocationLazyQuery
>;
export type LocationQueryResult = ApolloReactCommon.QueryResult<
  LocationQuery,
  LocationQueryVariables
>;
export const LocationDeleteDocument = gql`
  mutation LocationDelete($id: UUID!) {
    locationDelete(id: $id)
  }
`;
export type LocationDeleteMutationFn = ApolloReactCommon.MutationFunction<
  LocationDeleteMutation,
  LocationDeleteMutationVariables
>;

/**
 * __useLocationDeleteMutation__
 *
 * To run a mutation, you first call `useLocationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationDeleteMutation, { data, loading, error }] = useLocationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LocationDeleteMutation,
    LocationDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    LocationDeleteMutation,
    LocationDeleteMutationVariables
  >(LocationDeleteDocument, options);
}

export type LocationDeleteMutationHookResult = ReturnType<
  typeof useLocationDeleteMutation
>;
export type LocationDeleteMutationResult =
  ApolloReactCommon.MutationResult<LocationDeleteMutation>;
export type LocationDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    LocationDeleteMutation,
    LocationDeleteMutationVariables
  >;
export const MaintenanceCreateDocument = gql`
  mutation MaintenanceCreate($data: MaintenanceInput!) {
    maintenanceCreate(data: $data) {
      ...MaintenanceWithContactsFragment
    }
  }
  ${MaintenanceWithContactsFragmentFragmentDoc}
`;
export type MaintenanceCreateMutationFn = ApolloReactCommon.MutationFunction<
  MaintenanceCreateMutation,
  MaintenanceCreateMutationVariables
>;

/**
 * __useMaintenanceCreateMutation__
 *
 * To run a mutation, you first call `useMaintenanceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceCreateMutation, { data, loading, error }] = useMaintenanceCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMaintenanceCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceCreateMutation,
    MaintenanceCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceCreateMutation,
    MaintenanceCreateMutationVariables
  >(MaintenanceCreateDocument, options);
}

export type MaintenanceCreateMutationHookResult = ReturnType<
  typeof useMaintenanceCreateMutation
>;
export type MaintenanceCreateMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceCreateMutation>;
export type MaintenanceCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceCreateMutation,
    MaintenanceCreateMutationVariables
  >;
export const MaintenanceUpdateDocument = gql`
  mutation MaintenanceUpdate($id: UUID!, $data: MaintenanceInput!) {
    maintenanceUpdate(id: $id, data: $data) {
      ...MaintenanceWithContactsFragment
    }
  }
  ${MaintenanceWithContactsFragmentFragmentDoc}
`;
export type MaintenanceUpdateMutationFn = ApolloReactCommon.MutationFunction<
  MaintenanceUpdateMutation,
  MaintenanceUpdateMutationVariables
>;

/**
 * __useMaintenanceUpdateMutation__
 *
 * To run a mutation, you first call `useMaintenanceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceUpdateMutation, { data, loading, error }] = useMaintenanceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMaintenanceUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceUpdateMutation,
    MaintenanceUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceUpdateMutation,
    MaintenanceUpdateMutationVariables
  >(MaintenanceUpdateDocument, options);
}

export type MaintenanceUpdateMutationHookResult = ReturnType<
  typeof useMaintenanceUpdateMutation
>;
export type MaintenanceUpdateMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceUpdateMutation>;
export type MaintenanceUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceUpdateMutation,
    MaintenanceUpdateMutationVariables
  >;
export const MaintenanceNoteCreateDocument = gql`
  mutation MaintenanceNoteCreate($data: MaintenanceNoteInput!) {
    maintenanceNoteCreate(data: $data) {
      ...MaintenanceNoteFragment
    }
  }
  ${MaintenanceNoteFragmentFragmentDoc}
`;
export type MaintenanceNoteCreateMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceNoteCreateMutation,
    MaintenanceNoteCreateMutationVariables
  >;

/**
 * __useMaintenanceNoteCreateMutation__
 *
 * To run a mutation, you first call `useMaintenanceNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceNoteCreateMutation, { data, loading, error }] = useMaintenanceNoteCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMaintenanceNoteCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceNoteCreateMutation,
    MaintenanceNoteCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceNoteCreateMutation,
    MaintenanceNoteCreateMutationVariables
  >(MaintenanceNoteCreateDocument, options);
}

export type MaintenanceNoteCreateMutationHookResult = ReturnType<
  typeof useMaintenanceNoteCreateMutation
>;
export type MaintenanceNoteCreateMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceNoteCreateMutation>;
export type MaintenanceNoteCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceNoteCreateMutation,
    MaintenanceNoteCreateMutationVariables
  >;
export const MaintenanceNoteUpdateDocument = gql`
  mutation MaintenanceNoteUpdate($id: UUID!, $data: MaintenanceNoteInput!) {
    maintenanceNoteUpdate(id: $id, data: $data) {
      ...MaintenanceNoteFragment
    }
  }
  ${MaintenanceNoteFragmentFragmentDoc}
`;
export type MaintenanceNoteUpdateMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceNoteUpdateMutation,
    MaintenanceNoteUpdateMutationVariables
  >;

/**
 * __useMaintenanceNoteUpdateMutation__
 *
 * To run a mutation, you first call `useMaintenanceNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceNoteUpdateMutation, { data, loading, error }] = useMaintenanceNoteUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMaintenanceNoteUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceNoteUpdateMutation,
    MaintenanceNoteUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceNoteUpdateMutation,
    MaintenanceNoteUpdateMutationVariables
  >(MaintenanceNoteUpdateDocument, options);
}

export type MaintenanceNoteUpdateMutationHookResult = ReturnType<
  typeof useMaintenanceNoteUpdateMutation
>;
export type MaintenanceNoteUpdateMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceNoteUpdateMutation>;
export type MaintenanceNoteUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceNoteUpdateMutation,
    MaintenanceNoteUpdateMutationVariables
  >;
export const MaintenanceNoteDeleteDocument = gql`
  mutation MaintenanceNoteDelete($id: UUID!) {
    maintenanceNoteDelete(id: $id)
  }
`;
export type MaintenanceNoteDeleteMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceNoteDeleteMutation,
    MaintenanceNoteDeleteMutationVariables
  >;

/**
 * __useMaintenanceNoteDeleteMutation__
 *
 * To run a mutation, you first call `useMaintenanceNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceNoteDeleteMutation, { data, loading, error }] = useMaintenanceNoteDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceNoteDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceNoteDeleteMutation,
    MaintenanceNoteDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceNoteDeleteMutation,
    MaintenanceNoteDeleteMutationVariables
  >(MaintenanceNoteDeleteDocument, options);
}

export type MaintenanceNoteDeleteMutationHookResult = ReturnType<
  typeof useMaintenanceNoteDeleteMutation
>;
export type MaintenanceNoteDeleteMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceNoteDeleteMutation>;
export type MaintenanceNoteDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceNoteDeleteMutation,
    MaintenanceNoteDeleteMutationVariables
  >;
export const MaintenanceToggleDeletedScheduleTimestampDocument = gql`
  mutation MaintenanceToggleDeletedScheduleTimestamp(
    $id: UUID!
    $timestamp: Timestamp!
  ) {
    maintenanceToggleDeletedScheduleTimestamp(id: $id, timestamp: $timestamp) {
      ...MaintenanceFragment
    }
  }
  ${MaintenanceFragmentFragmentDoc}
`;
export type MaintenanceToggleDeletedScheduleTimestampMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceToggleDeletedScheduleTimestampMutation,
    MaintenanceToggleDeletedScheduleTimestampMutationVariables
  >;

/**
 * __useMaintenanceToggleDeletedScheduleTimestampMutation__
 *
 * To run a mutation, you first call `useMaintenanceToggleDeletedScheduleTimestampMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceToggleDeletedScheduleTimestampMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceToggleDeletedScheduleTimestampMutation, { data, loading, error }] = useMaintenanceToggleDeletedScheduleTimestampMutation({
 *   variables: {
 *      id: // value for 'id'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useMaintenanceToggleDeletedScheduleTimestampMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceToggleDeletedScheduleTimestampMutation,
    MaintenanceToggleDeletedScheduleTimestampMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceToggleDeletedScheduleTimestampMutation,
    MaintenanceToggleDeletedScheduleTimestampMutationVariables
  >(MaintenanceToggleDeletedScheduleTimestampDocument, options);
}

export type MaintenanceToggleDeletedScheduleTimestampMutationHookResult =
  ReturnType<typeof useMaintenanceToggleDeletedScheduleTimestampMutation>;
export type MaintenanceToggleDeletedScheduleTimestampMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceToggleDeletedScheduleTimestampMutation>;
export type MaintenanceToggleDeletedScheduleTimestampMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceToggleDeletedScheduleTimestampMutation,
    MaintenanceToggleDeletedScheduleTimestampMutationVariables
  >;
export const MaintenanceDeleteDocument = gql`
  mutation MaintenanceDelete($id: UUID!) {
    maintenanceDelete(id: $id)
  }
`;
export type MaintenanceDeleteMutationFn = ApolloReactCommon.MutationFunction<
  MaintenanceDeleteMutation,
  MaintenanceDeleteMutationVariables
>;

/**
 * __useMaintenanceDeleteMutation__
 *
 * To run a mutation, you first call `useMaintenanceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceDeleteMutation, { data, loading, error }] = useMaintenanceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceDeleteMutation,
    MaintenanceDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceDeleteMutation,
    MaintenanceDeleteMutationVariables
  >(MaintenanceDeleteDocument, options);
}

export type MaintenanceDeleteMutationHookResult = ReturnType<
  typeof useMaintenanceDeleteMutation
>;
export type MaintenanceDeleteMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceDeleteMutation>;
export type MaintenanceDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceDeleteMutation,
    MaintenanceDeleteMutationVariables
  >;
export const MaintenanceWithContactsDocument = gql`
  query MaintenanceWithContacts($id: UUID!) {
    maintenance(id: $id) {
      ...MaintenanceWithContactsFragment
    }
  }
  ${MaintenanceWithContactsFragmentFragmentDoc}
`;

/**
 * __useMaintenanceWithContactsQuery__
 *
 * To run a query within a React component, call `useMaintenanceWithContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceWithContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceWithContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceWithContactsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MaintenanceWithContactsQuery,
    MaintenanceWithContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MaintenanceWithContactsQuery,
    MaintenanceWithContactsQueryVariables
  >(MaintenanceWithContactsDocument, options);
}

export function useMaintenanceWithContactsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaintenanceWithContactsQuery,
    MaintenanceWithContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaintenanceWithContactsQuery,
    MaintenanceWithContactsQueryVariables
  >(MaintenanceWithContactsDocument, options);
}

export type MaintenanceWithContactsQueryHookResult = ReturnType<
  typeof useMaintenanceWithContactsQuery
>;
export type MaintenanceWithContactsLazyQueryHookResult = ReturnType<
  typeof useMaintenanceWithContactsLazyQuery
>;
export type MaintenanceWithContactsQueryResult = ApolloReactCommon.QueryResult<
  MaintenanceWithContactsQuery,
  MaintenanceWithContactsQueryVariables
>;
export const MaintenanceIndexDocument = gql`
  query MaintenanceIndex($startDate: Timestamp, $endDate: Timestamp) {
    maintenances {
      ...MaintenanceIndexFragment
    }
  }
  ${MaintenanceIndexFragmentFragmentDoc}
`;

/**
 * __useMaintenanceIndexQuery__
 *
 * To run a query within a React component, call `useMaintenanceIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceIndexQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useMaintenanceIndexQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MaintenanceIndexQuery,
    MaintenanceIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MaintenanceIndexQuery,
    MaintenanceIndexQueryVariables
  >(MaintenanceIndexDocument, options);
}

export function useMaintenanceIndexLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaintenanceIndexQuery,
    MaintenanceIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaintenanceIndexQuery,
    MaintenanceIndexQueryVariables
  >(MaintenanceIndexDocument, options);
}

export type MaintenanceIndexQueryHookResult = ReturnType<
  typeof useMaintenanceIndexQuery
>;
export type MaintenanceIndexLazyQueryHookResult = ReturnType<
  typeof useMaintenanceIndexLazyQuery
>;
export type MaintenanceIndexQueryResult = ApolloReactCommon.QueryResult<
  MaintenanceIndexQuery,
  MaintenanceIndexQueryVariables
>;
export const MaintenanceScheduleSnoozeReminderDocument = gql`
  mutation MaintenanceScheduleSnoozeReminder(
    $id: UUID!
    $data: MaintenanceScheduleSnoozeReminderInput!
  ) {
    maintenanceScheduleSnoozeReminder(id: $id, data: $data) {
      ...MaintenanceScheduleFragment
    }
  }
  ${MaintenanceScheduleFragmentFragmentDoc}
`;
export type MaintenanceScheduleSnoozeReminderMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceScheduleSnoozeReminderMutation,
    MaintenanceScheduleSnoozeReminderMutationVariables
  >;

/**
 * __useMaintenanceScheduleSnoozeReminderMutation__
 *
 * To run a mutation, you first call `useMaintenanceScheduleSnoozeReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceScheduleSnoozeReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceScheduleSnoozeReminderMutation, { data, loading, error }] = useMaintenanceScheduleSnoozeReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMaintenanceScheduleSnoozeReminderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceScheduleSnoozeReminderMutation,
    MaintenanceScheduleSnoozeReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceScheduleSnoozeReminderMutation,
    MaintenanceScheduleSnoozeReminderMutationVariables
  >(MaintenanceScheduleSnoozeReminderDocument, options);
}

export type MaintenanceScheduleSnoozeReminderMutationHookResult = ReturnType<
  typeof useMaintenanceScheduleSnoozeReminderMutation
>;
export type MaintenanceScheduleSnoozeReminderMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceScheduleSnoozeReminderMutation>;
export type MaintenanceScheduleSnoozeReminderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceScheduleSnoozeReminderMutation,
    MaintenanceScheduleSnoozeReminderMutationVariables
  >;
export const MaintenanceScheduleCompleteDocument = gql`
  mutation MaintenanceScheduleComplete($id: UUID!, $userIds: [UUID!]!) {
    maintenanceScheduleComplete(id: $id, userIds: $userIds) {
      ...MaintenanceScheduleFragment
    }
  }
  ${MaintenanceScheduleFragmentFragmentDoc}
`;
export type MaintenanceScheduleCompleteMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceScheduleCompleteMutation,
    MaintenanceScheduleCompleteMutationVariables
  >;

/**
 * __useMaintenanceScheduleCompleteMutation__
 *
 * To run a mutation, you first call `useMaintenanceScheduleCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceScheduleCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceScheduleCompleteMutation, { data, loading, error }] = useMaintenanceScheduleCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useMaintenanceScheduleCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceScheduleCompleteMutation,
    MaintenanceScheduleCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceScheduleCompleteMutation,
    MaintenanceScheduleCompleteMutationVariables
  >(MaintenanceScheduleCompleteDocument, options);
}

export type MaintenanceScheduleCompleteMutationHookResult = ReturnType<
  typeof useMaintenanceScheduleCompleteMutation
>;
export type MaintenanceScheduleCompleteMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceScheduleCompleteMutation>;
export type MaintenanceScheduleCompleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceScheduleCompleteMutation,
    MaintenanceScheduleCompleteMutationVariables
  >;
export const MaintenanceScheduleInCompleteDocument = gql`
  mutation MaintenanceScheduleInComplete($id: UUID!) {
    maintenanceScheduleInComplete(id: $id) {
      ...MaintenanceScheduleFragment
    }
  }
  ${MaintenanceScheduleFragmentFragmentDoc}
`;
export type MaintenanceScheduleInCompleteMutationFn =
  ApolloReactCommon.MutationFunction<
    MaintenanceScheduleInCompleteMutation,
    MaintenanceScheduleInCompleteMutationVariables
  >;

/**
 * __useMaintenanceScheduleInCompleteMutation__
 *
 * To run a mutation, you first call `useMaintenanceScheduleInCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceScheduleInCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceScheduleInCompleteMutation, { data, loading, error }] = useMaintenanceScheduleInCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceScheduleInCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MaintenanceScheduleInCompleteMutation,
    MaintenanceScheduleInCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MaintenanceScheduleInCompleteMutation,
    MaintenanceScheduleInCompleteMutationVariables
  >(MaintenanceScheduleInCompleteDocument, options);
}

export type MaintenanceScheduleInCompleteMutationHookResult = ReturnType<
  typeof useMaintenanceScheduleInCompleteMutation
>;
export type MaintenanceScheduleInCompleteMutationResult =
  ApolloReactCommon.MutationResult<MaintenanceScheduleInCompleteMutation>;
export type MaintenanceScheduleInCompleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MaintenanceScheduleInCompleteMutation,
    MaintenanceScheduleInCompleteMutationVariables
  >;
export const MaintenanceDocument = gql`
  query Maintenance($id: UUID!, $startDate: Timestamp, $endDate: Timestamp) {
    maintenance(id: $id) {
      ...MaintenanceWithRelationsFragment
    }
  }
  ${MaintenanceWithRelationsFragmentFragmentDoc}
`;

/**
 * __useMaintenanceQuery__
 *
 * To run a query within a React component, call `useMaintenanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useMaintenanceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MaintenanceQuery,
    MaintenanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MaintenanceQuery, MaintenanceQueryVariables>(
    MaintenanceDocument,
    options
  );
}

export function useMaintenanceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaintenanceQuery,
    MaintenanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaintenanceQuery,
    MaintenanceQueryVariables
  >(MaintenanceDocument, options);
}

export type MaintenanceQueryHookResult = ReturnType<typeof useMaintenanceQuery>;
export type MaintenanceLazyQueryHookResult = ReturnType<
  typeof useMaintenanceLazyQuery
>;
export type MaintenanceQueryResult = ApolloReactCommon.QueryResult<
  MaintenanceQuery,
  MaintenanceQueryVariables
>;
export const MaintenanceWithSchedulesDocument = gql`
  query MaintenanceWithSchedules($id: UUID!) {
    maintenance(id: $id) {
      ...MaintenanceWithSchedulesFragment
    }
  }
  ${MaintenanceWithSchedulesFragmentFragmentDoc}
`;

/**
 * __useMaintenanceWithSchedulesQuery__
 *
 * To run a query within a React component, call `useMaintenanceWithSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceWithSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceWithSchedulesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceWithSchedulesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MaintenanceWithSchedulesQuery,
    MaintenanceWithSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MaintenanceWithSchedulesQuery,
    MaintenanceWithSchedulesQueryVariables
  >(MaintenanceWithSchedulesDocument, options);
}

export function useMaintenanceWithSchedulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaintenanceWithSchedulesQuery,
    MaintenanceWithSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaintenanceWithSchedulesQuery,
    MaintenanceWithSchedulesQueryVariables
  >(MaintenanceWithSchedulesDocument, options);
}

export type MaintenanceWithSchedulesQueryHookResult = ReturnType<
  typeof useMaintenanceWithSchedulesQuery
>;
export type MaintenanceWithSchedulesLazyQueryHookResult = ReturnType<
  typeof useMaintenanceWithSchedulesLazyQuery
>;
export type MaintenanceWithSchedulesQueryResult = ApolloReactCommon.QueryResult<
  MaintenanceWithSchedulesQuery,
  MaintenanceWithSchedulesQueryVariables
>;
export const UserUpdateDocument = gql`
  mutation userUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UserUpdateMutationFn = ApolloReactCommon.MutationFunction<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >(UserUpdateDocument, options);
}

export type UserUpdateMutationHookResult = ReturnType<
  typeof useUserUpdateMutation
>;
export type UserUpdateMutationResult =
  ApolloReactCommon.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export const UserPhoneConfirmDocument = gql`
  mutation userPhoneConfirm($data: UserPhoneConfirmInput) {
    userPhoneConfirm(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UserPhoneConfirmMutationFn = ApolloReactCommon.MutationFunction<
  UserPhoneConfirmMutation,
  UserPhoneConfirmMutationVariables
>;

/**
 * __useUserPhoneConfirmMutation__
 *
 * To run a mutation, you first call `useUserPhoneConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPhoneConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPhoneConfirmMutation, { data, loading, error }] = useUserPhoneConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserPhoneConfirmMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPhoneConfirmMutation,
    UserPhoneConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserPhoneConfirmMutation,
    UserPhoneConfirmMutationVariables
  >(UserPhoneConfirmDocument, options);
}

export type UserPhoneConfirmMutationHookResult = ReturnType<
  typeof useUserPhoneConfirmMutation
>;
export type UserPhoneConfirmMutationResult =
  ApolloReactCommon.MutationResult<UserPhoneConfirmMutation>;
export type UserPhoneConfirmMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UserPhoneConfirmMutation,
    UserPhoneConfirmMutationVariables
  >;
export const UserPhoneVerifyDocument = gql`
  mutation userPhoneVerify($data: UserPhoneVerifyInput!) {
    userPhoneVerify(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UserPhoneVerifyMutationFn = ApolloReactCommon.MutationFunction<
  UserPhoneVerifyMutation,
  UserPhoneVerifyMutationVariables
>;

/**
 * __useUserPhoneVerifyMutation__
 *
 * To run a mutation, you first call `useUserPhoneVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPhoneVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPhoneVerifyMutation, { data, loading, error }] = useUserPhoneVerifyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserPhoneVerifyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPhoneVerifyMutation,
    UserPhoneVerifyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserPhoneVerifyMutation,
    UserPhoneVerifyMutationVariables
  >(UserPhoneVerifyDocument, options);
}

export type UserPhoneVerifyMutationHookResult = ReturnType<
  typeof useUserPhoneVerifyMutation
>;
export type UserPhoneVerifyMutationResult =
  ApolloReactCommon.MutationResult<UserPhoneVerifyMutation>;
export type UserPhoneVerifyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UserPhoneVerifyMutation,
    UserPhoneVerifyMutationVariables
  >;
export const UserDocument = gql`
  query user {
    user {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}

export function useUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserQuery,
    UserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}

export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<
  UserQuery,
  UserQueryVariables
>;
export const ToggleTwoFactorDocument = gql`
  mutation ToggleTwoFactor($twoFactorEnabled: Boolean!) {
    toggleTwoFactor(twoFactorEnabled: $twoFactorEnabled) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type ToggleTwoFactorMutationFn = ApolloReactCommon.MutationFunction<
  ToggleTwoFactorMutation,
  ToggleTwoFactorMutationVariables
>;

/**
 * __useToggleTwoFactorMutation__
 *
 * To run a mutation, you first call `useToggleTwoFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTwoFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTwoFactorMutation, { data, loading, error }] = useToggleTwoFactorMutation({
 *   variables: {
 *      twoFactorEnabled: // value for 'twoFactorEnabled'
 *   },
 * });
 */
export function useToggleTwoFactorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ToggleTwoFactorMutation,
    ToggleTwoFactorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ToggleTwoFactorMutation,
    ToggleTwoFactorMutationVariables
  >(ToggleTwoFactorDocument, options);
}

export type ToggleTwoFactorMutationHookResult = ReturnType<
  typeof useToggleTwoFactorMutation
>;
export type ToggleTwoFactorMutationResult =
  ApolloReactCommon.MutationResult<ToggleTwoFactorMutation>;
export type ToggleTwoFactorMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ToggleTwoFactorMutation,
    ToggleTwoFactorMutationVariables
  >;
export const SendEmailDocument = gql`
  mutation SendEmail($message: String!) {
    sendEmail(message: $message)
  }
`;
export type SendEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendEmailMutation,
  SendEmailMutationVariables
>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendEmailMutation,
    SendEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SendEmailMutation,
    SendEmailMutationVariables
  >(SendEmailDocument, options);
}

export type SendEmailMutationHookResult = ReturnType<
  typeof useSendEmailMutation
>;
export type SendEmailMutationResult =
  ApolloReactCommon.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendEmailMutation,
  SendEmailMutationVariables
>;
export const ReportAssetDocument = gql`
  query ReportAsset($filterBy: ReportAssetFilterInput!, $timezone: String) {
    reportAsset(filterBy: $filterBy, timezone: $timezone)
  }
`;

/**
 * __useReportAssetQuery__
 *
 * To run a query within a React component, call `useReportAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportAssetQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useReportAssetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ReportAssetQuery,
    ReportAssetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ReportAssetQuery, ReportAssetQueryVariables>(
    ReportAssetDocument,
    options
  );
}

export function useReportAssetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReportAssetQuery,
    ReportAssetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ReportAssetQuery,
    ReportAssetQueryVariables
  >(ReportAssetDocument, options);
}

export type ReportAssetQueryHookResult = ReturnType<typeof useReportAssetQuery>;
export type ReportAssetLazyQueryHookResult = ReturnType<
  typeof useReportAssetLazyQuery
>;
export type ReportAssetQueryResult = ApolloReactCommon.QueryResult<
  ReportAssetQuery,
  ReportAssetQueryVariables
>;
export const CreateReportAssetDocument = gql`
  mutation CreateReportAsset(
    $fileType: ReportFileType!
    $filterBy: ReportAssetFilterInput!
    $timezone: String
  ) {
    createReportAsset(
      fileType: $fileType
      filterBy: $filterBy
      timezone: $timezone
    )
  }
`;
export type CreateReportAssetMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportAssetMutation,
  CreateReportAssetMutationVariables
>;

/**
 * __useCreateReportAssetMutation__
 *
 * To run a mutation, you first call `useCreateReportAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportAssetMutation, { data, loading, error }] = useCreateReportAssetMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      filterBy: // value for 'filterBy'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateReportAssetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportAssetMutation,
    CreateReportAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateReportAssetMutation,
    CreateReportAssetMutationVariables
  >(CreateReportAssetDocument, options);
}

export type CreateReportAssetMutationHookResult = ReturnType<
  typeof useCreateReportAssetMutation
>;
export type CreateReportAssetMutationResult =
  ApolloReactCommon.MutationResult<CreateReportAssetMutation>;
export type CreateReportAssetMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateReportAssetMutation,
    CreateReportAssetMutationVariables
  >;
export const CompletedByUsersDocument = gql`
  query CompletedByUsers {
    completedByUsers {
      id
      fullName
      email
    }
  }
`;

/**
 * __useCompletedByUsersQuery__
 *
 * To run a query within a React component, call `useCompletedByUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedByUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedByUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletedByUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompletedByUsersQuery,
    CompletedByUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CompletedByUsersQuery,
    CompletedByUsersQueryVariables
  >(CompletedByUsersDocument, options);
}

export function useCompletedByUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompletedByUsersQuery,
    CompletedByUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CompletedByUsersQuery,
    CompletedByUsersQueryVariables
  >(CompletedByUsersDocument, options);
}

export type CompletedByUsersQueryHookResult = ReturnType<
  typeof useCompletedByUsersQuery
>;
export type CompletedByUsersLazyQueryHookResult = ReturnType<
  typeof useCompletedByUsersLazyQuery
>;
export type CompletedByUsersQueryResult = ApolloReactCommon.QueryResult<
  CompletedByUsersQuery,
  CompletedByUsersQueryVariables
>;
export const ReportMaintenanceDocument = gql`
  query ReportMaintenance(
    $filterBy: ReportMaintenanceFilterInput!
    $timezone: String
  ) {
    reportMaintenance(filterBy: $filterBy, timezone: $timezone)
  }
`;

/**
 * __useReportMaintenanceQuery__
 *
 * To run a query within a React component, call `useReportMaintenanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportMaintenanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportMaintenanceQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useReportMaintenanceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ReportMaintenanceQuery,
    ReportMaintenanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ReportMaintenanceQuery,
    ReportMaintenanceQueryVariables
  >(ReportMaintenanceDocument, options);
}

export function useReportMaintenanceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReportMaintenanceQuery,
    ReportMaintenanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ReportMaintenanceQuery,
    ReportMaintenanceQueryVariables
  >(ReportMaintenanceDocument, options);
}

export type ReportMaintenanceQueryHookResult = ReturnType<
  typeof useReportMaintenanceQuery
>;
export type ReportMaintenanceLazyQueryHookResult = ReturnType<
  typeof useReportMaintenanceLazyQuery
>;
export type ReportMaintenanceQueryResult = ApolloReactCommon.QueryResult<
  ReportMaintenanceQuery,
  ReportMaintenanceQueryVariables
>;
export const CreateReportMaintenanceDocument = gql`
  mutation CreateReportMaintenance(
    $fileType: ReportFileType!
    $filterBy: ReportMaintenanceFilterInput!
    $timezone: String
  ) {
    createReportMaintenance(
      fileType: $fileType
      filterBy: $filterBy
      timezone: $timezone
    )
  }
`;
export type CreateReportMaintenanceMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateReportMaintenanceMutation,
    CreateReportMaintenanceMutationVariables
  >;

/**
 * __useCreateReportMaintenanceMutation__
 *
 * To run a mutation, you first call `useCreateReportMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMaintenanceMutation, { data, loading, error }] = useCreateReportMaintenanceMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      filterBy: // value for 'filterBy'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateReportMaintenanceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportMaintenanceMutation,
    CreateReportMaintenanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateReportMaintenanceMutation,
    CreateReportMaintenanceMutationVariables
  >(CreateReportMaintenanceDocument, options);
}

export type CreateReportMaintenanceMutationHookResult = ReturnType<
  typeof useCreateReportMaintenanceMutation
>;
export type CreateReportMaintenanceMutationResult =
  ApolloReactCommon.MutationResult<CreateReportMaintenanceMutation>;
export type CreateReportMaintenanceMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateReportMaintenanceMutation,
    CreateReportMaintenanceMutationVariables
  >;
export const ReportPlanDocument = gql`
  query ReportPlan($filterBy: ReportPlanFilterInput!, $timezone: String) {
    reportPlan(filterBy: $filterBy, timezone: $timezone)
  }
`;

/**
 * __useReportPlanQuery__
 *
 * To run a query within a React component, call `useReportPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPlanQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useReportPlanQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ReportPlanQuery,
    ReportPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ReportPlanQuery, ReportPlanQueryVariables>(
    ReportPlanDocument,
    options
  );
}

export function useReportPlanLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReportPlanQuery,
    ReportPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ReportPlanQuery,
    ReportPlanQueryVariables
  >(ReportPlanDocument, options);
}

export type ReportPlanQueryHookResult = ReturnType<typeof useReportPlanQuery>;
export type ReportPlanLazyQueryHookResult = ReturnType<
  typeof useReportPlanLazyQuery
>;
export type ReportPlanQueryResult = ApolloReactCommon.QueryResult<
  ReportPlanQuery,
  ReportPlanQueryVariables
>;
export const CreateReportPlanDocument = gql`
  mutation CreateReportPlan(
    $fileType: ReportFileType!
    $filterBy: ReportPlanFilterInput!
    $timezone: String
  ) {
    createReportPlan(
      fileType: $fileType
      filterBy: $filterBy
      timezone: $timezone
    )
  }
`;
export type CreateReportPlanMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportPlanMutation,
  CreateReportPlanMutationVariables
>;

/**
 * __useCreateReportPlanMutation__
 *
 * To run a mutation, you first call `useCreateReportPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportPlanMutation, { data, loading, error }] = useCreateReportPlanMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      filterBy: // value for 'filterBy'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateReportPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportPlanMutation,
    CreateReportPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateReportPlanMutation,
    CreateReportPlanMutationVariables
  >(CreateReportPlanDocument, options);
}

export type CreateReportPlanMutationHookResult = ReturnType<
  typeof useCreateReportPlanMutation
>;
export type CreateReportPlanMutationResult =
  ApolloReactCommon.MutationResult<CreateReportPlanMutation>;
export type CreateReportPlanMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateReportPlanMutation,
    CreateReportPlanMutationVariables
  >;
export const ReportUserActivityDocument = gql`
  query ReportUserActivity(
    $userId: String!
    $filterBy: ReportUserFilterInput!
    $timezone: String
  ) {
    reportUser(userId: $userId, filterBy: $filterBy, timezone: $timezone)
  }
`;

/**
 * __useReportUserActivityQuery__
 *
 * To run a query within a React component, call `useReportUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportUserActivityQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filterBy: // value for 'filterBy'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useReportUserActivityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ReportUserActivityQuery,
    ReportUserActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ReportUserActivityQuery,
    ReportUserActivityQueryVariables
  >(ReportUserActivityDocument, options);
}

export function useReportUserActivityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReportUserActivityQuery,
    ReportUserActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ReportUserActivityQuery,
    ReportUserActivityQueryVariables
  >(ReportUserActivityDocument, options);
}

export type ReportUserActivityQueryHookResult = ReturnType<
  typeof useReportUserActivityQuery
>;
export type ReportUserActivityLazyQueryHookResult = ReturnType<
  typeof useReportUserActivityLazyQuery
>;
export type ReportUserActivityQueryResult = ApolloReactCommon.QueryResult<
  ReportUserActivityQuery,
  ReportUserActivityQueryVariables
>;
export const CreateReportUserActivityDocument = gql`
  mutation CreateReportUserActivity(
    $fileType: ReportFileType!
    $filterBy: ReportUserFilterInput!
    $userId: String!
    $timezone: String
  ) {
    createReportUser(
      fileType: $fileType
      filterBy: $filterBy
      userId: $userId
      timezone: $timezone
    )
  }
`;
export type CreateReportUserActivityMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateReportUserActivityMutation,
    CreateReportUserActivityMutationVariables
  >;

/**
 * __useCreateReportUserActivityMutation__
 *
 * To run a mutation, you first call `useCreateReportUserActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportUserActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportUserActivityMutation, { data, loading, error }] = useCreateReportUserActivityMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      filterBy: // value for 'filterBy'
 *      userId: // value for 'userId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateReportUserActivityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportUserActivityMutation,
    CreateReportUserActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateReportUserActivityMutation,
    CreateReportUserActivityMutationVariables
  >(CreateReportUserActivityDocument, options);
}

export type CreateReportUserActivityMutationHookResult = ReturnType<
  typeof useCreateReportUserActivityMutation
>;
export type CreateReportUserActivityMutationResult =
  ApolloReactCommon.MutationResult<CreateReportUserActivityMutation>;
export type CreateReportUserActivityMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateReportUserActivityMutation,
    CreateReportUserActivityMutationVariables
  >;
export const UserResetPasswordDocument = gql`
  mutation userResetPassword($data: UserResetPasswordInput!) {
    userResetPassword(data: $data)
  }
`;
export type UserResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UserResetPasswordMutation,
  UserResetPasswordMutationVariables
>;

/**
 * __useUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResetPasswordMutation, { data, loading, error }] = useUserResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserResetPasswordMutation,
    UserResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserResetPasswordMutation,
    UserResetPasswordMutationVariables
  >(UserResetPasswordDocument, options);
}

export type UserResetPasswordMutationHookResult = ReturnType<
  typeof useUserResetPasswordMutation
>;
export type UserResetPasswordMutationResult =
  ApolloReactCommon.MutationResult<UserResetPasswordMutation>;
export type UserResetPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UserResetPasswordMutation,
    UserResetPasswordMutationVariables
  >;
export const UserSignInDocument = gql`
  mutation userSignIn($data: UserSignInInput!) {
    userSignIn(data: $data) {
      jwtToken
    }
  }
`;
export type UserSignInMutationFn = ApolloReactCommon.MutationFunction<
  UserSignInMutation,
  UserSignInMutationVariables
>;

/**
 * __useUserSignInMutation__
 *
 * To run a mutation, you first call `useUserSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignInMutation, { data, loading, error }] = useUserSignInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserSignInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserSignInMutation,
    UserSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserSignInMutation,
    UserSignInMutationVariables
  >(UserSignInDocument, options);
}

export type UserSignInMutationHookResult = ReturnType<
  typeof useUserSignInMutation
>;
export type UserSignInMutationResult =
  ApolloReactCommon.MutationResult<UserSignInMutation>;
export type UserSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserSignInMutation,
  UserSignInMutationVariables
>;
export const UserCreateDocument = gql`
  mutation userCreate($data: UserCreateInput!) {
    userCreate(data: $data) {
      jwtToken
    }
  }
`;
export type UserCreateMutationFn = ApolloReactCommon.MutationFunction<
  UserCreateMutation,
  UserCreateMutationVariables
>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserCreateMutation,
    UserCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserCreateMutation,
    UserCreateMutationVariables
  >(UserCreateDocument, options);
}

export type UserCreateMutationHookResult = ReturnType<
  typeof useUserCreateMutation
>;
export type UserCreateMutationResult =
  ApolloReactCommon.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserCreateMutation,
  UserCreateMutationVariables
>;
export const CompanyUserCreateDocument = gql`
  mutation CompanyUserCreate($email: String!, $role: CompanyUserRole!) {
    companyUserCreate(data: { email: $email, role: $role }) {
      ...CompanyUserWithUserFragment
    }
  }
  ${CompanyUserWithUserFragmentFragmentDoc}
`;
export type CompanyUserCreateMutationFn = ApolloReactCommon.MutationFunction<
  CompanyUserCreateMutation,
  CompanyUserCreateMutationVariables
>;

/**
 * __useCompanyUserCreateMutation__
 *
 * To run a mutation, you first call `useCompanyUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserCreateMutation, { data, loading, error }] = useCompanyUserCreateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCompanyUserCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyUserCreateMutation,
    CompanyUserCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyUserCreateMutation,
    CompanyUserCreateMutationVariables
  >(CompanyUserCreateDocument, options);
}

export type CompanyUserCreateMutationHookResult = ReturnType<
  typeof useCompanyUserCreateMutation
>;
export type CompanyUserCreateMutationResult =
  ApolloReactCommon.MutationResult<CompanyUserCreateMutation>;
export type CompanyUserCreateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyUserCreateMutation,
    CompanyUserCreateMutationVariables
  >;
export const CompanyUserDeleteDocument = gql`
  mutation CompanyUserDelete($id: UUID!) {
    companyUserDelete(id: $id)
  }
`;
export type CompanyUserDeleteMutationFn = ApolloReactCommon.MutationFunction<
  CompanyUserDeleteMutation,
  CompanyUserDeleteMutationVariables
>;

/**
 * __useCompanyUserDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserDeleteMutation, { data, loading, error }] = useCompanyUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyUserDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyUserDeleteMutation,
    CompanyUserDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyUserDeleteMutation,
    CompanyUserDeleteMutationVariables
  >(CompanyUserDeleteDocument, options);
}

export type CompanyUserDeleteMutationHookResult = ReturnType<
  typeof useCompanyUserDeleteMutation
>;
export type CompanyUserDeleteMutationResult =
  ApolloReactCommon.MutationResult<CompanyUserDeleteMutation>;
export type CompanyUserDeleteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyUserDeleteMutation,
    CompanyUserDeleteMutationVariables
  >;
export const CompanyUsersDocument = gql`
  query CompanyUsers {
    companyUsers {
      ...CompanyUserWithUserFragment
    }
  }
  ${CompanyUserWithUserFragmentFragmentDoc}
`;

/**
 * __useCompanyUsersQuery__
 *
 * To run a query within a React component, call `useCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyUsersQuery,
    CompanyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CompanyUsersQuery,
    CompanyUsersQueryVariables
  >(CompanyUsersDocument, options);
}

export function useCompanyUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompanyUsersQuery,
    CompanyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CompanyUsersQuery,
    CompanyUsersQueryVariables
  >(CompanyUsersDocument, options);
}

export type CompanyUsersQueryHookResult = ReturnType<
  typeof useCompanyUsersQuery
>;
export type CompanyUsersLazyQueryHookResult = ReturnType<
  typeof useCompanyUsersLazyQuery
>;
export type CompanyUsersQueryResult = ApolloReactCommon.QueryResult<
  CompanyUsersQuery,
  CompanyUsersQueryVariables
>;
export const CompanyUserInvitationResendDocument = gql`
  mutation CompanyUserInvitationResend($id: UUID!) {
    companyUserInvitationResend(id: $id) {
      ...CompanyUserWithUserFragment
    }
  }
  ${CompanyUserWithUserFragmentFragmentDoc}
`;
export type CompanyUserInvitationResendMutationFn =
  ApolloReactCommon.MutationFunction<
    CompanyUserInvitationResendMutation,
    CompanyUserInvitationResendMutationVariables
  >;

/**
 * __useCompanyUserInvitationResendMutation__
 *
 * To run a mutation, you first call `useCompanyUserInvitationResendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserInvitationResendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserInvitationResendMutation, { data, loading, error }] = useCompanyUserInvitationResendMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyUserInvitationResendMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyUserInvitationResendMutation,
    CompanyUserInvitationResendMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyUserInvitationResendMutation,
    CompanyUserInvitationResendMutationVariables
  >(CompanyUserInvitationResendDocument, options);
}

export type CompanyUserInvitationResendMutationHookResult = ReturnType<
  typeof useCompanyUserInvitationResendMutation
>;
export type CompanyUserInvitationResendMutationResult =
  ApolloReactCommon.MutationResult<CompanyUserInvitationResendMutation>;
export type CompanyUserInvitationResendMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyUserInvitationResendMutation,
    CompanyUserInvitationResendMutationVariables
  >;
export const CompanyUserUpdateDocument = gql`
  mutation CompanyUserUpdate(
    $role: CompanyUserRole!
    $id: UUID!
    $passwordRaw: String
  ) {
    companyUserUpdate(
      id: $id
      data: { role: $role }
      passwordRaw: $passwordRaw
    ) {
      ...CompanyUserWithUserFragment
    }
  }
  ${CompanyUserWithUserFragmentFragmentDoc}
`;
export type CompanyUserUpdateMutationFn = ApolloReactCommon.MutationFunction<
  CompanyUserUpdateMutation,
  CompanyUserUpdateMutationVariables
>;

/**
 * __useCompanyUserUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserUpdateMutation, { data, loading, error }] = useCompanyUserUpdateMutation({
 *   variables: {
 *      role: // value for 'role'
 *      id: // value for 'id'
 *      passwordRaw: // value for 'passwordRaw'
 *   },
 * });
 */
export function useCompanyUserUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyUserUpdateMutation,
    CompanyUserUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CompanyUserUpdateMutation,
    CompanyUserUpdateMutationVariables
  >(CompanyUserUpdateDocument, options);
}

export type CompanyUserUpdateMutationHookResult = ReturnType<
  typeof useCompanyUserUpdateMutation
>;
export type CompanyUserUpdateMutationResult =
  ApolloReactCommon.MutationResult<CompanyUserUpdateMutation>;
export type CompanyUserUpdateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompanyUserUpdateMutation,
    CompanyUserUpdateMutationVariables
  >;
export const ZendeskSignInDocument = gql`
  mutation zendeskSignIn {
    zendeskSignIn {
      jwtToken
    }
  }
`;
export type ZendeskSignInMutationFn = ApolloReactCommon.MutationFunction<
  ZendeskSignInMutation,
  ZendeskSignInMutationVariables
>;

/**
 * __useZendeskSignInMutation__
 *
 * To run a mutation, you first call `useZendeskSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZendeskSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zendeskSignInMutation, { data, loading, error }] = useZendeskSignInMutation({
 *   variables: {
 *   },
 * });
 */
export function useZendeskSignInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ZendeskSignInMutation,
    ZendeskSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ZendeskSignInMutation,
    ZendeskSignInMutationVariables
  >(ZendeskSignInDocument, options);
}

export type ZendeskSignInMutationHookResult = ReturnType<
  typeof useZendeskSignInMutation
>;
export type ZendeskSignInMutationResult =
  ApolloReactCommon.MutationResult<ZendeskSignInMutation>;
export type ZendeskSignInMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ZendeskSignInMutation,
    ZendeskSignInMutationVariables
  >;
export const UserWithCompaniesDocument = gql`
  query userWithCompanies {
    user {
      ...UserFragment
      companyUsers {
        ...CompanyUserWithCompanyFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${CompanyUserWithCompanyFragmentFragmentDoc}
`;

/**
 * __useUserWithCompaniesQuery__
 *
 * To run a query within a React component, call `useUserWithCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithCompaniesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserWithCompaniesQuery,
    UserWithCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserWithCompaniesQuery,
    UserWithCompaniesQueryVariables
  >(UserWithCompaniesDocument, options);
}

export function useUserWithCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserWithCompaniesQuery,
    UserWithCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserWithCompaniesQuery,
    UserWithCompaniesQueryVariables
  >(UserWithCompaniesDocument, options);
}

export type UserWithCompaniesQueryHookResult = ReturnType<
  typeof useUserWithCompaniesQuery
>;
export type UserWithCompaniesLazyQueryHookResult = ReturnType<
  typeof useUserWithCompaniesLazyQuery
>;
export type UserWithCompaniesQueryResult = ApolloReactCommon.QueryResult<
  UserWithCompaniesQuery,
  UserWithCompaniesQueryVariables
>;
