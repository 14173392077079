import {
  EuiAvatar,
  EuiComment,
  EuiFlexGroup,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import React, { FC, HTMLAttributes } from "react";

import { UserWithCompaniesQuery } from "../../../../../../graphql/graphql";
import { WOComment } from "../../../../../../graphql/types/queries/workOrdersQuery";
import TextareaMarkdown from "../TextareaMarkdown";
import CommentItem from "./commentItem";

interface CommentsPresenterProps extends HTMLAttributes<HTMLDivElement> {
  timezone: string;
  comments?: WOComment[];
  currentUser: UserWithCompaniesQuery["user"];
  onChange: (value: any) => void;
  save: () => void;
  cancel?: () => void;
  value: string;
}

const CommentsPresenter: FC<CommentsPresenterProps> = ({
  timezone,
  comments = [],
  currentUser,
  onChange,
  cancel,
  save,
  value,
}) => {
  const { euiTheme }: UseEuiTheme<{ border: any; sizes: any; colors: any }> =
    useEuiTheme();
  const MAX_INPUT_LENGTH = "500px";
  return (
    <EuiFlexGroup
      direction="column"
      justifyContent="flexStart"
      alignItems="stretch"
      gutterSize="none"
    >
      <EuiText
        style={{
          fontSize: euiTheme.size.base,
          fontWeight: euiTheme.font.weight.medium,
          lineHeight: euiTheme.sizes["18px"],
          marginBottom: euiTheme.size.base,
        }}
        color={euiTheme.colors.title}
      >
        Comments {!!comments.length && `(${comments.length})`}
      </EuiText>
      <EuiComment
        username={currentUser.fullName}
        timelineAvatar={
          <EuiAvatar
            color={euiTheme.colors.schemePrimary["500"]}
            name={currentUser.fullName || ""}
          />
        }
      >
        <TextareaMarkdown
          value={value}
          save={save}
          cancel={cancel}
          onChange={onChange}
          placeholder="Add comment..."
          saveButtonName="Add Comment"
          style={{
            padding: euiTheme.size.m,
            paddingBottom: euiTheme.sizes["15px"],
            cursor: "pointer",
            marginBottom: euiTheme.size.l,
            maxHeight: MAX_INPUT_LENGTH,
            overflow: "auto",
            fontWeight: euiTheme.font.weight.regular,
            fontSize: euiTheme.sizes["14px"],
            lineHeight: euiTheme.sizes["17px"],
            color: euiTheme.colors.text,
            border: euiTheme.border.thin,
            borderRadius: euiTheme.sizes["6px"],
          }}
        />
      </EuiComment>
      {!!comments.length &&
        comments
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((comment) => (
            <CommentItem
              key={comment.id}
              comment={comment}
              timezone={timezone}
            />
          ))}
    </EuiFlexGroup>
  );
};

export default CommentsPresenter;
