import React from "react";

import AddPaymentMethod from "../../../components/elements/addPaymentMethod";

declare let window: any;

interface CreatePaymentPageProps {}

const NativeCreatePaymentPage: React.FC<CreatePaymentPageProps> = () => {
  const handlePaymentMethodAdded = () => {
    if (window.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ eventType: "CA:PaymentCreated" })
      );
    }
  };

  return <AddPaymentMethod paymentMethodAdded={handlePaymentMethodAdded} />;
};

export default NativeCreatePaymentPage;
