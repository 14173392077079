import { Icon, IconProps } from "@chakra-ui/react";

const EyeWash = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M27.648 36.357a1.667 1.667 0 0 0 0 1.953c.662.913 16.4 22.357 36.352 22.357 19.952 0 35.69-21.444 36.352-22.357a1.67 1.67 0 0 0 0-1.953C99.69 35.443 83.952 14 64 14c-19.952 0-35.69 21.443-36.352 22.357ZM44 37.333A20.022 20.022 0 0 1 61.74 17.47c.75-.068 1.5-.137 2.26-.137.76 0 1.51.069 2.26.137a19.99 19.99 0 0 1 0 39.727c-.75.068-1.5.136-2.26.136-.76 0-1.51-.068-2.26-.136A20.021 20.021 0 0 1 44 37.333Zm52.895 0A70.243 70.243 0 0 1 83.488 50.13a23.217 23.217 0 0 0 0-25.593 70.246 70.246 0 0 1 13.407 12.796ZM44.512 24.537a23.216 23.216 0 0 0 0 25.593 70.243 70.243 0 0 1-13.407-12.797 70.246 70.246 0 0 1 13.407-12.796Z"
      fill="currentColor"
    />
    <path
      d="M74 37.334a10 10 0 1 0-10 10 10.01 10.01 0 0 0 10-10Zm-16.667 0a6.667 6.667 0 1 1 13.334 0 6.667 6.667 0 0 1-13.334 0ZM64 67.333A1.667 1.667 0 1 0 64 64a1.667 1.667 0 0 0 0 3.333ZM64 74a1.667 1.667 0 1 0 0-3.333A1.667 1.667 0 0 0 64 74ZM64 80.667a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334ZM64 87.333A1.667 1.667 0 1 0 64 84a1.667 1.667 0 0 0 0 3.333ZM49 67.333A1.667 1.667 0 1 0 49 64a1.667 1.667 0 0 0 0 3.333ZM52.333 74a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333ZM55.667 80.667a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334ZM57.333 87.333a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333ZM49 87.333A1.667 1.667 0 1 0 49 84a1.667 1.667 0 0 0 0 3.333ZM45.667 80.667a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334ZM42.333 74a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333ZM79 67.333A1.667 1.667 0 1 0 79 64a1.667 1.667 0 0 0 0 3.333ZM75.667 74a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333ZM72.333 80.667a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334ZM70.667 87.333a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333ZM79 87.333A1.667 1.667 0 1 0 79 84a1.667 1.667 0 0 0 0 3.333ZM82.333 80.667a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333ZM85.667 74a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333ZM62.333 114h3.334A3.333 3.333 0 0 0 69 110.667V104h6.667A8.346 8.346 0 0 0 84 95.667V94a3.334 3.334 0 0 0-3.333-3.333H47.333A3.333 3.333 0 0 0 44 94v1.667A8.343 8.343 0 0 0 52.333 104H59v6.667A3.333 3.333 0 0 0 62.333 114Zm3.334-3.333h-3.334V104h3.334v6.667Zm-18.334-15V94h33.334v1.667a5 5 0 0 1-5 5H52.333a5 5 0 0 1-5-5Z"
      fill="currentColor"
    />
  </Icon>
);

export default EyeWash;
