import React from "react";

interface AssetIconProps {
  width?: number;
  height?: number;
}

const AssetIcon = (props: AssetIconProps) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="6" cy="2.79586" rx="6" ry="2.79586" fill="inherit" />
    <path
      d="M0 6.83286V4.34766C0.333333 5.59026 2.625 6.83286 6 6.83286C9.375 6.83286 11.6667 5.59026 12 4.34766V6.83286C11 8.07547 8.625 8.69676 6 8.69677C3.375 8.69677 1 8.07547 0 6.83286Z"
      fill="inherit"
    />
    <path
      d="M0 10.1375V7.65234C0.333333 8.89495 2.625 10.1375 6 10.1375C9.375 10.1375 11.6667 8.89495 12 7.65234V10.1375C11 11.3802 8.625 12.0014 6 12.0015C3.375 12.0015 1 11.3802 0 10.1375Z"
      fill="inherit"
    />
  </svg>
);

export default AssetIcon;
