import { Icon, IconProps } from "@chakra-ui/react";

const TransferSwitch = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M89.659 34.867a2.44 2.44 0 0 1-2.684 4.01 27.401 27.401 0 0 0-11.688-2.586c-14.23 0-25.982 10.809-27.48 24.647l-.06.554h5.972a2.44 2.44 0 0 1 1.725 4.166L37.158 83.944a2.43 2.43 0 0 1-1.725.715 2.432 2.432 0 0 1-1.726-.715L15.42 65.658a2.441 2.441 0 0 1 1.726-4.166h6.503l.034-.464C25.175 40.636 42.245 24.5 63.01 24.5c9.897 0 19.36 3.682 26.649 10.367Zm-42.686 32.36.854-.854h-2.622a2.44 2.44 0 0 1-2.44-2.44c0-16.83 12.852-30.716 29.256-32.36l.082-.98a34.613 34.613 0 0 0-9.092-1.211c-19.052 0-34.551 15.499-34.551 34.55a2.44 2.44 0 0 1-2.44 2.441h-2.981l.853.854L35.08 78.413l.354.354.353-.354 11.187-11.186ZM94.293 44.056l18.286 18.286a2.44 2.44 0 0 1-1.725 4.167H104.35l-.034.463C102.824 87.364 85.755 103.5 64.99 103.5A39.322 39.322 0 0 1 38.34 93.133l-.302.33.302-.33a2.44 2.44 0 0 1 2.684-4.01 27.403 27.403 0 0 0 11.689 2.586c14.23 0 25.981-10.809 27.48-24.647l.06-.554H74.281a2.44 2.44 0 0 1-1.726-4.166l18.286-18.286a2.44 2.44 0 0 1 3.451 0ZM55.98 96.426l-.081.98a34.613 34.613 0 0 0 9.092 1.212c19.052 0 34.55-15.498 34.55-34.55a2.44 2.44 0 0 1 2.441-2.441h2.98l-.853-.854-11.187-11.186-.353-.354-.354.354-11.186 11.186-.854.854h2.621a2.44 2.44 0 0 1 2.44 2.44c0 16.83-12.851 30.717-29.256 32.36Z"
      fill="currentColor"
    />
  </Icon>
);

export default TransferSwitch;
