import { Alert, AlertDescription } from "@chakra-ui/react";
import React from "react";

import { getRoutePath } from "../../../../../router";
import Link from "../../../../elements/link";

interface PhoneConfirmProps {}

const PhoneConfirm: React.FC<PhoneConfirmProps> = () => {
  return (
    <Alert status="error">
      <AlertDescription>
        <Link
          to={getRoutePath("mySettingsChangePhone")}
          variant="link"
          colorScheme="white"
        >
          Welcome! Click here to verify your phone number
        </Link>
      </AlertDescription>
    </Alert>
  );
};

export default PhoneConfirm;
