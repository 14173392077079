import { useEffect, useState } from "react";

import useDebounce from "./useDebounce";

const useAddressAutoSuggest = (
  query: string,
  { country, types = "address" }: { country?: string; types?: string }
) => {
  const debouncedSearchTerm = useDebounce(query, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    let mounted = true;

    const loadAddress = async (debouncedSearchTerm: string) => {
      try {
        setIsLoading(true);
        const result: any = await (
          await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${debouncedSearchTerm}.json?access_token=${
              process.env.REACT_APP_MAPBOX_PUBLIC_KEY
            }${country ? `&country=${country}` : ""}&types=${types}`
          )
        ).json();
        if (mounted) setResults(result.features || []);
      } catch {
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (debouncedSearchTerm) {
      loadAddress(debouncedSearchTerm);
    } else {
      setResults([]);
    }

    return () => {
      mounted = false;
    };
  }, [debouncedSearchTerm, country, types]);

  return { isLoading, results, resetResults: () => setResults([]) };
};

export default useAddressAutoSuggest;
