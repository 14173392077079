import { Icon, IconProps } from "@chakra-ui/react";

const ServerRack = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fill="currentColor"
      d="M104.363 60.846c3.829 0 6.945-3.116 6.945-6.945V29.945c0-3.829-3.116-6.945-6.945-6.945H23.637c-3.829 0-6.945 3.116-6.945 6.945v23.96c0 3.825 3.116 6.941 6.945 6.941h2.517v6.308h-2.517c-3.829 0-6.945 3.116-6.945 6.945v23.96c0 3.825 3.116 6.941 6.945 6.941h80.729c3.826 0 6.942-3.116 6.942-6.945v-23.96c0-3.825-3.116-6.941-6.945-6.941h-2.517v-6.308h2.517ZM23 53.901V29.945c0-.354.284-.637.637-.637h80.729c.35 0 .634.284.634.637v23.96c0 .35-.284.633-.637.633H23.637a.635.635 0 0 1-.637-.637ZM105 74.1v23.96c0 .35-.284.633-.637.633H23.637a.635.635 0 0 1-.637-.637v-23.96c0-.35.284-.633.637-.633H104.363c.353 0 .637.283.637.637Zm-9.462-6.945H32.462v-6.308h63.076v6.308Z"
    />
    <path
      fill="currentColor"
      d="M57.692 89.23a3.154 3.154 0 1 0 0-6.307 3.154 3.154 0 0 0 0 6.308ZM45.077 89.23a3.154 3.154 0 1 0 0-6.307 3.154 3.154 0 0 0 0 6.308ZM32.462 89.23a3.154 3.154 0 1 0 0-6.307 3.154 3.154 0 0 0 0 6.308ZM95.538 82.923H73.462a3.155 3.155 0 0 0 0 6.308h22.076a3.155 3.155 0 0 0 0-6.308ZM57.692 45.077a3.154 3.154 0 1 0 0-6.308 3.154 3.154 0 0 0 0 6.308ZM45.077 45.077a3.154 3.154 0 1 0 0-6.308 3.154 3.154 0 0 0 0 6.308ZM32.462 45.077a3.154 3.154 0 1 0 0-6.308 3.154 3.154 0 0 0 0 6.308ZM73.462 45.077h22.076a3.155 3.155 0 0 0 0-6.308H73.462a3.155 3.155 0 0 0 0 6.308Z"
    />
  </Icon>
);

export default ServerRack;
