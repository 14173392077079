import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

interface LinkProps extends ButtonProps {
  to: RouterLinkProps["to"];
  target?: string;
  rel?: string;
}

const Link: React.FC<LinkProps> = React.forwardRef((props, ref: any) => {
  return (
    <Button
      fontSize="base"
      minWidth="0"
      {...props}
      ref={ref}
      as={RouterLinkWrap}
    />
  );
});

export default Link;

const RouterLinkWrap = React.forwardRef((props: RouterLinkProps, ref: any) => (
  <RouterLink {...props} ref={ref} />
));
