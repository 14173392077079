import { Input, Textarea } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { sortBy } from "lodash";
import React from "react";

import {
  AssetFieldFragmentFragment,
  AssetTypeWithRelationsFragmentFragment,
  AssetWithRelationsFragmentFragment,
  FieldType,
} from "../../../graphql/graphql";
import { yupBoolean, yupString } from "../../../utils/validation";
import FormGroup from "../../elements/formGroup";
import Autosuggest from "../autosuggest";
import CheckBoxInput from "../checkbox";
import SelectCombobox from "../selectCombobox";

interface AssetFieldsProps {
  assetFields: AssetFieldFragmentFragment[];
  autoFocus?: boolean;
}

const AssetFields: React.FC<AssetFieldsProps> = ({
  assetFields,
  autoFocus = false,
}) => {
  const fields = sortBy(assetFields, "order");
  const { getFieldProps } = useFormikContext();
  return (
    <>
      {fields.map((assetField, index) =>
        assetField.type === FieldType.Boolean ? (
          <CheckBoxInput
            key={assetField.id}
            label={assetField.label}
            name={assetField.id}
            autoFocus={autoFocus && index === 0}
          />
        ) : assetField.type === FieldType.Select ? (
          <SelectCombobox
            key={assetField.id}
            label={assetField.label}
            name={assetField.id}
            options={
              assetField.selectOptions
                ? assetField.selectOptions
                    .split("\n")
                    .map((so) => ({ label: so, query: so, value: so }))
                : []
            }
            autoFocus={autoFocus && index === 0}
          />
        ) : (
          <FormGroup
            key={assetField.id}
            label={assetField.label}
            name={assetField.id}
          >
            {assetField.type === FieldType.Text ? (
              <Textarea
                autoComplete="off"
                autoFocus={autoFocus && index === 0}
                {...getFieldProps(assetField.id)}
              />
            ) : assetField.type === FieldType.Autosuggest &&
              assetField.selectOptions ? (
              <Autosuggest
                items={assetField.selectOptions.split("\n")}
                autoComplete="off"
                autoFocus={autoFocus && index === 0}
                {...getFieldProps(assetField.id)}
              />
            ) : (
              <Input
                autoComplete="off"
                {...getFieldProps(assetField.id)}
                autoFocus={autoFocus && index === 0}
              />
            )}
          </FormGroup>
        )
      )}
    </>
  );
};

export default AssetFields;

export const getAssetFieldsValidationSchema = (
  assetType: AssetTypeWithRelationsFragmentFragment
) => {
  const yupShape: any = {};

  assetType.assetFields.forEach((assetField) => {
    let schema =
      assetField.type === FieldType.Boolean ? yupBoolean() : yupString();
    schema = schema.label(assetField.label);
    if (assetField.required) schema = schema.required();
    if (schema.isType("string") && assetField.validationRegExp) {
      try {
        schema = (schema as any).matches(
          new RegExp(assetField.validationRegExp),
          {
            message:
              assetField.validationRegExpMessage ||
              "Please enter correct value",
            excludeEmptyString: true,
          }
        );
      } catch (e) {}
    }
    yupShape[assetField.id] = schema;
  });

  return yupShape;
};

export const getAssetFieldsInitialValues = (
  assetType: AssetTypeWithRelationsFragmentFragment,
  asset?: AssetWithRelationsFragmentFragment
) => {
  const initialValues: any = {};
  assetType.assetFields.forEach((assetField) => {
    initialValues[assetField.id] =
      assetField.type === FieldType.Boolean ? false : "";
    if (asset) {
      const assetFieldValue = asset.assetFieldValues.find(
        (aft) => aft.assetFieldId === assetField.id
      );
      if (assetFieldValue && assetFieldValue.value)
        initialValues[assetField.id] = assetFieldValue.value;
    }
  });
  return initialValues;
};
