import { useReactiveVar } from "@apollo/client";
import { EuiIcon, useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Link from "../../../../components/elements/link";
import { useLocationIndexQuery } from "../../../../graphql/graphql";
import { currentCompanyVar } from "../../../../graphql/reactiveVariables";
import { WOLocation } from "../../../../graphql/types/queries/workOrdersQuery";
import { getRoutePath } from "../../../../router";
import SublocationSelectModal from "../../components/sublocationSelectModal";
import { createWORedirectData } from "../../helpers/workOrdersReactiveVariables";
import { WorkOrderCreateRedirectData } from "../../models/createWORedirectData";

interface WorkOrderCreateButtonProps {
  title?: string;
  assetId?: string;
  locationId?: string;
  availableLocationIds?: string[];
  setLocation?: boolean;
  button?: ReactNode;
  disabled?: boolean;
}

const WorkOrderCreateButton: React.FC<WorkOrderCreateButtonProps> = ({
  title,
  assetId,
  locationId,
  setLocation,
  availableLocationIds,
  button,
  disabled,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any; colors: any }> = useEuiTheme();
  const navigate = useNavigate();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [isSublocationModalOpen, setIsSublocationModalOpen] =
    useState<boolean>(false);
  const [WOCreateData, setWOCreateData] = useState<WorkOrderCreateRedirectData>(
    {}
  );

  const { data, loading } = useLocationIndexQuery({
    fetchPolicy: "network-only",
    variables: {
      companyId: currentCompany?.id,
    },
    skip: !currentCompany?.id,
  });

  const getLocationParent = (
    location: WOLocation,
    locationsData: WOLocation[] = []
  ): WOLocation => {
    if (location.parentId && locationsData.length) {
      const parent = locationsData.find((loc) => loc.id === location.parentId);
      return parent?.parentId
        ? getLocationParent(parent, locationsData)
        : parent || location;
    } else {
      return location;
    }
  };

  const handleLinkClick = (event: any) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (setLocation) {
      event.preventDefault();
      event.stopPropagation();
      !isSublocationModalOpen && setIsSublocationModalOpen(true);
    } else {
      handleRedirectDataAccepted();
    }
  };

  const onWOCreateRedirectDataChange = (
    dredirectData: WorkOrderCreateRedirectData
  ) => {
    const sublocationId =
      dredirectData.sublocationId || WOCreateData.sublocationId;
    if (
      !WOCreateData?.locationId &&
      !dredirectData.locationId &&
      sublocationId
    ) {
      const sublocation = data?.locations.find(
        (loc) => loc.id === sublocationId
      );
      dredirectData.locationId = sublocation
        ? getLocationParent(sublocation, data?.locations).id
        : null;
    }
    setWOCreateData({ ...WOCreateData, ...dredirectData });
  };

  const closeSublocationModal = () => {
    setIsSublocationModalOpen(false);
  };

  const handleRedirectDataAccepted = () => {
    createWORedirectData(WOCreateData);
    navigate(getRoutePath("workOrdersCreate"));
  };

  useEffect(() => {
    let parentLocationId = locationId;
    if (data?.locations?.length && parentLocationId) {
      const parentLocation = data.locations.find(
        (loc) => loc.id === parentLocationId
      );
      parentLocationId = parentLocation
        ? getLocationParent(parentLocation, data.locations)?.id
        : locationId;
    }
    setWOCreateData(() => {
      return {
        ...WOCreateData,
        assetId: assetId,
        locationId: parentLocationId,
        sublocationId: locationId,
        companyId: currentCompany?.id,
      };
    });
  }, [assetId, locationId, currentCompany, data]);

  return (
    <>
      {button ? (
        <div onClick={handleLinkClick}>{button}</div>
      ) : (
        <Link
          to={getRoutePath("workOrdersCreate")}
          aria-label={title ? title : "Add new Work Order"}
          variant="icon"
          colorScheme="secondary"
          disabled={disabled}
          borderRadius={euiTheme.border.radius.medium}
          onClick={handleLinkClick}
          css={
            !disabled
              ? css`
                  &:hover {
                    background-color: ${euiTheme.colors.secondary["500"]};

                    & .create-work-order-button {
                      color: ${euiTheme.colors.emptyShade};
                    }
                  }
                `
              : css`
                  & .create-work-order-button {
                    cursor: not-allowed;
                  }
                `
          }
        >
          <button className={"create-work-order-button"}>
            <EuiIcon type="plusInCircle" />
            <div>{title ? title : "Create New Work Order"}</div>
          </button>
        </Link>
      )}
      <SublocationSelectModal
        closeModal={closeSublocationModal}
        isOpen={isSublocationModalOpen}
        WOCreateData={WOCreateData}
        availableLocationIds={availableLocationIds}
        onDataAccepted={handleRedirectDataAccepted}
        locations={data?.locations}
        locationsLoading={loading}
        onWOCreateRedirectDataChange={onWOCreateRedirectDataChange}
      />
    </>
  );
};

export default WorkOrderCreateButton;
