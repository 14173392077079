import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import PageSpinner from "../../../components/elements/pageSpinner";
import { useCompanyQuery } from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import Presenter from "./presenter";

interface CompanyContactChangePageProps {}

const CompanyContactChangePage: React.FC<
  CompanyContactChangePageProps
> = () => {
  const { data: currentCompanyData } = useCompanyQuery();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("companySettings"));
    }, 500);
  };

  return (
    <>
      <Helmet>
        <title>Company Contact Info</title>
      </Helmet>
      <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Company Contact Info</DrawerHeader>
            <DrawerBody>
              <Box marginTop="10">
                {currentCompanyData ? (
                  <Presenter currentCompany={currentCompanyData.company} />
                ) : (
                  <PageSpinner />
                )}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default CompanyContactChangePage;
