import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Locations = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 24 24" boxSize="24px" {...props}>
    <path d="M12.057 3c2.56.075 4.655 1.124 5.973 3.297.776 1.273 1.086 2.622.931 4.045-.077 1.05-.465 1.948-1.008 2.847-.62.974-1.241 1.948-1.94 2.847-1.086 1.499-2.172 2.997-3.258 4.57-.388.525-1.086.525-1.474 0-1.551-2.172-3.18-4.345-4.732-6.593a11.185 11.185 0 01-1.164-2.097 5.702 5.702 0 01-.31-2.997c.31-2.098 1.396-3.746 3.258-4.87C9.497 3.299 10.738 3 12.057 3zm2.948 6.743c0-1.573-1.319-2.847-2.87-2.772-1.707 0-3.026 1.199-2.948 2.772 0 1.573 1.318 2.772 2.87 2.772 1.629 0 2.948-1.199 2.948-2.772z" />
  </Icon>
);

export default Locations;
