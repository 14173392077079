import { ApolloClient, ApolloProvider } from "@apollo/client";
import { Box, CSSReset, ChakraProvider } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { useWindowSize } from "react-use";

import RouterAllPage from "./allPageRoutes";
import getClient from "./apolloClient";
import chakraTheme from "./chakraTheme";
import StartUpChecks from "./components/elements/startUpChecks";
import ElasticUIProvider from "./elasticUITheme/elasticUIProvider";
import FontFaces from "./fonts";
import useDebounce from "./hooks/useDebounce";
import Router from "./router";
import Tracking from "./tracking";

const App = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
  const { height } = useWindowSize();
  const debouncedHeight = useDebounce(height, 200);
  const [client, setClient] = React.useState<ApolloClient<any>>();

  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${debouncedHeight * 0.01}px`
    );
  }, [debouncedHeight]);

  React.useEffect(() => {
    const initClient = async () => {
      setClient(await getClient());
    };
    initClient();
  }, [setClient]);

  return client ? (
    <ChakraProvider theme={chakraTheme as any}>
      <ElasticUIProvider>
        <Box letterSpacing="0.025rem" color="black">
          <CSSReset />
          <FontFaces />
          <ApolloProvider client={client}>
            <StartUpChecks>
              <Elements stripe={stripePromise}>
                <BrowserRouter>
                  <Router />
                  <RouterAllPage />
                  <Tracking />
                </BrowserRouter>
              </Elements>
            </StartUpChecks>
          </ApolloProvider>
        </Box>
      </ElasticUIProvider>
    </ChakraProvider>
  ) : null;
};

export default App;
