import React from "react";

import { WorkOrder } from "../../../../models/workOrder";
import WorkOrderEditorPresenter from "./presenter";

export interface WorkOrderEditorProps {
  workOrder: WorkOrder;
  changeIsEdit?: (isEditMode: boolean) => void;
  onWorkOrderChange: (workOrder: Partial<WorkOrder>) => void;
}

const WorkOrderEditor: React.FC<WorkOrderEditorProps> = ({
  workOrder,
  changeIsEdit,
  onWorkOrderChange,
}) => {
  return (
    <>
      <WorkOrderEditorPresenter
        workOrder={workOrder}
        changeIsEdit={changeIsEdit}
        onWorkOrderChange={onWorkOrderChange}
      />
    </>
  );
};

export default WorkOrderEditor;
