import "leaflet-polylinedecorator";
import "leaflet-geometryutil";
import "leaflet-editable";

import { useEuiTheme } from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { FeatureCollection } from "geojson";
import L, { LatLngTuple } from "leaflet";
import { FC, useEffect } from "react";
import { PolylineProps, useMap } from "react-leaflet";

import handleException from "../../../../utils/error";

export type AssetConnection = [[number, number], [number, number]][];

export interface AssetConnectionsData {
  coordinates: AssetConnection;
  fromAssetId: string;
  toAssetId: string;
}

interface AssetConnectionLinesProps {
  fedFromConnections?: AssetConnectionsData[];
  fedToConnections?: AssetConnectionsData[];
  geoJSON?: FeatureCollection;
  assetCoordinates?: LatLngTuple;
  assetId?: string;
}

const AssetConnectionLines: FC<AssetConnectionLinesProps> = ({
  fedFromConnections,
  fedToConnections,
  geoJSON,
  assetCoordinates,
}) => {
  const map = useMap();
  const { euiTheme }: UseEuiTheme<{ colors: any }> = useEuiTheme();

  let closestPoint: any[] = [];
  if (geoJSON && assetCoordinates) {
    geoJSON.features.forEach((feature: any) => {
      try {
        if (feature.properties?.radius) {
          closestPoint.push({
            lng: feature.geometry.coordinates[1],
            lat: feature.geometry.coordinates[0],
          });
        } else {
          closestPoint.push(
            L.GeometryUtil.closest(
              map,
              feature.geometry.coordinates,
              assetCoordinates
            )
          );
        }
      } catch (error: any) {
        handleException(error);
      }
    });
  }

  return (
    <>
      {!!assetCoordinates &&
        closestPoint.map((point, i) => (
          <PolylineDecorator
            key={i}
            positions={[
              [assetCoordinates[1], assetCoordinates[0]],
              [point.lng, point.lat],
            ]}
            pathOptions={{ color: euiTheme.colors.secondary["500"] }}
            tooltip="Affected area"
          />
        ))}
      {!!fedToConnections &&
        fedToConnections.map((c, i) => (
          <PolylineDecorator
            key={i}
            positions={c.coordinates}
            pathOptions={{ color: euiTheme.colors.secondary["500"] }}
            tooltip="Fed to"
          />
        ))}
      {!!fedFromConnections &&
        fedFromConnections.map((c, i) => (
          <PolylineDecorator
            key={i}
            positions={c.coordinates}
            pathOptions={{ color: euiTheme.colors.schemePrimary["500"] }}
            tooltip="Fed from"
          />
        ))}
    </>
  );
};

export default AssetConnectionLines;

interface PolylineDecoratorProps extends PolylineProps {
  tooltip: string;
  editable?: boolean;
  handleConnectionChange?: (points: AssetConnection) => void;
}

const PolylineDecorator: FC<PolylineDecoratorProps> = (props) => {
  const map = useMap();
  const onConnectionChange = props.handleConnectionChange;

  useEffect(() => {
    const polyline = (L as any).polyline(props.positions, props.pathOptions);
    const polylineDecorator = L.polylineDecorator(polyline, {
      patterns: [
        {
          offset: "50%",
          repeat: 0,
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            pathOptions: { ...props.pathOptions, weight: 4 },
          }),
        },
      ],
    });
    polyline.bindTooltip(props.tooltip);
    polyline.addTo(map);
    polylineDecorator.bindTooltip(props.tooltip);
    polylineDecorator.addTo(map);

    return () => {
      polyline.remove();
      polylineDecorator?.remove();
    };
  }, [props, map, onConnectionChange]);

  return null;
};
