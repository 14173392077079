import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { addMonths, setHours, setMinutes } from "date-fns";
import { Form, Formik } from "formik";
import React from "react";

import {
  MaintenanceDurationType,
  MaintenanceIntervalType,
  MaintenanceRemindBeforeType,
} from "../../../graphql/graphql";
import { timezone as defaultTimezone } from "../../../utils/date/timezones";
import { assetMaintenanceFormValidationSchema } from "../../../utils/validation";
import Autosuggest from "../autosuggest";
import DatePicker from "../datePickerField";
import FormGroup from "../formGroup";
import FormSectionTitle from "../formSectionTitle";
import MaintenanceIntervalField from "../maintenanceIntervalField";
import MaintenanceRemindBeforeField from "../maintenanceRemindBeforeField";
import TimezoneField from "../timezoneField";

interface ModalNewFormProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (data: MaintenanceTemplateFormData) => void;
  showAllFields?: boolean;
  name?: string;
  durationType?: MaintenanceDurationType;
  durationValue?: number;
  intervalType?: MaintenanceIntervalType;
  intervalValue?: number;
  startDateTime?: Date;
  timezone?: string;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
  title?: string;
}

const ModalNewForm: React.FC<ModalNewFormProps> = ({
  isOpen,
  onClose,
  handleSubmit,
  showAllFields = false,
  name,
  intervalType,
  intervalValue,
  startDateTime,
  timezone,
  remindBeforeType,
  remindBeforeValue,
  durationType,
  durationValue,
  title,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      portalProps={{ appendToParentPortal: false }}
    >
      <ModalOverlay zIndex={1700}>
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{title ?? "Create New Service Event"}</ModalHeader>
          <ModalBody paddingBottom="6">
            <Formik
              initialValues={{
                name: name ?? "",
                intervalType: intervalType ?? MaintenanceIntervalType.Months,
                intervalValue: intervalValue ?? 1,
                startDateTime:
                  startDateTime ??
                  setHours(setMinutes(addMonths(new Date(), 1), 0), 9),
                timezone: timezone ?? defaultTimezone,
                remindBeforeType:
                  remindBeforeType ?? MaintenanceRemindBeforeType.Minutes,
                remindBeforeValue: remindBeforeValue ?? 1,
                // TODO: default 1 min for now, we have to change to one hour later on
                durationType: durationType ?? MaintenanceDurationType.Minutes,
                durationValue: durationValue ?? 1,
              }}
              onSubmit={(data) => handleSubmit(data)}
              validationSchema={assetMaintenanceFormValidationSchema}
            >
              <Form noValidate id="maintenance_template_form">
                {showAllFields && (
                  <FormGroup label="Title" name="name">
                    <Autosuggest
                      items={[
                        "Service reminder",
                        "Inspection",
                        "Tune up",
                        "Calibration",
                      ]}
                      autoComplete="off"
                      name="name"
                      autoFocus
                    />
                  </FormGroup>
                )}
                <FormSectionTitle marginBottom="2" mt="6">
                  Reminders
                </FormSectionTitle>
                <DatePicker
                  name="startDateTime"
                  label="Send reminder on"
                  showTimeSelect
                  timeIntervals={15}
                />
                <TimezoneField name="timezone" />
                <Box as="fieldset">
                  <MaintenanceRemindBeforeField hideContactUsersField />
                </Box>
                {showAllFields && (
                  <Box as="fieldset" marginTop="3">
                    <MaintenanceIntervalField alwaysOn hideEndOption />
                  </Box>
                )}
              </Form>
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Button w="full" form="maintenance_template_form" type="submit">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ModalNewForm;

type MaintenanceTemplateFormData = {
  name: string;
  durationType: MaintenanceDurationType;
  durationValue: number;
  intervalType: MaintenanceIntervalType;
  intervalValue: number;
  startDateTime: Date;
  timezone: string;
  remindBeforeType?: MaintenanceRemindBeforeType;
  remindBeforeValue?: number;
};
