import { Icon, IconProps } from "@chakra-ui/react";

const Clock = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <circle
      cx={64}
      cy={64}
      r={47}
      stroke="currentColor"
      strokeWidth={6}
      fillOpacity={0}
    />
    <path
      d="M62 36v30h26"
      stroke="currentColor"
      strokeWidth={6}
      fillOpacity={0}
    />
  </Icon>
);

export default Clock;
