import {
  Avatar,
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

import AdminIcon from "../../components/icons/admin";
import FlagIcon from "../../components/icons/flag";
import Manager from "../../components/icons/manager";
import UserIcon from "../../components/icons/user";
import { canceledPlanStatus } from "../../constants/plan";
import {
  CompanyUserRole,
  CompanyUserWithCompanyFragmentFragment,
  useCompanyQuery,
} from "../../graphql/graphql";
import MyCompanyDelete from "./delete";
import SwitchCompany from "./switch";

interface MyCompaniesPagePresenterProps {
  companyUsers: CompanyUserWithCompanyFragmentFragment[];
}

const MyCompaniesPagePresenter: React.FC<MyCompaniesPagePresenterProps> = ({
  companyUsers,
}) => {
  const { colors } = useTheme();
  const { data: currentCompanyData } = useCompanyQuery();

  return (
    <Box paddingBottom="8">
      <List paddingBottom="4">
        {companyUsers.map((companyUser) => (
          <ListItem
            role="group"
            marginTop="4"
            backgroundColor="secondary.10"
            border={
              companyUser.company.id === currentCompanyData?.company.id
                ? `2px solid ${colors.secondary[100]}`
                : "none"
            }
            _hover={{ boxShadow: "md" }}
            key={companyUser.id}
          >
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              width="full"
              padding="4"
              height={{ base: "104px", md: "88px" }}
            >
              <Box
                marginLeft="auto"
                marginRight="3"
                flexGrow={1}
                isTruncated
                display="inherit"
                alignItems="center"
              >
                <Avatar
                  name={companyUser.company.name}
                  size="sm"
                  bg="black"
                  color="white"
                />
                <Box marginX="2" flexGrow={1} minWidth="0">
                  <Heading
                    as="h3"
                    fontWeight="medium"
                    fontSize={{ base: "sm", md: "base" }}
                    flexGrow={1}
                    isTruncated
                  >
                    {companyUser.company.name}
                  </Heading>
                  <Flex alignItems="center" flex="1">
                    {companyUser.role === CompanyUserRole.Manager ? (
                      <Manager boxSize="4" />
                    ) : companyUser.role === CompanyUserRole.Admin ? (
                      <AdminIcon boxSize="4" />
                    ) : companyUser.role === CompanyUserRole.Owner ? (
                      <FlagIcon boxSize="4" />
                    ) : (
                      <UserIcon boxSize="4" />
                    )}
                    <Text
                      marginX="1"
                      fontSize="sm"
                      color="gray.600"
                      textTransform="capitalize"
                    >
                      {companyUser.role === CompanyUserRole.Owner ? (
                        `${companyUser.role
                          .toLowerCase()
                          .replace("_", " ")} (Me)`
                      ) : (
                        <Text as="span">
                          {companyUser.role.toLowerCase().replace("_", " ")}
                        </Text>
                      )}
                    </Text>
                  </Flex>
                </Box>
              </Box>
              <Flex direction="column" alignItems="center">
                <SwitchCompany
                  company={companyUser.company}
                  active={
                    !canceledPlanStatus.includes(
                      companyUser.company.planStripeStatus || ""
                    ) ||
                    [CompanyUserRole.Owner, CompanyUserRole.Admin].includes(
                      companyUser.role
                    )
                  }
                  currentCompanyData={currentCompanyData}
                />
                {companyUser.role !== CompanyUserRole.Owner && (
                  <MyCompanyDelete
                    companyUsers={companyUsers}
                    companyUser={companyUser}
                  />
                )}
              </Flex>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MyCompaniesPagePresenter;
