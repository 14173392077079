import React from "react";

import { WorkOrderAsset } from "../../../../models/workOrder";
import WorkOrderCreateServiceAssetsTablePresenter from "./presenter";

interface WorkOrderCreateServiceAssetsTableProps {
  companyId?: string;
  assets?: WorkOrderAsset[] | null;
  onAssetDelete?: (asset: WorkOrderAsset) => void;
  onAddNewAssetClick?: () => void;
  editable?: boolean;
}

const WorkOrderCreateServiceAssetsTable: React.FC<
  WorkOrderCreateServiceAssetsTableProps
> = ({ companyId, assets, onAssetDelete, onAddNewAssetClick, editable }) => {
  return (
    <WorkOrderCreateServiceAssetsTablePresenter
      companyId={companyId}
      assets={assets}
      editable={editable}
      onAssetDelete={onAssetDelete}
      onAddNewAssetClick={onAddNewAssetClick}
    />
  );
};

export default WorkOrderCreateServiceAssetsTable;
