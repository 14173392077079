import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import FormGroup from "../../../components/elements/formGroup";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyFragmentFragment,
  useCompanyUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { emailSchema, yupObject } from "../../../utils/validation";

interface CompanyEmailChangePagePresenterProps {
  currentCompany: CompanyFragmentFragment;
}

const CompanyEmailChangePagePresenter: React.FC<
  CompanyEmailChangePagePresenterProps
> = ({ currentCompany }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [companyUpdateMutation, { loading }] = useCompanyUpdateMutation();

  const onSubmit = async (
    data: CompanyFormData,
    { setFieldError }: FormikHelpers<CompanyFormData>
  ) => {
    try {
      const { data: serverData, errors } = await companyUpdateMutation({
        variables: { data: { ...data, name: currentCompany.name } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        navigate(getRoutePath("companySettings"));
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        email: currentCompany.email || "",
      }}
      validationSchema={CompanyFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ getFieldProps }) => (
        <Form noValidate>
          <FormGroup label="Email" name="email">
            <Input autoFocus autoComplete="email" {...getFieldProps("email")} />
          </FormGroup>
          <Box marginY="10">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyEmailChangePagePresenter;

const CompanyFormValidationSchema = yupObject().shape({
  email: emailSchema,
});

type CompanyFormData = {
  email: string;
};
