import { useReactiveVar } from "@apollo/client";
import { EuiFlexGroup, EuiFlexItem, useEuiTheme } from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { useEffect, useMemo, useState } from "react";

import Accordion from "../../../components/accordion";
import Label from "../../../components/label";
import { MessageUtils } from "../../../helpers/messages";
import { companyChanged } from "../../../helpers/workOrdersReactiveVariables";
import {
  BusinessSeverityEnum,
  OrderPriorityEnum,
  businessSeverityOptions,
  orderPriorityOption,
} from "../../../models/enums";
import { WorkOrder } from "../../../models/workOrder";
import ViewEditCombobox from "../../components/viewEditCombobox";
import ViewEditSelect from "../../components/viewEditSelect";
import { SectionDataPlan } from "./index";

interface WorkOrderEditPlanPresenterProps {
  sectionData: SectionDataPlan;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  orderTypesOption: EuiSuperSelectOption<string>[];
  serviceCategoriesOption: EuiSuperSelectOption<string>[];
  workOrdersOptions: EuiComboBoxOptionOption<string>[];
}

const WorkOrderEditPlanPresenter: React.FC<WorkOrderEditPlanPresenterProps> = ({
  sectionData,
  onWorkOrderChange,
  orderTypesOption = [],
  serviceCategoriesOption = [],
  workOrdersOptions = [],
  children,
}) => {
  const { euiTheme } = useEuiTheme();
  const [parentOrderErrors, setParentOrderErrors] = useState<string[]>([]);
  const [parentOrderTouched, setParentOrderTouched] = useState<boolean>(false);
  const orderTypeRevisitId = useMemo(
    () =>
      orderTypesOption?.find(
        (item) => (item.inputDisplay as string)?.toLowerCase() === "revisit"
      )?.value,
    [orderTypesOption]
  );
  const minSelectorWidth = "235px";
  const onOrderTypeSelect = (orderTypeId: string) => {
    onWorkOrderChange({
      orderTypeId,
    });
    setParentOrderTouched(false);
  };

  const onOrderPrioritySelect = (orderPriority: OrderPriorityEnum) =>
    onWorkOrderChange({
      orderPriority,
    });
  const onServiceCategorySelect = (serviceCategoryId: string) => {
    onWorkOrderChange({
      serviceCategoryId,
    });
  };
  const onBusinessSeveritySelect = (businessSeverity: BusinessSeverityEnum) =>
    onWorkOrderChange({
      businessSeverity,
    });

  const onParentOrderSelected = (orderId: string) => {
    setParentOrderTouched(true);
    onWorkOrderChange({ parentOrderId: orderId });
  };

  const onParentOrderBlur = () => {
    setParentOrderTouched(true);
  };

  useEffect(() => {
    if (
      parentOrderTouched &&
      orderTypeRevisitId === sectionData.orderTypeId &&
      !sectionData.parentOrderId
    ) {
      setParentOrderErrors([MessageUtils.commonRequiredFieldErrorMessage]);
    } else {
      setParentOrderErrors([]);
    }
  }, [parentOrderTouched, sectionData]);
  const isCompanyChanged: { changed: boolean } = useReactiveVar(companyChanged);

  useEffect(() => {
    if (isCompanyChanged.changed) {
      resetErrors();
    }
  }, [isCompanyChanged]);

  const resetErrors = () => {
    setParentOrderTouched(false);
  };
  return (
    <EuiFlexItem style={{ minWidth: "100%" }} grow={0}>
      <Accordion
        id="wo-service-plan"
        label="Service Plan"
        required={true}
        initialIsOpen={true}
      >
        <EuiFlexGroup
          direction="column"
          justifyContent="spaceBetween"
          alignItems="stretch"
          wrap={false}
        >
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Order Type" required={true} />
            <div style={{ minWidth: minSelectorWidth }}>
              <ViewEditSelect
                selectedValue={sectionData.orderTypeId}
                options={orderTypesOption}
                onSelect={onOrderTypeSelect}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Service Category" required={true} />
            <div style={{ minWidth: minSelectorWidth }}>
              <ViewEditSelect
                placeholder={"Select Service Category"}
                selectedValue={sectionData.serviceCategoryId}
                options={serviceCategoriesOption}
                onSelect={onServiceCategorySelect}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Business Severity" required={true} />
            <div style={{ minWidth: minSelectorWidth }}>
              <ViewEditSelect
                options={businessSeverityOptions}
                selectedValue={sectionData?.businessSeverity}
                onSelect={onBusinessSeveritySelect}
                placeholder={"Select Business Severity"}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label label="Execution Priority" />
            <div style={{ minWidth: minSelectorWidth }}>
              <ViewEditSelect
                selectedValue={sectionData?.orderPriority}
                options={orderPriorityOption}
                onSelect={onOrderPrioritySelect}
                placeholder={"Select Execution Priority"}
                compressed={true}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: euiTheme.size.s,
            }}
          >
            <Label
              label="Parent Order"
              required={orderTypeRevisitId === sectionData.orderTypeId}
            />
            <div style={{ minWidth: minSelectorWidth, maxWidth: "235px" }}>
              <ViewEditCombobox
                options={workOrdersOptions}
                selectedValue={sectionData?.parentOrderId}
                onValueChange={onParentOrderSelected}
                onBlur={onParentOrderBlur}
                invalid={!!parentOrderErrors.length}
                placeholder={"Search Parent Order"}
                externalErrors={parentOrderErrors}
                compressed={true}
                required={orderTypeRevisitId === sectionData.orderTypeId}
              />
            </div>
          </EuiFlexItem>
          {children}
        </EuiFlexGroup>
      </Accordion>
    </EuiFlexItem>
  );
};

export default WorkOrderEditPlanPresenter;
