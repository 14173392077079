import { EuiText, useEuiTheme } from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { css } from "@emotion/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Combobox from "../../components/combobox";
import Label from "../../components/label";

interface ViewEditComboboxProps {
  selectedValue?: string[] | string | null;
  label?: string | null;
  options: Array<EuiComboBoxOptionOption<string>>;
  onValueChange: (value: any) => void;
  required?: boolean;
  placeholder?: string;
  style?: any;
  className?: string;
  compressed?: boolean;
  singleSelect?: boolean;
  height?: string;
  resetControl?: { reset: boolean };
  invalid?: boolean;
  externalErrors?: string[];
  onBlur?: () => void;
  resetInputMode?: { reset: boolean };
}

const ViewEditCombobox: React.FC<ViewEditComboboxProps> = ({
  selectedValue,
  label,
  onValueChange,
  onBlur,
  externalErrors,
  className = "",
  placeholder,
  options,
  resetControl,
  compressed = false,
  style = {},
  required = false,
  singleSelect = true,
  height,
  resetInputMode,
}) => {
  const { euiTheme } = useEuiTheme();
  const [errors, setErrors] = useState<string[]>();
  const [value, setValue] = useState<string | string[]>(selectedValue || "");
  const [displayText, setDisplayText] = useState<string[]>([]);
  const [isInput, setIsInput] = useState<boolean>(false);

  const checkErrors = useCallback(() => {
    if (required && !value?.length) {
      setErrors([`${label || "Value"} is required`]);
      return true;
    }
    setErrors([]);
    return false;
  }, [required, value, setErrors]);

  const onSelect = (selectedId: string) => {
    setValue(selectedId);
    onValueChange(selectedId);
  };

  useEffect(() => {
    setValue(selectedValue || "");
  }, [selectedValue]);

  useEffect(() => {
    setErrors([...(errors || []), ...(externalErrors || [])]);
  }, [externalErrors]);

  useEffect(() => {
    let filter = value;
    if (typeof value === "string") {
      filter = [value];
    }
    if (options?.length && value) {
      const text = options
        .filter((i) => filter.includes(i.value || ""))
        .map((i) => i.label);
      setDisplayText(text);
    } else {
      setDisplayText([]);
    }

    const isErrors = checkErrors();

    if (singleSelect) {
      setIsInput(isErrors);
    }
  }, [value, options, required]);

  useEffect(() => {
    checkErrors();
  }, [required]);

  useEffect(() => {
    setIsInput(resetInputMode?.reset || false);
  }, [resetInputMode]);

  const onDropdownClosed = () => {
    const isErrors = checkErrors();
    setIsInput(isErrors);
  };

  style = {
    fontSize: "14px",
    fontWeight: 500,
    ...style,
  };
  return (
    <>
      <div style={{ position: "relative" }} className={className}>
        {label && (
          <Label
            label={label || ""}
            required={required}
            style={{ paddingBottom: "5px" }}
          />
        )}
        {isInput && (
          <Combobox
            placeholder={placeholder}
            options={options}
            selectedValue={value}
            invalid={!!errors?.length}
            errors={errors}
            onChange={onSelect}
            compressed={compressed}
            autoFocus={true}
            singleSelect={singleSelect}
            onDropdownClosed={onDropdownClosed}
            height={height}
            resetControl={resetControl}
            onBlur={onBlur}
          />
        )}
        {!isInput && (
          <EuiText
            className={"ellipsis"}
            style={{
              ...style,
              cursor: "pointer",
              borderRadius: "0",
              marginBottom: "0",
              height: !singleSelect ? "unset" : !compressed ? "40px" : "30px",
              letterSpacing: "normal",
              lineHeight: !singleSelect
                ? "unset"
                : !compressed
                ? "40px"
                : "30px",
              minHeight: !compressed ? "40px" : "30px",
              padding: "0",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onClick={() => setIsInput(true)}
            css={css`
              color: var(--color-text);

              &:hover {
                background: var(--color-hover);
              }
            `}
          >
            {displayText.map((text) => (
              <div key={text}> {text} </div>
            ))}
          </EuiText>
        )}
        {!displayText?.length && placeholder && !isInput && (
          <EuiText
            className={"placeholder"}
            style={{
              top: "unset",
              left: "4px",
              bottom: compressed ? "6px" : "10px",
              lineHeight: "21px",
            }}
          >
            {placeholder}
          </EuiText>
        )}
      </div>
    </>
  );
};

export default ViewEditCombobox;
