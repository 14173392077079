import { Box, Button, Flex, FormLabel } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import { IconTypes } from "../../../../graphql/graphql";

const name = "iconType";

interface TypePickerProps {
  color: string;
}

const TypePicker: React.FC<TypePickerProps> = ({ color }) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const value = get(values, name);

  return (
    <Flex alignItems="center">
      <FormLabel margin="0">Icon Style</FormLabel>
      <Button
        marginX="2"
        variant="outline"
        colorScheme="secondary"
        size="sm"
        onClick={() => setFieldValue(name, IconTypes.Square)}
        isActive={value === IconTypes.Square}
        _hover={{ backgroundColor: "#b3d6ff" }}
        _active={{ backgroundColor: "#b3d6ff" }}
        border="0"
        padding="1"
        height="8"
        aria-label="Select square icon type"
      >
        <Box marginTop=".1rem" boxSize="20px" backgroundColor={color} />
      </Button>
      <Button
        variant="outline"
        colorScheme="secondary"
        size="sm"
        onClick={() => setFieldValue(name, IconTypes.Circle)}
        isActive={value === IconTypes.Circle}
        _hover={{ backgroundColor: "#b3d6ff" }}
        _active={{ backgroundColor: "#b3d6ff" }}
        border="0"
        padding="1"
        height="8"
        aria-label="Select round icon type"
      >
        <Box
          marginTop=".1rem"
          boxSize="20px"
          backgroundColor={color}
          borderRadius="full"
        />
      </Button>
    </Flex>
  );
};

export default TypePicker;
