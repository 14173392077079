import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Resend = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    color="#7A7A7A"
    width="16px"
    height="13px"
    viewBox="0 0 16 13"
    {...props}
  >
    <path d="M6.33338 10.8167C6.33502 10.9297 6.28976 11.0383 6.20838 11.1167L6.04171 11.2833C5.96348 11.3622 5.85698 11.4066 5.74588 11.4066C5.63478 11.4066 5.52828 11.3622 5.45005 11.2833L0.625045 6.45834C0.54448 6.38273 0.498779 6.27716 0.498779 6.16667C0.498779 6.05619 0.54448 5.95062 0.625045 5.87501L5.45005 1.05001C5.52828 0.971127 5.63478 0.926758 5.74588 0.926758C5.85698 0.926758 5.96348 0.971127 6.04171 1.05001L6.20838 1.21667C6.28976 1.29507 6.33502 1.40369 6.33338 1.51667V3.66667C11.213 3.75344 15.205 7.57859 15.5 12.45C15.5011 12.5507 15.4655 12.6484 15.4 12.725C15.3286 12.7944 15.233 12.8332 15.1334 12.8333H15.05C14.8494 12.8326 14.6778 12.689 14.6417 12.4917C14.25 10.3333 10.2667 8.66667 6.33338 8.66667V10.8167Z" />
  </Icon>
);

export default Resend;
