import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Manager = (props: IconProps & any) => (
  <Icon
    fill="currentColor"
    color="#459BFE"
    viewBox="0 0 24 24"
    boxSize="24px"
    {...props}
  >
    <path d="M12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3ZM12 14C8.996 14 3 15.508 3 18.5V20C3 20.552 3.448 21 4 21H10.457L11.125 17L10.625 16L12 15L13.375 16L12.875 17L13.543 21H16V20C16 19.448 16.448 19 17 19H18C18.552 19 19 19.448 19 20V21H20C20.552 21 21 20.552 21 20V18.5C21 15.508 15.004 14 12 14Z" />
  </Icon>
);

export default Manager;
