import { ApolloCache } from "@apollo/client";

import { removeCompanyId, setCompanyId } from "../utils/companyId";
import { removeJwt, setJwt } from "../utils/jwt";
import {
  AppSettingsDocument,
  AssetCategoryConfigDocument,
  AssetCategoryDisplayType,
  AssetConfigDocument,
  AssetDisplayType,
  AssetTypeConfigDocument,
  AssetTypeDisplayType,
  CurrentCompanyDocument,
  DashboardConfigDocument,
  FloorPlanConfigDocument,
  FloorPlanDisplayType,
  FolderConfigDocument,
  FolderDisplayType,
  LocationConfigDocument,
  MaintenanceConfigDocument,
  MaintenanceDisplayType,
  MaintenanceFilterStatus,
  MaintenanceStatusType,
  SessionDocument,
  SortBy,
  SortOrder,
} from "./graphql";

const resolvers = {
  Mutation: {
    updateSession: (
      _: any,
      { jwt }: { jwt?: string },
      { cache }: { cache: ApolloCache<any> }
    ): boolean => {
      if (jwt) {
        setJwt(jwt);
      } else {
        removeJwt();
      }
      cache.writeQuery({
        query: SessionDocument,
        data: { session: { isLoggedIn: !!jwt } },
      });
      return true;
    },

    updateCurrentCompany: (
      _: any,
      { id }: { id?: string },
      { cache }: any
    ): boolean => {
      if (id) {
        setCompanyId(id);
      } else {
        removeCompanyId();
      }
      cache.writeQuery({
        query: CurrentCompanyDocument,
        data: { currentCompany: { id } },
      });
      return true;
    },

    updateAppSettings: (
      _: any,
      {
        sideNavMinimized,
        plaidOpen,
      }: {
        sideNavMinimized?: boolean;
        plaidOpen?: boolean;
      },
      { cache }: any
    ): boolean => {
      const data = cache.readQuery({ query: AppSettingsDocument });
      cache.writeQuery({
        query: AppSettingsDocument,
        data: {
          appSettings: {
            sideNavMinimized:
              typeof sideNavMinimized === "boolean"
                ? sideNavMinimized
                : data.appSettings.sideNavMinimized,
            plaidOpen:
              typeof plaidOpen === "boolean"
                ? plaidOpen
                : data.appSettings.plaidOpen,
          },
        },
      });
      return true;
    },

    updateFolderConfig: function (
      _: any,
      {
        sortBy,
        sortOrder,
        displayType,
      }: {
        sortBy?: SortBy;
        sortOrder?: SortOrder;
        displayType?: FolderDisplayType;
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: FolderConfigDocument });
      cache.writeQuery({
        query: FolderConfigDocument,
        data: {
          folderConfig: {
            sortBy: sortBy || data.folderConfig.sortBy,
            sortOrder: sortOrder || data.folderConfig.sortOrder,
            displayType: displayType || data.folderConfig.displayType,
          },
        },
      });
      return true;
    },

    updateLocationConfig: function (
      _: any,
      {
        sortBy,
        sortOrder,
        activeTabIndex,
        activeCollapsible,
      }: {
        sortBy?: SortBy;
        sortOrder?: SortOrder;
        activeTabIndex?: number;
        activeCollapsible?: string[];
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: LocationConfigDocument });
      cache.writeQuery({
        query: LocationConfigDocument,
        data: {
          locationConfig: {
            sortBy: sortBy || data.locationConfig.sortBy,
            sortOrder: sortOrder || data.locationConfig.sortOrder,
            activeTabIndex:
              typeof activeTabIndex === "number"
                ? activeTabIndex
                : data.locationConfig.activeTabIndex,
            activeCollapsible:
              activeCollapsible || data.locationConfig.activeCollapsible,
          },
        },
      });
      return true;
    },

    updateAssetConfig: function (
      _: any,
      {
        sortBy,
        sortOrder,
        activeTabIndex,
        displayType,
        filter,
        filterCategoryId,
        activeCollapsible,
      }: {
        sortBy?: SortBy;
        sortOrder?: SortOrder;
        activeTabIndex?: number;
        displayType?: AssetDisplayType;
        filter?: MaintenanceStatusType[];
        filterCategoryId?: string;
        activeCollapsible?: string[];
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: AssetConfigDocument });
      cache.writeQuery({
        query: AssetConfigDocument,
        data: {
          assetConfig: {
            sortBy: sortBy || data.assetConfig.sortBy,
            sortOrder: sortOrder || data.assetConfig.sortOrder,
            activeTabIndex:
              typeof activeTabIndex === "number"
                ? activeTabIndex
                : data.assetConfig.activeTabIndex,
            displayType: displayType || data.assetConfig.displayType,
            filter: filter || data.assetConfig.filter,
            filterCategoryId:
              typeof filterCategoryId === "string"
                ? filterCategoryId
                : data.assetConfig.filterCategoryId,
            activeCollapsible:
              activeCollapsible || data.assetConfig.activeCollapsible,
          },
        },
      });
      return true;
    },

    updateMaintenanceConfig: function (
      _: any,
      {
        sortBy,
        sortOrder,
        displayType,
        filterStatus,
        filterCategoryId,
      }: {
        sortBy?: SortBy;
        sortOrder?: SortOrder;
        displayType?: MaintenanceDisplayType;
        filterStatus?: MaintenanceFilterStatus[];
        filterCategoryId?: string;
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: MaintenanceConfigDocument });
      cache.writeQuery({
        query: MaintenanceConfigDocument,
        data: {
          maintenanceConfig: {
            sortBy: sortBy || data.maintenanceConfig.sortBy,
            sortOrder: sortOrder || data.maintenanceConfig.sortOrder,
            displayType: displayType || data.maintenanceConfig.displayType,
            filterStatus: filterStatus || data.maintenanceConfig.filterStatus,
            filterCategoryId:
              typeof filterCategoryId === "string"
                ? filterCategoryId
                : data.maintenanceConfig.filterCategoryId,
          },
        },
      });
      return true;
    },

    updateAssetCategoryConfig: function (
      _: any,
      {
        sortBy,
        sortOrder,
        displayType,
        showHidden,
      }: {
        sortBy?: SortBy;
        sortOrder?: SortOrder;
        displayType?: AssetCategoryDisplayType;
        showHidden?: boolean;
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: AssetCategoryConfigDocument });
      cache.writeQuery({
        query: AssetCategoryConfigDocument,
        data: {
          assetCategoryConfig: {
            sortBy: sortBy || data.assetCategoryConfig.sortBy,
            sortOrder: sortOrder || data.assetCategoryConfig.sortOrder,
            displayType: displayType || data.assetCategoryConfig.displayType,
            showHidden:
              typeof showHidden === "boolean"
                ? showHidden
                : data.assetCategoryConfig.showHidden,
          },
        },
      });
      return true;
    },

    updateAssetTypeConfig: function (
      _: any,
      {
        sortBy,
        sortOrder,
        displayType,
      }: {
        sortBy?: SortBy;
        sortOrder?: SortOrder;
        displayType?: AssetTypeDisplayType;
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: AssetTypeConfigDocument });
      cache.writeQuery({
        query: AssetTypeConfigDocument,
        data: {
          assetTypeConfig: {
            sortBy: sortBy || data.assetTypeConfig.sortBy,
            sortOrder: sortOrder || data.assetTypeConfig.sortOrder,
            displayType: displayType || data.assetTypeConfig.displayType,
          },
        },
      });
      return true;
    },

    updateFloorPlanConfig: function (
      _: any,
      {
        sortBy,
        sortOrder,
        displayType,
        filterCategoryId,
      }: {
        sortBy?: SortBy;
        sortOrder?: SortOrder;
        displayType?: FloorPlanDisplayType;
        filterCategoryId?: string;
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: FloorPlanConfigDocument });
      cache.writeQuery({
        query: FloorPlanConfigDocument,
        data: {
          floorPlanConfig: {
            sortBy: sortBy || data.floorPlanConfig.sortBy,
            sortOrder: sortOrder || data.floorPlanConfig.sortOrder,
            displayType: displayType || data.floorPlanConfig.displayType,
            filterCategoryId:
              typeof filterCategoryId === "string"
                ? filterCategoryId
                : data.floorPlanConfig.filterCategoryId,
          },
        },
      });
      return true;
    },

    updateDashboardConfig: function (
      _: any,
      {
        filter,
      }: {
        filter?: MaintenanceStatusType[];
      },
      { cache }: any
    ): boolean {
      const data = cache.readQuery({ query: DashboardConfigDocument });
      cache.writeQuery({
        query: DashboardConfigDocument,
        data: {
          dashboardConfig: {
            filter: filter || data.dashboardConfig.filter,
          },
        },
      });
      return true;
    },
  },
};

export default resolvers;
