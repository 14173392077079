import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";

import Label from "../../../../components/label";
import Select from "../../../../components/select";
import { MessageUtils } from "../../../../helpers/messages";
import { WorkOrder } from "../../../../models/workOrder";

export type WorkOrderCreateAddAssetSettingsPresenterProps = {
  workOrder: WorkOrder;
  onAssetTypeSelected: (assetTypeId: string | null) => void;
  onCommentCreated: (comment: string | null) => void;
  assetServiceTypes: EuiSuperSelectOption<string>[];
};

const WorkOrderCreateAddAssetSettingsPresenter = ({
  workOrder,
  onAssetTypeSelected,
  onCommentCreated,
  assetServiceTypes,
}: WorkOrderCreateAddAssetSettingsPresenterProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [commentErrors, setCommentErrors] = useState<string[]>([]);
  const [assetTypeId, setAssetTypeId] = useState<string | null>(null);
  const _onAssetTypeSelected = (assetTypeId: string) => {
    setAssetTypeId(assetTypeId);
    onAssetTypeSelected(assetTypeId);
  };
  const _onCommentChange = (event: BaseSyntheticEvent) => {
    const text = event.target.value;
    onCommentCreated(text);
    if (text?.trim().length <= 200) {
      setCommentErrors([]);
    } else {
      setCommentErrors(["*Max 200 characters"]);
    }
  };

  useEffect(() => {
    if (touched && !assetTypeId) {
      setErrors([MessageUtils.commonRequiredFieldErrorMessage]);
    } else {
      setErrors([]);
    }
  }, [touched, assetTypeId]);

  return (
    <>
      <EuiText className={"asset-title"}>Asset's Service Settings</EuiText>
      <EuiFlexGroup gutterSize={"none"}>
        <EuiFlexItem
          grow={1}
          style={{
            marginRight: "56px",
            height: "fit-content",
            minWidth: "275px",
          }}
        >
          <Select
            options={assetServiceTypes}
            placeholder={"Select Service Type"}
            invalid={!!errors?.length}
            onSelect={_onAssetTypeSelected}
            label={"Asset's Service Type"}
            required={true}
            onBlur={() => setTouched(true)}
            errors={errors}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          <EuiFormRow
            error={commentErrors}
            isInvalid={!!commentErrors?.length}
            fullWidth={true}
          >
            <EuiFlexGroup direction={"column"} gutterSize={"none"}>
              <Label label="Comment" style={{ paddingBottom: "5px" }} />
              <EuiTextArea
                isInvalid={!!commentErrors?.length}
                placeholder="Start typing..."
                onChange={_onCommentChange}
                fullWidth={true}
                resize={"none"}
                style={{ height: "66px" }}
              />
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
export default WorkOrderCreateAddAssetSettingsPresenter;
