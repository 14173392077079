export const arrayFlatReverse = (array: any[]) => {
  if (array && array[0] && typeof array[0][0] === "number") {
    return array;
  }

  return array.reduce((acc, curr, index) => {
    if (index % 2 === 0) {
      acc.push([curr]);
    } else {
      acc[acc.length - 1][1] = curr;
    }
    return acc;
  }, []);
};
