import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  List,
  ListItem,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import AgreementsField from "../../components/elements/agreementsField";
import FormGroup from "../../components/elements/formGroup";
import Link from "../../components/elements/link";
import PasswordInput from "../../components/elements/passwordInput";
import PhoneInput from "../../components/elements/phoneInput";
import SelectCombobox from "../../components/elements/selectCombobox";
import { BUSINESS_TYPES, INDUSTRIES } from "../../constants/options";
import { JOB_TITLES } from "../../constants/options";
import {
  CompanyPlanInterval,
  CompanyPlanName,
  useUpdateSessionMutation,
  useUserCreateMutation,
} from "../../graphql/graphql";
import { getRoutePath } from "../../router";
import { getRedirectUrl, removeRedirectUrl } from "../../utils/redirection";
import setServerErrors, { setGenericMessage } from "../../utils/serverErrors";
import {
  agreementsSchema,
  companyBusinessTypeSchema,
  companyIndustrySchema,
  emailSchema,
  firstNameSchema,
  jobTitleSchema,
  lastNameSchema,
  nameSchema,
  newPasswordSchema,
  phoneSchema,
  yupBoolean,
  yupObject,
} from "../../utils/validation";

declare let window: any;
interface SignUpPagePresenterProps {
  companyPlanName?: CompanyPlanName;
  companyPlanInterval?: CompanyPlanInterval;
  hideSignInLink?: boolean;
  proSignUp?: boolean;
}

const SignUpPagePresenter: React.FC<SignUpPagePresenterProps> = ({
  companyPlanName,
  companyPlanInterval,
  hideSignInLink = false,
  proSignUp = false,
}) => {
  const [userCreateMutation, { loading }] = useUserCreateMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const [updateSessionMutation] = useUpdateSessionMutation();

  const onSubmit = async (
    data: SignUpFormData,
    { setFieldError }: FormikHelpers<SignUpFormData>
  ) => {
    try {
      const { data: serverData, errors } = await userCreateMutation({
        variables: { data: { ...data, companyPlanName, companyPlanInterval } },
      });
      if (errors) {
        if (Object.keys(errors).some((errorKey) => errorKey === "email")) {
          if ((errors as any).email.includes("Invitation Pending")) {
            (errors as any).email = (
              <Box>
                You have a pending invitation. Please accept your invitation to
                login or you can{" "}
                <Link
                  to={getRoutePath("forgotPassword")}
                  variant="link"
                  colorScheme="secondary"
                  fontSize="xs"
                >
                  reset your password
                </Link>
                .
              </Box>
            );
          } else if ((errors as any).email.includes("Account already exists")) {
            (errors as any).email = (
              <Box>
                Account already exists.{" "}
                <Link
                  to={getRoutePath("signIn")}
                  variant="link"
                  colorScheme="secondary"
                  fontSize="xs"
                >
                  Sign in instead?
                </Link>
              </Box>
            );
          }
        }
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        if (window.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              eventType: "CA:SignUp",
              payload: { jwt: serverData.userCreate.jwtToken },
            })
          );
        } else {
          await updateSessionMutation({
            variables: { jwt: serverData.userCreate.jwtToken },
          });
          const redirectTo = getRedirectUrl();
          navigate(redirectTo ?? getRoutePath("dashboard"), { replace: true });
          removeRedirectUrl();
        }
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const SignUpValidationSchema = useMemo(
    () =>
      yupObject().shape({
        email: emailSchema,
        passwordRaw: newPasswordSchema.label("Password"),
        firstName: firstNameSchema,
        lastName: lastNameSchema,
        jobTitle: jobTitleSchema,
        businessType: companyBusinessTypeSchema,
        companyName: nameSchema.label("Company name"),
        phone: phoneSchema,
        companyIndustry: companyIndustrySchema,
        agreements: agreementsSchema,
        mspAgreement: proSignUp
          ? agreementsSchema
          : yupBoolean().label("Agreements"),
      }),
    [proSignUp]
  );

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          companyName: "",
          email: "",
          passwordRaw: "",
          phone: "",
          companyIndustry: "",
          jobTitle: "",
          businessType: "",
          agreements: false,
          mspAgreement: false,
        }}
        validationSchema={SignUpValidationSchema}
        onSubmit={onSubmit}
      >
        {({ getFieldProps }) => (
          <Form noValidate>
            <Flex
              flexWrap="wrap"
              justifyContent="space-evenly"
              padding={{ base: "0", xl: "4" }}
            >
              <FormGroup
                label="Work Email"
                name="email"
                styleProps={{
                  width: { base: "100%", sm: "50%" },
                  paddingRight: { sm: "2" },
                }}
              >
                <Input
                  autoFocus
                  autoComplete="email"
                  type="email"
                  {...getFieldProps("email")}
                />
              </FormGroup>
              <FormGroup
                label="Create Password"
                name="passwordRaw"
                renderError={false}
                styleProps={{
                  width: { base: "100%", sm: "50%" },
                }}
              >
                <PasswordInput
                  showPasswordHint
                  autoComplete="new-password"
                  name="passwordRaw"
                />
              </FormGroup>
              <FormGroup
                label="First Name"
                name="firstName"
                styleProps={{
                  width: { base: "100%", sm: "50%" },
                  paddingRight: { sm: "2" },
                }}
              >
                <Input
                  autoComplete="given-name"
                  {...getFieldProps("firstName")}
                />
              </FormGroup>
              <FormGroup
                label="Last Name"
                name="lastName"
                styleProps={{
                  width: { base: "100%", sm: "50%" },
                }}
              >
                <Input
                  autoComplete="family-name"
                  {...getFieldProps("lastName")}
                />
              </FormGroup>
              <FormGroup
                label="Phone Number"
                name="phone"
                styleProps={{
                  width: { base: "100%", sm: "50%" },
                  paddingRight: { sm: "2" },
                }}
              >
                <PhoneInput autoComplete="tel" name="phone" />
              </FormGroup>
              <FormGroup
                label="Company Name"
                name="companyName"
                styleProps={{
                  width: { base: "100%", sm: "50%" },
                }}
              >
                <Input
                  autoComplete="organization"
                  {...getFieldProps("companyName")}
                />
              </FormGroup>
              <Box
                width={{ base: "100%", sm: "50%" }}
                paddingRight={{ sm: "2" }}
              >
                {proSignUp ? (
                  <SelectCombobox
                    options={BUSINESS_TYPES}
                    name="businessType"
                    label="Select Your Business Type"
                  />
                ) : (
                  <SelectCombobox
                    options={JOB_TITLES}
                    name="jobTitle"
                    label="Job Title"
                  />
                )}
              </Box>
              <Box width={{ base: "100%", sm: "50%" }}>
                <SelectCombobox
                  options={INDUSTRIES}
                  name="companyIndustry"
                  label="Select Your Industry"
                />
              </Box>
              <Center width="100%">
                <Box maxWidth={proSignUp ? "545px" : "465px"}>
                  <AgreementsField showMspAgreement={proSignUp} />
                </Box>
              </Center>
              <Box marginTop="4" width="100%" maxWidth={{ sm: "xs" }}>
                <Button type="submit" width="full" isLoading={loading}>
                  Start your free {proSignUp ? "pro " : ""}trial
                </Button>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
      {!hideSignInLink && (
        <List color="gray.600" textAlign="center">
          <ListItem marginY="4">
            Already have an account?{" "}
            <Link
              to={getRoutePath("signIn")}
              variant="link"
              colorScheme="secondary"
            >
              Sign in
            </Link>
          </ListItem>
        </List>
      )}
    </>
  );
};

export default SignUpPagePresenter;

type SignUpFormData = {
  email: string;
  passwordRaw: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  businessType: string;
  companyName: string;
  phone: string;
  companyIndustry: string;
  agreements: boolean;
  mspAgreement: boolean;
};

gql`
  mutation userCreate($data: UserCreateInput!) {
    userCreate(data: $data) {
      jwtToken
    }
  }
`;
