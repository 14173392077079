import { Box, Button, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import ContactCompanyUserField from "../../../components/elements/contactCompanyUserField";
import { GENERIC_SAVED_MESSAGE } from "../../../constants/lang/en";
import {
  CompanyWithContactUsersFragmentFragment,
  useCompanyUpdateMutation,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import { companyUserIdsSchema, yupObject } from "../../../utils/validation";

interface CompanyContactChangePagePresenterProps {
  currentCompany: CompanyWithContactUsersFragmentFragment;
}

const CompanyContactChangePagePresenter: React.FC<
  CompanyContactChangePagePresenterProps
> = ({ currentCompany }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [companyUpdateMutation, { loading }] = useCompanyUpdateMutation();

  const onSubmit = async (
    data: CompanyFormData,
    { setFieldError }: FormikHelpers<CompanyFormData>
  ) => {
    try {
      const { data: serverData, errors } = await companyUpdateMutation({
        variables: { data: { ...data, name: currentCompany.name } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        toast({
          description: GENERIC_SAVED_MESSAGE,
          status: "success",
          isClosable: true,
          position: "top",
        });
        navigate(getRoutePath("companySettings"));
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        companyUserIds: currentCompany.companyCompanyUsers
          ? currentCompany.companyCompanyUsers.map((cu) => cu.companyUser.id)
          : ([] as string[]),
      }}
      validationSchema={CompanyFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate>
          <ContactCompanyUserField name="companyUserIds" autoFocus />
          <Box marginY="10">
            <Button width="full" type="submit" isLoading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyContactChangePagePresenter;

const CompanyFormValidationSchema = yupObject().shape({
  companyUserIds: companyUserIdsSchema.required(),
});

type CompanyFormData = {
  companyUserIds: string[];
};
