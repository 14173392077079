import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Rocket = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 18 18" boxSize="18px" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.308 2.873l-.18-.18c-.28-.282-.66-.441-1.056-.443h-1.138c-1.587 0-3.11.632-4.232 1.755l-.914.915-.277.33H6.162c-.471 0-.915.222-1.198.6L2.342 9.352a.368.368 0 000 .488l1.019 1.02a1.123 1.123 0 001.655-.075l.068-.082 2.187 2.19-.082.067a1.126 1.126 0 00-.075 1.657l1.018 1.02a.367.367 0 00.487 0l3.498-2.625a1.5 1.5 0 00.6-1.2v-1.274l.329-.316.914-.915a6.004 6.004 0 001.79-4.237V3.93a1.501 1.501 0 00-.442-1.058zm-3.745 3.57a1.501 1.501 0 01-1.056 2.563A1.501 1.501 0 019.45 6.442a1.497 1.497 0 012.112 0zm-6.029 6.944a.36.36 0 00-.113-.262l-.532-.533a.36.36 0 00-.524 0L2.38 14.58a.375.375 0 000 .533l.524.524a.374.374 0 00.532 0L5.42 13.65a.36.36 0 00.113-.263z"
    />
  </Icon>
);

export default Rocket;
