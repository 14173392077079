import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

import AssetSelectFields from "../../../../components/elements/assetSelectFields";
import SelectCombobox from "../../../../components/elements/selectCombobox";
import { AssetWithRelationsFragmentFragment } from "../../../../graphql/graphql";
import { getAssetPartOptions } from "../../../../utils/assetPart";
import {
  assetIdsSchema,
  assetPartIdSchema,
  yupObject,
} from "../../../../utils/validation";

interface AffectedAssetCreatePresenterProps {
  loading: boolean;
  handleSubmit: (
    values: AffectedAssetFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  assetIds: string[];
  isOpen: boolean;
  onDrawerClose: () => void;
  typeAffected: boolean;
  asset: AssetWithRelationsFragmentFragment;
  assetPartId?: string;
}

const AffectedAssetCreatePresenter: React.FC<
  AffectedAssetCreatePresenterProps
> = ({
  loading,
  handleSubmit,
  assetIds,
  isOpen,
  onDrawerClose,
  typeAffected,
  asset,
  assetPartId,
}) => {
  const assetPartOptions = useMemo(() => getAssetPartOptions(asset), [asset]);
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onDrawerClose}>
      <Helmet>
        <title>Add New Fed {typeAffected ? "To" : "From"} Asset</title>
      </Helmet>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Add New Fed {typeAffected ? "To" : "From"} Asset
          </DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{
                assetIds: [],
                assetPartId: assetPartId || undefined,
              }}
              validationSchema={affectedAssetFormValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              <Form noValidate>
                <AssetSelectFields
                  name="assetIds"
                  excludedAssetIds={assetIds}
                  autoFocus
                />
                {typeAffected && assetPartOptions.length > 0 && (
                  <SelectCombobox
                    label="Breaker"
                    name="assetPartId"
                    options={assetPartOptions}
                  />
                )}
                <Box marginTop="8">
                  <Button width="full" type="submit" isLoading={loading}>
                    Save
                  </Button>
                </Box>
              </Form>
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AffectedAssetCreatePresenter;

const affectedAssetFormValidationSchema = yupObject().shape({
  assetIds: assetIdsSchema,
  assetPartId: assetPartIdSchema,
});

export type AffectedAssetFormData = {
  assetIds: string[];
  assetPartId?: string;
};
