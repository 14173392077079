import { useApolloClient, useReactiveVar } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistanceToNowStrict, isToday } from "date-fns";
import gql from "graphql-tag";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import {
  NOTIFICATION_DELETED,
  UNREAD_NOTIFICATIONS_DELETED,
} from "../../../../constants/lang/en";
import {
  DeleteNotificationDocument,
  NewNotificationDocument,
  NotificationFragmentFragment,
  NotificationsDocument,
  UpdateNotificationDocument,
  useNotificationDeleteAllReadMutation,
  useNotificationDeleteMutation,
  useNotificationReadAllMutation,
  useNotificationReadMutation,
  useNotificationUnreadMutation,
  useNotificationsQuery,
} from "../../../../graphql/graphql";
import { unreadNotificationsCountVar } from "../../../../graphql/reactiveVariables";
import useDebounce from "../../../../hooks/useDebounce";
import {
  getNotificationMessage,
  isReportNotification,
} from "../../../../utils/notification";
import { setGenericMessage } from "../../../../utils/serverErrors";
import Link from "../../../elements/link";
import MoreVIcon from "../../../icons/moreV";
import Notification from "../../../icons/notification";
import SearchIcon from "../../../icons/search";

interface NotificationsProps {}

const Notifications: FC<NotificationsProps> = () => {
  const cancelRef1 = useRef<HTMLButtonElement>(null);
  const cancelRef2 = useRef<HTMLButtonElement>(null);
  const {
    isOpen: isDeleteAllReadOpen,
    onOpen: onDeleteAllReadOpen,
    onClose: onDeleteAllReadClose,
  } = useDisclosure();
  const {
    isOpen: isMarkAllOpen,
    onOpen: onMarkAllOpen,
    onClose: onMarkAllClose,
  } = useDisclosure();
  const cancelRef3 = useRef<HTMLButtonElement>(null);
  const [notificationToDelete, setNotificationToDelete] = useState("");
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [filteredNotifications, setFilteredNotifications] = useState<
    NotificationFragmentFragment[]
  >([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);
  const unReadNotificationsCount = useReactiveVar(unreadNotificationsCountVar);

  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: searchParams.has("showNotifications"),
  });
  const toast = useToast();
  const navigate = useNavigate();
  const client = useApolloClient();
  const {
    data: notificationsData,
    subscribeToMore,
    refetch,
  } = useNotificationsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [notificationReadAllMutation] = useNotificationReadAllMutation({
    refetchQueries: [
      {
        query: NotificationsDocument,
      },
    ],
    awaitRefetchQueries: true,
  });
  const [notificationDeleteAllReadMutation] =
    useNotificationDeleteAllReadMutation({
      refetchQueries: [
        {
          query: NotificationsDocument,
        },
      ],
      awaitRefetchQueries: true,
    });
  const [notificationDeleteMutation] = useNotificationDeleteMutation();
  const [notificationReadMutation] = useNotificationReadMutation();
  const [notificationUnreadMutation] = useNotificationUnreadMutation();

  const readNotification = useCallback(
    async (id: string) => {
      try {
        await notificationReadMutation({ variables: { id } });
      } catch {}
    },
    [notificationReadMutation]
  );

  const unreadNotification = useCallback(
    async (id: string) => {
      try {
        await notificationUnreadMutation({ variables: { id } });
      } catch {}
    },
    [notificationUnreadMutation]
  );

  const deleteNotification = useCallback(
    async (id: string) => {
      try {
        await notificationDeleteMutation({ variables: { id } });
        client.cache.evict({ id: `Notification:${id}` });
        toast({
          description: NOTIFICATION_DELETED,
          status: "success",
          position: "top",
          isClosable: true,
        });
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [notificationDeleteMutation, toast, client]
  );

  const markAllNotificationRead = useCallback(async () => {
    try {
      await notificationReadAllMutation();
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [notificationReadAllMutation, toast]);

  const deleteAllReadNotification = useCallback(async () => {
    try {
      await notificationDeleteAllReadMutation();
      toast({
        description: UNREAD_NOTIFICATIONS_DELETED,
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  }, [notificationDeleteAllReadMutation, toast]);

  useEffect(() => {
    subscribeToMore({
      document: NewNotificationDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newNotification = (subscriptionData.data as any).newNotification;
        let search = window.location.search;
        search = search
          ? `${search}&reload=${Date.now()}`
          : `?reload=${Date.now()}`;
        navigate(`${window.location.pathname}${search}`, { replace: true });

        if (isReportNotification(newNotification.message)) {
          readNotification(newNotification.id);
          navigate(newNotification.path, { replace: true });
        } else {
          toast({
            description: (
              <Button
                variant="unstyled"
                textTransform="none"
                fontSize="md"
                fontWeight="normal"
                color="white"
                width="full"
                height="auto"
                onClick={() => {
                  readNotification(newNotification.id);
                  navigate(newNotification.path);
                }}
                whiteSpace="normal"
                pos="relative"
                top="-2px"
              >
                {newNotification.message}
              </Button>
            ),
            status: "info",
            position: "top",
            isClosable: true,
            duration: 10000,
          });
        }

        return {
          notifications: [newNotification, ...(prev?.notifications || [])],
        };
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    subscribeToMore({
      document: UpdateNotificationDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const updatedNotification = (subscriptionData.data as any)
          .updateNotification;

        const notifications: NotificationFragmentFragment[] = [];
        const notificationsLength = prev.notifications.length;
        for (let i = 0; i < notificationsLength; i++) {
          const notification = prev.notifications[i];
          if (notification.id === updatedNotification.id) {
            notifications.push(updatedNotification);
          } else {
            notifications.push(notification);
          }
        }
        return { notifications };
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    subscribeToMore({
      document: DeleteNotificationDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const deletedNotificationId = (subscriptionData.data as any)
          .deleteNotification.id;

        const notifications: NotificationFragmentFragment[] = [];
        const notificationsLength = prev.notifications.length;
        for (let i = 0; i < notificationsLength; i++) {
          const notification = prev.notifications[i];
          if (notification.id !== deletedNotificationId) {
            notifications.push(notification);
          }
        }
        return { notifications };
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!notificationsData) return;
    const notificationLength = notificationsData.notifications.length;
    let unReadNotificationsCount = 0;
    for (let i = 0; i < notificationLength; i++) {
      if (!notificationsData.notifications[i].readAt)
        unReadNotificationsCount++;
    }
    unreadNotificationsCountVar(unReadNotificationsCount);
  }, [notificationsData]);

  useEffect(() => {
    if (!notificationsData?.notifications) {
      setFilteredNotifications([]);
      return;
    }
    const debouncedQueryLowerCased = debouncedQuery.toLowerCase();
    setFilteredNotifications(
      notificationsData.notifications.filter(
        (n) =>
          (!debouncedQueryLowerCased ||
            n.message.toLowerCase().includes(debouncedQueryLowerCased)) &&
          (currentTabIndex === 0 ||
            (currentTabIndex === 1 && !n.readAt) ||
            (currentTabIndex === 2 && isToday(n.createdAt)))
      )
    );
  }, [currentTabIndex, notificationsData, debouncedQuery]);

  const resetSearch = useCallback(() => {
    setQuery("");
  }, [setQuery]);

  return (
    <Box>
      <Tooltip label="Notifications" hasArrow placement="bottom">
        <Button
          position="relative"
          onClick={() => {
            onOpen();
            refetch();
          }}
          variant="unstyled"
          size="xs"
          height="auto"
          minWidth="auto"
          aria-label="Notifications"
        >
          <Notification
            color={{ base: "gray.500", lg: "gray.800" }}
            boxSize="7"
          />
          {unReadNotificationsCount > 0 && (
            <Box
              width="10px"
              height="10px"
              backgroundColor="red.500"
              borderRadius="full"
              position="absolute"
              right="3px"
              top="-1px"
              borderWidth="1px"
              borderColor={{ base: "black", lg: "white" }}
            />
          )}
        </Button>
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onClose();
          if (searchParams.has("showNotifications")) setSearchParams({});
        }}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader display="flex" alignItems="center">
              <Heading as="h3" size="xs" textTransform="uppercase">
                Notifications{" "}
                {unReadNotificationsCount > 0 && (
                  <>({unReadNotificationsCount})</>
                )}
              </Heading>
              {notificationsData && notificationsData.notifications.length > 0 && (
                <Popover placement="top" isLazy returnFocusOnClose autoFocus>
                  <PopoverTrigger>
                    <IconButton
                      variant="icon"
                      colorScheme="gray"
                      marginLeft="3"
                      aria-label="Delete notifications"
                    >
                      <MoreVIcon />
                    </IconButton>
                  </PopoverTrigger>
                  <PopoverContent width="250px">
                    <PopoverArrow />
                    <PopoverBody>
                      {!!unReadNotificationsCount && (
                        <Button
                          variant="link"
                          paddingY="8px !important"
                          colorScheme="secondary"
                          width="full"
                          justifyContent="flex-start"
                          display="flex"
                          onClick={onMarkAllOpen}
                        >
                          Mark All As Read
                        </Button>
                      )}
                      {notificationsData?.notifications.length !==
                        unReadNotificationsCount && (
                        <Button
                          variant="link"
                          paddingY="8px !important"
                          colorScheme="secondary"
                          width="full"
                          justifyContent="flex-start"
                          display="flex"
                          onClick={onDeleteAllReadOpen}
                        >
                          Delete All Read Notifications
                        </Button>
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </DrawerHeader>
            <DrawerBody>
              <FormControl>
                <InputLeftElement
                  position="absolute"
                  paddingLeft="2"
                  top="3"
                  zIndex="2"
                  children={<SearchIcon color="gray.600" boxSize="4" />}
                />
                <Input
                  size="sm"
                  pl="8"
                  pr="8"
                  height="10"
                  fontWeight="normal"
                  paddingY="0"
                  autoFocus
                  placeholder="Search notifications..."
                  onChange={(e: any) => setQuery(e.currentTarget.value)}
                  value={query}
                  autoComplete="off"
                />
                {!!debouncedQuery && (
                  <InputRightElement
                    position="absolute"
                    paddingRight="4"
                    paddingY="2"
                    right="-10px"
                    top="0"
                    zIndex="10"
                    onClick={() => {
                      resetSearch();
                    }}
                    children={<CloseButton color="black" size="sm" />}
                    tabIndex={0}
                    role="button"
                  />
                )}
              </FormControl>
              <Tabs
                isFitted
                isLazy
                onChange={(index) => setCurrentTabIndex(index)}
                marginY="3"
              >
                <TabList>
                  <Tab>All</Tab>
                  <Tab>Unread</Tab>
                  <Tab>Today</Tab>
                </TabList>
              </Tabs>
              {filteredNotifications.length ? (
                filteredNotifications.map((notification) => (
                  <Box
                    key={notification.id}
                    fontSize="sm"
                    fontWeight="300"
                    marginBottom="3"
                    paddingBottom="3"
                    borderBottom="1px solid"
                    borderColor="gray.100"
                  >
                    <Flex alignItems="center">
                      <Flex
                        flex="none"
                        width="4"
                        direction="column"
                        alignSelf="stretch"
                      >
                        {!notification.readAt && (
                          <Box
                            width="2"
                            flexGrow={1}
                            backgroundColor="secondary.300"
                          />
                        )}
                      </Flex>
                      <Box flex={1}>
                        <Link
                          to={notification.path}
                          variant="unstyled"
                          textTransform="none"
                          fontSize="xs"
                          fontWeight={notification.readAt ? "normal" : "bold"}
                          color="gray.800"
                          width="full"
                          height="auto"
                          onClick={() => {
                            readNotification(notification.id);
                            onClose();
                          }}
                          whiteSpace="normal"
                        >
                          {getNotificationMessage(notification.message)}
                        </Link>
                        <Flex
                          alignItems="center"
                          textAlign="left"
                          width="full"
                          mt="1"
                        >
                          <Box color="gray.600" flex={1} fontSize="xs">
                            {formatDistanceToNowStrict(
                              new Date(notification.createdAt),
                              { addSuffix: true }
                            )}
                          </Box>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            width="75px"
                            flexGrow={0}
                          >
                            <Tooltip
                              label={
                                notification.readAt
                                  ? "Mark as unread"
                                  : "Mark as read"
                              }
                              hasArrow
                              placement="bottom"
                            >
                              <IconButton
                                variant="icon"
                                colorScheme="gray"
                                aria-label={`Mark notification as ${
                                  notification.readAt ? "unread" : "unread"
                                }`}
                                onClick={() =>
                                  notification.readAt
                                    ? unreadNotification(notification.id)
                                    : readNotification(notification.id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={
                                    notification.readAt
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                  size="lg"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              label="Delete Notification"
                              hasArrow
                              placement="bottom"
                            >
                              <CloseButton
                                onClick={() =>
                                  setNotificationToDelete(notification.id)
                                }
                                aria-label="Delete notification"
                                color="gray.500"
                              />
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Box
                  textAlign="center"
                  paddingX="2"
                  paddingY="8"
                  fontSize="sm"
                  color="gray.700"
                  width="full"
                >
                  {!!debouncedQuery
                    ? "No results found"
                    : currentTabIndex === 0
                    ? "No notifications right now"
                    : currentTabIndex === 1
                    ? "No unread notificaions right now"
                    : "No notifications received today"}
                </Box>
              )}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <AlertDialog
        leastDestructiveRef={cancelRef1}
        onClose={onDeleteAllReadClose}
        isOpen={isDeleteAllReadOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete all read <br />
              notifications?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef1}
                onClick={onDeleteAllReadClose}
                width="48%"
              >
                No, Don't Delete!
              </Button>
              <Button
                onClick={() => {
                  deleteAllReadNotification();
                  onDeleteAllReadClose();
                }}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        leastDestructiveRef={cancelRef2}
        onClose={onMarkAllClose}
        isOpen={isMarkAllOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogBody textAlign="center">
              Are you sure you want to mark all <br />
              notifications as read?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef2} onClick={onMarkAllClose} width="48%">
                No
              </Button>
              <Button
                onClick={() => {
                  markAllNotificationRead();
                  onMarkAllClose();
                }}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        leastDestructiveRef={cancelRef3}
        onClose={() => setNotificationToDelete("")}
        isOpen={!!notificationToDelete}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete this notification?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef2}
                onClick={() => setNotificationToDelete("")}
                width="48%"
              >
                No, Don't Delete!
              </Button>
              <Button
                onClick={() => {
                  deleteNotification(notificationToDelete);
                  setNotificationToDelete("");
                }}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Notifications;

gql`
  query Notifications {
    notifications {
      ...NotificationFragment
    }
  }

  subscription NewNotification {
    newNotification {
      ...NotificationFragment
    }
  }

  subscription UpdateNotification {
    updateNotification {
      ...NotificationFragment
    }
  }

  subscription DeleteNotification {
    deleteNotification {
      ...NotificationFragment
    }
  }

  mutation NotificationRead($id: UUID!) {
    notificationRead(id: $id) {
      ...NotificationFragment
    }
  }

  mutation NotificationUnread($id: UUID!) {
    notificationUnread(id: $id) {
      ...NotificationFragment
    }
  }

  mutation NotificationDelete($id: UUID!) {
    notificationDelete(id: $id)
  }

  mutation NotificationDeleteAll {
    notificationDeleteAll
  }

  mutation NotificationDeleteAllRead {
    notificationDeleteAllRead
  }

  mutation NotificationReadAll {
    notificationReadAll
  }
`;
