import React from "react";
import { useParams } from "react-router-dom";

import Presenter from "./presenter";

interface FolderCreatePageProps {}

const FolderCreatePage: React.FC<FolderCreatePageProps> = () => {
  const { folderId } = useParams();

  return folderId ? <Presenter parentId={folderId} /> : null;
};

export default FolderCreatePage;
