import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Helmet } from "react-helmet";

import FormGroup from "../../../components/elements/formGroup";
import { MaintenanceFragmentFragment } from "../../../graphql/graphql";
import { descriptionSchema, yupObject } from "../../../utils/validation";

interface MaintenanceNoteCreatePresenterProps {
  loading: boolean;
  handleSubmit: (
    values: MaintenanceFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  maintenance: MaintenanceFragmentFragment;
  isOpen: boolean;
  onModalClose: () => void;
  canEditMaintenance: boolean;
}

const MaintenanceNoteCreatePresenter: React.FC<
  MaintenanceNoteCreatePresenterProps
> = ({ maintenance, loading, handleSubmit, isOpen, onModalClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <Helmet>
        <title>{maintenance.misc.resolvedName} Notes</title>
      </Helmet>
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton right="0" />
          <ModalHeader px="8">
            {maintenance.misc.resolvedName} Notes
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ description: "" }}
              validationSchema={maintenanceFormValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ getFieldProps }) => (
                <Form noValidate>
                  <FormGroup label="Enter your notes here" name="description">
                    <Textarea
                      rows={4}
                      autoComplete="off"
                      {...getFieldProps("description")}
                      autoFocus
                    />
                  </FormGroup>
                  <Box marginTop="2">
                    <Button width="full" type="submit" isLoading={loading}>
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default MaintenanceNoteCreatePresenter;

const maintenanceFormValidationSchema = yupObject().shape({
  description: descriptionSchema.required(),
});

export type MaintenanceFormData = {
  description: string;
};
