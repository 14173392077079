import { uniqBy } from "lodash";
import { useEffect, useState } from "react";

import {
  LocationIndexFragmentFragment,
  MaintenanceStatusType,
} from "../graphql/graphql";

const useLocationAssets = (location: LocationIndexFragmentFragment) => {
  const [assets, setAssets] = useState<
    { id: string; maintenanceStatus: MaintenanceStatusType }[]
  >([]);

  useEffect(() => {
    const assets: { id: string; maintenanceStatus: MaintenanceStatusType }[] =
      [];

    const plans = location.floorPlans;
    const plansCount = plans.length;
    for (let i = 0; i < plansCount; i++) {
      const plan = plans[i];

      const floorPlanAssets = plan.floorPlanAssets;
      const fpaCount = floorPlanAssets.length;
      for (let j = 0; j < fpaCount; j++) {
        assets.push(floorPlanAssets[j].asset);
      }
    }

    setAssets(uniqBy(assets, "id"));
  }, [location]);

  return assets;
};

export default useLocationAssets;
