import { Icon, IconProps } from "@chakra-ui/react";

const SolarPanelBackupBattery = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.213 75.312h6.036c3.333 0 6.03 2.706 6.03 6.041v15.065a6.028 6.028 0 0 1-6.03 6.041h-6.036v1.501c0 3.329-2.704 6.04-6.022 6.04H18.743c-3.33 0-6.022-2.707-6.022-6.04V73.81c0-3.328 2.705-6.04 6.023-6.04h12.233c1.507-14.408 13.69-25.639 28.498-25.639 14.808 0 26.992 11.231 28.5 25.64h9.216c3.33 0 6.022 2.707 6.022 6.04v1.5ZM59.476 48.164c-11.472 0-20.95 8.538-22.424 19.606h44.847c-1.475-11.068-10.952-19.606-22.424-19.606Zm-40.733 25.64c.018 0 .01 30.156.01 30.156 0 .006 78.438.007 78.438.007-.017 0-.01-30.156-.01-30.156 0-.006-78.438-.008-78.438-.008Zm84.47 22.622h6.035l-.002-15.073c0-.008-6.033-.009-6.033-.009v15.082Zm-18.098-4.524v3.016a3.016 3.016 0 1 1-6.033 0v-3.016h-3.016a3.016 3.016 0 1 1 0-6.033h3.016v-3.016a3.016 3.016 0 1 1 6.033 0v3.016h3.016a3.016 3.016 0 1 1 0 6.033h-3.016Zm-45.246-6.033a3.016 3.016 0 1 1 0 6.033H27.803a3.016 3.016 0 1 1 0-6.033H39.87Zm22.623-55.803a3.016 3.016 0 1 1-6.033 0v-9.05a3.016 3.016 0 1 1 6.033 0v9.05Zm33.757 22.973a3.016 3.016 0 1 1-3.016-5.225l7.837-4.525a3.016 3.016 0 1 1 3.016 5.225l-7.837 4.525Zm-13.8-16.01a3.016 3.016 0 0 1-5.225-3.016l4.524-7.837a3.017 3.017 0 0 1 5.225 3.017l-4.525 7.836ZM25.717 47.814a3.017 3.017 0 0 1-3.017 5.225l-7.837-4.525a3.016 3.016 0 1 1 3.017-5.225l7.837 4.525Zm16.009-13.801a3.017 3.017 0 0 1-5.225 3.017l-4.524-7.837a3.017 3.017 0 0 1 5.224-3.017l4.525 7.837Z"
      fill="currentColor"
    />
  </Icon>
);

export default SolarPanelBackupBattery;
