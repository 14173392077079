import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faChevronDown,
  faChevronUp,
  faPen,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import AssetIcon from "../../../components/elements/assetIcons";
import ContactUserInfo from "../../../components/elements/contactUserInfo";
import Link from "../../../components/elements/link";
import QrCodes from "../../../components/elements/qrCodes";
import Status from "../../../components/elements/status";
import AddCircle from "../../../components/icons/addCircle";
import {
  AssetWithRelationsFragmentFragment,
  SpecialAssetFieldTypes,
} from "../../../graphql/graphql";
import { getRoutePath } from "../../../router";
import WorkOrderCreateButton from "../../workOrders/dashboard/components/createWorkOrderButton";
import AssetDelete from "../delete";
import AffectedAsset from "./affectedAsset";
import AssetDetails from "./details";
import AssetFields from "./details/fields";
import AssetFiles from "./files";
import FloorPlan from "./floorPlan";
import AssetMaintenance from "./maintenances";

interface AssetsShowPresenterProps {
  asset: AssetWithRelationsFragmentFragment;
  tabIndex: number;
  setTabIndex: (value: number) => void;
  canAddAsset: boolean;
  canEditAsset: boolean;
  canDeleteAsset: boolean;
}

const AssetsShowPresenter: React.FC<AssetsShowPresenterProps> = ({
  asset,
  tabIndex,
  setTabIndex,
  canAddAsset,
  canEditAsset,
  canDeleteAsset,
}) => {
  const [searchParams] = useSearchParams();
  const { isOpen: isDescriptionOpen, onToggle: toggleDescription } =
    useDisclosure();

  useEffect(() => {
    if (searchParams.has("tab")) {
      setTabIndex(+(searchParams.get("tab") || "0"));
    }
  }, [searchParams, setTabIndex]);

  const assetFieldsPresent = asset.assetType.assetFields.length > 0;
  const floorPlanAssetsWithAffectedAreas = asset.floorPlanAssets.filter(
    (fpa) => fpa.mapAffectedArea
  );

  const assetLocationIds =
    asset?.floorPlanAssets.map((fl) => fl.floorPlan.locationId) || [];

  return (
    <Box>
      <Helmet>
        <title>
          {asset.name} ({asset.assetType.name})
        </title>
      </Helmet>
      <Box backgroundColor="secondary.10" padding="3">
        <Flex
          alignItems={{ base: "flex-start", sm: "center" }}
          flexDirection={{ base: "column", sm: "row" }}
        >
          <Flex
            alignItems="center"
            paddingLeft="4"
            position="relative"
            marginLeft="-3"
          >
            <Status status={asset.maintenanceStatus} />
            <AssetIcon
              iconName={asset.assetType.iconName}
              iconColor={asset.assetType.misc.resolvedIconColor}
              iconType={asset.assetType.iconType}
              iconSize="sm"
            />
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              lineHeight={{ base: "156%", md: "120%" }}
              fontWeight="semibold"
              marginLeft="2"
              marginRight="3"
            >
              {asset.name} ({asset.assetType.name})
            </Text>
          </Flex>
          <Flex alignItems="center" marginTop={{ base: "4", sm: 0 }}>
            <QrCodes
              links={[
                {
                  path: getRoutePath("assetsShow", { assetId: asset.id }),
                  name: asset.name,
                },
              ]}
              printDocumentTitle={asset.name}
            />
            {canEditAsset && (
              <Tooltip label="Edit Asset" hasArrow placement="bottom">
                <Link
                  to={getRoutePath("assetsShowEdit", {
                    assetId: asset.id,
                  })}
                  aria-label="Edit asset"
                  variant="icon"
                  colorScheme="gray"
                  marginX="3"
                >
                  <FontAwesomeIcon icon={faPen} size="sm" />
                </Link>
              </Tooltip>
            )}
            {canDeleteAsset && <AssetDelete asset={asset} redirect />}
          </Flex>
          <Flex
            grow={1}
            justifyContent="flex-end"
            marginTop={{ base: "4", sm: 0 }}
          >
            <WorkOrderCreateButton
              title={"Create Work Order"}
              assetId={asset.id}
              availableLocationIds={assetLocationIds}
              setLocation={true}
              disabled={!asset?.floorPlanAssets?.length}
            />
          </Flex>
        </Flex>
        <Grid
          templateColumns={{
            base: "minmax(0, 1fr)",
            md: assetFieldsPresent
              ? "minmax(0, 2fr) repeat(3, minmax(0, 1fr))"
              : "repeat(3, minmax(0, 1fr))",
          }}
          gap={{ base: "6", md: "4" }}
          marginTop="4"
        >
          {assetFieldsPresent && <AssetFields asset={asset} />}
          <Box>
            <Box>
              <Flex alignItems="center">
                <Text lineHeight="21px" fontSize="sm" fontWeight="semibold">
                  Plans
                </Text>
                <Tooltip
                  label="This is a list of plans on which this asset appears"
                  hasArrow
                >
                  <Box color="gray.500" marginLeft="2" lineHeight="21px">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Box>
                </Tooltip>
                {canAddAsset && (
                  <Tooltip label="Add new asset plan" hasArrow>
                    <Link
                      to={getRoutePath("assetsShowFloorPlanCreate", {
                        assetId: asset.id,
                      })}
                      variant="icon"
                      colorScheme="graySecondary"
                      aria-label="Add New Asset Plan"
                      marginLeft="2"
                    >
                      <AddCircle />
                    </Link>
                  </Tooltip>
                )}
              </Flex>
              {asset.floorPlanAssets.length > 0 ? (
                asset.floorPlanAssets.map((fpa) => (
                  <FloorPlan
                    key={fpa.id}
                    floorPlanAsset={fpa}
                    assetId={asset.id}
                  />
                ))
              ) : (
                <Box
                  color="gray.300"
                  fontSize="xs"
                  as="span"
                  position="relative"
                  top="-1"
                >
                  —
                </Box>
              )}
            </Box>
            {floorPlanAssetsWithAffectedAreas.length > 0 && (
              <Box marginTop="6">
                <Text fontSize="sm" fontWeight="semibold">
                  Affected Areas
                </Text>
                {floorPlanAssetsWithAffectedAreas.map((fpa) => (
                  <FloorPlan
                    key={fpa.id}
                    floorPlanAsset={fpa}
                    assetId={asset.id}
                  />
                ))}
              </Box>
            )}
          </Box>
          <Box>
            <Box>
              <Flex alignItems="center">
                <Text fontSize="sm" fontWeight="semibold">
                  Fed From
                </Text>
                {canAddAsset && (
                  <Tooltip label="Add new fed from asset" hasArrow>
                    <Link
                      to={getRoutePath("assetsShowAffectedByCreate", {
                        assetId: asset.id,
                      })}
                      variant="icon"
                      colorScheme="graySecondary"
                      aria-label="Add New Fed From Asset"
                      marginLeft="2"
                    >
                      <AddCircle />
                    </Link>
                  </Tooltip>
                )}
              </Flex>
              {asset.assetAffectedByAssets.length > 0 ? (
                asset.assetAffectedByAssets.map((afa) => (
                  <AffectedAsset
                    key={afa.id}
                    affectedAsset={afa.asset}
                    assetAffectedAssetId={afa.id}
                    type="affectedBy"
                  />
                ))
              ) : (
                <Box
                  color="gray.300"
                  fontSize="xs"
                  as="span"
                  position="relative"
                  top="-1"
                >
                  —
                </Box>
              )}
            </Box>
            <Box marginTop="6">
              <Flex alignItems="center">
                <Text fontSize="sm" fontWeight="semibold">
                  Fed To
                </Text>
                {canAddAsset && (
                  <Tooltip label="Add new fed to asset" hasArrow>
                    <Link
                      to={getRoutePath("assetsShowAffectedCreate", {
                        assetId: asset.id,
                      })}
                      variant="icon"
                      colorScheme="graySecondary"
                      aria-label="Add New Fed To Asset"
                      marginLeft="2"
                    >
                      <AddCircle />
                    </Link>
                  </Tooltip>
                )}
              </Flex>
              {asset.assetAffectedAssets.length > 0 ? (
                asset.assetAffectedAssets.map((afa) => (
                  <AffectedAsset
                    key={afa.id}
                    affectedAsset={afa.affectedAsset}
                    assetAffectedAssetId={afa.id}
                    type="affected"
                  />
                ))
              ) : (
                <Box
                  color="gray.300"
                  fontSize="xs"
                  as="span"
                  position="relative"
                  top="-1"
                >
                  —
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Flex alignItems="center">
              <Text fontSize="sm" fontWeight="semibold">
                Contact
              </Text>
              {canAddAsset && (
                <Tooltip label="Add new contact" hasArrow>
                  <Link
                    to={
                      getRoutePath("assetsShowEdit", {
                        assetId: asset.id,
                      }) + "?contactForm=true"
                    }
                    variant="icon"
                    colorScheme="graySecondary"
                    aria-label="Add New Contact"
                    marginLeft="2"
                  >
                    <AddCircle />
                  </Link>
                </Tooltip>
              )}
            </Flex>
            {asset.assetCompanyUsers.length > 0 ? (
              <ContactUserInfo
                contactCompanyUsers={asset.assetCompanyUsers.map(
                  (acu) => acu.companyUser
                )}
              />
            ) : (
              <Box
                color="gray.300"
                fontSize="xs"
                as="span"
                position="relative"
                top="-1"
              >
                —
              </Box>
            )}
          </Box>
        </Grid>
        {!!asset.description && (
          <Box marginTop="4">
            <Collapse in={isDescriptionOpen}>
              <Box fontSize="sm" whiteSpace="pre-wrap" marginBottom="1">
                {asset.description}
              </Box>
            </Collapse>
            <Button
              size="sm"
              fontSize="sm"
              variant="link"
              colorScheme="gray"
              marginTop="2"
              paddingLeft="0"
              onClick={toggleDescription}
            >
              {isDescriptionOpen ? "Hide" : "Show"} description
              <Box marginLeft="2">
                <FontAwesomeIcon
                  icon={isDescriptionOpen ? faChevronUp : faChevronDown}
                  size="xs"
                />
              </Box>
            </Button>
          </Box>
        )}
      </Box>

      <Tabs index={tabIndex} onChange={setTabIndex} isLazy>
        <TabList>
          <Tab>
            {asset.assetType.specialAssetField !== SpecialAssetFieldTypes.Na &&
              "Panel & "}{" "}
            Pictures
          </Tab>
          <Tab>Service Schedule</Tab>
          <Tab>Documents</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AssetDetails
              asset={asset}
              canAddAsset={canAddAsset}
              canEditAsset={canEditAsset}
              canDeleteAsset={canDeleteAsset}
            />
          </TabPanel>
          <TabPanel>
            <AssetMaintenance assetId={asset.id} />
          </TabPanel>
          <TabPanel>
            <AssetFiles
              asset={asset}
              canAddAsset={canAddAsset}
              canEditAsset={canEditAsset}
              canDeleteAsset={canDeleteAsset}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AssetsShowPresenter;
