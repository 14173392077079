import { useReactiveVar } from "@apollo/client";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";

import {
  AssetCategoryItem,
  AssetTypeItem,
  FloorPlanAssetItem,
  LocationFloorPlan,
  LocationFragmentFragment,
  useLocationIndexQuery,
} from "../../../../../../graphql/graphql";
import useAssetCategories from "../../../../../../hooks/useAssetCategories";
import { TreeNode } from "../../../../components/treeSelect";
import { createWORedirectData } from "../../../../helpers/workOrdersReactiveVariables";
import { WorkOrderCreateRedirectData } from "../../../../models/createWORedirectData";
import { WorkOrder } from "../../../../models/workOrder";
import { AssetDropdownItem, ItemDropdownBase } from "../models/assets";
import WorkOrderCreateAddAssetHeaderPresenter from "./presenter";

export type WorkOrderCreateAddAssetHeaderProps = {
  workOrder: WorkOrder;
  onAssetSelected: (asset: AssetDropdownItem | null) => void;
  onSublocationSelected: (sublocation: ItemDropdownBase) => void;
};
export const ALL_OPTIONS = "ALL";
export default function WorkOrderCreateAddAssetHeader({
  workOrder,
  onAssetSelected,
  onSublocationSelected,
}: WorkOrderCreateAddAssetHeaderProps) {
  const allCategoriesOption = {
    value: ALL_OPTIONS,
    inputDisplay: "All Categories",
  };
  const allTypesOption = { value: ALL_OPTIONS, label: "All Types" };
  const { data: locationsWrapper } = useLocationIndexQuery({
    fetchPolicy: "network-only",
    variables: {
      companyId: workOrder.companyId,
    },
    skip: !workOrder?.companyId,
  });
  const { data: assetCategoriesData } = useAssetCategories({
    fetchPolicy: "network-only",
    variables: { companyId: workOrder?.companyId },
    skip: !workOrder?.companyId,
  });

  const WOCreateData: WorkOrderCreateRedirectData | null =
    useReactiveVar(createWORedirectData);
  const [preSelectData, setPreSelectData] =
    useState<WorkOrderCreateRedirectData | null>(null);
  const [preSelectedLocation, setPreSelectedLocation] = useState<string | null>(
    null
  );
  const [preSelectedAsset, setPreSelectedAsset] = useState<string | null>(null);
  const [orderedLocations, setOrderedLocations] = useState<TreeNode[]>([]);
  const [assets, setAssets] = useState<AssetDropdownItem[]>([]);
  const [categories, setCategories] = useState<EuiSuperSelectOption<string>[]>([
    allCategoriesOption,
  ]);
  const [allTypes, setAllTypes] = useState<EuiComboBoxOptionOption<string>[]>([
    allTypesOption,
  ]);
  const [filteredAssets, setFilteredAssets] =
    useState<EuiComboBoxOptionOption<string>[]>();
  const [filteredTypes, setFilteredTypes] = useState<
    EuiComboBoxOptionOption<string>[]
  >([allTypesOption]);
  const [filtersEnabled, setFiltersEnabled] = useState<boolean>(false);

  const [selectedCategory, setSelectedCategory] = useState<string>(ALL_OPTIONS);
  const [selectedType, setSelectedType] = useState<string>(ALL_OPTIONS);
  const [selectedAsset, setSelectedAsset] = useState<string | null>();

  useEffect(() => {
    if (locationsWrapper) {
      const parentLocation = locationsWrapper?.locations?.find(
        (l) => l.id === workOrder.locationId
      );
      if (parentLocation) {
        const rootLocation = mapLocationToNode(
          parentLocation as LocationFragmentFragment
        );
        rootLocation.isExpanded = true;
        setOrderedLocations([rootLocation]);
      }
    }
  }, [locationsWrapper]);

  useEffect(() => {
    if (assetCategoriesData?.assetCategories?.length) {
      setCategoryOptions(assetCategoriesData.assetCategories);
      setTypeOptions(assetCategoriesData.assetCategories);
    }
    setSelectedType(ALL_OPTIONS);
  }, [assetCategoriesData]);

  useEffect(() => {
    if (assets) {
      const filtered = (
        selectedType === ALL_OPTIONS && selectedCategory === ALL_OPTIONS
          ? assets
          : assets.filter(filterAssetsByCategoryAndType)
      ).map(mapToComboboxOptions);

      setFilteredAssets(filtered);
    }
  }, [assets, selectedType, selectedCategory]);

  useEffect(() => {
    if (WOCreateData) {
      setPreSelectData(WOCreateData);
    }
  }, [WOCreateData]);

  useEffect(() => {
    if (
      !preSelectedLocation &&
      preSelectData?.sublocationId &&
      orderedLocations.length
    ) {
      setPreSelectedLocation(preSelectData.sublocationId);
      onLocationSelected(preSelectData.sublocationId);
    } else if (preSelectedLocation) {
      setPreSelectData(() => {
        return { ...preSelectData, sublocationId: undefined };
      });
      setPreSelectedLocation(null);
    }
  }, [preSelectData, orderedLocations, preSelectedLocation]);

  useEffect(() => {
    if (!preSelectedAsset && preSelectData?.assetId && filteredAssets?.length) {
      setPreSelectedAsset(preSelectData.assetId);
      _onAssetSelected(preSelectData.assetId);
    } else if (preSelectedAsset) {
      setPreSelectData(() => {
        return { ...preSelectData, assetId: undefined };
      });
      setPreSelectedAsset(null);
    }
  }, [preSelectData, filteredAssets, preSelectedAsset]);

  /*Helper functions*/
  const mapToSelectOptions = (item: { id: string; name: string }) => ({
    value: item.id,
    inputDisplay: item.name,
  });

  const mapToComboboxOptions = (item: { id: string; name: string }) => ({
    value: item.id,
    label: item.name,
  });

  const mapLocationToNode = (location: LocationFragmentFragment): TreeNode => {
    const parentItem: TreeNode = { id: location.id, label: location.name };
    const children = locationsWrapper?.locations.filter(
      (l) => l.parentId === parentItem.id
    );
    if (children?.length) {
      parentItem.children = children.map((child) => mapLocationToNode(child));
    }
    return parentItem;
  };

  const mapFloorPlanAssetToAsset = (
    floorPlanAsset: FloorPlanAssetItem
  ): AssetDropdownItem => {
    const asset = floorPlanAsset.asset;
    const { id, name, assetType } = asset;
    const mapped: AssetDropdownItem = {
      id,
      name: name as string,
    };
    if (assetType) {
      mapped.assetType = {
        id: assetType.id,
        name: assetType.name as string,
      };
    }
    if (assetType?.assetCategory) {
      mapped.assetCategory = {
        id: assetType.assetCategory.id,
        name: assetType.assetCategory.name as string,
      };
    }
    if (!!asset.assetAffectedAssets?.length) {
      mapped.fedTo = asset.assetAffectedAssets?.map((item) => {
        return {
          id: item.affectedAsset.id,
          name: item.affectedAsset.name,
        } as ItemDropdownBase;
      });
    }
    if (!!asset.assetAffectedByAssets?.length) {
      mapped.fedFrom = asset.assetAffectedByAssets?.map((item) => {
        return {
          id: item.asset.id,
          name: item.asset.name,
        } as ItemDropdownBase;
      });
    }
    return mapped;
  };

  const filterAssetsByCategoryAndType = (asset: AssetDropdownItem) => {
    const isCategoryMatch =
      selectedCategory !== ALL_OPTIONS
        ? asset.assetCategory?.id === selectedCategory
        : true;
    const isTypeMatch =
      selectedType !== ALL_OPTIONS
        ? asset.assetType?.id === selectedType
        : true;
    return isCategoryMatch && isTypeMatch;
  };

  /*Initial setting Category and Types*/
  const setCategoryOptions = (assetCategories: AssetCategoryItem[]): void => {
    const mappedCategories: EuiSuperSelectOption<string>[] =
      assetCategories.map(mapToSelectOptions);
    mappedCategories.unshift(allCategoriesOption);
    setCategories(mappedCategories);
  };

  const setTypeOptions = (assetCategories: AssetCategoryItem[]): void => {
    const mappedTypes = (
      assetCategories.flatMap(
        (category) => category.assetTypes
      ) as AssetTypeItem[]
    ).map(mapToComboboxOptions);
    mappedTypes.unshift(allTypesOption);
    setFilteredTypes(mappedTypes);
    setAllTypes(mappedTypes);
  };

  const findSelectedNode = (
    locationId: string,
    rootNode: TreeNode[] = orderedLocations
  ): TreeNode[] => {
    const result = rootNode.find((l) => l.id === locationId);
    if (result) {
      return [result];
    } else {
      return rootNode
        .filter((l) => l.children?.length)
        .map((l) => findSelectedNode(locationId, l.children))
        .flat(Infinity) as TreeNode[];
    }
  };

  const fetchLocationAndChildrenIds = (
    location: TreeNode,
    result: string[]
  ) => {
    result.push(location.id);
    if (location?.children?.length) {
      location.children.forEach((loc) => {
        fetchLocationAndChildrenIds(loc, result);
      });
    }
  };

  const fetchSelectedPlans = (ids: string[]): LocationFloorPlan[] => {
    return ids
      .map(
        (id) =>
          locationsWrapper?.locations.find((l) => l.id === id)?.floorPlans || []
      )
      .flat(2);
  };

  /*Callbacks*/
  const onLocationSelected = (locationId: string): void => {
    const selectedSublocation = locationsWrapper?.locations.find(
      (l) => l.id === locationId
    );
    if (selectedSublocation) {
      const ids: string[] = [];
      const selectedNode = findSelectedNode(locationId)[0];
      fetchLocationAndChildrenIds(selectedNode, ids);
      const floorPlans = fetchSelectedPlans(ids);
      const selectedAssetsIds =
        workOrder?.assetWorkOrders?.map((a) => a.assetId) || [];
      const assets: AssetDropdownItem[] = uniqBy(
        floorPlans
          .flatMap((item) => item.floorPlanAssets)
          .map(mapFloorPlanAssetToAsset)
          .filter((asset) => !selectedAssetsIds.includes(asset.id)),
        "id"
      );
      setSelectedCategory(ALL_OPTIONS);
      setSelectedType(ALL_OPTIONS);
      setAsset(null);
      setAssets(assets);
      setFiltersEnabled(true);
      onSublocationSelected(selectedSublocation);
    }
  };

  const onCategorySelected = (categoryId: string): void => {
    setSelectedCategory(categoryId);
    setAsset(null);
  };

  const onTypeSelected = (typeId: string): void => {
    setSelectedType(typeId);
    setAsset(null);
  };

  const _onAssetSelected = (assetId: string): void => {
    if (selectedAsset) {
      setAsset(null);
      setTimeout(() => {
        setAsset(assetId);
      }, 100);
    } else {
      setAsset(assetId);
    }
  };

  const setAsset = (assetId: string | null) => {
    setSelectedAsset(assetId);
    const selectedAsset = assets.find((asset) => asset.id === assetId);
    if (selectedAsset) {
      onAssetSelected(selectedAsset);
    } else {
      onAssetSelected(null);
    }
  };

  const onTypeBlur = () => {
    if (!selectedType) {
      setSelectedType(ALL_OPTIONS);
    }
  };

  useEffect(() => {
    setSelectedType(ALL_OPTIONS);
    if (selectedCategory !== ALL_OPTIONS) {
      const assetTypes = (
        assetCategoriesData?.assetCategories.find(
          (category) => category.id === selectedCategory
        )?.assetTypes || []
      )?.map(mapToComboboxOptions);
      assetTypes.unshift(allTypesOption);
      setFilteredTypes(assetTypes);
    } else {
      setFilteredTypes(allTypes);
    }
  }, [selectedCategory]);

  return (
    <>
      <WorkOrderCreateAddAssetHeaderPresenter
        locations={orderedLocations}
        categories={categories}
        types={filteredTypes}
        assets={filteredAssets}
        onLocationSelected={onLocationSelected}
        onCategorySelected={onCategorySelected}
        onTypeSelected={onTypeSelected}
        onAssetSelected={_onAssetSelected}
        filtersEnabled={filtersEnabled}
        selectedCategory={selectedCategory}
        selectedType={selectedType}
        selectedAsset={selectedAsset}
        selectedLocation={preSelectedLocation}
        onTypeBlur={onTypeBlur}
      />
    </>
  );
}
