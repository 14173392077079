import { Box, Link } from "@chakra-ui/react";
import React from "react";

import { getRoutePath } from "../../../router";
import CheckBoxInput from "../checkbox";

interface AgreementsFieldProps {
  showMspAgreement?: boolean;
}

const AgreementsField: React.FC<AgreementsFieldProps> = ({
  showMspAgreement = false,
}) => {
  return (
    <Box fontSize="sm" color="gray.600" marginTop="2" width="100%">
      <CheckBoxInput
        label={
          <Box fontSize="xs" marginTop="0.5">
            I have read and accept CriticalAsset's{" "}
            <Link
              fontWeight="bold"
              _hover={{ color: "secondary.500" }}
              href={`${
                process.env.REACT_APP_LIVE_WEBSITE_URL + getRoutePath("terms")
              }`}
              isExternal
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              fontWeight="bold"
              _hover={{ color: "secondary.500" }}
              href={
                process.env.REACT_APP_LIVE_WEBSITE_URL + getRoutePath("privacy")
              }
              isExternal
            >
              Privacy Policy
            </Link>
          </Box>
        }
        name="agreements"
      />
      {showMspAgreement && (
        <CheckBoxInput
          label={
            <Box fontSize="xs" marginTop="0.5">
              I have read and accept CriticalAsset's{" "}
              <Link
                fontWeight="bold"
                _hover={{ color: "secondary.500" }}
                href={`${
                  process.env.REACT_APP_LIVE_WEBSITE_URL +
                  getRoutePath("mspAgreement")
                }`}
                isExternal
              >
                Managed Service Providers (MSP) Agreement
              </Link>
            </Box>
          }
          name="mspAgreement"
        />
      )}
    </Box>
  );
};

export default AgreementsField;
