import { Icon, IconProps } from "@chakra-ui/react";

const ExpansionTank = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.498 19.721h-8.174A6.345 6.345 0 0 0 67.185 15H60.82a6.345 6.345 0 0 0-6.138 4.721h-8.18C36.852 19.721 29 27.488 29 37.034v51.933c0 9.015 7.004 16.433 15.91 17.232v3.654h-.001c-.88 0-1.591.704-1.591 1.574a1.58 1.58 0 0 0 1.59 1.574h38.183a1.58 1.58 0 0 0 1.591-1.574c0-.87-.711-1.574-1.59-1.574h-.002v-3.654C91.996 105.399 99 97.98 99 88.967V37.032c0-9.545-7.852-17.312-17.502-17.312Zm-14.316-1.573H60.82a3.175 3.175 0 0 0-2.738 1.573h11.844a3.18 3.18 0 0 0-2.74-1.573h-.003Zm-19.09 91.704h31.815v-3.573H48.093v3.573Zm33.406-6.721c7.897 0 14.32-6.354 14.32-14.165v-23.6h-12.73v12.59c0 2.603-2.142 4.721-4.773 4.721h-28.64c-2.63 0-4.772-2.118-4.772-4.721V65.362h-12.72v23.605c0 7.81 6.422 14.164 14.319 14.164h34.996ZM48.085 49.627V63.76l.004.01a.064.064 0 0 1 .004.018.055.055 0 0 1-.004.018c-.002.007-.004.013-.004.02v14.13c0 .869.712 1.574 1.59 1.574h28.64c.878 0 1.59-.705 1.59-1.574V49.627c0-.869-.712-1.574-1.59-1.574h-28.64c-.877 0-1.59.706-1.59 1.574Zm35.003 12.59h12.73V37.034c0-7.81-6.423-14.164-14.32-14.164H46.502c-7.897 0-14.32 6.354-14.32 14.164v25.182h12.72V49.627c0-2.603 2.142-4.721 4.774-4.721h28.639c2.631 0 4.773 2.118 4.773 4.721v12.59Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
);

export default ExpansionTank;
