import { Flex, IconButton } from "@chakra-ui/react";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface MaintenanceActionsProps {
  handleEdit?: () => void;
  handleDelete?: () => void;
}

const MaintenanceActions: React.FC<MaintenanceActionsProps> = ({
  handleEdit,
  handleDelete,
}) => {
  return (
    <Flex alignItems="center" flexShrink={0}>
      {handleEdit && (
        <IconButton
          onClick={handleEdit}
          aria-label="Edit service event"
          variant="icon"
          colorScheme="gray"
          padding={2}
        >
          <FontAwesomeIcon icon={faPen} />
        </IconButton>
      )}
      {handleDelete && (
        <IconButton
          variant="icon"
          colorScheme="grayRed"
          aria-label="Delete service event"
          onClick={handleDelete}
          padding={2}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </IconButton>
      )}
    </Flex>
  );
};

export default MaintenanceActions;
