import { compile } from "path-to-regexp";
import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";

import AuthLayout from "./components/layouts/auth";
import DashboardLayout from "./components/layouts/dashboard";
import DashboardOrAuth from "./components/layouts/dashboardOrAuth";
import NativeLayout from "./components/layouts/native";
import ProgressLayout from "./components/layouts/progress";
import ElasticUIWrapper from "./elasticUITheme/elastiUIWrapper";
import AcceptInvitation from "./pages/acceptInvitation";
import AssetCategories from "./pages/assetCategories";
import AssetTypeCreate from "./pages/assetCategories/assetTypesCreate";
import AssetTypeEdit from "./pages/assetCategories/assetTypesEdit";
import AssetTypeMaintenancesShow from "./pages/assetCategories/assetTypesMaintenancesShow";
import AssetTypeShow from "./pages/assetCategories/assetTypesShow";
import AssetCategoryCreate from "./pages/assetCategories/create";
import AssetCategoryEdit from "./pages/assetCategories/edit";
import Assets from "./pages/assets";
import AssetFileCreate from "./pages/assets/assetFileCreate";
import AssetFileEdit from "./pages/assets/assetFileEdit";
import AssetFileShow from "./pages/assets/assetFileShow";
import AssetCreate from "./pages/assets/create";
import AssetEdit from "./pages/assets/edit";
import AssetsShow from "./pages/assets/show";
import AssetAffectedCreate from "./pages/assets/show/affectedCreate";
import AssetPartCreate from "./pages/assets/show/assetPartCreate";
import AssetPartEdit from "./pages/assets/show/assetPartEdit";
import FloorPlanAssetCreate from "./pages/assets/show/floorPlanCreate";
import CompanyBilling from "./pages/billing";
import CreatePaymentPage from "./pages/billing/createPayment";
import CompanyChange from "./pages/companyChange";
import CompanyCreate from "./pages/companyCreate";
import CompanyPlanCreate from "./pages/companyPlanSubscribe";
import CompanySettings from "./pages/companySettings";
import CompanyAddressChange from "./pages/companySettings/companyAddressChange";
import CompanyBusinessTypeChange from "./pages/companySettings/companyBusinessTypeChange";
import CompanyContactChange from "./pages/companySettings/companyContactChange";
import CompanyEmailChange from "./pages/companySettings/companyEmailChange";
import CompanyIndustryChange from "./pages/companySettings/companyIndustryChange";
import CompanyNameChange from "./pages/companySettings/companyNameChange";
import CompanyPhoneChange from "./pages/companySettings/companyPhoneChange";
import CompanyWebsiteChange from "./pages/companySettings/companyWebsiteChange";
import Dashboard from "./pages/dashboard";
import EmailVerify from "./pages/emailVerify";
import Files from "./pages/files";
import File from "./pages/files/file";
import FileEdit from "./pages/files/fileEdit";
import Folder from "./pages/files/folder";
import FolderCreate from "./pages/files/folderCreate";
import FolderEdit from "./pages/files/folderEdit";
import FolderInfo from "./pages/files/folderInfo";
import FloorPlansPage from "./pages/floorPlans";
import FloorPlanCreate from "./pages/floorPlans/create";
import FloorPlanEdit from "./pages/floorPlans/edit";
import ForgotPassword from "./pages/forgotPassword";
import HelpAndSupport from "./pages/helpAndSupport";
import Home from "./pages/home";
import Locations from "./pages/locations";
import LocationCreate from "./pages/locations/create";
import LocationEdit from "./pages/locations/edit";
import LocationsShow from "./pages/locations/show";
import MaintenancePage from "./pages/maintenances";
import MyCompanies from "./pages/myCompanies";
import MySettings from "./pages/mySettings";
import ChangeEmail from "./pages/mySettings/changeEmail";
import ChangeName from "./pages/mySettings/changeName";
import ChangePassword from "./pages/mySettings/changePassword";
import ChangePhone from "./pages/mySettings/changePhone";
import NativeAssetPanelPrintPage from "./pages/native/assetPanelPrint";
import NativeBillingPage from "./pages/native/billing";
import NativePaymentsPage from "./pages/native/billing/billingHistory";
import NativePaymentMethodssPage from "./pages/native/billing/paymentMethods";
import NativeCalendlyPage from "./pages/native/calendly";
import NativeChangePlanPage from "./pages/native/changePlan";
import NativeCreatePaymentPage from "./pages/native/createPayment";
import NativeLeafletPage from "./pages/native/leaflet";
import NativeLiveChatPage from "./pages/native/liveChat";
import MapboxPage from "./pages/native/mapbox";
// import NativeSignUpPage from "./pages/native/signup";
import NotAuthorized from "./pages/notAuthorized";
import NotFound from "./pages/notFound";
import Notifications from "./pages/notifications";
import Onboarding from "./pages/onboarding";
import ReportsPage from "./pages/reports";
import AssetsReportPage from "./pages/reports/asset";
import MaintenanceReportPage from "./pages/reports/maintenance";
// import PlansReportPage from "./pages/reports/plan";
import UserActivityReportPage from "./pages/reports/userActivity";
import ResetCompanyPage from "./pages/resetCompany";
import ResetPassword from "./pages/resetPassword";
import SignIn from "./pages/signIn";
import SignOut from "./pages/signOut";
import SignUp from "./pages/signUp";
// import SignUpPro from "./pages/signUp/pro";
import SystemStatus from "./pages/systemStatus";
import CompanyTeams from "./pages/teams";
import TeamChangeOwner from "./pages/teams/changeOwner";
import TeamCreate from "./pages/teams/create";
import TeamUpdate from "./pages/teams/update";
import WelcomePage from "./pages/welcome";
import WorkOrderCreatePage from "./pages/workOrders/create";
import WorkOrdersPage from "./pages/workOrders/dashboard";
import WorkOrderEditPage from "./pages/workOrders/edit";
import ZendeskSignIn from "./pages/zendeskSignIn";
import RouteAuthorized from "./routeAuthorized";

interface APRoute {
  path: string;
  element: ReactElement;
  loggedInRoute?: boolean;
  parentRouteKey?: string;
}

interface APRoutes {
  [key: string]: APRoute;
}

const routes: APRoutes = {
  home: {
    path: "/",
    element: (
      <DashboardOrAuth>
        <Home />
      </DashboardOrAuth>
    ),
  },
  emailVerify: {
    path: "/email-verify/:email/:token",
    element: (
      <DashboardOrAuth>
        <EmailVerify />
      </DashboardOrAuth>
    ),
  },

  signIn: {
    path: "/sign-in",
    element: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
  },
  signUp: {
    path: "/sign-up",
    element: (
      <AuthLayout>
        <SignUp />
      </AuthLayout>
    ),
  },
  // signUpPro: {
  //   path: "/pro-sign-up",
  //   element: (
  //     <AuthLayout showProMessage>
  //       <SignUpPro />
  //     </AuthLayout>
  //   ),
  // },
  acceptInvitation: {
    path: "/accept-invitation/:email/:token/:companyName/:userName",
    element: (
      <AuthLayout>
        <AcceptInvitation />
      </AuthLayout>
    ),
  },
  acceptInvitation2: {
    path: "/accept-invitation/:email/:token/:companyName",
    element: (
      <AuthLayout>
        <AcceptInvitation />
      </AuthLayout>
    ),
  },
  forgotPassword: {
    path: "/forgot-password",
    element: (
      <AuthLayout>
        <ForgotPassword />
      </AuthLayout>
    ),
  },
  resetPassword: {
    path: "/reset-password/:email/:token",
    element: (
      <AuthLayout>
        <ResetPassword />
      </AuthLayout>
    ),
  },

  terms: {
    path: "/terms",
    element: (
      <AuthLayout>
        <Dashboard />
      </AuthLayout>
    ),
  },
  privacy: {
    path: "/privacy",
    element: (
      <AuthLayout>
        <Dashboard />
      </AuthLayout>
    ),
  },
  mspAgreement: {
    path: "/msp-agreement",
    element: (
      <AuthLayout>
        <Dashboard />
      </AuthLayout>
    ),
  },

  companyChange: {
    path: "/company-change/:companyId",
    element: (
      <NativeLayout>
        <CompanyChange />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  resetCompany: {
    path: "/reset-company",
    element: (
      <AuthLayout>
        <ResetCompanyPage />
      </AuthLayout>
    ),
  },
  signOut: {
    path: "/sign-out",
    element: (
      <AuthLayout>
        <SignOut />
      </AuthLayout>
    ),
  },

  companyCreate: {
    path: "/company/create",
    element: (
      <ProgressLayout>
        <CompanyCreate />
      </ProgressLayout>
    ),
    loggedInRoute: true,
  },
  companyPlanCreate: {
    path: "/company/plan/create",
    element: (
      <ProgressLayout>
        <CompanyPlanCreate />
      </ProgressLayout>
    ),
    loggedInRoute: true,
  },

  welcome: {
    path: "/welcome",
    element: (
      <DashboardLayout>
        <WelcomePage />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  zendesk: {
    path: "/zendesk-sign-in",
    element: (
      <NativeLayout>
        <ZendeskSignIn />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  dashboard: {
    path: "/dashboard",
    element: (
      <DashboardLayout>
        <Dashboard />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  onboarding: {
    path: "/onboarding",
    element: (
      <DashboardLayout>
        <Onboarding />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  notifications: {
    path: "/notifications/:notificationId",
    element: (
      <DashboardLayout>
        <Notifications />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  locations: {
    path: "/locations",
    element: (
      <DashboardLayout>
        <Locations />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  locationsCreate: {
    path: "/locations/create",
    element: (
      <DashboardLayout>
        <Locations>
          <LocationCreate goBack />
        </Locations>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  locationsPlansCreate: {
    path: "/locations/plans/:locationId/create",
    element: (
      <DashboardLayout>
        <Locations>
          <FloorPlanCreate goBack />
        </Locations>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  locationsShow: {
    path: "/locations/:locationId",
    element: (
      <DashboardLayout>
        <LocationsShow />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  locationsShowCreate: {
    path: "/locations/:locationId/create",
    element: (
      <DashboardLayout>
        <LocationsShow>
          <LocationCreate goBack />
        </LocationsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  locationsShowEdit: {
    path: "/locations/:locationId/edit",
    element: (
      <DashboardLayout>
        <LocationsShow>
          <LocationEdit />
        </LocationsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  locationsShowPlanCreate: {
    path: "/locations/:locationId/plans/create",
    element: (
      <DashboardLayout>
        <LocationsShow>
          <FloorPlanCreate goBack />
        </LocationsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  locationsShowPlanEdit: {
    path: "/locations/:locationId/plans/:planId/edit",
    element: (
      <DashboardLayout>
        <LocationsShow>
          <FloorPlanEdit />
        </LocationsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  floorPlans: {
    path: "/plans",
    element: (
      <DashboardLayout>
        <FloorPlansPage />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  floorPlansCreate: {
    path: "/plans/create",
    element: (
      <DashboardLayout>
        <FloorPlansPage>
          <FloorPlanCreate goBack />
        </FloorPlansPage>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  floorPlansEdit: {
    path: "/plans/:planId/edit",
    element: (
      <DashboardLayout>
        <FloorPlansPage>
          <FloorPlanEdit />
        </FloorPlansPage>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  assets: {
    path: "/assets",
    element: (
      <DashboardLayout>
        <Assets />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsCreate: {
    path: "/assets/create",
    element: (
      <DashboardLayout>
        <Assets>
          <AssetCreate goBack />
        </Assets>
      </DashboardLayout>
    ),
    loggedInRoute: true,
    parentRouteKey: "assets",
  },
  assetsEdit: {
    path: "/assets/edit/:assetId",
    element: (
      <DashboardLayout>
        <Assets>
          <AssetEdit />
        </Assets>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShow: {
    path: "/assets/:assetId",
    element: (
      <DashboardLayout>
        <AssetsShow />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowEdit: {
    path: "/assets/:assetId/edit",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetEdit />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowAssetPartCreate: {
    path: "/assets/:assetId/asset-parts/create",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetPartCreate />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowAssetPartEdit: {
    path: "/assets/:assetId/asset-parts/:assetPartId/edit",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetPartEdit />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowFileCreate: {
    path: "/assets/:assetId/files/create",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetFileCreate />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowFileEdit: {
    path: "/assets/:assetId/files/:fileId/edit",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetFileEdit />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowFile: {
    path: "/assets/:assetId/files/:fileId",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetFileShow />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowFloorPlanCreate: {
    path: "/assets/:assetId/plans/create",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <FloorPlanAssetCreate />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowAffectedCreate: {
    path: "/assets/:assetId/affected/create",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetAffectedCreate type="affected" />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetsShowAffectedByCreate: {
    path: "/assets/:assetId/affected-by/create",
    element: (
      <DashboardLayout>
        <AssetsShow>
          <AssetAffectedCreate type="affectedBy" />
        </AssetsShow>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  maintenances: {
    path: "/service-schedule",
    element: (
      <DashboardLayout>
        <MaintenancePage />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  folders: {
    path: "/folders",
    element: (
      <DashboardLayout>
        <Files />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  foldersShow: {
    path: "/folders/:folderId",
    element: (
      <DashboardLayout>
        <Folder />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  foldersCreate: {
    path: "/folders/:folderId/create",
    element: (
      <DashboardLayout>
        <Folder>
          <FolderCreate />
        </Folder>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  foldersInfo: {
    path: "/folders/:folderId/info",
    element: (
      <DashboardLayout>
        <Folder>
          <FolderInfo />
        </Folder>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  foldersEdit: {
    path: "/folders/:folderId/edit",
    element: (
      <DashboardLayout>
        <Folder>
          <FolderEdit />
        </Folder>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  filesShow: {
    path: "/folders/:folderId/files/:fileId",
    element: (
      <DashboardLayout>
        <Folder>
          <File />
        </Folder>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  filesEdit: {
    path: "/folders/:folderId/files/:fileId/edit",
    element: (
      <DashboardLayout>
        <Folder>
          <FileEdit />
        </Folder>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  reports: {
    path: "/reports",
    element: (
      <DashboardLayout>
        <ReportsPage />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  maintenanceReports: {
    path: "/reports/maintenances",
    element: (
      <DashboardLayout>
        <MaintenanceReportPage />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetReports: {
    path: "/reports/assets",
    element: (
      <DashboardLayout>
        <AssetsReportPage />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  userActivityReports: {
    path: "/reports/user-activity",
    element: (
      <DashboardLayout>
        <UserActivityReportPage />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  companyTeams: {
    path: "/teams",
    element: (
      <DashboardLayout>
        <CompanyTeams />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyTeamsCreate: {
    path: "/teams/create",
    element: (
      <DashboardLayout>
        <CompanyTeams>
          <TeamCreate goBack />
        </CompanyTeams>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyTeamsChangeOwner: {
    path: "/teams/change-owner",
    element: (
      <DashboardLayout>
        <CompanyTeams>
          <TeamChangeOwner />
        </CompanyTeams>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyTeamsEdit: {
    path: "/teams/:teamId",
    element: (
      <DashboardLayout>
        <CompanyTeams>
          <TeamUpdate />
        </CompanyTeams>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  companySettings: {
    path: "/settings",
    element: (
      <DashboardLayout>
        <CompanySettings />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyNameChange: {
    path: "/settings/change-name",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyNameChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyAddressChange: {
    path: "/settings/change-address",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyAddressChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyContactChange: {
    path: "/settings/change-contact",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyContactChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyEmailChange: {
    path: "/settings/change-email",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyEmailChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyPhoneChange: {
    path: "/settings/change-phone",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyPhoneChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyWebsiteChange: {
    path: "/settings/change-website",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyWebsiteChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyBusinessTypeChange: {
    path: "/settings/change-business-type",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyBusinessTypeChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyIndustryChange: {
    path: "/settings/change-industry",
    element: (
      <DashboardLayout>
        <CompanySettings>
          <CompanyIndustryChange />
        </CompanySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  assetCategories: {
    path: "/asset-categories",
    element: (
      <DashboardLayout>
        <AssetCategories />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetCategoriesCreate: {
    path: "/asset-categories/create",
    element: (
      <DashboardLayout>
        <AssetCategories>
          <AssetCategoryCreate />
        </AssetCategories>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetCategoriesEdit: {
    path: "/asset-categories/edit/:assetCategoryId",
    element: (
      <DashboardLayout>
        <AssetCategories>
          <AssetCategoryEdit />
        </AssetCategories>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetTypesMaintenancesShow: {
    path: "/asset-categories/:assetCategoryId/asset-types/:assetTypeId/maintenances",
    element: (
      <DashboardLayout>
        <AssetCategories>
          <AssetTypeMaintenancesShow />
        </AssetCategories>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetTypesCreate: {
    path: "/asset-categories/:assetCategoryId/asset-types/create",
    element: (
      <DashboardLayout>
        <AssetCategories>
          <AssetTypeCreate />
        </AssetCategories>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetTypesEdit: {
    path: "/asset-categories/:assetCategoryId/asset-types/:assetTypeId/edit",
    element: (
      <DashboardLayout>
        <AssetCategories>
          <AssetTypeEdit />
        </AssetCategories>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  assetTypesShow: {
    path: "/asset-categories/:assetCategoryId/asset-types/:assetTypeId",
    element: (
      <DashboardLayout>
        <AssetCategories>
          <AssetTypeShow />
        </AssetCategories>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  companyPlan: {
    path: "/billing",
    element: (
      <DashboardLayout>
        <CompanyBilling />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyPaymentMethods: {
    path: "/billing/payment-methods",
    element: (
      <DashboardLayout>
        <CompanyBilling />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyPaymentMethodsCreate: {
    path: "/billing/payment-methods/create",
    element: (
      <DashboardLayout>
        <CompanyBilling>
          <CreatePaymentPage />
        </CompanyBilling>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  companyBillingHistory: {
    path: "/billing/payments",
    element: (
      <DashboardLayout>
        <CompanyBilling />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  helpAndSupport: {
    path: "/help-support",
    element: (
      <DashboardLayout>
        <HelpAndSupport />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  systemStatus: {
    path: "/help-support/system-status",
    element: (
      <DashboardLayout>
        <SystemStatus />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  mySettings: {
    path: "/my-settings",
    element: (
      <DashboardLayout>
        <MySettings />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  mySettingsChangeName: {
    path: "/my-settings/change-name",
    element: (
      <DashboardLayout>
        <MySettings>
          <ChangeName />
        </MySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  mySettingsChangePassword: {
    path: "/my-settings/change-password",
    element: (
      <DashboardLayout>
        <MySettings>
          <ChangePassword />
        </MySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  mySettingsChangeEmail: {
    path: "/my-settings/change-email",
    element: (
      <DashboardLayout>
        <MySettings>
          <ChangeEmail />
        </MySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },
  mySettingsChangePhone: {
    path: "/my-settings/change-phone",
    element: (
      <DashboardLayout>
        <MySettings>
          <ChangePhone />
        </MySettings>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  myCompanies: {
    path: "/my-companies",
    element: (
      <DashboardLayout>
        <MyCompanies />
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  nativeAssetPanelPrint: {
    path: "/native/asset-panel-print/:assetId",
    element: (
      <NativeLayout>
        <NativeAssetPanelPrintPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativeMapbox: {
    path: "/native/locations/:locationId/plans/map/create",
    element: (
      <NativeLayout>
        <MapboxPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  // nativeSignUp: {
  //   path: "/native/sign-up",
  //   element: (
  //     <NativeLayout>
  //       <NativeSignUpPage />
  //     </NativeLayout>
  //   ),
  // },
  nativePlans: {
    path: "/native/plans",
    element: (
      <NativeLayout noPadding>
        <NativeLeafletPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativeChangePlan: {
    path: "/native/billing/plans/change",
    element: (
      <NativeLayout>
        <NativeChangePlanPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativeCompanyPaymentMethodsCreate: {
    path: "/native/billing/payment-methods/create",
    element: (
      <NativeLayout>
        <NativeCreatePaymentPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativeCalendly: {
    path: "/native/calendly",
    element: (
      <NativeLayout noPadding>
        <NativeCalendlyPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativeBilling: {
    path: "/native/billing",
    element: (
      <NativeLayout>
        <NativeBillingPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativePaymentMethods: {
    path: "/native/billing/payment-methods",
    element: (
      <NativeLayout>
        <NativePaymentMethodssPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativePayments: {
    path: "/native/billing/payments",
    element: (
      <NativeLayout>
        <NativePaymentsPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },
  nativeLiveChat: {
    path: "/native/live-chat",
    element: (
      <NativeLayout>
        <NativeLiveChatPage />
      </NativeLayout>
    ),
    loggedInRoute: true,
  },

  workOrders: {
    path: "/work-orders",
    element: (
      <DashboardLayout disableSearch={true}>
        <ElasticUIWrapper>
          <WorkOrdersPage />
        </ElasticUIWrapper>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  workOrdersCreate: {
    path: "/work-orders/create",
    element: (
      <DashboardLayout disableSearch={true}>
        <ElasticUIWrapper>
          <WorkOrderCreatePage />
        </ElasticUIWrapper>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  workOrdersEdit: {
    path: "/work-orders/:workOrderId",
    element: (
      <DashboardLayout disableSearch={true}>
        <ElasticUIWrapper>
          <WorkOrderEditPage />
        </ElasticUIWrapper>
      </DashboardLayout>
    ),
    loggedInRoute: true,
  },

  notAuthorized: {
    path: "/not-authorized",
    element: (
      <DashboardOrAuth>
        <NotAuthorized />
      </DashboardOrAuth>
    ),
  },
  notFound: {
    path: "*",
    element: (
      <DashboardOrAuth>
        <NotFound />
      </DashboardOrAuth>
    ),
  },
};

const Router = () => {
  return (
    <Routes>
      {Object.keys(routes).map((routeKey) => {
        const route = routes[routeKey];
        return route.loggedInRoute ? (
          <Route
            path={route.path}
            key={route.path}
            element={
              <RouteAuthorized routeKey={routeKey}>
                {route.element}
              </RouteAuthorized>
            }
          />
        ) : (
          <Route path={route.path} key={route.path} element={route.element} />
        );
      })}
    </Routes>
  );
};

export default Router;

export const getRoutePath = (pathName: string, params?: object): string => {
  const route = routes[pathName];
  if (!route) return "";
  if (!params) return route.path;

  const toPath = compile(route.path, { encode: encodeURIComponent });
  return toPath(params);
};

export const restrictedPages = [
  getRoutePath("signOut"),
  getRoutePath("resetCompany"),
];
