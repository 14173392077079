export const getFilesUrl = (key: string, size?: number) => {
  const imgSize = size
    ? {
        width: Math.ceil(size * 2),
        height: Math.ceil(size * 2),
      }
    : {};
  return `${process.env.REACT_APP_FILES_URL}/${btoa(
    JSON.stringify({
      bucket: process.env.REACT_APP_FILES_BUCKET,
      key,
      edits: { resize: { ...imgSize, fit: "outside" }, rotate: null },
    })
  )}`;
};
