const baseStyle = () => ({
  header: {
    textAlign: "center",
  },
  dialog: {
    borderRadius: 0,
  },
  footer: {
    paddingLeft: "4",
    paddingRight: "4",
  },
});

const Modal = {
  baseStyle,
};

export default Modal;
