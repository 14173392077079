import { useReactiveVar } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import React from "react";

import { layoutOptionsVar } from "../../../../graphql/reactiveVariables";
import { mainLayoutPaddingX } from "../../../../utils/layout";

interface DashboardMainProps {}

const DashboardMain: React.FC<DashboardMainProps> = ({ children }) => {
  const styleProps = useReactiveVar(layoutOptionsVar).styleProps || {};

  return (
    <Box as="main" gridArea="main" overflowY="auto" id="main" role="main">
      <Box
        marginX="auto"
        width="100%"
        paddingX={mainLayoutPaddingX}
        id="main_inner"
        paddingY="4"
        {...styleProps}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardMain;
