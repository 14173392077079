import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

const PageSpinner: React.FC<{ height?: number }> = ({ height = 350 }) => (
  <Center minHeight={`${height}px`}>
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.100"
      color="blue.500"
      size="xl"
    />
  </Center>
);

export default PageSpinner;
