import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  CloseButton,
  Collapse,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faInfoCircle,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniqBy } from "lodash";
import { flatten } from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  FloorPlanAssetForMapFragmentFragment,
  FloorPlanForMapFragmentFragment,
  FloorPlanFragmentFragment,
  SortBy,
  SortOrder,
  useCompanyQuery,
} from "../../../graphql/graphql";
import {
  changePlanEditorOptions,
  isNativeWebViewVar,
  planEditorOptionsVar,
} from "../../../graphql/reactiveVariables";
import useAssetCategories from "../../../hooks/useAssetCategories";
import { maintenanceStatusTypeOptions } from "../../../pages/assets/presenter";
import { setPlanIdForLocation } from "../../../utils/lastOpenedPlanForLocation";
import orderBy from "../../../utils/sort";
import SearchIcon from "../../icons/search";
import AssetCategorySelectOption from "../assetCategorySelectOption";
import AssetTypeSelectOption from "../assetTypeSelectOption";
import LabeledMenuButton from "../labeledMenuButton";
import TooltipName from "../tooltipName";
import MapAsset from "./mapAsset";

interface MapAssetListProps {
  floorPlan: FloorPlanForMapFragmentFragment;
  floorPlanAssets: FloorPlanAssetForMapFragmentFragment[];
}

const MapAssetList: React.FC<MapAssetListProps> = ({
  floorPlan,
  floorPlanAssets,
}) => {
  const [isNative] = useState(isNativeWebViewVar());
  const {
    assetIds,
    // clusterAssets,
    quickDraw,
    affectedAreaConnections,
    showAssetStatus,
    assetCategoryIds,
    assetTypeIds,
    assetSearchQuery,
    assetMaintenanceStatus,
  } = useReactiveVar(planEditorOptionsVar);
  const { data: companyData } = useCompanyQuery();
  const { locationId } = useParams();
  const { isOpen: isDescriptionOpen, onToggle: toggleDescription } =
    useDisclosure();

  const { data: assetCategoriesData } = useAssetCategories();
  const assetCategory = useMemo(() => {
    if (assetCategoryIds.length !== 1) return undefined;
    const assetCategories = assetCategoriesData?.assetCategories;
    if (!assetCategories) return undefined;
    return assetCategories.find((ac) => ac.id === assetCategoryIds[0]);
  }, [assetCategoriesData?.assetCategories, assetCategoryIds]);
  const assetType = useMemo(() => {
    if (assetTypeIds.length !== 1) return undefined;
    const assetCategories = assetCategoriesData?.assetCategories;
    if (!assetCategories) return undefined;
    const assetTypes = flatten(
      assetCategories.map((ac) => ac.assetTypes || [])
    );
    return assetTypes.find((at) => at.id === assetTypeIds[0]);
  }, [assetCategoriesData?.assetCategories, assetTypeIds]);

  const floorPlanAssetAssets = useMemo(
    () =>
      orderBy(
        floorPlanAssets.map(({ asset }) => asset),
        SortBy.Name,
        SortOrder.Asc
      ),
    [floorPlanAssets]
  );
  const [, setSearchParams] = useSearchParams();

  // const toggleClusterAssets = useCallback(() => {
  //   changePlanEditorOptions({ clusterAssets: !clusterAssets });
  // }, [clusterAssets]);
  const toggleQuickDraw = useCallback(() => {
    changePlanEditorOptions({ quickDraw: !quickDraw });
  }, [quickDraw]);
  const toggleAffectedAreaConnections = useCallback(() => {
    changePlanEditorOptions({
      affectedAreaConnections: !affectedAreaConnections,
    });
  }, [affectedAreaConnections]);
  const toggleShowAssetStatus = useCallback(() => {
    changePlanEditorOptions({ showAssetStatus: !showAssetStatus });
  }, [showAssetStatus]);

  const assetTypesWithAssets = useMemo(
    () =>
      floorPlan
        ? uniqBy(
            floorPlan.floorPlanAssets.map((fpa) => fpa.asset.assetType),
            "id"
          )
        : [],
    [floorPlan]
  );
  const assetCategoriesWithAssets = useMemo(
    () =>
      orderBy(
        uniqBy(
          assetTypesWithAssets.map((at) => at.assetCategory),
          "id"
        ),
        SortBy.Name,
        SortOrder.Asc
      ),
    [assetTypesWithAssets]
  );

  const selectedAssetCategoryTypes = useMemo(() => {
    if (!assetCategoryIds.length) return [];
    return orderBy(
      assetTypesWithAssets.filter((at) =>
        assetCategoryIds.includes(at.assetCategoryId)
      ),
      SortBy.Name,
      SortOrder.Asc
    );
  }, [assetCategoryIds, assetTypesWithAssets]);

  const onPlanChange = React.useCallback(
    (floorPlan: FloorPlanFragmentFragment) => {
      if (locationId) setPlanIdForLocation(locationId, floorPlan.id);
      setSearchParams({ CaAprType: "planEditor", planId: floorPlan.id });
    },
    [locationId, setSearchParams]
  );

  const orderedFloorPlans = React.useMemo(
    () => orderBy(floorPlan.location.floorPlans, SortBy.Name, SortOrder.Asc),
    [floorPlan.location.floorPlans]
  );

  const goToPrevPlan = React.useCallback(() => {
    const index = orderedFloorPlans.findIndex((fp) => fp.id === floorPlan.id);
    onPlanChange(
      orderedFloorPlans[
        index - 1 < 0 ? orderedFloorPlans.length - 1 : index - 1
      ]
    );
  }, [floorPlan, onPlanChange, orderedFloorPlans]);

  const goToNextPlan = React.useCallback(() => {
    const index = orderedFloorPlans.findIndex((fp) => fp.id === floorPlan.id);
    onPlanChange(
      orderedFloorPlans[index + 1 >= orderedFloorPlans.length ? 0 : index + 1]
    );
  }, [floorPlan, onPlanChange, orderedFloorPlans]);

  const setAssetCategoryId = React.useCallback((ids?: string[]) => {
    changePlanEditorOptions({
      assetCategoryIds: ids ?? [],
      assetTypeIds: [],
      assetIds: [],
      assetPartIds: [],
    });
  }, []);

  const setAssetTypeId = React.useCallback((ids?: string[]) => {
    changePlanEditorOptions({
      assetTypeIds: ids ?? [],
      assetIds: [],
      assetPartIds: [],
    });
  }, []);

  const setMaintenances = React.useCallback((ids?: string[]) => {
    changePlanEditorOptions({
      assetMaintenanceStatus: ids ?? [],
      assetIds: [],
      assetPartIds: [],
    });
  }, []);

  const setAssetSearchQuery = React.useCallback((assetSearchQuery?: string) => {
    changePlanEditorOptions({
      assetSearchQuery: assetSearchQuery ?? "",
      assetIds: [],
      assetPartIds: [],
    });
  }, []);

  const handleSelectAllAssets = React.useCallback(() => {
    changePlanEditorOptions({
      assetIds: floorPlanAssetAssets.map((a) => a.id),
      assetPartIds: [],
    });
  }, [floorPlanAssetAssets]);

  const handleSelectNoneAssets = React.useCallback(() => {
    changePlanEditorOptions({
      assetIds: [],
      assetPartIds: [],
    });
  }, []);

  const allAssetsSelected = floorPlanAssets.length === assetIds.length;
  const noAssetsSelected = !assetIds.length;

  const selectedFilter =
    assetMaintenanceStatus.length < 1 || assetMaintenanceStatus.length > 2
      ? "All"
      : maintenanceStatusTypeOptions.filter((msto) =>
          assetMaintenanceStatus.includes(msto.value)
        );

  return (
    <>
      {!isNative && (
        <Box flexGrow={1} paddingX="2">
          <TooltipName
            name={companyData?.company.name || ""}
            as="h2"
            fontSize="md"
            marginBottom="2"
          />
          <Flex
            paddingBottom="3"
            alignItems="center"
            sx={{
              "> div:first-of-type": {
                width: "49%",
              },
            }}
          >
            {orderedFloorPlans.length > 1 ? (
              <>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <LabeledMenuButton
                        isOpen={isOpen}
                        value={floorPlan.name}
                        label="Plan"
                        styleProps={{
                          maxWidth: "none !important",
                          width: "100%",
                        }}
                      />
                      <MenuList>
                        {orderedFloorPlans.map((fp) => (
                          <MenuItem
                            key={fp.id}
                            onClick={() => onPlanChange(fp)}
                          >
                            {fp.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
                <IconButton
                  onClick={goToPrevPlan}
                  aria-label="Go to previous plan"
                  variant="link"
                  colorScheme="gray"
                  icon={<FontAwesomeIcon icon={faChevronLeft} />}
                />
                <IconButton
                  onClick={goToNextPlan}
                  aria-label="Go to next plan"
                  variant="link"
                  colorScheme="gray"
                  icon={<FontAwesomeIcon icon={faChevronRight} />}
                />
              </>
            ) : (
              <TooltipName name={floorPlan.name} as="h1" fontSize="xl" />
            )}
            <Spacer />
            <Box paddingRight={{ base: "10", md: "0" }}>
              <Popover isLazy returnFocusOnClose autoFocus>
                <PopoverTrigger>
                  <IconButton
                    aria-label="Plan Settings"
                    variant="link"
                    colorScheme="gray"
                    icon={<FontAwesomeIcon icon={faCog} />}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    {/* TODO: Decide if we need cluster option */}
                    {/* <Flex alignItems="center">
                      <Switch
                        isChecked={clusterAssets}
                        onChange={toggleClusterAssets}
                        aria-label={`${clusterAssets ? "Disable" : "Enable"} asset clustering`}
                      />
                      <Button
                        variant="unstyled"
                        onClick={toggleClusterAssets}
                        fontWeight="normal"
                        textTransform="none"
                        type="button"
                        marginLeft="2"
                      >
                        Clusters
                      </Button>
                    </Flex> */}
                    <Flex alignItems="center">
                      <Switch
                        isChecked={quickDraw}
                        onChange={toggleQuickDraw}
                        aria-label={`${
                          quickDraw ? "Disable" : "Enable"
                        } quick draw`}
                      />
                      <Button
                        variant="unstyled"
                        onClick={toggleQuickDraw}
                        fontWeight="normal"
                        textTransform="none"
                        type="button"
                        marginLeft="2"
                      >
                        Quick draw
                      </Button>
                    </Flex>
                    <Flex alignItems="center">
                      <Switch
                        isChecked={affectedAreaConnections}
                        onChange={toggleAffectedAreaConnections}
                        aria-label={`${
                          affectedAreaConnections ? "Disable" : "Enable"
                        } lines to affected areas`}
                      />
                      <Button
                        variant="unstyled"
                        onClick={toggleAffectedAreaConnections}
                        fontWeight="normal"
                        textTransform="none"
                        type="button"
                        marginLeft="2"
                      >
                        Affected area connectors
                      </Button>
                    </Flex>
                    <Flex alignItems="center">
                      <Switch
                        isChecked={showAssetStatus}
                        onChange={toggleShowAssetStatus}
                        aria-label={`${
                          showAssetStatus ? "Disable" : "Enable"
                        } asset service status in plan`}
                      />
                      <Button
                        variant="unstyled"
                        onClick={toggleShowAssetStatus}
                        fontWeight="normal"
                        textTransform="none"
                        type="button"
                        marginLeft="2"
                      >
                        Service status
                      </Button>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Flex>
          {!!floorPlan.description && (
            <>
              <Collapse in={isDescriptionOpen}>
                <Text fontSize="sm" whiteSpace="pre-wrap" marginBottom="2">
                  {floorPlan.description}
                </Text>
              </Collapse>
              <Button
                size="sm"
                fontSize="sm"
                variant="link"
                colorScheme="gray"
                paddingLeft="0"
                marginBottom="4"
                onClick={toggleDescription}
              >
                {isDescriptionOpen ? "Hide" : "Show"} description
                <Box marginLeft="2">
                  <FontAwesomeIcon
                    icon={isDescriptionOpen ? faChevronUp : faChevronDown}
                    size="xs"
                  />
                </Box>
              </Button>
            </>
          )}
        </Box>
      )}
      <Box marginBottom="3" paddingX="2">
        <Flex
          justifyContent="space-between"
          sx={{
            "> div:nth-of-type(even)": {
              width: "49%",
            },
          }}
        >
          <FormControl width="49%">
            <InputLeftElement
              marginTop="22px"
              paddingLeft="2"
              children={<SearchIcon color="gray.600" boxSize="4" />}
            />
            <Input
              paddingLeft="32px !important"
              paddingRight="24px !important"
              value={assetSearchQuery}
              onChange={(e) => setAssetSearchQuery(e.target.value)}
              placeholder="Search"
            />
            {!!assetSearchQuery && (
              <InputRightElement
                marginTop="18px"
                onClick={() => setAssetSearchQuery(undefined)}
                children={<CloseButton color="gray.600" size="sm" />}
                tabIndex={0}
                role="button"
              />
            )}
          </FormControl>
          <Menu closeOnSelect={false}>
            {({ isOpen }) => (
              <>
                <LabeledMenuButton
                  isOpen={isOpen}
                  value={
                    Array.isArray(selectedFilter) ? (
                      <Flex align="center">
                        {selectedFilter.map((fl: any) => (
                          <Box mr="2" key={fl.value}>
                            {fl.label}
                          </Box>
                        ))}
                      </Flex>
                    ) : (
                      selectedFilter
                    )
                  }
                  label="Status"
                  styleProps={{
                    maxWidth: "none !important",
                    width: "180px",
                  }}
                />
                <MenuList>
                  <MenuOptionGroup
                    type="checkbox"
                    onChange={(value) => {
                      if (!Array.isArray(value)) {
                        setMaintenances(value === "All" ? undefined : [value]);
                      } else if (value.includes("All")) {
                        setMaintenances();
                      } else {
                        setMaintenances(value);
                      }
                    }}
                    value={assetMaintenanceStatus}
                  >
                    <MenuItemOption value="All">All</MenuItemOption>
                    {maintenanceStatusTypeOptions.map((option) => (
                      <MenuItemOption key={option.value} value={option.value}>
                        {option.label}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
      </Box>
      <Box
        paddingX="2"
        marginBottom="3"
        paddingBottom="3"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Flex
          justifyContent="space-between"
          sx={{
            "> div:nth-of-type(odd)": {
              width: "49%",
            },
          }}
        >
          <Menu closeOnSelect={false}>
            {({ isOpen }) => (
              <>
                <LabeledMenuButton
                  isOpen={isOpen}
                  label="Category"
                  value={
                    assetCategoryIds.length > 1 ? (
                      <>
                        {pluralize("Category", assetCategoryIds.length, true)}
                      </>
                    ) : (
                      <AssetCategorySelectOption
                        assetCategory={assetCategory}
                      />
                    )
                  }
                  styleProps={{
                    maxWidth: "none !important",
                    width: "100%",
                  }}
                />
                <MenuList>
                  <MenuOptionGroup
                    type="checkbox"
                    onChange={(value) => {
                      if (!Array.isArray(value)) {
                        setAssetCategoryId(
                          value === "All" ? undefined : [value]
                        );
                      } else if (value.includes("All")) {
                        setAssetCategoryId();
                      } else {
                        setAssetCategoryId(value);
                      }
                    }}
                    value={assetCategoryIds}
                  >
                    <MenuItemOption value="All">All</MenuItemOption>
                    {assetCategoriesWithAssets.map((ac) => (
                      <MenuItemOption key={ac.id} value={ac.id}>
                        <AssetCategorySelectOption assetCategory={ac} />
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </>
            )}
          </Menu>
          {selectedAssetCategoryTypes.length > 0 && (
            <Menu closeOnSelect={false}>
              {({ isOpen }) => (
                <>
                  <LabeledMenuButton
                    isOpen={isOpen}
                    label="Type"
                    value={
                      assetTypeIds.length > 1 ? (
                        <>{pluralize("Type", assetTypeIds.length, true)}</>
                      ) : (
                        <AssetTypeSelectOption assetType={assetType} />
                      )
                    }
                    styleProps={{
                      maxWidth: "none !important",
                      width: "100%",
                    }}
                  />
                  <MenuList>
                    <MenuOptionGroup
                      type="checkbox"
                      onChange={(value) => {
                        if (!Array.isArray(value)) {
                          setAssetTypeId(value === "All" ? undefined : [value]);
                        } else if (value.includes("All")) {
                          setAssetTypeId();
                        } else {
                          setAssetTypeId(value);
                        }
                      }}
                      value={assetTypeIds}
                    >
                      <MenuItemOption value="All">All</MenuItemOption>
                      {selectedAssetCategoryTypes.map((at) => (
                        <MenuItemOption key={at.id} value={at.id}>
                          <AssetTypeSelectOption assetType={at} />
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </>
              )}
            </Menu>
          )}
        </Flex>
      </Box>
      <Box>
        {floorPlanAssetAssets.length ? (
          <>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              marginBottom="2"
              paddingX="2"
            >
              <Heading
                as="h2"
                size="sm"
                fontWeight="semibold"
                display="flex"
                alignItems="center"
              >
                {pluralize("asset", floorPlanAssetAssets.length, true)}
                <Tooltip
                  label="Click on any asset below to see affected areas"
                  hasArrow
                >
                  <Box color="gray.700" marginLeft="2">
                    <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                  </Box>
                </Tooltip>
              </Heading>
              <Button
                colorScheme="secondary"
                variant="link"
                rightIcon={
                  <FontAwesomeIcon
                    icon={
                      allAssetsSelected
                        ? faCheckSquare
                        : noAssetsSelected
                        ? faSquare
                        : faMinusSquare
                    }
                  />
                }
                onClick={
                  allAssetsSelected
                    ? handleSelectNoneAssets
                    : handleSelectAllAssets
                }
                marginRight="6px"
              >
                <Box fontSize="sm">
                  {allAssetsSelected ? "Unselect All" : "Select All"}
                </Box>
              </Button>
            </Flex>
            {floorPlanAssetAssets.map((a) => (
              <MapAsset key={a.id} asset={a} />
            ))}
          </>
        ) : (
          <Box textAlign="center" color="gray.700" fontSize="sm" paddingY="5">
            To add an asset to this plan, create a new asset or drag and drop an
            icon from the icon bar onto the plan.
          </Box>
        )}
      </Box>
    </>
  );
};

export default MapAssetList;
