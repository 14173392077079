import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Download = (props: IconProps & any) => (
  <Icon fill="currentColor" viewBox="0 0 24 24" boxSize="24px" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83337 14.1667C9.6667 14.1667 9.50003 14.0833 9.4167 14L5.1667 9.66667C5.00003 9.5 5.00003 9.25 5.1667 9.08333L5.75003 8.5C5.83337 8.33333 6.1667 8.33333 6.33337 8.58333L9.1667 11.4167V2.91667C9.1667 2.66667 9.33337 2.5 9.58337 2.5H10.4167C10.6667 2.5 10.8334 2.66667 10.8334 2.91667V11.4167L13.6667 8.58333C13.8334 8.41667 14.0834 8.41667 14.25 8.58333L14.8334 9.16667C15 9.33333 15 9.58333 14.8334 9.75L10.5834 14C10.5 14.0833 10.3334 14.1667 10.1667 14.1667H9.83337ZM17.5 12.9167V15.8333C17.5 16.75 16.75 17.5 15.8333 17.5H4.16667C3.25 17.5 2.5 16.75 2.5 15.8333V12.9167C2.5 12.6667 2.66667 12.5 2.91667 12.5H3.75C4 12.5 4.16667 12.6667 4.16667 12.9167V15.8333H15.8333V12.9167C15.8333 12.6667 16 12.5 16.25 12.5H17.0833C17.3333 12.5 17.5 12.6667 17.5 12.9167Z"
    />
  </Icon>
);

export default Download;
