import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React from "react";

import { CARD_DELETED } from "./../../../constants/lang/en";
import {
  GENERIC_ERROR_MESSAGE,
  PRIMARY_CARD_NO_DELETE,
  PRIMARY_PAYMENT_CHANGED,
} from "../../../constants/lang/en";
import {
  StripeCustomerDocument,
  StripePaymentMethodTypeFragmentFragment,
  StripePaymentMethodsDocument,
  useStripePaymentMethodDeleteMutation,
  useStripePaymentMethodMakePrimaryMutation,
} from "../../../graphql/graphql";
import { setGenericMessage } from "../../../utils/serverErrors";
import CreditCard from "../creditCard";
import Spinner from "../spinner";

interface CreditCardPaymentMethodProps {
  defaultSource?: string;
  card: StripePaymentMethodTypeFragmentFragment;
  type?: "default" | "simple";
  onSetPrimarySuccess?: () => void;
  showPrimary?: boolean;
}

const CreditCardPaymentMethod: React.FC<CreditCardPaymentMethodProps> = ({
  card,
  defaultSource,
  type = "default",
  showPrimary = true,
  onSetPrimarySuccess,
}) => {
  const isDefaultType = type === "default";
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [stripePaymentMethodMakePrimaryMutation, { loading: settingPrimary }] =
    useStripePaymentMethodMakePrimaryMutation({
      refetchQueries: [{ query: StripeCustomerDocument }],
      awaitRefetchQueries: true,
    });
  const [stripePaymentMethodDeleteMutation, { loading: deleting }] =
    useStripePaymentMethodDeleteMutation({
      refetchQueries: [{ query: StripePaymentMethodsDocument }],
      awaitRefetchQueries: true,
    });
  const cardDetails = card.card;

  const makePrimary = async () => {
    if (defaultSource === card.id) {
      if (onSetPrimarySuccess) onSetPrimarySuccess();
      return;
    }

    try {
      await stripePaymentMethodMakePrimaryMutation({
        variables: { stripePaymentMethodId: card.id },
      });
      if (onSetPrimarySuccess) onSetPrimarySuccess();
      if (isDefaultType) {
        toast({
          description: PRIMARY_PAYMENT_CHANGED,
          status: "success",
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    if (defaultSource === card.id) {
      toast({
        description: PRIMARY_CARD_NO_DELETE,
        status: "info",
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      if (!card.id) {
        throw new Error(GENERIC_ERROR_MESSAGE);
      }
      await stripePaymentMethodDeleteMutation({
        variables: { stripePaymentMethodId: card.id },
      });
      toast({
        description: CARD_DELETED,
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      tabIndex={isDefaultType ? -1 : 0}
      onClick={isDefaultType ? undefined : makePrimary}
      alignItems="center"
      paddingX={{ base: "2", md: "7" }}
      paddingY={{ base: "6", md: "7" }}
      marginBottom="4"
      border="none"
      outline="none"
      backgroundColor="secondary.10"
      _hover={isDefaultType ? {} : { boxShadow: "md", cursor: "pointer" }}
    >
      <CreditCard card={card} />
      {defaultSource === card.id ? (
        <Flex
          alignItems="center"
          fontSize="sm"
          color="black"
          justifyContent="space-between"
        >
          <Box as="span" fontSize="sm" marginRight="2">
            {showPrimary ? "Primary" : ""}
          </Box>
          <Text as="span" color="secondary.500">
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          </Text>
        </Flex>
      ) : isDefaultType ? (
        <Flex
          alignItems="center"
          fontSize="sm"
          color="black"
          justifyContent="space-between"
        >
          <Button
            variant="link"
            colorScheme="secondary"
            fontSize="sm"
            onClick={makePrimary}
            marginRight="2"
            minWidth="0"
            isLoading={settingPrimary}
          >
            Set as Primary
          </Button>
          <Tooltip label="Delete card" hasArrow placement="bottom">
            <IconButton
              variant="icon"
              colorScheme="grayRed"
              aria-label="Delete card"
              onClick={onOpen}
              isLoading={deleting}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        </Flex>
      ) : settingPrimary ? (
        <Spinner />
      ) : null}
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody textAlign="center">
              Are you sure you want to delete the following payment method?
              <br />
              <Text as="strong">
                {cardDetails?.brand?.toUpperCase()} ending in{" "}
                {cardDetails?.last4}
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} width="48%">
                No, Don't Delete!
              </Button>
              <Button
                onClick={handleDelete}
                colorScheme="red"
                ml="4%"
                width="48%"
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default CreditCardPaymentMethod;

gql`
  query StripePaymentMethods {
    stripePaymentMethods {
      ...StripePaymentMethodTypeFragment
    }
  }
`;

gql`
  mutation StripePaymentMethodCreate(
    $stripePaymentMethodId: String!
    $attemptToChargeOpenInvoice: Boolean
  ) {
    stripePaymentMethodCreate(
      stripePaymentMethodId: $stripePaymentMethodId
      attemptToChargeOpenInvoice: $attemptToChargeOpenInvoice
    ) {
      ...StripePaymentMethodTypeFragment
    }
  }
`;

gql`
  mutation StripePaymentMethodDelete($stripePaymentMethodId: String!) {
    stripePaymentMethodDelete(stripePaymentMethodId: $stripePaymentMethodId)
  }
`;

gql`
  mutation StripePaymentMethodMakePrimary($stripePaymentMethodId: String!) {
    stripePaymentMethodMakePrimary(
      stripePaymentMethodId: $stripePaymentMethodId
    )
  }
`;

gql`
  mutation StripePaymentMethodUpdate(
    $stripePaymentMethodId: String!
    $expYear: Float!
    $expMonth: Float!
  ) {
    stripePaymentMethodUpdate(
      stripePaymentMethodId: $stripePaymentMethodId
      expYear: $expYear
      expMonth: $expMonth
    ) {
      ...StripePaymentMethodTypeFragment
    }
  }
`;
