import { Icon, IconProps } from "@chakra-ui/react";

const ElectricalPanel = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M42.672 60.75h36.562c2.505 0 3.943-2.868 2.438-4.875L63.39 31.5a3.048 3.048 0 0 0-4.875 0L40.234 55.875c-1.503 2.004-.07 4.875 2.438 4.875Zm18.281-22.344 12.188 16.25H48.766l12.187-16.25Z"
      fill="currentColor"
    />
    <path
      d="M21.344 15.047v91.406a3.047 3.047 0 0 0 3.047 3.047h21.328v3.453a3.047 3.047 0 1 0 6.093 0V109.5h6.094v3.453a3.047 3.047 0 1 0 6.094 0V109.5h6.094v3.453a3.047 3.047 0 1 0 6.094 0V109.5h21.328a3.046 3.046 0 0 0 3.046-3.047V66.322c3.547-1.258 6.094-4.647 6.094-8.619 0-3.972-2.547-7.36-6.094-8.619V15.047A3.047 3.047 0 0 0 97.516 12H24.39a3.047 3.047 0 0 0-3.047 3.047Zm6.093 3.047H94.47v33.515a3.047 3.047 0 0 0 3.047 3.047 3.05 3.05 0 0 1 3.046 3.047 3.05 3.05 0 0 1-3.046 3.047 3.047 3.047 0 0 0-3.047 3.047v39.609H27.438V18.094Z"
      fill="currentColor"
    />
    <path
      d="M82.281 82.078a3.047 3.047 0 0 0-3.047-3.047H42.672a3.047 3.047 0 0 0 0 6.094h36.562a3.047 3.047 0 0 0 3.047-3.047ZM79.234 66.844H42.672a3.047 3.047 0 1 0 0 6.094h36.562a3.047 3.047 0 0 0 0-6.094ZM36.578 30.281a3.047 3.047 0 1 0 0-6.093 3.047 3.047 0 0 0 0 6.093ZM36.578 97.313a3.047 3.047 0 1 0 0-6.094 3.047 3.047 0 0 0 0 6.094ZM85.328 30.281a3.047 3.047 0 1 0 0-6.093 3.047 3.047 0 0 0 0 6.093ZM85.328 97.313a3.047 3.047 0 1 0 0-6.094 3.047 3.047 0 0 0 0 6.094Z"
      fill="currentColor"
    />
  </Icon>
);

export default ElectricalPanel;
