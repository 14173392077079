import { useReactiveVar } from "@apollo/client";
import { Box, Center } from "@chakra-ui/react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router-dom";

import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import { getRoutePath } from "../../../router";
import Link from "../link";

interface BillingInactivePlanProps {
  type: string;
}

const BillingInactivePlan: React.FC<BillingInactivePlanProps> = ({ type }) => {
  const location = useLocation();
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  return (
    <Center height="40">
      <Box as="span">
        <FontAwesomeIcon icon={faExclamationCircle} />
        <Box as="span" marginX="1">
          There is a problem with your payment.
        </Box>{" "}
        {ROLES.companyPlan.includes(currentCompanyRole.role) ? (
          <Link
            to={`${getRoutePath("companyPaymentMethods")}?redirectPath=${
              location.pathname
            }`}
            variant="link"
            colorScheme="secondary"
          >
            Fix Now
          </Link>
        ) : (
          <>Please contact your account admin</>
        )}{" "}
        to add more {type === "files" ? "storage" : type}.
      </Box>
    </Center>
  );
};

export default BillingInactivePlan;
