import "mapbox-gl/dist/mapbox-gl.css";

import { Buffer } from "buffer";

import { AspectRatio, Box, Button, Center, Input } from "@chakra-ui/react";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import axios from "axios";
import React from "react";
import ReactMapGL, { GeolocateControl, NavigationControl } from "react-map-gl";
import { useOrientation } from "react-use";

import {
  useCompanyQuery,
  useFileCreateMutation,
  useSignedUrlCreateMutation,
} from "../../../graphql/graphql";
import useAddressAutoSuggest from "../../../hooks/useAddressAutoSuggest";

interface MapProps {
  onSuccess: (id: string) => void;
}

const Map: React.FC<MapProps> = ({ onSuccess }) => {
  const [fileCreateMutation] = useFileCreateMutation();
  const [signedUrlCreateMutation] = useSignedUrlCreateMutation();
  const { data: currentCompanyData } = useCompanyQuery();
  const [query, setQuery] = React.useState("");
  const { results, resetResults } = useAddressAutoSuggest(query, {
    types: "address,postcode",
  });
  const [loading, setLoading] = React.useState(false);
  const mapRef = React.useRef<any>();
  const { type: orientation } = useOrientation();

  const [viewport, setViewport] = React.useState({
    latitude: 39.5,
    longitude: -98.35,
    zoom: 2,
  });

  const takeScreenshot = async () => {
    if (!mapRef || !mapRef.current || !currentCompanyData) return;
    const company = currentCompanyData.company;

    const mapboxMap = mapRef.current.getMap();
    const image = mapboxMap.getCanvas().toDataURL();
    if (!image) return;

    setLoading(true);
    const { data } = await signedUrlCreateMutation({
      variables: { data: [{ contentType: "image/png" }] },
    });

    if (data) {
      const buffer = Buffer.from(
        image.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );

      await axios.put(data.signedUrlCreate[0].signedUrl, buffer, {
        headers: { "Content-Type": "image/png", "Content-Encoding": "base64" },
      });
      const { data: fileData } = await fileCreateMutation({
        variables: {
          data: {
            path: data.signedUrlCreate[0].path,
            name: `Location-${Date.now()}`,
            folderId: company.uploadsFolderId || "",
          },
        },
      });

      if (fileData) onSuccess(fileData.fileCreate.id);
    }

    setLoading(false);
  };

  const handleAddressSelect = (id: string) => {
    const selectedResult: any = results.find((result: any) => result.id === id);
    const coordinates = selectedResult.geometry.coordinates;
    setViewport({
      latitude: coordinates[1],
      longitude: coordinates[0],
      zoom: 20,
    });
    setQuery("");
    resetResults();
  };

  return (
    <>
      <Combobox
        aria-label="Address suggestions"
        openOnFocus
        onSelect={(val) => {
          results.forEach((result: any) => {
            if (result.place_name === val) {
              handleAddressSelect(result.id);
              return;
            }
          });
        }}
      >
        <ComboboxInput
          as={Input as any}
          onChange={(e: any) => setQuery(e.currentTarget.value)}
          value={query}
          placeholder="Search"
          autoFocus
          autoComplete="off"
        />
        <ComboboxPopover>
          <ComboboxList>
            {results.map((result: any) => (
              <ComboboxOption key={result.id} value={result.place_name} />
            ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      <Center marginTop={4}>
        <AspectRatio
          width={
            orientation === "landscape-primary" ? "calc(96vh - 196px)" : "100%"
          }
          ratio={1}
        >
          <Box position="absolute" w="100%" h="100%">
            <ReactMapGL
              {...viewport}
              style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_PUBLIC_KEY}
              preserveDrawingBuffer
              ref={mapRef}
              pitch={30}
              attributionControl={false}
              onMoveEnd={(event) => setViewport(event.viewState)}
            >
              <Box position="absolute" top="0" left="0" padding="10px">
                <NavigationControl showCompass={false} />
              </Box>
              <Box position="absolute" top="0" right="0" margin="10px">
                <GeolocateControl />
              </Box>
            </ReactMapGL>
          </Box>
        </AspectRatio>
      </Center>
      <Box textAlign="center" marginTop={2} marginBottom={4}>
        <Button
          isLoading={loading}
          type="button"
          variant="outline"
          colorScheme="secondary"
          width="100%"
          onClick={() => takeScreenshot()}
        >
          Capture image
        </Button>
      </Box>
    </>
  );
};

export default Map;
