import {
  EuiFlexGroup,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  useEuiTheme,
} from "@elastic/eui";
import { UseEuiTheme } from "@elastic/eui/src/services/theme/hooks";
import { css } from "@emotion/react";
import React, { ReactNode } from "react";

interface TabLabelProps {
  isOpen: boolean;
  closeModal: () => void;
  noCloseIcon?: boolean;
  headerLabel?: string | ReactNode;
}

const Modal: React.FC<TabLabelProps> = ({
  isOpen,
  closeModal,
  noCloseIcon,
  headerLabel,
  children,
}) => {
  const { euiTheme }: UseEuiTheme<{ sizes: any }> = useEuiTheme();

  return (
    <>
      {isOpen ? (
        <EuiModal
          onClose={closeModal}
          maxWidth={false}
          className={noCloseIcon ? "critical-asset-modal_no-close-icon" : ""}
        >
          {headerLabel ? (
            <EuiModalHeader
              css={css`
                & {
                  padding-bottom: ${euiTheme.size.s};
                }
              `}
            >
              <EuiModalHeaderTitle>
                <EuiText
                  size="s"
                  style={{
                    padding: `0 ${euiTheme.size.base}`,
                    fontWeight: 500,
                    lineHeight: euiTheme.sizes["21px"],
                  }}
                >
                  {headerLabel}
                </EuiText>
              </EuiModalHeaderTitle>
            </EuiModalHeader>
          ) : null}
          <EuiModalBody
            css={css`
              & {
                .euiModalBody__overflow {
                  padding: 0 ${euiTheme.size.xxl} ${euiTheme.size.l};
                  margin-bottom: ${euiTheme.size.base};
                  mask-image: none;
                }
              }
            `}
          >
            {children}
          </EuiModalBody>
        </EuiModal>
      ) : null}
    </>
  );
};

export default Modal;
