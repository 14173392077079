import { Icon, IconProps } from "@chakra-ui/react";

const HVAC = (props: IconProps & any) => {
  return (
    <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
      <path
        d="M61.346 77.356V42.593a2.637 2.637 0 1 0-5.274 0v34.763C51.438 78.534 48 82.742 48 87.74c0 5.906 4.804 10.711 10.71 10.711 5.906 0 10.711-4.805 10.711-10.711 0-4.998-3.44-9.206-8.074-10.384Zm-2.637 15.822a5.444 5.444 0 0 1-5.437-5.438c0-3 2.44-5.44 5.437-5.44a5.444 5.444 0 0 1 5.438 5.44 5.444 5.444 0 0 1-5.438 5.438Z"
        fill="currentColor"
      />
      <path
        d="M71.795 70.983V32.087C71.795 24.871 65.925 19 58.71 19c-7.215 0-13.085 5.87-13.085 13.087v38.896a21.253 21.253 0 0 0-8.172 16.756c0 11.724 9.536 21.261 21.257 21.261 11.722 0 21.258-9.537 21.258-21.261 0-6.574-3.03-12.737-8.172-16.756ZM58.71 103.727c-8.814 0-15.984-7.172-15.984-15.988a15.99 15.99 0 0 1 7.017-13.236 2.638 2.638 0 0 0 1.155-2.181V32.087c0-4.308 3.505-7.814 7.812-7.814 4.308 0 7.813 3.506 7.813 7.814v40.234c0 .874.432 1.691 1.155 2.182a15.99 15.99 0 0 1 7.017 13.236c0 8.816-7.171 15.988-15.985 15.988Z"
        fill="currentColor"
      />
      <path
        d="M87.877 61.097h-8.172a2.637 2.637 0 1 0 0 5.274h8.172a2.637 2.637 0 0 0 0-5.274ZM87.877 50.549h-8.172a2.636 2.636 0 1 0 0 5.273h8.172a2.637 2.637 0 0 0 0-5.273ZM87.877 40h-8.172a2.636 2.636 0 1 0 0 5.273h8.172a2.637 2.637 0 0 0 0-5.273ZM79.705 34.724h8.172a2.637 2.637 0 0 0 0-5.273h-8.172a2.637 2.637 0 0 0 0 5.273Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default HVAC;
