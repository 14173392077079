import {
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import isEqual from "react-fast-compare";
import Slider from "react-slick";
import { ReactSortable } from "react-sortablejs";

import { GENERIC_ERROR_MESSAGE } from "../../../constants/lang/en";
import {
  AssetFileType,
  useAssetFileChangeOrderMutation,
} from "../../../graphql/graphql";
import ImagesReorder from "../../../pages/assets/show/details/imagesReorder";
import { getRoutePath } from "../../../router";
import { getFilesUrl } from "../../../utils/image";
import { setGenericMessage } from "../../../utils/serverErrors";
import AddCircle from "../../icons/addCircle";
import Link from "../link";
import PageSpinner from "../pageSpinner";
import ZoomWrapper from "../zoomWrapper";

export type ImageType = {
  id: string;
  path: string;
  name: string;
  description?: string;
};

interface ImageSliderProps {
  assetId: string;
  images: ImageType[];
  isPreview?: boolean;
  previewAvailable?: boolean;
  canAddAsset?: boolean;
  canSortAssetImages?: boolean;
  handleEdit?: (id: string) => void;
  handleDelete?: (image: any) => void;
  initialImageIndex?: number;
  setPreviewTransitionState?: React.Dispatch<
    React.SetStateAction<"NOT_STARTED" | "IN_PROGRESS" | "COMPLETED">
  >;
}

const ImageSlider: FC<ImageSliderProps> = ({
  images,
  assetId,
  handleEdit,
  handleDelete,
  isPreview = false,
  previewAvailable = false,
  canAddAsset = false,
  initialImageIndex,
  canSortAssetImages = false,
  setPreviewTransitionState,
}) => {
  const sliderRef = useRef<Slider>(null);
  const dragStatus: MutableRefObject<"PRESSED" | "DRAGGED" | "NOT_DRAGGED"> =
    useRef("NOT_DRAGGED");
  const [activeThumbIndex, setActiveThumbIndex] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [click, setClick] = useState(0);

  const [assetImages, setAssetImages] = useState<ImageType[]>(images);
  const toast = useToast();
  const [assetFileChangeOrder] = useAssetFileChangeOrderMutation();

  const handleAssetImagesReorder = useCallback(
    async (newAssetImages: ImageType[]) => {
      const oldImageIds = assetImages.map((ai) => ai.id);
      const newImageIds = newAssetImages.map((ai) => ai.id);
      const isOrderChanged = !isEqual(oldImageIds, newImageIds);
      if (!isOrderChanged) return;
      setAssetImages(newAssetImages);
      if (!canSortAssetImages) return;

      try {
        const { errors } = await assetFileChangeOrder({
          variables: { assetFileIds: newImageIds },
        });
        if (errors) {
          toast({
            description: GENERIC_ERROR_MESSAGE,
            status: "error",
            position: "top",
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          description: setGenericMessage(error),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    },
    [assetFileChangeOrder, assetImages, canSortAssetImages, toast]
  );

  const scrollToActiveSlide = useCallback(() => {
    const previews = document.querySelectorAll(".slick-dots li.slick-active");
    for (let i = 0; i < previews.length; ++i) {
      previews[i].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
    if (setPreviewTransitionState) setPreviewTransitionState("COMPLETED");
  }, [setPreviewTransitionState]);

  useEffect(() => {
    if (initialImageIndex !== undefined) {
      sliderRef.current?.slickGoTo(initialImageIndex, false);
      setTimeout(scrollToActiveSlide, 1000);
    }
  }, [initialImageIndex, scrollToActiveSlide]);

  useEffect(() => {
    setAssetImages(images);
  }, [images]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (click === 2) onOpen();
      setClick(0);
    }, 250);
    return () => clearTimeout(timer);
  }, [onOpen, click]);

  const customPaging = useCallback(
    (i) => {
      const image = assetImages[i];
      return image ? (
        <button id={image.id}>
          <Image
            src={getFilesUrl(image.path, 250)}
            alt={image.name}
            boxSize="20"
            _focus={{ outline: "none", boxShadow: "none" }}
            objectFit="contain"
            fallback={<Center boxSize="20" margin="auto" bgColor="gray.100" />}
          />
        </button>
      ) : (
        <></>
      );
    },
    [assetImages]
  );

  const appendDots = useCallback(
    (dots) =>
      assetImages.length > 1 && dots.length === assetImages.length ? (
        <div>
          {canSortAssetImages ? (
            <Flex
              maxWidth={isPreview ? "775px" : "unset"}
              margin={isPreview ? "auto" : "unset"}
              overflow={isPreview ? "auto" : "unset"}
              className="previewThumbsnails"
            >
              <ReactSortable
                tag="ul"
                style={{ width: "auto" }}
                list={assetImages.map((ai) => ({ ...ai, chosen: true }))}
                setList={handleAssetImagesReorder}
              >
                {dots}
              </ReactSortable>
              {canAddAsset && (
                <Flex
                  marginLeft="2"
                  width="20"
                  p="2"
                  borderWidth="1px"
                  borderColor="gray.300"
                >
                  <Tooltip label="Add new picture" hasArrow placement="right">
                    <Link
                      to={`${getRoutePath("assetsShowFileCreate", {
                        assetId,
                      })}?type=${AssetFileType.Image}`}
                      variant="icon"
                      colorScheme="secondary"
                      aria-label="Add New Picture"
                      w="full"
                    >
                      <AddCircle color="gray.300" boxSize="10" />
                    </Link>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          ) : (
            <Box as="ul" width="auto">
              {dots}
            </Box>
          )}
        </div>
      ) : (
        <></>
      ),
    [
      assetId,
      assetImages,
      canAddAsset,
      canSortAssetImages,
      handleAssetImagesReorder,
      isPreview,
    ]
  );

  return (
    <Box
      maxWidth={isPreview ? "100%" : "775px"}
      role="group"
      margin="auto"
      marginTop={assetImages.length > 1 ? "108px" : ""}
    >
      {assetImages.length === 1 && canAddAsset && (
        <Center>
          <Flex
            width="20"
            p="2"
            borderWidth="1px"
            borderColor="gray.300"
            mb="4"
          >
            <Tooltip label="Add new picture" hasArrow placement="right">
              <Link
                to={`${getRoutePath("assetsShowFileCreate", {
                  assetId,
                })}?type=${AssetFileType.Image}`}
                variant="icon"
                colorScheme="secondary"
                aria-label="Add New Picture"
                w="full"
              >
                <AddCircle color="gray.300" boxSize="10" />
              </Link>
            </Tooltip>
          </Flex>
        </Center>
      )}
      <Slider
        ref={sliderRef}
        adaptiveHeight={false}
        lazyLoad="progressive"
        prevArrow={
          <Box>
            <Box
              className="slick-inner-arrow"
              left={{
                base: undefined,
                lg: isPreview
                  ? `${(window.innerWidth - 775 - 100) / 2}px`
                  : undefined,
              }}
            />
          </Box>
        }
        nextArrow={
          <Box>
            <Box
              className="slick-inner-arrow"
              right={{
                base: undefined,
                lg: isPreview
                  ? `${(window.innerWidth - 775 - 100) / 2}px`
                  : undefined,
              }}
            />
          </Box>
        }
        beforeChange={(oldIndex, newIndex) => {
          setActiveThumbIndex(newIndex);
        }}
        afterChange={scrollToActiveSlide}
        speed={500}
        onInit={() => {
          if (initialImageIndex !== undefined) {
            sliderRef.current?.slickGoTo(initialImageIndex, false);
          }
        }}
        dots
        appendDots={appendDots}
        customPaging={customPaging}
        className="slick-with-paging"
        swipeToSlide={false}
        swipe={false}
      >
        {assetImages.map((image) => (
          <Box key={image.id} bgColor="transparent" position="relative">
            <ZoomWrapper isPreview={isPreview}>
              <Box
                onMouseDown={() => {
                  if (!isPreview) {
                    dragStatus.current = "PRESSED";
                    setClick((prev) => prev + 1);
                  }
                }}
                onMouseMove={() => {
                  if (!isPreview && dragStatus.current === "PRESSED") {
                    dragStatus.current = "DRAGGED";
                  }
                }}
                onMouseUp={() => {
                  if (!isPreview) {
                    if (dragStatus.current !== "DRAGGED") {
                      dragStatus.current = "NOT_DRAGGED";
                    } else {
                      dragStatus.current = "NOT_DRAGGED";
                    }
                    setClick((prev) => prev + 1);
                  }
                }}
                cursor={isPreview ? "default" : "pointer"}
                width="100%"
                height={isPreview ? `calc(100vh - 215px)` : "auto"}
                position="relative"
                _before={{
                  content: isPreview ? undefined : '""',
                  display: "block",
                  paddingTop: "100%",
                }}
              >
                <Box position="absolute" top="0" bottom="0" left="0" right="0">
                  <Image
                    src={getFilesUrl(image.path, 750)}
                    alt={image.name}
                    boxSize="full"
                    objectFit="contain"
                    fallback={
                      <Center width="full">
                        <PageSpinner />
                      </Center>
                    }
                  />
                </Box>
              </Box>
            </ZoomWrapper>
            {!isPreview && (!!handleEdit || !!handleDelete) && (
              <Box
                minWidth="50px"
                flexShrink={0}
                position="absolute"
                top="10px"
                right="10px"
                visibility="hidden"
                _groupHover={{ visibility: "visible" }}
              >
                {!!handleEdit && (
                  <Tooltip label="Edit Picture" hasArrow placement="bottom">
                    <IconButton
                      onClick={() => handleEdit(image.id)}
                      aria-label="Edit Asset"
                      variant="icon"
                      colorScheme="gray"
                    >
                      <FontAwesomeIcon icon={faPen} size="sm" />
                    </IconButton>
                  </Tooltip>
                )}
                {!!handleDelete && (
                  <Tooltip label="Delete Picture" hasArrow placement="bottom">
                    <IconButton
                      onClick={() => handleDelete(image)}
                      aria-label="Delete Asset"
                      variant="icon"
                      colorScheme="grayRed"
                      marginLeft="3"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
            <Box
              padding="2"
              color="black"
              visibility="hidden"
              _groupHover={{ visibility: "visible" }}
              textAlign="center"
            >
              <Text size="xs">{image.name}</Text>
              {!!image.description && (
                <Text size="xs" marginTop="1">
                  {image.description}
                </Text>
              )}
            </Box>
          </Box>
        ))}
      </Slider>
      {isOpen && previewAvailable && (
        <ImagesReorder
          assetId={assetId}
          onClose={onClose}
          assetImages={assetImages}
          initialImageIndex={activeThumbIndex}
          canSortAssetImages={canSortAssetImages}
        />
      )}
    </Box>
  );
};

export default ImageSlider;
