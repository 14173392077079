import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  Input,
  Textarea,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AssetIcon from "../../../components/elements/assetIcons";
import AssetIconField from "../../../components/elements/assetIcons/field";
import AssetTypeFields from "../../../components/elements/assetTypeFields";
import FormGroup from "../../../components/elements/formGroup";
import PageSpinner from "../../../components/elements/pageSpinner";
import SelectCombobox from "../../../components/elements/selectCombobox";
import { ASSET_TYPE_ADDED_MESSAGE } from "../../../constants/lang/en";
import {
  AssetCategoriesDocument,
  AssetCategoryFragmentFragment,
  AssetField,
  AssetFieldFragmentFragment,
  AssetPartFieldFragmentFragment,
  AssetTypeWithRelationsFragmentFragment,
  IconTypes,
  SpecialAssetFieldTypes,
  useAssetTypeCreateMutation,
  useAssetTypeLazyQuery,
} from "../../../graphql/graphql";
import useAssetTypes from "../../../hooks/useAssetTypes";
import { getRoutePath } from "../../../router";
import setServerErrors, {
  setGenericMessage,
} from "../../../utils/serverErrors";
import {
  assetFieldsSchema,
  descriptionSchema,
  iconColorSchema,
  iconNameSchema,
  iconTypeSchema,
  nameSchema,
  specialAssetFieldSchema,
  yupObject,
} from "../../../utils/validation";

interface AssetTypeCreatePresenterProps {
  onClose: () => void;
  assetCategory?: AssetCategoryFragmentFragment;
}

const AssetTypeCreatePresenter: React.FC<AssetTypeCreatePresenterProps> = ({
  onClose,
  assetCategory,
}) => {
  const [type, setType] = useState<"new" | "copy" | undefined>();
  const [defaultAssetType, setDefaultAssetType] =
    useState<AssetTypeWithRelationsFragmentFragment>();
  const toast = useToast();
  const navigate = useNavigate();
  const [fetchAssetType, { data }] = useAssetTypeLazyQuery({
    fetchPolicy: "network-only",
  });

  const assetTypes = useAssetTypes();

  const [assetTypeCreateMutation, { loading: isLoading }] =
    useAssetTypeCreateMutation({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: AssetCategoriesDocument }],
    });

  const handleDefaultAssetTypeSelect = useCallback(
    (id: string | null) => {
      fetchAssetType({ variables: { id } });
    },
    [fetchAssetType]
  );

  useEffect(() => {
    setDefaultAssetType(data?.assetType);
  }, [data?.assetType]);

  const onSubmit = async (
    data: AssetTypeFormData,
    { setFieldError }: FormikHelpers<AssetTypeFormData>
  ) => {
    try {
      const { data: serverData, errors } = await assetTypeCreateMutation({
        variables: { data: { ...data, assetCategoryId: assetCategory?.id } },
      });
      if (errors) {
        setServerErrors(errors, setFieldError);
      } else if (serverData) {
        onDrawerClose();
        toast({
          description: ASSET_TYPE_ADDED_MESSAGE,
          status: "success",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        description: setGenericMessage(error),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const onDrawerClose = () => {
    onClose();
    setTimeout(() => {
      navigate(getRoutePath("assetCategories"));
    }, 500);
  };

  return (
    <>
      <DrawerBody>
        {assetTypes ? (
          <Formik
            initialValues={
              {
                name: defaultAssetType ? defaultAssetType.name : "",
                description: defaultAssetType
                  ? defaultAssetType.description
                  : "",
                iconName: defaultAssetType ? defaultAssetType.iconName : "",
                iconColor: defaultAssetType ? defaultAssetType.iconColor : "",
                iconType: defaultAssetType
                  ? defaultAssetType.iconType
                  : IconTypes.Square,
                assetFieldsInput: defaultAssetType
                  ? getFieldsInitialValues(defaultAssetType.assetFields)
                  : [],
                assetPartFieldsInput: defaultAssetType
                  ? getFieldsInitialValues(defaultAssetType.assetPartFields)
                  : [],
                specialAssetField: defaultAssetType
                  ? defaultAssetType.specialAssetField
                  : SpecialAssetFieldTypes.Na,
              } as AssetTypeFormData
            }
            onSubmit={onSubmit}
            validationSchema={AssetTypeValidationSchema}
            enableReinitialize
          >
            {({ getFieldProps, values }) => (
              <Form id="asset_type_create" noValidate>
                <Flex align="center">
                  <Box fontSize="lg" marginBottom="3" marginRight="1">
                    Do you want to duplicate this asset type's fields from
                    another asset type?
                  </Box>
                  <Tooltip
                    label="Click YES if you want to copy the attributes from another asset type to save time having to recreate them. You will still be able to add, edit and delete any attributes you like."
                    hasArrow
                  >
                    <Box color="gray.700" marginLeft="2">
                      <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                    </Box>
                  </Tooltip>
                </Flex>
                <Flex>
                  <Button
                    width="full"
                    variant={type === "new" ? "solid" : "outline"}
                    colorScheme="secondary"
                    onClick={() => {
                      setDefaultAssetType(undefined);
                      setType("new");
                    }}
                    marginRight="2"
                  >
                    No
                  </Button>
                  <Button
                    width="full"
                    variant={type === "copy" ? "solid" : "outline"}
                    colorScheme="secondary"
                    onClick={() => {
                      setDefaultAssetType(undefined);
                      setType("copy");
                    }}
                    marginLeft="2"
                  >
                    Yes
                  </Button>
                </Flex>
                {type === "copy" && (
                  <>
                    {assetTypes?.length ? (
                      <SelectCombobox
                        label="Select asset type you want to duplicate"
                        name="copyFromId"
                        sortOptions
                        options={assetTypes.map((assetType) => ({
                          label: (
                            <Flex alignItems="center">
                              <AssetIcon
                                iconName={assetType.iconName}
                                iconColor={assetType.misc.resolvedIconColor}
                                iconType={assetType.iconType}
                                iconSize="xs"
                              />
                              <Box marginLeft="2">
                                {assetType.misc.resolvedName}
                              </Box>
                            </Flex>
                          ),
                          query: assetType.misc.resolvedName,
                          value: assetType.id,
                        }))}
                        onChange={handleDefaultAssetTypeSelect}
                        valueFromParent={defaultAssetType?.id}
                      />
                    ) : (
                      <Box textAlign="center">
                        This are no asset types to duplicate from
                      </Box>
                    )}
                  </>
                )}
                <Box mt="6">
                  <FormGroup label="Asset Type Name" name="name">
                    <Input
                      autoFocus
                      autoComplete="off"
                      {...getFieldProps("name")}
                    />
                  </FormGroup>
                  <FormGroup label="Description" name="description">
                    <Textarea
                      autoComplete="off"
                      {...getFieldProps("description")}
                    />
                  </FormGroup>
                  <AssetIconField
                    parentColor={assetCategory?.iconColor || undefined}
                  />
                  <Box display="none">
                    <SelectCombobox
                      label="Special Asset Field"
                      name="specialAssetField"
                      options={SpecialAssetFieldTypeOptions}
                    />
                  </Box>
                  {(type === "new" || !!defaultAssetType) && (
                    <>
                      <AssetTypeFields />
                      {values.specialAssetField !==
                        SpecialAssetFieldTypes.Na && (
                        <AssetTypeFields
                          name="assetPartFieldsInput"
                          label="Breaker Custom Fields"
                        />
                      )}
                    </>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <PageSpinner />
        )}
      </DrawerBody>
      <DrawerFooter>
        <Button
          width="full"
          type="submit"
          isLoading={isLoading}
          form="asset_type_create"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
};

export default AssetTypeCreatePresenter;

gql`
  mutation AssetTypeCreate($data: AssetTypeInput!) {
    assetTypeCreate(data: $data) {
      ...AssetTypeWithRelationsFragment
    }
  }
`;

export const AssetTypeValidationSchema = yupObject().shape({
  name: nameSchema.label("Asset Type name"),
  description: descriptionSchema,
  iconName: iconNameSchema,
  iconColor: iconColorSchema,
  iconType: iconTypeSchema,
  assetFieldsInput: assetFieldsSchema,
  assetPartFieldsInput: assetFieldsSchema,
  specialAssetField: specialAssetFieldSchema,
});

export type AssetTypeFormData = {
  name: string;
  description?: string;
  iconName: string;
  iconColor?: string;
  iconType: IconTypes;
  assetFieldsInput: AssetField[];
  assetPartFieldsInput: AssetField[];
  specialAssetField?: SpecialAssetFieldTypes;
};

export const SpecialAssetFieldTypeOptions = [
  {
    label: "No Special Field",
    query: "No Special Field",
    value: SpecialAssetFieldTypes.Na,
  },
  {
    label: "Electrical Panel - Normal",
    query: "Electrical Panel - Normal",
    value: SpecialAssetFieldTypes.ElectricalPanelNormal,
  },
  {
    label: "Electrical Panel - Grid",
    query: "Electrical Panel - Grid",
    value: SpecialAssetFieldTypes.ElectricalPanelGrid,
  },
];

export const getFieldsInitialValues = (
  fields: Array<AssetFieldFragmentFragment | AssetPartFieldFragmentFragment>
) =>
  fields
    ? sortBy(fields, "order").map((af) => ({
        id: af.id,
        label: af.label,
        type: af.type,
        selectOptions: af.selectOptions,
        unit: af.unit,
        required: af.required,
        validationRegExp: af.validationRegExp || "",
        validationRegExpMessage: af.validationRegExpMessage || "",
        order: af.order || undefined,
      }))
    : [];
