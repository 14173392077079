import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, formatDistanceToNow } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import MaintenanceRemindBeforeField from "../../../components/elements/maintenanceRemindBeforeField";
import {
  defaultDateWithTimeAndZoneFormat,
  defaultDateWithTimeFormat,
} from "../../../constants/date";
import {
  MaintenanceRemindBeforeType,
  MaintenanceScheduleFragmentFragment,
} from "../../../graphql/graphql";
import {
  maintenanceMiscTypeSchema,
  maintenanceMiscValueSchema,
  yupNumber,
  yupObject,
} from "../../../utils/validation";
import MaintenanceMarkAsComplete from "../markAsComplete";

interface MaintenanceCreatePresenterProps {
  loading: boolean;
  handleSnoozeSubmit: (
    values: MaintenanceFormData,
    formikHelpers: FormikHelpers<any>
  ) => void;
  onDrawerClose: () => void;
  maintenanceName: string;
  maintenanceLocationLabel: string;
  maintenanceSchedule: MaintenanceScheduleFragmentFragment;
  maintenanceContactIds: string[];
}

const MaintenanceCreatePresenter: React.FC<MaintenanceCreatePresenterProps> = ({
  loading,
  handleSnoozeSubmit,
  onDrawerClose,
  maintenanceName,
  maintenanceLocationLabel,
  maintenanceSchedule,
  maintenanceContactIds,
}) => {
  const [, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClose = useCallback(() => {
    onClose();
    setSearchParams({});
  }, [onClose, setSearchParams]);

  return (
    <Drawer isOpen onClose={onDrawerClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Service Schedule</DrawerHeader>
          <DrawerBody padding="6">
            {maintenanceSchedule.completedAt ? (
              <Heading as="h3" size="xl" marginBottom="4">
                {maintenanceName} was completed on{" "}
                {format(
                  maintenanceSchedule.completedAt,
                  defaultDateWithTimeFormat
                )}
                .
              </Heading>
            ) : (
              <>
                <Center marginBottom="4">
                  <Box>
                    <Heading as="h3" size="lg" marginBottom="4">
                      {maintenanceName} is due{" "}
                      {formatDistanceToNow(maintenanceSchedule.scheduledAt, {
                        addSuffix: true,
                      })}
                      .
                    </Heading>
                    <Button
                      width="full"
                      isLoading={loading}
                      leftIcon={<FontAwesomeIcon icon={faCheck} />}
                      onClick={onOpen}
                    >
                      Mark as Complete
                    </Button>
                  </Box>
                </Center>
                <Formik
                  initialValues={{
                    remindAfterType: MaintenanceRemindBeforeType.Hours,
                    remindAfterValue: 2,
                  }}
                  validationSchema={maintenanceFormValidationSchema}
                  onSubmit={handleSnoozeSubmit}
                  enableReinitialize
                >
                  {({ values }) => (
                    <Form noValidate>
                      <Divider borderColor="gray.300" marginTop="12" />
                      <Heading
                        as="h3"
                        size="lg"
                        textAlign="center"
                        marginBottom="4"
                      >
                        <Box
                          as="span"
                          display="inline-block"
                          backgroundColor="white"
                          paddingX="2"
                          position="relative"
                          top="-4"
                        >
                          OR
                        </Box>
                      </Heading>
                      <MaintenanceRemindBeforeField type="after" />
                      {!!values.remindAfterType && !!values.remindAfterValue && (
                        <Box marginTop="4">
                          <Button
                            width="full"
                            type="submit"
                            isLoading={loading}
                            variant="outline"
                            colorScheme="secondary"
                          >
                            Snooze Reminder
                          </Button>
                        </Box>
                      )}
                    </Form>
                  )}
                </Formik>
                {isOpen && (
                  <MaintenanceMarkAsComplete
                    maintenanceLocationsCopy={maintenanceLocationLabel}
                    maintenanceResolvedName={maintenanceName}
                    maintenanceScheduleDateCopy={format(
                      maintenanceSchedule.scheduledAt,
                      defaultDateWithTimeAndZoneFormat
                    )}
                    maintenanceScheduleId={maintenanceSchedule.id}
                    onClose={handleClose}
                    contactIds={maintenanceContactIds}
                  />
                )}
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MaintenanceCreatePresenter;

export const maintenanceFormValidationSchema = yupObject().shape({
  remindAfterType: maintenanceMiscTypeSchema
    .label("Reminder")
    .required("Required"),
  remindAfterValue: maintenanceMiscValueSchema
    .label("Reminder")
    .required("Required")
    .when("remindAfterType", {
      is: MaintenanceRemindBeforeType.Weeks,
      then: yupNumber().max(1, "Should be less than 1 week"),
    })
    .when("remindAfterType", {
      is: MaintenanceRemindBeforeType.Days,
      then: yupNumber().max(14, "Should be less than 14 days"),
    })
    .when("remindAfterType", {
      is: MaintenanceRemindBeforeType.Hours,
      then: yupNumber().max(24, "Should be less than 24 hours"),
    })
    .when("remindAfterType", {
      is: MaintenanceRemindBeforeType.Minutes,
      then: yupNumber().max(1440, "Should be less than 1440 minutes"),
    }),
});

export type MaintenanceFormData = {
  remindAfterType: MaintenanceRemindBeforeType;
  remindAfterValue: number;
};
