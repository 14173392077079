import { Icon, IconProps } from "@chakra-ui/react";

const MRI1 = (props: IconProps & any) => (
  <Icon boxSize="8" viewBox="0 0 128 128" {...props}>
    <path
      d="M114 52.232c0-4.23-.53-8.435-1.575-12.497a2.931 2.931 0 0 0-2.838-2.2H99.293C93.521 23.728 79.876 14 64 14c-20.096 0-36.62 15.585-38.121 35.303h-8.95A2.93 2.93 0 0 0 14 52.233c0 13.355 5.201 25.91 14.645 35.355 4.427 4.427 9.538 7.922 15.095 10.385-.204.4-.321.85-.321 1.33v11.767a2.93 2.93 0 0 0 2.93 2.93H81.65a2.93 2.93 0 0 0 2.93-2.93V99.303c0-.48-.117-.93-.321-1.33a49.986 49.986 0 0 0 15.096-10.385c9.443-9.444 14.644-22 14.644-35.356Zm-35.278 55.909H49.278v-5.909h29.444v5.909ZM31.627 52.232c0-17.85 14.522-32.373 32.373-32.373 17.85 0 32.373 14.523 32.373 32.373 0 4.96-1.123 9.663-3.125 13.869V64a2.93 2.93 0 0 0-2.93-2.93H75.734a14.62 14.62 0 0 0 2.963-8.838c0-8.104-6.593-14.697-14.697-14.697s-14.697 6.593-14.697 14.697a14.62 14.62 0 0 0 2.963 8.838H37.682a2.93 2.93 0 0 0-2.93 2.93v2.1a32.178 32.178 0 0 1-3.125-13.868Zm22.87 44.141-4.4-17.676h27.806l-4.4 17.676H54.497Zm.665-44.14c0-4.874 3.965-8.838 8.838-8.838 4.873 0 8.838 3.964 8.838 8.837 0 4.874-3.965 8.838-8.838 8.838-4.873 0-8.838-3.964-8.838-8.838ZM87.39 72.837H40.61V66.93H87.39v5.908ZM19.956 55.162h5.923c1.01 13.275 8.831 24.676 19.966 30.71l1.836 7.372c-15.422-6.159-26.585-20.773-27.725-38.082Zm60.363 38.083 1.836-7.373c11.946-6.473 20.077-19.124 20.077-33.64 0-3.04-.359-6-1.033-8.837h6.057c.587 2.894.884 5.858.884 8.837.001 18.578-11.536 34.51-27.82 41.013Z"
      fill="currentColor"
    />
    <path
      d="M61.591 31.627h4.818a2.93 2.93 0 0 0 0-5.86H61.59a2.93 2.93 0 0 0 0 5.86Z"
      fill="currentColor"
    />
  </Icon>
);

export default MRI1;
