import { useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";

import { useFileQuery, useFileSignedUrlQuery } from "../../../graphql/graphql";
import { currentCompanyRoleVar } from "../../../graphql/reactiveVariables";
import ROLES from "../../../roles";
import Presenter from "./presenter";

interface FilePageProps {}

const FilePage: React.FC<FilePageProps> = () => {
  const { fileId } = useParams();
  const { data } = useFileQuery({
    variables: { id: fileId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: signedUrlData } = useFileSignedUrlQuery({
    variables: { id: fileId },
    fetchPolicy: "no-cache",
  });
  const currentCompanyRole = useReactiveVar(currentCompanyRoleVar);

  return data && fileId ? (
    <Presenter
      file={data.file}
      fileSignedUrl={signedUrlData?.file.signedUrl}
      canEditFile={ROLES.filesEdit.includes(currentCompanyRole.role)}
      canDeleteFile={ROLES.filesDelete.includes(currentCompanyRole.role)}
    />
  ) : null;
};

export default FilePage;

gql`
  query File($id: UUID!) {
    file(id: $id) {
      ...FileFragment
    }
  }
`;
